/* eslint-disable no-console */

import { register } from 'register-service-worker';
import globals from './services/globals';

var TScMessenger = window.TScMessenger;

// let messageChannelPort2;

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development') {
// if (process.env.NODE_ENV === 'Prod' || process.env.NODE_ENV === 'Dev' || process.env.NODE_ENV === 'Test' || process.env.NODE_ENV === 'Upgrade' || process.env.NODE_ENV === 'Hotfix') {
  let versionNumber = process.env.VUE_APP_VERSION;

  let serviceWorkerPath = process.env.BASE_URL;
  if(location.href.indexOf('truck-science') !== -1) {
    serviceWorkerPath = location.origin + '/truck-science/';
  }
  globals.setServiceWorkerScope(serviceWorkerPath);
  TScMessenger.writeDebugMessage('Service worker path: ' + serviceWorkerPath);
  // register(`${process.env.BASE_URL}service-worker.js`, {
  // register(`./service-worker.js`, {
    register(serviceWorkerPath + 'service-worker.js', {
    ready () {
      TScMessenger.writeDebugMessage(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
      
    },
    registered (registration) {
      TScMessenger.writeDebugMessage('Service worker scope: ' + registration.scope);
      TScMessenger.writeDebugMessage('Service worker has been registered, version number: ' + versionNumber);
      
      if(registration.installing) {
        
        TScMessenger.writeDebugMessage('registerServiceWorker.js, registered, installing, posting NEW_APP_ACKNOWLEDGE_RESPONSE: ' + versionNumber);
          registration.installing.postMessage({ type: 'NEW_APP_ACKNOWLEDGE_RESPONSE'});
          if(registration.active) {
            const messageChannel = new MessageChannel();
            messageChannel.port1.onmessage = function (messageEvent) {
              TScMessenger.writeDebugMessage('registerServiceWorker.js, registered, installing, active, port1.onmessage');
              if(messageEvent.data && messageEvent.data.type && messageEvent.data.type === 'GET_VERSION_RESPONSE') {
                TScMessenger.writeDebugMessage('registerServiceWorker.js, registered, installing, active, port1.onmessage, receiving GET_VERSION_RESPONSE with: ' + messageEvent.data.version);
                globals.setIncomingSWVersionNumber(messageEvent.data.version)
              }
            };
            messageChannel.port1.onmessageerror = function () {
              TScMessenger.writeDebugMessage('registerServiceWorker.js, registered, installing, active, port1.onmessageerror, error setting up sw message channel 1');
            }
            
            TScMessenger.writeDebugMessage('registerServiceWorker.js, registered, installing, active, posting GET_VERSION to installing: ' + versionNumber);
            registration.installing.postMessage({ type: 'GET_VERSION'}, [messageChannel.port2]);
          }
          
      }
      if(registration.waiting) {
        TScMessenger.writeDebugMessage('registerServiceWorker.js, registered, waiting, posting NEW_APP_ACKNOWLEDGE_RESPONSE: ' + versionNumber);
          registration.waiting.postMessage({ type: 'NEW_APP_ACKNOWLEDGE_RESPONSE'});
          if(registration.active) {
            const messageChannel = new MessageChannel();
            messageChannel.port1.onmessage = function (messageEvent) {
              TScMessenger.writeDebugMessage('registerServiceWorker.js, registered, waiting, active, port1.onmessage');
              if(messageEvent.data && messageEvent.data.type && messageEvent.data.type === 'GET_VERSION_RESPONSE') {
                TScMessenger.writeDebugMessage('registerServiceWorker.js, registered, waiting, active, port1.onmessage, receiving GET_VERSION_RESPONSE with: ' + messageEvent.data.version);
                globals.setIncomingSWVersionNumber(messageEvent.data.version)
              }
            };
            messageChannel.port1.onmessageerror = function () {
              TScMessenger.writeDebugMessage('registerServiceWorker.js, registered, waiting, active, port1.onmessageerror, error setting up sw message channel 1');
            }
            TScMessenger.writeDebugMessage('registerServiceWorker.js, registered, waiting, active, posting GET_VERSION to waiting: ' + versionNumber);
            registration.waiting.postMessage({ type: 'GET_VERSION'}, [messageChannel.port2]);
          }
      }/*else {
          console.log('Posting NEW_APP_ACKNOWLEDGE_RESPONSE to active...' + versionNumber);
          registration.active.postMessage({ type: 'NEW_APP_ACKNOWLEDGE_RESPONSE'});
      }*/
      if(registration.active) {
          TScMessenger.writeDebugMessage('registerServiceWorker.js, registered, active, posting NEW_APP_ACKNOWLEDGE_RESPONSE: ' + versionNumber);
          registration.active.postMessage({ type: 'NEW_APP_ACKNOWLEDGE_RESPONSE'});
      }
      // if(versionNumber === '2023.1.8') {
      //   console.log('waiting: ' + registration.waiting );
      //   console.log('active: ' + registration.active );
      //   console.log('installing: ' + registration.installing );
      //   // if(registration.waiting && registration.active) {
      //   //     // if(localStorage.getItem('refreshing') === 'true') return;
      //   //     // localStorage.setItem('refreshing', 'true');
      //   //     console.log('SW waiting in index.html...');
      //   //     navigator.serviceWorker.addEventListener('controllerchange', function (event) {
      //   //     //console.log('controlling event detected');
      //   //     console.log('Calling window.location.reload(true);, controllerchange, index.html');
      //   //     window.location.reload(true);
      //   //     });
      //   //     registration.waiting.postMessage({ type: 'SKIP_WAITING'});
      //   // }
      //   registration.onupdatefound = function (event) {
      //       console.log('Service Worker update detected in registerServiceWorker...');
      //       // if(localStorage.getItem('refreshing') === 'true') return;
      //       // localStorage.setItem('refreshing', 'true');
      //       console.log('waiting: ' + registration.waiting );
      //       console.log('active: ' + registration.active );
      //       console.log('installing: ' + registration.installing );
      //       if (registration.active && registration.waiting) {
      //           console.log('posting skipwaiting 1 in registerServiceWorker...');
      //           navigator.serviceWorker.addEventListener('controllerchange', function (event) {
      //               //console.log('controlling event detected');
      //               console.log('Calling window.location.replace(window.location.origin), controllerchange 1, registerServiceWorker');
      //               localStorage.setItem('appUpdateDetected', 'YES');
      //               window.location.replace(window.location.origin);
      //           });
      //           console.log('posting skipwaiting 1 in registerServiceWorker...');
      //           registration.waiting.postMessage({ type: 'SKIP_WAITING'});
      //       }else {
      //           registration.installing.addEventListener('statechange', function (event) {
      //                   console.log('registerServiceWorker service worker state change fired: ' + event.target.state);
      //                   if (event.target.state === 'installed') {
      //                       if (registration.active) {
      //                           navigator.serviceWorker.addEventListener('controllerchange', function (event) {
      //                               //console.log('controlling event detected');
      //                               console.log('Calling window.location.reload(true);, controllerchange 2, registerServiceWorker');
      //                               localStorage.setItem('appUpdateDetected', 'YES');
      //                               window.location.replace(window.location.origin);
      //                           });
      //                           console.log('posting skipwaiting 2 in registerServiceWorker...');
      //                           registration.waiting.postMessage({ type: 'SKIP_WAITING'});
      //                       } else {
      //                           //do nothing - first time install
      //                       }
      //                   }
      //               });
      //           }
      //       // window.location.reload(true);
      //       //setupToastToInformUserOfUpdate();
      //       //doUpdateCode();
      //       // var metaUpdateReady = document.createElement('meta');
      //       // metaUpdateReady.name = "UPDATEREADY";
      //       // metaUpdateReady.content = 'UPDATEREADY_FIRED_INDEX';
      //       // document.getElementsByTagName('head')[0].appendChild(metaUpdateReady);
      //   };

      // }
      document.dispatchEvent(
        new CustomEvent('swRegistered', { detail: registration })
      );
      // setInterval(() => {
      //   registration.update();
      // }, 1000 * 60 * 5);
    },
    cached () {
      TScMessenger.writeDebugMessage('registerServiceWorker.js, cached');
    },
    updatefound (registration) {
      if(registration.installing) {
        TScMessenger.writeDebugMessage('registerServiceWorker.js, updatefound, installing, Posting NEW_APP_ACKNOWLEDGE_RESPONSE: ' + versionNumber);
        registration.installing.postMessage({ type: 'NEW_APP_ACKNOWLEDGE_RESPONSE'});
        if(registration.active) {
          const messageChannel = new MessageChannel();
          messageChannel.port1.onmessage = function (messageEvent) {
            TScMessenger.writeDebugMessage('registerServiceWorker.js, updatefound, installing, active, port1.onmessage');
            if(messageEvent.data && messageEvent.data.type && messageEvent.data.type === 'GET_VERSION_RESPONSE') {
              TScMessenger.writeDebugMessage('registerServiceWorker.js, updatefound, installing, active, port1.onmessage, receiving GET_VERSION_RESPONSE with: ' + messageEvent.data.version);
              globals.setIncomingSWVersionNumber(messageEvent.data.version)
            }
          };
          messageChannel.port1.onmessageerror = function () {
            TScMessenger.writeDebugMessage('registerServiceWorker.js, updatefound, installing, active, port1.onmessageerror, error setting up sw message channel 1');
          }
          TScMessenger.writeDebugMessage('registerServiceWorker.js, updatefound, installing, active, posting GET_VERSION to installing: ' + versionNumber);
          registration.installing.postMessage({ type: 'GET_VERSION'}, [messageChannel.port2]);
        }
      }
      if(registration.waiting) {
        TScMessenger.writeDebugMessage('registerServiceWorker.js, updatefound, waiting, Posting NEW_APP_ACKNOWLEDGE_RESPONSE: ' + versionNumber);
          registration.waiting.postMessage({ type: 'NEW_APP_ACKNOWLEDGE_RESPONSE'});
          if(registration.active) {
            const messageChannel = new MessageChannel();
            messageChannel.port1.onmessage = function (messageEvent) {
              TScMessenger.writeDebugMessage('registerServiceWorker.js, updatefound, waiting, active, port1.onmessage');
              if(messageEvent.data && messageEvent.data.type && messageEvent.data.type === 'GET_VERSION_RESPONSE') {
                TScMessenger.writeDebugMessage('registerServiceWorker.js, updatefound, waiting, active, port1.onmessage, receiving GET_VERSION_RESPONSE with: ' + messageEvent.data.version);
                globals.setIncomingSWVersionNumber(messageEvent.data.version)
              }
            };
            messageChannel.port1.onmessageerror = function () {
              TScMessenger.writeDebugMessage('registerServiceWorker.js, updatefound, waiting, active, port1.onmessageerror, error setting up sw message channel 1');
            }
            TScMessenger.writeDebugMessage('registerServiceWorker.js, updatefound, waiting, active, posting GET_VERSION to installing: ' + versionNumber);
            registration.waiting.postMessage({ type: 'GET_VERSION'}, [messageChannel.port2]);
          }
      }/*else {
          console.log('Posting NEW_APP_ACKNOWLEDGE_RESPONSE to active...' + versionNumber);
          registration.active.postMessage({ type: 'NEW_APP_ACKNOWLEDGE_RESPONSE'});
      }*/
      if(registration.active) {
        TScMessenger.writeDebugMessage('registerServiceWorker.js, updatefound, active, posting NEW_APP_ACKNOWLEDGE_RESPONSE: ' + versionNumber);
          registration.active.postMessage({ type: 'NEW_APP_ACKNOWLEDGE_RESPONSE'});
      }
      TScMessenger.writeDebugMessage('registerServiceWorker.js, updatefound, new content is downloading')
      document.dispatchEvent(
        new CustomEvent('swUpdateFound', { detail: registration })
      );
      
    },
    updated (registration) {
      TScMessenger.writeDebugMessage('registerServiceWorker.js, updated, new content is available; please refresh.')
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration })
      );
    },
    offline () {
      TScMessenger.writeDebugMessage('registerServiceWorker.js, offline, no internet connection found. App is running in offline mode.')
    },
    error (error) {
      TScMessenger.writeErrorMessage('registerServiceWorker.js, error, error during service worker registration: ', error)
    }
  })
}
