<template>
    <div class="template-menu-content-div">
        <div id="menu-content">
            <div class="menu-container" v-if="activeMenuStep">
                <div :class="activeMenuStep.customCss">
                    <table>
                        <MenuContentItem :itemsFromParent="menuContentItems"></MenuContentItem>
                    </table>
                </div>
            </div>
        </div>
    </div>


    <!-- <div>
        'template-menu-content'
    </div> -->

    <!-- id="template-menu-content"
    <div id="menu-content" data-bind="with: activeMenuStep">
        <div class="menu-container">
            <div data-bind="css: customCss">
                <table>
                    <tbody data-bind="template: {name: 'template-menu-item', foreach: menuStepItems, as: 'menuItem'}"></tbody>
                </table>
            </div>
        </div>
    </div> -->
</template>

<script>
import { shallowRef, watch, onUpdated, onMounted, toRef } from 'vue';

const TScMessenger = window.TScMessenger;

export default {
    props: {
        activeMenuStepFromParent: Object
    },
    setup(props) {
        const activeMenuStep = toRef(props, "activeMenuStepFromParent");
        const menuContentItems = shallowRef(null);
        let triggerChangeWatchCreated = false;

        if(activeMenuStep.value) {
            // menuContentItems.value = activeMenuStep.value.menuStepItems;
            setMenuContentItems(activeMenuStep.value.menuStepItems);
        }

        watch(() => props.activeMenuStepFromParent,
            (newValue, oldValue) => {
                if(!triggerChangeWatchCreated) {
                    setupTriggerChangeWatch();
                }
                // if(Array.isArray(activeMenuStep.value.menuStepItems)) {
                //     menuContentItems.value = activeMenuStep.value.menuStepItems;
                // } else {
                //     menuContentItems.value = activeMenuStep.value.menuStepItems.value;
                // }
                setMenuContentItems(activeMenuStep.value.menuStepItems);
            });
        if(activeMenuStep.value) {
            setupTriggerChangeWatch();
        }
        // watch(() => activeMenuStep.value.triggerChange, 
        //     (newValue, oldValue) => {
        //         console.log('triggerChange')
        //         if(newValue !== null) {
        //             if(Array.isArray(activeMenuStep.value.menuStepItems)) {
        //                 menuContentItems.value = activeMenuStep.value.menuStepItems;
        //             } else {
        //                 menuContentItems.value = activeMenuStep.value.menuStepItems.value;
        //             }
        //         }
        //     });

        onUpdated(() => {
            // TScMessenger.writeDebugMessage('in MenuContent onUpdated');
        });

        onMounted(() => {
            // TScMessenger.writeDebugMessage('in MenuContent onMounted');
        });
        
        function setMenuContentItems(msi) {
            if(Array.isArray(msi)) {
                menuContentItems.value = msi;
            } else {
                menuContentItems.value = msi.value;
            }
        }

        function setupTriggerChangeWatch() {
            triggerChangeWatchCreated = true;
            watch(() => activeMenuStep.value.triggerChange, 
            (newValue, oldValue) => {
                // TScMessenger.writeDebugMessage('triggerChange')
                if(newValue !== null) {
                    if(Array.isArray(activeMenuStep.value.menuStepItems)) {
                        menuContentItems.value = activeMenuStep.value.menuStepItems;
                    } else {
                        menuContentItems.value = activeMenuStep.value.menuStepItems.value;
                    }
                }
            });
        }

        return {
            activeMenuStep,
            menuContentItems
        }
    },
}
</script>

<style lang="scss" scoped>
</style>