
import config from './config';
import globals from './globals';
import settings from './settings/settings';
import logger from './logger';
import utils from './utils';
import dataManager from './dataManager';
import Interpreter from './interpreter';
import AttributeObservableGroup from './attributeObservableGroup';

// import { config, utils, globals, Interpreter, logger, AttributeObservableGroup, RigOps }  from '@/services';
import ValidationEngine from './validation/validationEngine';
import Customer from '../model/offer/customer/model.customer';
import Body from '../model/offer/configuration/model.body';
import Payload from '../model/offer/configuration/model.payload';
import PayloadStub from '../model/offer/configuration/model.payloadStub';
import BodyUpright from '../model/offer/configuration/model.bodyUpright';
import Crane from '../model/offer/configuration/model.crane';
import Other from '../model/offer/configuration/model.other';
import Fridge from '../model/offer/configuration/model.fridge';
import Taillift from '../model/offer/configuration/model.taillift';
import BodyStub from '../model/offer/configuration/model.bodyStub';
import AccessoryStub from '../model/offer/configuration/model.accessoryStub';
import FifthWheel from '../model/offer/configuration/model.fifthWheel';
import TrailerStub from '../model/offer/configuration/model.trailerStub';
import Trailer from '../model/offer/configuration/model.trailer';
import Hitch from '../model/offer/configuration/model.hitch';
import Hooklift from '../model/offer/configuration/model.hooklift';
import Offer from '../model/offer/model.offer';
import RigOps from './rigOps';
// import { Offer, Customer, Body, Payload, PayloadStub, BodyUpright, Crane, Other, Fridge, Taillift, BodyStub, AccessoryStub, FifthWheel, TrailerStub, Trailer, Hitch, Hooklift} from '@/model/offer/';
import Legislation from './legislation/model.legislation';
// import { pdfManager, pdfBuilders} from './pdf';
import pdfManager from '../services/pdf/pdfManager';
import pdfBuilders from '../services/pdf/pdfMakeBuilderHelper';
import legislationVals from './enum/utilLegislationAttributeObjectCode';
import UndoHandler from './undo/undoHandler';
// import $ from '../assets/scripts/jquery-3.6.0.min';
import Globalize from '../assets/scripts/globalize/globalize';
// import toastr from '../assets/scripts/toastr.min';

import { shallowRef, ref, computed, watch, nextTick, watchEffect, triggerRef, isRef } from 'vue';
import { Position, Size } from '@/model/drawing';
import bus from '@/main';
import CustomMessageBox from '@/services/CustomMessageBox';
import { router, ROUTES }  from '../router';
import Attribute from './legislation/model.attribute';

const $ = window.$;
const toastr = window.toastr;
const d3 = window.d3;
const TScMessenger = window.TScMessenger;
const svgAsPngUri = window.svgAsPngUri;



/*
define(['services/dataManager', 'services/config', 'services/utils', 'model/offer/model.offer', 'model/offer/customer/model.customer', 'model/offer/configuration/model.body',
    'model/offer/configuration/model.payload', 'model/offer/configuration/model.bodyUpright', 'services/attributeObservableGroup', 'durandal/app', 'services/globals', 'services/legislation/model.legislation',
    'services/interpreter', 'services/validation/validationEngine', 'model/offer/configuration/model.crane', 'model/offer/configuration/model.other', 'model/offer/configuration/model.fridge',
    'model/offer/configuration/model.taillift', 'services/pdf/pdfManager', 'model/offer/configuration/model.bodyStub', 'model/offer/configuration/model.accessoryStub', 'viewmodels/modals/verifyEmailModal',
    'durandal/system', 'services/logger', 'viewmodels/modals/customMessageBox', 'model/offer/configuration/model.fifthWheel', 'model/offer/configuration/model.trailerStub', 'model/offer/configuration/model.trailer',
    'services/enum/utilLegislationAttributeObjectCode', 'services/pdf/pdfMakeBuilderHelper', 'services/rigOps', 'model/offer/configuration/model.payloadStub', 'model/offer/configuration/model.hitch', 'durandal/plugins/router',
    'model/drawing/position', 'model/drawing/size', 'services/undo/undoHandler', 'model/offer/configuration/model.hooklift', 'viewmodels/modals/requestTrialExtensionModal'
],
    function (dataManager, config, utils, Offer, Customer, Body, Payload, BodyUpright, AttributeObservableGroup, app, globals, Legislation, Interpreter, ValidationEngine, Crane, Other,
        Fridge, Taillift, pdfManager, BodyStub, AccessoryStub, VerifyEmailModal, system, logger, CustomMessageBox, FifthWheel, TrailerStub, Trailer, legislationVals, pdfBuilders, RigOps, PayloadStub, Hitch, router, Position, Size, UndoHandler, Hooklift, requestTrialExtensionModal) {
*/

var
    //#region properties
    offerManager,
    rigOps = new RigOps(),
    evaluateLegislationCalled = 0,
    legislationValueFoundInQuickLookup = 0,
    legislationValueInterpreted = 0,
    interpreterInstance = new Interpreter(),
    curActiveLegislationsDataObject,
    liveUpdateMultipleLegislationsDataObject = {},
    generatingActiveLegislationsData = false,
    //overloadingColourInstances = {},
    mergedLegislationBridgeGroupsVisible,
    worstOverloadingStatus = config.OVERLOADING_STATUS.OK,
    lookupObjectsCleared = false,
    tableCellHighlightColours = {},
    bridgeFormulaPermissibleHighlightColours = {},
    steerAxleMinimumHighlightColours = {},
    worstBridgeFormulaOverloadColour = {},
    worstBridgeFormulaPermissibleOverloadColour = {},
    worstSteerAxleMinimumOverloadColour = {},
    changeListenerCallback,
    objectAddedListenerCallback,
    addToLocalStorageCallback,
    detachGraphicsManagerOnResizeCallback,
    waypointAddedListenerCallback,
    getDataUrlCallback,
    createBlobForDownloadCallback,
    graphicsManagerCleanUpCallback,
    clearOfferCallback,
    expandMenuLinkCallback,
    postCompleteSwapFunction,
    validationListeners = [],
    renderingCompleteListeners = [],
    syncListeners = [],
    undoRedoListeners = [],
    showShellSpinner,
    offerIsOpen = shallowRef(false),
    performValidation,
    //setActiveLegislationUsingIdInternalCall = false,
    availableBodies = [],
    availableAccessories = [],
    availableTrailers = [],
    availablePayloads = [],
    attributeObservableGroups = {},
    validationConfigs = {},
    imagesArray,
    lastErrorInputId = '',
    lastErrorTimerId = null,
    configurationActionFailedCallback,
    configurationActionResponseHandlerCallback,
    numChangesInCurrentCalculation = 0,
    emailVerified = shallowRef(false),
    numLinkableMissingValues = 0,
    numLinkableTurningCircleMissingValues = 0,
    landscapeImageWidth,
    landscapeImageHeight,
    updateBodyMenuCallback,
    updateVehicleMenuCallback,
    updateAccessoryMenuLabelCallback,
    createPdfWithPromiseCallback,
    refreshMenuOnChangeOfCabDimensionsOrChassisLengthCallback = null,
    syncErrorToastHandlerCallback,
    syncSuccessToastHandlerCallback,
    syncSavedCopyToastHandlerCallback,
    newSharedOfferToastHandlerCallback,
    newOrUpdatedTeamItemsSyncToastHandlerCallback,
    validationFailedWhileDraggingToastHandlerCallback,
    shellOpenBuyNowDialogCallback,
    refreshDataCallback = null,
    openSettingsModalCallback,
    curNotes,
    preventCostingTotalRevenueUpdate = false,
    curDistinctWarnings,
    curDistinctWarningsPerLegislation,
    curDistinctTips,
    curDDRNotes,
    curDistinctDDRNotes,
    offerOpen = false,
    dragging = false,
    curTurningCircleVals = null,
    defaultTurningCircleVals = {},
    notifyGraphicsManagerOnValidationFail = false,
    unitOfMeasureOptions = [],
    shellLogout,
    shellLogin,
    shellManageHiddenButtons,
    shellUpdateApplicationSelectionAndVehicleTypeSelection,
    shellEvaulateShowSaveOption,
    shellHandleEssentialsMessageResponse,
    shellDoAppGeneralSetupSteps,
    allowUpdateOfUnsavedChangesOnOffer = true,
    allowSpecificationUpdateOfUnsavedChangesOnOffer = false,
    preventDoActionsAfterSuccessfulValidationCall = false,
    // preventUpdatesDuringCleanup = false,
    preventUpdateDuringAOGOptionIdWatch = false,
    dynamicValidationGroups,
    legislationQuickLookup = {},
    skipGraphicsCallback = false,
    newAndSavedOffersForVehicleSelectionInMenu,
    clearPerformanceSimulationResultOnly,
    allowedAccessoryTypes,
    allowedTrailerTypesTrailer1,
    allowedTrailerTypesTrailer2,
    documentDefinition,
    openOfferIsSharedOffer = false,
    legislationSubstitutedForSharedCalc = false,
    defaultLegislationUsedDuToMissingLegislation = false,
    sharedOfferShareeUserId = null,
    sharedOfferCustomerId = null,
    sharedOfferOfferShareStatus = null,
    rigObjectRemovalHandlerCallback = null,
    postUndoConfigurationCallback = null,
    turningCircleLockedToSteeringAngleInner = false,
    requestSalesToolCallback = null,
    openSettingsModalOnClickFunction = null,
    sendIntercomSharedOfferAcceptedEvent,
    shareeUserId,
    shellOpenUserSettingsModalCallback,
    //offerOpeningListenerCallback,

    refreshUILiveLabels = null,
    refreshData = null,
    refreshDataSubscriptionRef = null,
    refreshCostingData = null,
    refreshSWLabels = null,
    id = shallowRef(),
    updateCounter = shallowRef(),
    currencySymbolMajor = shallowRef(),
    currencySymbolMinor = shallowRef(),
    languageId = shallowRef(),
    totalCapitalCost = shallowRef(),
    vehicleId = shallowRef(),
    vehicleName = shallowRef(),
    vehicleStatus = shallowRef(),
    vehicleModel = shallowRef(),
    vehicleMake = shallowRef(),
    vehicleRange = shallowRef(),
    vehicleGVW = shallowRef(),
    vehiclePayload = shallowRef(),
    vehiclePower = shallowRef(),
    vehicleAxleLayout = shallowRef(),
    vehiclePlusBodyImageURL = shallowRef(),
    bodyType = shallowRef(),
    bodyManufacturer = shallowRef(),
    vehicleTypeTranslation = shallowRef(),
    vehicleDistributionGroupId = shallowRef(),
    vehicleDistributionOptionId = shallowRef(),
    // optionPrice = shallowRef(0),
    specificationFetchComplete = shallowRef(false),
    specificationFetchSuccessful = shallowRef(false),
    selectedCustomer = shallowRef(null),
    containsUnsavedChanges = shallowRef(true),
    selectedCompetitor1Id = shallowRef(),
    selectedCompetitor2Id = shallowRef(),
    selectedCompetitor1 = shallowRef(),
    selectedCompetitor2 = shallowRef(),
    customerAttachedToOffer = shallowRef(false),
    unsavedChangesOnOffer = shallowRef(false),
    customerNamesList = shallowRef([]),
    customerList = shallowRef([]),
    availableCustomersSelected = shallowRef(false),
    isReadOnly = shallowRef(false),
    validPdfOnLocalStorage = shallowRef(true),
    pdfNoOfPages = shallowRef(),
    summaryNote = shallowRef(),
    // onRoadCostsVersion = onRoadCostsVersion,
    _legislation,
    _legislations,
    _activeOffer,
    _originalOffer,
    _baseOffer,
    
    interpreter,
    recentlyCachedSubscriptionRef,
    cached = shallowRef(0),
    configDrawing,
    configDrawingTopView,
    configDrawingCogView,
    vehicleConfigurationDrawingObjects = {},
    totalPages,
    costingChartDrawing = null,
    performanceChartDrawing = null,
    stackedBarChartSvg = null,
    activeConfigurationWizardItem = null,
    activeConfigurationWizardItemSize = null,
    originalConfigurationWizardItem = null,
    originalConfigurationWizardItemSize = null,
    undoHandler = null,
    numUndoOps = shallowRef(0),
    imageDataByUrl,
    imageHasBeenAddedToPdfMap,
    numRedoOps = shallowRef(0),
    
    undoHandlerHasNoUndoOps = computed(function () {
        return numUndoOps.value === 0;
    }),
    undoHandlerHasNoRedoOps = computed(function () {
        return numRedoOps.value === 0;
    }),
    // offer properties

    activeLegislation = shallowRef(),
    //activeLegislationId = shallowRef(),
    activeMeasurementSystem = shallowRef(),
    
    activeApplication = shallowRef(),
    
    description = shallowRef(),
    //additionalDescription = shallowRef(),
    preventSettingsUpdate = false,
    displayCostingTab = false,
    displaySpecificationTab = false,
    standardItemToast = null,
    licenceFeesMessageArray = [],
    notifyUserOfTollFeesChange = false,

    //#region Observables
    
    
    
    
    //Configuration
    errorMessage = shallowRef(),
    activeCabColour = shallowRef(),
    activeConfigurationViewDetail = shallowRef(),
    activeConfigurationViewType = shallowRef(),
    activeConfigurationElevation = shallowRef(),
    activeConfigurationDisplay = shallowRef(),
    //Costing
    noOfUnits = shallowRef(),
    noOfUnitsMin = shallowRef(),
    noOfUnitsMax = shallowRef(),
    noOfUnitsIncrement = shallowRef(),
    fuelCost = shallowRef(),
    fuelCostMin = shallowRef(),
    fuelCostMax = shallowRef(),
    vehiclePrice = shallowRef(),
    vehiclePriceMin = shallowRef(),
    vehiclePriceMax = shallowRef(),
    vehiclePriceIncrement = shallowRef(),
    bodyAndEquipmentPrice = shallowRef(),
    bodyAndEquipmentPriceMin = shallowRef(),
    bodyAndEquipmentPriceMax = shallowRef(),
    bodyAndEquipmentPriceIncrement = shallowRef(),
    optionPrice = shallowRef(),
    optionPriceMin = shallowRef(),
    optionPriceMax = shallowRef(),
    optionPriceIncrement = shallowRef(),
    optionPriceMinSlider = shallowRef(),
    optionPriceMaxSlider = shallowRef(),
    monthlyDistance = shallowRef(),
    monthlyDistanceMin = shallowRef(),
    monthlyDistanceMax = shallowRef(),
    monthlyDistanceIncrement = shallowRef(),
    fuelConsumption = shallowRef(),
    fuelConsumptionMin = shallowRef(),
    fuelConsumptionMax = shallowRef(),
    fuelConsumptionIncrement = shallowRef(),
    fuelCostIncrement = shallowRef(),
    maintenanceRate = shallowRef(),
    maintenanceRateMin = shallowRef(),
    maintenanceRateMax = shallowRef(),
    maintenanceRateIncrement = shallowRef(),
    depositRate = shallowRef(),
    depositRateMin = shallowRef(),
    depositRateMax = shallowRef(),
    depositRateIncrement = shallowRef(),
    residualRate = shallowRef(),
    residualRateMin = shallowRef(),
    residualRateMax = shallowRef(),
    residualRateIncrement = shallowRef(),
    interestRate = shallowRef(),
    interestRateMin = shallowRef(),
    interestRateMax = shallowRef(),
    interestRateIncrement = shallowRef(),
    repaymentTerm = shallowRef(),
    repaymentTermMin = shallowRef(),
    repaymentTermMax = shallowRef(),
    repaymentTermIncrement = shallowRef(),
    onRoadCostsCosts = shallowRef(),
    onRoadCostsDiscounts = shallowRef(),
    originalVehiclePrice = shallowRef(),
    originalBodyAndEquipmentPrice = shallowRef(),
    originalOptionPrice = shallowRef(),

    financeCostPerMonthFormattedComp = null,
    financeCostPerKMFormattedComp = null,
    crewCostPerMonthFormattedComp = null,
    crewCostPerKMFormattedComp = null,
    insuranceCostPerMonthFormattedComp = null,
    insuranceCostPerKMFormattedComp = null,
    licenceFeesCostPerMonthFormattedComp = null,
    licenceFeesCostPerKMFormattedComp = null,
    fuelAndAdblueCostPerMonthFormattedComp = null,
    fuelAndAdblueCostPerKMFormattedComp = null,
    overheadsCostPerMonthFormattedComp = null,
    overheadsCostPerKMFormattedComp = null,
    fixedCostsTotalCostsPerMonthFormattedComp = null,
    fixedCostsTotalCostsPerKMFormattedComp = null,
    fuelCostPerMonthFomattedComp = null,
    fuelCostPerKMFormattedComp = null,
    tyreCostPerMonthFormattedComp = null,
    tyreCostPerKMFormattedComp = null,
    maintenanceCostPerMonthFormattedComp = null,
    maintenanceCostPerKMFormattedComp = null,
    tollFeesCostPerMonthFormattedComp = null,
    tollFeesCostPerKMFormattedComp = null,
    variableCostsTotalCostPerMonthFormattedComp = null,
    variableCostsTotalCostPerKMFormattedComp = null,
    totalCostsPerMonthFormattedComp = null,
    totalCostsPerKMFormattedComp = null,
    totalRevenuePerMonthFormattedComp = null,
    totalRevenuePerKMFormattedComp = null,
    totalProfitPerMonthFormattedComp = null,
    totalProfitPerKMFormattedComp = null,
    totalFinanceCostFormattedComp = null,
    financeCostPercentageOfTotalFormattedComp = null,
    crewCostPercentageOfTotalFormattedComp = null,
    insuranceCostPercentageOfTotalFormattedComp = null,
    licenceFeesCostPercentageOfTotalFormattedComp = null,
    overheadsCostPercentageOfTotalFormattedComp = null,
    fuelCostPercentageOfTotalFomattedComp = null,
    tyreCostPercentageOfTotalFormattedComp = null,
    maintenanceCostPercentageOfTotalFormattedComp = null,
    tollFeesCostPercentageOfTotalFormattedComp = null,
    //Performance
    waypoints = shallowRef([]),
    simulationResults = shallowRef([]),
    tripCosts = shallowRef([]),
    topLeftLatitude = shallowRef(),
    topLeftLongitude = shallowRef(),
    bottomRightLatitude = shallowRef(),
    bottomRightLongitude = shallowRef(),
    tripDistanceOutbound = shallowRef(),
    tripTimeOutbound = shallowRef(),
    fuelConsumptionOutbound = shallowRef(),
    totalFuelOutbound = shallowRef(),
    tripDistanceReturn = shallowRef(),
    tripTimeReturn = shallowRef(),
    fuelConsumptionReturn = shallowRef(),
    totalFuelReturn = shallowRef(),
    totalUnladen = null,
    maximumPayload = null,
    maximumGross = null,
    preventToastFromBeingDisplayed = false,

    //Specification
    baseVehicle = shallowRef(),
    specificationHeadings = shallowRef([]),
    //Summary
    //#endregion Observables

    //#region Computeds
    cachingCompleted = computed(function () {
        return cached.value === 2;
    }),
    cachingNotCompleted = computed(function () {
        return cached.value !== 2;
    }),

    
    vehicleTitleComputed = computed(function () {
        return description.value;                
    }),
    vehicleDescriptionComputed = computed(function () {
        description.value;
        if (getActiveOffer() !== '' && getActiveOffer() !== undefined) {
            if (globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code)) {
                return '';
            } else {
                if (description.value !== getActiveOffer().getRig().getVehicle().getDescription() && description.value !== '') {
                    return getActiveOffer().getRig().getVehicle().getDescription();
                } else {
                    return '';
                }
            }
        } else {
            return '';
        }
    }),
    
    monthlyRepayment = computed(function () {
        if (interestRate.value > 0) {
            return globals.calculateMonthlyRepaymentWithInterestRate(totalCapitalCosts.value, depositRate.value, residualRate.value, interestRate.value, repaymentTerm.value);
        }
        if (interestRate.value === 0 && repaymentTerm.value > 0) {
            return globals.calculateMonthlyRepaymentWithoutInterestRate(totalCapitalCosts.value, depositRate.value, residualRate.value, repaymentTerm.value);
        }
        if (repaymentTerm.value === 0) {
            return globals.calculateMonthlyRepaymentWithoutRepaymentTerm(totalCapitalCosts.value, depositRate.value, residualRate.value);
        }
        return 0;
    }),
    totalCapitalCosts = computed(function () {
        return vehiclePrice.value + bodyAndEquipmentPrice.value + optionPrice.value;
    }),
    competitor1Array = computed(function () {
        selectedCompetitor2.value;
        if (getActiveOffer() !== undefined && getSpecification() !== undefined) {
            return getSpecification().getCompetitorVehicles().filter(function (vehicle) {
                if (selectedCompetitor2.value !== undefined) {
                    return vehicle.vehicleId !== selectedCompetitor2.value.vehicleId;
                } else {
                    return false;
                }
            });
        }
        return false;
    }),
    competitor2Array = computed(function () {
        selectedCompetitor1.value;
        if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
            return getSpecification().getCompetitorVehicles().filter(function (vehicle) {
                if (selectedCompetitor1.value !== undefined) {
                    return vehicle.vehicleId !== selectedCompetitor1.value.vehicleId;
                } else {
                    return false;
                }
            });
        }
        return false;
    }),
    totalNetPrice = computed(function () {
        return vehiclePrice.value /*+ bodyAndEquipmentPrice() + optionPrice.value*/;
    }),
    totalNetPriceFormatted = computed(function () {
        return Globalize.format(totalNetPrice(), "n0"); // User Story 17565
    }),
    vehicleNetPrice = computed(function () {
        return vehiclePrice.value;
    }),
    totalOnRoadCosts = computed(function () {
        var onRoadCostsTotal = 0;
        onRoadCostsCosts.value.forEach(function (currentItem, index) {
            onRoadCostsTotal += currentItem.constantValueObv();
        });
        return parseInt(totalNetPrice()) + onRoadCostsTotal;
    }),
    totalOnRoadCostsLessCapitalCosts = computed(function () {
        return totalOnRoadCosts() - totalNetPrice();
    }),
    totalOnRoadCostsLessDiscount = computed(function () {
        var onRoadDiscountsTotal = 0;
        onRoadCostsDiscounts.value.forEach(function (currentItem, index) {
            onRoadDiscountsTotal += currentItem.constantValueObv();
        });
        return totalOnRoadCosts() - onRoadDiscountsTotal;
    }),
    totalOnRoadCostsDiscount = computed(function () {
        var onRoadDiscountsTotal = 0;
        onRoadCostsDiscounts.value.forEach(function (currentItem, index) {
            onRoadDiscountsTotal += currentItem.constantValueObv();
        });
        return onRoadDiscountsTotal;
    }),
    totalFinanceCost = computed(function () {
        return monthlyRepayment.value * repaymentTerm.value;
    }),
    totalOnRoadCostsFormatted = computed(function () {
        return Globalize.format(totalOnRoadCosts(), "n0"); // User Story 17565
    }),
    totalOnRoadCostsLessDiscountFormatted = computed(function () {
        return Globalize.format(totalOnRoadCostsLessDiscount(), "n0"); // User Story 17565
    }),
    totalCapitalCostsFormatted = computed(function () {
        return Globalize.format(totalCapitalCosts(), "n" + getDecimalsHighPricedItems()); // User Story 17565
    });


//#endregion Computeds
//#endregion properties

//#endregion

settings.setOfferManager(offerManager);

dataManager.registerSyncOpSuccessResponseHandler(syncOpSuccessResponseHandler);

function syncOpSuccessResponseHandler(op, itemDescriptionText, response, idPriorToSync) {
    var actionTextToUse = '';
    var showTaostrAsWarning = false;
    var toastText = '';
    switch (op) {
        case config.OPERATION.SaveOffer.Code:
            globals.offerSyncing.value = false;
            if (offerIsOpen.value && (offerManager.id.value === response.savedOffer.Offer.Id || offerManager.id.value === idPriorToSync)) {
                handleSaveOfferResponse(response);
                if(globals.dependentActionDfd) {
                    globals.dependentActionDfd.resolve();
                    globals.dependentActionDfd = null;
                }
            }
            if (offerIsOpen.value && getActiveOffer().getIsFromTSV()) {
                //getActiveOffer().setIsFromTSV(false);
                getActiveOffer().setId(response.savedOffer.Offer.Id);
                id.value = response.savedOffer.Offer.Id;
            }
            actionTextToUse = config.getTranslationText('647').toLowerCase();
            if (response.savedOffer.Result.ReturnCode === 2) {
                showTaostrAsWarning = true;
                //toastText = config.getTranslationText('818').replace('$S1', itemDescriptionText).replace('$S2', response.savedOffer.Offer.Description);
                toastText = config.getTranslationText('818', [itemDescriptionText, response.savedOffer.Offer.Description]);
            }
            break;
        case config.OPERATION.SaveCustomAccessory.Code:
            handleSaveCustomAccessoryResponse(op, response, idPriorToSync);
            actionTextToUse = config.getTranslationText('2799').toLowerCase();
            if (response.savedAccessory.Result.ReturnCode === 2 || response.savedAccessory.Result.ReturnCode === 3) {
                showTaostrAsWarning = true;
                var libraryType = response.savedAccessory.Result.ReturnCode === 3 ? config.getTranslationText('2823') : config.getTranslationText('2824');
                //toastText = config.getTranslationText('1408').replace('$S1', itemDescriptionText).replace('$S2', libraryType).replace('$S3', response.savedAccessory.AccessoryDescription);
                toastText = config.getTranslationText('1408', [itemDescriptionText, libraryType, response.savedAccessory.AccessoryDescription]);
            }
            break;
        case config.OPERATION.SaveCustomer.Code:
            if (offerIsOpen.value && offerManager.selectedCustomer.value !== null && offerManager.selectedCustomer.value !== undefined && offerManager.selectedCustomer.value !== '' &&
                (offerManager.selectedCustomer.value.getId() === response.savedCustomer.Customer.Id || offerManager.selectedCustomer.value.getId() === idPriorToSync)) {
                handleSaveCustomerResponse(op, response, idPriorToSync);
            }
            actionTextToUse = config.getTranslationText('647').toLowerCase();
            break;
        case config.OPERATION.UpdateOfferStatus.Code:
            if (offerIsOpen.value && (offerManager.id.value === idPriorToSync)) {
                getActiveOffer().setUpdateCounter(response.offerStatusResponse.UpdateCounter);
            }
            if (selectedCustomer.value !== undefined && selectedCustomer.value !== null && selectedCustomer.value !== '' && selectedCustomer.value.getId() === response.offerStatusResponse.CustomerId) {
                selectedCustomer.value.setOverallUpdateCounter(response.offerStatusResponse.OverallCustomerUpdateCounter);
                updateSelectedCustomer(selectedCustomer.value);
            }
            actionTextToUse = config.getTranslationText('647').toLowerCase();
            break;
        case config.OPERATION.SaveCustomerNote.Code:
            if (selectedCustomer.value !== undefined && selectedCustomer.value !== null && selectedCustomer.value !== '' && selectedCustomer.value.getId() === response.savedNote.CustomerId) {
                selectedCustomer.value.setOverallUpdateCounter(response.savedNote.OverallCustomerUpdateCounter);
                updateSelectedCustomer(selectedCustomer.value);
            }
            actionTextToUse = config.getTranslationText('647').toLowerCase();
            break;
        case config.OPERATION.SaveCustomerAction.Code:
            if (selectedCustomer.value !== undefined && selectedCustomer.value !== null && selectedCustomer.value !== '' && selectedCustomer.value.getId() === response.savedAction.CustomerId) {
                selectedCustomer.value.setOverallUpdateCounter(response.savedAction.OverallCustomerUpdateCounter);
                updateSelectedCustomer(selectedCustomer.value);
            }
            actionTextToUse = config.getTranslationText('647').toLowerCase();
            break;
        case config.OPERATION.SaveUserSettings.Code:
            actionTextToUse = config.getTranslationText('2800').toLowerCase();
            if (response.data.UserType !== null && response.data.UserType !== '') {
                globals.user.updateUser({
                    userType: response.data.UserType
                });
            }
            if (response.data.TrialDaysRemaining > -1) {
                globals.user.updateUser({
                    trialDaysRemaining: response.data.TrialDaysRemaining
                });
            }
            if (Array.isArray(response.data.Integrations)) {
                // get the current integrations
                var currentIntegrations = globals.user.getIntegrations(),
                    integrationIndex;
                // loop through response integrations
                response.data.Integrations.forEach(function (integrationFromResponse) {
                    if (integrationFromResponse.Operation === config.OPERATION_TYPES.UPDATE) {
                        // Add or update
                        integrationIndex = currentIntegrations.findIndex(function (currentInt) {
                            return currentInt.Type = integrationFromResponse.IntegrationDetails.Type;
                        });

                        if (integrationIndex > -1) {
                            // Update
                            currentIntegrations[integrationIndex] = integrationFromResponse.IntegrationDetails;
                            // TODOTC: Add Intercom event here
                            if (integrationFromResponse.IntegrationDetails.Type === config.INTEGRATION_TYPES.NTEA) {
                                dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.UPDATED_NTEA_INTEGRATION));
                            }
                        } else {
                            // Add
                            currentIntegrations.push(integrationFromResponse.IntegrationDetails);
                            // TODOTC: Add Intercom event here
                            if (integrationFromResponse.IntegrationDetails.Type === config.INTEGRATION_TYPES.NTEA) {
                                dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.ADDED_NTEA_INTEGRATION));
                            }
                        }
                    }
                    if (integrationFromResponse.Operation === config.OPERATION_TYPES.DELETE) {
                        // Remove
                        integrationIndex = currentIntegrations.findIndex(function (currentInt) {
                            return currentInt.Type = integrationFromResponse.IntegrationDetails.Type;
                        });
                        if (integrationIndex > -1) {
                            currentIntegrations.splice(integrationIndex, 1);
                            // TODOTC: Add Intercom event here
                            if (integrationFromResponse.IntegrationDetails.Type === config.INTEGRATION_TYPES.NTEA) {
                                dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.REMOVED_NTEA_INTEGRATION));
                            }
                        }
                    }
                });

                globals.user.updateUser({
                    integrations: currentIntegrations
                });
            }
            if (response.data.BillingDetails) {
                globals.user.updateUser({
                    billingDetails: response.data.BillingDetails
                });
            }
            break;
        case config.OPERATION.ChangePassword.Code:
            //show the successfully saved toast
            actionTextToUse = config.getTranslationText('2801').toLowerCase();
            break;
        case config.OPERATION.DeleteCustomAccessory.Code:
        case config.OPERATION.DeleteCustomer.Code:
        case config.OPERATION.DeleteCustomerNote.Code:
        case config.OPERATION.DeleteCustomerAction.Code:
        case config.OPERATION.DeleteOffer.Code:
            actionTextToUse = config.getTranslationText('648').toLowerCase();
            break;
    }

    if (syncListeners.length > 0) {
        syncListeners.forEach(function (listener) {
            try {
                if (listener.syncOpSuccessResponseHandler !== undefined) {
                    listener.syncOpSuccessResponseHandler(op, response, idPriorToSync);
                }
            } catch (listenerCallbackEx) {
                TScMessenger.writeDebugMessage('OfferManager.js, syncOpSuccessResponseHandler, error notifying sync success listners');
            }
        });
    }
    if (showTaostrAsWarning === true) {
        syncSavedCopyToastHandlerCallback(toastText);
    } else {
        if(getPreventToastFromBeingDisplayed() === false) {
            syncSuccessToastHandlerCallback(actionTextToUse, itemDescriptionText);
        } else {
            setPreventToastFromBeingDisplayed(false);
        }
    }
    if (shellOpenBuyNowDialogCallback) {
        shellOpenBuyNowDialogCallback();
    }
}

dataManager.registerSyncOpFailureResponseHandler(syncOpFailureResponseHandler);

function syncOpFailureResponseHandler(op, itemDescriptionText, data) {

    if (data) {
        switch (op) {
            case config.OPERATION.SaveOffer.Code:
                if (data.Result.ReturnCode === -3) {
                    configurationActionResponseHandlerCallback(config.ACTION_TYPE.SAVE_OFFER, config.ACTION_ID.SAVE_WILL_OVERWRITE, function () {
                        data.syncItem.Details.ForceSave = true;
                        dataManager.doSync([data.syncItem]);
                    }, data);
                } else if (data.Result.ReturnCode === -4) {
                    configurationActionResponseHandlerCallback(config.ACTION_TYPE.SAVE_OFFER, config.ACTION_ID.SHARE_ACCESS_PERMISSION_VIEW_ONLY, undefined, data);
                }
                break;
            case config.OPERATION.SaveCustomer.Code:
            case config.OPERATION.UpdateOfferStatus.Code:
            case config.OPERATION.SaveCustomerNote.Code:
            case config.OPERATION.SaveCustomerAction.Code:
            case config.OPERATION.SaveUserSettings.Code:
            case config.OPERATION.ChangePassword.Code:
            case config.OPERATION.DeleteCustomer.Code:
            case config.OPERATION.DeleteCustomerNote.Code:
            case config.OPERATION.DeleteCustomerAction.Code:
            case config.OPERATION.DeleteOffer.Code:
                //do  nothing, not supported yet
                break;
            case config.OPERATION.DeleteCustomAccessory.Code:
                if (data.ReturnCode === -3) {
                    configurationActionResponseHandlerCallback(config.ACTION_TYPE.CUSTOM_DELETE, config.ACTION_ID.ALREADY_DELETED, undefined, data);
                } else if (data.ReturnCode === -2) {
                    configurationActionResponseHandlerCallback(config.ACTION_TYPE.CUSTOM_DELETE, config.ACTION_ID.ACCESS_LEVEL_CHANGED, undefined, data);
                }
                break;
            case config.OPERATION.SaveCustomAccessory.Code:
                dataManager.log("syncOpFailureResponseHandler, operation=SaveCustomAccessory, item description=" + itemDescriptionText + ", ReturnCode=" + data.Result.ReturnCode + ", Message=" + data.Result.Message, null, 'offerManager', config.LOG_MESSAGE_TYPE.ERROR);
                if (data.Result.ReturnCode === -3) {
                    configurationActionResponseHandlerCallback(config.ACTION_TYPE.CUSTOM_SAVE, config.ACTION_ID.ACCESS_LEVEL_CHANGED, undefined, data);
                } else if (data.Result.ReturnCode === -4) {
                    configurationActionResponseHandlerCallback(config.ACTION_TYPE.CUSTOM_SAVE, config.ACTION_ID.SAVE_WILL_OVERWRITE, function () {
                        data.syncItem.Details.ForceSave = true;
                        dataManager.doSync([data.syncItem]);
                    }, data);
                } else if (data.Result.ReturnCode === -5) {
                    configurationActionResponseHandlerCallback(config.ACTION_TYPE.CUSTOM_SAVE, config.ACTION_ID.NAME_ALREADY_EXISTS_TEAM, undefined, data);
                } else if (data.Result.ReturnCode === -6) {
                    configurationActionResponseHandlerCallback(config.ACTION_TYPE.CUSTOM_SAVE, config.ACTION_ID.NAME_ALREADY_EXISTS_PERSONAL, undefined, data);
                }
                break;
        }
    } else {

        var actionTextToUse = '';
        switch (op) {
            case config.OPERATION.SaveOffer.Code:
            case config.OPERATION.SaveCustomAccessory.Code:
            case config.OPERATION.SaveCustomer.Code:
            case config.OPERATION.UpdateOfferStatus.Code:
            case config.OPERATION.SaveCustomerNote.Code:
            case config.OPERATION.SaveCustomerAction.Code:
            case config.OPERATION.SaveUserSettings.Code:
            case config.OPERATION.ChangePassword.Code:
                actionTextToUse = config.getTranslationText('647').toLowerCase();
                break;
            case config.OPERATION.DeleteCustomAccessory.Code:
            case config.OPERATION.DeleteCustomer.Code:
            case config.OPERATION.DeleteCustomerNote.Code:
            case config.OPERATION.DeleteCustomerAction.Code:
            case config.OPERATION.DeleteOffer.Code:
                actionTextToUse = config.getTranslationText('648');
                break;
        }
        syncErrorToastHandlerCallback(actionTextToUse, itemDescriptionText);

        if (syncListeners.length > 0) {
            syncListeners.forEach(function (listener) {
                try {
                    if (listener.synOpFailureResponseHandler !== undefined) {
                        listener.synOpFailureResponseHandler(op, data);
                    }
                } catch (listenerCallbackEx) {
                    TScMessenger.writeDebugMessage('OfferManager.js, syncOpFailureResponseHandler, error notifying sync failure listners');
                }
            });
        }
    }
}

function handleSaveCustomAccessoryResponse(op, response, idPriorToSync) {
    var objectToUpdate = null;
    var newId = response.savedAccessory.AccessoryId;
    var newUpdateCounter = response.savedAccessory.UpdateCounter;
    var newSource = config.VEHICLE_SOURCE_TYPES.CUSTOM;


    if (getActiveOffer() !== null && getActiveOffer() !== undefined && getActiveOffer() !== '') {
        switch (response.savedAccessory.AccessoryType) {
            case config.ACCESSORY_TYPES.BODY:
                // update the crane object here
                //var tempBody = getActiveOffer().getConfiguration().getAccessoryHolder().getBody();
                var tempBody = rigOps.getAccessoryHolderFromPath(response.savedAccessory.ParentType).getBody();
                if (tempBody !== null && tempBody.getSourceDatabaseId() === idPriorToSync) {
                    //updateBodyMenuCallback(response.savedAccessory.AccessoryDescription, tempBody.getPath());
                    objectToUpdate = tempBody;
                }
                break;
            case config.ACCESSORY_TYPES.CRANE:
                // update the crane object here
                //var tempCrane = getActiveOffer().getConfiguration().getAccessoryHolder().getCrane();
                var tempCrane = rigOps.getAccessoryHolderFromPath(response.savedAccessory.ParentType).getCrane();
                if (tempCrane !== null && tempCrane.getSourceDatabaseId() === idPriorToSync) {
                    objectToUpdate = tempCrane;
                }
                break;
            case config.ACCESSORY_TYPES.OTHER:
                //var temp = saveAccessoryModal.menuPath.split('.');
                //var otherNumber = parseInt(temp[temp.length - 1]);
                //var tempOther = getActiveOffer().getConfiguration().getAccessoryHolder().getAccessoryBySourceDatabaseId(idPriorToSync);
                var tempOther = rigOps.getAccessoryHolderFromPath(response.savedAccessory.ParentType).getAccessoryBySourceDatabaseId(response.savedAccessory.AccessoryType, idPriorToSync);
                if (tempOther !== null) {
                    objectToUpdate = tempOther;
                }
                break;
            case config.ACCESSORY_TYPES.FRIDGE:
                //var tempFridge = getActiveOffer().getConfiguration().getAccessoryHolder().getFridge();
                var tempFridge = rigOps.getAccessoryHolderFromPath(response.savedAccessory.ParentType).getFridge();
                if (tempFridge !== null && tempFridge.getSourceDatabaseId() === idPriorToSync) {
                    objectToUpdate = tempFridge;
                }
                break;
            case config.ACCESSORY_TYPES.TAILLIFT:
                //var tempTaillift = offerManager.getActiveOffer().getConfiguration().getAccessoryHolder().getTaillift();
                var tempTaillift = rigOps.getAccessoryHolderFromPath(response.savedAccessory.ParentType).getTaillift();
                if (tempTaillift !== null && tempTaillift.getSourceDatabaseId() === idPriorToSync) {
                    objectToUpdate = tempTaillift;
                }
                break;
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                //var tempFifthWheel = offerManager.getActiveOffer().getConfiguration().getAccessoryHolder().getFifthWheel();
                var tempFifthWheel = rigOps.getAccessoryHolderFromPath(response.savedAccessory.ParentType).getFifthWheel();
                if (tempFifthWheel !== null && tempFifthWheel.getSourceDatabaseId() === idPriorToSync) {
                    objectToUpdate = tempFifthWheel;
                }
                break;
            case config.ACCESSORY_TYPES.HITCH:
                //var tempHitch = offerManager.getActiveOffer().getConfiguration().getAccessoryHolder().getHitch();
                var tempHitch = rigOps.getAccessoryHolderFromPath(response.savedAccessory.ParentType).getHitch();
                if (tempHitch !== null && tempHitch.getSourceDatabaseId() === idPriorToSync) {
                    objectToUpdate = tempHitch;
                }
                break;
            case config.ACCESSORY_TYPES.HOOKLIFT:
                var tempHooklift = rigOps.getAccessoryHolderFromPath(response.savedAccessory.ParentType).getHooklift();
                if (tempHooklift !== null && tempHooklift.getSourceDatabaseId() === idPriorToSync) {
                    objectToUpdate = tempHooklift;
                }
                break;
            case config.ACCESSORY_TYPES.TRAILER:
                var tempTrailer = offerManager.getActiveOffer().getRig().getTrailerBySourceDatabaseId(idPriorToSync);
                if (tempTrailer !== null && tempTrailer.getSourceDatabaseId() === idPriorToSync) {
                    objectToUpdate = tempTrailer;
                }
                break;
            case config.ACCESSORY_TYPES.PAYLOAD:
                //var tempPayload = getActiveOffer().getConfiguration().getPayloadHolder().getPayloadBySourceDatabaseId(idPriorToSync);
                var tempPayload = rigOps.getPayloadHolderFromPath(response.savedAccessory.ParentType).getPayloadBySourceDatabaseId(idPriorToSync);
                if (tempPayload !== null) {
                    objectToUpdate = tempPayload;
                }
                break;
        }

        if (objectToUpdate !== null) {
            objectToUpdate.setSourceDatabaseId(newId);
            objectToUpdate.setSource(newSource);
            objectToUpdate.setAccessLevel(response.savedAccessory.AccessLevel);
            objectToUpdate.setDescription(response.savedAccessory.AccessoryDescription);
            refreshData.value = new Date().getTime();
            if (newId !== idPriorToSync) {
                undoHandler.updateOpDefunctObjectInternalId(idPriorToSync, newId, objectToUpdate.getPath());
            }
        }
        // update the list of accessories
        if (newId !== idPriorToSync) {
            response.savedAccessory.Description = response.savedAccessory.AccessoryDescription
            if (response.savedAccessory.AccessoryType === config.ACCESSORY_TYPES.BODY) {
                updateBodyOnAvailableBodiesArray(response.savedAccessory, idPriorToSync);
            } else if (response.savedAccessory.AccessoryType === config.ACCESSORY_TYPES.TRAILER) {
                updateTrailerOnAvailableTrailersArray(response.savedAccessory, idPriorToSync);
            } else if (response.savedAccessory.AccessoryType === config.ACCESSORY_TYPES.PAYLOAD) {
                updatePayloadOnAvailablePayloadsArray(response.savedAccessory, idPriorToSync);
            } else {
                updateAccessoryOnAvailableAccessoriesArray(response.savedAccessory, idPriorToSync);
            }

        } else {
            var itemDetails = objectToUpdate !== null ? objectToUpdate : undefined;
            if (response.savedAccessory.AccessoryType === config.ACCESSORY_TYPES.BODY) {
                if (response.savedAccessory.Description !== response.savedAccessory.AccessoryDescription) {
                    response.savedAccessory.Description = response.savedAccessory.AccessoryDescription;
                    addBodyToAvailableBodiesArray(response.savedAccessory, itemDetails);
                } else {
                    updateBodyOnAvailableBodiesArray(response.savedAccessory);
                }

            } else if (response.savedAccessory.AccessoryType === config.ACCESSORY_TYPES.TRAILER) {
                if (response.savedAccessory.Description !== response.savedAccessory.AccessoryDescription) {
                    response.savedAccessory.Description = response.savedAccessory.AccessoryDescription;
                    addTrailerToAvailableTrailersArray(response.savedAccessory, itemDetails);
                } else {
                    updateTrailerOnAvailableTrailersArray(response.savedAccessory);
                }

            } else if (response.savedAccessory.AccessoryType === config.ACCESSORY_TYPES.PAYLOAD) {
                if (response.savedAccessory.Description !== response.savedAccessory.AccessoryDescription) {
                    response.savedAccessory.Description = response.savedAccessory.AccessoryDescription;
                    addPayloadToAvailablePayloadsArray(response.savedAccessory, itemDetails);
                } else {
                    updatePayloadOnAvailablePayloadsArray(response.savedAccessory);
                }

            } else {
                if (response.savedAccessory.Description !== response.savedAccessory.AccessoryDescription) {
                    response.savedAccessory.Description = response.savedAccessory.AccessoryDescription;
                    addAccessoryToAvailableAccessoriesArray(response.savedAccessory, itemDetails);
                } else {
                    updateAccessoryOnAvailableAccessoriesArray(response.savedAccessory);
                }

            }

        }
    }

}

function handleSaveCustomerResponse(op, response, idPriorToSync) {
    selectedCustomer.value.setId(response.savedCustomer.Customer.Id);
    selectedCustomer.value.setUpdateCounter(response.savedCustomer.Customer.UpdateCounter);
    selectedCustomer.value.setOverallUpdateCounter(response.savedCustomer.Customer.OverallUpdateCounter);
    updateSelectedCustomer(selectedCustomer.value);
}

function setupCostingObservables() {

    var path = config.OBJECT_TYPES.COSTING;

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGUTILISATION, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.MONTHLYDISTANCE)
        .setter(getActiveOffer().getCosting().setMonthlyDistance)
        .getter(getActiveOffer().getCosting().getMonthlyDistance)
        .addSubscription(function (val) {
            if (getActiveOffer().getCosting().getFuelAndAdblueCostPerMonthOverride() === false) {
                updateCostingFuelAndAdblueValues();
                var newFuelAndAdblueCostPerMonth = getActiveOffer().getCosting().getFuelAndAdblueCostPerMonth();
                offerManager.getAttributeGroup(path + '.' + config.VALUE_TYPE.COSTINGFUELANDADBLUECOSTPERMONTH).formatted.value = newFuelAndAdblueCostPerMonth;
                getActiveOffer().getCosting().setFuelAndAdblueCostPerMonth(newFuelAndAdblueCostPerMonth);
            }
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGUTILISATION)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGTRIPS, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.VALUEPERMONTH)
        .setter(getActiveOffer().getCosting().setMonthlyTrips)
        .getter(getActiveOffer().getCosting().getMonthlyTrips)
        .addSubscription(function (val) {
            if (getActiveOffer().getCosting().getTollFeesOverride() === false) {
                var newTollFees = (getActiveOffer().getCosting().getTollFeesPerTrip() + getActiveOffer().getCosting().getPermitCostPerTrip()) * getActiveOffer().getCosting().getMonthlyTrips();
                offerManager.getAttributeGroup(path + '.' + config.VALUE_TYPE.COSTINGTOLLFEES).formatted.value = newTollFees;
                getActiveOffer().getCosting().setTollFees(newTollFees);
            }
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGTRIPS)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGFINANCEPERIOD, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.MONTHS)
        .setter(getActiveOffer().getCosting().setRepaymentTerm)
        .getter(getActiveOffer().getCosting().getRepaymentTerm)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGFINANCEPERIOD)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGINTERESTRATE, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.PERCENTAGEDOUBLEPLACE)
        .setter(getActiveOffer().getCosting().setInterestRate)
        .getter(getActiveOffer().getCosting().getInterestRate)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGINTERESTRATE)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGRESIDUALRATE, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.PERCENTAGESINGLEPLACE)
        .setter(getActiveOffer().getCosting().setResidualRate)
        .getter(getActiveOffer().getCosting().getResidualRate)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGRESIDUALRATE)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGFUELPRICE, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.CURRENCYPERVOLUME)
        .setter(getActiveOffer().getCosting().setFuelCost)
        .getter(getActiveOffer().getCosting().getFuelCost)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGFUELPRICE)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGFINANCETOTALPRICE, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.CURRENCYTOTALPRICE)
        //.setter(getActiveOffer().getCosting().setVehiclePrice)
        //.getter(getActiveOffer().getCosting().getVehiclePrice)
        .setter(getActiveOffer().getCosting().setFinanceTotalPrice)
        .getter(getActiveOffer().getCosting().getFinanceTotalPrice)
        .addOverrideMechanism(getActiveOffer().getCosting,
            function (val) {
                offerManager.getAttributeGroup(path + '.' + config.VALUE_TYPE.COSTINGFINANCETOTALPRICE).override.value = val;
                getActiveOffer().getCosting().setFinanceTotalPriceOverride(val);
                if (val === true) {
                    dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.OVERWROTE_FINANCE_TOTAL_PRICE));
                }
                refreshUILiveLabels.value = new Date().getTime();
            },
            function () {
                //Change to calculate from finance detail
                function calculateDiscountedPrice(price, discount) {
                    var discountValue = 0;
                    if (discount > 0) {
                        discountValue = (price / 100) * discount;
                    }
                    return price - discountValue;
                }

                var netPrice = 0;
                netPrice += calculateDiscountedPrice(getActiveOffer().getCosting().getVehiclePrice(), getActiveOffer().getCosting().getVehicleDiscount());
                getActiveOffer().getConfiguration().getAccessoryHolder().getAccessories().forEach(function (accessory) {
                    netPrice += calculateDiscountedPrice(accessory.getCostingListPrice(), accessory.getCostingDiscount());
                });

                if (getActiveOffer().getTrailer1() !== null && getActiveOffer().getTrailer1() !== undefined) {
                    netPrice += calculateDiscountedPrice(getActiveOffer().getTrailer1().getCostingListPrice(), getActiveOffer().getTrailer1().getCostingDiscount());
                    getActiveOffer().getTrailer1().getAccessoryHolder().getAccessories().forEach(function (accessory) {
                        netPrice += calculateDiscountedPrice(accessory.getCostingListPrice(), accessory.getCostingDiscount());
                    });
                }

                if (getActiveOffer().getTrailer2() !== null && getActiveOffer().getTrailer2() !== undefined) {
                    netPrice += calculateDiscountedPrice(getActiveOffer().getTrailer2().getCostingListPrice(), getActiveOffer().getTrailer2().getCostingDiscount());
                    getActiveOffer().getTrailer2().getAccessoryHolder().getAccessories().forEach(function (accessory) {
                        netPrice += calculateDiscountedPrice(accessory.getCostingListPrice(), accessory.getCostingDiscount());
                    });
                }

                return netPrice;
                //return getOriginalOffer().getCosting().getFinanceTotalPrice();
            },
            function () {
                return getActiveOffer().getCosting().getFinanceTotalPriceOverride();
            })
        .overridePermission(function () {
            return true;
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGFINANCETOTALPRICE)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGCREWCOST, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.CURRENCYPERMONTH)
        .setter(getActiveOffer().getCosting().setCrewCostPerMonth)
        .getter(getActiveOffer().getCosting().getCrewCostPerMonth)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGCREWCOST)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGINSURANCERATE, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.PERCENTAGEOFTOTALPRICE)
        .setter(getActiveOffer().getCosting().setInsuranceRate)
        .getter(getActiveOffer().getCosting().getInsuranceRate)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGINSURANCERATE)), path);

    if (displayLicenceDetailButtonOnCosting()) {
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGLICENCEFEES, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.CURRENCYPERYEAR)
            .setter(getActiveOffer().getCosting().setLicenceFees)
            .getter(getActiveOffer().getCosting().getLicenceFees)
            .addOverrideMechanism(getActiveOffer().getCosting,
                function (val) {
                    offerManager.getAttributeGroup(path + '.' + config.VALUE_TYPE.COSTINGLICENCEFEES).override.value = val;
                    getActiveOffer().getCosting().setLicenceFeesOverride(val);
                    if (val === true) {
                        dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.OVERWROTE_LICENCE_FEES));
                    }
                    refreshUILiveLabels.value = new Date().getTime();
                },
                function () {
                    if (displayLicenceDetailButtonOnCosting()) {
                        updateCostingLicenceFees();

                        var valueToUse = 0;
                        valueToUse += getActiveOffer().getCosting().getVehicleLicenceFees();
                        if (getActiveOffer().getTrailer1() !== null) {
                            valueToUse += getActiveOffer().getRig().getTrailer1().getLicenceFees();
                        }
                        if (getActiveOffer().getTrailer2() !== null) {
                            valueToUse += getActiveOffer().getRig().getTrailer2().getLicenceFees();
                        }

                        return valueToUse;
                    } else {
                        return getOriginalOffer().getCosting().getLicenceFees();
                    }
                },
                function () {
                    return getActiveOffer().getCosting().getLicenceFeesOverride();
                })
            .overridePermission(function () {
                return true;
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGLICENCEFEES)), path);
    } else {
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGLICENCEFEES, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.CURRENCYPERYEAR)
            .setter(getActiveOffer().getCosting().setLicenceFees)
            .getter(getActiveOffer().getCosting().getLicenceFees)
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGLICENCEFEES)), path);
    }

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGOVERHEADSRATE, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.PERCENTAGEOFFIXEDCOST)
        .setter(getActiveOffer().getCosting().setOverheadRate)
        .getter(getActiveOffer().getCosting().getOverheadRate)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGOVERHEADSRATE)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGFUELCONSUMPTION, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.VOLUMEPER100KMS)
        //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGFUELCONSUMPTION, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.FUELCONSUMPTION)
        //.setter(getActiveOffer().getCosting().setFuelConsumption)
        //.getter(getActiveOffer().getCosting().getFuelConsumption)
        .setter(setFuelConsumptionForMetricMeasurementSystem)
        .getter(getFuelConsumptionForMetricMeasurementSystem)
        .overridePermission(function () {
            return true;
        })
        .addOverrideMechanism(getActiveOffer().getCosting,
            function (val) {
                offerManager.getAttributeGroup(path + '.' + config.VALUE_TYPE.COSTINGFUELCONSUMPTIONIMPERIAL).override.value = val;
                getActiveOffer().getCosting().setFuelConsumptionOverride(val);
                if (val === true) {
                    dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.OVERWROTE_FUEL_CONSUMPTION));
                }
                refreshUILiveLabels.value = new Date().getTime();
            },
            function () {
                // Add check here
                // If performance is available then use this
                // Otherwise use value from database
                if (globals.isObjectValid(getActiveOffer().getPerformance()) && typeof getActiveOffer().getPerformance().getSimResAverageFuel() === 'number' && getActiveOffer().getPerformance().getSimResAverageFuel() > 0) {
                    return getAverageFuelValue(getActiveOffer().getPerformance().getSimResAverageFuel());
                } else {
                    return getAverageFuelValue(getActiveOffer().getCosting().getFuelConsumptionOnDatabase());
                }
                //return getOriginalOffer().getCosting().getFuelConsumption();
            },
            function () {
                return getActiveOffer().getCosting().getFuelConsumptionOverride();
            })
        .overridePermission(function () {
            return true;
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGFUELCONSUMPTION)), path);

    //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGFUELCONSUMPTION, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.VOLUMEPER100KMS)
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGFUELCONSUMPTIONIMPERIAL, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.FUELCONSUMPTION)
        // .setter(setFuelConsumptionForImperialMeasurementSystem)
        // .getter(getFuelConsumptionForImperialMeasurementSystem)
        .setter(getActiveOfferCosting().setFuelConsumption)
        .getter(getActiveOfferCosting().getFuelConsumption)
        .overridePermission(function () {
            return true;
        })
        .addOverrideMechanism(getActiveOffer().getCosting,
            function (val) {
                offerManager.getAttributeGroup(path + '.' + config.VALUE_TYPE.COSTINGFUELCONSUMPTION).override.value = val;
                getActiveOffer().getCosting().setFuelConsumptionOverride(val);
                if (val === true) {
                    dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.OVERWROTE_FUEL_CONSUMPTION));
                }
                refreshUILiveLabels.value = new Date().getTime();
            },
            function () {
                // Add check here
                // If performance is available then use this
                // Otherwise use value from database
                if (globals.isObjectValid(getActiveOffer().getPerformance()) && typeof getActiveOffer().getPerformance().getSimResAverageFuel() === 'number' && getActiveOffer().getPerformance().getSimResAverageFuel() > 0) {
                    return getActiveOffer().getPerformance().getSimResAverageFuel();
                } else {
                    return getActiveOffer().getCosting().getFuelConsumptionOnDatabase();
                }
                //return getOriginalOffer().getCosting().getFuelConsumption();
            },
            function () {
                return getActiveOffer().getCosting().getFuelConsumptionOverride();
            })
        .overridePermission(function () {
            return true;
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGFUELCONSUMPTIONIMPERIAL)), path);

    // Fuel and Adblue
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGFUELANDADBLUECOSTPERMONTH, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.CURRENCYPERMONTH)
        .setter(getActiveOffer().getCosting().setFuelAndAdblueCostPerMonth)
        .getter(getActiveOffer().getCosting().getFuelAndAdblueCostPerMonth)
        .overridePermission(function () {
            return true;
        })
        .addOverrideMechanism(getActiveOffer().getCosting,
            function (val) {
                getActiveOffer().getCosting().setFuelAndAdblueCostPerMonthOverride(val);
                refreshUILiveLabels.value = new Date().getTime();
            },
            function () {
                updateCostingFuelAndAdblueValues();
                return getActiveOffer().getCosting().getFuelAndAdblueCostPerMonth();
            },
            function () {
                return getActiveOffer().getCosting().getFuelAndAdblueCostPerMonthOverride();
            })
        .overridePermission(function () {
            return true;
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGFUELANDADBLUECOSTPERMONTH)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGTYRES, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.CURRENCYMINORPERDISTANCE)
        // .setter(getActiveOffer().getCosting().setTyreRate)
        .setter(setterForTyreRateOnAttributeObservableGroup)
        .getter(getActiveOffer().getCosting().getTyreRate)
        .overridePermission(function () {
            return true;
        })
        .addOverrideMechanism(getActiveOffer().getCosting,
            function (val) {
                offerManager.getAttributeGroup(path + '.' + config.VALUE_TYPE.COSTINGTYRES).override.value = val;
                getActiveOffer().getCosting().setTyreRateOverride(val);
                if (val === true) {
                    dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.OVERWROTE_TYRE_RATE));
                    getActiveOffer().getCosting().setTyreRateSetUsingMeasurementSystemId(getActiveOffer().getMeasurementSystem().id);
                } else {
                    getActiveOffer().getCosting().setTyreRateSetUsingMeasurementSystemId(null);
                }
                refreshUILiveLabels.value = new Date().getTime();
            },
            function () {
                //return getOriginalOffer().getCosting().getTyreRate();
                return getTyreRateValueFromTyreDetail();
            },
            function () {
                return getActiveOffer().getCosting().getTyreRateOverride();
            })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGTYRES)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGMAINTENANCE, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.CURRENCYMINORPERDISTANCE)
        .setter(getActiveOffer().getCosting().setMaintenanceRate)
        .getter(getActiveOffer().getCosting().getMaintenanceRate)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGMAINTENANCE)), path);

    if (displayTollFeesDetailOnCosting() === true) {
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGTOLLFEES, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.CURRENCYPERMONTH)
            .setter(getActiveOffer().getCosting().setTollFees)
            .getter(getActiveOffer().getCosting().getTollFees)
            .addOverrideMechanism(getActiveOffer().getCosting,
                function (val) {
                    offerManager.getAttributeGroup(path + '.' + config.VALUE_TYPE.COSTINGTOLLFEES).override.value = val;
                    getActiveOffer().getCosting().setTollFeesOverride(val);
                    if (val === true) {
                        dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.OVERWROTE_LICENCE_FEES));
                    }
                    refreshUILiveLabels.value = new Date().getTime();
                },
                function () {
                    if (displayTollFeesDetailOnCosting()) {
                        //getActiveOffer().getPerformance().getTolls().forEach(function(toll) {
                        //    valueToUse += toll.getActiveTollFee();
                        //});
                        return (getActiveOffer().getCosting().getTollFeesPerTrip() + getActiveOffer().getCosting().getPermitCostPerTrip()) * getActiveOffer().getCosting().getMonthlyTrips();
                    } else {
                        return getOriginalOffer().getCosting().getTollFees();
                    }
                },
                function () {
                    return getActiveOffer().getCosting().getTollFeesOverride();
                })
            .overridePermission(function () {
                return true;
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGTOLLFEES)), path);

        //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGTOLLFEESPERTRIP, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.CURRENCYPERYEAR)
        //    .setter(getActiveOffer().getCosting().setTollFeesPerTrip)
        //    .getter(getActiveOffer().getCosting().getTollFeesPerTrip)
        //    .addOverrideMechanism(getActiveOffer().getCosting,
        //        function (val) {
        //            offerManager.getAttributeGroup(path + '.' + config.VALUE_TYPE.COSTINGTOLLFEESPERTRIP).override(val);
        //            getActiveOffer().getCosting().setTollFeesPerTripOverride(val);
        //            //if (val === true) {
        //            //    dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.OVERWROTE_LICENCE_FEES));
        //            //}
        //            refreshUILiveLabels(new Date().getTime());
        //        },
        //        function () {
        //            if (displayLicenceDetailButtonOnCosting()) {
        //                var valueToUse = 0;
        //                //valueToUse += getActiveOffer().getCosting().getVehicleTollFees();
        //                //if (getActiveOffer().getTrailer1() !== null) {
        //                //    valueToUse += getActiveOffer().getRig().getTrailer1().getTollFees();
        //                //}
        //                //if (getActiveOffer().getTrailer2() !== null) {
        //                //    valueToUse += getActiveOffer().getRig().getTrailer1().getTollFees();
        //                //}

        //                return valueToUse;
        //            } else {
        //                return getOriginalOffer().getCosting().getTollFeesPerTrip();
        //            }
        //        },
        //        function () {
        //            return getActiveOffer().getCosting().getTollFeesPerTripOverride();
        //        })
        //    .overridePermission(function () {
        //        return true;
        //    })
        //    .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGTOLLFEESPERTRIP)), path);

        //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGPERMITCOSTPERTRIP, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.CURRENCYPERMONTH)
        //    .setter(getActiveOffer().getCosting().SetPermitCostPerTrip)
        //    .getter(getActiveOffer().getCosting().getPermitCostPerTrip)
        //    .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGPERMITCOSTPERTRIP)), path);
    } else {
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGTOLLFEES, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.CURRENCYPERMONTH)
            .setter(getActiveOffer().getCosting().setTollFees)
            .getter(getActiveOffer().getCosting().getTollFees)
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGTOLLFEES)), path);
    }

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGTOTALREVENUE, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.CURRENCYPERDISTANCE)
        // .setter(getActiveOffer().getCosting().setTotalRevenue)
        .setter(setterForTotalRevenueOnAttributeObservableGroup)
        .getter(getActiveOffer().getCosting().getTotalRevenue)
        // .setter(setTotalRevenueUsingMeasurementSystem)
        // .getter(getTotalRevenueUsingMeasurementSystem)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGTOTALREVENUE)), path);

}

dataManager.registerSharedOfferSyncCallback(sharedOfferSyncHandler);
dataManager.registerTeamSyncHandlerCallback(teamSyncHandler);

function sharedOfferSyncHandler(result) {
    if (result.NumSharedOffersNotSeen > 0 && globals.user.getFirstName() !== config.shareeTempName) {
        if (offerIsOpen.value === true && getActiveOffer().getIsSharedOffer() === true) {
            if (result.NumSharedOffersNotSeen > 1) {
                newSharedOfferToastHandlerCallback(result.NumSharedOffersNotSeen);
            }
        } else {
            newSharedOfferToastHandlerCallback(result.NumSharedOffersNotSeen);
        }

    }
}

function teamSyncHandler(result) {

    if (result.NewOrUpdatedTeamItems.Bodies.length > 0) {
        result.NewOrUpdatedTeamItems.Bodies.forEach(function (bodyAsJSON) {
            try {
                updateBodyOnAvailableBodiesArray(bodyAsJSON);
            } catch (bodyNotFoundEx) {
                addBodyToAvailableBodiesArray(bodyAsJSON, undefined);
            }
        });
        newOrUpdatedTeamItemsSyncToastHandlerCallback(config.getTranslationText('1323'));
    }
    if (result.NewOrUpdatedTeamItems.Cranes.length > 0) {
        result.NewOrUpdatedTeamItems.Cranes.forEach(function (craneAsJSON) {
            try {
                updateAccessoryOnAvailableAccessoriesArray(craneAsJSON);
            } catch (craneNotFoundEx) {
                addAccessoryToAvailableAccessoriesArray(craneAsJSON, undefined);
            }
        });
        newOrUpdatedTeamItemsSyncToastHandlerCallback(config.getTranslationText('1324'));
    }
    if (result.NewOrUpdatedTeamItems.Others.length > 0) {
        result.NewOrUpdatedTeamItems.Others.forEach(function (otherAsJSON) {
            try {
                updateAccessoryOnAvailableAccessoriesArray(otherAsJSON);
            } catch (otherNotFoundEx) {
                addAccessoryToAvailableAccessoriesArray(otherAsJSON, undefined)
            }
        });
        newOrUpdatedTeamItemsSyncToastHandlerCallback(config.getTranslationText('1325'));
    }
    if (result.NewOrUpdatedTeamItems.Fridges.length > 0) {
        result.NewOrUpdatedTeamItems.Fridges.forEach(function (fridgeAsJSON) {
            try {
                updateAccessoryOnAvailableAccessoriesArray(fridgeAsJSON);
            } catch (fridgeNotFoundEx) {
                addAccessoryToAvailableAccessoriesArray(fridgeAsJSON, undefined);
            }
        });
        newOrUpdatedTeamItemsSyncToastHandlerCallback(config.getTranslationText('1326'));
    }
    if (result.NewOrUpdatedTeamItems.Taillifts.length > 0) {
        result.NewOrUpdatedTeamItems.Taillifts.forEach(function (tailliftAsJSON) {
            try {
                updateAccessoryOnAvailableAccessoriesArray(tailliftAsJSON);
            } catch (tailliftNotFoundEx) {
                addAccessoryToAvailableAccessoriesArray(tailliftAsJSON, undefined);
            }
        });
        newOrUpdatedTeamItemsSyncToastHandlerCallback(config.getTranslationText('1327'));
    }
    if (result.NewOrUpdatedTeamItems.FifthWheels.length > 0) {
        result.NewOrUpdatedTeamItems.FifthWheels.forEach(function (fifthWheelAsJSON) {
            try {
                updateAccessoryOnAvailableAccessoriesArray(fifthWheelAsJSON);
            } catch (fifthWheelNotFoundEx) {
                addAccessoryToAvailableAccessoriesArray(fifthWheelAsJSON, undefined);
            }
        });
        newOrUpdatedTeamItemsSyncToastHandlerCallback(config.getTranslationText('1329'));
    }
    if (result.NewOrUpdatedTeamItems.Hitches.length > 0) {
        result.NewOrUpdatedTeamItems.Hitches.forEach(function (hitchAsJSON) {
            try {
                updateAccessoryOnAvailableAccessoriesArray(hitchAsJSON);
            } catch (hitchNotFoundEx) {
                addAccessoryToAvailableAccessoriesArray(hitchAsJSON, undefined);
            }
        });
        newOrUpdatedTeamItemsSyncToastHandlerCallback(config.getTranslationText('1328'));
    }
    if (result.NewOrUpdatedTeamItems.Hooklifts.length > 0) {
        result.NewOrUpdatedTeamItems.Hooklifts.forEach(function (hookliftAsJSON) {
            try {
                updateAccessoryOnAvailableAccessoriesArray(hookliftAsJSON);
            } catch (hitchNotFoundEx) {
                addAccessoryToAvailableAccessoriesArray(hookliftAsJSON, undefined);
            }
        });
        newOrUpdatedTeamItemsSyncToastHandlerCallback(config.getTranslationText('1328'));
    }
    if (result.NewOrUpdatedTeamItems.Trailers.length > 0) {
        result.NewOrUpdatedTeamItems.Trailers.forEach(function (trailerAsJSON) {
            try {
                updateTrailerOnAvailableTrailersArray(trailerAsJSON);
            } catch (trailerNotFoundEx) {
                addTrailerToAvailableTrailersArray(trailerAsJSON, undefined);
            }
        });
        newOrUpdatedTeamItemsSyncToastHandlerCallback(config.getTranslationText('1330'));
    }
    if (result.NewOrUpdatedTeamItems.Payloads.length > 0) {
        result.NewOrUpdatedTeamItems.Payloads.forEach(function (payloadAsJSON) {
            try {
                updatePayloadOnAvailablePayloadsArray(payloadAsJSON);
            } catch (payloadNotFoundEx) {
                addPayloadToAvailablePayloadsArray(payloadAsJSON, undefined);
            }
        });
        newOrUpdatedTeamItemsSyncToastHandlerCallback(config.getTranslationText('1331'));
    }

}

function setupCostingFormattedComputeds() {
    var costing = getActiveOffer().getCosting();

    financeCostPerMonthFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        var financeCostToUse = costing.getFinanceCostPerMonth();
        if (!getActiveOffer().getCosting().getFinanceTotalPriceOverride()) {
            if (typeof costing.getFinanceDetailCostPerMonth() === 'number') {
                financeCostToUse = costing.getFinanceDetailCostPerMonth();
            }
        } else {
            financeCostToUse = globals.calculateFinanceCostPerMonth(getActiveOffer().getCosting().getFinanceTotalPrice(), getActiveOffer().getCosting().getResidualRate(), getActiveOffer().getCosting().getInterestRate(), getActiveOffer().getCosting().getRepaymentTerm());
        }

        return getUIFormattedCurrencyPerMonthValueNoUnit(financeCostToUse);
        //return getUIFormattedCurrencyPerMonthValueNoUnit(costing.getFinanceCostPerMonth());
    });

    financeCostPerKMFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerDistanceValueNoUnit(costing.getFinanceCostPerKM(globals.user.getActiveMeasurementSystem().id), true);
    });

    crewCostPerMonthFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerMonthValueNoUnit(costing.getCrewCostPerMonth());
    });

    crewCostPerKMFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerDistanceValueNoUnit(costing.getCrewCostPerKM(globals.user.getActiveMeasurementSystem().id), true);
    });

    insuranceCostPerMonthFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerMonthValueNoUnit(costing.getInsuranceCostPerMonth());

    });

    insuranceCostPerKMFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerDistanceValueNoUnit(costing.getInsuranceCostPerKM(globals.user.getActiveMeasurementSystem().id), true);
    });

    licenceFeesCostPerMonthFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerMonthValueNoUnit(costing.getLicenceFeesCostPerMonth());
    });

    licenceFeesCostPerKMFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerDistanceValueNoUnit(costing.getLicenceFeesCostPerKM(globals.user.getActiveMeasurementSystem().id), true);
    });

    overheadsCostPerMonthFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerMonthValueNoUnit(costing.getOverheadsCostPerMonth());
    });

    overheadsCostPerKMFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerDistanceValueNoUnit(costing.getOverheadsCostPerKM(globals.user.getActiveMeasurementSystem().id), true);
    });

    fixedCostsTotalCostsPerMonthFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerMonthValueNoUnit(costing.getFixedCostsTotalCostPerMonth());
    });

    fixedCostsTotalCostsPerKMFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerDistanceValueNoUnit(costing.getFixedCostsTotalCostPerKM(globals.user.getActiveMeasurementSystem().id), true);
    });

    fuelCostPerMonthFomattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        // return getUIFormattedCurrencyPerMonthValueNoUnit(costing.getFuelCostPerMonth(globals.user.getActiveMeasurementSystem().id), true);
        return getUIFormattedCurrencyPerMonthValueNoUnit(costing.getFuelAndAdblueCostPerMonth(globals.user.getActiveMeasurementSystem().id), true);
    });

    fuelCostPerKMFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        // return getUIFormattedCurrencyPerDistanceValueNoUnit(costing.getFuelCostPerKM(globals.user.getActiveMeasurementSystem().id), true);
        return getUIFormattedCurrencyPerDistanceValueNoUnit(costing.getFuelAndAdblueCostPerKM(globals.user.getActiveMeasurementSystem().id), true);
    });

    fuelAndAdblueCostPerMonthFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerMonthValueNoUnit(costing.getFuelAndAdblueCostPerMonth());
    });

    fuelAndAdblueCostPerKMFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerDistanceValueNoUnit(costing.getFuelAndAdblueCostPerKM(globals.user.getActiveMeasurementSystem().id), true);
    });

    tyreCostPerMonthFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerMonthValueNoUnit(costing.getTyreCostPerMonth(globals.user.getActiveMeasurementSystem().id));
    });

    tyreCostPerKMFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        updateCostingTyreRate();
        return getUIFormattedCurrencyPerDistanceValueNoUnitDecimals(costing.getTyreCostPerKM(), true);
    });

    maintenanceCostPerMonthFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerMonthValueNoUnit(costing.getMaintenanceCostPerMonth(globals.user.getActiveMeasurementSystem().id));
    });

    maintenanceCostPerKMFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerDistanceValueNoUnitDecimals(costing.getMaintenanceCostPerKM(), true);
    });

    tollFeesCostPerMonthFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerMonthValueNoUnit(costing.getTollFeesCostPerMonth());
    });

    tollFeesCostPerKMFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerDistanceValueNoUnit(costing.getTollFeesCostPerKM(globals.user.getActiveMeasurementSystem().id), true);
    });

    variableCostsTotalCostPerMonthFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerMonthValueNoUnit(costing.getVariableCostsTotalCostPerMonth(globals.user.getActiveMeasurementSystem().id));
    });

    variableCostsTotalCostPerKMFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerDistanceValueNoUnit(costing.getVariableCostsTotalCostPerKM(globals.user.getActiveMeasurementSystem().id), true);
    });

    totalCostsPerMonthFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerMonthValueNoUnit(costing.getTotalCostsPerMonth(globals.user.getActiveMeasurementSystem().id));
    });

    totalCostsPerKMFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerDistanceValueNoUnit(costing.getTotalCostsPerKM(globals.user.getActiveMeasurementSystem().id), true);
    });

    totalRevenuePerMonthFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerMonthValueNoUnit(costing.getTotalRevenuePerMonth(globals.user.getActiveMeasurementSystem().id));
    });

    totalRevenuePerKMFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        updateCostingTotalRevenue();
        return getUIFormattedCurrencyPerDistanceValueNoUnit(costing.getTotalRevenuePerKM(), true);
    });

    totalProfitPerMonthFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerMonthValueNoUnit(costing.getTotalProfitPerMonth(globals.user.getActiveMeasurementSystem().id));
    });

    totalProfitPerKMFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedCurrencyPerDistanceValueNoUnit(costing.getTotalProfitPerKM(globals.user.getActiveMeasurementSystem().id), true);
    });

    totalFinanceCostFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return getUIFormattedHighCurrencyValue(costing.getTotalFinanceCost());
    });

    financeCostPercentageOfTotalFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return config.getTranslationText('761') + ' ' + getUIFormattedPercentageSinglePlaceValue(costing.getFinanceCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id)); // User Story 17565
    });

    crewCostPercentageOfTotalFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return config.getTranslationText('762') + ' ' + getUIFormattedPercentageSinglePlaceValue(costing.getCrewCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id)); // User Story 17565
    });

    insuranceCostPercentageOfTotalFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return config.getTranslationText('763') + ' ' + getUIFormattedPercentageSinglePlaceValue(costing.getInsuranceCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id)); // User Story 17565
    });

    licenceFeesCostPercentageOfTotalFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return config.getTranslationText('764') + ' ' + getUIFormattedPercentageSinglePlaceValue(costing.getLicenceFeesCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id)); // User Story 17565
    });

    overheadsCostPercentageOfTotalFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return config.getTranslationText('765') + ' ' + getUIFormattedPercentageSinglePlaceValue(costing.getOverheadsCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id)); // User Story 17565
    });

    fuelCostPercentageOfTotalFomattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return config.getTranslationText('766') + ' ' + getUIFormattedPercentageSinglePlaceValue(costing.getFuelCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id)); // User Story 17565
    });

    tyreCostPercentageOfTotalFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return config.getTranslationText('767') + ' ' + getUIFormattedPercentageSinglePlaceValue(costing.getTyreCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id)); // User Story 17565
    });

    maintenanceCostPercentageOfTotalFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return config.getTranslationText('768') + ' ' + getUIFormattedPercentageSinglePlaceValue(costing.getMaintenanceCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id)); // User Story 17565
    });

    tollFeesCostPercentageOfTotalFormattedComp = computed(function () {
        refreshData.value;
        refreshCostingData.value;
        return config.getTranslationText('769') + ' ' + getUIFormattedPercentageSinglePlaceValue(costing.getTollFeesCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id)); // User Story 17565
    });
}

function getFinanceCostPerMonthFormattedComp() {
    return financeCostPerMonthFormattedComp;
}

function getFinanceCostPerKMFormattedComp() {
    return financeCostPerKMFormattedComp;
}

function getDisplayCostingTab() {
    return displayCostingTab;
}

function setDisplayCostingTab(newValue) {
    displayCostingTab = newValue;
}

function getDisplaySpecificationTab() {
    return displaySpecificationTab;
}

function setDisplaySpecificationTab(newValue) {
    displaySpecificationTab = newValue;
}

function cleanUpCostingComputeds() {
    if (financeCostPerMonthFormattedComp !== null) {
        // financeCostPerMonthFormattedComp();
        financeCostPerMonthFormattedComp = null;
    }

    if (financeCostPerKMFormattedComp !== null) {
        // financeCostPerKMFormattedComp();
        financeCostPerKMFormattedComp = null;
    }

    if (crewCostPerMonthFormattedComp !== null) {
        // crewCostPerMonthFormattedComp();
        crewCostPerMonthFormattedComp = null;
    }

    if (crewCostPerKMFormattedComp !== null) {
        // crewCostPerKMFormattedComp();
        crewCostPerKMFormattedComp = null;
    }

    if (insuranceCostPerMonthFormattedComp !== null) {
        // insuranceCostPerMonthFormattedComp();
        insuranceCostPerMonthFormattedComp = null;
    }

    if (insuranceCostPerKMFormattedComp !== null) {
        // insuranceCostPerKMFormattedComp();
        insuranceCostPerKMFormattedComp = null;
    }

    if (licenceFeesCostPerMonthFormattedComp !== null) {
        // licenceFeesCostPerMonthFormattedComp();
        licenceFeesCostPerMonthFormattedComp = null;
    }

    if (licenceFeesCostPerKMFormattedComp !== null) {
        // licenceFeesCostPerKMFormattedComp();
        licenceFeesCostPerKMFormattedComp = null;
    }

    if(fuelAndAdblueCostPerMonthFormattedComp !== null) {
        fuelAndAdblueCostPerMonthFormattedComp = null;
    }

    if(fuelAndAdblueCostPerKMFormattedComp !== null) {
        fuelAndAdblueCostPerKMFormattedComp = null;
    }

    if (overheadsCostPerMonthFormattedComp !== null) {
        // overheadsCostPerMonthFormattedComp();
        overheadsCostPerMonthFormattedComp = null;
    }

    if (overheadsCostPerKMFormattedComp !== null) {
        // overheadsCostPerKMFormattedComp();
        overheadsCostPerKMFormattedComp = null;
    }

    if (fixedCostsTotalCostsPerMonthFormattedComp !== null) {
        // fixedCostsTotalCostsPerMonthFormattedComp();
        fixedCostsTotalCostsPerMonthFormattedComp = null;
    }

    if (fixedCostsTotalCostsPerKMFormattedComp !== null) {
        // fixedCostsTotalCostsPerKMFormattedComp();
        fixedCostsTotalCostsPerKMFormattedComp = null;
    }

    if (fuelCostPerMonthFomattedComp !== null) {
        // fuelCostPerMonthFomattedComp();
        fuelCostPerMonthFomattedComp = null;
    }

    if (fuelCostPerKMFormattedComp !== null) {
        // fuelCostPerKMFormattedComp();
        fuelCostPerKMFormattedComp = null;
    }

    if (tyreCostPerMonthFormattedComp !== null) {
        // tyreCostPerMonthFormattedComp();
        tyreCostPerMonthFormattedComp = null;
    }

    if (tyreCostPerKMFormattedComp !== null) {
        // tyreCostPerKMFormattedComp();
        tyreCostPerKMFormattedComp = null;
    }

    if (maintenanceCostPerMonthFormattedComp !== null) {
        // maintenanceCostPerMonthFormattedComp();
        maintenanceCostPerMonthFormattedComp = null;
    }

    if (maintenanceCostPerKMFormattedComp !== null) {
        // maintenanceCostPerKMFormattedComp();
        maintenanceCostPerKMFormattedComp = null;
    }

    if (tollFeesCostPerMonthFormattedComp !== null) {
        // tollFeesCostPerMonthFormattedComp();
        tollFeesCostPerMonthFormattedComp = null;
    }

    if (tollFeesCostPerKMFormattedComp !== null) {
        // tollFeesCostPerKMFormattedComp();
        tollFeesCostPerKMFormattedComp = null;
    }

    if (variableCostsTotalCostPerMonthFormattedComp !== null) {
        // variableCostsTotalCostPerMonthFormattedComp();
        variableCostsTotalCostPerMonthFormattedComp = null;
    }

    if (variableCostsTotalCostPerKMFormattedComp !== null) {
        // variableCostsTotalCostPerKMFormattedComp();
        variableCostsTotalCostPerKMFormattedComp = null;
    }

    if (totalCostsPerMonthFormattedComp !== null) {
        // totalCostsPerMonthFormattedComp();
        totalCostsPerMonthFormattedComp = null;
    }

    if (totalCostsPerKMFormattedComp !== null) {
        // totalCostsPerKMFormattedComp();
        totalCostsPerKMFormattedComp = null;
    }

    if (totalRevenuePerMonthFormattedComp !== null) {
        // totalRevenuePerMonthFormattedComp();
        totalRevenuePerMonthFormattedComp = null;
    }

    if (totalRevenuePerKMFormattedComp !== null) {
        // totalRevenuePerKMFormattedComp();
        totalRevenuePerKMFormattedComp = null;
    }

    if (totalProfitPerMonthFormattedComp !== null) {
        // totalProfitPerMonthFormattedComp();
        totalProfitPerMonthFormattedComp = null;
    }

    if (totalProfitPerKMFormattedComp !== null) {
        // totalProfitPerKMFormattedComp();
        totalProfitPerKMFormattedComp = null;
    }


    if (totalFinanceCostFormattedComp !== null) {
        // totalFinanceCostFormattedComp();
        totalFinanceCostFormattedComp = null;
    }

    if (financeCostPercentageOfTotalFormattedComp !== null) {
        // financeCostPercentageOfTotalFormattedComp();
        financeCostPercentageOfTotalFormattedComp = null;
    }

    if (crewCostPercentageOfTotalFormattedComp !== null) {
        // crewCostPercentageOfTotalFormattedComp();
        crewCostPercentageOfTotalFormattedComp = null;
    }

    if (insuranceCostPercentageOfTotalFormattedComp !== null) {
        // insuranceCostPercentageOfTotalFormattedComp();
        insuranceCostPercentageOfTotalFormattedComp = null;
    }

    if (licenceFeesCostPercentageOfTotalFormattedComp !== null) {
        // licenceFeesCostPercentageOfTotalFormattedComp();
        licenceFeesCostPercentageOfTotalFormattedComp = null;
    }

    if (overheadsCostPercentageOfTotalFormattedComp !== null) {
        // overheadsCostPercentageOfTotalFormattedComp();
        overheadsCostPercentageOfTotalFormattedComp = null;
    }

    if (fuelCostPercentageOfTotalFomattedComp !== null) {
        // fuelCostPercentageOfTotalFomattedComp();
        fuelCostPercentageOfTotalFomattedComp = null;
    }
    if (tyreCostPercentageOfTotalFormattedComp !== null) {
        // tyreCostPercentageOfTotalFormattedComp();
        tyreCostPercentageOfTotalFormattedComp = null;
    }

    if (maintenanceCostPercentageOfTotalFormattedComp !== null) {
        // maintenanceCostPercentageOfTotalFormattedComp();
        maintenanceCostPercentageOfTotalFormattedComp = null;
    }

    if (tollFeesCostPercentageOfTotalFormattedComp !== null) {
        // tollFeesCostPercentageOfTotalFormattedComp();
        tollFeesCostPercentageOfTotalFormattedComp = null;
    }
}

function setupPerformanceObservables() {
    var path = config.OBJECT_TYPES.PERFORMANCE;

    //Vehicle Total Unladen
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.PERFORMANCEVEHICLETOTALUNLADEN, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(getActiveOffer().getPerformance().getVehicleTotalUnladen)
        .setter(getActiveOffer().getPerformance().setVehicleTotalUnladen)
        .overridePermission(function () {
            return true;
        })
        .addOverrideMechanism(getActiveOffer().getPerformance,
            function (val) {
                getActiveOffer().getPerformance().setVehicleTotalUnladenOverride(val);
            },
            function () {
                return getActiveOffer().getConfiguration().getUnladenTotal();
            },
            function () {
                return getActiveOffer().getPerformance().getVehicleTotalUnladenOverride();
            })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.PERFORMANCEVEHICLETOTALUNLADEN)), path);

    //Frontal Area
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.PERFORMANCEFRONTALAREA, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.AREA)
        .getter(getActiveOffer().getPerformance().getFrontalArea)
        .setter(getActiveOffer().getPerformance().setFrontalArea)
        .overridePermission(function () {
            return true;
        })
        .addOverrideMechanism(getActiveOffer().getPerformance,
            function (val) {
                getActiveOffer().getPerformance().setFrontalAreaOverride(val);
            },
            function () {
                return rigOps.getFrontalArea();
            },
            function () {
                return getActiveOffer().getPerformance().getFrontalAreaOverride();
            })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.PERFORMANCEFRONTALAREA)), path);

    //Coefficient Of Drag
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.PERFORMANCECOEFFICIENTOFDRAG, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.COEFFICIENTOFDRAG)
        .getter(getActiveOffer().getPerformance().getCoefficientOfDrag)
        .setter(getActiveOffer().getPerformance().setCoefficientOfDrag)
        .overridePermission(function () {
            return true;
        })
        .addOverrideMechanism(getActiveOffer().getPerformance,
            function (val) {
                getActiveOffer().getPerformance().setCoefficientOfDragOverride(val);
            },
            function () {
                return getOriginalOffer().getPerformance().getCoefficientOfDrag();
            },
            function () {
                return getActiveOffer().getPerformance().getCoefficientOfDragOverride();
            })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.PERFORMANCECOEFFICIENTOFDRAG)), path);

    //Drive Axle Ratio
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.PERFORMANCEDRIVEAXLERATIO, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.DRIVEAXLERATIO)
        .getter(getActiveOffer().getPerformance().getDriveAxleRatio)
        .setter(getActiveOffer().getPerformance().setDriveAxleRatio)
        .overridePermission(function () {
            return globals.user.hasPermission(config.PERMISSIONS.PERFORMANCE_ADMIN_DRIVE_AXLE.Code);
        })
        .readOnlyPermission(function () {
            return !globals.user.hasPermission(config.PERMISSIONS.PERFORMANCE_ADMIN_DRIVE_AXLE.Code);
        })
        .addOverrideMechanism(getActiveOffer().getPerformance,
            function (val) {
                getActiveOffer().getPerformance().setDriveAxleRatioOverride(val);
            },
            function () {
                return getOriginalOffer().getConfiguration().getAxlesHolder().getStandardRatioOfDrivenAxle();
            },
            function () {
                return getActiveOffer().getPerformance().getDriveAxleRatioOverride();
            })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.PERFORMANCEDRIVEAXLERATIO)), path);

    //Maximum Speed
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.PERFORMANCEMAXIMUMSPEED, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.SPEED)
        .getter(getActiveOffer().getPerformance().getSpeedMaxLoaded)
        .setter(getActiveOffer().getPerformance().setSpeedMaxLoaded)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.PERFORMANCEMAXIMUMSPEED)), path);

    //Trailer Total Unladen
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.PERFORMANCETRAILERTOTALUNLADEN, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(getActiveOffer().getPerformance().getTrailerTotalUnladen)
        .setter(getActiveOffer().getPerformance().setTrailerTotalUnladen)
        .overridePermission(function () {
            return true;
        })
        .addOverrideMechanism(getActiveOffer().getPerformance,
            function (val) {
                getActiveOffer().getPerformance().setTrailerTotalUnladenOverride(val);
            },
            function () {
                if (getActiveOffer().getRig().getTrailer1() !== null) {
                    return getActiveOffer().getRig().getTrailer1().getUnladenTotal();
                } else {
                    return 0;
                }

            },
            function () {
                return getActiveOffer().getPerformance().getTrailerTotalUnladenOverride();
            })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.PERFORMANCETRAILERTOTALUNLADEN)), path);

    //Trailer 2 Total Unladen
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.PERFORMANCETRAILER2TOTALUNLADEN, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(getActiveOffer().getPerformance().getTrailer2TotalUnladen)
        .setter(getActiveOffer().getPerformance().setTrailer2TotalUnladen)
        .overridePermission(function () {
            return true;
        })
        .addOverrideMechanism(getActiveOffer().getPerformance,
            function (val) {
                getActiveOffer().getPerformance().setTrailer2TotalUnladenOverride(val);
            },
            function () {
                if (getActiveOffer().getRig().getTrailer2() !== null) {
                    return getActiveOffer().getRig().getTrailer2().getUnladenTotal();
                } else {
                    return 0;
                }

            },
            function () {
                return getActiveOffer().getPerformance().getTrailer2TotalUnladenOverride();
            })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.PERFORMANCETRAILER2TOTALUNLADEN)), path);
}

function setupPerformanceFormattedComputeds() {
    var performance = getActiveOffer().getPerformance();

    totalUnladen = computed(function () {
        refreshData.value;
        if (getActiveOffer() !== '' && getActiveOffer() !== undefined) {
            return getUIFormattedMassValue(performance.getVehicleTotalUnladen() + performance.getTrailerTotalUnladen() + performance.getTrailer2TotalUnladen(), 0);
        }
        return '';
    });
    maximumPayload = computed(function () {
        refreshData.value;
        if (getActiveOffer() !== '' && getActiveOffer() !== undefined) {
            return getUIFormattedMassValue(getMaximumPayload(), 0);
        }
        return '';
    });
    maximumGross = computed(function () {
        refreshData.value;
        if (getActiveOffer() !== '' && getActiveOffer() !== undefined) {
            var totalUnladen = performance.getVehicleTotalUnladen() + performance.getTrailerTotalUnladen() + performance.getTrailer2TotalUnladen();
            var maximumPayload = getMaximumPayload();
            return getUIFormattedMassValue(totalUnladen + maximumPayload, 0);
        }
        return '';
    });

    function getMaximumPayload() {
        var value = 0;

        waypoints.value.forEach(function (waypoint) {
            if (waypoint.getNetPayload() > value) {
                value = waypoint.getNetPayload();
            }
        });

        return value;
    }
}

function cleanUpPerformanceFormattedComputeds() {
    if (totalUnladen !== null) {
        // totalUnladen.dispose();
        totalUnladen = null;
    }
    if (maximumPayload !== null) {
        // maximumPayload.dispose();
        maximumPayload = null;
    }
    if (maximumGross !== null) {
        // maximumGross.dispose();
        maximumGross = null;
    }
}

function setupUserObservables() {
    var path = config.OBJECT_TYPES.SETTINGS;

    if (getActiveOffer() === undefined || getActiveOffer() === '') {
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSCOLOURDRAWINGS, config.INPUT_TYPE.CHECKBOX)
            .addSubscription(function (val) {
                globals.user.updateUser({
                    colourDrawings: val
                });
            })
            .setVal(globals.user.getColourDrawings())
            .disableUndoOpCreation(), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSSHOWCOMPLIANCESCORECARD, config.INPUT_TYPE.CHECKBOX)
            .addSubscription(function (val) {
                globals.user.updateUser({
                    showComplianceScorecard: val
                });
            })
            .setVal(globals.user.getShowComplianceScorecard())
            .disableUndoOpCreation(), path);

        //var legislationOption = globals.user.getAvailableLegislations()
        //    .filter(function (option) {
        //        if (getLegislation() !== undefined) {
        //            if (option.id === getLegislation().id) {
        //                return option;
        //            }
        //        } else {
        //            if (option.id === globals.user.getActiveLegislationId()) {
        //                return option;
        //            }
        //        }
        //    })[0];
        //if (legislationOption === undefined) {
        //    legislationOption = globals.user.getAvailableLegislations()[0];
        //}

        //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSLEGISLATION, config.INPUT_TYPE.SELECT)
        //    .setVal(legislationOption)
        //    .addExtraUpdateFunction(setActiveLegislationUsingId)
        //    .addValidation(new ValidationEngine(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSLEGISLATION, config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_AND_OPS))
        //    .disableUndoOpCreation(), path);

        var measurementSystemOption = globals.user.getAvailableMeasurementSystems()
            .filter(function (option) {
                if (option.id === globals.user.getActiveMeasurementSystemId()) {
                    return option;
                } else if (globals.user.getActiveMeasurementSystemId() === '' || globals.user.getActiveMeasurementSystemId() === undefined) {
                    return option;
                }
            })[0];
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSMEASUREMENTSYSTEM, config.INPUT_TYPE.SELECT)
            .setVal(measurementSystemOption)
            .addExtraUpdateFunction(setActiveMeasurementSystemUsingId)
            .disableUndoOpCreation(), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSLENGTHINCREMENT, config.INPUT_TYPE.TEL)
            .setter(globals.user.setLengthIncrement)
            .getter(globals.user.getLengthIncrement)
            .disableUndoOpCreation(), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSMASSINCREMENT, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.MASS)
            .setter(globals.user.setMassIncrement)
            .getter(globals.user.getMassIncrement)
            .disableUndoOpCreation(), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSPERCENTAGEINCREMENT, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.PERCENTAGEINCREMENT)
            .setter(globals.user.setPercentageIncrement)
            .getter(globals.user.getPercentageIncrement)
            .disableUndoOpCreation(), path);

        var specifyWheelbaseAsOption = getSpecifyWheelbaseAsOptions()
            .filter(function (option) {
                if (option.id === globals.user.getSpecifyWheelbaseAs()) {
                    return option;
                } else if (globals.user.getSpecifyWheelbaseAs() === '' || globals.user.getSpecifyWheelbaseAs() === undefined) {
                    return option;
                }
            })[0];
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSSPECIFYWHEELBASEAS, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                globals.user.updateUser({
                    specifyWheelbaseAs: val
                });
                if (refreshSWLabels !== null) {
                    refreshSWLabels.value = new Date().getTime();
                }
            })
            .setVal(specifyWheelbaseAsOption)
            .disableUndoOpCreation(), path);

        var specifyCabDimensionsAsOption = getSpecifyCabDimensionsAsOptions()
            .find(function (option) {
                return option.id === globals.user.getSpecifyCabDimensionsAs();
            });
        var specifyCabDimensionsAsOptionToUse = specifyCabDimensionsAsOption !== undefined ? specifyCabDimensionsAsOption : getSpecifyCabDimensionsAsOptions()[0];
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSSPECIFYCABDIMENSIONSAS, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                globals.user.updateUser({
                    specifyCabDimensionsAs: val
                });
            })
            .setVal(specifyCabDimensionsAsOptionToUse)
            .disableUndoOpCreation(), path);

        var specifyChassisLengthAsOption = offerManager.getSpecifyChassisLengthAsOptions()
            .find(function (option) {
                return option.id === globals.user.getSpecifyChassisLengthAs();
            });
        var specifyChassisLengthAsOptionToUse = specifyChassisLengthAsOption !== undefined ? specifyChassisLengthAsOption : getSpecifyChassisLengthAsOptions()[0];
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSSPECIFYCHASSISLENGTHAS, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                globals.user.updateUser({
                    specifyChassisLengthAs: val
                });
            })
            .setVal(specifyChassisLengthAsOptionToUse)
            .disableUndoOpCreation(), path);

        var specifyFuelConsumptionAsOption = offerManager.getSpecifyFuelConsumptionAsOptions()
            .find(function (option) {
                return option.id === globals.user.getSpecifyFuelConsumptionAs();
            });
        var specifyFuelConsumptionAsOptionToUse = specifyFuelConsumptionAsOption !== undefined ? specifyFuelConsumptionAsOption : getSpecifyFuelConsumptionAsOptions()[0];
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSSPECIFYFUELCONSUMPTIONAS, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                globals.user.updateUser({
                    specifyFuelConsumptionAs: val
                });
            })
            .setVal(specifyFuelConsumptionAsOptionToUse)
            .disableUndoOpCreation(), path);

        var specifyAxleRatingAsOption = offerManager.getSpecifyAxleRatingAsOptions()
            .find(function (option) {
                return option.id === globals.user.getSpecifyAxleRatingAs();
            });
        var specifyAxleRatingAsOptionToUse = specifyAxleRatingAsOption !== undefined ? specifyAxleRatingAsOption : getSpecifyAxleRatingAsOptions()[0]; //User
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSSPECIFYAXLERATINGAS, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                globals.user.updateUser({
                    specifyAxleRatingAs: val
                });
            })
            .setVal(specifyAxleRatingAsOptionToUse)
            .disableUndoOpCreation(), path);
            
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSSPECIFYLICENCINGREGIONAS, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                globals.user.updateUser({
                    specifyLicencingRegionAs: val
                });
            })
            .setVal(globals.user.getSpecifyLicencingRegionAs())
            .disableUndoOpCreation(), path);

        var defaultReportPdfPageSizeOption = offerManager.getDefaultReportPdfPageSizeOptions()
            .find(function (option) {
                return option.id === globals.user.getDefaultReportPdfPageSize();
            });
        var defaultReportPdfPageSizeOptionToUse = defaultReportPdfPageSizeOption !== undefined ? defaultReportPdfPageSizeOption : getDefaultReportPdfPageSizeOptions()[0]; //User
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSDEFAULTREPORTPDFPAGESIZE, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                globals.user.updateUser({
                    defaultReportPdfPageSize: val
                });
            })
            .setVal(defaultReportPdfPageSizeOptionToUse)
            .disableUndoOpCreation(), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSCOMPANYADDITIONALNOTE, config.INPUT_TYPE.TEXT)
            .setter(globals.user.setCompanyAdditionalNote)
            .getter(globals.user.getCompanyAdditionalNote)
            .disableUndoOpCreation(), path);

        var specifyBodyPositionAsOption = offerManager.getSpecifyBodyPositionAsOptions()
            .find(function (option) {
                return option.id === globals.user.getSpecifyBodyPositionAs();
            });
        var specifyBodyPositionAsOptionToUse = specifyBodyPositionAsOption !== undefined ? specifyBodyPositionAsOption : getSpecifyBodyPositionAsOptions()[0]; //User
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSSPECIFYBODYPOSITIONAS, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                globals.user.updateUser({
                    specifyBodyPositionAs: val
                });
            })
            .setVal(specifyBodyPositionAsOptionToUse)
            .disableUndoOpCreation(), path);

        var specifyEquipmentPositionAsOption = offerManager.getSpecifyEquipmentPositionAsOptions()
            .find(function (option) {
                return option.id === globals.user.getSpecifyEquipmentPositionAs();
            });
        var specifyEquipmentPositionAsOptionToUse = specifyEquipmentPositionAsOption !== undefined ? specifyEquipmentPositionAsOption : getSpecifyEquipmentPositionAsOptions()[0]; //User
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSSPECIFYEQUIPMENTPOSITIONAS, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                globals.user.updateUser({
                    specifyEquipmentPositionAs: val
                });
            })
            .setVal(specifyEquipmentPositionAsOptionToUse)
            .disableUndoOpCreation(), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSSHOWTIPS, config.INPUT_TYPE.CHECKBOX)
            .addSubscription(function (val) {
                globals.user.updateUser({
                    showTips: val
                });
            })
            .setVal(globals.user.getShowTips())
            .disableUndoOpCreation(), path);

        var reportViewLayoutOption = offerManager.getReportViewLayoutOptions()
            .find(function (option) {
                return option.id === globals.user.getReportViewLayout();
            });
        var reportViewLayoutOptionToUse = reportViewLayoutOption !== undefined ? reportViewLayoutOption : getReportViewLayoutOptions()[0]; //OFFER
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSREPORTVIEWLAYOUT, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                globals.user.updateUser({
                    reportViewLayout: val
                });
            })
            .setVal(reportViewLayoutOptionToUse)
            .disableUndoOpCreation(), path);
    }

    setupOfferSettingsObservables();
}

function setupOfferSettingsObservables() {
    var path = config.OBJECT_TYPES.SETTINGS;

    if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSDESCRIPTION, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
            .setter(getActiveOffer().setDescription)
            .getter(getActiveOffer().getDescription)
            //.visiblePermission(function () { return globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code); })
            /* .readOnlyPermission(function () {
                return !globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code);
            })*/
            .addSubscription(function (val) {
                description.value = val;
                getActiveOffer().setDescription(val);
            })
            .disableUndoOpCreation(), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSOVERALLDESCRIPTION, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
            .getter(getActiveOffer().getOverallDescription)
            .readOnly()
            .visiblePermission(function () {
                return !globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code);
            })
            .disableUndoOpCreation(), path);

        //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSADDITIONALDESCRIPTION, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
        //    .setter(getActiveOffer().setAdditionalDescription)
        //    .getter(getActiveOffer().getAdditionalDescription)
        //    .visiblePermission(function () {
        //        return !globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code);
        //    })
        //    .addSubscription(function (val) {
        //        additionalDescription(val);
        //        getActiveOffer().setAdditionalDescription(val);
        //    })
        //    .disableUndoOpCreation(), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSPREPAREDFORNAME, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
            .setter(getActiveOffer().setPreparedForName)
            .getter(getActiveOffer().getPreparedForName)
            .addSubscription(function (val) {
                getActiveOffer().setPreparedForName(val);
            })
            // .visiblePermission(function () {
            //     return globals.user.isUserAllowedToAddPreparedForDetails();
            // })
            .disableUndoOpCreation(), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSPREPAREDFORTELEPHONE, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
            .setter(getActiveOffer().setPreparedForTelephone)
            .getter(getActiveOffer().getPreparedForTelephone)
            .addSubscription(function (val) {
                getActiveOffer().setPreparedForTelephone(val);
            })
            // .visiblePermission(function () {
            //     return globals.user.isUserAllowedToAddPreparedForDetails();
            // })
            .disableUndoOpCreation(), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SUMMARYNOTE, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
            .setter(getActiveOffer().setSummaryNote)
            .getter(getActiveOffer().getSummaryNote)
            .visiblePermission(function () {
                return areModulesOtherThanConfigurationAvailable();
            })
            .addSubscription(function (val) {
                getActiveOffer().setSummaryNote(val);
            })
            .disableUndoOpCreation(), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSCOLOURDRAWINGS, config.INPUT_TYPE.CHECKBOX)
            .addSubscription(function (val) {
                globals.user.updateUser({
                    colourDrawings: val
                });
            })
            .setVal(globals.user.getColourDrawings())
            .disableUndoOpCreation(), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSSHOWCOMPLIANCESCORECARD, config.INPUT_TYPE.CHECKBOX)
            .addSubscription(function (val) {
                globals.user.updateUser({
                    showComplianceScorecard: val
                });
            })
            .setVal(globals.user.getShowComplianceScorecard())
            .disableUndoOpCreation(), path);

        //var legislationOption = globals.user.getAvailableLegislations()
        //    .filter(function (option) {
        //        if (option.id === getLegislation().id) {
        //            return option;
        //        }
        //    })[0];
        //if (legislationOption === undefined) {
        //    legislationOption = globals.user.getAvailableLegislations()[0];
        //}

        //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSLEGISLATION, config.INPUT_TYPE.SELECT)
        //    .setVal(legislationOption)
        //    .addExtraUpdateFunction(setActiveLegislationUsingId)
        //    .addValidation(new ValidationEngine(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSLEGISLATION, config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_AND_OPS))
        //    .disableUndoOpCreation(), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSAPPLYHIGHERMASSLIMITS, config.INPUT_TYPE.CHECKBOX)
            .visiblePermission(function () {
                return getLesserOfActiveLegislationVals(legislationVals.variableTypes.ShowHigherMassLimitsOption) === 1 && getLesserOfActiveLegislationVals(legislationVals.variableTypes.HigherMassLimitsForCombination) === 1;
            })
            .addSubscription(function (val) {
                getActiveOffer().setApplyHigherMassLimits(val);
            })
            .setVal(getActiveOffer().getApplyHigherMassLimits())
            .disableUndoOpCreation(), path);

        var vehicleApplicationOption = getAvailableVehicleApplicationTypes()[0];


        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSVEHICLEAPPLICATION, config.INPUT_TYPE.SELECT)
            .addExtraUpdateFunction(setActiveApplicationUsingId)
            .setVal(vehicleApplicationOption)
            .addValidation(new ValidationEngine(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSVEHICLEAPPLICATION, config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_AND_OPS))
            .disableUndoOpCreation(), path);

        var measurementSystemOption = globals.user.getAvailableMeasurementSystems()
            .filter(function (option) {
                if (option.id === globals.user.getActiveMeasurementSystemId()) {
                    return option;
                } else if (globals.user.getActiveMeasurementSystemId() === '' || globals.user.getActiveMeasurementSystemId() === undefined) {
                    return option;
                }
            })[0];
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSMEASUREMENTSYSTEM, config.INPUT_TYPE.SELECT)
            .setVal(measurementSystemOption)
            .addExtraUpdateFunction(setActiveMeasurementSystemUsingId)
            // .addLinkedAOG(path + '.' + config.VALUE_TYPE.SETTINGSLENGTHINCREMENT)
            // .addLinkedAOG(path + '.' + config.VALUE_TYPE.SETTINGSMASSINCREMENT)
            .disableUndoOpCreation(), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSLENGTHINCREMENT, config.INPUT_TYPE.TEL)
            .setter(getActiveOffer().setLengthIncrement)
            .getter(getActiveOffer().getLengthIncrement)
            .addSubscription(function (val) {
                globals.user.setLengthIncrement(val);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.SETTINGSLENGTHINCREMENT))
            //.addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.DEFAULTINCREMENTVALIDATION))
            .disableUndoOpCreation(), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSMASSINCREMENT, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.MASS)
            .setter(getActiveOffer().setMassIncrement)
            .getter(getActiveOffer().getMassIncrement)
            .addSubscription(function (val) {
                globals.user.setMassIncrement(val);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.SETTINGSMASSINCREMENT))
            //.addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.DEFAULTINCREMENTVALIDATION))
            .disableUndoOpCreation(), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSPERCENTAGEINCREMENT, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.PERCENTAGEINCREMENT)
            .setter(getActiveOffer().setPercentageIncrement)
            .getter(getActiveOffer().getPercentageIncrement)
            .addSubscription(function (val) {
                globals.user.setPercentageIncrement(val);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.SETTINGSPERCENTAGEINCREMENT))
            //.addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.DEFAULTPERCENTAGEVALIDATION))
            .disableUndoOpCreation(), path);

        var specifyWheelbaseAsOption = getSpecifyWheelbaseAsOptions()
            .filter(function (option) {
                if (option.id === globals.user.getSpecifyWheelbaseAs()) {
                    return option;
                } else if (globals.user.getSpecifyWheelbaseAs() === '' || globals.user.getSpecifyWheelbaseAs() === undefined) {
                    return option;
                }
            })[0];
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSSPECIFYWHEELBASEAS, config.INPUT_TYPE.SELECT)
            .setVal(specifyWheelbaseAsOption)
            .addSubscription(function (val) {
                var oldVal = globals.user.getSpecifyWheelbaseAs();
                globals.user.updateUser({
                    specifyWheelbaseAs: val
                });
                if (getActiveOffer() !== null && getActiveOffer() !== undefined && getActiveOffer() !== '' && getActiveOffer().getRig().getVehicle) {
                    if (getActiveOffer().getRig().getVehicle().getWheelbaseOverride() === true) {
                        globals.wheelbaseSpecifiedOptionJustChanged = true;
                        registerRenderingCompleteCallbackListener(setWheelbaseSpecifiedJustChangedOptionToFalse);
                    } else {
                        getActiveOffer().getRig().getVehicle().setWheelbaseSpecified(rigOps.getWheelbaseSpecified());
                        rigOps.adjustRearMostProtrudingItemWhenSwitchingWheelbaseSpecifiedAsOption(oldVal, val);
                    }

                }
            })
            .disableUndoOpCreation(), path);

        var specifyCabDimensionsAsOption = getSpecifyCabDimensionsAsOptions()
            .find(function (option) {
                return option.id === globals.user.getSpecifyCabDimensionsAs();
            });
        var specifyCabDimensionsAsOptionToUse = specifyCabDimensionsAsOption !== undefined ? specifyCabDimensionsAsOption : getSpecifyCabDimensionsAsOptions()[0];
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSSPECIFYCABDIMENSIONSAS, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                globals.user.updateUser({
                    specifyCabDimensionsAs: val
                });
            })
            .setVal(specifyCabDimensionsAsOptionToUse)
            .disableUndoOpCreation(), path);

        var specifyChassisLengthAsOption = offerManager.getSpecifyChassisLengthAsOptions()
            .find(function (option) {
                return option.id === globals.user.getSpecifyChassisLengthAs();
            });
        var specifyChassisLengthAsOptionToUse = specifyChassisLengthAsOption !== undefined ? specifyChassisLengthAsOption : getSpecifyChassisLengthAsOptions()[0];
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSSPECIFYCHASSISLENGTHAS, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                globals.user.updateUser({
                    specifyChassisLengthAs: val
                });
                if (val === config.SPECIFY_CHASSIS_LENGTH_AS_OPTIONS.CA) {
                    if (getActiveOffer().getRig().getVehicle().getWheelbaseOverride() === true) {
                        getActiveOffer().getRig().getVehicle().setCabToAxlesOverride(true);
                    }
                }

            })
            .setVal(specifyChassisLengthAsOptionToUse)
            .disableUndoOpCreation(), path);

        var specifyFuelConsumptionAsOption = offerManager.getSpecifyFuelConsumptionAsOptions()
            .find(function (option) {
                return option.id === globals.user.getSpecifyFuelConsumptionAs();
            });
        var specifyFuelConsumptionAsOptionToUse = specifyFuelConsumptionAsOption !== undefined ? specifyFuelConsumptionAsOption : getSpecifyFuelConsumptionAsOptions()[0];
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSSPECIFYFUELCONSUMPTIONAS, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                globals.user.updateUser({
                    specifyFuelConsumptionAs: val
                });
                if (val === config.SPECIFY_CHASSIS_LENGTH_AS_OPTIONS.CA) {
                    if (getActiveOffer().getRig().getVehicle().getWheelbaseOverride() === true) {
                        getActiveOffer().getRig().getVehicle().setCabToAxlesOverride(true);
                    }
                }

            })
            .setVal(specifyFuelConsumptionAsOptionToUse)
            .disableUndoOpCreation(), path);

        var specifyAxleRatingAsOption = offerManager.getSpecifyAxleRatingAsOptions()
            .find(function (option) {
                return option.id === globals.user.getSpecifyAxleRatingAs();
            });
        var specifyAxleRatingAsOptionToUse = specifyAxleRatingAsOption !== undefined ? specifyAxleRatingAsOption : getSpecifyAxleRatingAsOptions()[0]; //OFFER
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSSPECIFYAXLERATINGAS, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                
                if (val === config.SPECIFY_AXLE_RATING_AS_OPTIONS.SIMPLIFIED) {
                    getActiveOffer().getRig().getVehicle().getAxlesHolder().convertManufacturerRatingsFromDetailedToSimplified();
                }
                getActiveOffer().getRig().getVehicle().getAxlesHolder().updateValuesOnAllAxlesAndAxleGroups();
                globals.user.updateUser({
                    specifyAxleRatingAs: val
                });
            })
            .setVal(specifyAxleRatingAsOptionToUse)
            .disableUndoOpCreation(), path);

        var defaultReportPdfPageSizeOption = offerManager.getDefaultReportPdfPageSizeOptions()
            .find(function (option) {
                return option.id === globals.user.getDefaultReportPdfPageSize();
            });
        var defaultReportPdfPageSizeOptionToUse = defaultReportPdfPageSizeOption !== undefined ? defaultReportPdfPageSizeOption : getDefaultReportPdfPageSizeOptions()[0]; //User
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSDEFAULTREPORTPDFPAGESIZE, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                globals.user.updateUser({
                    defaultReportPdfPageSize: val
                });
            })
            .setVal(defaultReportPdfPageSizeOptionToUse)
            .disableUndoOpCreation(), path);

        var specifyBodyPositionAsOption = offerManager.getSpecifyBodyPositionAsOptions()
            .find(function (option) {
                return option.id === globals.user.getSpecifyBodyPositionAs();
            });
        var specifyBodyPositionAsOptionToUse = specifyBodyPositionAsOption !== undefined ? specifyBodyPositionAsOption : getSpecifyBodyPositionAsOptions()[0]; //OFFER
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSSPECIFYBODYPOSITIONAS, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                globals.user.updateUser({
                    specifyBodyPositionAs: val
                });
            })
            .setVal(specifyBodyPositionAsOptionToUse)
            .disableUndoOpCreation(), path);

        var specifyEquipmentPositionAsOption = offerManager.getSpecifyEquipmentPositionAsOptions()
            .find(function (option) {
                return option.id === globals.user.getSpecifyEquipmentPositionAs();
            });
        var specifyEquipmentPositionAsOptionToUse = specifyEquipmentPositionAsOption !== undefined ? specifyEquipmentPositionAsOption : getSpecifyEquipmentPositionAsOptions()[0]; //OFFER
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSSPECIFYEQUIPMENTPOSITIONAS, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                globals.user.updateUser({
                    specifyEquipmentPositionAs: val
                });
            })
            .setVal(specifyEquipmentPositionAsOptionToUse)
            .disableUndoOpCreation(), path);
            
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSSPECIFYLICENCINGREGIONAS, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                globals.user.updateUser({
                    specifyLicencingRegionAs: val
                });
            })
            .setVal(globals.user.getSpecifyLicencingRegionAs())
            .disableUndoOpCreation(), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSSHOWTIPS, config.INPUT_TYPE.CHECKBOX)
            .addSubscription(function (val) {
                globals.user.updateUser({
                    showTips: val
                });
            })
            .setVal(globals.user.getShowTips())
            .disableUndoOpCreation(), path);

        var reportViewLayoutOption = offerManager.getReportViewLayoutOptions()
            .find(function (option) {
                return option.id === globals.user.getReportViewLayout();
            });
        var reportViewLayoutOptionToUse = reportViewLayoutOption !== undefined ? reportViewLayoutOption : getReportViewLayoutOptions()[0]; //OFFER
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSREPORTVIEWLAYOUT, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                globals.user.updateUser({
                    reportViewLayout: val
                });
            })
            .setVal(reportViewLayoutOptionToUse)
            .disableUndoOpCreation(), path);
    }
}

function setWheelbaseSpecifiedJustChangedOptionToFalse() {
    globals.wheelbaseSpecifiedOptionJustChanged = false;
    removeRenderingCompleteCallbackListener(setWheelbaseSpecifiedJustChangedOptionToFalse);
    //rigOps.adjustRearMostProtrudingItemWhenSwitchingWheelbaseSpecifiedAsOption(oldVal, val);
}

function setupReportOptionObservables() {
    var path = config.OBJECT_TYPES.REPORT_OPTIONS,
        settingsPath = config.OBJECT_TYPES.SETTINGS;

    getActiveOffer().getSummaryReportOptions().forEach(function (reportOption) {
        createReportOptionObservables(reportOption, path);
    });

    if (isConfigurationReportOptionAvailable()) {
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SETTINGSADDITIONALNOTES, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
            .setter(getActiveOffer().setAdditionalNote)
            .getter(getActiveOffer().getAdditionalNote)
            .visiblePermission(function () {
                var configurationReportOption = getSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.CONFIGURATION);
                return configurationReportOption.getIsChecked();
            })
            .addSubscription(function (val) {
                getActiveOffer().setAdditionalNote(val);
            })
            .disableUndoOpCreation(), settingsPath);
    }

}

function createReportOptionObservables(reportOption, path) {
    path = path + '.' + reportOption.getCode();

    reportOption.getSummaryReportOfferOptions().forEach(function (reportOfferOption) {
        createReportOfferOptionObservables(reportOfferOption, path);
    });

    if (reportOption.getCode() === config.SUMMARY_REPORT_OPTIONS.PERFORMANCE) {
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SUMMARYREPORTOPTION, config.INPUT_TYPE.CHECKBOX)
            .readOnlyPermission(function () {
                if (getActiveOffer().getPerformance().getSimulationCreated() === true) {
                    return false;
                } else {
                    return true;
                }
            })
            .addSubscription(function (val) {
                reportOption.setIsChecked(val);
            })
            .setVal(reportOption.getIsChecked())
            .disableUndoOpCreation(), path);
    } else if (reportOption.getCode() === config.SUMMARY_REPORT_OPTIONS.SPECIFICATION) {
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SUMMARYREPORTOPTION, config.INPUT_TYPE.CHECKBOX)
            //.readOnlyPermission(function () {
            //    if (getActiveOffer().getSpecification() !== null && getActiveOffer().getSpecification() !== undefined && ((getActiveOffer().getSpecification().getSelectedCompetitor1() !== null && getActiveOffer().getSpecification().getSelectedCompetitor1() !== undefined) ||
            //        (getActiveOffer().getSpecification().getSelectedCompetitor2() !== null && getActiveOffer().getSpecification().getSelectedCompetitor2() !== undefined))) {
            //        return false;
            //    } else {
            //        return true;
            //    }
            //})
            .addSubscription(function (val) {
                reportOption.setIsChecked(val);
            })
            .setVal(reportOption.getIsChecked())
            .disableUndoOpCreation(), path);
    } else {
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SUMMARYREPORTOPTION, config.INPUT_TYPE.CHECKBOX)
            .addSubscription(function (val) {
                reportOption.setIsChecked(val);
                reportOption.getSummaryReportOfferOptions().forEach(function (reportOfferOption) {
                    reportOfferOption.setIsChecked(val);
                });
            })
            .setVal(reportOption.getIsChecked())
            .disableUndoOpCreation(), path);
    }
}

function createReportOfferOptionObservables(reportOfferOption, path) {
    path = path + '.' + reportOfferOption.getCode();

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SUMMARYREPORTOPTION, config.INPUT_TYPE.CHECKBOX)
        .visiblePermission(function () {
            var configurationReportOption = getSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.CONFIGURATION);
            return configurationReportOption.getIsChecked();
        })
        .addSubscription(function (val) {
            reportOfferOption.setIsChecked(val);
        })
        .setVal(reportOfferOption.getIsChecked())
        .disableUndoOpCreation(), path);

}

function setupVehicleObservables() {
    ///DIMENSIONS
    //WHEELBASE & AXLE SPACING

    var isLoadingAdmin = globals.user.isLoadingAdministrator();

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.VEHICLEDESCRIPTION, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
        .setter(function (newValue) {
            getActiveOffer().getRig().getVehicle().setDescription(newValue);
        })
        .getter(function () {
            return getActiveOffer().getRig().getVehicle().getDescription();
        })
        //.visiblePermission(function () { return globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code); })
        .readOnlyPermission(function () {
            return getActiveOffer().getRig().getVehicle().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD;
        })
        .addSubscription(function (val) {
            getActiveOffer().getRig().getVehicle().setDescription(val);
            // description.valueHasMutated();
            triggerRef(description);
            if(typeof updateVehicleMenuCallback === 'function') {
                updateVehicleMenuCallback(val, path);
            }
        })
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.VEHICLEDESCRIPTION, config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_AND_OPS)));


    var vehicleFrontOverhangAttributeGroup = new AttributeObservableGroup(config.VALUE_TYPE.FRONTOVERHANG, config.INPUT_TYPE.UPDOWNBOX)
        .visiblePermission(isNotStandardVehicle)
        .setter(getConfiguration().setFrontOverhang)
        .getter(getConfiguration().getFrontOverhang)


    var vehicleFrontOverhangValidationObject = new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.FRONTOVERHANG);

    //vehicleFrontOverhangValidationObject.addOp(config.VALIDATION_OP_TYPE.INC_BY_DELTA, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES);
    vehicleFrontOverhangValidationObject.addOpBlock(function (newValue, oldValue, deltaVal) {
        var tempVehicle = getActiveOffer().getRig().getVehicle();
        if (globals.user.getSpecifyCabDimensionsAs() === config.SPECIFY_CAB_DIMENSIONS_AS_OPTIONS.AC) {
            tempVehicle.setBumperToBackOfCab(tempVehicle.getBumperToBackOfCab() + deltaVal);
        } else {
            tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() + deltaVal);
        }
        if (tempVehicle.getAccessoryHolder().getHitch() !== null) {
            tempVehicle.getAccessoryHolder().getHitch().setHorizontalGap(tempVehicle.getWheelbaseTheoreticalEnd());
        }

        checkIfBodyAndBreakChassisLinkIfNecessary();

        if(globals.user.getSpecifyBodyPositionAs() === config.SPECIFY_POSITION_AS_OPTIONS.FROM_FRONTMOST_AXLE) {
            rigOps.calculateFromAxle1FrontForRelevantAccessories();
        }
        
    });
    

    if (!isLoadingAdmin) {
        vehicleFrontOverhangValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLVEHICLELENGTH, rigOps.getOverallVehicleLengthLegal);
    }

    vehicleFrontOverhangValidationObject.addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES);

    vehicleFrontOverhangAttributeGroup.addValidation(vehicleFrontOverhangValidationObject);

    addAttributeGroup(vehicleFrontOverhangAttributeGroup);

    var vehicleAxle1FrontToAxle2FrontAttributeGroup = new AttributeObservableGroup(config.VALUE_TYPE.AXLE1FRONTTOAXLE2FRONT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getAxle1FrontToAxle2Front)
        .setter(getConfiguration().setAxle1FrontToAxle2Front)
        .visiblePermission(showAxle1FrontToAxle2Front)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setAxle1FrontToAxle2FrontOverride(val);
            },
            function () {
                return getOriginalOffer().getRig().getVehicle().getAxle1FrontToAxle2Front();
            },
            function () {
                return getConfiguration().getAxle1FrontToAxle2FrontOverride();
            },
            function () {
                var tempVehicle = getActiveOffer().getRig().getVehicle();
                var tempOriginalVehicle = getOriginalOffer().getRig().getVehicle();
                var delta = (tempOriginalVehicle.getAxle1FrontToAxle2Front() - tempVehicle.getAxle1FrontToAxle2Front());
                //tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() + delta);
                var deltaAxle1FrontToAxle2Front = tempOriginalVehicle.getAxle1FrontToAxle2Front() - tempVehicle.getAxle1FrontToAxle2Front();

                handleAxleSpacingOverrideRemove(delta, deltaAxle1FrontToAxle2Front, tempVehicle, tempVehicle.getAxle1FrontToAxle2Front, config.AXLE_SECTIONS.AXLE_1_FRONT_TO_AXLE_2_FRONT);
            })
        .overridePermission(showIfStandardAndAdmin)
    //.setAllowOpsWhileDragging(true);


    var vehicleAxle1FrontToAxle2FrontValidationObject = new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.AXLE1FRONTTOAXLE2FRONT);

    //vehicleAxle1FrontToAxle2FrontValidationObject.addOp(config.VALIDATION_OP_TYPE.INC_BY_DELTA, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES);

    vehicleAxle1FrontToAxle2FrontValidationObject.addOpBlock(function (newValue, oldValue, deltaVal) {
        var tempVehicle = getActiveOffer().getRig().getVehicle();
        //handleRearMostProtrudingItemBehindCabToRearAxlesChange(tempVehicle, tempVehicle.getAxle1FrontToAxle2Front, config.AXLE_SECTIONS.AXLE_1_FRONT_TO_AXLE_2_FRONT);
        var specifiedWheelbaseAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.WHEELBASESPECIFIED);
        var calculatedWheelbaseSpecified = rigOps.getWheelbaseSpecified();
        var specifiedWheelbaseVal = specifiedWheelbaseAOG.value.value;
        var specifyWheelbaseAs = globals.user.getSpecifyWheelbaseAs();


        var wheelbaseDelta = calculatedWheelbaseSpecified - specifiedWheelbaseVal;
        var deltaToApply;
        if (specifyWheelbaseAs === config.SPECIFY_WHEELBASE_AS_OPTIONS.CENTRE_OF_FRONT_AXLES_TO_CENTRE_OF_REAR_AXLES) {
            deltaToApply = wheelbaseDelta * 2;
        } else {
            deltaToApply = wheelbaseDelta;
        }

        tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() + deltaToApply);
    }, function (newValue, oldValue, deltaVal) {
        var specifiedWheelbaseAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.WHEELBASESPECIFIED);
        if (specifiedWheelbaseAOG.override.value === false) {
            var tempVehicle = getActiveOffer().getRig().getVehicle();


            //var deltaToApply;
            ////if (globals.user.getSpecifyWheelbaseAs() === config.SPECIFY_WHEELBASE_AS_OPTIONS.CENTRE_OF_FRONT_AXLES_TO_CENTRE_OF_REAR_AXLES) {
            ////    deltaToApply = deltaVal/2;
            ////} else {
            //    deltaToApply = deltaVal;
            ////}

            tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() - deltaVal);
        }
    });
    vehicleAxle1FrontToAxle2FrontValidationObject.addOpBlock(function (newValue, oldValue, deltaVal) {
        var tempVehicle = getActiveOffer().getRig().getVehicle();

        tempVehicle.setWheelbaseTheoretical(tempVehicle.getWheelbaseTheoretical());
        checkIfBodyAndBreakChassisLinkIfNecessary();

        //if (globals.user.getSpecifyWheelbaseAs() === )
        handleWheelbaseSpecifiedChanges(tempVehicle);


        if (tempVehicle.getAxle1FrontToAxle2FrontOverride() === false) {
            tempVehicle.setAxle1FrontToAxle2FrontOverride(true);
        }
    });
    vehicleAxle1FrontToAxle2FrontValidationObject.reverseOpUndoOrder();
    vehicleAxle1FrontToAxle2FrontValidationObject.addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.AXLE1FRONTTOWHEELBASETHEORETICALSTART);
    if (!isLoadingAdmin) {
        vehicleAxle1FrontToAxle2FrontValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLVEHICLELENGTH, rigOps.getOverallVehicleLengthLegal);
    }
    vehicleAxle1FrontToAxle2FrontValidationObject.addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES);
    vehicleAxle1FrontToAxle2FrontValidationObject.addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.WHEELBASESPECIFIED);

    vehicleAxle1FrontToAxle2FrontAttributeGroup.addValidation(vehicleAxle1FrontToAxle2FrontValidationObject);

    addAttributeGroup(vehicleAxle1FrontToAxle2FrontAttributeGroup);



    var vehicleAxle2FrontToAxle1RearAttributeGroup = new AttributeObservableGroup(config.VALUE_TYPE.AXLE2FRONTTOAXLE1REAR, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getAxle2FrontToAxle1Rear)
        .setter(getConfiguration().setAxle2FrontToAxle1Rear)
        .visiblePermission(function () {
            return false;
        });


    //var vehicleAxle2FrontToAxle1RearValidationObject = new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.AXLE2FRONTTOAXLE1REAR);
    //vehicleAxle2FrontToAxle1RearValidationObject.addOp(config.VALIDATION_OP_TYPE.INC_BY_DELTA, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES)
    //        //.addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.WHEELBASETHEORETICAL)
    //vehicleAxle2FrontToAxle1RearValidationObject.addOpBlock(function (newValue, oldValue, deltaVal) {
    //    var tempVehicle = getActiveOffer().getRig().getVehicle();
    //    tempVehicle.setWheelbaseTheoretical(tempVehicle.getWheelbaseTheoretical());
    //    if (tempVehicle.getAccessoryHolder().getHitch() !== null) {
    //        tempVehicle.getAccessoryHolder().getHitch().setHorizontalGap(tempVehicle.getWheelbaseTheoreticalEnd());
    //    }

    //    var axle2Rear = tempVehicle.getAxlesHolder().getAxle2Rear();
    //    var axle3Rear = tempVehicle.getAxlesHolder().getAxle3Rear();
    //    var axle4Rear = tempVehicle.getAxlesHolder().getAxle4Rear();

    //    //if (axle2Rear !== null && axle2Rear.isAdded() && axle2Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
    //    //    tempVehicle.setBreakPointForAxle1RearToAxle2RearFromAxle1Rear(tempVehicle.getBreakPointForAxle1RearToAxle2RearFromAxle1Rear() - deltaVal);
    //    //}

    //    //if (axle2Rear !== null && axle2Rear.isAdded() && axle2Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG && axle3Rear !== null) {
    //    //    //tempVehicle.setBreakPointForAxle1RearToAxle2RearFromAxle1Rear(tempVehicle.getBreakPointForAxle1RearToAxle2RearFromAxle1Rear() - deltaVal/2);
    //    //    tempVehicle.setBreakPointForAxle2RearToAxle3RearFromAxle2Rear(tempVehicle.getBreakPointForAxle2RearToAxle3RearFromAxle2Rear() - deltaVal);
    //    //}
    //    //if (axle3Rear !== null && axle3Rear.isAdded() && axle3Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG && axle4Rear !== null) {
    //    //    //tempVehicle.setBreakPointForAxle2RearToAxle3RearFromAxle2Rear(tempVehicle.getBreakPointForAxle2RearToAxle3RearFromAxle2Rear() - deltaVal);
    //    //    tempVehicle.setBreakPointForAxle3RearToAxle4RearFromAxle3Rear(tempVehicle.getBreakPointForAxle3RearToAxle4RearFromAxle3Rear() - deltaVal);
    //    //}
    //    //if (axle4Rear !== null && axle4Rear.isAdded() && axle4Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
    //    //    tempVehicle.setBreakPointForAxle3RearToAxle4RearFromAxle3Rear(tempVehicle.getBreakPointForAxle3RearToAxle4RearFromAxle3Rear() - deltaVal);
    //    //}

    //    checkIfBodyAndBreakChassisLinkIfNecessary();
    //});
    //if (!isLoadingAdmin) {
    //    vehicleAxle2FrontToAxle1RearValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLVEHICLELENGTH, rigOps.getOverallVehicleLengthLegal);
    //    vehicleAxle2FrontToAxle1RearValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.REAROVERHANGTOTAL);
    //}        
    //vehicleAxle2FrontToAxle1RearValidationObject.addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES);

    //vehicleAxle2FrontToAxle1RearAttributeGroup.addValidation(vehicleAxle2FrontToAxle1RearValidationObject);

    addAttributeGroup(vehicleAxle2FrontToAxle1RearAttributeGroup);



    var vehicleAxle1RearToAxle2RearAttributeGroup = new AttributeObservableGroup(config.VALUE_TYPE.AXLE1REARTOAXLE2REAR, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getAxle1RearToAxle2Rear)
        .setter(getConfiguration().setAxle1RearToAxle2Rear)
        .visiblePermission(showAxle1RearToAxle2Rear)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setAxle1RearToAxle2RearOverride(val);
            },
            function () {
                return getOriginalOffer().getRig().getVehicle().getAxle1RearToAxle2Rear();
            },
            function () {
                return getConfiguration().getAxle1RearToAxle2RearOverride();
            },
            function () {
                var tempVehicle = getActiveOffer().getRig().getVehicle();
                var tempOriginalVehicle = getOriginalOffer().getRig().getVehicle();

                var delta = (tempVehicle.getRearAxleSpan(1, tempOriginalVehicle.getAxle1RearToAxle2Rear()) - tempVehicle.getRearAxleSpan()) / 2;
                var deltaAxle1RearToAxle2Rear = tempOriginalVehicle.getAxle1RearToAxle2Rear() - tempVehicle.getAxle1RearToAxle2Rear();
                handleAxleSpacingOverrideRemove(delta, deltaAxle1RearToAxle2Rear, tempVehicle, tempVehicle.getAxle1RearToAxle2Rear, config.AXLE_SECTIONS.AXLE_1_REAR_TO_AXLE_2_REAR);

            })
        .overridePermission(showIfStandardAndAdmin)
        .setAllowOpsWhileDragging(true);

    var vehicleAxle1RearToAxle2RearValidationObject = new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.AXLE1REARTOAXLE2REAR);

    vehicleAxle1RearToAxle2RearValidationObject.addOpBlock(function (newValue, oldValue, deltaVal) {

        var tempVehicle = getActiveOffer().getRig().getVehicle();
        handleRearMostProtrudingItemBehindCabToRearAxlesChange(deltaVal, tempVehicle.getAxle1RearToAxle2Rear, config.AXLE_SECTIONS.AXLE_1_REAR_TO_AXLE_2_REAR);
    }, function (newValue, oldValue, deltaVal) {

        var tempVehicle = getActiveOffer().getRig().getVehicle();
        //handleRearMostProtrudingItemBehindCabToRearAxlesChange(deltaVal, tempVehicle.getAxle1RearToAxle2Rear, config.AXLE_SECTIONS.AXLE_1_REAR_TO_AXLE_2_REAR);
        //tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() - deltaVal);
        handleRearMostProtrudingItemBehindCabToRearAxlesUndo(deltaVal, tempVehicle.getAxle2RearToAxle3Rear, config.AXLE_SECTIONS.AXLE_1_REAR_TO_AXLE_2_REAR);
    });
    vehicleAxle1RearToAxle2RearValidationObject.reverseOpUndoOrder();
    vehicleAxle1RearToAxle2RearValidationObject.addOpBlock(function (newValue, oldValue, deltaVal) {

        var tempVehicle = getActiveOffer().getRig().getVehicle();

        tempVehicle.setWheelbaseTheoretical(tempVehicle.getWheelbaseTheoretical());
        if (tempVehicle.getAxlesHolder().getAxle2Rear().isAdded() && tempVehicle.getAxlesHolder().getAxle3Rear()) {
            tempVehicle.setBreakpointForRearOverhangFromRearMostAxle(tempVehicle.getBreakpointForRearOverhangFromRearMostAxle() - deltaVal);
        }
        checkIfBodyAndBreakChassisLinkIfNecessary();

        handleWheelbaseSpecifiedChanges(tempVehicle);

        if (tempVehicle.getAxle1RearToAxle2RearOverride() === false) {
            tempVehicle.setAxle1RearToAxle2RearOverride(true);
        }

    });

    if (!isLoadingAdmin) {
        vehicleAxle1RearToAxle2RearValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLVEHICLELENGTH, rigOps.getOverallVehicleLengthLegal)
        vehicleAxle1RearToAxle2RearValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.REAROVERHANGTOTAL)
    }
    vehicleAxle1RearToAxle2RearValidationObject.addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES)
    vehicleAxle1RearToAxle2RearValidationObject.addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.WHEELBASESPECIFIED);

    vehicleAxle1RearToAxle2RearAttributeGroup.addValidation(vehicleAxle1RearToAxle2RearValidationObject)

    addAttributeGroup(vehicleAxle1RearToAxle2RearAttributeGroup);

    function handleRearMostProtrudingItemBehindCabToRearAxlesChange(deltaVal, axleSpacingFunc, axleSection) {


        var tempVehicle = getActiveOffer().getRig().getVehicle();
        //var specifiedWheelbaseAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.WHEELBASESPECIFIED);
        //var calculatedWheelbaseSpecified = rigOps.getWheelbaseSpecified();
        //var specifiedWheelbaseVal = specifiedWheelbaseAOG.value();



        //var wheelbaseDelta = calculatedWheelbaseSpecified - specifiedWheelbaseVal;
        //var deltaToApply = wheelbaseDelta;

        //if (specifiedWheelbaseAOG.override() === true) {
        //    tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() + deltaVal/2);
        //}
        //if (axleSpacingAttribute === config.VALUE_TYPE.AXLE1REARTOAXLE2REAR) {
        //    tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() + deltaVal);
        //} else {
        //    tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() + deltaVal / 2);
        //}
        //if (specifiedWheelbaseAOG.override() === false) {
        var delta = getRearAxleWheelbaseDelta(globals.user.getSpecifyWheelbaseAs(), axleSpacingFunc, deltaVal, axleSection);
        tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() + delta);
        //}
        //var tempVehicle = getActiveOffer().getRig().getVehicle();
        //var tempOriginalVehicle = getOriginalOffer().getRig().getVehicle();

        //var delta = (tempVehicle.getRearAxleSpan(1, tempOriginalVehicle.getAxle1RearToAxle2Rear()) - tempVehicle.getRearAxleSpan()) / 2;
        //var deltaAxle1RearToAxle2Rear = tempOriginalVehicle.getAxle1RearToAxle2Rear() - tempVehicle.getAxle1RearToAxle2Rear();
        //tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() + delta);
    }

    function handleRearMostProtrudingItemBehindCabToRearAxlesUndo(deltaVal, axleSpacingFunc, axleSection) {


        var tempVehicle = getActiveOffer().getRig().getVehicle();

        var delta = getRearAxleWheelbaseDelta(globals.user.getSpecifyWheelbaseAs(), axleSpacingFunc, deltaVal, axleSection);
        tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() - delta);

    }

    function handleAxleSpacingOverrideRemove(deltaForRearMostProtruding, deltaForAxleSpacing, tempVehicle, axleSpacingFunc, axleSection, addedRearSection) {
        var specifiedWheelbaseAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.WHEELBASESPECIFIED);
        var calculatedWheelbaseSpecified = rigOps.getWheelbaseSpecified();
        var specifiedWheelbaseVal = specifiedWheelbaseAOG.value.value;
        var specifyWheelbaseAs = globals.user.getSpecifyWheelbaseAs();

        var deltaToApply = 0;
        if (tempVehicle.getWheelbaseOverride() === true) {

            tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() - (calculatedWheelbaseSpecified - specifiedWheelbaseVal));
            var axle2FrontToAxle1Rear = tempVehicle.getAxle2FrontToAxle1Rear();
            deltaToApply = getRearAxleWheelbaseDelta(specifyWheelbaseAs, axleSpacingFunc, deltaForAxleSpacing, axleSection);
            tempVehicle.setAxle2FrontToAxle1Rear(axle2FrontToAxle1Rear - deltaToApply);

        } else {



            
            if (axleSection === config.AXLE_SECTIONS.AXLE_1_FRONT_TO_AXLE_2_FRONT) {
                if (specifyWheelbaseAs === config.SPECIFY_WHEELBASE_AS_OPTIONS.CENTRE_OF_FRONT_AXLES_TO_CENTRE_OF_REAR_AXLES) {
                    deltaToApply = deltaForAxleSpacing / 2;
                } else {
                    deltaToApply = deltaForAxleSpacing;
                }

            } else {


                deltaToApply = getRearAxleWheelbaseDelta(specifyWheelbaseAs, axleSpacingFunc, deltaForAxleSpacing, axleSection);

            }
            tempVehicle.setWheelbaseSpecified(calculatedWheelbaseSpecified + deltaToApply);


            tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() + deltaForRearMostProtruding);

            addedRearSection = rigOps.getIsAddedRearSection(axleSection);

            if (addedRearSection) {
                tempVehicle.setBreakpointForRearOverhangFromRearMostAxle(getOriginalOffer().getRig().getVehicle().getBreakpointForRearOverhangFromRearMostAxle() - rigOps.getAddedAxlesSpread() - deltaForAxleSpacing);
            }

        }
    }

    function getRearAxleWheelbaseDelta(specifyWheelbaseAs, axleSpacingFunc, deltaForAxleSpacing, axleSection) {
        var tempVehicle = getActiveOffer().getRig().getVehicle();

        var deltaToApply = 0;
        var axle1RearToCentreOfRearAxles;

        switch (specifyWheelbaseAs) {
            case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTER_OF_REAR_DRIVEN_AXLES:
                var axle1RearToCentreOfRearDrivenAxles = tempVehicle.getAxle1RearToCentreOfRearDrivenAxles();
                if (axle1RearToCentreOfRearDrivenAxles > 0) {
                    if (axle1RearToCentreOfRearDrivenAxles >= tempVehicle.getAxle1RearToStartOfAxleSection(axleSection) + axleSpacingFunc()) {
                        deltaToApply = deltaForAxleSpacing;
                    } else if (axle1RearToCentreOfRearDrivenAxles <= tempVehicle.getAxle1RearToStartOfAxleSection(axleSection)) {
                        deltaToApply = 0;
                    } else {
                        deltaToApply = deltaForAxleSpacing / 2;
                    }
                }
                break;
            case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTRE_OF_REAR_AXLES:
                axle1RearToCentreOfRearAxles = tempVehicle.getAxle1RearToCentreOfRearAxles();
                if (axle1RearToCentreOfRearAxles > 0) {
                    deltaToApply = deltaForAxleSpacing / 2;
                }
                break;
            case config.SPECIFY_WHEELBASE_AS_OPTIONS.CENTRE_OF_FRONT_AXLES_TO_CENTRE_OF_REAR_AXLES:
                axle1RearToCentreOfRearAxles = tempVehicle.getAxle1RearToCentreOfRearAxles();
                if (axle1RearToCentreOfRearAxles > 0) {
                    deltaToApply = deltaForAxleSpacing / 2;
                }
                break;
            case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_FIRST_REAR_DRIVEN_AXLE:
                var axle1RearToFirstRearDrivenAxle = tempVehicle.getAxle1RearToFirstRearDrivenAxle();
                if (axle1RearToFirstRearDrivenAxle > tempVehicle.getAxle1RearToStartOfAxleSection(axleSection)) {
                    deltaToApply = deltaForAxleSpacing;
                }
                break;
        }
        return deltaToApply;
    }





    var vehicleAxle2RearToAxle3RearAttributeGroup = new AttributeObservableGroup(config.VALUE_TYPE.AXLE2REARTOAXLE3REAR, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getAxle2RearToAxle3Rear)
        .setter(getConfiguration().setAxle2RearToAxle3Rear)
        .visiblePermission(showAxle2RearToAxle3Rear)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setAxle2RearToAxle3RearOverride(val);
            },
            function () {
                return getOriginalOffer().getRig().getVehicle().getAxle2RearToAxle3Rear();
            },
            function () {
                return getConfiguration().getAxle2RearToAxle3RearOverride();
            },
            function () {
                var tempVehicle = getActiveOffer().getRig().getVehicle();
                var tempOriginalVehicle = getOriginalOffer().getRig().getVehicle();

                var delta = (tempVehicle.getRearAxleSpan(2, tempOriginalVehicle.getAxle2RearToAxle3Rear()) - tempVehicle.getRearAxleSpan()) / 2;
                var deltaAxle2RearToAxle3Rear = tempOriginalVehicle.getAxle2RearToAxle3Rear() - tempVehicle.getAxle2RearToAxle3Rear();

                handleAxleSpacingOverrideRemove(delta, deltaAxle2RearToAxle3Rear, tempVehicle, tempVehicle.getAxle2RearToAxle3Rear, config.AXLE_SECTIONS.AXLE_2_REAR_TO_AXLE_3_REAR);

            })
        .overridePermission(showIfStandardAndAdmin)
        .setAllowOpsWhileDragging(true);

    var vehicleAxle2RearToAxle3RearValidationObject = new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.AXLE2REARTOAXLE3REAR);
    vehicleAxle2RearToAxle3RearValidationObject.addOpBlock(function (newValue, oldValue, deltaVal) {
        var tempVehicle = getActiveOffer().getRig().getVehicle();
        handleRearMostProtrudingItemBehindCabToRearAxlesChange(deltaVal, tempVehicle.getAxle2RearToAxle3Rear, config.AXLE_SECTIONS.AXLE_2_REAR_TO_AXLE_3_REAR);
    }, function (newValue, oldValue, deltaVal) {

        var tempVehicle = getActiveOffer().getRig().getVehicle();
        //handleRearMostProtrudingItemBehindCabToRearAxlesChange(deltaVal, tempVehicle.getAxle1RearToAxle2Rear, config.AXLE_SECTIONS.AXLE_1_REAR_TO_AXLE_2_REAR);
        //tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() - deltaVal);
        handleRearMostProtrudingItemBehindCabToRearAxlesUndo(deltaVal, tempVehicle.getAxle2RearToAxle3Rear, config.AXLE_SECTIONS.AXLE_2_REAR_TO_AXLE_3_REAR);
    })
    vehicleAxle2RearToAxle3RearValidationObject.reverseOpUndoOrder();
    vehicleAxle2RearToAxle3RearValidationObject.addOpBlock(function (newValue, oldValue, deltaVal) {

        var tempVehicle = getActiveOffer().getRig().getVehicle();

        tempVehicle.setWheelbaseTheoretical(tempVehicle.getWheelbaseTheoretical());
        if (tempVehicle.getAxlesHolder().getAxle3Rear().isAdded() && tempVehicle.getAxlesHolder().getAxle4Rear()) {
            tempVehicle.setBreakpointForRearOverhangFromRearMostAxle(tempVehicle.getBreakpointForRearOverhangFromRearMostAxle() - deltaVal);
        }
        checkIfBodyAndBreakChassisLinkIfNecessary();
        handleWheelbaseSpecifiedChanges(tempVehicle);

        if (tempVehicle.getAxle2RearToAxle3RearOverride() === false) {
            tempVehicle.setAxle2RearToAxle3RearOverride(true);
        }
    })
    if (!isLoadingAdmin) {
        vehicleAxle2RearToAxle3RearValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLVEHICLELENGTH, rigOps.getOverallVehicleLengthLegal);
        vehicleAxle2RearToAxle3RearValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.REAROVERHANGTOTAL);
    }

    vehicleAxle2RearToAxle3RearValidationObject.addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES);
    vehicleAxle2RearToAxle3RearValidationObject.addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.WHEELBASESPECIFIED);


    vehicleAxle2RearToAxle3RearAttributeGroup.addValidation(vehicleAxle2RearToAxle3RearValidationObject);
    addAttributeGroup(vehicleAxle2RearToAxle3RearAttributeGroup);



    var vehicleAxle3RearToAxle4RearAttributeGroup = new AttributeObservableGroup(config.VALUE_TYPE.AXLE3REARTOAXLE4REAR, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getAxle3RearToAxle4Rear)
        .setter(getConfiguration().setAxle3RearToAxle4Rear)
        .visiblePermission(showAxle3RearToAxle4Rear)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setAxle3RearToAxle4RearOverride(val);
            },
            function () {
                return getOriginalOffer().getRig().getVehicle().getAxle3RearToAxle4Rear();
            },
            function () {
                return getConfiguration().getAxle3RearToAxle4RearOverride();
            },
            function () {
                var tempVehicle = getActiveOffer().getRig().getVehicle();
                var tempOriginalVehicle = getOriginalOffer().getRig().getVehicle();
                //var delta = (tempOriginalVehicle.getRearAxleSpan() - tempVehicle.getRearAxleSpan()) / 2;
                var delta = (tempVehicle.getRearAxleSpan(3, tempOriginalVehicle.getAxle3RearToAxle4Rear()) - tempVehicle.getRearAxleSpan()) / 2;
                //tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() + delta);
                var deltaAxle3RearToAxle4Rear = tempOriginalVehicle.getAxle3RearToAxle4Rear() - tempVehicle.getAxle3RearToAxle4Rear();


                handleAxleSpacingOverrideRemove(delta, deltaAxle3RearToAxle4Rear, tempVehicle, tempVehicle.getAxle3RearToAxle4Rear, config.AXLE_SECTIONS.AXLE_3_REAR_TO_AXLE_4_REAR);
            })
        .overridePermission(showIfStandardAndAdmin)
        .setAllowOpsWhileDragging(true);

    var vehicleAxle3RearToAxle4RearValidationObject = new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.AXLE3REARTOAXLE4REAR);
    vehicleAxle3RearToAxle4RearValidationObject.addOpBlock(function (newValue, oldValue, deltaVal) {
        var tempVehicle = getActiveOffer().getRig().getVehicle();
        handleRearMostProtrudingItemBehindCabToRearAxlesChange(deltaVal, tempVehicle.getAxle3RearToAxle4Rear, config.AXLE_SECTIONS.AXLE_3_REAR_TO_AXLE_4_REAR);
    }, function (newValue, oldValue, deltaVal) {

        var tempVehicle = getActiveOffer().getRig().getVehicle();
        //handleRearMostProtrudingItemBehindCabToRearAxlesChange(deltaVal, tempVehicle.getAxle1RearToAxle2Rear, config.AXLE_SECTIONS.AXLE_1_REAR_TO_AXLE_2_REAR);
        //tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() - deltaVal);
        handleRearMostProtrudingItemBehindCabToRearAxlesUndo(deltaVal, tempVehicle.getAxle2RearToAxle3Rear, config.AXLE_SECTIONS.AXLE_3_REAR_TO_AXLE_4_REAR);
    })
    vehicleAxle3RearToAxle4RearValidationObject.reverseOpUndoOrder();
    vehicleAxle3RearToAxle4RearValidationObject.addOpBlock(function (newValue, oldValue, deltaVal) {

        var tempVehicle = getActiveOffer().getRig().getVehicle();

        tempVehicle.setWheelbaseTheoretical(tempVehicle.getWheelbaseTheoretical());
        if (tempVehicle.getAxlesHolder().getAxle4Rear().isAdded()) {
            tempVehicle.setBreakpointForRearOverhangFromRearMostAxle(tempVehicle.getBreakpointForRearOverhangFromRearMostAxle() - deltaVal);
        }
        checkIfBodyAndBreakChassisLinkIfNecessary();
        handleWheelbaseSpecifiedChanges(tempVehicle);

        if (tempVehicle.getAxle3RearToAxle4RearOverride() === false) {
            tempVehicle.setAxle3RearToAxle4RearOverride(true);
        }

    });
    if (!isLoadingAdmin) {
        vehicleAxle3RearToAxle4RearValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLVEHICLELENGTH, rigOps.getOverallVehicleLengthLegal);
        vehicleAxle3RearToAxle4RearValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.REAROVERHANGTOTAL);
    }
    vehicleAxle3RearToAxle4RearValidationObject.addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES);
    vehicleAxle3RearToAxle4RearValidationObject.addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.WHEELBASESPECIFIED);

    vehicleAxle3RearToAxle4RearAttributeGroup.addValidation(vehicleAxle3RearToAxle4RearValidationObject);

    addAttributeGroup(vehicleAxle3RearToAxle4RearAttributeGroup);

    //****************************
    if (getConfiguration().getWheelbaseOverride() === false || getConfiguration().getWheelbaseSpecified() === undefined) {
        getConfiguration().setWheelbaseSpecified(rigOps.getWheelbaseSpecified());
        getOriginalOffer().getRig().getVehicle().setWheelbaseSpecified(rigOps.getOriginalWheelbaseSpecified());
    }

    var vehicleWheelbaseSpecifiedAttributeGroup = new AttributeObservableGroup(config.VALUE_TYPE.WHEELBASESPECIFIED, config.INPUT_TYPE.UPDOWNBOX)
        .getter(rigOps.getVehicleWheelbaseSpecified)
        .setter(function (val) {
            getConfiguration().setWheelbaseSpecified(val);
        })
        .visiblePermission(function () {
            return true;
        })
        .readOnlyPermission(function () {
            return !isSpecifyChassisLengthAsOptionWheelbase();
        })
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setWheelbaseOverride(val);
            },
            function () {
                return rigOps.getVehicleWheelbaseSpecified();
            },
            function () {
                return getConfiguration().getWheelbaseOverride();
            },
            function (delta) {
                var tempVehicle = getActiveOffer().getRig().getVehicle();
                var tempOriginalVehicle = getOriginalOffer().getRig().getVehicle();

                var deltaAxle2FrontToAxle1Rear = tempOriginalVehicle.getAxle2FrontToAxle1Rear() - tempVehicle.getAxle2FrontToAxle1Rear();
                tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() + deltaAxle2FrontToAxle1Rear);

                tempVehicle.setAxle2FrontToAxle1Rear(tempOriginalVehicle.getAxle2FrontToAxle1Rear());
                if (tempVehicle.getAccessoryHolder().getHitch() !== null) {
                    tempVehicle.getAccessoryHolder().getHitch().setHorizontalGap(tempVehicle.getWheelbaseTheoreticalEnd() + tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle());
                }

            })
        .overridePermission(function () {
            return true;
        })
        .setAllowOpsWhileDragging(true);

    var vehicleWheelbaseSpecifiedValidationObject = new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.WHEELBASESPECIFIED);
    vehicleWheelbaseSpecifiedValidationObject.addPrevalidationValFunc('BACKEND.' + config.VALUE_TYPE.CABPROTRUSION, rigOps.getCabProtrusion); // User Story 17565
    vehicleWheelbaseSpecifiedValidationObject.addOp(config.VALIDATION_OP_TYPE.INC_BY_DELTA, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES);
    vehicleWheelbaseSpecifiedValidationObject.addOp(config.VALIDATION_OP_TYPE.INC_BY_DELTA, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.AXLE2FRONTTOAXLE1REAR);
    vehicleWheelbaseSpecifiedValidationObject.reverseOpUndoOrder();
    vehicleWheelbaseSpecifiedValidationObject.addOpBlock(function (newValue, oldValue, deltaVal) {
        var tempVehicle = getActiveOffer().getRig().getVehicle();
        tempVehicle.setWheelbaseTheoretical(tempVehicle.getWheelbaseTheoretical());
        if (tempVehicle.getAccessoryHolder().getHitch() !== null) {
            tempVehicle.getAccessoryHolder().getHitch().setHorizontalGap(tempVehicle.getWheelbaseTheoreticalEnd() + tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle()); 
        }

        checkIfBodyAndBreakChassisLinkIfNecessary();
        if (tempVehicle.getWheelbaseOverride() === false) {
            tempVehicle.setWheelbaseOverride(true);
        }
        if (globals.user.getSpecifyChassisLengthAs() === config.SPECIFY_CHASSIS_LENGTH_AS_OPTIONS.CA) {
            if (tempVehicle.getCabToAxlesOverride() === false) {
                tempVehicle.setCabToAxlesOverride(true);
            }
        }
    });
    if (!isLoadingAdmin) {
        vehicleWheelbaseSpecifiedValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLVEHICLELENGTH, rigOps.getOverallVehicleLengthLegal);
        vehicleWheelbaseSpecifiedValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.REAROVERHANGTOTAL);
    }
    vehicleWheelbaseSpecifiedValidationObject.addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES);

    vehicleWheelbaseSpecifiedAttributeGroup.addValidation(vehicleWheelbaseSpecifiedValidationObject);

    addAttributeGroup(vehicleWheelbaseSpecifiedAttributeGroup);


    //*****************************

    var vehicleCabToAxlesAttributeGroup = new AttributeObservableGroup(config.VALUE_TYPE.CABTOAXLES, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return rigOps.getCabToAxles();
        })
        .setter(function (val) {
            var newWheelbaseSpecified = val - rigOps.getAppropriateAxle1FrontToAxle2FrontAddition() + getConfiguration().getAxle1FrontToBackOfCab();
            getActiveOffer().getRig().getVehicle().setWheelbaseSpecified(newWheelbaseSpecified);
        })
        //.addSubscription(function (val) { 
        //    if (isSpecifyChassisLengthAsOptionCA() === true) {
        //        if (typeof val === 'number' && isNaN(val) === false) {
        //            // Update Wheelbase Specified
        //            // return tempVehicle.getWheelbaseSpecified() + evaluateLegislation(legislationVals.variableTypes.ComVehAxle1FrontToWheelbaseLegislationStart) - tempVehicle.getAxle1FrontToBackOfCab();
        //            var wheelbaseSpecifiedAttrGroup = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.WHEELBASESPECIFIED),
        //                newWheelbaseSpecified = val - rigOps.getAppropriateAxle1FrontToAxle2FrontAddition() + getConfiguration().getAxle1FrontToBackOfCab();
        //            getConfiguration().setWheelbaseSpecified(newWheelbaseSpecified);
        //            wheelbaseSpecifiedAttrGroup.value(newWheelbaseSpecified);
        //        }
        //    }
        //})
        .visiblePermission(function () {
            return true;
        })
        .readOnlyPermission(function () {
            return isSpecifyChassisLengthAsOptionWheelbase();
        })
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setCabToAxlesOverride(val);
            },
            function () {
                return rigOps.getCabToAxles();
            },
            function () {
                return getConfiguration().getCabToAxlesOverride();
            },
            function (delta) {
                var tempVehicle = getActiveOffer().getRig().getVehicle();
                var tempOriginalVehicle = getOriginalOffer().getRig().getVehicle();

                var deltaAxle2FrontToAxle1Rear = tempOriginalVehicle.getAxle2FrontToAxle1Rear() - tempVehicle.getAxle2FrontToAxle1Rear();
                tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() + deltaAxle2FrontToAxle1Rear);

                tempVehicle.setAxle2FrontToAxle1Rear(tempOriginalVehicle.getAxle2FrontToAxle1Rear());

                tempVehicle.setWheelbaseSpecified(rigOps.getWheelbaseSpecified());


            })
        .overridePermission(function () {
            return true;
        });

    var vehicleCabToAxlesValidationObject = new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CABTOAXLES)
        .addPrevalidationValFunc('BACKEND.' + config.VALUE_TYPE.CABPROTRUSION, rigOps.getCabProtrusion) // User Story 17565
        .addOp(config.VALIDATION_OP_TYPE.INC_BY_DELTA, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES)
        .addOp(config.VALIDATION_OP_TYPE.INC_BY_DELTA, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.AXLE2FRONTTOAXLE1REAR)
        .reverseOpUndoOrder()
        .addOpBlock(function (newValue, oldValue, deltaVal) {
            var tempVehicle = getActiveOffer().getRig().getVehicle();
            tempVehicle.setWheelbaseTheoretical(tempVehicle.getWheelbaseTheoretical());
            if (tempVehicle.getAccessoryHolder().getHitch() !== null) {
                tempVehicle.getAccessoryHolder().getHitch().setHorizontalGap(tempVehicle.getWheelbaseTheoreticalEnd());
            }

            checkIfBodyAndBreakChassisLinkIfNecessary();
            if (tempVehicle.getWheelbaseOverride() === false) {
                tempVehicle.setWheelbaseOverride(true);
            }
            if (tempVehicle.getCabToAxlesOverride() === false) {
                tempVehicle.setCabToAxlesOverride(true);
            }
            //handleWheelbaseSpecifiedChanges(tempVehicle);
        });
    if (!isLoadingAdmin) {
        vehicleCabToAxlesValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLVEHICLELENGTH, rigOps.getOverallVehicleLengthLegal);
        vehicleCabToAxlesValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.REAROVERHANGTOTAL);
    }

    vehicleCabToAxlesValidationObject.addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES);

    vehicleCabToAxlesAttributeGroup.addValidation(vehicleCabToAxlesValidationObject);

    addAttributeGroup(vehicleCabToAxlesAttributeGroup);

    //*****************************
    var vehicleRearOverhangAttributeGroup = new AttributeObservableGroup(config.VALUE_TYPE.REAROVERHANG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getRearOverhang)
        .setter(getConfiguration().setRearOverhang)
        .visiblePermission(function () {
            return !globals.user.showAfterframe();
        });

    var vehicleRearOverhangValidationObject = new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REAROVERHANG);
    vehicleRearOverhangValidationObject.addOpBlock(function (newValue, oldValue, deltaVal) {
        checkIfBodyAndBreakChassisLinkIfNecessary();
    });
    if (!isLoadingAdmin) {
        vehicleRearOverhangValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLVEHICLELENGTH, rigOps.getOverallVehicleLengthLegal);
        vehicleRearOverhangValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.REAROVERHANGTOTAL);
    }
    vehicleRearOverhangAttributeGroup.addValidation(vehicleRearOverhangValidationObject);

    addAttributeGroup(vehicleRearOverhangAttributeGroup);



    var vehicleAfterFrameAttributeGroup = new AttributeObservableGroup(config.VALUE_TYPE.AFTERFRAME, config.INPUT_TYPE.UPDOWNBOX)
        .getter(rigOps.getAfterframe)
        .visiblePermission(function () {
            return globals.user.showAfterframe();
        });
        // .setter(getConfiguration().setRearOverhang);

    var vehicleAfterFrameValidationObject = new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.AFTERFRAME);
    vehicleAfterFrameValidationObject.addOpBlock(function (newValue, oldValue, deltaVal) {
        getConfiguration().setRearOverhang(getConfiguration().getRearOverhang() + deltaVal);
        checkIfBodyAndBreakChassisLinkIfNecessary();
    });
    if (!isLoadingAdmin) {
        vehicleAfterFrameValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLVEHICLELENGTH, rigOps.getOverallVehicleLengthLegal);
        vehicleAfterFrameValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.REAROVERHANGTOTAL);
    }
    vehicleAfterFrameAttributeGroup.addValidation(vehicleAfterFrameValidationObject);

    addAttributeGroup(vehicleAfterFrameAttributeGroup);



    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.REARPROTRUSION, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getRearProtrusion)
        .setter(getConfiguration().setRearProtrusion)
        .visiblePermission(isNotStandardVehicle)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REARPROTRUSION)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.GAPBETWEENVEHICLEANDTRAILER, rigOps.getVehicleAndDrawbarGapBetweenVehicleAndTrailer)
            .addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLVEHICLELENGTH, rigOps.getOverallVehicleLengthLegal)
            .addBackEndAttributeValidationStep(config.VALUE_TYPE.REAROVERHANGTOTAL)
        ));

    //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.WHEELBASETHEORETICAL, config.INPUT_TYPE.UPDOWNBOX)
    //    .getter(getConfiguration().getWheelbaseTheoretical)
    //    .setter(getConfiguration().setWheelbaseTheoretical)
    //    .addOverrideMechanism(getConfiguration,
    //        function (val) { getConfiguration().setWheelbaseTheoreticalOverride(val); },
    //        function () {
    //            return getActiveOffer().getConfiguration().getWheelbaseTheoretical();
    //        },
    //        function () { return getConfiguration().getWheelbaseTheoreticalOverride(); })
    //    .overridePermission(showUnlessStandardAndNotAdmin)
    //    .visiblePermission(function () {
    //        return !(vehicleAxleLayout.value === '4X2' && getConfiguration().getAxle1RearToAxle2Rear() === 0);
    //    })
    //    .readOnlyPermission(readOnlyIfStandardAndNotAdmin)
    //    .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.WHEELBASETHEORETICAL)));

    //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.AXLE1FRONTTOWHEELBASETHEORETICALSTART, config.INPUT_TYPE.UPDOWNBOX)
    //    .getter(getConfiguration().getAxle1FrontToWheelbaseTheoreticalStart)
    //    .setter(getConfiguration().setAxle1FrontToWheelbaseTheoreticalStart)
    //    .addOverrideMechanism(getConfiguration,
    //        function (val) { getConfiguration().setAxle1FrontToWheelbaseTheoreticalStartOverride(val); },
    //        function () { return getConfiguration().getAxle1FrontToWheelbaseTheoreticalStart(); },
    //        function () { return getConfiguration().getAxle1FrontToWheelbaseTheoreticalStartOverride(); })
    //    .overridePermission(showAxle1FrontToWheelbaseTheoreticalStart)
    //    .visiblePermission(showAxle1FrontToWheelbaseTheoreticalStart)
    //    .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.AXLE1FRONTTOWHEELBASETHEORETICALSTART)));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FIFTHWHEELOFFSET, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getFifthWheelOffset)
        .setter(getConfiguration().setFifthWheelOffset)
        .visiblePermission(isFifthWheelIncludedOnVehicle)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.FIFTHWHEELOFFSET)));

    //CAB
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BUMPERTOBACKOFCAB, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getBumperToBackOfCab)
        .setter(getConfiguration().setBumperToBackOfCab)
        .visiblePermission(showCabAttributesNotCompleteVehicle)
        .readOnlyPermission(isSpecifyCabDimensionsAc)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.BUMPERTOBACKOFCAB)
            .addOp(config.VALIDATION_OP_TYPE.DEC_BY_DELTA, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES)
            .addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if(globals.user.getSpecifyBodyPositionAs() === config.SPECIFY_POSITION_AS_OPTIONS.FROM_FRONTMOST_AXLE) {
                    rigOps.calculateFromAxle1FrontForRelevantAccessories();
                }
            })
        ));
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.AXLE1FRONTTOBACKOFCAB, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getAxle1FrontToBackOfCab)
        .setter(function (val) {
            var newBbcValue = val + getConfiguration().getFrontOverhang();
            getActiveOffer().getRig().getVehicle().setBumperToBackOfCab(newBbcValue);
        })
        //.addSubscription(function(val){
        //    // Update BBC value
        //    // Bumper to Back of Cab - Front Overhang
        //    var newBbcValue = val + getConfiguration().getFrontOverhang(),
        //        bbcAttrGroup = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.BUMPERTOBACKOFCAB);
        //    getConfiguration().setBumperToBackOfCab(newBbcValue);
        //    bbcAttrGroup.value(newBbcValue);
        //})
        .visiblePermission(showCabAttributesNotCompleteVehicle)
        .readOnlyPermission(isSpecifyCabDimensionsBbc)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.AXLE1FRONTTOBACKOFCAB)
            .addOp(config.VALIDATION_OP_TYPE.DEC_BY_DELTA, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES)
            .addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if(globals.user.getSpecifyBodyPositionAs() === config.SPECIFY_POSITION_AS_OPTIONS.FROM_FRONTMOST_AXLE) {
                    rigOps.calculateFromAxle1FrontForRelevantAccessories();
                }
            })
        ));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getRearMostProtrudingItemBehindCabToRearAxles)
        .setter(getConfiguration().setRearMostProtrudingItemBehindCabToRearAxles)
        .visiblePermission(showCabAttributesNotCompleteVehicle)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES)));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CABGAPRECOMMENDEDMIN, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getCabGapRecommendedMin)
        .setter(getConfiguration().setCabGapRecommendedMin)
        .visiblePermission(showCabAttributesNotCompleteVehicle)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CABGAPRECOMMENDEDMIN)));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CABTILT, config.INPUT_TYPE.CHECKBOX)
        .visiblePermission(showCabTiltSection)
        .setVal(getConfiguration().getCabTilt())
        .addSubscription(function (val) {
            getConfiguration().setCabTilt(val);
        }));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CABHINGEPOINTFROMBUMPER, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getHingePointFromBumper)
        .setter(getConfiguration().setHingePointFromBumper)
        .visiblePermission(showCabAttributes)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CABHINGEPOINTFROMBUMPER)));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CABHINGEPOINTFROMGROUND, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getHingePointFromGround)
        .setter(getConfiguration().setHingePointFromGround)
        .visiblePermission(showCabAttributes)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CABHINGEPOINTFROMGROUND)));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CABTILTRADIUS, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getCabTiltRadius)
        .setter(getConfiguration().setCabTiltRadius)
        .visiblePermission(showCabAttributes)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CABTILTRADIUS)));

    //HEIGHT
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BUMPERTOSTARTOFBODYINTERNAL, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getBumperToStartOfBodyInternal)
        .setter(getConfiguration().setBumperToStartOfBodyInternal)
        .visiblePermission(isCompleteTemplateVehicle)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.BUMPERTOSTARTOFBODYINTERNAL)
            .addOp(config.VALIDATION_OP_TYPE.DEC_BY_DELTA, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.BODYLENGTHINTERNAL)
            .addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.BODYLENGTHINTERNAL)
        ));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYLENGTHINTERNAL, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getBodyLengthInternal)
        .setter(getConfiguration().setBodyLengthInternal)
        .visiblePermission(isCompleteTemplateVehicle)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.BODYLENGTHINTERNAL)));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYFLOORHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getFloorHeight)
        .setter(getConfiguration().setFloorHeight)
        .visiblePermission(isCompleteTemplateVehicle)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.BODYFLOORHEIGHT)));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYHEIGHTINTERNAL, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getBodyHeightInternal)
        .setter(getConfiguration().setBodyHeightInternal)
        .visiblePermission(isCompleteTemplateVehicle)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.BODYHEIGHTINTERNAL)));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CABHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getCabHeight)
        .setter(getConfiguration().setCabHeight)
        .visiblePermission(isTemplateVehicle)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CABHEIGHT)));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CHASSISHEIGHTFRONT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getChassisHeightFront)
        .setter(getConfiguration().setChassisHeightFront)
        .visiblePermission(showChassisHeightFront)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CHASSISHEIGHTFRONT)));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CHASSISHEIGHTREAR, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getChassisHeightRear)
        .setter(getConfiguration().setChassisHeightRear)
        .visiblePermission(showChassisHeightFront)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CHASSISHEIGHTREAR)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if (getConfiguration().getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                    getConfiguration().setChassisHeightFront(newValue);
                }
            })
            .reverseOpUndoOrder()
        ));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FIFTHWHEELHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getFifthWheelHeightFromGround)
        .setter(getConfiguration().setFifthWheelHeightFromGround)
        .visiblePermission(showFifthWheelHeight)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.FIFTHWHEELHEIGHT)));

    //WIDTH
    let cabWidthAOG = new AttributeObservableGroup(config.VALUE_TYPE.CABWIDTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getCabWidth)
        .setter(getConfiguration().setCabWidth)
        .visiblePermission(isTemplateVehicleOrIsStandardVehicleAndIsLoadingAdmin)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setCabWidthOverride(val);
            },
            function () {
                return getOriginalOffer().getRig().getVehicle().getCabWidth();
            },
            function () {
                return getConfiguration().getCabWidthOverride();
            })
        .overridePermission(isStandardVehicleAndIsLoadingAdmin)
        .readOnlyPermission(isStandardVehicleAndHasTopViewGraphic)
        

    let cabWidthAOGValidation  = new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CABWIDTH);
    if(!globals.user.isLoadingAdministrator()) {
        cabWidthAOGValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.COMBINATIONOVERALLWIDTH, rigOps.getCombinationOverallWidth);
    }
    cabWidthAOG.addValidation(cabWidthAOGValidation);
    addAttributeGroup(cabWidthAOG);

    let rearWidthAOG = new AttributeObservableGroup(config.VALUE_TYPE.REARWIDTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getRearWidth)
        .setter(getConfiguration().setRearWidth)
        .visiblePermission(isTemplateVehicleOrIsStandardVehicleAndIsLoadingAdmin)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setRearWidthOverride(val);
            },
            function () {
                return getOriginalOffer().getRig().getVehicle().getRearWidth();
            },
            function () {
                return getConfiguration().getRearWidthOverride();
            })
        .overridePermission(isStandardVehicleAndIsLoadingAdmin)
        .readOnlyPermission(isStandardVehicleAndHasTopViewGraphic);

    let rearWidthAOGValidation = new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REARWIDTH)
        .addOpBlock(function (newValue, oldValue, deltaVal) {
            var tempVehicle = getActiveOffer().getRig().getVehicle();
            var tempVehicleAccessoryHolder = tempVehicle.getAccessoryHolder();
            var tempFifthWheel = tempVehicleAccessoryHolder.getFifthWheel();
            if (tempFifthWheel !== null) {
                tempFifthWheel.setFromLeftOfVehicleRearWidth(tempVehicle.getRearWidth() / 2);
            }
            var tempHitch = tempVehicleAccessoryHolder.getHitch();
            if (tempHitch !== null) {
                tempHitch.setFromLeftOfVehicleRearWidth(tempVehicle.getRearWidth() / 2);
            }
            
            rigOps.updateFromLeftOfChassisRearWidthForAllChassisItems(deltaVal, path);
            rigOps.updateFromLeftOfBodyWidthExternalForAllChassisItems(deltaVal, path);
        });
    
    if(!globals.user.isLoadingAdministrator()) {
        rearWidthAOGValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.COMBINATIONOVERALLWIDTH, rigOps.getCombinationOverallWidth);
    }

    rearWidthAOG.addValidation(rearWidthAOGValidation);
    addAttributeGroup(rearWidthAOG);

    //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.OVERALLWIDTH, config.INPUT_TYPE.UPDOWNBOX)
    //    .getter(getConfiguration().getOverallWidth)
    //    //.setter(getConfiguration().setOverallWidth)
    //    //.visiblePermission(isTemplateVehicleOrIsStandardVehicleAndIsLoadingAdmin)
    //    .visible(false)
    //    .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.OVERALLWIDTH)));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRACKWIDTHFRONT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getTrackWidthFront)
        .setter(getConfiguration().setTrackWidthFront)
        .visiblePermission(isTemplateVehicleOrIsStandardVehicleAndIsLoadingAdmin)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setTrackWidthFrontOverride(val);
            },
            function () {
                return getOriginalOffer().getRig().getVehicle().getTrackWidthFront();
            },
            function () {
                return getConfiguration().getTrackWidthFrontOverride();
            })
        .overridePermission(isStandardVehicleAndIsLoadingAdmin)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TRACKWIDTHFRONT)));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRACKWIDTHREAR, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getTrackWidthRear)
        .setter(getConfiguration().setTrackWidthRear)
        .visiblePermission(isTemplateVehicleOrIsStandardVehicleAndIsLoadingAdmin)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setTrackWidthRearOverride(val);
            },
            function () {
                return getOriginalOffer().getRig().getVehicle().getTrackWidthRear();
            },
            function () {
                return getConfiguration().getTrackWidthRearOverride();
            })
        .overridePermission(isStandardVehicleAndIsLoadingAdmin)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TRACKWIDTHREAR)
            //.addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CHASSISWIDTH)
        ));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CHASSISWIDTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getChassisWidth)
        .setter(getConfiguration().setChassisWidth)
        .visiblePermission(isTemplateVehicleOrIsStandardVehicleAndIsLoadingAdmin)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setChassisWidthOverride(val);
            },
            function () {
                return getOriginalOffer().getRig().getVehicle().getChassisWidth();
            },
            function () {
                return getConfiguration().getChassisWidthOverride();
            })
        .overridePermission(isStandardVehicleAndIsLoadingAdmin)
        .readOnlyPermission(isStandardVehicleAndHasTopViewGraphic)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CHASSISWIDTH)
            //.addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TRACKWIDTHREAR)
        ));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYWIDTHINTERNAL, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getLoadCompartmentWidth)
        .setter(getConfiguration().setLoadCompartmentWidth)
        .visiblePermission(isCompleteTemplateVehicle)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.BODYWIDTHINTERNAL)));

    function isStandardVehicleAndHasTopViewGraphic() {

        var tempVehicle = getActiveOffer().getRig().getVehicle();

        var xmlDoc = tempVehicle.getDecompressedVehicleTopViewDrawing();
        var vehicleHasTopViewGraphic;
        if (xmlDoc === null || (xmlDoc.documentElement && xmlDoc.documentElement.childElementCount <= 1)) {
            vehicleHasTopViewGraphic = false;
        } else {
            vehicleHasTopViewGraphic = true;
        }

        return tempVehicle.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && vehicleHasTopViewGraphic === true;
    }

    //Turning Ability
    var turningAbilitySpecifiedAsOption = getTurningAbilitySpecifiedAsOption();

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TURNINGABILITYSPECIFIEDAS, config.INPUT_TYPE.SELECT)
        .addSubscription(function (val) {
            getConfiguration().setTurningAbilitySpecifiedAs(val);
            turningCircleLockedToSteeringAngleInner = false;
            var valToUse = 0;
            if (val === config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER) {
                valToUse = rigOps.getVehicleSteeringAngleInner();
            } else if (val === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB) {
                valToUse = rigOps.getVehicleTurningRadiusCurbToCurb();
            } else if (val === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL) {
                valToUse = rigOps.getVehicleTurningRadiusWallToWall();
            }

            rigOps.updateTurningCircleCalculatedVals(val, valToUse);
            

        })
        .visiblePermission(function () {
            return isTemplateVehicleOrIsStandardVehicleAndIsLoadingAdmin() && !rigOps.getVehicleWheelbaseTurningChanged() && !turningCircleLockedToSteeringAngleInner;
        })
        //.readOnlyPermission(function () {
        //    return rigOps.getVehicleWheelbaseTurningChanged();
        //})
        //.setVal(turningAbilitySpecifiedAsOption)
        .optionGetter(getTurningAbilitySpecifiedAsOption));


    if (!isStandardVehicleAndIsLoadingAdmin()) {
        getConfiguration().setSteeringAngleInnerOverride(true);
        getConfiguration().setTurningRadiusWallToWallOverride(true);
        getConfiguration().setTurningRadiusCurbToCurbOverride(true);
    }

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.STEERINGANGLEINNER, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.DEGREES)
        .getter(rigOps.getVehicleSteeringAngleInner)
        .setter(getConfiguration().setSteeringAngleInner)
        .visiblePermission(showSteeringAngleInner)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                //getConfiguration().setSteeringAngleInnerOverride(val);
                //getConfiguration().setTurningRadiusWallToWallOverride(val);
                //getConfiguration().setTurningRadiusCurbToCurbOverride(val);

                if (val === false) {
                    if (!rigOps.getVehicleWheelbaseTurningChanged()) {
                        rigOps.updateTurningCircleCalculatedVals();
                    }
                    
                }
                handleTurningCircleOverrideChange(config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER, val);
            },
            function () {
                if (!rigOps.getVehicleWheelbaseTurningChanged()) {
                    return getOriginalOffer().getRig().getVehicle().getSteeringAngleInner();
                } else {
                    return getActiveOffer().getRig().getVehicle().getSteeringAngleInner();
                }
            },
            function () {
                return getConfiguration().getSteeringAngleInnerOverride();
            })
        .overridePermission(isStandardVehicleAndIsLoadingAdmin)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.STEERINGANGLEINNER)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                rigOps.updateTurningCircleCalculatedVals(config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER, newValue);
                updateOriginalTurningCircleValuesIfNecessary();
            })
            .addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TURNINGRADIUSCURBTOCURB)
            .addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TURNINGRADIUSWALLTOWALL)
        ));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TURNINGRADIUSCURBTOCURB, config.INPUT_TYPE.UPDOWNBOX)
        .getter(rigOps.getVehicleTurningRadiusCurbToCurb)
        .setter(getConfiguration().setTurningRadiusCurbToCurb)
        .visiblePermission(showTurningRadiusCurbToCurb)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                //getConfiguration().setTurningRadiusCurbToCurbOverride(val);
                //getConfiguration().setTurningRadiusWallToWallOverride(val);
                //getConfiguration().setSteeringAngleInnerOverride(val);

                if (val === false) {
                    rigOps.updateTurningCircleCalculatedVals();
                }
                handleTurningCircleOverrideChange(config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB, val);
            },
            function () {
                return getOriginalOffer().getRig().getVehicle().getTurningRadiusCurbToCurb();
            },
            function () {
                return getConfiguration().getTurningRadiusCurbToCurbOverride();
            })
        .overridePermission(isStandardVehicleAndIsLoadingAdmin)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TURNINGRADIUSCURBTOCURB)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                rigOps.updateTurningCircleCalculatedVals(config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB, newValue);
                updateOriginalTurningCircleValuesIfNecessary();
            })
            .addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TURNINGRADIUSWALLTOWALL)
            .addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.STEERINGANGLEINNER)
        ));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TURNINGRADIUSWALLTOWALL, config.INPUT_TYPE.UPDOWNBOX)
        .getter(rigOps.getVehicleTurningRadiusWallToWall)
        .setter(getConfiguration().setTurningRadiusWallToWall)
        .visiblePermission(showTurningRadiusWallToWall)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                //getConfiguration().setTurningRadiusWallToWallOverride(val);
                //getConfiguration().setTurningRadiusCurbToCurbOverride(val);
                //getConfiguration().setSteeringAngleInnerOverride(val);

                if (val === false) {
                    rigOps.updateTurningCircleCalculatedVals();
                }
                handleTurningCircleOverrideChange(config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL, val);
            },
            function () {
                return getOriginalOffer().getRig().getVehicle().getTurningRadiusWallToWall();
            },
            function () {
                return getConfiguration().getTurningRadiusWallToWallOverride();
            })
        .overridePermission(isStandardVehicleAndIsLoadingAdmin)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TURNINGRADIUSWALLTOWALL)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                rigOps.updateTurningCircleCalculatedVals(config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL, newValue);
                updateOriginalTurningCircleValuesIfNecessary();
            })
            .addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TURNINGRADIUSCURBTOCURB)
            .addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.STEERINGANGLEINNER)
        ));



    function handleTurningCircleOverrideChange(steeringAttributeId, val) {
        if (!rigOps.getVehicleWheelbaseTurningChanged()) {


            if (steeringAttributeId === config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER) {

                getConfiguration().setSteeringAngleInnerOverride(val);
                getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TURNINGRADIUSCURBTOCURB).override.value = val;
                getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TURNINGRADIUSWALLTOWALL).override.value = val;
            } else if (steeringAttributeId === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB) {
                getConfiguration().setTurningRadiusCurbToCurbOverride(val);
                getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.STEERINGANGLEINNER).override.value = val;
                getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TURNINGRADIUSWALLTOWALL).override.value = val;
            } else if (steeringAttributeId === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL) {
                getConfiguration().setTurningRadiusWallToWallOverride(val);
                getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.STEERINGANGLEINNER).override.value = val;
                getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TURNINGRADIUSCURBTOCURB).override.value = val;
            }
        } else {
            //if (steeringAttributeId === config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER) {
            getConfiguration().setSteeringAngleInnerOverride(val);
            //} else if (steeringAttributeId === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB) {
            getConfiguration().setTurningRadiusCurbToCurbOverride(val);
            //} else if (steeringAttributeId === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL) {
            getConfiguration().setTurningRadiusWallToWallOverride(val);
            //}
        }
    }

    var path = config.CHASSIS_OBJECT_TYPES.VEHICLE;
    ////WEIGHTS
    //KERB WEIGHTS
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TAREFRONT, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(getConfiguration().getTareFront)
        .setter(getConfiguration().setTareFront)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setTareFront(getConfiguration().getTareFront());
                getConfiguration().setTareFrontOverride(val);

                //if (val === true) {
                //    //getConfiguration().getAxlesHolder().setAxleGroupWeightOverrides(config.AXLE_LOCATIONS.FRONT, false);
                //    //rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEWEIGHT, config.AXLE_LOCATIONS.FRONT, getConfiguration().getTareFront());
                //    rigOps.distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.FRONT, getConfiguration().getTareFront());
                //}
                //getConfiguration().getAxlesHolder().getAxles().forEach(function (axle) {
                //    if (rigOps.getAxleLocationFromAxle(axle) === config.AXLE_LOCATIONS.FRONT) {
                //        var frontAxleWeightAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + axle.getId() + '.' + config.VALUE_TYPE.AXLEWEIGHT);
                //        frontAxleWeightAOG.permissionObv(frontAxleWeightAOG.permission(frontAxleWeightAOG.getPath() + '.' + config.VALUE_TYPE.AXLEWEIGHT));
                //    }
                //});
            },
            function () {
                //return rigOps.getVehicleTareFront();
                return getActiveOffer().getRig().getVehicle().getTareFront();
            },
            function () {
                return getConfiguration().getTareFrontOverride();
            },
            function () {
                rigOps.resetAxleValues(path, config.VALUE_TYPE.AXLEWEIGHT, config.AXLE_LOCATIONS.FRONT);
            }, true)
        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
        .overridePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
        //.readOnlyPermission(function () {
        //    return getConfiguration().getAxlesHolder().getAxle1Front().getAxleWeightOverride();
        //})
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TAREFRONT)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                //rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEWEIGHT, config.AXLE_LOCATIONS.FRONT, newValue);
                rigOps.distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.FRONT, newValue);
            })
        ));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TAREREAR, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(getConfiguration().getTareRear)
        .setter(getConfiguration().setTareRear)
        //.addOverrideMechanism(getConfiguration,
        //    function (val) {
        //        getConfiguration().setTareRear(getConfiguration().getTareRear());
        //        getConfiguration().setTareRearOverride(val);
        //        if (val === true) {
        //            getConfiguration().getAxlesHolder().setAxleGroupWeightOverrides(config.AXLE_LOCATIONS.REAR, false);
        //            //rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEWEIGHT, config.AXLE_LOCATIONS.REAR, getConfiguration().getTareRear());
        //            rigOps.distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.REAR, getConfiguration().getTareRear());
        //        }
        //        getConfiguration().getAxlesHolder().getAxles().forEach(function (axle) {
        //            if (rigOps.getAxleLocationFromAxle(axle) === config.AXLE_LOCATIONS.REAR) {
        //                var rearAxleWeightAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + axle.getId() + '.' + config.VALUE_TYPE.AXLEWEIGHT);
        //                rearAxleWeightAOG.permissionObv(rearAxleWeightAOG.permission(rearAxleWeightAOG.getPath() + '.' + config.VALUE_TYPE.AXLEWEIGHT));
        //            }
        //        });
        //    },
        //    function () {
        //        return rigOps.getVehicleTareRear();
        //    },
        //    function () {
        //        return getConfiguration().getTareRearOverride();
        //    },
        //    function () {
        //        rigOps.resetAxleValues(path, config.VALUE_TYPE.AXLEWEIGHT, config.AXLE_LOCATIONS.REAR);
        //    }, true)
        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
        //.overridePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
        .readOnlyPermission(function () {
            return true;
        })
        /*.addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TAREREAR)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                //rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEWEIGHT, config.AXLE_LOCATIONS.REAR, newValue);
                rigOps.distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.REAR, newValue);
            })
        )*/);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TAREREARREAR, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(getConfiguration().getTareRearRear)
        .setter(function (val) { 
            getConfiguration().setTareRearRear(val) 
        })
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setTareRearRear(getConfiguration().getTareRearRear());
                getConfiguration().setTareRearOverride(val);
                //if (val === true) {

                //    getConfiguration().getAxlesHolder().setAxleGroupWeightOverrides(config.AXLE_LOCATIONS.REAR, false);
                //    //rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEWEIGHT, config.AXLE_LOCATIONS.REAR, getConfiguration().getTareRear());
                //    rigOps.distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.REAR, getConfiguration().getTareRear());
                //}
                //getConfiguration().getAxlesHolder().getAxles().forEach(function (axle) {
                //    if (rigOps.getAxleLocationFromAxle(axle) === config.AXLE_LOCATIONS.REAR) {
                //        var rearAxleWeightAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + axle.getId() + '.' + config.VALUE_TYPE.AXLEWEIGHT);
                //        rearAxleWeightAOG.permissionObv(rearAxleWeightAOG.permission(rearAxleWeightAOG.getPath() + '.' + config.VALUE_TYPE.AXLEWEIGHT));
                //    }
                //});
            },
            function () {
                return getOriginalOffer().getRig().getVehicle().getTareRearRear();
            },
            function () {
                return getConfiguration().getTareRearOverride();
            },
            function () {
                //rigOps.resetAxleValues(path, config.VALUE_TYPE.AXLEWEIGHT, config.AXLE_LOCATIONS.REAR);
                rigOps.distributeAxleWeightOverRearRearAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, getOriginalOffer().getRig().getVehicle().getTareRearRear());
            }, true)
        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
        .overridePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
        //.readOnlyPermission(function () {
        //    return getConfiguration().getAxlesHolder().getAxle1Rear().getAxleWeightOverride();
        //})
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TAREREARREAR)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                //rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEWEIGHT, config.AXLE_LOCATIONS.REAR, newValue);
                //rigOps.distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.REAR, newValue);
                rigOps.distributeAxleWeightOverRearRearAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, newValue);
            })
        ));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HORIZONTALCOG, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.LENGTH)
        .getter(rigOps.getVehicleHorizontalCOG)
        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
        .readOnly(), path + '.' + config.OBJECT_TYPES.AXLES_TOTAL);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.VERTICALCOG, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.LENGTH)
        .getter(rigOps.getVehicleVerticalCOG)
        .setter(getConfiguration().setVehicleVerticalCOG)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setVehicleVerticalCOGOverride(val);
            },
            function () {
                return rigOps.getVehicleVerticalCOG();
            },
            function () {
                return getConfiguration().getVehicleVerticalCOGOverride();
            })
        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
        .overridePermission(hideForStandardVehicleUnlessLoadingAdmin)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_TOTAL + '.' + config.VALUE_TYPE.VERTICALCOG)), path + '.' + config.OBJECT_TYPES.AXLES_TOTAL);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.LATERALCOG, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.LENGTH)
        .getter(rigOps.getVehicleLateralCOG)
        .setter(getConfiguration().setVehicleLateralCOG)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setVehicleLateralCOGOverride(val);
            },
            function () {
                return rigOps.getVehicleLateralCOG();
            },
            function () {
                return getConfiguration().getVehicleLateralCOGOverride();
            })
        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
        .overridePermission(hideForStandardVehicleUnlessLoadingAdmin)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_TOTAL + '.' + config.VALUE_TYPE.LATERALCOG)), path + '.' + config.OBJECT_TYPES.AXLES_TOTAL);


    var yesNoOption = getYesNoNotSpecifiedOptions()
        .filter(function (option) {
            if (option.id === getConfiguration().getTareToolsIncluded()) {
                return option;
            } else if (option.id === config.YES_NO_OPTIONS.NOT_SPECIFIED && getConfiguration().getTareToolsIncluded() === '') {
                return option;
            }
        })[0];

    //getAttributeGroup(vehiclePath + config.VALUE_TYPE.TOOLSINCLUDED).optionId(yesNoOption.id);

    //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TOOLSINCLUDED, config.INPUT_TYPE.SELECT)
    //    .addSubscription(function (val) {
    //        getConfiguration().setTareToolsIncluded(val);
    //    })
    //    .visiblePermission(isNotStandardVehicle)
    //    .setVal(yesNoOption));
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TOOLSINCLUDED, config.INPUT_TYPE.SELECT)
        .addSubscription(function (val) {
            getConfiguration().setTareToolsIncluded(val);
        })
        .visiblePermission(isNotStandardVehicle)
        .setVal(yesNoOption), path + '.' + config.OBJECT_TYPES.AXLES_TOTAL);


    yesNoOption = getYesNoNotSpecifiedOptions()
        .filter(function (option) {
            if (option.id === getConfiguration().getTareSpareWheelIncluded()) {
                return option;
            } else if (option.id === config.YES_NO_OPTIONS.NOT_SPECIFIED && getConfiguration().getTareSpareWheelIncluded() === '') {
                return option;
            }
        })[0];


    //getAttributeGroup(vehiclePath + config.VALUE_TYPE.SPAREWHEELINCLUDED).option(yesNoOption);
    //getAttributeGroup(vehiclePath + config.VALUE_TYPE.SPAREWHEELINCLUDED).optionId(yesNoOption.id);

    //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SPAREWHEELINCLUDED, config.INPUT_TYPE.SELECT)
    //    .addSubscription(function (val) {
    //        getConfiguration().setTareSpareWheelIncluded(val);
    //    })
    //    .visiblePermission(isNotStandardVehicle)
    //    .setVal(yesNoOption));
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SPAREWHEELINCLUDED, config.INPUT_TYPE.SELECT)
        .addSubscription(function (val) {
            getConfiguration().setTareSpareWheelIncluded(val);
        })
        .visiblePermission(isNotStandardVehicle)
        .setVal(yesNoOption), path + '.' + config.OBJECT_TYPES.AXLES_TOTAL);
        
    //MANUFACTURERSRATINGS
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(getConfiguration().getGAFront)
        .setter(getConfiguration().setGAFront)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setGAFront(getConfiguration().getGAFront());
                getConfiguration().setManufacturersFrontAxlesMassOverride(val);

                if (val === true) {
                    getConfiguration().getAxlesHolder().setAxleGroupManufacturerRatingOverrides(config.AXLE_LOCATIONS.FRONT, false);
                    getConfiguration().getAxlesHolder().setAxleGroupSuspensionMassRatingOverrides(config.AXLE_LOCATIONS.FRONT, false);
                    getConfiguration().getAxlesHolder().setAxleGroupTyreMassRatingOverrides(config.AXLE_LOCATIONS.FRONT, false);
                    rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEMANUFACTURERRATING, config.AXLE_LOCATIONS.FRONT, getConfiguration().getGAFront());
                    rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.SUSPENSIONMASSRATING, config.AXLE_LOCATIONS.FRONT, getConfiguration().getGAFront());
                    rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.TYREMASSRATING, config.AXLE_LOCATIONS.FRONT, getConfiguration().getGAFront());

                    if (getConfiguration().getAxlesHolder().getAxle1Front().getAxleWeightOverride() === false) {
                        rigOps.distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.FRONT, getConfiguration().getTareFront());
                    }
                }
                getConfiguration().getAxlesHolder().getAxles().forEach(function (axle) {
                    if (rigOps.getAxleLocationFromAxle(axle) === config.AXLE_LOCATIONS.FRONT) {
                        var frontAxleManufacturerRatingAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + axle.getId() + '.' + config.VALUE_TYPE.AXLEMANUFACTURERRATING);
                        frontAxleManufacturerRatingAOG.permissionObv(frontAxleManufacturerRatingAOG.permission(frontAxleManufacturerRatingAOG.getPath() + '.' + config.VALUE_TYPE.AXLEMANUFACTURERRATING));

                        var frontAxleSuspensionMassRatingAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + axle.getId() + '.' + config.VALUE_TYPE.SUSPENSIONMASSRATING);
                        frontAxleSuspensionMassRatingAOG.permissionObv(frontAxleSuspensionMassRatingAOG.permission(frontAxleSuspensionMassRatingAOG.getPath() + '.' + config.VALUE_TYPE.SUSPENSIONMASSRATING));

                        var frontAxleTyreMassRatingAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + axle.getId() + '.' + config.VALUE_TYPE.TYREMASSRATING);
                        frontAxleTyreMassRatingAOG.permissionObv(frontAxleTyreMassRatingAOG.permission(frontAxleTyreMassRatingAOG.getPath() + '.' + config.VALUE_TYPE.TYREMASSRATING));
                    }
                });
            },
            function () {
                return getConfiguration().getGAFront();
            },
            function () {
                return getConfiguration().getManufacturersFrontAxlesMassOverride();
            },
            function () {
                rigOps.resetAxleValues(path, config.VALUE_TYPE.AXLEMANUFACTURERRATING, config.AXLE_LOCATIONS.FRONT);
                rigOps.resetAxleValues(path, config.VALUE_TYPE.SUSPENSIONMASSRATING, config.AXLE_LOCATIONS.FRONT);
                rigOps.resetAxleValues(path, config.VALUE_TYPE.TYREMASSRATING, config.AXLE_LOCATIONS.FRONT);
            }, true)
        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
        .overridePermission(showUnlessStandardAndNotAdmin)
        //.readOnlyPermission(function () {
        //    return getConfiguration().getAxlesHolder().getAxle1Front().getManufacturerRatingOverride();
        //})
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEMANUFACTURERRATING, config.AXLE_LOCATIONS.FRONT, newValue);
                rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.SUSPENSIONMASSRATING, config.AXLE_LOCATIONS.FRONT, newValue);
                rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.TYREMASSRATING, config.AXLE_LOCATIONS.FRONT, newValue);

                if (getConfiguration().getAxlesHolder().getAxle1Front().getAxleWeightOverride() === false) {
                    rigOps.distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.FRONT, getConfiguration().getTareFront());
                }
            })
        ));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.MANUFACTURERSREARAXLEMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(getConfiguration().getGARear)
        .setter(getConfiguration().setGARear)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setGARear(getConfiguration().getGARear());
                getConfiguration().setManufacturersRearAxlesMassOverride(val);

                if (val === true) {
                    getConfiguration().getAxlesHolder().setAxleGroupManufacturerRatingOverrides(config.AXLE_LOCATIONS.REAR, false);
                    getConfiguration().getAxlesHolder().setAxleGroupSuspensionMassRatingOverrides(config.AXLE_LOCATIONS.REAR, false);
                    getConfiguration().getAxlesHolder().setAxleGroupTyreMassRatingOverrides(config.AXLE_LOCATIONS.REAR, false);
                    rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEMANUFACTURERRATING, config.AXLE_LOCATIONS.REAR, getConfiguration().getGARear());
                    rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.SUSPENSIONMASSRATING, config.AXLE_LOCATIONS.REAR, getConfiguration().getGARear());
                    rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.TYREMASSRATING, config.AXLE_LOCATIONS.REAR, getConfiguration().getGARear());

                    if (getConfiguration().getAxlesHolder().getAxle1Rear().getAxleWeightOverride() === false) {
                        rigOps.distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.REAR, getConfiguration().getTareRear());
                    }
                }
                getConfiguration().getAxlesHolder().getAxles().forEach(function (axle) {
                    if (rigOps.getAxleLocationFromAxle(axle) === config.AXLE_LOCATIONS.REAR) {
                        var rearAxleManufacturerRatingAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + axle.getId() + '.' + config.VALUE_TYPE.AXLEMANUFACTURERRATING);
                        rearAxleManufacturerRatingAOG.permissionObv(rearAxleManufacturerRatingAOG.permission(rearAxleManufacturerRatingAOG.getPath() + '.' + config.VALUE_TYPE.AXLEMANUFACTURERRATING));

                        var rearAxleSuspensionMassRatingAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + axle.getId() + '.' + config.VALUE_TYPE.SUSPENSIONMASSRATING);
                        rearAxleSuspensionMassRatingAOG.permissionObv(rearAxleSuspensionMassRatingAOG.permission(rearAxleSuspensionMassRatingAOG.getPath() + '.' + config.VALUE_TYPE.SUSPENSIONMASSRATING));

                        var rearAxleTyreMassRatingAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + axle.getId() + '.' + config.VALUE_TYPE.TYREMASSRATING);
                        rearAxleTyreMassRatingAOG.permissionObv(rearAxleTyreMassRatingAOG.permission(rearAxleTyreMassRatingAOG.getPath() + '.' + config.VALUE_TYPE.TYREMASSRATING));
                    }
                });

            },
            function () {
                return getConfiguration().getGARear();
            },
            function () {
                return getConfiguration().getManufacturersRearAxlesMassOverride();
            },
            function () {
                rigOps.resetAxleValues(path, config.VALUE_TYPE.AXLEMANUFACTURERRATING, config.AXLE_LOCATIONS.REAR);
                rigOps.resetAxleValues(path, config.VALUE_TYPE.SUSPENSIONMASSRATING, config.AXLE_LOCATIONS.REAR);
                rigOps.resetAxleValues(path, config.VALUE_TYPE.TYREMASSRATING, config.AXLE_LOCATIONS.REAR);
            }, true)
        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
        .overridePermission(showUnlessStandardAndNotAdmin)
        //.readOnlyPermission(function () {
        //    return getConfiguration().getAxlesHolder().getAxle1Rear().getManufacturerRatingOverride();
        //})
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.MANUFACTURERSREARAXLEMASS)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEMANUFACTURERRATING, config.AXLE_LOCATIONS.REAR, newValue);
                rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.SUSPENSIONMASSRATING, config.AXLE_LOCATIONS.REAR, newValue);
                rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.TYREMASSRATING, config.AXLE_LOCATIONS.REAR, newValue);

                if (getConfiguration().getAxlesHolder().getAxle1Rear().getAxleWeightOverride() === false) {
                    rigOps.distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.REAR, getConfiguration().getTareRear());
                }
            })
        ));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.MANUFACTURERSGROSSVEHICLEMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(getConfiguration().getGVM)
        .setter(getConfiguration().setGVM)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setManufacturersGrossVehicleMassOverride(val);
            },
            function () {
                return getOriginalOffer().getConfiguration().getGVM();
            },
            function () {
                return getConfiguration().getManufacturersGrossVehicleMassOverride();
            })
        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
        .overridePermission(showUnlessStandardAndNotAdmin)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_TOTAL + '.' + config.VALUE_TYPE.MANUFACTURERSGROSSVEHICLEMASS)), path + '.' + config.OBJECT_TYPES.AXLES_TOTAL);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.VEHICLEBRIDGEFORMULAPERMISSIBLE, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        //.getter(rigOps.getVehicleTotalBridgeOrRegulationVal)
        .getter(getConfiguration().getVehicleBridgeFormulaPermissible)
        .setter(getConfiguration().setVehicleBridgeFormulaPermissible)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setVehicleBridgeFormulaPermissibleOverride(val);
            },
            function () {
                return getOriginalOffer().getConfiguration().getVehicleBridgeFormulaPermissible();
                //return rigOps.getVehicleTotalBridgeOrRegulationVal();
            },
            function () {
                return getConfiguration().getVehicleBridgeFormulaPermissibleOverride();
        })
        .overridePermission(function () { return true; })
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_TOTAL + '.' + config.VALUE_TYPE.VEHICLEBRIDGEFORMULAPERMISSIBLE)), path + '.' + config.OBJECT_TYPES.AXLES_TOTAL);
    
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.VEHICLETOTALPERMISSIBLE, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(function () {
            //rigOps.getVehicleTotalPermissible
                var mergedPermissibleValues = getMergedLegislationsCombinationPermissibleVals(getDataForActiveLegislations().activeLegislationsCombinationPermissibleValues);
                //rigOps.setCombinationPermissibleVals(mergedPermissibleValues);
                return mergedPermissibleValues.vehicleTotalPermissible;
            })
        .readOnly(), path + '.' + config.OBJECT_TYPES.AXLES_TOTAL);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.MANUFACTURERSGROSSCOMBINATIONMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(getConfiguration().getGCM)
        .setter(getConfiguration().setGCM)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setManufacturersGrossCombinationMassOverride(val);
            },
            function () {
                return getOriginalOffer().getConfiguration().getGCM();
            },
            function () {
                return getConfiguration().getManufacturersGrossCombinationMassOverride();
            })
        .overridePermission(isStandardVehicleAndIsLoadingAdmin)
        .visiblePermission(showCombinationPermissibleRelatedAttribbuteOnTotalCard)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_TOTAL + '.' + config.VALUE_TYPE.MANUFACTURERSGROSSCOMBINATIONMASS)), path + '.' + config.OBJECT_TYPES.AXLES_TOTAL);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COMBINATIONBRIDGEFORMULAPERMISSIBLE, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        //.getter(rigOps.getCombinationTotalBridgeOrRegulationVal)
        .getter(getConfiguration().getCombinationBridgeFormulaPermissible)
        .setter(getConfiguration().setCombinationBridgeFormulaPermissible)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setCombinationBridgeFormulaPermissibleOverride(val);
            },
            function () {
                return getOriginalOffer().getConfiguration().getCombinationBridgeFormulaPermissible();
                //return rigOps.getCombinationTotalBridgeOrRegulationVal();
            },
            function () {
                return getConfiguration().getCombinationBridgeFormulaPermissibleOverride();
            })
        .overridePermission(function () { return true; })
        .visiblePermission(showCombinationPermissibleRelatedAttribbuteOnTotalCard)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_TOTAL + '.' + config.VALUE_TYPE.COMBINATIONBRIDGEFORMULAPERMISSIBLE)), path + '.' + config.OBJECT_TYPES.AXLES_TOTAL);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COMBINATIONTOTALPERMISSIBLE, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(rigOps.getCombinationTotalPermissible)
        .visiblePermission(showCombinationPermissibleRelatedAttribbuteOnTotalCard)
        .readOnly(), path + '.' + config.OBJECT_TYPES.AXLES_TOTAL);

    //yesNoOption = getYesNoOptions()
    //    .filter(function (option) {
    //        if (option.id === getConfiguration().getFrontRoadFriendlySuspension()) {
    //            return option;
    //        } else if (option.id === config.YES_NO_OPTIONS.NO && (getConfiguration().getFrontRoadFriendlySuspension() === '' || getConfiguration().getFrontRoadFriendlySuspension() === null || getConfiguration().getFrontRoadFriendlySuspension() === undefined)) {
    //            return option;
    //        }
    //    })[0];

    function getFrontRoadFriendlySuspensionOption() {
        return getYesNoOptions()
            .filter(function (option) {
                if (option.id === getConfiguration().getFrontRoadFriendlySuspension()) {
                    return option;
                } else if (option.id === config.YES_NO_OPTIONS.NO && (getConfiguration().getFrontRoadFriendlySuspension() === '' || getConfiguration().getFrontRoadFriendlySuspension() === null || getConfiguration().getFrontRoadFriendlySuspension() === undefined)) {
                    return option;
                }
            })[0];
    }

    //getAttributeGroup(vehiclePath + config.VALUE_TYPE.FRONTROADFRIENDLYSUSPENSION).option(yesNoOption);
    //getAttributeGroup(vehiclePath + config.VALUE_TYPE.FRONTROADFRIENDLYSUSPENSION).optionId(yesNoOption.id);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FRONTROADFRIENDLYSUSPENSION, config.INPUT_TYPE.SELECT)
        .addSubscription(function (val) {
            getConfiguration().setFrontRoadFriendlySuspension(val);
        })
        //.visiblePermission(isNotStandardVehicleOrIsStandardVehicleAndShowHigherMassLimitsIsTrue)
        .visiblePermission(showFrontRoadFriendlySuspension)
        .setVal(getFrontRoadFriendlySuspensionOption())
        .optionGetter(getFrontRoadFriendlySuspensionOption), path);


    //yesNoOption = getYesNoOptions()
    //    .filter(function (option) {
    //        if (option.id === getConfiguration().getRearRoadFriendlySuspension()) {
    //            return option;
    //        } else if (option.id === config.YES_NO_OPTIONS.NO && (getConfiguration().getRearRoadFriendlySuspension() === '' || getConfiguration().getRearRoadFriendlySuspension() === null || getConfiguration().getRearRoadFriendlySuspension() === undefined)) {
    //            return option;
    //        }
    //    })[0];

    function getRearRoadFriendlySuspensionOption() {
        return getYesNoOptions()
            .filter(function (option) {
                if (option.id === getConfiguration().getRearRoadFriendlySuspension()) {
                    return option;
                } else if (option.id === config.YES_NO_OPTIONS.NO && (getConfiguration().getRearRoadFriendlySuspension() === '' || getConfiguration().getRearRoadFriendlySuspension() === null || getConfiguration().getRearRoadFriendlySuspension() === undefined)) {
                    return option;
                }
            })[0];
    }

    //getAttributeGroup(vehiclePath + config.VALUE_TYPE.REARROADFRIENDLYSUSPENSION).option(yesNoOption);
    //getAttributeGroup(vehiclePath + config.VALUE_TYPE.REARROADFRIENDLYSUSPENSION).optionId(yesNoOption.id);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.REARROADFRIENDLYSUSPENSION, config.INPUT_TYPE.SELECT)
        .addSubscription(function (val) {
            getConfiguration().setRearRoadFriendlySuspension(val);
        })
        //.visiblePermission(isNotStandardVehicleOrIsStandardVehicleAndShowHigherMassLimitsIsTrue)
        .visiblePermission(evaluateShowSuspension)
        .setVal(getRearRoadFriendlySuspensionOption())
        .optionGetter(getRearRoadFriendlySuspensionOption), path);


    //yesNoOption = getYesNoOptions()
    //    .filter(function (option) {
    //        if (option.id === getConfiguration().getRolloverProtection()) {
    //            return option;
    //        } else if (option.id === config.YES_NO_OPTIONS.NO && (getConfiguration().getRolloverProtection() === '' || getConfiguration().getRolloverProtection() === null || getConfiguration().getRolloverProtection() === undefined)) {
    //            return option;
    //        }
    //    })[0];

    function getRolloverProtectionOption() {
        return getYesNoOptions()
            .filter(function (option) {
                if (option.id === getConfiguration().getRolloverProtection()) {
                    return option;
                } else if (option.id === config.YES_NO_OPTIONS.NO && (getConfiguration().getRolloverProtection() === '' || getConfiguration().getRolloverProtection() === null || getConfiguration().getRolloverProtection() === undefined)) {
                    return option;
                }
            })[0];
    }

    //getAttributeGroup(vehiclePath + config.VALUE_TYPE.ROLLOVERPROTECTION).option(yesNoOption);
    //getAttributeGroup(vehiclePath + config.VALUE_TYPE.ROLLOVERPROTECTION).optionId(yesNoOption.id);

    //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ROLLOVERPROTECTION, config.INPUT_TYPE.SELECT)
    //    .addSubscription(function (val) {
    //        getConfiguration().setRolloverProtection(val);
    //    })
    //    //.visiblePermission(isNotStandardVehicleOrIsStandardVehicleAndShowHigherMassLimitsIsTrue)
    //    .visiblePermission(evaluateShowSuspension)
    //    .setVal(yesNoOption));
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ROLLOVERPROTECTION, config.INPUT_TYPE.SELECT)
        .addSubscription(function (val) {
            getConfiguration().setRolloverProtection(val);
        })
        //.visiblePermission(isNotStandardVehicleOrIsStandardVehicleAndShowHigherMassLimitsIsTrue)
        .visiblePermission(evaluateShowSafety)
        .setVal(getRolloverProtectionOption())
        .optionGetter(getRolloverProtectionOption), path + '.' + config.OBJECT_TYPES.SAFETY);
        

    //yesNoOption = getYesNoOptions()
    //    .filter(function (option) {
    //        if (option.id === getConfiguration().getFrontUnderrunProtection()) {
    //            return option;
    //        } else if (option.id === config.YES_NO_OPTIONS.NO && (getConfiguration().getFrontUnderrunProtection() === '' || getConfiguration().getFrontUnderrunProtection() === null || getConfiguration().getFrontUnderrunProtection() === undefined)) {
    //            return option;
    //        }
    //    })[0];

    function getFrontUnderrunProtectionOption() {
        return getYesNoOptions()
            .filter(function (option) {
                if (option.id === getConfiguration().getFrontUnderrunProtection()) {
                    return option;
                } else if (option.id === config.YES_NO_OPTIONS.NO && (getConfiguration().getFrontUnderrunProtection() === '' || getConfiguration().getFrontUnderrunProtection() === null || getConfiguration().getFrontUnderrunProtection() === undefined)) {
                    return option;
                }
            })[0];
    }

    function getYesNoSafetyAttributeOption(safetyAttributeGetterFunc) {
        return getYesNoOptions()
            .filter(function (option) {
                if (option.id === safetyAttributeGetterFunc()) {
                    return option;
                } else if (option.id === config.YES_NO_OPTIONS.NO && (safetyAttributeGetterFunc() === '' || safetyAttributeGetterFunc() === null || safetyAttributeGetterFunc() === undefined)) {
                    return option;
                }
            })[0];
    }

    //getAttributeGroup(vehiclePath + config.VALUE_TYPE.FRONTUNDERRUNPROTECTION).option(yesNoOption);
    //getAttributeGroup(vehiclePath + config.VALUE_TYPE.FRONTUNDERRUNPROTECTION).optionId(yesNoOption.id);

    //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FRONTUNDERRUNPROTECTION, config.INPUT_TYPE.SELECT)
    //    .addSubscription(function (val) {
    //        getConfiguration().setFrontUnderrunProtection(val);
    //    })
    //    //.visiblePermission(isNotStandardVehicleOrIsStandardVehicleAndShowHigherMassLimitsIsTrue)
    //    .visiblePermission(evaluateShowSuspension)
    //    .setVal(yesNoOption));
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FRONTUNDERRUNPROTECTION, config.INPUT_TYPE.SELECT)
        .addSubscription(function (val) {
            getConfiguration().setFrontUnderrunProtection(val);
        })
        //.visiblePermission(isNotStandardVehicleOrIsStandardVehicleAndShowHigherMassLimitsIsTrue)
        .visiblePermission(evaluateShowSafety)
        .setVal(getFrontUnderrunProtectionOption())
        .optionGetter(getFrontUnderrunProtectionOption), path + '.' + config.OBJECT_TYPES.SAFETY);



    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.DEVICESFORINDIRECTVISION, config.INPUT_TYPE.SELECT)
        .addSubscription(function (val) {
            getConfiguration().setDevicesForIndirectVision(val);
        })
        .visiblePermission(evaluateShowSafety)
        .setVal(getYesNoSafetyAttributeOption(getConfiguration().getDevicesForIndirectVision))
        .optionGetter(function () { return getYesNoSafetyAttributeOption(getConfiguration().getDevicesForIndirectVision); }), path + '.' + config.OBJECT_TYPES.SAFETY);

    
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COMMERCIALVEHICLEBRAKESYSTEMS, config.INPUT_TYPE.SELECT)
        .addSubscription(function (val) {
            getConfiguration().setCommercialVehicleBrakeSystems(val);
        })
        .visiblePermission(evaluateShowSafety)
        .setVal(getYesNoSafetyAttributeOption(getConfiguration().getCommercialVehicleBrakeSystems))
        .optionGetter(function () { return getYesNoSafetyAttributeOption(getConfiguration().getCommercialVehicleBrakeSystems); }), path + '.' + config.OBJECT_TYPES.SAFETY);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ADVANCEDEMERGENCYBRAKING, config.INPUT_TYPE.SELECT)
        .addSubscription(function (val) {
            getConfiguration().setAdvancedEmergencyBraking(val);
        })
        .visiblePermission(evaluateShowSafety)
        .setVal(getYesNoSafetyAttributeOption(getConfiguration().getAdvancedEmergencyBraking))
        .optionGetter(function () { return getYesNoSafetyAttributeOption(getConfiguration().getAdvancedEmergencyBraking); }), path + '.' + config.OBJECT_TYPES.SAFETY);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.LANEDEPARTUREWARNING, config.INPUT_TYPE.SELECT)
        .addSubscription(function (val) {
            getConfiguration().setLaneDepartureWarning(val);
        })
        .visiblePermission(evaluateShowSafety)
        .setVal(getYesNoSafetyAttributeOption(getConfiguration().getLaneDepartureWarning))
        .optionGetter(function () { return getYesNoSafetyAttributeOption(getConfiguration().getLaneDepartureWarning); }), path + '.' + config.OBJECT_TYPES.SAFETY);



    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SIDEUNDERRUNPROTECTION, config.INPUT_TYPE.SELECT)
        .addSubscription(function (val) {
            getConfiguration().setSideUnderrunProtection(val);
        })
        .visiblePermission(evaluateShowSafety)
        .setVal(getYesNoSafetyAttributeOption(getConfiguration().getSideUnderrunProtection))
        .optionGetter(function () { return getYesNoSafetyAttributeOption(getConfiguration().getSideUnderrunProtection); }), path + '.' + config.OBJECT_TYPES.SAFETY);
    


    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CONSPICUITYMARKINGSTOTHEREAR, config.INPUT_TYPE.SELECT)
        .addSubscription(function (val) {
            getConfiguration().setConspicuityMarkingsToTheRear(val);
        })
        .visiblePermission(evaluateShowSafety)
        .setVal(getYesNoSafetyAttributeOption(getConfiguration().getConspicuityMarkingsToTheRear))
        .optionGetter(function () { return getYesNoSafetyAttributeOption(getConfiguration().getConspicuityMarkingsToTheRear); }), path + '.' + config.OBJECT_TYPES.SAFETY);



    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CONSPICUITYMARKINGSTOBOTHSIDES, config.INPUT_TYPE.SELECT)
        .addSubscription(function (val) {
            getConfiguration().setConspicuityMarkingsToBothSides(val);
        })
        .visiblePermission(evaluateShowSafety)
        .setVal(getYesNoSafetyAttributeOption(getConfiguration().getConspicuityMarkingsToBothSides))
        .optionGetter(function () { return getYesNoSafetyAttributeOption(getConfiguration().getConspicuityMarkingsToBothSides); }), path + '.' + config.OBJECT_TYPES.SAFETY);
        


    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BLINDSPOTINFORMATIONSYSTEMS, config.INPUT_TYPE.SELECT)
        .addSubscription(function (val) {
            getConfiguration().setBlindSpotInformationSystems(val);
        })
        .visiblePermission(evaluateShowSafety)
        .setVal(getYesNoSafetyAttributeOption(getConfiguration().getBlindSpotInformationSystems))
        .optionGetter(function () { return getYesNoSafetyAttributeOption(getConfiguration().getBlindSpotInformationSystems); }), path + '.' + config.OBJECT_TYPES.SAFETY);

        

    //LEGISLATIVE LIMITS
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.PERMISSIBLEFRONTAXLEMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(rigOps.getVehicleFrontPermissible)
        .setter(getConfiguration().setAUFront)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setPermissibleFrontOverride(val);
            },
            function () {
                return rigOps.getVehicleFrontPermissible();
            },
            function () {
                return getConfiguration().getPermissibleFrontOverride();
            })
        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
        .overridePermission(showUnlessStandardAndNotAdmin)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.PERMISSIBLEFRONTAXLEMASS)));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.PERMISSIBLEREARAXLEMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(rigOps.getVehicleRearPermissible)
        .setter(getConfiguration().setAURear)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setPermissibleRearOverride(val);
            },
            function () {
                return rigOps.getVehicleRearPermissible();
            },
            function () {
                return getConfiguration().getPermissibleRearOverride();
            })
        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
        .overridePermission(showUnlessStandardAndNotAdmin)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.PERMISSIBLEREARAXLEMASS)));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.PERMISSIBLETOTALAXLEMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS) 
        .getter(function () {
            //rigOps.getCombinationTotalPermissible
            var mergedPermissibleValues = getMergedLegislationsCombinationPermissibleVals(getDataForActiveLegislations().activeLegislationsCombinationPermissibleValues);
            //rigOps.setCombinationPermissibleVals(mergedPermissibleValues);
            return mergedPermissibleValues.combinationTotalPermissible;
        })
        .setter(getConfiguration().setPermissibleTotal)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setPermissibleTotalOverride(val);
            },
            function () {
                return rigOps.getCombinationTotalPermissible();
            },
            function () {
                return getConfiguration().getPermissibleTotalOverride();
            })
        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
        .overridePermission(showUnlessStandardAndNotAdmin)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.PERMISSIBLETOTALAXLEMASS)));

    //CREW
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CREWMASSPERPERSON, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(getConfiguration().getCrewMassPerPerson)
        .setter(getConfiguration().setCrewMassPerPerson)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CREWMASSPERPERSON)));


    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CREWFIRSTROW, config.INPUT_TYPE.CHECKBOX)
        .readOnly()
        .setVal(true))

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.NOOFCREWINFIRSTROW, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.NONE)
        .getter(getConfiguration().getNoOfCrewInFirstRow)
        .setter(getConfiguration().setNoOfCrewInFirstRow)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.NOOFCREWINFIRSTROW)));
    //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CREWFIRSTROWHORIZONTALCOG, config.INPUT_TYPE.UPDOWNBOX));
    //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CREWFIRSTROWVERTICALCOG, config.INPUT_TYPE.UPDOWNBOX));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TAREDRIVERINCLUDED, config.INPUT_TYPE.CHECKBOX)
        
        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
        .setVal((function () {
            if (getConfiguration().getTareDriverIncluded() === config.TARE_DRIVER_INCLUDED_OPTIONS.YES) {
                return true;
            } else {
                return false;
            }
        })())
        .addSubscription(function (val) {
            if (val === true) {
                getConfiguration().setTareDriverIncluded(config.TARE_DRIVER_INCLUDED_OPTIONS.YES);
            } else {
                getConfiguration().setTareDriverIncluded(config.TARE_DRIVER_INCLUDED_OPTIONS.NO);
            }

        }));


    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CREWFIRSTROWHORIZONTALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(rigOps.getVehicleCrewFirstRowHorizontalCOG)
        .setter(getConfiguration().setCrewFirstRowCOG)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setCrewFirstRowHorizontalCOGOverride(val);
            },
            function () {
                return rigOps.getVehicleCrewFirstRowHorizontalCOG();
            },
            function () {
                return getConfiguration().getCrewFirstRowHorizontalCOGOverride();
            })
        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
        //.overridePermission(hideForStandardVehicleUnlessLoadingAdmin)
        .overridePermission(function () {
            return !isNotStandardVehicle();
        })
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CREWFIRSTROWHORIZONTALCOG)));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CREWFIRSTROWVERTICALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(rigOps.getVehicleCrewFirstRowVerticalCOG)
        .setter(getConfiguration().setCrewFirstRowVerticalCOG)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setCrewFirstRowVerticalCOGOverride(val);
            },
            function () {
                return rigOps.getVehicleCrewFirstRowVerticalCOG();
            },
            function () {
                return getConfiguration().getCrewFirstRowVerticalCOGOverride();
            })
        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
        //.overridePermission(hideForStandardVehicleUnlessLoadingAdmin)
        .overridePermission(function () {
            return !isNotStandardVehicle();
        })
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CREWFIRSTROWVERTICALCOG)));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CREWFIRSTROWLATERALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(rigOps.getVehicleCrewFirstRowLateralCOG)
        .setter(getConfiguration().setCrewFirstRowLateralCOG)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setCrewFirstRowLateralCOGOverride(val);
            },
            function () {
                return rigOps.getVehicleCrewFirstRowLateralCOG();
            },
            function () {
                return getConfiguration().getCrewFirstRowLateralCOGOverride();
            })
        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
        //.overridePermission(hideForStandardVehicleUnlessLoadingAdmin)
        .overridePermission(function () {
            return !isNotStandardVehicle();
        })
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CREWFIRSTROWLATERALCOG)));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CREWSECONDROW, config.INPUT_TYPE.CHECKBOX)
        .visiblePermission(showCrewSecondRowOption)
        .setVal(getConfiguration().getCrewSecondRow())
        .addSubscription(function (val) {
            getConfiguration().setCrewSecondRow(val);
        }));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.NOOFCREWINSECONDROW, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.NONE)
        .getter(getConfiguration().getNoOfCrewInSecondRow)
        .setter(getConfiguration().setNoOfCrewInSecondRow)
        .visiblePermission(showCrewSecondRowSection)
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.NOOFCREWINSECONDROW)));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CREWSECONDROWHORIZONTALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(rigOps.getVehicleCrewSecondRowHorizontalCOG)
        .setter(getConfiguration().setCrewSecondRowCOG)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setCrewSecondRowHorizontalCOGOverride(val);
            },
            function () {
                return rigOps.getVehicleCrewSecondRowHorizontalCOG();
            },
            function () {
                return getConfiguration().getCrewSecondRowHorizontalCOGOverride();
            })
        .visiblePermission(isTemplateVehicleAndShowSecondRow)
        //.overridePermission(hideForStandardVehicleUnlessLoadingAdmin)
        .overridePermission(function () {
            return !isNotStandardVehicle();
        })
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CREWSECONDROWHORIZONTALCOG)));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CREWSECONDROWVERTICALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(rigOps.getVehicleCrewSecondRowVerticalCOG)
        .setter(getConfiguration().setCrewSecondRowVerticalCOG)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setCrewSecondRowVerticalCOGOverride(val);
            },
            function () {
                return rigOps.getVehicleCrewSecondRowVerticalCOG();
            },
            function () {
                return getConfiguration().getCrewSecondRowVerticalCOGOverride();
            })
        .visiblePermission(isTemplateVehicleAndShowSecondRow)
        //.overridePermission(hideForStandardVehicleUnlessLoadingAdmin)
        .overridePermission(function () {
            return !isNotStandardVehicle();
        })
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CREWSECONDROWVERTICALCOG)));

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CREWSECONDROWLATERALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(rigOps.getVehicleCrewSecondRowLateralCOG)
        .setter(getConfiguration().setCrewSecondRowLateralCOG)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setCrewSecondRowLateralCOGOverride(val);
            },
            function () {
                return rigOps.getVehicleCrewSecondRowLateralCOG();
            },
            function () {
                return getConfiguration().getCrewSecondRowLateralCOGOverride();
            })
        .visiblePermission(isTemplateVehicleAndShowSecondRow)
        //.overridePermission(hideForStandardVehicleUnlessLoadingAdmin)
        .overridePermission(function () {
            return !isNotStandardVehicle();
        })
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CREWSECONDROWLATERALCOG)));
}

function handleWheelbaseSpecifiedChanges(tempVehicle) {
    var specifiedWheelbaseAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.WHEELBASESPECIFIED);
    var calculatedWheelbaseSpecified = rigOps.getWheelbaseSpecified();
    var specifiedWheelbaseVal = specifiedWheelbaseAOG.value.value;
    if (tempVehicle.getWheelbaseOverride() === true) {

        var axle2FrontToAxle1Rear = tempVehicle.getAxle2FrontToAxle1Rear();


        tempVehicle.setAxle2FrontToAxle1Rear(axle2FrontToAxle1Rear - (calculatedWheelbaseSpecified - specifiedWheelbaseVal));
        tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() - (calculatedWheelbaseSpecified - specifiedWheelbaseVal));
    } else {
        tempVehicle.setWheelbaseSpecified(calculatedWheelbaseSpecified);

    }

}

function checkIfBodyAndBreakChassisLinkIfNecessary() {
    if (getActiveOffer().getRig().getVehicle().getAccessoryHolder().getBody() !== null) {
        var keepChassisFlushWithBodyAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.KEEPCHASSISFLUSHWITHBODY);
        if (keepChassisFlushWithBodyAOG.value.value === true) {
            keepChassisFlushWithBodyAOG.value.value = false;
            configurationActionResponseHandlerCallback(config.ACTION_TYPE.ATTRIBUTE_VALUE_CHANGED, config.ACTION_ID.CHASSIS_BODY_ALIGNMENT_BROKEN);
        }
    }
}

function updateOriginalTurningCircleValuesIfNecessary() {
    var tempVehicle = getActiveOffer().getRig().getVehicle();

    if (tempVehicle.getSteeringAngleInnerOverride() === false) {
        //var tempGVM = getConfiguration().getGVM();
        //tempVehicle.setGVM(tempGVM + deltaVal);
        //getOriginalOffer().getConfiguration().setGVM(tempGVM + deltaVal);
        getOriginalOffer().getRig().getVehicle().setTurningRadiusCurbToCurb(tempVehicle.getTurningRadiusCurbToCurb());
        getOriginalOffer().getRig().getVehicle().setTurningRadiusWallToWall(tempVehicle.getTurningRadiusWallToWall());
        getOriginalOffer().getRig().getVehicle().setSteeringAngleInner(tempVehicle.getSteeringAngleInner());
    }
}

//#region override observable subscriptions
watch(summaryNote, function (newValue) {
    if (getActiveOffer() !== undefined && getActiveOffer() !== '' && getActiveOffer() !== null) {
        getActiveOffer().setSummaryNote(newValue);
    }
});
//endregion override observable subscriptions



function getTotalPages() {
    return totalPages;
}

function setConfigDrawing(newValue) {
    configDrawing = newValue;
}

function setConfigDrawingTopView(newValue) {
    configDrawingTopView = newValue;
}

function setConfigDrawingCogView(newValue) {
    configDrawingCogView = newValue;
}

function setCostingChartDrawing(newValue) {
    costingChartDrawing = newValue;
}

function setPerformanceChartDrawing(newValue) {
    performanceChartDrawing = newValue;
}

/**
    * Get the text and number of lines that will be generated for the overall description in report that will be displayed in the header
    * @param {number} [maxNumberOfLines] - Max number of lines that will be displayed
    * @returns {object} - Object with text and number of lines that will be displayed
    */
function getOverallDescriptionForPdf(maxNumberOfLines) {
    var maxLengthOfString1Line = 250,
        maxLengthOfString2Lines = maxLengthOfString1Line * 2,
        lengthOfString = globals.getWidthOfText(offerManager.vehicleTitleComputed.value, 'Roboto', 12), // User Story 17565
        maxLengthOfString = globals.user.isLoadingAdministrator() === true ? maxLengthOfString2Lines : maxLengthOfString1Line,
        prevent2LineText = false;

    if (maxNumberOfLines === 1) {
        maxLengthOfString = maxLengthOfString1Line;
        prevent2LineText = true;
    }
    // One line is 250
    // Two Line max is 250 * 2
    if (lengthOfString > maxLengthOfString) {
        if(getNumberOfLinesFromString(offerManager.vehicleTitleComputed.value, maxLengthOfString) > 1 && prevent2LineText === false) {
            return get2LineText(offerManager.vehicleTitleComputed.value, maxLengthOfString);
        } else {
            return getTextWithEllipsis(offerManager.vehicleTitleComputed.value, maxLengthOfString);
        }
    } else {
        if(lengthOfString > maxLengthOfString1Line) {
            // Text does not require ellipsis but is longer than 1 line
            return get2LineTextWithoutEllipsis(offerManager.vehicleTitleComputed.value);
        } else {
            return {
                numberOfLines: 1,
                text: offerManager.vehicleTitleComputed.value
            };
        }

    }

    function getTextWithEllipsis(stringToUse, maxLengthOfString) {
        var stringToTest = stringToUse.substring(0, stringToUse.length - 3);
        var lengthOfStringToTest = globals.getWidthOfText(stringToTest.concat('...'), 'Roboto', 12); // User Story 17565
        if (lengthOfStringToTest > maxLengthOfString) {
            return getTextWithEllipsis(stringToTest, maxLengthOfString);
        } else {
            //return stringToTest.concat('...');
            return {
                numberOfLines: lengthOfStringToTest > maxLengthOfString1Line ? 2 : 1,
                text: stringToTest.concat('...')
            };
        }
    }

    /**
     * Get the number of lines that will be generated for the overall description in report that will be displayed in the header
     * @param {string} stringToUse - String to test
     * @param {number} maxLengthOfString - Max length of string
     * @returns {number} - Number of lines that will be displayed
     */
    function getNumberOfLinesFromString(stringToUse, maxLengthOfString) {
        var stringToTest = stringToUse;
        var lengthOfStringToTest = globals.getWidthOfText(stringToTest, 'Roboto', 12);
        if (lengthOfStringToTest > maxLengthOfString) {
            return 2;
        } else {
            return 1;
        }
    }

    /**
     * Get the text (including ellipsis if necessary) and number of lines that will be generated for the overall description in report that will be displayed in the header
     * @param {string} stringToUse - String to display
     * @param {number} maxLengthOfString - Max length of string
     * @returns {object} - Object with text and number of lines that will be displayed
     */
    function get2LineText(stringToUse, maxLengthOfString) {
        var stringObject = getTextWithEllipsis(stringToUse, maxLengthOfString);
        var stringToTest = stringObject.text,
            lengthOfStringToTest = stringToTest.length,
            line1 = stringToTest.substring(0, lengthOfStringToTest / 2),
            line2 = stringToTest.substring(lengthOfStringToTest / 2, lengthOfStringToTest);
        return {
            numberOfLines: 2,
            textLine1: line1,
            textLine2: line2
        }
    }

    function get2LineTextWithoutEllipsis(stringToUse) {
        var stringToTest = stringToUse,
            lengthOfStringToTest = stringToTest.length,
            line1 = stringToTest.substring(0, lengthOfStringToTest / 2),
            line2 = stringToTest.substring(lengthOfStringToTest / 2, lengthOfStringToTest);
        return {
            numberOfLines: 2,
            textLine1: line1,
            textLine2: line2
        }
    }
}

function getBaseVehicleDescriptionForPdf() {
    return vehicleDescriptionComputed.value.length > 50 ? vehicleDescriptionComputed.value.substring(0, 47).concat('...') : vehicleDescriptionComputed.value;
}

/**
    * Show toast if the user adds a standard body/accessory/payload/trailer if they haven't already checked the don't show again checkbox
    * @param {string} textToInsert - Text to include in message
    */
function showStandardItemToast(editLevel, textToInsert) {
    var toasterOptions = config.toastrOptionsAppUpdate,
        messageCode;

    //if (globals.user.isLoadingAdministrator() === true) {
    if (editLevel === config.ITEM_EDIT_LEVEL_OPTIONS.NO_EDIT) {
        messageCode = '3077';
    } else {
        messageCode = '3076';
    }

    if (standardItemToast !== null) {
        standardItemToast.remove();
        toastr.clear(standardItemToast, {
            force: true
        });
        standardItemToast = null;
    }

    toastr.options = toasterOptions;
    standardItemToast = toastr.info(config.getTranslationText(messageCode, [textToInsert.toLowerCase(), textToInsert.toLowerCase(), textToInsert.toLowerCase()]) + '<br /><br /><input id="dont-show-again-checkbox" type="checkbox" class="toast-checkbox" ></input><label for="dont-show-again-checkbox" class="toast-checkbox-label">' + config.getTranslationText('3078') + '</label>');

    if (standardItemToast !== undefined) {
        if (standardItemToast.find('#dont-show-again-checkbox').length) {
            standardItemToast.delegate('#dont-show-again-checkbox', 'click', function () {
                if ($(this).is(':checked')) {
                    dismissDontAskMeAgain(config.APP_OPTION_ITEMS.STANDARD_ITEM_DONT_ASK_ME_AGAIN.Code);
                    setTimeout(function () {
                        standardItemToast.remove();
                        toastr.clear(standardItemToast, {
                            force: true
                        });
                        standardItemToast = null;
                    }, 500);
                }
            });
        }
    }
}

function clearStandardItemToast() {
    if (standardItemToast !== null) {
        var tempHideDuration = toastr.options.hideDuration;
        toastr.clear(standardItemToast, {
            force: true
        });
        standardItemToast = null;
        toastr.options.hideDuration = tempHideDuration;
    }
}

function dismissDontAskMeAgain(itemCodeToDismiss) {
    var dfd = $.Deferred();
    $.when(dataManager.dismissAppOptionItem(itemCodeToDismiss)).done(function (transactionResult) {
        if (transactionResult.ReturnCode === 1) {
            // Update the array on user and update user on localStorage
            globals.user.dismissAppOptionItem(itemCodeToDismiss);
            if (itemCodeToDismiss === config.APP_OPTION_ITEMS.REFERRAL_DONT_ASK_ME_AGAIN.Code) {
                dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.REFERRAL_DONT_ASK_AGAIN));
            }
        } else {
            dataManager.log("offerManager.dismissDontAskMeAgain, dataManager.dismissAppOptionItem, done, silent", transactionResult.Message, 'offerManager', config.LOG_MESSAGE_TYPE.ERROR);
        }
        dfd.resolve({});
    })
        .fail(function (error) {
            dataManager.log("offerManager.dismissDontAskMeAgain, dataManager.dismissAppOptionItem, fail, silent", error, 'offerManager', config.LOG_MESSAGE_TYPE.ERROR);
            self.displayDashboardOpen(false);
            dfd.resolve({});
        });

    return dfd.promise();
}

function getDefaultFromAxle1Front(accessory) {
    var tempVehicle = getActiveOffer().getRig().getVehicle();
    if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY && accessory.getType() === config.BODY_TYPES.HOOKLIFT && accessory.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
        var tempHooklift = tempVehicle.getAccessoryHolder().getHooklift();
        return tempVehicle.getBumperToBackOfCab() + tempHooklift.getHorizontalGap() + tempHooklift.getHookOffset() - tempVehicle.getFrontOverhang();
        // return 0;
    } else {
        return tempVehicle.getCabGapRecommendedMin() + tempVehicle.getBumperToBackOfCab() - tempVehicle.getFrontOverhang();
    }
}

function addBodyToCurrentChassis(body, path) {

    var payloadHolderToUse, originalPayloadHolderToUse, chassisObjectToUse, originalChassisObjectToUse;
    if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
        payloadHolderToUse = getActiveOffer().getRig().getVehicle().getPayloadHolder();
        originalPayloadHolderToUse = getOriginalOffer().getRig().getVehicle().getPayloadHolder();
        chassisObjectToUse = getActiveOffer().getRig().getVehicle();
        originalChassisObjectToUse = getOriginalOffer().getRig().getVehicle();
    } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        payloadHolderToUse = getActiveOffer().getRig().getTrailer1().getPayloadHolder();
        originalPayloadHolderToUse = getOriginalOffer().getRig().getTrailer1().getPayloadHolder();
        chassisObjectToUse = getActiveOffer().getRig().getTrailer1();
        originalChassisObjectToUse = getOriginalOffer().getRig().getTrailer1();
    } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
        payloadHolderToUse = getActiveOffer().getRig().getTrailer2().getPayloadHolder();
        originalPayloadHolderToUse = getOriginalOffer().getRig().getTrailer2().getPayloadHolder();
        chassisObjectToUse = getActiveOffer().getRig().getTrailer2();
        originalChassisObjectToUse = getOriginalOffer().getRig().getTrailer2();
    }

    body.setHorizontalGap(getBodyCabGapIfAny(path));
    body.setVerticalGap(getBodyVerticalGap(path))

    if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        //if (body.getType() === config.BODY_TYPES.HOOKLIFT && body.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
        //    var tempHooklift = chassisObjectToUse.getAccessoryHolder().getHooklift();
        //    body.setFromAxle1Front(chassisObjectToUse.getBumperToBackOfCab() + tempHooklift.getHorizontalGap() + tempHooklift.getHookOffset() - chassisObjectToUse.getFrontOverhang());
        //} else {
        //    body.setFromAxle1Front(chassisObjectToUse.getCabGapRecommendedMin() + chassisObjectToUse.getBumperToBackOfCab() - chassisObjectToUse.getFrontOverhang());
        //}
        body.setFromAxle1Front(getDefaultFromAxle1Front(body));
    }

    setParent(body, path);
    if (body.getLength() === 0 || (body.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && getActiveOffer().getKeepChassisFlushWithBody() === true && rigOps.getChassisTypeUsingPath(path) === config.CHASSIS_OBJECT_TYPES.VEHICLE)) {
        body.setLength(getBodyLength(path));
    }

    if (body.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
        body.setSubframeLength(getBodySubframeLength(path));
    }
    body.setSubframeHeight(getBodySubframeHeight(body, path));
    body.setWidth(getBodyWidth(body, path));

    //originalBodyCopy.setHorizontalGap(getConfiguration().getCabGapRecommendedMin());
    //if (originalBodyCopy.getLength() === 0) {
    //    originalBodyCopy.setLength(getConfiguration().getCabToChassisEnd() - getConfiguration().getCabGapRecommendedMin());
    //}

    var originalBodyCopy = body.clone(true);

    

    var tempCopyPayloadObject = setupDefaultPayloadForBody(payloadHolderToUse, body);
    //payloadHolderToUse.setPayloadType(getActiveOffer().getRig().getVehicle().getPayloadHolder().getPayloadType());
    payloadHolderToUse.setPayloadType(getPayloadTypeToUse());

    chassisObjectToUse.getAccessoryHolder().add(body);
    originalChassisObjectToUse.getAccessoryHolder().add(originalBodyCopy);

    //var maximisedLoad = rigOps.getMaximisedLoad();

    //tempCopyPayloadObject.setMass(maximisedLoad);
    //if (getActiveOffer().getRig().getVehicle().getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.SIMPLE) {
    //    clearActiveLegislationQuickLookupObjects();
    //    if (needToMaximise(rigOps)) {
    //        rigOps.maximisePayloads();
    //    }
    //}
    var tempOriginalPayloadObject = tempCopyPayloadObject.clone(true);
    originalPayloadHolderToUse.add(tempOriginalPayloadObject);
    generateDataForActiveLegislations();
    addPayloadObservables([tempCopyPayloadObject], path.split('.')[0] + '.' + config.ACCESSORY_TYPES.PAYLOAD);
    //createPayloadObservables(tempCopyPayloadObject, path.split('.')[0] + '.' + config.ACCESSORY_TYPES.PAYLOAD);

    updateCostingItems();
    createAccessoryObjectWithObservables(body, path);
    if (body.getType() === config.BODY_TYPES.TIMBER) {
        if (body.getSource() === config.ACCESSORY_SOURCE_TYPES.CUSTOM) {
            addCustomUprightsObservables(body, path);
        } else {
            undoHandler.disableUndoOpCreation();
            addDefaultUprightsToTimberBody(body, path);
            nextTick(function () {
                undoHandler.enableUndoOpCreation();
            });
        }
    }
    //dataManager.sendEventInfoToIntercom(globals.getIntercomObject(config.INTERCOM_EVENT.ADDED_BODY, body.getIntercomMetadata()));
    dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.ADDED_BODY, body.getIntercomMetadata(rigOps.getChassisTypeUsingPath(path))));
    
    
    doCommonUpdateActions();
    notifyValidationListeners();
    if(typeof objectAddedListenerCallback === 'function') {
        objectAddedListenerCallback(config.OBJECT_TYPES.ACCESSORY, body, path);
    }
    clearPerformanceResults();
    unsavedChangesOnOffer.value = true;
    if (body.getSource() === config.ACCESSORY_SOURCE_TYPES.STANDARD && globals.user.displayStandardItemMessage() === true) {
        showStandardItemToast(body.getEditLevel(), globals.getAccessoryTypeTranslation(body.getAccessoryType(), body.getAccessorySubType()));
    }

    function getBodyCabGapIfAny(path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1 && body.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
            return getConfiguration().getCabGapRecommendedMin();
        } else {
            return 0;
        }
    }

    function getBodyVerticalGap(path) {
        switch (rigOps.getChassisTypeUsingPath(path)) {
            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                return getActiveOffer().getRig().getVehicle().getChassisHeightMax();
            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                return getActiveOffer().getRig().getTrailer1().getChassisHeight();
            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                return getActiveOffer().getRig().getTrailer2().getChassisHeight();
        }
        return 0;
    }

    function getBodyLength(path) {
        var bodyLength = 0;
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            if (body.getLengthType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
                bodyLength = getConfiguration().getCabToChassisEnd() - getConfiguration().getCabGapRecommendedMin() - body.getTailboardThickness() - body.getHeadboardThickness() - body.getRampLength() - body.getRampPlatesProtrusion();
            } else {
                bodyLength = getConfiguration().getCabToChassisEnd() - getConfiguration().getCabGapRecommendedMin() - body.getRampLength() - body.getRampPlatesProtrusion();
            }
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            var tempTrailer1 = getActiveOffer().getTrailer1();
            if (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI) {
                bodyLength = tempTrailer1.getOverallLengthExclAccessories();
            } else if (tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
                bodyLength = tempTrailer1.getFrontToStartOfTaper();
            } else if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR || tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                bodyLength = tempTrailer1.getOverallLengthExclAccessoriesAndDrawbar();
            } else {
                bodyLength = 0;
            }
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            var tempTrailer2 = getActiveOffer().getTrailer2();
            if (tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                bodyLength = tempTrailer2.getOverallLengthExclAccessories();
            } else if (tempTrailer2.getType() === config.TRAILER_TYPES.INTERLINK) {
                bodyLength = tempTrailer2.getFrontToStartOfTaper();
            } else if (tempTrailer2.getType() === config.TRAILER_TYPES.DRAWBAR || tempTrailer2.getType() === config.TRAILER_TYPES.PUP) {
                bodyLength = tempTrailer2.getOverallLengthExclAccessoriesAndDrawbar();
            } else {
                bodyLength = 0;
            }
            //bodyLength = getActiveOffer().getTrailer2().getOverallLengthExclAccessories();
        }

        return bodyLength;
    }

    function getBodySubframeLength(path) {
        var bodySubframeLength;
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            if (body.getType() === config.BODY_TYPES.HOOKLIFT && body.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
                bodySubframeLength = body.getActualLength() - (getConfiguration().getRearOverhang() - getConfiguration().getRearOverhangManufacturerMin());
            } else {
                bodySubframeLength = body.getActualLength();
            }
        } else {
            bodySubframeLength = body.getActualLength();
        }
        return bodySubframeLength;
    }

    function getBodyWidth(body, path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            if (body.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
                return body.getWidthType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL ? getConfiguration().getOverallWidth() : getConfiguration().getOverallWidth() - body.getSidewallRight() - body.getSidewallLeft();
            } else {
                return body.getWidth();
            }
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            if (body.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
                return body.getWidthType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL ? getActiveOffer().getTrailer1().getOverallWidth() : getActiveOffer().getTrailer1().getOverallWidth() - body.getSidewallRight() - body.getSidewallLeft();
            } else {
                return body.getWidth();
            }
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            if (body.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
                return body.getWidthType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL ? getActiveOffer().getTrailer2().getOverallWidth() : getActiveOffer().getTrailer2().getOverallWidth() - body.getSidewallRight() - body.getSidewallLeft();
            } else {
                return body.getWidth();
            }
        }
        return body.getWidth();
    }

    function getBodySubframeHeight(body, path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1 || path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            return 0;
        } else {
            return body.getSubframeHeight();
        }
    }

    /**
        * Returns DETAILED if any chassis object has a payload type set to DETAILED. Otherwise returns SIMPLE
        * @returns {string} Returns either SIMPLE/DETAILED value from PAYLOAD_TYPES enum in config
        */
    function getPayloadTypeToUse() {
        //var payloadTypeToReturn = config.PAYLOAD_TYPES.SIMPLE;
        //getActiveOffer().getRig().getChassisObjects().forEach(function (chassisObject) {
        //    if (chassisObject.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {
        //        payloadTypeToReturn = config.PAYLOAD_TYPES.DETAILED;
        //    }
        //});
        //return payloadTypeToReturn;

        var chassisObjectsArray = getActiveOffer().getRig().getChassisObjects();
        for (var i = 0; i < chassisObjectsArray.length; i++) {
            if (chassisObjectsArray[i].getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {
                return config.PAYLOAD_TYPES.DETAILED;
            }
        }
        return config.PAYLOAD_TYPES.SIMPLE;
    }
}

function setParent(accessory, path) {
    if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
        accessory.setParent(config.CHASSIS_OBJECT_TYPES.VEHICLE);
    } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        accessory.setParent(config.CHASSIS_OBJECT_TYPES.TRAILER1);
    } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
        accessory.setParent(config.CHASSIS_OBJECT_TYPES.TRAILER2);
    }
}

function getBodyStub(id, source, accessoryType, accessLevel) {
    try {
        return availableBodies.filter(function (bodyStub) {
            return id === bodyStub.getId() && accessoryType === bodyStub.getAccessoryType() && source === bodyStub.getSource() && accessLevel === bodyStub.getAccessLevel();
        })[0] || null;
    } catch (bodyNotFoundEx) {
        throw 1;
    }
}

/**
    * Checks availableBodies array for custom hooklifts
    * @returns {boolean} - True if there is a custom hooklift body present else False
    */
function doesUserHaveSavedHookliftBodies() {
    var savedHooklifts = availableBodies.filter(function (bodyStub) {
        return bodyStub.getType() === config.BODY_TYPES.HOOKLIFT && bodyStub.getSource() === config.ACCESSORY_SOURCE_TYPES.CUSTOM;
    });

    return savedHooklifts.length > 0;
}

function getAccessoryStub(id, source, accessoryType, accessLevel) {
    try {
        return availableAccessories.filter(function (accessoryStub) {
            return id === accessoryStub.getId() && accessoryType === accessoryStub.getAccessoryType() && source === accessoryStub.getSource() && accessLevel === accessoryStub.getAccessLevel();
        })[0] || null;
    } catch (accessoryNotFoundEx) {
        throw 1;
    }
}

function getPayloadStub(id, source, accessoryType, accessLevel) {
    try {
        return availablePayloads.filter(function (payloadStub) {
            return id === payloadStub.getId() && accessoryType === payloadStub.getAccessoryType() && source === payloadStub.getSource() && accessLevel === payloadStub.getAccessLevel();
        })[0] || null;
    } catch (payloadNotFoundEx) {
        throw 1;
    }
}

function getTrailerStub(id, source, accessoryType, accessLevel) {
    try {
        return availableTrailers.filter(function (trailerStub) {
            return id === trailerStub.getId() && accessoryType === trailerStub.getAccessoryType() && source === trailerStub.getSource() && accessLevel === trailerStub.getAccessLevel();
        })[0] || null;
    } catch (trailerNotFoundEx) {
        throw 1;
    }
}

function getBody(bodyStub, path) {
    var dfd = $.Deferred();
    $.when(dataManager.getBody(bodyStub.getId(), bodyStub.getSource(), bodyStub.getAccessLevel()), dataManager.getBodyMasses()).then(function (bodyAsJSON, bodyMasses) {
        var body = null;
        if (bodyAsJSON.Result.ReturnCode === 1) {
            //// Get rid of superfluous bady masses
            //var availableBodyMasses = bodyMasses.bodyMasses.filter(function(bodyMass){
            //    return bodyMass.BodyType === bodyAsJSON.Body.Type;
            //});
            //// Sort remaining body masses
            //availableBodyMasses.sort(function(a, b){
            //    if (a.Material === b.Material) {
            //        if (a.FloorType > b.FloorType) {
            //            return 1;
            //        }
            //        if (a.FloorType < b.FloorType) {
            //            return -1;
            //        }
            //        return 0;
            //    } else {
            //        if (a.Material > b.Material) {
            //        return 1;
            //        }
            //        if (a.Material < b.Material) {
            //            return -1;
            //        }
            //        return 0;
            //    }
            //});
            var availableBodyMasses = getFilteredBodyMasses(bodyMasses.bodyMasses, bodyAsJSON.Body.Type);
            if (bodyAsJSON.Body.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM && bodyAsJSON.Body.KeepChassisFlushWithBody !== undefined && rigOps.getChassisTypeUsingPath(path) === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                getActiveOffer().setKeepChassisFlushWithBody(bodyAsJSON.Body.KeepChassisFlushWithBody);
            }

            // If a standard body is selected then the payload vertical and lateral COG overrides are relevant for TIM. (Bug 15738)
            // The override values on the app are set by the user and are not relevant for TIM. They are relevant for the offer
            if (bodyAsJSON.Body.Source === config.VEHICLE_SOURCE_TYPES.STANDARD) {
                bodyAsJSON.Body.PayloadVerticalCOGOverride = false;
                bodyAsJSON.Body.PayloadLateralCOGOverride = false;
            }

            if (isCostingAvailableForOffer() === true) {
                //costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType

                bodyAsJSON.Body.CostingListPrice = 0;
                bodyAsJSON.Body.CostingListPriceOverride = false;
                bodyAsJSON.Body.CostingDiscount = 0;
                if (getActiveOffer().getCosting() !== undefined && getActiveOffer().getCosting() !== null) {
                    bodyAsJSON.Body.CostingPeriod = getActiveOffer().getCosting().getVehicleCommonGroupFinancePeriod();
                    bodyAsJSON.Body.CostingInterestRate = getActiveOffer().getCosting().getCountryInterestRate();
                } else {
                    bodyAsJSON.Body.CostingPeriod = 0;
                    bodyAsJSON.Body.CostingInterestRate = 0;
                }
                bodyAsJSON.Body.CostingResidualRate = 0;
                bodyAsJSON.Body.CostingResidualRateType = config.RESIDUAL_RATE_TYPES.NET_PRICE;
            }

            body = createBodyObject(bodyAsJSON.Body, null, availableBodyMasses);
            if(bodyAsJSON.Body.DecompressedGraphicBlob && utils.isValidDecompressedGraphicFile(bodyAsJSON.Body.DecompressedGraphicBlob)) {
                body.setDecompressedGraphicBlob(bodyAsJSON.Body.DecompressedGraphicBlob);
            }
            if(bodyAsJSON.Body.DecompressedTopViewGraphicBlob && utils.isValidDecompressedGraphicFile(bodyAsJSON.Body.DecompressedTopViewGraphicBlob)) {
                body.setDecompressedTopViewGraphicBlob(bodyAsJSON.Body.DecompressedTopViewGraphicBlob);
            }
            //body.setAvailableBodyMasses(availableBodyMasses);

            var cached = body.getCached();
            if (bodyStub.getAccessLevel() === config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY) {
                cached = 0;
            }
            bodyStub.setCached(cached);
            body.setAccessLevel(bodyStub.getAccessLevel());
        } else if (bodyAsJSON.Result.ReturnCode === -2 || bodyAsJSON.Result.ReturnCode === -3) {
            //clean up stub - delete in memory and from the cache
            removeBodyFromAvailableBodiesArray(bodyStub);
            dataManager.remove(config.OPERATION.RemoveLocalBody, bodyStub.getId() + '_' + bodyStub.getAccessoryType() + '_' + bodyStub.getSource());
        }
        dfd.resolve({
            Response: bodyAsJSON,
            Body: body
        });
    })
        .fail(function (error) {
            dfd.reject(error);
        });

    

    return dfd.promise();
}

function getFilteredBodyMasses(bodyMassesArray, bodyType) {
    // Get rid of superfluous bady masses
    var availableBodyMasses = bodyMassesArray.filter(function (bodyMass) {
        return bodyMass.BodyType === bodyType;
    });
    // Sort remaining body masses
    availableBodyMasses.sort(function (a, b) {
        if (a.Material === b.Material) {
            if (a.FloorType > b.FloorType) {
                return 1;
            }
            if (a.FloorType < b.FloorType) {
                return -1;
            }
            return 0;
        } else {
            if (a.Material > b.Material) {
                return 1;
            }
            if (a.Material < b.Material) {
                return -1;
            }
            return 0;
        }
    });
    return availableBodyMasses;
}

function addCustomUprightsObservables(body, path) {
    path = path + '.' + config.OBJECT_TYPES.UPRIGHTS;

    body.getBodyUprightHolder().getBodyUprights().forEach(function (upright) {
        createBodyUprightsObjectObservables(upright, path);
        var skipGraphicsUpdate = true;
        if(typeof objectAddedListenerCallback === 'function') {
            objectAddedListenerCallback(config.OBJECT_TYPES.UPRIGHTS, upright, path, skipGraphicsUpdate);
        }
    });
}

function addDefaultUprightsToTimberBody(timberBody, path) {

    path = path + '.' + config.OBJECT_TYPES.UPRIGHTS;
    var defaultWidth = 150,
        defaultHeight = timberBody.getHeadboardHeight(),
        defaultNumberOfUprights = 4,
        defaultSpacing = ((timberBody.getActualLength() - timberBody.getHeadboardThickness()) - defaultNumberOfUprights * defaultWidth) / (defaultNumberOfUprights + 1);


    for (var i = 0; i < 4; i++) {
        var newUpright = new BodyUpright(globals.nextId(), i + 1, (defaultSpacing * (i + 1)) + (defaultWidth * i), defaultWidth, defaultHeight);
        timberBody.getBodyUprightHolder().add(newUpright);
        createBodyUprightsObjectObservables(newUpright, path);
        var skipGraphicsUpdate = true;
        if(typeof objectAddedListenerCallback === 'function') {
            objectAddedListenerCallback(config.OBJECT_TYPES.UPRIGHTS, newUpright, path, skipGraphicsUpdate);
        }
    }
    /*        newUpright = New FileLoadingAccessoryBodyUpright
            newUpright.Height = defaultHeight
            newUpright.Width = defaultWidth
            newUpright.InsideFrontToUpright = defaultSpacing
            .Uprights.Add(newUpright)

            newUpright = New FileLoadingAccessoryBodyUpright
            newUpright.Height = defaultHeight
            newUpright.Width = defaultWidth
            newUpright.InsideFrontToUpright = defaultSpacing + defaultWidth + defaultSpacing
            .Uprights.Add(newUpright)

            newUpright = New FileLoadingAccessoryBodyUpright
            newUpright.Height = defaultHeight
            newUpright.Width = defaultWidth
            newUpright.InsideFrontToUpright = defaultSpacing + defaultWidth + defaultSpacing + defaultWidth + defaultSpacing
            .Uprights.Add(newUpright)

            newUpright = New FileLoadingAccessoryBodyUpright
            newUpright.Height = defaultHeight
            newUpright.Width = defaultWidth
            newUpright.InsideFrontToUpright = defaultSpacing + defaultWidth + defaultSpacing + defaultWidth + defaultSpacing + defaultWidth + defaultSpacing
            .Uprights.Add(newUpright)
    */

}

function setupDefaultPayloadForBody(payloadHolder, body) {


    var payloadHeight = 0;

    var tempBodyType = body.getType();
    switch (tempBodyType) {
        case config.BODY_TYPES.FLATDECK:
        case config.BODY_TYPES.VAN:
        case config.BODY_TYPES.TAUTLINER:
        case config.BODY_TYPES.TANKER:
        case config.BODY_TYPES.PIGCARRIER:
        case config.BODY_TYPES.SHEEPCARRIER:
        case config.BODY_TYPES.CATTLECARRIER:
        case config.BODY_TYPES.COMPACTOR:
        case config.BODY_TYPES.ROLLBACK:
        case config.BODY_TYPES.HOOKLIFT:
        case config.BODY_TYPES.OTHER:
            payloadHeight = body.getActualExternalHeight() - body.getSubframeHeight() - body.getSubstructureHeight() - body.getRoofThickness();
            break;
        case config.BODY_TYPES.SERVICE:
            payloadHeight = body.getActualExternalHeight();
            break;
        case config.BODY_TYPES.TIPPER:
            payloadHeight = body.getActualRearDimensionHeight() - body.getSubstructureHeight();
            break;
        case config.BODY_TYPES.TIMBER:
        case config.BODY_TYPES.DROPSIDE:
            payloadHeight = Math.max(body.getHeadboardHeight(), body.getTailboardHeight());
            break;
        case config.BODY_TYPES.BEAVERTAIL:
            payloadHeight = Math.max(body.getHeadboardHeight(), body.getTailboardHeight(), body.getHeightOfRampPlatesProtrudingAboveDeck(body.getRampAngle(), body.getRampLength(), body.getRampPlatesHeight()), body.getActualInternalHeight());
            break;
    }

    return setUpDefaultPayload(body.getSource(), payloadHolder, body.getActualInternalLength(), body.getActualInternalWidth(), payloadHeight, body.getPayloadHorizontalCOG(), false, body.getPayloadVerticalCOG(), body.getPayloadVerticalCOGOverride(), body.getPayloadLateralCOG(), body.getPayloadLateralCOGOverride(), body.getPayloadCOGType(), body.getPayloadVerticalCOGType(), body.getPayloadLateralCOGType());
}

function setupDefaultPayloadForCompleteVehicle(vehicle) {
    return setUpDefaultPayload(vehicle.getSource(), vehicle.getPayloadHolder(), vehicle.getBodyLengthInternal(), vehicle.getLoadCompartmentWidth(), vehicle.getBodyHeightInternal(), vehicle.getPayloadHorizontalCOG(), false, vehicle.getPayloadVerticalCOG(), vehicle.getPayloadVerticalCOGOverride(), vehicle.getPayloadLateralCOG(), vehicle.getPayloadLateralCOGOverride(), vehicle.getPayloadCOGType());
}

function setupDefaultPayloadForStandardTrailer(trailer) { 
    return setUpDefaultPayload(trailer.getSource(), trailer.getPayloadHolder(), trailer.getPayloadLength(), trailer.getOverallWidth(), trailer.getOverallHeight() - trailer.getChassisHeight(), trailer.getPayloadHorizontalCOG(), false, trailer.getPayloadVerticalCOG(), false, trailer.getPayloadLateralCOG(), false, trailer.getPayloadCOGType(), trailer.getPayloadVerticalCOGType(), trailer.getPayloadLateralCOGType());
}

function setUpDefaultPayload(source, payloadHolder, length, width, height, horizontalCOG, horizontalCOGOverride, verticalCOG, verticalCOGOverride, lateralCOG, lateralCOGOverride, cogType, verticalCOGType, lateralCOGType) {
    return payloadHolder.initialiseSimplePayload(source, length,
        width,
        height,
        horizontalCOG,
        horizontalCOGOverride,
        verticalCOG,
        verticalCOGOverride,
        lateralCOG,
        lateralCOGOverride,
        cogType,
        verticalCOGType,
        lateralCOGType);
}

function unpinAllEquipmentItemDimensions() {
    getActiveOffer().getRig().getChassisObjects().forEach(function (chassisObject) {
        chassisObject.getAccessoryHolder().getAccessories().forEach(function (accessory) {
            if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY && accessory.getAccessoryType() !== config.ACCESSORY_TYPES.FIFTH_WHEEL && accessory.getAccessoryType() !== config.ACCESSORY_TYPES.HITCH && accessory.getIsPinned()) {
                accessory.setIsPinned(false);
            }
        });
    });
}

function addAccessoryToCurrentChassis(accessory, path) {

    clearActiveLegislationQuickLookupObjects();

    var chassisObjectToUse = rigOps.getChassisObjectFromPath(path),
        originalChassisObjectToUse = rigOps.getOriginalChassisObjectFromPath(path);

    setParent(accessory, path);

    accessory.setHorizontalGap(getAccessoryHorizontalGap(accessory, path));
    accessory.setVerticalGap(getAccessoryVerticalGap(accessory, path));
    

    if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.TAILLIFT) {
        if (accessory.getType() === config.TAILLIFT_TYPES.COLUMN_UPPER_BOX && accessory.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
            accessory.setPlatformHeight(getColumnUpperBoxTailliftHeight(path));
        }
    } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FIFTH_WHEEL) {
        if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && (chassisObjectToUse.getAccessoryHolder().getBody() !== null || chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE)) {
            chassisObjectToUse.getPayloadHolder().clearAllPayload();
        }
        
        accessory.setIsPinned(true);
    } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.HITCH) {
        
        accessory.setIsPinned(true);
    } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER && chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {

        //accessory.setFromAxle1Front(chassisObjectToUse.getBumperToBackOfCab() - chassisObjectToUse.getFrontOverhang());
        accessory.setFromAxle1Front(rigOps.calculateFromAxle1Front(accessory));
    } else if ((accessory.getAccessoryType() === config.ACCESSORY_TYPES.CRANE || accessory.getAccessoryType() === config.ACCESSORY_TYPES.HOOKLIFT) && chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {

        accessory.setFromAxle1Front(chassisObjectToUse.getCabGapRecommendedMin() + chassisObjectToUse.getBumperToBackOfCab() - chassisObjectToUse.getFrontOverhang());
    }


    if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER && accessory.getAccessorySubType() === config.ACCESSORY_TYPES.BULLBAR) {
        getActiveOffer().getRig().getVehicle().setBullbarAdded(true);
    }
    if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER && accessory.getAccessorySubType() === config.ACCESSORY_TYPES.SNOWPLOW) {
        getActiveOffer().getRig().getVehicle().setSnowploughAdded(true);
    }
    if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FIFTH_WHEEL) {
        getActiveOffer().getRig().getVehicle().setFifthWheelAdded(true);
    }
    if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.CRANE && chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        getActiveOffer().getRig().getVehicle().setCraneAdded(true);
    }

    chassisObjectToUse.getAccessoryHolder().add(accessory);
    originalChassisObjectToUse.getAccessoryHolder().add(accessory.clone(true));

    //doActionsAfterSuccesfulValidation(config.OBJECT_TYPES.ACCESSORY);
    updatePayload();
    updateCostingItems();
    createAccessoryObjectWithObservables(accessory, path);
    //dataManager.sendEventInfoToIntercom(globals.getIntercomObject(config.INTERCOM_EVENT.ADDED_EQUIPMENT, accessory.getIntercomMetadata()));
    dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.ADDED_EQUIPMENT, accessory.getIntercomMetadata(rigOps.getChassisTypeUsingPath(path))));
    doCommonUpdateActions();
    notifyValidationListeners();
    if(typeof objectAddedListenerCallback === 'function') {
        objectAddedListenerCallback(config.OBJECT_TYPES.ACCESSORY, accessory, path);
    }
    clearPerformanceResults();
    unsavedChangesOnOffer.value = true;
    if (accessory.getSource() === config.ACCESSORY_SOURCE_TYPES.STANDARD && globals.user.displayStandardItemMessage() === true) {
        showStandardItemToast(accessory.getEditLevel(), globals.getAccessoryTypeTranslation(accessory.getAccessoryType(), accessory.getAccessorySubType()));
    }

    function getColumnUpperBoxTailliftHeight(path) {
        var bodyToUse;
        switch (rigOps.getChassisTypeUsingPath(path)) {
            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                bodyToUse = getActiveOffer().getRig().getVehicle().getAccessoryHolder().getBody();
                break;
            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                bodyToUse = getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getBody();
                break;
            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                bodyToUse = getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getBody();
                break;
        }

        return bodyToUse.getActualExternalHeight() - bodyToUse.getSubframeHeight();
    }

    function getAccessoryHorizontalGap(accessory, path) {
        var accessoryType = accessory.getAccessoryType();

        switch (rigOps.getChassisTypeUsingPath(path)) {
            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                if (accessoryType === config.ACCESSORY_TYPES.TAILLIFT) {
                    if (getActiveOffer().getRig().getVehicle().getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && getActiveOffer().getRig().getVehicle().getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                        return getActiveOffer().getRig().getVehicle().getOverallLength() - getActiveOffer().getRig().getVehicle().getBumperToBackOfCab();
                    } else {
                        return rigOps.getBodyGap(config.CHASSIS_OBJECT_TYPES.VEHICLE) + getActiveOffer().getRig().getVehicle().getAccessoryHolder().getBody().getActualLength();
                    }
                } else if (accessoryType === config.ACCESSORY_TYPES.FIFTH_WHEEL) {
                    return 0;
                } else if (accessoryType === config.ACCESSORY_TYPES.HITCH) {
                    var tempVehicle = getActiveOffer().getRig().getVehicle();
                        
                    return tempVehicle.getWheelbaseTheoreticalEnd() + tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle();
                } else if (accessoryType === config.ACCESSORY_TYPES.OTHER) {
                    switch (accessory.getAccessorySubType()) {
                        case config.ACCESSORY_TYPES.OTHER:
                            return 0;
                        case config.ACCESSORY_TYPES.BULLBAR:
                        case config.ACCESSORY_TYPES.SNOWPLOW:
                            return -getActiveOffer().getRig().getVehicle().getBumperToBackOfCab() - accessory.getLength();
                        case config.ACCESSORY_TYPES.FAIRING:
                            if (getActiveOffer().getRig().getVehicle().getAccessoryHolder().getBody() !== null) {
                                return rigOps.getBodyGap(config.CHASSIS_OBJECT_TYPES.VEHICLE) - accessory.getLength();
                            } else {
                                return 0;
                            }
                        case config.ACCESSORY_TYPES.OUTRIGGER:
                        case config.ACCESSORY_TYPES.AERIAL_PLATFORM:
                            return getActiveOffer().getRig().getVehicle().getCabToChassisEnd() - accessory.getLength();
                        case config.ACCESSORY_TYPES.AEROKIT:
                            return -accessory.getLength();
                        case config.ACCESSORY_TYPES.TOOLBOX:
                            return 500;
                        case config.ACCESSORY_TYPES.REAR_BUMPER:
                            return getActiveOffer().getRig().getVehicle().getCabToChassisEnd() - accessory.getLength() + 100;

                    }
                } else {
                    return getActiveOffer().getRig().getVehicle().getCabGapRecommendedMin();
                }
                break;
            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                if (accessoryType === config.ACCESSORY_TYPES.TAILLIFT) {
                    return rigOps.getBodyGap(config.CHASSIS_OBJECT_TYPES.TRAILER1) + getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getBody().getActualLength();
                } else if (accessoryType === config.ACCESSORY_TYPES.OTHER) {
                    switch (accessory.getAccessorySubType()) {
                        case config.ACCESSORY_TYPES.OTHER:
                            return 0;
                        case config.ACCESSORY_TYPES.BULLBAR:
                        case config.ACCESSORY_TYPES.SNOWPLOW:
                            return 0;
                        case config.ACCESSORY_TYPES.FAIRING:
                            if (getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getBody() !== null) {
                                return rigOps.getBodyGap(config.CHASSIS_OBJECT_TYPES.TRAILER1) - accessory.getLength();
                            } else {
                                return 0;
                            }
                        case config.ACCESSORY_TYPES.OUTRIGGER:
                        case config.ACCESSORY_TYPES.AERIAL_PLATFORM:
                            return getActiveOffer().getRig().getTrailer1().getLength() - accessory.getLength();
                        case config.ACCESSORY_TYPES.AEROKIT:
                            return 0;
                        case config.ACCESSORY_TYPES.TOOLBOX:
                            return 500;
                        case config.ACCESSORY_TYPES.REAR_BUMPER:
                            return getActiveOffer().getRig().getTrailer1().getLength() - accessory.getLength() + 100;
                    }
                } else {
                    return 0;
                }
                break;
            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                if (accessoryType === config.ACCESSORY_TYPES.TAILLIFT) {
                    return rigOps.getBodyGap(config.CHASSIS_OBJECT_TYPES.TRAILER2) + getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getBody().getActualLength();
                } else if (accessoryType === config.ACCESSORY_TYPES.OTHER) {
                    switch (accessory.getAccessorySubType()) {
                        case config.ACCESSORY_TYPES.OTHER:
                            return 0;
                        case config.ACCESSORY_TYPES.BULLBAR:
                        case config.ACCESSORY_TYPES.SNOWPLOW:
                            return 0;
                        case config.ACCESSORY_TYPES.FAIRING:
                            if (getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getBody() !== null) {
                                return rigOps.getBodyGap(config.CHASSIS_OBJECT_TYPES.TRAILER2) - accessory.getLength();
                            } else {
                                return 0;
                            }
                        case config.ACCESSORY_TYPES.OUTRIGGER:
                        case config.ACCESSORY_TYPES.AERIAL_PLATFORM:
                            return getActiveOffer().getRig().getTrailer2().getLength() - accessory.getLength();
                        case config.ACCESSORY_TYPES.AEROKIT:
                            return 0;
                        case config.ACCESSORY_TYPES.TOOLBOX:
                            return 500;
                        case config.ACCESSORY_TYPES.REAR_BUMPER:
                            return getActiveOffer().getRig().getTrailer2().getLength() - accessory.getLength() + 100;

                    }
                } else {
                    return 0;
                }
                break;
        }
    }

    function getAccessoryVerticalGap(accessory, path) {
        var accessoryType = accessory.getAccessoryType();

        switch (rigOps.getChassisTypeUsingPath(path)) {
            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                if (accessoryType === config.ACCESSORY_TYPES.TAILLIFT) {
                    return 0; //getActiveOffer().getRig().getVehicle().getAccessoryHolder().getBody().getSubframeHeight() + getActiveOffer().getRig().getVehicle().getAccessoryHolder().getBody().getSubstructureHeight();
                } else if (accessoryType === config.ACCESSORY_TYPES.OTHER) {
                    switch (accessory.getAccessorySubType()) {
                        case config.ACCESSORY_TYPES.OTHER:
                        case config.ACCESSORY_TYPES.AERIAL_PLATFORM:
                            return 0;
                        case config.ACCESSORY_TYPES.BULLBAR:
                            return -(accessory.getHeight() / 2);
                        case config.ACCESSORY_TYPES.FAIRING:
                            if (getActiveOffer().getRig().getVehicle().getAccessoryHolder().getBody() !== null) {
                                return getActiveOffer().getRig().getVehicle().getAccessoryHolder().getBody().getActualExternalHeight() - accessory.getHeight();
                            } else {
                                return 0;
                            }
                        case config.ACCESSORY_TYPES.OUTRIGGER:
                        case config.ACCESSORY_TYPES.SNOWPLOW:
                            return -getActiveOffer().getRig().getVehicle().getChassisHeightMax() + 300;
                        case config.ACCESSORY_TYPES.AEROKIT:
                            return getActiveOffer().getRig().getVehicle().getCabHeight() - getActiveOffer().getRig().getVehicle().getChassisHeightMax();
                        case config.ACCESSORY_TYPES.TOOLBOX:
                            return -500;
                        case config.ACCESSORY_TYPES.REAR_BUMPER:
                            return -getActiveOffer().getRig().getVehicle().getChassisHeightMax() + 500;

                    }
                } else if (accessoryType === config.ACCESSORY_TYPES.HITCH || accessoryType === config.ACCESSORY_TYPES.HOOKLIFT) {
                    return 0;
                } else {
                    return getActiveOffer().getRig().getVehicle().getChassisHeightMax();
                }
                break;
            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                if (accessoryType === config.ACCESSORY_TYPES.TAILLIFT) {
                    return 0; //getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getBody().getSubframeHeight() + getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getBody().getSubstructureHeight();
                } else if (accessoryType === config.ACCESSORY_TYPES.OTHER) {
                    switch (accessory.getAccessorySubType()) {
                        case config.ACCESSORY_TYPES.OTHER:
                        case config.ACCESSORY_TYPES.AERIAL_PLATFORM:
                            return 0;
                        case config.ACCESSORY_TYPES.BULLBAR:
                        case config.ACCESSORY_TYPES.SNOWPLOW:
                            return 0;
                        case config.ACCESSORY_TYPES.FAIRING:
                            if (getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getBody() !== null) {
                                return getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getBody().getActualExternalHeight() - accessory.getHeight();
                            } else {
                                return 0;
                            }
                        case config.ACCESSORY_TYPES.OUTRIGGER:
                            return -getActiveOffer().getRig().getTrailer1().getChassisHeight() + 300;
                        case config.ACCESSORY_TYPES.AEROKIT:
                            return 0;
                        case config.ACCESSORY_TYPES.TOOLBOX:
                            return -500;
                        case config.ACCESSORY_TYPES.REAR_BUMPER:
                            return -getActiveOffer().getRig().getTrailer1().getChassisHeight() + 500;

                    }
                } else if (accessoryType === config.ACCESSORY_TYPES.HITCH || accessoryType === config.ACCESSORY_TYPES.HOOKLIFT) {
                    return 0;
                } else {
                    return getActiveOffer().getRig().getTrailer1().getChassisHeight();
                }
                break;
            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                if (accessoryType === config.ACCESSORY_TYPES.TAILLIFT) {
                    return 0; //getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getBody().getSubframeHeight() + getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getBody().getSubstructureHeight();
                } else if (accessoryType === config.ACCESSORY_TYPES.OTHER) {
                    switch (accessory.getAccessorySubType()) {
                        case config.ACCESSORY_TYPES.OTHER:
                        case config.ACCESSORY_TYPES.AERIAL_PLATFORM:
                            return 0;
                        case config.ACCESSORY_TYPES.BULLBAR:
                        case config.ACCESSORY_TYPES.SNOWPLOW:
                            return 0;
                        case config.ACCESSORY_TYPES.FAIRING:
                            if (getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getBody() !== null) {
                                return getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getBody().getActualExternalHeight() - accessory.getHeight();
                            } else {
                                return 0;
                            }
                        case config.ACCESSORY_TYPES.OUTRIGGER:
                            return -getActiveOffer().getRig().getTrailer2().getChassisHeight() + 300;
                        case config.ACCESSORY_TYPES.AEROKIT:
                            return 0;
                        case config.ACCESSORY_TYPES.TOOLBOX:
                            return -500;
                        case config.ACCESSORY_TYPES.REAR_BUMPER:
                            return -getActiveOffer().getRig().getTrailer2().getChassisHeight() + 500;
                    }
                } else if (accessoryType === config.ACCESSORY_TYPES.HITCH || accessoryType === config.ACCESSORY_TYPES.HOOKLIFT) {
                    return 0;
                } else {
                    return getActiveOffer().getRig().getTrailer2().getChassisHeight();
                }
                break;
        }
    }
}

function getAccessory(accessoryStub, rootPath) {
    var dfd = $.Deferred();

    dataManager.getAccessory(accessoryStub.getId(), accessoryStub.getAccessoryType(), accessoryStub.getSource(), accessoryStub.getAccessLevel())
        .then(function (accessoryAsJSON) {
            var accessory = null;
            if (accessoryAsJSON.Result.ReturnCode === 1) {
                accessory = createAccessoryObject(accessoryAsJSON.Accessory, undefined, rootPath, accessoryStub.getCached());
                if(accessoryAsJSON.Accessory.DecompressedGraphicBlob && utils.isValidDecompressedGraphicFile(accessoryAsJSON.Accessory.DecompressedGraphicBlob)) {
                    accessory.setDecompressedGraphicBlob(accessoryAsJSON.Accessory.DecompressedGraphicBlob);
                }
                if(accessoryAsJSON.Accessory.DecompressedTopViewGraphicBlob && utils.isValidDecompressedGraphicFile(accessoryAsJSON.Accessory.DecompressedTopViewGraphicBlob)) {
                    accessory.setDecompressedTopViewGraphicBlob(accessoryAsJSON.Accessory.DecompressedTopViewGraphicBlob);
                }
                var cached = accessory.getCached();
                if (accessoryStub.getAccessLevel() === config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY) {
                    cached = 0;
                }
                accessoryStub.setCached(cached);
                accessory.setAccessLevel(accessoryStub.getAccessLevel());
            } else if (accessoryAsJSON.Result.ReturnCode === -2 || accessoryAsJSON.Result.ReturnCode === -3) {
                //clean up stubs
                removeAccessoryFromAvailableAccessoriesArray(accessoryStub);
                dataManager.remove(config.OPERATION.RemoveLocalAccessory, accessoryStub.getId() + '_' + accessoryStub.getAccessoryType() + '_' + accessoryStub.getSource());
            }
            dfd.resolve({
                Response: accessoryAsJSON,
                Accessory: accessory
            });
        })
        .fail(function (error) {
            dfd.reject(error);
        });

    return dfd.promise();
}

function addTrailerToCurrentRig(trailer, path) {
    
    

    var tempPayloadObject = null;
    if (trailer.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (trailer.getBodyType() !== '' && trailer.getBodyType() !== 'NONE')) { // User Story 17565
        tempPayloadObject = setupDefaultPayloadForStandardTrailer(trailer);
    }


    getActiveOffer().getRig().addChassisObject(trailer);
    var originalTrailerCopy = trailer.clone(true);
    originalTrailerCopy.getAxlesHolder().setStartingNumberForAxles(trailer.getAxlesHolder().getStartingNumberForAxles());
    originalTrailerCopy.getAxlesHolder().resequence();
    getOriginalOffer().getRig().addChassisObject(originalTrailerCopy);

    setLegislationLookupObjectsCleared(false);
    clearActiveLegislationQuickLookupObjects();

    generateDataForActiveLegislations();
    var mergedPermissibleValues = getMergedLegislationsCombinationPermissibleVals(getDataForActiveLegislations().activeLegislationsCombinationPermissibleValues);
    rigOps.setCombinationPermissibleVals(mergedPermissibleValues);

    
    

    

    changeListenerCallback('Combination_Changed'); // User Story 17565
    

    if (isCostingAvailableForOffer() === true) {
        var defaultLicenceRegion;
        if (path.includes('TRAILER1')) { // User Story 17565
            // setup licence fees using default on user
            //defaultLicenceRegion = getActiveOffer().getCosting().getLicenceCategories().find(function (lc) {
            //    return lc.CategoryType === 'TRAILER' && lc.RegionId === globals.user.getSpecifyLicencingRegionAs() && getActiveOffer().getRig().getTrailer1().getUnladenTotal() > lc.MassLow && getActiveOffer().getRig().getTrailer1().getUnladenTotal() <= lc.MassHigh;
            //});
            //if (defaultLicenceRegion === undefined) {
            //    defaultLicenceRegion = getActiveOffer().getCosting().getLicenceCategories().find(function (lc) {
            //        return lc.CategoryType === 'TRAILER' && getActiveOffer().getRig().getTrailer1().getUnladenTotal() > lc.MassLow && getActiveOffer().getRig().getTrailer1().getUnladenTotal() <= lc.MassHigh;
            //    });
            //}
            //defaultLicenceRegion = globals.getLicenceCategoryObjectToUse(getActiveOffer().getCosting().getLicenceCategories(), 'TRAILER', getActiveOffer().getRig().getVehicle().getUnladenTotal(), globals.user.getSpecifyLicencingRegionAs());
            defaultLicenceRegion = globals.getLicenceCategoryObjectToUse(getActiveOffer().getCosting().getLicenceCategories(), 'TRAILER', getActiveOffer().getRig().getTrailer1().getUnladenTotal(), globals.user.getSpecifyLicencingRegionAs()); // User Story 17565

            //getActiveOffer().getRig().getTrailer1().setLicenceFees(defaultLicenceRegion.Price);
            getActiveOffer().getRig().getTrailer1().setLicenceFees(defaultLicenceRegion.TotalPrice);
            getActiveOffer().getRig().getTrailer1().setLicenceFeesOverride(false);
            getActiveOffer().getRig().getTrailer1().setLicenceFeesRegionId(defaultLicenceRegion.RegionId);
        } else {
            //defaultLicenceRegion = getActiveOffer().getCosting().getLicenceCategories().find(function (lc) {
            //    return lc.CategoryType === 'TRAILER' && lc.RegionId === globals.user.getSpecifyLicencingRegionAs() && getActiveOffer().getRig().getTrailer2().getUnladenTotal() > lc.MassLow && getActiveOffer().getRig().getTrailer2().getUnladenTotal() <= lc.MassHigh;
            //});
            //if (defaultLicenceRegion === undefined) {
            //    defaultLicenceRegion = getActiveOffer().getCosting().getLicenceCategories().find(function (lc) {
            //        return lc.CategoryType === 'TRAILER' && getActiveOffer().getRig().getTrailer1().getUnladenTotal() > lc.MassLow && getActiveOffer().getRig().getTrailer1().getUnladenTotal() <= lc.MassHigh;
            //    });
            //}
            //defaultLicenceRegion = globals.getLicenceCategoryObjectToUse(getActiveOffer().getCosting().getLicenceCategories(), 'TRAILER', getActiveOffer().getRig().getVehicle().getUnladenTotal(), globals.user.getSpecifyLicencingRegionAs());
            defaultLicenceRegion = globals.getLicenceCategoryObjectToUse(getActiveOffer().getCosting().getLicenceCategories(), 'TRAILER', getActiveOffer().getRig().getTrailer2().getUnladenTotal(), globals.user.getSpecifyLicencingRegionAs()); // User Story 17565
            //getActiveOffer().getRig().getTrailer2().setLicenceFees(defaultLicenceRegion.Price);
            getActiveOffer().getRig().getTrailer2().setLicenceFees(defaultLicenceRegion.TotalPrice);
            getActiveOffer().getRig().getTrailer2().setLicenceFeesOverride(false);
            getActiveOffer().getRig().getTrailer2().setLicenceFeesRegionId(defaultLicenceRegion.RegionId);
        }
        updateCostingItems();
    }
    var tempOriginalPayloadObject;
    if (tempPayloadObject !== null) {
        //var maximisedLoad = rigOps.getMaximisedLoad();

        //tempPayloadObject.setMass(maximisedLoad);
        //clearActiveLegislationQuickLookupObjects();
        //if (needToMaximise(rigOps)) {
        //    rigOps.maximisePayloads();
        //}
        tempOriginalPayloadObject = tempPayloadObject.clone(true);
        originalTrailerCopy.getPayloadHolder().add(tempOriginalPayloadObject);

        addPayloadObservables([tempPayloadObject], trailer.getChassisObjectName() + '.' + config.ACCESSORY_TYPES.PAYLOAD);
        //createPayloadObservables(tempPayloadObject, trailer.getChassisObjectName() + '.' + config.ACCESSORY_TYPES.PAYLOAD);
    }
    setupOriginalAxleInternalIdsOnTrailers();

    createAccessoryObjectWithObservables(trailer, path);
    if (trailer.getAccessoryHolder().getBody() !== null) { 
        createAccessoryObjectWithObservables(trailer.getAccessoryHolder().getBody(), path);
        originalTrailerCopy.getAccessoryHolder().add(trailer.getAccessoryHolder().getBody().clone(true));

        tempPayloadObject = setupDefaultPayloadForBody(trailer.getPayloadHolder(), trailer.getAccessoryHolder().getBody());

        //var maximisedLoad = rigOps.getMaximisedLoad();

        //tempPayloadObject.setMass(maximisedLoad);
        //clearActiveLegislationQuickLookupObjects();
        //if (needToMaximise(rigOps)) {
        //    rigOps.maximisePayloads();
        //}
        tempOriginalPayloadObject = tempPayloadObject.clone();
        originalTrailerCopy.getPayloadHolder().add(tempOriginalPayloadObject);

        addPayloadObservables([tempPayloadObject], trailer.getChassisObjectName() + '.' + config.ACCESSORY_TYPES.PAYLOAD);
        //createPayloadObservables(tempPayloadObject, path.split('.')[0] + '.' + config.ACCESSORY_TYPES.PAYLOAD);

    }
    if (trailer.getAccessoryHolder().getCrane() !== null) {
        createAccessoryObjectWithObservables(trailer.getAccessoryHolder().getCrane(), path);
        originalTrailerCopy.getAccessoryHolder().add(trailer.getAccessoryHolder().getCrane().clone(true));
    }
    if (trailer.getAccessoryHolder().getFridge() !== null) {
        createAccessoryObjectWithObservables(trailer.getAccessoryHolder().getFridge(), path);
        originalTrailerCopy.getAccessoryHolder().add(trailer.getAccessoryHolder().getFridge().clone(true));
    }
    if (trailer.getAccessoryHolder().getTaillift() !== null) {
        createAccessoryObjectWithObservables(trailer.getAccessoryHolder().getTaillift(), path);
        originalTrailerCopy.getAccessoryHolder().add(trailer.getAccessoryHolder().getTaillift().clone(true));
    }
    if (trailer.getAccessoryHolder().getAccessoryCount(config.ACCESSORY_TYPES.OTHER) > 0) {
        trailer.getAccessoryHolder().getAccessories().forEach(function (accessory) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                createAccessoryObjectWithObservables(accessory, path);
                originalTrailerCopy.getAccessoryHolder().add(accessory.clone(true));
            }
        });

    }

    if (path.includes('TRAILER1')) {
        dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.ADDED_TRAILER_1, trailer.getIntercomMetadata()));
        //getActiveOffer().getRig().getTrailer1().getAxlesHolder().setStartingNumberForAxles(getActiveOffer().getRig().getVehicle().getAxlesHolder().getNumberOfAxles());
    } else {
        dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.ADDED_TRAILER_2, trailer.getIntercomMetadata()));
        //getActiveOffer().getRig().getTrailer2().getAxlesHolder().setStartingNumberForAxles(getActiveOffer().getRig().getVehicle().getAxlesHolder().getNumberOfAxles() + getActiveOffer().getRig().getTrailer1().getAxlesHolder().getNumberOfAxles());
    }
    clearActiveLegislationQuickLookupObjects(); 
    //rigOps.updateBridgeFormulaPermissiblesOnAxle(trailer.getChassisObjectName());
    updateCostingItems();
    
    doCommonUpdateActions();
    notifyValidationListeners();
    mergedPermissibleValues = getMergedLegislationsCombinationPermissibleVals(getDataForActiveLegislations().activeLegislationsCombinationPermissibleValues);
    if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR && trailer.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
        //var trailerPermissibleFront = rigOps.getTrailer1PermissibleFront();

        trailer.setPermissibleFront(mergedPermissibleValues.trailer1PermissibleFront);
        originalTrailerCopy.setPermissibleFront(mergedPermissibleValues.trailer1PermissibleFront);
    } else if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR && trailer.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
        //var trailerPermissibleFront = rigOps.getTrailer2PermissibleFront();

        trailer.setPermissibleFront(mergedPermissibleValues.trailer2PermissibleFront);
        originalTrailerCopy.setPermissibleFront(mergedPermissibleValues.trailer2PermissibleFront);
    }
    if (trailer.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
        trailer.setPermissibleRear(mergedPermissibleValues.trailer1PermissibleRear);
        originalTrailerCopy.setPermissibleRear(mergedPermissibleValues.trailer1PermissibleRear);
    } else if (trailer.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
        trailer.setPermissibleRear(mergedPermissibleValues.trailer2PermissibleRear);
        originalTrailerCopy.setPermissibleRear(mergedPermissibleValues.trailer2PermissibleRear);
    }
    

    getActiveOffer().getRig().getVehicle().setPermissibleTotal(mergedPermissibleValues.combinationTotalPermissible);
    
    addAxlesObservables(trailer.getAxlesHolder().getAxles(), trailer.getChassisObjectName());
    addAxleGroupsObservables(trailer.getAxlesHolder().getAxleGroups(), trailer.getChassisObjectName());
    
    //addTrailerAxleTotalObservables(trailer.getChassisObjectName());
    //dataManager.sendEventInfoToIntercom(globals.getIntercomObject(config.INTERCOM_EVENT.ADDED_BODY, body.getIntercomMetadata()));

    

    //updateCostingTyreRate();
    rigOps.resequenceTrailerAxles();
    if(typeof objectAddedListenerCallback === 'function') {
        objectAddedListenerCallback(config.OBJECT_TYPES.ACCESSORY, trailer, path);
    }
    

    refreshCombinationTotalAOGPermissions();

    clearPerformanceResults();
    unsavedChangesOnOffer.value = true;
    if (trailer.getSource() === config.ACCESSORY_SOURCE_TYPES.STANDARD && globals.user.displayStandardItemMessage() === true) {
        showStandardItemToast(trailer.getEditLevel(), globals.getTrailerTypeText(trailer.getType(), path));
    }
}

function refreshCombinationTotalAOGPermissions() {
    var manufacturersGrossCombinationMassAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_TOTAL + '.' + config.VALUE_TYPE.MANUFACTURERSGROSSCOMBINATIONMASS);
    manufacturersGrossCombinationMassAOG.permissionObv.value = manufacturersGrossCombinationMassAOG.permission(manufacturersGrossCombinationMassAOG.getPath());

    var combinationBridgeFormulaPermissibleAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_TOTAL + '.' + config.VALUE_TYPE.COMBINATIONBRIDGEFORMULAPERMISSIBLE);
    combinationBridgeFormulaPermissibleAOG.permissionObv.value = combinationBridgeFormulaPermissibleAOG.permission(combinationBridgeFormulaPermissibleAOG.getPath());

    var combinationTotalPermissibleAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_TOTAL + '.' + config.VALUE_TYPE.COMBINATIONTOTALPERMISSIBLE);
    combinationTotalPermissibleAOG.permissionObv.value = combinationTotalPermissibleAOG.permission(combinationTotalPermissibleAOG.getPath());
}

/**
    * Clear results on performance if available and set performance report option on summary to false
    */
function clearPerformanceResults() {
    if (typeof clearPerformanceSimulationResultOnly === 'function') {
        clearPerformanceSimulationResultOnly();
        // clear the report option on summary
        var performanceReportOption = getAttributeGroup(config.OBJECT_TYPES.REPORT_OPTIONS + '.' + config.SUMMARY_REPORT_OPTIONS.PERFORMANCE + '.' + config.VALUE_TYPE.SUMMARYREPORTOPTION);
        performanceReportOption.value.value = false;
    } else {
        clearSimulationResults();
    }
}

function clearSimulationResults() {            
    if (getActiveOffer().getPerformance() && getActiveOffer().getPerformance().getSimulationCreated() === true  && globals.user.hasPermission(config.PERMISSIONS.PERFORMANCE.Code)) {
        getActiveOffer().getPerformance().setSimResAverageAdblue(undefined);
        getActiveOffer().getPerformance().setSimResAverageCO2Emissions(undefined);
        getActiveOffer().getPerformance().setSimResAverageFuel(undefined);
        getActiveOffer().getPerformance().setSimResAverageSpeed(undefined);
        getActiveOffer().getPerformance().setSimResProductivity(undefined);
        getActiveOffer().getPerformance().setSimResTotalAdblue(undefined);
        getActiveOffer().getPerformance().setSimResTotalCO2Emissions(undefined);
        getActiveOffer().getPerformance().setSimResTotalFuel(undefined);
        getActiveOffer().getPerformance().setSimResTripDistance(undefined);
        getActiveOffer().getPerformance().setSimResTripTime(undefined);
        getActiveOffer().getPerformance().setSimResTotalTimeInTopGear(undefined);
        getActiveOffer().getPerformance().setSimResTotalGearChanges(undefined);
        getActiveOffer().getPerformance().setSimulationCreated(false);
        clearPerformanceReportOptionIfNecessary();
    }
}

function updateCostingTotalRevenue() {
    // if(getPreventCostingTotalRevenueUpdate() === false) {
    //     var newValue = getActiveOffer().getCosting().getTotalCostsPerKM(globals.user.getActiveMeasurementSystemId());
    //     getActiveOffer().getCosting().setTotalRevenue(newValue);
    //     var costingTotalRevenueAOG = offerManager.getAttributeGroup('COSTING.' + config.VALUE_TYPE.COSTINGTOTALREVENUE);
    //     costingTotalRevenueAOG.value.value = parseFloat(newValue);
    // }

    try {
        var valueToUse = 0,
            attrGroup,
            measurementSystemToUse;

        measurementSystemToUse = getMeasurementSystemToUse(getActiveOffer().getMeasurementSystem());
        if(measurementSystemToUse.id !== getActiveOffer().getCosting().getTotalRevenueSetUsingMeasurementSystemId()) {
            attrGroup = getAttributeGroup(config.OBJECT_TYPES.COSTING + '.' + config.VALUE_TYPE.COSTINGTOTALREVENUE);
            if(measurementSystemToUse.id === globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.METRIC).id) {
                valueToUse = getActiveOffer().getCosting().getTotalRevenue() * globals.getMeasurementSystemUsingType(getActiveOffer().getCosting().getTotalRevenueSetUsingMeasurementSystemId()).distanceConversion;
            } else {
                valueToUse = getActiveOffer().getCosting().getTotalRevenue() / measurementSystemToUse.distanceConversion;
            }
            
            if (isRef(attrGroup.value) === true) {
                attrGroup.value.value = valueToUse;
            }

            if(measurementSystemToUse.id !== globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.METRIC).id) {
                getActiveOffer().getCosting().setTotalRevenueAsCurrencyPerKm(valueToUse * measurementSystemToUse.distanceConversion);
            } else {
                getActiveOffer().getCosting().setTotalRevenueAsCurrencyPerKm(valueToUse);
            }
            getActiveOffer().getCosting().setTotalRevenue(valueToUse);
            getActiveOffer().getCosting().setTotalRevenueSetUsingMeasurementSystemId(measurementSystemToUse.id);
        }
    } catch (e) {
        TScMessenger.writeDebugMessage('OfferManager.js, updateCostingTotalRevenue, error');
    }
    
}

function updateCostingTyreRate() {
    var valueToUse = 0,
        convertedValueToUse = 0,
        attrGroup,
        measurementSystemToUse;
    if (getActiveOffer().getCosting().getTyreRateOverride() === false) {
        attrGroup = getAttributeGroup(config.OBJECT_TYPES.COSTING + '.' + config.VALUE_TYPE.COSTINGTYRES);
        valueToUse += getValueFromAxles(getActiveOffer().getConfiguration().getAxles());
        convertedValueToUse += getConvertedValueFromAxles(getActiveOffer().getConfiguration().getAxles());
        if (getActiveOffer().getTrailer1() !== null) {
            valueToUse += getValueFromAxles(getActiveOffer().getTrailer1().getAxlesHolder().getAxles());
            convertedValueToUse += getConvertedValueFromAxles(getActiveOffer().getTrailer1().getAxlesHolder().getAxles());
        }
        if (getActiveOffer().getTrailer2() !== null) {
            valueToUse += getValueFromAxles(getActiveOffer().getTrailer2().getAxlesHolder().getAxles());
            convertedValueToUse += getConvertedValueFromAxles(getActiveOffer().getTrailer2().getAxlesHolder().getAxles());
        }
        //tyeRateAttrGroup.formatted(valueToUse);
        // if (typeof attrGroup.value === 'function') {
        if (isRef(attrGroup.value) === true) {
            attrGroup.value.value = convertedValueToUse;
        }
        getActiveOffer().getCosting().setTyreRateAsCentsPerKm(valueToUse);
        getActiveOffer().getCosting().setTyreRate(convertedValueToUse);
    } else {
        // Check measurement system
        measurementSystemToUse = getMeasurementSystemToUse(getActiveOffer().getMeasurementSystem());
        if(measurementSystemToUse.id !== getActiveOffer().getCosting().getTyreRateSetUsingMeasurementSystemId()) {
            attrGroup = getAttributeGroup(config.OBJECT_TYPES.COSTING + '.' + config.VALUE_TYPE.COSTINGTYRES);
            if(measurementSystemToUse.id === globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.METRIC).id) {
                valueToUse = getActiveOffer().getCosting().getTyreRate() * globals.getMeasurementSystemUsingType(getActiveOffer().getCosting().getTyreRateSetUsingMeasurementSystemId()).distanceConversion;
            } else {
                valueToUse = getActiveOffer().getCosting().getTyreRate() / measurementSystemToUse.distanceConversion;
            }
            
            if (isRef(attrGroup.value) === true) {
                attrGroup.value.value = valueToUse;
            }
            // getActiveOffer().getCosting().setTyreRate(valueToUse);
            // getActiveOffer().getCosting().setTyreRateSetUsingMeasurementSystemId(measurementSystemToUse.id);

            if(measurementSystemToUse.id !== globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.METRIC).id) {
                getActiveOffer().getCosting().setTyreRateAsCentsPerKm(valueToUse * measurementSystemToUse.distanceConversion);
            } else {
                getActiveOffer().getCosting().setTyreRateAsCentsPerKm(valueToUse);
            }
            getActiveOffer().getCosting().setTyreRate(valueToUse);
            getActiveOffer().getCosting().setTyreRateSetUsingMeasurementSystemId(measurementSystemToUse.id);
        }
    }

    function getValueFromAxles(axles) {
        var valueToUse = 0;

        axles.forEach(function (axleItem) {
            valueToUse += axleItem.getCostPerKm();
        });

        return valueToUse;
    }

    function getConvertedValueFromAxles(axles) {
        var valueToUse = 0;

        axles.forEach(function (axleItem) {
            valueToUse += axleItem.getCostPerDistance(getMeasurementSystemToUse(getActiveOffer().getMeasurementSystem()));
        });

        return valueToUse;
    }
}

function updateCostingLicenceFees(performCheckOnLicenceFees) {
    if (displayLicenceDetailButtonOnCosting()) {
        // Update detail form
        var licenceFeesRegionIdToUse = getActiveOffer().getCosting().getVehicleLicenceFeesRegionId() !== globals.user.getSpecifyLicencingRegionAs() ? getActiveOffer().getCosting().getVehicleLicenceFeesRegionId() : globals.user.getSpecifyLicencingRegionAs();
        //var defaultLicenceRegion = globals.getLicenceCategoryObjectToUse(getActiveOffer().getCosting().getLicenceCategories(), 'VEHICLE', getActiveOffer().getRig().getVehicle().getUnladenTotal(), globals.user.getSpecifyLicencingRegionAs());
        var defaultLicenceRegion = globals.getLicenceCategoryObjectToUse(getActiveOffer().getCosting().getLicenceCategories(), 'VEHICLE', getActiveOffer().getRig().getVehicle().getUnladenTotal(), licenceFeesRegionIdToUse);

        if (getActiveOffer().getCosting().getVehicleLicenceFeesOverride() === false) {
            if (performCheckOnLicenceFees === true) {
                if (getActiveOffer().getCosting().getLicenceFeesOverride() === true) {
                    getActiveOffer().getCosting().setVehicleLicenceFees(defaultLicenceRegion.TotalPrice);
                } else {
                    if (defaultLicenceRegion.TotalPrice === getActiveOffer().getCosting().getVehicleLicenceFees()) {
                        getActiveOffer().getCosting().setVehicleLicenceFees(defaultLicenceRegion.TotalPrice);
                    } else {
                        addItemToLicenceFeesMessageObject(config.CHASSIS_OBJECT_TYPES.VEHICLE, defaultLicenceRegion.TotalPrice, getActiveOffer().getCosting().getVehicleLicenceFees());
                    }
                }
            } else {
                getActiveOffer().getCosting().setVehicleLicenceFees(defaultLicenceRegion.TotalPrice);
            }
        }
        if (getActiveOffer().getTrailer1() !== null && getActiveOffer().getRig().getTrailer1().getLicenceFeesOverride() === false) {
            defaultLicenceRegion = globals.getLicenceCategoryObjectToUse(getActiveOffer().getCosting().getLicenceCategories(), 'TRAILER', getActiveOffer().getRig().getTrailer1().getUnladenTotal(), getActiveOffer().getRig().getTrailer1().getLicenceFeesRegionId());
            //getActiveOffer().getRig().getTrailer1().setLicenceFees(defaultLicenceRegion.Price);
            
            if (performCheckOnLicenceFees === true) {
                if (getActiveOffer().getCosting().getLicenceFeesOverride() === true) {
                    getActiveOffer().getRig().getTrailer1().setLicenceFees(defaultLicenceRegion.TotalPrice);
                } else {
                    if (defaultLicenceRegion.TotalPrice === getActiveOffer().getRig().getTrailer1().getLicenceFees()) {
                        getActiveOffer().getRig().getTrailer1().setLicenceFees(defaultLicenceRegion.TotalPrice);
                    } else {
                        addItemToLicenceFeesMessageObject(config.CHASSIS_OBJECT_TYPES.TRAILER1, defaultLicenceRegion.TotalPrice, getActiveOffer().getRig().getTrailer1().getLicenceFees());
                    }
                }
            } else {
                getActiveOffer().getRig().getTrailer1().setLicenceFees(defaultLicenceRegion.TotalPrice);
            }
        }
        if (getActiveOffer().getTrailer2() !== null && getActiveOffer().getRig().getTrailer2().getLicenceFeesOverride() === false) {
            defaultLicenceRegion = globals.getLicenceCategoryObjectToUse(getActiveOffer().getCosting().getLicenceCategories(), 'TRAILER', getActiveOffer().getRig().getTrailer2().getUnladenTotal(), getActiveOffer().getRig().getTrailer2().getLicenceFeesRegionId());
            //getActiveOffer().getRig().getTrailer2().setLicenceFees(defaultLicenceRegion.Price);
            
            if (performCheckOnLicenceFees === true) {
                if (getActiveOffer().getCosting().getLicenceFeesOverride() === true) {
                    getActiveOffer().getRig().getTrailer2().setLicenceFees(defaultLicenceRegion.TotalPrice);
                } else {
                    if (defaultLicenceRegion.TotalPrice === getActiveOffer().getRig().getTrailer2().getLicenceFees()) {
                        getActiveOffer().getRig().getTrailer2().setLicenceFees(defaultLicenceRegion.TotalPrice);
                    } else {
                        addItemToLicenceFeesMessageObject(config.CHASSIS_OBJECT_TYPES.TRAILER2, defaultLicenceRegion.TotalPrice, getActiveOffer().getRig().getTrailer2().getLicenceFees());
                    }
                }                        
            } else {
                getActiveOffer().getRig().getTrailer2().setLicenceFees(defaultLicenceRegion.TotalPrice);
            }
        }

        // // Update costing screen
        // var valueToUse = 0,
        //     attrGroup = getAttributeGroup(config.OBJECT_TYPES.COSTING + '.' + config.VALUE_TYPE.COSTINGLICENCEFEES);
        // valueToUse += getActiveOffer().getCosting().getVehicleLicenceFees();
        // if (getActiveOffer().getTrailer1() !== null) {
        //     valueToUse += getActiveOffer().getRig().getTrailer1().getLicenceFees();
        // }
        // if (getActiveOffer().getTrailer2() !== null) {
        //     valueToUse += getActiveOffer().getRig().getTrailer2().getLicenceFees();
        // }
        // if (typeof attrGroup.value === 'function') {
        //     attrGroup.value(valueToUse);
        // }
        // getActiveOffer().getCosting().setLicenceFees(valueToUse);
        updateCostingLicenceFeesAttrObvGroup();

    }
}

function updateCostingLicenceFeesAttrObvGroup() {
    if (getActiveOffer().getCosting().getLicenceFeesOverride() === false && displayLicenceDetailButtonOnCosting()) {
        // Update costing screen
        var valueToUse = 0,
            attrGroup = getAttributeGroup(config.OBJECT_TYPES.COSTING + '.' + config.VALUE_TYPE.COSTINGLICENCEFEES);
        valueToUse += getActiveOffer().getCosting().getVehicleLicenceFees();
        if (getActiveOffer().getTrailer1() !== null) {
            valueToUse += getActiveOffer().getRig().getTrailer1().getLicenceFees();
        }
        if (getActiveOffer().getTrailer2() !== null) {
            valueToUse += getActiveOffer().getRig().getTrailer2().getLicenceFees();
        }
        if (typeof attrGroup.value === 'function') {
            attrGroup.value.value = valueToUse;
        }
        getActiveOffer().getCosting().setLicenceFees(valueToUse);
    }
}

function updateCostingFuelAndAdblueValues() {
    updateCostingFuelAndAdblueTotalCostPerMonthValues();
    updateCostingFuelAndAdblueCostPerMonthAttrObvGroup();
}

function updateCostingFuelAndAdblueTotalCostPerMonthValues() {
    let valueToUse = 0;
    if(getActiveOffer().getCosting().getVehicleFuelAndAdblueTotalCostPerMonthOverride() === true) {
        valueToUse += getActiveOffer().getCosting().getVehicleFuelAndAdblueTotalCostPerMonth();
    } else {
        // Calculate the value
        // let vehicleFuelCostPerMonth = getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelConsumption() * getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelCostPerVolume() * getActiveOffer().getCosting().getMonthlyDistance();
        // let vehicleFuelCostPerMonth = globals.getMonthlyFuelCostUsingFuelConsumptionPerDistance(
        //     getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelCostPerVolume(), 
        //     globals.getConvertedConsumptionValue(getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelConsumption(), getActiveOffer().getMeasurementSystem()), 
        //     getActiveOffer().getCosting().getMonthlyDistance());
        let vehicleFuelCostPerMonth = globals.getMonthlyFuelCostUsingFuelConsumptionPerDistance(
            globals.getConvertedFuelPriceValue(getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelCostPerVolume(), getActiveOffer().getMeasurementSystem()), 
            globals.getConvertedConsumptionValue(getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelConsumption(), getActiveOffer().getMeasurementSystem(), config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.VOLUME_PER_DISTANCE), 
            // globals.getConvertedConsumptionValue(getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelConsumption(),  globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.METRIC), config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.VOLUME_PER_DISTANCE),
            globals.getMonthlyDistanceForMeasurementSystem(getActiveOffer().getCosting().getMonthlyDistance(), getActiveOffer().getMeasurementSystem()));
        // let vehicleAdblueConsumption = getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelConsumption() * getActiveOffer().getCosting().getVehicleFuelAndAdblueAdblueConsumption() / 100;
        // let vehicleAdblueCostPerMonth = vehicleAdblueConsumption * getActiveOffer().getCosting().getVehicleFuelAndAdblueAdblueCostPerVolume() * getActiveOffer().getCosting().getMonthlyDistance();
        // let vehicleAdblueCostPerMonth = globals.getMonthlyFuelCostUsingFuelConsumptionPerDistance(
        //     getActiveOffer().getCosting().getVehicleFuelAndAdblueAdblueCostPerVolume(),
        //     vehicleAdblueConsumption,
        //     getActiveOffer().getCosting().getMonthlyDistance());
        
        // let vehicleAdblueConsumption = globals.getConvertedConsumptionValue(getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelConsumption(), getActiveOffer().getMeasurementSystem(), globals.user.getSpecifyFuelConsumptionAs())
        //                                 * globals.getConvertedConsumptionValue(getActiveOffer().getCosting().getVehicleFuelAndAdblueAdblueConsumption(), getActiveOffer().getMeasurementSystem(), globals.user.getSpecifyFuelConsumptionAs()) / 100;
        let vehicleAdblueConsumption = getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelConsumption() * getActiveOffer().getCosting().getVehicleFuelAndAdblueAdblueConsumption() / 100;
        let vehicleAdblueCostPerMonth = globals.getMonthlyFuelCostUsingFuelConsumptionPerDistance(
            globals.getConvertedFuelPriceValue(getActiveOffer().getCosting().getVehicleFuelAndAdblueAdblueCostPerVolume(), getActiveOffer().getMeasurementSystem()),
            // globals.getConvertedConsumptionValue(vehicleAdblueConsumption, getActiveOffer().getMeasurementSystem(), globals.user.getSpecifyFuelConsumptionAs()),
            globals.getConvertedConsumptionValue(vehicleAdblueConsumption, getActiveOffer().getMeasurementSystem(), config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.VOLUME_PER_DISTANCE),
            globals.getMonthlyDistanceForMeasurementSystem(getActiveOffer().getCosting().getMonthlyDistance(), getActiveOffer().getMeasurementSystem()));
        
            let vehicleEquipmentFuelCostPerMonth = globals.getMonthlyFuelCostUsingFuelConsumptionPerHour(
            globals.getConvertedFuelPriceValue(getActiveOffer().getCosting().getVehicleFuelAndAdblueEquipmentFuelCostPerVolume(), getActiveOffer().getMeasurementSystem()), 
            // globals.getConvertedConsumptionValue(getActiveOffer().getCosting().getVehicleFuelAndAdblueEquipmentFuelConsumption(), getActiveOffer().getMeasurementSystem(), getActiveOffer().getMeasurementSystem()), 
            globals.getConvertedConsumptionValue(getActiveOffer().getCosting().getVehicleFuelAndAdblueEquipmentFuelConsumption(), getActiveOffer().getMeasurementSystem(), config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.VOLUME_PER_DISTANCE), 
            globals.getMonthlyDistanceForMeasurementSystem(getActiveOffer().getCosting().getVehicleFuelAndAdblueEquipmentUtilisation(), getActiveOffer().getMeasurementSystem()));
        getActiveOffer().getCosting().setVehicleFuelAndAdblueTotalCostPerMonth(vehicleFuelCostPerMonth + vehicleAdblueCostPerMonth + vehicleEquipmentFuelCostPerMonth);
        valueToUse += getActiveOffer().getCosting().getVehicleFuelAndAdblueTotalCostPerMonth();
    }
    
    if (getActiveOffer().getTrailer1() !== null) {
        if(getActiveOffer().getRig().getTrailer1().getFuelAndAdblueTotalCostPerMonthOverride() === true) {
            valueToUse += getActiveOffer().getRig().getTrailer1().getFuelAndAdblueTotalCostPerMonth();
        } else {
            // Calculate the value
            // let trailer1EquipmentFuelCostPerMonth = getActiveOffer().getRig().getTrailer1().getFuelAndAdblueEquipmentFuelConsumption() * getActiveOffer().getRig().getTrailer1().getFuelAndAdblueEquipmentFuelCostPerVolume() * getActiveOffer().getRig().getTrailer1().getFuelAndAdblueEquipmentUtilisation();
            let trailer1EquipmentFuelCostPerMonth = globals.getMonthlyFuelCostUsingFuelConsumptionPerHour(getActiveOffer().getRig().getTrailer1().getFuelAndAdblueEquipmentFuelCostPerVolume(), getActiveOffer().getRig().getTrailer1().getFuelAndAdblueEquipmentFuelConsumption(), getActiveOffer().getRig().getTrailer1().getFuelAndAdblueEquipmentUtilisation());
            getActiveOffer().getRig().getTrailer1().setFuelAndAdblueTotalCostPerMonth(trailer1EquipmentFuelCostPerMonth);
            valueToUse += getActiveOffer().getRig().getTrailer1().getFuelAndAdblueTotalCostPerMonth();
        }
    }
    if (getActiveOffer().getTrailer2() !== null) {
        if(getActiveOffer().getRig().getTrailer2().getFuelAndAdblueTotalCostPerMonthOverride() === true) {
            valueToUse += getActiveOffer().getRig().getTrailer2().getFuelAndAdblueTotalCostPerMonth();
        } else {
            // Calculate the value
            // let trailer2EquipmentFuelCostPerMonth = getActiveOffer().getRig().getTrailer2().getFuelAndAdblueEquipmentFuelConsumption() * getActiveOffer().getRig().getTrailer2().getFuelAndAdblueEquipmentFuelCostPerVolume() * getActiveOffer().getRig().getTrailer2().getFuelAndAdblueEquipmentUtilisation();
            let trailer2EquipmentFuelCostPerMonth = globals.getMonthlyFuelCostUsingFuelConsumptionPerHour(getActiveOffer().getRig().getTrailer2().getFuelAndAdblueEquipmentFuelCostPerVolume(), getActiveOffer().getRig().getTrailer2().getFuelAndAdblueEquipmentFuelConsumption(), getActiveOffer().getRig().getTrailer2().getFuelAndAdblueEquipmentUtilisation());
            getActiveOffer().getRig().getTrailer2().setFuelAndAdblueTotalCostPerMonth(trailer2EquipmentFuelCostPerMonth);
            valueToUse += getActiveOffer().getRig().getTrailer2().getFuelAndAdblueTotalCostPerMonth();
        }
    }
    if(getActiveOffer().getCosting().getFuelAndAdblueCostPerMonthOverride() === false) {
        getActiveOffer().getCosting().setFuelAndAdblueCostPerMonth(valueToUse);
    }
}

function updateCostingFuelAndAdblueCostPerMonthAttrObvGroup() {
    // if (getActiveOffer().getCosting().getFuelAndAdblueCostPerMonthOverride() === false && displayLicenceDetailButtonOnCosting()) {
    if (getActiveOffer().getCosting().getFuelAndAdblueCostPerMonthOverride() === false) {
        // Update costing screen
        var valueToUse = 0,
            attrGroup = getAttributeGroup(config.OBJECT_TYPES.COSTING + '.' + config.VALUE_TYPE.COSTINGFUELANDADBLUECOSTPERMONTH);
        valueToUse += getActiveOffer().getCosting().getVehicleFuelAndAdblueTotalCostPerMonth();
        if (getActiveOffer().getTrailer1() !== null) {
            valueToUse += getActiveOffer().getRig().getTrailer1().getFuelAndAdblueTotalCostPerMonth();
        }
        if (getActiveOffer().getTrailer2() !== null) {
            valueToUse += getActiveOffer().getRig().getTrailer2().getFuelAndAdblueTotalCostPerMonth();
        }
        if (typeof attrGroup.value === 'function') {
            attrGroup.value.value = valueToUse;
        }
        getActiveOffer().getCosting().setFuelAndAdblueCostPerMonth(valueToUse);
    }
}

function updateCostingTollFees(performCheckOnTollFees) {
    if (displayTollFeesDetailOnCosting()) {
        // Update detail form
        var vehicleClassIdToUse = getVehicleClassId();

        if (getActiveOffer().getCosting().getVehicleClassIdOverride() === false) {
            getActiveOffer().getCosting().setVehicleClassId(vehicleClassIdToUse);
        } else {
            vehicleClassIdToUse = getActiveOffer().getCosting().getVehicleClassIdOverride();
        }

        // Update the tolls on performance
        var newTollFeesPerTrip = 0;
        getActiveOffer().getPerformance().getTolls().forEach(function (toll) {
            var tollToUse = globals.user.getTolls().find(function (userToll) { return userToll.Id === toll.getId(); });
            if (tollToUse !== undefined) {
                var tollFeeToUse = tollToUse.Fees.find(function (tollFee) { return tollFee.TollClassId === getActiveOffer().getCosting().getVehicleClassId(); });
                // Update toll to use
                toll.setActiveTollFee(tollFeeToUse.Fee);
                newTollFeesPerTrip += toll.getActiveTollFee();
            }
        });
        if (getActiveOffer().getCosting().getTollFeesPerTripOverride() === false) {
            if (performCheckOnTollFees === true) {
                var tollFeesFromUser = getTollFeesFromUserRelatedToRoute();
                if (getActiveOffer().getCosting().getTollFeesOverride() === false && getActiveOffer().getCosting().getTollFeesPerTripOverride() === false && tollFeesFromUser !== getActiveOffer().getCosting().getTollFeesPerTrip()) {
                    setNotifyUserOfTollFeesChange(true);
                } else {
                    // Update toll fees per trip
                    getActiveOffer().getCosting().setTollFeesPerTrip(newTollFeesPerTrip);
                }
            } else {
                // Update toll fees per trip
                getActiveOffer().getCosting().setTollFeesPerTrip(newTollFeesPerTrip);
            }
        }
    }
    updateCostingTollFeesAttrObvGroup();
}

function updateCostingTollFeesAttrObvGroup() {
    if (getActiveOffer().getCosting().getTollFeesOverride() === false && displayTollFeesDetailOnCosting()) {
        // Update costing screen
        var valueToUse = (getActiveOffer().getCosting().getTollFeesPerTrip() + getActiveOffer().getCosting().getPermitCostPerTrip()) * getActiveOffer().getCosting().getMonthlyTrips(),
            attrGroup = getAttributeGroup(config.OBJECT_TYPES.COSTING + '.' + config.VALUE_TYPE.COSTINGTOLLFEES);
        
        if (typeof attrGroup.value === 'function') {
            attrGroup.value.value = valueToUse;
        }
        getActiveOffer().getCosting().setTollFees(valueToUse);
    }
}

function updateFinanceCostPerMonthValues(updateFinanceTotalPrice) {
    if (updateFinanceTotalPrice && getActiveOffer().getCosting().getFinanceTotalPriceOverride() === false) {
        var attrGroup = getAttributeGroup(config.OBJECT_TYPES.COSTING + '.' + config.VALUE_TYPE.COSTINGFINANCETOTALPRICE);

        if (typeof attrGroup.value === 'function') {
            attrGroup.value.value = getFinanceTotalPrice();
        }
        getActiveOffer().getCosting().setFinanceTotalPrice(getFinanceTotalPrice());
        getOriginalOffer().getCosting().setFinanceTotalPrice(getFinanceTotalPrice());
    }

    getActiveOffer().getCosting().setFinanceDetailCostPerMonth(getFinanceCostPerMonth());
    getOriginalOffer().getCosting().setFinanceDetailCostPerMonth(getFinanceCostPerMonth());

    getActiveOffer().getCosting().setFinanceCostPerMonth(globals.getFinanceCostPerMonth(getActiveOffer().getCosting().getFinanceTotalPrice(), getActiveOffer().getCosting().getResidualRate(), getActiveOffer().getCosting().getInterestRate(), getActiveOffer().getCosting().getRepaymentTerm()));
    getOriginalOffer().getCosting().setFinanceCostPerMonth(globals.getFinanceCostPerMonth(getActiveOffer().getCosting().getFinanceTotalPrice(), getActiveOffer().getCosting().getResidualRate(), getActiveOffer().getCosting().getInterestRate(), getActiveOffer().getCosting().getRepaymentTerm()));
}

/**
        * Update finance total price 
        * */
function updateFinanceTotalPriceFunction() {
    var newTotal = 0;
    var financeTotalPriceAttributeObvGroup = getAttributeGroup('COSTING.' + config.VALUE_TYPE.COSTINGFINANCETOTALPRICE);

    if (typeof financeTotalPriceAttributeObvGroup.override === 'function' && financeTotalPriceAttributeObvGroup.override.value === false) {
        newTotal += calculateNetPrice(getActiveOffer().getCosting().getVehiclePrice(), getActiveOffer().getCosting().getVehicleDiscount());
        
        getActiveOffer().getRig().getVehicle().getAccessoryHolder().getAccessories().forEach(function (accessory) {
            newTotal += calculateNetPrice(accessory.getCostingListPrice(), accessory.getCostingDiscount());
        });

        if (getActiveOffer().getTrailer1() !== null) {
            newTotal += calculateNetPrice(getActiveOffer().getTrailer1().getCostingListPrice(), getActiveOffer().getTrailer1().getCostingDiscount());

            getActiveOffer().getTrailer1().getAccessoryHolder().getAccessories().forEach(function (accessory) {
                newTotal += calculateNetPrice(accessory.getCostingListPrice(), accessory.getCostingDiscount());
            });
        }

        if (getActiveOffer().getTrailer2() !== null) {
            newTotal += calculateNetPrice(getActiveOffer().getTrailer2().getCostingListPrice(), getActiveOffer().getTrailer2().getCostingDiscount());

            getActiveOffer().getTrailer2().getAccessoryHolder().getAccessories().forEach(function (accessory) {
                newTotal += calculateNetPrice(accessory.getCostingListPrice(), accessory.getCostingDiscount());
            });
        }

        //financeTotalPriceAttributeObvGroup.disableUndoOpCreation();
        //financeTotalPriceAttributeObvGroup.formatted(newTotal);
        //financeTotalPriceAttributeObvGroup.enableUndoOpCreation();
        //getActiveOffer().getCosting().setFinanceTotalPrice(newTotal);

        getActiveOffer().getCosting().setFinanceTotalPrice(newTotal);
        getOriginalOffer().getCosting().setFinanceTotalPrice(newTotal);
    }

    /**
        * Calculate and return discounted price
        * @param {number} price - Price of item
        * @param {number} discount - Discount percentage to be applied
        */
    function calculateNetPrice(price, discount) {
        return price - (price / 100) * discount;
    }
}

function updateCostingItems(updateFinanceTotalPrice, performCheckOnLicenceFees, performCheckOnTollFees) {
    if (getActiveOffer().getCosting() !== undefined && getActiveOffer().getCosting() !== null && globals.user.hasPermission(config.PERMISSIONS.COSTING.Code)) {
        updateFinanceTotalPriceFunction();
        updateFinanceCostPerMonthValues(updateFinanceTotalPrice);
        updateCostingTyreRate();
        updateCostingLicenceFees(performCheckOnLicenceFees);
        updateCostingFuelAndAdblueValues();
        //if (getActiveOffer().getPerformance() && getActiveOffer().getPerformance().getTolls()) {
        if (displayTollFeesDetailOnCosting() === true) {
            updateCostingTollFees(performCheckOnTollFees);
        }
    }
}


function getTrailer(trailerStub, path) {
    var dfd = $.Deferred();

    dataManager.getTrailer(trailerStub.getId(), trailerStub.getSource(), trailerStub.getAccessLevel())
        .then(function (trailerAsJSON) {
            var trailer = null;

            if (trailerAsJSON.Result.ReturnCode === 1) {
                trailerAsJSON.Trailer.CostingListPrice = 0;
                trailerAsJSON.Trailer.CostingListPriceOverride = false;
                trailerAsJSON.Trailer.CostingDiscount = 0;
                if (getActiveOffer().getCosting() !== undefined && getActiveOffer().getCosting() !== null && globals.user.hasPermission(config.PERMISSIONS.COSTING.Code)) {
                    trailerAsJSON.Trailer.CostingPeriod = getActiveOffer().getCosting().getVehicleCommonGroupFinancePeriod();
                    trailerAsJSON.Trailer.CostingInterestRate = getActiveOffer().getCosting().getCountryInterestRate();
                } else {
                    trailerAsJSON.Trailer.CostingPeriod = 0;
                    trailerAsJSON.Trailer.CostingInterestRate = 0;
                }
                trailerAsJSON.Trailer.CostingResidualRate = 0;
                trailerAsJSON.Trailer.CostingResidualRateType = config.RESIDUAL_RATE_TYPES.NET_PRICE;

                trailer = createTrailerObject('NEW', trailerAsJSON.Trailer, path); // User Story 17565
                if(trailerAsJSON.Trailer.DecompressedGraphicBlob && utils.isValidDecompressedGraphicFile(trailerAsJSON.Trailer.DecompressedGraphicBlob)) {
                    trailer.setDecompressedGraphicBlob(trailerAsJSON.Trailer.DecompressedGraphicBlob);
                }
                if(trailerAsJSON.Trailer.DecompressedTopViewGraphicBlob  && utils.isValidDecompressedGraphicFile(trailerAsJSON.Trailer.DecompressedTopViewGraphicBlob)) {
                    trailer.setDecompressedTopViewGraphicBlob(trailerAsJSON.Trailer.DecompressedTopViewGraphicBlob);
                }
                var cached = trailer.getCached();
                if (trailerStub.getAccessLevel() === config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY) {
                    cached = 0;
                }
                trailerStub.setCached(cached);

                trailer.setAccessLevel(trailerStub.getAccessLevel());
            } else if (trailerAsJSON.Result.ReturnCode === -2 || trailerAsJSON.Result.ReturnCode === -3) {
                // clean up stub
                removeTrailerFromAvailableTrailersArray(trailerStub);
                dataManager.remove(config.OPERATION.RemoveLocalTrailer, trailerStub.getId() + '_' + trailerStub.getAccessoryType() + '_' + trailerStub.getSource());
            }
            dfd.resolve({
                Response: trailerAsJSON,
                Trailer: trailer
            });
        })
        .fail(function (error) {
            dfd.reject(error);
        });

    return dfd.promise();
}


function addPayloadToCurrentChassis(payload, path) {

    var chassisObjectToUse = rigOps.getChassisObjectFromPath(path),
        originalChassisObjectToUse = rigOps.getOriginalChassisObjectFromPath(path);

    setParent(payload, path);

    chassisObjectToUse.getPayloadHolder().add(payload);
    originalChassisObjectToUse.getPayloadHolder().add(payload.clone(true));

    updatePayload();
    createPayloadObservables(payload, path);

    dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.ADDED_PAYLOAD_ITEM, payload.getIntercomMetadata()));

    doCommonUpdateActions();
    notifyValidationListeners();
    if(typeof objectAddedListenerCallback === 'function') {
        objectAddedListenerCallback(config.OBJECT_TYPES.ACCESSORY, payload, path);
    }
    clearPerformanceResults();
    unsavedChangesOnOffer.value = true;
    if (payload.getSource() === config.ACCESSORY_SOURCE_TYPES.STANDARD && globals.user.displayStandardItemMessage() === true) {
        showStandardItemToast(payload.getEditLevel(), globals.getAccessoryTypeTranslation(payload.getAccessoryType(), payload.getAccessorySubType()));
    }
}

function getPayload(payloadStub, path) {
    var dfd = $.Deferred();

    dataManager.getPayload(payloadStub.getId(), payloadStub.getSource(), payloadStub.getAccessLevel())
        .then(function (payloadAsJSON) {
            var payload = null;

            if (payloadAsJSON.Result.ReturnCode === 1) {
                payload = createPayloadObject('NEW', payloadAsJSON.Payload); // User Story 17565
                if(payloadAsJSON.Payload.DecompressedGraphicBlob  && utils.isValidDecompressedGraphicFile(payloadAsJSON.Payload.DecompressedGraphicBlob)) {
                    payload.setDecompressedGraphicBlob(payloadAsJSON.Payload.DecompressedGraphicBlob);
                }
                if(payloadAsJSON.Payload.DecompressedTopViewGraphicBlob && utils.isValidDecompressedGraphicFile(payloadAsJSON.Payload.DecompressedTopViewGraphicBlob)) {
                    payload.setDecompressedTopViewGraphicBlob(payloadAsJSON.Payload.DecompressedTopViewGraphicBlob);
                }
                var cached = payload.getCached();
                if (payloadStub.getAccessLevel() === config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY) {
                    cached = 0;
                }
                payloadStub.setCached(cached);
                payload.setAccessLevel(payloadStub.getAccessLevel());
            } else if (payloadAsJSON.Result.ReturnCode === -2 || payloadAsJSON.Result.ReturnCode === -3) {
                //clean up stubs
                removePayloadFromAvailablePayloadsArray(payloadStub);
                dataManager.remove(config.OPERATION.RemoveLocalPayload, payloadStub.getId() + '_' + payloadStub.getAccessoryType() + '_' + payloadStub.getSource());
            }

            dfd.resolve({
                Response: payloadAsJSON,
                Payload: payload
            });
        })
        .fail(function (error) {
            dfd.reject(error);
        });

    return dfd.promise();
}

function addWaypoint(waypoint) {
    waypoints.value.push(waypoint);
    updateWaypointNetPayloads();
    createObjectWithObservables(config.OBJECT_TYPES.WAYPOINT, waypoint, 'PERFORMANCE.WAYPOINTS');
    waypointAddedListenerCallback(config.OBJECT_TYPES.WAYPOINT, waypoint, 'PERFORMANCE');
}

function swapCurrentVehicle(vehicleStubDetails, newOfferDetails, newSpecDetails, licenceCategories) {
    
    undoHandler.disableUndoOpCreation();
    globals.modulesAddedOrDroppedAfterSwap.value = false;
    var fromVehicle = getActiveOffer().getConfiguration().getDescription();
    var toVehicle = vehicleStubDetails.Description;

    setStubValuesOnOffer(vehicleStubDetails, newOfferDetails.offer.Offer);

    var newOffer = new Offer(newOfferDetails.offer.Offer);
    newOffer.setSpecDate(vehicleStubDetails.SpecDate);
    newOffer.setApplication(getAvailableVehicleApplicationTypes()[0])

    var originalNewOffer = new Offer(newOfferDetails.offer.Offer);
    var baseNewOffer = new Offer(newOfferDetails.offer.Offer);

    var warnings;
    var curMaxSpeed, maxGearedSpeed, speedLimitedFlag = false;
    var configurationAdded = false,
        configurationDropped = false,
        performanceAdded = false,
        performanceDropped = false,
        costingAdded = false,
        costingDropped = false,
        specificationAdded = false,
        specificationDropped = false;
    var removeAddedFifthWheel = false;

    let currentVehicleType = getActiveOffer().getConfiguration().getType();
    let newVehicleType = newOfferDetails.offer.Offer.Configuration.Type;
    if(currentVehicleType === config.VEHICLE_TYPES.COMPLETE_VEHICLE && (newVehicleType === config.VEHICLE_TYPES.RIGID || newVehicleType === config.VEHICLE_TYPES.TRUCK_TRACTOR)) {
        //show can't swap from complete vehicle to rigid (overlay) dialog
        globals.showDialog(new CustomMessageBox(config.getMessageText('4804', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('4804')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
        
        return;
    }else if((currentVehicleType === config.VEHICLE_TYPES.RIGID || currentVehicleType === config.VEHICLE_TYPES.TRUCK_TRACTOR) && newVehicleType === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //show can't swap from rigid to complete vehicle (overlay) dialog
        globals.showDialog(new CustomMessageBox(config.getMessageText('4803', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('4803')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));

        return;
    }

    if (getActiveOffer().getRig().getVehicle().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES && (newOffer.getRig().getVehicle().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.NO || newOffer.getRig().getVehicle().getTareFifthWheelIncluded() === '') && getActiveOffer().getRig().getTrailer1() !== null && (getActiveOffer().getRig().getTrailer1().getType() === config.TRAILER_TYPES.SEMI || getActiveOffer().getRig().getTrailer1().getType() === config.TRAILER_TYPES.INTERLINK)) {
        //prevent swap and inform user why it failed
        var dialogBodyText = config.getMessageText('1633', globals.getActiveCultureCodeForTranslations()).replace('$S1', toVehicle); // User Story 17565
        globals.showDialog(new CustomMessageBox(dialogBodyText, config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1633')))
            .addCallback(function(dialogResult){
                nextTick(function () {
                    undoHandler.enableUndoOpCreation();
                    sendSwapRelatedIntercomEvent(fromVehicle, toVehicle, config.INTERCOM_EVENT.VEHICLE_SWAP_FAILED);
                });
            });
    } else {

        if (getActiveOffer().getRig().getVehicle().getAccessoryHolder().getFifthWheel() !== null && newOffer.getRig().getVehicle().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
            removeAddedFifthWheel = true;
        }

        if (globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION.Code)) {
            if (newOffer.getConfiguration() !== undefined && newOffer.getDataAvailability().ActiveConfiguration) {
                if (getActiveOffer().getConfiguration() !== undefined) {
                    setupOriginalOfferFuelTankInternalIds(newOffer, originalNewOffer);
                    setupOriginalAxleInternalIds(newOffer, originalNewOffer);

                    copyActiveOfferAdditionsOntoNewOffer(newOffer, getActiveOffer());

                    var tempRigOpsForSwap = new RigOps();
                    var evaluateLegislationWrapper = function (attributeName, legislation, alternativeRigOps) {
                        return evaluateLegislation(attributeName, legislation, tempRigOpsForSwap)
                    };
                    // var baseOffer = undefined;
                    tempRigOpsForSwap.setup(newOffer, originalNewOffer, baseNewOffer, evaluateLegislationWrapper, legislationVals);
                    tempRigOpsForSwap.setCombinationPermissibleVals();
                    setLegislationLookupObjectsCleared(false);
                    clearActiveLegislationQuickLookupObjects();
                    if (rigOps.canMaximisePayloadForCombination() && rigOps.noSimplePayloadObjectOverridden()) {
                        tempRigOpsForSwap.maximisePayloads();
                    }
                    generateDataForActiveLegislations(tempRigOpsForSwap);
                    warnings = generateDistinctWarnings(generateWarnings(tempRigOpsForSwap));

                } else {
                    //not currently possible to move from vehicle without configuration to vehicle with configuration as swap button is on vehicle menu in configuration. Will need to implement this path though if that changes in future
                    configurationAdded = true;
                }
            } else {
                if (getActiveOffer().getConfiguration() !== undefined) {
                    configurationDropped = true;
                }
            }
        }

        if (globals.user.hasPermission(config.PERMISSIONS.PERFORMANCE.Code)) {
            if (newOffer.getPerformance() !== undefined && newOffer.getDataAvailability().ActivePerformance) {
                if (getActiveOffer().getPerformance() !== undefined) {

                    curMaxSpeed = getActiveOffer().getPerformance().getSpeedMaxLoaded();
                    maxGearedSpeed = getCurMeasurementSysRoundedSpeed(getPerformanceMaximumSpeedMax(undefined, newOffer));

                    if (curMaxSpeed > maxGearedSpeed) {
                        curMaxSpeed = maxGearedSpeed;
                        speedLimitedFlag = true;
                    }


                } else {
                    performanceAdded = true;
                }
            } else {
                if (getActiveOffer().getPerformance() !== undefined) {
                    performanceDropped = true;
                }
            }
        }

        if (globals.user.hasPermission(config.PERMISSIONS.COSTING.Code)) {
            if (newOffer.getCosting() !== undefined && newOffer.getDataAvailability().ActiveCosting) {
                if (getActiveOffer().getCosting() !== undefined) {
                    //costing updated
                    
                } else {
                    costingAdded = true;
                }
            } else {
                if (getActiveOffer().getCosting() !== undefined) {
                    costingDropped = true;
                }
            }
        }

        if (globals.user.hasPermission(config.PERMISSIONS.SPECIFICATION.Code) || globals.user.allowSpecificationModule()) {
            if (newSpecDetails !== undefined && newOffer.getDataAvailability().ActiveSpecification) {
                if (getActiveOffer().getSpecification() !== undefined) {
                    //specification updated

                } else {
                    specificationAdded = true;
                }

            } else {
                if (getActiveOffer().getSpecification() !== undefined) {
                    specificationDropped = true;
                }
            }
        }

        var dialogMessage, dialogButtons, dialogThenFunction;
        var warningsTriggered = [];
        if (globals.user.isLoadingAdministrator()) {
            setupCancelOrContinueDialog();
        } else {
            if (warnings !== undefined) {


                var rearOverhangExceededWarning = warnings.getStringTrackerObjectById(config.WARNING_ID.REAR_OVERHANG_EXCEEDED);
                if (rearOverhangExceededWarning !== null) {
                    warningsTriggered.push(rearOverhangExceededWarning);
                }


                var overallHeightExceededWarning = warnings.getStringTrackerObjectById(config.WARNING_ID.OVERALL_HEIGHT_EXCEEDED);
                if (overallHeightExceededWarning !== null) {
                    warningsTriggered.push(overallHeightExceededWarning);
                }

                var overallLengthExceededWarning = warnings.getStringTrackerObjectById(config.WARNING_ID.OVERALL_LENGTH_EXCEEDED);
                if (overallLengthExceededWarning !== null) {
                    warningsTriggered.push(overallLengthExceededWarning);
                }
            }
            setupNonAdminDialogTextAndButtons();
        }
        if (dialogMessage !== undefined && dialogMessage !== '') {
            globals.showDialog(new CustomMessageBox(dialogMessage, config.applicationTitle, dialogButtons).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                .addCallback(dialogThenFunction);
        } else {
            globals.showSpinner();
            completeSwap();
        }
    }


    //need to check for admin user, generate module changes, messages and dialogs and then abort or complete swap

    function setupCancelOrContinueDialog() {
        dialogMessage = getSwapResultingChangesText();
        dialogButtons = [config.getTranslationText('952'), config.getTranslationText('65')];
        dialogThenFunction = cancelOrContinueSwap;
    }

    function sendSwapRelatedIntercomEvent(fromVehicle, toVehicle, intercomEvent) {
        //config.INTERCOM_EVENT.VEHICLE_SWAP_SUCCEEDED:
        //config.INTERCOM_EVENT.VEHICLE_SWAP_FAILED:
        //config.INTERCOM_EVENT.VEHICLE_SWAP_CANCELLED:
        var metaDataObject = {};
        metaDataObject[config.INTERCOM_METADATA_ITEM.FROM_VEHICLE] = fromVehicle;
        metaDataObject[config.INTERCOM_METADATA_ITEM.TO_VEHICLE] = toVehicle;

        dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(intercomEvent, metaDataObject));
    }

    function setupNonAdminDialogTextAndButtons() {

        var dialogBodyText;

        if (warningsTriggered.length > 0) {
            dialogBodyText = config.getTranslationText('1085') + ':</br>';

            warningsTriggered.forEach(function (warning) {
                dialogBodyText += ' - ' + warning.getStringVal() + '</br>';
            });

            dialogMessage = dialogBodyText;
            dialogButtons = [config.getTranslationText('2706')];
            dialogThenFunction = function (dialogResult) {
                nextTick(function () {
                    undoHandler.enableUndoOpCreation();
                    sendSwapRelatedIntercomEvent(fromVehicle, toVehicle, config.INTERCOM_EVENT.VEHICLE_SWAP_FAILED);
                });
            };
        } else {
            setupCancelOrContinueDialog();
        }


    }

    function getSwapResultingChangesText() {
        var swapResultingChangesText = '';
        if (configurationAdded || configurationDropped || performanceAdded || performanceDropped || costingAdded || costingDropped || specificationAdded || specificationDropped || speedLimitedFlag) {
            swapResultingChangesText += config.getTranslationText('1075') + ':';

            if (configurationAdded) {
                addLineBreak();
                swapResultingChangesText += ' - ' + config.getTranslationText('1076');
            }
            if (configurationDropped) {
                addLineBreak();
                swapResultingChangesText += ' - ' + config.getTranslationText('1077');
            }
            if (performanceAdded) {
                addLineBreak();
                swapResultingChangesText += ' - ' + config.getTranslationText('1078');
            }
            if (performanceDropped) {
                addLineBreak();
                swapResultingChangesText += ' - ' + config.getTranslationText('1079');
            }
            if (costingAdded) {
                addLineBreak();
                swapResultingChangesText += ' - ' + config.getTranslationText('1080');
            }
            if (costingDropped) {
                addLineBreak();
                swapResultingChangesText += ' - ' + config.getTranslationText('1081');
            }
            if (specificationAdded) {
                addLineBreak();
                swapResultingChangesText += ' - ' + config.getTranslationText('1082');
            }
            if (specificationDropped) {
                addLineBreak();
                swapResultingChangesText += ' - ' + config.getTranslationText('1083');
            }
            if (speedLimitedFlag) {
                addLineBreak();
                swapResultingChangesText += ' - ' + config.getTranslationText('1084');
            }

            addLineBreak();
        }

        return swapResultingChangesText;
        
        function addLineBreak() {
            swapResultingChangesText += '<br>';
        }
    }

    async function cancelOrContinueSwap(dialogResult) {
        if (dialogResult === config.getTranslationText('952')) {
            showShellSpinner(true);
            // completeSwap(true);
            if(router.currentRoute.value.name !== ROUTES.CONFIGURATION.name && router.currentRoute.value.name !== ROUTES.SUMMARY.name) {
                // await router.push(ROUTES.CONFIGURATION.name);
                // completeSwap(true);
                await goToConfigurationAndCompleteSwap(true);
                // nextTick(function () {
                //     nextTick(function () {
                //         completeSwap(true);
                //     });
                // });
            } else {
                completeSwap(true);
            }
        } else if (dialogResult === config.getTranslationText('65')) {
            doActionsAfterSuccesfulValidation();
            nextTick(function () {
                undoHandler.enableUndoOpCreation();
                sendSwapRelatedIntercomEvent(fromVehicle, toVehicle, config.INTERCOM_EVENT.VEHICLE_SWAP_CANCELLED);
            });
        }
    }

    async function goToConfigurationAndCompleteSwap(navigateToConfiguration) {
        await router.push(ROUTES.CONFIGURATION.name);
        completeSwap(true);
    }

    /**
     * 
     * @param {boolean} navigateToConfiguration - If true, will navigate to configuration after swap is complete
     */
    function completeSwap(navigateToConfiguration) {
        
        getActiveOffer().setDataAvailability(newOfferDetails.offer.Offer.DataAvailability);
            
        var reportIsCheckedValues = {};
        getActiveOffer().getSummaryReportOptions().forEach(function (reportOption) {
            if(reportOption.getCode() === config.SUMMARY_REPORT_OPTIONS.CONFIGURATION || reportOption.getCode() === config.SUMMARY_REPORT_OPTIONS.SPECIFICATION || reportOption.getCode() === config.SUMMARY_REPORT_OPTIONS.COSTING) {
                reportIsCheckedValues[reportOption.getCode()] = reportOption.getIsChecked();
            }
        });

        getActiveOffer().createSummaryReports(newOfferDetails.offer.Offer.DataAvailability);
        updateReportOfferOptions();
        activateReports(reportIsCheckedValues);

        populateOfferManagerTopLevelObservables(vehicleStubDetails, newOfferDetails.offer.Offer);
        getActiveOffer().setupOfferLevelValues(newOfferDetails.offer.Offer);
        getActiveOffer().setSpecDate(newOffer.getSpecDate());


        if (newOffer.getConfiguration() !== undefined) {
            if (getActiveOffer().getConfiguration() !== undefined) {
                completeConfigurationSwap();
            } else {
                //not currently possible to move from vehicle without configuration to vehicle with configuration as swap button is on vehicle menu in configuration. Will need to implement this path though if that changes in future 
            }
        } else {
            if (getActiveOffer().getConfiguration() !== undefined) {
                getActiveOffer().setConfiguration(undefined);
                getOriginalOffer().setConfiguration(undefined);
                deleteObservables(config.CHASSIS_OBJECT_TYPES.VEHICLE);
            }
        }


        if (newSpecDetails !== undefined) {
            specificationFetchComplete.value = false;
            specificationFetchSuccessful.value = false;
            if (getActiveOffer().getSpecification() !== undefined) {
                //handleSpecificationResults(newSpecDetails);
                getActiveOffer().setSpecification(undefined);
                //getOriginalOffer().setSpecification(undefined);
            }
            
            handleSpecificationResults(newSpecDetails);
        } else {
            getActiveOffer().setSpecification(undefined);
        }

        if (newOffer.getPerformance() !== undefined && globals.user.hasPermission(config.PERMISSIONS.PERFORMANCE.Code)) {
            if (getActiveOffer().getPerformance() !== undefined) {
                completePerformanceSwap();
            } else {
                getActiveOffer().createPerformance(newOfferDetails.offer.Offer.Performance);
                getOriginalOffer().createPerformance(newOfferDetails.offer.Offer.Performance);
                setupValidationConfigs();
                setupPerformanceObservables();
                setupPerformanceFormattedComputeds();
            }
            updateObservables();
            // Clear performance results
            clearPerformanceResults();
        } else {
            if (getActiveOffer().getPerformance() !== undefined && globals.user.hasPermission(config.PERMISSIONS.PERFORMANCE.Code)) {
                getActiveOffer().setPerformance(undefined);
                getOriginalOffer().setPerformance(undefined);
                deleteObservables(config.OBJECT_TYPES.PERFORMANCE);
            }
        }

        if (newOffer.getCosting() !== undefined && globals.user.hasPermission(config.PERMISSIONS.COSTING.Code)) {
            if (getActiveOffer().getCosting() !== undefined) {
                var curUtilisation = getActiveOffer().getCosting().getMonthlyDistance();
                var curFinancePeriod = getActiveOffer().getCosting().getRepaymentTerm();
                var curInterestRate = getActiveOffer().getCosting().getInterestRate();
                var curFuelPrice = getActiveOffer().getCosting().getFuelCost();
                var curCrew = getActiveOffer().getCosting().getCrewCostPerMonth();
                var curInsurance = getActiveOffer().getCosting().getInsuranceRate();
                var curOverheads = getActiveOffer().getCosting().getOverheadRate();
                var curTollFees = getActiveOffer().getCosting().getTollFees();
                var curTotalRevenue = getActiveOffer().getCosting().getTotalRevenue();

                var curFinanceTotalPrice = getActiveOffer().getCosting().getFinanceTotalPrice(),
                    curFinanceTotalPriceOverride = getActiveOffer().getCosting().getFinanceTotalPriceOverride(),
                    curLicenceFees = getActiveOffer().getCosting().getLicenceFees(),
                    curLicenceFeesOverride = getActiveOffer().getCosting().getLicenceFeesOverride(),
                    curFuelAndAdblueCostPerMonth = getActiveOffer().getCosting().getFuelAndAdblueCostPerMonth(),
                    curFuelAndAdblueCostPerMonthOverride = getActiveOffer().getCosting().getFuelAndAdblueCostPerMonthOverride(),
                    curTyreRate = getActiveOffer().getCosting().getTyreRate(),
                    curTyreRateOverride = getActiveOffer().getCosting().getTyreRateOverride(),
                    curVehicleLicenceFeesRegionId;

                if (displayLicenceDetailButtonOnCosting()) {
                    curVehicleLicenceFeesRegionId = getActiveOffer().getCosting().getVehicleLicenceFeesRegionId();
                }

                getActiveOffer().updateCosting(newOfferDetails.offer.Offer.Costing);
                getOriginalOffer().updateCosting(newOfferDetails.offer.Offer.Costing);

                getActiveOffer().getCosting().setLicenceCategories(licenceCategories.licenceCategories);
                getOriginalOffer().getCosting().setLicenceCategories(licenceCategories.licenceCategories);

                getActiveOffer().getCosting().setMonthlyDistance(curUtilisation);
                getActiveOffer().getCosting().setRepaymentTerm(curFinancePeriod);
                getActiveOffer().getCosting().setInterestRate(curInterestRate);
                getActiveOffer().getCosting().setFuelCost(curFuelPrice);
                getActiveOffer().getCosting().setCrewCostPerMonth(curCrew);
                getActiveOffer().getCosting().setInsuranceRate(curInsurance);
                getActiveOffer().getCosting().setOverheadRate(curOverheads);
                getActiveOffer().getCosting().setTollFees(curTollFees);
                getActiveOffer().getCosting().setTotalRevenue(curTotalRevenue);

                if(curFinanceTotalPriceOverride === true) {
                    getActiveOffer().getCosting().setFinanceTotalPrice(curFinanceTotalPrice);
                    getActiveOffer().getCosting().setFinanceTotalPriceOverride(curFinanceTotalPriceOverride);
                }
                if(curLicenceFeesOverride === true) {
                    getActiveOffer().getCosting().setLicenceFees(curLicenceFees);
                    getActiveOffer().getCosting().setLicenceFeesOverride(curLicenceFeesOverride);
                }
                if(curFuelAndAdblueCostPerMonthOverride === true) {
                    getActiveOffer().getCosting().setFuelAndAdblueCostPerMonth(curFuelAndAdblueCostPerMonth);
                    getActiveOffer().getCosting().setFuelAndAdblueCostPerMonthOverride(curFuelAndAdblueCostPerMonthOverride);
                }

                getActiveOffer().getCosting().setVehicleFuelAndAdblueFuelConsumption(newOffer.getCosting().getVehicleFuelAndAdblueFuelConsumption());
                getActiveOffer().getCosting().setVehicleFuelAndAdblueFuelConsumptionOverride(false);
                getActiveOffer().getCosting().setVehicleFuelAndAdblueAdblueConsumption(newOffer.getCosting().getVehicleFuelAndAdblueAdblueConsumption());
                getActiveOffer().getCosting().setVehicleFuelAndAdblueAdblueConsumptionOverride(false);

                getActiveOffer().updateCostingFinanceTotalPrice();

                updateCapitalCosts();
                var defaultLicenceRegion;
                if (displayLicenceDetailButtonOnCosting()) {
                    defaultLicenceRegion = globals.getLicenceCategoryObjectToUse(getActiveOffer().getCosting().getLicenceCategories(), 'VEHICLE', getActiveOffer().getRig().getVehicle().getUnladenTotal(), globals.user.getSpecifyLicencingRegionAs());
                    if(curVehicleLicenceFeesRegionId !== defaultLicenceRegion.RegionId) {
                        getActiveOffer().getCosting().setVehicleLicenceFeesRegionId(curVehicleLicenceFeesRegionId);
                    } else {
                        getActiveOffer().getCosting().setVehicleLicenceFeesRegionId(defaultLicenceRegion.RegionId);
                    }
                }
                
                if(curTyreRateOverride === true) {
                    getActiveOffer().getCosting().setTyreRate(curTyreRate);
                    getActiveOffer().getCosting().setTyreRateOverride(curTyreRateOverride);
                } else {
                    getActiveOffer().getCosting().setTyreRate(getTyreRateValueFromTyreDetail());
                }
                updateCostingItems();
            } else {
                getActiveOffer().createCosting(newOfferDetails.offer.Offer.Costing);
                getOriginalOffer().createCosting(newOfferDetails.offer.Offer.Costing);

                getActiveOffer().getCosting().setLicenceCategories(licenceCategories.licenceCategories);
                getOriginalOffer().getCosting().setLicenceCategories(licenceCategories.licenceCategories);

                setupValidationConfigs();
                updateCapitalCosts();
                if (displayLicenceDetailButtonOnCosting()) {
                    defaultLicenceRegion = globals.getLicenceCategoryObjectToUse(getActiveOffer().getCosting().getLicenceCategories(), 'VEHICLE', getActiveOffer().getRig().getVehicle().getUnladenTotal(), globals.user.getSpecifyLicencingRegionAs());
                    getActiveOffer().getCosting().setVehicleLicenceFeesRegionId(defaultLicenceRegion.RegionId);
                }
                getActiveOffer().getCosting().setTyreRate(getTyreRateValueFromTyreDetail());
                updateCostingItems();

                setupCostingObservables();
                setupCostingFormattedComputeds();
            }
            updateObservables();
        } else {
            if (getActiveOffer().getCosting() !== undefined && globals.user.hasPermission(config.PERMISSIONS.COSTING.Code)) {
                cleanUpCostingComputeds();
                getActiveOffer().setCosting(undefined);
                getOriginalOffer().setCosting(undefined);
                deleteObservables(config.OBJECT_TYPES.COSTING);
            }
        }

        setupReportOptionObservables();
        
        if (globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION.Code) && newOffer.getConfiguration() !== undefined && newOffer.getDataAvailability().ActiveConfiguration) {
            doCommonUpdateActions();

            refreshData.value = new Date().getTime();
            refreshUILiveLabels.value = new Date().getTime();
        }
        if(typeof objectAddedListenerCallback === 'function') {
            objectAddedListenerCallback(config.CHASSIS_OBJECT_TYPES.VEHICLE, getActiveOffer().getConfiguration(), config.CHASSIS_OBJECT_TYPES.VEHICLE);
        }
        unsavedChangesOnOffer.value = true;
        globals.hideSpinner();
        // showShellSpinner(false);
        sendSwapRelatedIntercomEvent(fromVehicle, toVehicle, config.INTERCOM_EVENT.VEHICLE_SWAP_SUCCEEDED);
        if(typeof postCompleteSwapFunction === 'function') {
            postCompleteSwapFunction();
        }
        configurationActionResponseHandlerCallback(config.ACTION_TYPE.SWAP, config.ACTION_ID.SWAP_COMPLETED, function (dialogResult) {
            if (dialogResult === config.getTranslationText('576')) {
                description.value = vehicleName.value;
                //additionalDescription('');
                getActiveOffer().setDescription(newOfferDetails.offer.Offer.Description || '');
                //getActiveOffer().setAdditionalDescription(newOfferDetails.offer.Offer.AdditionalDescription || '');
                var settingsDescriptionAOG = getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSDESCRIPTION);
                settingsDescriptionAOG.value.value = getActiveOffer().getDescription();
            }
        });
        undoHandler.clearUndoStack();
        if (configurationAdded || configurationDropped || performanceAdded || performanceDropped || costingAdded || costingDropped || specificationAdded || specificationDropped) {
            globals.modulesAddedOrDroppedAfterSwap.value = true;            
        }
        updateRoutesWhenOpeningOffer();
        
        nextTick(function () {
            undoHandler.enableUndoOpCreation();
        });
    }
    
    function completeConfigurationSwap() {

        getActiveOffer().getRig().getVehicle().setVehicleTopViewDrawing(null);
        getOriginalOffer().getRig().getVehicle().setVehicleTopViewDrawing(null);
        getActiveOffer().getRig().getVehicle().setDecompressedVehicleTopViewDrawing(null);
        getOriginalOffer().getRig().getVehicle().setDecompressedVehicleTopViewDrawing(null);

        getActiveOffer().updateConfiguration(newOfferDetails.offer.Offer.Configuration);
        getOriginalOffer().updateConfiguration(newOfferDetails.offer.Offer.Configuration);

        getActiveOffer().getRig().getVehicle().getAxlesHolder().calculatePercentagesOfWeightCarried();
        getOriginalOffer().getRig().getVehicle().getAxlesHolder().calculatePercentagesOfWeightCarried();

        var tempVehicle = getActiveOffer().getRig().getVehicle();

        if (tempVehicle.getAccessoryHolder().getFridge() !== null) {
            tempVehicle.getAccessoryHolder().getFridge().setVerticalGap(tempVehicle.getChassisHeightMax());
        }

        setupOriginalOfferFuelTankInternalIds(getActiveOffer(), getOriginalOffer());
        setupOriginalAxleInternalIds(getActiveOffer(), getOriginalOffer());
        generateDataForActiveLegislations();
        //updateBridgeFormulas(config.CHASSIS_OBJECT_TYPES.VEHICLE);
        deleteObservables(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES);
        deleteObservables(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.FUEL_TANKS);

        addAxlesObservables(getConfiguration().getAxles(), config.CHASSIS_OBJECT_TYPES.VEHICLE);
        addAxleGroupsObservables(getConfiguration().getAxleGroups(), config.CHASSIS_OBJECT_TYPES.VEHICLE);
        addAxleRearTotalObjectObservables(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_REAR_TOTAL);
        //addAxlesTotalsObservables(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_TOTAL);
        addFuelTankObservables(getConfiguration().getFuelTanks());
        setupAllowedAccessoryTypes();
        setupAllowedTrailerTypesTrailer1();
        setupAllowedTrailerTypesTrailer2();
        resetTurningAbilityVariables();
        initialiseTurningAbilityVariables();
        forceTurningAbilityMenuItemsRefresh();
        updateGapPositioningForAddedEquipment();
        tempVehicle.setWheelbaseOverride(false);
        tempVehicle.setWheelbaseSpecified(rigOps.getWheelbaseSpecified());
        getActiveOffer().setKeepChassisFlushWithBody(false);

        if (tempVehicle.getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
            tempVehicle.setFifthWheelAdded(true);
        } else {
            tempVehicle.setFifthWheelAdded(false);
        }

        if (tempVehicle.getAccessoryHolder().getBody() !== null) {
            var keepChassisFlushWithBodyAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.KEEPCHASSISFLUSHWITHBODY);
            keepChassisFlushWithBodyAOG.value.value = false;
        }

        if (removeAddedFifthWheel) {
            //deleteObject(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FIFTH_WHEEL, getActiveOffer().getRig().getVehicle().getAccessoryHolder().getFifthWheel().getId());
            configurationActionResponseHandlerCallback(config.ACTION_TYPE.SWAP, config.ACTION_ID.REMOVE_ADDED_FIFTHWHEEL_AFTER_SWAP);
        }
        
        
        
    }

    function updateGapPositioningForAddedEquipment() {

        //var bodyCabGapAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYCABGAP);
        //bodyCabGapAOG.
        var tempVehicle = getActiveOffer().getRig().getVehicle();
        var tempOriginalVehicle = getOriginalOffer().getRig().getVehicle();

        var tempBody = tempVehicle.getAccessoryHolder().getBody();
        if (tempBody !== null) {
            tempBody.setCabGapOverride(false);
            tempBody.setHorizontalGap(tempVehicle.getCabGapRecommendedMin());

            var tempOriginalBody = tempOriginalVehicle.getAccessoryHolder().getBody();
            if (tempOriginalBody !== null) {
                tempOriginalBody.setHorizontalGap(tempVehicle.getCabGapRecommendedMin());
            }
        }



        var tempCrane = tempVehicle.getAccessoryHolder().getCrane();
        if (tempCrane !== null) {
            tempCrane.setCabGapOverride(false);
            tempCrane.setHorizontalGap(tempVehicle.getCabGapRecommendedMin());

            var tempOriginalCrane = tempOriginalVehicle.getAccessoryHolder().getCrane();
            if (tempOriginalCrane !== null) {
                tempOriginalCrane.setHorizontalGap(tempVehicle.getCabGapRecommendedMin());
            }
        }

        var tempFithWheel = tempVehicle.getAccessoryHolder().getFifthWheel();
        if (tempFithWheel !== null) {
            tempFithWheel.setOffset(tempVehicle.getFifthWheelOffset())

            var tempOriginalFithWheel = tempOriginalVehicle.getAccessoryHolder().getFifthWheel();
            if (tempOriginalFithWheel !== null) {
                tempOriginalFithWheel.setOffset(tempVehicle.getFifthWheelOffset())
            }
        }

        var tempHitch = tempVehicle.getAccessoryHolder().getHitch();
        var offset;
        if (tempHitch !== null) {
            offset = tempVehicle.getOverallLengthExclAccessories() - tempVehicle.getRearProtrusion() + tempHitch.getCouplingOffset() - (tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical());
            tempHitch.setOffset(offset);

            var tempOriginalHitch = tempOriginalVehicle.getAccessoryHolder().getHitch();
            if (tempOriginalHitch !== null) {
                offset = tempVehicle.getOverallLengthExclAccessories() - tempVehicle.getRearProtrusion() + tempHitch.getCouplingOffset() - (tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical());
                tempOriginalHitch.setOffset(offset);
            }
        }

    }

    

    function forceTurningAbilityMenuItemsRefresh() {

        //var turningAbilitySpecifiedAsAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TURNINGABILITYSPECIFIEDAS);
        //turningAbilitySpecifiedAsAOG.optionProxy(getActiveOffer().getRig().getVehicle().getTurningAbilitySpecifiedAs());
        //turningAbilitySpecifiedAsAOG.optionId(getTurningAbilitySpecifiedAsOptions()[0].id);
        //turningAbilitySpecifiedAsAOG.permissionObv(turningAbilitySpecifiedAsAOG.permission(turningAbilitySpecifiedAsAOG.getPath()));

        var steeringAngleInnerAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.STEERINGANGLEINNER);
        steeringAngleInnerAOG.permissionObv.value = steeringAngleInnerAOG.permission(steeringAngleInnerAOG.getPath());

        var turningRadiusCurbToCurbAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TURNINGRADIUSCURBTOCURB);
        turningRadiusCurbToCurbAOG.permissionObv.value = turningRadiusCurbToCurbAOG.permission(turningRadiusCurbToCurbAOG.getPath());

        var turningRadiusWallToWallAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TURNINGRADIUSWALLTOWALL);
        turningRadiusWallToWallAOG.permissionObv.value = turningRadiusWallToWallAOG.permission(turningRadiusWallToWallAOG.getPath());
    }

    function completePerformanceSwap() {
        getActiveOffer().updatePerformance(newOfferDetails.offer.Offer.Performance);
        getOriginalOffer().updatePerformance(newOfferDetails.offer.Offer.Performance);

        getActiveOffer().getPerformance().setSpeedMaxLoaded(curMaxSpeed);
        getActiveOffer().getPerformance().setVehicleTotalUnladenOverride(false);
        getActiveOffer().getPerformance().setFrontalAreaOverride(false);
        getActiveOffer().getPerformance().setCoefficientOfDragOverride(false);
        getActiveOffer().getPerformance().setDriveAxleRatioOverride(false);
        //getActiveOffer().getPerformance().setTrailerTotalUnladenOverride(false);
        //getActiveOffer().getPerformance().setTrailer2TotalUnladenOverride(false);

        getActiveOffer().getPerformance().setDriveAxleRatio(getActiveOffer().getRig().getVehicle().getAxlesHolder().getStandardRatioOfDrivenAxle());
        getActiveOffer().getPerformance().setVehicleTotalUnladen(getActiveOffer().getRig().getVehicle().getUnladenTotal());
        getActiveOffer().getPerformance().setFrontalArea(getRigOps().getFrontalArea());
        if (getActiveOffer().getRig().getTrailer1() !== null) {
            if (getActiveOffer().getPerformance().getTrailerTotalUnladenOverride() === false) {
                getActiveOffer().getPerformance().setTrailerTotalUnladen(getActiveOffer().getRig().getTrailer1().getUnladenTotal());
            }
        } else {
            getActiveOffer().getPerformance().setTrailerTotalUnladen(0);
        }
        if (getActiveOffer().getRig().getTrailer2() !== null) {
            if (getActiveOffer().getPerformance().getTrailer2TotalUnladenOverride() === false) {
                getActiveOffer().getPerformance().setTrailer2TotalUnladen(getActiveOffer().getRig().getTrailer2().getUnladenTotal());
            }
        } else {
            getActiveOffer().getPerformance().setTrailer2TotalUnladen(0);
        }

        if (clearPerformanceSimulationResultOnly) {
            clearPerformanceSimulationResultOnly();
        }
    }

}

function resetTurningAbilityVariables() {
    var tempVehicle = getActiveOffer().getRig().getVehicle();

    tempVehicle.setTurningAbilitySpecifiedAs(undefined);
    tempVehicle.setSteeringAngleInnerOverride(false);
    tempVehicle.setTurningRadiusWallToWallOverride(false);
    tempVehicle.setTurningRadiusCurbToCurbOverride(false);

}

function setStubValuesOnOffer(vehicleDetails, offerDetails) {
    offerDetails.Id = 0
    offerDetails.UpdateCounter = 0
    offerDetails.retrievedBodyType = vehicleDetails.BodyTypeTranslation;
    offerDetails.retrievedBodyManufacturer = vehicleDetails.BodyManufacturerDescription;
    offerDetails.VehicleMake = vehicleDetails.vehicleMake;
    offerDetails.VehicleRange = vehicleDetails.vehicleRange;
    offerDetails.Description = offerDetails.VehicleDescription;
    offerDetails.Costing.OnRoadCostsCosts = globals.user.getOnRoadCostsCosts();
    offerDetails.Costing.OnRoadCostsDiscounts = globals.user.getOnRoadCostsDiscounts();
}

function copyActiveOfferAdditionsOntoNewOffer(newOffer, activeOffer) {
    activeOffer.getRig().getVehicle().getAccessoryHolder().getAccessories().forEach(function (accessory) {
        newOffer.getRig().getVehicle().getAccessoryHolder().add(accessory);
    });

    activeOffer.getRig().getVehicle().getPayloadHolder().getPayloadObjects().forEach(function (payload) {
        newOffer.getRig().getVehicle().getPayloadHolder().add(payload);
    });

    if (activeOffer.getRig().getTrailer1() !== null) {
        newOffer.getRig().addChassisObject(activeOffer.getRig().getTrailer1());
        if (activeOffer.getRig().getTrailer2() !== null) {
            newOffer.getRig().addChassisObject(activeOffer.getRig().getTrailer2());
        }
    }

}

function populateOfferManagerTopLevelObservables(vehicleStubDetails, newOfferDetails) {
    languageId.value = newOfferDetails.LanguageId;
    vehicleId.value = newOfferDetails.VehicleId;
    vehicleName.value = newOfferDetails.VehicleDescription;
    vehicleModel.value = newOfferDetails.VehicleModel;
    vehicleMake.value = vehicleStubDetails.vehicleMake;
    vehicleRange.value = vehicleStubDetails.vehicleRange;
    vehicleGVW.value = newOfferDetails.GVW;
    vehiclePayload.value = newOfferDetails.Payload;
    vehiclePower.value = newOfferDetails.Power;
    vehicleAxleLayout.value = newOfferDetails.AxleLayout;
    vehiclePlusBodyImageURL.value = newOfferDetails.VehiclePlusBodyImageURL;
    bodyType.value = vehicleStubDetails.BodyTypeTranslation;
    bodyManufacturer.value = vehicleStubDetails.BodyManufacturerDescription;
    vehicleTypeTranslation.value = vehicleStubDetails.VehicleTypeTranslation;
    vehicleDistributionGroupId.value = newOfferDetails.VehicleDistributionGroupId;
    vehicleDistributionOptionId.value = newOfferDetails.VehicleDistributionOptionId;
    vehicleStatus.value = vehicleStubDetails.Status;
}

function getAvailableVehicleApplicationTypes(legislations) {
    var returnArray = [],
        showConcessionalMassLimitsOption = false,
        showHigherMassLimitsOption = false,
        showAbnormalPermitOption = false;
        

    if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
        if (getLesserOfActiveLegislationVals(legislationVals.variableTypes.ShowConcessionalMassLimitsOption, legislations) === 1 && getLesserOfActiveLegislationVals(legislationVals.variableTypes.ConcessionalMassLimitsForCombination, legislations) === 1) {
            showConcessionalMassLimitsOption = true;
        }
        if (getLesserOfActiveLegislationVals(legislationVals.variableTypes.ShowHigherMassLimitsOption, legislations) === 1 && getLesserOfActiveLegislationVals(legislationVals.variableTypes.HigherMassLimitsForCombination, legislations) === 1) {
            showHigherMassLimitsOption = true;
        }
        if (getLesserOfActiveLegislationVals(legislationVals.variableTypes.AbnormalPermit, legislations) === 1) {
            showAbnormalPermitOption = true;
        }

        returnArray.push({
            id: config.VEHICLE_APPLICATION_TYPES.ONROAD,
            description: config.getTranslationText('275')
        });

        // If legislation allows for this to be displayed
        //if (evaluateLegislation('SHOWHIGHERMASSLIMITSOPTION', legislationToUse) === 1 && evaluateLegislation('HIGHERMASSLIMITSFORCOMBINATION', legislationToUse) === 1) {
        //if (evaluateLegislation('SHOWCONCESSIONALMASSLIMITSOPTION', legislationToUse, null, true) === 1 && evaluateLegislation('CONCESSIONALMASSLIMITSFORCOMBINATION', legislationToUse, null, true) === 1) {
        if (showConcessionalMassLimitsOption === true){
            //If Legislation, ShowConcessionalMassLimitsOption = 1 and Legislation, ConcessionalMassLimitsForCombination = 1
            returnArray.push({
                id: config.VEHICLE_APPLICATION_TYPES.CONCESSIONALMASSLIMIT,
                description: config.getTranslationText('1354')
            });
        }

        // If legislation allows for this to be displayed
        //if (evaluateLegislation('SHOWHIGHERMASSLIMITSOPTION', legislationToUse, null, true) === 1 && evaluateLegislation('HIGHERMASSLIMITSFORCOMBINATION', legislationToUse, null, true) === 1) {
        if (showHigherMassLimitsOption === true){
            returnArray.push({
                id: config.VEHICLE_APPLICATION_TYPES.HIGHERMASSLIMIT,
                description: config.getTranslationText('184')
            });
        }

        returnArray.push({
            id: config.VEHICLE_APPLICATION_TYPES.OFFROADONLY,
            description: config.getTranslationText('871')
        });

        //if (evaluateLegislation(legislationVals.variableTypes.AbnormalPermit) === 1) {
        if (showAbnormalPermitOption === true){
            returnArray.push({
                id: config.VEHICLE_APPLICATION_TYPES.ABNORMALPERMIT,
                description: config.getTranslationText('2')
            });
        }
    }

    //function checkLegislation(legislationToCheck) {
    //    if (evaluateLegislation(legislationVals.variableTypes.ShowConcessionalMassLimitsOption, legislationToCheck, null, true) === 1 && evaluateLegislation(legislationVals.variableTypes.ConcessionalMassLimitsForCombination, legislationToCheck, null, true) === 1) {
    //        showConcessionalMassLimitsOption = true;
    //    }
    //    if (evaluateLegislation(legislationVals.variableTypes.ShowHigherMassLimitsOption, legislationToCheck, null, true) === 1 && evaluateLegislation(legislationVals.variableTypes.HigherMassLimitsForCombination, legislationToCheck, null, true) === 1) {
    //        showHigherMassLimitsOption = true;
    //    }
    //    if (evaluateLegislation(legislationVals.variableTypes.AbnormalPermit) === 1) {
    //        showAbnormalPermitOption = true;
    //    }
    //}

    return returnArray;
}

function getSideDoorTypeOptions() {
    return [{
        id: config.SIDE_DOOR_TYPES.SINGLE,
        description: config.getTranslationText('438')
    },
    {
        id: config.SIDE_DOOR_TYPES.DOUBLE,
        description: config.getTranslationText('119')
    }
    ];
}

function getYesNoNotSpecifiedOptions() {
    return [{
        id: config.YES_NO_OPTIONS.YES,
        description: config.getTranslationText('2594')
    },
    {
        id: config.YES_NO_OPTIONS.NO,
        description: config.getTranslationText('2595')
    },
    {
        id: config.YES_NO_OPTIONS.NOT_SPECIFIED,
        description: config.getTranslationText('266')
    }
    ];
}

function getYesNoOptions() {
    return [{
        id: config.YES_NO_OPTIONS.YES,
        description: config.getTranslationText('2862')
    },
    {
        id: config.YES_NO_OPTIONS.NO,
        description: config.getTranslationText('2863')
    }
    ];
}

function getNumberOfTyresOptions() {
    return [{
        id: config.NUMBER_OF_TYRES_ON_AXLE_OPTIONS.TWO.id,
        description: config.NUMBER_OF_TYRES_ON_AXLE_OPTIONS.TWO.val
    },
    {
        id: config.NUMBER_OF_TYRES_ON_AXLE_OPTIONS.FOUR.id,
        description: config.NUMBER_OF_TYRES_ON_AXLE_OPTIONS.FOUR.val
    }
    ];
}

function getCOGTypeOptions() {
    return [{
        id: config.COG_TYPES.PERCENTAGE,
        description: '%'
    },
    {
        id: config.COG_TYPES.LENGTH,
        description: globals.user.getActiveMeasurementSystem().unitLength
    }
    ]
}

function getMassTypeOptions() {
    return [{
        id: config.MASS_TYPES.MASS,
        description: globals.user.getActiveMeasurementSystem().unitMass
    },
    {
        id: config.MASS_TYPES.MASSPERDISTANCE,
        description: globals.user.getActiveMeasurementSystem().unitLinearDensity
    }
    ]
}

function getFuelTypeOptions() {
    return [
        {
            id: config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE,
            description: config.getTranslationText('4648')
        },
        {
            id: config.FUEL_TYPE_OPTIONS.BIODIESEL.CODE,
            description: config.getTranslationText('4646')
        },
        {
            id: config.FUEL_TYPE_OPTIONS.CNG.CODE,
            description: config.getTranslationText('4644')
        },
        {
            id: config.FUEL_TYPE_OPTIONS.DIESEL.CODE,
            description: config.getTranslationText('112')
        },
        {
            id: config.FUEL_TYPE_OPTIONS.ETHANOL.CODE,
            description: config.getTranslationText('129')
        },
        {
            id: config.FUEL_TYPE_OPTIONS.HYDROGEN_FUEL_CELL.CODE,
            description: config.getTranslationText('4647')
        },
        {
            id: config.FUEL_TYPE_OPTIONS.LNG.CODE,
            description: config.getTranslationText('4645')
        },
        {
            id: config.FUEL_TYPE_OPTIONS.PETROL.CODE,
            description: config.getTranslationText('302')
        }
    ]
}

function getSortedFuelTypeOptions() {
    let fuelTypeOptions = getFuelTypeOptions();

    fuelTypeOptions.sort(function (a, b) {
        if (a.description < b.description)  { 
            return -1; 
        } else if (a.description > b.description) { 
            return 1;
        }
        return 0;
      });

      return fuelTypeOptions
}

function getOfferedAsOptions() {
    return [{
        id: config.OFFERED_AS_OPTIONS.STANDARD,
        description: config.getTranslationText('449')
    },
    {
        id: config.OFFERED_AS_OPTIONS.OPTIONAL,
        description: config.getTranslationText('277')
    }
    ]
}

function getBodyLengthTypeOptions() {
    return [{
        id: config.BODY_MEASUREMENT_TYPES.EXTERNAL,
        description: config.getTranslationText('1485')
    },
    {
        id: config.BODY_MEASUREMENT_TYPES.INTERNAL,
        description: config.getTranslationText('1486')
    }
    ]
}

function getUnitOfMeasureOptions() {
    if (unitOfMeasureOptions.length === 0) {
        unitOfMeasureOptions.push({
            id: config.PAYLOAD_UNIT_OF_MEASURE_TYPES.MASS,
            description: globals.user.getActiveMeasurementSystem().unitMass
        });
        unitOfMeasureOptions.push({
            id: config.PAYLOAD_UNIT_OF_MEASURE_TYPES.VOLUME,
            description: globals.user.getActiveMeasurementSystem().unitVolumePlural
        });
        unitOfMeasureOptions.push({
            id: config.PAYLOAD_UNIT_OF_MEASURE_TYPES.CUBIC_VOLUME,
            description: globals.user.getActiveMeasurementSystem().unitCubicVolume
        });
    }
    return unitOfMeasureOptions;
    //return [
    //    {
    //        id: config.PAYLOAD_UNIT_OF_MEASURE_TYPES.MASS,
    //        description: globals.user.getActiveMeasurementSystem().unitMass
    //    },
    //    {
    //        id: config.PAYLOAD_UNIT_OF_MEASURE_TYPES.VOLUME,
    //        description: globals.user.getActiveMeasurementSystem().unitVolumePlural
    //    },
    //    {
    //        id: config.PAYLOAD_UNIT_OF_MEASURE_TYPES.CUBIC_VOLUME,
    //        description: globals.user.getActiveMeasurementSystem().unitCubicVolume
    //    }
    //];
}

function getPayloadTypeOptions() {
    return [{
        id: config.PAYLOAD_TYPES.SIMPLE,
        description: config.getTranslationText('899')
    },
    {
        id: config.PAYLOAD_TYPES.DETAILED,
        description: config.getTranslationText('900')
    }
    ];
}

function getWeightTypeOptions() {
    return [{
        id: config.PAYLOAD_WEIGHT_TYPES.WEIGHT,
        description: config.getTranslationText('4039')
    },
    {
        id: config.PAYLOAD_WEIGHT_TYPES.VOLUME,
        description: config.getTranslationText('4040')
    }
    ];
}

function getDrawbarTypeOptions() {
    return [{
        id: config.DRAWBAR_TYPES.CONVENTIONAL,
        description: config.getTranslationText('930')
    },
    {
        id: config.DRAWBAR_TYPES.UNDERSLUNG,
        description: config.getTranslationText('931')
    }
    ];
}

function getPostValidatedVehicleStatusOptions() {
    return [{
        id: config.VEHICLE_STATUS_TYPES.CURRENT,
        description: config.getTranslationText('1115')
    },
    {
        id: config.VEHICLE_STATUS_TYPES.DISCONTINUED,
        description: config.getTranslationText('1116')
    },
    {
        id: config.VEHICLE_STATUS_TYPES.SPECIAL,
        description: config.getTranslationText('1117')
    },
    {
        id: config.VEHICLE_STATUS_TYPES.PROTOTYPE,
        description: config.getTranslationText('1118')
    }
    ];
}

function getSpecifyWheelbaseAsOptions() {
    return [{
        id: config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTER_OF_REAR_DRIVEN_AXLES,
        description: config.getTranslationText('1162')
    },
    {
        id: config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTRE_OF_REAR_AXLES,
        description: config.getTranslationText('1163')
    },
    {
        id: config.SPECIFY_WHEELBASE_AS_OPTIONS.CENTRE_OF_FRONT_AXLES_TO_CENTRE_OF_REAR_AXLES,
        description: config.getTranslationText('1164')
    },
    {
        id: config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_FIRST_REAR_DRIVEN_AXLE,
        description: config.getTranslationText('1165')
    }
    ];
}

function getSpecifyWheelbaseAsTextUsingId(id) {
    switch (id) {
        case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTER_OF_REAR_DRIVEN_AXLES:
            return config.getTranslationText('1162');
        case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTRE_OF_REAR_AXLES:
            return config.getTranslationText('1163');
        case config.SPECIFY_WHEELBASE_AS_OPTIONS.CENTRE_OF_FRONT_AXLES_TO_CENTRE_OF_REAR_AXLES:
            return config.getTranslationText('1164');
        case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_FIRST_REAR_DRIVEN_AXLE:
            return config.getTranslationText('1165');
        default:
            return '';
    }
}

function getSpecifyCabDimensionsAsOptions() {
    return [{
        id: config.SPECIFY_CAB_DIMENSIONS_AS_OPTIONS.BBC,
        description: config.getTranslationText('1468')
    },
    {
        id: config.SPECIFY_CAB_DIMENSIONS_AS_OPTIONS.AC,
        description: config.getTranslationText('1469')
    }
    ];
}

function getSpecifyChassisLengthAsOptions() {
    return [{
        id: config.SPECIFY_CHASSIS_LENGTH_AS_OPTIONS.WHEELBASE,
        description: config.getTranslationText('1466')
    },
    {
        id: config.SPECIFY_CHASSIS_LENGTH_AS_OPTIONS.CA,
        description: config.getTranslationText('1467')
    }
    ];
}

function getSpecifyFuelConsumptionAsOptions(ms) {
    var measurementSystemIdToUse = typeof ms === 'number' ? ms : globals.user.getActiveMeasurementSystem().id;
    if (measurementSystemIdToUse === config.MEASUREMENT_SYSTEM.IMPERIALUK || measurementSystemIdToUse === config.MEASUREMENT_SYSTEM.IMPERIALUS) {
        return [{
            id: config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.VOLUME_PER_DISTANCE,
            description: config.getTranslationText('1481')
        },
        {
            id: config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME,
            description: config.getTranslationText('1480')
        }
        ];
    } else {
        return [{
            id: config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.VOLUME_PER_DISTANCE,
            description: config.getTranslationText('1479')
        },
        {
            id: config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME,
            description: config.getTranslationText('1478')
        }
        ];
    }
}

function getSpecifyAxleRatingAsOptions() {
    return [{
        id: config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED,
        description: config.getTranslationText('2411')
    },
    {
        id: config.SPECIFY_AXLE_RATING_AS_OPTIONS.SIMPLIFIED,
        description: config.getTranslationText('2412')
    }
    ];
}

/**
    * Returns array of objects that can be used in Specify Body Position As select on settings modal
    * @returns {object[]} Returns array of objects containing id and description to be used in select
    */
function getSpecifyBodyPositionAsOptions() {
    return [{
        id: config.SPECIFY_POSITION_AS_OPTIONS.FROM_BACK_OF_CAB,
        description: config.getTranslationText('4416')
    },
    {
        id: config.SPECIFY_POSITION_AS_OPTIONS.FROM_FRONTMOST_AXLE,
        description: config.getTranslationText('4417')
    }
    ];
}

/**
    * Returns array of objects that can be used in Specify Equipment Position As select on settings modal
    * @returns {object[]} Returns array of objects containing id and description to be used in select
    */
function getSpecifyEquipmentPositionAsOptions() {
    return [{
        id: config.SPECIFY_POSITION_AS_OPTIONS.FROM_BACK_OF_CAB,
        description: config.getTranslationText('4418')
    },
    {
        id: config.SPECIFY_POSITION_AS_OPTIONS.FROM_FRONTMOST_AXLE,
        description: config.getTranslationText('4419')
    }
    ];
}

function getDefaultReportPdfPageSizeOptions() {
    return [{
        id: config.PDFMAKE_PAGE_SIZE_OPTIONS.A4,
        description: config.getTranslationText('4222')
    },
    {
        id: config.PDFMAKE_PAGE_SIZE_OPTIONS.LETTER,
        description: config.getTranslationText('4223')
    }
    ];
}

function getReportViewLayoutOptions() {
    return [{
        id: config.REPORT_VIEW_LAYOUT_OPTIONS.SIMPLIFIED,
        description: config.getTranslationText('4749')
    },
    {
        id: config.REPORT_VIEW_LAYOUT_OPTIONS.ADVANCED,
        description: config.getTranslationText('4750')
    }
    ];
}

function getTurningAbilitySpecifiedAsOptions() {
    return [{
        id: config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER,
        description: config.getTranslationText('826')
    },
    {
        id: config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB,
        description: config.getTranslationText('827')
    },
    {
        id: config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL,
        description: config.getTranslationText('828')
    }
    ];
}

function getTurningAbilitySpecifiedAsOption() {
    var idOfOptionToUse, optionToUse = null;


    if (getConfiguration().getTurningAbilitySpecifiedAs() === config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER ||
        getConfiguration().getTurningAbilitySpecifiedAs() === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB ||
        getConfiguration().getTurningAbilitySpecifiedAs() === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL) {

        idOfOptionToUse = getConfiguration().getTurningAbilitySpecifiedAs();

    } else {
        var turningRadiusOuterMax = getLesserOfActiveLegislationVals(legislationVals.variableTypes.TurningRadiusOuterMax);
        var turningRadiusCurbToCurbMax = getLesserOfActiveLegislationVals(legislationVals.variableTypes.TurningRadiusCurbToCurbMax);

        var tempVehicle = getActiveOffer().getRig().getVehicle();
        var tempOriginalVehicle = getOriginalOffer().getRig().getVehicle();

        if (turningRadiusOuterMax > 0) {
            if (tempVehicle.getTurningRadiusWallToWall() > 0) {
                idOfOptionToUse = config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL;
            } else {
                if (tempVehicle.getSteeringAngleInner() > 0) {
                    idOfOptionToUse = config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER;
                } else {
                    if (tempVehicle.getTurningRadiusCurbToCurb() > 0) {
                        idOfOptionToUse = config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB;
                    } else {
                        idOfOptionToUse = config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER;
                    }
                }
            }

        } else {
            if (tempVehicle.getTurningRadiusCurbToCurb() > 0) {
                idOfOptionToUse = config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB;
            } else {
                if (tempVehicle.getSteeringAngleInner() > 0) {
                    idOfOptionToUse = config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER;
                } else {
                    if (tempVehicle.getTurningRadiusWallToWall() > 0) {
                        idOfOptionToUse = config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL;
                    } else {
                        idOfOptionToUse = config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER;
                    }
                }
            }
        }

        //if (getConfiguration().getSteeringAngleInner() > 0) {
        //    idOfOptionToUse = config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER;

        //} else {
        //    if (getConfiguration().getTurningRadiusCurbToCurb() > 0) {
        //        idOfOptionToUse = config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB;
        //    } else if (getConfiguration().getTurningRadiusWallToWall() > 0) {
        //        idOfOptionToUse = config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL;
        //    } else {
        //        idOfOptionToUse = config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER;
        //    }
        //}

        if (idOfOptionToUse === config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER) {
            tempVehicle.setTurningRadiusCurbToCurb(0);
            tempVehicle.setTurningRadiusWallToWall(0);
            tempOriginalVehicle.setTurningRadiusCurbToCurb(0);
            tempOriginalVehicle.setTurningRadiusWallToWall(0);
        } else if (idOfOptionToUse === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB) {
            tempVehicle.setSteeringAngleInner(0);
            tempVehicle.setTurningRadiusWallToWall(0);
            tempOriginalVehicle.setSteeringAngleInner(0);
            tempOriginalVehicle.setTurningRadiusWallToWall(0);
        } else if (idOfOptionToUse === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL) {
            tempVehicle.setTurningRadiusCurbToCurb(0);
            tempVehicle.setSteeringAngleInner(0);
            tempOriginalVehicle.setTurningRadiusCurbToCurb(0);
            tempOriginalVehicle.setSteeringAngleInner(0);
        }

        defaultTurningCircleVals.turningRadiusCurbToCurb = tempOriginalVehicle.getTurningRadiusCurbToCurb();
        defaultTurningCircleVals.turningRadiusWallToWall = tempOriginalVehicle.getTurningRadiusWallToWall();
        defaultTurningCircleVals.steeringAngleInner = tempOriginalVehicle.getSteeringAngleInner();


        getActiveOffer().getRig().getVehicle().setTurningAbilitySpecifiedAs(idOfOptionToUse);
    }




    optionToUse = getTurningAbilitySpecifiedAsOptions()
        .filter(function (option) {
            if (option.id === idOfOptionToUse) {
                return option;
            }
        })[0];

    return optionToUse || getTurningAbilitySpecifiedAsOptions()[0];
}

function getLegislation() {
    return _legislation;
}

function setLegislation(newValue) {
    _legislation = newValue;
}

function getLegislations() {
    return _legislations;
}

function setLegislations(newValue) {
    _legislations = newValue;
}

function getOriginalOffer() {
    return _originalOffer;
}

function setOriginalOffer(newValue) {
    _originalOffer = newValue;
}

function getActiveOffer() {
    return _activeOffer;
}

function setActiveOffer(newValue) {
    _activeOffer = newValue;
}
function getBaseOffer() {
    return _baseOffer;
}

function setBaseOffer(newValue) {
    _baseOffer = newValue;
}

function getActiveOfferPerformance() {
    if (getActiveOffer() !== '') {
        return getActiveOffer().getPerformance();
    }
}

function isActiveOfferAvailable() {
    return getActiveOffer() !== undefined && getActiveOffer() !== '';
}

function isActiveOfferCostingAvailable() {
    if(isActiveOfferAvailable() && globals.user.hasPermission(config.PERMISSIONS.COSTING.Code)) {
        return getActiveOffer().getCosting() !== undefined && getActiveOffer().getCosting() !== '' && getActiveOffer().getCosting() !== null;
    } else {
        return false;
    }
}

function getActiveOfferCosting() {
    return getActiveOffer().getCosting();
}

function isTrailerSourceStandard(path) {
    var trailerToUse = rigOps.getChassisObjectFromPath(path);
    if (trailerToUse !== null) {
        return trailerToUse.getSource() === config.ACCESSORY_SOURCE_TYPES.STANDARD;
    } else {
        return false;
    }
}

function getDecimalsHighPricedItems() {
    if (getActiveOffer() !== undefined && getActiveOffer() !== '' && getActiveOffer().getDataAvailability().ActiveCosting) {
        return isActiveOfferAvailable() && isActiveOfferCostingAvailable() ? getActiveOfferCosting().getDecimalsHighPricedItems() : 0;
    } else {
        return 0;
    }
}

function getDecimalsMediumPricedItems() {
    return isActiveOfferAvailable() && isActiveOfferCostingAvailable() ? getActiveOfferCosting().getDecimalsMediumPricedItems() : 0;
}

function getDecimalsLowPricedItems() {
    return isActiveOfferAvailable() && isActiveOfferCostingAvailable() ? getActiveOfferCosting().getDecimalsLowPricedItems() : 0;
}

function getSpecification() {
    return getActiveOffer().getSpecification();
}

function getConfiguration() {
    return getActiveOffer().getConfiguration();
}

function getPerformance() {
    if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
        return getActiveOffer().getPerformance();
    } else {
        return null;
    }
}
//#endregion Getters and setters
//selectedOffer.VehicleTypeImage
//#region Offer Functions
function openNewOffer(newOfferVehicleId, selectedOffer, menuItemIntercomObject, dataPointOverrides) {
    //make sure the token is still valid and extend if necessary
    // if (!dataManager.securityTokenIsValid()) {
    //     shellLogout();
    // }
    var dfd = $.Deferred();
    cached.value = 0;
    //offerOpeningListenerCallback('turn_cached_animation_on');
    TScMessenger.writeDebugMessage("OfferManager.js, openNewOffer, before recentlyCached subscription setup");
    recentlyCachedSubscriptionRef = watch(dataManager.recentlyCached, function (newVal) {
        
        if (newVal.Type === "NEW" && newVal.Id === newOfferVehicleId && newVal.Value === 2) {
            if (recentlyCachedSubscriptionRef !== null && recentlyCachedSubscriptionRef !== undefined) {
                recentlyCachedSubscriptionRef();
                //utils.clearSubscriptions(recentlyCachedSubscriptionRef._target);
                recentlyCachedSubscriptionRef = null;
            }
            TScMessenger.writeDebugMessage("OfferManager.js, openNewOffer, recentlyCached subscription callback, removing recentlyCached subscription");
            //setTimeout(function () {

            //}, 1500);
            cached.value = 2;
        }
    });

    deleteObservables('SETTINGS');

    var activeLegislationIdsFromUser = globals.user.getDashboardConfiguration().getDashboardLegislationIds(); 

    evaluateLegislationCalled = 0;
    legislationValueFoundInQuickLookup = 0;
    legislationValueInterpreted = 0;

    $.when(dataManager.getNewOfferDetails(newOfferVehicleId, selectedOffer.VehicleDistributionOptionId, selectedOffer.VehicleDistributionGroupId), loadLegislationsDetails(activeLegislationIdsFromUser),
        dataManager.getBodyStubs(), dataManager.getAccessoryStubs(), dataManager.getTrailerStubs(), dataManager.getPayloadStubs(), getNewAndSavedOffersForSelectionList(), dataManager.getLicenceCategories())
        .done(function (success, legislationDetails, bodyStubs, accessoryStubs, trailerStubs, payloadStubs, newAndSavedOffersForVehSelectionWizInMenu, licenceCategories) {
            if (success.offer.Result.ReturnCode > 0) {
                    var results,
                        offerDetails = success.offer.Offer;
                    TScMessenger.writeDebugMessage('OfferManager.js, openNewOffer, getNewOfferDetails, done, Cached()= ' + cached.value + ', success.offer.Complete= ' + success.offer.Complete);
                    if (cached.value === 2 || success.offer.Complete === 2) {
                        success.offer.Complete = 2;
                        cached.value = 2;
                        TScMessenger.writeDebugMessage("OfferManager.js, openNewOffer, getNewOfferDetails, done, Cached()= " + cached.value + ', removing subscription to recentlyCached');
                        
                        //offerOpeningListenerCallback('turn_cached_animation_off');
                        if (recentlyCachedSubscriptionRef !== null && recentlyCachedSubscriptionRef !== undefined) {
                            recentlyCachedSubscriptionRef();
                            recentlyCachedSubscriptionRef = null;
                        }
                    }
                    /*else {
                                            cached(success.offer.Complete || 0);
                                        }*/

                    setLegislationLookupObjectsCleared(false);
                    dynamicValidationGroups = [];
                    refreshData = shallowRef(new Date().getTime());
                    refreshCostingData = shallowRef(new Date().getTime());
                    refreshUILiveLabels = shallowRef(new Date().getTime());
                    setPreventCostingTotalRevenueUpdate(false);
                    globals.watchersArr = [];
                    setupRefreshDataSubscription();
                   
                    newAndSavedOffersForVehicleSelectionInMenu = newAndSavedOffersForVehSelectionWizInMenu;

                    numChangesInCurrentCalculation = 0;

                    setStubValuesOnOffer(selectedOffer, offerDetails);
                    //offerDetails.Id = 0
                    //offerDetails.UpdateCounter = 0
                    //offerDetails.retrievedBodyType = selectedOffer.BodyTypeTranslation;
                    //offerDetails.retrievedBodyManufacturer = selectedOffer.BodyManufacturerDescription;
                    //offerDetails.VehicleMake = selectedOffer.vehicleMake;
                    //offerDetails.VehicleRange = selectedOffer.vehicleRange;
                    //offerDetails.Description = offerDetails.VehicleDescription;
                    //offerDetails.Costing.OnRoadCostsCosts = globals.user.getOnRoadCostsCosts();
                    //offerDetails.Costing.OnRoadCostsDiscounts = globals.user.getOnRoadCostsDiscounts();

                    //populate the calculation and set it to open
                    currencySymbolMajor.value = success.offer.Offer.CurrencySymbolMajor;
                    currencySymbolMinor.value = success.offer.Offer.CurrencySymbolMinor;

                    if(dataPointOverrides) {
                        applyDataPointOverridesToCalculation(dataPointOverrides, offerDetails);
                    }

                    results = success.offer.Offer;
                    performValidation = false;
                    setActiveOffer(new Offer(offerDetails));

                    getActiveOffer().setSpecDate(selectedOffer.SpecDate);

                    

                    if (bodyStubs.bodies.Bodies.length > 0) {
                        availableBodies = [];
                        populateAvailableBodiesArray(bodyStubs);
                    }

                    if (accessoryStubs.accessories.Accessories.length > 0) {
                        availableAccessories = [];
                        populateAvailableAccessoriesArray(accessoryStubs);
                    }

                    if (trailerStubs.trailers.Trailers.length > 0) {
                        availableTrailers = [];
                        populateAvailableTrailersArray(trailerStubs);
                    }

                    if (payloadStubs.payloads.Payloads.length > 0) {
                        availablePayloads = [];
                        populateAvailablePayloadsArray(payloadStubs);
                    }

                    setOriginalOffer(new Offer(offerDetails));
                    setBaseOffer(new Offer(offerDetails));
                    setupOriginalOfferFuelTankInternalIds(getActiveOffer(), getOriginalOffer());
                    setupOriginalAxleInternalIds(getActiveOffer(), getOriginalOffer());

                    getActiveOffer().getRig().getVehicle().getAxlesHolder().calculatePercentagesOfWeightCarried();
                    getOriginalOffer().getRig().getVehicle().getAxlesHolder().calculatePercentagesOfWeightCarried();
                    //var reportOfferOptions = [];
                    //if (getActiveOffer().getDataAvailability().ActiveConfiguration) {
                    //    reportOfferOptions = [{
                    //        Code: config.SUMMARY_REPORT_OPTIONS.CONFIGURATION,
                    //        Options: [{
                    //            Code: config.SUMMARY_REPORT_OFFER_OPTIONS.DRAWING_VIEW,
                    //            IsChecked: true
                    //        }, {
                    //            Code: config.SUMMARY_REPORT_OFFER_OPTIONS.LOAD_PLAN,
                    //            IsChecked: true
                    //        }, {
                    //            Code: config.SUMMARY_REPORT_OFFER_OPTIONS.WEIGHT_AND_COG,
                    //            IsChecked: true
                    //        }]
                    //    }];
                    //}
                    //reportOfferOptions.forEach(function(reportOfferOption) {                            
                    //    var reportOptionToUpdate = getSummaryReportOptionUsingCode(reportOfferOption.Code);
                    //    if (reportOptionToUpdate !== null) {
                    //        reportOptionToUpdate.createSummaryReportOfferOptions(reportOfferOption.Options);
                    //    }
                    //});
                    updateReportOfferOptions();

                    // activate reports
                    activateReports();

                    populateOfferManagerTopLevelObservables(selectedOffer, success.offer.Offer);

                    

                    if (isCostingAvailableForOffer() === true) {
                        if(globals.user.hasPermission(config.PERMISSIONS.COSTING.Code)) {
                            updateCapitalCosts();
                        }
                        

                        var costPerKmValue = getTyreRateValueFromTyreDetail();
                        getActiveOffer().getCosting().setTyreRate(costPerKmValue);
                        getOriginalOffer().getCosting().setTyreRate(costPerKmValue);

                        // Update the costing for finance detail
                        getActiveOffer().getCosting().setVehiclePeriod(getActiveOffer().getCosting().getRepaymentTerm());
                        getActiveOffer().getCosting().setVehicleInterestRate(getActiveOffer().getCosting().getInterestRate());
                        getActiveOffer().getCosting().setVehicleResidualRate(getActiveOffer().getCosting().getResidualRate());

                        getOriginalOffer().getCosting().setVehiclePeriod(getActiveOffer().getCosting().getRepaymentTerm());
                        getOriginalOffer().getCosting().setVehicleInterestRate(getActiveOffer().getCosting().getInterestRate());
                        getOriginalOffer().getCosting().setVehicleResidualRate(getActiveOffer().getCosting().getResidualRate());

                        // Set the R/month value here
                        //updateFinanceCostPerMonthValues();

                        //getActiveOffer().getCosting().setFinanceCostPerMonth(globals.getFinanceCostPerMonth(getActiveOffer().getCosting().getVehiclePrice(), getActiveOffer().getCosting().getResidualRate(), getActiveOffer().getCosting().getInterestRate(), getActiveOffer().getCosting().getRepaymentTerm()));
                        //getOriginalOffer().getCosting().setFinanceCostPerMonth(globals.getFinanceCostPerMonth(getActiveOffer().getCosting().getVehiclePrice(), getActiveOffer().getCosting().getResidualRate(), getActiveOffer().getCosting().getInterestRate(), getActiveOffer().getCosting().getRepaymentTerm()));
                    }
                    customerAttachedToOffer.value = false;
                    //unsavedChangesOnOffer.value = true;
                    validPdfOnLocalStorage.value = false;
                    pdfNoOfPages.value = 4;
                    summaryNote.value = '';
                    setActiveMeasurementSystemUsingId(globals.user.getActiveMeasurementSystemId());
                    handleMeasurementSystemUpdate(activeMeasurementSystem.value);
                    getActiveOffer().setLengthIncrement(globals.user.getLengthIncrement());
                    getActiveOffer().setMassIncrement(globals.user.getMassIncrement());
                    getActiveOffer().setPercentageIncrement(globals.user.getPercentageIncrement());
                    interpreterInstance.setFormatter(getInterpreterFormatter());
                    //additionalNote('');
                    description.value = vehicleName.value;
                    //additionalDescription('');

                    //if (getActiveOffer().getDataAvailability().ActiveCosting && getActiveOffer().getCosting() !== undefined) {
                    //    // Add licence categories
                    //    getActiveOffer().getCosting().setLicenceCategories(licenceCategories.licenceCategories);
                    //    getOriginalOffer().getCosting().setLicenceCategories(licenceCategories.licenceCategories);

                    //    // setup licence fees using default on user
                    //    //var defaultLicenceRegion = getActiveOffer().getCosting().getLicenceCategories().find(function(lc){
                    //    //    return lc.CategoryType === 'VEHICLE' && lc.RegionId === globals.user.getSpecifyLicencingRegionAs() && getActiveOffer().getRig().getVehicle().getUnladenTotal() > lc.MassLow && getActiveOffer().getRig().getVehicle().getUnladenTotal() <= lc.MassHigh;
                    //    //});
                    //    //if (defaultLicenceRegion === undefined) {
                    //    //    defaultLicenceRegion = getActiveOffer().getCosting().getLicenceCategories().find(function (lc) {
                    //    //        return lc.CategoryType === 'VEHICLE' && getActiveOffer().getRig().getVehicle().getUnladenTotal() > lc.MassLow && getActiveOffer().getRig().getVehicle().getUnladenTotal() <= lc.MassHigh;
                    //    //    });
                    //    //}
                    //    var defaultLicenceRegion = globals.getLicenceCategoryObjectToUse(getActiveOffer().getCosting().getLicenceCategories(), 'VEHICLE', getActiveOffer().getRig().getVehicle().getUnladenTotal(), globals.user.getSpecifyLicencingRegionAs());
                    //    //getActiveOffer().getCosting().setVehicleLicenceFees(defaultLicenceRegion.Price);
                    //    getActiveOffer().getCosting().setVehicleLicenceFees(defaultLicenceRegion.TotalPrice);
                    //    getActiveOffer().getCosting().setVehicleLicenceFeesOverride(false);
                    //    getActiveOffer().getCosting().setVehicleLicenceFeesRegionId(defaultLicenceRegion.RegionId);
                    //    updateCostingItems();
                    //    getActiveOffer().getCosting().setTotalRevenue(getActiveOffer().getCosting().getTotalCostsPerKM(globals.user.getActiveMeasurementSystemId()));
                    //    getOriginalOffer().getCosting().setTotalRevenue(getOriginalOffer().getCosting().getTotalCostsPerKM(globals.user.getActiveMeasurementSystemId()));

                    //}

                    isReadOnly.value = false;

                    // if (getActiveOffer().getDataAvailability().ActiveCosting && getActiveOffer().getCosting() !== undefined) {
                    //     onRoadCostsVersion = globals.user.getOnRoadCostsVersion();
                    //     getActiveOffer().getCosting().setOnRoadCostsConstantValueForPercentage();
                    // }

                    rigOps.setup(getActiveOffer(), getOriginalOffer(), getBaseOffer(), getLesserOfActiveLegislationVals, legislationVals);
                    try {
                        globals.debugLegislation = evaluateLegislation('DEBUGACTIVATED');
                        TScMessenger.writeDebugMessage('OfferManager.js, openNewOffer, getNewOfferDetails, done, debugging is activated for interpreter');
                    } catch (evalEx) {
                        TScMessenger.writeDebugMessage('OfferManager.js, openNewOffer, getNewOfferDetails, done, debugging not activated for interpreter');
                    }
                    rigOps.adjustRearMostProtrudingItemForWheelbaseSecifiedAsOptionFromDefaultWheelbase();
                    
                    activeApplication.value = getAvailableVehicleApplicationTypes()[0];
                    getActiveOffer().setApplication(activeApplication.value);
                    clearActiveLegislationQuickLookupObjects();
                    initialiseTurningAbilityVariables();
                    generateDataForActiveLegislations();
                    var mergedPermissibleValues = getMergedLegislationsCombinationPermissibleVals(getDataForActiveLegislations().activeLegislationsCombinationPermissibleValues);
                    rigOps.setCombinationPermissibleVals(mergedPermissibleValues);
                    /*updateBridgeFormulas(config.CHASSIS_OBJECT_TYPES.VEHICLE);*/
                    //rigOps.updateBridgeFormulaPermissiblesOnAxle(config.CHASSIS_OBJECT_TYPES.VEHICLE);

                    if (isCostingAvailableForOffer() === true) {
                        // Add licence categories
                        getActiveOffer().getCosting().setLicenceCategories(licenceCategories.licenceCategories);
                        getOriginalOffer().getCosting().setLicenceCategories(licenceCategories.licenceCategories);

                        // setup licence fees using default on user
                        //var defaultLicenceRegion = getActiveOffer().getCosting().getLicenceCategories().find(function(lc){
                        //    return lc.CategoryType === 'VEHICLE' && lc.RegionId === globals.user.getSpecifyLicencingRegionAs() && getActiveOffer().getRig().getVehicle().getUnladenTotal() > lc.MassLow && getActiveOffer().getRig().getVehicle().getUnladenTotal() <= lc.MassHigh;
                        //});
                        //if (defaultLicenceRegion === undefined) {
                        //    defaultLicenceRegion = getActiveOffer().getCosting().getLicenceCategories().find(function (lc) {
                        //        return lc.CategoryType === 'VEHICLE' && getActiveOffer().getRig().getVehicle().getUnladenTotal() > lc.MassLow && getActiveOffer().getRig().getVehicle().getUnladenTotal() <= lc.MassHigh;
                        //    });
                        //}
                        var defaultLicenceRegion = globals.getLicenceCategoryObjectToUse(getActiveOffer().getCosting().getLicenceCategories(), 'VEHICLE', getActiveOffer().getRig().getVehicle().getUnladenTotal(), globals.user.getSpecifyLicencingRegionAs());
                        //getActiveOffer().getCosting().setVehicleLicenceFees(defaultLicenceRegion.Price);
                        getActiveOffer().getCosting().setVehicleLicenceFees(defaultLicenceRegion.TotalPrice);
                        getActiveOffer().getCosting().setVehicleLicenceFeesOverride(false);
                        getActiveOffer().getCosting().setVehicleLicenceFeesRegionId(defaultLicenceRegion.RegionId);
                        updateCostingItems();
                        
                        getActiveOffer().getCosting().setTotalRevenue(getActiveOffer().getCosting().getTotalCostsPerKM(globals.user.getActiveMeasurementSystemId()));
                        getActiveOffer().getCosting().setTotalRevenueSetUsingMeasurementSystemId(globals.user.getActiveMeasurementSystemId());
                        getActiveOffer().getCosting().setTotalRevenueAsCurrencyPerKm(getActiveOffer().getCosting().getTotalCostsPerKM());

                        getOriginalOffer().getCosting().setTotalRevenue(getOriginalOffer().getCosting().getTotalCostsPerKM(globals.user.getActiveMeasurementSystemId()));
                        getOriginalOffer().getCosting().setTotalRevenueSetUsingMeasurementSystemId(globals.user.getActiveMeasurementSystemId());
                        getOriginalOffer().getCosting().setTotalRevenueAsCurrencyPerKm(getOriginalOffer().getCosting().getTotalCostsPerKM());
                        // setTotalRevenueUsingMeasurementSystem(getActiveOffer().getCosting().getTotalCostsPerKM(globals.user.getActiveMeasurementSystemId()));
                        // setOriginalOfferTotalRevenueUsingMeasurementSystem(getOriginalOffer().getCosting().getTotalCostsPerKM(globals.user.getActiveMeasurementSystemId()));
                    }

                    if (getConfiguration().getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                        var tempPayloadObject = setupDefaultPayloadForCompleteVehicle(getConfiguration());
                        var tempOriginalPayloadObject = setupDefaultPayloadForCompleteVehicle(getOriginalOffer().getConfiguration());
                        //tempPayloadObject.setMass(rigOps.getMaximisedLoad());
                        clearActiveLegislationQuickLookupObjects();
                        if (needToMaximise(rigOps)) {
                            rigOps.maximisePayloads();
                        }
                        tempOriginalPayloadObject.setMass(tempPayloadObject.getMass());
                        tempOriginalPayloadObject.setId(tempPayloadObject.getId());
                        addPayloadObservables([tempPayloadObject], config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.PAYLOAD);
                        //createPayloadObservables(tempPayloadObject, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.PAYLOAD);
                    }

                    //getActiveOffer().getRig().getVehicle().setPermissibleTotal(rigOps.getCombinationTotalPermissible());

                    setupValidationConfigs();
                    if (isCostingAvailableForOffer() === true) {
                        setupCostingObservables();
                        setupCostingFormattedComputeds();
                    }
                    //setupSettingsObservables();
                    setupOfferSettingsObservables();
                    setupReportOptionObservables();
                    if (getActiveOffer().getDataAvailability().ActivePerformance && getActiveOffer().getPerformance() !== undefined && globals.user.hasPermission(config.PERMISSIONS.PERFORMANCE.Code)) {
                        setupPerformanceObservables();
                        setupPerformanceFormattedComputeds();
                    }

                    if (getActiveOffer().getConfiguration().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
                        getActiveOffer().getRig().getVehicle().setFifthWheelAdded(true);
                    }
                    setupVehicleObservables();
                    updateObservables();
                    offerIsOpen.value = true;
                    performValidation = true;

                    addAxlesObservables(getConfiguration().getAxles(), config.CHASSIS_OBJECT_TYPES.VEHICLE);
                    addAxleGroupsObservables(getConfiguration().getAxlesHolder().getAxleGroups(), config.CHASSIS_OBJECT_TYPES.VEHICLE);
                    addAxleRearTotalObjectObservables(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_REAR_TOTAL);
                    //addAxlesTotalsObservables(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_TOTAL);
                    addFuelTankObservables(getConfiguration().getFuelTanks());

                    clearLocalStorageItems();

                    numLinkableMissingValues = 0;
                    numLinkableTurningCircleMissingValues = 0;
                    let intercomMetaDataObj = getActiveOffer().getIntercomMetadata('NEW', menuItemIntercomObject);
                    if(dataPointOverrides) {
                        intercomMetaDataObj[config.INTERCOM_METADATA_ITEM.SPEC_DATA_POINTS] = JSON.stringify(dataPointOverrides);
                    }
                    dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.OPENED_OFFER, intercomMetaDataObj));

                    offerOpen = true;
                    setupAllowedAccessoryTypes();
                    setupAllowedTrailerTypesTrailer1();
                    setupAllowedTrailerTypesTrailer2();

                    undoHandler = new UndoHandler(offerManager, numUndoOps, numRedoOps);
                    undoHandler.disableUndoOpCreation();
                }
                
            updateRoutesWhenOpeningOffer();
                
            
            // NotAllowedCombinationVehicle
            // let startMillis = new Date().getTime();
            // let notAllowedCombinationVehicle = evaluateLegislation('NOTALLOWEDCOMBINATIONVEHICLE');
            // let endMillis = new Date().getTime();
            // console.log("notAllowedCombinationVehicle duration: " + (endMillis - startMillis));
            
            // let startMillis = new Date().getTime();
            // let customComVehGross = evaluateLegislation('CUSTOMCOMVEHGROSS');
            // let endMillis = new Date().getTime();
            // console.log("customComVehGross duration: " + (endMillis - startMillis));
            
            // startMillis = new Date().getTime();
            // let notAllowedCombination = evaluateLegislation('NOTALLOWEDCOMBINATION');
            // endMillis = new Date().getTime();
            // console.log("notAllowedCombination duration: " + (endMillis - startMillis));

            // startMillis = new Date().getTime();
            // let trailer1PermissibleFrontMax = evaluateLegislation('TRAILER1PERMISSIBLEFRONTMAX');
            // endMillis = new Date().getTime();
            // console.log("Trailer1PermissibleFrontMax duration: " + (endMillis - startMillis));

            // startMillis = new Date().getTime();
            // let trailer2PermissibleFrontMax = evaluateLegislation('TRAILER2PERMISSIBLEFRONTMAX');
            // endMillis = new Date().getTime();
            // console.log("Trailer2PermissibleFrontMax duration: " + (endMillis - startMillis));

            // startMillis = new Date().getTime();
            // let trailer1PermissibleRearMax = evaluateLegislation('TRAILER1PERMISSIBLEREARMAX');
            // endMillis = new Date().getTime();
            // console.log("Trailer1PermissibleRearMax duration: " + (endMillis - startMillis));

            // startMillis = new Date().getTime();
            // let trailer2PermissibleRearMax = evaluateLegislation('TRAILER2PERMISSIBLEREARMAX');
            // endMillis = new Date().getTime();
            // console.log("Trailer2PermissibleRearMax duration: " + (endMillis - startMillis));


            // startMillis = new Date().getTime();
            // let helperCheckWeights = evaluateLegislation('HELPERCHECKWEIGHTS');
            // endMillis = new Date().getTime();
            // console.log("helperCheckWeights duration: " + (endMillis - startMillis));

            // startMillis = new Date().getTime();
            // let helperBridgeFormulaUsed = evaluateLegislation('HELPERBRIDGEFORMULAUSED');
            // endMillis = new Date().getTime();
            // console.log("helperBridgeFormulaUsed duration: " + (endMillis - startMillis));

            // startMillis = new Date().getTime();
            // let ManufNotProvidedCombined = evaluateLegislation('MANUFNOTPROVIDEDCOMBINED');
            // endMillis = new Date().getTime();
            // console.log("ManufNotProvidedCombined duration: " + (endMillis - startMillis));

            // startMillis = new Date().getTime();
            // let helperCombinationGrossPermissible = evaluateLegislation('HELPERCOMBINATIONGROSSPERMISSIBLE');
            // endMillis = new Date().getTime();
            // console.log("helperCombinationGrossPermissible duration: " + (endMillis - startMillis));


            // startMillis = new Date().getTime();
            // let vehicleFrontSingleOrDual = evaluateLegislation('VEHICLEFRONTSINGLEORDUAL');
            // endMillis = new Date().getTime();
            // console.log("vehicleFrontSingleOrDual duration: " + (endMillis - startMillis));

            // startMillis = new Date().getTime();
            // let vehicleRearSingleDualTridem = evaluateLegislation('VEHICLEREARSINGLEDUALTRIDEM');
            // endMillis = new Date().getTime();
            // console.log("vehicleRearSingleDualTridem duration: " + (endMillis - startMillis));

            // startMillis = new Date().getTime();
            // let vehicleMinus1NoOfAxles = getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.vehicleMinus1NoOfAxles, true);
            // endMillis = new Date().getTime();
            // console.log("vehicleMinus1NoOfAxles duration: " + (endMillis - startMillis));

            // startMillis = new Date().getTime();
            // let vehicleMinus2NoOfAxles = getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.vehicleMinus2NoOfAxles, true);
            // endMillis = new Date().getTime();
            // console.log("vehicleMinus2NoOfAxles duration: " + (endMillis - startMillis));

            // startMillis = new Date().getTime();
            // let vehicleMinus3NoOfAxles = getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.vehicleMinus3NoOfAxles, true);
            // endMillis = new Date().getTime();
            // console.log("vehicleMinus3NoOfAxles duration: " + (endMillis - startMillis));

            // startMillis = new Date().getTime();
            // let vehicleMinus1Raised = getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.vehicleMinus1Raised, true);
            // endMillis = new Date().getTime();
            // console.log("vehicleMinus1Raised duration: " + (endMillis - startMillis));

            // startMillis = new Date().getTime();
            // let vehicleMinus2Raised = getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.vehicleMinus2Raised, true);
            // endMillis = new Date().getTime();
            // console.log("vehicleMinus2Raised duration: " + (endMillis - startMillis));

            // startMillis = new Date().getTime();
            // let vehicleMinus3Raised = getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.vehicleMinus3Raised, true);
            // endMillis = new Date().getTime();
            // console.log("vehicleMinus3Raised duration: " + (endMillis - startMillis));
             
            // startMillis = new Date().getTime();
            // let vehiclePermissibleRearPusher1 = getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.vehiclePermissibleRearPusher1, true);
            // endMillis = new Date().getTime();
            // console.log("vehiclePermissibleRearPusher1 duration: " + (endMillis - startMillis));

            // startMillis = new Date().getTime();
            // let vehiclePermissibleRearPusher2 = getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.vehiclePermissibleRearPusher2, true);
            // endMillis = new Date().getTime();
            // console.log("vehiclePermissibleRearPusher2 duration: " + (endMillis - startMillis));
            
            // startMillis = new Date().getTime();
            // let vehiclePermissibleRearPusher3 = getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.vehiclePermissibleRearPusher3, true);
            // endMillis = new Date().getTime();
            // console.log("vehiclePermissibleRearPusher3 duration: " + (endMillis - startMillis));

            dfd.resolve(success);

        }).fail(function (error) {
            dfd.reject({
                errorMessage: error.errorMessage
            });
        });

    return dfd.promise();
}

function mapSpecDataPointCalculationDataPoint(dataPointName) {
    // let mappedResult = undefined;
    // switch(dataPointName.toUpperCase()) {
    //     case config.SPEC_DATA_POINT.AxleRatio:
    //         mappedResult = config.CALCULATION_DATA_POINT.StandardRatio;
    //         break;
    // }
    return config.SPEC_DATA_POINT_TO_CALCULATION_DATA_POINT_MAPPING[dataPointName] || dataPointName;
}

function returnValueOrMappedValue(dataPointOverrides, interfaceAttribute) {
    if(typeof interfaceAttribute === 'string') {
        return dataPointOverrides[interfaceAttribute];
    }
    let valToReturn;
    if(interfaceAttribute.Mappings && interfaceAttribute.Mappings.length > 0) {
        let mappedValue = '';
        interfaceAttribute.Mappings.every(function (attributeMapping) {
            if(attributeMapping.Code === dataPointOverrides[interfaceAttribute.Name]) {
                mappedValue = attributeMapping.Value;
                return false;
            }
            return true;
        });
        valToReturn = mappedValue;
    } else {
        valToReturn = dataPointOverrides[interfaceAttribute.Name];
    }
    if(interfaceAttribute.Unit && interfaceAttribute.Unit !== undefined && interfaceAttribute.Unit !== '' && interfaceAttribute.Unit !== null && interfaceAttribute.Unit !== "n/a") {
        return convertValToMetricIfNecessary(interfaceAttribute.Unit, valToReturn);
    }else {
        return valToReturn;
    }
}

function convertValToMetricIfNecessary(unit, val) {
    let imperialUSMeasurementSystem = globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUS);
    val = Number(val);
    if(unit === 'lb') {
        val = val / imperialUSMeasurementSystem.massConversion;
    }
    return val;
}

function applyDataPointOverridesToCalculation(dataPointOverrides, offerDetails) {
    let companyInterface = globals.user.getCompanyInterface();
    if(companyInterface.CompanyInterfaceAttributesAndMappings) {
        companyInterface.CompanyInterfaceAttributesAndMappings.forEach(function (interfaceAttribute) {
            if(dataPointOverrides[interfaceAttribute.Name]) {
                let valToApply = returnValueOrMappedValue(dataPointOverrides, interfaceAttribute);
                if(valToApply && valToApply !== '') {
                    if(interfaceAttribute.Name === 'axleRatioCode') {
                        offerDetails.Configuration.Axles.forEach((axleStub) => {
                            if(axleStub.DrivenAxle) {
                                if(interfaceAttribute.TruckScienceName !== '' && axleStub[interfaceAttribute.TruckScienceName]) {
                                    axleStub[interfaceAttribute.TruckScienceName] = valToApply;
                                } else if(axleStub[interfaceAttribute.Name]){
                                    axleStub[interfaceAttribute.Name] = valToApply;
                                }
                            }
                        });
                    } else if(interfaceAttribute.Name === 'transmissionCode'){
                        let filteredTransmissionArr = companyInterface.Transmissions.filter(function (transmission) {
                                return transmission.Code === valToApply;
                        });
                        if(filteredTransmissionArr && filteredTransmissionArr.length > 0) {
                            let mappedTransmission = filteredTransmissionArr[0];
                            offerDetails.Configuration.TopGearRatio = mappedTransmission.TopGearRatio;
                            offerDetails.Configuration.TransmissionTorqueConverterMultiplication = mappedTransmission.TransmissionTorqueConverterMultiplication;
                            offerDetails.Configuration.TransmissionFirstGearRatio = mappedTransmission.TransmissionFirstGearRatio;
                            offerDetails.Configuration.TransmissionReverseGearRatio = mappedTransmission.TransmissionReverseGearRatio;
                        }
                    } else if(interfaceAttribute.Name === 'engineCode'){
                        let filteredEnginesArr = companyInterface.Engines.filter(function (engine) {
                                return engine.Code === valToApply;
                        });
                        if(filteredEnginesArr && filteredEnginesArr.length > 0) {
                            let mappedEngine = filteredEnginesArr[0];
                            offerDetails.Configuration.EngineMaximumTorqueNm = mappedEngine.EngineMaximumTorqueNm;
                            offerDetails.Configuration.EngineMaximumTorqueLbFt = mappedEngine.EngineMaximumTorqueLbFt;
                            offerDetails.Configuration.EmissionsStandard = mappedEngine.EmissionsStandard;
                            offerDetails.Configuration.EmissionsStandardCode = mappedEngine.EmissionsStandardCode;
                        }
                    } else if(interfaceAttribute.Name === 'frontTireCode' || interfaceAttribute.Name === 'rearTireCode'){
                        let filteredTyreArr = companyInterface.Tyres.filter(function (tyre) {
                                return tyre.Code === valToApply;
                        });
                        if(filteredTyreArr && filteredTyreArr.length > 0) {
                            let mappedTyre = filteredTyreArr[0];
                            if(interfaceAttribute.Name === 'frontTireCode') {
                                offerDetails.Configuration.Axles.forEach((axleStub) => {
                                    if(axleStub.Type === config.VEHICLE_AXLE_POSITION_TYPES.FRONT) {
                                        axleStub.TyreRadius = mappedTyre.TyreRadius;
                                        axleStub.TyreWidth = mappedTyre.TyreWidth;
                                        axleStub.RevsPerKM = mappedTyre.RevsPerKM;
                                        axleStub.TyreMassRatingSingle = mappedTyre.TyreMassRatingSingle;
                                        axleStub.TyreMassRatingDual = mappedTyre.TyreMassRatingDual;
                                        axleStub.TyreStaticLoadedRadius = mappedTyre.TyreStaticLoadedRadius;
                                    }
                                });
                            } else {
                                offerDetails.Configuration.Axles.forEach((axleStub) => {
                                    if(axleStub.Type === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
                                        axleStub.TyreRadius = mappedTyre.TyreRadius;
                                        axleStub.TyreWidth = mappedTyre.TyreWidth;
                                        axleStub.RevsPerKM = mappedTyre.RevsPerKM;
                                        axleStub.TyreMassRatingSingle = mappedTyre.TyreMassRatingSingle;
                                        axleStub.TyreMassRatingDual = mappedTyre.TyreMassRatingDual;
                                        axleStub.TyreStaticLoadedRadius = mappedTyre.TyreStaticLoadedRadius;
                                    }
                                });
                            }
                        }
                    } else if(interfaceAttribute.Name === 'frontAxleCapCode' || interfaceAttribute.Name === 'frontSpringCapCode'){
                        offerDetails.Configuration.Axles.forEach((axleStub) => {
                            if(axleStub.Type === config.VEHICLE_AXLE_POSITION_TYPES.FRONT) {
                                if(interfaceAttribute.TruckScienceName !== '' && axleStub[interfaceAttribute.TruckScienceName]) {
                                    axleStub[interfaceAttribute.TruckScienceName] = valToApply;
                                } else if(axleStub[interfaceAttribute.Name]){
                                    axleStub[interfaceAttribute.Name] = valToApply;
                                }
                            }
                        });
                    } else if(interfaceAttribute.Name === 'rearAxleCapCode' || interfaceAttribute.Name === 'rearSpringCapCode'){
                        offerDetails.Configuration.Axles.forEach((axleStub) => {
                            if(axleStub.Type === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
                                if(interfaceAttribute.TruckScienceName !== '' && axleStub[interfaceAttribute.TruckScienceName]) {
                                    axleStub[interfaceAttribute.TruckScienceName] = valToApply;
                                } else if(axleStub[interfaceAttribute.Name]){
                                    axleStub[interfaceAttribute.Name] = valToApply;
                                }
                            }
                        });
                    }else {
                        if(interfaceAttribute.TruckScienceName !== '' && offerDetails.Configuration[interfaceAttribute.TruckScienceName]) {
                            offerDetails.Configuration[interfaceAttribute.TruckScienceName] = valToApply;
                        } else if(offerDetails.Configuration[interfaceAttribute.Name]){
                            offerDetails.Configuration[interfaceAttribute.Name] = valToApply;
                        } else {
                            let breakHere = 0;
                        }
                    }
                    
                }
                
            }else {
                let breakHere = 0;
            }
        });
    }
    
    // if(dataPointOverrides[config.SPEC_DATA_POINT.AxleRatio]) {
    //     offerDetails.Configuration.Axles.forEach((axleStub) => {
    //         if(axleStub.DrivenAxle) {
    //             axleStub[mapSpecDataPointCalculationDataPoint(config.SPEC_DATA_POINT.AxleRatio)] = Number(dataPointOverrides[config.SPEC_DATA_POINT.AxleRatio]); 
    //         }
    //     });
    // }
}

function checkSpecDataPoints(originalSpecDataPoints) {
    let mappedSpecDataPoints = {};
    let missingSpecDataPoints = [];
    let unmappedSpecDataPoints = [];
    let companyInterface = globals.user.getCompanyInterface();
    if(companyInterface.CompanyInterfaceAttributesAndMappings) {
        companyInterface.CompanyInterfaceAttributesAndMappings.forEach(function (interfaceAttribute) {
            if(originalSpecDataPoints[interfaceAttribute.Name]) {
                let valToApply = returnValueOrMappedValue(originalSpecDataPoints, interfaceAttribute);
                if(valToApply && valToApply !== '') {
                    mappedSpecDataPoints[interfaceAttribute.Name] = valToApply;
                } else if(!valToApply) {
                    unmappedSpecDataPoints.push(interfaceAttribute.Name);
                }
                
            }else {
                missingSpecDataPoints.push(interfaceAttribute.Name);
            }
        });
    }
    
    return {
        mappedSpecDataPoints: mappedSpecDataPoints,
        unmappedSpecDataPoints: unmappedSpecDataPoints,
        missingSpecDataPoints: missingSpecDataPoints
    }
}

function updateReportOfferOptions() {
    var reportOfferOptions = [];

    if (getActiveOffer().getDataAvailability().ActiveConfiguration) {
        reportOfferOptions = [{
            Code: config.SUMMARY_REPORT_OPTIONS.CONFIGURATION,
            Options: [{
                Code: config.SUMMARY_REPORT_OFFER_OPTIONS.DRAWING_VIEW,
                IsChecked: true
            }, {
                Code: config.SUMMARY_REPORT_OFFER_OPTIONS.LOAD_PLAN,
                IsChecked: true
            }, {
                Code: config.SUMMARY_REPORT_OFFER_OPTIONS.WEIGHT_AND_COG,
                IsChecked: true
            }]
        }];
    }

    reportOfferOptions.forEach(function (reportOfferOption) {
        var reportOptionToUpdate = getSummaryReportOptionUsingCode(reportOfferOption.Code);
        if (reportOptionToUpdate !== null) {
            reportOptionToUpdate.createSummaryReportOfferOptions(reportOfferOption.Options);
        }
    });
}

function activateReports(isCheckedValues) {
    var configurationReportValue = false,
        specificationReportValue = false,
        costingReportValue = false,
        performanceReportValue = false;
    if(isCheckedValues !== undefined) {
        configurationReportValue = typeof isCheckedValues[config.SUMMARY_REPORT_OPTIONS.CONFIGURATION] === 'boolean' ? isCheckedValues[config.SUMMARY_REPORT_OPTIONS.CONFIGURATION] : false;
        specificationReportValue = typeof isCheckedValues[config.SUMMARY_REPORT_OPTIONS.SPECIFICATION] === 'boolean' ? isCheckedValues[config.SUMMARY_REPORT_OPTIONS.SPECIFICATION] : false;
        costingReportValue = typeof isCheckedValues[config.SUMMARY_REPORT_OPTIONS.COSTING] === 'boolean' ? isCheckedValues[config.SUMMARY_REPORT_OPTIONS.COSTING] : false;
        performanceReportValue = typeof isCheckedValues[config.SUMMARY_REPORT_OPTIONS.PERFORMANCE] === 'boolean' ? isCheckedValues[config.SUMMARY_REPORT_OPTIONS.PERFORMANCE] : false;
    }
    if(isCheckedValues === undefined) {
        if (isConfigurationReportOptionAvailable()) {
            // activate configuration report only if available
            activateSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.CONFIGURATION);
        } else {
            if (isPerformanceReportOptionAvailable()) {
                // activate costing report if available
                activateSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.PERFORMANCE);
            }
            if (isCostingReportOptionAvailable()) {
                // activate costing report if available
                activateSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.COSTING);
            }
            if (isSpecificationReportOptionAvailable()) {
                // activate specification report if available
                activateSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.SPECIFICATION);
            }
        }
    } else {
        if (isConfigurationReportOptionAvailable() && configurationReportValue === true) {
            // activate configuration report only if available
            activateSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.CONFIGURATION);
        }
        if (isPerformanceReportOptionAvailable() && performanceReportValue === true) {
            // activate costing report if available
            activateSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.PERFORMANCE);
        }
        if (isCostingReportOptionAvailable() && costingReportValue === true) {
            // activate costing report if available
            activateSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.COSTING);
        }
        if (isSpecificationReportOptionAvailable() && specificationReportValue === true) {
            // activate specification report if available
            activateSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.SPECIFICATION);
        }
    }
}

function openExistingOfferWrapper(offerId, userIdToUse, selectedOffer, isSharedOffer, intercomMetaDataObject, sourceOfCall) {
    var dfd = $.Deferred();

    var shareeUserId;
    if (isSharedOffer === true) {
        if (selectedOffer.IsSupportOffer && selectedOffer.IsSupportOffer === true) {
            shareeUserId = selectedOffer.SharedWithUserId;
        } else {
            shareeUserId = selectedOffer.SalespersonId;
        }
        sharedOfferCustomerId = selectedOffer.CustomerId;
    }

    
    offerManager.openExistingOffer(offerId, selectedOffer.BodyType, selectedOffer.BodyManufacturer, selectedOffer.CustomerId, userIdToUse, false, selectedOffer.LegislationId, selectedOffer.VehicleMake, selectedOffer.VehicleRange, selectedOffer.Description, selectedOffer.SpecDate, selectedOffer.VehicleTypeTranslation, selectedOffer.Status, selectedOffer.VehicleTypeImage, isSharedOffer, intercomMetaDataObject)
        .then(function (success) {

            if (success.offer.Result.ReturnCode > 0) {

                //shell.manageHiddenButtons(success.offer.Offer.DataAvailability);

                globals.user.updateUser({
                    numberOfUsesSinceLastPrompt: globals.user.getNumberOfUsesSinceLastPrompt() + 1
                });
                //offerSelected(false);
                //shell.hideSpinner();
                //globals.navigatedInFromSelectionOrOffers(true);

                offerManager.setDisplayCostingTab(success.offer.Offer.DataAvailability.ActiveCosting);
                offerManager.setDisplaySpecificationTab(success.offer.Offer.DataAvailability.ActiveSpecification);

                if (success.offer.Offer.DataAvailability.ActiveSpecification === true && (globals.user.hasPermission(config.PERMISSIONS.SPECIFICATION.Code) || globals.user.allowSpecificationModule())) {
                    //now get the specification details in the background
                    offerManager.openNewOfferSpecification(success.offer.Offer.Id);
                }
                if (sourceOfCall === 'selection' && success.offer.Result.ReturnCode === 2) {
                    //send shared_Offer_accepted intercom event
                    var metaDataObj = {};
                    metaDataObj[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = getActiveOffer().getDescription();
                    dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.SHARED_OFFER_ACCEPTED, metaDataObj));
                } else if (success.offer.Result.ReturnCode === 2) {
                    sendIntercomSharedOfferAcceptedEvent = true;

                }
            }
            dfd.resolve(success);
        })
        .fail(function (error) {
            dfd.reject(error);
        });


    return dfd.promise();
}

//function handleExistingOfferSuccess(success, dfd, sourceOfCall) {
//    var successToUse = success.offer !== undefined ? success.offer : success;
//    if (successToUse.Result.ReturnCode > 0) {

//        //shell.manageHiddenButtons(success.offer.Offer.DataAvailability);

//        globals.user.updateUser({
//            numberOfUsesSinceLastPrompt: globals.user.getNumberOfUsesSinceLastPrompt() + 1
//        });
//        //offerSelected(false);
//        //shell.hideSpinner();
//        //globals.navigatedInFromSelectionOrOffers(true);

//        offerManager.setDisplayCostingTab(successToUse.Offer.DataAvailability.ActiveCosting);
//        offerManager.setDisplaySpecificationTab(successToUse.Offer.DataAvailability.ActiveSpecification);

//        if (successToUse.Offer.DataAvailability.ActiveSpecification === true && globals.user.hasPermission(config.PERMISSIONS.SPECIFICATION.Code)) {
//            //now get the specification details in the background
//            offerManager.openNewOfferSpecification(successToUse.Offer.Id);
//        }
//        if (sourceOfCall === 'selection' && successToUse.Result.ReturnCode === 2) {
//            //send shared_Offer_accepted intercom event
//            var metaDataObj = {};
//            metaDataObj[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = getActiveOffer().getDescription();
//            dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.SHARED_OFFER_ACCEPTED, metaDataObj));
//        } else if (successToUse.Result.ReturnCode === 2) {
//            sendIntercomSharedOfferAcceptedEvent = true;

//        }
//    }
//    if (dfd !== undefined) {
//        dfd.resolve(success);
//    }
    
//}

function openExistingOffer(offerId, retrievedBodyType, retrievedBodyManufacturer, customerId, activeSalesPersonId, disableInteractions, compoundLegislationId, vehMake, vehRange, vehModel, specDate, vehTypeTranslation, vehStatus, vehTypeImage, isSharedOffer, menuItemIntercomObject) {
    //make sure the token is still valid and extend if necessary
    if (!dataManager.securityTokenIsValid()) {
        shellLogout();
    }
    var dfd = $.Deferred();
    deleteObservables('SETTINGS');
    
    openOfferIsSharedOffer = isSharedOffer !== undefined ? isSharedOffer : false;
    //need to wait for setActiveLegislation to resolve before continuing with opening offer as legislation retrieval may fail.
    
    loadLegislationsDetails(globals.user.getDashboardConfiguration().getDashboardLegislationIds())
        .then(function () {
            recentlyCachedSubscriptionRef = watch(dataManager.recentlyCached, function (newVal) {
                if (newVal.Type === "SAVED" && newVal.Id === offerId && newVal.Value === 2) { // User Story 17565
                    if (recentlyCachedSubscriptionRef !== null && recentlyCachedSubscriptionRef !== undefined) {
                        recentlyCachedSubscriptionRef();
                        recentlyCachedSubscriptionRef = null;
                    }
                    cached.value = 2;
                }
            });

            $.when(dataManager.getExistingOfferDetails(offerId, customerId, activeSalesPersonId, isSharedOffer), dataManager.getBodyStubs(), dataManager.getAccessoryStubs(), dataManager.getTrailerStubs(), dataManager.getPayloadStubs(), getNewAndSavedOffersForSelectionList(), dataManager.getBodyMasses(), dataManager.getLicenceCategories())                        
                .done(function (offerDetails, bodyStubs, accessoryStubs, trailerStubs, payloadStubs, newAndSavedOffersForVehSelectionWizInMenu, bodyMasses, licenceCategories){
                    var otherOfferDetails = {
                        offerId: offerId,
                        retrievedBodyType: retrievedBodyType,
                        retrievedBodyManufacturer: retrievedBodyManufacturer,
                        customerId: customerId,
                        activeSalesPersonId: activeSalesPersonId,
                        disableInteractions: disableInteractions,
                        compoundLegislationId: compoundLegislationId,
                        vehMake: vehMake,
                        vehRange: vehRange,
                        specDate: specDate,
                        vehTypeTranslation: vehTypeTranslation,
                        vehStatus: vehStatus,
                        vehTypeImage: vehTypeImage,
                        isSharedOffer: isSharedOffer,
                        menuItemIntercomObject: menuItemIntercomObject
                    };

                    getExistingOfferDetailsSuccess(offerDetails, bodyStubs, accessoryStubs, trailerStubs, payloadStubs, newAndSavedOffersForVehSelectionWizInMenu, bodyMasses, licenceCategories, otherOfferDetails, dfd)
                })
                .fail(function (error) {
                    globals.user.updateUser({
                        accessCode: ''
                    });
                    
                    dfd.reject({
                        errorMessage: error.errorMessage
                    });
                });
        }).fail(function (error) {
            globals.user.updateUser({
                accessCode: ''
            });
            
            dfd.reject({
                errorMessage: error.errorMessage
            });
        });

    return dfd.promise();
}

/**
    * Function that creates the active/original offer for an existing offer
    * @param {object} success
    * @param {object} bodyStubs - Object that contains an array of body stubs
    * @param {object} accessoryStubs - Object that contains an array of accessory stubs
    * @param {object} trailerStubs - Object that contains an array of trailer stubs
    * @param {object} payloadStubs - Object that contains an array of payload stubs
    * @param {object} newAndSavedOffersForVehSelectionWizInMenu - Object that contains an array of vehicle stubs
    * @param {object} bodyMasses - Object that contains an array of body mass stubs
    * @param {object} licenceCategories - Object that contains an array of licence categories stubs
    * @param {object} otherOfferDetails - Object that contains items that are used to set values in offer
    * @param {Promise} dfd - Promise to be resolved when function completes successfully
    */
function getExistingOfferDetailsSuccess(success, bodyStubs, accessoryStubs, trailerStubs, payloadStubs, newAndSavedOffersForVehSelectionWizInMenu, bodyMasses, licenceCategories, otherOfferDetails, dfd) {
    //var offerId = 25061, retrievedBodyType = '', retrievedBodyManufacturer = '', customerId = 623, activeSalesPersonId = 20406, disableInteractions = false, compoundLegislationId = 1790002, 
    //    vehMake = null, vehRange = null, vehModel = "Astra HD9 4x4 44.42", specDate = null, vehTypeTranslation = null, vehStatus, vehTypeImage, isSharedOffer, menuItemIntercomObject;

    if (success.offer && success.offer.Result.ReturnCode > 0) {
        cached.value = success.offer.Complete || 0;
        if (cached.value === 2) {
            //offerOpeningListenerCallback('turn_cached_animation_off');
            if (recentlyCachedSubscriptionRef !== null && recentlyCachedSubscriptionRef !== undefined) {
                recentlyCachedSubscriptionRef();
                recentlyCachedSubscriptionRef = null;
            }
        }

        var originalAccessories = null;
        var originalTrailers = null;
        var originalPayloads = null;

        dataManager.getOriginalsForItemsAddedToRig(success.offer.Changes.Accessories, success.offer.Changes.Trailers, success.offer.Changes.Payloads, success.offer.Changes.Axles)
            .then(function (retrievedOriginals) {
                originalAccessories = retrievedOriginals !== null ? retrievedOriginals.accessories : null;
                originalTrailers = retrievedOriginals !== null ? retrievedOriginals.trailers : null;
                originalPayloads = retrievedOriginals !== null ? retrievedOriginals.payloads : null;
                doOpenExistingOffer();
            })
            .fail(function (error) {
                dfd.reject(error);
            });



        
    } else {
        dfd.resolve(success);
    }

    function doOpenExistingOffer() {
        var results, changes, customer, onRoadCostsCosts, onRoadCostsDiscounts, offerDetails;


        newAndSavedOffersForVehicleSelectionInMenu = newAndSavedOffersForVehSelectionWizInMenu;

        numChangesInCurrentCalculation = 0;
        
        vehicleDistributionGroupId.value = success.offer.Offer.VehicleDistributionGroupId;
        vehicleDistributionOptionId.value = success.offer.Offer.VehicleDistributionOptionId;

        dynamicValidationGroups = [];
        refreshData = shallowRef(new Date().getTime());
        refreshCostingData = shallowRef(new Date().getTime());
        refreshUILiveLabels = shallowRef(new Date().getTime());
        setPreventCostingTotalRevenueUpdate(false);
        globals.watchersArr = [];
        setupRefreshDataSubscription();

        results = success.offer.Offer;
        changes = success.offer.Changes;
        customer = success.offer.Customer;
        offerDetails = success.offer.Offer;
        performValidation = false;
        setNotifyUserOfTollFeesChange(false);

        var pre20201Calc = false;
        if (changes.BridgeFormulaRearPermissible === 0 && changes.VehicleBridgeFormulaPermissible === 0 && changes.CombinationBridgeFormulaPermissible === 0) {
            pre20201Calc = true;
        }
        //Update values for offer before creation
        offerDetails.Id = otherOfferDetails.offerId;
        offerDetails.UpdateCounter = changes.UpdateCounter;
        offerDetails.retrievedBodyType = otherOfferDetails.retrievedBodyType;
        offerDetails.retrievedBodyManufacturer = otherOfferDetails.retrievedBodyManufacturer;
        offerDetails.VehicleMake = otherOfferDetails.vehMake;
        offerDetails.VehicleRange = otherOfferDetails.vehRange;
        offerDetails.IsSharedOffer = otherOfferDetails.isSharedOffer;


        //Update the offer with changes
        offerDetails.Costing.NoOfUnits = changes.NumberOfVehicles
        offerDetails.Costing.VehiclePrice = changes.VehiclePrice
        offerDetails.Costing.BodyAndEquipmentPrice = changes.BodyAndEquipmentPrice
        offerDetails.Costing.OptionPrice = changes.OptionPrice
        offerDetails.Costing.DepositRate = changes.Deposit
        //offerDetails.Costing.FuelConsumption = changes.FuelConsumption
        offerDetails.Costing.FuelCost = changes.FuelCost
        offerDetails.Costing.InterestRate = changes.InterestRate
        offerDetails.Costing.MaintenanceRate = changes.MaintenanceCost
        offerDetails.Costing.MonthlyDistance = changes.MonthlyKilometres
        offerDetails.Costing.RepaymentTerm = changes.RepaymentTerm
        offerDetails.Costing.ResidualRate = changes.Residual
        offerDetails.Costing.OriginalVehiclePrice = changes.OriginalVehiclePrice
        offerDetails.Costing.OriginalBodyAndEquipmentPrice = changes.OriginalBodyAndEquipmentPrice
        offerDetails.Costing.OriginalOptionPrice = changes.OriginalOptionPrice
        offerDetails.Costing.OnRoadCostsCosts = changes.OnRoadCostsCosts
        offerDetails.Costing.OnRoadCostsDiscounts = changes.OnRoadCostsDiscounts        

        // setup the original offer before fuel tank changes are applied
        setOriginalOffer(new Offer(offerDetails));
        setBaseOffer(new Offer(offerDetails));
        offerDetails.Configuration.FuelTanks = changes.FuelTanks
        if (changes.Axles.length > 0) {
            var vehiclesAxles = changes.Axles.filter(function (axleAsJSON) {
                return axleAsJSON.ParentType === config.CHASSIS_OBJECT_TYPES.VEHICLE;
            });
            offerDetails.Configuration.Axles = vehiclesAxles;


        }


        //populate the calculation and set it to open
        currencySymbolMajor.value = success.offer.Offer.CurrencySymbolMajor;
        currencySymbolMinor.value = success.offer.Offer.CurrencySymbolMinor;
        languageId.value = success.offer.Offer.LanguageId;

        //setLegislation(new Legislation(legislationDetails));
        setActiveOffer(new Offer(offerDetails));
        getActiveOffer().setupLastModifiedByInfo(success.offer.LastModifiedByInfo);
        if (success.offer.ShareInfo) {
            getActiveOffer().setupShareInfo(success.offer.ShareInfo);
        }

        //setActiveLegislationObjectsOnOfferManagerAndActiveOfferAndUpdateInterpreter(legislationDetails);
        getActiveOffer().setSalesPersonId(otherOfferDetails.activeSalesPersonId);
        // need to do this to match the ids used between corresponding fueltanks on active offer and original offer
        setupOriginalOfferFuelTankInternalIds(getActiveOffer(), getOriginalOffer());


        if (isCostingAvailableForOffer() === true) {
            //Update values from changes
            getActiveOffer().getCosting().setVehiclePrice(changes.VehiclePrice);
            getActiveOffer().getCosting().setBodyAndEquipmentPrice(changes.BodyAndEquipmentPrice);

            getActiveOffer().getCosting().setOriginalVehiclePrice(changes.OriginalVehiclePrice);
            getActiveOffer().getCosting().setOriginalBodyAndEquipmentPrice(changes.OriginalBodyAndEquipmentPrice);
            getActiveOffer().getCosting().setOriginalOptionPrice(changes.OriginalOptionPrice);

            getActiveOffer().getCosting().setFuelConsumption(changes.FuelConsumption);

            // Setting original values for vehicle, body and equipmnent and option prices to the in original prices from the database
            getOriginalOffer().getCosting().setVehiclePrice(changes.OriginalVehiclePrice);
            getOriginalOffer().getCosting().setBodyAndEquipmentPrice(changes.OriginalBodyAndEquipmentPrice);
            getOriginalOffer().getCosting().setOptionPrice(changes.OriginalOptionPrice);

            getOriginalOffer().getCosting().setFuelConsumption(changes.FuelConsumption);
        }

        getActiveOffer().setPreparedForName(changes.PreparedForName);
        getActiveOffer().setPreparedForTelephone(changes.PreparedForTelephone);

        getOriginalOffer().setPreparedForName(changes.PreparedForName);
        getOriginalOffer().setPreparedForTelephone(changes.PreparedForTelephone);


        clearActiveLegislationQuickLookupObjects();
        rigOps.setup(getActiveOffer(), getOriginalOffer(), getBaseOffer(), getLesserOfActiveLegislationVals, legislationVals);
        setActiveApplicationUsingId(changes.ApplicationId);
        getActiveOffer().setApplication(activeApplication.value);



        if (changes.StructureVersion !== undefined && changes.StructureVersion >= 2) {

            //need to set up default payload objects on original COMPLETE_VEHICLE before changes are applied
            if (getConfiguration().getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                //var tempPayloadObject = setupDefaultPayloadForCompleteVehicle(getConfiguration());
                var tempOriginalPayloadObject = setupDefaultPayloadForCompleteVehicle(getOriginalOffer().getConfiguration());
                //tempPayloadObject.setMass(getMaximisedLoad());
                tempOriginalPayloadObject.setMass(rigOps.getMaximisedPayloadForChassis(config.CHASSIS_OBJECT_TYPES.VEHICLE));
            }

            getActiveOffer().getConfiguration().setAxle2FrontToAxle1Rear(changes.Axle2FrontToAxle1Rear);
            getActiveOffer().getConfiguration().setRearOverhang(changes.RearOverhang);

            //#region new attributes
            getActiveOffer().getConfiguration().setFrontOverhang(changes.FrontOverhang);
            getActiveOffer().getConfiguration().setAxle1FrontToAxle2Front(changes.Axle1FrontToAxle2Front);
            getActiveOffer().getConfiguration().setAxle1RearToAxle2Rear(changes.Axle1RearToAxle2Rear);
            getActiveOffer().getConfiguration().setAxle2RearToAxle3Rear(changes.Axle2RearToAxle3Rear);
            getActiveOffer().getConfiguration().setAxle3RearToAxle4Rear(changes.Axle3RearToAxle4Rear);
            getActiveOffer().getConfiguration().setRearProtrusion(changes.RearProtrusion);
            getActiveOffer().getConfiguration().setWheelbaseTheoretical(changes.WheelbaseTheoretical);
            getActiveOffer().getConfiguration().setWheelbaseTheoreticalOverride(changes.WheelbaseTheoreticalOverride);
            getActiveOffer().getConfiguration().setBumperToBackOfCab(changes.BumperToBackOfCab);
            getActiveOffer().getConfiguration().setRearMostProtrudingItemBehindCabToRearAxles(changes.RearMostProtrudingItemBehindCabToRearAxles);
            getActiveOffer().getConfiguration().setCabGapRecommendedMin(changes.CabGapRecommendedMin);
            //getActiveOffer().getConfiguration().setTiltCab(changes.TiltCab);
            getActiveOffer().getConfiguration().setCabTilt(changes.CabTilt);
            getActiveOffer().getConfiguration().setHingePointFromBumper(changes.HingePointFromBumper);
            getActiveOffer().getConfiguration().setHingePointFromGround(changes.HingePointFromGround);
            getActiveOffer().getConfiguration().setCabTiltRadius(changes.CabTiltRadius);
            getActiveOffer().getConfiguration().setCabHeight(changes.CabHeight);
            getActiveOffer().getConfiguration().setChassisHeightFront(changes.ChassisHeightFront);
            getActiveOffer().getConfiguration().setChassisHeightRear(changes.ChassisHeightRear);
            getActiveOffer().getConfiguration().setCabWidth(changes.CabWidth);
            getActiveOffer().getConfiguration().setRearWidth(changes.RearWidth);
            //getActiveOffer().getConfiguration().setOverallWidth(changes.OverallWidth);
            getActiveOffer().getConfiguration().setTrackWidthFront(changes.TrackWidthFront);
            getActiveOffer().getConfiguration().setTrackWidthRear(changes.TrackWidthRear);
            getActiveOffer().getConfiguration().setChassisWidth(changes.ChassisWidth);
            getActiveOffer().getConfiguration().setAxle1FrontToWheelbaseTheoreticalStart(changes.Axle1FrontToWheelbaseTheoreticalStart);
            getActiveOffer().getConfiguration().setAxle1FrontToWheelbaseTheoreticalStartOverride(changes.Axle1FrontToWheelbaseTheoreticalStartOverride);
            getActiveOffer().getConfiguration().setBumperToStartOfBodyInternal(changes.BumperToStartOfBodyInternal);
            getActiveOffer().getConfiguration().setBodyLengthInternal(changes.BodyLengthInternal);
            getActiveOffer().getConfiguration().setBodyHeightInternal(changes.BodyHeightInternal);
            getActiveOffer().getConfiguration().setFloorHeight(changes.FloorHeight);
            getActiveOffer().getConfiguration().setFifthWheelHeightFromGround(changes.FifthWheelHeightFromGround);

            //#endregion new attributes
            //#region new sprint 3, weights attributes
            getActiveOffer().getConfiguration().setTareFrontOverride(changes.TareFrontOverride);
            getActiveOffer().getConfiguration().setTareRearOverride(changes.TareRearOverride);
            getActiveOffer().getConfiguration().setCrewSecondRowVerticalCOG(changes.CrewSecondRowVerticalCOG);
            getActiveOffer().getConfiguration().setCrewSecondRowVerticalCOGOverride(changes.CrewSecondRowVerticalCOGOverride);
            getActiveOffer().getConfiguration().setCrewFirstRowVerticalCOG(changes.CrewFirstRowVerticalCOG);
            getActiveOffer().getConfiguration().setCrewFirstRowVerticalCOGOverride(changes.CrewFirstRowVerticalCOGOverride);
            getActiveOffer().getConfiguration().setVehicleVerticalCOG(changes.VehicleVerticalCOG);
            getActiveOffer().getConfiguration().setVehicleVerticalCOGOverride(changes.VehicleVerticalCOGOverride);
            getActiveOffer().getConfiguration().setTareToolsIncluded(changes.TareToolsIncluded);
            getActiveOffer().getConfiguration().setTareSpareWheelIncluded(changes.TareSpareWheelIncluded);
            getActiveOffer().getConfiguration().setGAFront(changes.GAFront);
            getActiveOffer().getConfiguration().setGARear(changes.GARear);
            getActiveOffer().getConfiguration().setAUFront(changes.AUFront);
            getActiveOffer().getConfiguration().setAURear(changes.AURear);
            getActiveOffer().getConfiguration().setFuelType(changes.FuelType);
            getActiveOffer().getConfiguration().setFuelSpecificGravity(changes.FuelSpecificGravity);
            getActiveOffer().getConfiguration().setManufacturersFrontAxlesMassOverride(changes.ManufacturersFrontAxlesMassOverride);
            getActiveOffer().getConfiguration().setManufacturersRearAxlesMassOverride(changes.ManufacturersRearAxlesMassOverride);
            getActiveOffer().getConfiguration().setManufacturersGrossVehicleMassOverride(changes.ManufacturersGrossVehicleMassOverride);
            getActiveOffer().getConfiguration().setGVM(changes.GVM);
            getActiveOffer().getConfiguration().setGCM(changes.GCM);
            getActiveOffer().getConfiguration().setFrontRoadFriendlySuspension(changes.FrontRoadFriendlySuspension);
            getActiveOffer().getConfiguration().setRearRoadFriendlySuspension(changes.RearRoadFriendlySuspension);
            getActiveOffer().getConfiguration().setRolloverProtection(changes.RolloverProtection);
            getActiveOffer().getConfiguration().setFrontUnderrunProtection(changes.FrontUnderrunProtection);
            //getActiveOffer().getConfiguration().setPermissibleRear(changes.PermissibleRear);
            //getActiveOffer().getConfiguration().setPermissibleFront(changes.PermissibleFront);
            //getActiveOffer().getConfiguration().setPermissibleTotal(changes.PermissibleTotal);
            //getActiveOffer().getConfiguration().setPermissibleRearOverride(changes.PermissibleRearOverride);
            //getActiveOffer().getConfiguration().setPermissibleFrontOverride(changes.PermissibleFrontOverride);
            //getActiveOffer().getConfiguration().setPermissibleTotalOverride(changes.PermissibleTotalOverride);
            getActiveOffer().getConfiguration().setCrewMassPerPerson(changes.CrewMassPerPerson);
            getActiveOffer().getConfiguration().setNoOfCrewInFirstRow(changes.NoOfCrewInFirstRow);
            getActiveOffer().getConfiguration().setCrewFirstRowCOG(changes.CrewFirstRowHorizontalCOG);
            getActiveOffer().getConfiguration().setNoOfCrewInSecondRow(changes.NoOfCrewInSecondRow);
            getActiveOffer().getConfiguration().setCrewSecondRowCOG(changes.CrewSecondRowHorizontalCOG);
            getActiveOffer().getConfiguration().setCrewFirstRowHorizontalCOGOverride(changes.CrewFirstRowHorizontalCOGOverride);
            getActiveOffer().getConfiguration().setCrewSecondRow(changes.CrewSecondRow);
            getActiveOffer().getConfiguration().setCrewSecondRowHorizontalCOGOverride(changes.CrewSecondRowHorizontalCOGOverride);
            getActiveOffer().getConfiguration().setTareFront(changes.TareFront);
            getActiveOffer().getConfiguration().setTareRear(changes.TareRear);
            getActiveOffer().getConfiguration().setTareRearRear(changes.TareRear);
            //endregion new sprint 3, weights attributes
            getActiveOffer().getConfiguration().setTareDriverIncluded(changes.TareDriverIncluded);
            getActiveOffer().setApplyHigherMassLimits(changes.ApplyHigherMassLimits);
            getActiveOffer().getConfiguration().setManufacturersGrossCombinationMassOverride(changes.ManufacturersGrossCombinationMassOverride);
            getActiveOffer().setSpecDate(otherOfferDetails.specDate);

            if (isCostingAvailableForOffer() === true) {
                //2017.10
                var measurementSystemToUse = getMeasurementSystemToUse();
                getActiveOffer().getCosting().setFuelConsumptionOverride(changes.FuelConsumptionOverride);
                getActiveOffer().getCosting().setDriverRemuneration(changes.DriverRemuneration);
                getActiveOffer().getCosting().setInsuranceRate(changes.InsuranceRate);
                getActiveOffer().getCosting().setOverheadRate(changes.OverheadRate);
                getActiveOffer().getCosting().setTotalRevenue(changes.TotalRevenue * measurementSystemToUse.distanceConversion);
                // getActiveOffer().getCosting().setTotalRevenue(changes.TotalRevenue);
                getActiveOffer().getCosting().setTotalRevenueSetUsingMeasurementSystemId(globals.user.getActiveMeasurementSystemId());
                getActiveOffer().getCosting().setTotalRevenueAsCurrencyPerKm(changes.TotalRevenue);
                getActiveOffer().getCosting().setTyreRate(changes.TyreRate * measurementSystemToUse.distanceConversion);
                // getActiveOffer().getCosting().setTyreRate(changes.TyreRate);
                getActiveOffer().getCosting().setTyreRateSetUsingMeasurementSystemId(globals.user.getActiveMeasurementSystemId());
                getActiveOffer().getCosting().setTyreRateAsCentsPerKm(changes.TyreRate);
                //getActiveOffer().getCosting().setLicenceFees(changes.LicenceFees);
                getActiveOffer().getCosting().setTollFees(changes.TollFees);
                //2018.11
                getActiveOffer().getCosting().setTyreRateOverride(changes.TyreRateOverride);
                //2019.6
                getActiveOffer().getCosting().setLicenceCategories(licenceCategories.licenceCategories);
                getOriginalOffer().getCosting().setLicenceCategories(licenceCategories.licenceCategories);
            }

            if (getActiveOffer().getDataAvailability().ActivePerformance && getActiveOffer().getPerformance() !== undefined) {
                getActiveOffer().getPerformance().setCoefficientOfDrag(changes.CoefficientOfDrag);
                getActiveOffer().getPerformance().setCoefficientOfDragOverride(changes.CoefficientOfDragOverride);
                getActiveOffer().getPerformance().setSpeedMaxLoaded(changes.SpeedMaxLoaded);
                getActiveOffer().getPerformance().setVehicleTotalUnladenOverride(changes.VehicleTotalUnladenOverride);
                getActiveOffer().getPerformance().setVehicleTotalUnladen(changes.VehicleTotalUnladen);
                getActiveOffer().getPerformance().setFrontalAreaOverride(changes.FrontalAreaOverride);
                getActiveOffer().getPerformance().setFrontalArea(changes.FrontalArea);
                getActiveOffer().getPerformance().setDriveAxleRatioOverride(changes.DriveAxleRatioOverride);
                getActiveOffer().getPerformance().setDriveAxleRatio(changes.DriveAxleRatio);
                getActiveOffer().getPerformance().setTrailerTotalUnladenOverride(changes.TrailerTotalUnladenOverride);
                getActiveOffer().getPerformance().setTrailerTotalUnladen(changes.TrailerTotalUnladen);
                getActiveOffer().getPerformance().setTrailer2TotalUnladenOverride(changes.Trailer2TotalUnladenOverride);
                getActiveOffer().getPerformance().setTrailer2TotalUnladen(changes.Trailer2TotalUnladen);
                getActiveOffer().getPerformance().setAltitudeProfileEntriesBase64(changes.AltitudeProfile);
                getActiveOffer().getPerformance().setSimResAverageAdblue(changes.SimResAverageAdblue);
                getActiveOffer().getPerformance().setSimResAverageCO2Emissions(changes.SimResAverageCO2Emissions);
                getActiveOffer().getPerformance().setSimResAverageFuel(changes.SimResAverageFuel);
                getActiveOffer().getPerformance().setSimResAverageSpeed(changes.SimResAverageSpeed);
                getActiveOffer().getPerformance().setSimResProductivity(changes.SimResProductivity);
                getActiveOffer().getPerformance().setSimResTotalAdblue(changes.SimResTotalAdblue);
                getActiveOffer().getPerformance().setSimResTotalCO2Emissions(changes.SimResTotalCO2Emissions);
                getActiveOffer().getPerformance().setSimResTotalFuel(changes.SimResTotalFuel);
                getActiveOffer().getPerformance().setSimResTripDistance(changes.SimResTripDistance);
                getActiveOffer().getPerformance().setSimResTripTime(changes.SimResTripTime);
                getActiveOffer().getPerformance().setSimResTotalTimeInTopGear(changes.SimResTotalTimeInTopGear);
                getActiveOffer().getPerformance().setSimResTotalGearChanges(changes.SimResTotalGearChanges);
                if(Array.isArray(changes.Waypoints) && changes.Waypoints.length > 1) {
                    getActiveOffer().getPerformance().setSimulationCreated(true);
                }
            }
            //2018.1
            getActiveOffer().getConfiguration().setCabWidthOverride(changes.CabWidthOverride);
            getActiveOffer().getConfiguration().setRearWidthOverride(changes.RearWidthOverride);
            getActiveOffer().getConfiguration().setTrackWidthFrontOverride(changes.TrackWidthFrontOverride);
            getActiveOffer().getConfiguration().setTrackWidthRearOverride(changes.TrackWidthRearOverride);
            getActiveOffer().getConfiguration().setChassisWidthOverride(changes.ChassisWidthOverride);
            getActiveOffer().getConfiguration().setTurningAbilitySpecifiedAs(changes.TurningAbilitySpecifiedAs);
            getActiveOffer().getConfiguration().setTurningRadiusCurbToCurbOverride(changes.TurningRadiusCurbToCurbOverride);
            getActiveOffer().getConfiguration().setTurningRadiusCurbToCurb(changes.TurningRadiusCurbToCurb);
            getActiveOffer().getConfiguration().setTurningRadiusWallToWallOverride(changes.TurningRadiusWallToWallOverride);
            getActiveOffer().getConfiguration().setTurningRadiusWallToWall(changes.TurningRadiusWallToWall);
            getActiveOffer().getConfiguration().setSteeringAngleInnerOverride(changes.SteeringAngleInnerOverride);
            getActiveOffer().getConfiguration().setSteeringAngleInner(changes.SteeringAngleInner);



            //2018.2
            getActiveOffer().getConfiguration().setAxle1FrontToAxle2FrontOverride(changes.Axle1FrontToAxle2FrontOverride);
            getActiveOffer().getConfiguration().setAxle2FrontToAxle1RearOverride(changes.Axle2FrontToAxle1RearOverride);
            getActiveOffer().getConfiguration().setAxle1RearToAxle2RearOverride(changes.Axle1RearToAxle2RearOverride);
            getActiveOffer().getConfiguration().setAxle2RearToAxle3RearOverride(changes.Axle2RearToAxle3RearOverride);
            getActiveOffer().getConfiguration().setAxle3RearToAxle4RearOverride(changes.Axle3RearToAxle4RearOverride);

            //2018.3
            getActiveOffer().getConfiguration().getPayloadHolder().setPayloadType(changes.PayloadType);


            //2019.2
            var reportOfferOptions = [];
            if (getActiveOffer().getDataAvailability().ActiveConfiguration) {
                reportOfferOptions.push({
                    Code: config.SUMMARY_REPORT_OPTIONS.CONFIGURATION,
                    Options: [{
                        Code: config.SUMMARY_REPORT_OFFER_OPTIONS.DRAWING_VIEW
                    }, {
                        Code: config.SUMMARY_REPORT_OFFER_OPTIONS.LOAD_PLAN
                    }, {
                        Code: config.SUMMARY_REPORT_OFFER_OPTIONS.WEIGHT_AND_COG
                    }]
                });
            }
            if (getActiveOffer().getDataAvailability().ActiveSpecification) {
                reportOfferOptions.push({
                    Code: config.SUMMARY_REPORT_OPTIONS.SPECIFICATION                    
                });
            }
            if (getActiveOffer().getDataAvailability().ActiveCosting) {
                reportOfferOptions.push({
                    Code: config.SUMMARY_REPORT_OPTIONS.COSTING                    
                });
            }
            if (getActiveOffer().getDataAvailability().ActivePerformance) {
                reportOfferOptions.push({
                    Code: config.SUMMARY_REPORT_OPTIONS.PERFORMANCE                    
                });
            }
            reportOfferOptions.forEach(function (reportOfferOption) {
                var reportOptionToUpdate = getSummaryReportOptionUsingCode(reportOfferOption.Code);
                if (reportOptionToUpdate !== null && Array.isArray(reportOfferOption.Options) && reportOfferOption.Options.length > 0) {
                    reportOptionToUpdate.createSummaryReportOfferOptions(reportOfferOption.Options);
                }
            });
            //2018.7
            if (changes.Reports !== null && changes.Reports !== undefined) {
                activateSummaryReportOptionUsingList(changes.Reports);
            }

            //2018.9
            if (changes.VehicleDescription !== '') {
                getActiveOffer().getConfiguration().setDescription(changes.VehicleDescription);
            }
            getActiveOffer().setKeepChassisFlushWithBody(changes.KeepChassisFlushWithBody);



            if (changes.AdditionalDescription !== '') {
                //getActiveOffer().setAdditionalDescription(changes.AdditionalDescription);
                changes.Description = changes.Description + ' ' + changes.AdditionalDescription
                changes.AdditionalDescription = '';
            }
            getActiveOffer().setDescription(changes.Description);
            description.value = changes.Description;
            //additionalDescription(changes.AdditionalDescription);

            //2018.11
            getActiveOffer().getConfiguration().setWheelbaseSpecified(changes.WheelbaseSpecified);
            getActiveOffer().getConfiguration().setWheelbaseOverride(changes.WheelbaseOverride);
            getActiveOffer().getConfiguration().setVehicleLateralCOG(changes.VehicleLateralCOG);
            getActiveOffer().getConfiguration().setVehicleLateralCOGOverride(changes.VehicleLateralCOGOverride);
            getActiveOffer().getConfiguration().setCrewFirstRowLateralCOG(changes.CrewFirstRowLateralCOG);
            getActiveOffer().getConfiguration().setCrewFirstRowLateralCOGOverride(changes.CrewFirstRowLateralCOGOverride);
            getActiveOffer().getConfiguration().setCrewSecondRowLateralCOG(changes.CrewSecondRowLateralCOG);
            getActiveOffer().getConfiguration().setCrewSecondRowLateralCOGOverride(changes.CrewSecondRowLateralCOGOverride);

            //2019.3
            getActiveOffer().getConfiguration().setFifthWheelOffset(changes.FifthWheelOffset);

            //2019.5
            getActiveOffer().getConfiguration().setCabToAxlesOverride(changes.CabToAxlesOverride);


            //2020.1
            if (changes.VehicleBridgeFormulaPermissibleOverride === true) {
                getActiveOffer().getConfiguration().setVehicleBridgeFormulaPermissible(changes.VehicleBridgeFormulaPermissible);
                getActiveOffer().getConfiguration().setVehicleBridgeFormulaPermissibleOverride(changes.VehicleBridgeFormulaPermissibleOverride);
            } else if (changes.PermissibleTotalOverride === true && (changes.Trailers === null || changes.Trailers === undefined || (changes.Trailers && changes.Trailers.length === 0))) {
                getActiveOffer().getConfiguration().setVehicleBridgeFormulaPermissible(changes.PermissibleTotal);
                getActiveOffer().getConfiguration().setVehicleBridgeFormulaPermissibleOverride(changes.PermissibleTotalOverride);
                //getActiveOffer().getRig().getVehicle().setPermissibleTotal(0);
                getActiveOffer().getRig().getVehicle().setPermissibleTotalOverride(false);

            }
            if (changes.CombinationBridgeFormulaPermissibleOverride === true) {
                getActiveOffer().getConfiguration().setCombinationBridgeFormulaPermissible(changes.CombinationBridgeFormulaPermissible);
                getActiveOffer().getConfiguration().setCombinationBridgeFormulaPermissibleOverride(changes.CombinationBridgeFormulaPermissibleOverride);
            } else if (changes.PermissibleTotalOverride === true && changes.Trailers !== null && changes.Trailers !== undefined && changes.Trailers.length > 0) {
                getActiveOffer().getConfiguration().setCombinationBridgeFormulaPermissible(changes.PermissibleTotal);
                getActiveOffer().getConfiguration().setCombinationBridgeFormulaPermissibleOverride(changes.PermissibleTotalOverride);
                //getActiveOffer().getRig().getVehicle().setPermissibleTotal(0);
                getActiveOffer().getRig().getVehicle().setPermissibleTotalOverride(false);

            }
            if (changes.BridgeFormulaRearPermissibleOverride === true) {
                getActiveOffer().getConfiguration().setBridgeFormulaRearPermissible(changes.BridgeFormulaRearPermissible);
                getActiveOffer().getConfiguration().setBridgeFormulaRearPermissibleOverride(changes.BridgeFormulaRearPermissibleOverride);
            } else if (changes.PermissibleRearOverride === true) {
                getActiveOffer().getConfiguration().setBridgeFormulaRearPermissible(changes.AURear);
                getActiveOffer().getConfiguration().setBridgeFormulaRearPermissibleOverride(changes.PermissibleRearOverride);
                //getActiveOffer().getRig().getVehicle().setPermissibleRear(0);
                getActiveOffer().getRig().getVehicle().setPermissibleRearOverride(false);


            }
            var frontAxleGroup = getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
            if (changes.Axles && changes.Axles[0] && changes.Axles[0].BridgeFormulaPermissibleOverride === false) {
                if (changes.PermissibleFrontOverride === true) {

                    frontAxleGroup.setBridgeFormulaPermissibleOverride(changes.PermissibleFrontOverride);
                    frontAxleGroup.setBridgeFormulaPermissible(changes.AUFront);
                    //getActiveOffer().getRig().getVehicle().setPermissibleFront(0);
                    getActiveOffer().getRig().getVehicle().setPermissibleFrontOverride(false);
                    var valPerAxle;
                    if (changes.AUFront === 0) {
                        valPerAxle = 0;
                    } else {
                        valPerAxle = changes.PermissibleFront / frontAxleGroup.getRelatedAxleIds().length;
                    }
                    frontAxleGroup.getRelatedAxleIds().forEach(function (axleId) {
                        var individualAxle = getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxleById(axleId);
                        individualAxle.setBridgeFormulaPermissibleOverride(changes.PermissibleFrontOverride);
                        individualAxle.setBridgeFormulaPermissible(valPerAxle);
                    });

                }
            }

            if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.SIMPLIFIED) {
                getActiveOffer().getRig().getVehicle().getAxlesHolder().convertManufacturerRatingsFromDetailedToSimplified();
            }

            //2020.3
            getActiveOffer().getConfiguration().setFuelSpecificGravityOverride(changes.FuelSpecificGravityOverride);
            //2023.4
            getActiveOffer().getConfiguration().setExpressedAs(changes.EnergySourceExpressedAs);
            getActiveOffer().getConfiguration().setSpecificGravityAdBlue(changes.AdBlueSpecificGravity);
            getActiveOffer().getConfiguration().setSpecificGravityAdBlueOverride(changes.AdBlueSpecificGravityOverride);

            //2023.5
            getActiveOffer().getConfiguration().setDevicesForIndirectVision(changes.DevicesForIndirectVision);
            getActiveOffer().getConfiguration().setCommercialVehicleBrakeSystems(changes.CommercialVehicleBrakeSystems);
            getActiveOffer().getConfiguration().setAdvancedEmergencyBraking(changes.AdvancedEmergencyBraking);
            getActiveOffer().getConfiguration().setLaneDepartureWarning(changes.LaneDepartureWarning);
            getActiveOffer().getConfiguration().setSideUnderrunProtection(changes.SideUnderrunProtection);
            getActiveOffer().getConfiguration().setConspicuityMarkingsToTheRear(changes.ConspicuityMarkingsToTheRear);
            getActiveOffer().getConfiguration().setConspicuityMarkingsToBothSides(changes.ConspicuityMarkingsToBothSides);
            getActiveOffer().getConfiguration().setBlindSpotInformationSystems(changes.BlindSpotInformationSystems);
        }

        selectedCompetitor1Id.value = changes.Competitor1Id;
        selectedCompetitor2Id.value = changes.Competitor2Id;

        // selectedcompany stuff here
        setSelectedCustomer(customer.Id, customer.ContactName, customer.Company, customer.Mobile, customer.DirectNumber, customer.Email, customer.UpdateCounter, customer.OverallUpdateCounter);

        bodyManufacturer. value = otherOfferDetails.retrievedBodyManufacturer;
        languageId.value = success.offer.Offer.LanguageId;
        vehicleId.value = success.offer.Changes.VehicleId;
        vehicleName.value = success.offer.Offer.VehicleDescription;
        vehicleModel.value = success.offer.Offer.VehicleModel;
        vehicleMake.value = otherOfferDetails.vehMake;
        vehicleRange.value = otherOfferDetails.vehRange;
        vehicleGVW.value = success.offer.Offer.GVW;
        vehiclePayload.value = success.offer.Offer.Payload;
        vehiclePower.value = success.offer.Offer.Power;
        vehicleAxleLayout.value = success.offer.Offer.AxleLayout;
        vehiclePlusBodyImageURL.value = success.offer.Offer.VehiclePlusBodyImageURL;
        bodyType.value  = otherOfferDetails.retrievedBodyType;
        bodyManufacturer.value = otherOfferDetails.retrievedBodyManufacturer;
        vehicleTypeTranslation.value = otherOfferDetails.vehTypeTranslation;
        vehicleStatus.value = otherOfferDetails.vehStatus;

        if (isCostingAvailableForOffer() === true) {
            updateCapitalCosts();
        }
        customerAttachedToOffer.value = true;
        //NOTE: Functions need to be updated
        //unsavedChangesOnOffer.value = false;
        validPdfOnLocalStorage.value = false;
        pdfNoOfPages.value = 4;
        summaryNote.value = changes.SummaryNote;

        // onRoadCostsVersion = changes.OnRoadCostsVersion;

        if (otherOfferDetails.disableInteractions) {
            isReadOnly.value = true;
        } else {
            isReadOnly.value = false;
        }

        // need to set active cab colour 
        if (changes.VehicleColour !== '') {
            getActiveOffer().getConfiguration().setActiveCabColour(changes.VehicleColour);
        }

        getActiveOffer().setLegislation(_legislation);

        if (globals.user.getEdition() === config.APP_EDITION.ESSENTIALS) {
            finishOpenExistingOffer();
        } else {
            let newSettingsObject = settings.newSettingsObject(true);
            let originalSettingsObject = settings.newSettingsObject(true);
            let offerEncodedLegislationIds = changes.PropertiesLegislations.map(function(curElement) {
                return  globals.encodeUniqueLegislationId(curElement.CountryId, curElement.LegislationId);
            })
            loadLegislationsDetails(offerEncodedLegislationIds).then(function (legislationDetails) {
                newSettingsObject.setActiveLegislations(legislationDetails);
                newSettingsObject.setDashboardLegislationIds(offerEncodedLegislationIds);
                settings.applyAnyChanges(newSettingsObject, originalSettingsObject)
                        .then(function (changesApplied) {
                            if (changesApplied) {
                                getActiveOffer().setLegislation(legislationDetails);
                                settings.saveSettings(newSettingsObject, [])
                                    .then(function(success) { 
                                        finishOpenExistingOffer();
                                    })
                                    .catch(function (error) {
                                        globals.user.updateUser({
                                            accessCode: ''
                                        });
                        
                                        dfd.reject({
                                            errorMessage: error.errorMessage
                                        });
                                    });
                            } else {
                                finishOpenExistingOffer();
                            }
                        });
                // getActiveOffer().setLegislation(legislationDetails);
                // setLegislations(legislationDetails);
                // //generateDataForActiveLegislationsAndSetMergedPermissibles();
                // finishOpenExistingOffer();
                //dfd.resolve(success);
            }).fail(function (error) {
                globals.user.updateUser({
                    accessCode: ''
                });
    
                dfd.reject({
                    errorMessage: error.errorMessage
                });
            });
        }
        

        // var updateLegislations = doLegislationsNeedToBeUpdated(changes.PropertiesLegislations);
        
        
        
        

        // if (updateLegislations === true) {
        //     globals.user.replaceDashboardLegislationsUsingArray(changes.PropertiesLegislations);
        //     loadLegislationsDetails(globals.user.getDashboardConfiguration().getDashboardLegislationIds()).then(function (legislationDetails) {
        //         getActiveOffer().setLegislation(legislationDetails);
        //         setLegislations(legislationDetails);
        //         //generateDataForActiveLegislationsAndSetMergedPermissibles();
        //         finishOpenExistingOffer();
        //         //dfd.resolve(success);
        //     }).fail(function (error) {
        //         globals.user.updateUser({
        //             accessCode: ''
        //         });

        //         dfd.reject({
        //             errorMessage: error.errorMessage
        //         });
        //     });
        // } else {
        //     finishOpenExistingOffer();
        // }
        
        function finishOpenExistingOffer() {
           
            getActiveOffer().setAdditionalNote(changes.AdditionalNote);
            // no longer applying measurement system as saved with calculation, see #16951
            // setActiveMeasurementSystemUsingId(changes.MeasurementSystemId); 
            setActiveMeasurementSystemUsingId(globals.user.getActiveMeasurementSystemId());
            handleMeasurementSystemUpdate(activeMeasurementSystem.value);
            getActiveOffer().setLengthIncrement(globals.user.getLengthIncrement());
            getActiveOffer().setMassIncrement(globals.user.getMassIncrement());
            getActiveOffer().setPercentageIncrement(globals.user.getPercentageIncrement());
            interpreterInstance.setFormatter(getInterpreterFormatter());

            if (getActiveOffer().getDataAvailability().ActivePerformance && getActiveOffer().getPerformance() !== undefined) {
                getActiveOffer().getPerformance().addWaypoints(changes.Waypoints);

                // get Tolls
                if (changes.OfferTollFees !== null) {
                    getActiveOffer().getPerformance().addTollsUsingTollFeesArray(changes.OfferTollFees);

                    if (offerManager.getActiveOffer().getCosting().getTollFeesPerTripOverride() === false) {
                        var tollFeesPerTripFromTolls = 0;
                        getActiveOffer().getPerformance().getTolls().forEach(function (toll) {
                            tollFeesPerTripFromTolls += toll.getActiveTollFee();
                        });
                        offerManager.getActiveOffer().getCosting().setTollFeesPerTrip(tollFeesPerTripFromTolls);
                    }
                }
            }
            if (isCostingAvailableForOffer() === true) {
                optionPrice.value = changes.OptionPrice;
                getActiveOffer().getCosting().setVehicleLicenceFees(changes.VehicleLicenceFees);
                getActiveOffer().getCosting().setVehicleLicenceFeesOverride(changes.VehicleLicenceFeesOverride);
                getActiveOffer().getCosting().setVehicleLicenceFeesRegionId(changes.VehicleLicenceFeesRegionId);

                //if(changes.VehicleLicenceFeesOverride === true) {
                //    getActiveOffer().getCosting().setVehicleLicenceFees(changes.VehicleLicenceFees);
                //    getActiveOffer().getCosting().setVehicleLicenceFeesOverride(changes.VehicleLicenceFeesOverride);
                //    getActiveOffer().getCosting().setVehicleLicenceFeesRegionId(changes.VehicleLicenceFeesRegionId);
                //} else {
                //    getActiveOffer().getCosting().setVehicleLicenceFeesRegionId(changes.VehicleLicenceFeesRegionId);
                //    getActiveOffer().getCosting().setVehicleLicenceFeesOverride(changes.VehicleLicenceFeesOverride);
                //    // check whether 
                //    var defaultLicenceRegion = globals.getLicenceCategoryObjectToUse(getActiveOffer().getCosting().getLicenceCategories(), 'VEHICLE', getActiveOffer().getRig().getVehicle().getUnladenTotal(), globals.user.getSpecifyLicencingRegionAs());
                //    if (defaultLicenceRegion.TotalPrice === changes.VehicleLicenceFees) {
                //        getActiveOffer().getCosting().setVehicleLicenceFees(changes.VehicleLicenceFees);
                //    } else {
                //        addItemToLicenceFeesMessageObject(config.CHASSIS_OBJECT_TYPES.VEHICLE, defaultLicenceRegion.TotalPrice, changes.VehicleLicenceFees);
                //    }
                //}

                getActiveOffer().getCosting().setVehiclePriceOverride(changes.VehiclePriceOverride);
                getActiveOffer().getCosting().setVehicleDiscount(changes.VehicleDiscount);
                getActiveOffer().getCosting().setVehiclePeriod(changes.VehiclePeriod);
                getActiveOffer().getCosting().setVehicleInterestRate(changes.VehicleInterestRate);
                getActiveOffer().getCosting().setVehicleResidualRate(changes.VehicleResidualRate);
                getActiveOffer().getCosting().setVehicleResidualRateType(changes.VehicleResidualRateType);
                getActiveOffer().getCosting().setFinanceTotalPrice(changes.FinanceTotalPrice);
                getActiveOffer().getCosting().setFinanceTotalPriceOverride(changes.FinanceTotalPriceOverride);
                getActiveOffer().getCosting().setLicenceFees(changes.LicenceFees);
                getActiveOffer().getCosting().setLicenceFeesOverride(changes.LicenceFeesOverride);

                getActiveOffer().getCosting().setTollFeesOverride(changes.TollFeesOverride);
                getActiveOffer().getCosting().setMonthlyTrips(changes.MonthlyTrips);
                getActiveOffer().getCosting().setVehicleClassId(changes.CostingVehicleClassId);
                getActiveOffer().getCosting().setVehicleClassIdOverride(changes.CostingVehicleClassIdOverride);
                getActiveOffer().getCosting().setTollFeesPerTrip(changes.CostingTollFeesPerTrip);
                getActiveOffer().getCosting().setTollFeesPerTripOverride(changes.CostingTollFeesPerTripOverride);
                getActiveOffer().getCosting().setPermitCostPerTrip(changes.CostingPermitCostsPerTrip);

                if (getActiveOffer().getCosting().getTollFeesOverride() === false) {
                    var monthlyTollsCosts = (offerManager.getActiveOffer().getCosting().getTollFeesPerTrip() + offerManager.getActiveOffer().getCosting().getPermitCostPerTrip()) * offerManager.getActiveOffer().getCosting().getMonthlyTrips();
                    offerManager.getActiveOffer().getCosting().setTollFees(monthlyTollsCosts);
                }

                // 2023.2
                getActiveOffer().getCosting().setFuelAndAdblueCostPerMonthOverride(changes.FuelAndAdblueCostPerMonthOverride);
                if(getActiveOffer().getCosting().getFuelAndAdblueCostPerMonthOverride() === true || (getActiveOffer().getCosting().getFuelAndAdblueCostPerMonthOverride() === false && changes.FuelAndAdblueCostPerMonth !== 0)) {
                    getActiveOffer().getCosting().setFuelAndAdblueCostPerMonth(changes.FuelAndAdblueCostPerMonth);
                }
                
                getActiveOffer().getCosting().setVehicleFuelAndAdblueTotalCostPerMonthOverride(changes.VehicleFuelAndAdblueTotalCostPerMonthOverride);
                if(getActiveOffer().getCosting().getVehicleFuelAndAdblueTotalCostPerMonthOverride() === true || (getActiveOffer().getCosting().getVehicleFuelAndAdblueTotalCostPerMonthOverride() === false && changes.VehicleFuelAndAdblueTotalCostPerMonth !== 0)) {
                    getActiveOffer().getCosting().setVehicleFuelAndAdblueTotalCostPerMonth(changes.VehicleFuelAndAdblueTotalCostPerMonth);
                }
                
                getActiveOffer().getCosting().setVehicleFuelAndAdblueFuelConsumptionOverride(changes.VehicleFuelAndAdblueFuelConsumptionOverride);
                if(getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelConsumptionOverride() === true || (getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelConsumptionOverride() === false && changes.VehicleFuelAndAdblueFuelConsumption !== 0)) {
                    getActiveOffer().getCosting().setVehicleFuelAndAdblueFuelConsumption(changes.VehicleFuelAndAdblueFuelConsumption);
                }
                
                getActiveOffer().getCosting().setVehicleFuelAndAdblueFuelCostPerVolumeOverride(changes.VehicleFuelAndAdblueFuelCostPerVolumeOverride);
                if(getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelCostPerVolumeOverride() === true || (getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelCostPerVolumeOverride() === false && changes.VehicleFuelAndAdblueFuelCostPerVolume !== 0)) {
                    getActiveOffer().getCosting().setVehicleFuelAndAdblueFuelCostPerVolume(changes.VehicleFuelAndAdblueFuelCostPerVolume);
                }
                
                getActiveOffer().getCosting().setVehicleFuelAndAdblueAdblueConsumptionOverride(changes.VehicleFuelAndAdblueAdblueConsumptionOverride);
                if(getActiveOffer().getCosting().getVehicleFuelAndAdblueAdblueConsumptionOverride() === true || (getActiveOffer().getCosting().getVehicleFuelAndAdblueAdblueConsumptionOverride() === false && changes.VehicleFuelAndAdblueAdblueConsumption !== 0)) {
                    getActiveOffer().getCosting().setVehicleFuelAndAdblueAdblueConsumption(changes.VehicleFuelAndAdblueAdblueConsumption);
                }
                
                getActiveOffer().getCosting().setVehicleFuelAndAdblueAdblueCostPerVolumeOverride(changes.VehicleFuelAndAdblueAdblueCostPerVolumeOverride);
                if(getActiveOffer().getCosting().getVehicleFuelAndAdblueAdblueCostPerVolumeOverride() === true || (getActiveOffer().getCosting().getVehicleFuelAndAdblueAdblueCostPerVolumeOverride() === false && changes.VehicleFuelAndAdblueAdblueCostPerVolume !== 0)) {
                    getActiveOffer().getCosting().setVehicleFuelAndAdblueAdblueCostPerVolume(changes.VehicleFuelAndAdblueAdblueCostPerVolume);
                }
                
                getActiveOffer().getCosting().setVehicleFuelAndAdblueEquipmentFuelConsumption(changes.VehicleFuelAndAdblueEquipmentFuelConsumption);
                getActiveOffer().getCosting().setVehicleFuelAndAdblueEquipmentFuelCostPerVolumeOverride(changes.VehicleFuelAndAdblueEquipmentFuelCostPerVolumeOverride);
                if(getActiveOffer().getCosting().getVehicleFuelAndAdblueEquipmentFuelCostPerVolumeOverride() === true || (getActiveOffer().getCosting().getVehicleFuelAndAdblueEquipmentFuelCostPerVolumeOverride() === false && changes.VehicleFuelAndAdblueEquipmentFuelCostPerVolume !== 0)) {
                    getActiveOffer().getCosting().setVehicleFuelAndAdblueEquipmentFuelCostPerVolume(changes.VehicleFuelAndAdblueEquipmentFuelCostPerVolume);
                }
                
                getActiveOffer().getCosting().setVehicleFuelAndAdblueEquipmentUtilisation(changes.VehicleFuelAndAdblueEquipmentUtilisation);

                if(getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelConsumptionOverride() === false) {
                    if(getActiveOffer().getCosting().getFuelConsumptionOverride() === true) {
                        getActiveOffer().getCosting().setVehicleFuelAndAdblueFuelConsumption(getActiveOffer().getCosting().getFuelConsumption());
                        getActiveOffer().getCosting().setVehicleFuelAndAdblueFuelConsumptionOverride(getActiveOffer().getCosting().getFuelConsumptionOverride());
                    } else {
                        // Update the costing for fuel and adblue detail
                        getActiveOffer().getCosting().setVehicleFuelAndAdblueFuelConsumption(getActiveOffer().getFuelAndAdblueFuelConsumptionForOverride());
                    }
                }
            }
            
            setupValidationConfigs();
            //if (getActiveOffer().getDataAvailability().ActiveCosting && getActiveOffer().getCosting() !== undefined) {
            //    setupCostingObservables();
            //    setupCostingFormattedComputeds();
            //}
            addAddedAxlesToOriginalRig(getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxles());
            setupOriginalAxleInternalIds(getActiveOffer(), getOriginalOffer());
            setupAllTrailersEquipmentAxlesPayloadsEtcValues();
            if (changes.StructureVersion !== undefined && changes.StructureVersion >= 4) {
                if (getActiveOffer().getRig().getVehicle().getAccessoryHolder().getFifthWheel() !== null) {
                    getActiveOffer().getRig().getVehicle().getAccessoryHolder().getFifthWheel().setIsPinned(true);
                }

                if (getActiveOffer().getRig().getVehicle().getAccessoryHolder().getHitch() !== null) {
                    getActiveOffer().getRig().getVehicle().getAccessoryHolder().getHitch().setIsPinned(true);
                }
            }
            if (changes.StructureVersion !== undefined && changes.StructureVersion < 5) {
                getActiveOffer().getRig().getVehicle().getAxlesHolder().calculatePercentagesOfWeightCarriedPreviouslySavedCalculation();
                getOriginalOffer().getRig().getVehicle().getAxlesHolder().calculatePercentagesOfWeightCarriedPreviouslySavedCalculation();
            }else {
                getOriginalOffer().getRig().getVehicle().getAxlesHolder().calculatePercentagesOfWeightCarried();
                getActiveOffer().getRig().getVehicle().getAxlesHolder().calculateRearRearPercentageOfWeightCarried();
            }
            
            if (!rigOps.getVehicleWheelbaseTurningChanged()) {
                initialiseTurningAbilityVariables();
            } else {
                turningCircleLockedToSteeringAngleInner = true;
                getActiveOffer().getRig().getVehicle().setTurningAbilitySpecifiedAs(config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER);
            }
            //if (updateLegislations !== true) {
                generateDataForActiveLegislationsAndSetMergedPermissibles();

            //}

            // TCTODO: This shouldn't have to be called twice. How can code be structured so that the legislation is evaluated correctly earlier.
            // This had to be called here because evaluateLegislation('HIGHERMASSLIMITSFORCOMBINATION', legislationToUse, null, true) is returning 1 when called before this and 0 when called here
            // Related to defect 10402
            setActiveApplicationUsingId(changes.ApplicationId);
            // This needs to be called again to ùpdate values after the application type has been setup above. The first call updates getAvailableVehicleApplicationTypes
            generateDataForActiveLegislationsAndSetMergedPermissibles();
            if (getActiveOffer().getRig().getVehicle().getPayloadHolder().getPayloadObjects().length > 0 && getActiveOffer().getRig().getVehicle().getPayloadHolder().getSimplePayloadObject() !== null && getActiveOffer().getRig().getVehicle().getPayloadHolder().getSimplePayloadObject().getSpecifyMassOverride() === false) {
                rigOps.maximisePayloads();
            }
            calculateFromCenterForAllItemsOnAllChassisObjects();
            setupAllTrailersEquipmentAxlesPayloadsEtcAOGs();
            addAxlesObservables(getConfiguration().getAxles(), config.CHASSIS_OBJECT_TYPES.VEHICLE);
            addAxleGroupsObservables(getConfiguration().getAxleGroups(), config.CHASSIS_OBJECT_TYPES.VEHICLE);
            addAxleRearTotalObjectObservables(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_REAR_TOTAL);
            addFuelTankObservables(getConfiguration().getFuelTanks());

            //setupSettingsObservables();
            setupOfferSettingsObservables();
            setupReportOptionObservables();
            if (getActiveOffer().getDataAvailability().ActivePerformance && getActiveOffer().getPerformance() !== undefined && globals.user.hasPermission(config.PERMISSIONS.PERFORMANCE.Code)) {
                setupPerformanceObservables();
                setupPerformanceFormattedComputeds();
            }



            //setActiveApplicationUsingId(changes.ApplicationId);
            setupVehicleObservables();

            if (pre20201Calc) {
                var tempVehicleHitch = getActiveOffer().getRig().getVehicle().getAccessoryHolder().getHitch();
                if (tempVehicleHitch !== null) {
                    tempVehicleHitch.setOffset(tempVehicleHitch.getOffset() - getActiveOffer().getRig().getVehicle().getWheelbaseTheoreticalEndToRearMostDrivenAxle());
                }
            }

            handleExistingOfferGAZeroIssue13386();

            if (isCostingAvailableForOffer() === true) {
                //getActiveOffer().getCosting().setFinanceDetailCostPerMonth(getFinanceCostPerMonth());
                //getOriginalOffer().getCosting().setFinanceDetailCostPerMonth(getFinanceCostPerMonth());

                //getActiveOffer().getCosting().setFinanceCostPerMonth(globals.getFinanceCostPerMonth(getActiveOffer().getCosting().getFinanceTotalPrice(), getActiveOffer().getCosting().getResidualRate(), getActiveOffer().getCosting().getInterestRate(), getActiveOffer().getCosting().getRepaymentTerm()));
                //getOriginalOffer().getCosting().setFinanceCostPerMonth(globals.getFinanceCostPerMonth(getActiveOffer().getCosting().getFinanceTotalPrice(), getActiveOffer().getCosting().getResidualRate(), getActiveOffer().getCosting().getInterestRate(), getActiveOffer().getCosting().getRepaymentTerm()));
                //updateFinanceCostPerMonthValues();
                updateCostingItems(null, true, true);
                setupCostingObservables();
                setupCostingFormattedComputeds();
            }
            if (getActiveOffer().getRig().getVehicle().getWheelbaseOverride() === true) {
                var specifiedWheelbaseAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.WHEELBASESPECIFIED);
                var calculatedWheelbaseSpecified = rigOps.getWheelbaseSpecified();
                var specifiedWheelbaseVal = specifiedWheelbaseAOG.value.value;
                if (calculatedWheelbaseSpecified !== specifiedWheelbaseVal) {
                    getActiveOffer().getRig().getVehicle().setWheelbaseSpecified(calculatedWheelbaseSpecified);
                    specifiedWheelbaseAOG.value.value = calculatedWheelbaseSpecified;
                }

            }

            updateObservables();
            addWaypointObservables(waypoints.value);
            offerIsOpen.value = true;
            performValidation = true;
            clearLocalStorageItems();
            numLinkableMissingValues = 0;
            numLinkableTurningCircleMissingValues = 0;

            dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.OPENED_OFFER, getActiveOffer().getIntercomMetadata('EXISTING', otherOfferDetails.menuItemIntercomObject)));

            offerOpen = true;
            setupAllowedAccessoryTypes();
            setupAllowedTrailerTypesTrailer1();
            setupAllowedTrailerTypesTrailer2();
            setupBodyMassOptions(bodyMasses.bodyMasses);

            if (Array.isArray(success.offer.WarningCodes) === true && success.offer.WarningCodes.length > 0) {
                getActiveOffer().setDisplayTransSolveWarningsMessage(true);
                getActiveOffer().setTransSolveWarningsArray(success.offer.WarningCodes);
            }

            undoHandler = new UndoHandler(offerManager, numUndoOps, numRedoOps);

            updateRoutesWhenOpeningOffer();
            
            dfd.resolve(success);
        }

        function doLegislationsNeedToBeUpdated(offerLegislation) {

            if (globals.user.getEdition() === config.APP_EDITION.ESSENTIALS) {
                return false;
            }

            var numberOfLegislationsOnCurrentDashboard = globals.user.getDashboardConfiguration().getDashboardLegislations().length;
            if (Array.isArray(offerLegislation)) {
                if (numberOfLegislationsOnCurrentDashboard !== offerLegislation.length) {
                    return true;
                }

                if (offerLegislation.length === 1 && (offerLegislation[0].CountryId === 0 || offerLegislation[0].LegislationId === 0)) {
                    return false;
                }

                var numberOfMatches = 0;
                offerLegislation.forEach(function (leg) {
                    var legCheck = globals.user.getDashboardConfiguration().getDashboardLegislations().find(function (dl) {
                        return leg.CountryId === dl.getCountryId() && leg.LegislationId === dl.getLegislationId();
                    });

                    if (legCheck !== undefined) {
                        numberOfMatches += 1;
                    }
                });
                return numberOfMatches !== numberOfLegislationsOnCurrentDashboard;
            }
            return true;
        }

        function generateDataForActiveLegislationsAndSetMergedPermissibles() {
            //generateDataForActiveLegislations();
            setLegislationLookupObjectsCleared(false);
            clearActiveLegislationQuickLookupObjects();
            //initialiseTurningAbilityVariables();
            generateDataForActiveLegislations();
            var mergedPermissibleValues = getMergedLegislationsCombinationPermissibleVals(getDataForActiveLegislations().activeLegislationsCombinationPermissibleValues);
            rigOps.setCombinationPermissibleVals(mergedPermissibleValues);
        }

        function setupAllTrailersEquipmentAxlesPayloadsEtcValues() {
            //add accessories

            if (changes.Trailers !== null && changes.Trailers !== undefined) {



                var trailerCounter = 1;
                changes.Trailers.forEach(function (trailerAsJSON) {
                    var chassisObjectType;
                    if (trailerCounter === 1) {
                        chassisObjectType = config.CHASSIS_OBJECT_TYPES.TRAILER1;
                        if (changes.Axles.length > 0) {
                            var trailer1Axles = changes.Axles.filter(function (axleAsJSON) {
                                return axleAsJSON.ParentType === config.CHASSIS_OBJECT_TYPES.TRAILER1;
                            });
                            trailerAsJSON.Axles = trailer1Axles;
                        }
                        trailerCounter++;
                    } else {
                        chassisObjectType = config.CHASSIS_OBJECT_TYPES.TRAILER2;
                        if (changes.Axles.length > 0) {
                            var trailer2Axles = changes.Axles.filter(function (axleAsJSON) {
                                return axleAsJSON.ParentType === config.CHASSIS_OBJECT_TYPES.TRAILER2;
                            });
                            trailerAsJSON.Axles = trailer2Axles;
                        }
                    }
                    if(getActiveOffer().getCosting() !== null && getActiveOffer().getCosting() !== undefined && trailerAsJSON.FuelAndAdblueEquipmentFuelCostPerVolumeOverride === false && trailerAsJSON.FuelAndAdblueEquipmentFuelCostPerVolume === 0) {
                        trailerAsJSON.FuelAndAdblueEquipmentFuelCostPerVolume = getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelCostPerVolumeOnDatabase();
                    }
                    let trailerObject = createTrailerObject('EXISTING', trailerAsJSON, chassisObjectType);
                    if(trailerAsJSON.DecompressedGraphicBlob && utils.isValidDecompressedGraphicFile(trailerAsJSON.DecompressedGraphicBlob)) {
                        trailerObject.setDecompressedGraphicBlob(trailerAsJSON.DecompressedGraphicBlob);
                    }
                    if(trailerAsJSON.DecompressedTopViewGraphicBlob && utils.isValidDecompressedGraphicFile(trailerAsJSON.DecompressedTopViewGraphicBlob)) {
                        trailerObject.setDecompressedTopViewGraphicBlob(trailerAsJSON.DecompressedTopViewGraphicBlob);
                    }
                    getActiveOffer().getRig().addChassisObject(trailerObject);
                });
            }

            var vehicleAccessories = extractAccessories(changes.Accessories, config.CHASSIS_OBJECT_TYPES.VEHICLE);
            getActiveOffer().getConfiguration().getAccessoryHolder().addAccessories(vehicleAccessories, getActiveOffer().getConfiguration().getChassisHeightMax(), getActiveOffer().getConfiguration().getWheelbaseTheoreticalEnd(), getActiveOffer().getConfiguration().getWheelbaseTheoreticalEndToRearMostDrivenAxle());
            rigOps.calculateFromAxle1FrontForRelevantAccessories();
            if (getActiveOffer().getConfiguration().getAccessoryHolder().getBullbar() !== null) {
                getActiveOffer().getRig().getVehicle().setBullbarAdded(true);
            }
            if (getActiveOffer().getConfiguration().getAccessoryHolder().getSnowplough() !== null) {
                getActiveOffer().getRig().getVehicle().setSnowploughAdded(true);
            }
            if (getActiveOffer().getConfiguration().getAccessoryHolder().getCrane() !== null) {
                getActiveOffer().getRig().getVehicle().setCraneAdded(true);
            }
            if (getActiveOffer().getConfiguration().getAccessoryHolder().getFifthWheel() !== null || getActiveOffer().getConfiguration().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
                getActiveOffer().getRig().getVehicle().setFifthWheelAdded(true);
            }

// if (activeRig.getVehicle().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {

            var trailer1 = getActiveOffer().getRig().getTrailer1();
            var trailer2 = getActiveOffer().getRig().getTrailer2();

            if (trailer1 !== null) {
                var trailer1Accessories = extractAccessories(changes.Accessories, config.CHASSIS_OBJECT_TYPES.TRAILER1);
                trailer1.getAccessoryHolder().addAccessories(trailer1Accessories, trailer1.getChassisHeight());


                if (trailer2 !== null) {
                    var trailer2Accessories = extractAccessories(changes.Accessories, config.CHASSIS_OBJECT_TYPES.TRAILER2);
                    trailer2.getAccessoryHolder().addAccessories(trailer2Accessories, trailer2.getChassisHeight());

                }
            }

            var vehiclePayloads = extractPayloadsForChassis(changes.Payloads, config.CHASSIS_OBJECT_TYPES.VEHICLE);


            if (vehiclePayloads.length > 0 && (trailer1 === null || (trailer1 !== null && (trailer1.getType() === config.TRAILER_TYPES.DRAWBAR || trailer1.getType() === config.TRAILER_TYPES.PUP || ((trailer1.getType() === config.TRAILER_TYPES.SEMI || trailer1.getType() === config.TRAILER_TYPES.INTERLINK) && (getActiveOffer().getConfiguration().getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE || getActiveOffer().getConfiguration().getAccessoryHolder().getFifthWheel() !== null || getActiveOffer().getConfiguration().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES)))))) {
                                //getConfiguration().getPayloadHolder()
                getConfiguration().getPayloadHolder().addPayloads(vehiclePayloads);
                if (changes.StructureVersion === 2) {
                    getConfiguration().getPayloadHolder().getDetailedPayloadItems().forEach(function (payloadItem) {
                        payloadItem.setMass(payloadItem.getPayload() * payloadItem.getDensity());
                    });    
                }
                var tempPayloadObject = getConfiguration().getPayloadHolder().getSimplePayloadObject();
                if (tempPayloadObject.getSpecifyMassOverride() === false) {
                    //tempPayloadObject.setMass(rigOps.getMaximisedLoad());
                    clearActiveLegislationQuickLookupObjects();
                    rigOps.maximisePayloads();
                }
                //addPayloadObservables([tempPayloadObject], config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.PAYLOAD);
                ////createPayloadObservables(tempPayloadObject, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.PAYLOAD);

                //getConfiguration().getPayloadHolder().getDetailedPayloadItems().forEach(function (payloadItem) {
                //    createPayloadObservables(payloadItem, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.PAYLOAD);
                //});
            }
            
            if (originalTrailers !== null) {
                addOriginalTrailersToOriginalRig(originalTrailers);
            }

            if (originalAccessories !== null) {
                addOriginalAccessoriesToOriginalRig(originalAccessories);
            }

            if (getActiveOffer().getRig().getTrailer1() !== null) {
                setupOriginalAxleInternalIdsOnTrailers();
                //rigOps.updateBridgeFormulaPermissiblesOnAxle(config.CHASSIS_OBJECT_TYPES.TRAILER1);
                setupPayloadOnChassis(changes, config.CHASSIS_OBJECT_TYPES.TRAILER1);
                //addAxlesObservables(getActiveOffer().getRig().getTrailer1().getAxlesHolder().getAxles(), config.CHASSIS_OBJECT_TYPES.TRAILER1);
                //addAxleGroupsObservables(getActiveOffer().getRig().getTrailer1().getAxlesHolder().getAxleGroups(), config.CHASSIS_OBJECT_TYPES.TRAILER1);
                //createTrailerObjectObservables(getActiveOffer().getRig().getTrailer1(), config.CHASSIS_OBJECT_TYPES.TRAILER1);
                if (getActiveOffer().getRig().getTrailer2() !== null) {
                    //rigOps.updateBridgeFormulaPermissiblesOnAxle(config.CHASSIS_OBJECT_TYPES.TRAILER2);
                    setupPayloadOnChassis(changes, config.CHASSIS_OBJECT_TYPES.TRAILER2);
                    //addAxlesObservables(getActiveOffer().getRig().getTrailer2().getAxlesHolder().getAxles(), config.CHASSIS_OBJECT_TYPES.TRAILER2);
                    //addAxleGroupsObservables(getActiveOffer().getRig().getTrailer2().getAxlesHolder().getAxleGroups(), config.CHASSIS_OBJECT_TYPES.TRAILER2);
                    //createTrailerObjectObservables(getActiveOffer().getRig().getTrailer2(), config.CHASSIS_OBJECT_TYPES.TRAILER2);
                }

            }

            maximisePayloadsIfPossible();
            getActiveOffer().getRig().getChassisObjects().forEach(function (chassisObject) {
                setupPayloadObservablesForChassis(chassisObject);
            });

            if (originalPayloads !== null) {
                addOriginalPayloadsToOriginalRig(originalPayloads);
            }


            handleTailiftsOnCalculationsSavedPriortoTailliftChanges(getActiveOffer().getConfiguration().getAccessoryHolder(), getOriginalOffer().getRig().getVehicle().getAccessoryHolder());
            if (trailer1 !== null) {
                handleTailiftsOnCalculationsSavedPriortoTailliftChanges(trailer1.getAccessoryHolder(), getOriginalOffer().getRig().getTrailer1().getAccessoryHolder());
            }


            if (getActiveOffer().getRig().getVehicle().getAxlesHolder().allFrontAxleWeightsAreZero()) {
                if (getActiveOffer().getRig().getVehicle().getTareFrontIgnoreOverride() > 0) {
                    //rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEWEIGHT, config.AXLE_LOCATIONS.FRONT, getActiveOffer().getRig().getVehicle().getTareFrontIgnoreOverride());
                    rigOps.distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.FRONT, getActiveOffer().getRig().getVehicle().getTareFrontIgnoreOverride());
                }
            }
            if (getActiveOffer().getRig().getVehicle().getAxlesHolder().allRearAxleWeightsAreZero()) {
                if (getActiveOffer().getRig().getVehicle().getTareRearIgnoreOverride() > 0) {
                    //rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEWEIGHT, config.AXLE_LOCATIONS.REAR, getActiveOffer().getRig().getVehicle().getTareRearIgnoreOverride());
                    rigOps.distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.REAR, getActiveOffer().getRig().getVehicle().getTareRearIgnoreOverride());
                }
            }

            //getActiveOffer().getConfiguration().getAccessoryHolder().getAccessories().forEach(function (accessory) {
            //    getOriginalOffer().getConfiguration().getAccessoryHolder().add(accessory.clone(true));
            //});


            //if (getActiveOffer().getConfiguration().getBody() !== null) {
            //    var tempPayloadObject = setupDefaultPayloadForBody(getConfiguration().getBody());
            //    tempPayloadObject.setMass(getMaximisedLoad());
            //}
            //if (getActiveOffer().getConfiguration().getAccessories().length > 0) {
            //    getActiveOffer().getConfiguration().getAccessories().forEach(function (accessory) {
            //        createAccessoryObjectWithObservables(accessory, config.CHASSIS_OBJECT_TYPES.VEHICLE);
            //    });

            //}

            //if (trailer1 !== null && trailer1.getAccessoryHolder().getAccessories().length > 0) {
            //    trailer1.getAccessoryHolder().getAccessories().forEach(function (accessory) {
            //        createAccessoryObjectWithObservables(accessory, config.CHASSIS_OBJECT_TYPES.TRAILER1);
            //    });
            //}

            //if (trailer2 !== null && trailer2.getAccessoryHolder().getAccessories().length > 0) {
            //    trailer2.getAccessoryHolder().getAccessories().forEach(function (accessory) {
            //        createAccessoryObjectWithObservables(accessory, config.CHASSIS_OBJECT_TYPES.TRAILER2);
            //    });
            //}

            //if (getActiveOffer().getConfiguration().getBody() !== null) {
            //    if (getActiveOffer().getConfiguration().getBody().getType() === config.BODY_TYPES.TIMBER) {
            //        addBodyUprightsObservables(getActiveOffer().getConfiguration().getBody().getBodyUprightHolder().getBodyUprights(), config.CHASSIS_OBJECT_TYPES.VEHICLE);
            //    }
            //}

            //if (trailer1 !== null && trailer1.getAccessoryHolder().getBody() !== null && trailer1.getAccessoryHolder().getBody().getType() === config.BODY_TYPES.TIMBER) {
            //    addBodyUprightsObservables(trailer1.getAccessoryHolder().getBody().getBodyUprightHolder().getBodyUprights(), config.CHASSIS_OBJECT_TYPES.TRAILER1);
            //}

            //if (trailer2 !== null && trailer2.getAccessoryHolder().getBody() !== null && trailer2.getAccessoryHolder().getBody().getType() === config.BODY_TYPES.TIMBER) {
            //    addBodyUprightsObservables(trailer2.getAccessoryHolder().getBody().getBodyUprightHolder().getBodyUprights(), config.CHASSIS_OBJECT_TYPES.TRAILER2);
            //}

            if (bodyStubs.bodies.Bodies.length > 0) {
                availableBodies = [];
                populateAvailableBodiesArray(bodyStubs);
            }

            if (accessoryStubs.accessories.Accessories.length > 0) {
                availableAccessories = [];
                populateAvailableAccessoriesArray(accessoryStubs);
            }

            if (trailerStubs.trailers.Trailers.length > 0) {
                availableTrailers = [];
                populateAvailableTrailersArray(trailerStubs);
            }

            if (payloadStubs.payloads.Payloads.length > 0) {
                availablePayloads = [];
                populateAvailablePayloadsArray(payloadStubs);
            }

            //var vehiclePayloads = changes.Payloads.filter(function (payloadAsJSON) {
            //    return payloadAsJSON.ParentType === config.CHASSIS_OBJECT_TYPES.VEHICLE;
            //});
            
        }

        

        function calculateFromCenterForAllItemsOnAllChassisObjects() {
            getActiveOffer().getRig().getChassisObjects().forEach(function (chassisObject) {
                rigOps.updateFromCentreOfChassisForAllChassisItems(chassisObject.getChassisObjectName());
            });
            
        }

        function setupAllTrailersEquipmentAxlesPayloadsEtcAOGs() {
            
            var trailer1 = getActiveOffer().getRig().getTrailer1();
            var trailer2 = getActiveOffer().getRig().getTrailer2();

            var vehiclePayloads = extractPayloadsForChassis(changes.Payloads, config.CHASSIS_OBJECT_TYPES.VEHICLE);


            if (vehiclePayloads.length > 0 && (trailer1 === null || (trailer1 !== null && (trailer1.getType() === config.TRAILER_TYPES.DRAWBAR || trailer1.getType() === config.TRAILER_TYPES.PUP || ((trailer1.getType() === config.TRAILER_TYPES.SEMI || trailer1.getType() === config.TRAILER_TYPES.INTERLINK) && (getActiveOffer().getConfiguration().getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE || getActiveOffer().getConfiguration().getAccessoryHolder().getFifthWheel() !== null || getActiveOffer().getConfiguration().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES)))))) {
                
                addPayloadObservables([getConfiguration().getPayloadHolder().getSimplePayloadObject()], config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.PAYLOAD);

                getConfiguration().getPayloadHolder().getDetailedPayloadItems().forEach(function (payloadItem) {
                    createPayloadObservables(payloadItem, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.PAYLOAD);
                });
            }

            

            if (getActiveOffer().getRig().getTrailer1() !== null) {
                addAxlesObservables(getActiveOffer().getRig().getTrailer1().getAxlesHolder().getAxles(), config.CHASSIS_OBJECT_TYPES.TRAILER1);
                addAxleGroupsObservables(getActiveOffer().getRig().getTrailer1().getAxlesHolder().getAxleGroups(), config.CHASSIS_OBJECT_TYPES.TRAILER1);
                createTrailerObjectObservables(getActiveOffer().getRig().getTrailer1(), config.CHASSIS_OBJECT_TYPES.TRAILER1);
                if (getActiveOffer().getRig().getTrailer2() !== null) {
                    addAxlesObservables(getActiveOffer().getRig().getTrailer2().getAxlesHolder().getAxles(), config.CHASSIS_OBJECT_TYPES.TRAILER2);
                    addAxleGroupsObservables(getActiveOffer().getRig().getTrailer2().getAxlesHolder().getAxleGroups(), config.CHASSIS_OBJECT_TYPES.TRAILER2);
                    createTrailerObjectObservables(getActiveOffer().getRig().getTrailer2(), config.CHASSIS_OBJECT_TYPES.TRAILER2);
                }
            }


            if (getActiveOffer().getConfiguration().getAccessories().length > 0) {
                getActiveOffer().getConfiguration().getAccessories().forEach(function (accessory) {
                    createAccessoryObjectWithObservables(accessory, config.CHASSIS_OBJECT_TYPES.VEHICLE);
                });

            }

            if (trailer1 !== null && trailer1.getAccessoryHolder().getAccessories().length > 0) {
                trailer1.getAccessoryHolder().getAccessories().forEach(function (accessory) {
                    createAccessoryObjectWithObservables(accessory, config.CHASSIS_OBJECT_TYPES.TRAILER1);
                });
            }

            if (trailer2 !== null && trailer2.getAccessoryHolder().getAccessories().length > 0) {
                trailer2.getAccessoryHolder().getAccessories().forEach(function (accessory) {
                    createAccessoryObjectWithObservables(accessory, config.CHASSIS_OBJECT_TYPES.TRAILER2);
                });
            }

            if (getActiveOffer().getConfiguration().getBody() !== null) {
                if (getActiveOffer().getConfiguration().getBody().getType() === config.BODY_TYPES.TIMBER) {
                    addBodyUprightsObservables(getActiveOffer().getConfiguration().getBody().getBodyUprightHolder().getBodyUprights(), config.CHASSIS_OBJECT_TYPES.VEHICLE);
                }
            }

            if (trailer1 !== null && trailer1.getAccessoryHolder().getBody() !== null && trailer1.getAccessoryHolder().getBody().getType() === config.BODY_TYPES.TIMBER) {
                addBodyUprightsObservables(trailer1.getAccessoryHolder().getBody().getBodyUprightHolder().getBodyUprights(), config.CHASSIS_OBJECT_TYPES.TRAILER1);
            }

            if (trailer2 !== null && trailer2.getAccessoryHolder().getBody() !== null && trailer2.getAccessoryHolder().getBody().getType() === config.BODY_TYPES.TIMBER) {
                addBodyUprightsObservables(trailer2.getAccessoryHolder().getBody().getBodyUprightHolder().getBodyUprights(), config.CHASSIS_OBJECT_TYPES.TRAILER2);
            }

        }

        function setupBodyMassOptions(bodyMassArray) {
            if (getActiveOffer().getRig().getVehicle().getAccessoryHolder().getBody() !== null) {
                getActiveOffer().getRig().getVehicle().getAccessoryHolder().getBody().setAvailableBodyMasses(getFilteredBodyMasses(bodyMassArray, getActiveOffer().getRig().getVehicle().getAccessoryHolder().getBody().getType()));
            }
            if (getActiveOffer().getRig().getTrailer1() !== null && getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getBody() !== null) {
                getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getBody().setAvailableBodyMasses(getFilteredBodyMasses(bodyMassArray, getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getBody().getType()));
            }
            if (getActiveOffer().getRig().getTrailer2() !== null && getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getBody() !== null) {
                getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getBody().setAvailableBodyMasses(getFilteredBodyMasses(bodyMassArray, getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getBody().getType()));
            }
        }

        //function getFinanceCostPerMonth() {
        //    var financeCostPerMonth = 0,
        //        costing = getActiveOffer().getCosting();

        //    function calculateFinanceCostPerMonthForAccessories(accessory) {
        //        var discountValue = 0,
        //            netPrice = accessory.getCostingListPrice();
        //        if (accessory.getCostingDiscount() > 0) {
        //            discountValue = (accessory.getCostingListPrice() / 100) * accessory.getCostingDiscount();
        //            netPrice = accessory.getCostingListPrice() - discountValue;
        //        }
        //        financeCostPerMonth += globals.getPricePerMonth(accessory.getCostingListPrice(),
        //            netPrice,
        //            accessory.getCostingPeriod(),
        //            accessory.getCostingInterestRate(),
        //            accessory.getCostingResidualRate(),
        //            accessory.getCostingResidualRateType());
        //    }

        //    //if (costing.getFinanceTotalPriceOverride() === true) {
        //    //    // Costing screen details
        //    //    financeCostPerMonth = globals.getFinanceCostPerMonth(costing.getFinanceTotalPrice(), costing.getResidualRate(), costing.getInterestRate(), costing.getRepaymentTerm());
        //    //} else {

        //    // Finance Detail modal details
        //    // Vehicle
        //    //financeCostPerMonth += globals.getFinanceCostPerMonth(costing.getFinanceTotalPrice(), costing.getResidualRate(), costing.getInterestRate(), costing.getRepaymentTerm());
        //    var vehicleDiscountValue = 0,
        //        vehicleNetPrice = costing.getVehiclePrice();
        //    if (costing.getVehicleDiscount() > 0) {
        //        vehicleDiscountValue = (costing.getVehiclePrice() / 100) * costing.getVehicleDiscount();
        //        vehicleNetPrice = costing.getVehiclePrice() - vehicleDiscountValue;
        //    }
        //    //financeCostPerMonth += globals.getFinanceCostPerMonth(costing.getFinanceTotalPrice(), costing.getResidualRate(), costing.getInterestRate(), costing.getRepaymentTerm());
        //    financeCostPerMonth += globals.getPricePerMonth(costing.getVehiclePrice(),
        //        vehicleNetPrice,
        //        costing.getVehiclePeriod(),
        //        costing.getVehicleInterestRate(),
        //        costing.getVehicleResidualRate(),
        //        costing.getVehicleResidualRateType());
        //    // Accessories
        //    //getActiveOffer().getRig().getVehicle().getAccessoryHolder().getAccessories().forEach(function(accessory) {
        //    //    var discountValue = 0,
        //    //        netPrice = accessory.getCostingListPrice();
        //    //    if (accessory.getDiscount() > 0) {
        //    //        discountValue = (accessory.getCostingListPrice() / 100) * accessory.getCostingDiscount();
        //    //        netPrice = accessory.getCostingListPrice() - discountValue;
        //    //    }
        //    //    financeCostPerMonth += globals.getPricePerMonth(accessory.getCostingListPrice(), 
        //    //        netPrice, 
        //    //        accessory.getCostingPeriod(), 
        //    //        accessory.getCostingInterestRate(),
        //    //        accessory.getCostingResidualRate(),
        //    //        accessory.getCostingInterestRateType());
        //    //});
        //    getActiveOffer().getRig().getVehicle().getAccessoryHolder().getAccessories().forEach(calculateFinanceCostPerMonthForAccessories);

        //    // Trailer 1
        //    if (getActiveOffer().getRig().getTrailer1() !== null) {
        //        calculateFinanceCostPerMonthForAccessories(getActiveOffer().getRig().getTrailer1());
        //        // Accessories
        //        getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getAccessories().forEach(calculateFinanceCostPerMonthForAccessories);
        //    }


        //    // Trailer 2
        //    if (getActiveOffer().getRig().getTrailer2() !== null) {
        //        calculateFinanceCostPerMonthForAccessories(getActiveOffer().getRig().getTrailer2());
        //        // Accessories
        //        getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getAccessories().forEach(calculateFinanceCostPerMonthForAccessories);
        //    }
        //    //}

        //    return financeCostPerMonth;
        //}
    }
}

function openDeepLinkedOfferShare(sharerUserId, offerId, customerId, shareeUserId) {
    
    if (sharerUserId === shareeUserId) {
        dataManager.getSavedOfferStubs()
        .then(function (result) {
            var savedOfferStub = result.Offers.filter(function (savedOfferStub) {
                return savedOfferStub.Id === offerId;
            })[0];

            if (savedOfferStub !== undefined && savedOfferStub !== null) {

                if (globals.isOnline.value === true) {
                    dataManager.logAppUsage(config.USAGE_TYPE.ONLINE);
                } else {
                    dataManager.logAppUsage(config.USAGE_TYPE.OFFLINE);
                }

                //make sure the token is still valid and extend if necessary
                if (!dataManager.securityTokenIsValid()) {
                    shellLogout();
                    return;
                }

                globals.showSpinner();
                var metadataObject = {};
                metadataObject[config.INTERCOM_METADATA_ITEM.ORIGIN] = config.SELECTION_MENU_ITEM_TYPE.SHARED;
                metadataObject[config.INTERCOM_METADATA_ITEM.ORIGIN_SOURCE] = config.CONSIDERED_REQUEST_SOURCES.SHARE_LINK;

                var isSharedOffer = false;
                offerManager.createRefreshSWLabelsObservable();
                offerManager.openExistingOfferWrapper(offerId, sharerUserId, savedOfferStub, isSharedOffer, metadataObject)
                    .then(function (success) {

                        if (success.offer.Result.ReturnCode > 0) {
                            globals.checkMultiSelectArrays(getNewAndSavedOffersForVehicleSelectionInMenu());
                            // shellManageHiddenButtons(success.offer.Offer.DataAvailability);
                            if(shellEvaulateShowSaveOption) {
                                shellEvaulateShowSaveOption();
                            }
                            shellUpdateApplicationSelectionAndVehicleTypeSelection();
                            globals.hideSpinner();
                            globals.navigatedInFromSelectionOrOffers.value = true;

                            offerManager.routeToNextModule();
                        } else {
                            globals.user.updateUser({
                                accessCode: ''
                            });
                            globals.hideSpinner();
                            
                            globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571'))).addCallback(function () {
                                if (globals.user.getUserType() === config.USER_TYPES.FULL_USER_OK || globals.user.getUserType() === config.USER_TYPES.TRIAL_USER_OK) {
                                    // if a full or trial user makes it this far then they have been properly authenticated so we can go to selection instead of logging them out
                                    router.push(ROUTES.SELECTION.name);
                                } else {
                                    shellLogout('mode=' + config.GET_STARTED_MODES.SIGN_UP);
                                }
                            });
                        }
                    })
                    .fail(function (error) {
                        globals.user.updateUser({
                            accessCode: ''
                        });
                        showShellSpinner(false);
                        globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')))
                            .addCallback(function (dialogResult) {
                                if (globals.user.getUserType() === config.USER_TYPES.FULL_USER_OK || globals.user.getUserType() === config.USER_TYPES.TRIAL_USER_OK) {
                                    // if a full or trial user makes it this far then they have been properly authenticated so we can go to selection instead of logging them out
                                    router.push(ROUTES.SELECTION.name);
                                } else {
                                    shellLogout('mode=' + config.GET_STARTED_MODES.SIGN_UP);
                                }
                             });
                    });

            }


        });
    } else {
        dataManager.getSharedOfferStubs(sharerUserId, shareeUserId)
        .then(function (result) {
            var sharedOfferStub = result.sharedOfferStubs.Offers.filter(function (sharedOfferStub) {
                return sharedOfferStub.Id === offerId;
            })[0];

            if (sharedOfferStub !== undefined && sharedOfferStub !== null) {

                if (globals.isOnline.value === true) {
                    dataManager.logAppUsage(config.USAGE_TYPE.ONLINE);
                } else {
                    dataManager.logAppUsage(config.USAGE_TYPE.OFFLINE);
                }

                //make sure the token is still valid and extend if necessary
                if (!dataManager.securityTokenIsValid()) {
                    shellLogout();
                    return;
                }

                globals.showSpinner();
                var metadataObject = {};
                metadataObject[config.INTERCOM_METADATA_ITEM.ORIGIN] = config.SELECTION_MENU_ITEM_TYPE.SHARED;
                metadataObject[config.INTERCOM_METADATA_ITEM.ORIGIN_SOURCE] = config.CONSIDERED_REQUEST_SOURCES.SHARE_LINK;

                var isSharedOffer = true;
                offerManager.createRefreshSWLabelsObservable();
                offerManager.openExistingOfferWrapper(offerId, sharerUserId, sharedOfferStub, isSharedOffer, metadataObject, 'deep-link')
                    .then(function (success) {

                        if (success.offer.Result.ReturnCode > 0) {
                            globals.checkMultiSelectArrays(getNewAndSavedOffersForVehicleSelectionInMenu());
                            // shellManageHiddenButtons(success.offer.Offer.DataAvailability);
                            if(shellEvaulateShowSaveOption) {
                                shellEvaulateShowSaveOption();
                            }
                            shellUpdateApplicationSelectionAndVehicleTypeSelection();
                            globals.hideSpinner();
                            globals.navigatedInFromSelectionOrOffers.value = true;

                            offerManager.routeToNextModule();
                        } else {
                            globals.user.updateUser({
                                accessCode: ''
                            });
                            globals.hideSpinner();
                            
                            globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571'))).addCallback(function () {
                                if (globals.user.getUserType() === config.USER_TYPES.FULL_USER_OK || globals.user.getUserType() === config.USER_TYPES.TRIAL_USER_OK) {
                                    // if a full or trial user makes it this far then they have been properly authenticated so we can go to selection instead of logging them out
                                    router.push(ROUTES.SELECTION.name);
                                } else {
                                    shellLogout('mode=' + config.GET_STARTED_MODES.SIGN_UP);
                                }
                            });
                        }
                    })
                    .fail(function (error) {
                        globals.user.updateUser({
                            accessCode: ''
                        });
                        globals.hideSpinner();
                        
                        globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571'))).addCallback(function () {
                            if (globals.user.getUserType() === config.USER_TYPES.FULL_USER_OK || globals.user.getUserType() === config.USER_TYPES.TRIAL_USER_OK) {
                                // if a full or trial user makes it this far then they have been properly authenticated so we can go to selection instead of logging them out
                                router.push(ROUTES.SELECTION.name);
                            } else {
                                shellLogout('mode=' + config.GET_STARTED_MODES.SIGN_UP);
                            }
                        });
                    });

            }


        });
    }
    
}

function checkShareStatusAndOpen(sharerUserId, offerId, customerId, shareeUserId) {
    globals.user.updateUser({
        accessCode: ''
    });
    if (sharerUserId === shareeUserId) {
        offerManager.openDeepLinkedOfferShare(sharerUserId, offerId, customerId, shareeUserId);
    } else {
        dataManager.getOfferShareStatus(sharerUserId, offerId, customerId, shareeUserId)
        .then(function (result) {
            if (result.Result.ReturnCode === 1) {
                if (result.ShareStatusDetail.ShareStatus !== null) {
                    offerManager.openDeepLinkedOfferShare(sharerUserId, offerId, customerId, shareeUserId);
                } else {
                    
                    globals.hideSpinner();
                    // if full user or trial user then navigate to selection and show message saying the share no longer exists, otherwise navigate to sign up and show message that the share no longer exists
                   
                    globals.showDialog(new CustomMessageBox(config.getMessageText('1637', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1637'))).addCallback(function () {
                        if ((globals.user.getUserType() === config.USER_TYPES.FULL_USER_OK || globals.user.getUserType() === config.USER_TYPES.TRIAL_USER_OK) && globals.user.getFirstName() !== config.shareeTempName) {
                            // if a full or trial user makes it this far then they have been properly authenticated so we can go to selection instead of loggin them out
                            router.push(ROUTES.SELECTION.name);
                        } else {
                            shellLogout('mode=' + config.GET_STARTED_MODES.SIGN_UP);
                        }
                    });
                }
            }
        });
    }
    
}

function handleDeepLinkAccessCode(accessCode) {
    var decodedAccessCode = globals.decodeAccessCode(accessCode);
    var accessCodeParts = decodedAccessCode.split('_');
    //ByVal userId As Integer, ByVal offerId As Integer, ByVal customerId As Integer, ByVal shareeUserId As Integer
    // HTML5AppAccessCodeTypeShare & "_" & shareDetails.OfferId & "_" & shareDetails.CustomerId & "_" & sharerUserId & "_" & shareeUserId

    var codeType = accessCodeParts[0];


    if (codeType === 'SHARE') { // User Story 17565
        globals.user.updateUser({
            accessCode: accessCode
        });
        globals.showSpinner();
        var offerId = Number(accessCodeParts[1]);
        var sharerUserId = Number(accessCodeParts[3]);
        var customerId = Number(accessCodeParts[2]);
        var shareeUserId = Number(accessCodeParts[4]);
        var shareeEmailAddress = accessCodeParts[5];
        
        var allowDistributorToOpenLink = false;
        if (accessCodeParts.length > 6 && accessCodeParts[6] === config.allowOpenOfferByDistributorFlagString) {
            allowDistributorToOpenLink = true;
        }
        
        if (globals.user.getEmailAddress() === shareeEmailAddress || (globals.user.getEmailAddress() !== shareeEmailAddress && globals.user.getIsMemberOfDistributorCompany() === true && allowDistributorToOpenLink === true && shareeEmailAddress === config.SUPPORT_EMAIL_ADDRESSES.TRUCKSCIENCE)) {
            if (dataManager.securityTokenIsValid(true) === true) {
                // open the shared offer
                checkShareStatusAndOpen(sharerUserId, offerId, customerId, shareeUserId);
            } else {
                // navigate to login with info about where to go after login
                navigateToLoginWithNextStepFlag(accessCode, config.USER_TYPES.FULL);
            }
        } else {
            globals.showSpinner();
            globals.preventHideSpinner = true;
            // logout, authenticate. if user is full_user_ok then navigate to login with info about where to go next, otherwise check status and goto share offer with flag to bring up nearly there dialog if user type pending or else navigate to login with info about where to go after if existing user
            dataManager.logout();


            //userNameOrEmailString, passwordString, machineCodeFromMemory, cdgId, true, isFromSignUp, authenticationMethod
            //username, password, machineCode, cdg, async, isFromSignUp, authenticationMethod
            var machineCodeFromMemory = '';//globals.user.getMachineCode();

            var cdgId = globals.user.getCdg();
            var byPassStandardLoginSetup = true;
            dataManager.login(shareeEmailAddress, 'placeholder', machineCodeFromMemory, cdgId, true, false, config.AUTHENTICATION_METHOD.SHARE, byPassStandardLoginSetup)
                .then(function (loginData) {
                    globals.dmLoginDfd = null;
                    if(loginData.result.ReturnCode > 0) {
                        if (globals.user.getUserType() === config.USER_TYPES.FULL_USER_OK) {
                            //navigate to login with info about where to go next
                            globals.preventHideSpinner = false;
                            globals.hideSpinner();
                            navigateToLoginWithNextStepFlag(accessCode, config.USER_TYPES.FULL);
                        } else if (globals.user.getUserType() === config.USER_TYPES.TRIAL_USER_OK && globals.user.getFirstName() !== config.shareeTempName) {
                            globals.preventHideSpinner = false;
                            globals.hideSpinner();
                            navigateToLoginWithNextStepFlag(accessCode, config.USER_TYPES.TRIAL);
                        } else {
                            var dataActionsDfd = $.Deferred();
                            globals.showSpinner();
                            globals.preventHideSpinner = true;
                            dataManager.doDataActionsAfterLoginOrReAuth(loginData, false, dataActionsDfd);
                            dataActionsDfd.then(function () {
                                shellDoAppGeneralSetupSteps();
                                checkShareStatusAndOpen(sharerUserId, offerId, customerId, shareeUserId);
                                globals.preventHideSpinner = false;
                                globals.hideSpinner();
                            });
    
    
    
                        }
                    }else if(loginData.result.ReturnCode === -1) {
                        globals.hideSpinner();
                        globals.showDialog(new CustomMessageBox(config.getMessageText('4809', globals.getActiveCultureCodeForTranslations(), [shareeEmailAddress]), config.applicationTitle,  [config.getTranslationText('271')], config.getMessageReference('4809'))).addCallback(function (clickedButtonText) {
                            shellLogout();
                        });
                    }else {
                        globals.hideSpinner();
                        globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571'))).addCallback(function (clickedButtonText) {
                            shellLogout();
                        });
                    }
                    
                })
                .fail(function () {
                    globals.hideSpinner();
                    globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571'))).addCallback(function (clickedButtonText) {
                        shellLogout();
                    });
                });
        }



    } else {
        globals.user.updateUser({
            accessCode: ''
        });
        // bring up app if authenticed and emails match or logout and navigate to login
        if (globals.user.getEmailAddress() === shareeEmailAddress) {
            if (dataManager.securityTokenIsValid(true) === true) {
                // open the app with message saying something went wrong
                globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')))
                    .addCallback(function (dialogResult) {
                        router.push(ROUTES.SELECTION.name);
                    });
            } else {
                // navigate to login
                shellLogout();
            }
        } else {
            // navigate to signup
            shellLogout('mode=' + config.GET_STARTED_MODES.SIGN_UP);
        }
    }
}

function navigateToLoginWithNextStepFlag(accessCode, userType) {
    // shellLogout('accessCode=' + accessCode + '&userType=' + userType);
    bus.emit('logout', { optionalNavRouteParam: 'accessCode=' + accessCode + '&userType=' + userType });
}

function handleExistingOfferGAZeroIssue13386() {
    //This is a hack hotfix to address issue whereby GA Front/Rear values of zero in the DB result in a blank overload cell in the table when axle manufacturer override checked. 
    var tempVehicle = getActiveOffer().getRig().getVehicle();
    if (tempVehicle.getGAFront() === 0) {
        var gaFrontFromAxleGroup = tempVehicle.getAxlesHolder().getLesserOfFrontAxleManufacturerRatings();
        if (gaFrontFromAxleGroup !== 0) {
            tempVehicle.setGAFront(gaFrontFromAxleGroup);
        }
    }
    if (tempVehicle.getGARear() === 0) {
        var gaRearFromAxleGroup = tempVehicle.getAxlesHolder().getLesserOfRearAxleManufacturerRatings();
        if (gaRearFromAxleGroup !== 0) {
            tempVehicle.setGARear(gaRearFromAxleGroup);
        }
    }
}

function getFinanceCostPerMonth() {
    var financeCostPerMonth = 0,
        costing = getActiveOffer().getCosting();

    function calculateFinanceCostPerMonthForAccessories(accessory) {
        var discountValue = 0,
            netPrice = accessory.getCostingListPrice();
        if (accessory.getCostingDiscount() > 0) {
            discountValue = (accessory.getCostingListPrice() / 100) * accessory.getCostingDiscount();
            netPrice = accessory.getCostingListPrice() - discountValue;
        }
        financeCostPerMonth += globals.getPricePerMonth(accessory.getCostingListPrice(),
            netPrice,
            accessory.getCostingPeriod(),
            accessory.getCostingInterestRate(),
            accessory.getCostingResidualRate(),
            accessory.getCostingResidualRateType());
    }

    //if (costing.getFinanceTotalPriceOverride() === true) {
    //    // Costing screen details
    //    financeCostPerMonth = globals.getFinanceCostPerMonth(costing.getFinanceTotalPrice(), costing.getResidualRate(), costing.getInterestRate(), costing.getRepaymentTerm());
    //} else {

    // Finance Detail modal details
    // Vehicle
    //financeCostPerMonth += globals.getFinanceCostPerMonth(costing.getFinanceTotalPrice(), costing.getResidualRate(), costing.getInterestRate(), costing.getRepaymentTerm());
    var vehicleDiscountValue = 0,
        vehicleNetPrice = costing.getVehiclePrice();
    if (costing.getVehicleDiscount() > 0) {
        vehicleDiscountValue = (costing.getVehiclePrice() / 100) * costing.getVehicleDiscount();
        vehicleNetPrice = costing.getVehiclePrice() - vehicleDiscountValue;
    }
    //financeCostPerMonth += globals.getFinanceCostPerMonth(costing.getFinanceTotalPrice(), costing.getResidualRate(), costing.getInterestRate(), costing.getRepaymentTerm());
    financeCostPerMonth += globals.getPricePerMonth(costing.getVehiclePrice(),
        vehicleNetPrice,
        costing.getVehiclePeriod(),
        costing.getVehicleInterestRate(),
        costing.getVehicleResidualRate(),
        costing.getVehicleResidualRateType());
    // Accessories
    //getActiveOffer().getRig().getVehicle().getAccessoryHolder().getAccessories().forEach(function(accessory) {
    //    var discountValue = 0,
    //        netPrice = accessory.getCostingListPrice();
    //    if (accessory.getDiscount() > 0) {
    //        discountValue = (accessory.getCostingListPrice() / 100) * accessory.getCostingDiscount();
    //        netPrice = accessory.getCostingListPrice() - discountValue;
    //    }
    //    financeCostPerMonth += globals.getPricePerMonth(accessory.getCostingListPrice(), 
    //        netPrice, 
    //        accessory.getCostingPeriod(), 
    //        accessory.getCostingInterestRate(),
    //        accessory.getCostingResidualRate(),
    //        accessory.getCostingInterestRateType());
    //});
    getActiveOffer().getRig().getVehicle().getAccessoryHolder().getAccessories().forEach(calculateFinanceCostPerMonthForAccessories);

    // Trailer 1
    if (getActiveOffer().getRig().getTrailer1() !== null) {
        calculateFinanceCostPerMonthForAccessories(getActiveOffer().getRig().getTrailer1());
        // Accessories
        getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getAccessories().forEach(calculateFinanceCostPerMonthForAccessories);
    }


    // Trailer 2
    if (getActiveOffer().getRig().getTrailer2() !== null) {
        calculateFinanceCostPerMonthForAccessories(getActiveOffer().getRig().getTrailer2());
        // Accessories
        getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getAccessories().forEach(calculateFinanceCostPerMonthForAccessories);
    }
    //}

    return financeCostPerMonth;
}

function getFinanceTotalPrice() {
    var financeTotalPrice = 0,
        costing = getActiveOffer().getCosting();

    function calculateFinanceTotalPriceForAccessories(accessory) {
        financeTotalPrice += calculateNetPrice(accessory.getCostingListPrice(), accessory.getCostingDiscount());
    }

    function calculateNetPrice(price, discount) {
        var discountValue = 0,
            netPrice = price;
        if (discount > 0) {
            discountValue = (price / 100) * discount;
            netPrice = price - discountValue;
        }
        return netPrice;
    }

    financeTotalPrice += calculateNetPrice(costing.getVehiclePrice(), costing.getVehicleDiscount());
    getActiveOffer().getRig().getVehicle().getAccessoryHolder().getAccessories().forEach(calculateNetPrice);

    // Trailer 1
    if (getActiveOffer().getRig().getTrailer1() !== null) {
        calculateFinanceTotalPriceForAccessories(getActiveOffer().getRig().getTrailer1());
        // Accessories
        getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getAccessories().forEach(calculateNetPrice);
    }


    // Trailer 2
    if (getActiveOffer().getRig().getTrailer2() !== null) {
        calculateFinanceTotalPriceForAccessories(getActiveOffer().getRig().getTrailer2());
        // Accessories
        getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getAccessories().forEach(calculateNetPrice);
    }

    return financeTotalPrice;
}

function getNewAndSavedOffersForSelectionList(activatingSelectionWizardMenuTabId, folderOrUserId, sharedWithUserId) {



    var newAndSavedOffersForSelectionListDfd = $.Deferred();

    dataManager.getSelectionList()
        .then(function (success) {
            globals.selectionListDfd = null;
            var tempArray = success.selection.Offers.slice(0);
            var returnCode = success.selection.Result.ReturnCode;

            if (globals.isOnline.value && activatingSelectionWizardMenuTabId && activatingSelectionWizardMenuTabId === config.SELECTION_WIZARD_MENU_ITEMS.SAVED_OFFERS) {
                //dataManager.setPreloadOffersRetryCount(0);
                //dataManager.preloadOffers(0)
                //    .then(function (success) {
                //        var savedOffers = [];
                //        ko.utils.arrayForEach(success.data.Customers, function (customer) {
                //            ko.utils.arrayForEach(customer.Offers, function (offer) {
                //                offer.CustomerId = customer.Id;
                //                offer.descriptionDisplay = function () {
                //                    if (globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code)) {
                //                        return offer.Description === undefined || offer.Description === "" ? offer.VehicleDescription : offer.Description;
                //                    } else {
                //                        return offer.Description + ' ' + offer.AdditionalDescription;
                //                    }
                //                }();
                //                offer.ModelCode = offer.VehicleModelCode;
                //                offer.isSavedOffer = true;
                //                offer.getFormattedOfferDate = function () {
                //                    //return Number.isNaN(moment(this.OfferDate).toDate().getDate()) !== true ? moment(this.OfferDate).format("YYYY/MM").toString() : moment(this.OfferDate.substr(0, 10)).format("YYYY/MM").toString();
                //                    //return Globalize.format(new Date(this.OfferDate), Globalize.cultures[globals.user.getCultureCode()].calendar.patterns.d + ' ' + Globalize.cultures[globals.user.getCultureCode()].calendar.patterns.t, globals.user.getCultureCode());
                //                    var timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone !== undefined ? new Intl.DateTimeFormat().resolvedOptions().timeZone : 'UTC',
                //                        options = {
                //                            year: 'numeric', month: 'numeric', day: 'numeric',
                //                            hour: 'numeric', minute: 'numeric', /*second: 'numeric',*/
                //                            hour12: false,
                //                            timeZone: timeZone
                //                        };
                //                    return new Intl.DateTimeFormat(globals.user.getCultureCode(), options).format(new Date(this.OfferDate));
                //                };
                //                savedOffers.push(offer);
                //            });
                //        });

                //        ko.utils.arrayForEach(savedOffers, function (offer) {
                //            tempArray.push(offer);
                //        });
                //        addComputedsAndResovle(tempArray);
                            

                //    })
                //    .fail(function (error) {
                //        newAndSavedOffersForSelectionListDfd.reject(error);
                //    });
                dataManager.getFolderContents(folderOrUserId)
                    .then(function (data) {
                        var savedCalcsTabRowElements = [];
                        data.Folders.forEach(function (folder) {
                            processFolderRowForSelection(folder, savedCalcsTabRowElements);
                        });
                        data.Offers.forEach(function (offer) {
                            processOfferRowForSelection(offer, savedCalcsTabRowElements);
                        });
                            
                        savedCalcsTabRowElements.forEach(function (offer) {
                            tempArray.push(offer);
                        });
                        addComputedsAndResovle(tempArray);
                    }).fail(function (error) {
                        newAndSavedOffersForSelectionListDfd.reject(error);
                    });
                    
            } else if (globals.isOnline.value && activatingSelectionWizardMenuTabId && activatingSelectionWizardMenuTabId === config.SELECTION_WIZARD_MENU_ITEMS.SHARED_OFFERS) {
                var sharedCalcsTabRowElements = [];
                if (folderOrUserId === 0) {
                    dataManager.getSharers()
                    .then(function (sharersResult) {
                            
                        sharersResult.Sharers.forEach(function (sharer) {
                            processVirtualFolderRowForSelection(sharer, sharedCalcsTabRowElements)
                            //sharedOfferStub.descriptionDisplay = sharedOfferStub.AdditionalDescription !== '' ? sharedOfferStub.Description + ' ' + sharedOfferStub.AdditionalDescription : sharedOfferStub.Description;
                            //sharedOfferStub.ModifiedByName = 'Modified By Name';//sharedOfferStub.LastModifiedByUserFirstName + ' ' + sharedOfferStub.LastModifiedByUserLastName;
                            //sharedOfferStub.ModelCode = sharedOfferStub.VehicleModelCode;
                            //sharedOfferStub.rowType = config.ROW_TYPE.OFFER;
                            //sharedOfferStub.isSharedOffer = true;
                            //sharedOfferStub.getFormattedOfferDate = function () {
                            //    //return Number.isNaN(moment(this.OfferDate).toDate().getDate()) !== true ? moment(this.OfferDate).format("YYYY/MM").toString() : moment(this.OfferDate.substr(0, 10)).format("YYYY/MM").toString();
                            //    var timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone !== undefined ? new Intl.DateTimeFormat().resolvedOptions().timeZone : 'UTC',
                            //        options = {
                            //            year: 'numeric', month: 'numeric', day: 'numeric',
                            //            hour: 'numeric', minute: 'numeric', /*second: 'numeric',*/
                            //            hour12: false,
                            //            timeZone: timeZone
                            //        };
                            //    return new Intl.DateTimeFormat(globals.user.getCultureCode(), options).format(new Date(this.OfferDate));
                            //};

                            //tempArray.push(sharedOfferStub);
                        });
                        sharedCalcsTabRowElements.forEach(function (element) {
                            tempArray.push(element);
                        });
                        addComputedsAndResovle(tempArray);
                    })
                    .fail(function (error) {
                        newAndSavedOffersForSelectionListDfd.reject(error);
                    });
                } else {
                    dataManager.getSharedOfferStubs(folderOrUserId, sharedWithUserId)
                    .then(function (sharedOfferStubsResult) {
                        sharedOfferStubsResult.sharedOfferStubs.Offers.forEach(function (sharedOfferStub) {
                            processSharedOfferRowForSelection(sharedOfferStub, sharedCalcsTabRowElements);
                            //sharedOfferStub.descriptionDisplay = sharedOfferStub.AdditionalDescription !== '' ? sharedOfferStub.Description + ' ' + sharedOfferStub.AdditionalDescription : sharedOfferStub.Description;
                            //sharedOfferStub.ModifiedByName = sharedOfferStub.LastModifiedByUserFirstName + ' ' + sharedOfferStub.LastModifiedByUserLastName;
                            //sharedOfferStub.ModelCode = sharedOfferStub.VehicleModelCode;
                            //sharedOfferStub.rowType = config.ROW_TYPE.OFFER;
                            //sharedOfferStub.isSharedOffer = true;
                            //sharedOfferStub.getFormattedOfferDate = function () {
                            //    //return Number.isNaN(moment(this.OfferDate).toDate().getDate()) !== true ? moment(this.OfferDate).format("YYYY/MM").toString() : moment(this.OfferDate.substr(0, 10)).format("YYYY/MM").toString();
                            //    var timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone !== undefined ? new Intl.DateTimeFormat().resolvedOptions().timeZone : 'UTC',
                            //        options = {
                            //            year: 'numeric', month: 'numeric', day: 'numeric',
                            //            hour: 'numeric', minute: 'numeric', /*second: 'numeric',*/
                            //            hour12: false,
                            //            timeZone: timeZone
                            //        };
                            //    return new Intl.DateTimeFormat(globals.user.getCultureCode(), options).format(new Date(this.OfferDate));
                            //};

                            //tempArray.push(sharedOfferStub);
                        });
                        sharedCalcsTabRowElements.forEach(function (element) {
                            tempArray.push(element);
                        });
                        addComputedsAndResovle(tempArray);
                    })
                    .fail(function (error) {
                        newAndSavedOffersForSelectionListDfd.reject(error);
                    });
                }
                    
            } else {
                addComputedsAndResovle(tempArray);
            }
        })
        .fail(function (error) {
            newAndSavedOffersForSelectionListDfd.reject(error);
        });

    function addComputedsAndResovle(tempArray) {
        tempArray = offerManager.addComputedsToVehicles(tempArray);
        //dfd.resolve({});
        newAndSavedOffersForSelectionListDfd.resolve(tempArray);
    }

    return newAndSavedOffersForSelectionListDfd.promise();
}

function getSearchResults(searchTerm, searchType, folderPathNavigationCallback) {
    var searchResultsForSelectionListDfd = $.Deferred();


    dataManager.getSearchResults(searchTerm, searchType)
            .then(function (data) {
                var breakHere = 0;
                var resultObjects = [];
                data.SearchResults.forEach(function (searchResult) {
                    var resultObject = searchResult.Result;
                    resultObject.folderPath = searchResult.FolderPathForDisplay;
                    resultObject.folderPathIds = searchResult.FolderPathAsFolderIds;
                    
                    if (searchResult.Type === config.ROW_TYPE.OFFER) {
                        //resultObject.isSavedOffer = true;
                        //resultObject.rowType = config.ROW_TYPE.OFFER;
                        processOfferRowForSelection(resultObject, resultObjects, searchType);
                    } else {
                        //resultObject.isSavedOffer = false;
                        //resultObject.rowType = config.ROW_TYPE.FOLDER;
                        processFolderRowForSelection(resultObject, resultObjects);
                    }
                    
                    //resultObject.isSharedOffer = false;
                    //resultObjects.push(resultObject);
                });
                addFolderPathNavigation(resultObjects, folderPathNavigationCallback);
                addComputedsAndResovle(resultObjects);
            }).fail(function (error) {
                searchResultsForSelectionListDfd.reject(error);
            });

    

    function addComputedsAndResovle(searchResults) {
        searchResults = addComputedsToVehicles(searchResults);

        searchResultsForSelectionListDfd.resolve(searchResults);
    }

    function addFolderPathNavigation(results, folderPathNavigationCallback) {
        results.forEach(function (result) {
            result.navigateToFolder = function (clickedItem) {
                folderPathNavigationCallback(clickedItem);
            };
        });
        
    }

    return searchResultsForSelectionListDfd.promise();
}

function processFolderRowForSelection(folder, rowArray) {
    folder.rowType = config.ROW_TYPE.FOLDER;
    folder.descriptionDisplay = folder.Name;
    
    rowArray.push(JSON.parse(JSON.stringify(folder)));
}
function processVirtualFolderRowForSelection(sharer, rowArray) {
    sharer.rowType = config.ROW_TYPE.VIRTUAL_FOLDER;
    if (sharer.CompanyName !== '') {
        //sharer.descriptionDisplay = sharer.LastName + ', ' + sharer.FirstName + ' - ' + sharer.CompanyName;
        sharer.descriptionDisplay = sharer.FirstName + ' ' + sharer.LastName + ' - ' + sharer.CompanyName; // User Story 17565 Translations 4948 4949 4950 4951
    } else {
        //sharer.descriptionDisplay = sharer.LastName + ', ' + sharer.FirstName;
        sharer.descriptionDisplay = sharer.FirstName + ' ' + sharer.LastName; // User Story 17565 Translations
    }
    if (sharer.IsSupportOffer === true) {
        sharer.descriptionDisplay += ' (via Support)'; // User Story 17565 Translations
    }
    rowArray.push(JSON.parse(JSON.stringify(sharer)));
}
function processOfferRowForSelection(offer, rowArray, searchType) {
    offer.descriptionDisplay = function () {
        if (globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code)) {
            return offer.Description === undefined || offer.Description === "" ? offer.VehicleDescription : offer.Description;
        } else {
            return offer.Description + ' ' + offer.AdditionalDescription;
        }
    }();
    offer.ModifiedByName = offer.LastModifiedByUserFirstName + ' ' + offer.LastModifiedByUserLastName;
    offer.isSavedOffer = true;
    if (searchType === config.SEARCH_TYPES.SHARED) {
        offer.isSharedOffer = true;
    } else {
        offer.isSharedOffer = false;
    }
    offer.rowType = config.ROW_TYPE.OFFER;
    
    var offerCopy = JSON.parse(JSON.stringify(offer));
    offerCopy.getFormattedOfferDate = function () {
        //return Number.isNaN(moment(this.OfferDate).toDate().getDate()) !== true ? moment(this.OfferDate).format("YYYY/MM").toString() : moment(this.OfferDate.substr(0, 10)).format("YYYY/MM").toString();
        //return Globalize.format(new Date(this.OfferDate), Globalize.cultures[globals.user.getCultureCode()].calendar.patterns.d + ' ' + Globalize.cultures[globals.user.getCultureCode()].calendar.patterns.t, globals.user.getCultureCode());
        var timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone !== undefined ? new Intl.DateTimeFormat().resolvedOptions().timeZone : 'UTC',
            options = {
                year: 'numeric', month: 'numeric', day: 'numeric',
                hour: 'numeric', minute: 'numeric', /*second: 'numeric',*/
                hour12: false,
                timeZone: timeZone
            },
            date = new Date(this.OfferDate),
            dateUtc = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()),
            dateToUse = new Date(dateUtc);
        return new Intl.DateTimeFormat(globals.getActiveCultureCodeForTranslations(), options).format(dateToUse);
        //return new Intl.DateTimeFormat(globals.user.getCultureCode(), options).format(new Date(this.OfferDate));
    };
    rowArray.push(offerCopy);
}

function processSharedOfferRowForSelection(sharedOfferStub, rowArray) {
    sharedOfferStub.descriptionDisplay = sharedOfferStub.AdditionalDescription !== '' ? sharedOfferStub.Description + ' ' + sharedOfferStub.AdditionalDescription : sharedOfferStub.Description;
    sharedOfferStub.ModifiedByName = sharedOfferStub.LastModifiedByUserFirstName + ' ' + sharedOfferStub.LastModifiedByUserLastName;
    sharedOfferStub.ModelCode = sharedOfferStub.VehicleModelCode;
    sharedOfferStub.rowType = config.ROW_TYPE.OFFER;
    sharedOfferStub.isSharedOffer = true;
    var sharedOfferStubCopy = JSON.parse(JSON.stringify(sharedOfferStub));
    sharedOfferStubCopy.getFormattedOfferDate = function () {
        //return Number.isNaN(moment(this.OfferDate).toDate().getDate()) !== true ? moment(this.OfferDate).format("YYYY/MM").toString() : moment(this.OfferDate.substr(0, 10)).format("YYYY/MM").toString();
        var timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone !== undefined ? new Intl.DateTimeFormat().resolvedOptions().timeZone : 'UTC',
            options = {
                year: 'numeric', month: 'numeric', day: 'numeric',
                hour: 'numeric', minute: 'numeric', /*second: 'numeric',*/
                hour12: false,
                timeZone: timeZone
            },
            date = new Date(this.OfferDate),
            dateUtc = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()),
            dateToUse = new Date(dateUtc);
        return new Intl.DateTimeFormat(globals.getActiveCultureCodeForTranslations(), options).format(dateToUse);
        //return new Intl.DateTimeFormat(globals.user.getCultureCode(), options).format(new Date(this.OfferDate));
    };

    rowArray.push(sharedOfferStubCopy);
}

function getNewAndSavedOffersForVehicleSelectionInMenu() {
    return newAndSavedOffersForVehicleSelectionInMenu;
}

function setupPayloadOnChassis(changes, chassisObjectType) {
    var chassisPayloads = extractPayloadsForChassis(changes.Payloads, chassisObjectType);

    if (chassisPayloads.length > 0) {
        var chassisObjectToUse = rigOps.getChassisObjectFromPath(chassisObjectType);
        chassisObjectToUse.getPayloadHolder().addPayloads(chassisPayloads);

        if (changes.StructureVersion === 2) {
            chassisObjectToUse.getPayloadHolder().getDetailedPayloadItems().forEach(function (payloadItem) {
                payloadItem.setMass(payloadItem.getPayload() * payloadItem.getDensity());
            });
        }

        var tempPayloadObject = chassisObjectToUse.getPayloadHolder().getSimplePayloadObject();
        //if (tempPayloadObject.getSpecifyMassOverride() === false) {
        //    //tempPayloadObject.setMass(rigOps.getMaximisedLoad());
        //    clearLegislationQuickLookup();
        //    rigOps.maximisePayloads();
        //}
        tempPayloadObject.setEditLevel(config.ITEM_EDIT_LEVEL_OPTIONS.FULL_EDIT);
        //addPayloadObservables([tempPayloadObject], chassisObjectType + '.' + config.ACCESSORY_TYPES.PAYLOAD);
        ////createPayloadObservables(tempPayloadObject, config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.ACCESSORY_TYPES.PAYLOAD);

        //chassisObjectToUse.getPayloadHolder().getDetailedPayloadItems().forEach(function (payloadItem) {
        //    createPayloadObservables(payloadItem, chassisObjectType + '.' + config.ACCESSORY_TYPES.PAYLOAD);
        //});
    }
}

function setupPayloadObservablesForChassis(chassisObjectToUse) {

    var tempPayloadObject = chassisObjectToUse.getPayloadHolder().getSimplePayloadObject();
    if (tempPayloadObject) {
        addPayloadObservables([tempPayloadObject], chassisObjectToUse.getChassisObjectName() + '.' + config.ACCESSORY_TYPES.PAYLOAD);
    }
    chassisObjectToUse.getPayloadHolder().getDetailedPayloadItems().forEach(function (payloadItem) {
        createPayloadObservables(payloadItem, chassisObjectToUse.getChassisObjectName() + '.' + config.ACCESSORY_TYPES.PAYLOAD);
    });

}

function maximisePayloadsIfPossible() {

    var doMaximise = true;

    if (rigOps.canMaximisePayloadForCombination()) {
        getActiveOffer().getRig().getChassisObjects().forEach(function (chassisObject) {
            var tempPayloadObject = chassisObject.getPayloadHolder().getSimplePayloadObject();
            if (tempPayloadObject && tempPayloadObject.getSpecifyMassOverride() === true) {
                doMaximise = false;
            }
        });
    }

    if (doMaximise) {
        clearActiveLegislationQuickLookupObjects();
        rigOps.maximisePayloads();
    }

}

function addAddedAxlesToOriginalRig(axles) {


    var tempVehicle = getActiveOffer().getRig().getVehicle();
    var tempOriginalVehicle = getOriginalOffer().getRig().getVehicle();


    var originalNumRearAxles = tempOriginalVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR);
    var originalNumFrontAxles = tempOriginalVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT);

    var tagCounter = 0,
        pusherCounter = 0,
        secondSteerCounter = 0;

    var i, axle;
    for (i = 0; i < axles.length; i++) {
        axle = axles[i];
        if (axle.isAdded() && axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            tempOriginalVehicle.getAxlesHolder().add(axle.clone(true));
            //tempOriginalVehicle.setGVM(tempOriginalVehicle.getGVM() + rigOps.getLesserOfAxleManufacturerRatings(axle));
            tagCounter++;
        }
    }
    for (i = axles.length - 1; i >= 0; i--) {
        axle = axles[i];
        if (axle.isAdded() && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER)) {
            tempOriginalVehicle.getAxlesHolder().add(axle.clone(true));
            //tempOriginalVehicle.setGVM(tempOriginalVehicle.getGVM() + rigOps.getLesserOfAxleManufacturerRatings(axle));
            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
                pusherCounter++;
            } else {
                secondSteerCounter++;
            }
        }
    }

    if (tagCounter > 0 || pusherCounter > 0 || secondSteerCounter > 0) {

        switch (originalNumRearAxles) {
            case 1:
                if (pusherCounter === 3) {
                    //all added rear axles are pushers so update axle1RearToAxle2Rear Axle2RearToAxle3Rear Axle3RearToAxle4Rear with default spacing
                    tempOriginalVehicle.setAxle1RearToAxle2Rear(config.vehicleAxleDefaultAxleSpread);
                    tempOriginalVehicle.setAxle2RearToAxle3Rear(config.vehicleAxleDefaultAxleSpread);
                    tempOriginalVehicle.setAxle3RearToAxle4Rear(config.vehicleAxleDefaultAxleSpread);
                    tempOriginalVehicle.setAxle2FrontToAxle1Rear(tempOriginalVehicle.getAxle2FrontToAxle1Rear() - (config.vehicleAxleDefaultAxleSpread * 3));
                } else if (pusherCounter === 2) {
                    tempOriginalVehicle.setAxle1RearToAxle2Rear(config.vehicleAxleDefaultAxleSpread);
                    tempOriginalVehicle.setAxle2RearToAxle3Rear(config.vehicleAxleDefaultAxleSpread);
                    tempOriginalVehicle.setAxle2FrontToAxle1Rear(tempOriginalVehicle.getAxle2FrontToAxle1Rear() - (config.vehicleAxleDefaultAxleSpread * 2));
                } else if (pusherCounter === 1) {
                    tempOriginalVehicle.setAxle1RearToAxle2Rear(config.vehicleAxleDefaultAxleSpread);
                    tempOriginalVehicle.setAxle2FrontToAxle1Rear(tempOriginalVehicle.getAxle2FrontToAxle1Rear() - config.vehicleAxleDefaultAxleSpread);
                }
                if (tagCounter === 3) {
                    //all added rear axles are tags so update axle1RearToAxle2Rear Axle2RearToAxle3Rear Axle3RearToAxle4Rear with default spacing
                    tempOriginalVehicle.setAxle1RearToAxle2Rear(config.vehicleAxleDefaultAxleSpread);
                    tempOriginalVehicle.setAxle2RearToAxle3Rear(config.vehicleAxleDefaultAxleSpread);
                    tempOriginalVehicle.setAxle3RearToAxle4Rear(config.vehicleAxleDefaultAxleSpread);
                    tempOriginalVehicle.setRearOverhang(tempOriginalVehicle.getRearOverhang() - (config.vehicleAxleDefaultAxleSpread * 3));
                    tempVehicle.setBreakpointForRearOverhangFromRearMostAxle(tempOriginalVehicle.getBreakpointForRearOverhangFromRearMostAxle() - config.vehicleAxleDefaultAxleSpread * 3);
                } else if (tagCounter === 2) {
                    if (pusherCounter === 0) {
                        tempOriginalVehicle.setAxle1RearToAxle2Rear(config.vehicleAxleDefaultAxleSpread);
                        tempOriginalVehicle.setAxle2RearToAxle3Rear(config.vehicleAxleDefaultAxleSpread);
                        tempOriginalVehicle.setRearOverhang(tempOriginalVehicle.getRearOverhang() - (config.vehicleAxleDefaultAxleSpread * 2));
                        tempVehicle.setBreakpointForRearOverhangFromRearMostAxle(tempOriginalVehicle.getBreakpointForRearOverhangFromRearMostAxle() - config.vehicleAxleDefaultAxleSpread * 2);
                    } else if (pusherCounter === 1) {
                        tempOriginalVehicle.setAxle2RearToAxle3Rear(config.vehicleAxleDefaultAxleSpread);
                        tempOriginalVehicle.setAxle3RearToAxle4Rear(config.vehicleAxleDefaultAxleSpread);
                        tempOriginalVehicle.setRearOverhang(tempOriginalVehicle.getRearOverhang() - (config.vehicleAxleDefaultAxleSpread * 2));
                        tempVehicle.setBreakpointForRearOverhangFromRearMostAxle(tempOriginalVehicle.getBreakpointForRearOverhangFromRearMostAxle() - config.vehicleAxleDefaultAxleSpread * 2);
                    }
                } else if (tagCounter === 1) {
                    if (pusherCounter === 0) {
                        tempOriginalVehicle.setAxle1RearToAxle2Rear(config.vehicleAxleDefaultAxleSpread);
                        tempOriginalVehicle.setRearOverhang(tempOriginalVehicle.getRearOverhang() - config.vehicleAxleDefaultAxleSpread);
                        tempVehicle.setBreakpointForRearOverhangFromRearMostAxle(tempOriginalVehicle.getBreakpointForRearOverhangFromRearMostAxle() - config.vehicleAxleDefaultAxleSpread);
                    }else if(pusherCounter === 1) {
                        tempOriginalVehicle.setAxle2RearToAxle3Rear(config.vehicleAxleDefaultAxleSpread);
                        tempOriginalVehicle.setRearOverhang(tempOriginalVehicle.getRearOverhang() - config.vehicleAxleDefaultAxleSpread);
                        tempVehicle.setBreakpointForRearOverhangFromRearMostAxle(tempOriginalVehicle.getBreakpointForRearOverhangFromRearMostAxle() - config.vehicleAxleDefaultAxleSpread);
                    } else if (pusherCounter === 2) {
                        tempOriginalVehicle.setAxle3RearToAxle4Rear(config.vehicleAxleDefaultAxleSpread);
                        tempOriginalVehicle.setRearOverhang(tempOriginalVehicle.getRearOverhang() - config.vehicleAxleDefaultAxleSpread);
                        tempVehicle.setBreakpointForRearOverhangFromRearMostAxle(tempOriginalVehicle.getBreakpointForRearOverhangFromRearMostAxle() - config.vehicleAxleDefaultAxleSpread);
                    }
                    
                }
                break;
            case 2:
                if (pusherCounter === 2) {
                    tempOriginalVehicle.setAxle3RearToAxle4Rear(tempOriginalVehicle.getAxle1RearToAxle2Rear());
                    tempOriginalVehicle.setAxle1RearToAxle2Rear(config.vehicleAxleDefaultAxleSpread);
                    tempOriginalVehicle.setAxle2RearToAxle3Rear(config.vehicleAxleDefaultAxleSpread);
                    tempOriginalVehicle.setAxle2FrontToAxle1Rear(tempOriginalVehicle.getAxle2FrontToAxle1Rear() - (config.vehicleAxleDefaultAxleSpread * 2));
                } else if (pusherCounter === 1) {
                    tempOriginalVehicle.setAxle2RearToAxle3Rear(tempOriginalVehicle.getAxle1RearToAxle2Rear());
                    tempOriginalVehicle.setAxle1RearToAxle2Rear(config.vehicleAxleDefaultAxleSpread);
                    tempOriginalVehicle.setAxle2FrontToAxle1Rear(tempOriginalVehicle.getAxle2FrontToAxle1Rear() - config.vehicleAxleDefaultAxleSpread);
                }
                if (tagCounter === 2) {
                    tempOriginalVehicle.setAxle2RearToAxle3Rear(config.vehicleAxleDefaultAxleSpread);
                    tempOriginalVehicle.setAxle3RearToAxle4Rear(config.vehicleAxleDefaultAxleSpread);
                    tempOriginalVehicle.setRearOverhang(tempOriginalVehicle.getRearOverhang() - (config.vehicleAxleDefaultAxleSpread * 2));
                    tempVehicle.setBreakpointForRearOverhangFromRearMostAxle(tempOriginalVehicle.getBreakpointForRearOverhangFromRearMostAxle() - config.vehicleAxleDefaultAxleSpread * 2);
                } else if (tagCounter === 1) {
                    if (pusherCounter === 0) {
                        tempOriginalVehicle.setAxle2RearToAxle3Rear(config.vehicleAxleDefaultAxleSpread);
                        tempOriginalVehicle.setRearOverhang(tempOriginalVehicle.getRearOverhang() - config.vehicleAxleDefaultAxleSpread);

                        tempVehicle.setBreakpointForRearOverhangFromRearMostAxle(tempOriginalVehicle.getBreakpointForRearOverhangFromRearMostAxle() - config.vehicleAxleDefaultAxleSpread);
                    } else if (pusherCounter === 1) {
                        tempOriginalVehicle.setAxle3RearToAxle4Rear(config.vehicleAxleDefaultAxleSpread);
                        tempOriginalVehicle.setRearOverhang(tempOriginalVehicle.getRearOverhang() - config.vehicleAxleDefaultAxleSpread);

                        tempVehicle.setBreakpointForRearOverhangFromRearMostAxle(tempOriginalVehicle.getBreakpointForRearOverhangFromRearMostAxle() - config.vehicleAxleDefaultAxleSpread);
                    }
                }
                break;
            case 3:
                if (pusherCounter === 1) {
                    tempOriginalVehicle.setAxle3RearToAxle4Rear(tempOriginalVehicle.getAxle2RearToAxle3Rear());
                    tempOriginalVehicle.setAxle2RearToAxle3Rear(tempOriginalVehicle.getAxle1RearToAxle2Rear());
                    tempOriginalVehicle.setAxle1RearToAxle2Rear(config.vehicleAxleDefaultAxleSpread);
                    tempOriginalVehicle.setAxle2FrontToAxle1Rear(tempOriginalVehicle.getAxle2FrontToAxle1Rear() - config.vehicleAxleDefaultAxleSpread);
                }
                if (tagCounter === 1) {
                    tempOriginalVehicle.setAxle3RearToAxle4Rear(config.vehicleAxleDefaultAxleSpread);
                    tempOriginalVehicle.setRearOverhang(tempOriginalVehicle.getRearOverhang() - config.vehicleAxleDefaultAxleSpread);
                    tempVehicle.setBreakpointForRearOverhangFromRearMostAxle(tempOriginalVehicle.getBreakpointForRearOverhangFromRearMostAxle() - config.vehicleAxleDefaultAxleSpread);
                }
                break;


        }
        if (secondSteerCounter === 1) {
            tempOriginalVehicle.setAxle1FrontToAxle2Front(config.vehicleAxleDefaultSecondSteerAxleSpread);
            tempOriginalVehicle.setAxle2FrontToAxle1Rear(tempOriginalVehicle.getAxle2FrontToAxle1Rear() - config.vehicleAxleDefaultSecondSteerAxleSpread);
            tempVehicle.setBreakpointForWheelbaseFromRearMostAxleFront(tempVehicle.getBreakpointForWheelbaseFromRearMostAxleFront() - config.vehicleAxleDefaultSecondSteerAxleSpread);
        }
    }

}

function extractAccessories(accessories, chassisObjectType) {
    var extractedAccessories = {};
    Object.keys(accessories).forEach(function (key) {
        if (accessories[key] !== null) {
            var filterAcessoriesForKey = accessories[key].filter(function (accessoryAsJSON) {
                return accessoryAsJSON.ParentType === chassisObjectType;
            });
            extractedAccessories[key] = filterAcessoriesForKey;
        }
    });
    return extractedAccessories;
}

function setUpAvailableReports(dataAvailabilty) {
    if (dataAvailabilty.ActiveConfiguration === true) {
        //TODO
    }

    if (dataAvailabilty.ActiveSpecification === true) {
        //TODO
    }
}

function extractPayloadsForChassis(payloads, chassisObjectType) {

    var filterPayloadsForChassis = payloads.filter(function (payloadAsJSON) {
        return payloadAsJSON.ParentType === chassisObjectType;
    });
    return filterPayloadsForChassis;
}

function initialiseTurningAbilityVariables() {
    var activeSteeringAttribute = getTurningAbilitySpecifiedAsOption().id;
    var activeSteeringAttributeValue;
    if (activeSteeringAttribute === config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER) {
        activeSteeringAttributeValue = getActiveOffer().getRig().getVehicle().getSteeringAngleInner();
    } else if (activeSteeringAttribute === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB) {
        activeSteeringAttributeValue = getActiveOffer().getRig().getVehicle().getTurningRadiusCurbToCurb();
    } else if (activeSteeringAttribute === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL) {
        activeSteeringAttributeValue = getActiveOffer().getRig().getVehicle().getTurningRadiusWallToWall();
    }
    rigOps.updateTurningCircleCalculatedVals(activeSteeringAttribute, activeSteeringAttributeValue);
    //curTurningCircleVals = getTurningCircleVals
}

function getCurTurningCircleVals() {
    if (curTurningCircleVals === null) {
        curTurningCircleVals = rigOps.getTurningCircleValues(activeConfigurationViewDetail.value.itemId)
    }
    return curTurningCircleVals;
}

function updateBridgeFormulas(chassisObjectTypeOrPath) {
    
    rigOps.updateBridgeFormulaPermissibleValues(chassisObjectTypeOrPath, getDataForActiveLegislations().mergedLegislationBridgeFormulaPermissibleVals[chassisObjectTypeOrPath]);

}

function handleTailiftsOnCalculationsSavedPriortoTailliftChanges(accessoryHolder, originalAccessoryHolder) {
    var tempTaillift = accessoryHolder.getTaillift();
    var tempOriginalTaillift = originalAccessoryHolder.getTaillift();

    if (tempTaillift !== null) {
        if (tempTaillift.getAboveOrBelowFloor() === -1) {
            tempTaillift.setAboveOrBelowFloor(-accessoryHolder.getBody().getSubstructureHeight());
            if (tempOriginalTaillift !== null) {
                tempTaillift.setVerticalCOG(tempOriginalTaillift.getVerticalCOG());
            }
        }
        if (tempTaillift.getMechanismHeight() === -1) {
            if (tempOriginalTaillift !== null) {
                tempTaillift.setMechanismHeight(tempOriginalTaillift.getMechanismHeight());
            } else {
                tempTaillift.setMechanismHeight(500);
            }
        }
        if (tempTaillift.getPlatformHeight() < 0) {
            if (tempOriginalTaillift !== null) {
                tempTaillift.setPlatformHeight(tempOriginalTaillift.getPlatformHeight());
            } else {
                tempTaillift.setPlatformHeight(0);
            }
        }

    }
}

function openNewOfferSpecification(existingOfferId) {
    //make sure the token is still valid and extend if necessary
    if (!dataManager.securityTokenIsValid()) {
        shellLogout();
    }
    specificationFetchComplete.value = false;
    specificationFetchSuccessful.value = false;

    var dfd = $.Deferred();
    var passBackResults = false;

    doOpenNewOfferSpecification(dfd, vehicleId.value, vehicleDistributionOptionId.value, vehicleDistributionGroupId.value, languageId.value, currencySymbolMajor.value, existingOfferId, passBackResults);




    return dfd.promise();

}

function preloadNewSpecForSwap(vehicleId, vehicleDistributionOptionId, vehicleDistributionGroupId, languageId, currencySymbolMajor, existingOfferId) {
    if (!dataManager.securityTokenIsValid()) {
        shellLogout();
    }


    var dfd = $.Deferred();
    var passBackResults = true;

    doOpenNewOfferSpecification(dfd, vehicleId, vehicleDistributionOptionId, vehicleDistributionGroupId, languageId, currencySymbolMajor, existingOfferId, passBackResults);

    return dfd.promise();
}

function doOpenNewOfferSpecification(dfd, vehicleId, vehicleDistributionOptionId, vehicleDistributionGroupId, languageId, currencySymbolMajor, existingOfferId, passBackResults, competitor1Id, competitor2Id) {
    $.when(dataManager.getOfferSpecificationDetails(vehicleId, vehicleDistributionOptionId, vehicleDistributionGroupId, languageId, currencySymbolMajor, existingOfferId, competitor1Id, competitor2Id)).then(

        function (success) {

            var results;

            results = success.offer;
            if (results.Result.ReturnCode === 1) {
                if (passBackResults) {
                    dfd.resolve(results);
                } else {
                    handleSpecificationResults(results);
                }
            } else if (results.Result.ReturnCode === 2) {
                if (passBackResults) {
                    dfd.resolve(results);
                } else {
                    handleSpecificationResults(results);
                }
                var listOfMissingTranslations = getStringOfFailedTranslations(results.FailedTranslations);
                globals.showDialog(new CustomMessageBox(listOfMissingTranslations, config.applicationTitle, [config.getTranslationText('271')]));
            } else {
                globals.showDialog(new CustomMessageBox(config.getMessageText('1601', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1601')))
                    .addCallback(function (dialogResult) { });
            }

        },

        function (error) {
            specificationFetchComplete.value = true;
            specificationFetchSuccessful.value = false;
            dfd.reject({
                errorMessage: error.errorMessage
            });
        }
    );

    function getStringOfFailedTranslations(listOfFailedTranslations) {
        // var dialogBodyText = 'The following translations could not be found in the database:</br></br>'; // User Story 17565 4946
        var dialogBodyText = '';

        listOfFailedTranslations.forEach(function (failedTranslation) {
            dialogBodyText += 'AttributeName: ' + failedTranslation.AttributeName + ', Code: ' + failedTranslation.Code + ', DatabaseAttributeId: ' + failedTranslation.DatabaseAttributeId + '</br>';
        });

        // return dialogBodyText;
        return config.getTranslationText('4946', [dialogBodyText]);
    }
}

function handleSpecificationResults(results) {
    getActiveOffer().createSpecification(results.Specification);
    getActiveOffer().getSpecification().addAdvantages(results.Advantages);

    selectedCompetitor1Id.value = getSpecification().getSelectedCompetitor1Id();
    selectedCompetitor2Id.value = getSpecification().getSelectedCompetitor2Id();

    updateSpecificationObservables();

    specificationFetchComplete.value = true;
    specificationFetchSuccessful.value = true;
}

function clearActiveLegislationQuickLookupObjects() {
    //legislationQuickLookup = {};
    if (!lookupObjectsCleared) {
        utils.deleteObjectMembers(legislationQuickLookup);
        utils.deleteObjectMembers(curActiveLegislationsDataObject);
        curActiveLegislationsDataObject = undefined;
        utils.deleteObjectMembers(tableCellHighlightColours);
        tableCellHighlightColours = undefined;
        utils.deleteObjectMembers(bridgeFormulaPermissibleHighlightColours);
        bridgeFormulaPermissibleHighlightColours = undefined;
        steerAxleMinimumHighlightColours = undefined;
        utils.deleteObjectMembers(worstBridgeFormulaOverloadColour);
        worstBridgeFormulaOverloadColour = undefined;
        worstBridgeFormulaPermissibleOverloadColour = undefined;
        worstSteerAxleMinimumOverloadColour = undefined;
        setLegislationLookupObjectsCleared(true);
    }
    //Object.keys(legislationQuickLookup).forEach(function (key) {
    //    Object.keys(legislationQuickLookup[key]).forEach(function (innerKey) {
    //        legislationQuickLookup[key][innerKey] = undefined;
    //    });
    //    //legislationQuickLookup[key];
    //});
}

function setLegislationLookupObjectsCleared(newValue) {
    lookupObjectsCleared = newValue;
}

function setSkipGraphicsCallback(newValue) {
    skipGraphicsCallback = newValue;
}

function closeOffer() {
    globals.setCurrentCombinationType(null);
    turningCircleLockedToSteeringAngleInner = false;
    offerOpen = false;
    unsavedChangesOnOffer.value = false;
    allowSpecificationUpdateOfUnsavedChangesOnOffer = false;
    setLegislationLookupObjectsCleared(false);
    setAllowUpdateOfUnsavedChangesOnOffer(false);
    clearActiveLegislationQuickLookupObjects();
    bus.emit('clearTipToasts');
    if (getActiveOffer() !== '' && getActiveOffer() !== undefined) {
        //clear out the truck data as the calculation will be closed
        if (getPerformance() !== null && getPerformance() !== undefined) {
            getPerformance().clear();
        }

        rigOps.cleanUp();
        if(clearOfferCallback) {
            clearOfferCallback();
        }
        getActiveOffer().cleanUp();
        setActiveOffer('');
        updateObservables();
        cleanUpCostingComputeds();
        cleanUpPerformanceFormattedComputeds();
        offerIsOpen.value = false;
        specificationFetchComplete.value = false;
        specificationFetchSuccessful.value = false;
        selectedCustomer.value = null;
        customerAttachedToOffer.value = false;
        //selectedAccessories([]);
        //attributeObservableGroups = [];
        deleteObservables('VEHICLE');
        deleteObservables(config.CHASSIS_OBJECT_TYPES.TRAILER1);
        deleteObservables(config.CHASSIS_OBJECT_TYPES.TRAILER2);
        deleteObservables('SETTINGS');
        deleteObservables('REPORTOPTIONS');
        deleteObservables('PERFORMANCE');
        deleteObservables('COSTING');
        deleteObservables('costingDetailModal');
        clearPDFImagesArray();
        clearLocalStorageItems();
        curNotes = undefined;
        globals.curWarnings = undefined;
        globals.curTips = undefined;
        globals.curDDRNotes = undefined;
        curActiveLegislationsDataObject = undefined;
        lookupObjectsCleared = false;
        generatingActiveLegislationsData = false;
        curDistinctWarnings = undefined;
        curDistinctWarningsPerLegislation = undefined;
        allowedAccessoryTypes = undefined;
        allowedTrailerTypesTrailer1 = undefined;
        allowedTrailerTypesTrailer2 = undefined;
        description.value = '';
        //additionalDescription('');
        openOfferIsSharedOffer = false;
        legislationSubstitutedForSharedCalc = false;
        defaultLegislationUsedDuToMissingLegislation = false;
        shareeUserId = null;
        sharedOfferCustomerId = null;

        utils.deleteObjectMembers(availableBodies);
        availableBodies = [];
        utils.deleteObjectMembers(availableAccessories);
        availableAccessories = [];
        utils.deleteObjectMembers(availableTrailers);
        availableTrailers = [];
        utils.deleteObjectMembers(availablePayloads);
        availablePayloads = [];

       
        if (changeListenerCallback !== undefined) {
            changeListenerCallback('Clear_All_Interaction_State'); // User Story 17565
        }
        if (dynamicValidationGroups && dynamicValidationGroups.length > 0) {
            dynamicValidationGroups.forEach(function (groupDetails) {
                groupDetails.validationObjectFunction = null;
                groupDetails.object = null;
            });
        }
        dynamicValidationGroups = [];
        if (refreshDataSubscriptionRef) {
            refreshDataSubscriptionRef();
        }
        globals.watchersArr.forEach(function (unWatchFunc){
            unWatchFunc();
        });
        // setTimeout(function () {
            if (refreshData) {
            
                try {
                    // utils.clearSubscriptions(refreshData);
                    refreshData.value = null;
                    refreshData = null;
                    refreshCostingData.value = null;
                    refreshCostingData = null;
                } catch (subscriptionDisposeEx) { 
                    TScMessenger.writeErrorMessage('Error cleaning up data refresh mechanism');
                }
            }
            if (refreshUILiveLabels) {
                
                try {
                    // utils.clearSubscriptions(refreshUILiveLabels);
                    refreshUILiveLabels.value = null;
                    refreshUILiveLabels = null;
                } catch (subscriptionDisposeEx) {
                    TScMessenger.writeErrorMessage('Error cleaning up ui refresh mechanism');
                }
            }
        // }, 200);
        
        dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.CLOSED_OFFER, {}));
    }

}

function createRefreshSWLabelsObservable() {
    refreshSWLabels = shallowRef(new Date().getTime());
}

function clearRefreshSWLabelsObservable() {
    if (refreshSWLabels) {
        try {
            // utils.clearSubscriptions(refreshSWLabels);
            refreshSWLabels.value = null;
            refreshSWLabels = null;
        } catch (subscriptionDisposeEx) {
            TScMessenger.writeErrorMessage('Error cleaning up sw refresh mechanism');
        }

    }
}

function clearLocalStorageItems() {
    localStorage.setItem('lastCreatedPdf', '');
    setConfigDrawing(null);
    dataManager.remove(config.OPERATION.ConfigDrawing, vehicleId.value + "_configurationDrawing");
}

function converToArrayOfCustomerObjects(customers) {
    var customerObjects = [];
    for (var i = 0; i < customers.length; i++) {
        customerObjects.push(createCustomer(customers[i]));
    }
    return customerObjects;
}

function getOfferDetailsToBeSaved() {
    return getActiveOffer().getOfferDetailsToBeSaved(rigOps);
}


function saveOffer(customer, attachmentDetails, sendEmail, saveOfferFlags, shareOptions, folderPath) {

    var dfd = $.Deferred();

    var offerDetailsToBeSaved = getOfferDetailsToBeSaved();
    offerDetailsToBeSaved.VehicleTypeTranslation = vehicleTypeTranslation.value;

    var curSelectedCustomer = selectedCustomer.value;

    selectedCustomer.value = customer;

    $.when(dataManager.saveOffer(customer.getId(), offerManager.id.value, getActiveOffer().getSalesPersonId(), customer.getCustomerDetails(), offerDetailsToBeSaved, attachmentDetails, sendEmail, saveOfferFlags, shareOptions, folderPath)).then(

        function (success) {

            if (success.savedOffer.Result.ReturnCode === 1 || success.savedOffer.Result.ReturnCode === 2) {

                handleSaveOfferResponse(success);
                unsavedChangesOnOffer.value = false;

                //dataManager.sendEventInfoToIntercom(globals.getIntercomObject(config.INTERCOM_EVENT.SAVED_OFFER));
                dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.SAVED_OFFER));
            }
            dfd.resolve(success);
        },

        function (error) {
            if (curSelectedCustomer === null) {
                removeSelectedCustomer();
            }
            dfd.reject(error);
        }
    );
    return dfd.promise();
}

function handleSaveOfferResponse(success) {

    if (!isNaN(success.savedOffer.Offer.Id) && success.savedOffer.Offer.Id !== 0 && success.savedOffer.Offer.Id !== getActiveOffer().getId()) {
        
        getActiveOffer().setIsSharedOffer(false);
        openOfferIsSharedOffer = false;
    
        if (getActiveOffer().getSalesPersonId() !== success.savedOffer.LastModifiedByInfo.LastModifiedByUserId) {
            getActiveOffer().setSalesPersonId(success.savedOffer.LastModifiedByInfo.LastModifiedByUserId);
        }
        getActiveOffer().setSharerUserInfo(undefined);
        getActiveOffer().setShareeUserInfoList([]);
        shellEvaulateShowSaveOption();
    }
    

    description.value = success.savedOffer.Offer.Description;
    //additionalDescription(success.savedOffer.Offer.AdditionalDescription);

    //getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSDESCRIPTION).formatted(description());
    //getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSADDITIONALDESCRIPTION).formatted(additionalDescription());

    if (selectedCustomer.value !== null && selectedCustomer.value !== undefined && selectedCustomer.value !== '') {
        selectedCustomer.value.setId(success.savedOffer.Customer.Id);
        selectedCustomer.value.setUpdateCounter(success.savedOffer.Customer.UpdateCounter);
        selectedCustomer.value.setOverallUpdateCounter(success.savedOffer.Customer.OverallUpdateCounter);



        updateSelectedCustomer(selectedCustomer.value);
    }
    offerManager.id.value = success.savedOffer.Offer.Id;
    offerManager.updateCounter.value = success.savedOffer.Offer.UpdateCounter;
}



function updatePendingVehicle(isSubmit, postValidatedVehicleStatus, comments) {
    dataManager.updatePendingVehicle(vehicleId.value, getActiveOffer().getPendingVehicleDetailsToBeSaved(rigOps), getActiveOffer().addFuelTankAttributesForAppData(getActiveOffer().getOfferDetailsToBeSaved(rigOps)), isSubmit, postValidatedVehicleStatus, comments)
        .then(function (result) {
            var description = getActiveOffer().getRig().getVehicle().getDescription();
            if (result.Result.ReturnCode === 1) {
                getActiveOffer().getRig().getVehicle().setUpdateCounter(result.UpdateCounter);
                unsavedChangesOnOffer.value = false;
                var intercomEventId;
                if (isSubmit) {
                    configurationActionResponseHandlerCallback(config.ACTION_TYPE.VEHICLE_DATA, config.ACTION_ID.VEHICLE_SUBMITTED);
                    syncSuccessToastHandlerCallback(config.getTranslationText('1119'), description);
                    intercomEventId = config.INTERCOM_EVENT.PENDING_VEHICLE_SUBMITTED;
                } else {
                    syncSuccessToastHandlerCallback(config.getTranslationText('1114'), description);
                    intercomEventId = config.INTERCOM_EVENT.PENDING_VEHICLE_UPDATED;
                }
                var intercomEventMeta = {};
                intercomEventMeta[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = description;
                dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(intercomEventId, intercomEventMeta));
            } else {
                if (isSubmit) {
                    syncErrorToastHandlerCallback(config.getTranslationText('1119'), description);
                } else {
                    syncErrorToastHandlerCallback(config.getTranslationText('1114'), description);
                }

            }

        })
        .fail(function (error) {
            showShellSpinner(false);
            if (error.errorMessage === 'Offline') { // User Story 17565
                globals.showDialog(new CustomMessageBox(config.getMessageText('1573', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1573')));
            } else {
                dataManager.log("updatePendingVehicle, dataManager.updatePendingVehicle, fail, 1571", error, 'offerManager', config.LOG_MESSAGE_TYPE.ERROR);
                globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')));
            }

        });
}

function shareOffer(shareeInfoStubs, offerInfoStubs, optionalCommentForSharee, copyMeIn) {
    var dfd = $.Deferred();


    dataManager.shareOffer(shareeInfoStubs, offerInfoStubs, optionalCommentForSharee, copyMeIn)
        .then(function (result) {
            dfd.resolve(result);
        });

    return dfd.promise();
}
//#endregion Offer Functions

//#region Other Functions
function isActiveOfferOpen() {
    return getActiveOffer() !== undefined && getActiveOffer() !== '';
}

function setSelectedCustomer(id, contactName, company, mobile, directNumber, email, updateCounter, overallUpdateCounter) {
    var customerDetails = {
        id: id,
        contactName: contactName,
        company: company,
        mobile: mobile,
        directNumber: directNumber,
        email: email,
        updateCounter: updateCounter,
        overallUpdateCounter: overallUpdateCounter
    };
    var customer = createCustomer(customerDetails);
    updateSelectedCustomer(customer);
}

function updateSelectedCustomer(customer) {
    if (getActiveOffer() !== null && getActiveOffer() !== undefined && getActiveOffer() !== '') {
        getActiveOffer().setCustomer(customer);
        customerAttachedToOffer.value = true;
    }

    selectedCustomer.value = customer;

}

function removeSelectedCustomer() {
    getActiveOffer().setCustomer(undefined);
    selectedCustomer.value = null;
    customerAttachedToOffer.value = false;
}

function updateCapitalCosts() {
    var vehicleCost = getActiveOffer().getCosting().getVehiclePrice();
    var bodyAndEquipmentCost = getActiveOffer().getCosting().getBodyAndEquipmentPrice();
    var optionCost = getActiveOffer().getCosting().getOptionPrice();
    
    //total the capital cost
    totalCapitalCost.value = currencySymbolMajor.value + ' ' + Globalize.format(Math.round(vehicleCost + bodyAndEquipmentCost + optionCost), "n0"); // User Story 17565
}

function setSelectedCompetitor1(id) {
    var competitorVehicles = getSpecification().getCompetitorVehicles();
    var temp = competitorVehicles.filter(function (vehicle) {
        return vehicle.vehicleId === id;
    });
    if (temp.length > 0) {
        getSpecification().setSelectedCompetitor1(temp[0]);
        selectedCompetitor1.value = getSpecification().getSelectedCompetitor1();
    }
}

function setSelectedCompetitor2(id) {
    var competitorVehicles = getSpecification().getCompetitorVehicles();
    var temp = competitorVehicles.filter(function (vehicle) {
        return vehicle.vehicleId === id;
    });
    if (temp.length > 0) {
        getSpecification().setSelectedCompetitor2(temp[0]);
        selectedCompetitor2.value = getSpecification().getSelectedCompetitor2();
    }
}

//#region Subscriptions
watch(selectedCompetitor1, function (newValue) {
    getSpecification().setSelectedCompetitor1(newValue);
    if (newValue.vehicleId !== selectedCompetitor1Id.value) {
        selectedCompetitor1Id.value = newValue.vehicleId;
    }

    if (unsavedChangesOnOffer.value === false && allowSpecificationUpdateOfUnsavedChangesOnOffer === true) {
        unsavedChangesOnOffer.value = true;
    }
});

watch(selectedCompetitor2, function (newValue) {
    getSpecification().setSelectedCompetitor2(newValue);
    if (newValue.vehicleId !== selectedCompetitor2Id.value) {
        selectedCompetitor2Id.value = newValue.vehicleId;
    }

    if (unsavedChangesOnOffer.value === false && allowSpecificationUpdateOfUnsavedChangesOnOffer === true) {
        unsavedChangesOnOffer.value = true;
    }
});
watch(id, function (newValue) {
    if (getActiveOffer() !== '') {
        getActiveOffer().setId(newValue);
    }
});
watch(updateCounter, function (newValue) {
    if (getActiveOffer() !== '') {
        getActiveOffer().setUpdateCounter(newValue);
    }
});
watch(validPdfOnLocalStorage, function (newValue) {
    if (!newValue) {
        if(clearOfferCallback) {
            clearOfferCallback();
        }
    }
});
watch(unsavedChangesOnOffer, function (newValue) {
    if (newValue) {
        validPdfOnLocalStorage.value = false;
    }
});
watch(waypoints, function (newValue) {
    if (getActiveOffer() !== '') {
        getActiveOffer().getPerformance().setWaypoints(newValue);
    }
});
watch(tripDistanceOutbound, function (newValue) {
    if (getActiveOffer() !== '') {
        getActiveOffer().getPerformance().setTripDistanceOutbound(newValue);
    }
});
watch(totalFuelOutbound, function (newValue) {
    if (getActiveOffer() !== '') {
        getActiveOffer().getPerformance().setTotalFuelOutbound(newValue);
    }
});
watch(tripTimeOutbound, function (newValue) {
    if (getActiveOffer() !== '') {
        getActiveOffer().getPerformance().setTripTimeOutbound(newValue);
    }
});
watch(fuelConsumptionOutbound, function (newValue) {
    if (getActiveOffer() !== '') {
        getActiveOffer().getCosting().setFuelConsumptionOutbound(newValue);
    }
});
watch(tripDistanceReturn, function (newValue) {
    if (getActiveOffer() !== '') {
        getActiveOffer().getPerformance().setTripDistanceReturn(newValue);
    }
});
watch(totalFuelReturn, function (newValue) {
    if (getActiveOffer() !== '') {
        getActiveOffer().getPerformance().setTotalFuelReturn(newValue);
    }
});
watch(tripTimeReturn, function (newValue) {
    if (getActiveOffer() !== '') {
        getActiveOffer().getPerformance().setTripTimeReturn(newValue);
    }
});
watch(fuelConsumptionReturn, function (newValue) {
    if (getActiveOffer() !== '') {
        getActiveOffer().getCosting().setFuelConsumptionReturn(newValue);
    }
});
watch(originalVehiclePrice, function (newValue) {
    if (getActiveOffer() !== '') {
        getActiveOffer().getCosting().setOriginalVehiclePrice(newValue);
    }
});
watch(originalBodyAndEquipmentPrice, function (newValue) {
    if (getActiveOffer() !== '') {
        getActiveOffer().getCosting().setOriginalBodyAndEquipmentPrice(newValue);
    }
});
watch(originalOptionPrice, function (newValue) {
    if (getActiveOffer() !== '') {
        getActiveOffer().getCosting().setOriginalOptionPrice(newValue);
    }
});
watch(description, function (newValue) {
    if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
        getActiveOffer().setDescription(newValue);
    }
});
watch(noOfUnits, function (newValue) {
    if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
        getActiveOffer().getCosting().setNoOfUnits(newValue);
    }
});
watch(vehiclePrice, function (newValue) {
    if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
        getActiveOffer().getCosting().setVehiclePrice(newValue);
    }
});
watch(bodyAndEquipmentPrice, function (newValue) {
    if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
        getActiveOffer().getCosting().setBodyAndEquipmentPrice(newValue);
    }
});
watch(optionPrice, function (newValue) {
    if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
        getActiveOffer().getCosting().setOptionPrice(newValue);
    }
});
watch(monthlyDistance, function (newValue) {
    if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
        getActiveOffer().getCosting().setMonthlyDistance(newValue);
    }
});
watch(fuelConsumption, function (newValue) {
    if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
        getActiveOffer().getCosting().setFuelConsumption(newValue);
    }
});
watch(fuelCost, function (newValue) {
    if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
        getActiveOffer().getCosting().setFuelCost(newValue);
    }
});
watch(maintenanceRate, function (newValue) {
    if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
        getActiveOffer().getCosting().setMaintenanceRate(newValue);
    }
});
watch(depositRate, function (newValue) {
    if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
        getActiveOffer().getCosting().setDepositRate(newValue);
    }
});
watch(residualRate, function (newValue) {
    if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
        getActiveOffer().getCosting().setResidualRate(newValue);
    }
});
watch(repaymentTerm, function (newValue) {
    if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
        getActiveOffer().getCosting().setRepaymentTerm(newValue);
    }
});
watch(interestRate, function (newValue) {
    if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
        getActiveOffer().getCosting().setInterestRate(newValue);
    }
});
watch(activeCabColour, function (newValue) {
    if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
        getActiveOffer().getConfiguration().setActiveCabColour(newValue);
    }
});
watch(activeLegislation, function (newValue) {
    globals.user.updateUser({
        activeLegislation: newValue
    });
});

watchEffect(function () {
    // activeMeasurementSystem.value;
    if(activeMeasurementSystem.value) {
        handleMeasurementSystemUpdate(activeMeasurementSystem.value);
    }
    
    // if (refreshUILiveLabels !== null) {
    //     refreshUILiveLabels.value = new Date().getTime();
    // }
    // if (refreshSWLabels !== null) {
    //     refreshSWLabels.value = new Date().getTime();
    // }
});

watch(activeMeasurementSystem, function (newValue) {
    
    // handleMeasurementSystemUpdate(newValue);
    if (refreshUILiveLabels !== null) {
        refreshUILiveLabels.value = new Date().getTime();
    }
    if (refreshSWLabels !== null) {
        refreshSWLabels.value = new Date().getTime();
    }
});
function handleMeasurementSystemUpdate(newValue) {
    if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
        getActiveOffer().setMeasurementSystem(newValue);
    }
    globals.user.updateUser({
        activeMeasurementSystem: newValue
    });
}
watch(activeApplication, function (newValue) {
    if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
        getActiveOffer().setApplication(newValue);
    }
});

function setupRefreshDataSubscription() {
    refreshDataSubscriptionRef = watch(refreshData, function () {
        if (refreshDataCallback !== null) {
            nextTick(function(){
                refreshDataCallback();
            });
        }
    });
}

//#region Subscriptions

function createCustomer(customer) {
    if (customer !== undefined) {
        if (typeof customer.Company === 'string' || typeof customer.company === 'string') {
            return new Customer(customer.Id || customer.id, customer.ContactName || customer.contactName, customer.Company || customer.company, customer.Mobile || customer.mobile,
                customer.DirectNumber || customer.directNumber, customer.Email || customer.email, customer.UpdateCounter || customer.updateCounter, customer.OverallUpdateCounter || customer.overallUpdateCounter);
        } else {
            return new Customer(customer.Id(), customer.ContactName(), customer.Company(), customer.Mobile(), customer.DirectNumber(), customer.Email(), customer.UpdateCounter(), customer.OverallUpdateCounter());
        }

    } else {
        return new Customer();
    }

}

function setupOriginalOfferFuelTankInternalIds(activeOffer, originalOffer) {
    for (var i = 0; i < originalOffer.getConfiguration().getFuelTanks().length; i++) {
        var tempFuelTank = activeOffer.getConfiguration().getFuelTankByNumber(i + 1);
        var tempOriginalFuelTank = originalOffer.getConfiguration().getFuelTankByNumber(i + 1);
        if (tempFuelTank !== null && tempOriginalFuelTank !== null) {
            tempOriginalFuelTank.setId(tempFuelTank.getId());
        }
    }
}

function setupOriginalAxleInternalIds(activeOffer, originalOffer) {
    for (var i = 0; i < originalOffer.getConfiguration().getAxles().length; i++) {
        originalOffer.getConfiguration().getAxlesHolder().getAxleByNumber(i + 1).setId(activeOffer.getConfiguration().getAxlesHolder().getAxleByNumber(i + 1).getId());
    }
    originalOffer.getConfiguration().getAxlesHolder().syncIdsOnAxleGroups();
    originalOffer.getConfiguration().getAxlesHolder().getAxleGroups().forEach(function(axleGroup){
        axleGroup.setId(activeOffer.getConfiguration().getAxlesHolder().getAxleGroupUsingType(axleGroup.getType()).getId());
    });
}

function setupOriginalAxleInternalIdsOnTrailers() {
    var i = 0;
    // Trailer 1
    if (getActiveOffer().getTrailer1() !== null) {
        var trailer1StartingNumberForAxles = getOriginalOffer().getTrailer1().getAxlesHolder().getStartingNumberForAxles();
        for (i = trailer1StartingNumberForAxles; i < trailer1StartingNumberForAxles + getOriginalOffer().getTrailer1().getAxlesHolder().getAxles().length; i++) {
            getOriginalOffer().getTrailer1().getAxlesHolder().getAxleByNumber(i + 1).setId(getActiveOffer().getTrailer1().getAxlesHolder().getAxleByNumber(i + 1).getId());
        }
        getOriginalOffer().getTrailer1().getAxlesHolder().syncIdsOnAxleGroups();
        getOriginalOffer().getTrailer1().getAxlesHolder().getAxleGroups().forEach(function (axleGroup) {
            if(axleGroup) {
                axleGroup.setId(getActiveOffer().getTrailer1().getAxlesHolder().getAxleGroupUsingType(axleGroup.getType()).getId());
            }
        });
    }
    
    // Trailer 2
    if (getActiveOffer().getTrailer2() !== null) {
        var trailer2StartingNumberForAxles = getOriginalOffer().getTrailer2().getAxlesHolder().getStartingNumberForAxles();
        for (i = trailer2StartingNumberForAxles; i < trailer2StartingNumberForAxles + getOriginalOffer().getTrailer2().getAxlesHolder().getAxles().length; i++) {
            getOriginalOffer().getTrailer2().getAxlesHolder().getAxleByNumber(i + 1).setId(getActiveOffer().getTrailer2().getAxlesHolder().getAxleByNumber(i + 1).getId());
        }
        getOriginalOffer().getTrailer2().getAxlesHolder().syncIdsOnAxleGroups();
        getOriginalOffer().getTrailer2().getAxlesHolder().getAxleGroups().forEach(function (axleGroup) {
            axleGroup.setId(getActiveOffer().getTrailer2().getAxlesHolder().getAxleGroupUsingType(axleGroup.getType()).getId());
        });
    }
}

function addOriginalAccessoriesToOriginalRig(originalAccessories) {

    var vehicleOriginalAccessories = extractAccessories(originalAccessories, config.CHASSIS_OBJECT_TYPES.VEHICLE);
    addOriginalAccessories(getActiveOffer().getRig().getVehicle(), getOriginalOffer().getRig().getVehicle(), vehicleOriginalAccessories, config.CHASSIS_OBJECT_TYPES.VEHICLE);

    if (getActiveOffer().getRig().getTrailer1() !== null) {
        var trailer1OriginalAccessories = extractAccessories(originalAccessories, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        addOriginalAccessories(getActiveOffer().getRig().getTrailer1(), getOriginalOffer().getRig().getTrailer1(), trailer1OriginalAccessories, config.CHASSIS_OBJECT_TYPES.TRAILER1);

        if (getActiveOffer().getRig().getTrailer2() !== null) {
            var trailer2OriginalAccessories = extractAccessories(originalAccessories, config.CHASSIS_OBJECT_TYPES.TRAILER2);
            addOriginalAccessories(getActiveOffer().getRig().getTrailer2(), getOriginalOffer().getRig().getTrailer2(), trailer2OriginalAccessories, config.CHASSIS_OBJECT_TYPES.TRAILER2);
        }
    }



    function addOriginalAccessories(chassisObjectToUse, originalChassisObjectToUse, accessories, rootPath) {
        if (accessories.Bodies && accessories.Bodies.length > 0) {
            accessories.Bodies.forEach(function (body) {
                
                var bodyObject = chassisObjectToUse.getAccessoryHolder().getAccessoryBySourceDatabaseId(config.ACCESSORY_TYPES.BODY, body.Id);
                var internalId = bodyObject.getId();

                var orginalBodyObject = createBodyObject(body, internalId);
                originalChassisObjectToUse.getAccessoryHolder().add(orginalBodyObject);
                if(rootPath === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    orginalBodyObject.setHorizontalGap(getConfiguration().getCabGapRecommendedMin());
                }
                bodyObject.setEditLevel(orginalBodyObject.getEditLevel());

                setupDefaultPayloadForBody(originalChassisObjectToUse.getPayloadHolder(), orginalBodyObject);

            });
        }

        if (accessories.Cranes && accessories.Cranes.length > 0) {
            accessories.Cranes.forEach(function (crane) {

                var craneObject = chassisObjectToUse.getAccessoryHolder().getAccessoryBySourceDatabaseId(config.ACCESSORY_TYPES.CRANE, crane.Id); 
                var internalId = craneObject.getId();

                var orginalCraneObject = createAccessoryObject(crane, internalId, rootPath);
                originalChassisObjectToUse.getAccessoryHolder().add(orginalCraneObject);

                craneObject.setEditLevel(orginalCraneObject.getEditLevel());
            });
        }
        if (accessories.Others && accessories.Others.length > 0) {
            var otherAccessorySourceDBIdCounterObject = {};
            accessories.Others.forEach(function (other) {

                if (otherAccessorySourceDBIdCounterObject[other.Id]) {
                    otherAccessorySourceDBIdCounterObject[other.Id]++;
                } else {
                    otherAccessorySourceDBIdCounterObject[other.Id] = 1;
                }

                var otherObject = chassisObjectToUse.getAccessoryHolder().getAccessoryBySourceDatabaseId(config.ACCESSORY_TYPES.OTHER, other.Id, otherAccessorySourceDBIdCounterObject[other.Id]); 
                var internalId = otherObject.getId();

                var orginalOtherObject = createAccessoryObject(other, internalId, rootPath);
                originalChassisObjectToUse.getAccessoryHolder().add(orginalOtherObject);

                otherObject.setEditLevel(orginalOtherObject.getEditLevel());
            });
        }
        if (accessories.Fridges && accessories.Fridges.length > 0) {
            accessories.Fridges.forEach(function (fridge) {

                var fridgeObject = chassisObjectToUse.getAccessoryHolder().getAccessoryBySourceDatabaseId(config.ACCESSORY_TYPES.FRIDGE, fridge.Id);
                var internalId = fridgeObject.getId();

                var orginalFridgeObject = createAccessoryObject(fridge, internalId, rootPath);
                originalChassisObjectToUse.getAccessoryHolder().add(orginalFridgeObject);

                fridgeObject.setEditLevel(orginalFridgeObject.getEditLevel());
            });
        }
        if (accessories.Taillifts && accessories.Taillifts.length > 0) {
            accessories.Taillifts.forEach(function (taillift) {

                var tailliftObject = chassisObjectToUse.getAccessoryHolder().getAccessoryBySourceDatabaseId(config.ACCESSORY_TYPES.TAILLIFT, taillift.Id); 
                var internalId = tailliftObject.getId();

                var orginalTailliftObject = createAccessoryObject(taillift, internalId, rootPath);
                originalChassisObjectToUse.getAccessoryHolder().add(orginalTailliftObject);

                tailliftObject.setEditLevel(orginalTailliftObject.getEditLevel());
            });
        }
        if (accessories.FifthWheels && accessories.FifthWheels.length > 0) {
            accessories.FifthWheels.forEach(function (fifthWheel) {

                var fifthWheelObject = chassisObjectToUse.getAccessoryHolder().getAccessoryBySourceDatabaseId(config.ACCESSORY_TYPES.FIFTH_WHEEL, fifthWheel.Id); 
                var internalId = fifthWheelObject.getId();

                var orginalFifthWheelObject = createAccessoryObject(fifthWheel, internalId, rootPath);
                originalChassisObjectToUse.getAccessoryHolder().add(orginalFifthWheelObject);

                fifthWheelObject.setEditLevel(orginalFifthWheelObject.getEditLevel());
            });

        }
        if (accessories.Hitches && accessories.Hitches.length > 0) {
            accessories.Hitches.forEach(function (hitch) {

                var hitchObject = chassisObjectToUse.getAccessoryHolder().getAccessoryBySourceDatabaseId(config.ACCESSORY_TYPES.HITCH, hitch.Id); 
                var internalId = hitchObject.getId();

                var orginalHitchObject = createAccessoryObject(hitch, internalId, rootPath);
                originalChassisObjectToUse.getAccessoryHolder().add(orginalHitchObject);

                hitchObject.setEditLevel(orginalHitchObject.getEditLevel());
            });

        }
        if (accessories.Hooklifts && accessories.Hooklifts.length > 0) {
            accessories.Hooklifts.forEach(function (hooklift) {

                var hookliftObject = chassisObjectToUse.getAccessoryHolder().getAccessoryBySourceDatabaseId(config.ACCESSORY_TYPES.HOOKLIFT, hooklift.Id);
                var internalId = hookliftObject.getId();

                var orginalHookliftObject = createAccessoryObject(hooklift, internalId, rootPath);
                originalChassisObjectToUse.getAccessoryHolder().add(orginalHookliftObject);

                hookliftObject.setEditLevel(orginalHookliftObject.getEditLevel());
            });
        }
    }


}

function addOriginalPayloadsToOriginalRig(originalPayloads) {
    var vehicleOriginalPayloads = extractPayloadsForChassis(originalPayloads, config.CHASSIS_OBJECT_TYPES.VEHICLE);
    addOriginalPayloads(getActiveOffer().getRig().getVehicle(), getOriginalOffer().getRig().getVehicle(), vehicleOriginalPayloads);

    if (getActiveOffer().getRig().getTrailer1() !== null) {
        var trailer1OriginalPayloads = extractPayloadsForChassis(originalPayloads, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        addOriginalPayloads(getActiveOffer().getRig().getTrailer1(), getOriginalOffer().getRig().getTrailer1(), trailer1OriginalPayloads);

        if (getActiveOffer().getRig().getTrailer2() !== null) {
            var trailer2OriginalPayloads = extractPayloadsForChassis(originalPayloads, config.CHASSIS_OBJECT_TYPES.TRAILER2);
            addOriginalPayloads(getActiveOffer().getRig().getTrailer2(), getOriginalOffer().getRig().getTrailer2(), trailer2OriginalPayloads);
        }
    }

    function addOriginalPayloads(chassisObjectToUse, originalChassisObjectToUse, payloads) {
        if (payloads.length > 0) {
            var payloadSourceDBIdCounterObject = {};
            payloads.forEach(function (payloadAsJSON) {

                if (payloadSourceDBIdCounterObject[payloadAsJSON.Id]) {
                    payloadSourceDBIdCounterObject[payloadAsJSON.Id]++;
                } else {
                    payloadSourceDBIdCounterObject[payloadAsJSON.Id] = 1;
                }

                var payloadObject = chassisObjectToUse.getPayloadHolder().getPayloadBySourceDatabaseId(payloadAsJSON.Id, payloadSourceDBIdCounterObject[payloadAsJSON.Id]);
                var internalId = payloadObject.getId();

                var orginalPayloadObject = createPayloadObject('NEW', payloadAsJSON, internalId); // User Story 17565
                originalChassisObjectToUse.getPayloadHolder().add(orginalPayloadObject);

                payloadObject.setEditLevel(orginalPayloadObject.getEditLevel());
            });
        }

    }
}

/*
if (accessories.Others && accessories.Others.length > 0) {
            var otherAccessorySourceDBIdCounterObject = {};
            accessories.Others.forEach(function (other) {
                
                if (otherAccessorySourceDBIdCounterObject[other.Id]) {
                    otherAccessorySourceDBIdCounterObject[other.Id]++;
                } else {
                    otherAccessorySourceDBIdCounterObject[other.Id] = 1;
                }
                var internalId = chassisObjectToUse.getAccessoryHolder().getAccessoryBySourceDatabaseId(other.Id, otherAccessorySourceDBIdCounterObject[other.Id]).getId();
                
                var orginalOtherObject = createAccessoryObject(other, internalId, rootPath);
                originalChassisObjectToUse.getAccessoryHolder().add(orginalOtherObject);
            });
        }
*/

function addOriginalTrailersToOriginalRig(originalTrailers) {
    if (originalTrailers.length > 0) {
        originalTrailers.forEach(function (trailerAsJSON) {
            var trailerObject = getActiveOffer().getRig().getTrailerBySourceDatabaseId(trailerAsJSON.Id); 
            var internalId = trailerObject.getId();

            var path = trailerAsJSON.RigPosition === 1 ? config.CHASSIS_OBJECT_TYPES.TRAILER1 : config.CHASSIS_OBJECT_TYPES.TRAILER2;
            trailerAsJSON.CostingListPrice = 0;
            trailerAsJSON.CostingListPriceOverride = false;
            trailerAsJSON.CostingDiscount = 0;
            if (getActiveOffer().getCosting() !== undefined && getActiveOffer().getCosting() !== null) {
                trailerAsJSON.CostingPeriod = getActiveOffer().getCosting().getVehicleCommonGroupFinancePeriod();
                trailerAsJSON.CostingInterestRate = getActiveOffer().getCosting().getCountryInterestRate();
            } else {
                trailerAsJSON.CostingPeriod = 0;
                trailerAsJSON.CostingInterestRate = 0;
            }
            trailerAsJSON.CostingResidualRate = 0;
            trailerAsJSON.CostingResidualRateType = config.RESIDUAL_RATE_TYPES.NET_PRICE;

            var orginalTrailerObject = createTrailerObject('NEW', trailerAsJSON, path, internalId); // User Story 17565
            getOriginalOffer().getRig().addChassisObject(orginalTrailerObject);

            trailerObject.setEditLevel(orginalTrailerObject.getEditLevel());
        });
    }
}

function populateAvailableBodiesArray(bodiesAsJSON) {
    availableBodies = [];
    var description;
    for (var i = 0; i < bodiesAsJSON.bodies.Bodies.length; i++) {
        var tempBody = bodiesAsJSON.bodies.Bodies[i];
        description = undefined;
        if (tempBody.Source === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
            description = config.getTranslationText('635', [tempBody.Description]);
        }

        availableBodies.push(new BodyStub(tempBody.Id, description || tempBody.Description, tempBody.Source, tempBody.AccessoryType, tempBody.AccessLevel || config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL, tempBody.Type, tempBody.FkTrailerId || 0, tempBody.UpdateCounter, tempBody.Make, tempBody.Range, tempBody.Status, tempBody.EditLevel, tempBody.cached, tempBody.SubType));
    }
}

function populateAvailableTrailersArray(trailersAsJSON) {
    availableTrailers = [];
    var description;
    for (var i = 0; i < trailersAsJSON.trailers.Trailers.length; i++) {
        var tempTrailer = trailersAsJSON.trailers.Trailers[i];
        description = undefined;
        if (tempTrailer.Source === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
            description = config.getTranslationText('635', [tempTrailer.Description]);
        }

        availableTrailers.push(new TrailerStub(tempTrailer.Id, description || tempTrailer.Description, tempTrailer.Source, tempTrailer.Type, tempTrailer.Status, tempTrailer.EditLevel, config.ACCESSORY_TYPES.TRAILER, tempTrailer.AccessLevel || config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL, tempTrailer.UpdateCounter, tempTrailer.Make, tempTrailer.Range, tempTrailer.BodyType, tempTrailer.cached));
    }
}

function populateAvailablePayloadsArray(payloadsAsJSON) {
    availablePayloads = [];
    var description;
    for (var i = 0; i < payloadsAsJSON.payloads.Payloads.length; i++) {
        var tempPayload = payloadsAsJSON.payloads.Payloads[i];
        description = undefined;
        if (tempPayload.Source === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
            description = config.getTranslationText('635', [tempPayload.Description]);
        }
        //id, description, source, accessoryType, fkTrailerId, updateCounter, cachedState
        availablePayloads.push(new PayloadStub(tempPayload.Id, description || tempPayload.Description, tempPayload.Source, tempPayload.Status, tempPayload.EditLevel, tempPayload.AccessoryType, tempPayload.AccessLevel || config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL, tempPayload.FkTrailerId, tempPayload.UpdateCounter, tempPayload.cached));
    }
}


function createBodyObject(bodyAsJSON, optionInternalId, availableBodyMasses) {

    var description = undefined;
    if (bodyAsJSON.Source === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
        description = config.getTranslationText('635', [globals.getBodyStubTypeText(bodyAsJSON.Type)]);
    }

    var internalId = optionInternalId || globals.nextId();

    var cogTypeDefaultValue = bodyAsJSON.COGType || bodyAsJSON.HorizontalCOGType;
    var payloadCOGTypeDefaultValue = bodyAsJSON.PayloadCOGType || bodyAsJSON.PayloadHorizontalCOGType;
    var rampPlatesCOGTypeDefaultValue = bodyAsJSON.RampPlatesCOGType;

    var defaultEditLevel = globals.getDefaultEditLevel(bodyAsJSON);

    var body = new Body(internalId, 'NONE', bodyAsJSON.Name, description || bodyAsJSON.Description, bodyAsJSON.Source, 0, 0, config.ACCESSORY_TYPES.BODY, bodyAsJSON.Graphic, bodyAsJSON.TopViewGraphicBlob, bodyAsJSON.Id, bodyAsJSON.SavedFromSourceDatabaseId || 0, bodyAsJSON.EditLevel || defaultEditLevel, bodyAsJSON.Type, bodyAsJSON.SubframeHeight, bodyAsJSON.SubstructureHeight,
        bodyAsJSON.HeadboardHeight, bodyAsJSON.TailboardHeight, bodyAsJSON.HeightType, bodyAsJSON.Height, bodyAsJSON.LengthType, bodyAsJSON.Length, bodyAsJSON.HingePoint,
        bodyAsJSON.Width, bodyAsJSON.WidthType, bodyAsJSON.Mass, bodyAsJSON.MassOverride || false, bodyAsJSON.CabGapOverride || false, bodyAsJSON.MassType, bodyAsJSON.HeadboardThickness, bodyAsJSON.TailboardThickness, bodyAsJSON.RoofThickness,
        bodyAsJSON.SidewallRight, bodyAsJSON.SidewallLeft, bodyAsJSON.Material, bodyAsJSON.FloorType, cogTypeDefaultValue, bodyAsJSON.COG || bodyAsJSON.HorizontalCOG, bodyAsJSON.HorizontalCOGOverride || false, bodyAsJSON.PayloadCOGType || bodyAsJSON.PayloadHorizontalCOGType,
        bodyAsJSON.PayloadCOG || bodyAsJSON.PayloadHorizontalCOG, bodyAsJSON.TarpCoverMassType, bodyAsJSON.TarpCoverMass, bodyAsJSON.VerticalCOG, bodyAsJSON.VerticalCOGOverride || false, bodyAsJSON.PayloadVerticalCOG,
        bodyAsJSON.RampLength, bodyAsJSON.RampAngle, bodyAsJSON.RampPlatesProtrusion, bodyAsJSON.RampPlatesHeight, bodyAsJSON.RampPlatesMass, bodyAsJSON.RampPlatesCOGType, bodyAsJSON.RampPlatesHorizontalCOG, bodyAsJSON.RampPlatesHorizontalCOGOverride || false, bodyAsJSON.RampPlatesVerticalCOG, false,
        bodyAsJSON.SubframeLength, bodyAsJSON.PayloadVerticalCOGOverride || false, bodyAsJSON.LateralCOG, bodyAsJSON.LateralCOGOverride || false, bodyAsJSON.PayloadLateralCOG, bodyAsJSON.PayloadLateralCOGOverride || false, bodyAsJSON.RampPlatesLateralCOG, bodyAsJSON.RampPlatesLateralCOGOverride || false,
        bodyAsJSON.BottomOfBodyToTopOfChassis, bodyAsJSON.LeftCompartmentWidth, bodyAsJSON.RightCompartmentWidth,
        bodyAsJSON.VerticalCOGType || cogTypeDefaultValue, bodyAsJSON.LateralCOGType || cogTypeDefaultValue, bodyAsJSON.PayloadVerticalCOGType || payloadCOGTypeDefaultValue, bodyAsJSON.PayloadLateralCOGType || payloadCOGTypeDefaultValue, bodyAsJSON.RampPlatesVerticalCOGType || rampPlatesCOGTypeDefaultValue, bodyAsJSON.RampPlatesLateralCOGType || rampPlatesCOGTypeDefaultValue,
        bodyAsJSON.VerticalCOGOverride, bodyAsJSON.RampPlatesVerticalCOGOverride, bodyAsJSON.PayloadVerticalCOGOverride, bodyAsJSON.LateralCOGOverride, bodyAsJSON.PayloadLateralCOGOverride, bodyAsJSON.RampPlatesLateralCOGOverride,
        bodyAsJSON.UpdateCounter, bodyAsJSON.cached, bodyAsJSON.SideDoors, bodyAsJSON.SideDoorType, bodyAsJSON.FrontToDoor, bodyAsJSON.WidthPerDoor, bodyAsJSON.DoorHeight, availableBodyMasses,
        bodyAsJSON.CostingListPrice, bodyAsJSON.CostingListPriceOverride, bodyAsJSON.CostingDiscount, bodyAsJSON.CostingPeriod, bodyAsJSON.CostingInterestRate, bodyAsJSON.CostingResidualRate, bodyAsJSON.CostingResidualRateType,
        bodyAsJSON.AboveBelowChassis || 0, bodyAsJSON.AboveBelowChassisOverride || false, bodyAsJSON.SubType);
    //costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType

    // Add uprights here
    if (body.getType() === config.BODY_TYPES.TIMBER) {
        if (bodyAsJSON.Uprights && bodyAsJSON.Uprights.length > 0) {
            bodyAsJSON.Uprights.forEach(function (upright) {
                body.getBodyUprightHolder().add(new BodyUpright(globals.nextId(), upright.Number, upright.InsideFrontToUpright, upright.Width, upright.Height));
            });
        }
    }
                

    return body;
}



function addBodyToAvailableBodiesArray(bodyAsJSON, bodyDetails) {
    var cachedVal = 2;

    var accessoryType = bodyDetails !== undefined ? bodyDetails.getAccessoryType() : bodyAsJSON.AccessoryType;
    var accessLevel = bodyDetails !== undefined ? bodyDetails.getAccessLevel() : bodyAsJSON.AccessLevel;
    var bodyType = bodyDetails !== undefined ? bodyDetails.getType() : bodyAsJSON.Type;

    if (accessLevel === config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY) {
        cachedVal = 0;
    }

    availableBodies.push(new BodyStub(bodyAsJSON.AccessoryId || bodyAsJSON.Id, bodyAsJSON.Description, bodyAsJSON.Source, accessoryType, accessLevel, bodyType, bodyAsJSON.FkTrailerId || 0, bodyAsJSON.UpdateCounter, bodyAsJSON.Make, bodyAsJSON.Range, bodyAsJSON.Status, bodyAsJSON.EditLevel, cachedVal, bodyAsJSON.SubType));
}

function addAccessoryToAvailableAccessoriesArray(accessoryAsJSON, accessoryDetails) {
    var cachedVal = 2;

    var accessoryType = accessoryDetails !== undefined ? accessoryDetails.getAccessoryType() : accessoryAsJSON.AccessoryType;
    var accessLevel = accessoryDetails !== undefined ? accessoryDetails.getAccessLevel() : accessoryAsJSON.AccessLevel;
    //var editLevel = accessoryDetails !== undefined ? accessoryDetails.getEditLevel() : accessoryAsJSON.EditLevel;
    var accessorySubType = accessoryDetails !== undefined ? accessoryDetails.getAccessorySubType() : accessoryAsJSON.Type;

    if (accessLevel === config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY) {
        cachedVal = 0;
    }
    availableAccessories.push(new AccessoryStub(accessoryAsJSON.AccessoryId || accessoryAsJSON.Id, accessoryAsJSON.Description, accessoryAsJSON.Source, accessoryType, accessLevel, accessoryAsJSON.FkTrailerId || 0, accessoryAsJSON.UpdateCounter, cachedVal, accessorySubType, accessoryAsJSON.Make, accessoryAsJSON.Range, accessoryAsJSON.Status, accessoryAsJSON.EditLevel));
    //availableAccessories.push(new AccessoryStub(accessoryAsJSON.AccessoryId || accessoryAsJSON.Id, accessoryAsJSON.Description, accessoryAsJSON.Source, accessoryType, accessLevel, accessoryAsJSON.FkTrailerId || 0, accessoryAsJSON.UpdateCounter, cachedVal, accessorySubType, accessoryAsJSON.Make, accessoryAsJSON.Range, accessoryAsJSON.Status, editLevel));
}

function addTrailerToAvailableTrailersArray(trailerAsJSON, trailerDetails) {
    var cachedVal = 2;

    var accessLevel = trailerDetails !== undefined ? trailerDetails.getAccessLevel() : trailerAsJSON.AccessLevel;

    if (accessLevel === config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY) {
        cachedVal = 0;
    }
    availableTrailers.push(new TrailerStub(trailerAsJSON.AccessoryId || trailerAsJSON.Id, trailerAsJSON.Description, trailerAsJSON.Source, trailerAsJSON.Type, trailerAsJSON.Status, trailerAsJSON.EditLevel, config.ACCESSORY_TYPES.TRAILER, accessLevel, trailerAsJSON.UpdateCounter, trailerAsJSON.Make, trailerAsJSON.Range, trailerAsJSON.BodyType, cachedVal));
}

function addPayloadToAvailablePayloadsArray(payloadAsJSON, payloadDetails) {
    var cachedVal = 2;

    var accessoryType = payloadDetails !== undefined ? payloadDetails.getAccessoryType() : payloadAsJSON.AccessoryType;
    var accessLevel = payloadDetails !== undefined ? payloadDetails.getAccessLevel() : payloadAsJSON.AccessLevel;

    if (accessLevel === config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY) {
        cachedVal = 0;
    }
    availablePayloads.push(new PayloadStub(payloadAsJSON.AccessoryId || payloadAsJSON.Id, payloadAsJSON.Description, payloadAsJSON.Source, payloadAsJSON.Status, payloadAsJSON.EditLevel, accessoryType, accessLevel, payloadAsJSON.FkTrailerId || 0, payloadAsJSON.UpdateCounter, cachedVal));
}

function updateBodyOnAvailableBodiesArray(bodyAsJSON, optional_idPriorToSync) {
    try {
        var idToCheck = optional_idPriorToSync !== undefined ? optional_idPriorToSync : bodyAsJSON.AccessoryId !== undefined ? bodyAsJSON.AccessoryId : bodyAsJSON.Id;
        var indexToUpdate = availableBodies.findIndex(function (bodyStub) {
            return idToCheck === bodyStub.getId() && bodyAsJSON.AccessoryType === bodyStub.getAccessoryType() && bodyAsJSON.Source === bodyStub.getSource();
        });

        availableBodies[indexToUpdate].setDescription(bodyAsJSON.Description);
        availableBodies[indexToUpdate].setSource(bodyAsJSON.Source);
        availableBodies[indexToUpdate].setId(bodyAsJSON.AccessoryId || bodyAsJSON.Id);
        availableBodies[indexToUpdate].setType(bodyAsJSON.Type);
        availableBodies[indexToUpdate].setAccessLevel(bodyAsJSON.AccessLevel);
        availableBodies[indexToUpdate].setUpdateCounter(bodyAsJSON.UpdateCounter);
        if (bodyAsJSON.AccessLevel === config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY) {
            availableBodies[indexToUpdate].setCached(0);
        } else {
            availableBodies[indexToUpdate].setCached(2);
        }
    } catch (bodyNotFoundEx) {
        throw 1;
    }
}

function updateAccessoryOnAvailableAccessoriesArray(accessoryAsJSON, optional_idPriorToSync) {
    try {
        var idToCheckFor = optional_idPriorToSync !== undefined ? optional_idPriorToSync : accessoryAsJSON.AccessoryId !== undefined ? accessoryAsJSON.AccessoryId : accessoryAsJSON.Id;
        var indexToUpdate = availableAccessories.findIndex(function (accessory) {
            return idToCheckFor === accessory.getId() && accessoryAsJSON.Source === accessory.getSource() && accessoryAsJSON.AccessoryType === accessory.getAccessoryType();
        });
        availableAccessories[indexToUpdate].setDescription(accessoryAsJSON.Description);
        availableAccessories[indexToUpdate].setSource(accessoryAsJSON.Source);
        availableAccessories[indexToUpdate].setId(accessoryAsJSON.Id || accessoryAsJSON.AccessoryId);
        availableAccessories[indexToUpdate].setAccessLevel(accessoryAsJSON.AccessLevel);
        availableAccessories[indexToUpdate].setUpdateCounter(accessoryAsJSON.UpdateCounter);
        if (accessoryAsJSON.AccessLevel === config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY) {
            availableAccessories[indexToUpdate].setCached(0);
        } else {
            availableAccessories[indexToUpdate].setCached(2);
        }
    } catch (accessoryNotFoundEx) {
        throw 1;
    }
}

function updateTrailerOnAvailableTrailersArray(trailerAsJSON, optional_idPriorToSync) {
    try {
        var idToCheckFor = optional_idPriorToSync !== undefined ? optional_idPriorToSync : trailerAsJSON.AccessoryId !== undefined ? trailerAsJSON.AccessoryId : trailerAsJSON.Id;
        var indexToUpdate = availableTrailers.findIndex(function (trailer) {
            return idToCheckFor === trailer.getId() && trailerAsJSON.Source === trailer.getSource() && trailerAsJSON.AccessoryType === trailer.getAccessoryType();
        });
        availableTrailers[indexToUpdate].setDescription(trailerAsJSON.Description);
        availableTrailers[indexToUpdate].setSource(trailerAsJSON.Source);
        availableTrailers[indexToUpdate].setId(trailerAsJSON.Id || trailerAsJSON.AccessoryId);
        availableTrailers[indexToUpdate].setAccessLevel(trailerAsJSON.AccessLevel);
        availableTrailers[indexToUpdate].setUpdateCounter(trailerAsJSON.UpdateCounter);

        if (trailerAsJSON.AccessLevel === config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY) {
            availableTrailers[indexToUpdate].setCached(0);
        } else {
            availableTrailers[indexToUpdate].setCached(2);
        }
    } catch (trailerNotFoundEx) {
        throw 1;
    }
}

function updatePayloadOnAvailablePayloadsArray(payloadAsJSON, optional_idPriorToSync) {
    try {

        var idToCheckFor = optional_idPriorToSync !== undefined ? optional_idPriorToSync : payloadAsJSON.AccessoryId !== undefined ? payloadAsJSON.AccessoryId : payloadAsJSON.Id;
        var indexToUpdate = availablePayloads.findIndex(function (payload) {
            return idToCheckFor === payload.getId() && payloadAsJSON.Source === payload.getSource() && payloadAsJSON.AccessoryType === payload.getAccessoryType();
        });
        availablePayloads[indexToUpdate].setDescription(payloadAsJSON.Description);
        availablePayloads[indexToUpdate].setSource(payloadAsJSON.Source);
        availablePayloads[indexToUpdate].setId(payloadAsJSON.Id || payloadAsJSON.AccessoryId);
        availablePayloads[indexToUpdate].setAccessLevel(payloadAsJSON.AccessLevel);
        availablePayloads[indexToUpdate].setUpdateCounter(payloadAsJSON.UpdateCounter);
        if (payloadAsJSON.AccessLevel === config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY) {
            availablePayloads[indexToUpdate].setCached(0);
        } else {
            availablePayloads[indexToUpdate].setCached(2);
        }
    } catch (payloadNotFoundEx) {
        throw 1;
    }
}

function removeBodyFromAvailableBodiesArray(stubToRemove) {
    var indexOfElementToRemove = availableBodies.findIndex(function (bodyStub) {
        return stubToRemove.getId() === bodyStub.getId() && stubToRemove.getAccessoryType() === bodyStub.getAccessoryType() && stubToRemove.getSource() === bodyStub.getSource();
    });
    availableBodies.splice(indexOfElementToRemove, 1);
}

function removeAccessoryFromAvailableAccessoriesArray(stubToRemove) {
    var indexOfElementToRemove = availableAccessories.findIndex(function (accessoryStub) {
        return stubToRemove.getId() === accessoryStub.getId() && stubToRemove.getAccessoryType() === accessoryStub.getAccessoryType() && stubToRemove.getSource() === accessoryStub.getSource();
    });
    availableAccessories.splice(indexOfElementToRemove, 1);
}

function removeTrailerFromAvailableTrailersArray(stubToRemove) {
    var indexOfElementToRemove = availableTrailers.findIndex(function (trailerStub) {
        return stubToRemove.getId() === trailerStub.getId() && stubToRemove.getAccessoryType() === trailerStub.getAccessoryType() && stubToRemove.getSource() === trailerStub.getSource();
    });
    availableTrailers.splice(indexOfElementToRemove, 1);
}

function removePayloadFromAvailablePayloadsArray(stubToRemove) {
    var indexOfElementToRemove = availablePayloads.findIndex(function (payloadStub) {
        return stubToRemove.getId() === payloadStub.getId() && stubToRemove.getAccessoryType() === payloadStub.getAccessoryType() && stubToRemove.getSource() === payloadStub.getSource();
    });
    availablePayloads.splice(indexOfElementToRemove, 1);
}

function populateAvailableAccessoriesArray(accessoriesAsJSON) {
    //var tempVehicle = getConfiguration();
    var description; //, horizontalCogOverride = false, verticalCogOverride = false, horizontalGap = tempVehicle.getCabGapRecommendedMin(), verticalGap = tempVehicle.getChassisHeightMax();

    if (accessoriesAsJSON.accessories.Accessories !== undefined && accessoriesAsJSON.accessories.Accessories.length > 0) {
        var accessoriesArr = accessoriesAsJSON.accessories.Accessories;
        for (var i = 0; i < accessoriesArr.length; i++) {

            var tempAccessoryStub = accessoriesArr[i];

            description = undefined;
            if (tempAccessoryStub.Source === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
                description = config.getTranslationText('635', [tempAccessoryStub.Description]);
            }

            var newAccessoryStub = new AccessoryStub(tempAccessoryStub.Id, description || tempAccessoryStub.Description, tempAccessoryStub.Source, tempAccessoryStub.AccessoryType, tempAccessoryStub.AccessLevel || config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL, tempAccessoryStub.FkTrailerId || 0, tempAccessoryStub.UpdateCounter, tempAccessoryStub.cached, tempAccessoryStub.Type, tempAccessoryStub.Make, tempAccessoryStub.Range, tempAccessoryStub.Status, tempAccessoryStub.EditLevel);

            availableAccessories.push(newAccessoryStub);
        }
    }

}

function createItemObject(accessoryAsJSON, optionalInternalId, rootPath, cachedFromStub) {

    switch (accessoryAsJSON.AccessoryType) {
        case config.ACCESSORY_TYPES.BODY:
            return createBodyObject(accessoryAsJSON, optionalInternalId);
        case config.ACCESSORY_TYPES.CRANE:
        case config.ACCESSORY_TYPES.OTHER:
        case config.ACCESSORY_TYPES.FRIDGE:
        case config.ACCESSORY_TYPES.TAILLIFT:
        case config.ACCESSORY_TYPES.FIFTH_WHEEL:
        case config.ACCESSORY_TYPES.HITCH:
        case config.ACCESSORY_TYPES.HOOKLIFT:
            return createAccessoryObject(accessoryAsJSON, optionalInternalId, rootPath, cachedFromStub);

        case config.ACCESSORY_TYPES.TRAILER:
            //#unfinished, need to support trailer here
            break;
        case config.ACCESSORY_TYPES.PAYLOAD:
            return createPayloadObject("NEW", accessoryAsJSON); // User Story 17565
        default:
            break;
    }
    return null;
}

function createAccessoryObject(accessoryAsJSON, optionalInternalId, rootPath, cachedFromStub) {

    var internalId = optionalInternalId || globals.nextId();

    //var tempVehicle = getConfiguration();
    var description, horizontalCogOverride = false,
        verticalCogOverride = false,
        lateralCogOverride = false,
        horizontalGap = 0,
        verticalGap = 0;
    var verticalCOGConfirmed, lateralCOGConfirmed;

    var chassisObjectToUse = rigOps.getChassisObjectFromPath(rootPath);

    if (accessoryAsJSON.Source === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
        description = config.getTranslationText('635', [globals.getAccessoryTypeTranslation(accessoryAsJSON.AccessoryType, accessoryAsJSON.Type)]);
    }

    if (accessoryAsJSON.Source === config.VEHICLE_SOURCE_TYPES.STANDARD) {
        verticalCOGConfirmed = accessoryAsJSON.VerticalCOGOverride;
        lateralCOGConfirmed = accessoryAsJSON.LateralCOGOverride;
    } else {
        verticalCOGConfirmed = false;
        lateralCOGConfirmed = false;
    }

    var defaultEditLevel = globals.getDefaultEditLevel(accessoryAsJSON);

    var fromLeftOfVehicleRearWidth, defaultCOGType, financePeriod, financeInterestRate, fromLeftOfChassisRearWidth, aboveBelowChassis, fromBackOfCab;
    if (accessoryAsJSON.AccessoryType === config.ACCESSORY_TYPES.CRANE) {

        var tempCrane = accessoryAsJSON;

        fromLeftOfVehicleRearWidth = tempCrane.FromLeftOfVehicleRearWidth || 0;
        if (fromLeftOfVehicleRearWidth === 0 || tempCrane.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
            if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                fromLeftOfVehicleRearWidth = (chassisObjectToUse.getRearWidth() - tempCrane.Width) / 2;
            } else {
                fromLeftOfVehicleRearWidth = (chassisObjectToUse.getOverallWidth() - tempCrane.Width) / 2;
            }

        }

        defaultCOGType = tempCrane.COGType,
            financePeriod = 0,
            financeInterestRate = 0;

        if (getActiveOffer().getCosting() !== undefined && getActiveOffer().getCosting() !== null) {
            financePeriod = getActiveOffer().getCosting().getVehicleCommonGroupFinancePeriod();
            financeInterestRate = getActiveOffer().getCosting().getCountryInterestRate();
        }

        return new Crane(internalId, 'NONE', tempCrane.Name, description || tempCrane.Description, tempCrane.Source, horizontalGap, verticalGap,
            config.ACCESSORY_TYPES.CRANE, tempCrane.GraphicBlob, tempCrane.TopViewGraphicBlob, tempCrane.SavedFromSourceDatabaseId || 0, tempCrane.EditLevel || defaultEditLevel, tempCrane.SubframeHeight, tempCrane.Length, tempCrane.Width, tempCrane.Height, tempCrane.Mass, tempCrane.MassOverride || false, tempCrane.CabGapOverride || false,
            tempCrane.COGType, tempCrane.HorizontalCOG, horizontalCogOverride, tempCrane.VerticalCOG, verticalCogOverride, tempCrane.Status, tempCrane.LateralCOG, lateralCogOverride, fromLeftOfVehicleRearWidth || 0, tempCrane.AboveBelowChassis || 0,
            tempCrane.VerticalCOGType || defaultCOGType, tempCrane.LateralCOGType || defaultCOGType,
            verticalCOGConfirmed, lateralCOGConfirmed,
            tempCrane.Id, tempCrane.UpdateCounter, cachedFromStub,
            0, false, 0, financePeriod, financeInterestRate,
            0, config.RESIDUAL_RATE_TYPES.NET_PRICE);
        //costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType

    } else if (accessoryAsJSON.AccessoryType === config.ACCESSORY_TYPES.OTHER) {

        var tempOther = accessoryAsJSON;

        aboveBelowChassis = 0,
            fromBackOfCab = horizontalGap;

        fromLeftOfVehicleRearWidth = tempOther.FromLeftOfVehicleRearWidth || 0;
        if (fromLeftOfVehicleRearWidth === 0 || tempOther.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
            if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                fromLeftOfVehicleRearWidth = (chassisObjectToUse.getRearWidth() - tempOther.Width) / 2;
            } else {
                fromLeftOfVehicleRearWidth = (chassisObjectToUse.getOverallWidth() - tempOther.Width) / 2;
            }
        }

        defaultCOGType = tempOther.COGType,
            financePeriod = 0,
            financeInterestRate = 0;

        if (getActiveOffer().getCosting() !== undefined && getActiveOffer().getCosting() !== null) {
            financePeriod = getActiveOffer().getCosting().getVehicleCommonGroupFinancePeriod();
            financeInterestRate = getActiveOffer().getCosting().getCountryInterestRate();
        }

        return new Other(internalId, 'NONE', tempOther.Name, description || tempOther.Description, tempOther.Source, fromBackOfCab, aboveBelowChassis, config.ACCESSORY_TYPES.OTHER, tempOther.GraphicBlob, tempOther.TopViewGraphicBlob, tempOther.SavedFromSourceDatabaseId, tempOther.EditLevel || defaultEditLevel,
            tempOther.Length, tempOther.Width, tempOther.Height, tempOther.Mass, tempOther.MassOverride || false, tempOther.COGType, tempOther.HorizontalCOG,
            horizontalCogOverride, tempOther.VerticalCOG, verticalCogOverride, tempOther.Status, tempOther.LateralCOG, lateralCogOverride, fromLeftOfVehicleRearWidth,
            tempOther.VerticalCOGType || defaultCOGType, tempOther.LateralCOGType || defaultCOGType,
            verticalCOGConfirmed, lateralCOGConfirmed, tempOther.Type, tempOther.Id, tempOther.UpdateCounter, cachedFromStub,
            0, false, 0, financePeriod, financeInterestRate,
            0, config.RESIDUAL_RATE_TYPES.NET_PRICE);
        //costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType

    } else if (accessoryAsJSON.AccessoryType === config.ACCESSORY_TYPES.FRIDGE) {

        var tempFridge = accessoryAsJSON;

        fromLeftOfBodyWidthExternal = (chassisObjectToUse.getAccessoryHolder().getBody().getActualWidth() - tempFridge.Width) / 2;
        defaultCOGType = tempFridge.COGType,
            financePeriod = 0,
            financeInterestRate = 0;

        if (getActiveOffer().getCosting() !== undefined && getActiveOffer().getCosting() !== null) {
            financePeriod = getActiveOffer().getCosting().getVehicleCommonGroupFinancePeriod();
            financeInterestRate = getActiveOffer().getCosting().getCountryInterestRate();
        }

        return new Fridge(internalId, 'NONE', tempFridge.Name, description || tempFridge.Description, tempFridge.Source, horizontalGap, verticalGap, config.ACCESSORY_TYPES.FRIDGE, tempFridge.GraphicBlob, tempFridge.TopViewGraphicBlob, tempFridge.SavedFromSourceDatabaseId || 0, tempFridge.EditLevel || defaultEditLevel,
            tempFridge.Width, tempFridge.Height, tempFridge.Mass, tempFridge.MassOverride || false, tempFridge.COGType, tempFridge.HorizontalCOG,
            horizontalCogOverride, tempFridge.VerticalCOG, verticalCogOverride, tempFridge.Status, tempFridge.MountingPosition || 0, tempFridge.Protrusion, tempFridge.LateralCOG, lateralCogOverride, fromLeftOfBodyWidthExternal,
            tempFridge.VerticalCOGType || defaultCOGType, tempFridge.LateralCOGType || defaultCOGType,
            verticalCOGConfirmed, lateralCOGConfirmed, tempFridge.Id, tempFridge.UpdateCounter, cachedFromStub,
            0, false, 0, financePeriod, financeInterestRate,
            0, config.RESIDUAL_RATE_TYPES.NET_PRICE, tempFridge.FromFrontOfBody || 0);
        //costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType

    } else if (accessoryAsJSON.AccessoryType === config.ACCESSORY_TYPES.TAILLIFT) {
        var tempTaillift = accessoryAsJSON;

        //var tailliftHorizontalGap = getConfiguration().getBody().getHorizontalGap() + getConfiguration().getBody().getActualLength();
        //var tailliftVerticalGap = getConfiguration().getBody().getSubframeHeight();
        //var tailliftHeight = tempTaillift.Height;

        var aboveOrBelowFloorVal, mechanismLengthVal, platformProtrusionVal, mechanismHeight, platformHeight;
        if (tempTaillift.Type === config.VEHICLE_SOURCE_TYPES.STANDARD) {
            mechanismLengthVal = tempTaillift.InternalMountingLength + tempTaillift.RearClearance;
            platformProtrusionVal = tempTaillift.RearProtrusion - tempTaillift.RearClearance;
            //aboveOrBelowFloorVal = -(getConfiguration().getBody().getSubstructureHeight()) + tempTaillift.AboveOrBelowSubframe || 0;

        } else {
            mechanismLengthVal = tempTaillift.InternalMountingLength;
            platformProtrusionVal = tempTaillift.RearProtrusion;
            //aboveOrBelowFloorVal = 0;
        }



        var aboveOrBelowSubframe = tempTaillift.AboveOrBelowSubframe || 0;
        if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            aboveOrBelowFloorVal = aboveOrBelowSubframe;
        } else {
            aboveOrBelowFloorVal = -(chassisObjectToUse.getAccessoryHolder().getBody().getSubstructureHeight()) + aboveOrBelowSubframe;
        }


        if (tempTaillift.MechanismHeight === -1) {
            mechanismHeight = 500;
        } else {
            mechanismHeight = tempTaillift.MechanismHeight;
        }
        if (tempTaillift.Height < 0) {
            platformHeight = 0;
        } else {
            platformHeight = tempTaillift.Height;
        }
        var fromLeftOfBodyWidthExternal;
        if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            fromLeftOfBodyWidthExternal = (chassisObjectToUse.getRearWidth() - tempTaillift.Width) / 2;
        } else {
            fromLeftOfBodyWidthExternal = (chassisObjectToUse.getAccessoryHolder().getBody().getActualWidth() - tempTaillift.Width) / 2;
        }

        defaultCOGType = tempTaillift.COGType,
            financePeriod = 0,
            financeInterestRate = 0;

        if (getActiveOffer().getCosting() !== undefined && getActiveOffer().getCosting() !== null) {
            financePeriod = getActiveOffer().getCosting().getVehicleCommonGroupFinancePeriod();
            financeInterestRate = getActiveOffer().getCosting().getCountryInterestRate();
        }

        return new Taillift(internalId, 'NONE', tempTaillift.Name, description || tempTaillift.Description, tempTaillift.Source, horizontalGap, verticalGap, config.ACCESSORY_TYPES.TAILLIFT, tempTaillift.GraphicBlob, tempTaillift.TopViewGraphicBlob, tempTaillift.SavedFromSourceDatabaseId, tempTaillift.EditLevel || defaultEditLevel,
            tempTaillift.Width, platformHeight, tempTaillift.Mass, tempTaillift.MassOverride || false, tempTaillift.COGType, tempTaillift.HorizontalCOG,
            horizontalCogOverride, tempTaillift.VerticalCOG, verticalCogOverride, tempTaillift.LateralCOG, lateralCogOverride, fromLeftOfBodyWidthExternal, tempTaillift.Status, tempTaillift.Type, tempTaillift.RearClearance || 0, mechanismLengthVal,
            platformProtrusionVal, aboveOrBelowFloorVal, mechanismHeight,
            tempTaillift.VerticalCOGType || defaultCOGType, tempTaillift.LateralCOGType || defaultCOGType,
            verticalCOGConfirmed, lateralCOGConfirmed, tempTaillift.Id, tempTaillift.UpdateCounter, cachedFromStub,
            0, false, 0, financePeriod, financeInterestRate,
            0, config.RESIDUAL_RATE_TYPES.NET_PRICE);
        //costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType

    } else if (accessoryAsJSON.AccessoryType === config.ACCESSORY_TYPES.FIFTH_WHEEL) {

        var tempFifthWheel = accessoryAsJSON;


        var fifthWheelOffset = getConfiguration().getFifthWheelOffset();
        //if (tempFifthWheel.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM) {//#unfinished, this will need to take different chassis into account when we add support for fifth wheel on trailer
        //    fifthWheelOffset = tempFifthWheel.Offset;
        //} else {
        //    fifthWheelOffset = getConfiguration().getFifthWheelOffset();
        //}
        fromLeftOfChassisRearWidth = rigOps.getChassisRearWidth(chassisObjectToUse) / 2;
        defaultCOGType = tempFifthWheel.COGType,
            financePeriod = 0,
            financeInterestRate = 0;

        if (getActiveOffer().getCosting() !== undefined && getActiveOffer().getCosting() !== null) {
            financePeriod = getActiveOffer().getCosting().getVehicleCommonGroupFinancePeriod();
            financeInterestRate = getActiveOffer().getCosting().getCountryInterestRate();
        }

        return new FifthWheel(internalId, 'NONE', tempFifthWheel.Name, description || tempFifthWheel.Description, tempFifthWheel.Source, horizontalGap, verticalGap, config.ACCESSORY_TYPES.FIFTH_WHEEL, tempFifthWheel.GraphicBlob, tempFifthWheel.TopViewGraphicBlob, tempFifthWheel.SavedFromSourceDatabaseId, tempFifthWheel.EditLevel || defaultEditLevel,
            fifthWheelOffset, tempFifthWheel.FrontOverhang, tempFifthWheel.Height, tempFifthWheel.Length, tempFifthWheel.SubframeHeight, tempFifthWheel.Width, tempFifthWheel.Mass, tempFifthWheel.MassOverride || false, tempFifthWheel.COGType, tempFifthWheel.HorizontalCOG,
            horizontalCogOverride, tempFifthWheel.VerticalCOG, verticalCogOverride, tempFifthWheel.Status, tempFifthWheel.LateralCOG, lateralCogOverride, fromLeftOfChassisRearWidth, tempFifthWheel.VerticalCOGType || defaultCOGType, tempFifthWheel.LateralCOGType || defaultCOGType,
            verticalCOGConfirmed, lateralCOGConfirmed, tempFifthWheel.Id, tempFifthWheel.UpdateCounter, cachedFromStub,
            0, false, 0, financePeriod, financeInterestRate,
            0, config.RESIDUAL_RATE_TYPES.NET_PRICE);
        //costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType

    } else if (accessoryAsJSON.AccessoryType === config.ACCESSORY_TYPES.HITCH) {

        var tempHitch = accessoryAsJSON;


        var offset, heightFromGround;
        offset = getConfiguration().getOverallLengthExclAccessories() - getConfiguration().getRearProtrusion() + tempHitch.CouplingOffset - (getConfiguration().getFrontOverhang() + getConfiguration().getAxle1FrontToWheelbaseTheoreticalStart() + getConfiguration().getWheelbaseTheoretical() + getConfiguration().getWheelbaseTheoreticalEndToRearMostDrivenAxle());
        //if (tempHitch.Type === config.HITCH_TYPES.CONVENTIONAL) {//#unfinished, this will need to take different chassis into account when we add support for hitch on trailer
        //    //offset = getConfiguration().getOverallLengthExclAccessories() - getConfiguration().getRearProtrusion() + tempHitch.CouplingOffset - (getConfiguration().getFrontOverhang() + getConfiguration().getAxle1FrontToWheelbaseTheoreticalStart() + getConfiguration().getWheelbaseTheoretical());
        //    heightFromGround = getConfiguration().getChassisHeightRear() - 200;
        //} else {
        //    //offset = getConfiguration().getWheelbaseTheoreticalEndToRearMostAxle() + ((getConfiguration().getAxleLastToChassisEnd() - getConfiguration().getRearProtrusion()) / 2);
        //    //heightFromGround = getConfiguration().getChassisHeightRear() - 500;
        //    heightFromGround = 400;
        //}
        heightFromGround = getHitchHeightFromGround(tempHitch.Type);

        fromLeftOfChassisRearWidth = rigOps.getChassisRearWidth(chassisObjectToUse) / 2;
        defaultCOGType = tempHitch.COGType,
            financePeriod = 0,
            financeInterestRate = 0;

        if (getActiveOffer().getCosting() !== undefined && getActiveOffer().getCosting() !== null) {
            financePeriod = getActiveOffer().getCosting().getVehicleCommonGroupFinancePeriod();
            financeInterestRate = getActiveOffer().getCosting().getCountryInterestRate();
        }

        return new Hitch(internalId, 'NONE', tempHitch.Name, description || tempHitch.Description, tempHitch.Source, horizontalGap, verticalGap, config.ACCESSORY_TYPES.HITCH, tempHitch.GraphicBlob, tempHitch.TopViewGraphicBlob, tempHitch.SavedFromSourceDatabaseId, tempHitch.EditLevel || defaultEditLevel,
            tempHitch.Type, tempHitch.Status, offset, tempHitch.Height, heightFromGround, tempHitch.CouplingHeight, tempHitch.Width, tempHitch.Mass, tempHitch.MassOverride || false, tempHitch.MountingLength, tempHitch.CouplingOffset, tempHitch.FunnelLength,
            tempHitch.COGType, tempHitch.HorizontalCOG, horizontalCogOverride, tempHitch.VerticalCOG, verticalCogOverride, tempHitch.LateralCOG, lateralCogOverride, fromLeftOfChassisRearWidth, tempHitch.VerticalCOGType || defaultCOGType, tempHitch.LateralCOGType || defaultCOGType,
            verticalCOGConfirmed, lateralCOGConfirmed,
            tempHitch.Id, tempHitch.UpdateCounter, cachedFromStub,
            0, false, 0, financePeriod, financeInterestRate,
            0, config.RESIDUAL_RATE_TYPES.NET_PRICE);
        //costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType

    } else if (accessoryAsJSON.AccessoryType === config.ACCESSORY_TYPES.HOOKLIFT) {

        var tempHooklift = accessoryAsJSON;

        aboveBelowChassis = 0,
            fromBackOfCab = horizontalGap;

        fromLeftOfVehicleRearWidth = tempHooklift.FromLeftOfVehicleRearWidth || 0;
        if (fromLeftOfVehicleRearWidth === 0 || tempHooklift.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
            if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                fromLeftOfVehicleRearWidth = (chassisObjectToUse.getRearWidth() - tempHooklift.Width) / 2;
            } else {
                fromLeftOfVehicleRearWidth = (chassisObjectToUse.getOverallWidth() - tempHooklift.Width) / 2;
            }
        }

        defaultCOGType = tempHooklift.COGType,
            financePeriod = 0,
            financeInterestRate = 0;

        if (getActiveOffer().getCosting() !== undefined && getActiveOffer().getCosting() !== null) {
            financePeriod = getActiveOffer().getCosting().getVehicleCommonGroupFinancePeriod();
            financeInterestRate = getActiveOffer().getCosting().getCountryInterestRate();
        }

        return new Hooklift(internalId, 'NONE', tempHooklift.Name, description || tempHooklift.Description, tempHooklift.Source, fromBackOfCab, aboveBelowChassis, config.ACCESSORY_TYPES.HOOKLIFT, tempHooklift.GraphicBlob, tempHooklift.TopViewGraphicBlob, tempHooklift.SavedFromSourceDatabaseId, tempHooklift.EditLevel || defaultEditLevel,
            tempHooklift.Length, tempHooklift.Width, tempHooklift.Height, tempHooklift.Mass, tempHooklift.MassOverride || false, false, tempHooklift.COGType, tempHooklift.HorizontalCOG,
            horizontalCogOverride, tempHooklift.VerticalCOG, verticalCogOverride, tempHooklift.Status, tempHooklift.LateralCOG, lateralCogOverride, fromLeftOfVehicleRearWidth,
            tempHooklift.VerticalCOGType || defaultCOGType, tempHooklift.LateralCOGType || defaultCOGType,
            verticalCOGConfirmed, lateralCOGConfirmed, tempHooklift.Type, tempHooklift.Id, tempHooklift.UpdateCounter, cachedFromStub,
            0, false, 0, financePeriod, financeInterestRate,
            0, config.RESIDUAL_RATE_TYPES.NET_PRICE, tempHooklift.AFrame, tempHooklift.HookOffset, tempHooklift.CentreOfRoller, tempHooklift.PlatformHeight);
        //costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType
    }
}

function getHitchHeightFromGround(hitchType) {
    if (hitchType === config.HITCH_TYPES.CONVENTIONAL) { //#unfinished, this will need to take different chassis into account when we add support for hitch on trailer
        //offset = getConfiguration().getOverallLengthExclAccessories() - getConfiguration().getRearProtrusion() + tempHitch.CouplingOffset - (getConfiguration().getFrontOverhang() + getConfiguration().getAxle1FrontToWheelbaseTheoreticalStart() + getConfiguration().getWheelbaseTheoretical());
        return getConfiguration().getChassisHeightRear() - 200;
    } else {
        //offset = getConfiguration().getWheelbaseTheoreticalEndToRearMostAxle() + ((getConfiguration().getAxleLastToChassisEnd() - getConfiguration().getRearProtrusion()) / 2);
        //heightFromGround = getConfiguration().getChassisHeightRear() - 500;
        return 400;
    }
}

function createTrailerObject(newOrExistingFlag, trailerAsJSON, path, optionInternalId) {

    var tareFrontOverride = newOrExistingFlag === 'NEW' ? false  : trailerAsJSON.TareFrontOverride || false,
        tareRearOverride = newOrExistingFlag === 'NEW' ? false  : trailerAsJSON.TareRearOverride || false,
        permissibleFrontOverride = newOrExistingFlag === 'NEW' ? false  : trailerAsJSON.PermissibleFrontOverride || false,
        permissibleRearOverride = newOrExistingFlag === 'NEW' ? false  : trailerAsJSON.PermissibleRearOverride || false,
        manufacturersFrontAxlesMassOverride = newOrExistingFlag === 'NEW' ? false  : trailerAsJSON.ManufacturersFrontAxlesMassOverride || false,
        manufacturersRearAxlesMassOverride = newOrExistingFlag === 'NEW' ? false  : trailerAsJSON.ManufacturersRearAxlesMassOverride || false;
    var description = undefined;
    var kingpinHeightSameAsVehicleFifthWheelHeight;
    var newTrailerChassisHeight;
    var rigPosition;

    var verticalCOGOverride, verticalCOGConfirmed, payloadVerticalCOGOverride, payloadVerticalCOGConfirmed, lateralCOGOverride, lateralCOGConfirmed, payloadLateralCOGOverride, payloadLateralCOGConfirmed;

    if (newOrExistingFlag === 'NEW') { // User Story 17565
        if (trailerAsJSON.Source === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
            description = config.getTranslationText('635', [globals.getTrailerTypeText(trailerAsJSON.Type, path)]);

        }

        if (trailerAsJSON.Type === config.TRAILER_TYPES.SEMI || trailerAsJSON.Type === config.TRAILER_TYPES.INTERLINK) {
            if (getConfiguration().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
                kingpinHeightSameAsVehicleFifthWheelHeight = getConfiguration().getFifthWheelHeightFromGround();
            } else {
                kingpinHeightSameAsVehicleFifthWheelHeight = getConfiguration().getAccessoryHolder().getFifthWheel().getSubframeHeight() + getConfiguration().getAccessoryHolder().getFifthWheel().getHeight() + getConfiguration().getChassisHeightMax();
            }

            if (trailerAsJSON.Source === config.VEHICLE_SOURCE_TYPES.STANDARD || trailerAsJSON.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
                newTrailerChassisHeight = trailerAsJSON.ChassisHeight;
            } else {
                newTrailerChassisHeight = kingpinHeightSameAsVehicleFifthWheelHeight + 100;
            }
        } else if (trailerAsJSON.Type === config.TRAILER_TYPES.DRAWBAR || trailerAsJSON.Type === config.TRAILER_TYPES.PUP) {
            kingpinHeightSameAsVehicleFifthWheelHeight = 0;
            newTrailerChassisHeight = trailerAsJSON.ChassisHeight;
            if (trailerAsJSON.Source !== config.VEHICLE_SOURCE_TYPES.STANDARD && !(trailerAsJSON.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(trailerAsJSON))) {
                trailerAsJSON.DrawbarHeight = Math.round(getActiveOffer().getRig().getVehicle().getAccessoryHolder().getHitch().getHeightFromGround());
            }
        }

        if (path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
            rigPosition = 1;
        } else {
            rigPosition = 2;
        }

        verticalCOGOverride = false;
        verticalCOGConfirmed = trailerAsJSON.VerticalCOGOverride || false;
        payloadVerticalCOGOverride = false;
        payloadVerticalCOGConfirmed = trailerAsJSON.PayloadVerticalCOGOverride || false;
        lateralCOGOverride = false;
        lateralCOGConfirmed = trailerAsJSON.LateralCOGOverride || false;
        payloadLateralCOGOverride = false;
        payloadLateralCOGConfirmed = trailerAsJSON.PayloadLateralCOGOverride || false;

        permissibleFrontOverride = false;
        permissibleRearOverride = false;

        if (trailerAsJSON.Source === config.VEHICLE_SOURCE_TYPES.TEMPLATE || (trailerAsJSON.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM && !utils.itemHasGraphic(trailerAsJSON))) {

            // this is required when an attribute has and override but the override is not shown
            manufacturersFrontAxlesMassOverride = true;
            manufacturersRearAxlesMassOverride = true;
        } else {
            manufacturersFrontAxlesMassOverride = false;
            manufacturersRearAxlesMassOverride = false;
        }

    } else {
        verticalCOGOverride = trailerAsJSON.VerticalCOGOverride;
        verticalCOGConfirmed = false;
        payloadVerticalCOGOverride = trailerAsJSON.PayloadVerticalCOGOverride;
        payloadVerticalCOGConfirmed = false;
        lateralCOGOverride = trailerAsJSON.LateralCOGOverride;
        lateralCOGConfirmed = false;
        payloadLateralCOGOverride = trailerAsJSON.PayloadLateralCOGOverride;
        payloadLateralCOGConfirmed = false;
    }

    var defaultEditLevel = globals.getDefaultEditLevel(trailerAsJSON);

    var internalId = optionInternalId || globals.nextId(),
        trailerFrontAxleAverageTyreLife = trailerAsJSON.FrontAxleAverageTyreLife || getActiveOffer().getConfiguration().getTrailerFrontAxleAverageTyreLife(),
        trailerRearAxleAverageTyreLife = trailerAsJSON.RearAxleAverageTyreLife || getActiveOffer().getConfiguration().getTrailerRearAxleAverageTyreLife(),
        trailerPriceRemainingSets = getActiveOffer().getConfiguration().getTyrePriceRemainingSets(),
        fuelAndAdblueEquipmentFuelCostPerVolume;

    if(trailerAsJSON.FuelAndAdblueEquipmentFuelCostPerVolumeOverride === false && trailerAsJSON.FuelAndAdblueEquipmentFuelCostPerVolume === 0 && getActiveOffer().getCosting()) { 
        fuelAndAdblueEquipmentFuelCostPerVolume = getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelCostPerVolumeOnDatabase();
    } else {
        fuelAndAdblueEquipmentFuelCostPerVolume = trailerAsJSON.FuelAndAdblueEquipmentFuelCostPerVolume
    }

    var newTrailer = new Trailer(internalId, rigOps.getChassisTypeUsingPath(path), trailerAsJSON.Name, description || trailerAsJSON.Description, trailerAsJSON.Source, 0, 0, config.ACCESSORY_TYPES.TRAILER, trailerAsJSON.GraphicBlob, trailerAsJSON.TopViewGraphicBlob, trailerAsJSON.Id || trailerAsJSON.SourceDatabaseId, trailerAsJSON.SavedFromSourceDatabaseId || 0, trailerAsJSON.EditLevel || defaultEditLevel, trailerAsJSON.Type, trailerAsJSON.NumberOfAxlesFront, trailerAsJSON.NumberOfAxlesRear,
        trailerAsJSON.GVM, trailerAsJSON.OverallWidth, kingpinHeightSameAsVehicleFifthWheelHeight || trailerAsJSON.KingpinHeight, newTrailerChassisHeight || trailerAsJSON.ChassisHeight, trailerAsJSON.OverallHeight, trailerAsJSON.FifthWheelOffset, trailerAsJSON.FifthWheelHeight, trailerAsJSON.DrawbarType, trailerAsJSON.DrawbarLength,
        trailerAsJSON.DrawbarHeight, trailerAsJSON.TurntableHeight, trailerAsJSON.TyreRadius, trailerAsJSON.FrontOverhang, trailerAsJSON.KingpinToLandingLegs, trailerAsJSON.HitchPointToLandingLegs, trailerAsJSON.Axle1FrontToAxle2Front, trailerAsJSON.Axle2FrontToAxle3Front,
        trailerAsJSON.RearMostFrontAxleToAxle1Rear, trailerAsJSON.HitchPointToAxle1Rear, trailerAsJSON.KingpinToAxle1Rear, trailerAsJSON.Axle1RearToAxle2Rear, trailerAsJSON.Axle2RearToAxle3Rear, trailerAsJSON.Axle3RearToAxle4Rear, trailerAsJSON.RearOverhang,
        trailerAsJSON.Axle1FrontToWheelbaseTheoreticalStart, trailerAsJSON.WheelbaseTheoretical, trailerAsJSON.FrontToStartOfTaper, trailerAsJSON.PayloadCOGType || '', trailerAsJSON.PayloadHorizontalCOG || 0, trailerAsJSON.MaterialOfChassis, trailerAsJSON.TareType, trailerAsJSON.TareTotal,
        trailerAsJSON.TareFront, tareFrontOverride, trailerAsJSON.TareRear, tareRearOverride, trailerAsJSON.ManufacturersFrontAxlesMass, trailerAsJSON.ManufacturersRearAxlesMass, trailerAsJSON.NumberOfTyresOnAxle1Front, trailerAsJSON.NumberOfTyresOnAxle2Front, trailerAsJSON.NumberOfTyresOnAxle3Front,
        trailerAsJSON.FrontRoadFriendlySuspension, trailerAsJSON.NumberOfTyresOnAxle1Rear, trailerAsJSON.NumberOfTyresOnAxle2Rear, trailerAsJSON.NumberOfTyresOnAxle3Rear, trailerAsJSON.NumberOfTyresOnAxle4Rear, trailerAsJSON.RearRoadFriendlySuspension, trailerAsJSON.HitchIncluded,
        trailerAsJSON.HitchOffset, trailerAsJSON.HitchHeight, trailerAsJSON.TyreWidth, trailerAsJSON.Status, trailerAsJSON.OverallHeightBreakpoint, trailerAsJSON.OverallHeightMaxCut, trailerAsJSON.DrawbarProtrusionBreakpoint, trailerAsJSON.DrawbarProtrusionMaxCut,
        trailerAsJSON.FrontOverhangBreakpoint, trailerAsJSON.FrontOverhangMaxCut, trailerAsJSON.WheelbaseTheoreticalBreakpoint, trailerAsJSON.WheelbaseTheoreticalMaxCut, trailerAsJSON.TrailerLengthBreakpoint, trailerAsJSON.TrailerLengthMaxCut, trailerAsJSON.BodyType, trailerAsJSON.Axles,
        trailerAsJSON.PermissibleFront || 0, permissibleFrontOverride, trailerAsJSON.PermissibleRear || 0, permissibleRearOverride,
        trailerAsJSON.WheelbaseTheoreticalChangeIncrement || 0, trailerAsJSON.LengthChangeIncrement || 0, trailerAsJSON.FrontOverhangChangeIncrement || 0, trailerAsJSON.OverallHeightChangeIncrement || 0, trailerAsJSON.DrawbarProtrusionChangeIncrement || 0,
        rigPosition || trailerAsJSON.RigPosition, manufacturersFrontAxlesMassOverride, manufacturersRearAxlesMassOverride,
        trailerAsJSON.VerticalCOG || 0, verticalCOGOverride, verticalCOGConfirmed, trailerAsJSON.PayloadVerticalCOG, payloadVerticalCOGOverride, payloadVerticalCOGConfirmed,
        trailerAsJSON.PayloadType, trailerFrontAxleAverageTyreLife, trailerRearAxleAverageTyreLife,
        trailerAsJSON.LateralCOG || 0, lateralCOGOverride, lateralCOGConfirmed, trailerAsJSON.PayloadLateralCOG, payloadLateralCOGOverride, payloadLateralCOGConfirmed, trailerPriceRemainingSets,
        trailerAsJSON.LicenceFees, trailerAsJSON.LicenceFeesOverride, trailerAsJSON.LicenceFeesRegionId,
        trailerAsJSON.UpdateCounter, trailerAsJSON.cached, trailerAsJSON.CostingListPrice, trailerAsJSON.CostingListPriceOverride, trailerAsJSON.CostingDiscount, trailerAsJSON.CostingPeriod, trailerAsJSON.CostingInterestRate, trailerAsJSON.CostingResidualRate, trailerAsJSON.CostingResidualRateType,
        trailerAsJSON.PayloadVerticalCOGType || '', trailerAsJSON.PayloadLateralCOGType || '', trailerAsJSON.FuelAndAdblueTotalCostPerMonth, 
        trailerAsJSON.FuelAndAdblueTotalCostPerMonthOverride, trailerAsJSON.FuelAndAdblueEquipmentFuelConsumption, 
        fuelAndAdblueEquipmentFuelCostPerVolume, trailerAsJSON.FuelAndAdblueEquipmentFuelCostPerVolumeOverride, trailerAsJSON.FuelAndAdblueEquipmentUtilisation,
        trailerAsJSON.GVMOverride || false
        //0, false, 0, getActiveOffer().getCosting().getVehicleCommonGroupFinancePeriod(), getActiveOffer().getCosting().getCountryInterestRate(), 0, config.RESIDUAL_RATE_TYPES.NET_PRICE
        //costingListPrice, costingListPriceOverride, costingDiscount, costingPeriod, costingInterestRate, costingResidualRate, costingResidualRateType
    );
    
    if (path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
        newTrailer.getAxlesHolder().setStartingNumberForAxles(getActiveOffer().getRig().getVehicle().getAxlesHolder().getNumberOfAxles());                
    } else {
        newTrailer.getAxlesHolder().setStartingNumberForAxles(getActiveOffer().getRig().getVehicle().getAxlesHolder().getNumberOfAxles() + getActiveOffer().getRig().getTrailer1().getAxlesHolder().getNumberOfAxles());           
    }
    if (newOrExistingFlag === 'NEW') { // User Story 17565
        newTrailer.getAxlesHolder().resetOverrides();
    }
    newTrailer.getAxlesHolder().resequence();
    /*
    this._wheelbaseTheoreticalChangeIncrement = 0;
this._lengthChangeIncrement = 0;
this._frontOverhangChangeIncrement = 0;
this._overallHeightChangeIncrement = 0;
    */

    newTrailer.setChassisObjectName(path);
    newTrailer.setRearOverhangAndCalcOverallLength(trailerAsJSON.RearOverhang);

    if (globals.chassisHasAddedEquipment(trailerAsJSON)) {

        newTrailer.getAccessoryHolder().addAccessories(trailerAsJSON.Accessories, newTrailer.getChassisHeight());
    }

    return newTrailer;

    /*
    (id, parent, name, description, source, horizontalGap, verticalGap, accessoryType, graphicBlob, sourceDatabaseId,
                type, numberOfAxlesFront, numberOfAxlesRear, gvm, overallWidth, kingpinHeight, chassisHeight, overallHeight, fifthWheelOffset, fifthWheelHeight, drawbarType, drawbarLength, drawbarHeight,
                turntableHeight, tyreRadius, frontOverhang, kingpinToLandingLegs, hitchPointToLandingLegs, axle1FrontToAxle2Front, axle2FrontToAxle3Front, rearMostFrontAxleToAxle1Rear, hitchPointToAxle1Rear,
                kingpinToAxle1Rear, axle1RearToAxle2Rear, axle2RearToAxle3Rear, axle3RearToAxle4Rear, rearOverhang, axle1FrontToWheelbaseTheoreticalStart, wheelbaseTheoretical, frontToStartOfTaper, payloadCOGType,
                payloadHorizontalCOG, materialOfChassis, tareType, tareTotal, tareFront, tareRear, manufacturerFrontAxlesMass, manufacturerRearAxlesMass, numberOfTyresOnAxle1Front, numberOfTyresOnAxle2Front, numberOfTyresOnAxle3Front,
                frontRoadFriendlySuspension, numberOfTyresOnAxle1Rear, numberOfTyresOnAxle2Rear, numberOfTyresOnAxle3Rear, numberOfTyresOnAxle4Rear, rearRoadFriendlySuspension, hitchIncluded, hitchOffset, hitchHeight,
                tyreWidth, status, overallHeightBreakpoint, overallHeightMaxCut, drawbarProtrusionBreakpoint, drawbarProtrusionMaxCut, frontOverhangBreakpoint, frontOverhangMaxCut, wheelbaseTheoreticalBreakpoint,
                wheelbaseTheoreticalMaxCut, trailerLengthBreakpoint, trailerLengthMaxCut, bodyType,
                updateCounter, cached
    */
}

function createPayloadObject(newOrExistingFlag, payloadAsJSON, optionalInternalId) {


    var description, horizontalCogOverride = false,
        verticalCogOverride = false,
        horizontalGap = 0,
        verticalGap = 0;
    var verticalCOGConfirmed, lateralCOGConfirmed;
    var mass, unitOfMeasure;
    var startX = 0,
        startY = 0;

    //if (payloadAsJSON.Source === config.VEHICLE_SOURCE_TYPES.STANDARD) {
    //    verticalCOGConfirmed = payloadAsJSON.VerticalCOGOverride;
    //} else {
    //    verticalCOGConfirmed = false;
    //}
    mass = payloadAsJSON.Mass;
    if (newOrExistingFlag === 'NEW') { // User Story 17565

        if (payloadAsJSON.Source === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
            description = config.getTranslationText('635', [globals.getAccessoryTypeTranslation(payloadAsJSON.AccessoryType)]);
        }
        if ((payloadAsJSON.PayloadType === null || payloadAsJSON.PayloadType === config.PAYLOAD_TYPES.DETAILED) && payloadAsJSON.WeightType === config.PAYLOAD_WEIGHT_TYPES.WEIGHT && mass === 0) {
            mass = payloadAsJSON.Payload * payloadAsJSON.Density;
        }
        if (payloadAsJSON.Source !== config.VEHICLE_SOURCE_TYPES.CUSTOM) {
            unitOfMeasure = globals.user.getActiveMeasurementSystem().unitMass;
        } else {
            unitOfMeasure = payloadAsJSON.UnitOfMeasure;
        }
        
        verticalCOGConfirmed = payloadAsJSON.VerticalCOGOverride || false;
        lateralCOGConfirmed = payloadAsJSON.LateralCOGOverride || false;
    } else {
        
        unitOfMeasure = payloadAsJSON.UnitOfMeasure;
        verticalCOGConfirmed = false;
        lateralCOGConfirmed = false;

        startX = payloadAsJSON.StartX;
        startY = payloadAsJSON.StartY;
    }

    var internalId = optionalInternalId || globals.nextId();

    //var unitOfMeasure;
    //if (payloadAsJSON.UnitOfMeasure === undefined) {
    //    var predefinedItem = getUnitOfMeasureOptions().forEach(function (untiOfMeasureOption) {
    //        return untiOfMeasureOption.id === config.PAYLOAD_UNIT_OF_MEASURE_TYPES.MASS;
    //    })[0];
    //    unitOfMeasure = predefinedItem.description;
    //} else {
    //    unitOfMeasure = payloadAsJSON.UnitOfMeasure;
    //}
    var defaultEditLevel = globals.getDefaultEditLevel(payloadAsJSON);
    if (payloadAsJSON.PayloadType === config.PAYLOAD_TYPES.SIMPLE) {
        defaultEditLevel = config.ITEM_EDIT_LEVEL_OPTIONS.FULL_EDIT;
    }

    var defaultCOGType = payloadAsJSON.COGType;
    // User Story 17565
    var newPayload = new Payload(internalId, payloadAsJSON.ParentType || 'NONE', payloadAsJSON.Name, description || payloadAsJSON.Description, payloadAsJSON.Source, 0, 0, payloadAsJSON.AccessoryType, payloadAsJSON.GraphicBlob, payloadAsJSON.TopViewGraphicBlob, payloadAsJSON.SavedFromSourceDatabaseId, payloadAsJSON.EditLevel || defaultEditLevel, payloadAsJSON.Status || '', 0, startX, startY, payloadAsJSON.StartZ, payloadAsJSON.Length, payloadAsJSON.Width,
        payloadAsJSON.Height, mass, payloadAsJSON.COGType, payloadAsJSON.HorizontalCOG, payloadAsJSON.HorizontalCOGOverride || false, payloadAsJSON.VerticalCOG, false, payloadAsJSON.LateralCOG, false, verticalCOGConfirmed, lateralCOGConfirmed, payloadAsJSON.SpecifyMassOverride, payloadAsJSON.Payload,
        unitOfMeasure, payloadAsJSON.Density, payloadAsJSON.PayloadType || config.PAYLOAD_TYPES.DETAILED, payloadAsJSON.VerticalCOGType || defaultCOGType, payloadAsJSON.LateralCOGType || defaultCOGType, payloadAsJSON.WeightType,
        payloadAsJSON.Id, payloadAsJSON.UpdateCounter /*, payloadAsJSON.Cached*/);


    return newPayload;
}

function getAvailableBodies() {
    return availableBodies;
}

function setAvailableBodies(newValue) {
    availableBodies = newValue;
}

function getAvailableAccessories() {
    return availableAccessories;
}

function getAvailableTrailers() {
    return availableTrailers;
}

function setAvailableAccessories(newValue) {
    availableAccessories = newValue;
}

function setAvailableTrailers(newValue) {
    return availableTrailers = newValue;
}

function getAvailablePayloads() {
    return availablePayloads;
}

function setAvailablePayloads(newValue) {
    availablePayloads = newValue;
}

function areModulesOtherThanConfigurationAvailableInDataAvailability() {
    var dataAvailability = getActiveOffer().getDataAvailability();
    return dataAvailability.ActiveBrochure === true || dataAvailability.ActivePerformance === true || dataAvailability.ActiveCosting === true || dataAvailability.ActiveSpecification === true;
}

function areModulesOtherThanConfigurationAvailable() {
    return globals.user.displayOfferModuleOtherThanConfigAndSummary() && areModulesOtherThanConfigurationAvailableInDataAvailability();
}

function isConfigurationModuleAvailable() {
    return getActiveOffer().getDataAvailability().ActiveConfiguration === true && globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION.Code);
}
//#endregion Functions

//#region Update Observable Values
//NOTE: Have to add the observables from the brochure, configuration, costing, performance, specification and sentOffer
//      
function updateObservables() {
    if (getActiveOffer() !== '') {
        var vehiclePath = 'VEHICLE.';
        // Configuration
        ////   Configuration - Dimensions
        if (getActiveOffer().getDataAvailability().ActiveConfiguration && getActiveOffer().getConfiguration() !== undefined) {
            activeCabColour.value = getActiveOffer().getConfiguration().getActiveCabColour();
        }

        
        // Performance
        if (getActiveOffer().getDataAvailability().ActivePerformance && getActiveOffer().getPerformance() !== undefined && globals.user.hasPermission(config.PERMISSIONS.PERFORMANCE.Code)) {
            waypoints.value = getActiveOffer().getPerformance().getWaypoints();
            for (var i = 0; i < waypoints.value.length; i++) {
                waypoints.value[i].waypointText = shallowRef();
                if (i === 0) {
                    waypoints.value[i].icon = 'start'; // User Story 17565
                } else if (i === 1) {
                    waypoints.value[i].icon = 'end'; // User Story 17565
                } else {
                    waypoints.value[i].icon = 'via'; // User Story 17565
                }
            }
            
            tripCosts.value = getActiveOffer().getPerformance().getTripCosts();
            topLeftLatitude.value = getActiveOffer().getPerformance().getTopLeftLatitude();
            topLeftLongitude.value = getActiveOffer().getPerformance().getTopLeftLongitude();
            bottomRightLatitude.value = getActiveOffer().getPerformance().getBottomRightLatitude();
            bottomRightLongitude.value = getActiveOffer().getPerformance().getBottomRightLongitude();
        }
        if (isCostingAvailableForOffer() === true) {
            getActiveOfferCosting().getOnRoadCostsCosts().forEach(function (onRoadCost) {
                onRoadCost.setTypePercentageValue();
            });
            getActiveOfferCosting().getOnRoadCostsDiscounts().forEach(function (onRoadCost) {
                onRoadCost.setTypePercentageValue();
            });
            onRoadCostsCosts.value = getActiveOfferCosting().getOnRoadCostsCosts();
            onRoadCostsDiscounts.value = getActiveOfferCosting().getOnRoadCostsDiscounts();
        }
        //fuelSpecificGravity(getConfiguration().getFuelSpecificGravity());

        // Sent Offers
    } else {
        // Configuration
        activeCabColour.value = '';

        // Costing
        noOfUnits.value = '';
        noOfUnitsMin.value = '';
        noOfUnitsMax.value = '';
        noOfUnitsIncrement.value = '';
        fuelCost.value = '';
        fuelCostMin.value = '';
        fuelCostMax.value = '';
        vehiclePrice.value = '';
        vehiclePriceMin.value = '';
        vehiclePriceMax.value = '';
        vehiclePriceIncrement.value = '';
        bodyAndEquipmentPrice.value = '';
        bodyAndEquipmentPriceMin.value = '';
        bodyAndEquipmentPriceMax.value = '';
        bodyAndEquipmentPriceIncrement.value = '';
        optionPrice.value = '';
        optionPriceMin.value = '';
        optionPriceMax.value = '';
        optionPriceIncrement.value = '';
        optionPriceMinSlider.value = '';
        optionPriceMaxSlider.value = '';
        monthlyDistance.value = '';
        monthlyDistanceMin.value = '';
        monthlyDistanceMax.value = '';
        monthlyDistanceIncrement.value = '';
        fuelConsumption.value = '';
        fuelConsumptionMin.value = '';
        fuelConsumptionMax.value = '';
        fuelConsumptionIncrement.value = '';
        fuelCostIncrement.value = '';
        maintenanceRate.value = '';
        maintenanceRateMin.value = '';
        maintenanceRateMax.value = '';
        maintenanceRateIncrement.value = '';
        depositRate.value = '';
        depositRateMin.value = '';
        depositRateMax.value = '';
        depositRateIncrement.value = '';
        residualRate.value = '';
        residualRateMin.value = '';
        residualRateMax.value = '';
        residualRateIncrement.value = '';
        interestRate.value = '';
        interestRateMin.value = '';
        interestRateMax.value = '';
        interestRateIncrement.value = '';
        repaymentTerm.value = '';
        repaymentTermMin.value = '';
        repaymentTermMax.value = '';
        repaymentTermIncrement.value = '';
        onRoadCostsCosts.value = [];
        onRoadCostsDiscounts.value = [];
        originalVehiclePrice.value = '';
        // Performance
        waypoints.value = [];
        simulationResults.value = [];
        tripCosts.value = [];
        topLeftLatitude.value = '';
        topLeftLongitude.value = '';
        bottomRightLatitude.value = '';
        bottomRightLongitude.value = '';

        activeConfigurationViewDetail.value = undefined;
        activeConfigurationViewType.value = undefined;
        activeConfigurationElevation.value = undefined;
        activeConfigurationDisplay.value = undefined;
        // Sent Offers
    }
}







function equipFactoryFittedFuelTank(path, fuelTankType) {
    let fuelTankTypeForContents = typeof fuelTankType === 'string' ? fuelTankType : getActiveOffer().getRig().getVehicle().getFuelType();
    //euip the fueltank on vehicle
    //create observable structure
    //pass fuel tank back with callback
    var addedFuelTank = getConfiguration().addFuelTank(undefined, fuelTankTypeForContents);
    //var keepSameId = true;
    //getOriginalOffer().getConfiguration().addFuelTank(addedFuelTank.clone(keepSameId));
    if (addedFuelTank !== null) {
        addedFuelTank.setIncludeInCalculation(true);
        getOriginalOffer().getRig().getVehicle().addFuelTank(addedFuelTank.clone(true));
        var source = config.OBJECT_TYPES.FUEL_TANKS;
        createObjectWithObservables(source, addedFuelTank, path);
        if(typeof objectAddedListenerCallback === 'function') {
            // nextTick(function (){
                objectAddedListenerCallback(source, addedFuelTank, path);
            // });
            
        }
    } else {
        //callback saying fueltank limit reached ??
    }

}

function equipAxle(path, axleType) {

    var dfd = $.Deferred();
    //showShellSpinner(true);

    var chassisObjectToUse = rigOps.getChassisObjectFromPath(path),
        originalChassisObjectToUse = rigOps.getOriginalChassisObjectFromPath(path);


    var addedAxle = rigOps.addAxle(axleType, path);


    if (addedAxle !== null) {

        //undoHandler.newAddAxleOp(path, addedAxle);

        originalChassisObjectToUse.getAxlesHolder().add(addedAxle.clone(true));
        if(addedAxle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || addedAxle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            originalChassisObjectToUse.getAxlesHolder().calculatePercentagesOfWeightCarried();
        }
        
        




        // var axleLocation = rigOps.getAxleLocationFromAxle(addedAxle);

        //if (axleLocation === config.AXLE_LOCATIONS.FRONT) {

        //    chassisObjectToUse.setTareFront(chassisObjectToUse.getAxlesHolder().getFrontAxleWeight());
        //    chassisObjectToUse.setGAFront(chassisObjectToUse.getAxlesHolder().getFrontAxleManufacturerRating());

        //    var tareFrontAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TAREFRONT);
        //    tareFrontAOG.permissionObv(tareFrontAOG.permission(tareFrontAOG.getPath()));

        //    var manufacturersFrontAxlesMassAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS);
        //    manufacturersFrontAxlesMassAOG.permissionObv(manufacturersFrontAxlesMassAOG.permission(manufacturersFrontAxlesMassAOG.getPath()));

        //    getConfiguration().getAxlesHolder().getAxles().forEach(function (axle) {
        //        if (rigOps.getAxleLocationFromAxle(axle) === config.AXLE_LOCATIONS.FRONT) {
        //            var frontAxleWeightAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + axle.getId() + '.' + config.VALUE_TYPE.AXLEWEIGHT);
        //            frontAxleWeightAOG.permissionObv(frontAxleWeightAOG.permission(frontAxleWeightAOG.getPath() + '.' + config.VALUE_TYPE.AXLEWEIGHT));

        //            var frontAxleManufacturerRatingAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + axle.getId() + '.' + config.VALUE_TYPE.AXLEMANUFACTURERRATING);
        //            frontAxleManufacturerRatingAOG.permissionObv(frontAxleManufacturerRatingAOG.permission(frontAxleManufacturerRatingAOG.getPath() + '.' + config.VALUE_TYPE.AXLEMANUFACTURERRATING));
        //        }
        //    });

        //} else {

        //    chassisObjectToUse.setTareRear(chassisObjectToUse.getAxlesHolder().getRearAxleWeight());
        //    chassisObjectToUse.setGARear(chassisObjectToUse.getAxlesHolder().getRearAxleManufacturerRating());

        //    var tareRearAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TAREREAR);
        //    tareRearAOG.permissionObv(tareRearAOG.permission(tareRearAOG.getPath()));

        //    var manufacturersRearAxlesMassAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.MANUFACTURERSREARAXLEMASS);
        //    manufacturersRearAxlesMassAOG.permissionObv(manufacturersRearAxlesMassAOG.permission(manufacturersRearAxlesMassAOG.getPath()));

        //    getConfiguration().getAxlesHolder().getAxles().forEach(function (axle) {
        //        if (rigOps.getAxleLocationFromAxle(axle) === config.AXLE_LOCATIONS.REAR) {
        //            var rearAxleWeightAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + axle.getId() + '.' + config.VALUE_TYPE.AXLEWEIGHT);
        //            rearAxleWeightAOG.permissionObv(rearAxleWeightAOG.permission(rearAxleWeightAOG.getPath() + '.' + config.VALUE_TYPE.AXLEWEIGHT));

        //            var rearAxleManufacturerRatingAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + axle.getId() + '.' + config.VALUE_TYPE.AXLEMANUFACTURERRATING);
        //            rearAxleManufacturerRatingAOG.permissionObv(rearAxleManufacturerRatingAOG.permission(rearAxleManufacturerRatingAOG.getPath() + '.' + config.VALUE_TYPE.AXLEMANUFACTURERRATING));
        //        }
        //    });
        //}

        skipGraphicsCallback = true;
        doActionsAfterSuccesfulValidation(source);
        var source = config.OBJECT_TYPES.AXLES;
        nextTick(function (){
            createObjectWithObservables(source, addedAxle, path);
            if(typeof objectAddedListenerCallback === 'function') {
                objectAddedListenerCallback(source, addedAxle, path);
            }
            updateTyreRate();
            rigOps.resequenceTrailerAxles();
            
            
            dfd.resolve({
                Axle: addedAxle
            });
            //showShellSpinner(false);
    
            dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.ADDED_AXLE, addedAxle.getIntercomMetadata()));
        });
        // doActionsAfterSuccesfulValidation(source).then(function (){
            
            
           
        // });

       
    }

    return dfd.promise();
}

function updateTyreRate() {
    // Check if permissions are available?
    var costPerKmValue;
    if (typeof getActiveOffer().getCosting() === 'object' && getActiveOffer().getCosting().getTyreRateOverride() === false) {
        costPerKmValue = getTyreRateValueFromTyreDetail();
        getActiveOffer().getCosting().setTyreRate(costPerKmValue);
    }

}

function equipBodyUpright(path) {
    var newUpright = new BodyUpright(globals.nextId(), undefined, 0, 0, 0);
    rigOps.getChassisObjectFromPath(path).getAccessoryHolder().getBody().getBodyUprightHolder().add(newUpright);
    var source = config.OBJECT_TYPES.UPRIGHTS;
    createObjectWithObservables(source, newUpright, path);
    if(typeof objectAddedListenerCallback === 'function') {
        objectAddedListenerCallback(source, newUpright, path);
    }

}

function updateSpecificationObservables() {
    baseVehicle.value = getSpecification().getBaseVehicle();
    specificationHeadings.value = getSpecification().getHeadings();
    setSpecificationVehicles();
}

//#region Update select menu items
function setActiveMeasurementSystemUsingId(id, updateIncrements) {
    var dfd = $.Deferred();

    var oldLengthIncrement, oldMassIncrement, oldPercentageIncrement;
    if (id === 0) {
        activeMeasurementSystem.value = globals.user.getAvailableMeasurementSystems()[0];
        dfd.resolve();
    } else {
        //filter measurement systems
        var temp = globals.user.getAvailableMeasurementSystems().filter(function (system) {
            return system.id === id;
        });
        //set activeMeasurementSystem
        activeMeasurementSystem.value = temp[0];

        if (offerOpen === true) {
            refreshUILiveLabels.value = new Date().getTime();
        }

        //activeMeasurementSystemId(activeMeasurementSystem().id);
        //if (updateIncrements) {
        //update increments
        //oldLengthIncrement = lengthIncrement();
        //oldMassIncrement = massIncrement();
        //oldPercentageIncrement = percentageIncrement();
        //getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSLENGTHINCREMENT).value(activeMeasurementSystem().defaultLengthIncrement);
        //getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSMASSINCREMENT).value(activeMeasurementSystem().defaultMassIncrement);
        //getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSPERCENTAGEINCREMENT).value(activeMeasurementSystem().defaultPercentageIncrement);
        //lengthIncrementFormatted(activeMeasurementSystem().defaultLengthIncrement);
        //if (oldLengthIncrement === lengthIncrement()) {
        //    lengthIncrement.valueHasMutated();
        //}
        //massIncrementFormatted(activeMeasurementSystem().defaultMassIncrement);
        //if (oldMassIncrement === massIncrement()) {
        //    massIncrement.valueHasMutated();
        //}
        //percentageIncrement(activeMeasurementSystem().defaultPercentageIncrement);
        //if (oldPercentageIncrement === percentageIncrement()) {
        //    percentageIncrement.valueHasMutated();
        //}
        //}
        dfd.resolve();
    }

    return dfd.promise();
}

function getInterpreterFormatter() {
    let formatter = {}
    formatter.getCurMeasurementSysRoundedLength = getCurMeasurementSysRoundedLength;
    formatter.getCurMeasurementSysRoundedMass = getCurMeasurementSysRoundedMass; 
    return formatter;
}

//function setActiveLegislationUsingId(id, elementId, previousValue) {
//    var dfd = $.Deferred();
//    if (setActiveLegislationUsingIdInternalCall === false) {
//        showShellSpinner(true);
//    }
//    if (id === 0) {
//        //activeLegislation(globals.user.getAvailableLegislations()[0]);

//        //showShellSpinner(true);
//        doLegislationRetrievalAndUpdate(globals.user.getAvailableLegislations()[0]);
//        //dfd.resolve();
//    } else {
//        //filter legislations
//        var temp = ko.utils.arrayFilter(globals.user.getAvailableLegislations(), function (legislation) {
//            return legislation.id === id;
//        });

//        //showShellSpinner(true);
//        if (temp[0] !== undefined) {
//            doLegislationRetrievalAndUpdate(temp[0]);
//        } else {
//            if (openOfferIsSharedOffer === true) {
//                legislationSubstitutedForSharedCalc = true;
//                doLegislationRetrievalAndUpdate(globals.user.getAvailableLegislations()[0]);
//            } else {
//                //dfd.reject({
//                //    errorMessage: 'Missing legislation, data refresh needed'
//                //});
//                defaultLegislationUsedDuToMissingLegislation = true;
//                var defaultLegislation = globals.user.getAvailableLegislations().filter(function (legislation) {
//                    return legislation.isDefault;
//                });
                
//                doLegislationRetrievalAndUpdate(defaultLegislation[0]);
//            }

//        }
//    }

//    function doLegislationRetrievalAndUpdate(legislation) {
//        dataManager.getLegislationDetails(legislation.legislationId, legislation.countryId)
//            .then(function (legislationDetails) {
//                if (setActiveLegislationUsingIdInternalCall === false) {
//                    showShellSpinner(false);
//                }

//                if (elementId) {
//                    $('#' + elementId).each(function () {
//                        $(this).data('lastValue', $(this).val());
//                    });
//                }
//                //set activeLegislation
//                activeLegislation(legislation);
//                //activeLegislationId(activeLegislation().id);
//                setActiveLegislationObjectsOnOfferManagerAndActiveOfferAndUpdateInterpreter(legislationDetails);
//                //dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.CHANGED_SETTINGS, { SettingName: 'Change Legislation' }));
//                if (getActiveOffer() !== '' && getActiveOffer() !== null && getActiveOffer() !== undefined) {

//                    getActiveOffer().setApplyHigherMassLimits(false);

//                    try {
//                        getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSAPPLYHIGHERMASSLIMITS).value(false);

//                        var attrObvGroup = offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSVEHICLEAPPLICATION);
//                        attrObvGroup.extraUpdate(config.VEHICLE_APPLICATION_TYPES.ONROAD);
//                        //addSettingNameToChangedSettingNamesList(config.SETTINGS_OPTIONS.VEHICLE_APPLICATION);

//                    } catch (cantFindAttributeGroupEx) {

//                    }
//                }
//                dfd.resolve();
//            })
//            .fail(function (errorMessage) {
//                if (setActiveLegislationUsingIdInternalCall === false) {
//                    showShellSpinner(false);
//                }
//                if (previousValue) {
//                    $('#' + elementId).val(previousValue);
//                    $('#' + elementId).selectmenu("refresh");
//                }

//                app.showDialog(new CustomMessageBox(config.getMessageText('1573', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1573'))).then(function () { });
//                dfd.reject(errorMessage);
//            });
//    }

//    return dfd.promise();
//}

function setActiveApplicationUsingId(id) {
    var dfd = $.Deferred(),
        applicationTypeToUse;

    if (id === 0) {
        activeApplication.value = getAvailableVehicleApplicationTypes()[0];
        dfd.resolve();
    } else {
        //filter legislations
        var temp = getAvailableVehicleApplicationTypes().filter(function (application) {
            return application.id === id;
        });
        applicationTypeToUse = temp.length === 0 ? getAvailableVehicleApplicationTypes()[0] : temp[0];
        //set activeLegislation
        activeApplication.value = applicationTypeToUse;
        dfd.resolve();
        //activeApplicationId(activeApplication().id);
    }
    //dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.CHANGED_SETTINGS, { SettingName: 'Application' }));
    return dfd.promise();
}


//#endregion Update select menu items

////#region Increment/Decrement Observable
//function incrementObservable(observable, incrementType, overrideObv) {
//    if (overrideObv === undefined || overrideObv()) {
//        var temp = observable();
//        var minusValue = isMinusValue(temp);

//        temp = parseInt(temp.replace(/[^\.\d]/g, ""));

//        if (minusValue) {
//            temp = -temp;
//        }

//        switch (incrementType) {
//            case config.INCREMENT_TYPE.LENGTH:
//                temp += lengthIncrement();
//                break;
//            case config.INCREMENT_TYPE.MASS:
//                temp += massIncrement();
//                break;
//            case config.INCREMENT_TYPE.PERCENTAGE:
//                temp += percentageIncrement();
//                break;
//            default:
//                break;
//        }
//        observable(temp);
//    }
//}

//function decrementObservable(observable, incrementType, overrideObv) {
//    if (overrideObv === undefined || overrideObv()) {
//        var temp = observable();
//        var minusValue = isMinusValue(temp);



//        temp = parseInt(temp.replace(/[^\.\d]/g, ""));
//        if (minusValue) {
//            temp = -temp;
//        }
//        switch (incrementType) {
//            case config.INCREMENT_TYPE.LENGTH:
//                temp -= lengthIncrement();
//                break;
//            case config.INCREMENT_TYPE.MASS:
//                temp -= massIncrement();
//                break;
//            case config.INCREMENT_TYPE.PERCENTAGE:
//                temp -= percentageIncrement();
//                break;
//            default:
//                break;
//        }
//        observable(temp);
//    }
//}

//function isMinusValue(valToCheck) {
//    if (typeof valToCheck === 'string') {
//        if (valToCheck.indexOf('-') !== -1) {
//            return true;
//        }
//    } else {
//        if (valToCheck < 0) {
//            return true;
//        }
//    }
//    return false;
//}
////#endregion Increment/Decrement Observable
//#endregion Update Observable Values

/**
    * Evaluate attribute on legislation object
    * @param {string} attributeName - Name of attribute to look for in interpreter
    * @param {object} legislation - legislation objetc to use
    * @param {object} alternativeRigOps - alternativeRigOps
    * @param {boolean} forceInterpreterLookup - prevent function from using legislationQuickLookup
    * @return {number} - Returns 1 (true) or 0 (false)
    */
function evaluateLegislation(attributeName, legislation, alternativeRigOps, forceInterpreterLookup) {
    evaluateLegislationCalled++;
    var legislationToUse = legislation !== undefined ? legislation : getLegislations()[0];
    var rigOpsToUse = alternativeRigOps !== undefined && alternativeRigOps !== null ? alternativeRigOps : rigOps;
    var forceInterpreterLookupToUse = typeof forceInterpreterLookup === 'boolean' ? forceInterpreterLookup : false;
    //var attributeDetails = getLegislation().evaluate(attributeName);

    if (legislationQuickLookup[legislationToUse.id] !== undefined) {
        if (!forceInterpreterLookupToUse) {
            if (legislationQuickLookup[legislationToUse.id][attributeName] !== undefined) {
                legislationValueFoundInQuickLookup++;
                return legislationQuickLookup[legislationToUse.id][attributeName];
            }
        }
    } else {
        legislationQuickLookup[legislationToUse.id] = {};
    }
    legislationValueInterpreted++;
    //var tempInterpreter = new Interpreter(getLegislation()); 
    var attributeDetails = legislationToUse.evaluate(attributeName);

    
    
    interpreterInstance.setLegislationObject(legislationToUse);
    interpreterInstance.setRigOps(rigOpsToUse);
    
    //var tempInterpreter = new Interpreter(legislationToUse, rigOpsToUse);
    //var evalResult = tempInterpreter.interpret(attributeDetails);
    let startMillis, endMillis;
    if(globals.debugLegislation) {
        console.log('**********************     Evaluating: ' + attributeName + '    ****************************');
        startMillis = new Date().getTime();
    }
    // TScMessenger.writeTimerMessage('Evaluation Started: ' + attributeName);
    
   
    var evalResult = interpreterInstance.interpret(attributeDetails, true);
    if(globals.debugLegislation) {
        endMillis = new Date().getTime();
        // TScMessenger.writeTimerMessage('Evaluation Ended: ' + attributeName);
        // TScMessenger.writeTimerMessage('Evaluation time in millis for ' + attributeName + ': ' + (endMillis - startMillis + '\n\n'));

        if(endMillis - startMillis > 2) {
            if(longRunningLegislationAttributes[attributeName]) {
                longRunningLegislationAttributes[attributeName].duration = Math.max(longRunningLegislationAttributes[attributeName].duration, endMillis - startMillis);
            }else {
                longRunningLegislationAttributes[attributeName] = {};
                longRunningLegislationAttributes[attributeName].attributeName = attributeName;
                longRunningLegislationAttributes[attributeName].duration = endMillis - startMillis;
            }
        }
        let evaluationTimesFromInterpreter = interpreterInstance.getAttributeAndVariableEvluationTimes();
        if(evaluationTimesFromInterpreter.attributes) {
            console.log("\n************************************************************************************************************************************\n");
            Object.keys(evaluationTimesFromInterpreter.attributes).forEach(function (indentNumber) {
                console.log("*** Interpreter Attribute Name: " + Object.values(evaluationTimesFromInterpreter.attributes[indentNumber])[0].name);
                console.log("*** Interpreter Duration: " + (Object.values(evaluationTimesFromInterpreter.attributes[indentNumber])[0].endMillis - Object.values(evaluationTimesFromInterpreter.attributes[indentNumber])[0].startMillis) + '\n');
                Object.values(evaluationTimesFromInterpreter.variables[indentNumber]).forEach(function (variableTiming) {
                    console.log("********** Interpreter Variable Name: " + variableTiming.name);
                    console.log("********** Interpreter Duration: " + (variableTiming.endMillis - variableTiming.startMillis) + '\n');
                });
                
            });
            console.log("\n************************************************************************************************************************************\n");
        }
    }
    
    legislationQuickLookup[legislationToUse.id][attributeName] = evalResult;
    //interpreterInstance.setLegislationObject(getLegislations()[0]);
    //interpreterInstance.setRigOps(rigOps);
    //tempInterpreter = null;
    return evalResult;
}

//function evaluateLegislation(attributeName, legislation, alternativeRigOps, forceInterpreterLookup) {
//    evaluateLegislationCalled++;
//    var legislationToUse = legislation !== undefined ? legislation : getLegislation();
//    var rigOpsToUse = alternativeRigOps !== undefined && alternativeRigOps !== null ? alternativeRigOps : rigOps;
//    var forceInterpreterLookupToUse = typeof forceInterpreterLookup === 'boolean' ? forceInterpreterLookup : false;
//    //var attributeDetails = getLegislation().evaluate(attributeName);

//    if (legislationQuickLookup[legislationToUse.id] !== undefined) {
//        if (!forceInterpreterLookupToUse) {
//            if (legislationQuickLookup[legislationToUse.id][attributeName] !== undefined) {
//                legislationValueFoundInQuickLookup++;
//                return legislationQuickLookup[legislationToUse.id][attributeName];
//            }
//        }
//    } else {
//        legislationQuickLookup[legislationToUse.id] = {};
//    }
//    legislationValueInterpreted++;
//    //var tempInterpreter = new Interpreter(getLegislation()); 
//    var attributeDetails = legislationToUse.evaluate(attributeName);

//    var tempInterpreter = new Interpreter(legislationToUse, rigOpsToUse);
//    var evalResult = tempInterpreter.interpret(attributeDetails);
//    legislationQuickLookup[legislationToUse.id][attributeName] = evalResult;
//    tempInterpreter = null;
//    return evalResult;
//}

function getInterpreterInstance() {
    return interpreterInstance;
}

//#region Validation
function validateFuelConsumption(newValue, oldValue) {
    return true;
}

function validateSelectedCompetitor1(newValue, oldValue) {
    if (newValue === undefined || newValue === 0) {
        return false;
    } else {
        return true;
    }
}

function validateSelectedCompetitor2(newValue, oldValue) {
    if (newValue === undefined || newValue === 0) {
        return false;
    } else {
        return true;
    }
}

function displayError(id, errorMessage) {
    if (dragging === true) {
        validationFailedWhileDraggingToastHandlerCallback(errorMessage);
    }
    displayErrorOnMenu(id);
}

function displayErrorOnMenu(id) {
    if (lastErrorInputId !== '') {
        clearError(true);
    }
    lastErrorInputId = id;
    clearError();
    
    $('#' + id).tooltip('destroy');
    $('#' + id).addClass('has-error');
    $('#' + id).tooltip({ trigger: 'manual' });
    setTimeout(function () {
        $('#' + id).tooltip('show');
    }, 10);
}

function hideErrorOnMenu(id) {
    $('#' + id).removeClass('has-error');
    $('#' + id).tooltip('destroy');
}

function clearError(immediate) {
    if (immediate !== undefined && immediate === true) {
        if (lastErrorTimerId !== null) {
            clearTimeout(lastErrorTimerId);
            lastErrorTimerId = null;
        }
        if (lastErrorInputId !== '') {
            hideErrorOnMenu(lastErrorInputId);
            lastErrorInputId = '';
        }
    } else {
        if (lastErrorTimerId === null) {
            if (lastErrorInputId !== '') {
                lastErrorTimerId = setTimeout(function () {
                    if (lastErrorInputId !== '') {
                        hideErrorOnMenu(lastErrorInputId);
                        lastErrorInputId = '';
                        lastErrorTimerId = null;
                    }
                }, 3500);
            }
            //
        }
    }

}
//#endregion Validation

//#region UpdateValue
function updateFuelConsumption(newValue) {
    //alert('updateFuelConsumption newValue = ' + newValue);
}

function updateSelectedCompetitor1(newValue) {
    if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
        getSpecification().setSelectedCompetitor1(newValue);
    }
}

function updateSelectedCompetitor2(newValue) {
    if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
        getSpecification().setSelectedCompetitor2(newValue);
    }
}
//#endregion UpdateValue

function getValidationConfig(valueType) {
    return validationConfigs[valueType];
}

function getConditionalWheelbaseMin() {

    if (globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code)) {
        //return evaluateLegislation('VEHICLEWHEELBASETHEORETICALADMINMIN');
        return getHighestMinFromActiveLegislations(legislationVals.variableTypes.VehicleWheelbaseTheoreticalAdminMin);
    } else {
        //return evaluateLegislation('VEHICLEWHEELBASETHEORETICALMIN');
        return getHighestMinFromActiveLegislations(legislationVals.variableTypes.VehicleWheelbaseTheoreticalMin);
    }
}

function getConditionalWheelbaseMax() {

    if (globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code)) {
        //return evaluateLegislation('VEHICLEWHEELBASETHEORETICALADMINMAX');
        return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.VehicleWheelbaseTheoreticalAdminMax);
    } else {
        //return evaluateLegislation('VEHICLEWHEELBASETHEORETICALMAX');
        return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.VehicleWheelbaseTheoreticalMax);
    }
}

function getBumperToBackOfCabMaxVal() {
    return getConfiguration().getFrontOverhang() + getConfiguration().getAxle1FrontToAxle2Front() + getConfiguration().getAxle2FrontToAxle1Rear();
}

function getVehicleHorizontalCOGMaxVal() {
    return getConfiguration().getAxle1FrontToAxle2Front() - getConfiguration().getAxle1FrontToWheelbaseTheoreticalStart() +
        getConfiguration().getAxle2FrontToAxle1Rear() + getConfiguration().getAxle1RearToAxle2Rear() + getConfiguration().getAxle2RearToAxle3Rear() +
        getConfiguration().getAxle3RearToAxle4Rear() + getConfiguration().getRearOverhang() + getConfiguration().getRearProtrusion();
}

function getBodySideDoorFrontToDoorMax(attrPath) {

    var tempBody = rigOps.getBodyFromPath(attrPath);

    if (tempBody.getSideDoorType() === config.SIDE_DOOR_TYPES.SINGLE) {
        return tempBody.getActualLength() - tempBody.getSideDoorWidthPerDoor();
    } else {
        return tempBody.getActualLength() - (tempBody.getSideDoorWidthPerDoor() * 2);
    }

}

function getBodySideDoorWidthPerDoorMax(attrPath) {

    var tempBody = rigOps.getBodyFromPath(attrPath);

    if (tempBody.getSideDoorType() === config.SIDE_DOOR_TYPES.SINGLE) {
        return tempBody.getActualLength() - tempBody.getSideDoorFrontToDoor();
    } else {
        return (tempBody.getActualLength() - tempBody.getSideDoorFrontToDoor()) / 2;
    }
}

function getRampLengthMaxVal(attrPath) {
    var absoluteMax = 7000;

    var tempBody = rigOps.getBodyFromPath(attrPath);
    var opposite = 0;

    if (attrPath.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
        opposite = getActiveOffer().getRig().getVehicle().getChassisHeightRear();
    } else if (attrPath.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        opposite = getActiveOffer().getRig().getTrailer1().getChassisHeight();
    } else if (attrPath.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
        opposite = getActiveOffer().getRig().getTrailer2().getChassisHeight();
    }

    var tanTheta = Math.tan(tempBody.getRampAngle() / (180 / Math.PI));
    var maxRampLengthBeforegroundIntersection = Math.floor(opposite / tanTheta);

    return Math.min(absoluteMax, maxRampLengthBeforegroundIntersection);
}

function getRampAngleMaxVal(attrPath) {

    var absoluteMax = 60;

    var tempBody = rigOps.getBodyFromPath(attrPath);
    var opposite = 0;

    if (attrPath.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
        opposite = getActiveOffer().getRig().getVehicle().getChassisHeightRear();
    } else if (attrPath.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        opposite = getActiveOffer().getRig().getTrailer1().getChassisHeight();
    } else if (attrPath.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
        opposite = getActiveOffer().getRig().getTrailer2().getChassisHeight();
    }

    var tanTheta = opposite / tempBody.getRampLength();
    var maxAngleBeforeGroundIntersection = Math.floor(Math.atan(tanTheta) * 180 / Math.PI);

    return Math.min(absoluteMax, maxAngleBeforeGroundIntersection);
}

function getBodyLengthMinVal(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);
    if (tempBody.getLengthType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
        return tempBody.getHeadboardThickness() + tempBody.getTailboardThickness();
    } else {
        return 0;
    }
}

function getBodyLengthMaxVal(attrPath) {
    if (globals.user.isLoadingAdministrator()) {
        return 25000;
    } else {
        var bodyToUse = rigOps.getBodyFromPath(attrPath);

        return /*evaluateLegislation('COMVEHBODYLENGTHMAX')*/ getLowestMaxFromActiveLegislations(legislationVals.variableTypes.ComVehBodyLengthMax) - bodyToUse.getRampLength() - bodyToUse.getRampPlatesProtrusion();
    }
}

function getTrailerRearAxlesSpacingMinVal(mainAttrPath) {
    if (mainAttrPath.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        return getActiveOffer().getTrailer1().getTyreRadius() * 2;
    } else {
        return getActiveOffer().getTrailer2().getTyreRadius() * 2;
    }
}

function getOverallChassisObjectLengthMax(mainAttrPath) {
    if (mainAttrPath.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
        //return evaluateLegislation(legislationVals.variableTypes.OverallVehicleLengthMax);
        return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.OverallVehicleLengthMax);
    } else if (mainAttrPath.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        //return evaluateLegislation(legislationVals.variableTypes.Trailer1LengthMax);
        return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer1LengthMax);
    } else if (mainAttrPath.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
        //return evaluateLegislation(legislationVals.variableTypes.Trailer2LengthMax);
        return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer2LengthMax);
    } else {
        return 0;
    }
}

function getOverallChassisObjectLengthMin(mainAttrPath) {
    if (mainAttrPath.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
        return undefined;
    } else if (mainAttrPath.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        //return evaluateLegislation(legislationVals.variableTypes.Trailer1LengthMin);
        return getHighestMinFromActiveLegislations(legislationVals.variableTypes.Trailer1LengthMin);
    } else if (mainAttrPath.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
        //return evaluateLegislation(legislationVals.variableTypes.Trailer2LengthMin);
        return getHighestMinFromActiveLegislations(legislationVals.variableTypes.Trailer2LengthMin);
    } else {
        return 0;
    }
}

function getChassisObjectRearOverhangLegislation(attrPath) {
    if (attrPath.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
        //return evaluateLegislation(legislationVals.variableTypes.ComVehRearOverhangLegislation);
        return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.ComVehRearOverhangLegislation);
    } else if (attrPath.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        //return evaluateLegislation(legislationVals.variableTypes.Trailer1RearOverhangLegislation);
        return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer1RearOverhangLegislation);
    } else if (attrPath.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
        //return evaluateLegislation(legislationVals.variableTypes.Trailer2RearOverhangLegislation);
        return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer2RearOverhangLegislation);
    }
    return 0;
}

function getChassisObjectRearOverhangLegislationMax(attrPath) {
    if (attrPath.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
        if (globals.user.isLoadingAdministrator()) {
            return 9999;
        } else {
            //return evaluateLegislation(legislationVals.variableTypes.ComVehRearOverhangLegislationMax);
            return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.ComVehRearOverhangLegislationMax);
        }
    } else if (attrPath.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        if (globals.user.isLoadingAdministrator()) {
            return 15000;
        } else {
            //return evaluateLegislation(legislationVals.variableTypes.Trailer1RearOverhangLegislationMax);
            return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer1RearOverhangLegislationMax);
        }

    } else if (attrPath.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
        if (globals.user.isLoadingAdministrator()) {
            return 15000;
        } else {
            //return evaluateLegislation(legislationVals.variableTypes.Trailer2RearOverhangLegislationMax);
            return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer2RearOverhangLegislationMax);
        }

    }
    return 0;
}

function getOverallChassisObjectHeightMax(attrPath) { 
    if (attrPath.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
        //return evaluateLegislation(legislationVals.variableTypes.OverallVehicleHeightMax);
        return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.OverallVehicleHeightMax);
    } else if (attrPath.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        //return evaluateLegislation(legislationVals.variableTypes.OverallTrailer1HeightMax);
        return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.OverallTrailer1HeightMax);
    } else if (attrPath.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
        //return evaluateLegislation(legislationVals.variableTypes.OverallTrailer2HeightMax);
        return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.OverallTrailer2HeightMax);
    }
    return 0;
}

function getCraneCabGapMax(attrPath) {
    //if (attrPath.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
    //    return 9000;
    //} else {
    //    return 20000;
    //}
    return 20000;
}

/**
    * Check for A-Frame, Hook Offset and Centre of Roller which should not exceed length of hooklift according to the Vision Scope
    * @param {string} attrPath - Path to the hooklift
    * @returns {number} - Length of hooklift if available, else 0
    */
function getHookliftLengthForValidation(attrPath) {
    if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE)) {
        return getActiveOffer().getRig().getVehicle().getAccessoryHolder().getHooklift().getLength();
    } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
        return getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getHooklift().getLength();
    } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
        return getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getHooklift().getLength();
    }
    return 0;
}

/**
    * Check for Platform Height which should not exceed height of hooklift according to the Vision Scope
    * @param {string} attrPath - Path to the hooklift
    * @returns {number} - Height of hooklift if available, else 0
    */
function getHookliftHeightForValidation(attrPath) {
    if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE)) {
        return getActiveOffer().getRig().getVehicle().getAccessoryHolder().getHooklift().getHeight();
    } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
        return getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getHooklift().getHeight();
    } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
        return getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getHooklift().getHeight();
    }
    return 0;
}

function getOtherCabGapMin(attrPath) {
    if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE)) {
        return -(getActiveOffer().getRig().getVehicle().getBumperToBackOfCab() + 5000);
    } else {
        return 0;
    }
}

function getOtherCabGapMax(attrPath) {
    if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE)) {
        return 9999;
    } else {
        return 20000;
    }
}

function getFridgeHeightMax(attrPath) {
    if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE)) {
        return getActiveOffer().getRig().getVehicle().getAccessoryHolder().getBody().getActualExternalHeight();
    } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
        return getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getBody().getActualExternalHeight();
    } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
        return getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getBody().getActualExternalHeight();
    }
    return 0;
}

function getBodyHorizontalCOGMax(attrPath) {
    if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE)) {
        return getActiveOffer().getRig().getVehicle().getAccessoryHolder().getBody().getActualLength();
    } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
        return getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getBody().getActualLength();
    } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
        return getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getBody().getActualLength();
    }
    return 0;
}

function getBodyVerticalCOGMax(attrPath) {

    var tempBody = rigOps.getBodyFromPath(attrPath);

    if (tempBody !== null) {
        if (tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
            return tempBody.getHeadboardHeight() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight();
        } else {
            return tempBody.getActualExternalHeight();
        }
    }


    //if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE)) {
    //    return getActiveOffer().getRig().getVehicle().getAccessoryHolder().getBody().getActualExternalHeight();
    //} else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
    //    return getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getBody().getActualExternalHeight();
    //} else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
    //    return getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getBody().getActualExternalHeight();
    //}
    return 0;
}

function getBodyHingePointMax(attrPath) {
    if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE)) {
        return getActiveOffer().getRig().getVehicle().getAccessoryHolder().getBody().getActualLength();
    } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
        return getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getBody().getActualLength();
    } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
        return getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getBody().getActualLength();
    }
    return 0;
}

function getBodySideDoorHeightMax(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    if (tempBody !== null) {
        return tempBody.getActualInternalHeight();
    } else {
        return 0;
    }
}

function getBodyUprightInsideFrontToUprightMax(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    if (tempBody !== null) {
        return tempBody.getActualInternalLength();
    } else {
        return 0;
    }
}

function getBodyUprightWidthMax(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    if (tempBody !== null) {
        return tempBody.getActualInternalWidth();
    } else {
        return 0;
    }
}

function getCraneHorizontalCOGMax(attrPath) {
    var tempCrane = rigOps.getCraneFromPath(attrPath);

    if (tempCrane !== null) {
        return tempCrane.getLength();
    } else {
        return 0;
    }
}

function getCraneVerticalCOGMax(attrPath) {
    var tempCrane = rigOps.getCraneFromPath(attrPath);

    if (tempCrane !== null) {
        return tempCrane.getHeight();
    } else {
        return 0;
    }
}

function getCraneLateralCOGMax(attrPath) {
    var tempCrane = rigOps.getCraneFromPath(attrPath);

    if (tempCrane !== null) {
        return tempCrane.getWidth();
    } else {
        return 0;
    }
}

function getPayloadHorizontalCOGMax(attrPath) {
    var tempPayloadHolder = rigOps.getPayloadHolderFromPath(attrPath);

    if (tempPayloadHolder.getPayloadType() === config.PAYLOAD_TYPES.SIMPLE) {
        if (tempPayloadHolder !== null) {
            return tempPayloadHolder.getSimplePayloadObject().getLength();
        } else {
            return 0;
        }
    } else {

        var payloadId = getObjectNumberOrIdFromPathToValue(attrPath);
        var tempPayload = rigOps.getPayloadFromPathById(attrPath, payloadId);
        if (tempPayload !== null) {
            return tempPayload.getLength();
        } else {
            return 0;
        }

    }


}

function getPayloadVerticalCOGMax(attrPath) {
    var tempPayloadHolder = rigOps.getPayloadHolderFromPath(attrPath);

    if (tempPayloadHolder.getPayloadType() === config.PAYLOAD_TYPES.SIMPLE) {
        if (tempPayloadHolder !== null) {
            return tempPayloadHolder.getSimplePayloadObject().getHeight();
        } else {
            return 0;
        }
    } else {

        var payloadId = getObjectNumberOrIdFromPathToValue(attrPath);
        var tempPayload = rigOps.getPayloadFromPathById(attrPath, payloadId);
        if (tempPayload !== null) {
            return tempPayload.getHeight();
        } else {
            return 0;
        }

    }


}

function getPayloadLateralCOGMax(attrPath) {
    var tempPayloadHolder = rigOps.getPayloadHolderFromPath(attrPath);

    if (tempPayloadHolder.getPayloadType() === config.PAYLOAD_TYPES.SIMPLE) {
        if (tempPayloadHolder !== null) {
            return tempPayloadHolder.getSimplePayloadObject().getWidth();
        } else {
            return 0;
        }
    } else {

        var payloadId = getObjectNumberOrIdFromPathToValue(attrPath);
        var tempPayload = rigOps.getPayloadFromPathById(attrPath, payloadId);
        if (tempPayload !== null) {
            return tempPayload.getWidth();
        } else {
            return 0;
        }

    }


}

function getOtherHorizontalCOGMax(attrPath) {
    var tempOther = rigOps.getOtherFromPathById(attrPath, getObjectNumberOrIdFromPathToValue(attrPath));

    if (tempOther !== null) {
        return tempOther.getLength();
    } else {
        return 0;
    }
}

function getOtherVerticalCOGMax(attrPath) {
    var tempOther = rigOps.getOtherFromPathById(attrPath, getObjectNumberOrIdFromPathToValue(attrPath));

    if (tempOther !== null) {
        return tempOther.getHeight();
    } else {
        return 0;
    }
}

function getOtherLateralCOGMax(attrPath) {
    var tempOther = rigOps.getOtherFromPathById(attrPath, getObjectNumberOrIdFromPathToValue(attrPath));

    if (tempOther !== null) {
        return tempOther.getWidth();
    } else {
        return 0;
    }
}

/**
    * Get the max value of the Horizontal COG of a hooklift
    * @param {string} attrPath - Path to the hooklift
    * @returns {number} - The length of the hooklift if available else 0
    */
function getHookliftHorizontalCOGMax(attrPath) {
    var tempHooklift = rigOps.getHookliftFromPath(attrPath);

    if (tempHooklift !== null) {
        return tempHooklift.getLength();
    } else {
        return 0;
    }
}

/**
    * Get the max value of the Vertical COG of a hooklift
    * @param {string} attrPath - Path to the hooklift
    * @returns {number} - The length of the hooklift if available else 0
    */
function getHookliftVerticalCOGMax(attrPath) {
    var tempHooklift = rigOps.getHookliftFromPath(attrPath);

    if (tempHooklift !== null) {
        return tempHooklift.getHeight();
    } else {
        return 0;
    }
}

/**
    * Get the max value of the Lateral COG of a hooklift
    * @param {string} attrPath - Path to the hooklift
    * @returns {number} - The length of the hooklift if available else 0
    */
function getHookliftLateralCOGMax(attrPath) {
    var tempHooklift = rigOps.getHookliftFromPath(attrPath);

    if (tempHooklift !== null) {
        return tempHooklift.getWidth();
    } else {
        return 0;
    }
}


function getFridgeHorizontalCOGMax(attrPath) {
    var tempFridge = rigOps.getFridgeFromPath(attrPath);

    if (tempFridge !== null) {
        return tempFridge.getProtrusion();
    } else {
        return 0;
    }
}

function getFridgeVerticalCOGMax(attrPath) {
    var tempFridge = rigOps.getFridgeFromPath(attrPath);

    if (tempFridge !== null) {
        return tempFridge.getHeight();
    } else {
        return 0;
    }
}

function getFridgeLateralCOGMax(attrPath) {
    var tempFridge = rigOps.getFridgeFromPath(attrPath);

    if (tempFridge !== null) {
        return tempFridge.getWidth();
    } else {
        return 0;
    }
}

//function getTailliftRearClearanceMax(attrPath) {
//    var tempTaillift = rigOps.getTailliftFromPath(attrPath);

//    if(tempTaillift !== null) {
//        return tempTaillift.getRearProtrusion();
//    }else {
//        return 0;
//    }
//}

//function getTailliftInternalMountingLengthMax(attrPath) {
//    var tempChassisObject = rigOps.getChassisObjectFromPath(attrPath);

//    if (tempChassisObject !== null) {
//        return tempChassisObject.getRearOverhang();
//    } else {
//        return 0;
//    }
//}

function getTailliftHorizontalCOGMax(attrPath) {
    var tempTaillift = rigOps.getTailliftFromPath(attrPath);

    if (tempTaillift !== null) {
        return tempTaillift.getMechanismLength() + tempTaillift.getPlatformProtrusion();
    } else {
        return 0;
    }
}

function getTailliftVerticalCOGMax(attrPath) {
    var tempTaillift = rigOps.getTailliftFromPath(attrPath);

    if (tempTaillift !== null) {
        return tempTaillift.getHeight();
    } else {
        return 0;
    }
}

function getTailliftLateralCOGMax(attrPath) {
    var tempTaillift = rigOps.getTailliftFromPath(attrPath);

    if (tempTaillift !== null) {
        return tempTaillift.getWidth();
    } else {
        return 0;
    }
}

function getRampPlatesHorizontalCOGMax(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    if (tempBody !== null) {
        return tempBody.getRampPlatesProtrusion();
    } else {
        return 0;
    }
}

function getRampPlateVerticalCOGMax(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    if (tempBody !== null) {
        return tempBody.getRampPlatesHeight();
    } else {
        return 0;
    }
}

function getFuelTankHorizontalCOGMax() {
    var tempVehicle = getActiveOffer().getRig().getVehicle();
    return tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + tempVehicle.getAxle1RearToAxle2Rear() +
        tempVehicle.getAxle2RearToAxle3Rear() + tempVehicle.getAxle3RearToAxle4Rear() + tempVehicle.getRearOverhang();
}

function getTrailerHorizontalCOGMax(attrPath) {
    if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
        return getActiveOffer().getRig().getTrailer1().getOverallLengthExclAccessories();
    } else {
        return getActiveOffer().getRig().getTrailer2().getOverallLengthExclAccessories();
    }
}

function getTrailerVerticalCOGMax(attrPath) {
    if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
        return getActiveOffer().getRig().getTrailer1().getOverallHeight();
    } else {
        return getActiveOffer().getRig().getTrailer2().getOverallHeight();
    }
}

function getTrailerLateralCOGMax(attrPath) {
    if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
        return getActiveOffer().getRig().getTrailer1().getOverallWidth();
    } else {
        return getActiveOffer().getRig().getTrailer2().getOverallWidth();
    }
}

function getPerformanceMaximumSpeedMax(attrPath, optionalNewOffer) {

    var tempPerformance = optionalNewOffer !== undefined ? optionalNewOffer.getPerformance() : getActiveOffer().getPerformance();
    var tempConfiguration = optionalNewOffer !== undefined ? optionalNewOffer.getConfiguration() : getActiveOffer().getConfiguration();

    var revsGoverned = tempPerformance.getRevsGoverned();
    var revsPerKMOfDrivenAxleTyre = tempConfiguration.getAxlesHolder().getRevsPerKMOfDrivenAxleTyre();
    var driveAxleRatio = tempPerformance.getDriveAxleRatio() || tempConfiguration.getAxlesHolder().getStandardRatioOfDrivenAxle();
    var driveAxleRatioForCalculation = driveAxleRatio === 0 ? 1 : driveAxleRatio;
    var topGearRatio = tempPerformance.getTopGearRatio();
    //getActiveOffer().getRig().getVehicle().getAxlesHolder().getStandardRatioOfDrivenAxle()
    // return (revsGoverned * 60) /
    //     (revsPerKMOfDrivenAxleTyre * driveAxleRatio * topGearRatio);
    return (revsGoverned * 60) /
        (revsPerKMOfDrivenAxleTyre * driveAxleRatioForCalculation * topGearRatio);
}

function getFifthWheelHeightMin(attrPath) {
    if (attrPath.includes(config.OBJECT_TYPES.ACCESSORY)) {
        return 0;
    } else {
        return 500;
    }
}

function getFifthWheelHeightMax(attrPath) {
    if (attrPath.includes(config.OBJECT_TYPES.ACCESSORY)) {
        return 1000;
    } else {
        return 2500;
    }
}

function getVehicleOverallWidthMaxLegislationVal() {
    //return evaluateLegislation(legislationVals.variableTypes.VehicleOverallWidthMax);
    if (globals.user.isLoadingAdministrator()) {
        return 20000;
    }else {
        return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.VehicleOverallWidthMax);
    }
    
}

function getAxle2FrontToAxle1RearMin() {
    if (globals.user.isLoadingAdministrator()) {
        return 0
    } else {
        var tempVehicle = getActiveOffer().getRig().getVehicle();
        return /*evaluateLegislation(legislationVals.variableTypes.VehicleWheelbaseTheoreticalMin)*/ getHighestMinFromActiveLegislations(legislationVals.variableTypes.VehicleWheelbaseTheoreticalMin) + tempVehicle.getWheelbaseTheoreticalEndToAxle4Rear() -
            tempVehicle.getAxle1RearToAxle2Rear() - tempVehicle.getAxle2RearToAxle3Rear() - tempVehicle.getAxle3RearToAxle4Rear();
    }
}

function getAxle2FrontToAxle1RearMax() {
    if (globals.user.isLoadingAdministrator()) {
        return 9999;
    } else {
        var tempVehicle = getActiveOffer().getRig().getVehicle();
        return /*evaluateLegislation(legislationVals.variableTypes.VehicleWheelbaseTheoreticalMax)*/ getLowestMaxFromActiveLegislations(legislationVals.variableTypes.VehicleWheelbaseTheoreticalMax) + tempVehicle.getWheelbaseTheoreticalEndToAxle4Rear() -
            tempVehicle.getAxle1RearToAxle2Rear() - tempVehicle.getAxle2RearToAxle3Rear() - tempVehicle.getAxle3RearToAxle4Rear();
    }
}

function getRearOverhangLegislationMax() {
    if (globals.user.isLoadingAdministrator()) {
        return 9999;
    } else {
        //return evaluateLegislation(legislationVals.variableTypes.ComVehRearOverhangLegislationMax);
        return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.ComVehRearOverhangLegislationMax);
    }
}

function getDrawbarLengthMin(attrPath) {
    if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
        return Math.max(/*evaluateLegislation(legislationVals.variableTypes.Trailer1DrawbarProtrusionMin)*/ getHighestMinFromActiveLegislations(legislationVals.variableTypes.Trailer1DrawbarProtrusionMin), 0);
    } else {
        return Math.max(/*evaluateLegislation(legislationVals.variableTypes.Trailer2DrawbarProtrusionMin)*/ getHighestMinFromActiveLegislations(legislationVals.variableTypes.Trailer2DrawbarProtrusionMin), 0);
    }
}

function getTrailerLengthMin(attrPath) {
    if (globals.user.isLoadingAdministrator()) {
        return 0;
    } else {
        if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
            //return evaluateLegislation(legislationVals.variableTypes.Trailer1LengthMin);
            return getHighestMinFromActiveLegislations(legislationVals.variableTypes.Trailer1LengthMin);
        } else {
            //return evaluateLegislation(legislationVals.variableTypes.Trailer2LengthMin);
            return getHighestMinFromActiveLegislations(legislationVals.variableTypes.Trailer2LengthMin);
        }
    }
}

function getTrailerLengthMax(attrContext) {
    if (globals.user.isLoadingAdministrator()) {
        //return 25000;
        if (attrContext.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
            //return evaluateLegislation(legislationVals.variableTypes.Trailer1LengthMaxAdmin);
            return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer1LengthMaxAdmin);
        } else {
            //return evaluateLegislation(legislationVals.variableTypes.Trailer2LengthMaxAdmin);
            return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer2LengthMaxAdmin);
        }
    } else {
        if (attrContext.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
            //return evaluateLegislation(legislationVals.variableTypes.Trailer1LengthMax);
            return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer1LengthMax);
        } else {
            //return evaluateLegislation(legislationVals.variableTypes.Trailer2LengthMax);
            return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer2LengthMax);
        }
    }
}

function getTrailerFrontOverhangMin(attrPath) {
    if (globals.user.isLoadingAdministrator()) {
        return 0;
    } else {
        if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
            //return evaluateLegislation(legislationVals.variableTypes.Trailer1FrontOverhangMin);
            return getHighestMinFromActiveLegislations(legislationVals.variableTypes.Trailer1FrontOverhangMin);
        } else {
            //return evaluateLegislation(legislationVals.variableTypes.Trailer2FrontOverhangMin);
            return getHighestMinFromActiveLegislations(legislationVals.variableTypes.Trailer2FrontOverhangMin);
        }
    }
}

function getTrailerFrontOverhangMax(attrPath) {
    if (globals.user.isLoadingAdministrator()) {
        return 5000;
    } else {
        if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
            //return evaluateLegislation(legislationVals.variableTypes.Trailer1FrontOverhangMax);
            return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer1FrontOverhangMax);
        } else {
            //return evaluateLegislation(legislationVals.variableTypes.Trailer2FrontOverhangMax);
            return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer2FrontOverhangMax);
        }
    }
}

function getTrailerWheelbaseTheoreticalMin(attrPath) {
    if (globals.user.isLoadingAdministrator()) {
        return 0;
    } else {
        if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
            //return evaluateLegislation(legislationVals.variableTypes.Trailer1WheelbaseTheoreticalMin);
            return getHighestMinFromActiveLegislations(legislationVals.variableTypes.Trailer1WheelbaseTheoreticalMin);
        } else {
            //return evaluateLegislation(legislationVals.variableTypes.Trailer2WheelbaseTheoreticalMin);
            return getHighestMinFromActiveLegislations(legislationVals.variableTypes.Trailer2WheelbaseTheoreticalMin);
        }
    }
}

function getTrailerWheelbaseTheoreticalMax(attrPath) {
    if (globals.user.isLoadingAdministrator()) {
        return 20000;
    } else {
        if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
            //return evaluateLegislation(legislationVals.variableTypes.Trailer1WheelbaseTheoreticalMax);
            return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer1WheelbaseTheoreticalMax);
        } else {
            //return evaluateLegislation(legislationVals.variableTypes.Trailer2WheelbaseTheoreticalMax);
            return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer2WheelbaseTheoreticalMax);
        }
    }
}

function getKingpinToRearOfTrailerMax() {
    if (globals.user.isLoadingAdministrator()) {
        return 23000;
    } else {
        //return evaluateLegislation(legislationVals.variableTypes.SemiArticulationToRearOfTrailer);
        return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.SemiArticulationToRearOfTrailer);
    }
}

function getTurntableToRearOfTrailerMax() {
    if (globals.user.isLoadingAdministrator()) {
        return 23000;
    } else {
        //return evaluateLegislation(legislationVals.variableTypes.SemiOrDogArticulationToRearOfTrailer);
        return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.SemiOrDogArticulationToRearOfTrailer);
    }
}

function getPayloadLengthMax(attrPath) {

    //var chassisObjectToUse = rigOps.getChassisObjectFromPath(attrPath);
    //var tempBody = chassisObjectToUse.getAccessoryHolder().getBody();

    //if (tempBody !== null) {
    //    //return tempBody.getActualInternalLength();
    //    return 20000;
    //} else {
    //    if ((attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) || attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) && (chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(chassisObjectToUse)))) {
    //        return chassisObjectToUse.getLength();
    //    } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
    //        return chassisObjectToUse.getBodyLengthInternal();
    //    }
    //}

    return 99999;
}

function getPayloadHeightMax(attrPath) {

    var chassisObjectToUse = rigOps.getChassisObjectFromPath(attrPath);
    var tempBody = chassisObjectToUse.getAccessoryHolder().getBody();

    if (tempBody !== null) {
        //return tempBody.getActualInternalHeight();
        return 5000;
    } else {
        if ((attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) || attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) && (chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(chassisObjectToUse)))) {
            return chassisObjectToUse.getOverallHeight();
        } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            return chassisObjectToUse.getBodyHeightInternal();
        }
    }

    return 0;
}

function getPayloadFromHeadboardMin(attrPath) {

    var chassisObjectToUse = rigOps.getChassisObjectFromPath(attrPath);
    var tempBody = chassisObjectToUse.getAccessoryHolder().getBody();
    var tempPayload = rigOps.getPayloadFromPathById(attrPath, globals.getIdOrNumberFromPath(attrPath, 2));

    return -tempPayload.getLength();
    //if (tempBody !== null) {
    //    return tempBody.getActualInternalLength();
    //} else {
    //    if ((attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) || attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) && (chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(chassisObjectToUse)))) {
    //        return chassisObjectToUse.getLength();
    //    } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
    //        return chassisObjectToUse.getBodyLengthInternal();
    //    }
    //}

    //return 0;
}

function getPayloadFromHeadboardMax(attrPath) {

    var chassisObjectToUse = rigOps.getChassisObjectFromPath(attrPath);
    var tempBody = chassisObjectToUse.getAccessoryHolder().getBody();

    if (tempBody !== null) {
        return tempBody.getActualInternalLength();
    } else {
        if ((attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) || attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) && (chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(chassisObjectToUse)))) {
            return chassisObjectToUse.getLength();
        } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            return chassisObjectToUse.getBodyLengthInternal();
        }
    }

    return 0;
}

function getPayloadAboveDeckMax(attrPath) {

    //var chassisObjectToUse = rigOps.getChassisObjectFromPath(attrPath);
    //var tempBody = chassisObjectToUse.getAccessoryHolder().getBody();

    //if (tempBody !== null) {
    //    return tempBody.getActualInternalHeight();
    //} else {
    //    if ((attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) || attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) && (chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(chassisObjectToUse)))) {
    //        return chassisObjectToUse.getOverallHeight();
    //    } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
    //        return chassisObjectToUse.getBodyHeightInternal();
    //    }
    //}

    return 9999;
}

function getPayloadAboveDeckMin(attrPath) {

    var chassisObjectToUse = rigOps.getChassisObjectFromPath(attrPath);
    var tempBody = chassisObjectToUse.getAccessoryHolder().getBody();

    if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE)) {
        if (chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            return -chassisObjectToUse.getFloorHeight();
        } else if (tempBody !== null) {
            return -(chassisObjectToUse.getChassisHeightMax() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight());
        }
    } else {
        if (tempBody === null) {
            return -chassisObjectToUse.getChassisHeight();
        } else {
            return -(chassisObjectToUse.getChassisHeight() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight());
        }
    }
}

function getHitchOffsetMax(attrPath) {
    //#unfinished, will need to cater for trailer when hitch added to trailer
    var tempVehicle = getActiveOffer().getRig().getVehicle();

    //return tempVehicle.getOverallLengthExclAccessories() - tempVehicle.getRearProtrusion() - tempVehicle.getWheelbaseTheoreticalEnd() + 1000;
    return tempVehicle.getRearMostDrivenAxleToChassisEnd() + 5000;
}

function getHitchHorizontalCOGMax(attrPath) {
    //#unfinished, will need to cater for trailer when hitch added to trailer
    var tempHitch = getActiveOffer().getRig().getVehicle().getAccessoryHolder().getHitch();
    if (tempHitch !== null) {
        return tempHitch.getMountingLength() + tempHitch.getCouplingOffset() + tempHitch.getFunnelLength();
    } else {
        return 0;
    }

}

function getHitchVerticalCOGMax(attrPath) {
    //#unfinished, will need to cater for trailer when hitch added to trailer
    var tempHitch = getActiveOffer().getRig().getVehicle().getAccessoryHolder().getHitch();
    if (tempHitch !== null) {
        return tempHitch.getHeight();
    } else {
        return 0;
    }
}

function getHitchLateralCOGMax(attrPath) {
    //#unfinished, will need to cater for trailer when hitch added to trailer
    var tempHitch = getActiveOffer().getRig().getVehicle().getAccessoryHolder().getHitch();
    if (tempHitch !== null) {
        return tempHitch.getWidth();
    } else {
        return 0;
    }
}

function getTrailerFrontAxleSpacingMin(attrPath) {
    return rigOps.getChassisObjectFromPath(attrPath).getTyreWidth() * 2;
}

function getTrailerDrawbarHeightMax(attrPath) {
    if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
        //return evaluateLegislation(legislationVals.variableTypes.OverallTrailer1HeightMax);
        return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.OverallTrailer1HeightMax);
    } else {
        //return evaluateLegislation(legislationVals.variableTypes.OverallTrailer2HeightMax);
        return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.OverallTrailer2HeightMax);
    }
}

function getTrailerTurntableHeightMax(attrPath) {
    if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
        //return evaluateLegislation(legislationVals.variableTypes.OverallTrailer1HeightMax);
        return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.OverallTrailer1HeightMax);
    } else {
        //return evaluateLegislation(legislationVals.variableTypes.OverallTrailer2HeightMax);
        return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.OverallTrailer2HeightMax);
    }
}

function getHitchCouplingHeightMax(attrPath) {
    //#unfinished, will need to change when hitch added to semi trailer
    return rigOps.getChassisObjectFromPath(attrPath).getAccessoryHolder().getHitch().getHeight();
}

function getTrailerOverallWidthMax(attrContext) {
    if(globals.user.isLoadingAdministrator()) {
        return 20000;
    }else {
        let bodyWidthManufacturerMax = getActiveOffer().getRig().getVehicle().getBodyWidthManufacturerMax();
        if (attrContext.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
            if(bodyWidthManufacturerMax > 0) {
                return Math.min(bodyWidthManufacturerMax, getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer1OverallWidthMax));
            }
            return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer1OverallWidthMax);
        } else if (attrContext.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
            if(bodyWidthManufacturerMax > 0) {
                return Math.min(bodyWidthManufacturerMax, getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer2OverallWidthMax));
            }
            return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer2OverallWidthMax);
        }
    }
    // if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
    //     //return evaluateLegislation(legislationVals.variableTypes.Trailer1OverallWidthMax);
    //     return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer1OverallWidthMax);
    // } else {
    //     //return evaluateLegislation(legislationVals.variableTypes.Trailer2OverallWidthMax);
    //     return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer2OverallWidthMax);
    // }
}

function getTrailerRearMostFrontAxleToAxle1RearMin(attrPath) {
    if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
        return getActiveOffer().getRig().getTrailer1().getAxlesHolder().getRearMostFrontAxle().getTyreRadius();
    } else {
        return getActiveOffer().getRig().getTrailer2().getAxlesHolder().getRearMostFrontAxle().getTyreRadius();
    }
}

function getTotalWidthMax(attrPath) {

   

    if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE)) {
        return getVehicleOverallWidthMaxLegislationVal();
    } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
        return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer1OverallWidthMax);
    } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
        return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer2OverallWidthMax);
    }
    return 0;
    
}


function getTrailerDrawbarLengthMax(attrPath) {
    if (globals.user.isLoadingAdministrator()) {
        return 9000;
    } else {
        //return evaluateLegislation(legislationVals.variableTypes.Trailer1DrawbarLengthMax);
        return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer1DrawbarLengthMax);
    }
}

function getBodyOrEquipmentHeightMax(attrContext) {
    //var chassisObectToUse = rigOps.getChassisObjectFromPath(attrPath);
    //var tempBody = chassisObectToUse.getAccessoryHolder().getBody();

    if (globals.user.isLoadingAdministrator()) {
        //return tempBody.getActualInternalHeight();
        return 9000;
    } else {
        //return evaluateLegislation(legislationVals.variableTypes.OverallVehicleHeightMax);
        return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.OverallVehicleHeightMax);
    }
}

function getBodyOrEquipmentWidthMax(attrContext) {
    //var chassisObectToUse = rigOps.getChassisObjectFromPath(attrPath);
    //var tempBody = chassisObectToUse.getAccessoryHolder().getBody();

    if (globals.user.isLoadingAdministrator()) {
        //return tempBody.getActualInternalHeight();
        return 20000;
    } else {
        let bodyWidthManufacturerMax = getActiveOffer().getRig().getVehicle().getBodyWidthManufacturerMax();
        if (attrContext.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE)) {
            if(bodyWidthManufacturerMax > 0) {
                return Math.min(bodyWidthManufacturerMax, getLowestMaxFromActiveLegislations(legislationVals.variableTypes.VehicleOverallWidthMax));
            }
            return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.VehicleOverallWidthMax);
        } else if (attrContext.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
            if(bodyWidthManufacturerMax > 0) {
                return Math.min(bodyWidthManufacturerMax, getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer1OverallWidthMax));
            }
            return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer1OverallWidthMax);
        } else if (attrContext.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
            if(bodyWidthManufacturerMax > 0) {
                return Math.min(bodyWidthManufacturerMax, getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer2OverallWidthMax));
            }
            return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.Trailer2OverallWidthMax);
        }

    }

    return 0;
}

function getGapBetweenVehicleAndTrailerMax() {
    if (globals.user.isLoadingAdministrator()) {
        //return tempBody.getActualInternalHeight();
        return 9000;
    } else {
        //return evaluateLegislation(legislationVals.variableTypes.ComVehDrawbarGapBetweenVehicleAndTrailerMax);
        return getLowestMaxFromActiveLegislations(legislationVals.variableTypes.ComVehDrawbarGapBetweenVehicleAndTrailerMax);
    }
}

function getHitchPointToAxle1RearMin(attrPath) {
    if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
        return getActiveOffer().getRig().getTrailer1().getDrawbarLength();
    } else {
        return getActiveOffer().getRig().getTrailer2().getDrawbarLength();
    }
}

function getBodyRoofThicknessMax(attrPath) {
    if (globals.user.isLoadingAdministrator()) {
        return 1000;
    } else {
        return 500;
    }
}

function getFifthWheelOffsetMin() {
    if (globals.user.isLoadingAdministrator()) {
        return -5000;
    } else {
        return getActiveOffer().getRig().getVehicle().getFifthWheelOffsetMin();
    }
}

function getFifthWheelOffsetMax() {
    if (globals.user.isLoadingAdministrator()) {
        return 5000;
    } else {
        return getActiveOffer().getRig().getVehicle().getFifthWheelOffsetMax();
    }
}

function getAxleDesiredWeightMax(path) {
    var axleToUse = rigOps.getAxleFromPath(path);
    if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED) {
        return axleToUse.getSuspensionMassRating();
    } else {
        return axleToUse.getGrossAxleWeightRating();
    }
}

function getVehicleLateralCOGMaxVal() {
    return Math.max(getActiveOffer().getRig().getVehicle().getCabWidth(), getActiveOffer().getRig().getVehicle().getRearWidth());
}

function getBodyLateralCOGMax(attrPath) {
    if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE)) {
        return getActiveOffer().getRig().getVehicle().getAccessoryHolder().getBody().getActualWidth();
    } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
        return getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getBody().getActualWidth();
    } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
        return getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getBody().getActualWidth();
    }
    return 0;
}

function getPayloadFromLeftSidewallMax(attrPath) {

    var tempBody = rigOps.getBodyFromPath(attrPath);
    var accessory = rigOps.getAccessoryFromPath(attrPath);

    var offsetToCentreLine = rigOps.getTopViewCentreLine();

    if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE)) {
        if (tempBody !== null) {
            return (offsetToCentreLine + tempBody.getActualInternalWidth() / 2 + 10000) - accessory.getWidth();
        } else {
            return (offsetToCentreLine + getActiveOffer().getRig().getVehicle().getLoadCompartmentWidth() / 2 + 10000) - accessory.getWidth();
        }

    } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
        if (tempBody !== null) {
            return (offsetToCentreLine + tempBody.getActualInternalWidth() / 2 + 10000) - accessory.getWidth();
        } else {
            return (offsetToCentreLine + getActiveOffer().getRig().getTrailer1().getOverallWidth() / 2 + 10000) - accessory.getWidth();
        }
    } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
        if (tempBody !== null) {
            return (offsetToCentreLine + tempBody.getActualInternalWidth() / 2 + 10000) - accessory.getWidth();
        } else {
            return (offsetToCentreLine + getActiveOffer().getRig().getTrailer2().getOverallWidth() / 2 + 10000) - accessory.getWidth();
        }
    }
    return 0;
}

function getFromLeftOfBodyWidthExternalItemMax(attrPath) {

    var accessory = rigOps.getAccessoryFromPath(attrPath);

    var offsetToCentreLine = rigOps.getTopViewCentreLine();

    if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE)) {
        if (getActiveOffer().getRig().getVehicle().getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            return (offsetToCentreLine + getActiveOffer().getRig().getVehicle().getRearWidth() / 2 + 10000) - accessory.getWidth();
        } else {
            return (offsetToCentreLine + getActiveOffer().getRig().getVehicle().getAccessoryHolder().getBody().getActualWidth() / 2 + 10000) - accessory.getWidth();
        }
    } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
        return (offsetToCentreLine + getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getBody().getActualWidth() / 2 + 10000) - accessory.getWidth();
    } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
        return (offsetToCentreLine + getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getBody().getActualWidth() / 2 + 10000) - accessory.getWidth();
    }
    return 0;
}

function getFromLeftOfVehicleRearWidthItemMax(attrPath) {

    var accessory = rigOps.getAccessoryFromPath(attrPath); //rigOps.getLeftOfChassisRearWidth(getActiveOffer().getRig().getVehicle())

    var offsetToCentreLine = rigOps.getTopViewCentreLine();

    if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE)) {
        return (offsetToCentreLine + getActiveOffer().getRig().getVehicle().getRearWidth() / 2 + 10000) - accessory.getWidth(); //return getActiveOffer().getRig().getVehicle().getRearWidth() + 1000;//
    } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
        return (offsetToCentreLine + getActiveOffer().getRig().getTrailer1().getOverallWidth() / 2 + 10000) - accessory.getWidth();
    } else if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
        return (offsetToCentreLine + getActiveOffer().getRig().getTrailer2().getOverallWidth() / 2 + 10000) - accessory.getWidth();
    }
    return 0;
}

function getWheelbaseSpecifiedMin() {
    if (globals.user.isLoadingAdministrator()) {
        return 1000;
    } else {
        //return getActiveOffer().getRig().getVehicle().getWheelbaseTheoreticalManufacturerMin();
        return rigOps.getVehicleWheelbaseMinimum();
    }

}

function getWheelbaseSpecifiedMax() {
    if (globals.user.isLoadingAdministrator()) {
        return 9999;
    } else {
        //return getActiveOffer().getRig().getVehicle().getWheelbaseTheoreticalManufacturerMax();
        return rigOps.getVehicleWheelbaseMaximum();
    }

}

function getAxle1FrontToBackOfCabMin() {
    //return getActiveOffer().getRig().getVehicle().getBumperToBackOfCab() - getActiveOffer().getRig().getVehicle().getFrontOverhang();
    return -(getActiveOffer().getRig().getVehicle().getFrontOverhang());
}

function getAxle1FrontToBackOfCabMax() {
    return getActiveOffer().getRig().getVehicle().getAxle1FrontToAxle2Front() + getActiveOffer().getRig().getVehicle().getAxle2FrontToAxle1Rear();
}

function getCabToAxlesMin() {
    if (globals.user.isLoadingAdministrator()) {
        return 1000;
    } else {
        // getWheelbaseTheoreticalManufacturerMin + Axle1FrontToWheelbaseTheoreticalStart - Axle1FrontToBackOfCab
        return getActiveOffer().getRig().getVehicle().getWheelbaseTheoreticalManufacturerMin() + getActiveOffer().getRig().getVehicle().getAxle1FrontToWheelbaseTheoreticalStart() - getActiveOffer().getRig().getVehicle().getAxle1FrontToBackOfCab();
    }
}

function getCabToAxlesMax() {
    if (globals.user.isLoadingAdministrator()) {
        return 9999;
    } else {
        // getWheelbaseTheoreticalManufacturerMax + Axle1FrontToWheelbaseTheoreticalStart - Axle1FrontToBackOfCab
        return getActiveOffer().getRig().getVehicle().getWheelbaseTheoreticalManufacturerMax() + getActiveOffer().getRig().getVehicle().getAxle1FrontToWheelbaseTheoreticalStart() - getActiveOffer().getRig().getVehicle().getAxle1FrontToBackOfCab();
    }
}

function getBodyLeftCompartmentWidthMax(attrPath) {

    var tempBody = rigOps.getBodyFromPath(attrPath);

    return tempBody.getActualWidth() - tempBody.getRightCompartmentWidth();
}

function getBodyRightCompartmentWidthMax(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    return tempBody.getActualWidth() - tempBody.getLeftCompartmentWidth();
}

function getBottomOfBodyToTopOfChassisMax(attrPath) {

    var chassisObjectToUse = rigOps.getChassisObjectFromPath(attrPath);

    if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        return chassisObjectToUse.getChassisHeightRear();
    } else {
        return chassisObjectToUse.getChassisHeight();
    }
}

function getAboveBelowChassisMin(attrPath) {
    var chassisObjectToUse = rigOps.getChassisObjectFromPath(attrPath);


    if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE)) {
        return -(chassisObjectToUse.getChassisHeightMax());
    } else {
        return -(chassisObjectToUse.getChassisHeight());
    }
}

function getTrailerFrontToStartOfTaperMin(attrPath) {
    return getActiveOffer().getRig().getTrailer1().getFrontOverhang();
}

function getTrailerFrontToStartOfTaperMax(attrPath) {
    //return getActiveOffer().getRig().getTrailer1().getFrontOverhang() + getActiveOffer().getRig().getTrailer1().getKingpinToAxle1Rear();
    return getActiveOffer().getRig().getTrailer1().getLength();
}

function getTrailerFifthWheelOffsetMin(attrPath) {
    return -getActiveOffer().getRig().getTrailer1().getWheelbaseTheoreticalEndToRearOfTrailer();
}

function getTrailerFifthWheelOffsetMax(attrPath) {
    return getActiveOffer().getRig().getTrailer1().getWheelbaseTheoreticalEndToStartOfTaper();
}

function getItemVerticalCOGMax() {
    if (activeConfigurationWizardItem.getAccessoryType() === config.ACCESSORY_TYPES.BODY && activeConfigurationWizardItem.getType() === config.BODY_TYPES.HOOKLIFT && activeConfigurationWizardItem.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
        return activeConfigurationWizardItem.getHeadboardHeight() + activeConfigurationWizardItem.getSubframeHeight() + activeConfigurationWizardItem.getSubstructureHeight();
    } else {
        return activeConfigurationWizardItemSize.getHeight();
    }
}

function getMountingPositionMaxVal(attrPath) {
    var chassisObjectToUse = rigOps.getChassisObjectFromPath(attrPath);
    let tempFridge = rigOps.getFridgeFromPath(attrPath);
    let tempBody = rigOps.getBodyFromPath(attrPath);

    if (attrPath.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE)) {
        return chassisObjectToUse.getChassisHeightMax() + tempBody.getAboveBelowChassis() + tempBody.getActualExternalHeight() - tempFridge.getHeight();
    } else {
        return chassisObjectToUse.getChassisHeight() + tempBody.getAboveBelowChassis() + tempBody.getActualExternalHeight() - tempFridge.getHeight();
    }
}

function getFridgeFromFrontOfBodyMinVal(attrPath) {
    var chassisObjectToUse = rigOps.getChassisObjectFromPath(attrPath);

    if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        return -(chassisObjectToUse.getBumperToBackOfCab() + rigOps.getBodyGap(attrPath) + rigOps.getBodyFromPath(attrPath).getActualLength() + 5000);
    } else {
        return -(rigOps.getBodyGap(attrPath) + rigOps.getBodyFromPath(attrPath).getActualLength() + 5000);
    }
}

function getFridgeFromFrontOfBodyMaxVal(attrPath) {
    var chassisObjectToUse = rigOps.getChassisObjectFromPath(attrPath);

    if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        return (chassisObjectToUse.getBumperToBackOfCab() + rigOps.getBodyGap(attrPath) + 5000);
    } else {
        return (rigOps.getBodyGap(attrPath)) + 5000;
    }
}

function getHighestMinFromActiveLegislations(legislationValName) {
    return Math.max(getValueForAvailableLegislationsAsArray(legislationValName));
}

function getLowestMaxFromActiveLegislations(legislationValName) {
    return getLesserOfActiveLegislationVals(legislationValName);
}

function getValueForAvailableLegislationsAsArray(legislationValName, legislations) {
    var valObjs = evaluateValueForAvailableLegislations(legislationValName, legislations);

    var valsArray = [];
    Object.keys(valObjs).forEach(function (key) {
        valsArray.push(valObjs[key]);
    });

    return valsArray;
}

function getLesserOfActiveLegislationVals(legislationValName, legislations) {
    return Math.min(...getValueForAvailableLegislationsAsArray(legislationValName, legislations));
    //return evaluateLegislation(legislationValName);
}

function getGreaterOfActiveLegislationVals(legislationValName, legislations) {
    return Math.max(...getValueForAvailableLegislationsAsArray(legislationValName, legislations));
}

function evaluateValueForAvailableLegislations(legislationValName, legislations) {
    var evaluationResults = {},
        legislationsToCheck = Array.isArray(legislations) === true ? legislations : _legislations;

    var tempRigOpsForEvaluatingValueForActiveLegislations = new RigOps();
    
    legislationsToCheck.forEach(function (legislationDetails) { 

        var evaluateLegislationWrapper = function (attributeName) {
            return evaluateLegislation(attributeName, legislationDetails, tempRigOpsForEvaluatingValueForActiveLegislations);
        };
        // var baseOffer = undefined;
        tempRigOpsForEvaluatingValueForActiveLegislations.setup(getActiveOffer(), getOriginalOffer(), getBaseOffer(), evaluateLegislationWrapper, legislationVals);
        //tempRigOpsForEvaluatingValueForActiveLegislations.setCombinationPermissibleVals();
        //rigOps.setLegislationEvaluationFunction(evaluateLegislationWrapper);
        //clearLegislationQuickLookup();
        //if (needToMaximise(tempRigOpsForEvaluatingValueForActiveLegislations)) {
        //    tempRigOpsForEvaluatingValueForActiveLegislations.maximisePayloads();
        //}

        evaluationResults[legislationDetails.id] = evaluateLegislationWrapper(legislationValName);




    });
    //rigOps.setLegislationEvaluationFunction(getLesserOfActiveLegislationVals);
    return evaluationResults;
}

function needToMaximise(rigOpsToUse) {
    var doMaximise = true;

    if (rigOpsToUse.canMaximisePayloadForCombination()) {
        getActiveOffer().getRig().getChassisObjects().forEach(function (chassisObject) {
            var tempPayloadObject = chassisObject.getPayloadHolder().getSimplePayloadObject();
            if (tempPayloadObject && tempPayloadObject.getSpecifyMassOverride() === true) {
                doMaximise = false;
            }
        });
    }

    return doMaximise;
}

function getBridgeFormulaUsedInAtLeastOneActiveLegislation() {
    var evaluationResults = [];

    var tempRigOpsForBridgeFormulaCheck = new RigOps();

    _legislations.forEach(function (legislationDetails) {

        var evaluateLegislationWrapper = function (attributeName) {
            return evaluateLegislation(attributeName, legislationDetails, tempRigOpsForBridgeFormulaCheck);
        };
        // var baseOffer = undefined;
        tempRigOpsForBridgeFormulaCheck.setup(getActiveOffer(), getOriginalOffer(), getBaseOffer(), evaluateLegislationWrapper, legislationVals);
        tempRigOpsForBridgeFormulaCheck.setCombinationPermissibleVals();
        //rigOps.setLegislationEvaluationFunction(evaluateLegislationWrapper);
        //clearLegislationQuickLookup();
        //if (needToMaximise(rigOps)) {
        //    rigOps.maximisePayloads();
        //}

        evaluationResults.push(tempRigOpsForBridgeFormulaCheck.getCurrentCombinationBridgeFormulaUsed());




    });
    //rigOps.setLegislationEvaluationFunction(getLesserOfActiveLegislationVals);
    return Math.max(...evaluationResults);
}

function getDataForActiveLegislations(altRigOps) {
    if (!curActiveLegislationsDataObject) {
        if (!generatingActiveLegislationsData) {
            return generateDataForActiveLegislations(altRigOps);
        } else {
            return liveUpdateMultipleLegislationsDataObject;
        }
    }
    if (!generatingActiveLegislationsData) {
        return curActiveLegislationsDataObject;
    } else {
        return liveUpdateMultipleLegislationsDataObject;
    }
    
}

function generateDataForActiveLegislations(altRigOps) {
    
    generatingActiveLegislationsData = true;
    liveUpdateMultipleLegislationsDataObject = null;
    liveUpdateMultipleLegislationsDataObject = {};

    var rigOpsToUse = altRigOps !== undefined ? altRigOps : new RigOps();
    
    var legislationsBridgeGroupsVisible = {};

    liveUpdateMultipleLegislationsDataObject.worstOverloadingStatus = config.OVERLOADING_STATUS.OK;
    tableCellHighlightColours = {};
    bridgeFormulaPermissibleHighlightColours = {};
    steerAxleMinimumHighlightColours = {};
    worstBridgeFormulaOverloadColour = {};
    worstBridgeFormulaPermissibleOverloadColour = {};
    worstSteerAxleMinimumOverloadColour = {};

    var bypassCurObjects = false;
    if (altRigOps !== undefined) {
        bypassCurObjects = true;
    }
    
    liveUpdateMultipleLegislationsDataObject.newWarnings = {};
    liveUpdateMultipleLegislationsDataObject.newTips = {};
    liveUpdateMultipleLegislationsDataObject.newNotes = {};
    liveUpdateMultipleLegislationsDataObject.overloadingColourInstances = {};
    liveUpdateMultipleLegislationsDataObject.mergedLegislationBridgeFormulaPermissibleVals = {};
    liveUpdateMultipleLegislationsDataObject.activeLegislationsCombinationPermissibleValues = {};
    liveUpdateMultipleLegislationsDataObject.bridgeFormulaPermissibleOverloadingColourInstances = {};
    liveUpdateMultipleLegislationsDataObject.steerAxlesMinimumOverloadingColourInstances = {};
    liveUpdateMultipleLegislationsDataObject.rulesTriggeredForLegislation = {};
    liveUpdateMultipleLegislationsDataObject.triggeredRuleContexts = {};
    liveUpdateMultipleLegislationsDataObject.legislationUsesDataDrivenRulesMethod = {};

    var legislationBridgeFormulaPermissibleVals = {};

    
    
    _legislations.forEach(function (legislationDetails) {

        var evaluateLegislationWrapper = function (attributeName) {
            return evaluateLegislation(attributeName, legislationDetails, rigOpsToUse)
        };
        if (!altRigOps) {
            // var baseOffer = undefined;
            rigOpsToUse.setup(getActiveOffer(), getOriginalOffer(), getBaseOffer(), evaluateLegislationWrapper, legislationVals);
            rigOpsToUse.setCombinationPermissibleVals();
        } else {
            rigOpsToUse.setLegislationEvaluationFunction(evaluateLegislationWrapper);
        }
        // tempEvaluateLegislationRules(legislationDetails, evaluateLegislationWrapper);
        liveUpdateMultipleLegislationsDataObject.legislationUsesDataDrivenRulesMethod[legislationDetails.id] = evaluateLegislationWrapper(legislationVals.variableTypes.UsesDataDrivenRulesMethod) === 1 ? true : false;

        getActiveOffer().getRig().getChassisObjects().forEach(function (chassisObject) {
            var chassisObjectTypeOrPath = chassisObject.getChassisObjectName();
            //updateBridgeFormulas(chassisObject.getChassisObjectName());
            if (!legislationBridgeFormulaPermissibleVals[chassisObjectTypeOrPath]){
                legislationBridgeFormulaPermissibleVals[chassisObjectTypeOrPath] = [];
            }
            legislationBridgeFormulaPermissibleVals[chassisObjectTypeOrPath].push(rigOpsToUse.generateBridgeFormulaPermissibleValues(chassisObjectTypeOrPath));
        });

        

    });

    getActiveOffer().getRig().getChassisObjects().forEach(function (chassisObject) {
        var chassisObjectTypeOrPath = chassisObject.getChassisObjectName();
        liveUpdateMultipleLegislationsDataObject.mergedLegislationBridgeFormulaPermissibleVals[chassisObjectTypeOrPath] = mergeBridgeFormulaPermissibleVals(chassisObjectTypeOrPath);
        rigOpsToUse.updateBridgeFormulaPermissibleValues(chassisObjectTypeOrPath, liveUpdateMultipleLegislationsDataObject.mergedLegislationBridgeFormulaPermissibleVals[chassisObjectTypeOrPath]);
    });

    _legislations.forEach(function (legislationDetails) {

        var evaluateLegislationWrapper = function (attributeName) {
            return evaluateLegislation(attributeName, legislationDetails, rigOpsToUse)
        };
        if (!altRigOps) {
            // var baseOffer = undefined;
            rigOpsToUse.setup(getActiveOffer(), getOriginalOffer(), getBaseOffer(), evaluateLegislationWrapper, legislationVals);
            rigOpsToUse.setCombinationPermissibleVals();
        } else {
            rigOpsToUse.setLegislationEvaluationFunction(evaluateLegislationWrapper);
        }

        

        liveUpdateMultipleLegislationsDataObject.activeLegislationsCombinationPermissibleValues[legislationDetails.id] = rigOpsToUse.getCombinationPermissibleVals();


    });

    //getActiveOffer().getRig().getChassisObjects().forEach(function (chassisObject) {
    //    var chassisObjectTypeOrPath = chassisObject.getChassisObjectName();
    //    liveUpdateMultipleLegislationsDataObject.mergedLegislationBridgeFormulaPermissibleVals[chassisObjectTypeOrPath] = mergeBridgeFormulaPermissibleVals(chassisObjectTypeOrPath);
    //    rigOpsToUse.updateBridgeFormulaPermissibleValues(chassisObjectTypeOrPath, liveUpdateMultipleLegislationsDataObject.mergedLegislationBridgeFormulaPermissibleVals[chassisObjectTypeOrPath]); 
    //});

    

    _legislations.forEach(function (legislationDetails) {

        var evaluateLegislationWrapper = function (attributeName) {
            return evaluateLegislation(attributeName, legislationDetails, rigOpsToUse)
        };
        if (!altRigOps) {
            // var baseOffer = undefined;
            rigOpsToUse.setup(getActiveOffer(), getOriginalOffer(), getBaseOffer(), evaluateLegislationWrapper, legislationVals)
            rigOpsToUse.setCombinationPermissibleVals();
        } else {
            rigOpsToUse.setLegislationEvaluationFunction(evaluateLegislationWrapper);
        }
        if (needToMaximise(rigOpsToUse)) {
            rigOpsToUse.maximisePayloads();
        }

        //getActiveOffer().getRig().getChassisObjects().forEach(function (chassisObject) {
        //    var chassisObjectTypeOrPath = chassisObject.getChassisObjectName();
        //    //updateBridgeFormulas(chassisObject.getChassisObjectName());
        //    legislationBridgeFormulaPermissibleVals[chassisObjectTypeOrPath] = [];
        //    legislationBridgeFormulaPermissibleVals[chassisObjectTypeOrPath].push(rigOpsToUse.generateBridgeFormulaPermissibleValues(chassisObjectTypeOrPath));
        //});


        liveUpdateMultipleLegislationsDataObject.overloadingColourInstances[legislationDetails.id] = generateOverloadingColColours(undefined, rigOpsToUse, legislationDetails.id, evaluateLegislationWrapper, 'GROSS'); // User Story 17565
        liveUpdateMultipleLegislationsDataObject.bridgeFormulaPermissibleOverloadingColourInstances[legislationDetails.id] = generateBridgeFormulaPermissibleOverloadingColColours(undefined, rigOpsToUse, legislationDetails.id, evaluateLegislationWrapper, 'GROSS'); // User Story 17565
        liveUpdateMultipleLegislationsDataObject.steerAxlesMinimumOverloadingColourInstances[legislationDetails.id] = generateSteerAxleMinimumOverloadingColColours(rigOpsToUse, legislationDetails.id, evaluateLegislationWrapper, 'GROSS'); // User Story 17565
        
        legislationsBridgeGroupsVisible[legislationDetails.id] = rigOpsToUse.getBridgeGroupsVisible();


        liveUpdateMultipleLegislationsDataObject.worstOverloadingStatus = returnWorstColourStatus(getWorstOverloadingStatus(legislationDetails.id), liveUpdateMultipleLegislationsDataObject.worstOverloadingStatus);

        //clearBridgeWorstHighlightColour();
        //liveUpdateMultipleLegislationsDataObject.newWarnings[legislationDetails.id] = generateLegislationWarnings(legislationDetails.id, rigOpsToUse, evaluateLegislationWrapper);
        //if (curWarnings && curWarnings[legislationDetails.id]) {
        //    liveUpdateMultipleLegislationsDataObject.newWarnings[legislationDetails.id].markNewStrings(curWarnings[legislationDetails.id]);
        //} else {
        //    liveUpdateMultipleLegislationsDataObject.newWarnings[legislationDetails.id].markNewStrings();
        //}
        
        
        //liveUpdateMultipleLegislationsDataObject.activeLegislationsCombinationPermissibleValues[legislationDetails.id] = rigOpsToUse.getCombinationPermissibleVals();
        

    });

    
    liveUpdateMultipleLegislationsDataObject.mergedLegislationBridgeGroupsVisible = mergeBridgeGroupsVisible();
    
    
    
    
    var mergedPermissibleValues = getMergedLegislationsCombinationPermissibleVals(liveUpdateMultipleLegislationsDataObject.activeLegislationsCombinationPermissibleValues);
    //var maximisedLoads;
    if (needToMaximise(rigOpsToUse)) {
        rigOpsToUse.maximisePayloads(mergedPermissibleValues);
        //maximisedLoads = rigOpsToUse.getMaximisedLoad();
    }
    rigOpsToUse.setCombinationPermissibleVals(mergedPermissibleValues);
    
    clearBridgeWorstHighlightColour();

    _legislations.forEach(function (legislationDetails) {

        var evaluateLegislationWrapper = function (attributeName) {
            return evaluateLegislation(attributeName, legislationDetails, rigOpsToUse)
        };
        if (!altRigOps) {
            // var baseOffer = undefined;
            rigOpsToUse.setup(getActiveOffer(), getOriginalOffer(), getBaseOffer(), evaluateLegislationWrapper, legislationVals)
            //rigOpsToUse.setCombinationPermissibleVals(mergedPermissibleValues);
        } else {
            rigOpsToUse.setLegislationEvaluationFunction(evaluateLegislationWrapper);
        }
        //if (needToMaximise(rigOpsToUse)) {
        //    rigOpsToUse.maximisePayloads();
        //}

        ////getActiveOffer().getRig().getChassisObjects().forEach(function (chassisObject) {
        ////    var chassisObjectTypeOrPath = chassisObject.getChassisObjectName();
        ////    //updateBridgeFormulas(chassisObject.getChassisObjectName());
        ////    legislationBridgeFormulaPermissibleVals[chassisObjectTypeOrPath] = [];
        ////    legislationBridgeFormulaPermissibleVals[chassisObjectTypeOrPath].push(rigOpsToUse.generateBridgeFormulaPermissibleValues(chassisObjectTypeOrPath));
        ////});


        //liveUpdateMultipleLegislationsDataObject.overloadingColourInstances[legislationDetails.id] = generateOverloadingColColours(undefined, rigOpsToUse, legislationDetails.id, evaluateLegislationWrapper, 'GROSS');

        //legislationsBridgeGroupsVisible.push(rigOpsToUse.getBridgeGroupsVisible());

        
        //liveUpdateMultipleLegislationsDataObject.worstOverloadingStatus = returnWorstColourStatus(getWorstOverloadingStatus(legislationDetails.id), liveUpdateMultipleLegislationsDataObject.worstOverloadingStatus);
        if(liveUpdateMultipleLegislationsDataObject.legislationUsesDataDrivenRulesMethod[legislationDetails.id]) {
            liveUpdateMultipleLegislationsDataObject.rulesTriggeredForLegislation[legislationDetails.id] = evaluateLegislationRules(legislationDetails, evaluateLegislationWrapper);
            let generatedTipsNotesAndWarnings = buildLegislationTipsNotesAndWarnings(liveUpdateMultipleLegislationsDataObject.rulesTriggeredForLegislation[legislationDetails.id], legislationDetails, rigOpsToUse, evaluateLegislationWrapper);
            liveUpdateMultipleLegislationsDataObject.newWarnings[legislationDetails.id] = generatedTipsNotesAndWarnings.newWarnings;
            liveUpdateMultipleLegislationsDataObject.newTips[legislationDetails.id] = generatedTipsNotesAndWarnings.newTips;
            liveUpdateMultipleLegislationsDataObject.newNotes[legislationDetails.id] = generatedTipsNotesAndWarnings.newNotes;
        }else {
            liveUpdateMultipleLegislationsDataObject.newWarnings[legislationDetails.id] = generateLegislationWarnings(legislationDetails.id, rigOpsToUse, evaluateLegislationWrapper);
        }
        
        
        if (globals.curWarnings && globals.curWarnings[legislationDetails.id]) {
            liveUpdateMultipleLegislationsDataObject.newWarnings[legislationDetails.id].markNewStrings(globals.curWarnings[legislationDetails.id]);
        } else {
            liveUpdateMultipleLegislationsDataObject.newWarnings[legislationDetails.id].markNewStrings();
        }
        if(liveUpdateMultipleLegislationsDataObject.legislationUsesDataDrivenRulesMethod[legislationDetails.id]) {
            if (globals.curTips && globals.curTips[legislationDetails.id]) {
                liveUpdateMultipleLegislationsDataObject.newTips[legislationDetails.id].markNewStrings(globals.curTips[legislationDetails.id]);
            } else {
                liveUpdateMultipleLegislationsDataObject.newTips[legislationDetails.id].markNewStrings();
            }
            if (globals.curDDRNotes && globals.curDDRNotes[legislationDetails.id]) {
                liveUpdateMultipleLegislationsDataObject.newNotes[legislationDetails.id].markNewStrings(globals.curDDRNotes[legislationDetails.id]);
            } else {
                liveUpdateMultipleLegislationsDataObject.newNotes[legislationDetails.id].markNewStrings();
            }
        }

        //liveUpdateMultipleLegislationsDataObject.activeLegislationsCombinationPermissibleValues[legislationDetails.id] = rigOpsToUse.getCombinationPermissibleVals();


    });
    liveUpdateMultipleLegislationsDataObject.newWarnings[config.WARNINGS_HEADINGS.MANUFACTURER_RATINGS] = getManufacturerLimitsWarnings(rigOpsToUse);
    if (globals.curWarnings && globals.curWarnings[config.WARNINGS_HEADINGS.MANUFACTURER_RATINGS]) {
        liveUpdateMultipleLegislationsDataObject.newWarnings[config.WARNINGS_HEADINGS.MANUFACTURER_RATINGS].markNewStrings(globals.curWarnings[config.WARNINGS_HEADINGS.MANUFACTURER_RATINGS]);
    } else {
        liveUpdateMultipleLegislationsDataObject.newWarnings[config.WARNINGS_HEADINGS.MANUFACTURER_RATINGS].markNewStrings();
    }
    liveUpdateMultipleLegislationsDataObject.triggeredRuleContexts = extractRuleContexts(liveUpdateMultipleLegislationsDataObject.rulesTriggeredForLegislation);

    if (!bypassCurObjects) {
        globals.curTips = copyLegislationsStringTrackerSet(liveUpdateMultipleLegislationsDataObject.newTips);
        curDistinctTips = generateDistinctTips(globals.curTips);
        
        globals.curDDRNotes = copyLegislationsStringTrackerSet(liveUpdateMultipleLegislationsDataObject.newNotes);
        //curWarnings = liveUpdateMultipleLegislationsDataObject.newWarnings;
        curActiveLegislationsDataObject = liveUpdateMultipleLegislationsDataObject;
        //curDistinctWarnings = generateDistinctWarnings(curWarnings);
    }

    //rigOpsToUse.setLegislationEvaluationFunction(getLesserOfActiveLegislationVals);
    generatingActiveLegislationsData = false;
    return liveUpdateMultipleLegislationsDataObject;
    
    function mergeBridgeGroupsVisible() {
        var legislationBridgeGroupsVisibleValsArrays = {}

        Object.keys(Object.values(legislationsBridgeGroupsVisible)[0]).forEach(function (key) {
            legislationBridgeGroupsVisibleValsArrays[key] = [];
        });

        Object.values(legislationsBridgeGroupsVisible).forEach(function (legislationBridgeGroupVisibleValSet) {
            Object.keys(legislationBridgeGroupVisibleValSet).forEach(function (key) {
                legislationBridgeGroupsVisibleValsArrays[key].push(legislationBridgeGroupVisibleValSet[key]);
            });
        });

        var mergedLegislationBridgeGroupsVisibleValSet = {};
        Object.keys(legislationBridgeGroupsVisibleValsArrays).forEach(function (key) {
            mergedLegislationBridgeGroupsVisibleValSet[key] = Math.max(...legislationBridgeGroupsVisibleValsArrays[key]) === 1 ? true : false;
        });

        //rigOpsToUse.setLegislationEvaluationFunction(getLesserOfActiveLegislationVals);

        return mergedLegislationBridgeGroupsVisibleValSet;
    }

    function mergeBridgeFormulaPermissibleVals(chassisObjectTypeOrPath) {
        var legislationBridgeFormulaPermissibleValArrays = {};
        Object.keys(legislationBridgeFormulaPermissibleVals[chassisObjectTypeOrPath][0]).forEach(function (key) {
            legislationBridgeFormulaPermissibleValArrays[key] = [];
        });

        legislationBridgeFormulaPermissibleVals[chassisObjectTypeOrPath].forEach(function (legislationBridgeFormulaPermissibleValSet) {
            Object.keys(legislationBridgeFormulaPermissibleValSet).forEach(function (key) {
                legislationBridgeFormulaPermissibleValArrays[key].push(legislationBridgeFormulaPermissibleValSet[key]);
            });
        });

        var mergedLegislationBridgeFormulaPermissibleValSet = {};
        Object.keys(legislationBridgeFormulaPermissibleValArrays).forEach(function (key) {
            mergedLegislationBridgeFormulaPermissibleValSet[key] = Math.min(...legislationBridgeFormulaPermissibleValArrays[key]);
        });

        return mergedLegislationBridgeFormulaPermissibleValSet;
    }
    
}

// function copyTips(legislationsTips) {
//     var copiedLegislationsTips = {};
//     Object.keys(legislationsTips).forEach(function (encodedLegislationIdAsKey){
//         let legislationTipsCopy = new StringTracker();
//         legislationsTips[encodedLegislationIdAsKey].getStringTrackerObjects().forEach(function (trackerObj) {
//             legislationTipsCopy.addTracker(trackerObj);
//         });
//         copiedLegislationsTips[encodedLegislationIdAsKey] = legislationTipsCopy; 
        
//     });
//     return copiedLegislationsTips;
// }

function copyLegislationsStringTrackerSet(legislationsStringTrackerSet) {
    var copiedLegislationsStringTrackerSet = {};
    Object.keys(legislationsStringTrackerSet).forEach(function (encodedLegislationIdAsKey){
        let legislationStringTrackerCopy = new StringTracker();
        legislationStringTrackerCopy.addDefaultTrackerState(config.TRACKER_STATE_KEY.TYPE, config.TRACKER_TYPE.WARNING);
        legislationsStringTrackerSet[encodedLegislationIdAsKey].getStringTrackerObjects().forEach(function (trackerObj) {
            legislationStringTrackerCopy.addTracker(trackerObj);
        });
        copiedLegislationsStringTrackerSet[encodedLegislationIdAsKey] = legislationStringTrackerCopy; 
        
    });
    return copiedLegislationsStringTrackerSet;
}

function getActiveLegislationsCombinationPermissibleVals() {
    return getDataForActiveLegislations().activeLegislationsCombinationPermissibleValues; 
}

function getMergedLegislationsCombinationPermissibleVals(activeLegislationsCombinationPermissibleValues) {
    var mergedLegislationsCombinationPermissibleVals = {};
    /*var activeLegislationsCombinationPermissibleValues = getActiveLegislationsCombinationPermissibleVals();*/
    Object.keys(activeLegislationsCombinationPermissibleValues).forEach(function (legislationIdKey) {
        Object.keys(activeLegislationsCombinationPermissibleValues[legislationIdKey]).forEach(function (permissibleValKey) {
            if (!mergedLegislationsCombinationPermissibleVals[permissibleValKey]) {
                mergedLegislationsCombinationPermissibleVals[permissibleValKey] = activeLegislationsCombinationPermissibleValues[legislationIdKey][permissibleValKey];
            } else {
                mergedLegislationsCombinationPermissibleVals[permissibleValKey] = Math.min(activeLegislationsCombinationPermissibleValues[legislationIdKey][permissibleValKey], mergedLegislationsCombinationPermissibleVals[permissibleValKey]);
            }
        });
        
    });

    return mergedLegislationsCombinationPermissibleVals;
}

/**
    * Uses list of legislation stubs to get detailed legislation objects using dataManager.getLegislationDetails and load them into _legislations variable
    * @param {object[]} legislationStubs - Array of legislation stubs
    * @returns {Promise} - Resolves when all legislation requests have resolved
    */
function loadLegislationsDetails(legislationIds) {
    var dfd = $.Deferred();

    var listOfLegislations = [];
    var listOfLegislationRequests = [];
    legislationIds.forEach(function (id) {
        var newLegislation = globals.user.getAvailableLegislations().filter(function (legislation) {
            return legislation.id === id;
        })[0]||null;
        if (newLegislation !== null) {
            listOfLegislations.push(newLegislation);
        } else {
            TScMessenger.writeErrorMessage('Legislation not found in getAvailableLegislations. Legislation ID = ' + id);
        }
    });
    listOfLegislations = globals.sortLegislationsArray(listOfLegislations, globals.user.getCountryAbbreviation());
    listOfLegislations.forEach(function (legislation) {
        listOfLegislationRequests.push(dataManager.getLegislationDetails(legislation.legislationId, legislation.countryId));
    });

    if (listOfLegislationRequests.length === 0 && _legislations === undefined) {
        // if not getting any legislations and no legislations have been loaded then this will cause an error
        // Sort the legislations as they would be displayed in settings and get the first in the list to load it into memory so offer can be opened
        var newLegislationsArray = globals.sortLegislationsArray(globals.user.getAvailableLegislations(), globals.user.getCountryAbbreviation()),
            firstLegislation = newLegislationsArray[0];
        listOfLegislationRequests.push(dataManager.getLegislationDetails(firstLegislation.legislationId, firstLegislation.countryId));
    }

    if (listOfLegislationRequests.length > 0) {
        $.when.apply($, listOfLegislationRequests)
            .then(function () {  
                _legislations = [];
                var returnedLegislations = arguments;
                if (listOfLegislations.length > 0) {
                    listOfLegislations.forEach(function (leg, i) {
                        returnedLegislations[i].Description = listOfLegislations[i].description;
                        Object.keys(returnedLegislations).forEach(function (key) {
                            var rl = returnedLegislations[key];
                            if (rl.Id === leg.id) {
                                rl.roadNames = leg.roadNames;
                                _legislations.push(new Legislation(rl));
                            }
                        });
                    });
                } else {
                    Object.keys(returnedLegislations).forEach(function (key) {                                
                        _legislations.push(new Legislation(returnedLegislations[key]));
                    });
                }
                dfd.resolve(_legislations);
            })
            .fail(function (error) {
                dfd.reject(error);
            });
    } else {
        dfd.resolve(_legislations);
    }
        return dfd.promise();

}

function setupValidationConfigs() {
    if (getActiveOffer().getDataAvailability().ActiveConfiguration && getActiveOffer().getConfiguration() !== undefined) {

        addValidationConfig(200, function () {
            return /*evaluateLegislation('VEHICLEFRONTOVERHANGMAX')*/ getLowestMaxFromActiveLegislations(legislationVals.variableTypes.VehicleFrontOverhangMax);
        }, config.VALUE_TYPE.FRONTOVERHANG).addValidationBaseTextId(function () { return '1852'; });
        addValidationConfig(undefined, function () {
            return /*evaluateLegislation('OVERALLVEHICLELENGTHMAX')*/getLowestMaxFromActiveLegislations(legislationVals.variableTypes.OverallVehicleLengthMax);
        }, config.VALUE_TYPE.OVERALLVEHICLELENGTH).addValidationBaseTextId(function () { return '1853'; });
        addValidationConfig(getOverallChassisObjectLengthMin, getOverallChassisObjectLengthMax, config.VALUE_TYPE.OVERALLCHASSISOBJECTLENGTH).addValidationBaseTextId(function () { return '2002'; });
        addValidationConfig(0, 50000 /*rigOps.getCabToAxles*/, config.VALUE_TYPE.REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES).addValidationBaseTextId(function () { return '1854'; });
        addValidationConfig(0, 4000, config.VALUE_TYPE.AXLE1FRONTTOAXLE2FRONT).addValidationBaseTextId(function () { return '1855'; });
        addValidationConfig(getConditionalWheelbaseMin, getConditionalWheelbaseMax, config.VALUE_TYPE.WHEELBASETHEORETICAL).addValidationBaseTextId(function () { return '1863'; });
        addValidationConfig(0, function () {
            return Math.round(0.9 * getConfiguration().getAxle1FrontToAxle2Front())
        }, config.VALUE_TYPE.AXLE1FRONTTOWHEELBASETHEORETICALSTART).addValidationBaseTextId(function () { return '1864'; });
        addValidationConfig(getAxle2FrontToAxle1RearMin, getAxle2FrontToAxle1RearMax, config.VALUE_TYPE.AXLE2FRONTTOAXLE1REAR).addValidationBaseTextId(function () { return '1856'; });
        addValidationConfig(0, 9999, config.VALUE_TYPE.AXLE1REARTOAXLE2REAR).addValidationBaseTextId(function () { return '1857'; });
        addValidationConfig(0, 9999, config.VALUE_TYPE.AXLE2REARTOAXLE3REAR).addValidationBaseTextId(function () { return '1858'; });
        addValidationConfig(0, 9999, config.VALUE_TYPE.AXLE3REARTOAXLE4REAR).addValidationBaseTextId(function () { return '1859'; });
        addValidationConfig(getWheelbaseSpecifiedMin, getWheelbaseSpecifiedMax, config.VALUE_TYPE.WHEELBASESPECIFIED).addValidationBaseTextId(function () { return '2073'; });
        addValidationConfig(getAxle1FrontToBackOfCabMin, getAxle1FrontToBackOfCabMax, config.VALUE_TYPE.AXLE1FRONTTOBACKOFCAB).addValidationBaseTextId(function () { return '2127'; });
        addValidationConfig(getCabToAxlesMin, getCabToAxlesMax, config.VALUE_TYPE.CABTOAXLES).addValidationBaseTextId(function () { return '2128'; });

        //addValidationConfig(function () { return evaluateLegislation('VEHICLEREAROVERHANGMIN'); }, function () { return evaluateLegislation('COMVEHREAROVERHANGLEGISLATIONMAX'); }, config.VALUE_TYPE.REAROVERHANG);
        addValidationConfig(function () {
            return /*evaluateLegislation('VEHICLEREAROVERHANGMIN')*/ getHighestMinFromActiveLegislations(legislationVals.variableTypes.VehicleRearOverhangMin);
        }, getRearOverhangLegislationMax, config.VALUE_TYPE.REAROVERHANG).addValidationBaseTextId(function () { return '1860'; });
        addValidationConfig(0, 9999, config.VALUE_TYPE.AFTERFRAME).addValidationBaseTextId(function () { return '4851'; });
        addValidationConfig(function () {
            return /*evaluateLegislation('COMVEHREAROVERHANGLEGISLATION')*/getHighestMinFromActiveLegislations(legislationVals.variableTypes.ComVehRearOverhangLegislation);
        }, getRearOverhangLegislationMax, config.VALUE_TYPE.REAROVERHANGTOTAL, config.VALIDATION_MESSAGE_TYPE.LESSTHAN).addValidationBaseTextId(function () { return '1861'; });
        addValidationConfig(getChassisObjectRearOverhangLegislation, getChassisObjectRearOverhangLegislationMax, config.VALUE_TYPE.CHASSISOBJECTREAROVERHANGTOTAL, config.VALIDATION_MESSAGE_TYPE.LESSTHAN).addValidationBaseTextId(function () { return '2004'; });
        addValidationConfig(0, 500, config.VALUE_TYPE.REARPROTRUSION).addValidationBaseTextId(function () { return '1862'; });
        addValidationConfig(0, getBumperToBackOfCabMaxVal, config.VALUE_TYPE.BUMPERTOBACKOFCAB).addValidationBaseTextId(function () { return '1865'; });
        addValidationConfig(0, rigOps.getCabToAxles, config.VALUE_TYPE.CABGAPRECOMMENDEDMIN).addValidationBaseTextId(function () { return '1866'; });
        addValidationConfig(0, getConfiguration().getFrontOverhang, config.VALUE_TYPE.CABHINGEPOINTFROMBUMPER).addValidationBaseTextId(function () { return '1867'; });
        addValidationConfig(0, function () {
            return getConfiguration().getChassisHeightFront() + 500;
        }, config.VALUE_TYPE.CABHINGEPOINTFROMGROUND).addValidationBaseTextId(function () { return '1868'; });
        addValidationConfig(0, function () {
            return Math.sqrt(Math.pow(getConfiguration().getBumperToBackOfCab(), 2) + Math.pow((getConfiguration().getCabHeight() - getConfiguration().getHingePointFromGround()), 2)) + 500;
        }, config.VALUE_TYPE.CABTILTRADIUS).addValidationBaseTextId(function () { return '1869'; });
        addValidationConfig(0, getConfiguration().getOverallLength, config.VALUE_TYPE.BUMPERTOSTARTOFBODYINTERNAL).addValidationBaseTextId(function () { return '1870'; });
        addValidationConfig(0, function () {
            return getConfiguration().getOverallLength() - getConfiguration().getBumperToStartOfBodyInternal();
        }, config.VALUE_TYPE.BODYLENGTHINTERNAL).addValidationBaseTextId(function () { return '1871'; });
        addValidationConfig(0, getConfiguration().getRearWidth, config.VALUE_TYPE.BODYWIDTHINTERNAL).addValidationBaseTextId(function () { return '2101'; });
        addValidationConfig(0, function () {
            return getConfiguration().getCabHeight() - getConfiguration().getBodyHeightInternal();
        }, config.VALUE_TYPE.BODYFLOORHEIGHT).addValidationBaseTextId(function () { return '1872'; });
        addValidationConfig(0, function () {
            return getConfiguration().getCabHeight() - getConfiguration().getFloorHeight();
        }, config.VALUE_TYPE.BODYHEIGHTINTERNAL).addValidationBaseTextId(function () { return '1873'; });
        addValidationConfig(1300, function () {
            return /*evaluateLegislation('OVERALLVEHICLEHEIGHTMAX')*/getLowestMaxFromActiveLegislations(legislationVals.variableTypes.OverallVehicleHeightMax);
        }, config.VALUE_TYPE.CABHEIGHT).addValidationBaseTextId(function () { return '1874'; });
        addValidationConfig(200, 1500, config.VALUE_TYPE.CHASSISHEIGHTFRONT).addValidationBaseTextId(function () { return '1875'; });
        addValidationConfig(200, 1500, config.VALUE_TYPE.CHASSISHEIGHTREAR).addValidationBaseTextId(function () { return '1876'; });
        addValidationConfig(500, 2500, config.VALUE_TYPE.FIFTHWHEELHEIGHT).addValidationBaseTextId(function () { return '1877'; });
        addValidationConfig(0, getVehicleOverallWidthMaxLegislationVal, config.VALUE_TYPE.CABWIDTH).addValidationBaseTextId(function () { return '1878'; });
        addValidationConfig(0, getVehicleOverallWidthMaxLegislationVal, config.VALUE_TYPE.REARWIDTH).addValidationBaseTextId(function () { return '1879'; });
        addValidationConfig(getConfiguration().getCabWidth, getVehicleOverallWidthMaxLegislationVal, config.VALUE_TYPE.OVERALLWIDTH).addValidationBaseTextId(function () { return '1880'; });
        addValidationConfig(0, getConfiguration().getCabWidth, config.VALUE_TYPE.TRACKWIDTHFRONT).addValidationBaseTextId(function () { return '1881'; });
        addValidationConfig(0, getConfiguration().getRearWidth, config.VALUE_TYPE.TRACKWIDTHREAR).addValidationBaseTextId(function () { return '1882'; });
        addValidationConfig(0, getConfiguration().getTrackWidthRear, config.VALUE_TYPE.CHASSISWIDTH).addValidationBaseTextId(function () { return '1883'; });
        addValidationConfig(0, 30000, config.VALUE_TYPE.TAREFRONT).addValidationBaseTextId(function () { return '1884'; });
        addValidationConfig(0, 50000, config.VALUE_TYPE.TAREREAR).addValidationBaseTextId(function () { return '1885'; });
        addValidationConfig(0, 50000, config.VALUE_TYPE.TAREREARREAR).addValidationBaseTextId(function () { return '1885'; });
        addValidationConfig(function () {
            return -(getConfiguration().getFrontOverhang() + getConfiguration().getAxle1FrontToWheelbaseTheoreticalStart());
        }, getVehicleHorizontalCOGMaxVal, config.VALUE_TYPE.HORIZONTALCOG).addValidationBaseTextId(function () { return '1886'; });
        addValidationConfig(0, getConfiguration().getCabHeight, config.VALUE_TYPE.VERTICALCOG).addValidationBaseTextId(function () { return '1887'; });
        addValidationConfig(0, getVehicleLateralCOGMaxVal, config.VALUE_TYPE.LATERALCOG).addValidationBaseTextId(function () { return '2074'; });
        addValidationConfig(0, 100000, config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS).addValidationBaseTextId(function () { return '1888'; });
        addValidationConfig(0, 150000, config.VALUE_TYPE.MANUFACTURERSREARAXLEMASS).addValidationBaseTextId(function () { return '1889'; });
        addValidationConfig(0, 250000, config.VALUE_TYPE.MANUFACTURERSGROSSVEHICLEMASS).addValidationBaseTextId(function () { return '1890'; });
        addValidationConfig(0, 250000, config.VALUE_TYPE.MANUFACTURERSGROSSCOMBINATIONMASS).addValidationBaseTextId(function () { return '1891'; });
        addValidationConfig(0, 100000, config.VALUE_TYPE.PERMISSIBLEFRONTAXLEMASS).addValidationBaseTextId(function () { return '1892'; });
        addValidationConfig(0, 150000, config.VALUE_TYPE.PERMISSIBLEREARAXLEMASS).addValidationBaseTextId(function () { return '1893'; });
        addValidationConfig(0, 250000, config.VALUE_TYPE.PERMISSIBLETOTALAXLEMASS).addValidationBaseTextId(function () { return '1894'; });

        // TC TODO 2020.1
        // Make sure values and messages are correct
        addValidationConfig(0, 999999, config.VALUE_TYPE.VEHICLEBRIDGEFORMULAPERMISSIBLE).addValidationBaseTextId(function () { return '2478'; });
        addValidationConfig(0, 999999, config.VALUE_TYPE.COMBINATIONBRIDGEFORMULAPERMISSIBLE).addValidationBaseTextId(function () { return '2479'; });
        addValidationConfig(0, 999999, config.VALUE_TYPE.GROSSAXLEWEIGHTRATING).addValidationBaseTextId(function () { return '2479'; });
        addValidationConfig(0, 999999, config.VALUE_TYPE.DESIREDWEIGHT).addValidationBaseTextId(function () { return '2479'; });
        

        addValidationConfig(0, 500, config.VALUE_TYPE.CREWMASSPERPERSON).addValidationBaseTextId(function () { return '1895'; });
        addValidationConfig(0, function () {
            return isTemplateVehicle() ? 9 : getConfiguration().getCrewFirstRowMax();
        }, config.VALUE_TYPE.NOOFCREWINFIRSTROW).addValidationBaseTextId(function () { return '1896'; });
        addValidationConfig(-1000, getConfiguration().getWheelbaseTheoretical, config.VALUE_TYPE.CREWFIRSTROWHORIZONTALCOG).addValidationBaseTextId(function () { return '1897'; });
        addValidationConfig(0, getConfiguration().getCabHeight, config.VALUE_TYPE.CREWFIRSTROWVERTICALCOG).addValidationBaseTextId(function () { return '1898'; });
        addValidationConfig(0, getActiveOffer().getRig().getVehicle().getCabWidth, config.VALUE_TYPE.CREWFIRSTROWLATERALCOG).addValidationBaseTextId(function () { return '2085'; });
        addValidationConfig(0, function () {
            return isTemplateVehicle() ? 9 : getConfiguration().getCrewSecondRowMax();
        }, config.VALUE_TYPE.NOOFCREWINSECONDROW).addValidationBaseTextId(function () { return '1899'; });
        addValidationConfig(-1000, getConfiguration().getWheelbaseTheoretical, config.VALUE_TYPE.CREWSECONDROWHORIZONTALCOG).addValidationBaseTextId(function () { return '1900'; });
        addValidationConfig(0, getConfiguration().getCabHeight, config.VALUE_TYPE.CREWSECONDROWVERTICALCOG).addValidationBaseTextId(function () { return '1901'; });
        addValidationConfig(0, getActiveOffer().getRig().getVehicle().getCabWidth, config.VALUE_TYPE.CREWSECONDROWLATERALCOG).addValidationBaseTextId(function () { return '2086'; });

        addValidationConfig(-99999, 99999, config.VALUE_TYPE.BODYCABGAP).addValidationBaseTextId(function () { return '1902'; });
        addValidationConfig(-99999, 99999, config.VALUE_TYPE.BODYFROMAXLE1FRONT).addValidationBaseTextId(function () { return '4430'; });
        addValidationConfig(function () { return -getActiveOffer().getRig().getVehicle().getChassisHeightRear(); }, 2000, config.VALUE_TYPE.BODYABOVEBELOWCHASSIS).addValidationBaseTextId(function () { return '3071'; });
        addValidationConfig(getBodyLengthMinVal, getBodyLengthMaxVal, config.VALUE_TYPE.BODYLENGTH).addValidationBaseTextId(function () { return '1903'; });
        addValidationConfig(0, undefined, config.VALUE_TYPE.BODYHEADBOARDTHICKNESS).addValidationBaseTextId(function () { return '1904'; });
        //addValidationConfig(0, function () { return evaluateLegislation('OVERALLVEHICLEHEIGHTMAX'); }, config.VALUE_TYPE.OVERALLVEHICLEHEIGHT);
        addValidationConfig(0, getOverallChassisObjectHeightMax, config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT).addValidationBaseTextId(function () { return '2003'; });
        addValidationConfig(0, undefined, config.VALUE_TYPE.BODYTAILBOARDTHICKNESS).addValidationBaseTextId(function () { return '1906'; });
        addValidationConfig(0, 1000, config.VALUE_TYPE.BODYSUBFRAMEHEIGHT).addValidationBaseTextId(function () { return '1907'; });
        addValidationConfig(0, 1000, config.VALUE_TYPE.BODYSUBSTRUCTUREHEIGHT).addValidationBaseTextId(function () { return '1908'; });
        addValidationConfig(0, getBodyOrEquipmentHeightMax, config.VALUE_TYPE.BODYHEIGHT).addValidationBaseTextId(function () { return '1909'; });
        addValidationConfig(0, getBodyRoofThicknessMax, config.VALUE_TYPE.BODYROOFTHICKNESS).addValidationBaseTextId(function () { return '1910'; });
        addValidationConfig(0, 50000, config.VALUE_TYPE.BODYMASS).addValidationBaseTextId(function () { return '1911'; });
        addValidationConfig(0, getBodyHorizontalCOGMax, config.VALUE_TYPE.BODYHORIZONTALCOG).addValidationBaseTextId(function () { return '1912'; });
        addValidationConfig(0, getBodyVerticalCOGMax, config.VALUE_TYPE.BODYVERTICALCOG).addValidationBaseTextId(function () { return '1913'; });
        addValidationConfig(0, getBodyLateralCOGMax, config.VALUE_TYPE.BODYLATERALCOG).addValidationBaseTextId(function () { return '2075'; });
        addValidationConfig(0, getBodyOrEquipmentHeightMax, config.VALUE_TYPE.BODYHEADBOARDHEIGHT).addValidationBaseTextId(function () { return '1914'; });
        addValidationConfig(0, getBodyOrEquipmentHeightMax, config.VALUE_TYPE.BODYTAILBOARDHEIGHT).addValidationBaseTextId(function () { return '1915'; });
        addValidationConfig(0, getBodyHingePointMax, config.VALUE_TYPE.BODYHINGEPOINT).addValidationBaseTextId(function () { return '1916'; });
        addValidationConfig(0, getBodyOrEquipmentWidthMax, config.VALUE_TYPE.BODYWIDTH).addValidationBaseTextId(function () { return '1917'; });
        addValidationConfig(0, 1000, config.VALUE_TYPE.BODYSIDEWALLLEFT).addValidationBaseTextId(function () { return '1918'; });
        addValidationConfig(0, 1000, config.VALUE_TYPE.BODYSIDEWALLRIGHT).addValidationBaseTextId(function () { return '1919'; });
        addValidationConfig(0, getBodySideDoorFrontToDoorMax, config.VALUE_TYPE.BODYSIDEDOORFRONTTODOOR).addValidationBaseTextId(function () { return '1920'; });
        addValidationConfig(0, getBodySideDoorWidthPerDoorMax, config.VALUE_TYPE.BODYSIDEDOORWIDTHPERDOOR).addValidationBaseTextId(function () { return '1921'; });
        addValidationConfig(0, getBodySideDoorHeightMax, config.VALUE_TYPE.BODYSIDEDOORHEIGHT).addValidationBaseTextId(function () { return '1922'; });
        addValidationConfig(0, getBodyUprightInsideFrontToUprightMax, config.VALUE_TYPE.BODYUPRIGHTINSIDEFRONTTOUPRIGHT).addValidationBaseTextId(function () { return '1923'; });
        addValidationConfig(0, getBodyUprightWidthMax, config.VALUE_TYPE.BODYUPRIGHTWIDTH).addValidationBaseTextId(function () { return '1924'; });
        addValidationConfig(0, getBodyOrEquipmentHeightMax, config.VALUE_TYPE.BODYUPRIGHTHEIGHT).addValidationBaseTextId(function () { return '1925'; });
        addValidationConfig(0, getBottomOfBodyToTopOfChassisMax, config.VALUE_TYPE.BODYBOTTOMOFBODYTOTOPOFCHASSIS).addValidationBaseTextId(function () { return '2102'; });
        addValidationConfig(0, getBodyLeftCompartmentWidthMax, config.VALUE_TYPE.BODYLEFTCOMPARTMENTWIDTH).addValidationBaseTextId(function () { return '2103'; });
        addValidationConfig(0, getBodyRightCompartmentWidthMax, config.VALUE_TYPE.BODYRIGHTCOMPARTMENTWIDTH).addValidationBaseTextId(function () { return '2104'; });

        addValidationConfig(200, 1500, config.VALUE_TYPE.TYRERADIUS).addValidationBaseTextId(function () { return '1926'; });
        addValidationConfig(0, 900, config.VALUE_TYPE.TYREWIDTH).addValidationBaseTextId(function () { return '1927'; });

        addValidationConfig(0, getFuelTankHorizontalCOGMax, config.VALUE_TYPE.FUELTANKHORIZONTALCOG).addValidationBaseTextId(function () { return '1928'; });
        addValidationConfig(0, getConfiguration().getCabHeight, config.VALUE_TYPE.FUELTANKVERTICALCOG).addValidationBaseTextId(function () { return '1929'; });
        addValidationConfig(0, getConfiguration().getRearWidth, config.VALUE_TYPE.FUELTANKLATERALCOG).addValidationBaseTextId(function () { return '2087'; });
        addValidationConfig(0, function () {
            let tempFueltype = getConfiguration().getFuelType();
            if(tempFueltype === config.FUEL_TYPE_OPTIONS.CNG.CODE) {
                return 5000;
            } else if(tempFueltype === config.FUEL_TYPE_OPTIONS.LNG.CODE) {
                return 5000;
            } else if(tempFueltype === config.FUEL_TYPE_OPTIONS.HYDROGEN_FUEL_CELL.CODE) {
                return 10000;
            } if(tempFueltype === config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE) {
                return 100000;
            } else {
                return 10000;
            }
        }, config.VALUE_TYPE.FUELTANKCAPACITY).addValidationBaseTextId(function () { return '1930'; });
        addValidationConfig(0, function (mainAttrPath) {
            var fuelTankId = getObjectNumberOrIdFromPathToValue(mainAttrPath);
            return getConfiguration().getFuelTankById(fuelTankId).getCapacity();
        }, config.VALUE_TYPE.FUELTANKVOLUMEINCLUDEDINTARE).addValidationBaseTextId(function () { return '1931'; });
        addValidationConfig(0, function (mainAttrPath) {
            var fuelTankId = getObjectNumberOrIdFromPathToValue(mainAttrPath);
            return getConfiguration().getFuelTankById(fuelTankId).getCapacity() - getConfiguration().getFuelTankById(fuelTankId).getVolumeIncludedInTare();
            // return getConfiguration().getFuelTankById(fuelTankId).getCapacity();
        }, config.VALUE_TYPE.FUELTANKVOLUMEINCLUDEDINEXTRAS).addValidationBaseTextId(function () { return '1932'; });
        addValidationConfig(undefined, undefined, config.VALUE_TYPE.FUELEXPRESSEDAS);

        addValidationConfig(-9000, getCraneCabGapMax, config.VALUE_TYPE.CRANECABGAP).addValidationBaseTextId(function () { return '1933'; });
        addValidationConfig(-99999, 99999, config.VALUE_TYPE.CRANEFROMAXLE1FRONT).addValidationBaseTextId(function () { return '4426'; });
        addValidationConfig(-10000, getFromLeftOfVehicleRearWidthItemMax, config.VALUE_TYPE.CRANEFROMLEFTOFVEHICLEREARWIDTH).addValidationBaseTextId(function () { return '2098'; });
        addValidationConfig(-10000, 10000, config.VALUE_TYPE.CRANEFROMCENTREOFVEHICLE).addValidationBaseTextId(function () { return '4718'; });
        addValidationConfig(0, 1000, config.VALUE_TYPE.CRANESUBFRAMEHEIGHT).addValidationBaseTextId(function () { return '1934'; });
        addValidationConfig(0, 25000, config.VALUE_TYPE.CRANELENGTH).addValidationBaseTextId(function () { return '1935'; });
        addValidationConfig(getAboveBelowChassisMin, 9999, config.VALUE_TYPE.CRANEABOVEBELOWCHASSIS).addValidationBaseTextId(function () { return '2105'; });
        addValidationConfig(0, getBodyOrEquipmentHeightMax, config.VALUE_TYPE.CRANEHEIGHT).addValidationBaseTextId(function () { return '1936'; });
        addValidationConfig(0, getBodyOrEquipmentWidthMax, config.VALUE_TYPE.CRANEWIDTH).addValidationBaseTextId(function () { return '2089'; });
        addValidationConfig(-50000, 50000, config.VALUE_TYPE.CRANEMASS).addValidationBaseTextId(function () { return '1937'; });
        addValidationConfig(0, getCraneHorizontalCOGMax, config.VALUE_TYPE.CRANEHORIZONTALCOG).addValidationBaseTextId(function () { return '1938'; });
        addValidationConfig(0, getCraneVerticalCOGMax, config.VALUE_TYPE.CRANEVERTICALCOG).addValidationBaseTextId(function () { return '1939'; });
        addValidationConfig(0, getCraneLateralCOGMax, config.VALUE_TYPE.CRANELATERALCOG).addValidationBaseTextId(function () { return '2081'; });



        addValidationConfig(0, getPayloadLengthMax, config.VALUE_TYPE.DETAILEDPAYLOADLENGTH).addValidationBaseTextId(function () { return '2038'; });
        addValidationConfig(0, getBodyOrEquipmentHeightMax, config.VALUE_TYPE.DETAILEDPAYLOADHEIGHT).addValidationBaseTextId(function () { return '2039'; });
        addValidationConfig(0, getBodyOrEquipmentWidthMax, config.VALUE_TYPE.DETAILEDPAYLOADWIDTH).addValidationBaseTextId(function () { return '2093'; });
        addValidationConfig(getPayloadFromHeadboardMin, getPayloadFromHeadboardMax, config.VALUE_TYPE.DETAILEDPAYLOADFROMHEADBOARD).addValidationBaseTextId(function () { return '2040'; });
        addValidationConfig(-10000, getPayloadFromLeftSidewallMax, config.VALUE_TYPE.DETAILEDPAYLOADFROMLEFTSIDEWALL).addValidationBaseTextId(function () { return '2094'; });
        addValidationConfig(getPayloadAboveDeckMin, getPayloadAboveDeckMax, config.VALUE_TYPE.DETAILEDPAYLOADABOVEDECK).addValidationBaseTextId(function () { return '2041'; });
        addValidationConfig(0, 500000, config.VALUE_TYPE.DETAILEDPAYLOADPAYLOAD).addValidationBaseTextId(function () { return '1940'; });
        addValidationConfig(0, 999999, config.VALUE_TYPE.DETAILEDPAYLOADDENSITY).addValidationBaseTextId(function () { return '2126'; });
        addValidationConfig(0, getPayloadHorizontalCOGMax, config.VALUE_TYPE.DETAILEDPAYLOADHORIZONTALCOG).addValidationBaseTextId(function () { return '1941'; });
        addValidationConfig(0, getPayloadVerticalCOGMax, config.VALUE_TYPE.DETAILEDPAYLOADVERTICALCOG).addValidationBaseTextId(function () { return '1942'; });
        addValidationConfig(0, getPayloadLateralCOGMax, config.VALUE_TYPE.DETAILEDPAYLOADLATERALCOG).addValidationBaseTextId(function () { return '2078'; });
        addValidationConfig(0, 500000, config.VALUE_TYPE.SIMPLEPAYLOADMASS).addValidationBaseTextId(function () { return '2035'; });
        addValidationConfig(0, getPayloadHorizontalCOGMax, config.VALUE_TYPE.SIMPLEPAYLOADHORIZONTALCOG).addValidationBaseTextId(function () { return '2036'; });
        addValidationConfig(0, getPayloadVerticalCOGMax, config.VALUE_TYPE.SIMPLEPAYLOADVERTICALCOG).addValidationBaseTextId(function () { return '2037'; });
        addValidationConfig(0, getPayloadLateralCOGMax, config.VALUE_TYPE.SIMPLEPAYLOADLATERALCOG).addValidationBaseTextId(function () { return '2077'; });

        addValidationConfig(0, 9999, config.VALUE_TYPE.SETTINGSLENGTHINCREMENT).addValidationBaseTextId(function () { return '1943'; });
        addValidationConfig(0, 9999, config.VALUE_TYPE.SETTINGSMASSINCREMENT).addValidationBaseTextId(function () { return '1944'; });
        addValidationConfig(0, 100, config.VALUE_TYPE.SETTINGSPERCENTAGEINCREMENT).addValidationBaseTextId(function () { return '1945'; });

        addValidationConfig(getOtherCabGapMin, getOtherCabGapMax, config.VALUE_TYPE.OTHERCABGAP).addValidationBaseTextId(function () { return '1946'; });
        addValidationConfig(-99999, 99999, config.VALUE_TYPE.OTHERFROMAXLE1FRONT).addValidationBaseTextId(function () { return '4423'; });
        addValidationConfig(-10000, getFromLeftOfVehicleRearWidthItemMax, config.VALUE_TYPE.OTHERFROMLEFTOFVEHICLEREARWIDTH).addValidationBaseTextId(function () { return '2097'; });
        addValidationConfig(-10000, 10000, config.VALUE_TYPE.OTHERFROMCENTREOFVEHICLE).addValidationBaseTextId(function () { return '4718'; });
        addValidationConfig(getAboveBelowChassisMin, 9999, config.VALUE_TYPE.OTHERABOVEBELOWCHASSIS).addValidationBaseTextId(function () { return '1947'; });
        addValidationConfig(0, 99999, config.VALUE_TYPE.OTHERLENGTH).addValidationBaseTextId(function () { return '1948'; });
        addValidationConfig(0, getBodyOrEquipmentHeightMax, config.VALUE_TYPE.OTHERHEIGHT).addValidationBaseTextId(function () { return '1949'; });
        addValidationConfig(0, getBodyOrEquipmentWidthMax, config.VALUE_TYPE.OTHERWIDTH).addValidationBaseTextId(function () { return '1950'; }); //#unfinished, the max value here is incorrect
        addValidationConfig(-50000, 50000, config.VALUE_TYPE.OTHERMASS).addValidationBaseTextId(function () { return '1951'; });
        addValidationConfig(0, getOtherHorizontalCOGMax, config.VALUE_TYPE.OTHERHORIZONTALCOG).addValidationBaseTextId(function () { return '1952'; });
        addValidationConfig(0, getOtherVerticalCOGMax, config.VALUE_TYPE.OTHERVERTICALCOG).addValidationBaseTextId(function () { return '1953'; });
        addValidationConfig(0, getOtherLateralCOGMax, config.VALUE_TYPE.OTHERLATERALCOG).addValidationBaseTextId(function () { return '2084'; });

        addValidationConfig(-99999, 99999, config.VALUE_TYPE.HOOKLIFTCABGAP).addValidationBaseTextId(function () { return '4373'; });
        addValidationConfig(-99999, 99999, config.VALUE_TYPE.HOOKLIFTFROMAXLE1FRONT).addValidationBaseTextId(function () { return '4435'; });
        addValidationConfig(-10000, getFromLeftOfVehicleRearWidthItemMax, config.VALUE_TYPE.HOOKLIFTFROMLEFTOFVEHICLEREARWIDTH).addValidationBaseTextId(function () { return '4375'; });
        addValidationConfig(-10000, 10000, config.VALUE_TYPE.HOOKLIFTFROMCENTREOFVEHICLE).addValidationBaseTextId(function () { return '4718'; });
        addValidationConfig(getAboveBelowChassisMin, 9999, config.VALUE_TYPE.HOOKLIFTABOVEBELOWCHASSIS).addValidationBaseTextId(function () { return '4374'; });
        addValidationConfig(0, 99999, config.VALUE_TYPE.HOOKLIFTLENGTH).addValidationBaseTextId(function () { return '4376'; });
        addValidationConfig(0, getHookliftLengthForValidation, config.VALUE_TYPE.HOOKLIFTAFRAME).addValidationBaseTextId(function () { return '4377'; }); //Min = 0 mm, Max = Length
        addValidationConfig(0, getHookliftLengthForValidation, config.VALUE_TYPE.HOOKLIFTHOOKOFFSET).addValidationBaseTextId(function () { return '4378'; }); //Min = 0 mm, Max = Length
        addValidationConfig(0, getHookliftLengthForValidation, config.VALUE_TYPE.HOOKLIFTCENTREOFROLLER).addValidationBaseTextId(function () { return '4379'; }); //Min = 0 mm, Max = Length
        addValidationConfig(0, 99999, config.VALUE_TYPE.HOOKLIFTHEIGHT).addValidationBaseTextId(function () { return '4380'; }); //(Min = 0 mm), (Max = 99999 mm)
        addValidationConfig(0, getHookliftHeightForValidation, config.VALUE_TYPE.HOOKLIFTPLATFORMHEIGHT).addValidationBaseTextId(function () { return '4381'; }); //(Min = 0 mm), (Max = Height)
        addValidationConfig(0, 99999, config.VALUE_TYPE.HOOKLIFTWIDTH).addValidationBaseTextId(function () { return '4382'; }); //(Min = 0 mm), (Max = 99999 mm)
        addValidationConfig(-99999, 99999, config.VALUE_TYPE.HOOKLIFTMASS).addValidationBaseTextId(function () { return '4383'; }); //(Min = - 99999 kg), (Max = 99999kg)
        addValidationConfig(0, getHookliftHorizontalCOGMax, config.VALUE_TYPE.HOOKLIFTHORIZONTALCOG).addValidationBaseTextId(function () { return '4384'; });
        addValidationConfig(0, getHookliftVerticalCOGMax, config.VALUE_TYPE.HOOKLIFTVERTICALCOG).addValidationBaseTextId(function () { return '4385'; });
        addValidationConfig(0, getHookliftLateralCOGMax, config.VALUE_TYPE.HOOKLIFTLATERALCOG).addValidationBaseTextId(function () { return '4386'; });
        
        
        

        addValidationConfig(-1000, getMountingPositionMaxVal, config.VALUE_TYPE.FRIDGEMOUNTINGPOSITION).addValidationBaseTextId(function () { return '1954'; });
        addValidationConfig(getFridgeFromFrontOfBodyMinVal, getFridgeFromFrontOfBodyMaxVal, config.VALUE_TYPE.FRIDGEFROMFRONTOFBODY).addValidationBaseTextId(function () { return '4620'; });
        addValidationConfig(-10000, getFromLeftOfBodyWidthExternalItemMax, config.VALUE_TYPE.FRIDGEFROMLEFTOFBODYWIDTHEXTERNAL).addValidationBaseTextId(function () { return '2096'; });
        addValidationConfig(-10000, 10000, config.VALUE_TYPE.FRIDGEFROMCENTREOFVEHICLE).addValidationBaseTextId(function () { return '4718'; });
        addValidationConfig(0, 1500, config.VALUE_TYPE.FRIDGEPROTRUSION).addValidationBaseTextId(function () { return '1955'; });
        addValidationConfig(0, getFridgeHeightMax, config.VALUE_TYPE.FRIDGEHEIGHT).addValidationBaseTextId(function () { return '1956'; });
        addValidationConfig(0, getBodyOrEquipmentWidthMax, config.VALUE_TYPE.FRIDGEWIDTH).addValidationBaseTextId(function () { return '2090'; });
        addValidationConfig(-50000, 50000, config.VALUE_TYPE.FRIDGEMASS).addValidationBaseTextId(function () { return '1957'; });
        addValidationConfig(0, getFridgeHorizontalCOGMax, config.VALUE_TYPE.FRIDGEHORIZONTALCOG).addValidationBaseTextId(function () { return '1695'; });
        addValidationConfig(0, getFridgeVerticalCOGMax, config.VALUE_TYPE.FRIDGEVERTICALCOG).addValidationBaseTextId(function () { return '1696'; });
        addValidationConfig(0, getFridgeLateralCOGMax, config.VALUE_TYPE.FRIDGELATERALCOG).addValidationBaseTextId(function () { return '1823'; });

        addValidationConfig(-2000, 2000, config.VALUE_TYPE.TAILLIFTFROMBACKOFBODY).addValidationBaseTextId(function () { return '1960'; });
        addValidationConfig(-10000, getFromLeftOfBodyWidthExternalItemMax, config.VALUE_TYPE.TAILLIFTFROMLEFTOFBODYWIDTHEXTERNAL).addValidationBaseTextId(function () { return '2095'; });
        addValidationConfig(-10000, 10000, config.VALUE_TYPE.TAILLIFTFROMCENTREOFVEHICLE).addValidationBaseTextId(function () { return '4718'; });
        addValidationConfig(0, 4000, config.VALUE_TYPE.TAILLIFTMECHANISMLENGTH).addValidationBaseTextId(function () { return '1961'; });
        addValidationConfig(0, 1000, config.VALUE_TYPE.TAILLIFTPLATFORMPROTRUSION).addValidationBaseTextId(function () { return '1962'; });
        addValidationConfig(0, getBodyOrEquipmentHeightMax, config.VALUE_TYPE.TAILLIFTPLATFORMHEIGHT).addValidationBaseTextId(function () { return '1963'; });
        addValidationConfig(-1500, 1500, config.VALUE_TYPE.TAILLIFTABOVEORBELOWFLOOR).addValidationBaseTextId(function () { return '2071'; });
        addValidationConfig(0, 1500, config.VALUE_TYPE.TAILLIFTMECHANISMHEIGHT).addValidationBaseTextId(function () { return '2072'; });
        addValidationConfig(0, getBodyOrEquipmentWidthMax, config.VALUE_TYPE.TAILLIFTWIDTH).addValidationBaseTextId(function () { return '2092'; });
        addValidationConfig(-50000, 50000, config.VALUE_TYPE.TAILLIFTMASS).addValidationBaseTextId(function () { return '1964'; });
        addValidationConfig(0, getTailliftHorizontalCOGMax, config.VALUE_TYPE.TAILLIFTHORIZONTALCOG).addValidationBaseTextId(function () { return '1965'; });
        addValidationConfig(0, getTailliftVerticalCOGMax, config.VALUE_TYPE.TAILLIFTVERTICALCOG).addValidationBaseTextId(function () { return '1966'; });
        addValidationConfig(0, getTailliftLateralCOGMax, config.VALUE_TYPE.TAILLIFTLATERALCOG).addValidationBaseTextId(function () { return '2083'; });

        addValidationConfig(undefined, getTotalWidthMax, config.VALUE_TYPE.COMBINATIONOVERALLWIDTH).addValidationBaseTextId(function () { return '1967'; }); 

        addValidationConfig(0, getRampLengthMaxVal, config.VALUE_TYPE.RAMPLENGTH).addValidationBaseTextId(function () { return '1968'; });
        addValidationConfig(0, getRampAngleMaxVal, config.VALUE_TYPE.RAMPANGLE).addValidationBaseTextId(function () { return '1969'; });
        addValidationConfig(0, 1000, config.VALUE_TYPE.RAMPPLATESPROTRUSION).addValidationBaseTextId(function () { return '1970'; });
        addValidationConfig(0, getBodyOrEquipmentHeightMax, config.VALUE_TYPE.RAMPPLATESHEIGHT).addValidationBaseTextId(function () { return '1971'; });
        addValidationConfig(-10000, 10000, config.VALUE_TYPE.RAMPPLATESMASS).addValidationBaseTextId(function () { return '1972'; });
        addValidationConfig(0, getRampPlatesHorizontalCOGMax, config.VALUE_TYPE.RAMPPLATESHORIZONTALCOG).addValidationBaseTextId(function () { return '1974'; });
        addValidationConfig(0, getRampPlateVerticalCOGMax, config.VALUE_TYPE.RAMPPLATESVERTICALCOG).addValidationBaseTextId(function () { return '1975'; });
        addValidationConfig(0, getBodyLateralCOGMax, config.VALUE_TYPE.RAMPPLATESLATERALCOG).addValidationBaseTextId(function () { return '2088'; });

        addValidationConfig(0, function () {
            return /*evaluateLegislation('COMVEHBODYLENGTHMAX')*/getLowestMaxFromActiveLegislations(legislationVals.variableTypes.ComVehBodyLengthMax);
        }, config.VALUE_TYPE.BODYSUBFRAMELENGTH).addValidationBaseTextId(function () { return '1976'; });
        addValidationConfig(0, 90000, config.VALUE_TYPE.AXLEMANUFACTURERRATING).addValidationBaseTextId(function () { return '2068'; });
        addValidationConfig(0, 50000, config.VALUE_TYPE.AXLEWEIGHT).addValidationBaseTextId(function () { return '2065'; });
        addValidationConfig(0, 1000, config.VALUE_TYPE.AXLELIFTINGMAXTRAVEL).addValidationBaseTextId(function () { return '2069'; });
        addValidationConfig(0, 90000, config.VALUE_TYPE.SUSPENSIONMASSRATING).addValidationBaseTextId(function () { return '2066'; });
        addValidationConfig(0, 90000, config.VALUE_TYPE.TYREMASSRATING).addValidationBaseTextId(function () { return '2067'; });

        // TC TODO 2020.1
        // Make sure values and messages are correct
        addValidationConfig(0, 999999, config.VALUE_TYPE.AXLEBRIDGEFORMULAPERMISSIBLE).addValidationBaseTextId(function () { return '2476'; });
        addValidationConfig(0, getAxleDesiredWeightMax, config.VALUE_TYPE.AXLEDESIREDWEIGHT).addValidationBaseTextId(function () { return '2477'; });
        addValidationConfig(0, function (mainAttrPath) {
            let axleId = getObjectNumberOrIdFromPathToValue(mainAttrPath); 
            let axleToExclude = getConfiguration().getAxlesHolder().getAxleById(axleId);
            return 100 - getConfiguration().getAxlesHolder().getSumOfOtherPusherAndTagAxlesPercentageOfWeightCarried(axleToExclude); 
        }, config.VALUE_TYPE.AXLEPERCENTAGEOFWEIGHTCARRIED).addValidationBaseTextId(function () { return '4639'; });
        addValidationConfig(0, 999999, config.VALUE_TYPE.GROSSAXLEWEIGHTRATING).addValidationBaseTextId(function () { return '2479'; });

        addValidationConfig(getFifthWheelOffsetMin, getFifthWheelOffsetMax, config.VALUE_TYPE.FIFTHWHEELOFFSET).addValidationBaseTextId(function () { return '2107'; });
        //addValidationConfig(-10000, getFromLeftOfVehicleRearWidthItemMax, config.VALUE_TYPE.FIFTHWHEELFROMLEFTOFVEHICLEREARWIDTH);
        addValidationConfig(0, 1000, config.VALUE_TYPE.FIFTHWHEELFRONTOVERHANG).addValidationBaseTextId(function () { return '2405'; });
        addValidationConfig(0, 2000, config.VALUE_TYPE.FIFTHWHEELLENGTH).addValidationBaseTextId(function () { return '1977'; });
        addValidationConfig(0, 500, config.VALUE_TYPE.FIFTHWHEELSUBFRAMEHEIGHT).addValidationBaseTextId(function () { return '1978'; });
        addValidationConfig(getFifthWheelHeightMin, getFifthWheelHeightMax, config.VALUE_TYPE.FIFTHWHEELHEIGHT).addValidationBaseTextId(function () { return '1979'; });
        addValidationConfig(0, 2000, config.VALUE_TYPE.FIFTHWHEELWIDTH).addValidationBaseTextId(function () { return '1980'; });
        addValidationConfig(-2000, 2000, config.VALUE_TYPE.FIFTHWHEELMASS).addValidationBaseTextId(function () { return '1981'; });
        addValidationConfig(0, function () {
            return getConfiguration().getAccessoryHolder().getFifthWheel().getLength();
        }, config.VALUE_TYPE.FIFTHWHEELHORIZONTALCOG).addValidationBaseTextId(function () { return '1982'; });
        addValidationConfig(0, function () {
            return getConfiguration().getAccessoryHolder().getFifthWheel().getHeight() + getConfiguration().getAccessoryHolder().getFifthWheel().getSubframeHeight();
        }, config.VALUE_TYPE.FIFTHWHEELVERTICALCOG).addValidationBaseTextId(function () { return '1983'; });
        addValidationConfig(0, function () {
            return getConfiguration().getAccessoryHolder().getFifthWheel().getWidth();
        }, config.VALUE_TYPE.FIFTHWHEELLATERALCOG).addValidationBaseTextId(function () { return '2079'; });


        addValidationConfig(getDrawbarLengthMin, undefined, config.VALUE_TYPE.TRAILERDRAWBARPROTRUSION).addValidationBaseTextId(function () { return '2052'; });
        addValidationConfig(undefined, getTrailerDrawbarLengthMax, config.VALUE_TYPE.TRAILERDRAWBARLENGTH).addValidationBaseTextId(function () { return '2063'; });
        addValidationConfig(getTrailerLengthMin, getTrailerLengthMax, config.VALUE_TYPE.TRAILERLENGTH).addValidationBaseTextId(function () { return '1984'; });
        addValidationConfig(getTrailerFrontOverhangMin, getTrailerFrontOverhangMax, config.VALUE_TYPE.TRAILERFRONTOVERHANG).addValidationBaseTextId(function () { return '1985'; });
        addValidationConfig(getTrailerFrontToStartOfTaperMin, getTrailerFrontToStartOfTaperMax, config.VALUE_TYPE.TRAILERFRONTTOSTARTOFTAPER).addValidationBaseTextId(function () { return '2106'; });
        addValidationConfig(getTrailerFrontAxleSpacingMin, 4000, config.VALUE_TYPE.TRAILERAXLE1FRONTTOAXLE2FRONT).addValidationBaseTextId(function () { return '2053'; });
        addValidationConfig(getTrailerFrontAxleSpacingMin, 4000, config.VALUE_TYPE.TRAILERAXLE2FRONTTOAXLE3FRONT).addValidationBaseTextId(function () { return '2054'; });
        addValidationConfig(0, 2000, config.VALUE_TYPE.TRAILERAXLE1FRONTTOWHEELBASETHEORETICALSTART).addValidationBaseTextId(function () { return '2055'; });
        addValidationConfig(getTrailerWheelbaseTheoreticalMin, getTrailerWheelbaseTheoreticalMax, config.VALUE_TYPE.TRAILERWHEELBASETHEORETICAL).addValidationBaseTextId(function () { return '1986'; });
        addValidationConfig(2000, 20000, config.VALUE_TYPE.TRAILERKINGPINTOAXLE1REAR).addValidationBaseTextId(function () { return '1987'; });
        addValidationConfig(getTrailerRearAxlesSpacingMinVal, 4000, config.VALUE_TYPE.TRAILERAXLE1REARTOAXLE2REAR).addValidationBaseTextId(function () { return '1988'; });
        addValidationConfig(getTrailerRearAxlesSpacingMinVal, 4000, config.VALUE_TYPE.TRAILERAXLE2REARTOAXLE3REAR).addValidationBaseTextId(function () { return '1989'; });
        addValidationConfig(getTrailerRearAxlesSpacingMinVal, 4000, config.VALUE_TYPE.TRAILERAXLE3REARTOAXLE4REAR).addValidationBaseTextId(function () { return '1990'; });
        addValidationConfig(function () {
            return /*evaluateLegislation('TRAILER1AXLELASTTOCHASSISENDMIN')*/getHighestMinFromActiveLegislations(legislationVals.variableTypes.Trailer1AxleLastToChassisEndMin);
        }, undefined, config.VALUE_TYPE.TRAILERAXLELASTTOCHASSISEND).addValidationBaseTextId(function () { return '2001'; });
        //addValidationConfig(function () { return evaluateLegislation('TRAILER1AXLELASTTOCHASSISENDMIN'); }, undefined, config.VALUE_TYPE.TRAILERREAROVERHANGMIN);
        addValidationConfig(getTrailerRearMostFrontAxleToAxle1RearMin, 20000, config.VALUE_TYPE.TRAILERREARMOSTAXLEFRONTTOAXLE1REAR).addValidationBaseTextId(function () { return '2060'; });
        addValidationConfig(0, function (mainAttrPath) {
            if (mainAttrPath.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
                return getActiveOffer().getTrailer1().getKingpinToAxle1Rear();
            } else {
                return getActiveOffer().getTrailer2().getKingpinToAxle1Rear();
            }
        }, config.VALUE_TYPE.TRAILERKINGPINTOLANDINGLEGS).addValidationBaseTextId(function () { return '1991'; });
        addValidationConfig(getTrailerFifthWheelOffsetMin, getTrailerFifthWheelOffsetMax, config.VALUE_TYPE.TRAILERFIFTHWHEELOFFSET).addValidationBaseTextId(function () { return '2758'; });
        addValidationConfig(getHitchPointToAxle1RearMin, 20000, config.VALUE_TYPE.TRAILERHITCHPOINTTOAXLE1REAR).addValidationBaseTextId(function () { return '2070'; });
        addValidationConfig(function () {
            return /*evaluateLegislation('TRAILER1OVERALLHEIGHTMIN')*/getHighestMinFromActiveLegislations(legislationVals.variableTypes.Trailer1OverallHeightMin);
        }, function () {
                return /*evaluateLegislation(legislationVals.variableTypes.OverallTrailer1HeightMax)*/getLowestMaxFromActiveLegislations(legislationVals.variableTypes.OverallTrailer1HeightMax);
            }, config.VALUE_TYPE.TRAILEROVERALLHEIGHT).addValidationBaseTextId(function () { return '1992'; });
        addValidationConfig(0, function () {
            return /*evaluateLegislation(legislationVals.variableTypes.OverallTrailer1HeightMax)*/getLowestMaxFromActiveLegislations(legislationVals.variableTypes.OverallTrailer1HeightMax);
        }, config.VALUE_TYPE.TRAILERKINGPINHEIGHT).addValidationBaseTextId(function () { return '1993'; });
        addValidationConfig(0, function () {
            return /*evaluateLegislation(legislationVals.variableTypes.OverallTrailer1HeightMax)*/getLowestMaxFromActiveLegislations(legislationVals.variableTypes.OverallTrailer1HeightMax);
        }, config.VALUE_TYPE.TRAILERCHASSISHEIGHT).addValidationBaseTextId(function () { return '1994'; });
        addValidationConfig(0, function () {
            return /*evaluateLegislation(legislationVals.variableTypes.OverallTrailer1HeightMax)*/getLowestMaxFromActiveLegislations(legislationVals.variableTypes.OverallTrailer1HeightMax);
        }, config.VALUE_TYPE.TRAILERFIFTHWHEELHEIGHT).addValidationBaseTextId(function () { return '2108'; });
        addValidationConfig(getConfiguration().getGroundClearanceRear, getTrailerDrawbarHeightMax, config.VALUE_TYPE.TRAILERDRAWBARHEIGHT).addValidationBaseTextId(function () { return '2056'; });
        addValidationConfig(0, getTrailerTurntableHeightMax, config.VALUE_TYPE.TRAILERTURNTABLEHEIGHT).addValidationBaseTextId(function () { return '2057'; });
        addValidationConfig(0, getTrailerOverallWidthMax, config.VALUE_TYPE.TRAILEROVERALLWIDTH).addValidationBaseTextId(function () { return '2059'; });
        addValidationConfig(0, 1500, config.VALUE_TYPE.TRAILERTYRERADIUS).addValidationBaseTextId(function () { return '1995'; });
        addValidationConfig(0, 900, config.VALUE_TYPE.TRAILERTYREWIDTH).addValidationBaseTextId(function () { return '1996'; });
        addValidationConfig(-10000, 20000, config.VALUE_TYPE.TRAILERTAREFRONT).addValidationBaseTextId(function () { return '1997'; });
        addValidationConfig(0, 30000, config.VALUE_TYPE.TRAILERTAREREAR).addValidationBaseTextId(function () { return '1998'; });
        addValidationConfig(0, 75000, config.VALUE_TYPE.TRAILERMANUFACTURERSFRONTAXLESMASS).addValidationBaseTextId(function () { return '2061'; });
        addValidationConfig(0, 75000, config.VALUE_TYPE.TRAILERMANUFACTURERSREARAXLESMASS).addValidationBaseTextId(function () { return '1999'; });
        addValidationConfig(0, 75000, config.VALUE_TYPE.TRAILERPERMISSIBLEFRONT).addValidationBaseTextId(function () { return '2058'; });
        addValidationConfig(0, 75000, config.VALUE_TYPE.TRAILERPERMISSIBLEREAR).addValidationBaseTextId(function () { return '2000'; });
        // addValidationConfig(undefined, getKingpinToRearOfTrailerMax, config.VALUE_TYPE.KINGPINTOREAROFTRAILER).addValidationBaseTextId(function () { return '2005'; });
        addValidationConfig(undefined, getKingpinToRearOfTrailerMax, config.VALUE_TYPE.KINGPINTOREAROFTRAILER).addValidationBaseTextObject(function () { 
            let returnObj = {};
            returnObj[config.VALIDATION_MESSAGE_TYPE.BETWEEN] = '2005';
            returnObj[config.VALIDATION_MESSAGE_TYPE.LESSTHAN] = '5256';
            returnObj[config.VALIDATION_MESSAGE_TYPE.GREATERTHAN] = '5257';
            return returnObj;
        });
        addValidationConfig(0, 999999, config.VALUE_TYPE.TRAILERGROSSVEHICLEMASS).addValidationBaseTextId(function () { return '4844'; });
        addValidationConfig(0, getTrailerHorizontalCOGMax, config.VALUE_TYPE.TRAILERHORIZONTALCOG).addValidationBaseTextId(function () { return '2006'; });
        addValidationConfig(0, getTrailerVerticalCOGMax, config.VALUE_TYPE.TRAILERVERTICALCOG).addValidationBaseTextId(function () { return '2007'; });
        addValidationConfig(0, getTrailerLateralCOGMax, config.VALUE_TYPE.TRAILERLATERALCOG).addValidationBaseTextId(function () { return '2076'; });
        // addValidationConfig(undefined, getTurntableToRearOfTrailerMax, config.VALUE_TYPE.TURNTABLETOREAROFTRAILER).addValidationBaseTextId(function () { return '2062'; });
        addValidationConfig(undefined, getTurntableToRearOfTrailerMax, config.VALUE_TYPE.TURNTABLETOREAROFTRAILER).addValidationBaseTextObject(function () { 
            let returnObj = {};
            returnObj[config.VALIDATION_MESSAGE_TYPE.BETWEEN] = '2062';
            returnObj[config.VALIDATION_MESSAGE_TYPE.LESSTHAN] = '5258';
            returnObj[config.VALIDATION_MESSAGE_TYPE.GREATERTHAN] = '5259';
            return returnObj;
        });
        addValidationConfig(undefined, getGapBetweenVehicleAndTrailerMax, config.VALUE_TYPE.GAPBETWEENVEHICLEANDTRAILER).addValidationBaseTextId(function () { return '2064'; });

        addValidationConfig(0, 75, config.VALUE_TYPE.STEERINGANGLEINNER).addValidationBaseTextId(function () { return '2032'; });
        addValidationConfig(0, 99999, config.VALUE_TYPE.TURNINGRADIUSCURBTOCURB).addValidationBaseTextId(function () { return '2033'; });
        addValidationConfig(0, 99999, config.VALUE_TYPE.TURNINGRADIUSWALLTOWALL).addValidationBaseTextId(function () { return '2034'; });

        addValidationConfig(-500, getHitchOffsetMax, config.VALUE_TYPE.HITCHOFFSET).addValidationBaseTextId(function () { return '2042'; });
        //addValidationConfig(-10000, getFromLeftOfVehicleRearWidthItemMax, config.VALUE_TYPE.HITCHFROMLEFTOFVEHICLEREARWIDTH);
        addValidationConfig(0, 1000, config.VALUE_TYPE.HITCHMOUNTINGLENGTH).addValidationBaseTextId(function () { return '2043'; });
        addValidationConfig(0, 1000, config.VALUE_TYPE.HITCHCOUPLINGOFFSET).addValidationBaseTextId(function () { return '2044'; });
        addValidationConfig(0, 1000, config.VALUE_TYPE.HITCHFUNNELLENGTH).addValidationBaseTextId(function () { return '2045'; });
        addValidationConfig(0, getHitchCouplingHeightMax, config.VALUE_TYPE.HITCHCOUPLINGHEIGHT).addValidationBaseTextId(function () { return '2046'; });
        addValidationConfig(0, 1000, config.VALUE_TYPE.HITCHOVERALLHEIGHT).addValidationBaseTextId(function () { return '2047'; });
        addValidationConfig(0, getBodyOrEquipmentWidthMax, config.VALUE_TYPE.HITCHWIDTH).addValidationBaseTextId(function () { return '2091'; });
        addValidationConfig(getActiveOffer().getRig().getVehicle().getGroundClearanceRear(), getActiveOffer().getRig().getVehicle().getChassisHeightRear, config.VALUE_TYPE.HITCHHEIGHTFROMGROUND).addValidationBaseTextId(function () { return '2048'; });
        addValidationConfig(-50000, 50000, config.VALUE_TYPE.HITCHMASS).addValidationBaseTextId(function () { return '2049'; });
        addValidationConfig(0, getHitchHorizontalCOGMax, config.VALUE_TYPE.HITCHHORIZONTALCOG).addValidationBaseTextId(function () { return '2050'; });
        addValidationConfig(0, getHitchVerticalCOGMax, config.VALUE_TYPE.HITCHVERTICALCOG).addValidationBaseTextId(function () { return '2051'; });
        addValidationConfig(0, getHitchLateralCOGMax, config.VALUE_TYPE.HITCHLATERALCOG).addValidationBaseTextId(function () { return '2080'; });
    }
    if (isCostingAvailableForOffer() === true) {
        //addValidationConfig(getActiveOffer().getCosting().getMonthlyDistanceMin, getActiveOffer().getCosting().getMonthlyDistanceMax, config.VALUE_TYPE.COSTINGUTILISATION).addValidationBaseTextId(function () { return '2008'; });
        addValidationConfig(0, 200000, config.VALUE_TYPE.COSTINGUTILISATION).addValidationBaseTextId(function () { return '2008'; });
        //addValidationConfig(getActiveOffer().getCosting().getRepaymentTermMin, getActiveOffer().getCosting().getRepaymentTermMax, config.VALUE_TYPE.COSTINGFINANCEPERIOD).addValidationBaseTextId(function () { return '2009'; });
        addValidationConfig(0, 600, config.VALUE_TYPE.COSTINGFINANCEPERIOD).addValidationBaseTextId(function () { return '2009'; });
        //addValidationConfig(getActiveOffer().getCosting().getInterestRateMin, getActiveOffer().getCosting().getInterestRateMax, config.VALUE_TYPE.COSTINGINTERESTRATE).addValidationBaseTextId(function () { return '2010'; });
        addValidationConfig(0, 90, config.VALUE_TYPE.COSTINGINTERESTRATE).addValidationBaseTextId(function () { return '2010'; });
        //addValidationConfig(getActiveOffer().getCosting().getResidualRateMin, getActiveOffer().getCosting().getResidualRateMax, config.VALUE_TYPE.COSTINGRESIDUALRATE).addValidationBaseTextId(function () { return '2011'; });
        addValidationConfig(0, 90, config.VALUE_TYPE.COSTINGRESIDUALRATE).addValidationBaseTextId(function () { return '2011'; });
        //addValidationConfig(getActiveOffer().getCosting().getFuelCostMin, getActiveOffer().getCosting().getFuelCostMax, config.VALUE_TYPE.COSTINGFUELPRICE).addValidationBaseTextId(function () { return '2012'; });
        addValidationConfig(0, 200, config.VALUE_TYPE.COSTINGFUELPRICE).addValidationBaseTextId(function () { return '2012'; });
        //addValidationConfig(getActiveOffer().getCosting().getVehiclePriceMin, getActiveOffer().getCosting().getVehiclePriceMax, config.VALUE_TYPE.COSTINGFINANCETOTALPRICE).addValidationBaseTextId(function () { return '2013'; });
        addValidationConfig(0, 900000000, config.VALUE_TYPE.COSTINGFINANCETOTALPRICE).addValidationBaseTextId(function () { return '2013'; });
        //addValidationConfig(getActiveOffer().getCosting().getDriverRemunerationMin, getActiveOffer().getCosting().getDriverRemunerationMax, config.VALUE_TYPE.COSTINGCREWCOST).addValidationBaseTextId(function () { return '2014'; });
        addValidationConfig(0, 1000000, config.VALUE_TYPE.COSTINGCREWCOST).addValidationBaseTextId(function () { return '2014'; });
        //addValidationConfig(getActiveOffer().getCosting().getInsuranceRateMin, getActiveOffer().getCosting().getInsuranceRateMax, config.VALUE_TYPE.COSTINGINSURANCERATE).addValidationBaseTextId(function () { return '2015'; });
        addValidationConfig(0, 200, config.VALUE_TYPE.COSTINGINSURANCERATE).addValidationBaseTextId(function () { return '2015'; });
        //addValidationConfig(getActiveOffer().getCosting().getLicenceFeesMin, getActiveOffer().getCosting().getLicenceFeesMax, config.VALUE_TYPE.COSTINGLICENCEFEES).addValidationBaseTextId(function () { return '2016'; });
        addValidationConfig(0, 5000000, config.VALUE_TYPE.COSTINGLICENCEFEES).addValidationBaseTextId(function () { return '2016'; });
        //addValidationConfig(getActiveOffer().getCosting().getOverheadRateMin, getActiveOffer().getCosting().getOverheadRateMax, config.VALUE_TYPE.COSTINGOVERHEADSRATE).addValidationBaseTextId(function () { return '2017'; });
        addValidationConfig(0, 90, config.VALUE_TYPE.COSTINGOVERHEADSRATE).addValidationBaseTextId(function () { return '2017'; });
        //addValidationConfig(getActiveOffer().getCosting().getFuelConsumptionMin, getActiveOffer().getCosting().getFuelConsumptionMax, config.VALUE_TYPE.COSTINGFUELCONSUMPTION).addValidationBaseTextId(function () { return '2018'; });
        addValidationConfig(0, 1000, config.VALUE_TYPE.COSTINGFUELCONSUMPTION).addValidationBaseTextId(function () { return '2018'; });
        //addValidationConfig(getActiveOffer().getCosting().getFuelConsumptionMin, getActiveOffer().getCosting().getFuelConsumptionMax, config.VALUE_TYPE.COSTINGFUELCONSUMPTIONIMPERIAL).addValidationBaseTextId(function () { return '2406'; });
        addValidationConfig(0, 1000, config.VALUE_TYPE.COSTINGFUELCONSUMPTIONIMPERIAL).addValidationBaseTextId(function () { return '2406'; });
        addValidationConfig(0, 1000000, config.VALUE_TYPE.COSTINGFUELANDADBLUECOSTPERMONTH).addValidationBaseTextId(function () { return '2406'; });
        //addValidationConfig(getActiveOffer().getCosting().getTyreRateMin, getActiveOffer().getCosting().getTyreRateMax, config.VALUE_TYPE.COSTINGTYRES).addValidationBaseTextId(function () { return '2019'; });
        addValidationConfig(globals.costingTyreRateMin, globals.costingTyreRateMax, config.VALUE_TYPE.COSTINGTYRES).addValidationBaseTextId(function () { return '2019'; });
        //addValidationConfig(getActiveOffer().getCosting().getMaintenanceRateMin, getActiveOffer().getCosting().getMaintenanceRateMax, config.VALUE_TYPE.COSTINGMAINTENANCE).addValidationBaseTextId(function () { return '2020'; });
        addValidationConfig(0, 5000, config.VALUE_TYPE.COSTINGMAINTENANCE).addValidationBaseTextId(function () { return '2020'; });
        //addValidationConfig(getActiveOffer().getCosting().getTollFeesMin, getActiveOffer().getCosting().getTollFeesMax, config.VALUE_TYPE.COSTINGTOLLFEES).addValidationBaseTextId(function () { return '2021'; });
        addValidationConfig(0, 1000000, config.VALUE_TYPE.COSTINGTOLLFEES).addValidationBaseTextId(function () { return '2021'; });
        //addValidationConfig(getActiveOffer().getCosting().getTotalRevenueMin, getActiveOffer().getCosting().getTotalRevenueMax, config.VALUE_TYPE.COSTINGTOTALREVENUE).addValidationBaseTextId(function () { return '2022'; });
        addValidationConfig(0, 5000, config.VALUE_TYPE.COSTINGTOTALREVENUE).addValidationBaseTextId(function () { return '2022'; });
        //addValidationConfig(getActiveOffer().getCosting().getTyrePriceMin(), getActiveOffer().getCosting().getTyrePriceMax(), config.VALUE_TYPE.AVERAGECOSTOFTYRE);
        addValidationConfig(globals.costingAverageCostOfTyreMin, globals.costingAverageCostOfTyreMax, config.VALUE_TYPE.AVERAGECOSTOFTYRE);
        //addValidationConfig(getActiveOffer().getCosting().getAverageTyreLifeMin(), getActiveOffer().getCosting().getAverageTyreLifeMax(), config.VALUE_TYPE.AVERAGELIFEOFTYRE);
        addValidationConfig(globals.costingAverageLifeOfTyreMin, globals.costingAverageLifeOfTyreMax, config.VALUE_TYPE.AVERAGELIFEOFTYRE);

        //addValidationConfig(getActiveOffer().getCosting().getVehiclePriceMin, getActiveOffer().getCosting().getVehiclePriceMax, config.VALUE_TYPE.COSTINGFINANCEDETAILLISTPRICE);
        addValidationConfig(0, 100000000, config.VALUE_TYPE.COSTINGFINANCEDETAILLISTPRICE).addValidationBaseTextId(function () { return '4121'; });
        addValidationConfig(0, 90, config.VALUE_TYPE.COSTINGFINANCEDETAILDISCOUNT).addValidationBaseTextId(function () { return '4122'; });
        //addValidationConfig(getActiveOffer().getCosting().getRepaymentTermMin, getActiveOffer().getCosting().getRepaymentTermMax, config.VALUE_TYPE.COSTINGFINANCEDETAILPERIOD);
        addValidationConfig(0, 600, config.VALUE_TYPE.COSTINGFINANCEDETAILPERIOD).addValidationBaseTextId(function () { return '4123'; });
        //addValidationConfig(getActiveOffer().getCosting().getInterestRateMin, getActiveOffer().getCosting().getInterestRateMax, config.VALUE_TYPE.COSTINGFINANCEDETAILINTERESTRATE);
        addValidationConfig(0, 90, config.VALUE_TYPE.COSTINGFINANCEDETAILINTERESTRATE).addValidationBaseTextId(function () { return '4124'; });
        //addValidationConfig(getActiveOffer().getCosting().getResidualRateMin, getActiveOffer().getCosting().getResidualRateMax, config.VALUE_TYPE.COSTINGFINANCEDETAILRESIDUAL);
        addValidationConfig(0, 90, config.VALUE_TYPE.COSTINGFINANCEDETAILRESIDUAL).addValidationBaseTextId(function () { return '4125'; });

        //addValidationConfig(getActiveOffer().getCosting().getMonthlyTripsMin, getActiveOffer().getCosting().getMonthlyTripsMax, config.VALUE_TYPE.COSTINGTRIPS).addValidationBaseTextId(function () { return '4114'; });
        addValidationConfig(0, 1000, config.VALUE_TYPE.COSTINGTRIPS).addValidationBaseTextId(function () { return '4114'; });
        addValidationConfig(0, 500000, config.VALUE_TYPE.COSTINGTOLLFEESPERTRIP).addValidationBaseTextId(function () { return '4115'; });
        addValidationConfig(0, 500000, config.VALUE_TYPE.COSTINGPERMITCOSTPERTRIP).addValidationBaseTextId(function () { return '4116'; });

        // addValidationConfig(0, 10000000, config.VALUE_TYPE.COSTINGFUELANDADBLUECOSTPERMONTH).addValidationBaseTextId(function () { return '4570'; });
        addValidationConfig(getFuelAndAdblueCostPerMonthMin, getFuelAndAdblueCostPerMonthMax, config.VALUE_TYPE.COSTINGFUELANDADBLUECOSTPERMONTH).addValidationBaseTextId(function () { return '4573'; });
        // addValidationConfig(0, 10000000, config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILCOSTPERMONTH).addValidationBaseTextId(function () { return '4571'; });
        addValidationConfig(getFuelAndAdblueDetailCostPerMonthMin, getFuelAndAdblueDetailCostPerMonthMax, config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILCOSTPERMONTH).addValidationBaseTextId(function () { return '4574'; });
        // addValidationConfig(0, 1000, config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILFUELCONSUMPTION).addValidationBaseTextId(function () { return '4572'; });
        addValidationConfig(getFuelAndAdblueDetailFuelConsumptionMin, getFuelAndAdblueDetailFuelConsumptionMax, config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILFUELCONSUMPTION).addValidationBaseTextId(function () { return '4575'; });
        // addValidationConfig(0, 15, config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILADBLUECONSUMPTION).addValidationBaseTextId(function () { return '4573'; });
        addValidationConfig(getFuelAndAdblueDetailAdblueConsumptionMin, getFuelAndAdblueDetailAdblueConsumptionMax, config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILADBLUECONSUMPTION).addValidationBaseTextId(function () { return '4576'; });
        // addValidationConfig(0, 1000, config.VALUE_TYPE.COSTINGFUELANDADBLUEEQUIPMENTFUELCONSUMPTION).addValidationBaseTextId(function () { return '4574'; });
        addValidationConfig(getFuelAndAdblueDetailEquipmentFuelConsumptionMin, getFuelAndAdblueDetailEquipmentFuelConsumptionMax, config.VALUE_TYPE.COSTINGFUELANDADBLUEEQUIPMENTFUELCONSUMPTION).addValidationBaseTextId(function () { return '4577'; });
        // addValidationConfig(0, 200, config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILCOSTPERVOLUME).addValidationBaseTextId(function () { return '4575'; });
        addValidationConfig(getFuelAndAdblueDetailCostPerVolumeMin, getFuelAndAdblueDetailCostPerVolumeMax, config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILCOSTPERVOLUME).addValidationBaseTextId(function () { return '4578'; });
        // addValidationConfig(0, 200000, config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILUTILISATION).addValidationBaseTextId(function () { return '4576'; });
        addValidationConfig(getFuelAndAdblueDetailUtilisationMin, getFuelAndAdblueDetailUtilisationMax, config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILUTILISATION).addValidationBaseTextId(function () { return '4579'; });
    }

    if (getActiveOffer().getDataAvailability().ActivePerformance && getActiveOffer().getPerformance() !== undefined && globals.user.hasPermission(config.PERMISSIONS.PERFORMANCE.Code)) {
        addValidationConfig(1, 400000, config.VALUE_TYPE.PERFORMANCEVEHICLETOTALUNLADEN).addValidationBaseTextId(function () { return '2023'; });
        addValidationConfig(1, 30, config.VALUE_TYPE.PERFORMANCEFRONTALAREA).addCustomPrecisionFunctionForValidationCompareVals(function (value) {
            return getCustomPrecisionRoundedValue(value, 1);
        }).addValidationBaseTextId(function () { return '2024'; });
        addValidationConfig(0.10, 2, config.VALUE_TYPE.PERFORMANCECOEFFICIENTOFDRAG).addCustomPrecisionFunctionForValidationCompareVals(function (value) {
            return getCustomPrecisionRoundedValue(value, 2);
        }).addValidationBaseTextId(function () { return '2025'; });
        addValidationConfig(1, 30, config.VALUE_TYPE.PERFORMANCEDRIVEAXLERATIO).addCustomPrecisionFunctionForValidationCompareVals(function (value) {
            return getCustomPrecisionRoundedValue(value, 3);
        }).addValidationBaseTextId(function () { return '2026'; });
        addValidationConfig(1, getPerformanceMaximumSpeedMax, config.VALUE_TYPE.PERFORMANCEMAXIMUMSPEED).addValidationBaseTextId(function () { return '2027'; });
        addValidationConfig(1, 400000, config.VALUE_TYPE.PERFORMANCETRAILERTOTALUNLADEN).addValidationBaseTextId(function () { return '2028'; });
        addValidationConfig(1, 400000, config.VALUE_TYPE.PERFORMANCETRAILER2TOTALUNLADEN).addValidationBaseTextId(function () { return '2109'; });
        addValidationConfig(0, 400000, config.VALUE_TYPE.WAYPOINTPAYLOADLOADED).addValidationBaseTextId(function () { return '2030'; });
        addValidationConfig(0, 400000, config.VALUE_TYPE.WAYPOINTPAYLOADOFFLOADED).addValidationBaseTextId(function () { return '2031'; });


    }


    addValidationConfig(1, 30000, config.VALUE_TYPE.ITEMDRAWINGLENGTH).addValidationBaseTextId(function () { return '2110'; });
    addValidationConfig(1, 10000, config.VALUE_TYPE.ITEMDRAWINGHEIGHT).addValidationBaseTextId(function () { return '2111'; });
    addValidationConfig(0, 10000, config.VALUE_TYPE.ITEMDRAWINGWIDTH).addValidationBaseTextId(function () { return '2112'; });
    addValidationConfig(-90000, 90000, config.VALUE_TYPE.ITEMMASS).addValidationBaseTextId(function () { return '2116'; });
    addValidationConfig(0, function () {
        if (activeConfigurationWizardItem.getAccessoryType() === config.ACCESSORY_TYPES.BODY && activeConfigurationWizardItem.getType() === config.BODY_TYPES.HOOKLIFT && activeConfigurationWizardItem.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
            return activeConfigurationWizardItem.getSubframeLength();
        }
        return activeConfigurationWizardItemSize.getLength()
    }, config.VALUE_TYPE.ITEMHORIZONTALCOG).addValidationBaseTextId(function () { return '2113'; });
    addValidationConfig(0, getItemVerticalCOGMax, config.VALUE_TYPE.ITEMVERTICALCOG).addValidationBaseTextId(function () { return '2114'; });
    addValidationConfig(0, function () {
        return activeConfigurationWizardItemSize.getWidth()
    }, config.VALUE_TYPE.ITEMLATERALCOG).addValidationBaseTextId(function () { return '2115'; });
    addValidationConfig(0, function () {
        return activeConfigurationWizardItemSize.getLength()
    }, config.VALUE_TYPE.ITEMPROTRUSION).addValidationBaseTextId(function () { return '2117'; });
    addValidationConfig(0, function () {
        return activeConfigurationWizardItemSize.getLength()
    }, config.VALUE_TYPE.ITEMMECHANISMLENGTH).addValidationBaseTextId(function () { return '2118'; });
    addValidationConfig(0, function () {
        return activeConfigurationWizardItemSize.getHeight()
    }, config.VALUE_TYPE.ITEMMECHANISMHEIGHT).addValidationBaseTextId(function () { return '2119'; });
    addValidationConfig(0, function () {
        return activeConfigurationWizardItemSize.getLength()
    }, config.VALUE_TYPE.ITEMMOUNTINGLENGTH).addValidationBaseTextId(function () { return '2121'; });
    addValidationConfig(0, function () {
        return activeConfigurationWizardItemSize.getLength()
    }, config.VALUE_TYPE.ITEMCOUPLINGOFFSET).addValidationBaseTextId(function () { return '2122'; });
    addValidationConfig(0, function () {
        return activeConfigurationWizardItemSize.getLength()
    }, config.VALUE_TYPE.ITEMFRONTOVERHANG).addValidationBaseTextId(function () { return '2120'; });
    addValidationConfig(0, function () {
        return activeConfigurationWizardItemSize.getHeight()
    }, config.VALUE_TYPE.ITEMCOUPLINGHEIGHT).addValidationBaseTextId(function () { return '2123'; });
    addValidationConfig(0, 500000, config.VALUE_TYPE.ITEMPAYLOAD).addValidationBaseTextId(function () { return '2124'; });
    addValidationConfig(0, 999999, config.VALUE_TYPE.ITEMDENSITY).addValidationBaseTextId(function () { return '2125'; });
    addValidationConfig(0, 25000, config.VALUE_TYPE.ITEMSUBFRAMELENGTH).addValidationBaseTextId(function () { return '2129'; });
    addValidationConfig(0, 1000, config.VALUE_TYPE.ITEMHEADBOARDTHICKNESS).addValidationBaseTextId(function () { return '2130'; });
    addValidationConfig(0, 1000, config.VALUE_TYPE.ITEMTAILBOARDTHICKNESS).addValidationBaseTextId(function () { return '2131'; });
    addValidationConfig(0, 5000, config.VALUE_TYPE.ITEMRAMPLENGTH).addValidationBaseTextId(function () { return '2132'; });
    addValidationConfig(0, 1000, config.VALUE_TYPE.ITEMRAMPPLATESPROTRUSION).addValidationBaseTextId(function () { return '2133'; });
    addValidationConfig(0, 1500, config.VALUE_TYPE.ITEMBOTTOMOFBODYTOTOPOFCHASSIS).addValidationBaseTextId(function () { return '2134'; });
    addValidationConfig(0, 750, config.VALUE_TYPE.ITEMSUBFRAMEHEIGHT).addValidationBaseTextId(function () { return '2135'; });
    addValidationConfig(0, 750, config.VALUE_TYPE.ITEMSUBSTRUCTUREHEIGHT).addValidationBaseTextId(function () { return '2136'; });
    addValidationConfig(0, 1000, config.VALUE_TYPE.ITEMROOFTHICKNESS).addValidationBaseTextId(function () { return '2137'; });
    addValidationConfig(0, 10000, config.VALUE_TYPE.ITEMHEADBOARDHEIGHT).addValidationBaseTextId(function () { return '2138'; });
    addValidationConfig(0, 10000, config.VALUE_TYPE.ITEMTAILBOARDHEIGHT).addValidationBaseTextId(function () { return '2139'; });
    addValidationConfig(0, function () {
        return activeConfigurationWizardItemSize.getHeight()
    }, config.VALUE_TYPE.ITEMRAMPPLATESHEIGHT).addValidationBaseTextId(function () { return '2140'; });
    addValidationConfig(0, 1000, config.VALUE_TYPE.ITEMSIDEWALLLEFT).addValidationBaseTextId(function () { return '2141'; });
    addValidationConfig(0, 1000, config.VALUE_TYPE.ITEMSIDEWALLRIGHT).addValidationBaseTextId(function () { return '2142'; });
    addValidationConfig(0, 2000, config.VALUE_TYPE.ITEMLEFTCOMPARTMENTWIDTH).addValidationBaseTextId(function () { return '2143'; });
    addValidationConfig(0, 2000, config.VALUE_TYPE.ITEMRIGHTCOMPARTMENTWIDTH).addValidationBaseTextId(function () { return '2144'; });
    addValidationConfig(0, function () {
        return activeConfigurationWizardItemSize.getLength()
    }, config.VALUE_TYPE.ITEMBODYPAYLOADHORIZONTALCOG).addValidationBaseTextId(function () { return '2145'; });
    addValidationConfig(0, function () {
        return activeConfigurationWizardItemSize.getHeight()
    }, config.VALUE_TYPE.ITEMBODYPAYLOADVERTICALCOG).addValidationBaseTextId(function () { return '2146'; });
    addValidationConfig(0, function () {
        return activeConfigurationWizardItemSize.getWidth()
    }, config.VALUE_TYPE.ITEMBODYPAYLOADLATERALCOG).addValidationBaseTextId(function () { return '2147'; });
    addValidationConfig(0, 30000, config.VALUE_TYPE.ITEMLENGTH).addValidationBaseTextId(function () { return '2148'; });
    addValidationConfig(0, 10000, config.VALUE_TYPE.ITEMHEIGHT).addValidationBaseTextId(function () { return '2149'; });
    addValidationConfig(0, 10000, config.VALUE_TYPE.ITEMWIDTH).addValidationBaseTextId(function () { return '2150'; });
    addValidationConfig(0, function () {
        return activeConfigurationWizardItemSize.getHeight() - activeConfigurationWizardItem.getSubframeHeight() - activeConfigurationWizardItem.getSubstructureHeight();
    }, config.VALUE_TYPE.ITEMBODYDROPSIDEHEIGHT);
    addValidationConfig(0, function () {
        return activeConfigurationWizardItemSize.getLength()
    }, config.VALUE_TYPE.ITEMAFRAME).addValidationBaseTextId(function () { return '4400'; });
    addValidationConfig(0, function () {
        return activeConfigurationWizardItemSize.getLength()
    }, config.VALUE_TYPE.ITEMHOOKOFFSET).addValidationBaseTextId(function () { return '4401'; });
    addValidationConfig(0, function () {
        return activeConfigurationWizardItemSize.getLength()
    }, config.VALUE_TYPE.ITEMCENTREOFROLLER).addValidationBaseTextId(function () { return '4402'; });
    addValidationConfig(0, function () {
        return activeConfigurationWizardItemSize.getHeight()
    }, config.VALUE_TYPE.ITEMPLATFORMHEIGHT).addValidationBaseTextId(function () { return '4403'; });
    
    addValidationConfig(undefined, undefined, config.VALUE_TYPE.BODYDESCRIPTION).addValidationBaseTextId(function () { return '4278'; });
    addValidationConfig(undefined, undefined, config.VALUE_TYPE.CRANEDESCRIPTION).addValidationBaseTextId(function () { return '4279'; });
    addValidationConfig(undefined, undefined, config.VALUE_TYPE.OTHERDESCRIPTION).addValidationBaseTextId(function () { return '4280'; });
    addValidationConfig(undefined, undefined, config.VALUE_TYPE.FRIDGEDESCRIPTION).addValidationBaseTextId(function () { return '4281'; });
    addValidationConfig(undefined, undefined, config.VALUE_TYPE.TAILLIFTDESCRIPTION).addValidationBaseTextId(function () { return '4282'; });
    addValidationConfig(undefined, undefined, config.VALUE_TYPE.FIFTHWHEELDESCRIPTION).addValidationBaseTextId(function () { return '4283'; });
    addValidationConfig(undefined, undefined, config.VALUE_TYPE.HITCHDESCRIPTION).addValidationBaseTextId(function () { return '4284'; });
    addValidationConfig(undefined, undefined, config.VALUE_TYPE.TRAILERDESCRIPTION).addValidationBaseTextId(function () { return '4285'; });
    addValidationConfig(undefined, undefined, config.VALUE_TYPE.DETAILEDPAYLOADDESCRIPTION).addValidationBaseTextId(function () { return '4286'; });
    addValidationConfig(undefined, undefined, config.VALUE_TYPE.HOOKLIFTDESCRIPTION).addValidationBaseTextId(function () { return '4407'; });

    setupDefaultValidationConfigs();
}

function setupDefaultValidationConfigs() {
    addValidationConfig(0, 100, config.VALUE_TYPE.DEFAULTPERCENTAGEVALIDATION).addValidationBaseTextId(function () { return '4272'; });
    addValidationConfig(0, 999999, config.VALUE_TYPE.DEFAULTVALUEVALIDATION).addValidationBaseTextId(function () { return '4272'; });
    addValidationConfig(0, 9999, config.VALUE_TYPE.DEFAULTINCREMENTVALIDATION).addValidationBaseTextId(function () { return '4272'; });
    addValidationConfig(0, 360, config.VALUE_TYPE.DEFAULTDEGREESVALIDATION).addValidationBaseTextId(function () { return '4272'; });

    addValidationConfig(0, 9999, config.VALUE_TYPE.SETTINGSLENGTHINCREMENT).addValidationBaseTextId(function () { return '1943'; });
    addValidationConfig(0, 9999, config.VALUE_TYPE.SETTINGSMASSINCREMENT).addValidationBaseTextId(function () { return '1944'; });
    addValidationConfig(0, 100, config.VALUE_TYPE.SETTINGSPERCENTAGEINCREMENT).addValidationBaseTextId(function () { return '1945'; });            
}

/*
var overallVehicleHeightMax = evaluateLegislation('OVERALLVEHICLEHEIGHTMAX');
    var minVal = 0;
*/
function addValidationConfig(min, max, valueType, validationType) {
    //validationConfigs[valueType] = {
    //    minVal: min,
    //    maxVal: max,
    //    baseText: config.getValidationBaseText(valueType, globals.user.getCultureCode()),
    //    type: validationType || 'standard'
    //};

    validationConfigs[valueType] = new ValidationConfig(min, max, valueType, validationType);

    return validationConfigs[valueType];

    function ValidationConfig(min, max, valueType, validationType) {
        var self = this;

        self.minVal = min,
            self.maxVal = max,
            self.type = validationType || 'standard',
            self.useValidationBaseTextObject = false;

        self.addCustomPrecisionFunctionForValidationCompareVals = function (customPrecisionFunc) {
            self.customPrecisionFunc = customPrecisionFunc;
            return self;
        };

        self.addValidationBaseTextId = function(textId) {
            self.baseText = config.getValidationBaseText(textId(), globals.getActiveCultureCodeForTranslations());
            return self;
        };

        self.addValidationBaseTextObject = function (initObjFunc) {
            self.baseTextObject = {};
            let initObj = initObjFunc();
            if(typeof initObj[config.VALIDATION_MESSAGE_TYPE.BETWEEN] === 'string') {
                self.baseTextObject[config.VALIDATION_MESSAGE_TYPE.BETWEEN] = {};
                self.baseTextObject[config.VALIDATION_MESSAGE_TYPE.BETWEEN].messageCode = initObj[config.VALIDATION_MESSAGE_TYPE.BETWEEN];
                self.baseTextObject[config.VALIDATION_MESSAGE_TYPE.BETWEEN].baseText = config.getValidationBaseText(initObj[config.VALIDATION_MESSAGE_TYPE.BETWEEN], globals.getActiveCultureCodeForTranslations());
            }
            if(typeof initObj[config.VALIDATION_MESSAGE_TYPE.LESSTHAN] === 'string') {
                self.baseTextObject[config.VALIDATION_MESSAGE_TYPE.LESSTHAN] = {};
                self.baseTextObject[config.VALIDATION_MESSAGE_TYPE.LESSTHAN].messageCode = initObj[config.VALIDATION_MESSAGE_TYPE.LESSTHAN];
                self.baseTextObject[config.VALIDATION_MESSAGE_TYPE.LESSTHAN].baseText = config.getValidationBaseText(initObj[config.VALIDATION_MESSAGE_TYPE.LESSTHAN], globals.getActiveCultureCodeForTranslations());
            }
            if(typeof initObj[config.VALIDATION_MESSAGE_TYPE.GREATERTHAN] === 'string') {
                self.baseTextObject[config.VALIDATION_MESSAGE_TYPE.GREATERTHAN] = {};
                self.baseTextObject[config.VALIDATION_MESSAGE_TYPE.GREATERTHAN].messageCode = initObj[config.VALIDATION_MESSAGE_TYPE.GREATERTHAN];
                self.baseTextObject[config.VALIDATION_MESSAGE_TYPE.GREATERTHAN].baseText = config.getValidationBaseText(initObj[config.VALIDATION_MESSAGE_TYPE.GREATERTHAN], globals.getActiveCultureCodeForTranslations());
            }
            self.useValidationBaseTextObject = true;
            return self;
        };
    }
}

//function updateValue(newValue, oldValue, valueType, path) {
//    var deltaVal = newValue - oldValue;
//    var deltaValApplied = false;
//    var passedValidation = false;

//    var attributeGroup, errorMsgObv, resetObv, inputName;

//    if (valueType !== undefined) {
//        attributeGroup = getAttributeGroup(path + '.' + valueType);
//        if (attributeGroup !== null) {
//            errorMsgObv = attributeGroup.errorMessage;
//            resetObv = attributeGroup.reset;
//            inputName = attributeGroup.getInputName();
//        }
//    }


//    switch (valueType) {
//        default:
//            newValue = returnInteger(newValue);
//            return newValue;
//            break;
//    }
//}
let longRunningLegislationAttributes = {};
function doActionsAfterSuccesfulValidation(valueType, newValue, deltaValue) {
    if(globals.debugLegislation) {
        longRunningLegislationAttributes = {};
    }
    let doActionsDfd = $.Deferred();

    
    if(offerIsOpen.value) {
        // console.log('VehicleFifthWheelOffset: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.VehicleFifthWheelOffset, true));
        // console.log('VehicleRearMinus1SteeringAngle: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.VehicleRearMinus1SteeringAngle, true));
        // console.log('VehicleRearMinus2SteeringAngle: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.VehicleRearMinus2SteeringAngle, true));
        // console.log('VehicleRearMinus3SteeringAngle: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.VehicleRearMinus3SteeringAngle, true));
        // console.log('VehicleRearPlus1SteeringAngle: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.VehicleRearPlus1SteeringAngle, true));
        // console.log('VehicleRearPlus2SteeringAngle: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.VehicleRearPlus2SteeringAngle, true));
        // console.log('VehicleRearPlus3SteeringAngle: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.VehicleRearPlus3SteeringAngle, true));
        // console.log('VehicleOverallHeight: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.VehicleOverallHeight, true));
        // console.log('Trailer1OverallHeight: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.Trailer1OverallHeight, true));
        // console.log('Trailer2OverallHeight: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.Trailer2OverallHeight, true));
        // console.log('VehicleHeightExcludingPayload: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.VehicleHeightExcludingPayload, true));
        // console.log('Trailer1HeightExcludingPayload: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.Trailer1HeightExcludingPayload, true));
        // console.log('Trailer2HeightExcludingPayload: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.Trailer2HeightExcludingPayload, true));
        // console.log('CombinationHeightExcludingPayload: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.CombinationHeightExcludingPayload, true));
        // console.log('BodyTypeTrailer2: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.BodyTypeTrailer2, true));
        // console.log('OriginalWBSpecified: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.OriginalWBSpecified, true));
        // console.log('OriginalROH: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.OriginalROH, true));
        // console.log('OriginalCE: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.OriginalCE, true));
        // console.log('VehicleCE: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.VehicleCE, true));
        getMissingValuesLookupObject();
    }
    
    nextTick(function(){
        if (getActiveOffer() !== '' && getActiveOffer() !== undefined) {
            // getActiveOffer().getRig().getVehicle().getAxlesHolder().calculatePercentagesOfWeightCarried();
            // getOriginalOffer().getRig().getVehicle().getAxlesHolder().calculatePercentagesOfWeightCarried();

            evaluateLegislationCalled = 0;
            legislationValueFoundInQuickLookup = 0;
            legislationValueInterpreted = 0;
    
            clearActiveLegislationQuickLookupObjects();
            doActionsDfd.resolve();
            if (getActiveOffer().getDataAvailability().ActiveConfiguration && getActiveOffer().getConfiguration() !== undefined) {
                doCommonUpdateActions(valueType, newValue, deltaValue);
                refreshData.value = new Date().getTime();
                refreshUILiveLabels.value = new Date().getTime();
                
                //send settings data to Intercom
                notifyValidationListeners(valueType, newValue, deltaValue);
                if (changeListenerCallback !== undefined) {
                    if (skipGraphicsCallback === false) {
                        changeListenerCallback(valueType, newValue, deltaValue);
                    } else {
                        skipGraphicsCallback = false;
                    }
                }
                if(globals.debugLegislation) {
                    console.log("********************************************************************************************************************************** \n\n\n");
                    Object.values(longRunningLegislationAttributes).forEach(function (longRunningAttribute) {
                        console.log("Attribute Name: " + longRunningAttribute.attributeName + '\n');
                        console.log("Duration: " + longRunningAttribute.duration + '\n');
                    });
                    console.log("********************************************************************************************************************************** \n\n\n");
                }
            }
            if (allowUpdateOfUnsavedChangesOnOffer) {
                unsavedChangesOnOffer.value = true;
            }
            // window.top.postMessage('hello', '*');
        }else {
            doActionsDfd.resolve();
        }
    });

    return doActionsDfd.promise();
}

function notifyValidationListeners(valueType, newValue, deltaValue) {
    if (preventSettingsUpdate !== true) {
        if (validationListeners.length > 0) {
            validationListeners.forEach(function (listenerCallback) {
                try {
                    listenerCallback(valueType, newValue, deltaValue);
                } catch (listenerCallbackEx) {
                    TScMessenger.writeDebugMessage('OfferManager.js, notifyValidationListeners, error calling listenerCallback');
                }
            });
        }
    }
}

function doCommonUpdateActions(valueType, newValue, deltaValue) {
    globals.setCurrentCombinationType(rigOps.getCombinationType());
    setLegislationLookupObjectsCleared(false);
    clearActiveLegislationQuickLookupObjects();
    notifyGraphicsManagerOnValidationFail = false;
    updatePayload();
    

    validPdfOnLocalStorage.value = false;
    // validPdfOnLocalStorage.valueHasMutated();
    setConfigDrawing(null);
    dataManager.remove(config.OPERATION.ConfigDrawing, vehicleId.value + "_configurationDrawing");
    if (valueType !== config.VALUE_TYPE.STEERINGANGLEINNER && valueType !== config.VALUE_TYPE.TURNINGRADIUSCURBTOCURB && valueType !== config.VALUE_TYPE.TURNINGRADIUSWALLTOWALL && valueType !== config.VALUE_TYPE.TURNINGABILITYSPECIFIEDAS) {
        var turningAbilitySpecifiedAsAOG, steeringAngleInnerAOG, turningRadiusCurbToCurbAOG, turningRadiusWallToWallAOG;
        if (valueType === config.VALUE_TYPE.WHEELBASESPECIFIED /*&& (rigOps.getVehicleWheelbaseTurningChanged() || getConfiguration().getWheelbaseOverride())*/) {
            turningCircleLockedToSteeringAngleInner = true;
            turningAbilitySpecifiedAsAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TURNINGABILITYSPECIFIEDAS);
            turningAbilitySpecifiedAsAOG.permissionObv.value = turningAbilitySpecifiedAsAOG.permission(turningAbilitySpecifiedAsAOG.getPath());

            steeringAngleInnerAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.STEERINGANGLEINNER);
            steeringAngleInnerAOG.permissionObv.value = steeringAngleInnerAOG.permission(steeringAngleInnerAOG.getPath());

            turningRadiusCurbToCurbAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TURNINGRADIUSCURBTOCURB);
            turningRadiusCurbToCurbAOG.permissionObv.value = turningRadiusCurbToCurbAOG.permission(turningRadiusCurbToCurbAOG.getPath());

            turningRadiusWallToWallAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TURNINGRADIUSWALLTOWALL);
            turningRadiusWallToWallAOG.permissionObv.value = turningRadiusWallToWallAOG.permission(turningRadiusWallToWallAOG.getPath());
            rigOps.updateTurningCircleCalculatedVals(config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER, undefined/*steeringAngleInnerAOG.value()*/);
        }  else {
            if (valueType === config.VALUE_TYPE.SETTINGSLEGISLATION) {
                initialiseTurningAbilityVariables();
            } else {
                if (rigOps.getVehicleWheelbaseTurningChanged() || valueType === 'ITEM_DELETED') {
                    turningCircleLockedToSteeringAngleInner = true;
                    turningAbilitySpecifiedAsAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TURNINGABILITYSPECIFIEDAS);
                    turningAbilitySpecifiedAsAOG.permissionObv.value = turningAbilitySpecifiedAsAOG.permission(turningAbilitySpecifiedAsAOG.getPath());

                    steeringAngleInnerAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.STEERINGANGLEINNER);
                    steeringAngleInnerAOG.permissionObv.value = steeringAngleInnerAOG.permission(steeringAngleInnerAOG.getPath());

                    turningRadiusCurbToCurbAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TURNINGRADIUSCURBTOCURB);
                    turningRadiusCurbToCurbAOG.permissionObv.value = turningRadiusCurbToCurbAOG.permission(turningRadiusCurbToCurbAOG.getPath());

                    turningRadiusWallToWallAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TURNINGRADIUSWALLTOWALL);
                    turningRadiusWallToWallAOG.permissionObv.value = turningRadiusWallToWallAOG.permission(turningRadiusWallToWallAOG.getPath());
                    rigOps.updateTurningCircleCalculatedVals(config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER, undefined);
                } else {
                    rigOps.updateTurningCircleCalculatedVals(getActiveOffer().getRig().getVehicle().getTurningAbilitySpecifiedAs(), getActiveOffer().getRig().getVehicle().getCurrentTurningAbilityVal());
                }

            }

        }
        // if(offerIsOpen.value) {
        // //     // console.log('VehicleFifthWheelOffset: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.VehicleFifthWheelOffset, true));
        // //     console.log('VehicleRearMinus1SteeringAngle: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.VehicleRearMinus1SteeringAngle, true));
        // //     console.log('VehicleRearMinus2SteeringAngle: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.VehicleRearMinus2SteeringAngle, true));
        // //     console.log('VehicleRearMinus3SteeringAngle: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.VehicleRearMinus3SteeringAngle, true));
        // //     console.log('VehicleRearPlus1SteeringAngle: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.VehicleRearPlus1SteeringAngle, true));
        // //     console.log('VehicleRearPlus2SteeringAngle: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.VehicleRearPlus2SteeringAngle, true));
        // //     console.log('VehicleRearPlus3SteeringAngle: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.VehicleRearPlus3SteeringAngle, true));
        // //     console.log('VehicleOverallHeight: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.VehicleOverallHeight, true));
        // //     console.log('Trailer1OverallHeight: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.Trailer1OverallHeight, true));
        // //     console.log('Trailer2OverallHeight: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.Trailer2OverallHeight, true));
        // //     console.log('VehicleHeightExcludingPayload: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.VehicleHeightExcludingPayload, true));
        // //     console.log('Trailer1HeightExcludingPayload: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.Trailer1HeightExcludingPayload, true));
        // //     console.log('Trailer2HeightExcludingPayload: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.Trailer2HeightExcludingPayload, true));
        // //     console.log('CombinationHeightExcludingPayload: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.CombinationHeightExcludingPayload, true));
        // //     console.log('BodyTypeTrailer2: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.BodyTypeTrailer2, true));
        // //     console.log('EmissionsStandard: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.EmissionsStandard, true));
        //     // console.log('EmissionsStandardCode: ' + getInterpreterInstance().loadVariable(undefined, legislationVals.variableTypes.EmissionsStandardCode, true));
        // }
        updateOriginalTurningCircleValuesIfNecessary();
    }
    
    
    
    
    generateNotes();
    
    //make sure generateWarnings is called after generateDataForActiveLegislations
    generateDataForActiveLegislations();
    generateWarnings();
    var mergedPermissibleValues = getMergedLegislationsCombinationPermissibleVals(getActiveLegislationsCombinationPermissibleVals());
    rigOps.setCombinationPermissibleVals(mergedPermissibleValues);
    updateCostingItems();
    
}

function doActionsAfterOverrideChecked() {
    unsavedChangesOnOffer.value = true;
    generateNotes();
    generateDataForActiveLegislations();
    generateWarnings();
}

function doActionsAfterFailedValidation(valueType, oldValue) {
    setLegislationLookupObjectsCleared(false);
    clearActiveLegislationQuickLookupObjects();
    if (getActiveOffer().getDataAvailability().ActiveConfiguration && getActiveOffer().getConfiguration() !== undefined) {

        updatePayload();
        //doCommonUpdateActions();

        generateNotes();
        generateDataForActiveLegislations();
        generateWarnings();
        if (changeListenerCallback !== undefined && notifyGraphicsManagerOnValidationFail === true) {
            notifyGraphicsManagerOnValidationFail = false;
            changeListenerCallback(valueType, oldValue);
        }
    }
}

function doActionsWhileDragging() {
    setLegislationLookupObjectsCleared(false);
    clearActiveLegislationQuickLookupObjects();
    updatePayload();
    generateDataForActiveLegislations();
    generateWarnings();
    if (changeListenerCallback !== undefined) {
        changeListenerCallback('Refresh_Table_Data');
    }
}

function updateDynamicValidationGroups() {
    if (dynamicValidationGroups && dynamicValidationGroups.length > 0) {
        dynamicValidationGroups.forEach(function (groupDetails) {
            var aog = getAttributeGroup(groupDetails.path + '.' + groupDetails.attribute);
            var validationObject = groupDetails.validationObjectFunction(groupDetails.object, groupDetails.path);
            validationObject.setOfferManager(offerManager);
            aog.addValidation(validationObject);
        });
    }
}

function removeDeletedObjectsFromDynamicValidationGroups(path) {
    if (dynamicValidationGroups && dynamicValidationGroups.length > 0) {
        var filteredGroups = dynamicValidationGroups.filter(function (groupDetails) {
            return path !== groupDetails.path;
        });
        dynamicValidationGroups = filteredGroups;

    }
}

function addDynamicValidationGroup(path, attribute, validationObjectFunc, object) {
    dynamicValidationGroups.push({
        path: path,
        attribute: attribute,
        validationObjectFunction: validationObjectFunc,
        object: object
    });
}

function updatePayload() {
    var tempVehicle = getActiveOffer().getRig().getVehicle();
    var tempTrailer1 = getActiveOffer().getRig().getTrailer1();
    var tempTrailer2 = getActiveOffer().getRig().getTrailer2();

    var tempVehiclePayloadHolder = null,
        tempTrailer1PayloadHolder = null,
        tempTrailer2PayloadHolder = null;
    var tempVehicleBody = null,
        tempTrailer1Body = null,
        tempTrailer2Body = null;

    tempVehiclePayloadHolder = tempVehicle.getPayloadHolder();
    tempVehicleBody = tempVehicle.getAccessoryHolder().getBody();

    if (tempTrailer1 !== null) {
        tempTrailer1PayloadHolder = tempTrailer1.getPayloadHolder();
        tempTrailer1Body = tempTrailer1.getAccessoryHolder().getBody();
    }
    if (tempTrailer2 !== null) {
        tempTrailer2PayloadHolder = tempTrailer2.getPayloadHolder();
        tempTrailer2Body = tempTrailer2.getAccessoryHolder().getBody();
    }

    if (tempTrailer1 === null) {
        //if (tempVehicleBody !== null && tempVehiclePayloadHolder !== null && tempVehiclePayloadHolder.getSimplePayloadObject() !== null) {
        //    adjustSimplePayloadToReflectChangesBodyDimensions(tempVehiclePayloadHolder, tempVehicleBody);
        //}

        //if ((tempVehicleBody !== null || tempVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) && tempVehiclePayloadHolder !== null && tempVehiclePayloadHolder.getSimplePayloadObject() !== null) {
        //    maximiseSimplePayloadForGivenBody(tempVehiclePayloadHolder, tempVehicleBody);
        //}
        updateVehiclePayload(tempVehicle, tempVehicleBody, tempVehiclePayloadHolder);
    } else if (tempTrailer2 === null) {
        var combinationType = rigOps.getCombinationType();
        if (combinationType === config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR || combinationType === config.COMBINATION_TYPES.VEHICLE_AND_PUP) {
            updateVehiclePayload(tempVehicle, tempVehicleBody, tempVehiclePayloadHolder);
        }

        if (tempTrailer1Body !== null && tempTrailer1PayloadHolder !== null && tempTrailer1PayloadHolder.getSimplePayloadObject() !== null) {
            adjustSimplePayloadToReflectChangesBodyDimensions(tempTrailer1PayloadHolder, tempTrailer1Body);
        }


        if ((tempTrailer1Body !== null || tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(tempTrailer1))) && tempTrailer1PayloadHolder !== null && tempTrailer1PayloadHolder.getSimplePayloadObject() !== null) {
            maximiseSimplePayloadForGivenBody(tempTrailer1PayloadHolder, tempTrailer1Body);
        }
    } else {
        //var combinationType = rigOps.getCombinationType();
        //if (combinationType === config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR || combinationType === config.COMBINATION_TYPES.VEHICLE_AND_PUP) {
        //    updateVehiclePayload(tempVehicle, tempVehicleBody, tempVehiclePayloadHolder);
        //}

        if (tempTrailer1Body !== null && tempTrailer1PayloadHolder !== null && tempTrailer1PayloadHolder.getSimplePayloadObject() !== null) {
            adjustSimplePayloadToReflectChangesBodyDimensions(tempTrailer1PayloadHolder, tempTrailer1Body);
        }


        if ((tempTrailer1Body !== null || tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(tempTrailer1))) && tempTrailer1PayloadHolder !== null && tempTrailer1PayloadHolder.getSimplePayloadObject() !== null) {
            maximiseSimplePayloadForGivenBody(tempTrailer1PayloadHolder, tempTrailer1Body);
        }

        if (tempTrailer2Body !== null && tempTrailer2PayloadHolder !== null && tempTrailer2PayloadHolder.getSimplePayloadObject() !== null) {
            adjustSimplePayloadToReflectChangesBodyDimensions(tempTrailer2PayloadHolder, tempTrailer2Body);
        }


        if ((tempTrailer2Body !== null || tempTrailer2.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (tempTrailer2.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(tempTrailer2))) && tempTrailer2PayloadHolder !== null && tempTrailer2PayloadHolder.getSimplePayloadObject() !== null) {
            maximiseSimplePayloadForGivenBody(tempTrailer2PayloadHolder, tempTrailer2Body);
        }
        //#unfinished, implement scenario for two trailers on the rig
    }

    function adjustSimplePayloadToReflectChangesBodyDimensions(payloadHolder, body) {
        payloadHolder.getSimplePayloadObject().setLength(body.getActualInternalLength());
        payloadHolder.getSimplePayloadObject().setWidth(body.getActualInternalWidth());
        if (body.getType() === config.BODY_TYPES.SERVICE /*|| body.getType() === config.BODY_TYPES.COMPACTOR || body.getType() === config.BODY_TYPES.OTHER*/) {
            payloadHolder.getSimplePayloadObject().setHeight(body.getActualExternalHeight());
        } else if (body.getType() === config.BODY_TYPES.BEAVERTAIL || body.getType() === config.BODY_TYPES.DROPSIDE) {
            payloadHolder.getSimplePayloadObject().setHeight(body.getActualExternalHeight() - body.getSubframeHeight() - body.getSubstructureHeight());
        } else {
            payloadHolder.getSimplePayloadObject().setHeight(body.getActualInternalHeight());
        }
    }

    function maximiseSimplePayloadForGivenBody(payloadHolder, body) {
        var mergedPermissibleValues = getMergedLegislationsCombinationPermissibleVals(getActiveLegislationsCombinationPermissibleVals());
        var tempPayloadObject = payloadHolder.getSimplePayloadObject();
        if (tempPayloadObject.getSpecifyMassOverride() === false) {
            //tempPayloadObject.setMass(rigOps.getMaximisedLoad());
            clearActiveLegislationQuickLookupObjects();
            rigOps.maximisePayloads(mergedPermissibleValues);
        }
    }

    function updateVehiclePayload(tempVehicle, tempVehicleBody, tempVehiclePayloadHolder) {
        if (tempVehicleBody !== null && tempVehiclePayloadHolder !== null && tempVehiclePayloadHolder.getSimplePayloadObject() !== null) {
            adjustSimplePayloadToReflectChangesBodyDimensions(tempVehiclePayloadHolder, tempVehicleBody);
        }

        if ((tempVehicleBody !== null || tempVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) && tempVehiclePayloadHolder !== null && tempVehiclePayloadHolder.getSimplePayloadObject() !== null) {
            //maximiseSimplePayloadForGivenBody(tempVehiclePayloadHolder, tempVehicleBody);
        }
    }
}

function menuItemFocusCallback(path) {
    var breakHere = 0;
    changeListenerCallback('Focus_Event', path);
}

function returnInteger(valToCheck) {
    var temp;
    if (typeof valToCheck === 'string') {
        temp = parseInt(valToCheck.replace(/[^.\d]/g, ""));
    } else {
        temp = parseInt(valToCheck);
    }
    return isNaN(temp) ? 0 : temp;
}

function returnFloat(valToCheck) {
    var temp;
    if (typeof valToCheck === 'string') {
        temp = parseFloat(valToCheck.replace(/[^.\d]/g, ""));
    } else {
        temp = parseFloat(valToCheck);
    }
    return temp;
}

function convertToLength(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return value / measurementSystemToUse.lengthConversion;
}

function convertFromLength(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return value * measurementSystemToUse.lengthConversion;
}

function registerChangeListener(listenerCallback) {
    changeListenerCallback = listenerCallback;
    setupChangeListenerSubscriptions();
}

function registerAddToLocalStorageListener(listenerCallback, dataUrlCallback, detachOnResizeCallback) {
    addToLocalStorageCallback = listenerCallback;
    getDataUrlCallback = dataUrlCallback;
    detachGraphicsManagerOnResizeCallback = detachOnResizeCallback;
}

function registerCreateBlobForDownload(listenerCallback) {
    createBlobForDownloadCallback = listenerCallback;
}

function registerObjectAddedListener(listenerCallback) {
    objectAddedListenerCallback = listenerCallback;
}

function registerClearOfferCallback(listenerCallback) {
    clearOfferCallback = listenerCallback;
}

function registerMenuLinkExpansionRequestListener(listenerCallback) {
    expandMenuLinkCallback = listenerCallback;
}

function registerValidationCallbackListener(listener) {
    validationListeners.push(listener);
}

function registerUndoRedoCallbackListener(listener) {
    undoRedoListeners.push(listener);
}

function registerRenderingCompleteCallbackListener(listener) {
    renderingCompleteListeners.push(listener);
}

function registerGraphicsManagerCleanUpCallback(callback) {
    graphicsManagerCleanUpCallback = callback;
}

function registerPostCompleteSwapFunction(callback) {
    postCompleteSwapFunction = callback;
}

function removeValidationCallbackListener(listenerToRemove) {
    var index = validationListeners.indexOf(listenerToRemove);

    if (index > -1) {
        validationListeners.splice(index, 1);
    }
}

function removeUndoRedoCallbackListener(listenerToRemove) {
    var index = undoRedoListeners.indexOf(listenerToRemove);

    if (index > -1) {
        undoRedoListeners.splice(index, 1);
    }
}

function removeRenderingCompleteCallbackListener(listenerToRemove) {
    var index = renderingCompleteListeners.indexOf(listenerToRemove);

    if (index > -1) {
        renderingCompleteListeners.splice(index, 1);
    }
}

function getRenderingCompleteCallbackListeners() {
    return renderingCompleteListeners;
}

function registerSyncCallbackListener(listener) {
    syncListeners.push(listener);
}

function registerUpdateBodyMenuCallback(listenerCallback) {
    updateBodyMenuCallback = listenerCallback;
}

function registerUpdateVehicleMenuCallback(listenerCallback) {
    updateVehicleMenuCallback = listenerCallback;
}

function registerUpdateAccessoryMenuLabelListener(callback) {
    updateAccessoryMenuLabelCallback = callback;
}

function registerCreatePdfWithPromiseCallback(callback) {
    createPdfWithPromiseCallback = callback;
}

function registerSyncErrorToastHandler(callback) {
    syncErrorToastHandlerCallback = callback;
}

function registerSyncSuccessToastHandler(callback) {
    syncSuccessToastHandlerCallback = callback;
}

function registerSyncSavedCopyToastHandler(callback) {
    syncSavedCopyToastHandlerCallback = callback;
}

function registerNewSharedOffersToastHandler(callback) {
    newSharedOfferToastHandlerCallback = callback;
}

function registerNewOrUpdatedTeamItemsSyncToastHandler(callback) {
    newOrUpdatedTeamItemsSyncToastHandlerCallback = callback;
}

function registerWaypointAddedListener(listenerCallback) {
    waypointAddedListenerCallback = listenerCallback;
}

function registerValidationFailedWhileDraggingToastHandler(callback) {
    validationFailedWhileDraggingToastHandlerCallback = callback;
}

function registerRefreshDataCallback(callback) {
    refreshDataCallback = callback;
}

function registerOpenSettingsModalRequestListener(callback) {
    openSettingsModalCallback = callback;
}

function registerLogoutFunc(callback) {
    shellLogout = callback;
}

function registerLoginFunc(callback) {
    shellLogin = callback;
}

function registerManageHiddenButtonsFunc(callback) {
    shellManageHiddenButtons = callback;
}

function registerUpdateApplicationSelectionAndVehicleTypeSelectionFunc(callback) {
    shellUpdateApplicationSelectionAndVehicleTypeSelection = callback;
}

function registerDoAppGeneralSetupSteps(callback) {
    shellDoAppGeneralSetupSteps = callback;
}

function registerOpenBuyNowDialog(callback) {
    shellOpenBuyNowDialogCallback = callback;
}

function registerOpenUserSettingsModalCallback(callback) {
    shellOpenUserSettingsModalCallback = callback;
}

function registerClearSimulationResultsOnlyCallback(callback) {
    clearPerformanceSimulationResultOnly = callback;
}

function registerEvaulateShowSaveOptionFunc(callback) {
    shellEvaulateShowSaveOption = callback;
}

function registerHandleEssentialsMessageResponseFunc(callback) {
    shellHandleEssentialsMessageResponse = callback;
}

function getCreatePdfWithPromiseCallback() {
    return createPdfWithPromiseCallback;
}

function registerRefreshMenuOnChangeOfCabDimensionsOrChassisLengthCallback(callback) {
    if (typeof callback === 'function') {
        refreshMenuOnChangeOfCabDimensionsOrChassisLengthCallback = callback;
    } else {
        refreshMenuOnChangeOfCabDimensionsOrChassisLengthCallback = null;
    }
}

function registerRigObjectRemovalHandler(rigObjectRemovalHandler) {
    rigObjectRemovalHandlerCallback = rigObjectRemovalHandler;
}

function registerPostUndoCallback(postUndoCallback) {
    postUndoConfigurationCallback = postUndoCallback;
}

function registerRequestSalesToolCallback(callback) {
    requestSalesToolCallback = callback;
}

function getOpenSettingsModalOnClickFunction(callback) {
    return openSettingsModalOnClickFunction;
}

function setOpenSettingsModalOnClickFunction(callback) {
    openSettingsModalOnClickFunction = callback;
}

function getRefreshMenuOnChangeOfCabDimensionsOrChassisLengthCallback() {
    return refreshMenuOnChangeOfCabDimensionsOrChassisLengthCallback;
}

function setupChangeListenerSubscriptions() {
    watch(activeMeasurementSystem, function (newValue, oldValue) {
        if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
            // NOTE: Check with Peter if this is required
            if (oldValue === undefined && changeListenerCallback !== undefined) {
                changeListenerCallback("MEASUREMENT_SYSTEM_CHANGE"); // User Story 17565
            } else if (oldValue !== undefined) {
                if (newValue.id !== oldValue.id && changeListenerCallback !== undefined) {
                    changeListenerCallback("MEASUREMENT_SYSTEM_CHANGE"); // User Story 17565
                }
            }
        }
    });
    //additionalNote.beforeAndAfterSubscribe(function (oldValue, newValue) {
    //    if (getActiveOffer() !== undefined && getActiveOffer() !== '' && getActiveOffer().getMeasurementSystem() !== undefined) {
    //        if (newValue !== oldValue && changeListenerCallback !== undefined) {
    //            changeListenerCallback("ADDITIONAL_NOTE_CHANGE");
    //        }
    //    }
    //});
}

function getUIFormattedLengthValue(value, doNotIncludeUnit, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    if (doNotIncludeUnit === true) {
        return Globalize.format((value / measurementSystemToUse.lengthConversion), "n" + measurementSystemToUse.lengthPrecision); // User Story 17565
    } else {
        return Globalize.format((value / measurementSystemToUse.lengthConversion), "n" + measurementSystemToUse.lengthPrecision) + ' ' + measurementSystemToUse.unitLength; // User Story 17565
    }
}

function getUIFormattedWattValue(value) {
    return Globalize.format(value, "n1") + ' ' + config.getTranslationText('4658'); // User Story 17565
}

function getUIFormattedFuelExpressedAsValue(value) {
    return Globalize.format(value, "n1") + ' ' + getActiveOffer().getRig().getVehicle().getExpressedAs() // User Story 17565
}

function getUIFormattedEnergySourceUnitOfMeasure(value) {
    if(getConfiguration().getFuelType() === config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE) {
        return offerManager.getUIFormattedWattValue(value);
    }else if(offerManager.isFuelTypeWithExpressedASSupport()) {
        return offerManager.getUIFormattedFuelExpressedAsValue(value);
    }else {
        return offerManager.getUIFormattedVolumeValue(value);
    }
}

function getUIFormattedLengthValueNoUnit(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return Globalize.format((value / measurementSystemToUse.lengthConversion), "n" + measurementSystemToUse.lengthPrecision); // User Story 17565
}

function getUIBridgeFormattedLengthValue(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    if (measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.METRIC) {
        return getUIFormattedLengthValue(value);
    } else {
        return getUIBridgeFormattedDimensionValue(value);
    }
}

function getUIBridgeNearestFormattedLengthValue(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    if (measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.METRIC) {
        return getUIFormattedLengthValue(value);
    } else {
        return getUIBridgeNearestFormattedDimensionValue(value);
    }
}

function getUIFormattedNotConvertedLengthValue(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return Globalize.format(value, "n" + measurementSystemToUse.lengthPrecision) + ' ' + measurementSystemToUse.unitLength; // User Story 17565
}

function getUIFormattedVolumeValue(value, decimalPlaces, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem),
        decimalsToUse = decimalPlaces !== undefined ? decimalPlaces : measurementSystemToUse.volumePrecision;
    return Globalize.format((value * measurementSystemToUse.volumeConversion), "n" + decimalsToUse) + ' ' + measurementSystemToUse.unitVolume; // User Story 17565
}

function getUIFormattedDensityValue(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return Globalize.format((value * measurementSystemToUse.densityConversion), "n" + measurementSystemToUse.densityPrecision) + ' ' + measurementSystemToUse.unitDensity; // User Story 17565
}

function getUIFormattedPercentageValue(value, decimalPlaces, doNotIncludeUnit) {
    var decimalsToUse = typeof decimalPlaces === 'number' || typeof decimalPlaces === 'string' ? "n" + decimalPlaces : "n1"; // User Story 17565
    if (doNotIncludeUnit === true) {
        return Globalize.format(value, decimalsToUse);
    } else {
        return Globalize.format(value, decimalsToUse) + ' %';
    }
}

function getUIFormattedPercentageValueNoUnit(value, precision) {
    return Globalize.format(value, precision !== undefined ? "n" + precision : "n1"); // User Story 17565
}

function getUIFormattedPercentageIncrementValue(value, precision) {
    return Globalize.format(value, precision !== undefined ? "n" + precision : "n1") + ' %'; // User Story 17565
}

function getMeasurementSystemToUse(measurementSystem){
    if (measurementSystem !== undefined) {
        return measurementSystem;
    }
    if (getActiveOffer() !== undefined && getActiveOffer() !== null && getActiveOffer() !== '' && typeof getActiveOffer().getMeasurementSystem === 'function' && getActiveOffer().getMeasurementSystem() !== undefined) {
        return getActiveOffer().getMeasurementSystem();
    }
    return globals.user.getActiveMeasurementSystem();
}

function getCurMeasurementSysRoundedLength(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return parseFloat((value / measurementSystemToUse.lengthConversion).toFixed(measurementSystemToUse.lengthPrecision));
}

function getCurMeasurementSysRoundedSpeed(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return parseFloat((value * measurementSystemToUse.speedConversion).toFixed(measurementSystemToUse.speedPrecision));
}

function getCurMeasurementSysRoundedVolume(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return parseFloat((value * measurementSystemToUse.volumeConversion).toFixed(measurementSystemToUse.volumePrecision));
}

function getUIFormattedDimensionValue(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return Globalize.format((value / measurementSystemToUse.lengthConversion), "n" + measurementSystemToUse.lengthPrecision); // User Story 17565
}

function getUIBridgeFormattedDimensionValue(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    if (measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.METRIC) {
        return getUIFormattedDimensionValue(value);
    } else {
        var returnVal = '';
        var totalInches = value / measurementSystemToUse.lengthConversion;

        if (getCurMeasurementSysRoundedLength(value) <= getCurMeasurementSysRoundedLength(3048)) {
            returnVal = Globalize.format((totalInches), "n" + measurementSystemToUse.lengthPrecision) + '"'; // User Story 17565
        } else {
            var totalInchesRounded = totalInches;
            var feet = Math.floor(totalInchesRounded / 12);
            var inches = totalInchesRounded - (12 * feet);

            if (feet > 0) {
                returnVal += feet + '\' ';
            }

            returnVal += Globalize.format((inches), "n" + measurementSystemToUse.lengthPrecision) + '"'; // User Story 17565
        }
        return returnVal;
    }

}

function getUIBridgeNearestFormattedDimensionValue(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    if (measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.METRIC) {
        return getUIFormattedDimensionValue(value);
    } else {
        var returnVal = '';
        var totalInches = getCurMeasurementSysRoundedLength(value);

        if (getCurMeasurementSysRoundedLength(value) <= getCurMeasurementSysRoundedLength(3048)) {
            returnVal = Globalize.format((totalInches), "n" + measurementSystemToUse.lengthPrecision) + '"'; // User Story 17565
        } else {
            var totalInchesRounded = Math.round(totalInches);
            var feet = Math.floor(totalInchesRounded / 12);
            var inches = totalInchesRounded - (12 * feet);

            if (feet > 0) {
                returnVal += feet + '\' ';
            }

            returnVal += Globalize.format((inches), "n" + measurementSystemToUse.lengthPrecision) + '"'; // User Story 17565
        }
        return returnVal;
    }

}

function getUIFormattedMassValue(value, decimalPlaces, doNotIncludeUnit, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem),
        decimalsToUse = typeof decimalPlaces === 'number' || typeof decimalPlaces === 'string' ? decimalPlaces : measurementSystemToUse.massPrecision;
    if (doNotIncludeUnit === true) {
        return Globalize.format((value * measurementSystemToUse.massConversion), "n" + decimalsToUse); // User Story 17565
    } else {
        return Globalize.format((value * measurementSystemToUse.massConversion), "n" + decimalsToUse) + ' ' + measurementSystemToUse.unitMass; // User Story 17565
    }
}

function getUIFormattedMassValueIgnoreMeasurementSystem(value, decimalPlaces, doNotIncludeUnit, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem),
        decimalsToUse = typeof decimalPlaces === 'number' || typeof decimalPlaces === 'string' ? decimalPlaces : measurementSystemToUse.massPrecision;
    if (doNotIncludeUnit === true) {
        return Globalize.format((value), "n" + decimalsToUse); // User Story 17565
    } else {
        return Globalize.format((value), "n" + decimalsToUse) + ' ' + measurementSystemToUse.unitMass; // User Story 17565
    }
}

function getUIFormattedNotConvertedMassValue(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return Globalize.format(value, "n" + measurementSystemToUse.massPrecision) + ' ' + measurementSystemToUse.unitMass; // User Story 17565
}

function getUIFormattedLinearDensityValue(value, doNotIncludeUnit, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    if (doNotIncludeUnit === true) {
        return Globalize.format((value * measurementSystemToUse.linearDensityConversion), "n" + measurementSystemToUse.linearDensityPrecision); // User Story 17565
    } else {
        return Globalize.format((value * measurementSystemToUse.linearDensityConversion), "n" + measurementSystemToUse.linearDensityPrecision) + ' ' + measurementSystemToUse.unitLinearDensity; // User Story 17565
    }
}

function getUIFormattedLinearDensityValueNoUnit(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return Globalize.format((value * measurementSystemToUse.linearDensityConversion), "n" + measurementSystemToUse.linearDensityPrecision); // User Story 17565
}

function getUIFormattedDegreeValue(value) {
    var degreeSymbol = '°'; //'\U+00B0';
    return parseFloat(parseFloat(value).toFixed(2)) + '' + degreeSymbol;
}

function getCurMeasurementSysRoundedDegrees(value) {
    return parseFloat(parseFloat(value).toFixed(2));
}

function getCurMeasurementSysRoundedMass(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return parseFloat((value * measurementSystemToUse.massConversion).toFixed(measurementSystemToUse.massPrecision));
}

function getCurMeasurementSysRoundedPercentage(value) {
    return parseFloat(Globalize.format(value, "n2")); // User Story 17565
}

function getCustomPrecisionRoundedValue(value, precision) {
    //return parseFloat(Globalize.format(value, "n" + precision));
    if(typeof value === 'number') {
        return parseFloat((value).toFixed(precision));
    } else {
        TScMessenger.writeDebugMessage('OfferManager.js, getCustomPrecisionRoundedValue, value is not a number');
    }
}

function getUIFormattedMonthlyDistanceValue(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return Globalize.format((value * measurementSystemToUse.distanceConversion), "n0") + ' ' + measurementSystemToUse.unitMonthlyDistance; // User Story 17565
}

function getUIFormattedMonthsValue(value) {
    return value + " " + config.getTranslationText('255');
}

/**
    * Used to display trips value in Costing
    * @param {number} value - Number to display beside text
    */
function getUIFormattedValuePerMonthValue(value) {
    return config.getTranslationText('4085', [Globalize.format(value, 'n0')]); // User Story 17565
}

/**
    * Used to display trips value in Tolls & Permits Detail form
    * @param {number} value - Number to display beside text
    */
function getUIFormattedTripsPerMonthValue(value) {
    if (value === 1) {
        return config.getTranslationText('4111', [Globalize.format(value, 'n0')]);
    } else {
        return config.getTranslationText('4112', [Globalize.format(value, 'n0')]);
    }
}

function getUIFormattedCurrencyPerVolumeValue(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return currencySymbolMajor.value + Globalize.format(value / measurementSystemToUse.volumeConversion, "n" + getDecimalsMediumPricedItems()) + measurementSystemToUse.unitPerVolume; // User Story 17565
}

function getUIFormattedHighCurrencyValue(value) {
    return currencySymbolMajor.value + Globalize.format(value, "n" + getDecimalsHighPricedItems()); // User Story 17565
}

function getUIFormattedMediumCurrencyValue(value) {
    return currencySymbolMajor.value + Globalize.format(value, "n" + getDecimalsMediumPricedItems()); // User Story 17565
}

function getUIFormattedCurrencyTotalPrice(value) {
    return currencySymbolMajor.value + Globalize.format(value, "n" + getDecimalsHighPricedItems()) + ' ' + config.getTranslationText('758'); // User Story 17565
}

function getUIFormattedCurrencyPerMonthValue(value, decimalPlaces) {
    var decimalPlacesToUse = typeof decimalPlaces === 'number' ? decimalPlaces : getDecimalsHighPricedItems();
    return config.getTranslationText('4198', [currencySymbolMajor.value, Globalize.format(value, "n" + decimalPlacesToUse)]); // User Story 17565
}

function getUIFormattedCurrencyPerTripValue(value) {
    return config.getTranslationText('4084', [currencySymbolMajor.value, Globalize.format(value, "n" + getDecimalsLowPricedItems())]) // User Story 17565
}

function getUIFormattedCurrencyPerMonthValueNoUnit(value) {
    return currencySymbolMajor.value + Globalize.format(value, "n" + getDecimalsHighPricedItems()); // User Story 17565
}

function getUIFormattedPercentageOfTotalPrice(value) {
    return getUIFormattedPercentageSinglePlaceValue(value) + ' ' + config.getTranslationText('759');
}

function getUIFormattedPercentageSinglePlaceValue(value) {
    return Globalize.format(value, "n1") + '%'; // User Story 17565
}

function getUIFormattedPercentageDoublePlaceValue(value) {
    return Globalize.format(value, "n2") + '%'; // User Story 17565
}

function getUIFormattedCurrencyPerYearValue(value) {
    return currencySymbolMajor.value + Globalize.format(value, "n" + getDecimalsHighPricedItems()) + config.getTranslationText('760'); // User Story 17565
}

function getUIFormattedPercentageOfFixedCostValue(value) {
    return getUIFormattedPercentageSinglePlaceValue(value) + ' ' + config.getTranslationText('797');
}

function getUIFormattedVolumePer100KMsValue(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME || offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSPECIFYFUELCONSUMPTIONAS).optionId.value === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
        return Globalize.format(value, "n2") + ' ' + measurementSystemToUse.unitFuelConsumption; // User Story 17565
    } else {
        return Globalize.format(value, "n2") + ' ' + measurementSystemToUse.unitVolumePer100Distance; // User Story 17565
    }
}

function getUIFormattedCurrencyPerDistanceValue(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return Globalize.format(value, "n" + getDecimalsMediumPricedItems()) + ' ' + currencySymbolMajor.value + '/' + measurementSystemToUse.unitDistance; // User Story 17565
}

function getUIFormattedMinorCurrencyPerDistanceValue(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return Globalize.format(value, "n" + getDecimalsMediumPricedItems()) + ' ' + currencySymbolMinor.value + '/' + measurementSystemToUse.unitDistance; // User Story 17565
}

function getUIFormattedCurrencyPerDistanceValueNoUnit(value, doNotMultiply) {
    if (value > 1 || doNotMultiply === true) {
        return currencySymbolMajor.value + Globalize.format(value, "n" + getDecimalsMediumPricedItems()); // User Story 17565
    } else {
        return currencySymbolMajor.value + Globalize.format(value * 100, "n" + getDecimalsHighPricedItems()); // User Story 17565
    }
}

function getUIFormattedCurrencyPerDistanceValueNoUnitDecimals(value) {
    return currencySymbolMajor.value + Globalize.format(value, "n" + getDecimalsMediumPricedItems()); // User Story 17565
}

function getUIFormattedCurrencySinglePlaceValue(value) {
    return currencySymbolMajor.value + Globalize.format(value, "n1"); // User Story 17565
}

function getUIFormattedCurrencyNoDecimalPlaceValue(value) {
    return currencySymbolMajor.value + Globalize.format(value, "n0"); // User Story 17565
}

function getUIFormattedAreaValue(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return Globalize.format(value * measurementSystemToUse.areaConversion, "n" + measurementSystemToUse.areaPrecision) + ' ' + measurementSystemToUse.unitArea; // User Story 17565
}

function getCurMeasurementSysRoundedArea(value) {
    var measurementSystemToUse = getMeasurementSystemToUse();
    return parseFloat((value * measurementSystemToUse.areaConversion).toFixed(measurementSystemToUse.areaPrecision));
}

function getUIFormattedSpeedValue(value, decimalPlaces, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem),
        decimalsToUse = decimalPlaces !== undefined ? decimalPlaces : measurementSystemToUse.speedPrecision;
    return Globalize.format(value * measurementSystemToUse.speedConversion, "n" + decimalsToUse) + ' ' + measurementSystemToUse.unitSpeed; // User Story 17565
}

function getUIFormattedDistanceValue(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return Globalize.format(value * measurementSystemToUse.distanceConversion, "n1") + ' ' + measurementSystemToUse.unitDistance; // User Story 17565
}

function getUIFormattedDistanceNoDecimalValue(value, doNotConvertValue, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    if (doNotConvertValue === true){
        return Globalize.format(value, "n0") + ' ' + measurementSystemToUse.unitDistance; // User Story 17565
    } else {
        return Globalize.format(value * measurementSystemToUse.distanceConversion, "n0") + ' ' + measurementSystemToUse.unitDistance; // User Story 17565
    }
}

function getUIFormattedMinorMassPerDistanceValue(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem),
        convertedValue = value;
    if (measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUK || measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUS) {
        convertedValue = value / measurementSystemToUse.distanceConversion;
    }
    return Globalize.format(convertedValue, "n0") + ' ' + measurementSystemToUse.unitMassMinor + '/' + measurementSystemToUse.unitDistance; // User Story 17565
}

function getUIFormattedVolumePerDistanceValue(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem),
        convertedValue = value,
        unit = measurementSystemToUse.unitVolumePer100Distance;
    if (measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUK || measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUS) {
        convertedValue = 100 / value * measurementSystemToUse.distanceConversion / measurementSystemToUse.volumeConversion;
        unit = measurementSystemToUse.unitVolumePerDistance;
    }
    return Globalize.format(convertedValue, "n2") + ' ' + unit; // User Story 17565
}

// function getUIFormattedFuelConsumptionValue(value, measurementSystem) {
//     var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem),
//         convertedValue = value,
//         unit = measurementSystemToUse.unitVolumePer100Distance;
//     if (measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUK || measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUS) {
//         if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
//             //convertedValue = 100 / value;
//             unit = measurementSystemToUse.unitFuelConsumption;
//         }
//     } else {
//         if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
//             //convertedValue = 100 / value;
//             unit = measurementSystemToUse.unitFuelConsumption;
//         }
//     }
//     return Globalize.format(convertedValue, "n2") + ' ' + unit;
// }

function getUIFormattedFuelConsumptionValue(value, measurementSystem, useValueParam) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem),
        convertedValue,
        unit = measurementSystemToUse.unitVolumePer100Distance;
    if (measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUK || measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUS) {
        if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
            //convertedValue = 100 / value;
            unit = measurementSystemToUse.unitFuelConsumption;
        }
        convertedValue = getFuelConsumptionForImperialMeasurementSystem(measurementSystemToUse, value);
    } else {
        if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
            //convertedValue = 100 / value;
            unit = measurementSystemToUse.unitFuelConsumption;
        }
        convertedValue = getFuelConsumptionForMetricMeasurementSystem(value, useValueParam);
    }
    return Globalize.format(convertedValue, "n2") + ' ' + unit; // User Story 17565
}

function getUIFormattedFuelConsumptionValueNoConversion(value, measurementSystem, useValueParam) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem),
        convertedValue = value,
        unit = measurementSystemToUse.unitVolumePer100Distance;
    if (measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUK || measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUS) {
        if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
            //convertedValue = 100 / value;
            unit = measurementSystemToUse.unitFuelConsumption;
        }
        // convertedValue = getFuelConsumptionForImperialMeasurementSystem(measurementSystemToUse, value);
    } else {
        if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
            //convertedValue = 100 / value;
            unit = measurementSystemToUse.unitFuelConsumption;
        }
        // convertedValue = getFuelConsumptionForMetricMeasurementSystem(value, useValueParam);
    }
    return Globalize.format(convertedValue, "n2") + ' ' + unit; // User Story 17565
}

function getUIFormattedFuelConsumptionValueNoConversionForSlider(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem),
        convertedValue = value,
        unit = measurementSystemToUse.unitVolumePer100Distance;
    if (measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUK || measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUS) {
        if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
            //convertedValue = 100 / value;
            unit = measurementSystemToUse.unitFuelConsumption;
        }
        // convertedValue = getFuelConsumptionForImperialMeasurementSystem(measurementSystemToUse, value);
    } else {
        if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
            //convertedValue = 100 / value;
            unit = measurementSystemToUse.unitFuelConsumption;
        }
        // convertedValue = getFuelConsumptionForMetricMeasurementSystem(value, useValueParam);
    }
    return Globalize.format(convertedValue, "n2") + ' ' + unit; // User Story 17565
}

function getUIFormattedAdblueConsumptionValue(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem),
        convertedValue,
        unit = measurementSystemToUse.unitVolumePer100Distance;
    if (measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUK || measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUS) {
        if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
            //convertedValue = 100 / value;
            unit = measurementSystemToUse.unitFuelConsumption;
        }
        convertedValue = getAdblueConsumptionForImperialMeasurementSystem(value, measurementSystemToUse);
    } else {
        if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
            //convertedValue = 100 / value;
            unit = measurementSystemToUse.unitFuelConsumption;
        }
        convertedValue = getAdblueConsumptionForMetricMeasurementSystem(value);
    }
    return Globalize.format(convertedValue, "n2") + ' ' + unit; // User Story 17565
}

function getUIFormattedAdblueConsumptionValueNoConversion(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem),
        convertedValue = value,
        unit = measurementSystemToUse.unitVolumePer100Distance;
    if (measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUK || measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUS) {
        if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
            //convertedValue = 100 / value;
            unit = measurementSystemToUse.unitFuelConsumption;
        }
        // convertedValue = getFuelConsumptionForImperialMeasurementSystem(measurementSystemToUse, value);
    } else {
        if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
            //convertedValue = 100 / value;
            unit = measurementSystemToUse.unitFuelConsumption;
        }
        convertedValue = getAdblueConsumptionForMetricMeasurementSystem(value);
    }
    return Globalize.format(convertedValue, "n2") + ' ' + unit; // User Story 17565
}

function getUIFormattedPayloadDensityValue(value, payload, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return Globalize.format(value * measurementSystemToUse.massConversion, "n3") + ' ' + measurementSystemToUse.unitMass + '/' + payload.getUnitOfMeasure(); // User Story 17565
}

function getUIFormattedPayloadValue(value, payload) {
    return Globalize.format(value, "n1") + ' ' + payload.getUnitOfMeasure(); // User Story 17565
}

function getUIFormattedPercentageOfFuelValue(value) {
    return config.getTranslationText('4572', [Globalize.format(value, "n1")]); // User Story 17565
}

function getUIFormattedHoursPerMonthValue(value) {
    return config.getTranslationText('4570', [Globalize.format(value, "n0")]); // User Story 17565
}

function getUIFormattedVolumePerHourValue(value) {
    var measurementSystemToUse = getMeasurementSystemToUse();
    var precision = "n2"; // User Story 17565
    if(measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUK || measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUS) {
        precision = "n3"; // User Story 17565
    }
    return measurementSystemToUse.unitVolumePerHour.replace('$S1', Globalize.format(value, precision));
    // return config.getTranslationText('4571', [Globalize.format(value, "n2")]);
}

function getCurMeasurementSysUnitOfMeasure(unitOfMeasureType, unitFormFlag, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    switch (unitOfMeasureType) {
        case config.PAYLOAD_UNIT_OF_MEASURE_TYPES.MASS:
            return measurementSystemToUse.unitMass;
        case config.PAYLOAD_UNIT_OF_MEASURE_TYPES.VOLUME:
            if (unitFormFlag === 'plural') {
                return measurementSystemToUse.unitVolumePlural;
            } else {
                return measurementSystemToUse.unitVolumeSingular;
            }
        case config.PAYLOAD_UNIT_OF_MEASURE_TYPES.CUBIC_VOLUME:
            return measurementSystemToUse.unitCubicVolume;
    }
}

function getCurMeasurementSysUnitOfMeasurePrecision(unitOfMeasureType, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    switch (unitOfMeasureType) {
        case config.PAYLOAD_UNIT_OF_MEASURE_TYPES.MASS:
            return measurementSystemToUse.massPrecision;
        case config.PAYLOAD_UNIT_OF_MEASURE_TYPES.VOLUME:
            return measurementSystemToUse.volumePrecision;
        case config.PAYLOAD_UNIT_OF_MEASURE_TYPES.CUBIC_VOLUME:
            return measurementSystemToUse.unitCubicVolume;
    }
}


function getFuelConsumptionForImperialMeasurementSystem(measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem),
        returnVal,
        value = getActiveOfferCosting().getFuelConsumption(),
        imperialMeasurementSystem = measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUK ? globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUK) : globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUS);
    if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
        // mpg
        // return 100 / getActiveOfferCosting().getFuelConsumption() * imperialMeasurementSystem.distanceConversion / imperialMeasurementSystem.volumeConversion;
        returnVal = 100 / getActiveOfferCosting().getFuelConsumption() * imperialMeasurementSystem.distanceConversion / imperialMeasurementSystem.volumeConversion;
    } else {
        // gal/100mi
        // return 100 / (100 / getActiveOfferCosting().getFuelConsumption() * imperialMeasurementSystem.distanceConversion / imperialMeasurementSystem.volumeConversion);
        returnVal = 100 / (100 / getActiveOfferCosting().getFuelConsumption() * imperialMeasurementSystem.distanceConversion / imperialMeasurementSystem.volumeConversion);
    }
    return returnVal;
}

function getAdblueConsumptionForImperialMeasurementSystem(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem),
        returnVal,
        valueToUse = typeof value === 'number' ? value : getActiveOfferCosting().getFuelConsumption(),
        imperialMeasurementSystem = measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUK ? globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUK) : globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUS);
    if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
        // mpg
        // return 100 / getActiveOfferCosting().getFuelConsumption() * imperialMeasurementSystem.distanceConversion / imperialMeasurementSystem.volumeConversion;
        returnVal = valueToUse !== 0 ? 100 / valueToUse * imperialMeasurementSystem.distanceConversion / imperialMeasurementSystem.volumeConversion : 0;
    } else {
        // gal/100mi
        // return 100 / (100 / getActiveOfferCosting().getFuelConsumption() * imperialMeasurementSystem.distanceConversion / imperialMeasurementSystem.volumeConversion);
        returnVal = valueToUse !== 0 ? 100 / (100 / valueToUse * imperialMeasurementSystem.distanceConversion / imperialMeasurementSystem.volumeConversion) : 0;
    }
    return returnVal;
}

function getFuelConsumptionForImperialMeasurementSystemWithValueParam(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem),
        returnVal = 0,
        imperialMeasurementSystem = measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUK ? globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUK) : globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUS);
    if(typeof value === 'number' && value > 0) {
        if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
            // mpg
            // return 100 / getActiveOfferCosting().getFuelConsumption() * imperialMeasurementSystem.distanceConversion / imperialMeasurementSystem.volumeConversion;
            returnVal = 100 / value * imperialMeasurementSystem.distanceConversion / imperialMeasurementSystem.volumeConversion;
        } else {
            // gal/100mi
            // return 100 / (100 / getActiveOfferCosting().getFuelConsumption() * imperialMeasurementSystem.distanceConversion / imperialMeasurementSystem.volumeConversion);
            returnVal = 100 / (100 / value * imperialMeasurementSystem.distanceConversion / imperialMeasurementSystem.volumeConversion);
        }
    }
    
    return returnVal;
}

function getFuelConsumptionForMetricMeasurementSystem(value, useValueParam) {
    var valueToGet = useValueParam === true ? value : getActiveOfferCosting().getFuelConsumption();
    if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
        if(valueToGet === 0) {
            return 0;
        }
        // return 100 / getActiveOfferCosting().getFuelConsumption();
        return 100 / valueToGet;
    } else {
        // return getActiveOfferCosting().getFuelConsumption();
        return valueToGet;
    }
}

function getAdblueConsumptionForMetricMeasurementSystem(value) {
    var valueToGet = typeof value === 'number' ? value : getActiveOfferCosting().getFuelConsumption();
    if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
        // return 100 / getActiveOfferCosting().getFuelConsumption();
        if(valueToGet === 0) {
            return 0;
        }
        return 100 / valueToGet;
    } else {
        // return getActiveOfferCosting().getFuelConsumption();
        return valueToGet;
    }
}

function setFuelConsumptionForMetricMeasurementSystem(value) {
    var measurementSystemToUse = getMeasurementSystemToUse();
    if (measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.METRIC) {
        var attrObvGroup = getAttributeGroup(config.OBJECT_TYPES.COSTING + '.' + config.VALUE_TYPE.COSTINGFUELCONSUMPTION);
        //if (attrObvGroup.override() === true) {
        //    //var newValue = 100 / value;
        //    //getActiveOfferCosting().setFuelConsumption(newValue);
        //    getActiveOfferCosting().setFuelConsumption(value);
        //}
        var newValue = value;
        if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME || offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSPECIFYFUELCONSUMPTIONAS).optionId.value === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
            // km/l
            newValue = 100 / value;
            getActiveOfferCosting().setFuelConsumption(newValue);
        } else {
            // l/100km
            getActiveOfferCosting().setFuelConsumption(newValue);
        }
    }
}

function getAdblueConsumptionForImperialMeasurementSystemValue(value) {
    var measurementSystemToUse = getMeasurementSystemToUse(),
        convertedValue,
        imperialMeasurementSystem = measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUK ? globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUK) : globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUS),
        unit = imperialMeasurementSystem.unitFuelConsumption;
    if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
        convertedValue = value !== 0 ? 100 / value * imperialMeasurementSystem.distanceConversion / imperialMeasurementSystem.volumeConversion : 0;
    } else {
        convertedValue = value !== 0 ? 100 / (100 / value * imperialMeasurementSystem.distanceConversion / imperialMeasurementSystem.volumeConversion) : 0;
        unit = imperialMeasurementSystem.unitVolumePer100Distance;
    }

    return Globalize.format(convertedValue, "n2") + ' ' + unit; // User Story 17565
}

function getFuelConsumptionForImperialMeasurementSystemValue(value) {
    var measurementSystemToUse = getMeasurementSystemToUse(),
        convertedValue,
        imperialMeasurementSystem = measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUK ? globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUK) : globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUS),
        unit = imperialMeasurementSystem.unitFuelConsumption;
    if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
        convertedValue = value !== 0 ? 100 / value * imperialMeasurementSystem.distanceConversion / imperialMeasurementSystem.volumeConversion : 0;
    } else {
        convertedValue = value !== 0 ? 100 / (100 / value * imperialMeasurementSystem.distanceConversion / imperialMeasurementSystem.volumeConversion) : 0;
        unit = imperialMeasurementSystem.unitVolumePer100Distance;
    }

    return Globalize.format(convertedValue, "n2") + ' ' + unit; // User Story 17565
}

function setFuelConsumptionForImperialMeasurementSystem(value) {
    var measurementSystemToUse = getMeasurementSystemToUse(),
        imperialMeasurementSystem = measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUK ? globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUK) : globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUS);
    if (measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUK || measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUS) {
        var attrObvGroup = getAttributeGroup(config.OBJECT_TYPES.COSTING + '.' + config.VALUE_TYPE.COSTINGFUELCONSUMPTIONIMPERIAL);
        //if (attrObvGroup.override() === true) {
        var newValue;
        if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
            newValue = 100 / (value / imperialMeasurementSystem.distanceConversion * imperialMeasurementSystem.volumeConversion);
        } else {
            newValue = 100 / (100 / (value / imperialMeasurementSystem.distanceConversion * imperialMeasurementSystem.volumeConversion));
        }
        getActiveOfferCosting().setFuelConsumption(newValue);
        //}
    }
}

function getFuelConsumptionMin(measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem),
        value = getActiveOfferCosting().getFuelConsumptionMin();
    if (measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUK || measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUS) {
        value = getActiveOfferCosting().getFuelConsumptionMin() < getActiveOfferCosting().getFuelConsumptionMax() ? getActiveOfferCosting().getFuelConsumptionMin() : getActiveOfferCosting().getFuelConsumptionMax();
    }
    return value;
}

function getFuelConsumptionMax(measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem),
        value = getActiveOfferCosting().getFuelConsumptionMax();
    if (measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUK || measurementSystemToUse.id === config.MEASUREMENT_SYSTEM.IMPERIALUS) {
        value = getActiveOfferCosting().getFuelConsumptionMax() > getActiveOfferCosting().getFuelConsumptionMin() ? getActiveOfferCosting().getFuelConsumptionMax() : getActiveOfferCosting().getFuelConsumptionMin();
    }
    return value;
}

function getValue(valueType, params) {
    var valToReturn = null;
    switch (valueType) {
        case config.VALUE_TYPE.PERMISSIBLETOTALAXLEMASS:
            valToReturn = rigOps.getVehicleTotalPermissible();
            break;
        case config.VALUE_TYPE.PERMISSIBLEFRONTAXLEMASS:
            valToReturn = rigOps.getVehicleFrontPermissible();
            break;
        case config.VALUE_TYPE.PERMISSIBLEREARAXLEMASS:
            valToReturn = rigOps.getVehicleRearPermissible();
            break;
        case config.VALUE_TYPE.TAREFRONT:
            valToReturn = rigOps.getVehicleOnlyFrontTare();
            break;
        case config.VALUE_TYPE.TAREREAR:
            valToReturn = rigOps.getVehicleOnlyRearTare();
            break;
        case config.VALUE_TYPE.HORIZONTALCOG:
            valToReturn = rigOps.getVehicleHorizontalCOG();
            break;
        case config.VALUE_TYPE.VERTICALCOG:
            valToReturn = rigOps.getVehicleVerticalCOG();
            break
        case config.VALUE_TYPE.LATERALCOG:
            valToReturn = rigOps.getVehicleLateralCOG();
            break;
        case config.VALUE_TYPE.OVERALLHORIZONTALCOG:
            valToReturn = rigOps.getOverallHorizontalCOG();
            break;
        case config.VALUE_TYPE.OVERALLVERTICALCOG:
            valToReturn = rigOps.getOverallVerticalCOG();
            break;
        case config.VALUE_TYPE.OVERALLLATERALCOG:
            valToReturn = rigOps.getOverallLateralCOG();
            break;
        case config.VALUE_TYPE.VEHICLEFRONTAXLEOVERLOAD:
            valToReturn = rigOps.getVehicleFrontAxleOverload();
            break;
        case config.VALUE_TYPE.VEHICLEREARAXLEOVERLOAD:
            valToReturn = rigOps.getVehicleRearAxleOverload();
            break;
        case config.VALUE_TYPE.VEHICLETOTALOVERLOAD:
            valToReturn = rigOps.getVehicleTotalOverload();
            break;
        case config.VALUE_TYPE.AXLEOVERLOADPUSHER:
            valToReturn = rigOps.getPusherAxleOverload(params.axleNumber, params.pusherNumber);
            break;
        case config.VALUE_TYPE.AXLEOVERLOADTAG:
            valToReturn = rigOps.getTagAxleOverload(params.axleNumber, params.tagNumber);
            break;
        case config.VALUE_TYPE.AXLESOVERLOADREARREAR:
            valToReturn = rigOps.getRearRearAxlesOverload();
            break;
        case config.VALUE_TYPE.PERMISSIBLECOMBINATIONTOTAL:
            valToReturn = rigOps.getCombinationTotalPermissible();
            break;
    }
    if (valToReturn !== null) {
        return utils.returnValueOrZeroIfNanOrInfinity(valToReturn);
    }
    return null;
}

//function setToolsIncluded(id, elementId, previousValue) {
//    getYesNoNotSpecifiedOptions().forEach(function (option) {
//        if (id === option.id) {
//            toolsIncludedOption(option);
//            toolIncludedId(option.id);
//        }
//    });
//}

//function setSpareWheelIncluded(id, elementId, previousValue) {
//    getYesNoNotSpecifiedOptions().forEach(function (option) {
//        if (id === option.id) {
//            spareWheelIncludedOption(option);
//            spareWheelIncludedId(option.id);
//        }
//    });
//}

//function setFrontRoadFriendlySuspension(id, elementId, previousValue) {
//    getYesNoOptions().forEach(function (option) {
//        if (id === option.id) {
//            frontRoadFriendlySuspensionOption(option);
//            frontRoadFriendlySuspensionId(option.id);
//        }
//    });
//}

//function setRearRoadFriendlySuspension(id, elementId, previousValue) {
//    getYesNoOptions().forEach(function (option) {
//        if (id === option.id) {
//            rearRoadFriendlySuspensionOption(option);
//            rearRoadFriendlySuspensionId(option.id);
//        }
//    });
//}

//function setRolloverProtection(id, elementId, previousValue) {
//    getYesNoOptions().forEach(function (option) {
//        if (id === option.id) {
//            rolloverProtectionOption(option);
//            rolloverProtectionId(option.id);
//        }
//    });
//}

//function setFrontUnderrunProtection(id, elementId, previousValue) {
//    getYesNoOptions().forEach(function (option) {
//        if (id === option.id) {
//            frontUnderrunProtectionOption(option);
//            frontUnderrunProtectionId(option.id);
//        }
//    });
//}

//function getWorstOverloadingStatus() {

//    var tempTrailer1 = getActiveOffer().getRig().getTrailer1();

//    var frontAxleOverload, totalOverload, rearAxleOverload, trailerFrontOverload, trailerRearOverload;
//    var grossFrontTableHighLightColour, grossRearTableHighLightColour, grossTrailerRearHighlightColour, grossTotalTableHighLightColour;

//    if (tempTrailer1 === null) {
//        frontAxleOverload = offerManager.getValue(config.VALUE_TYPE.VEHICLEFRONTAXLEOVERLOAD);
//        totalOverload = offerManager.getValue(config.VALUE_TYPE.VEHICLETOTALOVERLOAD);
//        rearAxleOverload = offerManager.getValue(config.VALUE_TYPE.VEHICLEREARAXLEOVERLOAD);

//    } else if (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
//        frontAxleOverload = rigOps.getVehicleAndSemiOrInterlinkVehicleFrontAxleOverload();
//        totalOverload = rigOps.getVehicleAndSemiOrInterlinkTotalOverload();
//        rearAxleOverload = rigOps.getVehicleAndSemiOrInterlinkVehicleRearAxleOverload();
//        trailerRearOverload = rigOps.getVehicleAndSemiOrInterlinkTrailerRearAxleOverload();

//    } else if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
//        frontAxleOverload = offerManager.getValue(config.VALUE_TYPE.VEHICLEFRONTAXLEOVERLOAD);
//        totalOverload = rigOps.getVehicleAndDrawbarTotalOverload();
//        rearAxleOverload = offerManager.getValue(config.VALUE_TYPE.VEHICLEREARAXLEOVERLOAD);
//        trailerFrontOverload = rigOps.getVehicleAndDrawbarTrailerFrontAxleOverload();
//        trailerRearOverload = rigOps.getVehicleAndDrawbarTrailerRearAxleOverload();

//    } else if (tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
//        frontAxleOverload = rigOps.getVehicleAndPupVehicleFrontAxleOverload();
//        totalOverload = rigOps.getVehicleAndPupTotalOverload();
//        rearAxleOverload = rigOps.getVehicleAndPupVehicleRearAxleOverload();
//        trailerRearOverload = rigOps.getVehicleAndPupTrailerRearAxleOverload();

//    } else {
//        //#unfinished, if the code goes in here it mean we need to support another combination above e.g. vehicle + pup trailer
//        return config.OVERLOADING_STATUS.OVERLOADED;
//    }

//    grossFrontTableHighLightColour = getTableCellHighlightColour(config.TABLE_COLUMNS.FRONT, frontAxleOverload, 'GROSS');
//    if (grossFrontTableHighLightColour === config.TABLE_HIGHLIGHT_COLOURS.FAIL) {
//        return config.OVERLOADING_STATUS.OVERLOADED;
//    } else if (grossFrontTableHighLightColour === config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING) {
//        return config.OVERLOADING_STATUS.UNDERLOADED;
//    } else if (grossFrontTableHighLightColour === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
//        return config.OVERLOADING_STATUS.OVERLOADED_WITHIN_TOLERANCES;
//    }

//    grossRearTableHighLightColour = getTableCellHighlightColour(config.TABLE_COLUMNS.REAR, rearAxleOverload, 'GROSS');
//    if (grossRearTableHighLightColour === config.TABLE_HIGHLIGHT_COLOURS.FAIL) {
//        return config.OVERLOADING_STATUS.OVERLOADED;
//    } else if (grossRearTableHighLightColour === config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING) {
//        return config.OVERLOADING_STATUS.UNDERLOADED;
//    } else if (grossRearTableHighLightColour === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
//        return config.OVERLOADING_STATUS.OVERLOADED_WITHIN_TOLERANCES;
//    }

//    if (tempTrailer1 !== null) {

//        if (trailerFrontOverload !== undefined) {
//            grossTrailerFrontHighlightColour = getTableCellHighlightColour(config.TABLE_COLUMNS.TRAILER_FRONT, trailerFrontOverload, 'GROSS');
//            if (grossTrailerFrontHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.FAIL) {
//                return config.OVERLOADING_STATUS.OVERLOADED;
//            } else if (grossTrailerFrontHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING) {
//                return config.OVERLOADING_STATUS.UNDERLOADED;
//            } else if (grossTrailerFrontHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
//                return config.OVERLOADING_STATUS.OVERLOADED_WITHIN_TOLERANCES;
//            }
//        }

//        grossTrailerRearHighlightColour = getTableCellHighlightColour(config.TABLE_COLUMNS.TRAILER_REAR, trailerRearOverload, 'GROSS');
//        if (grossTrailerRearHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.FAIL) {
//            return config.OVERLOADING_STATUS.OVERLOADED;
//        } else if (grossTrailerRearHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING) {
//            return config.OVERLOADING_STATUS.UNDERLOADED;
//        } else if (grossTrailerRearHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
//            return config.OVERLOADING_STATUS.OVERLOADED_WITHIN_TOLERANCES;
//        }


//    }

//    grossTotalTableHighLightColour = getTableCellHighlightColour(config.TABLE_COLUMNS.TOTAL, totalOverload, 'GROSS');
//    if (grossTotalTableHighLightColour === config.TABLE_HIGHLIGHT_COLOURS.FAIL) {
//        return config.OVERLOADING_STATUS.OVERLOADED;
//    } else if (grossTotalTableHighLightColour === config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING) {
//        return config.OVERLOADING_STATUS.UNDERLOADED;
//    } else if (grossTotalTableHighLightColour === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
//        return config.OVERLOADING_STATUS.OVERLOADED_WITHIN_TOLERANCES;
//    }

//    return config.OVERLOADING_STATUS.OK;
//}

function getOverloadingColoursForLegislation(encodedLegislationId) {
    
    //if (!overloadingColourInstances[encodedLegislationId]) {
    //    generateOverloadingColColoursForActiveLegislations();
    //}
    //return overloadingColourInstances[encodedLegislationId]
    return getDataForActiveLegislations().overloadingColourInstances[encodedLegislationId];
}

function getBridgeFormulaPermissibleOverloadingColoursForLegislation(encodedLegislationId) {
    return getDataForActiveLegislations().bridgeFormulaPermissibleOverloadingColourInstances[encodedLegislationId];
}

function getSteerAxleMinimumOverloadingColoursForLegislation(encodedLegislationId) {
    return getDataForActiveLegislations().steerAxlesMinimumOverloadingColourInstances[encodedLegislationId];
}

function getOverloadingColoursForLegislations() {


    return getDataForActiveLegislations().overloadingColourInstances;
}
//function generateOverloadingColColoursForActiveLegislations() {
    
    
//    //overloadingColourInstances[_legislations[0].id] = generateOverloadingColColours(_legislations[0].id, evaluateLegislation, 'GROSS');
//    _legislations.forEach(function (legislationDetails) {

//        var evaluateLegislationWrapper = function (attributeName) {
//            return evaluateLegislation(attributeName, legislationDetails)
//        };
//        rigOps.setLegislationEvaluationFunction(evaluateLegislationWrapper);
//        //clearLegislationQuickLookup();

//        rigOps.maximisePayloads();

//        overloadingColourInstances[legislationDetails.id] = generateOverloadingColColours(legislationDetails.id, evaluateLegislationWrapper, 'GROSS');

//    });
//}



function generateOverloadingColColours(permissibleVals, rigOpsToUse, encodedLegislationId, evaluationFunc, rowName) {

    if (!permissibleVals) {
        permissibleVals = rigOpsToUse.getCombinationPermissibleVals();
    }
    //rigOpsToUse.setLegislationEvaluationFunction(evaluationFunc);

    var tempVehicle = rigOpsToUse.getActiveRig().getVehicle(), tempTrailer1 = rigOpsToUse.getActiveRig().getTrailer1(), tempTrailer2 = rigOpsToUse.getActiveRig().getTrailer2();

    var frontAxleCompareVal, totalCompareVal, rearAxleCompareVal, trailerFrontCompareVal, trailerRearCompareVal, trailer1CompareVal, trailer2CompareVal;
    var frontAxleRefVal, totalRefVal, rearAxleRefVal, trailerFrontRefVal, trailerRearRefVal, trailer1RefVal, trailer2RefVal;
    var grossFrontTableHighLightColour, grossRearTableHighLightColour, grossTrailerRearHighlightColour, grossTotalTableHighLightColour;

    var colHighlightColours = {};

    if (tempTrailer1 === null && tempTrailer2 === null) {
        frontAxleCompareVal = rigOpsToUse.getVehicleOnlyFrontGross();
        //frontAxleRefVal = rigOpsToUse.getVehicleFrontPermissible();
        frontAxleRefVal = permissibleVals.vehicleFrontPermissible;
        totalCompareVal = getActiveOffer().getRig().getVehicle().getGrossTotal();
        //totalRefVal = rigOpsToUse.getVehicleTotalPermissible();
        totalRefVal = permissibleVals.combinationTotalPermissible;
        rearAxleCompareVal = rigOpsToUse.getVehicleOnlyRearGross();
        //rearAxleRefVal = rigOpsToUse.getVehicleRearPermissible();
        rearAxleRefVal = permissibleVals.vehicleRearPermissible;
    } else if (tempTrailer2 === null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
        //frontAxleOverload = rigOpsToUse.getVehicleAndSemiOrInterlinkVehicleFrontAxleOverload();
        frontAxleCompareVal = rigOpsToUse.getVehicleAndSemiOrInterlinkVehicleFrontAxleGross();
        frontAxleRefVal = permissibleVals.vehicleFrontPermissible;
        //totalOverload = rigOpsToUse.getVehicleAndSemiOrInterlinkTotalOverload();
        totalCompareVal = getActiveOffer().getRig().getVehicle().getGrossTotal() + getActiveOffer().getRig().getTrailer1().getUnladenTotal() + getActiveOffer().getRig().getTrailer1().getPayloadTotal();
        totalRefVal = permissibleVals.combinationTotalPermissible;
        //rearAxleOverload = rigOpsToUse.getVehicleAndSemiOrInterlinkVehicleRearAxleOverload();
        rearAxleCompareVal = rigOpsToUse.getVehicleAndSemiOrInterlinkVehicleRearAxleGross();
        rearAxleRefVal = permissibleVals.vehicleRearPermissible;
        //trailerRearOverload = rigOpsToUse.getVehicleAndSemiOrInterlinkTrailerRearAxleOverload();
        trailerRearCompareVal = rigOpsToUse.getVehicleAndSemiOrInterlinkTrailerRearAxleGross();
        trailerRearRefVal = permissibleVals.trailer1PermissibleRear;

    } else if (tempTrailer2 === null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
        //frontAxleOverload = offerManager.getValue(config.VALUE_TYPE.VEHICLEFRONTAXLEOVERLOAD);
        frontAxleCompareVal = rigOpsToUse.getVehicleOnlyFrontGross();
        frontAxleRefVal = permissibleVals.vehicleFrontPermissible;
        //totalOverload = rigOpsToUse.getVehicleAndDrawbarTotalOverload();
        totalCompareVal = getActiveOffer().getRig().getVehicle().getGrossTotal() + getActiveOffer().getRig().getTrailer1().getUnladenTotal() + getActiveOffer().getRig().getTrailer1().getPayloadTotal();
        totalRefVal = permissibleVals.combinationTotalPermissible;
        //rearAxleOverload = offerManager.getValue(config.VALUE_TYPE.VEHICLEREARAXLEOVERLOAD);
        rearAxleCompareVal = rigOpsToUse.getVehicleOnlyRearGross();
        rearAxleRefVal = permissibleVals.vehicleRearPermissible;
        //trailerFrontOverload = rigOpsToUse.getVehicleAndDrawbarTrailerFrontAxleOverload();
        trailerFrontCompareVal = getActiveOffer().getRig().getTrailer1().getUnladenFront() + getActiveOffer().getRig().getTrailer1().getPayloadFront();
        trailerFrontRefVal = permissibleVals.trailer1PermissibleFront;
        //trailerRearOverload = rigOpsToUse.getVehicleAndDrawbarTrailerRearAxleOverload();
        trailerRearCompareVal = getActiveOffer().getRig().getTrailer1().getUnladenRear() + getActiveOffer().getRig().getTrailer1().getPayloadRear();
        trailerRearRefVal = permissibleVals.trailer1PermissibleRear;

    } else if (tempTrailer2 === null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
        //frontAxleOverload = rigOpsToUse.getVehicleAndPupVehicleFrontAxleOverload();
        frontAxleCompareVal = rigOpsToUse.getVehicleOnlyFrontGross() + rigOpsToUse.getVehicleAndPupTareTransferOntoVehicleFrontAxle() + rigOpsToUse.getVehicleAndPupPayloadTransferOntoVehicleFrontAxle();
        frontAxleRefVal = permissibleVals.vehicleFrontPermissible;
        //totalOverload = rigOpsToUse.getVehicleAndPupTotalOverload();
        totalCompareVal = getActiveOffer().getRig().getVehicle().getGrossTotal() + getActiveOffer().getRig().getTrailer1().getUnladenTotal() + getActiveOffer().getRig().getTrailer1().getPayloadTotal();
        totalRefVal = permissibleVals.combinationTotalPermissible;
        //rearAxleOverload = rigOpsToUse.getVehicleAndPupVehicleRearAxleOverload();
        rearAxleCompareVal = rigOpsToUse.getVehicleOnlyRearGross() + rigOpsToUse.getVehicleAndPupTareTransferOntoVehicleRearAxle() + rigOpsToUse.getVehicleAndPupPayloadTransferOntoVehicleRearAxle();
        rearAxleRefVal = permissibleVals.vehicleRearPermissible;
        //trailerRearOverload = rigOpsToUse.getVehicleAndPupTrailerRearAxleOverload();
        trailerRearCompareVal = getActiveOffer().getRig().getTrailer1().getUnladenRear() + getActiveOffer().getRig().getTrailer1().getPayloadRear();
        trailerRearRefVal = permissibleVals.trailer1PermissibleRear;

    } else if (tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
        //frontAxleOverload = applyRoundingForGrossOverload(rigOpsToUse.getVehicleAndInterlinkAndSemiVehicleFrontAxleOverload());
        frontAxleCompareVal = rigOpsToUse.getCombinationVehicleFrontGross();
        frontAxleRefVal = permissibleVals.vehicleFrontPermissible;
        //totalOverload = applyRoundingForGrossOverload(utils.returnValueOrZeroIfNanOrInfinity(rigOpsToUse.getVehicleAndInterlinkAndSemiTotalOverload()));
        totalCompareVal = rigOpsToUse.getCombinationGrossTotal();
        totalRefVal = permissibleVals.combinationTotalPermissible;
        //rearAxleOverload = applyRoundingForGrossOverload(rigOpsToUse.getVehicleAndInterlinkAndSemiVehicleRearAxleOverload());
        rearAxleCompareVal = rigOpsToUse.getCombinationVehicleRearGross();
        rearAxleRefVal = permissibleVals.vehicleRearPermissible;
        //trailer1Overload = applyRoundingForGrossOverload(rigOpsToUse.getVehicleAndInterlinkAndSemiTrailer1RearAxleOverload());
        trailer1CompareVal = tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear() + rigOpsToUse.getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1RearAxle() + rigOpsToUse.getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoTrailer1RearAxle();
        trailer1RefVal = permissibleVals.trailer1PermissibleRear;
        //trailer2Overload = applyRoundingForGrossOverload(rigOpsToUse.getVehicleAndInterlinkAndSemiTrailer2RearAxleOverload());
        trailer2CompareVal = tempTrailer2.getUnladenRear() + tempTrailer2.getPayloadRear();
        trailer2RefVal = permissibleVals.trailer2PermissibleRear;
    } else {
        //#unfinished, if the code goes in here it mean we need to support another combination above
        return {};
    }
    if (tempVehicle.getAxlesHolder().getHasPusherOrTagAxleDown()) {
        var rearConnectedCalculated = false;
        var pusherCounter = 1, tagCounter = 1, tagRearAxlePos = tempVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR) - tempVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.TAG) + 1;
        tempVehicle.getAxlesHolder().getAxles().forEach(function (axle, index) {
            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
                var pusherColName = config.TABLE_COLUMNS.PUSHER + '_' + pusherCounter + '_' + axle.getNumber();
                var pusherCompareVal = tempVehicle.getPusherOrTagRearAxleGross(axle.getNumber(), rigOpsToUse.getCombinationVehicleRearGross());
                var pusherRefVal = permissibleVals['vehiclePusher' + pusherCounter];//rigOpsToUse.getPusherOrTagPermissibleVal.call(rigOpsToUse, axle.getNumber(), pusherCounter, config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);

                colHighlightColours[pusherColName] = getTableCellHighlightColour(rigOpsToUse, encodedLegislationId, evaluationFunc, pusherColName, pusherCompareVal, pusherRefVal);
                pusherCounter++;
            } else if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                var tagColName = config.TABLE_COLUMNS.TAG + '_' + tagRearAxlePos + '_' + axle.getNumber();
                var tagCompareVal = tempVehicle.getPusherOrTagRearAxleGross(axle.getNumber(), rigOpsToUse.getCombinationVehicleRearGross());
                var tagRefVal = permissibleVals['vehicleTag' + tagCounter];//rigOpsToUse.getPusherOrTagPermissibleVal.call(rigOpsToUse, axle.getNumber(), tagCounter, config.VEHICLE_AXLE_POSITION_TYPES.TAG);

                colHighlightColours[tagColName] = getTableCellHighlightColour(rigOpsToUse, encodedLegislationId, evaluationFunc, tagColName, tagCompareVal, tagRefVal);
                tagCounter++;
                tagRearAxlePos++;
            } else if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR && rearConnectedCalculated === false) {
                rearConnectedCalculated = true;
                var rearConnectedColName = config.TABLE_COLUMNS.REAR_CONNECTED;
                var rearConnectedCompareVal = tempVehicle.getRearRearAxlesDistribution({ rearMassVal: rigOpsToUse.getCombinationVehicleRearGross() });
                var rearConnectedRefVal = permissibleVals.vehicleRearRearOnly;//rigOpsToUse.getRearRearAxlePermissibleVal();

                colHighlightColours[rearConnectedColName] = getTableCellHighlightColour(rigOpsToUse, encodedLegislationId, evaluationFunc, rearConnectedColName, rearConnectedCompareVal, rearConnectedRefVal);
            }
        });
    }
    colHighlightColours[config.TABLE_COLUMNS.FRONT] = getTableCellHighlightColour(rigOpsToUse, encodedLegislationId, evaluationFunc, config.TABLE_COLUMNS.FRONT, frontAxleCompareVal, frontAxleRefVal, rowName);
    colHighlightColours[config.TABLE_COLUMNS.REAR] = getTableCellHighlightColour(rigOpsToUse, encodedLegislationId, evaluationFunc, config.TABLE_COLUMNS.REAR, rearAxleCompareVal, rearAxleRefVal, rowName);
    if (tempTrailer1 !== null && tempTrailer2 === null) {
        if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
            colHighlightColours[config.TABLE_COLUMNS.TRAILER_FRONT] = getTableCellHighlightColour(rigOpsToUse, encodedLegislationId, evaluationFunc, config.TABLE_COLUMNS.TRAILER_FRONT, trailerFrontCompareVal, trailerFrontRefVal, rowName);
        }
        colHighlightColours[config.TABLE_COLUMNS.TRAILER_REAR] = getTableCellHighlightColour(rigOpsToUse, encodedLegislationId, evaluationFunc, config.TABLE_COLUMNS.TRAILER_REAR, trailerRearCompareVal, trailerRearRefVal, rowName);
        
    }else if (tempTrailer2 !== null) {
        
        colHighlightColours[config.TABLE_COLUMNS.TRAILER_1] = getTableCellHighlightColour(rigOpsToUse, encodedLegislationId, evaluationFunc, config.TABLE_COLUMNS.TRAILER_1, trailer1CompareVal, trailer1RefVal, rowName);
        colHighlightColours[config.TABLE_COLUMNS.TRAILER_2] = getTableCellHighlightColour(rigOpsToUse, encodedLegislationId, evaluationFunc, config.TABLE_COLUMNS.TRAILER_2, trailer2CompareVal, trailer2RefVal, rowName);
    }
    colHighlightColours[config.TABLE_COLUMNS.TOTAL] = getTableCellHighlightColour(rigOpsToUse, encodedLegislationId, evaluationFunc, config.TABLE_COLUMNS.TOTAL, totalCompareVal, totalRefVal, rowName);
        
        return colHighlightColours;
}

function generateBridgeFormulaPermissibleOverloadingColColours(bridgeRegulationVals, rigOpsToUse, encodedLegislationId, evaluationFunc, rowName) {

   
    //rigOpsToUse.setLegislationEvaluationFunction(evaluationFunc);

    var tempVehicle = rigOpsToUse.getActiveRig().getVehicle(), tempTrailer1 = rigOpsToUse.getActiveRig().getTrailer1(), tempTrailer2 = rigOpsToUse.getActiveRig().getTrailer2();

    if (!bridgeRegulationVals) {
        bridgeRegulationVals = {};
        bridgeRegulationVals[tempVehicle.getChassisObjectName()] = rigOpsToUse.generateBridgeFormulaPermissibleValues(tempVehicle.getChassisObjectName());
        if(tempTrailer1 !== null) {
            bridgeRegulationVals[tempTrailer1.getChassisObjectName()] = rigOpsToUse.generateBridgeFormulaPermissibleValues(tempTrailer1.getChassisObjectName());
            if(tempTrailer2 !== null) {
                bridgeRegulationVals[tempTrailer2.getChassisObjectName()] = rigOpsToUse.generateBridgeFormulaPermissibleValues(tempTrailer2.getChassisObjectName());
                
            }   
        }
    }

    var frontAxleCompareVal, totalCompareVal, rearAxleCompareVal, trailerFrontCompareVal, trailerRearCompareVal, trailer1CompareVal, trailer2CompareVal;
    var frontAxleRefVal, totalRefVal, rearAxleRefVal, trailerFrontRefVal, trailerRearRefVal, trailer1RefVal, trailer2RefVal;
    var grossFrontTableHighLightColour, grossRearTableHighLightColour, grossTrailerRearHighlightColour, grossTotalTableHighLightColour;

    var colHighlightColours = {};
    var hasPusherOrTagDown = tempVehicle.getAxlesHolder().getHasPusherOrTagAxleDown();
    if (tempTrailer1 === null && tempTrailer2 === null) {
        frontAxleCompareVal = rigOpsToUse.getVehicleOnlyFrontGross();
        //frontAxleRefVal = rigOpsToUse.getVehicleFrontPermissible();
        frontAxleRefVal = bridgeRegulationVals[tempVehicle.getChassisObjectName()].frontAxleGroupBridgeFormulaPermissible;
        totalCompareVal = getActiveOffer().getRig().getVehicle().getGrossTotal();
        //totalRefVal = rigOpsToUse.getVehicleTotalPermissible();
        totalRefVal = bridgeRegulationVals[tempVehicle.getChassisObjectName()].combinationTotalBridgeOrRegulationVal;
        rearAxleCompareVal = rigOpsToUse.getVehicleOnlyRearGross();
        //rearAxleRefVal = rigOpsToUse.getVehicleRearPermissible();
        rearAxleRefVal = hasPusherOrTagDown ? bridgeRegulationVals[tempVehicle.getChassisObjectName()].vehicleRearTotalBridgeOrRegulationVal : bridgeRegulationVals[tempVehicle.getChassisObjectName()].rearAxleBridgeFormulaPermissible;
    } else if (tempTrailer2 === null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
        //frontAxleOverload = rigOpsToUse.getVehicleAndSemiOrInterlinkVehicleFrontAxleOverload();
        frontAxleCompareVal = rigOpsToUse.getVehicleAndSemiOrInterlinkVehicleFrontAxleGross();
        frontAxleRefVal = bridgeRegulationVals[tempVehicle.getChassisObjectName()].frontAxleGroupBridgeFormulaPermissible;
        //totalOverload = rigOpsToUse.getVehicleAndSemiOrInterlinkTotalOverload();
        totalCompareVal = getActiveOffer().getRig().getVehicle().getGrossTotal() + getActiveOffer().getRig().getTrailer1().getUnladenTotal() + getActiveOffer().getRig().getTrailer1().getPayloadTotal();
        totalRefVal = bridgeRegulationVals[tempVehicle.getChassisObjectName()].combinationTotalBridgeOrRegulationVal;
        //rearAxleOverload = rigOpsToUse.getVehicleAndSemiOrInterlinkVehicleRearAxleOverload();
        rearAxleCompareVal = rigOpsToUse.getVehicleAndSemiOrInterlinkVehicleRearAxleGross();
        rearAxleRefVal = hasPusherOrTagDown ? bridgeRegulationVals[tempVehicle.getChassisObjectName()].vehicleRearTotalBridgeOrRegulationVal : bridgeRegulationVals[tempVehicle.getChassisObjectName()].rearAxleBridgeFormulaPermissible;
        //trailerRearOverload = rigOpsToUse.getVehicleAndSemiOrInterlinkTrailerRearAxleOverload();
        trailerRearCompareVal = rigOpsToUse.getVehicleAndSemiOrInterlinkTrailerRearAxleGross();
        trailerRearRefVal = bridgeRegulationVals[tempTrailer1.getChassisObjectName()].rearAxleBridgeFormulaPermissible;

    } else if (tempTrailer2 === null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
        //frontAxleOverload = offerManager.getValue(config.VALUE_TYPE.VEHICLEFRONTAXLEOVERLOAD);
        frontAxleCompareVal = rigOpsToUse.getVehicleOnlyFrontGross();
        frontAxleRefVal = bridgeRegulationVals[tempVehicle.getChassisObjectName()].frontAxleGroupBridgeFormulaPermissible;
        //totalOverload = rigOpsToUse.getVehicleAndDrawbarTotalOverload();
        totalCompareVal = getActiveOffer().getRig().getVehicle().getGrossTotal() + getActiveOffer().getRig().getTrailer1().getUnladenTotal() + getActiveOffer().getRig().getTrailer1().getPayloadTotal();
        totalRefVal = bridgeRegulationVals[tempVehicle.getChassisObjectName()].combinationTotalBridgeOrRegulationVal;
        //rearAxleOverload = offerManager.getValue(config.VALUE_TYPE.VEHICLEREARAXLEOVERLOAD);
        rearAxleCompareVal = rigOpsToUse.getVehicleOnlyRearGross();
        rearAxleRefVal = hasPusherOrTagDown ? bridgeRegulationVals[tempVehicle.getChassisObjectName()].vehicleRearTotalBridgeOrRegulationVal : bridgeRegulationVals[tempVehicle.getChassisObjectName()].rearAxleBridgeFormulaPermissible;
        //trailerFrontOverload = rigOpsToUse.getVehicleAndDrawbarTrailerFrontAxleOverload();
        trailerFrontCompareVal = getActiveOffer().getRig().getTrailer1().getUnladenFront() + getActiveOffer().getRig().getTrailer1().getPayloadFront();
        trailerFrontRefVal = bridgeRegulationVals[tempTrailer1.getChassisObjectName()].frontAxleGroupBridgeFormulaPermissible;
        //trailerRearOverload = rigOpsToUse.getVehicleAndDrawbarTrailerRearAxleOverload();
        trailerRearCompareVal = getActiveOffer().getRig().getTrailer1().getUnladenRear() + getActiveOffer().getRig().getTrailer1().getPayloadRear();
        trailerRearRefVal = bridgeRegulationVals[tempTrailer1.getChassisObjectName()].rearAxleBridgeFormulaPermissible;

    } else if (tempTrailer2 === null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
        //frontAxleOverload = rigOpsToUse.getVehicleAndPupVehicleFrontAxleOverload();
        frontAxleCompareVal = rigOpsToUse.getVehicleOnlyFrontGross() + rigOpsToUse.getVehicleAndPupTareTransferOntoVehicleFrontAxle() + rigOpsToUse.getVehicleAndPupPayloadTransferOntoVehicleFrontAxle();
        frontAxleRefVal = bridgeRegulationVals[tempVehicle.getChassisObjectName()].frontAxleGroupBridgeFormulaPermissible;
        //totalOverload = rigOpsToUse.getVehicleAndPupTotalOverload();
        totalCompareVal = getActiveOffer().getRig().getVehicle().getGrossTotal() + getActiveOffer().getRig().getTrailer1().getUnladenTotal() + getActiveOffer().getRig().getTrailer1().getPayloadTotal();
        totalRefVal = bridgeRegulationVals[tempVehicle.getChassisObjectName()].combinationTotalBridgeOrRegulationVal;
        //rearAxleOverload = rigOpsToUse.getVehicleAndPupVehicleRearAxleOverload();
        rearAxleCompareVal = rigOpsToUse.getVehicleOnlyRearGross() + rigOpsToUse.getVehicleAndPupTareTransferOntoVehicleRearAxle() + rigOpsToUse.getVehicleAndPupPayloadTransferOntoVehicleRearAxle();
        rearAxleRefVal = hasPusherOrTagDown ? bridgeRegulationVals[tempVehicle.getChassisObjectName()].vehicleRearTotalBridgeOrRegulationVal : bridgeRegulationVals[tempVehicle.getChassisObjectName()].rearAxleBridgeFormulaPermissible;
        //trailerRearOverload = rigOpsToUse.getVehicleAndPupTrailerRearAxleOverload();
        trailerRearCompareVal = getActiveOffer().getRig().getTrailer1().getUnladenRear() + getActiveOffer().getRig().getTrailer1().getPayloadRear();
        trailerRearRefVal = bridgeRegulationVals[tempTrailer1.getChassisObjectName()].rearAxleBridgeFormulaPermissible;

    } else if (tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
        //frontAxleOverload = applyRoundingForGrossOverload(rigOpsToUse.getVehicleAndInterlinkAndSemiVehicleFrontAxleOverload());
        frontAxleCompareVal = rigOpsToUse.getCombinationVehicleFrontGross();
        frontAxleRefVal = bridgeRegulationVals[tempVehicle.getChassisObjectName()].frontAxleGroupBridgeFormulaPermissible;
        //totalOverload = applyRoundingForGrossOverload(utils.returnValueOrZeroIfNanOrInfinity(rigOpsToUse.getVehicleAndInterlinkAndSemiTotalOverload()));
        totalCompareVal = rigOpsToUse.getCombinationGrossTotal();
        totalRefVal = bridgeRegulationVals[tempVehicle.getChassisObjectName()].combinationTotalBridgeOrRegulationVal;
        //rearAxleOverload = applyRoundingForGrossOverload(rigOpsToUse.getVehicleAndInterlinkAndSemiVehicleRearAxleOverload());
        rearAxleCompareVal = rigOpsToUse.getCombinationVehicleRearGross();
        rearAxleRefVal = hasPusherOrTagDown ? bridgeRegulationVals[tempVehicle.getChassisObjectName()].vehicleRearTotalBridgeOrRegulationVal : bridgeRegulationVals[tempVehicle.getChassisObjectName()].rearAxleBridgeFormulaPermissible;
        //trailer1Overload = applyRoundingForGrossOverload(rigOpsToUse.getVehicleAndInterlinkAndSemiTrailer1RearAxleOverload());
        trailer1CompareVal = tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear() + rigOpsToUse.getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1RearAxle() + rigOpsToUse.getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoTrailer1RearAxle();
        trailer1RefVal = bridgeRegulationVals[tempTrailer1.getChassisObjectName()].rearAxleBridgeFormulaPermissible;
        //trailer2Overload = applyRoundingForGrossOverload(rigOpsToUse.getVehicleAndInterlinkAndSemiTrailer2RearAxleOverload());
        trailer2CompareVal = tempTrailer2.getUnladenRear() + tempTrailer2.getPayloadRear();
        trailer2RefVal = bridgeRegulationVals[tempTrailer2.getChassisObjectName()].rearAxleBridgeFormulaPermissible;
    } else {
        //#unfinished, if the code goes in here it mean we need to support another combination above
        return {};
    }
    if (tempVehicle.getAxlesHolder().getHasPusherOrTagAxleDown()) {
        var rearConnectedCalculated = false;
        var pusherCounter = 1, tagCounter = 1, tagRearAxlePos = tempVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR) - tempVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.TAG) + 1;
        tempVehicle.getAxlesHolder().getAxles().forEach(function (axle, index) {
            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
                var pusherColName = config.TABLE_COLUMNS.PUSHER + '_' + pusherCounter + '_' + axle.getNumber();
                var pusherCompareVal = tempVehicle.getPusherOrTagRearAxleGross(axle.getNumber(), rigOpsToUse.getCombinationVehicleRearGross());
                var pusherRefVal = bridgeRegulationVals[tempVehicle.getChassisObjectName()]['pusherAxle' + pusherCounter + 'BridgeFormulaPermissible'];//rigOpsToUse.getPusherOrTagPermissibleVal.call(rigOpsToUse, axle.getNumber(), pusherCounter, config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);

                colHighlightColours[pusherColName] = getBridgeFormulaPermissibleHighlightColour(rigOpsToUse, encodedLegislationId, evaluationFunc, pusherColName, pusherCompareVal, pusherRefVal);
                pusherCounter++;
            } else if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                var tagColName = config.TABLE_COLUMNS.TAG + '_' + tagRearAxlePos + '_' + axle.getNumber();
                var tagCompareVal = tempVehicle.getPusherOrTagRearAxleGross(axle.getNumber(), rigOpsToUse.getCombinationVehicleRearGross());
                var tagRefVal = bridgeRegulationVals[tempVehicle.getChassisObjectName()]['tagAxle' + tagCounter + 'BridgeFormulaPermissible'];//rigOpsToUse.getPusherOrTagPermissibleVal.call(rigOpsToUse, axle.getNumber(), tagCounter, config.VEHICLE_AXLE_POSITION_TYPES.TAG);

                colHighlightColours[tagColName] = getBridgeFormulaPermissibleHighlightColour(rigOpsToUse, encodedLegislationId, evaluationFunc, tagColName, tagCompareVal, tagRefVal);
                tagCounter++;
                tagRearAxlePos++;
            } else if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR && rearConnectedCalculated === false) {
                rearConnectedCalculated = true;
                var rearConnectedColName = config.TABLE_COLUMNS.REAR_CONNECTED;
                var rearConnectedCompareVal = tempVehicle.getRearRearAxlesDistribution({ rearMassVal: rigOpsToUse.getCombinationVehicleRearGross() });
                var rearConnectedRefVal = bridgeRegulationVals[tempVehicle.getChassisObjectName()].rearAxleBridgeFormulaPermissible;//rigOpsToUse.getRearRearAxlePermissibleVal();

                colHighlightColours[rearConnectedColName] = getBridgeFormulaPermissibleHighlightColour(rigOpsToUse, encodedLegislationId, evaluationFunc, rearConnectedColName, rearConnectedCompareVal, rearConnectedRefVal);
            }
        });
    }
    colHighlightColours[config.TABLE_COLUMNS.FRONT] = getBridgeFormulaPermissibleHighlightColour(rigOpsToUse, encodedLegislationId, evaluationFunc, config.TABLE_COLUMNS.FRONT, frontAxleCompareVal, frontAxleRefVal, rowName);
    colHighlightColours[config.TABLE_COLUMNS.REAR] = getBridgeFormulaPermissibleHighlightColour(rigOpsToUse, encodedLegislationId, evaluationFunc, config.TABLE_COLUMNS.REAR, rearAxleCompareVal, rearAxleRefVal, rowName);
    if (tempTrailer1 !== null && tempTrailer2 === null) {
        if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
            colHighlightColours[config.TABLE_COLUMNS.TRAILER_FRONT] = getBridgeFormulaPermissibleHighlightColour(rigOpsToUse, encodedLegislationId, evaluationFunc, config.TABLE_COLUMNS.TRAILER_FRONT, trailerFrontCompareVal, trailerFrontRefVal, rowName);
        }
        colHighlightColours[config.TABLE_COLUMNS.TRAILER_REAR] = getBridgeFormulaPermissibleHighlightColour(rigOpsToUse, encodedLegislationId, evaluationFunc, config.TABLE_COLUMNS.TRAILER_REAR, trailerRearCompareVal, trailerRearRefVal, rowName);
        
    }else if (tempTrailer2 !== null) {
        
        colHighlightColours[config.TABLE_COLUMNS.TRAILER_1] = getBridgeFormulaPermissibleHighlightColour(rigOpsToUse, encodedLegislationId, evaluationFunc, config.TABLE_COLUMNS.TRAILER_1, trailer1CompareVal, trailer1RefVal, rowName);
        colHighlightColours[config.TABLE_COLUMNS.TRAILER_2] = getBridgeFormulaPermissibleHighlightColour(rigOpsToUse, encodedLegislationId, evaluationFunc, config.TABLE_COLUMNS.TRAILER_2, trailer2CompareVal, trailer2RefVal, rowName);
    }
    colHighlightColours[config.TABLE_COLUMNS.TOTAL] = getBridgeFormulaPermissibleHighlightColour(rigOpsToUse, encodedLegislationId, evaluationFunc, config.TABLE_COLUMNS.TOTAL, totalCompareVal, totalRefVal, rowName);
        
        return colHighlightColours;
}

function generateSteerAxleMinimumOverloadingColColours(rigOpsToUse, encodedLegislationId, evaluationFunc, rowName) {

    var tempVehicle = rigOpsToUse.getActiveRig().getVehicle(), tempTrailer1 = rigOpsToUse.getActiveRig().getTrailer1(), tempTrailer2 = rigOpsToUse.getActiveRig().getTrailer2();
    var frontAxleCompareVal;
    
    var colHighlightColours = {};
    var hasPusherOrTagDown = tempVehicle.getAxlesHolder().getHasPusherOrTagAxleDown();
    if (tempTrailer1 === null && tempTrailer2 === null) {
        frontAxleCompareVal = rigOpsToUse.getVehicleOnlyFrontGross();
    } else if (tempTrailer2 === null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
        frontAxleCompareVal = rigOpsToUse.getVehicleAndSemiOrInterlinkVehicleFrontAxleGross();
    } else if (tempTrailer2 === null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
        frontAxleCompareVal = rigOpsToUse.getVehicleOnlyFrontGross();
    } else if (tempTrailer2 === null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
        frontAxleCompareVal = rigOpsToUse.getVehicleOnlyFrontGross() + rigOpsToUse.getVehicleAndPupTareTransferOntoVehicleFrontAxle() + rigOpsToUse.getVehicleAndPupPayloadTransferOntoVehicleFrontAxle();
    } else if (tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
        frontAxleCompareVal = rigOpsToUse.getCombinationVehicleFrontGross();
    } else {
        //#unfinished, if the code goes in here it mean we need to support another combination above
        return {};
    }
    
    colHighlightColours[config.TABLE_COLUMNS.FRONT] = getSteerAxleMinimumHighlightColour(rigOpsToUse, encodedLegislationId, evaluationFunc, config.TABLE_COLUMNS.FRONT, frontAxleCompareVal, rowName);
        
    return colHighlightColours;
}

function getBridgeGroupsVisibleForActiveLegislations() {
    
    return getDataForActiveLegislations().mergedLegislationBridgeGroupsVisible;
    //var legislationsBridgeGroupsVisible = [], legislationBridgeGroupsVisibleValsArrays = {};

    //_legislations.forEach(function (legislationDetails) {

    //    var evaluateLegislationWrapper = function (attributeName) {
    //        return evaluateLegislation(attributeName, legislationDetails /*, tempRigOpsForSwap*/)
    //    };
    //    rigOps.setLegislationEvaluationFunction(evaluateLegislationWrapper);
    //    //clearLegislationQuickLookup();

    //    rigOps.maximisePayloads();

    //    legislationsBridgeGroupsVisible.push(rigOps.getBridgeGroupsVisible());

    //});

    //Object.keys(legislationsBridgeGroupsVisible[0]).forEach(function (key) {
    //    legislationBridgeGroupsVisibleValsArrays[key] = [];
    //});

    //legislationsBridgeGroupsVisible.forEach(function (legislationBridgeGroupVisibleValSet) {
    //    Object.keys(legislationBridgeGroupVisibleValSet).forEach(function (key) {
    //        legislationBridgeGroupsVisibleValsArrays[key].push(legislationBridgeGroupVisibleValSet[key]);
    //    });
    //});

    //var mergedLegislationBridgeGroupsVisibleValSet = {};
    //Object.keys(legislationBridgeGroupsVisibleValsArrays).forEach(function (key) {
    //    mergedLegislationBridgeGroupsVisibleValSet[key] = Math.max(...legislationBridgeGroupsVisibleValsArrays[key]) === 1 ? true : false;
    //});

    //rigOps.setLegislationEvaluationFunction(getLesserOfActiveLegislationVals);

    //return mergedLegislationBridgeGroupsVisibleValSet;
}

function getWorstOverloadingStatusFromActiveLegislations() {
    
    //var worstOverloadingStatus = config.OVERLOADING_STATUS.OK;
    
    //_legislations.forEach(function (legislationDetails) {
    //    var evaluateLegislationWrapper = function (attributeName) {
    //        return evaluateLegislation(attributeName, legislationDetails)
    //    };
    //    rigOps.setLegislationEvaluationFunction(evaluateLegislationWrapper);
    //    //clearLegislationQuickLookup();
    //    rigOps.maximisePayloads();
    //    worstOverloadingStatus = returnWorstColourStatus(getWorstOverloadingStatus(legislationDetails.id, evaluateLegislationWrapper), worstOverloadingStatus);
    //});

    //rigOps.setLegislationEvaluationFunction(getLesserOfActiveLegislationVals);

    return getDataForActiveLegislations().worstOverloadingStatus;
}

function getWorstOverloadingStatus(encodedLegislationId) {

    var tempTrailer1 = getActiveOffer().getRig().getTrailer1(), tempTrailer2 = getActiveOffer().getRig().getTrailer2();

    //var frontAxleCompareVal, totalCompareVal, rearAxleCompareVal, trailerFrontCompareVal, trailerRearCompareVal;
    //var frontAxleRefVal, totalRefVal, rearAxleRefVal, trailerFrontRefVal, trailerRearRefVal;
    var grossFrontTableHighLightColour, grossRearTableHighLightColour, grossTrailerRearHighlightColour, grossTrailerFrontHighlightColour, grossTrailer1HighlightColour, grossTrailer2HighlightColour, grossTotalTableHighLightColour;

    //if (tempTrailer1 === null) {
    //    //frontAxleOverload = offerManager.getValue(config.VALUE_TYPE.VEHICLEFRONTAXLEOVERLOAD);
    //    frontAxleCompareVal = rigOps.getVehicleOnlyFrontGross();
    //    frontAxleRefVal = rigOps.getVehicleFrontPermissible();
    //    //totalOverload = offerManager.getValue(config.VALUE_TYPE.VEHICLETOTALOVERLOAD);
    //    totalCompareVal = getActiveOffer().getRig().getVehicle().getGrossTotal();
    //    totalRefVal = rigOps.getVehicleTotalPermissible();
    //    //rearAxleOverload = offerManager.getValue(config.VALUE_TYPE.VEHICLEREARAXLEOVERLOAD);
    //    rearAxleCompareVal = rigOps.getVehicleOnlyRearGross();
    //    rearAxleRefVal = rigOps.getVehicleRearPermissible();

    //} else if (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
    //    //frontAxleOverload = rigOps.getVehicleAndSemiOrInterlinkVehicleFrontAxleOverload();
    //    frontAxleCompareVal = rigOps.getVehicleAndSemiOrInterlinkVehicleFrontAxleGross();
    //    frontAxleRefVal = rigOps.getVehicleFrontPermissible();
    //    //totalOverload = rigOps.getVehicleAndSemiOrInterlinkTotalOverload();
    //    totalCompareVal = getActiveOffer().getRig().getVehicle().getGrossTotal() + getActiveOffer().getRig().getTrailer1().getUnladenTotal() + getActiveOffer().getRig().getTrailer1().getPayloadTotal();
    //    totalRefVal = rigOps.getCombinationTotalPermissible();
    //    //rearAxleOverload = rigOps.getVehicleAndSemiOrInterlinkVehicleRearAxleOverload();
    //    rearAxleCompareVal = rigOps.getVehicleAndSemiOrInterlinkVehicleRearAxleGross();
    //    rearAxleRefVal = rigOps.getVehicleRearPermissible();
    //    //trailerRearOverload = rigOps.getVehicleAndSemiOrInterlinkTrailerRearAxleOverload();
    //    trailerRearCompareVal = rigOps.getVehicleAndSemiOrInterlinkTrailerRearAxleGross();
    //    trailerRearRefVal = rigOps.getTrailer1PermissibleRear();

    //} else if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
    //    //frontAxleOverload = offerManager.getValue(config.VALUE_TYPE.VEHICLEFRONTAXLEOVERLOAD);
    //    frontAxleCompareVal = rigOps.getVehicleOnlyFrontGross();
    //    frontAxleRefVal = rigOps.getVehicleFrontPermissible();
    //    //totalOverload = rigOps.getVehicleAndDrawbarTotalOverload();
    //    totalCompareVal = getActiveOffer().getRig().getVehicle().getGrossTotal() + getActiveOffer().getRig().getTrailer1().getUnladenTotal() + getActiveOffer().getRig().getTrailer1().getPayloadTotal();
    //    totalRefVal = rigOps.getCombinationTotalPermissible();
    //    //rearAxleOverload = offerManager.getValue(config.VALUE_TYPE.VEHICLEREARAXLEOVERLOAD);
    //    rearAxleCompareVal = rigOps.getVehicleOnlyRearGross();
    //    rearAxleRefVal = rigOps.getVehicleRearPermissible();
    //    //trailerFrontOverload = rigOps.getVehicleAndDrawbarTrailerFrontAxleOverload();
    //    trailerFrontCompareVal = getActiveOffer().getRig().getTrailer1().getUnladenFront() + getActiveOffer().getRig().getTrailer1().getPayloadFront();
    //    trailerFrontRefVal = rigOps.getTrailer1PermissibleFront();
    //    //trailerRearOverload = rigOps.getVehicleAndDrawbarTrailerRearAxleOverload();
    //    trailerRearCompareVal = getActiveOffer().getRig().getTrailer1().getUnladenRear() + getActiveOffer().getRig().getTrailer1().getPayloadRear();
    //    trailerRearRefVal = rigOps.getTrailer1PermissibleRear();

    //} else if (tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
    //    //frontAxleOverload = rigOps.getVehicleAndPupVehicleFrontAxleOverload();
    //    frontAxleCompareVal = rigOps.getVehicleOnlyFrontGross() + rigOps.getVehicleAndPupTareTransferOntoVehicleFrontAxle() + rigOps.getVehicleAndPupPayloadTransferOntoVehicleFrontAxle();
    //    frontAxleRefVal = rigOps.getVehicleFrontPermissible();
    //    //totalOverload = rigOps.getVehicleAndPupTotalOverload();
    //    totalCompareVal = getActiveOffer().getRig().getVehicle().getGrossTotal() + getActiveOffer().getRig().getTrailer1().getUnladenTotal() + getActiveOffer().getRig().getTrailer1().getPayloadTotal();
    //    totalRefVal = rigOps.getCombinationTotalPermissible();
    //    //rearAxleOverload = rigOps.getVehicleAndPupVehicleRearAxleOverload();
    //    rearAxleCompareVal = rigOps.getVehicleOnlyRearGross() + rigOps.getVehicleAndPupTareTransferOntoVehicleRearAxle() + rigOps.getVehicleAndPupPayloadTransferOntoVehicleRearAxle();
    //    rearAxleRefVal = rigOps.getVehicleRearPermissible();
    //    //trailerRearOverload = rigOps.getVehicleAndPupTrailerRearAxleOverload();
    //    trailerRearCompareVal = getActiveOffer().getRig().getTrailer1().getUnladenRear() + getActiveOffer().getRig().getTrailer1().getPayloadRear();
    //    trailerRearRefVal = rigOps.getTrailer1PermissibleRear();

    //} else {
    //    //#unfinished, if the code goes in here it mean we need to support another combination above e.g. vehicle + pup trailer
    //    return config.OVERLOADING_STATUS.OVERLOADED;
    //}

    //grossFrontTableHighLightColour = getTableCellHighlightColour(config.TABLE_COLUMNS.FRONT, frontAxleCompareVal, frontAxleRefVal, 'GROSS');
    //grossRearTableHighLightColour = getTableCellHighlightColour(config.TABLE_COLUMNS.REAR, rearAxleCompareVal, rearAxleRefVal, 'GROSS');
    //if (tempTrailer1 !== null) {
    //    if (trailerFrontOverload !== undefined) {
    //        grossTrailerFrontHighlightColour = getTableCellHighlightColour(config.TABLE_COLUMNS.TRAILER_FRONT, trailerFrontCompareVal, trailerFrontRefVal, 'GROSS');
    //    }
    //    grossTrailerRearHighlightColour = getTableCellHighlightColour(config.TABLE_COLUMNS.TRAILER_REAR, trailerRearCompareVal, trailerRearRefVal, 'GROSS');
    //}
    //grossTotalTableHighLightColour = getTableCellHighlightColour(config.TABLE_COLUMNS.TOTAL, totalCompareVal, totalRefVal, 'GROSS');
    
    //var colHighlightColours = generateOverloadingColColours(evaluationFunc, 'GROSS');
    var colHighlightColours = getOverloadingColoursForLegislation(encodedLegislationId);
    grossFrontTableHighLightColour = colHighlightColours[config.TABLE_COLUMNS.FRONT];
    if (grossFrontTableHighLightColour === config.TABLE_HIGHLIGHT_COLOURS.FAIL) {
        return config.OVERLOADING_STATUS.OVERLOADED;
    } else if (grossFrontTableHighLightColour === config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING) {
        return config.OVERLOADING_STATUS.UNDERLOADED;
    } else if (grossFrontTableHighLightColour === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
        return config.OVERLOADING_STATUS.OVERLOADED_WITHIN_TOLERANCES;
    }

    grossRearTableHighLightColour = colHighlightColours[config.TABLE_COLUMNS.REAR];
    if (grossRearTableHighLightColour === config.TABLE_HIGHLIGHT_COLOURS.FAIL) {
        return config.OVERLOADING_STATUS.OVERLOADED;
    } else if (grossRearTableHighLightColour === config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING) {
        return config.OVERLOADING_STATUS.UNDERLOADED;
    } else if (grossRearTableHighLightColour === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
        return config.OVERLOADING_STATUS.OVERLOADED_WITHIN_TOLERANCES;
    }

    if (tempTrailer1 !== null && tempTrailer2 === null) {

        if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
            grossTrailerFrontHighlightColour = colHighlightColours[config.TABLE_COLUMNS.TRAILER_FRONT];
            if (grossTrailerFrontHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.FAIL) {
                return config.OVERLOADING_STATUS.OVERLOADED;
            } else if (grossTrailerFrontHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING) {
                return config.OVERLOADING_STATUS.UNDERLOADED;
            } else if (grossTrailerFrontHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
                return config.OVERLOADING_STATUS.OVERLOADED_WITHIN_TOLERANCES;
            }
        }

        grossTrailerRearHighlightColour = colHighlightColours[config.TABLE_COLUMNS.TRAILER_REAR];
        if (grossTrailerRearHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.FAIL) {
            return config.OVERLOADING_STATUS.OVERLOADED;
        } else if (grossTrailerRearHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING) {
            return config.OVERLOADING_STATUS.UNDERLOADED;
        } else if (grossTrailerRearHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
            return config.OVERLOADING_STATUS.OVERLOADED_WITHIN_TOLERANCES;
        }


    } else if (tempTrailer2 !== null) {
        grossTrailer1HighlightColour = colHighlightColours[config.TABLE_COLUMNS.TRAILER_1];
        if (grossTrailer1HighlightColour === config.TABLE_HIGHLIGHT_COLOURS.FAIL) {
            return config.OVERLOADING_STATUS.OVERLOADED;
        } else if (grossTrailer1HighlightColour === config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING) {
            return config.OVERLOADING_STATUS.UNDERLOADED;
        } else if (grossTrailer1HighlightColour === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
            return config.OVERLOADING_STATUS.OVERLOADED_WITHIN_TOLERANCES;
        }

        grossTrailer2HighlightColour = colHighlightColours[config.TABLE_COLUMNS.TRAILER_2];
        if (grossTrailer2HighlightColour === config.TABLE_HIGHLIGHT_COLOURS.FAIL) {
            return config.OVERLOADING_STATUS.OVERLOADED;
        } else if (grossTrailer2HighlightColour === config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING) {
            return config.OVERLOADING_STATUS.UNDERLOADED;
        } else if (grossTrailer2HighlightColour === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
            return config.OVERLOADING_STATUS.OVERLOADED_WITHIN_TOLERANCES;
        }
    }

    
    if (grossTotalTableHighLightColour === config.TABLE_HIGHLIGHT_COLOURS.FAIL) {
        return config.OVERLOADING_STATUS.OVERLOADED;
    } else if (grossTotalTableHighLightColour === config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING) {
        return config.OVERLOADING_STATUS.UNDERLOADED;
    } else if (grossTotalTableHighLightColour === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
        return config.OVERLOADING_STATUS.OVERLOADED_WITHIN_TOLERANCES;
    }

    return config.OVERLOADING_STATUS.OK;
}

function clearBridgeWorstHighlightColour() {
    utils.deleteObjectMembers(worstBridgeFormulaOverloadColour);
    worstBridgeFormulaOverloadColour = undefined;
}

function clearBridgeFormulaPermissiblWorstHighlightColour() {
    utils.deleteObjectMembers(worstBridgeFormulaPermissibleOverloadColour);
    worstBridgeFormulaPermissibleOverloadColour = undefined;
}

function clearSteerAxleMinimumWorstHighlightColour() {
    utils.deleteObjectMembers(worstSteerAxleMinimumOverloadColour);
    worstSteerAxleMinimumOverloadColour = undefined;
}

function getBridgeFormulaGroupOverloadColour(bridgeGroup, rigOpsToUse, percentageRoundingFunc, usesDataDrivenRulesMethod) { 
    let extremeBridgeGroupName = rigOpsToUse.getExtremeBridgeGroupName(bridgeGroup);
    if(usesDataDrivenRulesMethod) {
        let matchedContext = offerManager.getTriggeredRuleContexts().bridge[extremeBridgeGroupName];
        if(matchedContext) {
            if(matchedContext.ruleType === config.DDR_RULE_TYPE.WARNING) {
                return config.TABLE_HIGHLIGHT_COLOURS.FAIL;
            } else if (matchedContext.ruleType === config.DDR_RULE_TYPE.CAUTION) {
                return config.TABLE_HIGHLIGHT_COLOURS.WARNING;
            }
        }
        return config.TABLE_HIGHLIGHT_COLOURS.OK;
    }else {
        return rigOpsToUse.getGroupOverloadColour(bridgeGroup, percentageRoundingFunc);    
    }
    
    
}

function getWorstBridgeFormulaOverloadColour(rigOpsToUse, encodedLegislationId, evaluationFunc) {
    if (!worstBridgeFormulaOverloadColour) {
        worstBridgeFormulaOverloadColour = {};
    }

    if (worstBridgeFormulaOverloadColour[encodedLegislationId] !== undefined) {
        return worstBridgeFormulaOverloadColour[encodedLegislationId];
    } 

    //if (evaluationFunc) {
    //    rigOpsToUse.setLegislationEvaluationFunction(evaluationFunc);
    //}

    var bridgeGroupsVisible = rigOpsToUse.getBridgeGroupsVisible();
    var overloadColour = rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP1, getCurMeasurementSysRoundedPercentage);
    var onlyCountDownAxles, tempVehicle, numDownFrontAxles, numDownRearAxles;
    switch (rigOpsToUse.getCombinationType()) {
        case config.COMBINATION_TYPES.VEHICLE:           
            if (bridgeGroupsVisible.showExtremeRearGroup) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP2, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showExtremeRearDrivenGroup) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP3, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showExtremeFrontGroup) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP4, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showPusher1Group) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP5, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showPusher2Group) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP6, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showPusher3Group) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP7, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showTag1Group) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP8, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showTag2Group) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP9, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showTag3Group) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP10, getCurMeasurementSysRoundedPercentage));
            }
            worstBridgeFormulaOverloadColour[encodedLegislationId] = overloadColour;
            return worstBridgeFormulaOverloadColour[encodedLegislationId];
        case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
        case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
        case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
            overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP2, getCurMeasurementSysRoundedPercentage));
            overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP3, getCurMeasurementSysRoundedPercentage));
            overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP7, getCurMeasurementSysRoundedPercentage));
            
            if (bridgeGroupsVisible.showExtremeRearGroup) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP4, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showExtremeRearDrivenGroup) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP5, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showExtremeFrontGroup) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP6, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showPusher1Group) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP8, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showPusher2Group) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP9, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showPusher3Group) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP10, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showTag1Group) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP11, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showTag2Group) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP12, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showTag3Group) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP13, getCurMeasurementSysRoundedPercentage));
            }
            worstBridgeFormulaOverloadColour[encodedLegislationId] = overloadColour;
            return worstBridgeFormulaOverloadColour[encodedLegislationId];
        case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
            overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP2, getCurMeasurementSysRoundedPercentage));
            overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP3, getCurMeasurementSysRoundedPercentage));
            overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP4, getCurMeasurementSysRoundedPercentage));
            overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP5, getCurMeasurementSysRoundedPercentage));
            overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP6, getCurMeasurementSysRoundedPercentage));
            
            onlyCountDownAxles = true;
            tempVehicle = getActiveOffer().getRig().getVehicle();
            numDownFrontAxles = tempVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles);
            numDownRearAxles = tempVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);

            if (bridgeGroupsVisible.showExtremeRearGroup && numDownRearAxles > 1) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP7, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showExtremeRearDrivenGroup && getActiveOffer().getRig().getVehicle().getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR) > 1) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP8, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showExtremeFrontGroup && numDownFrontAxles >= 1) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP9, getCurMeasurementSysRoundedPercentage));
            }

            if (bridgeGroupsVisible.showTrailer1ExtremeFrontGroup) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP10, getCurMeasurementSysRoundedPercentage));
            }

            if (bridgeGroupsVisible.showTrailer1ExtremeRearGroup) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP11, getCurMeasurementSysRoundedPercentage));
            }

            if (bridgeGroupsVisible.showPusher1Group) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP12, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showPusher2Group) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP13, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showPusher3Group) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP14, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showTag1Group) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP15, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showTag2Group) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP16, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showTag3Group) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP17, getCurMeasurementSysRoundedPercentage));
            }
            worstBridgeFormulaOverloadColour[encodedLegislationId] = overloadColour;
            return worstBridgeFormulaOverloadColour[encodedLegislationId];
        case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
            overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP2, getCurMeasurementSysRoundedPercentage));
            overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP3, getCurMeasurementSysRoundedPercentage));
            overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP4, getCurMeasurementSysRoundedPercentage));
            overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP5, getCurMeasurementSysRoundedPercentage));
            overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP6, getCurMeasurementSysRoundedPercentage));

            onlyCountDownAxles = true;
            tempVehicle = getActiveOffer().getRig().getVehicle();
            numDownFrontAxles = tempVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles);
            numDownRearAxles = tempVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);

            if (bridgeGroupsVisible.showExtremeRearGroup && numDownRearAxles > 1) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP7, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showExtremeRearDrivenGroup && getActiveOffer().getRig().getVehicle().getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR) > 1) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP8, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showExtremeFrontGroup && numDownFrontAxles >= 1) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP9, getCurMeasurementSysRoundedPercentage));
            }

            if (bridgeGroupsVisible.showTrailer1ExtremeRearGroup) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP10, getCurMeasurementSysRoundedPercentage));
            }

            if (bridgeGroupsVisible.showTrailer2ExtremeRearGroup) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP11, getCurMeasurementSysRoundedPercentage));
            }

            if (bridgeGroupsVisible.showPusher1Group) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP12, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showPusher2Group) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP13, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showPusher3Group) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP14, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showTag1Group) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP15, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showTag2Group) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP16, getCurMeasurementSysRoundedPercentage));
            }
            if (bridgeGroupsVisible.showTag3Group) {
                overloadColour = returnWorstColourStatus(overloadColour, rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP17, getCurMeasurementSysRoundedPercentage));
            }
            worstBridgeFormulaOverloadColour[encodedLegislationId] = overloadColour;
            return worstBridgeFormulaOverloadColour[encodedLegislationId];
    }
    return config.TABLE_HIGHLIGHT_COLOURS.OK;
}

function getWorstBridgeFormulaPermissibleOverloadColour(encodedLegislationId) {
    
    // if (!bridgeRegulationVals) {
    //     bridgeRegulationVals = {};
    //     bridgeRegulationVals[tempVehicle.getChassisObjectName()] = rigOpsToUse.generateBridgeFormulaPermissibleValues(tempVehicle.getChassisObjectName());
    //     if(tempTrailer1 !== null) {
    //         bridgeRegulationVals[tempTrailer1.getChassisObjectName()] = rigOpsToUse.generateBridgeFormulaPermissibleValues(tempTrailer1.getChassisObjectName());
    //         if(tempTrailer2 !== null) {
    //             bridgeRegulationVals[tempTrailer2.getChassisObjectName()] = rigOpsToUse.generateBridgeFormulaPermissibleValues(tempTrailer2.getChassisObjectName());
                
    //         }   
    //     }
    // }
    
    if (!worstBridgeFormulaPermissibleOverloadColour) {
        worstBridgeFormulaPermissibleOverloadColour = {};
    }

    if (worstBridgeFormulaPermissibleOverloadColour[encodedLegislationId] !== undefined) {
        return worstBridgeFormulaPermissibleOverloadColour[encodedLegislationId];
    }
    
    let bridgeFormulaPermissibleOverloadingColColourInstance = getBridgeFormulaPermissibleOverloadingColoursForLegislation(encodedLegislationId);
    let worstColour = config.TABLE_HIGHLIGHT_COLOURS.OK;
    Object.values(bridgeFormulaPermissibleOverloadingColColourInstance).forEach(function (value){
        worstColour = returnWorstColourStatus(worstColour, value);
    });

    worstBridgeFormulaPermissibleOverloadColour[encodedLegislationId] = worstColour;
    return worstColour;

    
}

function getWorstSteerAxleMinimumOverloadColour(encodedLegislationId) {
    
    // if (!bridgeRegulationVals) {
    //     bridgeRegulationVals = {};
    //     bridgeRegulationVals[tempVehicle.getChassisObjectName()] = rigOpsToUse.generateBridgeFormulaPermissibleValues(tempVehicle.getChassisObjectName());
    //     if(tempTrailer1 !== null) {
    //         bridgeRegulationVals[tempTrailer1.getChassisObjectName()] = rigOpsToUse.generateBridgeFormulaPermissibleValues(tempTrailer1.getChassisObjectName());
    //         if(tempTrailer2 !== null) {
    //             bridgeRegulationVals[tempTrailer2.getChassisObjectName()] = rigOpsToUse.generateBridgeFormulaPermissibleValues(tempTrailer2.getChassisObjectName());
                
    //         }   
    //     }
    // }
    
    if (!worstSteerAxleMinimumOverloadColour) {
        worstSteerAxleMinimumOverloadColour = {};
    }

    if (worstSteerAxleMinimumOverloadColour[encodedLegislationId] !== undefined) {
        return worstSteerAxleMinimumOverloadColour[encodedLegislationId];
    }
    
    let steerAxleMinimumOverloadingColColourInstance = getSteerAxleMinimumOverloadingColoursForLegislation(encodedLegislationId);
    let worstColour = config.TABLE_HIGHLIGHT_COLOURS.OK;
    Object.values(steerAxleMinimumOverloadingColColourInstance).forEach(function (value){
        worstColour = returnWorstColourStatus(worstColour, value);
    });

    worstSteerAxleMinimumOverloadColour[encodedLegislationId] = worstColour;
    return worstColour;

    
}

function returnWorstColourStatus(val1, val2) {

    if(val1 === undefined || val2 === undefined) {
        return val1 || val2;
    } 

    let COLOUR_NUMBER_MAP = {

    };
    COLOUR_NUMBER_MAP[config.TABLE_HIGHLIGHT_COLOURS.OK] = 1;
    COLOUR_NUMBER_MAP[config.DRAWING_COLOURS.LIGHT_GREEN] = 1;
    COLOUR_NUMBER_MAP[config.TABLE_HIGHLIGHT_COLOURS.WARNING] = 2;
    COLOUR_NUMBER_MAP[config.DRAWING_COLOURS.LIGHT_ORANGE] = 2;
    COLOUR_NUMBER_MAP[config.TABLE_HIGHLIGHT_COLOURS.FAIL] = 3;
    COLOUR_NUMBER_MAP[config.DRAWING_COLOURS.LIGHT_RED] = 3;

    let worstColourNumber = Math.max(COLOUR_NUMBER_MAP[val1], COLOUR_NUMBER_MAP[val2]);

    if (worstColourNumber === 1) {
        return config.TABLE_HIGHLIGHT_COLOURS.OK;
    } else if (worstColourNumber === 2) {
        return config.TABLE_HIGHLIGHT_COLOURS.WARNING;
    } else if (worstColourNumber === 3) {
        return config.TABLE_HIGHLIGHT_COLOURS.FAIL;
    }

    // if (val1 === config.TABLE_HIGHLIGHT_COLOURS.OK && ()) {
    //     return val1;
    // } else if (val1 === config.TABLE_HIGHLIGHT_COLOURS.OK && val2 !== config.TABLE_HIGHLIGHT_COLOURS.OK) {
    //     return val2;
    // } else if (val1 !== config.TABLE_HIGHLIGHT_COLOURS.OK && val2 === config.TABLE_HIGHLIGHT_COLOURS.OK) {
    //     return val1;
    // } else if (val1 === config.TABLE_HIGHLIGHT_COLOURS.WARNING && val2 === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
    //     return val1;
    // } else if (val1 !== config.TABLE_HIGHLIGHT_COLOURS.WARNING && val2 === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
    //     return val1;
    // } else if (val1 === config.TABLE_HIGHLIGHT_COLOURS.WARNING && val2 !== config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
    //     return val2;
    // } else {
    //     return val1 || val2;
    // }
}

// function returnWorstColourStatus(val1, val2) {
//     if (val1 === config.TABLE_HIGHLIGHT_COLOURS.OK && val2 === config.TABLE_HIGHLIGHT_COLOURS.OK) {
//         return val1;
//     } else if (val1 === config.TABLE_HIGHLIGHT_COLOURS.OK && val2 !== config.TABLE_HIGHLIGHT_COLOURS.OK) {
//         return val2;
//     } else if (val1 !== config.TABLE_HIGHLIGHT_COLOURS.OK && val2 === config.TABLE_HIGHLIGHT_COLOURS.OK) {
//         return val1;
//     } else if (val1 === config.TABLE_HIGHLIGHT_COLOURS.WARNING && val2 === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
//         return val1;
//     } else if (val1 !== config.TABLE_HIGHLIGHT_COLOURS.WARNING && val2 === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
//         return val1;
//     } else if (val1 === config.TABLE_HIGHLIGHT_COLOURS.WARNING && val2 !== config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
//         return val2;
//     } else {
//         return val1 || val2;
//     }
// }

function returnWorstIconStatus(val1, val2) {
    if (val1 === config.COMPLIANCE_STATUS_ICON.PASS && val2 === config.COMPLIANCE_STATUS_ICON.PASS) {
        return val1;
    } else if (val1 === config.COMPLIANCE_STATUS_ICON.PASS && val2 !== config.COMPLIANCE_STATUS_ICON.PASS) {
        return val2;
    } else if (val1 !== config.COMPLIANCE_STATUS_ICON.PASS && val2 === config.COMPLIANCE_STATUS_ICON.PASS) {
        return val1;
    } else if (val1 === config.COMPLIANCE_STATUS_ICON.WARNING && val2 === config.COMPLIANCE_STATUS_ICON.WARNING) {
        return val1;
    } else if (val1 !== config.COMPLIANCE_STATUS_ICON.WARNING && val2 === config.COMPLIANCE_STATUS_ICON.WARNING) {
        return val1;
    } else if (val1 === config.COMPLIANCE_STATUS_ICON.WARNING && val2 !== config.COMPLIANCE_STATUS_ICON.WARNING) {
        return val2;
    } else {
        return val1;
    }
}

//function getTableCellHighlightColour(columName, compareVal, rowName) {
//    if (columName === config.TABLE_COLUMNS.FRONT || columName === config.TABLE_COLUMNS.REAR || columName.indexOf(config.TABLE_COLUMNS.PUSHER) !== -1 || columName.indexOf(config.TABLE_COLUMNS.TAG) !== -1 || columName === config.TABLE_COLUMNS.REAR_CONNECTED || columName === config.TABLE_COLUMNS.TRAILER_REAR || columName === config.TABLE_COLUMNS.TRAILER_FRONT || columName === config.TABLE_COLUMNS.TRAILER_1 || columName === config.TABLE_COLUMNS.TRAILER_2) {
//        if (columName === config.TABLE_COLUMNS.FRONT && rowName !== undefined && rowName === 'GROSS') {
//            if (getActiveOffer().getTrailer1() === null) {
//                if (evaluateLegislation(legislationVals.variableTypes.ComVehSteerAxleMinimumApplies) === 1) {
//                    if (evaluateLegislation('COMVEHSTEERAXLEDUALUNDER') === 1 || evaluateLegislation('COMVEHSTEERAXLESINGLEUNDER') === 1) {
//                        return config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING;
//                    }
//                }
//            } else {
//                if (getCurMeasurementSysRoundedPercentage(compareVal) <= 0) {
//                    if (evaluateLegislation(legislationVals.variableTypes.ComVehSteerAxleMinimumApplies) === 1) {
//                        var combinationType = rigOps.getCombinationType();
//                        if (combinationType === config.COMBINATION_TYPES.VEHICLE_AND_SEMI) {
//                            if ((rigOps.getVehicleOnlyFrontUnladen() + rigOps.getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle() +
//                                rigOps.getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle()) < (evaluateLegislation(legislationVals.variableTypes.MinimumOnSteeringAxleOfArticulatedPercent) / 100 * rigOps.getVehicleAndSemiOrInterlinkTotalGross())) {
//                                return config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING;
//                            } else {
//                                return config.TABLE_HIGHLIGHT_COLOURS.OK;
//                            }
//                        } else if (combinationType === config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR || combinationType === config.COMBINATION_TYPES.VEHICLE_AND_PUP) {
//                            if (evaluateLegislation(legislationVals.variableTypes.ComVehSteerAxleDualUnder) === 1 && evaluateLegislation(legislationVals.variableTypes.ComVehSteerAxleSingleUnder) === 1) {
//                                return config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING;
//                            } else {
//                                return config.TABLE_HIGHLIGHT_COLOURS.OK;
//                            }
//                        }

//                    } else {
//                        return config.TABLE_HIGHLIGHT_COLOURS.OK;
//                    }
//                }
//            }
//        }
//        return doEval(evaluateLegislation(legislationVals.variableTypes.AxleTolerance), getCurMeasurementSysRoundedPercentage(compareVal));
//    } else {
//        return doEval(evaluateLegislation(legislationVals.variableTypes.TotalTolerance), getCurMeasurementSysRoundedPercentage(compareVal));
//    }

//    function doEval(legislationVal, compareVal) {
//        if (compareVal <= 0) {
//            if (columName.indexOf(config.TABLE_COLUMNS.PUSHER) !== -1 || columName.indexOf(config.TABLE_COLUMNS.TAG) !== -1 || columName === config.TABLE_COLUMNS.REAR_CONNECTED) {
//                return config.DRAWING_COLOURS.LIGHT_GREEN;
//            } else {
//                return config.TABLE_HIGHLIGHT_COLOURS.OK;
//            }
            
//        } else if (compareVal > 0 && compareVal <= legislationVal) {
//            if (columName.indexOf(config.TABLE_COLUMNS.PUSHER) !== -1 || columName.indexOf(config.TABLE_COLUMNS.TAG) !== -1 || columName === config.TABLE_COLUMNS.REAR_CONNECTED) {
//                return config.DRAWING_COLOURS.LIGHT_ORANGE;
//            } else {
//                return config.TABLE_HIGHLIGHT_COLOURS.WARNING;
//            }
            
//        } else if (compareVal > legislationVal) {
//            if (columName.indexOf(config.TABLE_COLUMNS.PUSHER) !== -1 || columName.indexOf(config.TABLE_COLUMNS.TAG) !== -1 || columName === config.TABLE_COLUMNS.REAR_CONNECTED) {
//                return config.DRAWING_COLOURS.LIGHT_RED;
//            } else {
//                return config.TABLE_HIGHLIGHT_COLOURS.FAIL;
//            }
            
//        }
//        return null;
//    }
//}

function getTableCellHighlightColour(rigOpsToUse, encodedLegislationId, evaluationFunc, columnName, compareVal, refVal, rowName) {
    
    if (!tableCellHighlightColours) {
        tableCellHighlightColours = {};
    }

    if (tableCellHighlightColours[encodedLegislationId] !== undefined) {
        if (tableCellHighlightColours[encodedLegislationId][columnName] !== undefined) {
            return tableCellHighlightColours[encodedLegislationId][columnName];
        }
    } else {
        tableCellHighlightColours[encodedLegislationId] = {};
    }

    //rigOps.setLegislationEvaluationFunction(evaluationFunc);

    compareVal = compareVal * globals.user.getActiveMeasurementSystem().massConversion;
    refVal = refVal * globals.user.getActiveMeasurementSystem().massConversion;


    if (columnName === config.TABLE_COLUMNS.FRONT || columnName === config.TABLE_COLUMNS.REAR || columnName.indexOf(config.TABLE_COLUMNS.PUSHER) !== -1 || columnName.indexOf(config.TABLE_COLUMNS.TAG) !== -1 || columnName === config.TABLE_COLUMNS.REAR_CONNECTED || columnName === config.TABLE_COLUMNS.TRAILER_REAR || columnName === config.TABLE_COLUMNS.TRAILER_FRONT || columnName === config.TABLE_COLUMNS.TRAILER_1 || columnName === config.TABLE_COLUMNS.TRAILER_2) {
        if (columnName === config.TABLE_COLUMNS.FRONT && rowName !== undefined && rowName === 'GROSS') {
            if (getActiveOffer().getTrailer1() === null) {
                //commented out in relation to 15692/16016
                // if (evaluationFunc(legislationVals.variableTypes.ComVehSteerAxleMinimumApplies) === 1) {
                //     if (evaluationFunc('COMVEHSTEERAXLEDUALUNDER') === 1 || evaluationFunc('COMVEHSTEERAXLESINGLEUNDER') === 1) {
                //         tableCellHighlightColours[encodedLegislationId][columnName] = config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING;
                //         return tableCellHighlightColours[encodedLegislationId][columnName];
                //     }
                // }
            } else {
                if (parseFloat(compareVal.toFixed(config.roundingPrecisionForTableAndWarnings)) <= 0) {
                    if (evaluationFunc(legislationVals.variableTypes.ComVehSteerAxleMinimumApplies) === 1) {
                        var combinationType = rigOpsToUse.getCombinationType();
                        if (combinationType === config.COMBINATION_TYPES.VEHICLE_AND_SEMI) {
                            if ((rigOpsToUse.getVehicleOnlyFrontUnladen() + rigOpsToUse.getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle() +
                                rigOpsToUse.getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle()) < (evaluationFunc(legislationVals.variableTypes.MinimumOnSteeringAxleOfArticulatedPercent) / 100 * rigOpsToUse.getVehicleAndSemiOrInterlinkTotalGross())) {
                                tableCellHighlightColours[encodedLegislationId][columnName] = config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING;
                                return tableCellHighlightColours[encodedLegislationId][columnName];
                            } else {
                                tableCellHighlightColours[encodedLegislationId][columnName] = config.TABLE_HIGHLIGHT_COLOURS.OK;
                                return tableCellHighlightColours[encodedLegislationId][columnName];
                            }
                        } else if (combinationType === config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR || combinationType === config.COMBINATION_TYPES.VEHICLE_AND_PUP) {
                            if (evaluationFunc(legislationVals.variableTypes.ComVehSteerAxleDualUnder) === 1 && evaluationFunc(legislationVals.variableTypes.ComVehSteerAxleSingleUnder) === 1) {
                                tableCellHighlightColours[encodedLegislationId][columnName] = config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING;
                                return tableCellHighlightColours[encodedLegislationId][columnName];
                            } else {
                                tableCellHighlightColours[encodedLegislationId][columnName] = config.TABLE_HIGHLIGHT_COLOURS.OK;
                                return tableCellHighlightColours[encodedLegislationId][columnName];
                            }
                        }

                    } else {
                        tableCellHighlightColours[encodedLegislationId][columnName] = config.TABLE_HIGHLIGHT_COLOURS.OK;
                        return tableCellHighlightColours[encodedLegislationId][columnName];
                    }
                }
            }
        }
        tableCellHighlightColours[encodedLegislationId][columnName] = doEval(evaluationFunc(legislationVals.variableTypes.AxleTolerance), compareVal, refVal, columnName);
        return tableCellHighlightColours[encodedLegislationId][columnName];
    } else {
        tableCellHighlightColours[encodedLegislationId][columnName] = doEval(evaluationFunc(legislationVals.variableTypes.TotalTolerance), compareVal, refVal, columnName);
        return tableCellHighlightColours[encodedLegislationId][columnName];
    }

    //function getComparisonOverloadStatus(comparisonVal, referenceVal) {
    //    var overloaded = false;
    //    var status = config.OVERLOADING_STATUS.OK;
    //    overloaded = applyRoundingForManufacturerWeightsComplianceFailed(comparisonVal) > applyRoundingForManufacturerWeightsComplianceFailed(referenceVal);
    //    if (overloaded) {
    //        status = config.OVERLOADING_STATUS.OVERLOADED;
    //        //check if status is OVERLOADED or OVERLOADED_WITHIN_TOLERANCES for SA regs
    //        if (globals.user.getActiveLegislation().countryId === 1) {

    //        }

    //    }

    //    return status;
    //}

    
}

function getBridgeFormulaPermissibleHighlightColour(rigOpsToUse, encodedLegislationId, evaluationFunc, columnName, compareVal, refVal, rowName) {
    
    if (!bridgeFormulaPermissibleHighlightColours) {
        bridgeFormulaPermissibleHighlightColours = {};
    }

    if (bridgeFormulaPermissibleHighlightColours[encodedLegislationId] !== undefined) {
        if (bridgeFormulaPermissibleHighlightColours[encodedLegislationId][columnName] !== undefined) {
            return bridgeFormulaPermissibleHighlightColours[encodedLegislationId][columnName];
        }
    } else {
        bridgeFormulaPermissibleHighlightColours[encodedLegislationId] = {};
    }

    //rigOps.setLegislationEvaluationFunction(evaluationFunc);

    compareVal = compareVal * globals.user.getActiveMeasurementSystem().massConversion;
    refVal = refVal * globals.user.getActiveMeasurementSystem().massConversion;

    // return doEval(evaluationFunc(legislationVals.variableTypes.TotalTolerance), compareVal, refVal, columnName);

    if (columnName === config.TABLE_COLUMNS.FRONT || columnName === config.TABLE_COLUMNS.REAR || columnName.indexOf(config.TABLE_COLUMNS.PUSHER) !== -1 || columnName.indexOf(config.TABLE_COLUMNS.TAG) !== -1 || columnName === config.TABLE_COLUMNS.REAR_CONNECTED || columnName === config.TABLE_COLUMNS.TRAILER_REAR || columnName === config.TABLE_COLUMNS.TRAILER_FRONT || columnName === config.TABLE_COLUMNS.TRAILER_1 || columnName === config.TABLE_COLUMNS.TRAILER_2) {
        // if (columnName === config.TABLE_COLUMNS.FRONT && rowName !== undefined && rowName === 'GROSS') {
        //     if (getActiveOffer().getTrailer1() === null) {
        //         if (evaluationFunc(legislationVals.variableTypes.ComVehSteerAxleMinimumApplies) === 1) {
        //             if (evaluationFunc('COMVEHSTEERAXLEDUALUNDER') === 1 || evaluationFunc('COMVEHSTEERAXLESINGLEUNDER') === 1) {
        //                 bridgeFormulaPermissibleHighlightColours[encodedLegislationId][columnName] = config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING;
        //                 return bridgeFormulaPermissibleHighlightColours[encodedLegislationId][columnName];
        //             }
        //         }
        //     } else {
        //         if (parseFloat(compareVal.toFixed(config.roundingPrecisionForTableAndWarnings)) <= 0) {
        //             if (evaluationFunc(legislationVals.variableTypes.ComVehSteerAxleMinimumApplies) === 1) {
        //                 var combinationType = rigOpsToUse.getCombinationType();
        //                 if (combinationType === config.COMBINATION_TYPES.VEHICLE_AND_SEMI) {
        //                     if ((rigOpsToUse.getVehicleOnlyFrontUnladen() + rigOpsToUse.getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle() +
        //                         rigOpsToUse.getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle()) < (evaluationFunc(legislationVals.variableTypes.MinimumOnSteeringAxleOfArticulatedPercent) / 100 * rigOpsToUse.getVehicleAndSemiOrInterlinkTotalGross())) {
        //                             bridgeFormulaPermissibleHighlightColours[encodedLegislationId][columnName] = config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING;
        //                         return bridgeFormulaPermissibleHighlightColours[encodedLegislationId][columnName];
        //                     } else {
        //                         bridgeFormulaPermissibleHighlightColours[encodedLegislationId][columnName] = config.TABLE_HIGHLIGHT_COLOURS.OK;
        //                         return bridgeFormulaPermissibleHighlightColours[encodedLegislationId][columnName];
        //                     }
        //                 } else if (combinationType === config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR || combinationType === config.COMBINATION_TYPES.VEHICLE_AND_PUP) {
        //                     if (evaluationFunc(legislationVals.variableTypes.ComVehSteerAxleDualUnder) === 1 && evaluationFunc(legislationVals.variableTypes.ComVehSteerAxleSingleUnder) === 1) {
        //                         bridgeFormulaPermissibleHighlightColours[encodedLegislationId][columnName] = config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING;
        //                         return bridgeFormulaPermissibleHighlightColours[encodedLegislationId][columnName];
        //                     } else {
        //                         bridgeFormulaPermissibleHighlightColours[encodedLegislationId][columnName] = config.TABLE_HIGHLIGHT_COLOURS.OK;
        //                         return bridgeFormulaPermissibleHighlightColours[encodedLegislationId][columnName];
        //                     }
        //                 }

        //             } else {
        //                 bridgeFormulaPermissibleHighlightColours[encodedLegislationId][columnName] = config.TABLE_HIGHLIGHT_COLOURS.OK;
        //                 return bridgeFormulaPermissibleHighlightColours[encodedLegislationId][columnName];
        //             }
        //         }
        //     }
        // }
        bridgeFormulaPermissibleHighlightColours[encodedLegislationId][columnName] = doEval(evaluationFunc(legislationVals.variableTypes.AxleTolerance), compareVal, refVal, columnName);
        return bridgeFormulaPermissibleHighlightColours[encodedLegislationId][columnName];
    } else {
        bridgeFormulaPermissibleHighlightColours[encodedLegislationId][columnName] = doEval(evaluationFunc(legislationVals.variableTypes.TotalTolerance), compareVal, refVal, columnName);
        return bridgeFormulaPermissibleHighlightColours[encodedLegislationId][columnName];
    }

    

    
}

function getSteerAxleMinimumHighlightColour(rigOpsToUse, encodedLegislationId, evaluationFunc, columnName, compareVal, rowName) {

    if (!steerAxleMinimumHighlightColours) {
        steerAxleMinimumHighlightColours = {};
    }

    if (steerAxleMinimumHighlightColours[encodedLegislationId] !== undefined) {
        if (steerAxleMinimumHighlightColours[encodedLegislationId][columnName] !== undefined) {
            return steerAxleMinimumHighlightColours[encodedLegislationId][columnName];
        }
    } else {
        steerAxleMinimumHighlightColours[encodedLegislationId] = {};
    }

    //rigOps.setLegislationEvaluationFunction(evaluationFunc);

    // compareVal = compareVal * globals.user.getActiveMeasurementSystem().massConversion;
    // refVal = refVal * globals.user.getActiveMeasurementSystem().massConversion;

    if (columnName === config.TABLE_COLUMNS.FRONT && rowName !== undefined && rowName === 'GROSS') {
        if (getActiveOffer().getTrailer1() === null) {
            if (evaluationFunc(legislationVals.variableTypes.ComVehSteerAxleMinimumApplies) === 1) {
                if (evaluationFunc('COMVEHSTEERAXLEDUALUNDER') === 1 || evaluationFunc('COMVEHSTEERAXLESINGLEUNDER') === 1) {
                    steerAxleMinimumHighlightColours[encodedLegislationId][columnName] = config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING;
                    return steerAxleMinimumHighlightColours[encodedLegislationId][columnName];
                }
            }
        } else {
            if (parseFloat(compareVal.toFixed(config.roundingPrecisionForTableAndWarnings)) <= 0) {
                if (evaluationFunc(legislationVals.variableTypes.ComVehSteerAxleMinimumApplies) === 1) {
                    var combinationType = rigOpsToUse.getCombinationType();
                    if (combinationType === config.COMBINATION_TYPES.VEHICLE_AND_SEMI) {
                        if ((rigOpsToUse.getVehicleOnlyFrontUnladen() + rigOpsToUse.getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle() +
                            rigOpsToUse.getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle()) < (evaluationFunc(legislationVals.variableTypes.MinimumOnSteeringAxleOfArticulatedPercent) / 100 * rigOpsToUse.getVehicleAndSemiOrInterlinkTotalGross())) {
                                steerAxleMinimumHighlightColours[encodedLegislationId][columnName] = config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING;
                            return steerAxleMinimumHighlightColours[encodedLegislationId][columnName];
                        } else {
                            steerAxleMinimumHighlightColours[encodedLegislationId][columnName] = config.TABLE_HIGHLIGHT_COLOURS.OK;
                            return steerAxleMinimumHighlightColours[encodedLegislationId][columnName];
                        }
                    } else if (combinationType === config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR || combinationType === config.COMBINATION_TYPES.VEHICLE_AND_PUP) {
                        if (evaluationFunc(legislationVals.variableTypes.ComVehSteerAxleDualUnder) === 1 && evaluationFunc(legislationVals.variableTypes.ComVehSteerAxleSingleUnder) === 1) {
                            steerAxleMinimumHighlightColours[encodedLegislationId][columnName] = config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING;
                            return steerAxleMinimumHighlightColours[encodedLegislationId][columnName];
                        } else {
                            steerAxleMinimumHighlightColours[encodedLegislationId][columnName] = config.TABLE_HIGHLIGHT_COLOURS.OK;
                            return steerAxleMinimumHighlightColours[encodedLegislationId][columnName];
                        }
                    }

                } else {
                    steerAxleMinimumHighlightColours[encodedLegislationId][columnName] = config.TABLE_HIGHLIGHT_COLOURS.OK;
                    return steerAxleMinimumHighlightColours[encodedLegislationId][columnName];
                }
            }
        }
        
    }
    steerAxleMinimumHighlightColours[encodedLegislationId][columnName] = config.TABLE_HIGHLIGHT_COLOURS.OK;
    return steerAxleMinimumHighlightColours[encodedLegislationId][columnName];
}

function doEval(legislationVal, compareVal, refVal, columnName) {
        
    var roundCompareVal = applyRounding(compareVal);
    var roundedRefVal = applyRounding(refVal);
    var overloaded = roundCompareVal > roundedRefVal;
    

    if (overloaded) {
        var legislationPercentageAsUnitVal = applyRounding(refVal * (legislationVal/100));
        if (roundCompareVal > 0 && (roundCompareVal - roundedRefVal) <= legislationPercentageAsUnitVal) {
            if (columnName.indexOf(config.TABLE_COLUMNS.PUSHER) !== -1 || columnName.indexOf(config.TABLE_COLUMNS.TAG) !== -1 || columnName === config.TABLE_COLUMNS.REAR_CONNECTED) {
                return config.DRAWING_COLOURS.LIGHT_ORANGE;
            } else {
                return config.TABLE_HIGHLIGHT_COLOURS.WARNING;
            }

        } else if (roundCompareVal - roundedRefVal > legislationPercentageAsUnitVal) {
            if (columnName.indexOf(config.TABLE_COLUMNS.PUSHER) !== -1 || columnName.indexOf(config.TABLE_COLUMNS.TAG) !== -1 || columnName === config.TABLE_COLUMNS.REAR_CONNECTED) {
                return config.DRAWING_COLOURS.LIGHT_RED;
            } else {
                return config.TABLE_HIGHLIGHT_COLOURS.FAIL;
            }

        }

    }
    if (columnName.indexOf(config.TABLE_COLUMNS.PUSHER) !== -1 || columnName.indexOf(config.TABLE_COLUMNS.TAG) !== -1 || columnName === config.TABLE_COLUMNS.REAR_CONNECTED) {
        return config.DRAWING_COLOURS.LIGHT_GREEN;
    } else {
        return config.TABLE_HIGHLIGHT_COLOURS.OK;
    } 
    
}
function applyRounding(valToRound) {
    return parseFloat(valToRound.toFixed(config.roundingPrecisionForTableAndWarnings));
}

function getTrailer1DrawbarLengthEqualToDrawbarLegislationLength() {
    let tempTrailer1 = getActiveOffer().getRig().getTrailer1();
    if(tempTrailer1) {
        return getCurMeasurementSysRoundedLength(tempTrailer1.getDrawbarLength()) !== getCurMeasurementSysRoundedLength(getLesserOfActiveLegislationVals(legislationVals.variableTypes.Trailer1DrawbarLengthLegislation));
    }
}

function setActiveLegislationObjectsOnOfferManagerAndActiveOfferAndUpdateInterpreter(legislationDetails) {
    //dataManager.getLegislationDetails(legislationStub.legislationId, legislationStub.countryId)
    //    .then(function (legislationDetails) {
    //        var tempLegislationObject = new Legislation(legislationDetails);
    //        if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
    //            getActiveOffer().setLegislation(tempLegislationObject);
    //        }
    //        setLegislation(tempLegislationObject);
    //        interpreter = new Interpreter(getLegislation());
    //    })
    //    .fail(function (errorMessage) {
    //        //should not fail as activeLegislation should not be set to noe that has not been retrieved
    //    });
    var tempLegislationObject = new Legislation(legislationDetails);
    if (getActiveOffer() !== undefined && getActiveOffer() !== '') {
        getActiveOffer().setLegislation(tempLegislationObject);
    }
    setLegislation(tempLegislationObject);
    //interpreter = new Interpreter(getLegislation());
}

function setSpinnerCallback(spinnerCallbackFunction) {
    showShellSpinner = spinnerCallbackFunction;
}

function isNotCompleteVehicle() {
    return getActiveOffer().getConfiguration().getType() !== config.VEHICLE_TYPES.COMPLETE_VEHICLE;
    //return false;
}

function isNotStandardVehicle() {
    return getActiveOffer().getConfiguration().getSource() !== config.VEHICLE_SOURCE_TYPES.STANDARD;
    //return false;
}

function isStandardVehicle() {
    return getActiveOffer().getConfiguration().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD;
    //return false;
}

function isNotStandardVehicleAndNotLoadingAdmin() {
    return getActiveOffer().getConfiguration().getSource() !== config.VEHICLE_SOURCE_TYPES.STANDARD && globals.user.isLoadingAdministrator();
}

function isNotStandardVehicleOrIsStandardVehicleAndShowHigherMassLimitsIsTrue() {
    return getActiveOffer().getConfiguration().getSource() !== config.VEHICLE_SOURCE_TYPES.STANDARD || (getActiveOffer().getConfiguration().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && getLesserOfActiveLegislationVals(legislationVals.variableTypes.ShowHigherMassLimitsOption) === 1);
    //return false;
}

function hideForStandardVehicleUnlessLoadingAdmin() {
    //if (getActiveOffer().getConfiguration().getSource() !== config.VEHICLE_SOURCE_TYPES.STANDARD) {
    //    return true;
    //}
    if ((getActiveOffer().getConfiguration().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && !globals.user.isLoadingAdministrator()) || getActiveOffer().getConfiguration().getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
        return false;
    } else {
        return true;
    }
}
    

function showUnlessStandardAndNotAdmin() {
    return !(getActiveOffer().getConfiguration().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && !globals.user.isLoadingAdministrator());
}

function showIfStandardAndAdmin() {
    return getActiveOffer().getConfiguration().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && globals.user.isLoadingAdministrator();
}

function showUnlessStandardAndNotAdminAndEnergySourceNotBatteryElectric() {
    
    return showUnlessStandardAndNotAdmin() && hideForBatteryElectric();
}

function hideForBatteryElectric() {
    return getActiveOffer().getRig().getVehicle().getFuelType() !== config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE;
}

function showUnlessTrailerStandardAndNotAdmin(path) {
    if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        return !(getActiveOffer().getTrailer1().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && !globals.user.isLoadingAdministrator());
    } else {
        return !(getActiveOffer().getTrailer2().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && !globals.user.isLoadingAdministrator());
    }

}

function showUnlessTrailerStandardOrCustomWithGraphicAndNotAdmin(path) {

    return !(isTrailerStandardOrCustomWithGraphic(path) && !globals.user.isLoadingAdministrator());
}

function energySourceIsNotBatteryElectric() {
    return getActiveOffer().getRig().getVehicle().getFuelType() !== config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE;
}

function showAdBlueSpecficGravity() {
    return getActiveOffer().getRig().getVehicle().countFuelTankType(config.FUEL_TYPE_OPTIONS.ADBLUE.CODE) > 0;
}

function showOverrideForStandardTrailer(path) {

    
    if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        return getActiveOffer().getTrailer1().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD;
    } else {
        return getActiveOffer().getTrailer2().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD;
    }
}

function showIfTrailerStandardAndAdmin(path) {
    if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        return getActiveOffer().getTrailer1().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && globals.user.isLoadingAdministrator();
    } else {
        return getActiveOffer().getTrailer2().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && globals.user.isLoadingAdministrator();
    }

}

function showIfTrailerStandardOrCustomWithGraphicAndAdmin(path) {
    if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        return (getActiveOffer().getTrailer1().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (getActiveOffer().getTrailer1().getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(getActiveOffer().getTrailer1()))) && globals.user.isLoadingAdministrator();
    } else {
        return (getActiveOffer().getTrailer2().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (getActiveOffer().getTrailer2().getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(getActiveOffer().getTrailer2()))) && globals.user.isLoadingAdministrator();
    }

}

function showUnlessTrailerStandard(path) {
    if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        return !(getActiveOffer().getTrailer1().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD);
    } else {
        return !(getActiveOffer().getTrailer2().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD);
    }
}

function showUnlessTrailerStandardOrCustomWithGraphic(path) {
    if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        return !((getActiveOffer().getTrailer1().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) || (getActiveOffer().getTrailer1().getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(getActiveOffer().getTrailer1())));
    } else {
        return !((getActiveOffer().getTrailer2().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) || (getActiveOffer().getTrailer2().getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(getActiveOffer().getTrailer2())));
    }
}

function isTrailerStandardOrCustomWithGraphic(path) {
    if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        return ((getActiveOffer().getTrailer1().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) || (getActiveOffer().getTrailer1().getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(getActiveOffer().getTrailer1())));
    } else {
        return ((getActiveOffer().getTrailer2().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) || (getActiveOffer().getTrailer2().getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(getActiveOffer().getTrailer2())));
    }
}

function showTrailerAxleSection(path, sectionName) {
    var trailer;
    if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        trailer = getActiveOffer().getTrailer1();
    } else {
        trailer = getActiveOffer().getTrailer2();
    }

    var sectionLengthVal;
    switch (sectionName) {
        case config.VALUE_TYPE.TRAILERAXLE1FRONTTOAXLE2FRONT:
            sectionLengthVal = trailer.getAxle1FrontToAxle2Front();
            break;
        case config.VALUE_TYPE.TRAILERAXLE2FRONTTOAXLE3FRONT:
            sectionLengthVal = trailer.getAxle2FrontToAxle3Front();
            break;
        case config.VALUE_TYPE.TRAILERAXLE1REARTOAXLE2REAR:
            sectionLengthVal = trailer.getAxle1RearToAxle2Rear();
            break;
        case config.VALUE_TYPE.TRAILERAXLE2REARTOAXLE3REAR:
            sectionLengthVal = trailer.getAxle2RearToAxle3Rear();
            break;
        case config.VALUE_TYPE.TRAILERAXLE3REARTOAXLE4REAR:
            sectionLengthVal = trailer.getAxle3RearToAxle4Rear();
            break;
    }

    //return showUnlessTrailerStandardOrCustomWithGraphic(path) && sectionLengthVal > 0;
    return sectionLengthVal > 0;
}



function readOnlyIfStandardAndNotAdmin() {
    return getActiveOffer().getConfiguration().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && !globals.user.isLoadingAdministrator()
}

function showUnlessStandardBodyAndNotAdmin(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);
    return !(tempBody.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && !globals.user.isLoadingAdministrator());
}

function showUnlessStandardBodyAndNotAdminOrIsTemplateBodyOrIsCustomBody(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    return !((tempBody.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && !globals.user.isLoadingAdministrator()) || tempBody.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || tempBody.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM);
}
function showOverrideForStandardBody(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    return tempBody.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD;
}

function showUnlessStandardCraneAndNotAdmin(attrPath) {

    var tempCrane = rigOps.getCraneFromPath(attrPath);

    return !(tempCrane.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && !globals.user.isLoadingAdministrator());
}

function showUnlessStandardCraneAndNotAdminOrIsTemplateCraneOrIsCustomCrane(attrPath) {

    var tempCrane = rigOps.getCraneFromPath(attrPath);

    return !((tempCrane.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && !globals.user.isLoadingAdministrator()) || tempCrane.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || tempCrane.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM);
}
function showOverrideForStandardCrane(attrPath) {

    var tempCrane = rigOps.getCraneFromPath(attrPath);

    return tempCrane.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD;
}
function showUnlessStandardOtherAndNotAdmin(attrPath, expectedIdPos) {

    var otherId = globals.getIdOrNumberFromPath(attrPath, expectedIdPos);

    var tempOther = rigOps.getOtherFromPathById(attrPath, otherId);

    return !(tempOther.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && !globals.user.isLoadingAdministrator());
}

function showUnlessStandardOtherAndNotAdminOrIsTemplateOtherOrIsCustomOther(attrPath, expectedIdPos) {

    var otherId = globals.getIdOrNumberFromPath(attrPath, expectedIdPos);

    var tempOther = rigOps.getOtherFromPathById(attrPath, otherId);

    return !((tempOther.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && !globals.user.isLoadingAdministrator()) || tempOther.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || tempOther.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM);
}

function showOverrideForStandardOther(attrPath, expectedIdPos) {

    var otherId = globals.getIdOrNumberFromPath(attrPath, expectedIdPos);

    var tempOther = rigOps.getOtherFromPathById(attrPath, otherId);

    return tempOther.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD;
}

/**
    * Show hooklift mass override if Standard only
    * @param {string} attrPath - Path to the 
    * @returns {boolean} 
    */
function showOverrideForStandardHooklift(attrPath) {
    var tempHooklift = rigOps.getHookliftFromPath(attrPath);
    return tempHooklift.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD;
}

/**
    * Show the override if the hooklift is attached to a vehicle.
    * TBD - Update to match VS -> When Specify Equipment Position As = From Frontmost Steering Axle and Hooklift is not on Trailer
    * @param {string} attrPath - Path to hooklift
    */
function showOverrideForHooklift(attrPath) {
    if (attrPath.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1 || attrPath.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        return false;
    } else {
        return true;
    }
}

///**
// * Show the override if the hooklift is attached to a vehicle.
// * TBD - Update to match VS -> When Specify Equipment Position As = From Frontmost Steering Axle and Hooklift is not on Trailer
// * @param {string} attrPath - Path to hooklift
// */
//function showCabGapOverrideForHooklift(attrPath) {
//    if (attrPath.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1 || attrPath.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
//        return false;
//    } else {
//        return true;
//    }
//}

function showUnlessStandardFridgeAndNotAdmin(attrPath) {

    var tempFridge = rigOps.getFridgeFromPath(attrPath);

    return !(tempFridge.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && !globals.user.isLoadingAdministrator());
}

function showUnlessStandardFridgeAndNotAdminOrIsTemplateFridgeOrIsCustomFridge(attrPath) {

    var tempFridge = rigOps.getFridgeFromPath(attrPath);

    return !((tempFridge.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && !globals.user.isLoadingAdministrator()) || tempFridge.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || tempFridge.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM);
}

function showOverrideForStandardFridge(attrPath) {

    var tempFridge = rigOps.getFridgeFromPath(attrPath);

    return tempFridge.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD;
}

function showUnlessStandardTailliftAndNotAdmin(attrPath) {

    var tempTaillift = rigOps.getTailliftFromPath(attrPath);

    return !(tempTaillift.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && !globals.user.isLoadingAdministrator());
}

function showUnlessStandardTailliftAndNotAdminOrIsTemplateTailliftOrIsCustomTaillift(attrPath) {

    var tempTaillift = rigOps.getTailliftFromPath(attrPath);

    return !((tempTaillift.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && !globals.user.isLoadingAdministrator()) || tempTaillift.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || tempTaillift.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM);
}

function showOverrideForStandardTaillift(attrPath) {

    var tempTaillift = rigOps.getTailliftFromPath(attrPath);

    return tempTaillift.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD;
}

function showUnlessStandardFifthWheelAndNotAdmin() {
    return !(getActiveOffer().getConfiguration().getAccessoryHolder().getFifthWheel().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && !globals.user.isLoadingAdministrator());
}

function showUnlessStandardFifthWheelAndNotAdminOrIsTemplateFifthWheelOrIsCustomFifthWheel() {
    return !((getActiveOffer().getConfiguration().getAccessoryHolder().getFifthWheel().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && !globals.user.isLoadingAdministrator()) || getActiveOffer().getConfiguration().getAccessoryHolder().getFifthWheel().getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || getActiveOffer().getConfiguration().getAccessoryHolder().getFifthWheel().getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM);
}

function showOverrideForStandardFifthWheel() {
    return getActiveOffer().getConfiguration().getAccessoryHolder().getFifthWheel().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD;
}

function showUnlessStandardHitchAndNotAdmin() {
    return !(getActiveOffer().getConfiguration().getAccessoryHolder().getHitch().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && !globals.user.isLoadingAdministrator());
}

function showUnlessStandardHitchAndNotAdminOrIsTemplateHitchOrIsCustomHitch() {
    return !((getActiveOffer().getConfiguration().getAccessoryHolder().getHitch().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && !globals.user.isLoadingAdministrator()) || getActiveOffer().getConfiguration().getAccessoryHolder().getHitch().getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || getActiveOffer().getConfiguration().getAccessoryHolder().getHitch().getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM);
}

function showOverrideForStandardHitch() {
    return getActiveOffer().getConfiguration().getAccessoryHolder().getHitch().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD;
}

function isStandardVehicleAndLoadingAdminOrIsTemplateVehicle() {
    return (getActiveOffer().getConfiguration().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && globals.user.isLoadingAdministrator()) || getActiveOffer().getConfiguration().getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE;
}

function isTemplateVehicleOrStandardVehicleAndNotLoadingAdmin() {
    return isTemplateVehicle() || (!isNotStandardVehicle() && !globals.user.isLoadingAdministrator())
}

function isTemplateVehicleOrIsStandardVehicleAndIsLoadingAdmin() {
    return isTemplateVehicle() || isStandardVehicleAndIsLoadingAdmin();
}

function isStandardVehicleAndIsLoadingAdmin() {
    return !isNotStandardVehicle() && globals.user.isLoadingAdministrator();
}

function showCombinationPermissibleRelatedAttribbuteOnTotalCard(attrPath) {
    return isStandardVehicleAndLoadingAdminOrIsTemplateVehicle(attrPath) && rigOps.getCombinationType() !== config.COMBINATION_TYPES.VEHICLE; 
}

function showSteeringAngleInner() {
    return ((getConfiguration().getTurningAbilitySpecifiedAs() === config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER || rigOps.getVehicleWheelbaseTurningChanged() || getConfiguration().getWheelbaseOverride() && !turningCircleLockedToSteeringAngleInner) || (getConfiguration().getTurningAbilitySpecifiedAs() !== config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER && turningCircleLockedToSteeringAngleInner)) && isTemplateVehicleOrIsStandardVehicleAndIsLoadingAdmin();
}

function showTurningRadiusCurbToCurb() {
    return getConfiguration().getTurningAbilitySpecifiedAs() === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB && isTemplateVehicleOrIsStandardVehicleAndIsLoadingAdmin() && !rigOps.getVehicleWheelbaseTurningChanged() && !showSteeringAngleInner();
}

function showTurningRadiusWallToWall() {
    return getConfiguration().getTurningAbilitySpecifiedAs() === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL && isTemplateVehicleOrIsStandardVehicleAndIsLoadingAdmin() && !rigOps.getVehicleWheelbaseTurningChanged() && !showSteeringAngleInner();
}

function showCrewSecondRowOption() {
    if (getConfiguration().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
        return getConfiguration().getCrewSecondRowIncluded();
    } else {
        return true;
    }

}

function showCrewSecondRowSection() {
    var crewSecondRow = getAttributeGroup('VEHICLE.' + config.VALUE_TYPE.CREWSECONDROW);
    if (crewSecondRow !== null) {
        return crewSecondRow.value.value;
    } else {
        return false;
    }

}

function isTemplateVehicleAndShowSecondRow() {
    return showCrewSecondRowSection() && (isTemplateVehicle() || (!isNotStandardVehicle() && globals.user.isLoadingAdministrator()));
}

function isStandardVehicleAndIsLoadingAdminAndShowSecondRow() {
    return showCrewSecondRowSection() && isStandardVehicleAndIsLoadingAdmin();
}

function showAxle1FrontToAxle2Front() {
    return showUnlessStandardAndNotAdmin() && getConfiguration().getAxlesHolder().getAxle2Front() !== null;
}

function showAxle1RearToAxle2Rear() {
    return showUnlessStandardAndNotAdmin() && getConfiguration().getAxlesHolder().getAxle2Rear() !== null;
}

function showAxle2RearToAxle3Rear() {
    return showUnlessStandardAndNotAdmin() && getConfiguration().getAxlesHolder().getAxle3Rear() !== null;
}

function showAxle3RearToAxle4Rear() {
    return showUnlessStandardAndNotAdmin() && getConfiguration().getAxlesHolder().getAxle4Rear() !== null;
}

function showWheelbaseTheoretical() {

    return true;
}

function showAxle1FrontToWheelbaseTheoreticalStart() {
    //return showUnlessStandardAndNotAdmin() && !(vehicleAxleLayout.value === '4X2' && getConfiguration().getAxle1RearToAxle2Rear() === 0);
    //return isNotStandardVehicle() && getConfiguration().getAxle1FrontToWheelbaseTheoreticalStart() !== 0;
    return showUnlessStandardAndNotAdmin() && getConfiguration().getAxle1FrontToAxle2Front() > 0;
}

function isFifthWheelIncludedOnVehicle() {
    if (getActiveOffer().getRig().getVehicle().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
        return true;
    } else {
        return false;
    }
}

function isTemplateVehicle() {
    return getActiveOffer().getConfiguration().getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE;
}

function isTemplateBody(attrPath) {

    var tempBody = rigOps.getBodyFromPath(attrPath);

    if (tempBody !== null) {
        return tempBody.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || tempBody.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM;
    } else {
        return false;
    }
}

function isTemplateOnlyBody(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    return tempBody.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE;
}

function isTemplateOrCustomBody(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    if (tempBody !== null) {
        return tempBody.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || tempBody.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM;
    } else {
        return false;
    }
}

function isTemplateOrCustomBodyWithoutGraphic(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    if (tempBody !== null) {
        return tempBody.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || (tempBody.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && !utils.itemHasGraphic(tempBody));
    } else {
        return false;
    }
}

function isCustomBodyWithGraphic(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    if (tempBody !== null) {
        return tempBody.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasValidSideViewGraphic(tempBody);
    } else {
        return false;
    }
}

function isTemplateOrCustomBeaverTailBody(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    return tempBody.getType() === config.BODY_TYPES.BEAVERTAIL && isTemplateOrCustomBody(attrPath);
}

function isTemplateOrCustomBeaverTailBodyWithoutGraphic(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    return tempBody.getType() === config.BODY_TYPES.BEAVERTAIL && isTemplateOrCustomBodyWithoutGraphic(attrPath);
}

function isCustomBeaverTailBodyWithGraphic(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    return tempBody.getType() === config.BODY_TYPES.BEAVERTAIL && isCustomBodyWithGraphic(attrPath);
}

function isTemplateOrCustomHookliftBody(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    return tempBody.getType() === config.BODY_TYPES.HOOKLIFT && isTemplateOrCustomBody(attrPath);
}

function isTemplateOrCustomHookliftBodyWithoutGraphic(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    return tempBody.getType() === config.BODY_TYPES.HOOKLIFT && isTemplateOrCustomBodyWithoutGraphic(attrPath);
}

function isHookliftBody(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    return tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY;
}

function isTemplateAccessory(path, accessoryType) {
    switch (accessoryType) {
        case config.ACCESSORY_TYPES.CRANE:
            return isTemplateCrane(path);
        case config.ACCESSORY_TYPES.FRIDGE:
            return isTemplateFridge(path);
        case config.ACCESSORY_TYPES.TAILLIFT:
            return isTemplateTaillift(path);
        case config.ACCESSORY_TYPES.OTHER:
            return isTemplateOther(path, 1);
        case config.ACCESSORY_TYPES.FIFTH_WHEEL:
            return isTemplateFifthWheel(path);
        case config.ACCESSORY_TYPES.HITCH:
            return isTemplateHitch(path);
        case config.ACCESSORY_TYPES.HOOKLIFT:
            return isTemplateHitch(path);
    }
}

function isTemplateOrCustomAccessory(path, accessoryType) {
    switch (accessoryType) {
        case config.ACCESSORY_TYPES.CRANE:
            return isTemplateOrCustomCrane(path);
        case config.ACCESSORY_TYPES.FRIDGE:
            return isTemplateOrCustomFridge(path);
        case config.ACCESSORY_TYPES.TAILLIFT:
            return isTemplateOrCustomTaillift(path);
        case config.ACCESSORY_TYPES.OTHER:
            return isTemplateOrCustomOther(path, 1);
        case config.ACCESSORY_TYPES.FIFTH_WHEEL:
            return isTemplateOrCustomFifthWheel(path);
        case config.ACCESSORY_TYPES.HITCH:
            return isTemplateOrCustomHitch(path);
        case config.ACCESSORY_TYPES.HOOKLIFT:
            return isTemplateOrCustomHitch(path);
        case config.ACCESSORY_TYPES.PAYLOAD:
            return isTemplateOrCustomPayload(path, 1);
    }
}

function isCustomAccessoryWithGraphic(path, accessoryType) {
    switch (accessoryType) {
        case config.ACCESSORY_TYPES.CRANE:
            //return isTemplateOrCustomCrane(path);
            return isCustomCraneWithGraphic(path);
        case config.ACCESSORY_TYPES.FRIDGE:
            //return isTemplateOrCustomFridge(path);
            return isCustomFridgeWithGraphic(path);
        case config.ACCESSORY_TYPES.TAILLIFT:
            //return isTemplateOrCustomTaillift(path);
            return isCustomTailliftWithGraphic(path);
        case config.ACCESSORY_TYPES.OTHER:
            //return isTemplateOrCustomOther(path, 1);
            return isCustomOtherWithGraphic(path, 1);
        case config.ACCESSORY_TYPES.FIFTH_WHEEL:
            //return isTemplateOrCustomFifthWheel(path);
            return isCustomFifthWheelWithGraphic(path);
        case config.ACCESSORY_TYPES.HITCH:
            //return isTemplateOrCustomHitch(path);
            return isCustomHitchWithGraphic(path);
        case config.ACCESSORY_TYPES.HOOKLIFT:
            //return isTemplateOrCustomHitch(path);
            return isCustomHookliftWithGraphic(path);
        case config.ACCESSORY_TYPES.PAYLOAD:
            //return isTemplateOrCustomPayload(path, 1);
            return isCustomPayloadWithGraphic(path, 1);
    }
}

function isCustomItemWithDXFImportedGraphic(path) {
    let accessoryType = rigOps.getAccessoryTypeFromPath(path);
    if(accessoryType !== config.ACCESSORY_TYPES.BODY) {
        return isCustomAccessoryWithGraphic(path, accessoryType) && !getObjectReadOnlyPermission(rigOps.getAccessoryFromPath(path), config.MENU_SECTION.SPECIFICATION)(path);
    } else if(accessoryType === config.ACCESSORY_TYPES.BODY) {
        return isCustomBodyWithGraphic(path) && !getObjectReadOnlyPermission(rigOps.getAccessoryFromPath(path), config.MENU_SECTION.SPECIFICATION)(path);
    }
    return false;
}

function isTemplateCrane(path) {

    var tempCrane = rigOps.getCraneFromPath(path);

    if (tempCrane !== null) {
        return tempCrane.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE;
    } else {
        return false;
    }
}

function isTemplateOrCustomCrane(path) {

    var tempCrane = rigOps.getCraneFromPath(path);

    if (tempCrane !== null) {
        return tempCrane.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || tempCrane.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM;
    } else {
        return false;
    }
}

function isTemplateOrCustomCraneWithoutGraphic(path) {

    var tempCrane = rigOps.getCraneFromPath(path);

    if (tempCrane !== null) {
        return tempCrane.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || (tempCrane.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && !utils.itemHasGraphic(tempCrane));
    } else {
        return false;
    }
}

function isCustomCraneWithGraphic(path) {
    var tempCrane = rigOps.getCraneFromPath(path);

    if (tempCrane !== null) {
        return tempCrane.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasValidSideViewGraphic(tempCrane);
    } else {
        return false;
    }
}

function isTemplateOther(path, expectedIdPos) {
    var otherId = globals.getIdOrNumberFromPath(path, expectedIdPos);

    var tempOther = rigOps.getOtherFromPathById(path, otherId);

    if (tempOther !== null) {
        return tempOther.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE;
    } else {
        return false;
    }
}

function isTemplateOrCustomOther(path, expectedIdPos) {
    var otherId = globals.getIdOrNumberFromPath(path, expectedIdPos);

    var tempOther = rigOps.getOtherFromPathById(path, otherId);

    if (tempOther !== null) {
        return tempOther.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || tempOther.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM;
    } else {
        return false;
    }
}

function isTemplateOrCustomOtherWithoutGraphic(path, expectedIdPos) {
    var otherId = globals.getIdOrNumberFromPath(path, expectedIdPos);

    var tempOther = rigOps.getOtherFromPathById(path, otherId);

    if (tempOther !== null) {
        return tempOther.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || (tempOther.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && !utils.itemHasGraphic(tempOther));
    } else {
        return false;
    }
}

function isCustomOtherWithGraphic(path, expectedIdPos) {
    var otherId = globals.getIdOrNumberFromPath(path, expectedIdPos);

    var tempOther = rigOps.getOtherFromPathById(path, otherId);

    if (tempOther !== null) {
        return tempOther.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasValidSideViewGraphic(tempOther);
    } else {
        return false;
    }
}

function isTemplateOrCustomPayload(path, expectedIdPos) {
    var payloadId = globals.getIdOrNumberFromPath(path, expectedIdPos);

    var tempPayload = rigOps.getPayloadFromPathById(path, payloadId);

    if (tempPayload !== null) {
        return tempPayload.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || tempPayload.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM;
    } else {
        return false;
    }
}

function isCustomPayloadWithGraphic(path, expectedIdPos) {
    var payloadId = globals.getIdOrNumberFromPath(path, expectedIdPos);

    var tempPayload = rigOps.getPayloadFromPathById(path, payloadId);

    if (tempPayload !== null) {
        return tempPayload.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasValidSideViewGraphic(tempPayload)
    } else {
        return false;
    }
}

function isTemplateFridge(attrPath) {

    var tempFridge = rigOps.getFridgeFromPath(attrPath)

    if (tempFridge !== null) {
        return tempFridge.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE;
    } else {
        return false;
    }
}

function isTemplateOrCustomFridge(attrPath) {

    var tempFridge = rigOps.getFridgeFromPath(attrPath)

    if (tempFridge !== null) {
        return tempFridge.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || tempFridge.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM;
    } else {
        return false;
    }
}

function isTemplateOrCustomFridgeWithoutGraphic(attrPath) {

    var tempFridge = rigOps.getFridgeFromPath(attrPath)

    if (tempFridge !== null) {
        return tempFridge.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || (tempFridge.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && !utils.itemHasGraphic(tempFridge));
    } else {
        return false;
    }
}

function isCustomFridgeWithGraphic(attrPath) {
    var tempFridge = rigOps.getFridgeFromPath(attrPath)

    if (tempFridge !== null) {
        return tempFridge.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasValidSideViewGraphic(tempFridge);
    } else {
        return false;
    }
}

function isTemplateTaillift(attrPath) {

    var tempTaillift = rigOps.getTailliftFromPath(attrPath);

    if (tempTaillift !== null) {
        return tempTaillift.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE;
    } else {
        return false;
    }
}

function isTemplateOrCustomTaillift(attrPath) {

    var tempTaillift = rigOps.getTailliftFromPath(attrPath);

    if (tempTaillift !== null) {
        return tempTaillift.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || tempTaillift.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM;
    } else {
        return false;
    }
}

function isTemplateOrCustomTailliftWithoutGraphic(attrPath) {

    var tempTaillift = rigOps.getTailliftFromPath(attrPath);

    if (tempTaillift !== null) {
        return tempTaillift.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || (tempTaillift.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && !utils.itemHasGraphic(tempTaillift));
    } else {
        return false;
    }
}

function isCustomTailliftWithGraphic(attrPath) {
    var tempTaillift = rigOps.getTailliftFromPath(attrPath);

    if (tempTaillift !== null) {
        return tempTaillift.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasValidSideViewGraphic(tempTaillift);
    } else {
        return false;
    }
}

function isTemplateOrCustomTailliftAndNotUpperBox(attrPath) {

    var tempTaillift = rigOps.getTailliftFromPath(attrPath);

    return isTemplateOrCustomTaillift(attrPath) && tempTaillift.getType() !== config.TAILLIFT_TYPES.COLUMN_UPPER_BOX;
}

function isTemplateOrCustomTailliftWithoutGraphicAndNotUpperBox(attrPath) {

    var tempTaillift = rigOps.getTailliftFromPath(attrPath);

    return isTemplateOrCustomTailliftWithoutGraphic(attrPath) && tempTaillift.getType() !== config.TAILLIFT_TYPES.COLUMN_UPPER_BOX;
}

function isNotUpperBox(attrPath) {

    var tempTaillift = rigOps.getTailliftFromPath(attrPath);

    return tempTaillift.getType() !== config.TAILLIFT_TYPES.COLUMN_UPPER_BOX;
}

function isTemplateOrCustomFifthWheel() {
    //#unfinished, will need to be updated when supporting fifth wheel on trailer
    if (getActiveOffer().getConfiguration().getAccessoryHolder().getFifthWheel() !== null) {
        return getActiveOffer().getConfiguration().getAccessoryHolder().getFifthWheel().getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || getActiveOffer().getConfiguration().getAccessoryHolder().getFifthWheel().getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM;
    } else {
        return false;
    }
}

function isTemplateOrCustomFifthWheelWithoutGraphic() {
    //#unfinished, will need to be updated when supporting fifth wheel on trailer

    var tempFifthWheel = getActiveOffer().getConfiguration().getAccessoryHolder().getFifthWheel();

    if (tempFifthWheel !== null) {
        return tempFifthWheel.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || (tempFifthWheel.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && !utils.itemHasGraphic(tempFifthWheel));
    } else {
        return false;
    }
}

function isCustomFifthWheelWithGraphic() {
    //#unfinished, will need to be updated when supporting fifth wheel on trailer
    var tempFifthWheel = getActiveOffer().getConfiguration().getAccessoryHolder().getFifthWheel();

    if (tempFifthWheel !== null) {
        return tempFifthWheel.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasValidSideViewGraphic(tempFifthWheel);
    } else {
        return false;
    }
}

function isTemplateFifthWheel() {
    if (getActiveOffer().getConfiguration().getAccessoryHolder().getFifthWheel() !== null) {
        return getActiveOffer().getConfiguration().getAccessoryHolder().getFifthWheel().getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE;
    } else {
        return false;
    }
}

function isTemplateOrCustomHitch() {
    //#unfinished, will need to be updated when supporting hitch on trailer
    if (getActiveOffer().getConfiguration().getAccessoryHolder().getHitch() !== null) {
        return getActiveOffer().getConfiguration().getAccessoryHolder().getHitch().getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || getActiveOffer().getConfiguration().getAccessoryHolder().getHitch().getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM;
    } else {
        return false;
    }
}

function isTemplateOrCustomHitchWithoutGraphic() {
    //#unfinished, will need to be updated when supporting hitch on trailer

    var tempHitch = getActiveOffer().getConfiguration().getAccessoryHolder().getHitch();

    if (tempHitch !== null) {
        return tempHitch.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || (tempHitch.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && !utils.itemHasGraphic(tempHitch));
    } else {
        return false;
    }
}

function isCustomHitchWithGraphic() {
    //#unfinished, will need to be updated when supporting hitch on trailer
    var tempHitch = getActiveOffer().getConfiguration().getAccessoryHolder().getHitch();

    if (tempHitch !== null) {
        return tempHitch.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasValidSideViewGraphic(tempHitch);
    } else {
        return false;
    }
}

function isCustomHookliftWithGraphic() {
    var tempHooklift = getActiveOffer().getConfiguration().getAccessoryHolder().getHooklift();

    if (tempHooklift !== null) {
        return tempHooklift.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasValidSideViewGraphic(tempHooklift);
    } else {
        return false;
    }
}

function isTemplateHitch() {
    //#unfinished, will need to be updated when hitch added to trailer
    if (getActiveOffer().getConfiguration().getAccessoryHolder().getHitch() !== null) {
        return getActiveOffer().getConfiguration().getAccessoryHolder().getHitch().getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE;
    } else {
        return false;
    }
}

function isTemplateVanBody(attrPath) {

    var tempBody = rigOps.getBodyFromPath(attrPath);

    if (tempBody !== null) {
        return isTemplateBody(attrPath) && tempBody.getType() === config.BODY_TYPES.VAN;
    } else {
        return false;
    }
}

function isTemplateOrCustomVanBody(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    if (tempBody !== null) {
        return isTemplateOrCustomBody(attrPath) && tempBody.getType() === config.BODY_TYPES.VAN;
    } else {
        return false;
    }
}

function isTemplateOrCustomVanBodyWithoutGraphic(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    if (tempBody !== null) {
        return isTemplateOrCustomBodyWithoutGraphic(attrPath) && tempBody.getType() === config.BODY_TYPES.VAN;
    } else {
        return false;
    }
}

function showBodyWidthRelatedAttribute(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    return tempBody.getType() !== config.BODY_TYPES.FLATDECK && tempBody.getType() !== config.BODY_TYPES.ROLLBACK && tempBody.getType() !== config.BODY_TYPES.TANKER && tempBody.getType() !== config.BODY_TYPES.SERVICE && tempBody.getType() !== config.BODY_TYPES.HOOKLIFT;
}

function showBodySidewallAttribute(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    return (tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) || (tempBody.getType() !== config.BODY_TYPES.FLATDECK && tempBody.getType() !== config.BODY_TYPES.ROLLBACK && tempBody.getType() !== config.BODY_TYPES.TANKER && tempBody.getType() !== config.BODY_TYPES.SERVICE && tempBody.getType() !== config.BODY_TYPES.HOOKLIFT) ;
}

function isTemplateTimberBody(attrPath) {

    var tempBody = rigOps.getBodyFromPath(attrPath);

    if (tempBody !== null) {
        return isTemplateBody(attrPath) && tempBody.getType() === config.BODY_TYPES.TIMBER;
    } else {
        return false;
    }
}

function isTemplateOrCustomTimberBody(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    if (tempBody !== null) {
        return isTemplateOrCustomBody(attrPath) && tempBody.getType() === config.BODY_TYPES.TIMBER;
    } else {
        return false;
    }
}

function isTemplateOrCustomTimberBodyWithoutGraphic(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    if (tempBody !== null) {
        return isTemplateOrCustomBodyWithoutGraphic(attrPath) && tempBody.getType() === config.BODY_TYPES.TIMBER;
    } else {
        return false;
    }
}

function isTemplateOrCustomTrailer(path) {
    var trailer;
    if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        trailer = getActiveOffer().getTrailer1();
    } else {
        trailer = getActiveOffer().getTrailer2();
    }
    if (trailer !== null) {
        return trailer.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || trailer.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM;
    } else {
        return false;
    }
}

function isTemplateTrailer(path) {
    var trailer;
    if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        trailer = getActiveOffer().getTrailer1();
    } else {
        trailer = getActiveOffer().getTrailer2();
    }
    if (trailer !== null) {
        return trailer.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE;
    } else {
        return false;
    }
}

function showBodySideDoorAttribute(attrPath) {
    var tempBody = rigOps.getBodyFromPath(attrPath);

    if (tempBody !== null) {
        return tempBody.getSideDoors();
    } else {
        return false;
    }
}

function isCompleteTemplateVehicle() {
    return isTemplateVehicle() && !isNotCompleteVehicle();
}

function showCabTiltAttributes() {
    return isTemplateVehicle() && getActiveOffer().getConfiguration().getCabTilt();
}

function showCabAttributesNotCompleteVehicle() {
    return isTemplateVehicle() && isNotCompleteVehicle();
}

function isSpecifyCabDimensionsBbc() {
    return globals.user.getSpecifyCabDimensionsAs() === config.SPECIFY_CAB_DIMENSIONS_AS_OPTIONS.BBC;
}

function isSpecifyCabDimensionsAc() {
    return globals.user.getSpecifyCabDimensionsAs() === config.SPECIFY_CAB_DIMENSIONS_AS_OPTIONS.AC;
}

function isSpecifyChassisLengthAsOptionCA() {
    return globals.user.getSpecifyChassisLengthAs() === config.SPECIFY_CHASSIS_LENGTH_AS_OPTIONS.CA;
}

function isSpecifyChassisLengthAsOptionWheelbase() {
    return globals.user.getSpecifyChassisLengthAs() === config.SPECIFY_CHASSIS_LENGTH_AS_OPTIONS.WHEELBASE;
}

function showCabTiltSection() {
    return (isNotCompleteVehicle() && getConfiguration().getControl() !== config.CONTROL_TYPES.NORMAL) && isTemplateVehicle();
}

function showCabAttributes() {

    return getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CABTILT).value.value && (isNotCompleteVehicle() && getConfiguration().getControl() !== config.CONTROL_TYPES.NORMAL) && isTemplateVehicle();
}

function showChassisHeightFront() {
    return isTemplateVehicle() && isNotCompleteVehicle();
}

function showFifthWheelHeight() {
    return isTemplateVehicle() && isNotCompleteVehicle() && getActiveOffer().getRig().getVehicle().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES;
}

function showFrontRoadFriendlySuspension() {
    //return (getConfiguration().getAxle1FrontToAxle2Front() > 0) && isNotStandardVehicle();
    return evaluateShowSuspension() && getConfiguration().getAxle1FrontToAxle2Front() > 0;
}

function evaluateShowSuspension() {
    return getLesserOfActiveLegislationVals(legislationVals.variableTypes.ShowSuspension) !== 0;
}
function evaluateShowSafety() {
    return getGreaterOfActiveLegislationVals(legislationVals.variableTypes.ShowSafety) !== 0;
}

function showAxleAttribute(path) {
    var axleId = getObjectNumberOrIdFromPathToValue(path);
    var tempAxle = path.includes(config.OBJECT_TYPES.AXLE_GROUPS) === true ? rigOps.getAxleGroupFromPath(path) : rigOps.getAxleFromPath(path);
    if (!isNaN(axleId) && tempAxle !== null) {
        //var axleAdded = getConfiguration().getAxleById(axleId).isAdded();
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            if (isTemplateVehicle() === true) {
                return true;
            } else {
                return tempAxle.isAdded();
            }
            //return isTemplateVehicle() && tempAxle.isAdded() === false;
        } else {
            if (isTemplateOrCustomTrailer(path) === true) {
                return true;
            } else {
                return tempAxle.isAdded();
            }
            //return isTemplateOrCustomTrailer(path) && tempAxle.isAdded() === false;
        }

    } else {
        return false;
    }

}

function showNumTyresAxleAttribute(path) {
    var axleId = getObjectNumberOrIdFromPathToValue(path);
    var tempAxle = path.includes(config.OBJECT_TYPES.AXLE_GROUPS) === true ? rigOps.getAxleGroupFromPath(path) : rigOps.getAxleFromPath(path);
    if (!isNaN(axleId) && tempAxle !== null) {
        //var axleAdded = getConfiguration().getAxleById(axleId).isAdded();
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            //Vehicle Source = Standard and Loading Admin = False and not Added Axle
            return !(isStandardVehicle() && !globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code) && !tempAxle.isAdded());
        } else {
            return showAxleAttribute(path);
        }

    } else {
        return false;
    }
}

function showTyreRadiusAxleAttribute(path) {
    var axleId = getObjectNumberOrIdFromPathToValue(path);
    var tempAxle = rigOps.getAxleFromPath(path);
    if (!isNaN(axleId) && tempAxle !== null) {
        //var axleAdded = getConfiguration().getAxleById(axleId).isAdded();
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            //Vehicle Source = Standard and Loading Admin = False and not Added Axle
            return !(isStandardVehicle() && !tempAxle.isAdded());
        } else {
            return showAxleAttribute(path);
        }

    } else {
        return false;
    }
}

function showAxleAttributeIfStandardAndLoadingAdminOrIfTemplate(path) {
    var axleId = getObjectNumberOrIdFromPathToValue(path);
    if (!isNaN(axleId)) {

        //return globals.user.isLoadingAdministrator() || isTemplateVehicle();
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            return globals.user.isLoadingAdministrator() || isTemplateVehicle();
        } else {
            return globals.user.isLoadingAdministrator() || isTemplateOrCustomTrailer(path);
        }
    } else {
        return false;
    }

}

function showAxleAttributeIfStandardAndLoadingAdminAddAxlesOrIfTemplate(path) {
    var axleId = getObjectNumberOrIdFromPathToValue(path);
    if (!isNaN(axleId)) {

        //return globals.user.isLoadingAdministrator() || isTemplateVehicle();
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            return globals.user.isLoadingAddAxlesAdministrator() || isTemplateVehicle();
        } else {
            return globals.user.isLoadingAddAxlesAdministrator() || isTemplateOrCustomTrailer(path);
        }
    } else {
        return false;
    }

}

function showAxleAttributeUnlessExistingAxleOnStandardAndNotLoadingAdmin(path) {
    var tempAxle = rigOps.getAxleFromPath(path);
    if (tempAxle !== null) {

        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            if (globals.user.isLoadingAddAxlesAdministrator() || isTemplateVehicle()) {
                return true;
            } else {
                // we know its standard and not loading admin
                if (tempAxle.isAdded()) {
                    return false
                } else {
                    return true;
                }
            }

        } else {
            if (globals.user.isLoadingAddAxlesAdministrator() || isTemplateOrCustomTrailer(path)) {
                return true;
            } else {
                // we know its standard and not loading admin
                if (tempAxle.isAdded()) {
                    return false
                } else {
                    return true;
                }
            }
        }
    }

    return false;


}

function showIfNotFrontOrSecondSteerAxle(path) {
    var tempAxle = rigOps.getAxleFromPath(path);
    if (tempAxle !== null) {
        return tempAxle.getType() !== config.VEHICLE_AXLE_POSITION_TYPES.FRONT && tempAxle.getType() !== config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER;
    }
    return false;
}

function showIfNotFrontOrRearAxle(path) {
    var tempAxle = rigOps.getAxleFromPath(path);
    if (tempAxle !== null) {
        return tempAxle.getType() !== config.VEHICLE_AXLE_POSITION_TYPES.FRONT && tempAxle.getType() !== config.VEHICLE_AXLE_POSITION_TYPES.REAR;
    }
    return false;
}

/**
        * Pre 2020.1 release this function was used to determine whether the Axles tab was displayed on the Vehicle menu.
        * Post 2020.1 release the function will be used to determine what attributes inside the Weights & Axles tab will be visible to the user
        * @returns {boolean} - Return true/false value to be used by UI
    */
function showAxlesTabForVehicle() {
    // !((isStandardVehicle && Not Admin) && Not Admin && Vehicle does not have lifting axle)
    return !(isStandardVehicle() && !globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code) && !globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_ADD_AXLES.Code) && !getActiveOffer().getRig().getVehicle().getAxlesHolder().hasLiftingAxle());
}


function fuelTankAttributeReadOnlyPermissionFunc(path) {
    var fuelTankId = getObjectNumberOrIdFromPathToValue(path);
    if (!isNaN(fuelTankId)) {
        var fuelTank = getConfiguration().getFuelTankById(fuelTankId);
        return fuelTank.isFactoryFitted() === true && !isNotStandardVehicle() && !globals.user.isLoadingAdministrator();
    } else {
        return false;
    }

}

function showPayloadDetailedMenu(path) {
    return rigOps.getPayloadHolderFromPath(path).getPayloadType() === config.PAYLOAD_TYPES.DETAILED;
}

function showPayloadSimpleMenu(path) {
    return rigOps.getPayloadHolderFromPath(path).getPayloadType() === config.PAYLOAD_TYPES.SIMPLE;
}

function getObjectNumberOrIdFromPathToValue(path) {
    return globals.getIdOrNumberFromPath(path, 2);
    //if (path === undefined) {
    //    console.log('Path undefined when looking for id');
    //    return null;
    //} else {
    //    var pathParts = path.split('.');
    //    return parseInt(pathParts[pathParts.length - 2]);
    //}

}

function getObjectNumberOrIdFromPathToObject(path) {
    return globals.getIdOrNumberFromPath(path, 1);
}



function getWarnings() {
    if (globals.curWarnings === undefined) {
        getDataForActiveLegislations();
        generateWarnings();
    }
    return globals.curWarnings;
}

function getWarningsByEncodedLegislationId(encodedLegislationId) {
    return getWarnings()[encodedLegislationId]; 
}

function getDistinctTips() {
    if (globals.curTips === undefined) {
        getDataForActiveLegislations();
        generateWarnings();
    }
    return curDistinctTips;
}

function getTips() {
    if (globals.curTips === undefined) {
        getDataForActiveLegislations();
        generateWarnings();
    }
    return globals.curTips;
}

function getDDRNotes() {
    if (globals.curDDRNotes === undefined) {
        getDataForActiveLegislations();
        generateWarnings();
    }
    return globals.curDDRNotes;
}

function getTipsByEncodedLegislationId(encodedLegislationId) {
    return getTips()[encodedLegislationId]; 
}

function getRulesTriggeredForLegislationByEncodedLegislationId(encodedLegislationId) {
    return getDataForActiveLegislations().rulesTriggeredForLegislation[encodedLegislationId];
}

function getLegislationUsesDataDrivenRuleMethod(encodeLegislationId) {
    return getDataForActiveLegislations().legislationUsesDataDrivenRulesMethod[encodeLegislationId];
}

function getTriggeredRuleContexts() {
    return getDataForActiveLegislations().triggeredRuleContexts;
}

function getDistinctWarnings() {
    if (globals.curWarnings === undefined) {
        getDataForActiveLegislations();
        generateWarnings();
    }
    return curDistinctWarnings;
}

function getDistinctWarningsPerLegislation() {
    if (globals.curWarnings === undefined) {
        getDataForActiveLegislations();
        generateWarnings();
    }
    return curDistinctWarningsPerLegislation;
}


function generateWarnings(altRigOps) {

    var bypassCurWarningsUpdate = false;
    if (altRigOps !== undefined) {
        bypassCurWarningsUpdate = true;
    }

    
    var newWarnings = getDataForActiveLegislations(altRigOps).newWarnings;
    newWarnings[config.WARNINGS_HEADINGS.INTERNAL_STANDARDS] = getInternalStandardsWarnings();
    
    
    if (globals.curWarnings && globals.curWarnings[config.WARNINGS_HEADINGS.INTERNAL_STANDARDS]) {
        newWarnings[config.WARNINGS_HEADINGS.INTERNAL_STANDARDS].markNewStrings(globals.curWarnings[config.WARNINGS_HEADINGS.INTERNAL_STANDARDS]);
    } else {
        newWarnings[config.WARNINGS_HEADINGS.INTERNAL_STANDARDS].markNewStrings();
    }
    if (!bypassCurWarningsUpdate) {
        globals.curWarnings = copyLegislationsStringTrackerSet(newWarnings);
        curDistinctWarnings = generateDistinctWarnings(globals.curWarnings);
        curDistinctWarningsPerLegislation = generateDistinctWarningsPerLegislation(globals.curWarnings);
    }
    
    return newWarnings;
}

function getInternalStandardFormatFunc(unitType) {
    var formatFuncToUse;
    
    switch (unitType) {
        case config.INCREMENT_TYPE.LENGTH.toUpperCase():
            formatFuncToUse = getUIFormattedLengthValue;
            break;
        case config.INCREMENT_TYPE.MASS.toUpperCase():
            formatFuncToUse = getUIFormattedMassValue;
            break;
        case config.INCREMENT_TYPE.PERCENTAGE.toUpperCase():
            formatFuncToUse = getUIFormattedPercentageValue;
            break;
        case config.INCREMENT_TYPE.AREA.toUpperCase():
            formatFuncToUse = getUIFormattedAreaValue;
            break;
        case config.INCREMENT_TYPE.DEGREES.toUpperCase():
            formatFuncToUse = getUIFormattedDegreeValue;
            break;
        case config.INCREMENT_TYPE.NONE.toUpperCase():
            formatFuncToUse = (value) => { return value };
            break;
    }
    return formatFuncToUse;
}

function getInternalStandardComparisonRoundingFunc(unitType) {
    var formatFuncToUse;
    
    switch (unitType) {
        case config.INCREMENT_TYPE.LENGTH.toUpperCase():
            formatFuncToUse = getCurMeasurementSysRoundedLength;
            break;
        case config.INCREMENT_TYPE.MASS.toUpperCase():
            formatFuncToUse = getCurMeasurementSysRoundedMass;
            break;
        case config.INCREMENT_TYPE.PERCENTAGE.toUpperCase():
            formatFuncToUse = getCurMeasurementSysRoundedPercentage;
            break;
        case config.INCREMENT_TYPE.AREA.toUpperCase():
            formatFuncToUse = getCurMeasurementSysRoundedArea;
            break;
        case config.INCREMENT_TYPE.DEGREES.toUpperCase():
            formatFuncToUse = getCurMeasurementSysRoundedDegrees;
            break;
    }
    return formatFuncToUse;
}

function generateDistinctWarnings(currentWarnings) {  

    var distinctWarnings = new StringTracker();
    distinctWarnings.addDefaultTrackerState(config.TRACKER_STATE_KEY.TYPE, config.TRACKER_TYPE.WARNING);
    Object.keys(currentWarnings).forEach(function (key) {
        currentWarnings[key].getStringTrackerObjects().forEach(function (trackerObj) {
            distinctWarnings.addUnique(trackerObj);    
        });
    });
    return distinctWarnings;
}

function generateDistinctWarningsPerLegislation(currentWarnings) {  

    let distinctWarningsPerLegislation = {};
    
    Object.keys(currentWarnings).forEach(function (key) {
        var distinctWarnings = new StringTracker();
        distinctWarnings.addDefaultTrackerState(config.TRACKER_STATE_KEY.TYPE, config.TRACKER_TYPE.WARNING);
        currentWarnings[key].getStringTrackerObjects().forEach(function (trackerObj) {
            distinctWarnings.addUnique(trackerObj);    
        });
        distinctWarningsPerLegislation[key] = distinctWarnings;
    });
    return distinctWarningsPerLegislation;
}

function generateDistinctTips(currentTips) {  

    var distinctTips = new StringTracker();
    distinctTips.addDefaultTrackerState(config.TRACKER_STATE_KEY.TYPE, config.TRACKER_TYPE.TIP);
    
    Object.keys(currentTips).forEach(function (key) {
        currentTips[key].getStringTrackerObjects().forEach(function (trackerObj) {
            distinctTips.addUnique(trackerObj);    
        });
    });
    return distinctTips;
}

function getInternalStandardsWarnings() {

    var internalStandardsWarnings = new StringTracker();
    internalStandardsWarnings.addDefaultTrackerState(config.TRACKER_STATE_KEY.TYPE, config.TRACKER_TYPE.WARNING);
    
    if (globals.user.getDashboardConfiguration().hasActiveInternalStandards()) {
        globals.user.getDashboardConfiguration().getInternalStandards().forEach(function (internalStandard) {
            if (internalStandard.getIsActive()) {
                getInternalStandardWarning(internalStandard);
            }

        });
    }


    function getInternalStandardWarning(internalStandard) {
        var passFailFlag = 'PASSED';
        var minOrMaxVal;
        var dashboardDataPoint = globals.user.getDashboardDataPoints().filter(function (dataPoint) {
            return dataPoint.id === internalStandard.getDataPointId();
        })[0] || null;
        if (needToEvaluateDataPointOrInternalStandard(dashboardDataPoint.name.toUpperCase())) {
            offerManager.getInterpreterInstance().setLegislationObject(getLegislations()[0]);
            rigOps.setLegislationEvaluationFunction(getLesserOfActiveLegislationVals);
            offerManager.getInterpreterInstance().setRigOps(rigOps);
            var dataPointVal = offerManager.getInterpreterInstance().loadVariable(undefined, dashboardDataPoint.name.toUpperCase(), true);
            var roundingFuncToUse = getInternalStandardComparisonRoundingFunc(dashboardDataPoint.unitFormat);
            switch (internalStandard.getRangeType()) {
                case config.RANGE_TYPES.BETWEEN:
                    //if (dataPointVal < internalStandard.getRangeMin() && dataPointVal <= internalStandard.getRangeMax()) {
                    //    checkPassFailStatus = true;
                    //}
                    if (roundingFuncToUse(dataPointVal) < roundingFuncToUse(internalStandard.getRangeMin())) {
                        passFailFlag = 'FAILED_MIN';
                        minOrMaxVal = internalStandard.getRangeMin();
                    }
                    if (roundingFuncToUse(dataPointVal) > roundingFuncToUse(internalStandard.getRangeMax())) {
                        minOrMaxVal = internalStandard.getRangeMax();
                        passFailFlag = 'FAILED_MAX';
                    }
                    break;
                case config.RANGE_TYPES.GREATER:
                    if (roundingFuncToUse(dataPointVal) < roundingFuncToUse(internalStandard.getRangeMin())) {
                        minOrMaxVal = internalStandard.getRangeMin();
                        passFailFlag = 'FAILED_MIN';
                    }
                    break;
                case config.RANGE_TYPES.LESSER:
                    if (roundingFuncToUse(dataPointVal) > roundingFuncToUse(internalStandard.getRangeMax())) {
                        minOrMaxVal = internalStandard.getRangeMax();
                        passFailFlag = 'FAILED_MAX';
                    }
                    break;
            }

            var formatFuncToUse = getInternalStandardFormatFunc(dashboardDataPoint.unitFormat);
            

            if (passFailFlag === 'FAILED_MIN') {
                internalStandardsWarnings.addString(config.WARNING_ID.INTERNAL_STANDARD + '_' + dashboardDataPoint.code, config.getTranslationText('4319', [dashboardDataPoint.description, formatFuncToUse(minOrMaxVal)]));
            } else if (passFailFlag === 'FAILED_MAX') {
                internalStandardsWarnings.addString(config.WARNING_ID.INTERNAL_STANDARD + '_' + dashboardDataPoint.code, config.getTranslationText('4320', [dashboardDataPoint.description, formatFuncToUse(minOrMaxVal)]));
            }
        } 
    }

    return internalStandardsWarnings;
}

function getManufacturerLimitsWarnings(rigOpsToUse) {
    var manufacturerLimitsWarnings = new StringTracker();
    manufacturerLimitsWarnings.addDefaultTrackerState(config.TRACKER_STATE_KEY.TYPE, config.TRACKER_TYPE.WARNING);
    
    getAxlesOverloadedWarning(manufacturerLimitsWarnings, rigOpsToUse);
    getGVMOrGCMExceededWarning(manufacturerLimitsWarnings, rigOpsToUse);
    if(getActiveOffer().getRig().getVehicle().getBodyWidthManufacturerMax() > 0) {
        getOverallWidthExceededWarningText(manufacturerLimitsWarnings, rigOpsToUse);
    }

    return manufacturerLimitsWarnings;

    function getAxlesOverloadedWarning(newWarningsTracker, rigOpsToUse) {
        if (rigOpsToUse.getManufacturerWeightsAxlesOverloaded()) {
            newWarningsTracker.addString(config.WARNING_ID.MANUFACTURERS_WEIGHTS_AXLES_OVERLOADED, config.getTranslationText('1260')).addState(config.TRACKER_STATE_KEY.SECTION, config.WARNINGS_HEADINGS.MANUFACTURER_RATINGS);
        }
    }

    function getGVMOrGCMExceededWarning(newWarningsTracker, rigOpsToUse) {
        if (rigOpsToUse.getManufacturerWeightsGrossCombinationOverloaded() || rigOpsToUse.getManufacturerWeightsVehicleAndTransferFromTrailerGVMOverloaded()) {
            var warningText = '';

            if (rigOpsToUse.getCombinationType() === config.COMBINATION_TYPES.VEHICLE || (rigOpsToUse.getManufacturerWeightsVehicleAndTransferFromTrailerGVMOverloaded() && !rigOpsToUse.getManufacturerWeightsGrossCombinationOverloaded())) {
                warningText = config.getTranslationText('1261');
            } else {
                warningText = config.getTranslationText('1262');
            }

            newWarningsTracker.addString(config.WARNING_ID.MANUFACTURERS_WEIGHTS_GROSS_COMBINATION_OVERLOADED, warningText).addState(config.TRACKER_STATE_KEY.SECTION, config.WARNINGS_HEADINGS.MANUFACTURER_RATINGS);
        }
    }

    function getOverallWidthExceededWarningText(newWarningsTracker, rigOpsToUse) {

        var bodyWidthManufacturerMax = getActiveOffer().getRig().getVehicle().getBodyWidthManufacturerMax();

        if (getCurMeasurementSysRoundedLength(rigOpsToUse.getCombinationOverallWidth()) > getCurMeasurementSysRoundedLength(bodyWidthManufacturerMax)) {
            newWarningsTracker.addString(config.WARNING_ID.MANUFACTURER_WIDTH_EXCEEDED, config.getTranslationText('4683', [getUIFormattedLengthValue(bodyWidthManufacturerMax)]));
        }
    }
    
}

function needToEvaluateDataPointOrInternalStandard(dataPointName) {
    var evaluateDataPoint = true;

    //if (dashboardDataPoint.name.toUpperCase() === utilLegislationAttributeObjectCode.variableTypes.VehicleFrontGrossPercent || dashboardDataPoint.name.toUpperCase() === utilLegislationAttributeObjectCode.variableTypes.VehicleRearGrossPercent || dashboardDataPoint.name.toUpperCase() === utilLegislationAttributeObjectCode.variableTypes.VehicleFrontGross ||
    //    dashboardDataPoint.name.toUpperCase() === utilLegislationAttributeObjectCode.variableTypes.VehicleRearGross || dashboardDataPoint.name.toUpperCase() === utilLegislationAttributeObjectCode.variableTypes.ComVehRearOverhangPercent || dashboardDataPoint.name.toUpperCase() === utilLegislationAttributeObjectCode.variableTypes.ComVehRearOverhang || 
    //    dashboardDataPoint.name.toUpperCase() === utilLegislationAttributeObjectCode.variableTypes.VehicleVerticalCOG || dashboardDataPoint.name.toUpperCase() === utilLegislationAttributeObjectCode.variableTypes.VehicleStaticRolloverAngle || dashboardDataPoint.name.toUpperCase() === utilLegislationAttributeObjectCode.variableTypes.CombinationTurningRadiusCurbToCurb || 
    //    dashboardDataPoint.name.toUpperCase() === utilLegislationAttributeObjectCode.variableTypes.CombinationTurningRadiusWallToWall || dashboardDataPoint.name.toUpperCase() === utilLegislationAttributeObjectCode.variableTypes.CombinationInnerRadius || dashboardDataPoint.name.toUpperCase() === utilLegislationAttributeObjectCode.variableTypes.ComVehOverallLength || 
    //    dashboardDataPoint.name.toUpperCase() === utilLegislationAttributeObjectCode.variableTypes.ComVehOverallHeight || dashboardDataPoint.name.toUpperCase() === utilLegislationAttributeObjectCode.variableTypes.ComVehFrontalArea) {

    //    evaluateDataPoint = true;

    //} else 
    let tempVehicle = getActiveOffer().getRig().getVehicle();
    let tempTrailer1 = getActiveOffer().getRig().getTrailer1();
    let tempTrailer2 = getActiveOffer().getRig().getTrailer2();

    if (dataPointName === legislationVals.variableTypes.ComVehBodyPlusPayloadPercentFront || dataPointName === legislationVals.variableTypes.ComVehBodyPlusPayloadPercentRear ||
        dataPointName === legislationVals.variableTypes.ComVehBodyGap || dataPointName === legislationVals.variableTypes.VehiclePayloadCOGMM || dataPointName === legislationVals.variableTypes.VehiclePayloadCOGPercent ||
        dataPointName === legislationVals.variableTypes.FrameCut) {
        if (tempVehicle.getAccessoryHolder().getBody() === null) {
            evaluateDataPoint = false;
        }
    } else if (dataPointName === legislationVals.variableTypes.OverallTrailer1VerticalCOG || dataPointName === legislationVals.variableTypes.Trailer1StaticRolloverAngle ||
        dataPointName === legislationVals.variableTypes.CombinationOverallHeight || dataPointName === legislationVals.variableTypes.UtilisationOfGCWRPercent || dataPointName === legislationVals.variableTypes.UtilisationOfCombinationPermissiblePercent) {
        if (tempTrailer1 === null) {
            evaluateDataPoint = false;
        }
    } else if (dataPointName === legislationVals.variableTypes.OverallTrailer2VerticalCOG || dataPointName === legislationVals.variableTypes.Trailer2StaticRolloverAngle) {
        if (tempTrailer2 === null) {
            evaluateDataPoint = false;
        }
    } else if (dataPointName === legislationVals.variableTypes.TrailerHitchLoadPercent || dataPointName === legislationVals.variableTypes.TrailerHitchLoadWeight || dataPointName === legislationVals.variableTypes.SwingClearanceBetweenRigidAndPup) {
        if (tempTrailer1 === null || tempTrailer1.getType() !== config.TRAILER_TYPES.PUP) {
            evaluateDataPoint = false;
        }

    } else if (dataPointName === legislationVals.variableTypes.SwingClearanceBetweenTruckTractorAndTrailer1 || dataPointName === legislationVals.variableTypes.Trailer1KingpinLoadWeight) {
        if (tempTrailer1 === null || (tempTrailer1.getType() !== config.TRAILER_TYPES.SEMI && tempTrailer1.getType() !== config.TRAILER_TYPES.INTERLINK)) {
            evaluateDataPoint = false;
        }
    } else if (dataPointName === legislationVals.variableTypes.SwingClearanceBetweenInterlinkTrailer1AndTrailer2 || dataPointName === legislationVals.variableTypes.Trailer2KingpinLoadWeight) {
        if (tempTrailer1 === null || tempTrailer2 === null || (tempTrailer1.getType() !== config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() !== config.TRAILER_TYPES.SEMI)) {
            evaluateDataPoint = false;
        }
    } else if (dataPointName === legislationVals.variableTypes.SwingClearanceBetweenRigidAndDrawbar) {
        if (tempTrailer1 === null || tempTrailer1.getType() !== config.TRAILER_TYPES.DRAWBAR) {
            evaluateDataPoint = false;
        }
    } else if (dataPointName === legislationVals.variableTypes.Trailer1PayloadCOGMM || dataPointName === legislationVals.variableTypes.Trailer1PayloadCOGPercent) {
        if (tempTrailer1 === null || ((tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE && tempTrailer1.getAccessoryHolder().getBody() === null) && tempTrailer1.getSource() !== config.VEHICLE_SOURCE_TYPES.STANDARD)) {
            evaluateDataPoint = false;
        }

    } else if (dataPointName === legislationVals.variableTypes.Trailer2PayloadCOGMM || dataPointName === legislationVals.variableTypes.Trailer2PayloadCOGPercent) {
        if (tempTrailer2 === null || ((tempTrailer2.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE && tempTrailer2.getAccessoryHolder().getBody() === null) && tempTrailer2.getSource() !== config.VEHICLE_SOURCE_TYPES.STANDARD)) {
            evaluateDataPoint = false;
        }
    } else if (dataPointName === legislationVals.variableTypes.CDLRequiredUS) {
        if (!rigOps.canEvaluateCDLRequiredUS()) {
            evaluateDataPoint = false;
        }
    } else if (dataPointName === legislationVals.variableTypes['1stGearStartability']) {
        if (!rigOps.canEvaluateFirstGearStartability()) {
            evaluateDataPoint = false;
        }
    } else if (dataPointName === legislationVals.variableTypes.ReverseGearStartability) {
        if (!rigOps.canEvaluateReverseGearStartability()) {
            evaluateDataPoint = false;
        }
    } else if (dataPointName === legislationVals.variableTypes['1stGearGradeability']) {
        if (!rigOps.canEvaluateFirstGearGradeability()) {
            evaluateDataPoint = false;
        }
    }
    
    
    return evaluateDataPoint;
}

class LegislationHelper {
    constructor() {

    }

    hasRegulationWarning() {

    }
}

function hasLegislationARegulationWarning(legislationDetails) {
    let regulationWarningFound = false;
    if(getLegislationUsesDataDrivenRuleMethod(legislationDetails.id)) {
        let rulesTriggeredForLegislation = getRulesTriggeredForLegislationByEncodedLegislationId(legislationDetails.id);
        rulesTriggeredForLegislation.forEach(function (triggeredRule){
            if(triggeredRule.RULETYPE === config.DDR_RULE_TYPE.WARNING) {
                if(triggeredRule.RULECATEGORY === config.DDR_RULE_CATEGORY.WEIGHTS) {
                    regulationWarningFound = true;
                }
            }
        });
    } else {
        let legislationWarnings = getWarningsByEncodedLegislationId(legislationDetails.id);

        clearBridgeFormulaPermissiblWorstHighlightColour();
        clearSteerAxleMinimumWorstHighlightColour();
        
        var bridgeFormulaPermissibleValWorstHighlightColour = getWorstBridgeFormulaPermissibleOverloadColour(legislationDetails.id);
        var steerAxleMinumumWorstHighlightColour = getWorstSteerAxleMinimumOverloadColour(legislationDetails.id);

        if (legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.PERMISSIBLE_TOTAL_OVERLOADED) !== null ||
            legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.AXLES_OVERLOADED) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.PUP_HEAVIER_THAN_VEHICLE) !== null ||
            legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.DRAWBAR_HEAVIER_THAN_VEHICLE) !== null) {
            if (bridgeFormulaPermissibleValWorstHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING || bridgeFormulaPermissibleValWorstHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.FAIL) {
                regulationWarningFound = true;
            } 
        } else if (legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.STEER_AXLE_MINIMUM) !== null) {
            if (steerAxleMinumumWorstHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING || steerAxleMinumumWorstHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.FAIL) {
                regulationWarningFound = true;
            } 
        }
    }
    return regulationWarningFound;
}

function hasLegislationARegulationCaution(legislationDetails) {
    let regulationCautionFound = false;
    if(getLegislationUsesDataDrivenRuleMethod(legislationDetails.id)) {
        let rulesTriggeredForLegislation = getRulesTriggeredForLegislationByEncodedLegislationId(legislationDetails.id);
        rulesTriggeredForLegislation.forEach(function (triggeredRule){
            if(triggeredRule.RULETYPE === config.DDR_RULE_TYPE.CAUTION) {
                if(triggeredRule.RULECATEGORY === config.DDR_RULE_CATEGORY.WEIGHTS) {
                    regulationCautionFound = true;
                }
            }
        });
    } else {
        let legislationWarnings = getWarningsByEncodedLegislationId(legislationDetails.id);

        offerManager.clearBridgeFormulaPermissiblWorstHighlightColour();
        offerManager.clearSteerAxleMinimumWorstHighlightColour();
        
        var bridgeFormulaPermissibleValWorstHighlightColour = offerManager.getWorstBridgeFormulaPermissibleOverloadColour(legislationDetails.id);
        var steerAxleMinumumWorstHighlightColour = offerManager.getWorstSteerAxleMinimumOverloadColour(legislationDetails.id);


        if (legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.PERMISSIBLE_TOTAL_OVERLOADED) !== null ||
            legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.AXLES_OVERLOADED) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.PUP_HEAVIER_THAN_VEHICLE) !== null ||
            legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.DRAWBAR_HEAVIER_THAN_VEHICLE) !== null) {
            if(bridgeFormulaPermissibleValWorstHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
                regulationCautionFound = true;
            } 
        } else if (legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.STEER_AXLE_MINIMUM) !== null) {
            if(steerAxleMinumumWorstHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
                regulationCautionFound = true;
            } 
        }
    }
    return regulationCautionFound;
}

function hasLegislationARegulationUnknown(legislationDetails, tempRigOpsForRegulatoryComplianceInstanceGeneration) {
    let regulationUnknownFound = false;
    if(getLegislationUsesDataDrivenRuleMethod(legislationDetails.id)) {
        let rulesTriggeredForLegislation = getRulesTriggeredForLegislationByEncodedLegislationId(legislationDetails.id);
        rulesTriggeredForLegislation.forEach(function (triggeredRule){
            if(triggeredRule.RULETYPE === config.DDR_RULE_TYPE.UNKNOWN) {
                if(triggeredRule.RULECATEGORY === config.DDR_RULE_CATEGORY.WEIGHTS) {
                    regulationUnknownFound = true;
                }
            }
        });
    } else {
                
        if (tempRigOpsForRegulatoryComplianceInstanceGeneration.hasMissingTareOrPermissibleWeigthsForRegulation()) {
            regulationUnknownFound = true;
        } 
    }
    return regulationUnknownFound;
}

function hasLegislationABridgeWarning(legislationDetails, tempRigOpsForRegulatoryComplianceInstanceGeneration, evaluateLegislationWrapper) {
    let bridgeWarningFound = false;
    if(getLegislationUsesDataDrivenRuleMethod(legislationDetails.id)) {
        let rulesTriggeredForLegislation = getRulesTriggeredForLegislationByEncodedLegislationId(legislationDetails.id);
        rulesTriggeredForLegislation.forEach(function (triggeredRule){
            if(triggeredRule.RULETYPE === config.DDR_RULE_TYPE.WARNING) {
                if(triggeredRule.RULECATEGORY === config.DDR_RULE_CATEGORY.BRIDGE) {
                    bridgeWarningFound = true;
                }
            }
        });
    } else {
        let legislationWarnings = getWarningsByEncodedLegislationId(legislationDetails.id);
        var mergedPermissibleValues = getMergedLegislationsCombinationPermissibleVals(getActiveLegislationsCombinationPermissibleVals());
        clearBridgeWorstHighlightColour();
        tempRigOpsForRegulatoryComplianceInstanceGeneration.setCombinationPermissibleVals(mergedPermissibleValues);
        if (offerManager.needToMaximise(tempRigOpsForRegulatoryComplianceInstanceGeneration)) {
            tempRigOpsForRegulatoryComplianceInstanceGeneration.maximisePayloads(mergedPermissibleValues);
        }
        var bridgeWorstHighlightColour = getWorstBridgeFormulaOverloadColour(tempRigOpsForRegulatoryComplianceInstanceGeneration, legislationDetails.id, evaluateLegislationWrapper);
        
        if (legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.X_DIMENSION_EXCEEDED) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.Y_DIMENSION_EXCEEDED) !== null ||
            legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.OVERALL_WHEELBASE) !== null) {
            bridgeWarningFound = true;
        } else if (bridgeWorstHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.FAIL) {
            bridgeWarningFound = true;
        } 
    }
    return bridgeWarningFound;
}

function hasLegislationABridgeCaution(legislationDetails, tempRigOpsForRegulatoryComplianceInstanceGeneration, evaluateLegislationWrapper) {
    let bridgeCautionFound = false;
    if(getLegislationUsesDataDrivenRuleMethod(legislationDetails.id)) {
        let rulesTriggeredForLegislation = getRulesTriggeredForLegislationByEncodedLegislationId(legislationDetails.id);
        rulesTriggeredForLegislation.forEach(function (triggeredRule){
            if(triggeredRule.RULETYPE === config.DDR_RULE_TYPE.CAUTION) {
                if(triggeredRule.RULECATEGORY === config.DDR_RULE_CATEGORY.BRIDGE) {
                    bridgeCautionFound = true;
                }
            }
        });
    } else {
        
        var mergedPermissibleValues = getMergedLegislationsCombinationPermissibleVals(getActiveLegislationsCombinationPermissibleVals());
        clearBridgeWorstHighlightColour();
        tempRigOpsForRegulatoryComplianceInstanceGeneration.setCombinationPermissibleVals(mergedPermissibleValues);
        if (offerManager.needToMaximise(tempRigOpsForRegulatoryComplianceInstanceGeneration)) {
            tempRigOpsForRegulatoryComplianceInstanceGeneration.maximisePayloads(mergedPermissibleValues);
        }
        var bridgeWorstHighlightColour = getWorstBridgeFormulaOverloadColour(tempRigOpsForRegulatoryComplianceInstanceGeneration, legislationDetails.id, evaluateLegislationWrapper);
        
        if (bridgeWorstHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
            bridgeCautionFound = true;
        } 
    }
    return bridgeCautionFound;
}

function hasLegislationABridgeUnknown(legislationDetails, tempRigOpsForRegulatoryComplianceInstanceGeneration) {
    let bridgeUnknownFound = false;
    if(getLegislationUsesDataDrivenRuleMethod(legislationDetails.id)) {
        let rulesTriggeredForLegislation = getRulesTriggeredForLegislationByEncodedLegislationId(legislationDetails.id);
        rulesTriggeredForLegislation.forEach(function (triggeredRule){
            if(triggeredRule.RULETYPE === config.DDR_RULE_TYPE.UNKNOWN) {
                if(triggeredRule.RULECATEGORY === config.DDR_RULE_CATEGORY.BRIDGE) {
                    bridgeUnknownFound = true;
                }
            }
        });
    } else {
        if (tempRigOpsForRegulatoryComplianceInstanceGeneration.hasMissingTareWeigthsForBridgeFormula() === true) {
            bridgeUnknownFound = true;
        }
    }
    return bridgeUnknownFound;
}

function hasLegislationADimensionWarning(legislationDetails) {
    let dimensionsWarningFound = false;
    if(getLegislationUsesDataDrivenRuleMethod(legislationDetails.id)) {
        let rulesTriggeredForLegislation = getRulesTriggeredForLegislationByEncodedLegislationId(legislationDetails.id);
        rulesTriggeredForLegislation.forEach(function (triggeredRule){
            if(triggeredRule.RULETYPE === config.DDR_RULE_TYPE.WARNING) {
                if(triggeredRule.RULECATEGORY === config.DDR_RULE_CATEGORY.DIMENSIONS) {
                    dimensionsWarningFound = true;
                }
            }
        });
    } else {
        let legislationWarnings = getWarningsByEncodedLegislationId(legislationDetails.id);

        if (legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.REAR_OVERHANG_EXCEEDED) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.VEHICLE_WHEELBASE_THEORETICAL) !== null ||
            legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.OVERALL_LENGTH) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.TRAILER_LENGTH) !== null ||
            legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.KINGPIN_TO_REAR_OF_TRAILER) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.TRAILER_FRONT_OVERHANG) !== null ||
            legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.TRAILER_WHEELBASE_THEORETICAL) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.TRAILER_REAR_OVERHANG_LEGISLATION) !== null ||
            legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.TRAILER_DRAWBAR_LENGTH) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.TURNTABLE_TO_REAR_OF_TRAILER) !== null ||
            legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.OVERALL_HEIGHT_EXCEEDED) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.GAP_BETWEEN_VEHICLE_AND_TRAILER) !== null ||
            legislationWarnings.getStringTrackerObjectById(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.TRAILER_REAR_OVERHANG_LEGISLATION) !== null || legislationWarnings.getStringTrackerObjectById(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.WARNING_ID.TRAILER_REAR_OVERHANG_LEGISLATION) !== null ||
            legislationWarnings.getStringTrackerObjectById(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.TRAILER_LENGTH) !== null || legislationWarnings.getStringTrackerObjectById(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.WARNING_ID.TRAILER_LENGTH) !== null ||
            legislationWarnings.getStringTrackerObjectById(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.KINGPIN_TO_REAR_OF_TRAILER) !== null || legislationWarnings.getStringTrackerObjectById(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.WARNING_ID.KINGPIN_TO_REAR_OF_TRAILER) !== null ||
            legislationWarnings.getStringTrackerObjectById(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.TRAILER_FRONT_OVERHANG) !== null || legislationWarnings.getStringTrackerObjectById(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.WARNING_ID.TRAILER_FRONT_OVERHANG) !== null ||
            legislationWarnings.getStringTrackerObjectById(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.TRAILER_WHEELBASE_THEORETICAL) !== null || legislationWarnings.getStringTrackerObjectById(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.WARNING_ID.TRAILER_WHEELBASE_THEORETICAL) !== null ||
            legislationWarnings.getStringTrackerObjectById(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.TRAILER_WHEELBASE_THEORETICAL) !== null || legislationWarnings.getStringTrackerObjectById(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.WARNING_ID.TRAILER_WHEELBASE_THEORETICAL) !== null ||
            legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.GAP_BETWEEN_TRAILER_AND_TRAILER) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.VEHICLE_LENGTH) !== null ||
            legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.OVERALL_LENGTH_EXCEEDED) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.KINGPIN_TO_REARMOST_AXLE_SETTING_EXCEEDED) !== null ||
            legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.CARGO_CARRYING_LENGTH_EXCEEDED) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.HOOKLIFT_BODY_OVERHANG_EXCEEDED) !== null) {
                dimensionsWarningFound = true;
        }
    }
    return dimensionsWarningFound;
}

function hasLegislationATurningCircleWarning(legislationDetails) {
    let turningCircleWarningFound = false;
    if(getLegislationUsesDataDrivenRuleMethod(legislationDetails.id)) {
        let rulesTriggeredForLegislation = getRulesTriggeredForLegislationByEncodedLegislationId(legislationDetails.id);
        rulesTriggeredForLegislation.forEach(function (triggeredRule){
            if(triggeredRule.RULETYPE === config.DDR_RULE_TYPE.WARNING) {
                if(triggeredRule.RULECATEGORY === config.DDR_RULE_CATEGORY.TURNING_CIRCLE) {
                    turningCircleWarningFound = true;
                }
            }
        });
    } else {
        let legislationWarnings = getWarningsByEncodedLegislationId(legislationDetails.id);

        if (legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.TURNING_RADIUS_OUTER) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.TURNING_RADIUS_INNER) !== null) {
            turningCircleWarningFound = true;
        }
    }
    return turningCircleWarningFound;
}

function hasLegislationATurningCircleUnknown(legislationDetails) {
    let turningCircleUnknownFound = false;
    if(getLegislationUsesDataDrivenRuleMethod(legislationDetails.id)) {
        let rulesTriggeredForLegislation = getRulesTriggeredForLegislationByEncodedLegislationId(legislationDetails.id);
        rulesTriggeredForLegislation.forEach(function (triggeredRule){
            if(triggeredRule.RULETYPE === config.DDR_RULE_TYPE.UNKNOWN) {
                if(triggeredRule.RULECATEGORY === config.DDR_RULE_CATEGORY.TURNING_CIRCLE) {
                    turningCircleUnknownFound = true;
                }
            }
        });
    } else {
        let legislationWarnings = getWarningsByEncodedLegislationId(legislationDetails.id);

        if (legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.MISSING_VALUES_TURNING_CIRCLE) !== null) {
            turningCircleUnknownFound = true;
        }
    }
    return turningCircleUnknownFound;
}

function evaluateLegislationRules(legislationDetails, evaluateLegislationWrapper) {
    let _triggeredRules = new Array();

    legislationDetails.attributes.forEach(function (attribute) {
         if(attribute.name.indexOf(config.DDR_LEGISLATION_ATTRIBUTE_PREFIX) !== -1) {
            let result = evaluateLegislationWrapper(attribute.name.toUpperCase());
            if(result !== 0) {
                try {
                    _triggeredRules.push(JSON.parse(result));
                }catch(jsonParseEx) {
                    var breakHere = 0;
                }
                
            }
         }
    });
    
    return _triggeredRules;
}

function tempEvaluateLegislationRules(legislationDetails, evaluateLegislationWrapper) {
    let _triggeredRules = new Array();

    legislationDetails.attributes.forEach(function (attribute) {
        //  if(attribute.name.indexOf(config.DDR_LEGISLATION_ATTRIBUTE_PREFIX) !== -1) {
            
            
            try {
                let result = evaluateLegislationWrapper(attribute.name.toUpperCase());
                console.log('Name: ' + attribute.name + ', Result: ' + result);
            }catch(jsonParseEx) {
                var breakHere = 0;
            }
                
            
        //  }
    });
    
    return _triggeredRules;
}

function extractRuleContexts(allLegislationsTriggeredRules) {
    let ruleContexts = {
        dim: {},
        bridge: {},
        axle: {}
    };
    Object.values(allLegislationsTriggeredRules).forEach(function (legislationTriggeredRules) {
        legislationTriggeredRules.forEach(function (triggeredRule){
            if(triggeredRule.RULECONTEXT && triggeredRule.RULECONTEXT.length > 0) {
                triggeredRule.RULECONTEXT.forEach(function (context) {
                    // if(typeof context === 'string') {
                        if(context.indexOf(config.DDR_RULE_CONTEXT_PREFIX.DIM) !== -1) {
                            let key = context.replace(config.DDR_RULE_CONTEXT_PREFIX.DIM, '');
                            let mappedResult = mapDataPointToAppIDOrPath(key).toUpperCase();
                            
                            ruleContexts.dim[mappedResult] = {}; 
                            ruleContexts.dim[mappedResult].messageCode = triggeredRule.MESSAGECODE;
                            ruleContexts.dim[mappedResult].ruleType = triggeredRule.RULETYPE;
                            

                        } else if(context.indexOf(config.DDR_RULE_CONTEXT_PREFIX.BRIDGE) !== -1) {
                            let key = context.replace(config.DDR_RULE_CONTEXT_PREFIX.BRIDGE, '');
                            ruleContexts.bridge[key] = {}; 
                            ruleContexts.bridge[key].messageCode = triggeredRule.MESSAGECODE;
                            ruleContexts.bridge[key].ruleType = triggeredRule.RULETYPE;
                        } else if(context.indexOf(config.DDR_RULE_CONTEXT_PREFIX.AXLE) !== -1) {
                            let key = context.replace(config.DDR_RULE_CONTEXT_PREFIX.AXLE, '');
                            ruleContexts.axle[key] = {};
                            ruleContexts.axle[key].messageCode = triggeredRule.MESSAGECODE;
                            ruleContexts.axle[key].ruleType = triggeredRule.RULETYPE;
                        }
                    // }else if(typeof context === 'object') {
                    //     Object.keys(context).forEach(function (chassisKey) {
                    //         context[chassisKey].forEach(function (subContext) {
                    //             let key = subContext.replace(config.DDR_RULE_CONTEXT_PREFIX.DIM, '');
                    //             let mappedResult = chassisKey + '.' + mapDataPointToAppIDOrPath(key).toUpperCase();
                                
                    //             ruleContexts.dim[mappedResult] = {}; 
                    //             ruleContexts.dim[mappedResult].messageCode = triggeredRule.MESSAGECODE;
                    //             ruleContexts.dim[mappedResult].ruleType = triggeredRule.RULETYPE;
                    //         });
                    //     });
                    // }
                });
            }
        });
    });
    
    return ruleContexts;
}

function mapDataPointToAppIDOrPath(dataPointName) {
    let mappedResult = undefined;
    switch(dataPointName.toUpperCase()) {
        case legislationVals.variableTypes.ComVehOverallLength:
            mappedResult = config.VALUE_TYPE.OVERALLVEHICLELENGTH;
            break;
        case legislationVals.variableTypes.VehicleFrontOverhang:
            mappedResult = config.VALUE_TYPE.FRONTOVERHANG;
            break;
        case legislationVals.variableTypes.VehicleRearOverhang:
            mappedResult = config.VALUE_TYPE.REAROVERHANG;
            break;
        case legislationVals.variableTypes.VehicleAxle1FrontToAxle2Front:
            mappedResult = config.VALUE_TYPE.AXLE1FRONTTOAXLE2FRONT;
            break;
        case legislationVals.variableTypes.VehicleRearMostAxleFrontToAxle1Rear:
            mappedResult = config.VALUE_TYPE.AXLE2FRONTTOAXLE1REAR;
            break;
        case legislationVals.variableTypes.VehicleAxle1RearToAxle2Rear:
            mappedResult = config.VALUE_TYPE.AXLE1REARTOAXLE2REAR;
            break;
        case legislationVals.variableTypes.VehicleAxle2RearToAxle3Rear:
            mappedResult = config.VALUE_TYPE.AXLE2REARTOAXLE3REAR;
            break;
        case legislationVals.variableTypes.VehicleAxle3RearToAxle4Rear:
            mappedResult = config.VALUE_TYPE.AXLE3REARTOAXLE4REAR;
            break;
        case legislationVals.variableTypes.VehicleAxle1FrontToWheelbaseTheoreticalStart:
            mappedResult = config.VALUE_TYPE.AXLE1FRONTTOWHEELBASETHEORETICALSTART;
            break;
        case legislationVals.variableTypes.VehicleWheelbaseTheoretical:
            mappedResult = config.VALUE_TYPE.WHEELBASESPECIFIED;
            break;
        case legislationVals.variableTypes.VehicleWheelbaseTechnical:
            mappedResult = config.VALUE_TYPE.WHEELBASETHEORETICAL;
            break;
        case legislationVals.variableTypes.VehicleRearProtrusion:
            mappedResult = config.VALUE_TYPE.REARPROTRUSION;
            break;
        case legislationVals.variableTypes.VehicleRearOverhangTotal:
            mappedResult = config.VALUE_TYPE.REAROVERHANGTOTAL;
            break;
        case legislationVals.variableTypes.VehicleBumperToBackOfCab:
            mappedResult = config.VALUE_TYPE.BUMPERTOBACKOFCAB;
            break;
        case legislationVals.variableTypes.BodyGapVehicle:
            mappedResult = config.VALUE_TYPE.BODYCABGAP;
            break;
        
        case legislationVals.variableTypes.Trailer1FrontOverhang: 
            mappedResult = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERFRONTOVERHANG;
            break;
        case legislationVals.variableTypes.Trailer2FrontOverhang: 
            mappedResult = config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.TRAILERFRONTOVERHANG;
            break;
        case legislationVals.variableTypes.Trailer1WheelbaseTheoretical: 
            mappedResult = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERWHEELBASETHEORETICAL;
            break;
        case legislationVals.variableTypes.Trailer2WheelbaseTheoretical: 
            mappedResult = config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.TRAILERWHEELBASETHEORETICAL;
            break;
        case legislationVals.variableTypes.Trailer1DrawbarLength:
            // if(getTrailer1DrawbarLengthEqualToDrawbarLegislationLength()) {
            //     mappedResult = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERDRAWBARLENGTH;
            // } else {
                mappedResult = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERDRAWBARPROTRUSION;
            // }
            break;
        case legislationVals.variableTypes.Trailer1WheelbaseTheoreticalEndToAxle4Rear: 
            mappedResult = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERWHEELBASETHEORETICALENDTOREARMOSTAXLE;
            break;
        case legislationVals.variableTypes.Trailer2WheelbaseTheoreticalEndToAxle4Rear: 
            mappedResult = config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.TRAILERWHEELBASETHEORETICALENDTOREARMOSTAXLE;
            break;
        case legislationVals.variableTypes.Trailer1WheelbaseTheoreticalStartFromAxle1Front: 
            mappedResult = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERAXLE1FRONTTOWHEELBASETHEORETICALSTART;
            break;
        case legislationVals.variableTypes.Trailer1RearOverhangTotal: 
            mappedResult = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERREAROVERHANGTOTAL;
            break;
        case legislationVals.variableTypes.Trailer2RearOverhangTotal: 
            mappedResult = config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.TRAILERREAROVERHANGTOTAL;
            break;
        case legislationVals.variableTypes.Trailer1Length: 
            mappedResult = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERLENGTH;
            break;
        case legislationVals.variableTypes.KingpinToRearOfTrailer1: 
            mappedResult = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.KINGPINTOREAROFTRAILER;
            break;
        case legislationVals.variableTypes.KingpinToRearOfTrailer2: 
            mappedResult = config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.KINGPINTOREAROFTRAILER;
            break;
        case legislationVals.variableTypes.CombinationOverallLength: 
            mappedResult = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.COMBINATIONOVERALLLENGTH;
            break;
        case legislationVals.variableTypes.CombinationOverallHeight: 
            if(rigOps.getRighMostChassisPathRootForCurrentCombination() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                mappedResult = config.VALUE_TYPE.OVERALLHEIGHTATREAR;
            } else {
                mappedResult = rigOps.getRighMostChassisPathRootForCurrentCombination() + '.' + config.VALUE_TYPE.OVERALLHEIGHTATREAR;
            }
            break;
        case legislationVals.variableTypes.ComVehRearOverhang: 
            mappedResult = config.VALUE_TYPE.REAROVERHANGLEGISLATION;
            break;
        case legislationVals.variableTypes.ComVehIntSemiXDimension: 
            mappedResult = config.VALUE_TYPE.BRIDGEXDIMENSION;
            break;
        case legislationVals.variableTypes.ComVehIntSemiYDimension: 
            mappedResult = config.VALUE_TYPE.BRIDGEYDIMENSION;
            break;
            
             
            
    }

    return mappedResult || dataPointName;
}

function buildLegislationTipsNotesAndWarnings(rulesTriggeredForLegislation, legislationToUse, altRigOps, altEvaluateLegislation) {
    let rigOpsToUse = altRigOps !== undefined ? altRigOps : rigOps;
    let evaluateLegislationToUse = altEvaluateLegislation !== undefined ? altEvaluateLegislation : evaluateLegislation;

    let newTipsNotesAndWarnings = { 
        newWarnings: new StringTracker(), 
        newTips: new StringTracker(),
        newNotes: new StringTracker()
    };

    newTipsNotesAndWarnings.newWarnings.addDefaultTrackerState(config.TRACKER_STATE_KEY.TYPE, config.TRACKER_TYPE.WARNING);
    newTipsNotesAndWarnings.newTips.addDefaultTrackerState(config.TRACKER_STATE_KEY.TYPE, config.TRACKER_TYPE.TIP);
    newTipsNotesAndWarnings.newNotes.addDefaultTrackerState(config.TRACKER_STATE_KEY.TYPE, config.TRACKER_TYPE.DDR_NOTE);

    rulesTriggeredForLegislation.forEach(function (triggeredRule) {

        let ddrTranslationText = globals.user.getTranslationForDDRMessageCode(triggeredRule.MESSAGECODE);
        let ruleText = '';
        if(triggeredRule.MESSAGEPARAMETERS && triggeredRule.MESSAGEPARAMETERS.length > 0) {
            //evaluate parameters
            let paramObjects = triggeredRule.MESSAGEPARAMETERS;
            evaluateParameterValues(paramObjects, evaluateLegislationToUse, legislationToUse);
            formatParameterValues(paramObjects);
            ruleText = substituteParameterValues(ddrTranslationText, paramObjects);
        }else {
            ruleText = ddrTranslationText;
        }

        if(triggeredRule.RULETYPE === config.DDR_RULE_TYPE.WARNING || triggeredRule.RULETYPE === config.DDR_RULE_TYPE.CAUTION || triggeredRule.RULETYPE === config.DDR_RULE_TYPE.UNKNOWN) {
            
            //pass
            newTipsNotesAndWarnings.newWarnings.addString(triggeredRule.MESSAGECODE, ruleText);
        } else if(triggeredRule.RULETYPE === config.DDR_RULE_TYPE.TIP) {
            let htmlSnippet = '<span>' + ruleText + '</span><div class="toast-tip-legislation-container"><span>[' + legislationToUse.description + ']</span></div>';
            newTipsNotesAndWarnings.newTips.addString(triggeredRule.MESSAGECODE + '_' + legislationToUse.id, htmlSnippet);
        } else if(triggeredRule.RULETYPE === config.DDR_RULE_TYPE.NOTE) {
            newTipsNotesAndWarnings.newNotes.addString(triggeredRule.MESSAGECODE, ruleText);
        }
    });

    return newTipsNotesAndWarnings;
}


function substituteParameterValues(ddrTranslationText, paramObjects) {
    let paramCounter = 1;
    paramObjects.forEach(function (paramObject) {
        ddrTranslationText = ddrTranslationText.replace('$S' + paramCounter, paramObject.VALUE);
        paramCounter++;
    });
    return ddrTranslationText;
}

function evaluateParameterValues(paramObjects, evaluateLegislationToUse, legislationToUse) {
    // let paramValues = [];

    paramObjects.forEach(function (paramObject) {
        
        try {
            // var attributeDetails = legislationToUse.evaluate(paramCode);
            if(paramObject.VALUETYPE === config.DDR_MESSAGE_PARAMETER_VALUE_TYPE.LEGISLATION) {
                paramObject.VALUE = evaluateLegislationToUse(paramObject.VALUECODE);
            } else {
                paramObject.VALUE = getInterpreterInstance().loadVariable(undefined, paramObject.VALUECODE, true);
            }
        } catch (ex) {
            TScMessenger.writeDebugMessage('OfferManager.js, evaluateParameterValues, DDR parameter could not be evaluated: ' + paramObject.VALUECODE);
        }
    });

    // return paramValues;
}

function formatParameterValues(paramObjects) {
    
    paramObjects.forEach(function (paramObject) {
        if (paramObject.UNITFORMAT === config.INCREMENT_TYPE.LENGTH.toUpperCase()) {
            paramObject.VALUE = getUIFormattedLengthValue(paramObject.VALUE);
        } else if (paramObject.UNITFORMAT === config.INCREMENT_TYPE.MASS.toUpperCase()) {
            paramObject.VALUE = getUIFormattedMassValue(paramObject.VALUE);
        }else if (paramObject.UNITFORMAT === config.INCREMENT_TYPE.PERCENTAGE.toUpperCase()) {
            paramObject.VALUE = getUIFormattedPercentageValue(paramObject.VALUE);
        }else if (paramObject.UNITFORMAT === config.INCREMENT_TYPE.AREA.toUpperCase()) {
            paramObject.VALUE = getUIFormattedAreaValue(paramObject.VALUE);
        }else if (paramObject.UNITFORMAT === config.INCREMENT_TYPE.DEGREES.toUpperCase()) {
            paramObject.VALUE = getUIFormattedDegreeValue(paramObject.VALUE);
        } 
    });
}
/*

switch (this.incrementType()) {
                    case config.INCREMENT_TYPE.LENGTH:
                        return formatLengthValue(value);
                    case config.INCREMENT_TYPE.MASS:
                        return formatMassValue(value);
                    case config.INCREMENT_TYPE.PERCENTAGE:
                        return formatPercentageValue(value);
                    case config.INCREMENT_TYPE.AREA:
                        return formatAreaValue(value);
                    case config.INCREMENT_TYPE.DEGREES:
                        return formatDegreesValue(value);
                    default:
                        return value;
                }

*/





function generateLegislationWarnings(encodedLegislationId, altRigOps, altEvaluateLegislation) {

    var rigOpsToUse = altRigOps !== undefined ? altRigOps : rigOps;
    var evaluateLegislationToUse = altEvaluateLegislation !== undefined ? altEvaluateLegislation : evaluateLegislation;

    
    //var warnings = [];

    var newWarnings = new StringTracker();
    newWarnings.addDefaultTrackerState(config.TRACKER_STATE_KEY.TYPE, config.TRACKER_TYPE.WARNING);

    var tempVehicle = getConfiguration(),
        tempOriginalVehicle = getOriginalOffer().getConfiguration(),
        tempTrailer1 = getActiveOffer().getRig().getTrailer1(),
        tempOriginalTrailer1 = getOriginalOffer().getRig().getTrailer1(),
        tempTrailer2 = getActiveOffer().getRig().getTrailer2(),
        tempOriginalTrailer2 = getOriginalOffer().getRig().getTrailer2();

    var displayId;
    if (offerManager.activeConfigurationDisplay.value !== undefined) {
        displayId = offerManager.activeConfigurationDisplay.value.itemId;
    } else {
        if (globals.user.overrideDefaultMenuDislpay(tempVehicle.getDBUnladenTotal()) === true) {
            displayId = config.DISPLAY_TYPE.DETAILED;
        } else {
            displayId = config.DISPLAY_TYPE.SIMPLIFIED;
        }
    }


    
    if (tempTrailer1 === null) {
        getVehicleOnlyWarnings();
    } else if (tempTrailer2 === null) {
        getTrailer1Warnings();
    } else {
        getTrailer2Warnings()
    }

    
    //if (!bypassCurWarningsUpdate) {
    //    curWarnings = newWarnings;
    //}
    return newWarnings;

    

    function getTrailer1Warnings() {


        var bridgeComplianceOnly;
        var warningsAdded, actualCabProtrusionSwingClearance;

        //getMissingValuesWarning();

        

        //axles overloaded
        getAxlesOverloadedPermissibleWarning();
        if (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
            //if (utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleAndSemiOrInterlinkVehicleFrontAxleOverload())) > 0 ||
            //    utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleAndSemiOrInterlinkVehicleRearAxleOverload())) > 0 ||
            //    utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleAndSemiOrInterlinkTrailerRearAxleOverload())) > 0) {
            //    //warnings.push(config.getTranslationText('33'))
            //    newWarnings.addString(config.WARNING_ID.AXLES_OVERLOADED, config.getTranslationText('33'));
            //}
            //permissible total overloaded
            if (utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getBridgeFormulaPermissibleCombinationOverload())) > 0) {
                // bridgeComplianceOnly = evaluateLegislationToUse(legislationVals.variableTypes.BridgeComplianceOnly);
                // if (bridgeComplianceOnly === undefined || bridgeComplianceOnly === null) {
                //     bridgeComplianceOnly = 0;
                // }
                // if(!(bridgeComplianceOnly === 0 && evaluateLegislationToUse(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles) === 1)) {
                    newWarnings.addString(config.WARNING_ID.PERMISSIBLE_TOTAL_OVERLOADED, config.getTranslationText('694'));
                // }
            }

            //steer axle minumum
            if (evaluateLegislationToUse(legislationVals.variableTypes.ComVehSemiSteerAxleMinimumApplies) === 1) {
                var minimumSteeringAxleofArticulatedPercent = evaluateLegislationToUse(legislationVals.variableTypes.MinimumOnSteeringAxleOfArticulatedPercent);
                if (getCurMeasurementSysRoundedMass((rigOps.getVehicleOnlyFrontUnladen() + rigOpsToUse.getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle() + rigOpsToUse.getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle())) <
                getCurMeasurementSysRoundedMass((minimumSteeringAxleofArticulatedPercent / 100 * rigOpsToUse.getVehicleAndSemiOrInterlinkTotalGross()))) {

                    //warnings.push(config.getTranslationText('697').replace('$S1', evaluateLegislation(legislationVals.variableTypes.MinimumOnSteeringAxleOfArticulatedPercent)));
                    //newWarnings.addString(config.WARNING_ID.STEER_AXLE_MINIMUM, config.getTranslationText('697').replace('$S1', minimumSteeringAxleofArticulatedPercent));
                    newWarnings.addString(config.WARNING_ID.STEER_AXLE_MINIMUM, config.getTranslationText('697', [minimumSteeringAxleofArticulatedPercent]));
                }
            }

            getVehicleAndSemiOrInterlinkBridgeFormulaWarning();

            getOverallWheelbaseBridgeExtremeVehicleWarning();

            //overall length
            var comVehSemiOverallLengthMax = evaluateLegislationToUse(legislationVals.variableTypes.ComVehSemiOverallLengthMax);
            if (getCurMeasurementSysRoundedLength(rigOpsToUse.getOverallLengthLegal()) > getCurMeasurementSysRoundedLength(comVehSemiOverallLengthMax)) {
                //warnings.push(config.getTranslationText('698') + ' ' + evaluateLegislation(legislationVals.variableTypes.ComVehSemiOverallLengthMax));
                //newWarnings.addString(config.WARNING_ID.OVERALL_LENGTH, config.getTranslationText('698') + ' ' + getUIFormattedLengthValue(comVehSemiOverallLengthMax));
                newWarnings.addString(config.WARNING_ID.OVERALL_LENGTH, config.getTranslationText('698', [getUIFormattedLengthValue(comVehSemiOverallLengthMax)]));
            }

            //trailer length
            getTrailerOverallLengthWarning()

            //kingpin to rear of trailer
            var semiArticulationToRearOfTrailer = evaluateLegislationToUse(legislationVals.variableTypes.SemiArticulationToRearOfTrailer);
            if (tempTrailer1.getArticulationPointToRearOfTrailer() > semiArticulationToRearOfTrailer) {
                //newWarnings.addString(config.WARNING_ID.KINGPIN_TO_REAR_OF_TRAILER, config.getTranslationText('880') + ' ' + getUIFormattedLengthValue(semiArticulationToRearOfTrailer));
                newWarnings.addString(config.WARNING_ID.KINGPIN_TO_REAR_OF_TRAILER, config.getTranslationText('880', [getUIFormattedLengthValue(semiArticulationToRearOfTrailer)]));
            }

            //trailer front overhang
            getFrontOverhangWarning();


            //trailer wheelbase theoretical
            //var trailer1WheelbaseTheoreticalMax = evaluateLegislation(legislationVals.variableTypes.Trailer1WheelbaseTheoreticalMax);
            //if (tempTrailer1.getWheelbaseTheoretical() > trailer1WheelbaseTheoreticalMax) {
            //    newWarnings.addString(config.WARNING_ID.TRAILER_WHEELBASE_THEORETICAL, config.getTranslationText('882') + ' ' + getUIFormattedLengthValue(trailer1WheelbaseTheoreticalMax));
            //}
            getTrailerWheelbaseTheoreticalWarning();

            //trailer rear overhang legislation
            getTrailerRearOverhangLegislationWarning();

            //kingpin rearmost axle setting
            getKingpinToRearmostaxleSettingWarning();

        } else if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
            //if (utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleFrontAxleOverload())) > 0 ||
            //    utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleRearAxleOverload())) > 0 ||
            //    utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleAndDrawbarTrailerFrontAxleOverload())) > 0 ||
            //    utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleAndDrawbarTrailerRearAxleOverload())) > 0) {
            //    //warnings.push(config.getTranslationText('33'))
            //    newWarnings.addString(config.WARNING_ID.AXLES_OVERLOADED, config.getTranslationText('33'));
            //}
            //permissible total overloaded
            if (utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getBridgeFormulaPermissibleCombinationOverload())) > 0) {
                // bridgeComplianceOnly = evaluateLegislationToUse(legislationVals.variableTypes.BridgeComplianceOnly);
                // if (bridgeComplianceOnly === undefined || bridgeComplianceOnly === null) {
                //     bridgeComplianceOnly = 0;
                // }
                // if(!(bridgeComplianceOnly === 0 && evaluateLegislationToUse(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles) === 1)) {
                    newWarnings.addString(config.WARNING_ID.PERMISSIBLE_TOTAL_OVERLOADED, config.getTranslationText('694'));
                // }
                
            }

            //steer axle minumum
            getSteerAxleMinimumWarning();

            getVehicleLengthWarning();

            //overall length
            var comVehDrawbarOverallLengthMax = evaluateLegislationToUse(legislationVals.variableTypes.ComVehDrawbarOverallLengthMax);
            if (getCurMeasurementSysRoundedLength(rigOpsToUse.getOverallLengthLegal()) > getCurMeasurementSysRoundedLength(comVehDrawbarOverallLengthMax)) {
                //warnings.push(config.getTranslationText('698') + ' ' + evaluateLegislation(legislationVals.variableTypes.ComVehSemiOverallLengthMax));
                newWarnings.addString(config.WARNING_ID.OVERALL_LENGTH, config.getTranslationText('698', [getUIFormattedLengthValue(comVehDrawbarOverallLengthMax)]));
            }

            //Drawbar heavier than vehicle
            if (evaluateLegislationToUse(legislationVals.variableTypes.ComVehDrawbarDrawbarHeavierThanVehicleApplies) === 1) {
                if (getCurMeasurementSysRoundedLength(tempTrailer1.getUnladenFront()) + getCurMeasurementSysRoundedLength(tempTrailer1.getPayloadFront()) + getCurMeasurementSysRoundedLength(tempTrailer1.getUnladenRear()) + getCurMeasurementSysRoundedLength(tempTrailer1.getPayloadRear()) >
                    getCurMeasurementSysRoundedLength(rigOpsToUse.getVehicleOnlyFrontGross()) + getCurMeasurementSysRoundedLength(rigOpsToUse.getVehicleOnlyRearGross())) {
                    newWarnings.addString(config.WARNING_ID.DRAWBAR_HEAVIER_THAN_VEHICLE, config.getTranslationText('929'));
                }
            }

            getVehicleAndDrawbarBridgeFormulaWarning();

            getOverallWheelbaseBridgeExtremeVehicleWarning();

            getDrawbarLengthWarning();

            getTrailerOverallLengthWarning();

            getDrawbarArticulationPointToRearOfTrailerWarning();

            getFrontOverhangWarning();

            getTrailerWheelbaseTheoreticalWarning();

            getTrailerRearOverhangLegislationWarning();

            getDrawbarGapBetweenVehicleAndTrailerWarning();

            getCargoCarryingLengthWarning();

        } else if (tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
            //axles overloaded
            //if (utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleAndPupVehicleFrontAxleOverload())) > 0 ||
            //    utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleAndPupVehicleRearAxleOverload())) > 0 ||
            //    utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleAndPupTrailerRearAxleOverload())) > 0) {
            //    //warnings.push(config.getTranslationText('33'))
            //    newWarnings.addString(config.WARNING_ID.AXLES_OVERLOADED, config.getTranslationText('33'));
            //}

            //permissible total overloaded
            if (utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getBridgeFormulaPermissibleCombinationOverload())) > 0) {
                // bridgeComplianceOnly = evaluateLegislationToUse(legislationVals.variableTypes.BridgeComplianceOnly);
                // if (bridgeComplianceOnly === undefined || bridgeComplianceOnly === null) {
                //     bridgeComplianceOnly = 0;
                // }
                // if(!(bridgeComplianceOnly === 0 && evaluateLegislationToUse(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles) === 1)) {
                    newWarnings.addString(config.WARNING_ID.PERMISSIBLE_TOTAL_OVERLOADED, config.getTranslationText('694'));
                // }
            }

            //steer axle minumum
            if (evaluateLegislationToUse(legislationVals.variableTypes.ComVehSteerAxleMinimumApplies) === 1) {
                if (evaluateLegislationToUse(legislationVals.variableTypes.ComVehSteerAxleDualUnder) === 1) {
                    var minimumOnDualSteeringAxlePercent = evaluateLegislationToUse(legislationVals.variableTypes.MinimumOnDualSteeringAxlePercent);
                    if (getCurMeasurementSysRoundedMass((rigOpsToUse.getVehicleOnlyFrontUnladen() + rigOpsToUse.getVehicleAndPupTareTransferOntoVehicleFrontAxle() + rigOpsToUse.getVehicleAndPupPayloadTransferOntoVehicleFrontAxle())) <
                        getCurMeasurementSysRoundedMass((minimumOnDualSteeringAxlePercent / 100 * rigOpsToUse.getVehicleAndPupTotalGross()))) {

                        //warnings.push(config.getTranslationText('697').replace('$S1', evaluateLegislation(legislationVals.variableTypes.MinimumOnSteeringAxleOfArticulatedPercent)));
                        newWarnings.addString(config.WARNING_ID.STEER_AXLE_MINIMUM, config.getTranslationText('697', [minimumOnDualSteeringAxlePercent]));
                    }
                } else if (evaluateLegislationToUse(legislationVals.variableTypes.ComVehSteerAxleSingleUnder) === 1) {
                    var minimumOnSingleSteeringAxlePercent = evaluateLegislationToUse(legislationVals.variableTypes.MinimumOnSingleSteeringAxlePercent);
                    if (getCurMeasurementSysRoundedMass((rigOpsToUse.getVehicleOnlyFrontUnladen() + rigOpsToUse.getVehicleAndPupTareTransferOntoVehicleFrontAxle() + rigOpsToUse.getVehicleAndPupPayloadTransferOntoVehicleFrontAxle())) <
                        getCurMeasurementSysRoundedMass((minimumOnSingleSteeringAxlePercent / 100 * rigOpsToUse.getVehicleAndPupTotalGross()))) {

                        //warnings.push(config.getTranslationText('697').replace('$S1', evaluateLegislation(legislationVals.variableTypes.MinimumOnSteeringAxleOfArticulatedPercent)));
                        newWarnings.addString(config.WARNING_ID.STEER_AXLE_MINIMUM, config.getTranslationText('697', [minimumOnSingleSteeringAxlePercent]));
                    }
                }
            }

            //Pup heavier than vehicle
            if (evaluateLegislationToUse(legislationVals.variableTypes.ComVehPupPupHeavierThanVehicleApplies) === 1) {
                if (getCurMeasurementSysRoundedLength(tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear()) >
                    getCurMeasurementSysRoundedLength(rigOpsToUse.getVehicleFrontAxleGrossIncludingTrailer1PupTransfer() + rigOpsToUse.getVehicleRearAxleGrossIncludingTrailer1PupTransfer())) {
                    newWarnings.addString(config.WARNING_ID.PUP_HEAVIER_THAN_VEHICLE, config.getTranslationText('929'));
                }
            }

            getVehicleAndPupBridgeFormulaWarning();

            getOverallWheelbaseBridgeExtremeVehicleWarning();

            getVehicleLengthWarning();

            //overall length
            var comVehPupOverallLengthMax = evaluateLegislationToUse(legislationVals.variableTypes.ComVehPupOverallLengthMax);

            if (getCurMeasurementSysRoundedLength(rigOpsToUse.getOverallLengthLegal()) > getCurMeasurementSysRoundedLength(comVehPupOverallLengthMax)) {
                //warnings.push(config.getTranslationText('698') + ' ' + evaluateLegislation(legislationVals.variableTypes.ComVehSemiOverallLengthMax));
                newWarnings.addString(config.WARNING_ID.OVERALL_LENGTH, config.getTranslationText('698', [getUIFormattedLengthValue(comVehPupOverallLengthMax)]));
            }

            getDrawbarLengthWarning();

            getTrailerOverallLengthWarning();

            getTrailerWheelbaseTheoreticalWarning();

            getTrailerRearOverhangLegislationWarning();

            getPupGapBetweenVehicleAndTrailerWarning();

            getCargoCarryingLengthWarning();
        }


        getOverallHeightExceededWarningText();



        var turningRadiusCurbToCurMax = evaluateLegislationToUse(legislationVals.variableTypes.TurningRadiusCurbToCurbMax);
        var turningRadiusOuterMax = evaluateLegislationToUse(legislationVals.variableTypes.TurningRadiusOuterMax);

        if (turningRadiusCurbToCurMax !== 0 ||
            turningRadiusOuterMax !== 0 && activeConfigurationViewDetail.value !== undefined && activeConfigurationViewDetail.value.itemId === config.VIEW_DETAIL.TURNING_CIRCLE_REGULATION) {

            var turningCircleVals = rigOpsToUse.getTurningCircleValues(config.VIEW_DETAIL.TURNING_CIRCLE_REGULATION);
            var turningRadiusInnerMin = evaluateLegislationToUse(legislationVals.variableTypes.TurningRadiusInnerMin);

            if (getActiveOffer().getRig().getVehicle().allowTurningCircleCalculation() && (turningRadiusCurbToCurMax !== 0 || turningRadiusOuterMax !== 0)) {
                if (turningCircleVals.trailer1TurningRadiusInner < turningRadiusInnerMin) {
                    //newWarnings.addString(config.WARNING_ID.TURNING_RADIUS_INNER, config.getTranslationText('856').replace('$S1', getUIFormattedLengthValue(turningCircleVals.trailer1TurningRadiusInner)).replace('$S2', getUIFormattedLengthValue(turningRadiusInnerMin)));
                    newWarnings.addString(config.WARNING_ID.TURNING_RADIUS_INNER, config.getTranslationText('856', [getUIFormattedLengthValue(turningCircleVals.trailer1TurningRadiusInner), getUIFormattedLengthValue(turningRadiusInnerMin)]));
                }
                if (turningRadiusCurbToCurMax > 0) {
                    if (turningCircleVals.turningRadiusCurbToCurb > turningRadiusCurbToCurMax) {
                        //newWarnings.addString(config.WARNING_ID.TURNING_RADIUS_OUTER, config.getTranslationText('857').replace('$S1', getUIFormattedLengthValue(turningCircleVals.turningRadiusCurbToCurb)).replace('$S2', getUIFormattedLengthValue(turningRadiusCurbToCurMax)));
                        newWarnings.addString(config.WARNING_ID.TURNING_RADIUS_OUTER, config.getTranslationText('857', [getUIFormattedLengthValue(turningCircleVals.turningRadiusCurbToCurb), getUIFormattedLengthValue(turningRadiusCurbToCurMax)]));
                    }
                } else {
                    if (turningCircleVals.turningRadiusWallToWall > turningRadiusOuterMax) {
                        //newWarnings.addString(config.WARNING_ID.TURNING_RADIUS_OUTER, config.getTranslationText('858').replace('$S1', getUIFormattedLengthValue(turningCircleVals.turningRadiusWallToWall)).replace('$S2', getUIFormattedLengthValue(turningRadiusOuterMax)));
                        newWarnings.addString(config.WARNING_ID.TURNING_RADIUS_OUTER, config.getTranslationText('858', [getUIFormattedLengthValue(turningCircleVals.turningRadiusWallToWall), getUIFormattedLengthValue(turningRadiusOuterMax)]));
                    }

                }

            }
            //if (rigOps.isSwingClearanceWarning()) {
            //    newWarnings.addString(config.WARNING_ID.SWING_CLEARANCE, config.getTranslationText('699').replace('$S1', getCurMeasurementSysRoundedLength(rigOps.getVehicleAndSemiOrInterlinkSwingClearanceBetweenVehicleAndTrailer())));
            //}
            //if (rigOps.getVehicleAndSemiOrInterlinkProtrusionSwingClearanceBetweenVehicleAndTrailer() < config.protrusionSwingClearanceLimit) {
            //    newWarnings.addString(config.WARNING_ID.SWING_CLEARANCE_CAB_PROTRUSION, config.getTranslationText('700').replace('$S1', getCurMeasurementSysRoundedLength(rigOps.getVehicleAndSemiOrInterlinkProtrusionSwingClearanceBetweenVehicleAndTrailer())));

            //}
        }


        //getCantCalculateStaticRolloverText();
    }

    function getTrailer2Warnings() {



        var warningsAdded, actualCabProtrusionSwingClearance;

        //getMissingValuesWarning();

        

        //axles overloaded
        getAxlesOverloadedPermissibleWarning();
        if (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
            //if (utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleAndInterlinkAndSemiVehicleFrontAxleOverload())) > 0 ||
            //    utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleAndInterlinkAndSemiVehicleRearAxleOverload())) > 0 ||
            //    utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleAndInterlinkAndSemiTrailer1RearAxleOverload())) > 0 ||
            //    utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleAndInterlinkAndSemiTrailer2RearAxleOverload())) > 0) {
            //    //warnings.push(config.getTranslationText('33'))
            //    newWarnings.addString(config.WARNING_ID.AXLES_OVERLOADED, config.getTranslationText('33'));
            //}
            //permissible total overloaded
            if (utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getBridgeFormulaPermissibleCombinationOverload())) > 0) {
                // var bridgeComplianceOnly = evaluateLegislationToUse(legislationVals.variableTypes.BridgeComplianceOnly);
                // if (bridgeComplianceOnly === undefined || bridgeComplianceOnly === null) {
                //     bridgeComplianceOnly = 0;
                // }
                // if(!(bridgeComplianceOnly === 0 && evaluateLegislationToUse(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles) === 1)) {
                    newWarnings.addString(config.WARNING_ID.PERMISSIBLE_TOTAL_OVERLOADED, config.getTranslationText('694'));
                // }
            }

            //steer axle minumum
            if (evaluateLegislationToUse(legislationVals.variableTypes.ComVehIntSemiSteerAxleMinimumApplies) === 1) {
                var minimumSteeringAxleofArticulatedPercent = evaluateLegislationToUse(legislationVals.variableTypes.MinimumOnSteeringAxleOfArticulatedPercent);
                if (getCurMeasurementSysRoundedMass((rigOpsToUse.getVehicleOnlyFrontUnladen() + rigOpsToUse.getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle() + rigOpsToUse.getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle() +
                    rigOpsToUse.getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleFrontAxle() + rigOpsToUse.getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoVehicleFrontAxle())) <
                    getCurMeasurementSysRoundedMass((minimumSteeringAxleofArticulatedPercent / 100 * (rigOpsToUse.getVehicleAndInterlinkAndSemiTotalGross() - tempTrailer2.getUnladenRear() - tempTrailer2.getPayloadRear())))) {

                    //warnings.push(config.getTranslationText('697').replace('$S1', evaluateLegislation(legislationVals.variableTypes.MinimumOnSteeringAxleOfArticulatedPercent)));
                    //newWarnings.addString(config.WARNING_ID.STEER_AXLE_MINIMUM, config.getTranslationText('697').replace('$S1', minimumSteeringAxleofArticulatedPercent));
                    newWarnings.addString(config.WARNING_ID.STEER_AXLE_MINIMUM, config.getTranslationText('697', [minimumSteeringAxleofArticulatedPercent]));
                }
            }

            


            getVehicleAndInterlinkAndSemiBridgeFormulaWarning();



            //overall length
            var comVehIntSemiOverallLengthMax = evaluateLegislationToUse(legislationVals.variableTypes.ComVehIntSemiOverallLengthMax);
            if (getCurMeasurementSysRoundedLength(rigOpsToUse.getOverallLengthLegal()) > getCurMeasurementSysRoundedLength(comVehIntSemiOverallLengthMax)) {
                //warnings.push(config.getTranslationText('698') + ' ' + evaluateLegislation(legislationVals.variableTypes.ComVehSemiOverallLengthMax));
                newWarnings.addString(config.WARNING_ID.OVERALL_LENGTH, config.getTranslationText('698', [getUIFormattedLengthValue(comVehIntSemiOverallLengthMax)]));
            }

            //trailer length
            getTrailer1OverallLengthWarning();
            getTrailer2OverallLengthWarning();

            if (evaluateLegislationToUse(legislationVals.variableTypes.KingpinToRearOfTrailerUsed) === 1 && globals.user.getActiveLegislation().countryId === 3) {
                var semiOrDogArticulationToRearOfTrailer = evaluateLegislationToUse(legislationVals.variableTypes.SemiOrDogArticulationToRearOfTrailer);
                if (getCurMeasurementSysRoundedLength(tempTrailer2.getArticulationPointToRearOfTrailer()) > getCurMeasurementSysRoundedLength(semiOrDogArticulationToRearOfTrailer)) {
                    //newWarnings.addString(config.WARNING_ID.KINGPIN_TO_REAR_OF_TRAILER, config.getTranslationText('1288').replace('$S1', getUIFormattedLengthValue(semiOrDogArticulationToRearOfTrailer)));
                    newWarnings.addString(config.WARNING_ID.KINGPIN_TO_REAR_OF_TRAILER, config.getTranslationText('1288', [getUIFormattedLengthValue(semiOrDogArticulationToRearOfTrailer)]));
                }
            }

            //trailer front overhang
            getTrailer1FrontOverhangWarning();
            getTrailer2FrontOverhangWarning();


            //trailer wheelbase theoretical
            //var trailer1WheelbaseTheoreticalMax = evaluateLegislation(legislationVals.variableTypes.Trailer1WheelbaseTheoreticalMax);
            //if (tempTrailer1.getWheelbaseTheoretical() > trailer1WheelbaseTheoreticalMax) {
            //    newWarnings.addString(config.WARNING_ID.TRAILER_WHEELBASE_THEORETICAL, config.getTranslationText('882') + ' ' + getUIFormattedLengthValue(trailer1WheelbaseTheoreticalMax));
            //}
            getTrailer1WheelbaseTheoreticalWarning();
            getTrailer2WheelbaseTheoreticalWarning();

            //trailer rear overhang legislation
            getTrailer1RearOverhangLegislationWarning();
            getTrailer2RearOverhangLegislationWarning();

        }
        /*else if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            if (utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleFrontAxleOverload())) > 0 ||
                                utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleRearAxleOverload())) > 0 ||
                                utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleAndDrawbarTrailerFrontAxleOverload())) > 0 ||
                                utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleAndDrawbarTrailerRearAxleOverload())) > 0) {
                                //warnings.push(config.getTranslationText('33'))
                                newWarnings.addString(config.WARNING_ID.AXLES_OVERLOADED, config.getTranslationText('33'));
                            }
                            //permissible total overloaded
                            if (utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleAndDrawbarTotalOverload())) > 0) {
                                newWarnings.addString(config.WARNING_ID.PERMISSIBLE_TOTAL_OVERLOADED, config.getTranslationText('694'));
                            }

                            //steer axle minumum
                            getSteerAxleMinimumWarning();


                            //overall length
                            var comVehDrawbarOverallLengthMax = evaluateLegislationToUse(legislationVals.variableTypes.ComVehDrawbarOverallLengthMax);
                            if (getCurMeasurementSysRoundedLength(rigOpsToUse.getOverallLengthLegal()) > comVehDrawbarOverallLengthMax) {
                                //warnings.push(config.getTranslationText('698') + ' ' + evaluateLegislation(legislationVals.variableTypes.ComVehSemiOverallLengthMax));
                                newWarnings.addString(config.WARNING_ID.OVERALL_LENGTH, config.getTranslationText('698') + ' ' + getUIFormattedLengthValue(comVehDrawbarOverallLengthMax));
                            }

                            //Drawbar heavier than vehicle
                            if (evaluateLegislation(legislationVals.variableTypes.ComVehDrawbarDrawbarHeavierThanVehicleApplies) === 1) {
                                if (getCurMeasurementSysRoundedLength(tempTrailer1.getUnladenFront() + tempTrailer1.getPayloadFront() + tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear()) >
                                    getCurMeasurementSysRoundedLength(rigOps.getVehicleOnlyFrontGross() + rigOps.getVehicleOnlyRearGross())) {
                                    newWarnings.addString(config.WARNING_ID.DRAWBAR_HEAVIER_THAN_VEHICLE, config.getTranslationText('929'));
                                }
                            }

                            getVehicleAndDrawbarBridgeFormulaWarning();


                            getDrawbarLengthWarning();

                            getTrailerOverallLengthWarning();

                            getDrawbarArticulationPointToRearOfTrailerWarning();

                            getFrontOverhangWarning();

                            getTrailerWheelbaseTheoreticalWarning();

                            getTrailerRearOverhangLegislationWarning();

                            getDrawbarGapBetweenVehicleAndTrailerWarning();
                        } else if (tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                            //axles overloaded
                            if (utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleAndPupVehicleFrontAxleOverload())) > 0 ||
                                utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleAndPupVehicleRearAxleOverload())) > 0 ||
                                utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleAndPupTrailerRearAxleOverload())) > 0) {
                                //warnings.push(config.getTranslationText('33'))
                                newWarnings.addString(config.WARNING_ID.AXLES_OVERLOADED, config.getTranslationText('33'));
                            }

                            //permissible total overloaded
                            if (utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleAndPupTotalOverload())) > 0) {
                                newWarnings.addString(config.WARNING_ID.PERMISSIBLE_TOTAL_OVERLOADED, config.getTranslationText('694'));
                            }

                            //steer axle minumum
                            if (evaluateLegislationToUse(legislationVals.variableTypes.ComVehSteerAxleMinimumApplies) === 1) {
                                if (evaluateLegislationToUse(legislationVals.variableTypes.ComVehSteerAxleDualUnder) === 1) {
                                    var minimumOnDualSteeringAxlePercent = evaluateLegislationToUse(legislationVals.variableTypes.MinimumOnDualSteeringAxlePercent);
                                    if (getCurMeasurementSysRoundedMass((tempVehicle.getUnladenFront() + rigOpsToUse.getVehicleAndPupTareTransferOntoVehicleFrontAxle() + rigOpsToUse.getVehicleAndPupPayloadTransferOntoVehicleFrontAxle())) <
                                            getCurMeasurementSysRoundedLength((minimumOnDualSteeringAxlePercent / 100 * rigOpsToUse.getVehicleAndPupTotalGross()))) {

                                        //warnings.push(config.getTranslationText('697').replace('$S1', evaluateLegislation(legislationVals.variableTypes.MinimumOnSteeringAxleOfArticulatedPercent)));
                                        //newWarnings.addString(config.WARNING_ID.STEER_AXLE_MINIMUM, config.getTranslationText('697').replace('$S1', minimumOnDualSteeringAxlePercent));
                                        newWarnings.addString(config.WARNING_ID.STEER_AXLE_MINIMUM, config.getTranslationText('697', [minimumOnDualSteeringAxlePercent]));
                                    }
                                } else if (evaluateLegislationToUse(legislationVals.variableTypes.ComVehSteerAxleSingleUnder) === 1) {
                                    var minimumOnSingleSteeringAxlePercent = evaluateLegislationToUse(legislationVals.variableTypes.MinimumOnSingleSteeringAxlePercent);
                                    if (getCurMeasurementSysRoundedMass((tempVehicle.getUnladenFront() + rigOpsToUse.getVehicleAndPupTareTransferOntoVehicleFrontAxle() + rigOpsToUse.getVehicleAndPupPayloadTransferOntoVehicleFrontAxle())) <
                                            getCurMeasurementSysRoundedLength((minimumOnSingleSteeringAxlePercent / 100 * rigOpsToUse.getVehicleAndPupTotalGross()))) {

                                        //warnings.push(config.getTranslationText('697').replace('$S1', evaluateLegislation(legislationVals.variableTypes.MinimumOnSteeringAxleOfArticulatedPercent)));
                                        //newWarnings.addString(config.WARNING_ID.STEER_AXLE_MINIMUM, config.getTranslationText('697').replace('$S1', minimumOnSingleSteeringAxlePercent));
                                        newWarnings.addString(config.WARNING_ID.STEER_AXLE_MINIMUM, config.getTranslationText('697', [minimumOnSingleSteeringAxlePercent]));
                                    }
                                }
                            }

                            //Pup heavier than vehicle
                            if (evaluateLegislationToUse(legislationVals.variableTypes.ComVehPupPupHeavierThanVehicleApplies) === 1) {
                                if (getCurMeasurementSysRoundedLength(tempTrailer1.getUnladenFront() + tempTrailer1.getPayloadFront() + tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear()) >
                                    getCurMeasurementSysRoundedLength(rigOps.getVehicleOnlyFrontGross() + rigOps.getVehicleOnlyRearGross())) {
                                    newWarnings.addString(config.WARNING_ID.PUP_HEAVIER_THAN_VEHICLE, config.getTranslationText('929'));
                                }
                            }

                            getVehicleAndPupBridgeFormulaWarning();

                            //overall length
                            var comVehPupOverallLengthMax = evaluateLegislationToUse(legislationVals.variableTypes.ComVehPupOverallLengthMax);

                            if (getCurMeasurementSysRoundedLength(rigOps.getOverallLengthLegal()) > comVehPupOverallLengthMax) {
                                //warnings.push(config.getTranslationText('698') + ' ' + evaluateLegislation(legislationVals.variableTypes.ComVehSemiOverallLengthMax));
                                newWarnings.addString(config.WARNING_ID.OVERALL_LENGTH, config.getTranslationText('698') + ' ' + getUIFormattedLengthValue(comVehPupOverallLengthMax));
                            }

                            getDrawbarLengthWarning();

                            getTrailerOverallLengthWarning();

                            getTrailerWheelbaseTheoreticalWarning();

                            getTrailerRearOverhangLegislationWarning();

                            getPupGapBetweenVehicleAndTrailerWarning();
                        }*/


        getOverallHeightExceededWarningText();

        getCargoCarryingLengthWarning();

        getXYDimensionExceededWarnings();

        getOverallWheelbaseBridgeExtremeVehicleWarning();

        var turningRadiusCurbToCurMax = evaluateLegislationToUse(legislationVals.variableTypes.TurningRadiusCurbToCurbMax);
        var turningRadiusOuterMax = evaluateLegislationToUse(legislationVals.variableTypes.TurningRadiusOuterMax);

        if (turningRadiusCurbToCurMax !== 0 ||
            turningRadiusOuterMax !== 0 && activeConfigurationViewDetail.value !== undefined && activeConfigurationViewDetail.value.itemId === config.VIEW_DETAIL.TURNING_CIRCLE_REGULATION) {

            var turningCircleVals = rigOpsToUse.getTurningCircleValues(config.VIEW_DETAIL.TURNING_CIRCLE_REGULATION);
            var turningRadiusInnerMin = evaluateLegislationToUse(legislationVals.variableTypes.TurningRadiusInnerMin);

            if (getActiveOffer().getRig().getVehicle().allowTurningCircleCalculation() && (turningRadiusCurbToCurMax !== 0 || turningRadiusOuterMax !== 0)) {



                if (turningCircleVals.trailer1TurningRadiusInner < turningRadiusInnerMin) {
                    //newWarnings.addString(config.WARNING_ID.TURNING_RADIUS_INNER, config.getTranslationText('856').replace('$S1', getUIFormattedLengthValue(turningCircleVals.trailer1TurningRadiusInner)).replace('$S2', getUIFormattedLengthValue(turningRadiusInnerMin)));
                    newWarnings.addString(config.WARNING_ID.TURNING_RADIUS_INNER, config.getTranslationText('856', [getUIFormattedLengthValue(turningCircleVals.trailer1TurningRadiusInner), getUIFormattedLengthValue(turningRadiusInnerMin)]));
                }
                if (turningRadiusCurbToCurMax > 0) {
                    if (turningCircleVals.turningRadiusCurbToCurb > turningRadiusCurbToCurMax) {
                        //newWarnings.addString(config.WARNING_ID.TURNING_RADIUS_OUTER, config.getTranslationText('857').replace('$S1', getUIFormattedLengthValue(turningCircleVals.turningRadiusCurbToCurb)).replace('$S2', getUIFormattedLengthValue(turningRadiusCurbToCurMax)));
                        newWarnings.addString(config.WARNING_ID.TURNING_RADIUS_OUTER, config.getTranslationText('857', [getUIFormattedLengthValue(turningCircleVals.turningRadiusCurbToCurb), getUIFormattedLengthValue(turningRadiusCurbToCurMax)]));
                    }
                } else {
                    if (turningCircleVals.turningRadiusWallToWall > turningRadiusOuterMax) {
                        //newWarnings.addString(config.WARNING_ID.TURNING_RADIUS_OUTER, config.getTranslationText('858').replace('$S1', getUIFormattedLengthValue(turningCircleVals.turningRadiusWallToWall)).replace('$S2', getUIFormattedLengthValue(turningRadiusOuterMax)));
                        newWarnings.addString(config.WARNING_ID.TURNING_RADIUS_OUTER, config.getTranslationText('858', [getUIFormattedLengthValue(turningCircleVals.turningRadiusWallToWall), getUIFormattedLengthValue(turningRadiusOuterMax)]));
                    }

                }

            }
            //if (rigOps.isSwingClearanceWarning()) {
            //    newWarnings.addString(config.WARNING_ID.SWING_CLEARANCE, config.getTranslationText('699').replace('$S1', getCurMeasurementSysRoundedLength(rigOps.getVehicleAndSemiOrInterlinkSwingClearanceBetweenVehicleAndTrailer())));
            //}
            //if (rigOps.getVehicleAndSemiOrInterlinkProtrusionSwingClearanceBetweenVehicleAndTrailer() < config.protrusionSwingClearanceLimit) {
            //    newWarnings.addString(config.WARNING_ID.SWING_CLEARANCE_CAB_PROTRUSION, config.getTranslationText('700').replace('$S1', getCurMeasurementSysRoundedLength(rigOps.getVehicleAndSemiOrInterlinkProtrusionSwingClearanceBetweenVehicleAndTrailer())));

            //}
        }


        //getCantCalculateStaticRolloverText();
    }






    function getVehicleOnlyWarnings() {
        //getMissingValuesWarning();
        

        var dontShowAxlesOverloadWarningForFront = false,
            dontShowAxlesOverloadWarningForRear = false;
        
        var curMissingValsObject;
        if (activeConfigurationViewType.value !== undefined && activeConfigurationViewType.value !== null) {
            if (activeConfigurationViewType.value.itemId === config.VIEW_TYPE.OVERVIEW || activeConfigurationViewType.value.itemId === config.VIEW_TYPE.COG) {
                //missingValuesWarningText = getMissingValuesWarningText(getMissingValuesLookupObject, false);
                curMissingValsObject = getMissingValuesLookupObject();


            } else if (activeConfigurationViewType.value.itemId === config.VIEW_TYPE.TURNING_CIRCLE) {
                //missingValuesWarningText = getMissingValuesWarningText(getTurningCircleMissingValuesLookupObject, false);
                curMissingValsObject = getTurningCircleMissingValuesLookupObject();
            }
        }

        if (curMissingValsObject !== undefined && (curMissingValsObject[config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS] !== undefined || curMissingValsObject[config.VALUE_TYPE.TAREFRONT] !== undefined)) {
            dontShowAxlesOverloadWarningForFront = true;
        }
        if (curMissingValsObject !== undefined && (curMissingValsObject[config.VALUE_TYPE.MANUFACTURERSREARAXLEMASS] !== undefined || curMissingValsObject[config.VALUE_TYPE.TAREREAR] !== undefined)) {
            dontShowAxlesOverloadWarningForRear = true;
        }
        //if (missingValuesWarningText !== '') {
        //    //warnings.push(missingValuesWarningText);
        //    newWarnings.addString(config.WARNING_ID.MISSING_VALUES, missingValuesWarningText);
        //}

        
        //Axles overloaded
        getAxlesOverloadedPermissibleWarning(config.CHASSIS_OBJECT_TYPES.VEHICLE, dontShowAxlesOverloadWarningForFront, dontShowAxlesOverloadWarningForRear);

        // //Permissible Overloaded
        // if (utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleTotalOverload())) > 0 && !(dontShowAxlesOverloadWarningForFront === true && dontShowAxlesOverloadWarningForRear === true)) {
        //     var bridgeComplianceOnly = evaluateLegislationToUse(legislationVals.variableTypes.BridgeComplianceOnly);
        //     if (bridgeComplianceOnly === undefined || bridgeComplianceOnly === null) {
        //         bridgeComplianceOnly = 0;
        //     }
        //     if(!(bridgeComplianceOnly === 0 && evaluateLegislationToUse(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles) === 1)) {
        //         newWarnings.addString(config.WARNING_ID.PERMISSIBLE_TOTAL_OVERLOADED, config.getTranslationText('535'));
        //     }
        // }
        //Permissible Overloaded
        if (utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getBridgeFormulaPermissibleCombinationOverload())) > 0 && !(dontShowAxlesOverloadWarningForFront === true && dontShowAxlesOverloadWarningForRear === true)) {
            // var bridgeComplianceOnly = evaluateLegislationToUse(legislationVals.variableTypes.BridgeComplianceOnly);
            // if (bridgeComplianceOnly === undefined || bridgeComplianceOnly === null) {
            //     bridgeComplianceOnly = 0;
            // }
            // if(!(bridgeComplianceOnly === 0 && evaluateLegislationToUse(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles) === 1)) {
                newWarnings.addString(config.WARNING_ID.PERMISSIBLE_TOTAL_OVERLOADED, config.getTranslationText('535'));
            // }
        }
        //Steer Axle Minimum
        getSteerAxleMinimumWarning();
        getVehicleBridgeFormulaWarning();
        getOverallWheelbaseBridgeExtremeVehicleWarning();

        var turningRadiusCurbToCurMax = evaluateLegislationToUse(legislationVals.variableTypes.TurningRadiusCurbToCurbMax);
        var turningRadiusOuterMax = evaluateLegislationToUse(legislationVals.variableTypes.TurningRadiusOuterMax);

        if (turningRadiusCurbToCurMax !== 0 || turningRadiusOuterMax !== 0) {
            var turningCircleVals = rigOpsToUse.getTurningCircleValues(config.VIEW_DETAIL.TURNING_CIRCLE_REGULATION);
            var turningRadiusInnerMin = evaluateLegislationToUse(legislationVals.variableTypes.TurningRadiusInnerMin);

            if (getActiveOffer().getRig().getVehicle().allowTurningCircleCalculation() && (turningRadiusCurbToCurMax !== 0 || turningRadiusOuterMax !== 0)) {



                if (turningCircleVals.vehicleTurningRadiusInner < turningRadiusInnerMin) {
                    newWarnings.addString(config.WARNING_ID.TURNING_RADIUS_INNER, config.getTranslationText('856', [getUIFormattedLengthValue(turningCircleVals.vehicleTurningRadiusInner), getUIFormattedLengthValue(turningRadiusInnerMin)]));
                }
                if (turningRadiusCurbToCurMax > 0) {
                    if (turningCircleVals.turningRadiusCurbToCurb > turningRadiusCurbToCurMax) {
                        //newWarnings.addString(config.WARNING_ID.TURNING_RADIUS_OUTER, config.getTranslationText('857').replace('$S1', getUIFormattedLengthValue(turningCircleVals.turningRadiusCurbToCurb)).replace('$S2', getUIFormattedLengthValue(turningRadiusCurbToCurMax)));
                        newWarnings.addString(config.WARNING_ID.TURNING_RADIUS_OUTER, config.getTranslationText('857', [getUIFormattedLengthValue(turningCircleVals.turningRadiusCurbToCurb), getUIFormattedLengthValue(turningRadiusCurbToCurMax)]));
                    }
                } else {
                    if (turningCircleVals.turningRadiusWallToWall > turningRadiusOuterMax) {
                        //newWarnings.addString(config.WARNING_ID.TURNING_RADIUS_OUTER, config.getTranslationText('858').replace('$S1', getUIFormattedLengthValue(turningCircleVals.turningRadiusWallToWall)).replace('$S2', getUIFormattedLengthValue(turningRadiusOuterMax)));
                        newWarnings.addString(config.WARNING_ID.TURNING_RADIUS_OUTER, config.getTranslationText('858', [getUIFormattedLengthValue(turningCircleVals.turningRadiusWallToWall), getUIFormattedLengthValue(turningRadiusOuterMax)]));
                    }

                }

            }
        }
        getRearOverhangExceededWarningText();
        getHookliftBodyOverhangWarningText();
        getWheelbaseExceededWarningText();
        getOverallLengthExceededWarningText();
        getOverallHeightExceededWarningText();
        
        //getCantCalculateStaticRolloverText();
    }

    function getKingpinToRearmostaxleSettingWarning() {
        var kingpinToRearMostAxleSetting = getActiveOffer().getRig().getTrailer1().getKingpinToRearMostAxleSetting();
        var kingpinToRearmostAxleSettingSettingMax = evaluateLegislationToUse(legislationVals.variableTypes.KingpinToRearmostAxleSettingMax);

        if (getCurMeasurementSysRoundedLength(kingpinToRearMostAxleSetting) > getCurMeasurementSysRoundedLength(kingpinToRearmostAxleSettingSettingMax)) {
            newWarnings.addString(config.WARNING_ID.KINGPIN_TO_REARMOST_AXLE_SETTING_EXCEEDED, config.getTranslationText('4255', [getUIFormattedLengthValue(kingpinToRearMostAxleSetting), getUIFormattedLengthValue(kingpinToRearmostAxleSettingSettingMax)]));
        }
    }

    function getXYDimensionExceededWarnings() {

        if (evaluateLegislationToUse(legislationVals.variableTypes.ComVehIntSemiXYUsed) === 1) {

            if (evaluateLegislationToUse(legislationVals.variableTypes.ComVehIntSemiXExceeded) === 1) {
                //newWarnings.addString(config.WARNING_ID.X_DIMENSION_EXCEEDED, config.getTranslationText('1295').replace('$S1', getUIFormattedLengthValue(evaluateLegislationToUse(legislationVals.variableTypes.ComVehIntSemiXMinusYMax))));
                newWarnings.addString(config.WARNING_ID.X_DIMENSION_EXCEEDED, config.getTranslationText('1295', [getUIFormattedLengthValue(evaluateLegislationToUse(legislationVals.variableTypes.ComVehIntSemiXMinusYMax))]));
            }

            if (evaluateLegislationToUse(legislationVals.variableTypes.ComVehIntSemiYExceeded) === 1) {
                //newWarnings.addString(config.WARNING_ID.Y_DIMENSION_EXCEEDED, config.getTranslationText('1296').replace('$S1', getUIFormattedLengthValue(evaluateLegislationToUse(legislationVals.variableTypes.ComVehIntSemiYMinusXMax))));
                newWarnings.addString(config.WARNING_ID.Y_DIMENSION_EXCEEDED, config.getTranslationText('1296', [getUIFormattedLengthValue(evaluateLegislationToUse(legislationVals.variableTypes.ComVehIntSemiYMinusXMax))]));
            }
        }

    }

    

    function getVehicleManufacturerRatingFrontAxlesWarning() {
        if (rigOpsToUse.getVehicleManufacturerRatingFrontAxlesExceeded()) {
            newWarnings.addString(config.WARNING_ID.MANUFACTURERS_RATING_VEHICLE_FRONT_AXLES_EXCEEDED, config.getTranslationText('1280'));
        }
    }

    function getVehicleManufacturerRatingRearAxlesWarning() {
        if (rigOpsToUse.getVehicleManufacturerRatingRearAxlesExceeded()) {
            newWarnings.addString(config.WARNING_ID.MANUFACTURERS_RATING_VEHICLE_REAR_AXLES_EXCEEDED, config.getTranslationText('1281'));
        }
    }

    function getPusherOrTagManufacturerRatingWarning() {
        if (getActiveOffer().getRig().getVehicle().getAccessoryHolder().getAnyPusherOrTagAxleOverloaded(config.roundingPrecisionForTableAndWarnings)) {
            newWarnings.addString(config.WARNING_ID.MANUFACTURERS_RATING_PUSHER_OR_TAG_AXLE_EXCEEDED, config.getTranslationText('1282'));
        }
    }

    function getTrailer1ManufacturerRatingRearAxlesWarning() {
        if (rigOpsToUse.getManufacturerWeightsTrailer1RearAxlesExceeded()) {
            newWarnings.addString(config.WARNING_ID.MANUFACTURERS_RATING_TRAILER1_REAR_AXLES_EXCEEDED, config.getTranslationText('1283'));
        }
    }

    function getTrailer2ManufacturerRatingRearAxlesWarning() {
        if (rigOpsToUse.getManufacturerWeightsTrailer2RearAxlesExceeded()) {
            newWarnings.addString(config.WARNING_ID.MANUFACTURERS_RATING_TRAILER2_REAR_AXLES_EXCEEDED, config.getTranslationText('1284'));
        }
    }

    

    
    function getGVMExceededWarning() {
        if (rigOpsToUse.getManufacturerWeightsVehicleGVMOverloaded()) {
            newWarnings.addString(config.WARNING_ID.MANUFACTURERS_WEIGHTS_GVM_EXCEEDED, config.getTranslationText('1261'));
        }
    }

    //function getCantCalculateStaticRolloverText() {


    //    if (tempTrailer1 === null) {
    //        getVehicleCantCalculateStaticRolloverAngle();
    //    } else {
    //        getVehicleCantCalculateStaticRolloverAngle();
    //        getTrailer1CantCalculateStaticRolloverAngle();
    //    }

    //    function getTrailer1CantCalculateStaticRolloverAngle() {
    //        if (!rigOpsToUse.canCalculateTrailer1StaticRolloverAngle()) {
    //            newWarnings.addString(config.WARNING_ID.CANT_CALCULATE_TRAILER1_STATIC_ROLLOVER_ANGLE, config.getTranslationText('1208'));
    //        }
    //    }

    //    function getVehicleCantCalculateStaticRolloverAngle() {
    //        if (!rigOpsToUse.canCalculateVehicleStaticRolloverAngle()) {
    //            newWarnings.addString(config.WARNING_ID.CANT_CALCULATE_VEHICLE_STATIC_ROLLOVER_ANGLE, config.getTranslationText('1199'));
    //        }
    //    }
    //}

    function getRearOverhangExceededWarningText() {

        var comVehRearOverhangLegislationMax = evaluateLegislationToUse(legislationVals.variableTypes.ComVehRearOverhangLegislationMax);

        if (getCurMeasurementSysRoundedLength(evaluateLegislationToUse(legislationVals.variableTypes.ComVehRearOverhangLegislation)) > getCurMeasurementSysRoundedLength(comVehRearOverhangLegislationMax)) {
            //newWarnings.addString(config.WARNING_ID.REAR_OVERHANG_EXCEEDED, config.getTranslationText('865').replace('$S1', getUIFormattedLengthValue(comVehRearOverhangLegislationMax)));
            newWarnings.addString(config.WARNING_ID.REAR_OVERHANG_EXCEEDED, config.getTranslationText('865', [getUIFormattedLengthValue(comVehRearOverhangLegislationMax)]));
        }
    }

    function getHookliftBodyOverhangWarningText() {
        var tempBody = getActiveOffer().getRig().getVehicle().getAccessoryHolder().getBody();
        if (tempBody !== null && tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {

        
            var hookliftBodyOverhangMax = evaluateLegislationToUse(legislationVals.variableTypes.HookliftBodyOverhangMax);

            if (getCurMeasurementSysRoundedLength(getActiveOffer().getRig().getVehicle().getBodyOverhang()) > getCurMeasurementSysRoundedLength(hookliftBodyOverhangMax)) {
                newWarnings.addString(config.WARNING_ID.HOOKLIFT_BODY_OVERHANG_EXCEEDED, config.getTranslationText('4390', [getUIFormattedLengthValue(hookliftBodyOverhangMax)]));
            }
        }
    }
    

    function getWheelbaseExceededWarningText() {

        var wbtMax = evaluateLegislationToUse(legislationVals.variableTypes.VehicleWheelbaseTheoreticalAdminMax);
        var bodyEquipmentOrPayloadProtrusion = Math.max(0, (tempVehicle.getOverallLengthInclAccessoriesAndPayload() - tempVehicle.getOverallLengthInclAccessories()));
        bodyEquipmentOrPayloadProtrusion = Math.max(bodyEquipmentOrPayloadProtrusion, tempVehicle.getPortionOfOverallLengthOverhangingRear() - tempVehicle.getOverallLengthInclAccessories());



        if (getCurMeasurementSysRoundedLength(tempVehicle.getAxle2FrontToAxle1Rear()) > getCurMeasurementSysRoundedLength((wbtMax + tempVehicle.getWheelbaseTheoreticalEndToAxle4Rear() - tempVehicle.getAxle1RearToAxle2Rear() - tempVehicle.getAxle2RearToAxle3Rear() - tempVehicle.getAxle3RearToAxle4Rear()) + bodyEquipmentOrPayloadProtrusion)) {
            newWarnings.addString(config.WARNING_ID.VEHICLE_WHEELBASE_THEORETICAL, config.getTranslationText('866', [getUIFormattedLengthValue(wbtMax)]));
        }
    }

    function getOverallLengthExceededWarningText() {

        var overallVehicleLengthMax = evaluateLegislationToUse(legislationVals.variableTypes.OverallVehicleLengthMax);

        if (getCurMeasurementSysRoundedLength(rigOpsToUse.getOverallLengthLegal()) > getCurMeasurementSysRoundedLength(overallVehicleLengthMax)) {
            newWarnings.addString(config.WARNING_ID.OVERALL_LENGTH_EXCEEDED, config.getTranslationText('963', [getUIFormattedLengthValue(overallVehicleLengthMax)]));
        }
    }

    

    function getOverallHeightExceededWarningText() {

        var overallVehicleHeightMax = evaluateLegislationToUse(legislationVals.variableTypes.OverallVehicleHeightMax);

        if (getCurMeasurementSysRoundedLength(rigOpsToUse.getOverallHeight()) > getCurMeasurementSysRoundedLength(overallVehicleHeightMax)) {
            newWarnings.addString(config.WARNING_ID.OVERALL_HEIGHT_EXCEEDED, config.getTranslationText('964', [getUIFormattedLengthValue(overallVehicleHeightMax)]));
        }
    }

    function getMissingValuesWarning() {
        var missingValuesWarningText, missingValuesTurningCircleWarningText;
        if (activeConfigurationViewType.value !== undefined && activeConfigurationViewType.value !== null) {
            if (activeConfigurationViewType.value.itemId === config.VIEW_TYPE.OVERVIEW || activeConfigurationViewType.value.itemId === config.VIEW_TYPE.COG) {
                missingValuesWarningText = getMissingValuesWarningText(getMissingValuesLookupObject, false);

            } else if (activeConfigurationViewType.value.itemId === config.VIEW_TYPE.TURNING_CIRCLE) {
                missingValuesWarningText = getMissingValuesWarningText(getTurningCircleMissingValuesLookupObject, false);
            }
        } else {
            missingValuesWarningText = getMissingValuesWarningText(getMissingValuesLookupObject, false);

        }

        // missingValuesWarningText = getMissingValuesWarningText(getMissingValuesLookupObject, false);
        missingValuesTurningCircleWarningText = getMissingValuesWarningText(getTurningCircleMissingValuesLookupObject, false);

        if (missingValuesWarningText !== '') {
            //warnings.push(missingValuesWarningText);
            newWarnings.addString(config.WARNING_ID.MISSING_VALUES_VEHICLE, missingValuesWarningText);
        }
        if (missingValuesTurningCircleWarningText !== '') {
            //warnings.push(missingValuesWarningText);
            newWarnings.addString(config.WARNING_ID.MISSING_VALUES_TURNING_CIRCLE, missingValuesTurningCircleWarningText);
        }
    }

    function getAxlesOverloadedPermissibleWarning(chassisObjecType, dontShowAxlesOverloadWarningForFront, dontShowAxlesOverloadWarningForRear) {
        //var colHighlightColours = generateOverloadingColColours(encodedLegislationId, evaluateLegislationToUse, 'GROSS');
        // var colHighlightColours = getOverloadingColoursForLegislation(encodedLegislationId);
        var colHighlightColours = getBridgeFormulaPermissibleOverloadingColoursForLegislation(encodedLegislationId);
        var colOverloaded = false;
        Object.keys(colHighlightColours).forEach(function (key){
            if (key.indexOf(config.TABLE_COLUMNS.PUSHER) !== -1 || key.indexOf(config.TABLE_COLUMNS.TAG) !== -1 || key.indexOf(config.TABLE_COLUMNS.REAR_CONNECTED) !== -1) {
                if (colHighlightColours[key] !== config.DRAWING_COLOURS.LIGHT_GREEN) {
                    colOverloaded = true;
                }
            } else if (chassisObjecType === config.CHASSIS_OBJECT_TYPES.VEHICLE && key.indexOf(config.TABLE_COLUMNS.TOTAL) === -1 && key.indexOf(config.TABLE_COLUMNS.FRONT) !== -1) {
                if (colHighlightColours[key] !== config.TABLE_HIGHLIGHT_COLOURS.OK && dontShowAxlesOverloadWarningForFront === false) {
                    colOverloaded = true;
                }
            } else if (chassisObjecType === config.CHASSIS_OBJECT_TYPES.VEHICLE && key.indexOf(config.TABLE_COLUMNS.TOTAL) === -1 && key.indexOf(config.TABLE_COLUMNS.REAR) !== -1) {
                if (colHighlightColours[key] !== config.TABLE_HIGHLIGHT_COLOURS.OK && dontShowAxlesOverloadWarningForRear === false) {
                    colOverloaded = true;
                }
            } else if (key.indexOf(config.TABLE_COLUMNS.TOTAL) === -1) {
                if (colHighlightColours[key] !== config.TABLE_HIGHLIGHT_COLOURS.OK) {
                    colOverloaded = true;
                }
            }
        });
        
        if (colOverloaded === true) {
            newWarnings.addString(config.WARNING_ID.AXLES_OVERLOADED, config.getTranslationText('33'));
        }

        //var tempVehicle = getActiveOffer().getRig().getVehicle();

        //switch (rigOps.getCombinationType()) {
        //    case config.COMBINATION_TYPES.VEHICLE:
        //        //if ((utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleFrontAxleOverload())) > 0 && dontShowAxlesOverloadWarningForFront === false) || (utils.returnValueOrZeroIfNanOrInfinity(getCurMeasurementSysRoundedPercentage(rigOpsToUse.getVehicleRearAxleOverload())) > 0 && dontShowAxlesOverloadWarningForRear === false)) {
        //        if (tempVehicle.getAxlesHolder().getHasPusherOrTagAxleDown()) {
        //            if (colHighightColours[config.TABLE_COLUMNS.FRONT] !== config.TABLE_HIGHLIGHT_COLOURS.OK || colHighightColours[config.TABLE_COLUMNS.REAR] !== config.TABLE_HIGHLIGHT_COLOURS.OK) {
        //                //warnings.push(config.getTranslationText('33'));
        //                newWarnings.addString(config.WARNING_ID.AXLES_OVERLOADED, config.getTranslationText('33'));
        //            }
        //        } else {
        //            if (colHighightColours[config.TABLE_COLUMNS.FRONT] !== config.TABLE_HIGHLIGHT_COLOURS.OK || colHighightColours[config.TABLE_COLUMNS.REAR] !== config.TABLE_HIGHLIGHT_COLOURS.OK) {
        //                //warnings.push(config.getTranslationText('33'));
        //                newWarnings.addString(config.WARNING_ID.AXLES_OVERLOADED, config.getTranslationText('33'));
        //            }
        //        }
        //    case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
        //        return getManufacturerWeightsVehicleAndDrawbarComplianceFailed();
        //    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
        //        return getManufacturerWeightsVehicleAndSemiOrInterlinkComplianceFailed();
        //    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
        //        return getManufacturerWeightsVehicleAndPupComplianceFailed();
        //    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
        //        return getManufacturerWeightsVehicleAndInterlinkAndSemiComplianceFailed();
        //}

        
    }


    function getSteerAxleMinimumWarning() {
        if (evaluateLegislationToUse('COMVEHSTEERAXLEMINIMUMAPPLIES') === 1) {
            if (evaluateLegislationToUse('COMVEHSTEERAXLEDUALUNDER') === 1) {
                newWarnings.addString(config.WARNING_ID.STEER_AXLE_MINIMUM, config.getTranslationText('453', [evaluateLegislationToUse('MINIMUMONDUALSTEERINGAXLEPERCENT')]));
            } else if (evaluateLegislationToUse('COMVEHSTEERAXLESINGLEUNDER') === 1) {
                newWarnings.addString(config.WARNING_ID.STEER_AXLE_MINIMUM, config.getTranslationText('452', [evaluateLegislationToUse('MINIMUMONSINGLESTEERINGAXLEPERCENT')]));
            }
        }
    }

    function getVehicleLengthWarning() {
        var includeBullbar = evaluateLegislationToUse(legislationVals.variableTypes.BullbarIncludedInLength) === 1;
        var motorVehicleLengthMaxTowing = evaluateLegislationToUse(legislationVals.variableTypes.MotorVehicleLengthMaxTowing);
        var combinationVehicleOverallLength = getActiveOffer().getRig().getVehicle().getOverallLengthInclAccessories(includeBullbar);
        if (getCurMeasurementSysRoundedLength(combinationVehicleOverallLength) > getCurMeasurementSysRoundedLength(motorVehicleLengthMaxTowing)) {
            newWarnings.addString(config.WARNING_ID.VEHICLE_LENGTH, config.getTranslationText('4042', [getUIFormattedLengthValue(combinationVehicleOverallLength), getUIFormattedLengthValue(motorVehicleLengthMaxTowing)]));
        }
    }

    function getOverallWheelbaseBridgeExtremeVehicleWarning() {
        if (evaluateLegislationToUse(legislationVals.variableTypes.OverallWheelbaseRestriction) === 1) {
            var combinationExtremeGroupBridgeFormulaDistance = rigOpsToUse.getCombinationExtremeGroupDistance();
            var overallWheelbaseMax = evaluateLegislationToUse(legislationVals.variableTypes.OverallWheelbaseMax);
            if (getCurMeasurementSysRoundedLength(combinationExtremeGroupBridgeFormulaDistance) > getCurMeasurementSysRoundedLength(overallWheelbaseMax)) {
                newWarnings.addString(config.WARNING_ID.OVERALL_WHEELBASE, config.getTranslationText('4043', [getUIFormattedLengthValue(combinationExtremeGroupBridgeFormulaDistance), getUIFormattedLengthValue(overallWheelbaseMax)]));
            }
        }
    }

    function getVehicleBridgeFormulaWarning() {
        if (evaluateLegislationToUse(legislationVals.variableTypes.ComVehBridgeFormulaUsed) === 1) {
            if (getWorstBridgeFormulaOverloadColour(rigOpsToUse, encodedLegislationId, evaluateLegislationToUse) !== config.TABLE_HIGHLIGHT_COLOURS.OK) {
                newWarnings.addString(config.WARNING_ID.BRIDGE_FORMULA, config.getTranslationText('1013'));
            }
        }
    }

    function getVehicleAndDrawbarBridgeFormulaWarning() {
        if (evaluateLegislationToUse(legislationVals.variableTypes.ComVehDrawbarBridgeFormulaUsed) === 1) {
            if (getWorstBridgeFormulaOverloadColour(rigOpsToUse, encodedLegislationId, evaluateLegislationToUse) !== config.TABLE_HIGHLIGHT_COLOURS.OK) {
                newWarnings.addString(config.WARNING_ID.BRIDGE_FORMULA, config.getTranslationText('1013'));
            }
        }
    }

    function getVehicleAndPupBridgeFormulaWarning() {
        if (evaluateLegislationToUse(legislationVals.variableTypes.ComVehPupBridgeFormulaUsed) === 1) {
            if (getWorstBridgeFormulaOverloadColour(rigOpsToUse, encodedLegislationId, evaluateLegislationToUse) !== config.TABLE_HIGHLIGHT_COLOURS.OK) {
                newWarnings.addString(config.WARNING_ID.BRIDGE_FORMULA, config.getTranslationText('1013'));
            }
        }
    }

    function getVehicleAndSemiOrInterlinkBridgeFormulaWarning() {
        if (evaluateLegislationToUse(legislationVals.variableTypes.ComVehSemiBridgeFormulaUsed) === 1) {
            if (getWorstBridgeFormulaOverloadColour(rigOpsToUse, encodedLegislationId, evaluateLegislationToUse) !== config.TABLE_HIGHLIGHT_COLOURS.OK) {
                newWarnings.addString(config.WARNING_ID.BRIDGE_FORMULA, config.getTranslationText('1013'));
            }
        }
    }

    function getVehicleAndInterlinkAndSemiBridgeFormulaWarning() {
        if (evaluateLegislationToUse(legislationVals.variableTypes.ComVehIntSemiBridgeFormulaUsed) === 1) {
            if (getWorstBridgeFormulaOverloadColour(rigOpsToUse, encodedLegislationId, evaluateLegislationToUse) !== config.TABLE_HIGHLIGHT_COLOURS.OK) {
                newWarnings.addString(config.WARNING_ID.BRIDGE_FORMULA, config.getTranslationText('1013'));
            }
        }
    }

    function getDrawbarArticulationPointToRearOfTrailerWarning() {

        if (evaluateLegislationToUse(legislationVals.variableTypes.KingpinToRearOfTrailerUsed) === 1 && globals.user.getActiveLegislation().countryId === 3) {
            var semiOrDogArticulationPointToRearOftrailer = evaluateLegislationToUse(legislationVals.variableTypes.SemiOrDogArticulationToRearOfTrailer);
            if (getCurMeasurementSysRoundedLength(tempTrailer1.getArticulationPointToRearOfTrailer()) > getCurMeasurementSysRoundedLength(semiOrDogArticulationPointToRearOftrailer)) {
                newWarnings.addString(config.WARNING_ID.TURNTABLE_TO_REAR_OF_TRAILER, config.getTranslationText('1021', [getUIFormattedLengthValue(semiOrDogArticulationPointToRearOftrailer)]));
            }
        }
    }

    function getFrontOverhangWarning() {
        var trailer1FrontOverhangMax = evaluateLegislationToUse(legislationVals.variableTypes.Trailer1FrontOverhangMax);
        if (getCurMeasurementSysRoundedLength(tempTrailer1.getFrontOverhang()) > getCurMeasurementSysRoundedLength(trailer1FrontOverhangMax)) {
            //newWarnings.addString(config.WARNING_ID.TRAILER_FRONT_OVERHANG, config.getTranslationText('881') + ' ' + getUIFormattedLengthValue(trailer1FrontOverhangMax));
            newWarnings.addString(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.TRAILER_FRONT_OVERHANG, config.getTranslationText('881', [getUIFormattedLengthValue(trailer1FrontOverhangMax)]));
        }
    }

    function getTrailer1FrontOverhangWarning() {
        var trailer1FrontOverhangMax = evaluateLegislationToUse(legislationVals.variableTypes.Trailer1FrontOverhangMax);
        if (getCurMeasurementSysRoundedLength(tempTrailer1.getFrontOverhang()) > getCurMeasurementSysRoundedLength(trailer1FrontOverhangMax)) {
            //newWarnings.addString(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.TRAILER_FRONT_OVERHANG, config.getTranslationText('1289').replace('$S1', getUIFormattedLengthValue(trailer1FrontOverhangMax)));
            newWarnings.addString(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.TRAILER_FRONT_OVERHANG, config.getTranslationText('1289', [getUIFormattedLengthValue(trailer1FrontOverhangMax)]));
        }
    }

    function getTrailer2FrontOverhangWarning() {
        var trailer2FrontOverhangMax = evaluateLegislationToUse(legislationVals.variableTypes.Trailer2FrontOverhangMax);
        if (getCurMeasurementSysRoundedLength(tempTrailer2.getFrontOverhang()) > trailer2FrontOverhangMax) {
            //newWarnings.addString(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.WARNING_ID.TRAILER_FRONT_OVERHANG, config.getTranslationText('1290').replace('$S1', getUIFormattedLengthValue(trailer2FrontOverhangMax)));
            newWarnings.addString(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.WARNING_ID.TRAILER_FRONT_OVERHANG, config.getTranslationText('1290', [getUIFormattedLengthValue(trailer2FrontOverhangMax)]));
        }
    }

    function getTrailerWheelbaseTheoreticalWarning() {
        //if (globals.user.getActiveLegislation().countryId === 3) {
        var trailer1WheelbaseTheoreticalMax = evaluateLegislationToUse(legislationVals.variableTypes.Trailer1WheelbaseTheoreticalMax);
        if (getCurMeasurementSysRoundedLength(tempTrailer1.getWheelbaseTheoretical()) > getCurMeasurementSysRoundedLength(trailer1WheelbaseTheoreticalMax)) {
            //newWarnings.addString(config.WARNING_ID.TRAILER_WHEELBASE_THEORETICAL, config.getTranslationText('882') + ' ' + getUIFormattedLengthValue(trailer1WheelbaseTheoreticalMax));
            newWarnings.addString(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.TRAILER_WHEELBASE_THEORETICAL, config.getTranslationText('882', [getUIFormattedLengthValue(trailer1WheelbaseTheoreticalMax)]));
        }
        //}

    }

    function getTrailer1WheelbaseTheoreticalWarning() {
        var trailer1WheelbaseTheoreticalMax = evaluateLegislationToUse(legislationVals.variableTypes.Trailer1WheelbaseTheoreticalMax);
        if (getCurMeasurementSysRoundedLength(tempTrailer1.getWheelbaseTheoretical()) > getCurMeasurementSysRoundedLength(trailer1WheelbaseTheoreticalMax)) {
            //newWarnings.addString(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.TRAILER_WHEELBASE_THEORETICAL, config.getTranslationText('1291').replace('$S1', getUIFormattedLengthValue(trailer1WheelbaseTheoreticalMax)));
            newWarnings.addString(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.TRAILER_WHEELBASE_THEORETICAL, config.getTranslationText('1291', [getUIFormattedLengthValue(trailer1WheelbaseTheoreticalMax)]));
        }
    }

    function getTrailer2WheelbaseTheoreticalWarning() {
        var trailer2WheelbaseTheoreticalMax = evaluateLegislationToUse(legislationVals.variableTypes.Trailer2WheelbaseTheoreticalMax);
        if (getCurMeasurementSysRoundedLength(tempTrailer2.getWheelbaseTheoretical()) > getCurMeasurementSysRoundedLength(trailer2WheelbaseTheoreticalMax)) {
            //newWarnings.addString(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.WARNING_ID.TRAILER_WHEELBASE_THEORETICAL, config.getTranslationText('1292').replace('$S1', getUIFormattedLengthValue(trailer2WheelbaseTheoreticalMax)));
            newWarnings.addString(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.WARNING_ID.TRAILER_WHEELBASE_THEORETICAL, config.getTranslationText('1292', [getUIFormattedLengthValue(trailer2WheelbaseTheoreticalMax)]));
        }
    }

    function getTrailerRearOverhangLegislationWarning() {
        var trailer1RearOverhangLegislationMax = evaluateLegislationToUse(legislationVals.variableTypes.Trailer1RearOverhangLegislationMax);
        if (getCurMeasurementSysRoundedLength(evaluateLegislationToUse(legislationVals.variableTypes.Trailer1RearOverhangLegislation)) > getCurMeasurementSysRoundedLength(trailer1RearOverhangLegislationMax)) {
            //newWarnings.addString(config.WARNING_ID.TRAILER_REAR_OVERHANG_LEGISLATION, config.getTranslationText('883') + ' ' + getUIFormattedLengthValue(trailer1RearOverhangLegislationMax));
            newWarnings.addString(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.TRAILER_REAR_OVERHANG_LEGISLATION, config.getTranslationText('883', [getUIFormattedLengthValue(trailer1RearOverhangLegislationMax)]));
        }
    }

    function getTrailer1RearOverhangLegislationWarning() {
        var trailer1RearOverhangLegislationMax = evaluateLegislationToUse(legislationVals.variableTypes.Trailer1RearOverhangLegislationMax);
        if (getCurMeasurementSysRoundedLength(evaluateLegislationToUse(legislationVals.variableTypes.Trailer1RearOverhangLegislation)) > getCurMeasurementSysRoundedLength(trailer1RearOverhangLegislationMax)) {
            //newWarnings.addString(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.TRAILER_REAR_OVERHANG_LEGISLATION, config.getTranslationText('1293').replace('$S1', getUIFormattedLengthValue(trailer1RearOverhangLegislationMax)));
            newWarnings.addString(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.TRAILER_REAR_OVERHANG_LEGISLATION, config.getTranslationText('1293', [getUIFormattedLengthValue(trailer1RearOverhangLegislationMax)]));
        }
    }

    function getTrailer2RearOverhangLegislationWarning() {
        var trailer2RearOverhangLegislationMax = evaluateLegislationToUse(legislationVals.variableTypes.Trailer2RearOverhangLegislationMax);
        if (getCurMeasurementSysRoundedLength(evaluateLegislationToUse(legislationVals.variableTypes.Trailer2RearOverhangLegislation)) > getCurMeasurementSysRoundedLength(trailer2RearOverhangLegislationMax)) {
            //newWarnings.addString(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.WARNING_ID.TRAILER_REAR_OVERHANG_LEGISLATION, config.getTranslationText('1294').replace('$S1', getUIFormattedLengthValue(trailer2RearOverhangLegislationMax)));
            newWarnings.addString(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.WARNING_ID.TRAILER_REAR_OVERHANG_LEGISLATION, config.getTranslationText('1294', [getUIFormattedLengthValue(trailer2RearOverhangLegislationMax)]));
        }
    }

    function getTrailerOverallLengthWarning() {
        var trailer1LengthMax = evaluateLegislationToUse(legislationVals.variableTypes.Trailer1LengthMax);
        //var bodyEquipmentOrPayloadProtrustion = Math.max((tempVehicle.getOverallLengthInclAccessoriesAndPayload() -tempVehicle.getOverallLengthInclAccessories()), tempVehicle.getOverallLengthInclAccessories() -tempVehicle.getOverallLengthExclAccessories());

        if (getCurMeasurementSysRoundedLength(tempTrailer1.getOverallLengthInclAccessoriesAndPayload(true)) > getCurMeasurementSysRoundedLength(trailer1LengthMax)) {
            //newWarnings.addString(config.WARNING_ID.TRAILER_LENGTH, config.getTranslationText('879') + ' ' + getUIFormattedLengthValue(trailer1LengthMax));
            newWarnings.addString(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.TRAILER_LENGTH, config.getTranslationText('879', [getUIFormattedLengthValue(trailer1LengthMax)]));
        }
    }

    function getTrailer1OverallLengthWarning() {
        var trailer1LengthMax = evaluateLegislationToUse(legislationVals.variableTypes.Trailer1LengthMax);

        if (getCurMeasurementSysRoundedLength(tempTrailer1.getOverallLengthInclAccessoriesAndPayload(true)) > getCurMeasurementSysRoundedLength(trailer1LengthMax)) {
            //newWarnings.addString(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.TRAILER_LENGTH, config.getTranslationText('1285').replace('$S1', getUIFormattedLengthValue(trailer1LengthMax)));
            newWarnings.addString(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.TRAILER_LENGTH, config.getTranslationText('1285', [getUIFormattedLengthValue(trailer1LengthMax)]));
        }
    }

    function getTrailer2OverallLengthWarning() {
        var trailer2LengthMax = evaluateLegislationToUse(legislationVals.variableTypes.Trailer2LengthMax);
        //var bodyEquipmentOrPayloadProtrustion = Math.max((tempVehicle.getOverallLengthInclAccessoriesAndPayload() -tempVehicle.getOverallLengthInclAccessories()), tempVehicle.getOverallLengthInclAccessories() -tempVehicle.getOverallLengthExclAccessories());

        if (getCurMeasurementSysRoundedLength(tempTrailer2.getOverallLengthInclAccessoriesAndPayload(true)) > getCurMeasurementSysRoundedLength(trailer2LengthMax)) {
            //newWarnings.addString(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.WARNING_ID.TRAILER_LENGTH, config.getTranslationText('1286').replace('$S1', getUIFormattedLengthValue(trailer2LengthMax)));
            newWarnings.addString(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.WARNING_ID.TRAILER_LENGTH, config.getTranslationText('1286', [getUIFormattedLengthValue(trailer2LengthMax)]));
        }
    }

    function getDrawbarLengthWarning() {
        var trailer1DrawbarLengthMax = evaluateLegislationToUse(legislationVals.variableTypes.Trailer1DrawbarLengthMax);
        if (getCurMeasurementSysRoundedLength(evaluateLegislationToUse(legislationVals.variableTypes.Trailer1DrawbarLengthLegislation)) > getCurMeasurementSysRoundedLength(trailer1DrawbarLengthMax)) {
            //newWarnings.addString(config.WARNING_ID.TRAILER_DRAWBAR_LENGTH, config.getTranslationText('1024').replace('$S1', getUIFormattedLengthValue(trailer1DrawbarLengthMax)));
            newWarnings.addString(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.TRAILER_DRAWBAR_LENGTH, config.getTranslationText('1024', [getUIFormattedLengthValue(trailer1DrawbarLengthMax)]));
        }
    }

    function getDrawbarGapBetweenVehicleAndTrailerWarning() {
        var comVehDrawbarGapBetweenVehicleAndTrailerMax = evaluateLegislationToUse(legislationVals.variableTypes.ComVehDrawbarGapBetweenVehicleAndTrailerMax);
        if (getCurMeasurementSysRoundedLength(rigOpsToUse.getVehicleAndDrawbarGapBetweenVehicleAndTrailer()) > getCurMeasurementSysRoundedLength(comVehDrawbarGapBetweenVehicleAndTrailerMax)) {
            //newWarnings.addString(config.WARNING_ID.GAP_BETWEEN_VEHICLE_AND_TRAILER, config.getTranslationText('1028').replace('$S1', getUIFormattedLengthValue(comVehDrawbarGapBetweenVehicleAndTrailerMax)));
            newWarnings.addString(config.WARNING_ID.GAP_BETWEEN_VEHICLE_AND_TRAILER, config.getTranslationText('1028', [getUIFormattedLengthValue(comVehDrawbarGapBetweenVehicleAndTrailerMax)]));
        }
    }

    function getPupGapBetweenVehicleAndTrailerWarning() {
        var comVehPupGapBetweenVehicleAndTrailerMax = evaluateLegislationToUse(legislationVals.variableTypes.ComVehPupGapBetweenVehicleAndTrailerMax);
        if (getCurMeasurementSysRoundedLength(rigOpsToUse.getVehicleAndDrawbarGapBetweenVehicleAndTrailer()) > getCurMeasurementSysRoundedLength(comVehPupGapBetweenVehicleAndTrailerMax)) {
            //newWarnings.addString(config.WARNING_ID.GAP_BETWEEN_VEHICLE_AND_TRAILER, config.getTranslationText('1028').replace('$S1', getUIFormattedLengthValue(comVehPupGapBetweenVehicleAndTrailerMax)));
            newWarnings.addString(config.WARNING_ID.GAP_BETWEEN_VEHICLE_AND_TRAILER, config.getTranslationText('1028', [getUIFormattedLengthValue(comVehPupGapBetweenVehicleAndTrailerMax)]));
        }
    }

    function getCargoCarryingLengthWarning() {
        var cargoCarryingLength = rigOpsToUse.getCombinationCargoCarryingLength();
        var cargoCarryingLengthMax = evaluateLegislationToUse(legislationVals.variableTypes.CargoCarryingLengthMax);
        if (cargoCarryingLength > 0) {
            if (getCurMeasurementSysRoundedLength(cargoCarryingLength) > getCurMeasurementSysRoundedLength(cargoCarryingLengthMax)) {
                //newWarnings.addString(config.WARNING_ID.GAP_BETWEEN_VEHICLE_AND_TRAILER, config.getTranslationText('1028').replace('$S1', getUIFormattedLengthValue(comVehPupGapBetweenVehicleAndTrailerMax)));
                newWarnings.addString(config.WARNING_ID.CARGO_CARRYING_LENGTH_EXCEEDED, config.getTranslationText('4256', [getUIFormattedLengthValue(cargoCarryingLength), getUIFormattedLengthValue(cargoCarryingLengthMax)]));
            }
        }
    }
    //return warnings;
}

function StringTracker() {
    var stringTrackerObjects = [];
    let defaultState = null;

    this.getStringTrackerObjects = function (rowCheckFunction) {
        //return stringTrackerObjects;
        var arr = [];

        stringTrackerObjects.forEach(function (stringTrackerObject) {
            if ((typeof rowCheckFunction !== 'function' || (typeof rowCheckFunction === 'function' && rowCheckFunction(stringTrackerObject) === true)) && !stringTrackerObject.getIsHidden()) {
                arr.push(stringTrackerObject);
            }
        });

        return arr;
    }

    this.addDefaultTrackerState = function(key, value) {
        if(defaultState === null) {
            defaultState = {};
        }
        defaultState[key] = value;
    }

    this.getNumNew = function () {

        var numNewCounter = 0;

        stringTrackerObjects.forEach(function (stringTrackerObject) {
            if (stringTrackerObject.getIsNew() === true) {
                numNewCounter++;
            }
        });

        return numNewCounter;
    }


    this.getAsArrayOfStrings = function (rowCheckFunction) {
        var arr = [];

        stringTrackerObjects.forEach(function (stringTrackerObject) {
            if ((typeof rowCheckFunction !== 'function' || (typeof rowCheckFunction === 'function' && rowCheckFunction(stringTrackerObject) === true)) && !stringTrackerObject.getIsHidden()) {
                arr.push(stringTrackerObject.getStringVal());
            }
        });

        return arr;
    }

    this.addString = function (id, stringVal) {
        let newTracker = new Tracker(id, stringVal);
        if(defaultState) {
            Object.keys(defaultState).forEach((key)=>{
                newTracker.addState(key, defaultState[key]);
            });
        }
        stringTrackerObjects.push(newTracker);
        return newTracker;
    }

    this.addTracker = function(trackerObj) {
        stringTrackerObjects.push(trackerObj);
    }

    this.addUnique = function (newTrackerObj) {
        var addNewItem = true;
        stringTrackerObjects.forEach(function (stringTrackerObj) {
            if (stringTrackerObj.getId() === newTrackerObj.getId()) {
                addNewItem = false;
            }
        });
        if (addNewItem) {
            stringTrackerObjects.push(newTrackerObj);    
        }
    }

    this.getStringTrackerObjectById = function (id) {
        var returnTrackerObj = null;

        stringTrackerObjects.forEach(function (stringTrackerObject) {
            if (stringTrackerObject.getId() === id) {
                returnTrackerObj = stringTrackerObject;
            }
        });

        return returnTrackerObj;
    }

    this.markAllAsNew = function () {
        stringTrackerObjects.forEach(function (stringTrackerObject) {
            if (!stringTrackerObject.getIsHidden()) {
                stringTrackerObject.setIsNew(true);
            }
        });
    }

    this.markAllAsViewed = function () {
        stringTrackerObjects.forEach(function (stringTrackerObject) {
            if (!stringTrackerObject.getIsHidden()) {
                stringTrackerObject.setIsNew(false);
            }
        });
    }

    this.markNewStrings = function (curStrings) {
        if (curStrings !== undefined) {

            stringTrackerObjects.forEach(function (stringTrackerObject) {
                var curString = curStrings.getStringTrackerObjectById(stringTrackerObject.getId());
                if (curString === null && !stringTrackerObject.getIsHidden()) {
                    stringTrackerObject.setIsNew(true);
                } else {
                    if (curString.getIsNew() === true && !stringTrackerObject.getIsHidden()) {
                        stringTrackerObject.setIsNew(true);
                    }
                }
            });
        } else {
            this.markAllAsNew();
        }
    }

    function Tracker(id, stringVal) {
        var _id = id;
        var _stringVal = stringVal;
        var _isNew = false;
        var _isHidden = false;
        let _state = null;

        this.getId = function () {
            return _id;
        }

        this.setIsNew = function (newValue) {
            _isNew = newValue;
        }

        this.getIsNew = function () {
            return _isNew;
        }

        this.getIsHidden = function () {
            return _isHidden;
        }

        this.setIsHidden = function (newValue) {
            _isHidden = newValue;
        }

        this.getStringVal = function () {
            return _stringVal;
        }

        this.addState = function (key, value) {
            if(_state === null) {
                _state = {};
            }
            _state[key] = value;
        }

        this.getState = function (key) {
            if(_state) {
                return _state[key] || null;
            }
            return null;
        }
    }
}

function getNotes() {
    if (curNotes === undefined) {
        generateNotes();
    }
    return curNotes;
}

function hasFuelInFuelAndCrew() {
    return getCurMeasurementSysRoundedVolume(getConfiguration().getFuelOnlyIncludedInExtras()) > 0 || getCurMeasurementSysRoundedVolume(getConfiguration().getAdBlueIncludedInExtras()) > 0;
}

function generateNotes( /*notes*/) {

    var tempVehicle = getConfiguration(),
        tempOriginalVehicle = getOriginalOffer().getConfiguration(),
        tempTrailer1 = getActiveOffer().getRig().getTrailer1(),
        tempOriginalTrailer1 = getOriginalOffer().getRig().getTrailer1(),
        tempTrailer2 = getActiveOffer().getRig().getTrailer2(),
        tempOriginalTrailer2 = getOriginalOffer().getRig().getTrailer2();



    var newNotes = new StringTracker();
    newNotes.addDefaultTrackerState(config.TRACKER_STATE_KEY.TYPE, config.TRACKER_TYPE.NOTE);

    if (tempTrailer1 === null) {
        getVehicleOnlyNotes();
    } else if (tempTrailer2 === null) {
        getTrailer1Notes();
    } else {
        getTrailer2Notes();
    }

    newNotes.markNewStrings(curNotes);

    curNotes = newNotes;
    return newNotes;

    function getVehicleOnlyNotes() {
        var tempBody = getConfiguration().getBody();
        var hasBody = tempBody !== null;
        var note = '';
        var noteValue = '';
        var notesAndWarningsSeparator = "; ";
        var clauseAdded = false;

        getBatteryCapacityNote() ;
        getManufacturerRatingsNote();
        let driverIncluded = getConfiguration().getTareDriverIncluded() === config.TARE_DRIVER_INCLUDED_OPTIONS.YES;

        var totalNumCrew = Number(getConfiguration().getNoOfCrewInFirstRow());
        if (getConfiguration().getCrewSecondRow() === true) {
            totalNumCrew += Number(getConfiguration().getNoOfCrewInSecondRow());
        }
        let numCrewInExtras = driverIncluded ? totalNumCrew - 1 : totalNumCrew;
        var itemAdded;
        // if (getCurMeasurementSysRoundedVolume(getConfiguration().getFuelIncludedInUnladen()) > 0 ||
        //     getConfiguration().getTareToolsIncluded() === config.VEHICLE_INCLUSION_TYPES.YES ||
        //     getConfiguration().getTareSpareWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES ||
        //     getConfiguration().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES || totalNumCrew > 0) {

        //     itemAdded = false;

        //     noteValue = noteValue + config.getTranslationText('195') + ' = ';
        //     clauseAdded = true;

        //     if (getCurMeasurementSysRoundedVolume(getConfiguration().getFuelIncludedInUnladen()) > 0) {
        //         noteValue = noteValue + getUIFormattedVolumeValue(getConfiguration().getFuelIncludedInUnladen()) + ' ' + config.getTranslationText('159');
        //         itemAdded = true;
        //     }

        //     if (itemAdded === true) {
        //         noteValue = noteValue + ', ';
        //     }

        //     var crewMassPerPerson = getUIFormattedMassValue(getConfiguration().getCrewMassPerPerson());

        //     noteValue = noteValue + config.getTranslationText('626', [totalNumCrew, crewMassPerPerson]);
        //     itemAdded = true;

        //     if (getConfiguration().getTareToolsIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
        //         if (itemAdded === true) {
        //             noteValue = noteValue + ', ';
        //         }
        //         noteValue = noteValue + config.getTranslationText('491');
        //         itemAdded = true;
        //     }

        //     if (getConfiguration().getTareSpareWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
        //         if (itemAdded === true) {
        //             noteValue = noteValue + ', ';
        //         }
        //         noteValue = noteValue + config.getTranslationText('441');
        //         itemAdded = true;
        //     }

        //     if (getConfiguration().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
        //         if (itemAdded === true) {
        //             noteValue = noteValue + ', ';
        //         }
        //         noteValue = noteValue + config.getTranslationText('137');
        //         itemAdded = true;
        //     }
        //     newNotes.addString(config.NOTE_ID.INCLUDED_IN_UNLADEN, noteValue).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        //     noteValue = '';
        // }

        if (getCurMeasurementSysRoundedVolume(getConfiguration().getFuelOnlyIncludedInChassis()) > 0 ||
            getCurMeasurementSysRoundedVolume(getConfiguration().getAdBlueIncludedInChassis()) > 0 ||
            getConfiguration().getTareToolsIncluded() === config.VEHICLE_INCLUSION_TYPES.YES ||
            getConfiguration().getTareSpareWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES ||
            getConfiguration().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES || driverIncluded) {
        

                itemAdded = false;

                // noteValue = noteValue + config.getTranslationText('4664');

                if (getCurMeasurementSysRoundedVolume(getConfiguration().getFuelOnlyIncludedInChassis()) > 0) {
                    noteValue = noteValue + getUIFormattedEnergySourceUnitOfMeasure(getConfiguration().getFuelOnlyIncludedInChassis()) + ' ' + config.getTranslationText('159');
                    itemAdded = true;
                }
    
               
                
                if (getCurMeasurementSysRoundedVolume(getConfiguration().getAdBlueIncludedInChassis()) > 0) {
                    if (itemAdded === true) {
                        noteValue = noteValue + ', ';
                    }
                    noteValue = noteValue + getUIFormattedVolumeValue(getConfiguration().getAdBlueIncludedInChassis()) + ' ' + config.getTranslationText('4666');
                    itemAdded = true;
                }
    
                if(driverIncluded) {
                    if (itemAdded === true) {
                        noteValue = noteValue + ', ';
                    }
                    
                    noteValue = noteValue + config.getTranslationText('4667');
                    itemAdded = true;
                }

                if (getConfiguration().getTareToolsIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
                    if (itemAdded === true) {
                        noteValue = noteValue + ', ';
                    }
                    noteValue = noteValue + config.getTranslationText('491');
                    itemAdded = true;
                }
    
                if (getConfiguration().getTareSpareWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
                    if (itemAdded === true) {
                        noteValue = noteValue + ', ';
                    }
                    noteValue = noteValue + config.getTranslationText('441');
                    itemAdded = true;
                }
    
                if (getConfiguration().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
                    if (itemAdded === true) {
                        noteValue = noteValue + ', ';
                    }
                    noteValue = noteValue + config.getTranslationText('137');
                    itemAdded = true;
                }
                // newNotes.addString(config.NOTE_ID.INCLUDED_IN_CHASSIS_WEIGHT, noteValue).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
                newNotes.addString(config.NOTE_ID.INCLUDED_IN_CHASSIS_WEIGHT, config.getTranslationText('4664', [noteValue])).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);                
                noteValue = '';
        
            }

            if (hasFuelInFuelAndCrew() ||
                numCrewInExtras > 0) {
        
                let translationTextCode;

                itemAdded = false;
                if (hasFuelInFuelAndCrew()) {
                    // noteValue = noteValue + config.getTranslationText('4665');
                    translationTextCode = '4665';
                }else {
                    // noteValue = noteValue + config.getTranslationText('4670');
                    translationTextCode = '4670';
                }

                if (getCurMeasurementSysRoundedVolume(getConfiguration().getFuelOnlyIncludedInExtras()) > 0) {
                    noteValue = noteValue + getUIFormattedEnergySourceUnitOfMeasure(getConfiguration().getFuelOnlyIncludedInExtras()) + ' ' + config.getTranslationText('159');
                    itemAdded = true;
                }
    
               
                
                if (getCurMeasurementSysRoundedVolume(getConfiguration().getAdBlueIncludedInExtras()) > 0) {
                    if (itemAdded === true) {
                        noteValue = noteValue + ', ';
                    }
                    noteValue = noteValue + getUIFormattedVolumeValue(getConfiguration().getAdBlueIncludedInExtras()) + ' ' + config.getTranslationText('4666');
                    itemAdded = true;
                }
    
                


                var crewMassPerPerson = getUIFormattedMassValue(getConfiguration().getCrewMassPerPerson());
                
                if(numCrewInExtras > 0) {
                    if (itemAdded === true) {
                        noteValue = noteValue + ', ';
                    }
                    noteValue = noteValue + config.getTranslationText('626', [numCrewInExtras, crewMassPerPerson]);
                }
                
                newNotes.addString(config.NOTE_ID.INCLUDED_IN_CHASSIS_WEIGHT, config.getTranslationText(translationTextCode, [noteValue])).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
                noteValue = '';
        
            }

        if (getCurMeasurementSysRoundedVolume(getConfiguration().getFuelExcludedInUnladen()) > 0 ||
            getConfiguration().getTareToolsIncluded() === config.VEHICLE_INCLUSION_TYPES.NO ||
            getConfiguration().getTareSpareWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.NO ||
            getConfiguration().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.NO) {

            itemAdded = false;

            // noteValue = noteValue + config.getTranslationText('131') + ' = ';

            if (getCurMeasurementSysRoundedVolume(getConfiguration().getFuelOnlyExcludedInUnladen()) > 0) {
                noteValue = noteValue + getUIFormattedEnergySourceUnitOfMeasure(getConfiguration().getFuelOnlyExcludedInUnladen()) + ' ' + config.getTranslationText('159');
                itemAdded = true;
            }

            if (getCurMeasurementSysRoundedVolume(getConfiguration().getAdBlueExcludedInUnladen()) > 0) {
                if (itemAdded === true) {
                    noteValue = noteValue + ', ';
                }
                noteValue = noteValue + getUIFormattedVolumeValue(getConfiguration().getAdBlueExcludedInUnladen()) + ' ' + config.getTranslationText('4666');
                itemAdded = true;
            }

            if (getConfiguration().getTareToolsIncluded() === config.VEHICLE_INCLUSION_TYPES.NO) {
                if (itemAdded === true) {
                    noteValue = noteValue + ', ';
                }
                noteValue = noteValue + config.getTranslationText('491');
                itemAdded = true;
            }

            if (getConfiguration().getTareSpareWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.NO) {
                if (itemAdded === true) {
                    noteValue = noteValue + ', ';
                }
                noteValue = noteValue + config.getTranslationText('441');
                itemAdded = true;
            }

            if (getConfiguration().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.NO) {
                if (itemAdded === true) {
                    noteValue = noteValue + ', ';
                }
                noteValue = noteValue + config.getTranslationText('137');
                itemAdded = true;
            }
            newNotes.addString(config.NOTE_ID.EXCLUDED_FROM_UNLADEN, config.getTranslationText('131', [noteValue])).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            noteValue = '';

        }

        if (getConfiguration().getTareToolsIncluded() === config.VEHICLE_INCLUSION_TYPES.NOT_SPECIFIED ||
            getConfiguration().getTareSpareWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.NOT_SPECIFIED ||
            getConfiguration().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.NOT_SPECIFIED) {

            itemAdded = false;

            // noteValue = noteValue + config.getTranslationText('266') + ' = ';

            if (getConfiguration().getTareToolsIncluded() === config.VEHICLE_INCLUSION_TYPES.NOT_SPECIFIED) {
                if (itemAdded === true) {
                    noteValue = noteValue + ', ';
                }
                noteValue = noteValue + config.getTranslationText('491');
                itemAdded = true;
            }

            if (getConfiguration().getTareSpareWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.NOT_SPECIFIED) {
                if (itemAdded === true) {
                    noteValue = noteValue + ', ';
                }
                noteValue = noteValue + config.getTranslationText('441');
                itemAdded = true;
            }

            if (getConfiguration().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.NOT_SPECIFIED) {
                if (itemAdded === true) {
                    noteValue = noteValue + ', ';
                }
                noteValue = noteValue + config.getTranslationText('137');
                itemAdded = true;
            }
            newNotes.addString(config.NOTE_ID.NOT_SPECIFIED_UNLADEN, config.getTranslationText('5264', [noteValue])).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        }

        getSafetyFeaturesNote();

        //Dimensions not Provided
        getMissingValuesNote();

        // 'centre of gravity
        noteValue = '';
        if(tempVehicle.getFuelType() === config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE) {
            noteValue = config.getTranslationText('4674');
        }else {
            noteValue = config.getTranslationText('71');
        }
        

        newNotes.addString(config.NOTE_ID.CENTRE_OF_GRAVITY, noteValue).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);

        //'legislation
        noteValue = '';

        getLegislationNote();
        getApplicationNote();
        getModelCodeNote();
        getSpecDateNote();

        getMeasurementSystemNote();

        if (getConfiguration().getAccessories().length > 0) {
            var firstAppend = true;
            note = '';
            if (hasBody) {
                if (tempBody.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || tempBody.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
                    note = note + tempBody.getDescription();
                } else {
                    var bodyMassPerMetre;
                    if (tempBody.getMassType() === config.MASS_TYPES.MASS) {
                        if (tempBody.getLengthType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                            bodyMassPerMetre = tempBody.getMass() / (tempBody.getLength() / 1000);
                        } else {
                            bodyMassPerMetre = tempBody.getMass() / ((tempBody.getHeadboardThickness() +
                                tempBody.getLength() +
                                tempBody.getTailboardThickness()) / 1000);
                        }
                    } else {
                        bodyMassPerMetre = tempBody.getMass();
                    }
                    note = note + tempBody.getDescription() + ' - ' + getUIFormattedLinearDensityValue(bodyMassPerMetre);
                }
                firstAppend = false;
            }


            for (var i = 0; i < getConfiguration().getAccessoryHolder().getAccessories().length; i++) {
                if (getConfiguration().getAccessoryHolder().getAccessories()[i].getAccessoryType() !== config.ACCESSORY_TYPES.BODY) {
                    if (firstAppend === false) {
                        note += ', ';
                    }
                    note += getConfiguration().getAccessoryHolder().getAccessories()[i].getDescription();
                    firstAppend = false;
                }
            }

            newNotes.addString(config.NOTE_ID.BODY_AND_EQUIPMENT_ADDED, config.getTranslationText('639', [note])).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        }

        if (hasBody) {
            newNotes.addString(config.NOTE_ID.EXTERNAL_BODY_WIDTH, config.getTranslationText('627') + ' = ' + getUIFormattedLengthValue(tempBody.getActualWidth())).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            if (tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
                newNotes.addString(config.NOTE_ID.SUBFRAME_LENGTH, config.getTranslationText('603') + ' = ' + getUIFormattedLengthValue(tempBody.getSubframeLength())).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }
        }

        //Wheelbase changed and approval
        getWheelbaseChangedAndWheelbaseChangedApprovalNotes();

        //rear overhang changed
        getRearOverhangChangedNote();

        //Theoretical Wheelbase
        if (tempVehicle.getWheelbaseTheoretical() !== tempOriginalVehicle.getWheelbaseTheoretical()) {
            newNotes.addString(config.NOTE_ID.THEORETICAL_WHEELBASE, config.getTranslationText('572')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        }

        //Cab Tilt Clearance and Radius
        getCabTiltClearanceAndRadiusNotes();

        //Taillift Clearance
        getTailliftClearanceNote();

        //Cab Gap
        getCabGapNote();
        getCabGapNotProvidedNote();

        //Off road only
        getOffRoadOnlyNote();

        //Abnormal Permit
        getAbnormalPermitNote();

        //Compactor Allowance
        if (getLesserOfActiveLegislationVals('COMVEHCOMPACTORALLOWANCEAPPLIES') === 1) {
            if (getLesserOfActiveLegislationVals('COMVEHCOMPACTORQUALIFIES') === 1) {
                newNotes.addString(config.NOTE_ID.COMPACTOR_ALLOWANCE, config.getTranslationText('298')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }
        }

        //Administrator Changed Tare
        getAdministratorChangedTareNote();

        //Administrator Changed Permissible
        getAdministratorChangedPermissibleNote();


        //only showing COG warning for Standard vehicles
        if (tempVehicle.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {

            // Vertical COGs zero for vehicle, body, crew , fueltanks, payload and equipment
            var zeroVCOGNoteText = getVerticalCOGZeroNoteText();
            if (zeroVCOGNoteText !== '') {
                //warnings.push(zeroVCOGWarningText);
                newNotes.addString(config.NOTE_ID.VERTICAL_COG_ZERO, zeroVCOGNoteText).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }

            // Vertical COGs estimated for vehicle, body, crew , fueltanks, payload and equipment
            var estimatedVCOGNoteText = getVerticalCOGEstimatedNoteText();
            if (estimatedVCOGNoteText !== '') {
                //warnings.push(estimatedVCOGWarningText);
                newNotes.addString(config.NOTE_ID.VERTICAL_COG_ESTIMATED, estimatedVCOGNoteText).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }

            var massesAdjustedNoteText = getMassAdjustedNoteText();
            if (massesAdjustedNoteText !== '') {
                //warnings.push(adjustedHCOGWarningText);
                newNotes.addString(config.NOTE_ID.MASSES_ADJUSTED, massesAdjustedNoteText).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }


            // Vertical COGs adjusted(overriden) for vehicle, body, crew , fueltanks, payload and equipment
            var adjustedVCOGNoteText = getVerticalCOGAdjustedNoteText();
            if (adjustedVCOGNoteText !== '') {
                //warnings.push(adjustedVCOGWarningText);
                newNotes.addString(config.NOTE_ID.VERTICAL_COG_ADJUSTED, adjustedVCOGNoteText).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }

            // Horizontal COGs adjusted(overriden) for payload, body, crew, fueltanks and equipment
            var adjustedHCOGNoteText = getHorizontalCOGAdjustedNoteText();
            if (adjustedHCOGNoteText !== '') {
                //warnings.push(adjustedHCOGWarningText);
                newNotes.addString(config.NOTE_ID.HORIZONTAL_COG_ADJUSTED, adjustedHCOGNoteText).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }

            getVehicleWidthAdjustedNoteText();

            getTurningAbilityAdjustedNoteText();
        }

        getTurningCircleNote(newNotes);

        getStaticRolloverAngleNote();

        getCantCalculateStaticRolloverText();
    }

    function getTrailer1Notes() {

        //if (notes === undefined) {
        //    notes = [];
        //}

        //var newNotes = new StringTracker();

        var note = '';
        var noteValue = '';
        var notesAndWarningsSeparator = "; ";
        var clauseAdded = false;
        getBatteryCapacityNote();
        getManufacturerRatingsNote();

        noteValue = config.getTranslationText('691') + ': ' + tempTrailer1.getDescription();

        if (tempTrailer1.getTareFrontOverride() === true || tempTrailer1.getTareRearOverride() === true || tempTrailer1.getPermissibleRearOverride() === true || (tempTrailer1.getManufacturersRearAxlesMass() !== tempOriginalTrailer1.getManufacturersRearAxlesMass())) {
            noteValue = noteValue + ' ' + config.getTranslationText('702');
        }

        //notes.push(noteValue);
        newNotes.addString(config.NOTE_ID.TRAILER_DESCRIPTION, noteValue).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        noteValue = '';

        if (tempTrailer1.getTareType() === config.TRAILER_TARE_TYPES.SPECIFY_COMPLETE_TRAILER_SPLITS) {
            noteValue = config.getTranslationText('692');
            newNotes.addString(config.NOTE_ID.TARE_MASS_MANUALLY_INCLUDED, noteValue).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        } else {
            var equipmentNote = getEquipentListForChassisObject(tempTrailer1);
            if (equipmentNote !== '') {
                noteValue = config.getTranslationText('693') + ': ' + equipmentNote;
                newNotes.addString(config.NOTE_ID.BODY_AND_EQUIPMENT_ADDED_TRAILER, noteValue).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }
        }


        //notes.push(noteValue);


        //'legislation
        noteValue = '';

        getLegislationNote();
        getApplicationNote();
        getModelCodeNote();
        getSpecDateNote();

        getMeasurementSystemNote();

        //wheelbase changed and approval
        getWheelbaseChangedAndWheelbaseChangedApprovalNotes();

        //rear overhang changed
        getRearOverhangChangedNote();


        if (tempTrailer1.getType() !== config.TRAILER_TYPES.PUP) {
            //payload cog or body cog adjusted
            if (tempTrailer1.getAccessoryHolder().getBody() !== null) {
                if (tempTrailer1.getPayloadHolder().getPayloadObjects().length > 0) {
                    if (tempTrailer1.getPayloadHolder().getAveragePayloadHorizontalCOG() !== tempOriginalTrailer1.getPayloadHolder().getAveragePayloadHorizontalCOG()) {
                        newNotes.addString(config.NOTE_ID.PAYLOAD_COG_ADJUSTED, config.getTranslationText('695')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
                    }
                }
                if (getCurMeasurementSysRoundedLength(tempTrailer1.getAccessoryHolder().getBody().getActualHorizontalCOG()) !== getCurMeasurementSysRoundedLength(tempOriginalTrailer1.getAccessoryHolder().getBody().getActualHorizontalCOG())) {
                    //warnings.push(config.getTranslationText('696'));
                    newNotes.addString(config.NOTE_ID.BODY_COG_ADJUSTED, config.getTranslationText('696')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
                }
            } else if (tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
                if (tempTrailer1.getPayloadHolder().getPayloadObjects().length > 0) {
                    if (tempTrailer1.getPayloadHolder().getAveragePayloadHorizontalCOG() !== tempOriginalTrailer1.getPayloadHolder().getAveragePayloadHorizontalCOG()) {
                        //warnings.push(config.getTranslationText('695'));
                        newNotes.addString(config.NOTE_ID.PAYLOAD_COG_ADJUSTED, config.getTranslationText('695')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
                    }
                }
            }
        }



        if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR || tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
            if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                //body cog adjusted on vehicle or trailer
                getBodyHorizontalCOGAdjustedNote();
            }

            //cab tilt clearance and radius
            getCabTiltClearanceAndRadiusNotes();
        }
        //taillift clearance
        getTailliftClearanceNote();

        if (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI) {
            //template vehicle
            getTemplateVehicleNote();
        }

        //cab gap
        getCabGapNote();
        getCabGapNotProvidedNote();

        if (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
            //swing clearance
            if (rigOps.getVehicleAndSemiOrInterlinkSwingClearanceBetweenVehicleAndTrailer() < config.swingClearanceLimit) {
                //warnings.push(config.getTranslationText('699').replace('$S1', rigOps.getVehicleAndSemiOrInterlinkSwingClearanceBetweenVehicleAndTrailer()));
                //newNotes.addString(config.NOTE_ID.SWING_CLEARANCE, config.getTranslationText('699').replace('$S1', getUIFormattedLengthValue(rigOps.getVehicleAndSemiOrInterlinkSwingClearanceBetweenVehicleAndTrailer())).replace('$S2', getUIFormattedLengthValue(config.swingClearanceLimit)));
                newNotes.addString(config.NOTE_ID.SWING_CLEARANCE, config.getTranslationText('699', [getUIFormattedLengthValue(rigOps.getVehicleAndSemiOrInterlinkSwingClearanceBetweenVehicleAndTrailer()), getUIFormattedLengthValue(config.swingClearanceLimit)])).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }
            if (rigOps.getVehicleAndSemiOrInterlinkProtrusionSwingClearanceBetweenVehicleAndTrailer() < config.protrusionSwingClearanceLimit) {
                //warnings.push(config.getTranslationText('700').replace('$S1', rigOps.getVehicleAndSemiOrInterlinkProtrusionSwingClearanceBetweenVehicleAndTrailer()));
                //newNotes.addString(config.NOTE_ID.SWING_CLEARANCE_CAB_PROTRUSION, config.getTranslationText('700').replace('$S1', getUIFormattedLengthValue(rigOps.getVehicleAndSemiOrInterlinkProtrusionSwingClearanceBetweenVehicleAndTrailer())).replace('$S2', getUIFormattedLengthValue(config.protrusionSwingClearanceLimit)));
                newNotes.addString(config.NOTE_ID.SWING_CLEARANCE_CAB_PROTRUSION, config.getTranslationText('700', [getUIFormattedLengthValue(rigOps.getVehicleAndSemiOrInterlinkProtrusionSwingClearanceBetweenVehicleAndTrailer()), getUIFormattedLengthValue(config.protrusionSwingClearanceLimit)])).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }

            //swing clearance landing legs
            //warnings.push(config.getTranslationText('701'));
            newNotes.addString(config.NOTE_ID.SWING_CLEARANCE_LANDING_LEGS, config.getTranslationText('701')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        } else if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
            if (rigOps.getVehicleAndDrawbarSwingClearanceBetweenVehicleAndTrailer() < config.swingClearanceLimit) {
                //warnings.push(config.getTranslationText('699').replace('$S1', rigOps.getVehicleAndSemiOrInterlinkSwingClearanceBetweenVehicleAndTrailer()));
                //newNotes.addString(config.NOTE_ID.SWING_CLEARANCE, config.getTranslationText('699').replace('$S1', getUIFormattedLengthValue(rigOps.getVehicleAndDrawbarSwingClearanceBetweenVehicleAndTrailer())).replace('$S2', getUIFormattedLengthValue(config.swingClearanceLimit)));
                newNotes.addString(config.NOTE_ID.SWING_CLEARANCE, config.getTranslationText('699', [getUIFormattedLengthValue(rigOps.getVehicleAndDrawbarSwingClearanceBetweenVehicleAndTrailer()), getUIFormattedLengthValue(config.swingClearanceLimit)])).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }
        } else if (tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
            if (rigOps.getVehicleAndPupSwingClearanceBetweenVehicleAndTrailer() < config.swingClearanceLimit) {
                //warnings.push(config.getTranslationText('699').replace('$S1', rigOps.getVehicleAndSemiOrInterlinkSwingClearanceBetweenVehicleAndTrailer()));
                newNotes.addString(config.NOTE_ID.SWING_CLEARANCE, config.getTranslationText('699', [getUIFormattedLengthValue(rigOps.getVehicleAndPupSwingClearanceBetweenVehicleAndTrailer()), getUIFormattedLengthValue(config.swingClearanceLimit)])).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }


        }



        //off road only
        getOffRoadOnlyNote();

        //abnormal permit 
        getAbnormalPermitNote();

        //administrator changed tare
        getAdministratorChangedTareNote();

        //administrator changed permissible
        getAdministratorChangedPermissibleNote();

        getVehicleWidthAdjustedNoteText();

        getTurningCircleNote(newNotes);

        getTurningAbilityAdjustedNoteText();

        getStaticRolloverAngleNote();

        getCantCalculateStaticRolloverText();
    }

    function getTrailer2Notes() {

        //if (notes === undefined) {
        //    notes = [];
        //}

        //var newNotes = new StringTracker();

        var note = '';
        var noteValue = '';
        var notesAndWarningsSeparator = "; ";
        var clauseAdded = false;
        getBatteryCapacityNote();
        getManufacturerRatingsNote();

        noteValue = config.getTranslationText('1274') + ': ' + tempTrailer1.getDescription();

        if (tempTrailer1.getTareFrontOverride() === true || tempTrailer1.getTareRearOverride() === true || tempTrailer1.getPermissibleRearOverride() === true || (tempTrailer1.getManufacturersRearAxlesMass() !== tempOriginalTrailer1.getManufacturersRearAxlesMass())) {
            noteValue = noteValue + ' ' + config.getTranslationText('702');
        }

        //notes.push(noteValue);
        newNotes.addString(tempTrailer1.getChassisObjectName() + '.' + config.NOTE_ID.TRAILER_DESCRIPTION, noteValue).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        noteValue = '';


        noteValue = config.getTranslationText('1275') + ': ' + tempTrailer2.getDescription();

        if (tempTrailer2.getTareFrontOverride() === true || tempTrailer2.getTareRearOverride() === true || tempTrailer2.getPermissibleRearOverride() === true || (tempTrailer2.getManufacturersRearAxlesMass() !== tempOriginalTrailer2.getManufacturersRearAxlesMass())) {
            noteValue = noteValue + ' ' + config.getTranslationText('702');
        }

        //notes.push(noteValue);
        newNotes.addString(tempTrailer2.getChassisObjectName() + '.' + config.NOTE_ID.TRAILER_DESCRIPTION, noteValue).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        noteValue = '';


        var equipmentNote;
        if (tempTrailer1.getTareType() !== config.TRAILER_TARE_TYPES.SPECIFY_COMPLETE_TRAILER_SPLITS) {
            equipmentNote = getEquipentListForChassisObject(tempTrailer1);
            if (equipmentNote !== '') {
                noteValue = config.getTranslationText('1276') + ': ' + equipmentNote;
                newNotes.addString(tempTrailer1.getChassisObjectName() + '.' + config.NOTE_ID.BODY_AND_EQUIPMENT_ADDED_TRAILER, noteValue).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }
        }

        if (tempTrailer2.getTareType() !== config.TRAILER_TARE_TYPES.SPECIFY_COMPLETE_TRAILER_SPLITS) {
            equipmentNote = getEquipentListForChassisObject(tempTrailer2);
            if (equipmentNote !== '') {
                noteValue = config.getTranslationText('1277') + ': ' + equipmentNote;
                newNotes.addString(tempTrailer2.getChassisObjectName() + '.' + config.NOTE_ID.BODY_AND_EQUIPMENT_ADDED_TRAILER, noteValue).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }
        }


        //notes.push(noteValue);


        //'legislation
        noteValue = '';

        getLegislationNote();
        getApplicationNote();
        getModelCodeNote();
        getSpecDateNote();

        getMeasurementSystemNote();

        //wheelbase changed and approval
        getWheelbaseChangedAndWheelbaseChangedApprovalNotes();

        //rear overhang changed
        getRearOverhangChangedNote();


        if (tempTrailer1.getType() !== config.TRAILER_TYPES.PUP) {
            //payload cog or body cog adjusted
            if (tempTrailer1.getAccessoryHolder().getBody() !== null || tempTrailer1.getAccessoryHolder().getBody() !== null) {
                if (tempTrailer1.getPayloadHolder().getPayloadObjects().length > 0 || tempTrailer2.getPayloadHolder().getPayloadObjects().length > 0) {
                    if (tempTrailer1.getPayloadHolder().getAveragePayloadHorizontalCOG() !== tempOriginalTrailer1.getPayloadHolder().getAveragePayloadHorizontalCOG() || tempTrailer2.getPayloadHolder().getAveragePayloadHorizontalCOG() !== tempOriginalTrailer2.getPayloadHolder().getAveragePayloadHorizontalCOG()) {
                        //warnings.push(config.getTranslationText('695'));
                        newNotes.addString(config.NOTE_ID.PAYLOAD_COG_ADJUSTED, config.getTranslationText('695')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
                    }
                }
                var tempTrailer1Body = tempTrailer1.getAccessoryHolder().getBody(),
                    tempOriginalTrailer1Body = tempOriginalTrailer1.getAccessoryHolder().getBody();
                var tempTrailer2Body = tempTrailer2.getAccessoryHolder().getBody(),
                    tempOriginalTrailer2Body = tempOriginalTrailer2.getAccessoryHolder().getBody();
                if ((tempTrailer1Body !== null && getCurMeasurementSysRoundedLength(tempTrailer1Body.getActualHorizontalCOG()) !== getCurMeasurementSysRoundedLength(tempOriginalTrailer1Body.getActualHorizontalCOG())) || (tempTrailer2Body !== null && getCurMeasurementSysRoundedLength(tempTrailer2Body.getActualHorizontalCOG()) !== getCurMeasurementSysRoundedLength(tempOriginalTrailer2Body.getActualHorizontalCOG()))) {
                    //warnings.push(config.getTranslationText('696'));
                    newNotes.addString(config.NOTE_ID.BODY_COG_ADJUSTED, config.getTranslationText('696')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
                }
            } else if (tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || tempTrailer2.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
                if (tempTrailer1.getPayloadHolder().getPayloadObjects().length > 0 || tempTrailer2.getPayloadHolder().getPayloadObjects().length > 0) {
                    if (tempTrailer1.getPayloadHolder().getAveragePayloadHorizontalCOG() !== tempOriginalTrailer1.getPayloadHolder().getAveragePayloadHorizontalCOG() || tempTrailer2.getPayloadHolder().getAveragePayloadHorizontalCOG() !== tempOriginalTrailer2.getPayloadHolder().getAveragePayloadHorizontalCOG()) {
                        //warnings.push(config.getTranslationText('695'));
                        newNotes.addString(config.NOTE_ID.PAYLOAD_COG_ADJUSTED, config.getTranslationText('695')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
                    }
                }
            }
        }

        //taillift clearance
        getTailliftClearanceNote();

        if (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI) {
            //template vehicle
            getTemplateVehicleNote();
        }

        //cab gap
        getCabGapNote();
        getCabGapNotProvidedNote();

        if (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
            //swing clearance
            if (rigOps.getVehicleAndInterlinkAndSemiSwingClearanceBetweenVehicleAndTrailer1() < config.swingClearanceLimit) {
                //warnings.push(config.getTranslationText('699').replace('$S1', rigOps.getVehicleAndSemiOrInterlinkSwingClearanceBetweenVehicleAndTrailer()));
                //newNotes.addString(config.NOTE_ID.SWING_CLEARANCE, config.getTranslationText('1271').replace('$S1', getUIFormattedLengthValue(rigOps.getVehicleAndInterlinkAndSemiSwingClearanceBetweenVehicleAndTrailer1())).replace('$S2', getUIFormattedLengthValue(config.swingClearanceLimit)));
                newNotes.addString(config.NOTE_ID.SWING_CLEARANCE, config.getTranslationText('1271', [getUIFormattedLengthValue(rigOps.getVehicleAndInterlinkAndSemiSwingClearanceBetweenVehicleAndTrailer1()), getUIFormattedLengthValue(config.swingClearanceLimit)])).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }
            if (rigOps.getVehicleAndInterlinkAndSemiProtrusionSwingClearanceBetweenVehicleAndTrailer1() < config.protrusionSwingClearanceLimit) {
                //warnings.push(config.getTranslationText('700').replace('$S1', rigOps.getVehicleAndSemiOrInterlinkProtrusionSwingClearanceBetweenVehicleAndTrailer()));
                //newNotes.addString(config.NOTE_ID.SWING_CLEARANCE_CAB_PROTRUSION, config.getTranslationText('1272').replace('$S1', getUIFormattedLengthValue(rigOps.getVehicleAndInterlinkAndSemiProtrusionSwingClearanceBetweenVehicleAndTrailer1())).replace('$S2', getUIFormattedLengthValue(config.protrusionSwingClearanceLimit)));
                newNotes.addString(config.NOTE_ID.SWING_CLEARANCE_CAB_PROTRUSION, config.getTranslationText('1272', [getUIFormattedLengthValue(rigOps.getVehicleAndInterlinkAndSemiProtrusionSwingClearanceBetweenVehicleAndTrailer1()), getUIFormattedLengthValue(config.protrusionSwingClearanceLimit)])).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }
            if (rigOps.getVehicleAndInterlinkAndSemiSwingClearanceBetweenTrailer1AndTrailer2() < config.swingClearanceLimit) {
                //warnings.push(config.getTranslationText('700').replace('$S1', rigOps.getVehicleAndSemiOrInterlinkProtrusionSwingClearanceBetweenVehicleAndTrailer()));
                //newNotes.addString(config.NOTE_ID.SWING_CLEARANCE_TRAILERS, config.getTranslationText('1273').replace('$S1', getUIFormattedLengthValue(rigOps.getVehicleAndInterlinkAndSemiSwingClearanceBetweenTrailer1AndTrailer2())).replace('$S2', getUIFormattedLengthValue(config.swingClearanceLimit)));
                newNotes.addString(config.NOTE_ID.SWING_CLEARANCE_TRAILERS, config.getTranslationText('1273', [getUIFormattedLengthValue(rigOps.getVehicleAndInterlinkAndSemiSwingClearanceBetweenTrailer1AndTrailer2()), getUIFormattedLengthValue(config.swingClearanceLimit)])).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }

            //swing clearance landing legs
            newNotes.addString(config.NOTE_ID.SWING_CLEARANCE_LANDING_LEGS, config.getTranslationText('701')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        }
        /*else if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            if (rigOps.getVehicleAndDrawbarSwingClearanceBetweenVehicleAndTrailer() < config.swingClearanceLimit) {
                                //warnings.push(config.getTranslationText('699').replace('$S1', rigOps.getVehicleAndSemiOrInterlinkSwingClearanceBetweenVehicleAndTrailer()));
                                newNotes.addString(config.NOTE_ID.SWING_CLEARANCE, config.getTranslationText('699').replace('$S1', getUIFormattedLengthValue(rigOps.getVehicleAndDrawbarSwingClearanceBetweenVehicleAndTrailer())).replace('$S2', getUIFormattedLengthValue(config.swingClearanceLimit)));
                            }
                        } else if (tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                            if (rigOps.getVehicleAndPupSwingClearanceBetweenVehicleAndTrailer() < config.swingClearanceLimit) {
                                //warnings.push(config.getTranslationText('699').replace('$S1', rigOps.getVehicleAndSemiOrInterlinkSwingClearanceBetweenVehicleAndTrailer()));
                                newNotes.addString(config.NOTE_ID.SWING_CLEARANCE, config.getTranslationText('699').replace('$S1', getUIFormattedLengthValue(rigOps.getVehicleAndPupSwingClearanceBetweenVehicleAndTrailer())).replace('$S2', getUIFormattedLengthValue(config.swingClearanceLimit)));
                            }


                        }*/



        //off road only
        getOffRoadOnlyNote();

        //abnormal permit 
        getAbnormalPermitNote();

        //administrator changed tare
        getAdministratorChangedTareNote();

        //administrator changed permissible
        getAdministratorChangedPermissibleNote();

        getVehicleWidthAdjustedNoteText();

        getTurningCircleNote(newNotes);

        getTurningAbilityAdjustedNoteText();

        getStaticRolloverAngleNote();

        getCantCalculateStaticRolloverText();
    }

    function getManufacturerRatingsNote() {
        if(getActiveOffer().getRig().getVehicle().getBodyWidthManufacturerMax() === 0) {
            newNotes.addString(config.NOTE_ID.MANUFACTURER_RATINGS, config.getTranslationText('4329')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.MANUFACTURER_RATINGS);
        }else {
            newNotes.addString(config.NOTE_ID.MANUFACTURER_RATINGS, config.getTranslationText('4684')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.MANUFACTURER_RATINGS);
        }
    }

    function getModelCodeNote() {
        if (getActiveOffer().getVehicleModelCode() !== undefined && getActiveOffer().getVehicleModelCode() !== '') {
            newNotes.addString(config.NOTE_ID.MODEL_CODE, config.getTranslationText('4163', [getActiveOffer().getVehicleModelCode()])).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        }
    }

    /**
        * Updates newNotes array with text related to spec date of the vehicle on the offer
            * */
    function getSpecDateNote() {
        if (getActiveOffer().getSpecDate() !== undefined && getActiveOffer().getSpecDate() !== '' && getActiveOffer().getSpecDate() !== null) {
            newNotes.addString(config.NOTE_ID.SPEC_DATE, config.getTranslationText('444', [getActiveOffer().getSpecDate()])).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        } 
    }

    /**
        * Updates newNotes array with text related to active legislations on the offer and if the user has overwritten legislation values
            * */
    function getLegislationNote() {
        var noteValue,
            translationTextCodeToUse;

        // if (_legislations.length === 1) {
        //     if (rigOps.hasUserOverriddenRegulationValue()) {
        //         translationTextCodeToUse = '4296';
        //     } 

        //     //var legislationForDescription = globals.user.getAvailableLegislations().find(function (availableLegislation) {
        //     //    return availableLegislation.id === _legislations[0].id;
        //     //});
        //     // var legislationDescription = globals.user.getLegislationDescriptionUsingId(_legislations[0].id);
        //     var legislationDescription = _legislations[0].description;
        //     if (translationTextCodeToUse !== undefined) {
        //         noteValue = config.getTranslationText(translationTextCodeToUse, [legislationDescription]);
        //     } else {
        //         noteValue = legislationDescription;
        //     }

        //     newNotes.addString(config.NOTE_ID.LEGISLATION + '_' + _legislations[0].id , noteValue).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.REGULATIONS_APPLIED);
        // } else {
            

            _legislations.forEach(function (leg, index, arr) {
               

                if (rigOps.hasUserOverriddenRegulationValue()) {
                    translationTextCodeToUse = '4296';
                }
                if (translationTextCodeToUse !== undefined) {
                    noteValue = config.getTranslationText(translationTextCodeToUse, [leg.description]);
                } else {
                    noteValue =  leg.description;
                } 
    
                newNotes.addString(config.NOTE_ID.LEGISLATION + '_' + leg.id , noteValue).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.REGULATIONS_APPLIED);
            });

            
        // }
        
        
    }

    function getApplicationNote() {
        var noteValue = config.getTranslationText('20');

        if (getActiveOffer().getApplyHigherMassLimits() === true) {
            noteValue = noteValue + ' = ' + config.getTranslationText('184');
        } else {
            switch (getActiveOffer().getApplication().id) {
                case config.VEHICLE_APPLICATION_TYPES.ONROAD:
                    noteValue = noteValue + ' = ' + config.getTranslationText('2597');
                    break;
                case config.VEHICLE_APPLICATION_TYPES.ONANDOFFROAD:
                    noteValue = noteValue + ' = ' + config.getTranslationText('273');
                    break;
                case config.VEHICLE_APPLICATION_TYPES.OFFROADONLY:
                    noteValue = noteValue + ' = ' + config.getTranslationText('270');
                    break;
                case config.VEHICLE_APPLICATION_TYPES.ABNORMALPERMIT:
                    noteValue = noteValue + ' = ' + config.getTranslationText('2');
                    break;
                case config.VEHICLE_APPLICATION_TYPES.HIGHERMASSLIMIT:
                    noteValue = noteValue + ' = ' + config.getTranslationText('184');
                    break;
                case config.VEHICLE_APPLICATION_TYPES.CONCESSIONALMASSLIMIT:
                    noteValue = noteValue + ' = ' + config.getTranslationText('1354');
                    break;
            }
        }
        newNotes.addString(config.NOTE_ID.APPLICATION, noteValue).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);

        if (globals.getActiveCultureCodeForTranslations().toLowerCase() === config.LANGUAGE_PACK.ENGLISH.AU.cultureCode.toLowerCase()
            || globals.getActiveCultureCodeForTranslations().toLowerCase() === config.LANGUAGE_PACK.ENGLISH.NZ.cultureCode.toLowerCase()
            || globals.getActiveCultureCodeForTranslations().toLowerCase() === config.LANGUAGE_PACK.ENGLISH.US.cultureCode.toLowerCase()
            || globals.getActiveCultureCodeForTranslations().toLowerCase() === config.LANGUAGE_PACK.ENGLISH.CA.cultureCode.toLowerCase()
            || globals.getActiveCultureCodeForTranslations().toLowerCase() === config.LANGUAGE_PACK.FRENCH.CA.cultureCode.toLowerCase()) {
            switch (getActiveOffer().getApplication().id) {
                case config.VEHICLE_APPLICATION_TYPES.HIGHERMASSLIMIT:
                    newNotes.addString(config.NOTE_ID.APPLICATION_MESSAGE, config.getMessageText('1654', globals.getActiveCultureCodeForTranslations())).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
                    break;
                case config.VEHICLE_APPLICATION_TYPES.CONCESSIONALMASSLIMIT:
                    if (globals.getActiveCultureCodeForTranslations().toLowerCase() === config.LANGUAGE_PACK.ENGLISH.AU.cultureCode.toLowerCase() || globals.getActiveCultureCodeForTranslations().toLowerCase() === config.LANGUAGE_PACK.ENGLISH.NZ.cultureCode.toLowerCase()) {
                        newNotes.addString(config.NOTE_ID.APPLICATION_MESSAGE, config.getMessageText('1655', globals.getActiveCultureCodeForTranslations())).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
                    }
                    break;
                default:
                    break;
            }
        }
    }

    function getSafetyFeaturesNote() {
        let noteText = '';
        let safetyFeaturesPrefixAdded = false;
        
        if(tempVehicle.getRolloverProtection() === config.YES_NO_OPTIONS.YES) {
            addNotePrefixOrDelimeter();
            noteText += config.getMessageText('4712', globals.getActiveCultureCodeForTranslations())
        }
        if(tempVehicle.getFrontUnderrunProtection() === config.YES_NO_OPTIONS.YES) {
            addNotePrefixOrDelimeter();
            noteText += config.getMessageText('4711', globals.getActiveCultureCodeForTranslations())
        }
        if(tempVehicle.getDevicesForIndirectVision() === config.YES_NO_OPTIONS.YES) {
            addNotePrefixOrDelimeter();
            noteText += config.getMessageText('4704', globals.getActiveCultureCodeForTranslations())
        }
        if(tempVehicle.getCommercialVehicleBrakeSystems() === config.YES_NO_OPTIONS.YES) {
            addNotePrefixOrDelimeter();
            noteText += config.getMessageText('4705', globals.getActiveCultureCodeForTranslations())
        }
        if(tempVehicle.getAdvancedEmergencyBraking() === config.YES_NO_OPTIONS.YES) {
            addNotePrefixOrDelimeter();
            noteText += config.getMessageText('4713', globals.getActiveCultureCodeForTranslations())
        }
        if(tempVehicle.getLaneDepartureWarning() === config.YES_NO_OPTIONS.YES) {
            addNotePrefixOrDelimeter();
            noteText += config.getMessageText('4706', globals.getActiveCultureCodeForTranslations())
        }
        if(tempVehicle.getSideUnderrunProtection() === config.YES_NO_OPTIONS.YES) {
            addNotePrefixOrDelimeter();
            noteText += config.getMessageText('4707', globals.getActiveCultureCodeForTranslations())
        }
        if(tempVehicle.getConspicuityMarkingsToTheRear() === config.YES_NO_OPTIONS.YES) {
            addNotePrefixOrDelimeter();
            noteText += config.getMessageText('4708', globals.getActiveCultureCodeForTranslations())
        }
        if(tempVehicle.getConspicuityMarkingsToBothSides() === config.YES_NO_OPTIONS.YES) {
            addNotePrefixOrDelimeter();
            noteText += config.getMessageText('4709', globals.getActiveCultureCodeForTranslations())
        }
        if(tempVehicle.getBlindSpotInformationSystems() === config.YES_NO_OPTIONS.YES) {
            addNotePrefixOrDelimeter();
            noteText += config.getMessageText('4710', globals.getActiveCultureCodeForTranslations())
        }

        if(safetyFeaturesPrefixAdded) {
            newNotes.addString(config.NOTE_ID.SAFETY_FEATURES, noteText).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        }

        function addNotePrefixOrDelimeter() {
            if(!safetyFeaturesPrefixAdded) {
                noteText += config.getMessageText('4703', globals.getActiveCultureCodeForTranslations()) + ' ';
                safetyFeaturesPrefixAdded = true;
            }else {
                noteText += ', ';
            }
        }
    }

    function getMissingValuesNote() {
        var missingValuesWarningText, missingValuesTurningCircleWarningText;
        if (activeConfigurationViewType.value !== undefined && activeConfigurationViewType.value !== null) {
            if (activeConfigurationViewType.value.itemId === config.VIEW_TYPE.OVERVIEW || activeConfigurationViewType.value.itemId === config.VIEW_TYPE.COG) {
                missingValuesWarningText = getMissingValuesWarningText(getMissingValuesLookupObject, false);

            } else if (activeConfigurationViewType.value.itemId === config.VIEW_TYPE.TURNING_CIRCLE) {
                missingValuesWarningText = getMissingValuesWarningText(getTurningCircleMissingValuesLookupObject, false);
            }
        } else {
            missingValuesWarningText = getMissingValuesWarningText(getMissingValuesLookupObject, false);

        }

        missingValuesWarningText = getMissingValuesWarningText(getMissingValuesLookupObject, false);
        missingValuesTurningCircleWarningText = getMissingValuesWarningText(getTurningCircleMissingValuesLookupObject, false);

        if (missingValuesWarningText !== '') {
            //warnings.push(missingValuesWarningText);
            newNotes.addString(config.WARNING_ID.MISSING_VALUES_VEHICLE, missingValuesWarningText).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        }
        if (missingValuesTurningCircleWarningText !== '') {
            //warnings.push(missingValuesWarningText);
            newNotes.addString(config.WARNING_ID.MISSING_VALUES_TURNING_CIRCLE, missingValuesTurningCircleWarningText).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        }
    }

    function getMeasurementSystemNote() {
        newNotes.addString(config.NOTE_ID.MEASUREMENT_SYSTEM, config.getTranslationText('242') + ' = ' + activeMeasurementSystem.value.description).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
    }

    function getStaticRolloverAngleNote() {

        if (tempTrailer1 === null) {
            getVehicleStaticRolloverAngle();
        } else if (tempTrailer2 === null) {
            getVehicleStaticRolloverAngle();
            getTrailer1StaticRolloverAngle();
        } else {
            getVehicleStaticRolloverAngle();
            getTrailer1StaticRolloverAngle();
            getTrailer2StaticRolloverAngle();
        }

        function getTrailer1StaticRolloverAngle() {
            if (rigOps.canCalculateTrailer1StaticRolloverAngle()) {

                var noteBaseText;
                if (tempTrailer2 !== null) {
                    noteBaseText = config.getTranslationText('1278');
                } else {
                    noteBaseText = config.getTranslationText('1207');
                }
                newNotes.addString(config.NOTE_ID.TRAILER1_STATIC_ROLLOVER_ANGLE, noteBaseText.replace('$S1', getUIFormattedDegreeValue(rigOps.getTrailer1StaticRolloverAngle()))).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL); // User Story 17565
            }
        }

        function getTrailer2StaticRolloverAngle() {
            if (rigOps.canCalculateTrailer2StaticRolloverAngle()) {
                //newNotes.addString(config.NOTE_ID.TRAILER2_STATIC_ROLLOVER_ANGLE, config.getTranslationText('1279').replace('$S1', getUIFormattedDegreeValue(rigOps.getTrailer2StaticRolloverAngle())));
                newNotes.addString(config.NOTE_ID.TRAILER2_STATIC_ROLLOVER_ANGLE, config.getTranslationText('1279', [getUIFormattedDegreeValue(rigOps.getTrailer2StaticRolloverAngle())])).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }
        }

        function getVehicleStaticRolloverAngle() {
            if (rigOps.canCalculateVehicleStaticRolloverAngle()) {
                //newNotes.addString(config.NOTE_ID.VEHICLE_STATIC_ROLLOVER_ANGLE, config.getTranslationText('1198').replace('$S1', getUIFormattedDegreeValue(rigOps.getVehicleStaticRolloverAngle())));
                newNotes.addString(config.NOTE_ID.VEHICLE_STATIC_ROLLOVER_ANGLE, config.getTranslationText('1198', [getUIFormattedDegreeValue(rigOps.getVehicleStaticRolloverAngle())])).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }
        }
    }

    function getCantCalculateStaticRolloverText() {
        if (tempTrailer1 === null) {
            getVehicleCantCalculateStaticRolloverAngle();
        } else if (tempTrailer2 === null) {
            getVehicleCantCalculateStaticRolloverAngle();
            getTrailer1CantCalculateStaticRolloverAngle();
        } else {
            getVehicleCantCalculateStaticRolloverAngle();
            getTrailer1CantCalculateStaticRolloverAngle();
            getTrailer2CantCalculateStaticRolloverAngle();
        }

        function getTrailer1CantCalculateStaticRolloverAngle() {
            if (!rigOps.canCalculateTrailer1StaticRolloverAngle()) {
                newNotes.addString(config.NOTE_ID.CANT_CALCULATE_TRAILER1_STATIC_ROLLOVER_ANGLE, config.getTranslationText('1208')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }
        }

        function getTrailer2CantCalculateStaticRolloverAngle() {
            if (!rigOps.canCalculateTrailer2StaticRolloverAngle()) {
                newNotes.addString(config.NOTE_ID.CANT_CALCULATE_TRAILER2_STATIC_ROLLOVER_ANGLE, config.getTranslationText('1208')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }
        }

        function getVehicleCantCalculateStaticRolloverAngle() {
            if (!rigOps.canCalculateVehicleStaticRolloverAngle()) {
                newNotes.addString(config.NOTE_ID.CANT_CALCULATE_VEHICLE_STATIC_ROLLOVER_ANGLE, config.getTranslationText('1199')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }
        }
    }

    function getAddtionalNote() {
        if (getActiveOffer().getAdditionalNote() !== undefined && getActiveOffer().getAdditionalNote().length > 0) {
            //notes.push(config.getTranslationText('14') + ' = ' + getActiveOffer().getAdditionalNote());
            newNotes.addString(config.NOTE_ID.ADDITIONAL_NOTE, config.getTranslationText('14') + ' = ' + getActiveOffer().getAdditionalNoteForDrawing()).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        }
    }

    function getWheelbaseChangedAndWheelbaseChangedApprovalNotes() {
        if (getCurMeasurementSysRoundedLength(tempVehicle.getAxle2FrontToAxle1Rear()) !== getCurMeasurementSysRoundedLength(tempOriginalVehicle.getAxle2FrontToAxle1Rear())) {
            var wheelbaseChangeVal = tempOriginalVehicle.getAxle2FrontToAxle1Rear() - tempVehicle.getAxle2FrontToAxle1Rear();
            if (wheelbaseChangeVal < 0) {
                newNotes.addString(config.NOTE_ID.WHEELBASE_CHANGED, config.getTranslationText('545') + ' ' + getUIFormattedLengthValue(Math.abs(wheelbaseChangeVal))).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL); // User Story 17565
            } else {
                newNotes.addString(config.NOTE_ID.WHEELBASE_CHANGED, config.getTranslationText('546') + ' ' + getUIFormattedLengthValue(wheelbaseChangeVal)).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL); // User Story 17565
            }
            newNotes.addString(config.NOTE_ID.WHEELBASE_CHANGED_APPROVAL, config.getTranslationText('126')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        }
    }

    function getRearOverhangChangedNote() {
        if (tempVehicle.getRearOverhang() !== tempOriginalVehicle.getRearOverhang()) {
            var rearOverhangChangeVal = tempOriginalVehicle.getRearOverhang() - tempVehicle.getRearOverhang();
            if (rearOverhangChangeVal < 0) {
                newNotes.addString(config.NOTE_ID.REAR_OVERHANG_CHANGED, config.getTranslationText('538') + ' ' + getUIFormattedLengthValue(Math.abs(rearOverhangChangeVal))).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL); // User Story 17565
            } else {
                newNotes.addString(config.NOTE_ID.REAR_OVERHANG_CHANGED, config.getTranslationText('539') + ' ' + getUIFormattedLengthValue(rearOverhangChangeVal)).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL); // User Story 17565
            }
        }
    }

    function getCabTiltClearanceAndRadiusNotes() {
        if (tempVehicle.getControl() !== config.CONTROL_TYPES.NORMAL && tempVehicle.getCabTilt() === true &&
            (tempVehicle.getAccessoryHolder().getBody() !== null && (tempVehicle.getAccessoryHolder().getBody().getType() === config.BODY_TYPES.TIPPER ||
                tempVehicle.getAccessoryHolder().getFridge() !== null || tempVehicle.getAccessoryHolder().getFairing() !== null))) {
            //warnings.push(config.getTranslationText('61'));
            newNotes.addString(config.NOTE_ID.CAB_TILT_CLEARANCE, config.getTranslationText('61')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            if (tempVehicle.getCabTiltRadius() === 0) {
                //warnings.push(config.getTranslationText('63'));
                newNotes.addString(config.NOTE_ID.CAB_TILT_RADIUS, config.getTranslationText('63')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }
        }
    }

    function getTailliftClearanceNote() {
        if (tempVehicle.getAccessoryHolder().getTaillift() !== null || (tempTrailer1 !== null && tempTrailer1.getAccessoryHolder().getTaillift() !== null) || (tempTrailer2 !== null && tempTrailer2.getAccessoryHolder().getTaillift() !== null)) {
            newNotes.addString(config.NOTE_ID.TAILLIFT_CLEARANCE, config.getTranslationText('472')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        }
    }

    function getCabGapNote() {
        var showCabGapMsg = false;
        if (tempVehicle.getAccessoryHolder().getBody() !== null) {
            if (tempVehicle.getAccessoryHolder().getBody().getHorizontalGap() < tempVehicle.getCabGapRecommendedMin()) {
                showCabGapMsg = true;
            }
        }

        if (tempVehicle.getAccessoryHolder().getCrane() !== null) {
            if (tempVehicle.getAccessoryHolder().getCrane().getHorizontalGap() < tempVehicle.getCabGapRecommendedMin()) {
                showCabGapMsg = true;
            }
        }

        if (showCabGapMsg === true) {
            newNotes.addString(config.NOTE_ID.CAB_GAP, config.getTranslationText('480', [getUIFormattedLengthValue(tempVehicle.getCabGapRecommendedMin())])).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        }
    }


    function getBatteryCapacityNote() {
        if(tempVehicle.getFuelType() === config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE) {
            let sumBatteryCapacity = tempVehicle.getSumBatteryCapacity();

            newNotes.addString(config.NOTE_ID.BATTERY_CAPACITY, config.getTranslationText('4663', [getUIFormattedWattValue(sumBatteryCapacity)])).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        }
    }

    /**
        * Displays message if body/crane attached to vehicle and cab gap recommended min = 0
            * */
    function getCabGapNotProvidedNote() {
        if (tempVehicle.getAccessoryHolder().getBody() !== null || tempVehicle.getAccessoryHolder().getCrane() !== null) {
            var showCabGapMsg = false;
            if (tempVehicle.getCabGapRecommendedMin() === 0) {
                showCabGapMsg = true;
            }
            if (showCabGapMsg === true) {
                newNotes.addString(config.NOTE_ID.CAB_GAP_NOT_PROVIDED, config.getTranslationText('4160')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }
        }
    }

    function getOffRoadOnlyNote() {
        if (getActiveOffer().getApplication().id === config.VEHICLE_APPLICATION_TYPES.OFFROADONLY) {
            newNotes.addString(config.NOTE_ID.OFF_ROAD_ONLY, config.getTranslationText('297')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        }
    }

    function getAbnormalPermitNote() {
        if (getActiveOffer().getApplication().id === config.VEHICLE_APPLICATION_TYPES.ABNORMALPERMIT) {
            newNotes.addString(config.NOTE_ID.ABNORMAL_PERMIT, config.getTranslationText('3')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        }
    }

    function getTemplateVehicleNote() {
        if (getConfiguration().getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
            newNotes.addString(config.NOTE_ID.TEMPLATE_VEHICLE, config.getTranslationText('104')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        }
    }

    function getAdministratorChangedTareNote() {
        if (tempVehicle.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
            if (getCurMeasurementSysRoundedMass(rigOps.getVehicleOnlyFrontTare()) !== getCurMeasurementSysRoundedMass(tempOriginalVehicle.getTareFront()) ||
                getCurMeasurementSysRoundedMass(rigOps.getVehicleOnlyFrontTare()) !== getCurMeasurementSysRoundedMass(tempOriginalVehicle.getTareRear())) {
                //warnings.push(config.getTranslationText('481'));
                newNotes.addString(config.NOTE_ID.ADMINISTRATOR_CHANGED_TARE, config.getTranslationText('481')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }
        }
    }

    function getAdministratorChangedPermissibleNote() {
        //if (tempVehicle.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
        //if ((getCurMeasurementSysRoundedMass(tempVehicle.getAUFront()) !== getCurMeasurementSysRoundedMass(tempOriginalVehicle.getAUFront()) && getCurMeasurementSysRoundedMass(tempOriginalVehicle.getAUFront()) > 0) ||
        //        (getCurMeasurementSysRoundedMass(tempVehicle.getAURear()) !== getCurMeasurementSysRoundedMass(tempOriginalVehicle.getAURear()) && getCurMeasurementSysRoundedMass(tempOriginalVehicle.getAURear()) > 0) ||
        //        tempVehicle.getPermissibleTotalOverride() === true) {
        //    //warnings.push(config.getTranslationText('15'));
        //    newNotes.addString(config.NOTE_ID.ADMINISTRATOR_CHANGED_PERMISSIBLE, config.getTranslationText('15'));
        //}

        var showNoteForTrailerFrontPermissible = false,
            showNoteForTrailerRearPermissible = false;
        var tempTrailer1 = getActiveOffer().getRig().getTrailer1();
        if (tempTrailer1 !== null) {
            if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                if (tempTrailer1.getPermissibleFrontOverride() === true) {
                    showNoteForTrailerFrontPermissible = true;
                }
            }
            if (tempTrailer1.getPermissibleRearOverride() === true) {
                showNoteForTrailerRearPermissible = true;
            }
        }


        if (tempVehicle.getPermissibleFrontOverride() === true || tempVehicle.getPermissibleRearOverride() == true || tempVehicle.getPermissibleTotalOverride() === true || showNoteForTrailerFrontPermissible || showNoteForTrailerRearPermissible) {
            //warnings.push(config.getTranslationText('15'));
            newNotes.addString(config.NOTE_ID.ADMINISTRATOR_CHANGED_PERMISSIBLE, config.getTranslationText('15')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        }
        //}
    }

    function getVerticalCOGZeroNoteText() {

        var verticalCOGZeroNoteText = '';
        var zeroCOGs = [];

        //Vehicle Vertical COG zero
        if (rigOps.getVehicleVerticalCOG() === 0) {
            zeroCOGs.push(config.getTranslationText('4210'));
        }

        //Body Vertical COG zero
        if (tempVehicle.getAccessoryHolder().getBody() !== null && tempVehicle.getAccessoryHolder().getBody().getVerticalCOG() === 0) {
            zeroCOGs.push(config.getTranslationText('2837'));
        }
        //Payload Vertical COG zero
        if (tempVehicle.getPayloadHolder().getPayloadObjects().length > 0) {

            if (tempVehicle.getPayloadHolder().getSimplePayloadObject().getVerticalCOG() === 0) {
                zeroCOGs.push(config.getTranslationText('2864'));
            }
            //#unfinished, this is hardcoded for single point payload, will need to change for multi point payload when we can get a unique description for each payload or do something similar to fuel tanks i.e. use number  
        }
        //Crane Vertical COG zero
        if (tempVehicle.getAccessoryHolder().getCrane() !== null && tempVehicle.getAccessoryHolder().getCrane().getVerticalCOG() === 0) {
            zeroCOGs.push(config.getTranslationText('2763'));
        }
        //Fridge Vertical COG zero
        if (tempVehicle.getAccessoryHolder().getFridge() !== null && tempVehicle.getAccessoryHolder().getFridge().getVerticalCOG() === 0) {
            zeroCOGs.push(config.getTranslationText('2769'));
        }
        //Taillift Vertical COG zero
        if (getConfiguration().getAccessoryHolder().getTaillift() !== null && tempVehicle.getAccessoryHolder().getTaillift().getVerticalCOG() === 0) {
            zeroCOGs.push(config.getTranslationText('2780'));
        }
        //FifthWheel Vertical COG zero
        if (getConfiguration().getAccessoryHolder().getFifthWheel() !== null && tempVehicle.getAccessoryHolder().getFifthWheel().getVerticalCOG() === 0) {
            zeroCOGs.push(config.getTranslationText('2851'));
        }
        //Hitch Vertical COG zero
        if (getConfiguration().getAccessoryHolder().getHitch() !== null && tempVehicle.getAccessoryHolder().getHitch().getVerticalCOG() === 0) {
            zeroCOGs.push(config.getTranslationText('188'));
        }
        //Other Equipmnet Vertical COG zero
        if (getConfiguration().getAccessoryHolder().getAccessories().length > 0) {
            getConfiguration().getAccessoryHolder().getAccessories().forEach(function (accessoryObject) {
                if (accessoryObject.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                    if (accessoryObject.getVerticalCOG() === 0) {
                        zeroCOGs.push(accessoryObject.getDescription());
                    }
                }
            });
        }

        // Crew Vertical COG zero
        if (getConfiguration().getCrewFirstRowVerticalCOG() === 0) {
            zeroCOGs.push(config.getTranslationText('2843'));
        }
        if (getConfiguration().getCrewSecondRow() === true) {
            if (getConfiguration().getCrewSecondRowVerticalCOG() === 0) {
                zeroCOGs.push(config.getTranslationText('2847'));
            }
        }

        // Fuel Tanks Vertical COG zero
        getConfiguration().getFuelTanks().forEach(function (fuelTank) {
            if (fuelTank.getVerticalCog() === 0) {
                zeroCOGs.push(config.getTranslationText('2857') + ' ' + fuelTank.getNumber());
            }
        });



        if (zeroCOGs.length > 0) {
            verticalCOGZeroNoteText = config.getTranslationText('630');

            if (zeroCOGs.length === 1) {
                verticalCOGZeroNoteText = verticalCOGZeroNoteText.replace('$S1', zeroCOGs[0].toLowerCase()); // User Story 17565
            } else {
                verticalCOGZeroNoteText = verticalCOGZeroNoteText.replace('$S1', getArrayValsAsConcatenatedString(zeroCOGs)); // User Story 17565
            }
        }

        return verticalCOGZeroNoteText;

    }

    function getVerticalCOGEstimatedNoteText() {

        var verticalCOGEstimatedNoteText = '';
        var estimatedCOGs = [];

        //Vehicle Vertical COG Estimated
        if (tempVehicle.getVehicleVerticalCOGOverride() === false && tempOriginalVehicle.getVehicleVerticalCOGConfirmed() === false) {
            estimatedCOGs.push(config.getTranslationText('4211'));
        }

        //Body Vertical COG Estimated
        if (tempVehicle.getAccessoryHolder().getBody() !== null && tempVehicle.getAccessoryHolder().getBody().getVerticalCOGOverride() === false && tempOriginalVehicle.getAccessoryHolder().getBody().getVerticalCOGConfirmed() === false) {
            estimatedCOGs.push(config.getTranslationText('2838'));
        }

        //Payload Vertical COG Estimated
        if (tempVehicle.getPayloadHolder().getPayloadObjects().length > 0) {
            if (tempVehicle.getPayloadHolder().getSimplePayloadObject().getVerticalCOGOverride() === false && tempOriginalVehicle.getPayloadHolder().getSimplePayloadObject().getVerticalCOGConfirmed() === false) {
                estimatedCOGs.push(config.getTranslationText('2865'));
            }
        }

        //Crane Vertical COG Adjusted
        if (tempVehicle.getAccessoryHolder().getCrane() !== null && tempVehicle.getAccessoryHolder().getCrane().getVerticalCOGOverride() === false && tempOriginalVehicle.getAccessoryHolder().getCrane().getVerticalCOGConfirmed() === false) {
            estimatedCOGs.push(config.getTranslationText('2764'));
        }
        //Fridge Vertical COG Adjusted
        if (tempVehicle.getAccessoryHolder().getFridge() !== null && tempVehicle.getAccessoryHolder().getFridge().getVerticalCOGOverride() === false && tempOriginalVehicle.getAccessoryHolder().getFridge().getVerticalCOGConfirmed() === false) {
            estimatedCOGs.push(config.getTranslationText('2770'));
        }
        //Taillift Vertical COG Adjusted
        if (tempVehicle.getAccessoryHolder().getTaillift() !== null && tempVehicle.getAccessoryHolder().getTaillift().getVerticalCOGOverride() === false && tempOriginalVehicle.getAccessoryHolder().getTaillift().getVerticalCOGConfirmed() === false) {
            estimatedCOGs.push(config.getTranslationText('2781'));
        }
        //Fifth Wheel Vertical COG Adjusted
        if (tempVehicle.getAccessoryHolder().getFifthWheel() !== null && tempVehicle.getAccessoryHolder().getFifthWheel().getVerticalCOGOverride() === false && tempOriginalVehicle.getAccessoryHolder().getFifthWheel().getVerticalCOGConfirmed() === false) {
            estimatedCOGs.push(config.getTranslationText('2852'));
        }
        //Hitch Vertical COG Adjusted
        if (tempVehicle.getAccessoryHolder().getHitch() !== null && tempVehicle.getAccessoryHolder().getHitch().getVerticalCOGOverride() === false && tempOriginalVehicle.getAccessoryHolder().getHitch().getVerticalCOGConfirmed() === false) {
            estimatedCOGs.push(config.getTranslationText('188'));
        }
        //Other Equipmnet Vertical COG Adjusted
        if (tempVehicle.getAccessoryHolder().getAccessories().length > 0) {
            tempVehicle.getAccessoryHolder().getAccessories().forEach(function (accessoryObject) {
                if (accessoryObject.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                    var originalOther = tempOriginalVehicle.getAccessoryHolder().getOtherUsingId(accessoryObject.getId());
                    if (originalOther !== null) {
                        if (accessoryObject.getVerticalCOGOverride() === false && originalOther.getVerticalCOGConfirmed() === false) {
                            estimatedCOGs.push(accessoryObject.getDescription());
                        }
                    }

                }
            });
        }

        // Crew Vertical COG        
        var testPath = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CREWFIRSTROWVERTICALCOG;
        
        
        // if (getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CREWFIRSTROWVERTICALCOG).permission().override === true && tempVehicle.getCrewFirstRowVerticalCOGOverride() === false && tempOriginalVehicle.getCrewFirstRowVerticalCOGConfirmed() === false) {
        if (getAttributeGroup(testPath).permission().override === true && tempVehicle.getCrewFirstRowVerticalCOGOverride() === false && tempOriginalVehicle.getCrewFirstRowVerticalCOGConfirmed() === false) {
            estimatedCOGs.push(config.getTranslationText('2844'));
        }
        if (tempVehicle.getCrewSecondRow() === true) {
            if (tempVehicle.getCrewSecondRowVerticalCOGOverride() === false && tempOriginalVehicle.getCrewSecondRowVerticalCOGConfirmed() === false) {
                estimatedCOGs.push(config.getTranslationText('2848'));
            }
        }

        // Fuel Tanks Vertical COG
        tempVehicle.getFuelTanks().forEach(function (fuelTank) {
            if (fuelTank.isFactoryFitted() && fuelTank.getVerticalCogOverride() === false && tempOriginalVehicle.getFuelTankById(fuelTank.getId()).getVerticalCOGConfirmed() === false) {
                estimatedCOGs.push(config.getTranslationText('2858') + ' ' + fuelTank.getNumber());
            }
        });



        if (estimatedCOGs.length > 0) {
            verticalCOGEstimatedNoteText = config.getTranslationText('632');

            if (estimatedCOGs.length === 1) {
                verticalCOGEstimatedNoteText = verticalCOGEstimatedNoteText.replace('$S1', estimatedCOGs[0].toLowerCase()); // User Story 17565
            } else {

                verticalCOGEstimatedNoteText = verticalCOGEstimatedNoteText.replace('$S1', getArrayValsAsConcatenatedString(estimatedCOGs)); // User Story 17565
            }
        }

        return verticalCOGEstimatedNoteText;

    }

    function getMassAdjustedNoteText() {

        var massAdjustedNoteText = '';
        var adjustedMasses = [];

        //Body Mass Adjusted
        var tempBody = tempVehicle.getAccessoryHolder().getBody();
        if (tempBody !== null && tempBody.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && tempBody.getMassOverride() === true) {
            adjustedMasses.push(config.getTranslationText('2839'));
        }

        //Crane Mass Adjusted
        var tempCrane = tempVehicle.getAccessoryHolder().getCrane();
        if (tempCrane !== null && tempCrane.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && tempCrane.getMassOverride() === true) {
            adjustedMasses.push(config.getTranslationText('2765'));
        }
        //Fridge Mass Adjusted
        var tempFridge = tempVehicle.getAccessoryHolder().getFridge();
        if (tempFridge !== null && tempFridge.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && tempFridge.getMassOverride() === true) {
            adjustedMasses.push(config.getTranslationText('2771'));
        }
        //Taillift Mass Adjusted
        var tempTaillift = getConfiguration().getAccessoryHolder().getTaillift();
        if (tempTaillift !== null && tempTaillift.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && tempTaillift.getMassOverride() === true) {
            adjustedMasses.push(config.getTranslationText('2782'));
        }
        //Fifth Wheel Mass Adjusted
        var tempFifthWheel = getConfiguration().getAccessoryHolder().getFifthWheel();
        if (tempFifthWheel !== null && tempFifthWheel.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && tempFifthWheel.getMassOverride() === true) {
            adjustedMasses.push(config.getTranslationText('2853'));
        }
        //Hitch Mass Adjusted
        var tempHitch = getConfiguration().getAccessoryHolder().getHitch();
        if (tempHitch !== null && tempHitch.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && tempHitch.getMassOverride() === true) {
            adjustedMasses.push(config.getTranslationText('188'));
        }

        //Other Equipmnet Vertical COG Adjusted
        if (getConfiguration().getAccessoryHolder().getAccessories().length > 0) {
            getConfiguration().getAccessoryHolder().getAccessories().forEach(function (accessoryObject) {
                if (accessoryObject.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                    if (accessoryObject.getMassOverride() === true && accessoryObject.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
                        adjustedMasses.push(accessoryObject.getDescription());
                    }
                }
            });
        }


        if (adjustedMasses.length > 0) {
            massAdjustedNoteText = config.getTranslationText('812');

            if (adjustedMasses.length === 1) {
                massAdjustedNoteText = massAdjustedNoteText.replace('$S1', adjustedMasses[0].toLowerCase()); // User Story 17565
            } else {

                massAdjustedNoteText = massAdjustedNoteText.replace('$S1', getArrayValsAsConcatenatedString(adjustedMasses)); // User Story 17565
            }
        }

        return massAdjustedNoteText;

    }

    function getVerticalCOGAdjustedNoteText() {

        var verticalCOGAdjustedNoteText = '';
        var adjustedCOGs = [];

        //Vehicle Vertical COG Adjusted
        if (tempVehicle.getVehicleVerticalCOGOverride() === true && tempVehicle.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
            adjustedCOGs.push(config.getTranslationText('4212'));
        }

        //Body Vertical COG Adjusted
        var tempBody = tempVehicle.getAccessoryHolder().getBody();
        if (tempBody !== null && tempBody.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && tempBody.getVerticalCOGOverride() === true) {
            adjustedCOGs.push(config.getTranslationText('2840'));

            //Payload Vertical COG Adjusted
            if (tempVehicle.getPayloadHolder().getPayloadObjects().length > 0) {
                if (tempVehicle.getPayloadHolder().getAveragePayloadVerticalCOG() !== tempOriginalVehicle.getPayloadHolder().getAveragePayloadVerticalCOG()) {
                    adjustedCOGs.push(config.getTranslationText('2866'));
                }
            }

        } else {
            //Payload Vertical COG Adjusted
            if (tempVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE && tempVehicle.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && tempVehicle.getPayloadHolder().getPayloadObjects().length > 0) {
                if (tempVehicle.getPayloadHolder().getAveragePayloadVerticalCOG() !== tempOriginalVehicle.getPayloadHolder().getAveragePayloadVerticalCOG()) {
                    adjustedCOGs.push(config.getTranslationText('2866'));
                }
            }
        }

        //Crane Vertical COG Adjusted
        var tempCrane = tempVehicle.getAccessoryHolder().getCrane();
        if (tempCrane !== null && tempCrane.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && tempCrane.getVerticalCOGOverride() === true) {
            adjustedCOGs.push(config.getTranslationText('2766'));
        }
        //Fridge Vertical COG Adjusted
        var tempFridge = tempVehicle.getAccessoryHolder().getFridge();
        if (tempFridge !== null && tempFridge.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && tempFridge.getVerticalCOGOverride() === true) {
            adjustedCOGs.push(config.getTranslationText('2772'));
        }
        //Taillift Vertical COG Adjusted
        var tempTaillift = getConfiguration().getAccessoryHolder().getTaillift();
        if (tempTaillift !== null && tempTaillift.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && tempTaillift.getVerticalCOGOverride() === true) {
            adjustedCOGs.push(config.getTranslationText('2783'));
        }
        //Fifth Wheel Vertical COG Adjusted
        var tempFifthWheel = getConfiguration().getAccessoryHolder().getFifthWheel();
        if (tempFifthWheel !== null && tempFifthWheel.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && tempFifthWheel.getVerticalCOGOverride() === true) {
            adjustedCOGs.push(config.getTranslationText('2854'));
        }
        //Hitch Vertical COG Adjusted
        var tempHitch = getConfiguration().getAccessoryHolder().getHitch();
        if (tempHitch !== null && tempHitch.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && tempHitch.getVerticalCOGOverride() === true) {
            adjustedCOGs.push(config.getTranslationText('188'));
        }

        //Other Equipmnet Vertical COG Adjusted
        if (getConfiguration().getAccessoryHolder().getAccessories().length > 0) {
            getConfiguration().getAccessoryHolder().getAccessories().forEach(function (accessoryObject) {
                if (accessoryObject.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                    if (accessoryObject.getVerticalCOGOverride() === true && accessoryObject.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
                        adjustedCOGs.push(accessoryObject.getDescription());
                    }
                }
            });
        }

        if (tempVehicle.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
            // Crew Vertical COG
            if (getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CREWFIRSTROWVERTICALCOG).permission().override === true && getConfiguration().getCrewFirstRowVerticalCOGOverride() === true) {
                adjustedCOGs.push(config.getTranslationText('2845'));
            }
            if (getConfiguration().getCrewSecondRow() === true) {
                if (getConfiguration().getCrewSecondRowVerticalCOGOverride() === true) {
                    adjustedCOGs.push(config.getTranslationText('2849'));
                }
            }

            // Fuel Tanks Vertical COG
            getConfiguration().getFuelTanks().forEach(function (fuelTank) {
                if (fuelTank.getVerticalCogOverride() === true) {
                    adjustedCOGs.push(config.getTranslationText('2859') + ' ' + fuelTank.getNumber());
                }
            });
        }

        if (adjustedCOGs.length > 0) {
            verticalCOGAdjustedNoteText = config.getTranslationText('631');

            if (adjustedCOGs.length === 1) {
                verticalCOGAdjustedNoteText = verticalCOGAdjustedNoteText.replace('$S1', adjustedCOGs[0].toLowerCase()); // User Story 17565
            } else {

                verticalCOGAdjustedNoteText = verticalCOGAdjustedNoteText.replace('$S1', getArrayValsAsConcatenatedString(adjustedCOGs)); // User Story 17565
            }
        }

        return verticalCOGAdjustedNoteText;

    }

    function getHorizontalCOGAdjustedNoteText() {

        var horizontalCOGAdjustedNoteText = '';
        var adjustedCOGs = [];

        //Body COG Adjusted
        var tempBody = tempVehicle.getAccessoryHolder().getBody();
        if (tempBody !== null && tempBody.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && tempBody.getHorizontalCOGOverride() === true) {
            adjustedCOGs.push(config.getTranslationText('2841'));

            //Payload COG Adjusted
            if (tempVehicle.getPayloadHolder().getPayloadObjects().length > 0) {
                if (tempVehicle.getPayloadHolder().getAveragePayloadHorizontalCOG() !== tempOriginalVehicle.getPayloadHolder().getAveragePayloadHorizontalCOG()) {
                    adjustedCOGs.push(config.getTranslationText('2867'));
                }
            }
        } else {
            //Payload COG Adjusted
            if (tempVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE && tempVehicle.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && tempVehicle.getPayloadHolder().getPayloadObjects().length > 0) {
                if (tempVehicle.getPayloadHolder().getAveragePayloadHorizontalCOG() !== tempOriginalVehicle.getPayloadHolder().getAveragePayloadHorizontalCOG()) {
                    adjustedCOGs.push(config.getTranslationText('2867'));
                }
            }
        }



        //Crane COG Adjusted
        var tempCrane = tempVehicle.getAccessoryHolder().getCrane();
        if (tempCrane !== null && tempCrane.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && tempCrane.getHorizontalCOGOverride() === true) {
            adjustedCOGs.push(config.getTranslationText('2767'));
        }

        //Fridge COG Adjusted
        var tempFridge = tempVehicle.getAccessoryHolder().getFridge()
        if (tempFridge !== null && tempFridge.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && tempFridge.getHorizontalCOGOverride() === true) {
            adjustedCOGs.push(config.getTranslationText('2773'));
        }

        //Taillift COG Adjusted
        var tempTaillift = tempVehicle.getAccessoryHolder().getTaillift();
        if (tempTaillift !== null && tempTaillift.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && tempTaillift.getHorizontalCOGOverride() === true) {
            adjustedCOGs.push(config.getTranslationText('2784'));
        }

        //FifthWheel COG Adjusted
        var tempFifthWheel = tempVehicle.getAccessoryHolder().getFifthWheel();
        if (tempFifthWheel !== null && tempFifthWheel.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && tempFifthWheel.getHorizontalCOGOverride() === true) {
            adjustedCOGs.push(config.getTranslationText('2855'));
        }

        //Hitch COG Adjusted
        var tempHitch = tempVehicle.getAccessoryHolder().getHitch();
        if (tempHitch !== null && tempHitch.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && tempHitch.getHorizontalCOGOverride() === true) {
            adjustedCOGs.push(config.getTranslationText('188'));
        }

        //Other Equipmnet COG Adjusted
        if (tempVehicle.getAccessoryHolder().getAccessories().length > 0) {
            tempVehicle.getAccessoryHolder().getAccessories().forEach(function (accessoryObject) {
                if (accessoryObject.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                    if (accessoryObject.getHorizontalCOGOverride() === true && accessoryObject.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
                        adjustedCOGs.push(accessoryObject.getDescription());
                    }
                }
            });
        }

        if (tempVehicle.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
            // Crew Horizontal COG
            if (getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CREWFIRSTROWHORIZONTALCOG).permission().override === true && tempVehicle.getCrewFirstRowHorizontalCOGOverride() === true) {
                adjustedCOGs.push(config.getTranslationText('2846'));
            }
            if (tempVehicle.getCrewSecondRow() === true) {
                if (tempVehicle.getCrewSecondRowHorizontalCOGOverride() === true) {
                    adjustedCOGs.push(config.getTranslationText('2850'));
                }
            }

            // Fuel Tanks Horizontal COG
            tempVehicle.getFuelTanks().forEach(function (fuelTank) {
                if (fuelTank.getHorizontalCogOverride() === true) {
                    adjustedCOGs.push(config.getTranslationText('2860') + ' ' + fuelTank.getNumber());
                }
            });
        }


        if (adjustedCOGs.length > 0) {
            horizontalCOGAdjustedNoteText = config.getTranslationText('629');

            if (adjustedCOGs.length === 1) {
                horizontalCOGAdjustedNoteText = horizontalCOGAdjustedNoteText.replace('$S1', adjustedCOGs[0].toLowerCase()); // User Story 17565
            } else {
                //var adjustedCOGsReplacementString = '';
                //for (var i = 0; i < adjustedCOGs.length; i++) {
                //    adjustedCOGsReplacementString = adjustedCOGsReplacementString + adjustedCOGs[i].toLowerCase();

                //    if (i >= 0 && i < adjustedCOGs.length - 2) {
                //        adjustedCOGsReplacementString = adjustedCOGsReplacementString + ', ';
                //    }else if (i === adjustedCOGs.length - 2) {
                //        adjustedCOGsReplacementString = adjustedCOGsReplacementString + ' & ';
                //    }
                //}
                horizontalCOGAdjustedNoteText = horizontalCOGAdjustedNoteText.replace('$S1', getArrayValsAsConcatenatedString(adjustedCOGs)); // User Story 17565
            }
        }

        return horizontalCOGAdjustedNoteText;

    }

    function getBodyHorizontalCOGAdjustedNote() {
        var vehicleBodyHCOGAdjusted = false,
            trailer1BodyHCOGAdjusted = false;

        vehicleBodyHCOGAdjusted = checkBodyHCOGAdjusted(getActiveOffer().getRig().getVehicle().getAccessoryHolder().getBody(), getOriginalOffer().getRig().getVehicle().getAccessoryHolder().getBody());
        trailer1BodyHCOGAdjusted = checkBodyHCOGAdjusted(getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getBody(), getOriginalOffer().getRig().getTrailer1().getAccessoryHolder().getBody());

        if (vehicleBodyHCOGAdjusted || trailer1BodyHCOGAdjusted) {
            newNotes.addString(config.NOTE_ID.BODY_HCOG_ADJUSTED, config.getTranslationText('696')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        }

        function checkBodyHCOGAdjusted(tempBody, tempOriginalBody) {
            if (tempBody !== null && tempBody.getHorizontalCOGOverride() === true && tempBody.getHorizontalCOG() !== tempOriginalBody.getHorizontalCOG()) {
                return true;
            }
            return false;
        }
    }

    function getArrayValsAsConcatenatedString(arrayVals) {
        var concatenatedString = '';
        for (var i = 0; i < arrayVals.length; i++) {
            concatenatedString = concatenatedString + arrayVals[i].toLowerCase();

            if (i >= 0 && i < arrayVals.length - 2) {
                concatenatedString = concatenatedString + ', ';
            } else if (i === arrayVals.length - 2) {
                concatenatedString = concatenatedString + ' & ';
            }
        }
        return concatenatedString;
    }

    function getVehicleWidthAdjustedNoteText() {
        if (tempVehicle.getCabWidthOverride() === true || tempVehicle.getRearWidthOverride() === true || tempVehicle.getTrackWidthFrontOverride() === true || tempVehicle.getTrackWidthRearOverride() === true || tempVehicle.getChassisWidthOverride() === true) {
            newNotes.addString(config.NOTE_ID.VEHICLE_WIDTH_ADJUSTED, config.getTranslationText('863')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        }
    }

    function getTurningAbilityAdjustedNoteText() {
        if (tempVehicle.getSteeringAngleInnerOverride() === true || tempVehicle.getTurningRadiusCurbToCurbOverride() === true || tempVehicle.getTurningRadiusWallToWallOverride() === true) {
            newNotes.addString(config.NOTE_ID.TURNING_ABILITY_ADJUSTED, config.getTranslationText('864')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        }
    }

    function getTurningCircleNote(newNotes) {
        if (activeConfigurationViewDetail.value !== undefined && activeConfigurationViewDetail.value.itemId === config.VIEW_DETAIL.TURNING_CIRCLE_REGULATION) {
            var turningRadiusOuterMax = getLesserOfActiveLegislationVals(legislationVals.variableTypes.TurningRadiusOuterMax);
            if (turningRadiusOuterMax > 0) {
                //newNotes.addString(config.NOTE_ID.TURNING_CIRCLE_REGULATION_WALL_TO_WALL, config.getTranslationText('853').replace('$S1', getUIFormattedLengthValue(turningRadiusOuterMax)).replace('$S2', getUIFormattedLengthValue(evaluateLegislation(legislationVals.variableTypes.TurningRadiusInnerMin))));
                newNotes.addString(config.NOTE_ID.TURNING_CIRCLE_REGULATION_WALL_TO_WALL, config.getTranslationText('853', [getUIFormattedLengthValue(turningRadiusOuterMax), getUIFormattedLengthValue(getLesserOfActiveLegislationVals(legislationVals.variableTypes.TurningRadiusInnerMin))])).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            } else {
                var turningRadiusCurbToCurbMax = getLesserOfActiveLegislationVals(legislationVals.variableTypes.TurningRadiusCurbToCurbMax);
                //newNotes.addString(config.NOTE_ID.TURNING_CIRCLE_REGULATION_CURB_TO_CURB, config.getTranslationText('861').replace('$S1', getUIFormattedLengthValue(turningRadiusCurbToCurbMax)).replace('$S2', getUIFormattedLengthValue(evaluateLegislation(legislationVals.variableTypes.TurningRadiusInnerMin))));
                newNotes.addString(config.NOTE_ID.TURNING_CIRCLE_REGULATION_CURB_TO_CURB, config.getTranslationText('861', [getUIFormattedLengthValue(turningRadiusCurbToCurbMax), getUIFormattedLengthValue(getLesserOfActiveLegislationVals(legislationVals.variableTypes.TurningRadiusInnerMin))])).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
            }

        } else {
            newNotes.addString(config.NOTE_ID.TURNING_CIRCLE_SMALLEST, config.getTranslationText('854')).addState(config.TRACKER_STATE_KEY.SECTION, config.NOTES_HEADINGS.GENERAL);
        }
    }

    function getEquipentListForChassisObject(chassisObjectToUse) {
        var note = '';

        var tempBody = chassisObjectToUse.getAccessoryHolder().getBody();
        var hasBody = tempBody !== null;

        if (chassisObjectToUse.getAccessoryHolder().getAccessories().length > 0) {
            var firstAppend = true;
            if (hasBody) {
                if (tempBody.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || tempBody.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
                    note = note + tempBody.getDescription();
                } else {
                    var bodyMassPerMetre;
                    if (tempBody.getMassType() === config.MASS_TYPES.MASS) {
                        if (tempBody.getLengthType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                            bodyMassPerMetre = tempBody.getMass() / (tempBody.getLength() / 1000);
                        } else {
                            bodyMassPerMetre = tempBody.getMass() / ((tempBody.getHeadboardThickness() +
                                tempBody.getLength() +
                                tempBody.getTailboardThickness()) / 1000);
                        }
                    } else {
                        bodyMassPerMetre = tempBody.getMass();
                    }
                    note = note + tempBody.getDescription() + ' - ' + getUIFormattedLinearDensityValue(bodyMassPerMetre);
                }
                firstAppend = false;
            }


            /*
            ' Other accessories
        For i As Integer = 0 To _Vehicle.Accessories.Count(False) - 1
            If itemAdded Then
                noteValue = noteValue & ", "
            End If
            If _Vehicle.Accessories.Item(i, False).Source = SourceTypes.Standard Then
                noteValue = noteValue & _Vehicle.Accessories.Item(i, False).Description
            Else
                noteValue = noteValue & _Custom & " " & _Vehicle.Accessories.Item(i, False).AccessorySummaryDescription
            End If
            itemAdded = True
        Next
            */

            for (var i = 0; i < chassisObjectToUse.getAccessoryHolder().getAccessories().length; i++) {
                if (chassisObjectToUse.getAccessoryHolder().getAccessories()[i].getAccessoryType() !== config.ACCESSORY_TYPES.BODY) {
                    if (firstAppend === false) {
                        note += ', ';
                    }
                    note += chassisObjectToUse.getAccessoryHolder().getAccessories()[i].getDescription();
                    firstAppend = false;
                }
            }

            //notes.push(note);
        }
        return note;
    }
}

//function getAttributeGroup(name, path) {
//    var group = null;
//    attributeObservableGroups.forEach(function (attributeGroup) {
//        if (attributeGroup.valueType === name) {
//            group = attributeGroup;
//            if (group.getOfferManager() === null) {
//                group.setOfferManager(offerManager);
//            }
//        }
//    });

//    return group;
//}

function getAttributeGroupDir(path) {
    var parts = path.split('.');

    if (parts.length > 1) {
        var curLevel = attributeObservableGroups[parts[0]];
        for (var i = 1; i < parts.length; i++) {
            curLevel = curLevel[parts[i]];
        }
        return curLevel;
    } else {
        if (attributeObservableGroups[parts[0]]) {
            return attributeObservableGroups[parts[0]];
        }
    }
    return null;
}

function getAttributeGroup(path) {
    var group = null;

    var parts = path.split('.');

    group = getGroup(parts, attributeObservableGroups);
    if (group !== null && group !== undefined && group.getOfferManager !== undefined && group.getOfferManager() === null) {
        group.setOfferManager(offerManager);
    }


    return group;
}

//function addAttributeGroup(attributeGroup) {
//    attributeObservableGroups.push(attributeGroup);
//}
function addAttributeGroup(attributeGroup, path) {
    var tempPath = path || config.CHASSIS_OBJECT_TYPES.VEHICLE;
    attributeGroup.setPath(tempPath);
 
    var parts = tempPath.split('.');

    var placeToAdd = getGroup(parts, attributeObservableGroups);
    placeToAdd[attributeGroup.getName()] = attributeGroup;
    //attributeObservableGroups.path = attributeGroup;
    //attributeObservableGroups.push(attributeGroup);
    
}

function getGroup(pathParts, attributeGroups) {

    if (pathParts.length === 1) {
        var levelRequired = attributeGroups[pathParts[0]];
        if (levelRequired === undefined || levelRequired === null) {
            attributeGroups[pathParts[0]] = {};
            return attributeGroups[pathParts[0]];
        } else {

            return levelRequired;
        }

    } else {
        var nextLevel = attributeGroups[pathParts[0]];
        if (nextLevel === undefined || nextLevel === null) {
            attributeGroups[pathParts[0]] = {};
            nextLevel = attributeGroups[pathParts[0]];
        }
        pathParts.splice(0, 1);
        return getGroup(pathParts, nextLevel);
    }
}

function isAttributeGroupVisible(path) {
    var group = getAttributeGroup(path);
    if (group !== null && group.permission !== undefined) {
        return group.permission(path).visible;
    } else {
        return false;
    }
}

function doGraphicsCallback(source) {
    changeListenerCallback(source);
}

function checkObjectRules(path, type, callbackToContinueExecutionIfPassed) {
    var chassisObjectToUse = rigOps.getChassisObjectFromPath(path);
    var parent = chassisObjectToUse.getChassisObjectName();

    var objectType = rigOps.getObjectTypeFromPath(path);

    switch (objectType) {
        case config.OBJECT_TYPES.AXLES:
            //'first check whether we can add this axle at all
            if (type === config.VEHICLE_AXLE_POSITION_TYPES.TAG && chassisObjectToUse.getRearOverhang() < (config.vehicleAxleDefaultAxleSpread + config.vehicleAxleDefaultTyreRadius + config.vehicleAxleDefaultAxleBuffer)) {
                configurationActionFailedCallback(parent, objectType, config.ACTION_FAILURE_REASONS.NO_SPACE_TAG);
            } else if (type === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER && chassisObjectToUse.getAxle2FrontToAxle1Rear() < (config.vehicleAxleDefaultAxleSpread + (config.vehicleAxleDefaultTyreRadius + config.vehicleAxleDefaultAxleBuffer) * 2)) {
                configurationActionFailedCallback(parent, objectType, config.ACTION_FAILURE_REASONS.NO_SPACE_PUSHER);
            } else if (type === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER && chassisObjectToUse.getAxle2FrontToAxle1Rear() < (config.vehicleAxleDefaultSecondSteerAxleSpread + (config.vehicleAxleDefaultTyreRadius + config.vehicleAxleDefaultAxleBuffer) * 2)) {
                configurationActionFailedCallback(parent, objectType, config.ACTION_FAILURE_REASONS.NO_SPACE_SECOND_STEER);
            } else if (globals.user.isUserAllowedFullAccessToConfigurationAddingAxles() === false) {
                configurationActionFailedCallback(parent, objectType, config.ACTION_FAILURE_REASONS.USER_NEEDS_TO_UPGRADE, type);
            } else {
                callbackToContinueExecutionIfPassed();
            }


            break;
        default:
            callbackToContinueExecutionIfPassed();
    }
}

function checkObjectDeleteRules(path, callbackToContinueExecutionIfPassed, dfd) {
    var chassisObjectToUse = rigOps.getChassisObjectFromPath(path);
    var parent = chassisObjectToUse.getChassisObjectName();
    var objectType = rigOps.getObjectTypeFromPath(path);

    switch (objectType) {
        case config.OBJECT_TYPES.AXLE_GROUPS:
        case config.OBJECT_TYPES.AXLES:
            var axleGroup = rigOps.getAxleGroupFromPath(path),
                axleGroupAxle;
            if (objectType === config.OBJECT_TYPES.AXLE_GROUPS) {
                axleGroup.getRelatedAxleIds().forEach(function (axleId) {
                    var tempAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
                    if (tempAxle.isAdded()) {
                        axleGroupAxle = tempAxle;
                    }
                });
            }
            
            var axle = objectType === config.OBJECT_TYPES.AXLE_GROUPS ? axleGroupAxle : rigOps.getAxleFromPath(path);

            if (axle !== null) {
                var noOfFrontAxles = chassisObjectToUse.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT);
                var noOfRearAxles = chassisObjectToUse.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR);
                var rearAxleNumber = chassisObjectToUse.getAxlesHolder().getAxleNumber(axle) - noOfFrontAxles;
                if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                    if (rearAxleNumber === noOfRearAxles) {
                        callbackToContinueExecutionIfPassed();
                    } else {
                        dfd.reject();
                        configurationActionFailedCallback(parent, objectType, config.ACTION_FAILURE_REASONS.INCORRECT_TAG_DELETE_ORDER);
                    }
                } else if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
                    if (rearAxleNumber === 1) {
                        callbackToContinueExecutionIfPassed();
                    } else {
                        dfd.reject();
                        configurationActionFailedCallback(parent, objectType, config.ACTION_FAILURE_REASONS.INCORRECT_PUSHER_DELETE_ORDER);
                    }
                } else {
                    callbackToContinueExecutionIfPassed();
                }
            }

            break;
        default:
            callbackToContinueExecutionIfPassed();
    }
}

function checkAccessoryRules(path, accessoryType, type, callbackToContinueExecutionIfPassed, itemData, forcePass, forceRuleCheck) {
    
    var parent = rigOps.getChassisTypeUsingPath(path);

    var chassisObjectToUse;
    switch (parent.split('.')[0]) {
        case config.CHASSIS_OBJECT_TYPES.VEHICLE:
            chassisObjectToUse = getActiveOffer().getRig().getVehicle();
            break;
        case config.CHASSIS_OBJECT_TYPES.TRAILER1:
            chassisObjectToUse = getActiveOffer().getRig().getTrailer1();
            break;
        case config.CHASSIS_OBJECT_TYPES.TRAILER2:
            chassisObjectToUse = getActiveOffer().getRig().getTrailer2();
            break;
    }
    if (typeof forcePass === "boolean" && forcePass === true) {
        callbackToContinueExecutionIfPassed();
    } else {


        switch (accessoryType) {
            case config.ACCESSORY_TYPES.FRIDGE:
                if (chassisObjectToUse.getAccessoryHolder().getBody() === null) {
                    configurationActionFailedCallback(parent, accessoryType, config.ACTION_FAILURE_REASONS.NO_BODY, itemData);
                } else {
                    if (chassisObjectToUse.getAccessoryHolder().getFridge() !== null) {
                        configurationActionFailedCallback(parent, accessoryType, config.ACTION_FAILURE_REASONS.ALREADY_ADDED, itemData);
                    } else if (chassisObjectToUse.getAccessoryHolder().getBody().getType() !== config.BODY_TYPES.VAN && chassisObjectToUse.getAccessoryHolder().getBody().getType() !== config.BODY_TYPES.TAUTLINER && chassisObjectToUse.getAccessoryHolder().getBody().getType() !== config.BODY_TYPES.OTHER) {
                        configurationActionFailedCallback(parent, accessoryType, config.ACTION_FAILURE_REASONS.INCOMPATIBLE_WITH_BODY, itemData);
                    } else {
                        callbackToContinueExecutionIfPassed();
                    }
                }
                break;
            case config.ACCESSORY_TYPES.TAILLIFT:
                if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObjectToUse.getType() !== config.VEHICLE_TYPES.COMPLETE_VEHICLE) && chassisObjectToUse.getAccessoryHolder().getBody() === null) {

                    configurationActionFailedCallback(parent, accessoryType, config.ACTION_FAILURE_REASONS.NO_BODY, itemData);
                } else {
                    if (chassisObjectToUse.getAccessoryHolder().getTaillift() !== null) {
                        configurationActionFailedCallback(parent, accessoryType, config.ACTION_FAILURE_REASONS.ALREADY_ADDED, itemData);
                    }/* else if (chassisObjectToUse.getAccessoryHolder().getBody() !== null && chassisObjectToUse.getAccessoryHolder().getBody().getType() !== config.BODY_TYPES.VAN &&
                        chassisObjectToUse.getAccessoryHolder().getBody().getType() !== config.BODY_TYPES.DROPSIDE &&
                        chassisObjectToUse.getAccessoryHolder().getBody().getType() !== config.BODY_TYPES.FLATDECK &&
                        chassisObjectToUse.getAccessoryHolder().getBody().getType() !== config.BODY_TYPES.TAUTLINER &&
                        chassisObjectToUse.getAccessoryHolder().getBody().getType() !== config.BODY_TYPES.SERVICE) {
                        configurationActionFailedCallback(parent, accessoryType, config.ACTION_FAILURE_REASONS.INCOMPATIBLE_WITH_BODY, itemData);
                    } */else {
                        callbackToContinueExecutionIfPassed();
                    }

                }
                break;
            case config.ACCESSORY_TYPES.CRANE:
                if (chassisObjectToUse.getAccessoryHolder().getCrane() !== null) {
                    configurationActionFailedCallback(parent, accessoryType, config.ACTION_FAILURE_REASONS.ALREADY_ADDED, itemData);
                } else {
                    callbackToContinueExecutionIfPassed();
                }
                break;
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                if (chassisObjectToUse.getAccessoryHolder().getFifthWheel() !== null) {
                    configurationActionFailedCallback(parent, accessoryType, config.ACTION_FAILURE_REASONS.ALREADY_ADDED, itemData);
                } else if (chassisObjectToUse.getAccessoryHolder().getBody() !== null) {
                    //configurationActionResponseHandlerCallback(config.ACTION_TYPE.ADD_ITEM, config.ACTION_ID.FIFTH_WHEEL_WHEN_BODY_PRESENT);
                    configurationActionFailedCallback(parent, accessoryType, config.ACTION_FAILURE_REASONS.HAS_BODY, itemData);
                } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                    //configurationActionResponseHandlerCallback(config.ACTION_TYPE.ADD_ITEM, config.ACTION_ID.FIFTH_WHEEL_WHEN_BODY_PRESENT);
                    configurationActionFailedCallback(parent, accessoryType, config.ACTION_FAILURE_REASONS.COMPLETE_VEHICLE, itemData);
                } else {
                    callbackToContinueExecutionIfPassed();
                }
                break;
            case config.ACCESSORY_TYPES.HITCH:
                if (chassisObjectToUse.getAccessoryHolder().getHitch() !== null) {
                    configurationActionFailedCallback(parent, accessoryType, config.ACTION_FAILURE_REASONS.ALREADY_ADDED, itemData);
                } else {
                    callbackToContinueExecutionIfPassed();
                }
                break;
            case config.ACCESSORY_TYPES.HOOKLIFT:
                if (chassisObjectToUse.getAccessoryHolder().getHooklift() !== null) {
                    configurationActionFailedCallback(parent, accessoryType, config.ACTION_FAILURE_REASONS.ALREADY_ADDED, itemData);
                } else {
                    callbackToContinueExecutionIfPassed();
                }
                break;
            case config.ACCESSORY_TYPES.TRAILER:
                if (type !== undefined) {
                    if (type === config.TRAILER_TYPES.SEMI || type === config.TRAILER_TYPES.INTERLINK) {
                        if (getConfiguration().getAccessoryHolder().getFifthWheel() === null && (getConfiguration().getTareFifthWheelIncluded() === '' || getConfiguration().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.NO || getConfiguration().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.NOT_SPECIFIED)) {
                            configurationActionFailedCallback(parent, accessoryType, config.ACTION_FAILURE_REASONS.NO_FIFTH_WHEEL, itemData);
                        } else {
                            callbackToContinueExecutionIfPassed();
                        }
                    } else if (type === config.TRAILER_TYPES.DRAWBAR || type === config.TRAILER_TYPES.PUP) {
                        if (getConfiguration().getAccessoryHolder().getHitch() === null) {
                            configurationActionFailedCallback(parent, accessoryType, config.ACTION_FAILURE_REASONS.NO_HITCH, itemData);
                        } else {
                            callbackToContinueExecutionIfPassed();
                        }
                    } else {
                        callbackToContinueExecutionIfPassed();
                    }

                } else {
                    callbackToContinueExecutionIfPassed();
                }

                break;
            case config.ACCESSORY_TYPES.BODY:
                if (forceRuleCheck === true) {
                    if (type === config.BODY_TYPES.HOOKLIFT) {
                        if (itemData.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY && chassisObjectToUse.getAccessoryHolder().getHooklift() === null) {
                            configurationActionFailedCallback(parent, accessoryType, config.ACTION_FAILURE_REASONS.NO_HOOKLIFT_ACCESSORY, itemData);
                        } else if (itemData.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY && itemData.getSource() !== config.ACCESSORY_SOURCE_TYPES.CUSTOM) {
                            configurationActionFailedCallback(parent, accessoryType, config.ACTION_FAILURE_REASONS.NO_HOOKLIFT_ACCESSORY, itemData);
                        } else  {
                            callbackToContinueExecutionIfPassed();
                        }
                    } else {
                        callbackToContinueExecutionIfPassed();
                    }
                } else {
                    callbackToContinueExecutionIfPassed();
                }
                break;
            default:
                callbackToContinueExecutionIfPassed();
        }
    }
}

function checkDeleteRules(path, callbackToContinueExecutionIfPassed, dfd) {
    if (rigOps.getAccessoryTypeFromPath(path) !== '' && rigOps.getAccessoryTypeFromPath(path) !== null) {
        checkAccessoryDeleteRules(path, callbackToContinueExecutionIfPassed, dfd);
    } else if (rigOps.getObjectTypeFromPath(path) !== '' && rigOps.getObjectTypeFromPath(path) !== null) {
        checkObjectDeleteRules(path, callbackToContinueExecutionIfPassed, dfd);
    } else {
        callbackToContinueExecutionIfPassed();
    }
}

function checkAccessoryDeleteRules(path, callbackToContinueExecutionIfPassed, dfd) {

    var parent = rigOps.getChassisTypeUsingPath(path);
    var accessoryType = rigOps.getAccessoryTypeFromPath(path);


    var chassisObjectToUse;
    switch (parent.split('.')[0]) {
        case config.CHASSIS_OBJECT_TYPES.VEHICLE:
            chassisObjectToUse = getActiveOffer().getRig().getVehicle();
            break;
        case config.CHASSIS_OBJECT_TYPES.TRAILER1:
            chassisObjectToUse = getActiveOffer().getRig().getTrailer1();
            if (accessoryType === null) {
                accessoryType = config.ACCESSORY_TYPES.TRAILER;
            }
            break;
        case config.CHASSIS_OBJECT_TYPES.TRAILER2:
            chassisObjectToUse = getActiveOffer().getRig().getTrailer2();
            if (accessoryType === null) {
                accessoryType = config.ACCESSORY_TYPES.TRAILER;
            }
            break;
    }

    switch (accessoryType) {
        case config.ACCESSORY_TYPES.FRIDGE:
            callbackToContinueExecutionIfPassed();
            break;
        case config.ACCESSORY_TYPES.TAILLIFT:
            callbackToContinueExecutionIfPassed();
            break;
        case config.ACCESSORY_TYPES.CRANE:
            callbackToContinueExecutionIfPassed();
            break;
        case config.ACCESSORY_TYPES.FIFTH_WHEEL:
            if (path.includes(config.ACCESSORY_TYPES.FIFTH_WHEEL) && offerManager.getActiveOffer().getRig().getTrailer1() !== null && (offerManager.getActiveOffer().getRig().getTrailer1().getType() === config.TRAILER_TYPES.SEMI || offerManager.getActiveOffer().getRig().getTrailer1().getType() === config.TRAILER_TYPES.INTERLINK)) {
                dfd.reject();
                configurationActionFailedCallback(parent, accessoryType, config.ACTION_FAILURE_REASONS.MUST_DELETE_TRAILER_FIRST);
            } else {
                callbackToContinueExecutionIfPassed();
            }
            break;
        case config.ACCESSORY_TYPES.HITCH:
            if (path.includes(config.ACCESSORY_TYPES.HITCH) && offerManager.getActiveOffer().getRig().getTrailer1() !== null && (offerManager.getActiveOffer().getRig().getTrailer1().getType() === config.TRAILER_TYPES.DRAWBAR || offerManager.getActiveOffer().getRig().getTrailer1().getType() === config.TRAILER_TYPES.PUP)) {
                dfd.reject();
                configurationActionFailedCallback(parent, accessoryType, config.ACTION_FAILURE_REASONS.MUST_DELETE_TRAILER_FIRST);
            } else {
                callbackToContinueExecutionIfPassed();
            }
            break;
        case config.ACCESSORY_TYPES.HOOKLIFT:
            if (path.includes(config.ACCESSORY_TYPES.HOOKLIFT) && chassisObjectToUse.getAccessoryHolder().getBody() !== null && chassisObjectToUse.getAccessoryHolder().getBody().getType() === config.BODY_TYPES.HOOKLIFT && chassisObjectToUse.getAccessoryHolder().getBody().getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                dfd.reject();
                configurationActionFailedCallback(parent, accessoryType, config.ACTION_FAILURE_REASONS.MUST_DELETE_BODY_FIRST);
            } else {
                callbackToContinueExecutionIfPassed();
            }
            //callbackToContinueExecutionIfPassed();
            break;
        case config.ACCESSORY_TYPES.TRAILER:
            //callbackToContinueExecutionIfPassed();

            if (path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) && offerManager.getActiveOffer().getRig().getTrailer1() !== null && offerManager.getActiveOffer().getRig().getTrailer1().getType() === config.TRAILER_TYPES.INTERLINK && offerManager.getActiveOffer().getRig().getTrailer2() !== null) {
                dfd.reject();
                configurationActionFailedCallback(parent, accessoryType, config.ACTION_FAILURE_REASONS.MUST_DELETE_TRAILER2_FIRST);
            } else {
                callbackToContinueExecutionIfPassed();
            }

            break;
        default:
            callbackToContinueExecutionIfPassed();
    }
}

function registerConfigurationActionFailedCallbackListener(callbackListener) {
    configurationActionFailedCallback = callbackListener;
}

function registerConfigurationActionResponseHandler(callbackListener) {
    configurationActionResponseHandlerCallback = callbackListener;
}

function incrementNumChanges() {
    numChangesInCurrentCalculation++;
}

function checkIfTrialChangesAllowed() {
    if (globals.user.getEmailVerifiedStatus() === config.EMAIL_VERIFICATION_STATUS_OPTIONS.NOT_VERIFIED) {
        if (numChangesInCurrentCalculation >= Number(config.maxTrialChangesBeforeForcedEmailVerification)) {
            return false;
        }
    }
    return true;
}

function checkShowVerifyEmailModal() {
    if (globals.user.getEmailVerifiedStatus() === config.EMAIL_VERIFICATION_STATUS_OPTIONS.NOT_VERIFIED) {
        if (numChangesInCurrentCalculation >= Number(config.maxTrialChangesBeforeForcedEmailVerification)) {

            checkVerifiedStatusOnline();
        }
    }
}

function checkVerifiedStatusOnline() {
    var dfd = $.Deferred();

    if (globals.isOnline.value === true) {
        dataManager.checkEmailVerificationStatusOnline()
            .then(function (emailVerifiedYesNo) {
                if (emailVerifiedYesNo.Verified === 'YES') {
                    globals.user.updateUser({
                        emailVerifiedStatus: config.EMAIL_VERIFICATION_STATUS_OPTIONS.VERIFIED
                    });
                    dfd.resolve(config.EMAIL_VERIFICATION_STATUS_OPTIONS.VERIFIED);
                } else {
                    //showEmailVerificationModal();
                    dfd.resolve(config.EMAIL_VERIFICATION_STATUS_OPTIONS.NOT_VERIFIED);
                }
            });
    } else {
        //showEmailVerificationModal();
        dfd.resolve(config.EMAIL_VERIFICATION_STATUS_OPTIONS.NOT_VERIFIED);
    }

    return dfd.promise();
}

function showEmailVerificationModal(optionalCode, optionalDdf) {

    var dfd;
    if (optionalDdf) {
        dfd = optionalDdf;
    } else {
        dfd = $.Deferred();
    }
    
    globals.showDialog({
        type: 'VerifyEmailModal',
        callback: function (dialogResult) {
            if (dialogResult !== undefined) {
                if (dialogResult.Action === 'verify') {
                    //call verification with code
                    globals.showSpinner();
                    dataManager.postVerificationCode(dialogResult.Code)
                        .then(function (emailVerifiedYesOrNo) {
                            //var messageCode = !globals.user.isVipTrialUser() && globals.user.displayNteaOptionsToUser() ? '1566' : '1562';
                            var messageCode = globals.getMessageCodeForTrialUsers(globals.user);
                            globals.hideSpinner();
                            if (emailVerifiedYesOrNo.Verified === 'YES') { // User Story 17565
                                var daysLeftMessageBox = new CustomMessageBox(config.getMessageText(messageCode, globals.getActiveCultureCodeForTranslations(), globals.user.getTrialDaysRemaining()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference(messageCode));
                                globals.showDialog(daysLeftMessageBox).addCallback(function (response) {
                                    if (messageCode !== '1566' || messageCode !== '4483' || response === config.getTranslationText('271')) {
                                        dfd.resolve(config.getTranslationText('271'));
                                    }
                                });
                                if (messageCode === '1566') {
                                    setTimeout(function () {
                                        $('#ntea-message-link').click(function () {
                                            // daysLeftMessageBox.__dialog__.close();
                                            $('.popup-modal .btn-primary.btn.btn-default.btn-truckscience').click();
                                            // shellOpenUserSettingsModalCallback(config.SETTINGS_MODAL_TABS.INTEGRATION, showShellSpinner, dfd);
                                            shellOpenUserSettingsModalCallback(config.SETTINGS_MODAL_TABS.INTEGRATION, null, dfd);
                                            var metaDataObject = {};
                                            metaDataObject[config.INTERCOM_METADATA_ITEM.SOURCE] = config.CONSIDERED_INTEGRATION_SOURCE.WELCOME_MESSAGE;
                                            dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.CONSIDERED_NTEA_INTEGRATION, metaDataObject));
                                            return false;
                                        });
                                    }, 1000);
                                }
                                if (messageCode === '4483') {
                                    setTimeout(function () {
                                        $('#sales-tool-request-link').click(function () {
                                            // daysLeftMessageBox.__dialog__.close();
                                            $('.popup-modal .btn-primary.btn.btn-default.btn-truckscience').click();
                                            nextTick(function(){
                                                globals.showDialog({
                                                    type: 'RequestTrialExtensionModal',
                                                    callback: requestSalesToolCallback,
                                                    modalType: config.REQUEST_MODAL_TYPE.SALES_TOOL
                                                });
                                            });
                                        });
                                    }, 1000);
                                }
                                globals.user.updateUser({
                                    emailVerifiedStatus: config.EMAIL_VERIFICATION_STATUS_OPTIONS.VERIFIED
                                });
                                emailVerified.value = true;
                                //dataManager.sendEventInfoToIntercom(globals.getIntercomObject(config.INTERCOM_EVENT.VERIFIED_EMAIL, { VerifiedFrom: 'In App' }));
                                //dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.VERIFIED_EMAIL, { VerifiedFrom: 'In App' }));
                                dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.VERIFIED_EMAIL, {
                                    VerifiedFrom: config.EMAIL_VERIFIED_FROM_OPTIONS.IN_APP
                                }));
                            } else {
                                globals.showDialog(new CustomMessageBox(config.getMessageText('1558', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1558')))
                                    .addCallback(function () {
                                        return showEmailVerificationModal(dialogResult.Code, dfd);
                                    });
                            }
                        })
                        .fail(function (error) {
                            globals.hideSpinner();
                            if (error.errorMessage === 'Offline') { // User Story 17565
                                globals.showDialog(new CustomMessageBox(config.getMessageText('1573', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1573')))
                                    .addCallback(function () {
                                        return showEmailVerificationModal(dialogResult.Code, dfd);
                                    });
                            } else {
                                dataManager.log("showEmailVerificationDialog, dataManager.postVerificationCode, fail, 1571", error, 'offerManager', config.LOG_MESSAGE_TYPE.ERROR);
                                globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')))
                                    .addCallback(function () {
                                        return showEmailVerificationModal(dialogResult.Code, dfd);
                                    });
                            }
                        });
                } else if (dialogResult.Action === 'resendEmail') {
                    globals.showSpinner();
                    dataManager.resendVerificationEmail()
                        .then(function (result) {
                            globals.hideSpinner();
                            if (result.ReturnCode === 1) {
                                globals.showDialog(new CustomMessageBox(config.getMessageText('1560', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1560')))
                                    .addCallback(function () {
                                        nextTick(function(){
                                            return showEmailVerificationModal(dialogResult.Code, dfd);
                                        });
                                    });
                            } else {
                                globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')))
                                    .addCallback(function () {
                                        nextTick(function(){
                                            return showEmailVerificationModal(dialogResult.Code, dfd);
                                        });
                                    });
                            }
                        })
                        .fail(function (error) {
                            if (error.errorMessage === 'Offline') {
                                globals.showDialog(new CustomMessageBox(config.getMessageText('1573', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1573')))
                                    .addCallback(function () {
                                        return showEmailVerificationModal(dialogResult.Code, dfd);
                                    });
                            } else {
                                dataManager.log("showEmailVerificationDialog, dataManager.postVerificationCode, fail, 1571", error, 'offerManager', config.LOG_MESSAGE_TYPE.ERROR);
                                globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')))
                                    .addCalbback(function () {
                                        return showEmailVerificationModal(dialogResult.Code, dfd);
                                    });
                            }
                        });
                } else if (dialogResult.Action === 'startAgain') {
                    shellLogout('mode=signup');
                }
            }
        },
        optionalCode: optionalCode,
        emailAddress:  globals.user.getEmailAddress()
    });
    

    return dfd.promise();

}

function getMetricDistanceValue(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return value / measurementSystemToUse.distanceConversion;
}

function getMetricVolumeValue(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return value / measurementSystemToUse.volumeConversion;
}

function getMetricDistanceValueReverse(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return value * measurementSystemToUse.distanceConversion;
}

function getConvertedDistanceValue(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return value * measurementSystemToUse.distanceConversion;
}

function getConvertedVolumeValue(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return value * measurementSystemToUse.volumeConversion;
}

function convertDistanceValueToMetric(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return value / measurementSystemToUse.distanceConversion;
}

function convertVolumeValueToMetric(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return value / measurementSystemToUse.volumeConversion;
}

function getMetricVolumeValueReverse(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return value * measurementSystemToUse.volumeConversion;
}

function getFuelConsumptionValueConversion(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return value * measurementSystemToUse.volumeConversion / measurementSystemToUse.distanceConversion;
}

function getConvertedFuelCostValue(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return value / measurementSystemToUse.volumeConversion;
}

function getConvertedMaintenanceRateValue(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return value / measurementSystemToUse.distanceConversion;
}

function getFuelConsumptionValueConversionToMetric(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return value / measurementSystemToUse.volumeConversion * measurementSystemToUse.distanceConversion;
}

function getFuelCostValueConversionToMetric(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return value * measurementSystemToUse.volumeConversion;
}

function getMaintenanceRateValueConversionToMetric(value, measurementSystem) {
    var measurementSystemToUse = getMeasurementSystemToUse(measurementSystem);
    return value * measurementSystemToUse.distanceConversion;
}

function getMissingValuesLookupObject() {

    var missingValuesLookupObject = {};
    var linkableMissingValues = 0; //numLinkableMissingValues

    var tareFront = getActiveOffer().getRig().getVehicle().getTareFront()//rigOps.getVehicleTareFront(); 
    var tareRear = getActiveOffer().getRig().getVehicle().getTareRearRear();
    var tareRearIsZero = false;
    var tareFrontIsZero = false;
    var manufacturersFrontAxleMassIsZero = false,
        manufacturersRearAxleMassIsZero = false,
        manufacturersGrossVehicleMassIsZero = false;

    if (tareFront === 0) {
        tareFrontIsZero = true;
        missingValuesLookupObject[config.VALUE_TYPE.TAREFRONT] = getLookupObject(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TAREFRONT, true, config.getTranslationText('101'));
        linkableMissingValues++;
    }

    if (tareRear === 0) {
        tareRearIsZero = true;
        missingValuesLookupObject[config.VALUE_TYPE.TAREREARREAR] = getLookupObject(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TAREREARREAR, true, config.getTranslationText('2590'));
        linkableMissingValues++;
    }

    if (tareRearIsZero === true || tareFrontIsZero === true) {
        missingValuesLookupObject[config.VALUE_TYPE.TARETOTAL] = getLookupObject(config.VALUE_TYPE.TARETOTAL);
        missingValuesLookupObject[config.VALUE_TYPE.UNLADENTOTAL] = getLookupObject(config.VALUE_TYPE.UNLADENTOTAL);

        if (getConfiguration().getPayloadHolder().getPayloadObjects().length > 0 && getConfiguration().getPayloadHolder().getSimplePayloadObject().getSpecifyMassOverride() === false) {
            missingValuesLookupObject[config.VALUE_TYPE.PAYLOADTOTAL] = getLookupObject(config.VALUE_TYPE.PAYLOADTOTAL);
            missingValuesLookupObject[config.VALUE_TYPE.PAYLOADFRONT] = getLookupObject(config.VALUE_TYPE.PAYLOADFRONT);
            missingValuesLookupObject[config.VALUE_TYPE.PAYLOADREAR] = getLookupObject(config.VALUE_TYPE.PAYLOADREAR);
        }


        if (tareFrontIsZero === true) {
            missingValuesLookupObject[config.VALUE_TYPE.UNLADENFRONT] = getLookupObject(config.VALUE_TYPE.UNLADENFRONT);
            missingValuesLookupObject[config.VALUE_TYPE.GROSSFRONT] = getLookupObject(config.VALUE_TYPE.GROSSFRONT);
        }

        if (tareRearIsZero === true) {
            missingValuesLookupObject[config.VALUE_TYPE.UNLADENREAR] = getLookupObject(config.VALUE_TYPE.UNLADENREAR);
            missingValuesLookupObject[config.VALUE_TYPE.GROSSREAR] = getLookupObject(config.VALUE_TYPE.GROSSREAR);
        }

    }

    if (tareRearIsZero === true && tareFrontIsZero === true) {
        missingValuesLookupObject[config.VALUE_TYPE.GROSSTOTAL] = getLookupObject(config.VALUE_TYPE.GROSSTOTAL);
    }

    //if (tareRearIsZero === true) {

    //}

    //if (tareFrontIsZero === true) {

    //}

    if (getConfiguration().getGAFront() === 0) {
        //if (getVehicleFrontPermissible() === 0) {
        manufacturersFrontAxleMassIsZero = true;
        var targetAttribute;
        //missingValuesLookupObject[config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS] = getLookupObject(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS, true, config.getTranslationText('236'));
        if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.SIMPLIFIED) {
            linkableMissingValues++;
            missingValuesLookupObject[config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS] = getLookupObject(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getId() + '.' + config.VALUE_TYPE.GROSSAXLEWEIGHTRATING, true, config.getTranslationText('236'), null, true, config.AXLE_LOCATIONS.FRONT);
        } else {
            targetAttribute, axleRatingZero = false, suspensionRatingZero = false, tyreMassRatingZero = false;
            var axleGroupFront = getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);

            if (axleGroupFront.getManufacturerRating() === 0) {
                axleRatingZero = true;
                linkableMissingValues++;
            }

            if (axleGroupFront.getSuspensionMassRating() === 0) {    
                suspensionRatingZero = true;
                linkableMissingValues++;
            }

            if (axleGroupFront.getTyreMassRating() === 0) {
                tyreMassRatingZero = true
                linkableMissingValues++;
            }

            if (axleRatingZero) {
                targetAttribute = config.VALUE_TYPE.AXLEMANUFACTURERRATING;
            } else if (suspensionRatingZero) {
                targetAttribute = config.VALUE_TYPE.SUSPENSIONMASSRATING;
            } else if (tyreMassRatingZero) {
                targetAttribute = config.VALUE_TYPE.TYREMASSRATING;
            }
            if (targetAttribute !== undefined) {
                missingValuesLookupObject[config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS] = getLookupObject(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getId() + '.' + targetAttribute, true, config.getTranslationText('236'), null, true, config.AXLE_LOCATIONS.FRONT);
            }
        }
        
        //'.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getId() + '.'
        
    }

    if (getConfiguration().getGARear() === 0) {
        //if (getVehicleRearPermissible() === 0) {
        manufacturersRearAxleMassIsZero = true;
        //missingValuesLookupObject[config.VALUE_TYPE.MANUFACTURERSREARAXLEMASS] = getLookupObject(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.MANUFACTURERSREARAXLEMASS, true, config.getTranslationText('2591'));
        if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.SIMPLIFIED) {
            linkableMissingValues++;
            missingValuesLookupObject[config.VALUE_TYPE.MANUFACTURERSREARAXLEMASS] = getLookupObject(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getId() + '.' + config.VALUE_TYPE.GROSSAXLEWEIGHTRATING, true, config.getTranslationText('2591'), null, true, config.AXLE_LOCATIONS.REAR);
        } else {
            var axleRatingZero = false, suspensionRatingZero = false, tyreMassRatingZero = false;
            var axleGroupRear = getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
            if (axleGroupRear.getManufacturerRating() === 0) {
                axleRatingZero = true;
                linkableMissingValues++;
            }

            if (axleGroupRear.getSuspensionMassRating() === 0) {
                suspensionRatingZero = true;
                linkableMissingValues++;
            }

            if (axleGroupRear.getTyreMassRating() === 0) {
                tyreMassRatingZero = true
                linkableMissingValues++;
            }

            if (axleRatingZero) {
                targetAttribute = config.VALUE_TYPE.AXLEMANUFACTURERRATING;
            } else if (suspensionRatingZero) {
                targetAttribute = config.VALUE_TYPE.SUSPENSIONMASSRATING;
            } else if (tyreMassRatingZero) {
                targetAttribute = config.VALUE_TYPE.TYREMASSRATING;
            }
            if (targetAttribute !== undefined) {
                missingValuesLookupObject[config.VALUE_TYPE.MANUFACTURERSREARAXLEMASS] = getLookupObject(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getId() + '.' + targetAttribute, true, config.getTranslationText('2591'), null, true, config.AXLE_LOCATIONS.REAR);
            }
        }
    }

    if (getConfiguration().getGVM() === 0) {
        //if (getVehicleTotalPermissible() === 0) {
        manufacturersGrossVehicleMassIsZero = true;
        missingValuesLookupObject[config.VALUE_TYPE.MANUFACTURERSGROSSVEHICLEMASS] = getLookupObject(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_TOTAL + '.' + config.VALUE_TYPE.MANUFACTURERSGROSSVEHICLEMASS, true, config.getTranslationText('1358'));
        linkableMissingValues++;
    }
    if (tareRearIsZero === true || manufacturersRearAxleMassIsZero === true) {
        missingValuesLookupObject[config.VALUE_TYPE.OVERLOADREAR] = getLookupObject(config.VALUE_TYPE.OVERLOADREAR);
    }
    if (tareFrontIsZero === true || manufacturersFrontAxleMassIsZero === true) {
        missingValuesLookupObject[config.VALUE_TYPE.OVERLOADFRONT] = getLookupObject(config.VALUE_TYPE.OVERLOADFRONT);
    }
    //if (tareRearIsZero === true || tareFrontIsZero === true || manufacturersFrontAxleMassIsZero === true || manufacturersRearAxleMassIsZero === true || manufacturersGrossVehicleMassIsZero === true) {
    //    missingValuesLookupObject[config.VALUE_TYPE.OVERLOADTOTAL] = getLookupObject(config.VALUE_TYPE.OVERLOADTOTAL);
    //    missingValuesLookupObject[config.VALUE_TYPE.OVERLOADFRONT] = getLookupObject(config.VALUE_TYPE.OVERLOADFRONT);
    //    missingValuesLookupObject[config.VALUE_TYPE.OVERLOADREAR] = getLookupObject(config.VALUE_TYPE.OVERLOADREAR);
    //}
    if ((tareRearIsZero === true && tareFrontIsZero === true) || (manufacturersFrontAxleMassIsZero === true && manufacturersRearAxleMassIsZero === true) || (manufacturersGrossVehicleMassIsZero === true)) {
        missingValuesLookupObject[config.VALUE_TYPE.OVERLOADTOTAL] = getLookupObject(config.VALUE_TYPE.OVERLOADTOTAL);
    }

    if (getActiveOffer().getRig().getTrailer1() !== null || getActiveOffer().getRig().getTrailer2() !== null) {
        if (getConfiguration().getGCM() === 0) {
            //manufacturersGrossVehicleMassIsZero = true;
            missingValuesLookupObject[config.VALUE_TYPE.MANUFACTURERSGROSSCOMBINATIONMASS] = getLookupObject(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_TOTAL +  '.' + config.VALUE_TYPE.MANUFACTURERSGROSSCOMBINATIONMASS, true, config.getTranslationText('1359'));
            linkableMissingValues++;
        }
    }

    numLinkableMissingValues = linkableMissingValues;

    return missingValuesLookupObject;


}

function getTurningCircleMissingValuesLookupObject() {
    var missingValuesLookupObject = {};
    var linkableMissingValues = 0; //numLinkableMissingValues

    var tempVehicle = getActiveOffer().getRig().getVehicle();
    var tempTrailer1 = getActiveOffer().getRig().getTrailer1();
    var tempTrailer2 = getActiveOffer().getRig().getTrailer2();

    var wheelbaseTurning = tempVehicle.getWheelbaseTurning();
    var frontOverhang = tempVehicle.getFrontOverhang();
    var cabWidth = tempVehicle.getCabWidth();
    var trackWidthFront = tempVehicle.getTrackWidthFront();
    var tyreWidthFront = tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth();

    var rearWidth = tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation();

    var steeringAngleInner = tempVehicle.getSteeringAngleInner();
    var turningRadiusCurbToCurb = tempVehicle.getTurningRadiusCurbToCurb();
    var turningRadiusWallToWall = tempVehicle.getTurningRadiusWallToWall();

    //var steeringAngleInnerIsZero = false;
    //var turningRadiusCurbToCurbIsZero = false;
    //var turningRadiusWallToWallIsZero = false;

    if (wheelbaseTurning === 0) {
        missingValuesLookupObject[config.VALUE_TYPE.WHEELBASETURNING] = getLookupObject(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.AXLE2FRONTTOAXLE1REAR, true, config.getTranslationText('571'));
        linkableMissingValues++;
    }

    if (frontOverhang === 0) {
        missingValuesLookupObject[config.VALUE_TYPE.FRONTOVERHANG] = getLookupObject(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.FRONTOVERHANG, true, config.getTranslationText('2624'));
        linkableMissingValues++;
    }

    if (cabWidth === 0) {
        missingValuesLookupObject[config.VALUE_TYPE.CABWIDTH] = getLookupObject(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CABWIDTH, true, config.getTranslationText('2625'));
        linkableMissingValues++;
    }

    if (trackWidthFront === 0) {
        missingValuesLookupObject[config.VALUE_TYPE.TRACKWIDTHFRONT] = getLookupObject(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TRACKWIDTHFRONT, true, config.getTranslationText('2626'));
        linkableMissingValues++;
    }

    if (tyreWidthFront === 0) {

        var axleId = getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxle1Front().getId();

        //missingValuesLookupObject[config.VALUE_TYPE.TYREWIDTH] = getLookupObject(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.TYREWIDTH, true, config.getTranslationText('852'));
        missingValuesLookupObject[config.VALUE_TYPE.TYREWIDTH] = getLookupObject(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getId() + '.' + config.VALUE_TYPE.TYREWIDTH, true, config.getTranslationText('852'));
        
        linkableMissingValues++;
    }

    if (rearWidth === 0) {
        missingValuesLookupObject[config.VALUE_TYPE.REARWIDTH] = getLookupObject(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REARWIDTH, true, config.getTranslationText('327'));
        linkableMissingValues++;
    }

    if (tempTrailer1 !== null) {

        var trailer1WheelbaseTurning = tempTrailer1.getWheelbaseTurning();
        var trailer1OverallWidth = tempTrailer1.getOverallWidth();

        if (trailer1WheelbaseTurning === 0) {
            missingValuesLookupObject[config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERWHEELBASETURNING] = getLookupObject(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERWHEELBASETHEORETICAL, true, config.getTranslationText('1316'));
            linkableMissingValues++;
        }

        if (trailer1OverallWidth === 0) {
            missingValuesLookupObject[config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILEROVERALLWIDTH] = getLookupObject(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILEROVERALLWIDTH, true, config.getTranslationText('1314'));
            linkableMissingValues++;
        }

        if (tempTrailer2 !== null) {
            var trailer2WheelbaseTurning = tempTrailer2.getWheelbaseTurning();
            var trailer2OverallWidth = tempTrailer2.getOverallWidth();

            if (trailer2WheelbaseTurning === 0) {
                missingValuesLookupObject[config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.TRAILERWHEELBASETURNING] = getLookupObject(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.TRAILERWHEELBASETHEORETICAL, true, config.getTranslationText('1317'));
                linkableMissingValues++;
            }

            if (trailer2OverallWidth === 0) {
                missingValuesLookupObject[config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.TRAILEROVERALLWIDTH] = getLookupObject(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.TRAILEROVERALLWIDTH, true, config.getTranslationText('1315'));
                linkableMissingValues++;
            }
        }
    }

    if (parseFloat(steeringAngleInner) === 0 && turningRadiusCurbToCurb === 0 && turningRadiusWallToWall === 0) {
        missingValuesLookupObject[config.VALUE_TYPE.TURNINGABILITYSPECIFIEDAS] = getLookupObject(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TURNINGABILITYSPECIFIEDAS, true, config.getTranslationText('830'));
        linkableMissingValues++;
    }

    numLinkableTurningCircleMissingValues = linkableMissingValues;

    return missingValuesLookupObject;
}

function getStandardTableCellInteractionObject(interactionId, elementId) {
    //if (pathToVal.indexOf('.') !== -1) {
    //    pathParts = pathToVal.split('.');
    //    menuLinkId = pathParts[pathParts.length - 1];
    //} else {
    //    menuLinkId = pathToVal;
    //}
    return {
        showAsLink: true,
        id: interactionId,
        elementId: elementId,
        callbacks: {
            mouseover: function (mousePos) {
                standardTableMouseOverCallback(elementId, mousePos)
            },
            click: function (mousePos) {
                standardTableClickCallback(interactionId, elementId, mousePos)
            },
            mouseout: function (mousePos) {
                standardTableMouseOutCallback(elementId, mousePos)
            }
        }
    };
}

function getComplianceDashboardInteractionObject(interactionId, elementId) {
    //if (pathToVal.indexOf('.') !== -1) {
    //    pathParts = pathToVal.split('.');
    //    menuLinkId = pathParts[pathParts.length - 1];
    //} else {
    //    menuLinkId = pathToVal;
    //}
    return {
        id: interactionId,
        elementId: elementId,
        callbacks: {
            mouseover: function (mousePos) {
                complianceDashboardMouseOverCallback(interactionId, elementId, mousePos)
            },
            click: function (mousePos) {
                complianceDashboardClickCallback(interactionId, elementId, mousePos)
            },
            mouseout: function (mousePos) {
                complianceDashboardMouseOutCallback(interactionId, elementId, mousePos)
            }
        }
    };
}

function getLookupObject(pathToVal, showAsLinkInTableBool, valLinkText, optionalTableLinkChar, doNotTrimPath, prependIdText) {

    var pathParts;
    var menuLinkId;
    var menuLink;
    var tableLinkChar;
    if (pathToVal.indexOf('.') !== -1) {
        pathParts = pathToVal.split('.');
        menuLinkId = typeof prependIdText === 'string' ? prependIdText + pathParts[pathParts.length - 1] : pathParts[pathParts.length - 1];
        menuLink = getMenuLink(valLinkText, menuLinkId);

    } else {
        menuLinkId = pathToVal;
        menuLink = null;
        showAsLinkInTableBool = false;
    }
    tableLinkChar = optionalTableLinkChar || config.defaultTableLinkChar;
    return {
        showAsLink: showAsLinkInTableBool,
        path: pathToVal,
        id: menuLinkId,
        menuLink: menuLink,
        variableName: valLinkText,
        tableLinkChar: tableLinkChar,
        doNotTrimPath: typeof doNotTrimPath === 'boolean' ? doNotTrimPath : false,
        callbacks: showAsLinkInTableBool === true ? {
            mouseover: function (mousePos) {
                tableMouseoverCallback(menuLinkId, mousePos)
            },
            click: function (mousePos) {
                tableClickCallback(menuLinkId, mousePos)
            },
            mouseout: function (mousePos) {
                tableMouseoutCallback(menuLinkId, mousePos)
            }
        } : undefined
    };
}

function tableMouseoutCallback(idOfMousedOutCell, mousePos) {
    //$('html,body').css('cursor', 'auto');
    ////hideToolTipOverGraphic();
    //setTimeout(function () {
    //    try {
    //        $('#tooltip-element').remove();
    //        $('#tooltip-element').tooltip('destroy');
    //        //$('#weights-table-tooltip-div').remove(); 
    //    }catch(ex) {

    //    }
    //}, 2500);

}

function tableMouseoverCallback(idOfMousedOverCell, mousePos) {
    ////console.log('Mouseover: ' + idOfMousedOverCell);
    //$('html,body').css('cursor', 'pointer');

    ////if ($('#weights-table-tooltip-div').length === 0) {
    ////    var toolTipContainerDiv = '<div id="weights-table-tooltip-div" style="position: absolute;width:100%; height: 100%;"></div>';
    ////    $("#drawingArea").append(toolTipContainerDiv);
    ////}

    //var div = $('<div style="position:absolute; top: ' + (mousePos.y-15) + 'px; left: ' + (mousePos.x-30) + 'px;width:100px; height:30px;z-index: 1030" id"tooltip-element" data-toggle="tooltip" title="Hooray!">      </div>')

    ////$("#weights-table-tooltip-div").append(div);
    //$("#drawingArea").append(div);
    //$("#tooltip-element").tooltip({placement: 'top', trigger: 'manual' }).tooltip('show');
}

function tableClickCallback(idOfClickedCell, mousePos) {
    //alert('Clicked: ' + idOfClickedCell);
    try {
        if (idOfClickedCell === config.INTERACTION_ID.XXED_OUT_VALUE) {
            globals.showDialog(new CustomMessageBox(config.getTranslationText('686'), config.applicationTitle, [config.getTranslationText('271')]));
        } else {
            var missingValuesObject = getMissingValuesLookupObject();
            doActionsAfterMenuLinkClickPriorToMenuExpansion(missingValuesObject[idOfClickedCell]);
            expandMenuLinkCallback(missingValuesObject[idOfClickedCell]);
        }

    } catch (configurationNotShowingEx) {
        TScMessenger.writeDebugMessage('OfferManager.js, tableClickCallback, error');
    }

}

function dimensionValueClickCallback(idOfDimensionClicked, mousePos) {
    try {
        globals.showDialog(new CustomMessageBox(config.getTranslationText('686'), config.applicationTitle, [config.getTranslationText('271')]));
    } catch (configurationNotShowingEx) {
        TScMessenger.writeDebugMessage('OfferManager.js, dimensionValueClickCallback, error');
    }
}

function dimensionMouseOverCallback(idOfDimensionClicked, mousePos) {

}

function cOGElementClickCallback(idOfCOGElementClicked, mousePos) {
    try {
        globals.showDialog(new CustomMessageBox(config.getTranslationText('686'), config.applicationTitle, [config.getTranslationText('271')]));
    } catch (configurationNotShowingEx) {
        TScMessenger.writeDebugMessage('OfferManager.js, cOGElementClickCallback, error');
    }
}
//interactionId, elementId, mousePos, interactionBehaviour
function drawingGroupClickCallback(interactionId, idOfDrawingGroupClicked, mousePos, interactionBehaviour) {
    if (interactionBehaviour === config.INTERACTION_BEHAVIOUR.PINNABLE) {
        validPdfOnLocalStorage.value = false;
        // validPdfOnLocalStorage.valueHasMutated();
        setConfigDrawing(null);
        dataManager.remove(config.OPERATION.ConfigDrawing, vehicleId.value + "_configurationDrawing");
    } else {
        expandMenuLinkCallback({
            path: idOfDrawingGroupClicked
        });
    }
}

function drawingGroupMouseOverCallback(idOfDrawingGroupMouseOvered, mousePos) {
    //do nothing
}

function drawingGroupMouseOutCallback(idOfDrawingGroupMouseOuted, mousePos) {
    // do nothing
}

function standardTableClickCallback(interactionId, idOfCellClicked, mousePos) {
    expandMenuLinkCallback({
        path: idOfCellClicked,
        doNotTrimPath: true
    });
}

function standardTableMouseOverCallback(idOfDrawingGroupMouseOvered, mousePos) {
    // do nothing
}

function standardTableMouseOutCallback(idOfDrawingGroupMouseOuted, mousePos) {
    // do nothing
}

function complianceDashboardClickCallback(interactionId, elementId, mousePos) {
    if (elementId === config.COMPLIANCE_IDS.NAVIGATION_SETTINGS_ICON) {
        openSettingsModalCallback('legislation');
    } else /*if (elementId === config.COMPLIANCE_DASHBOARD_VIEWS.REGULATORY_COMPLIANCE) */{
        //changeListenerCallback('DASHBOARD_DRILL_DOWN', elementId);
        invalidatePdfAndDrawing();
        changeListenerCallback(interactionId, elementId);
    }
}

function complianceDashboardMouseOverCallback(interactionId, elementId, mousePos) {
    //do nothing
}

function complianceDashboardMouseOutCallback(interactionId, elementId, mousePos) {
    //do nothing
}

function setAttributeOverrideIfNecessary(missingValueObject) {
    if (missingValueObject.id === config.VALUE_TYPE.TAREFRONT || 
        missingValueObject.id === config.VALUE_TYPE.TAREREAR || 
        missingValueObject.id === config.VALUE_TYPE.TAREREARREAR || 
        missingValueObject.id === config.VALUE_TYPE.MANUFACTURERSGROSSVEHICLEMASS ||
        missingValueObject.id.includes(config.VALUE_TYPE.GROSSAXLEWEIGHTRATING) ||
        missingValueObject.id === config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS || 
        missingValueObject.id === config.VALUE_TYPE.MANUFACTURERSREARAXLEMASS) {

        getAttributeGroup(missingValueObject.path).override.value = true;
    }
}

function getMenuLink(valLinkText, menuLinkId) {
    return '<span class="toastr-link" id="' + menuLinkId + '-menu-link">' + valLinkText + '</span>';
}

//function expandMenuToSpecificLocation(event) {

//    expandMenuLinkCallback(getMissingValuesLookupObject()[event.target.result].path);
//}

function doActionsAfterMenuLinkClickPriorToMenuExpansion(missingValueObject) {
    setAttributeOverrideIfNecessary(missingValueObject);
}

/**
    * Function to create string containing warnings generated from the missing values object. Used to generate text for Notes and for missing value toasts
    * @param {function} getMissingValuesFunc - Function that returns missing values object used to create message
    * @param {boolean} createWarningsForToast - True when generating message for missing values toast to create <li> items. Otherwise warning text for notes will be generated
    * @returns {string} - Empty string when no <li> items created for toast or no missing values. Otherwise message containing missing values.
    */
function getMissingValuesWarningText(getMissingValuesFunc, createWarningsForToast) {
    if (createWarningsForToast === true) {
        return createWarningTextForToast(getMissingValuesFunc);
    } else {
        return createWarningTextForNotes(getMissingValuesFunc);
    }
    
    /**
        * Create text to be displayed on toast based on missing values function
        * @param {function} getMissingValuesFunc - Function that returns missing values object used to create message
        * @returns {string} Empty string when no <li> items created for toast. Otherwise message containing missing values in a list.
        */
    function createWarningTextForToast(getMissingValuesFunc) {
        var missingValues = getMissingValuesFunc(),
            listItems = [];

        if (Object.keys(missingValues).length > 0) {
            for (var missingVal in missingValues) {
                if (missingValues[missingVal]) {
                    if (missingValues[missingVal].variableName !== undefined) {
                        listItems.push('<li>' + missingValues[missingVal].menuLink + '</li>');
                    }
                }
            }
        }

        if (listItems.length > 0) {
            var listOfWarnings = '<ul style="list-style-type: none;list-style-position:inside;margin:0;padding:0;">';
            listItems.forEach(function (warningLi) {
                listOfWarnings += warningLi;
            });
            listOfWarnings += '</ul>';
            return config.getTranslationText('440', [listOfWarnings]);
        } else {
            return '';
        }
    }

    /**
        * Create text to be displayed on notes/report based on missing values function
        * @param {function} getMissingValuesFunc - Function that returns missing values object used to create message
        * @returns {string} Empty string when no missing. Otherwise message containing missing values in a comma separated list.
        */
    function createWarningTextForNotes(getMissingValuesFunc) {
        var missingValues = getMissingValuesFunc(),
            warningText = '';

        if (Object.keys(missingValues).length > 0) {
            for (var missingVal in missingValues) {
                if (missingValues[missingVal]) {
                    if (missingValues[missingVal].variableName !== undefined) {
                        if (warningText !== '') {
                            warningText += ', '
                        }
                        warningText += missingValues[missingVal].variableName;
                    }
                }
            }
            return config.getTranslationText('176', [warningText])
        }

        return warningText;
    }
}

function getNumLinkableMissingValues() {
    // if (router && router.activeItem() && router.activeItem().title === "Configuration") {
    if (router && router.currentRoute && router.currentRoute.value.name === "configuration") {
        return numLinkableMissingValues;
    } else {
        return 0;
    }
}

function getNumLinkableTurningCircleMissingValues() {
    if (router && router.currentRoute && router.currentRoute.value.name === "configuration") {
        return numLinkableTurningCircleMissingValues;
    } else {
        return 0;
    }
}

function getTrialUserXValuesInteractionMetaObject(tableLinkChar) {
    //body
    //equipment
    //unladen
    //payload
    //gross
    return {
        showAsLink: true,
        id: config.INTERACTION_ID.XXED_OUT_VALUE,
        tableLinkChar: tableLinkChar,
        callbacks: {
            mouseover: function (mousePos) {
                tableMouseoverCallback(config.INTERACTION_ID.XXED_OUT_VALUE, mousePos)
            },
            click: function (mousePos) {
                tableClickCallback(config.INTERACTION_ID.XXED_OUT_VALUE, mousePos)
            },
            mouseout: function (mousePos) {
                tableMouseoutCallback(config.INTERACTION_ID.XXED_OUT_VALUE, mousePos)
            }
        }
    }

}

function getDimensionInteractionObject(id) {
    return {
        //showAsLink: true,
        id: id,
        callbacks: {
            mouseover: undefined,
            click: function (mousePos) {
                dimensionValueClickCallback(id, mousePos)
            },
            mouseout: undefined
        }
    }
}

function getCOGElementInteractionObject(interactionId, elementId) {
    return {
        //showAsLink: true,
        id: interactionId,
        elementId: elementId,
        callbacks: {
            mouseover: function (mousePos) {
                drawingGroupMouseOverCallback(interactionId, elementId, mousePos);
            },
            click: function (mousePos) {
                cOGElementClickCallback(interactionId, elementId, mousePos)
            },
            mouseout: function (mousePos) {
                drawingGroupMouseOutCallback(interactionId, elementId, mousePos);
            }
        }
    }
}

function getDrawingGroupInteractionObject(interactionId, elementId) {
    return {
        id: interactionId,
        elementId: elementId,
        callbacks: {
            mouseover: function (mousePos) {
                drawingGroupMouseOverCallback(interactionId, elementId, mousePos);
            },
            click: function (mousePos, interactionBehaviour, hitMaskId) {
                var idToUse = hitMaskId !== undefined ? hitMaskId : elementId;
                drawingGroupClickCallback(interactionId, idToUse, mousePos, interactionBehaviour);
            },
            mouseout: function (mousePos) {
                drawingGroupMouseOutCallback(interactionId, elementId, mousePos);
            }
        }
    }
}

//function getCOGDimensionInteractionObject(interactionId, elementId) {
//    return {
//        id: interactionId,
//        elementId: elementId,
//        callbacks: {
//            mouseover: undefined,
//            click: function (mousePos) {
//                drawingGroupClickCallback(interactionId, elementId, mousePos);
//            },
//            mouseout: function (mousePos) {
//                drawingGroupMouseOutCallback(interactionId, elementId, mousePos);
//            },
//            mousemove: function (mousePos) {
//                drawingGroupMouseOverCallback(interactionId, elementId, mousePos);
//            }        
//        }
//    }
//}

function getDraggableObjectInteractionObject(interactionId, elementId, dragObjects) {
    return {
        id: interactionId,
        elementId: elementId,
        dragInfoObjects: dragObjects,
        callbacks: {
            dragStart: function (newAttributeValuesToBypassValidationWith) {
                newAttributeValuesToBypassValidationWith.forEach(function (dragObjInfo) {
                    var aog = getAttributeGroup(dragObjInfo.path);
                    //aog.setUndoVal();
                    //aog.setBypassValidation(true);
                    //aog.formatted(dragObjInfo.dragValue);
                    aog.disableUndoOpCreation();
                    if (aog.getAllowOpsWhileDragging()) {
                        aog.setPreDraggingUndoVal(aog.value.value);
                    }
                });
                dragging = true;
            },
            dragEnd: function (newAttributeValuesToValidate) {
                if (newAttributeValuesToValidate) {
                    if (newAttributeValuesToValidate.length > 1) {

                        //var aog1 = getAttributeGroup(newAttributeValuesToValidate[0].dragObjInfo.path);

                        //aog1.enableUndoOpCreation();

                        //var aog2 = getAttributeGroup(newAttributeValuesToValidate[1].dragObjInfo.path);
                        //undoHandler.newLinkedChangeValueOp(newAttributeValuesToValidate[0].dragObjInfo.path, newAttributeValuesToValidate[1].dragObjInfo.path, aog2.getUndoVal(), newValue);
                        //aog2.enableUndoOpCreation();

                        var aogCounter = 0;
                        newAttributeValuesToValidate.forEach(function (dragObjInfo) {
                            aogCounter++;
                            var aog = getAttributeGroup(dragObjInfo.path);
                            notifyGraphicsManagerOnValidationFail = true;

                            if (dragObjInfo.switchThreshold) {
                                aog.enableUndoOpCreation();
                                //aog.value(dragObjInfo.switchOn);
                            } else {
                                var undoVal = aog.getUndoVal();
                                var valueAdjustment = dragObjInfo.valueAdjustment;

                                aog.disableUndoOpCreation();
                                aog.validate(dragObjInfo.value + (dragObjInfo.valueAdjustment / activeMeasurementSystem.value.lengthConversion));
                                aog.enableUndoOpCreation();
                                aog.preventUndoValueUpdateWhileDragging(false);
                                if (aogCounter === 1) {
                                    let aog1UndoVal = undoVal;
                                    let aog1Value = dragObjInfo.value;
                                    let aog1ValueAdjustment = valueAdjustment;
                                    let aog1Path = dragObjInfo.path;
                                    setTimeout(function () {
                                        undoHandler.newChangeValueOp(aog1Path, aog1UndoVal, aog1Value + (aog1ValueAdjustment / activeMeasurementSystem.value.lengthConversion)).addIsFromDraggingFlag();
                                    }, 50);
                                } else {
                                    let aog2UndoVal = undoVal;
                                    let aog2Value = dragObjInfo.value;
                                    let aog2ValueAdjustment = valueAdjustment;
                                    let aog2Path = dragObjInfo.path;
                                    setTimeout(function () {
                                        undoHandler.newLinkedChangeValueOp(newAttributeValuesToValidate[aogCounter - 2].path, aog2Path, aog2UndoVal, aog2Value + (aog2ValueAdjustment / activeMeasurementSystem.value.lengthConversion)).addIsFromDraggingFlag();
                                    }, 50);

                                }
                                
                            }


                        });

                    } else {
                        newAttributeValuesToValidate.forEach(function (dragObjInfo) {
                            var aog = getAttributeGroup(dragObjInfo.path);
                            notifyGraphicsManagerOnValidationFail = true;

                            if (dragObjInfo.switchThreshold) {
                                //aog.value(dragObjInfo.switchOn);
                                aog.enableUndoOpCreation();
                            } else {
                                if (aog.getAllowOpsWhileDragging()) {
                                    var undoVal = aog.getPreDraggingUndoVal();
                                    var valueAdjustment = dragObjInfo.valueAdjustment;
                                    if (aog.getAllowOpsWhileDragging()) {
                                        aog.setPreDraggingUndoVal(-1);
                                    }
                                    aog.validate(dragObjInfo.value + (dragObjInfo.valueAdjustment / activeMeasurementSystem.value.lengthConversion));
                                    aog.enableUndoOpCreation();
                                    aog.preventUndoValueUpdateWhileDragging(false);
                                    setTimeout(function () {
                                        undoHandler.newChangeValueOp(dragObjInfo.path, undoVal, dragObjInfo.value + (valueAdjustment / activeMeasurementSystem.value.lengthConversion)).addIsFromDraggingFlag();
                                    }, 50);
                                } else {
                                    aog.enableUndoOpCreation();
                                    aog.validate(dragObjInfo.value + (dragObjInfo.valueAdjustment / activeMeasurementSystem.value.lengthConversion));
                                }


                            }
                            //if (dragObjInfo.path.includes(config.ACCESSORY_TYPES.BODY)) {
                            //    var accessoryHolder = rigOps.getAccessoryHolderFromPath(dragObjInfo.path);

                            //    if (accessoryHolder.getFridge() !== null) {
                            //        var fridgePath = rigOps.getChassisTypeUsingPath(dragObjInfo.path) + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FRIDGE + '.' + config.VALUE_TYPE.CAB_GAP;
                            //        var fridgeCabGapAOG = getAttributeGroup(fridgePath);
                            //        if (!fridgeCabGapAOG.getUndoValSet()) {
                            //            fridgeCabGapAOG.setUndoVal();
                            //        }
                            //        fridgeCabGapAOG.setBypassValidation(true);
                            //        fridgeCabGapAOG.formatted(getUIFormattedLengthValue(fridgeCabGapAOG.value() + dragObjInfo.dragHorizontalDelta));
                            //    }

                            //}
                            //aog.setUndoVal(-1);
                            //aog.setBypassValidation(false);
                        });
                    }


                }
                dragging = false;
            },
            dragMove: function (newAttributeValuesToBypassValidationWith) {
                newAttributeValuesToBypassValidationWith.forEach(function (dragObjInfo) {
                    var aog = getAttributeGroup(dragObjInfo.path);
                    if (dragObjInfo.switchThreshold && !dragObjInfo.isLockedOut) { //currently this only supports raising/dropping axles
                        aog.enableUndoOpCreation();
                        if (dragObjInfo.switchOn === true) {
                            aog.value.value = true;
                        } else {
                            aog.value.value = false;
                        }
                        if (dragObjInfo.path.indexOf(config.VALUE_TYPE.AXLERAISED) !== -1) {
                            var liftingPath = dragObjInfo.path.replace(config.VALUE_TYPE.AXLERAISED, config.VALUE_TYPE.LIFTINGAXLE);
                            var liftingAOG = getAttributeGroup(liftingPath);
                            liftingAOG.permissionObv(liftingAOG.permission(liftingAOG.getPath()));
                        }
                        notifyIntercomAxleRaisedOrDropped(rigOps.getAxleFromPath(dragObjInfo.path));
                        doActionsAfterSuccesfulValidation();
                    } else {
                        if (!dragObjInfo.isLockedOut) {
                            if (!aog.getUndoValSet()) {
                                aog.setUndoVal();
                                //if (aog.getAllowOpsWhileDragging()) {
                                //    aog.setPreDraggingUndoVal(aog.getUndoVal());
                                //}
                            }
                            //aog.setBypassValidation(true);
                            if (aog.getAllowOpsWhileDragging()) {
                                aog.setValidationMode(config.VALIDATION_MODE.ALLOW_OPS);
                                aog.getValidator().updateMainAttrValidationStepType(config.VALIDATION_STEP_TYPES.SKIP_VALIDATION_ALLOW_OPS);
                                aog.preventUndoValueUpdateWhileDragging(true);
                            } else {
                                aog.setValidationMode(config.VALIDATION_MODE.OFF);
                            }
                            aog.formatted.value = getUIFormattedLengthValue(dragObjInfo.dragValue + dragObjInfo.valueAdjustment);
                        }
                    }

                    //if (dragObjInfo.path.includes(config.ACCESSORY_TYPES.BODY)) {
                    //    var accessoryHolder = rigOps.getAccessoryHolderFromPath(dragObjInfo.path);

                    //    if (accessoryHolder.getFridge() !== null) {
                    //        var fridgePath = rigOps.getChassisTypeUsingPath(dragObjInfo.path) + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FRIDGE + '.' + config.VALUE_TYPE.CAB_GAP;
                    //        var fridgeCabGapAOG = getAttributeGroup(fridgePath);
                    //        if (!fridgeCabGapAOG.getUndoValSet()) {
                    //            fridgeCabGapAOG.setUndoVal();
                    //        }
                    //        fridgeCabGapAOG.setBypassValidation(true);
                    //        fridgeCabGapAOG.formatted(getUIFormattedLengthValue(fridgeCabGapAOG.value() + dragObjInfo.dragHorizontalDelta));
                    //    }
                    //    //if (dragObjInfo.path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE)) {
                    //    //    if
                    //    //} else if (dragObjInfo.path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {

                    //    //} else if (dragObjInfo.path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {

                    //    //}
                    //}
                    //generateWarnings();
                    //if (offerManager.getWarnings().getStringTrackerObjectById(config.WARNING_ID.REAR_OVERHANG_EXCEEDED) !== null || offerManager.getWarnings().getStringTrackerObjectById(config.WARNING_ID.VEHICLE_WHEELBASE_THEORETICAL) !== null || offerManager.getWarnings().getStringTrackerObjectById(config.WARNING_ID.OVERALL_LENGTH) !== null) {
                    //    dragObjInfo.dimensionObj.setShowAsWarning(true);
                    //} else {
                    //    dragObjInfo.dimensionObj.setShowAsWarning(false);
                    //}
                });
                doActionsWhileDragging();
            }
        }
    };
}

function getItemDraggableObjectInteractionObject(interactionId, elementId, dragObjects) {
    return {
        id: interactionId,
        elementId: elementId,
        dragInfoObjects: dragObjects,
        callbacks: {
            dragStart: function (newAttributeValuesToBypassValidationWith) {
                /* newAttributeValuesToBypassValidationWith.forEach(function (dragObjInfo) {
                        var aog = getAttributeGroup(dragObjInfo.path);
                        aog.setUndoVal();
                        aog.setBypassValidation(true);
                        //aog.formatted(dragObjInfo.dragValue);
                    });*/
                dragging = true;
            },
            dragEnd: function (newAttributeValuesToValidate) {
                if (newAttributeValuesToValidate) {
                    newAttributeValuesToValidate.forEach(function (dragObjInfo) {
                        var aog = getAttributeGroup(dragObjInfo.path);
                        notifyGraphicsManagerOnValidationFail = true;
                        aog.validate(dragObjInfo.value + (dragObjInfo.valueAdjustment / activeMeasurementSystem.value.lengthConversion));
                    });
                }
                dragging = false;
                if (changeListenerCallback !== undefined) {
                    //changeListenerCallback('Refresh_Table_Data');
                    changeListenerCallback();
                }
            },
            dragMove: function (newAttributeValuesToBypassValidationWith) {
                newAttributeValuesToBypassValidationWith.forEach(function (dragObjInfo) {
                    var aog = getAttributeGroup(dragObjInfo.path);
                    if (!aog.getUndoValSet()) {
                        aog.setUndoVal();
                    }
                    aog.setValidationMode(config.VALIDATION_MODE.OFF);
                    aog.formatted.value = getUIFormattedLengthValue(dragObjInfo.dragValue + dragObjInfo.valueAdjustment);

                });
                //doActionsWhileDragging();
            }
        }
    };
}



function getTrialUserObfuscationTextWithCurrentMassUnit() {
    return config.trailUserObfuscationText + ' ' + activeMeasurementSystem.value.unitMass;
}

function setPreventSettingsUpdate(value) {
    preventSettingsUpdate = value;
}

function setAllowUpdateOfUnsavedChangesOnOffer(value) {
    allowUpdateOfUnsavedChangesOnOffer = value;
}

function getRigOps() {
    return rigOps;
}

// function quickSearchSortFunction(arrayToSort, columns) {
//     var sortValue;

//     function sortOfferDate(a, b, sortType) {
//         var aDate = Number.isNaN(moment(a.OfferDate).toDate().getDate()) !== true ? moment(a.OfferDate) : moment(a.OfferDate.substr(0, 10)),
//             bDate = Number.isNaN(moment(b.OfferDate).toDate().getDate()) !== true ? moment(b.OfferDate) : moment(b.OfferDate.substr(0, 10));

//         switch (sortType) {
//             case config.SORT_OPTIONS.ASC:
//                 return dateComparison(aDate, bDate);
//             case config.SORT_OPTIONS.DESC:
//                 return dateComparison(bDate, aDate);
//         }

//         function dateComparison(firstDate, secondDate) {
//             if (firstDate.isSame(secondDate, 'month')) {
//                 return 0;
//             } else {
//                 if (firstDate.isBefore(secondDate, 'month')) {
//                     return -1;
//                 }
//                 if (firstDate.isSame(secondDate, 'month')) {
//                     return 1;
//                 }
//             }
//         }
//     }

//     return arrayToSort.sort(function (a, b) {
//         if (a.Source === b.Source && a.isSavedOffer === b.isSavedOffer) {
//             if (a.isSavedOffer === true && b.isSavedOffer === true) {
//                 for (var i = 0; i < columns.length; i++) {
//                     var propertyName;
//                     switch (columns[i].itemToSort) {
//                         case 'Description':
//                             propertyName = 'descriptionDisplay';
//                             break;
//                         case 'AxleLayout':
//                             propertyName = 'AxleLayout';
//                             break;
//                         case 'VehicleTypeTranslation':
//                             propertyName = 'VehicleTypeTranslation';
//                             break;
//                         case 'SpecDate':
//                             propertyName = 'SpecDate';
//                             break;
//                         default:
//                             propertyName = columns[i].itemToSort;
//                             break;
//                     }
//                     if (columns[i].sortType() === config.SORT_OPTIONS.ASC) {
//                         if (propertyName === 'AxleLayout') {
//                             if (sortByAxleLayoutWithType(a, b, config.SORT_OPTIONS.ASC) !== 0) {
//                                 return sortByAxleLayoutWithType(a, b, config.SORT_OPTIONS.ASC);
//                             }
//                         } else if (propertyName === 'OfferDate') {
//                             sortValue = sortOfferDate(a, b, config.SORT_OPTIONS.ASC);
//                             if (sortValue !== 0) {
//                                 return sortValue;
//                             }
//                         } else {
//                             if (typeof a[propertyName] === 'string' || typeof b[propertyName] === 'string') {
//                                 if (a[propertyName].toUpperCase() < b[propertyName].toUpperCase()) {
//                                     return -1;
//                                 }
//                                 if (a[propertyName].toUpperCase() > b[propertyName].toUpperCase()) {
//                                     return 1;
//                                 }
//                             } else {
//                                 if (a[propertyName] < b[propertyName]) {
//                                     return -1;
//                                 }
//                                 if (a[propertyName] > b[propertyName]) {
//                                     return 1;
//                                 }
//                             }
//                         }
//                     } else {
//                         if (propertyName === 'AxleLayout') {
//                             if (sortByAxleLayoutWithType(a, b, config.SORT_OPTIONS.DESC) !== 0) {
//                                 return sortByAxleLayoutWithType(a, b, config.SORT_OPTIONS.DESC);
//                             }
//                         } else if (propertyName === 'OfferDate') {
//                             sortValue = sortOfferDate(a, b, config.SORT_OPTIONS.DESC);
//                             if (sortValue !== 0) {
//                                 return sortValue;
//                             }
//                         } else {
//                             if (typeof a[propertyName] === 'string' || typeof b[propertyName] === 'string') {
//                                 if (a[propertyName].toUpperCase() < b[propertyName].toUpperCase()) {
//                                     return 1;
//                                 }
//                                 if (a[propertyName].toUpperCase() > b[propertyName].toUpperCase()) {
//                                     return -1;
//                                 }
//                             } else {
//                                 if (a[propertyName] < b[propertyName]) {
//                                     return 1;
//                                 }
//                                 if (a[propertyName] > b[propertyName]) {
//                                     return -1;
//                                 }
//                             }
//                         }
//                     }
//                 }
//             } else {
//                 for (var i = 0; i < columns.length; i++) {
//                     if (columns[i].sortType() === config.SORT_OPTIONS.ASC) {
//                         if (a[columns[i].itemToSort] !== null && b[columns[i].itemToSort] !== null && a[columns[i].itemToSort] !== undefined && b[columns[i].itemToSort] !== undefined) {
//                             if (columns[i].itemToSort === "AxleLayout") {
//                                 if (sortByAxleLayoutWithType(a, b, config.SORT_OPTIONS.ASC) !== 0) {
//                                     return sortByAxleLayoutWithType(a, b, config.SORT_OPTIONS.ASC);
//                                 }
//                             } else if (columns[i].itemToSort === 'OfferDate') {
//                                 sortValue = sortOfferDate(a, b, config.SORT_OPTIONS.ASC);
//                                 if (sortValue !== 0) {
//                                     return sortValue;
//                                 }
//                             } else {
//                                 if (typeof a[columns[i].itemToSort] === 'string' || typeof b[columns[i].itemToSort] === 'string') {
//                                     if (a[columns[i].itemToSort].toUpperCase() < b[columns[i].itemToSort].toUpperCase()) {
//                                         return -1;
//                                     }
//                                     if (a[columns[i].itemToSort].toUpperCase() > b[columns[i].itemToSort].toUpperCase()) {
//                                         return 1;
//                                     }
//                                 } else {
//                                     if (a[columns[i].itemToSort] < b[columns[i].itemToSort]) {
//                                         return -1;
//                                     }
//                                     if (a[columns[i].itemToSort] > b[columns[i].itemToSort]) {
//                                         return 1;
//                                     }
//                                 }
//                             }
//                         }
//                     } else {
//                         if (a[columns[i].itemToSort] !== null && b[columns[i].itemToSort] !== null && a[columns[i].itemToSort] !== undefined && b[columns[i].itemToSort] !== undefined) {
//                             if (columns[i].itemToSort === 'AxleLayout') {
//                                 if (sortByAxleLayoutWithType(a, b, config.SORT_OPTIONS.DESC) !== 0) {
//                                     return sortByAxleLayoutWithType(a, b, config.SORT_OPTIONS.DESC);
//                                 }
//                             } else if (columns[i].itemToSort === 'OfferDate') {
//                                 sortValue = sortOfferDate(a, b, config.SORT_OPTIONS.DESC);
//                                 if (sortValue !== 0) {
//                                     return sortValue;
//                                 }
//                             } else {
//                                 if (typeof a[columns[i].itemToSort] === 'string' || typeof b[columns[i].itemToSort] === 'string') {
//                                     if (a[columns[i].itemToSort].toUpperCase() < b[columns[i].itemToSort].toUpperCase()) {
//                                         return 1;
//                                     }
//                                     if (a[columns[i].itemToSort].toUpperCase() > b[columns[i].itemToSort].toUpperCase()) {
//                                         return -1;
//                                     }
//                                 } else {
//                                     if (a[columns[i].itemToSort] < b[columns[i].itemToSort]) {
//                                         return 1;
//                                     }
//                                     if (a[columns[i].itemToSort] > b[columns[i].itemToSort]) {
//                                         return -1;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         } else {
//             if (a.Source === undefined || b.Source === undefined) {
//                 return 1;
//             }
//             if (a.Source === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
//                 return 1;
//             }
//             if (b.Source === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
//                 return -1;
//             }
//             if (a.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
//                 return 1;
//             }
//             if (b.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
//                 return -1;
//             }
//             if (a.isSavedOffer) {
//                 return 1;
//             }
//             if (b.isSavedOffer) {
//                 return -1;
//             }
//         }
//         return 0;
//     });
// }

function quickSearchFilterFunction(row, searchTermsArray, filtersArray, showAll) {
    var returnValue = false,
        cultureCode = globals.getActiveCultureCodeForTranslations().toLowerCase();
    if (searchTermsArray.length === 0 || searchTermsArray[0] === "") {
        if (row.Source === config.VEHICLE_SOURCE_TYPES.TEMPLATE || row.isSavedOffer) {
            return false;
        } else {
            return true;
        }
    } else {
        var numberOfMatches = 0;
        var wheelbaseTheoreticalConvertedValueString = row.wheelbaseTheoreticalConvertedValueString();
        for (var i = 0; i < searchTermsArray.length; i++) {
            if (row.isSavedOffer) {
                return false;
                //switch (cultureCode) {
                //    case config.LANGUAGE_PACK.ENGLISH.US.cultureCode.toLowerCase():
                //        if (row.descriptionDisplay.toUpperCase().includes(searchTermsArray[i].toUpperCase()) || row.AxleLayout.toUpperCase().includes(searchTermsArray[i].toUpperCase())) {
                //            numberOfMatches += 1;
                //        }
                //        break;
                //    case config.LANGUAGE_PACK.ENGLISH.AU.cultureCode.toLowerCase():
                //    case config.LANGUAGE_PACK.ENGLISH.SA.cultureCode.toLowerCase():
                //        if (row.descriptionDisplay.toUpperCase().includes(searchTermsArray[i].toUpperCase()) || row.AxleLayout.toUpperCase().includes(searchTermsArray[i].toUpperCase()) || row.ModelCode.toUpperCase().includes(searchTermsArray[i].toUpperCase()) || row.wheelbaseTheoreticalConvertedValueString().toUpperCase().includes(searchTermsArray[i].toUpperCase())) {
                //            numberOfMatches += 1;
                //        }
                //        break;
                //    default:
                //        if (row.descriptionDisplay.toUpperCase().includes(searchTermsArray[i].toUpperCase()) || row.AxleLayout.toUpperCase().includes(searchTermsArray[i].toUpperCase()) || row.wheelbaseTheoreticalConvertedValueString().toUpperCase().includes(searchTermsArray[i].toUpperCase())) {
                //            numberOfMatches += 1;
                //        }
                //        break;
                //}
            } else {
                if (row.Source === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
                    returnValue = false;
                    break;
                } else {
                    //if (row.Description.toUpperCase().includes(searchTermsArray[i].toUpperCase()) || row.AxleLayout.toUpperCase().includes(searchTermsArray[i].toUpperCase())) {
                    //    numberOfMatches += 1;
                    //}
                    switch (cultureCode) {
                        //case 'en-us':
                        case config.LANGUAGE_PACK.ENGLISH.US.cultureCode.toLowerCase():
                            if (row.Description.toUpperCase().includes(searchTermsArray[i].toUpperCase()) || row.AxleLayout.toUpperCase().includes(searchTermsArray[i].toUpperCase())) {
                                numberOfMatches += 1;
                            }
                            break;
                        //case 'en-au':
                        //case 'en-za':
                        case config.LANGUAGE_PACK.ENGLISH.AU.cultureCode.toLowerCase():
                        case config.LANGUAGE_PACK.ENGLISH.NZ.cultureCode.toLowerCase():
                        case config.LANGUAGE_PACK.ENGLISH.SA.cultureCode.toLowerCase():
                            if (row.Description.toUpperCase().includes(searchTermsArray[i].toUpperCase()) || row.AxleLayout.toUpperCase().includes(searchTermsArray[i].toUpperCase()) || row.ModelCode.toUpperCase().includes(searchTermsArray[i].toUpperCase()) || wheelbaseTheoreticalConvertedValueString.toUpperCase().includes(searchTermsArray[i].toUpperCase())) {
                                numberOfMatches += 1;
                            }
                            break;
                        default:
                            if (row.Description.toUpperCase().includes(searchTermsArray[i].toUpperCase()) || row.AxleLayout.toUpperCase().includes(searchTermsArray[i].toUpperCase()) || wheelbaseTheoreticalConvertedValueString.toUpperCase().includes(searchTermsArray[i].toUpperCase())) {
                                numberOfMatches += 1;
                            }
                            break;
                    }
                }
                //returnValue = row.Description.toUpperCase().includes(searchTermsArray[i].toUpperCase());
                //if (!row.Description.toUpperCase().includes(searchTermsArray[i].toUpperCase())) {
                //    break;
                //}
            }
        }
        returnValue = numberOfMatches === searchTermsArray.length;
    }
    return returnValue;
}

function addComputedsToVehicles(vehiclesArray) {
    vehiclesArray.forEach(function (vehicle) {
        if ((vehicle.rowType && vehicle.rowType === config.ROW_TYPE.OFFER) || !vehicle.rowType) {
            vehicle.gvwFormatted = shallowRef();
            vehicle.payloadFormatted = shallowRef();
            vehicle.gcwFormatted = shallowRef();
            vehicle.wheelbaseFormatted = shallowRef();
            vehicle.cabToAxlesFormatted = shallowRef();

            if (refreshSWLabels !== null) {
                vehicle.refreshSWLabelsSubscriptionRef = watch(refreshSWLabels, function () {
                    vehicle.updateRowVals();
                });
            }
            vehicle.updateRowVals = function () {
                if(vehicle.gvwFormatted !== null) {
                    vehicle.gvwFormatted.value = (function () {
                        var measurementSystem = globals.user.getActiveMeasurementSystem();
    
                        if (vehicle.GVW === 0) {
                            return '-';
                        } else {
                            return Globalize.format(vehicle.GVW * measurementSystem.massConversion, "n0") + " " + measurementSystem.unitMass; // User Story 17565
                        }
                    })();
                }
                if(vehicle.payloadFormatted !== null) {
                    vehicle.payloadFormatted.value = (function () {
                        var measurementSystem = globals.user.getActiveMeasurementSystem();
                        return Globalize.format(vehicle.Payload * measurementSystem.massConversion, "n0") + " " + measurementSystem.unitMass; // User Story 17565
                    })();
                }
                if(vehicle.gcwFormatted !== null) {
                    vehicle.gcwFormatted.value = (function () {
                        var measurementSystem = globals.user.getActiveMeasurementSystem();
                        if (vehicle.GCW === 0) {
                            return '-';
                        } else {
                            return Globalize.format(vehicle.GCW * measurementSystem.massConversion, "n0") + " " + measurementSystem.unitMass; // User Story 17565
                        }
                    })();
                }
                
                if(vehicle.wheelbaseFormatted !== null) {
                    vehicle.wheelbaseFormatted.value = (function () {
                        var measurementSystem = globals.user.getActiveMeasurementSystem(),
                            wheelbaseToUse = vehicle.WheelbaseTheoretical;
    
                        switch (globals.user.getSpecifyWheelbaseAs()) {
                            case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTER_OF_REAR_DRIVEN_AXLES:
                                wheelbaseToUse = vehicle.WheelbaseFMSACRDA;
                                break;
                            case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTRE_OF_REAR_AXLES:
                                wheelbaseToUse = vehicle.WheelbaseFMSACRA;
                                break;
                            case config.SPECIFY_WHEELBASE_AS_OPTIONS.CENTRE_OF_FRONT_AXLES_TO_CENTRE_OF_REAR_AXLES:
                                wheelbaseToUse = vehicle.WheelbaseCFACRA;
                                break;
                            case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_FIRST_REAR_DRIVEN_AXLE:
                                wheelbaseToUse = vehicle.WheelbaseFMSAFRDA;
                                break;
                            default:
                                wheelbaseToUse = vehicle.WheelbaseTheoretical;
                                break;
                        }
                        if (wheelbaseToUse === 0) {
                            return '-';
                        } else {
                            return Globalize.format(wheelbaseToUse / measurementSystem.lengthConversion, "n" + measurementSystem.lengthPrecision) + " " + measurementSystem.unitLength; // User Story 17565
                        }
                    })();
                }
                
                if(vehicle.cabToAxlesFormatted !== null) {
                    vehicle.cabToAxlesFormatted.value = (function () {
                        var measurementSystem = globals.user.getActiveMeasurementSystem(),
                            cabToAxlesToUse = 0;
    
                        switch (globals.user.getSpecifyWheelbaseAs()) {
                            case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTER_OF_REAR_DRIVEN_AXLES:
                                cabToAxlesToUse = vehicle.CabToAxlesFMSACRDA;
                                break;
                            case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTRE_OF_REAR_AXLES:
                                cabToAxlesToUse = vehicle.CabToAxlesFMSACRA;
                                break;
                            case config.SPECIFY_WHEELBASE_AS_OPTIONS.CENTRE_OF_FRONT_AXLES_TO_CENTRE_OF_REAR_AXLES:
                                cabToAxlesToUse = vehicle.CabToAxlesCFACRA;
                                break;
                            case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_FIRST_REAR_DRIVEN_AXLE:
                                cabToAxlesToUse = vehicle.CabToAxlesFMSAFRDA;
                                break;
                            default:
                                cabToAxlesToUse = 0;
                                break;
                        }
                        if (cabToAxlesToUse === 0) {
                            return '-';
                        } else {
                            return Globalize.format(cabToAxlesToUse / measurementSystem.lengthConversion, "n" + measurementSystem.lengthPrecision) + " " + measurementSystem.unitLength; // User Story 17565
                        }
                    })();
                }
            }
            vehicle.updateRowVals();
            vehicle.wheelbaseTheoreticalConvertedValueString = function () {
                //var measurementSystem = globals.user.getActiveMeasurementSystem();
                var lengthConversion = globals.user.getActiveMeasurementSystem().lengthConversion;
                return parseInt(Math.round(vehicle.WheelbaseTheoretical / lengthConversion)).toString();
            };
            if (vehicle.isSavedOffer === undefined) {
                vehicle.isSavedOffer = false;
            }
            if (vehicle.isSharedOffer === undefined) {
                vehicle.isSharedOffer = false;
            }
            vehicle.rowType === config.ROW_TYPE.OFFER;

            vehicle.imageAvailable = shallowRef(true);
            if (vehicle.OfferCached === undefined) {
                //vehiclesArray[i].OfferCached = shallowRef(0);
                vehicle.OfferCached = 0;
            }
            vehicle.ShowAsUnavailable = getCachedOrNotComputed(vehicle.OfferCached);
        } else if (vehicle.rowType && vehicle.rowType === config.ROW_TYPE.VIRTUAL_FOLDER) {
            vehicle.isSavedOffer = false;
            vehicle.isSharedOffer = false;
            vehicle.rowType === config.ROW_TYPE.VIRTUAL_FOLDER;
        } else {
            vehicle.isSavedOffer = false;
            vehicle.isSharedOffer = false;
            vehicle.rowType === config.ROW_TYPE.FOLDER;
        }
        vehicle.isSelected = shallowRef(false);
        if (vehicle.folderPath === undefined) {
            vehicle.folderPath = '';
        }
    });

    return vehiclesArray;
}

function getCachedOrNotComputed(offerCached) {

    return computed(function () {
        return ((globals.isOnline.value === false) && (offerCached !== 2));
    });
}

function routeToNextModule() {
    if (offerManager.getActiveOffer().getConfiguration() !== null && offerManager.getActiveOffer().getConfiguration() !== undefined && globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION.Code) && router.hasRoute(ROUTES.CONFIGURATION.name) && offerManager.getActiveOffer().isModuleAvailableOnOffer(ROUTES.CONFIGURATION.meta.dataAvailabilityCode)) {
        router.push(ROUTES.CONFIGURATION.name);
    } else if (offerManager.getActiveOffer().getPerformance() !== null && offerManager.getActiveOffer().getPerformance() !== undefined && globals.user.hasPermission(config.PERMISSIONS.PERFORMANCE.Code) && router.hasRoute(ROUTES.PERFORMANCE.name) && offerManager.getActiveOffer().isModuleAvailableOnOffer(ROUTES.PERFORMANCE.meta.dataAvailabilityCode)) {
        router.push(ROUTES.PERFORMANCE.name);
    } else if (offerManager.getActiveOffer().getCosting() !== null && offerManager.getActiveOffer().getCosting() !== undefined && globals.user.hasPermission(config.PERMISSIONS.COSTING.Code) && router.hasRoute(ROUTES.COSTING.name) && offerManager.getActiveOffer().isModuleAvailableOnOffer(ROUTES.COSTING.meta.dataAvailabilityCode)) {
        router.push(ROUTES.COSTING.name);
    } else if (offerManager.getActiveOffer().getSpecification() !== null && offerManager.getActiveOffer().getSpecification() !== undefined && globals.user.hasPermission(config.PERMISSIONS.SPECIFICATION.Code) && router.hasRoute(ROUTES.SPECIFICATION.name) && offerManager.getActiveOffer().isModuleAvailableOnOffer(ROUTES.SPECIFICATION.meta.dataAvailabilityCode)) {
        router.push(ROUTES.SPECIFICATION.name);
    } else {
        if (router.hasRoute(ROUTES.SUMMARY.name)) {
            router.push(ROUTES.SUMMARY.name);
        } else {
            globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')));
        }

    }
}

function updateRoutesWhenOpeningOffer() {
    var routesArray = [];
    utils.removeAllRoutes();
    routesArray = utils.generateRoutesBasedOnPermissionsAndDataAvailability(routesArray, globals.user.getPermissions(), getActiveOffer().getDataAvailability());
    utils.addRoutes(routesArray);
}

function getLegislationSubstitutedForSharedCalc() {
    return legislationSubstitutedForSharedCalc;
}
function getDefaultLegislationUsedDuToMissingLegislation() {
    return defaultLegislationUsedDuToMissingLegislation;
}

function getSharedOfferShareeUserId() {
    return shareeUserId;
}

function getSharedOfferCustomerId() {
    return sharedOfferCustomerId;
}

function getSendIntercomSharedOfferAcceptedEvent() {
    return sendIntercomSharedOfferAcceptedEvent;
}

function resetSendIntercomSharedOfferAcceptedEvent() {
    sendIntercomSharedOfferAcceptedEvent = null;
}

function getActiveConfigurationWizardItem() {
    return activeConfigurationWizardItem;
}

function getActiveConfigurationWizardItemSize() {
    return activeConfigurationWizardItemSize;
}

function getOriginalConfigurationWizardItem() {
    return originalConfigurationWizardItem;
}

function getOriginalConfigurationWizardItemSize() {
    return originalConfigurationWizardItemSize;
}

function setConfigurationWizardItems(configurationWizardItem, originalItem) {
    activeConfigurationWizardItem = configurationWizardItem;
    originalConfigurationWizardItem = originalItem;
}

function undo() {
    if (undoHandler !== null) {
        undoHandler.undo();
    }
}

function redo() {
    if (undoHandler !== null) {
        undoHandler.redo();
    }
}

function invalidatePdfAndDrawing() {
    validPdfOnLocalStorage.value = false;
    setConfigDrawing(null);
    dataManager.remove(config.OPERATION.ConfigDrawing, vehicleId.value + "_configurationDrawing");
}

function removeObjectFromRig(path, objectInternalId) {
    bus.emit('removeRigObject', { path: path, objectInternalId: objectInternalId });
}

function notifyUndoRedoListeners(op, opContext) {
    if (undoRedoListeners.length > 0) {
        undoRedoListeners.forEach(function (listenerCallback) {
            try {
                listenerCallback(op, opContext);
            } catch (listenerCallbackEx) {
                TScMessenger.writeDebugMessage('OfferManager.js, notifyUndoRedoListeners, catch, error when trying to notify undo/redo listener');
                
            }
        });
    }
}

//function handleUndoRedo(op, opContext) {
//    if (op.getType() === config.CALCULATION_OP_TYPES.COMPOUND_CHANGE_VALUE) {

//        if (opContext === 'UNDO') {
//            applyCostingDetailValues(op.getUndoValuesObject());
//        } else {
//            applyCostingDetailValues(op.getRedoValuesObject());
//        }
//        refreshData(new Date().getTime());
//    }
//}

function applyCostingDetailValues(values) {
    Object.keys(values).forEach(function (key) {
        if (values[key].value !== undefined) {
            values[key].setter(values[key].value);
        } else {
            applyCostingDetailValues(values[key]);
        }
    });
}

// function getFirstMatchingVehicleStubForVehicleCode(vehicleModelCode) {
//     let dfd = $.Deferred();
//     dataManager.getSelectionList()
//         .then(function (success) {
            
//             let vehicleStubs = success.selection.Offers.slice(0);
//             let returnCode = success.selection.Result.ReturnCode;
//             let matchedVehicles = vehicleStubs.filter(function (vehicleStub){
//                 return vehicleStub.ModelCode === vehicleModelCode;
//             });
//             if(matchedVehicles.length > 1) {
//                 matchedVehicles.sort(function (a,b) {
//                     return new Date(b.SpecDate) - new Date(a.SpecDate);
//                 });
//                 matchedVehicles.sort(function (a, b) {
//                     let vehicleAStatus = a.Status.toLowerCase();
//                     let vehicleBStatus = b.Status.toLowerCase();
//                     if(vehicleAStatus < vehicleBStatus) {
//                         return -1;
//                     }
//                     if(vehicleAStatus > vehicleBStatus) {
//                         return 1;
//                     }
//                     return 0;
//                 });
//                 dfd.resolve(matchedVehicles[0]);
//             }else if(matchedVehicles.length === 1) {
//                 dfd.resolve(matchedVehicles[0]);
//             } else {
//                 dfd.resolve(null);
//             }
            
//         })
//         .fail(function (error) {
//             dfd.reject(error);
//         });

//         return dfd.promise();
// }

function getFirstMatchingVehicleStubForSpecDataPoints(specDataPoints) {
    let dfd = $.Deferred();
    dataManager.getSelectionList()
        .then(function (success) {
            
            let vehicleStubs = success.selection.Offers.slice(0);
            let returnCode = success.selection.Result.ReturnCode;

            let bodyCodeInterfaceAttribute = globals.user.getCompanyInterfaceAttributeByName('bodyCode');
            let bodyCodeForMatch = returnValueOrMappedValue(specDataPoints, bodyCodeInterfaceAttribute || 'bodyCode');

            let wheelbaseCodeInterfaceAttribute = globals.user.getCompanyInterfaceAttributeByName('wheelbaseCode');
            let wheelbaseCodeForMatch = returnValueOrMappedValue(specDataPoints, wheelbaseCodeInterfaceAttribute || 'wheelbaseCode');

            let modelYearInterfaceAttribute = globals.user.getCompanyInterfaceAttributeByName('modelYear');
            let modelYearForMatch = returnValueOrMappedValue(specDataPoints, modelYearInterfaceAttribute || 'modelYear');

            let brakeSystemCodeInterfaceAttribute = globals.user.getCompanyInterfaceAttributeByName('brakeSystemCode');
            let brakeSystemCodeForMatch = returnValueOrMappedValue(specDataPoints, brakeSystemCodeInterfaceAttribute || 'brakeSystemCode');

            let engineCodeInterfaceAttribute = globals.user.getCompanyInterfaceAttributeByName('engineCode');
            let engineCodeForMatch = returnValueOrMappedValue(specDataPoints, engineCodeInterfaceAttribute || 'engineCode');

            let gVWRInterfaceAttribute = globals.user.getCompanyInterfaceAttributeByName('gvwr');
            let gVWRForMatch = returnValueOrMappedValue(specDataPoints, gVWRInterfaceAttribute || 'gvwr');

            let matchedVehicles = vehicleStubs.filter(function (vehicleStub){
                if(vehicleStub.BrakeSystemType !== null && vehicleStub.BrakeSystemType !== undefined && vehicleStub.BrakeSystemType !== '') {
                    return vehicleStub.BodyCode === bodyCodeForMatch && 
                    vehicleStub.WheelbaseCode === wheelbaseCodeForMatch && 
                    vehicleStub.ModelYear === modelYearForMatch &&
                    vehicleStub.BrakeSystemType === brakeSystemCodeForMatch;
                } else {
                    return vehicleStub.BodyCode === bodyCodeForMatch && 
                    vehicleStub.WheelbaseCode === wheelbaseCodeForMatch && 
                    vehicleStub.ModelYear === modelYearForMatch;
                }

                
            });
            if(matchedVehicles.length > 1) {
                //search remaining vehicle based on engineCode and possibly other values such as gvm/gcm - might need to prioritise the order
                //that level 2 searches happen in
                if(engineCodeForMatch) {
                    let matchedVehicles2 = matchedVehicles.filter(function (vehicleStub){
                        return vehicleStub.EngineCode === engineCodeForMatch;
                    });
                    if(matchedVehicles2.length > 1) {
                        //search remaining vehicle based on engineCode and possibly other values such as gvm/gcm - might need to prioritise the order
                        //that level 2 searches happen in
                        if(gVWRForMatch) {
                            let matchedVehicles3 = matchedVehicles2.filter(function (vehicleStub){
                                return vehicleStub.GVW === gVWRForMatch;
                            });
                            if(matchedVehicles3.length > 1) {
                                dfd.resolve(null);     
                            }else if(matchedVehicles3.length === 1) {
                                dfd.resolve(matchedVehicles3[0]);
                            } else {
                                dfd.resolve(null);
                            }
                        } else {
                            dfd.resolve(null);
                        }
                    }else if(matchedVehicles2.length === 1) {
                        dfd.resolve(matchedVehicles2[0]);
                    } else {
                        dfd.resolve(null);
                    }
                } else {
                    dfd.resolve(null);
                }



                // matchedVehicles.sort(function (a,b) {
                //     return new Date(b.SpecDate) - new Date(a.SpecDate);
                // });
                // matchedVehicles.sort(function (a, b) {
                //     let vehicleAStatus = a.Status.toLowerCase();
                //     let vehicleBStatus = b.Status.toLowerCase();
                //     if(vehicleAStatus < vehicleBStatus) {
                //         return -1;
                //     }
                //     if(vehicleAStatus > vehicleBStatus) {
                //         return 1;
                //     }
                //     return 0;
                // });
                // dfd.resolve(matchedVehicles[0]);
            }else if(matchedVehicles.length === 1) {
                dfd.resolve(matchedVehicles[0]);
            } else {
                dfd.resolve(null);
            }
            
        })
        .fail(function (error) {
            dfd.reject(error);
        });

        return dfd.promise();
}

offerManager = {
    openNewOffer: openNewOffer,
    openNewOfferSpecification: openNewOfferSpecification,
    closeOffer: closeOffer,
    offerIsOpen: offerIsOpen,
    saveOffer: saveOffer,
    currencySymbolMajor: currencySymbolMajor,
    totalCapitalCost: totalCapitalCost,
    updateCapitalCosts: updateCapitalCosts,
    optionPrice: optionPrice,
    specificationFetchComplete: specificationFetchComplete,
    specificationFetchSuccessful: specificationFetchSuccessful,
    selectedCustomer: selectedCustomer,
    containsUnsavedChanges: containsUnsavedChanges,
    getOfferDetailsToBeSaved: getOfferDetailsToBeSaved,
    openExistingOffer: openExistingOffer,
    setSpecificationVehicles: setSpecificationVehicles,
    customerAttachedToOffer: customerAttachedToOffer,
    unsavedChangesOnOffer: unsavedChangesOnOffer,
    availableCustomersSelected: availableCustomersSelected,
    getUIFormattedDensityValue: getUIFormattedDensityValue,
    //getCustomers: getCustomers,
    customerList: customerList,
    customerNamesList: customerNamesList,
    isReadOnly: isReadOnly,
    validPdfOnLocalStorage: validPdfOnLocalStorage,
    pdfNoOfPages: pdfNoOfPages,
    summaryNote: summaryNote,
    cached: cached,
    getActiveOffer: getActiveOffer,
    getOriginalOffer: getOriginalOffer,
    getBaseOffer: getBaseOffer,
    activeCabColour: activeCabColour,
    createCustomer: createCustomer,
    registerChangeListener: registerChangeListener,
    registerObjectAddedListener: registerObjectAddedListener,
    registerWaypointAddedListener: registerWaypointAddedListener,
    registerAddToLocalStorageListener: registerAddToLocalStorageListener,
    registerCreateBlobForDownload: registerCreateBlobForDownload,
    registerGraphicsManagerCleanUpCallback: registerGraphicsManagerCleanUpCallback,
    registerClearOfferCallback: registerClearOfferCallback,
    registerConfigurationActionFailedCallbackListener: registerConfigurationActionFailedCallbackListener,
    registerConfigurationActionResponseHandler: registerConfigurationActionResponseHandler,
    registerValidationCallbackListener: registerValidationCallbackListener,
    registerPostCompleteSwapFunction: registerPostCompleteSwapFunction,
    removeValidationCallbackListener: removeValidationCallbackListener,
    registerRenderingCompleteCallbackListener: registerRenderingCompleteCallbackListener,
    removeRenderingCompleteCallbackListener: removeRenderingCompleteCallbackListener,
    registerUndoRedoCallbackListener: registerUndoRedoCallbackListener,
    removeUndoRedoCallbackListener: removeUndoRedoCallbackListener,
    getRenderingCompleteCallbackListeners: getRenderingCompleteCallbackListeners,
    registerSyncCallbackListener: registerSyncCallbackListener,
    getUIFormattedLengthValue: getUIFormattedLengthValue,
    getUIBridgeFormattedLengthValue: getUIBridgeFormattedLengthValue,
    getCurMeasurementSysRoundedLength: getCurMeasurementSysRoundedLength,
    getUIFormattedMassValue: getUIFormattedMassValue,
    getUIFormattedMassValueIgnoreMeasurementSystem: getUIFormattedMassValueIgnoreMeasurementSystem,
    getUIFormattedDimensionValue: getUIFormattedDimensionValue,
    getUIBridgeFormattedDimensionValue: getUIBridgeFormattedDimensionValue,
    getUIBridgeNearestFormattedLengthValue: getUIBridgeNearestFormattedLengthValue,
    getUIFormattedPercentageValue: getUIFormattedPercentageValue,
    getUIFormattedPercentageIncrementValue: getUIFormattedPercentageIncrementValue,
    getUIFormattedLinearDensityValue: getUIFormattedLinearDensityValue,
    getUIFormattedLinearDensityValueNoUnit: getUIFormattedLinearDensityValueNoUnit,
    getUIFormattedVolumeValue: getUIFormattedVolumeValue,
    getUIFormattedNotConvertedLengthValue: getUIFormattedNotConvertedLengthValue,
    getUIFormattedNotConvertedMassValue: getUIFormattedNotConvertedMassValue,
    getUIFormattedDegreeValue: getUIFormattedDegreeValue,
    getUIFormattedMonthlyDistanceValue: getUIFormattedMonthlyDistanceValue,
    getUIFormattedMonthsValue: getUIFormattedMonthsValue,
    getUIFormattedValuePerMonthValue: getUIFormattedValuePerMonthValue,
    getUIFormattedTripsPerMonthValue: getUIFormattedTripsPerMonthValue,
    getUIFormattedCurrencyPerVolumeValue: getUIFormattedCurrencyPerVolumeValue,
    getUIFormattedHighCurrencyValue: getUIFormattedHighCurrencyValue,
    getUIFormattedMediumCurrencyValue: getUIFormattedMediumCurrencyValue,
    getUIFormattedCurrencyTotalPrice: getUIFormattedCurrencyTotalPrice,
    getUIFormattedCurrencyPerMonthValue: getUIFormattedCurrencyPerMonthValue,
    getUIFormattedCurrencyPerTripValue: getUIFormattedCurrencyPerTripValue,
    getUIFormattedPercentageOfTotalPrice: getUIFormattedPercentageOfTotalPrice,
    getUIFormattedPercentageSinglePlaceValue: getUIFormattedPercentageSinglePlaceValue,
    getUIFormattedPercentageDoublePlaceValue: getUIFormattedPercentageDoublePlaceValue,
    getUIFormattedCurrencyPerYearValue: getUIFormattedCurrencyPerYearValue,
    getUIFormattedPercentageOfFixedCostValue: getUIFormattedPercentageOfFixedCostValue,
    getUIFormattedVolumePer100KMsValue: getUIFormattedVolumePer100KMsValue,
    getUIFormattedCurrencyPerDistanceValue: getUIFormattedCurrencyPerDistanceValue,
    getUIFormattedMinorCurrencyPerDistanceValue: getUIFormattedMinorCurrencyPerDistanceValue,
    getUIFormattedCurrencySinglePlaceValue: getUIFormattedCurrencySinglePlaceValue,
    getUIFormattedCurrencyNoDecimalPlaceValue: getUIFormattedCurrencyNoDecimalPlaceValue,
    getUIFormattedAreaValue: getUIFormattedAreaValue,
    getUIFormattedSpeedValue: getUIFormattedSpeedValue,
    getUIFormattedDistanceValue: getUIFormattedDistanceValue,
    getUIFormattedDistanceNoDecimalValue: getUIFormattedDistanceNoDecimalValue,
    getUIFormattedMinorMassPerDistanceValue: getUIFormattedMinorMassPerDistanceValue,
    getUIFormattedVolumePerDistanceValue: getUIFormattedVolumePerDistanceValue,
    getUIFormattedFuelConsumptionValue: getUIFormattedFuelConsumptionValue,
    getUIFormattedFuelConsumptionValueNoConversion: getUIFormattedFuelConsumptionValueNoConversion,
    getUIFormattedFuelConsumptionValueNoConversionForSlider: getUIFormattedFuelConsumptionValueNoConversionForSlider,
    getUIFormattedAdblueConsumptionValue: getUIFormattedAdblueConsumptionValue,
    getUIFormattedAdblueConsumptionValueNoConversion: getUIFormattedAdblueConsumptionValueNoConversion,
    getUIFormattedPercentageOfFuelValue: getUIFormattedPercentageOfFuelValue,
    getUIFormattedHoursPerMonthValue: getUIFormattedHoursPerMonthValue,
    getUIFormattedVolumePerHourValue: getUIFormattedVolumePerHourValue,
    getCustomPrecisionRoundedValue: getCustomPrecisionRoundedValue,
    getCurMeasurementSysRoundedPercentage: getCurMeasurementSysRoundedPercentage,
    getCurMeasurementSysRoundedMass: getCurMeasurementSysRoundedMass,
    getValue: getValue,
    getTableCellHighlightColour: getTableCellHighlightColour,
    getAvailableVehicleApplicationTypes: getAvailableVehicleApplicationTypes,
    setSpinnerCallback: setSpinnerCallback,
    updateSelectedCustomer: updateSelectedCustomer,
    getYesNoNotSpecifiedOptions: getYesNoNotSpecifiedOptions,
    getYesNoOptions: getYesNoOptions,
    getOfferedAsOptions: getOfferedAsOptions,
    //#region observables
    //offer
    id: id,
    updateCounter: updateCounter,
    vehicleId: vehicleId,
    vehicleName: vehicleName,
    vehicleStatus: vehicleStatus,
    vehicleModel: vehicleModel,
    vehicleMake: vehicleMake,
    vehicleTitleComputed: vehicleTitleComputed,
    vehicleDescriptionComputed: vehicleDescriptionComputed,
    vehicleRange: vehicleRange,
    vehicleGVW: vehicleGVW,
    vehiclePayload: vehiclePayload,
    vehiclePower: vehiclePower,
    vehicleAxleLayout: vehicleAxleLayout,
    vehiclePlusBodyImageURL: vehiclePlusBodyImageURL,
    bodyType: bodyType,
    bodyManufacturer: bodyManufacturer,
    vehicleDistributionGroupId: vehicleDistributionGroupId,
    vehicleDistributionOptionId: vehicleDistributionOptionId,
    evaluateLegislation: evaluateLegislation,
    isActiveOfferOpen: isActiveOfferOpen,
    //incrementObservable: incrementObservable,
    //decrementObservable: decrementObservable,
    // offer properties
    activeLegislation: activeLegislation,
    //activeLegislationId: activeLegislationId,
    activeMeasurementSystem: activeMeasurementSystem,
    //activeMeasurementSystemId: activeMeasurementSystemId,
    //lengthIncrement: lengthIncrement,
    //lengthIncrementFormatted: lengthIncrementFormatted,
    //massIncrement: massIncrement,
    //massIncrementFormatted: massIncrementFormatted,
    //percentageIncrement: percentageIncrement,
    //percentageIncrementFormatted: percentageIncrementFormatted,
    activeApplication: activeApplication,
    //activeApplicationId: activeApplicationId,
    //additionalNote: additionalNote,
    description: description,
    //additionalDescription: additionalDescription,
    //setActiveLegislationUsingId: setActiveLegislationUsingId,
    setActiveApplicationUsingId: setActiveApplicationUsingId,
    setActiveMeasurementSystemUsingId: setActiveMeasurementSystemUsingId,
    //configuration
    getConfiguration: getConfiguration,
    activeConfigurationViewDetail: activeConfigurationViewDetail,
    activeConfigurationViewType: activeConfigurationViewType,
    activeConfigurationElevation: activeConfigurationElevation,
    activeConfigurationDisplay: activeConfigurationDisplay,
    errorMessage: errorMessage,
    getFuelTypeOptions: getFuelTypeOptions,
    getSortedFuelTypeOptions: getSortedFuelTypeOptions,
    //createFuelTankArrayForMenu: createFuelTankArrayForMenu,
    //deleteFuelTankFromMenu: deleteFuelTankFromMenu,
    //fuelSpecificGravityFormatted: fuelSpecificGravityFormatted,
    //costing
    //noOfUnits: noOfUnits,
    //noOfUnitsMin: noOfUnitsMin,
    //noOfUnitsMax: noOfUnitsMax,
    //noOfUnitsIncrement: noOfUnitsIncrement,
    //fuelCost: fuelCost,
    //fuelCostMin: fuelCostMin,
    //fuelCostMax: fuelCostMax,
    //fuelCostIncrement: fuelCostIncrement,
    //vehiclePrice: vehiclePrice,
    //vehiclePriceMin: vehiclePriceMin,
    //vehiclePriceMax: vehiclePriceMax,
    //vehiclePriceIncrement: vehiclePriceIncrement,
    //vehiclePriceMinActual: vehiclePriceMinActual,
    //vehiclePriceMaxActual: vehiclePriceMaxActual,
    //vehiclePriceTextInput: vehiclePriceTextInput,
    //bodyAndEquipmentPrice: bodyAndEquipmentPrice,
    //bodyAndEquipmentPriceMin: bodyAndEquipmentPriceMin,
    //bodyAndEquipmentPriceMax: bodyAndEquipmentPriceMax,
    //bodyAndEquipmentPriceIncrement: bodyAndEquipmentPriceIncrement,
    //bodyAndEquipmentPriceMinActual: bodyAndEquipmentPriceMinActual,
    //bodyAndEquipmentPriceMaxActual: bodyAndEquipmentPriceMaxActual,
    //optionPrice: optionPrice,
    //optionPriceMin: optionPriceMin,
    //optionPriceMax: optionPriceMax,
    //optionPriceIncrement: optionPriceIncrement,
    //monthlyDistance: monthlyDistance,
    //monthlyDistanceMin: monthlyDistanceMin,
    //monthlyDistanceMax: monthlyDistanceMax,
    //monthlyDistanceConversion: monthlyDistanceConversion,
    //monthlyDistanceMinConversion: monthlyDistanceMinConversion,
    //monthlyDistanceMaxConversion: monthlyDistanceMaxConversion,
    //monthlyDistanceIncrement: monthlyDistanceIncrement,
    //monthlyDistanceIncrementConversion: monthlyDistanceIncrementConversion,
    //getMetricDistanceValue: getMetricDistanceValue,
    //convertDistanceValueToMetric: convertDistanceValueToMetric,
    //convertVolumeValueToMetric: convertVolumeValueToMetric,
    //getMetricDistanceValueReverse: getMetricDistanceValueReverse,
    //getMetricVolumeValueReverse: getMetricVolumeValueReverse,
    //getFuelConsumptionValueConversion: getFuelConsumptionValueConversion,
    //getFuelConsumptionValueConversionToMetric: getFuelConsumptionValueConversionToMetric,
    //getFuelCostValueConversionToMetric: getFuelCostValueConversionToMetric,
    //getMaintenanceRateValueConversionToMetric: getMaintenanceRateValueConversionToMetric,
    //fuelConsumption: fuelConsumption,
    //fuelConsumptionMin: fuelConsumptionMin,
    //fuelConsumptionMax: fuelConsumptionMax,
    //fuelConsumptionIncrement: fuelConsumptionIncrement,
    //fuelConsumptionConversion: fuelConsumptionConversion,
    //fuelConsumptionMinConversion: fuelConsumptionMinConversion,
    //fuelConsumptionMaxConversion: fuelConsumptionMaxConversion,
    //fuelConsumptionIncrementConversion: fuelConsumptionIncrementConversion,
    //maintenanceRate: maintenanceRate,
    //maintenanceRateMin: maintenanceRateMin,
    //maintenanceRateMax: maintenanceRateMax,
    //maintenanceRateIncrement: maintenanceRateIncrement,
    //maintenanceRateConversion: maintenanceRateConversion,
    //maintenanceRateMinConversion: maintenanceRateMinConversion,
    //maintenanceRateMaxConversion: maintenanceRateMaxConversion,
    //maintenanceRateIncrementConversion: maintenanceRateIncrementConversion,
    //depositRate: depositRate,
    //depositRateMin: depositRateMin,
    //depositRateMax: depositRateMax,
    //depositRateIncrement: depositRateIncrement,
    //residualRate: residualRate,
    //residualRateMin: residualRateMin,
    //residualRateMax: residualRateMax,
    //residualRateIncrement: residualRateIncrement,
    //interestRate: interestRate,
    //interestRateMin: interestRateMin,
    //interestRateMax: interestRateMax,
    //interestRateIncrement: interestRateIncrement,
    //repaymentTerm: repaymentTerm,
    //repaymentTermMin: repaymentTermMin,
    //repaymentTermMax: repaymentTermMax,
    //repaymentTermIncrement: repaymentTermIncrement,
    //vehiclePriceComputedChanged: vehiclePriceComputedChanged,
    //bodyAndEquipmentPriceComputedChanged: bodyAndEquipmentPriceComputedChanged,
    //vehiclePriceFormatted: vehiclePriceFormatted,
    //vehiclePriceMinFormatted: vehiclePriceMinFormatted,
    //vehiclePriceMaxFormatted: vehiclePriceMaxFormatted,
    //bodyAndEquipmentPriceFormatted: bodyAndEquipmentPriceFormatted,
    //bodyAndEquipmentPriceMinFormatted: bodyAndEquipmentPriceMinFormatted,
    //bodyAndEquipmentPriceMaxFormatted: bodyAndEquipmentPriceMaxFormatted,
    //optionPriceFormatted: optionPriceFormatted,
    //optionPriceMinFormatted: optionPriceMinFormatted,
    //optionPriceMaxFormatted: optionPriceMaxFormatted,
    //optionPriceMinSlider: optionPriceMinSlider,
    //optionPriceMaxSlider: optionPriceMaxSlider,
    //optionPriceMinSliderFormatted: optionPriceMinSliderFormatted,
    //optionPriceMaxSliderFormatted: optionPriceMaxSliderFormatted,
    //monthlyDistanceFormatted: monthlyDistanceFormatted,
    //monthlyDistanceMinFormatted: monthlyDistanceMinFormatted,
    //monthlyDistanceMaxFormatted: monthlyDistanceMaxFormatted,
    //fuelCostFormatted: fuelCostFormatted,
    //fuelCostMinFormatted: fuelCostMinFormatted,
    //fuelCostMaxFormatted: fuelCostMaxFormatted,
    //fuelCostConversion: fuelCostConversion,
    //fuelCostIncrementConversion: fuelCostIncrementConversion,
    //fuelCostMinConversion: fuelCostMinConversion,
    //fuelCostMaxConversion: fuelCostMaxConversion,
    //maintenanceRateFormatted: maintenanceRateFormatted,
    //maintenanceRateMinFormatted: maintenanceRateMinFormatted,
    //maintenanceRateMaxFormatted: maintenanceRateMaxFormatted,
    //financeRepaymentPerDistanceFormatted: financeRepaymentPerDistanceFormatted,
    //monthlyRepayment: monthlyRepayment,
    //monthlyRepaymentFormatted: monthlyRepaymentFormatted,
    //fuelCostPerDistance: fuelCostPerDistance,
    //fuelCostPerDistanceFormatted: fuelCostPerDistanceFormatted,
    //fuelCostPerMonth: fuelCostPerMonth,
    //fuelCostPerMonthFormatted: fuelCostPerMonthFormatted,
    //maintenanceRatePerDistance: maintenanceRatePerDistance,
    //maintenanceRatePerDistanceFormatted: maintenanceRatePerDistanceFormatted,
    //maintenanceRatePerMonth: maintenanceRatePerMonth,
    //maintenanceRatePerMonthFormatted: maintenanceRatePerMonthFormatted,
    //totalVariableCostsAmountFormatted: totalVariableCostsAmountFormatted,
    //variableCostsPerMonthTotal: variableCostsPerMonthTotal,
    //variableCostsPerMonthTotalFormatted: variableCostsPerMonthTotalFormatted,
    //totalCostPerDistanceFormatted: totalCostPerDistanceFormatted,
    //totalCostPerMonthFormatted: totalCostPerMonthFormatted,
    //financeAmount: financeAmount,
    //fuelConsumptionFormatted: fuelConsumptionFormatted,
    //fuelConsumptionMinFormatted: fuelConsumptionMinFormatted,
    //fuelConsumptionMaxFormatted: fuelConsumptionMaxFormatted,
    //totalCapitalCosts: totalCapitalCosts,
    onRoadCostsCosts: onRoadCostsCosts,
    onRoadCostsDiscounts: onRoadCostsDiscounts,
    //vehicleDiscount: vehicleDiscount,
    //vehicleDiscountPercentage: vehicleDiscountPercentage,
    //totalCapitalCostsFormatted: totalCapitalCostsFormatted,

    financeCostPerMonthFormattedComp: function () {
        return financeCostPerMonthFormattedComp;
    },
    financeCostPerKMFormattedComp: function () {
        return financeCostPerKMFormattedComp;
    },
    crewCostPerMonthFormattedComp: function () {
        return crewCostPerMonthFormattedComp;
    },
    crewCostPerKMFormattedComp: function () {
        return crewCostPerKMFormattedComp;
    },
    insuranceCostPerMonthFormattedComp: function () {
        return insuranceCostPerMonthFormattedComp;
    },
    insuranceCostPerKMFormattedComp: function () {
        return insuranceCostPerKMFormattedComp;
    },
    licenceFeesCostPerMonthFormattedComp: function () {
        return licenceFeesCostPerMonthFormattedComp;
    },
    licenceFeesCostPerKMFormattedComp: function () {
        return licenceFeesCostPerKMFormattedComp;
    },
    fuelAndAdblueCostPerMonthFormattedComp: function () {
        return fuelAndAdblueCostPerMonthFormattedComp;
    },
    fuelAndAdblueCostPerKMFormattedComp: function () {
        return fuelAndAdblueCostPerKMFormattedComp;
    },
    overheadsCostPerMonthFormattedComp: function () {
        return overheadsCostPerMonthFormattedComp;
    },
    overheadsCostPerKMFormattedComp: function () {
        return overheadsCostPerKMFormattedComp;
    },
    fixedCostsTotalCostsPerMonthFormattedComp: function () {
        return fixedCostsTotalCostsPerMonthFormattedComp;
    },
    fixedCostsTotalCostsPerKMFormattedComp: function () {
        return fixedCostsTotalCostsPerKMFormattedComp;
    },
    fuelCostPerMonthFomattedComp: function () {
        return fuelCostPerMonthFomattedComp;
    },
    fuelCostPerKMFormattedComp: function () {
        return fuelCostPerKMFormattedComp;
    },
    tyreCostPerMonthFormattedComp: function () {
        return tyreCostPerMonthFormattedComp;
    },
    tyreCostPerKMFormattedComp: function () {
        return tyreCostPerKMFormattedComp;
    },
    maintenanceCostPerMonthFormattedComp: function () {
        return maintenanceCostPerMonthFormattedComp;
    },
    maintenanceCostPerKMFormattedComp: function () {
        return maintenanceCostPerKMFormattedComp;
    },
    tollFeesCostPerMonthFormattedComp: function () {
        return tollFeesCostPerMonthFormattedComp;
    },
    tollFeesCostPerKMFormattedComp: function () {
        return tollFeesCostPerKMFormattedComp;
    },
    variableCostsTotalCostPerMonthFormattedComp: function () {
        return variableCostsTotalCostPerMonthFormattedComp;
    },
    variableCostsTotalCostPerKMFormattedComp: function () {
        return variableCostsTotalCostPerKMFormattedComp;
    },
    totalCostsPerMonthFormattedComp: function () {
        return totalCostsPerMonthFormattedComp;
    },
    totalCostsPerKMFormattedComp: function () {
        return totalCostsPerKMFormattedComp;
    },
    totalRevenuePerMonthFormattedComp: function () {
        return totalRevenuePerMonthFormattedComp;
    },
    totalRevenuePerKMFormattedComp: function () {
        return totalRevenuePerKMFormattedComp;
    },
    totalProfitPerMonthFormattedComp: function () {
        return totalProfitPerMonthFormattedComp;
    },
    totalProfitPerKMFormattedComp: function () {
        return totalProfitPerKMFormattedComp;
    },
    financeCostPercentageOfTotalFormattedComp: function () {
        return financeCostPercentageOfTotalFormattedComp;
    },
    crewCostPercentageOfTotalFormattedComp: function () {
        return crewCostPercentageOfTotalFormattedComp;
    },
    insuranceCostPercentageOfTotalFormattedComp: function () {
        return insuranceCostPercentageOfTotalFormattedComp;
    },
    licenceFeesCostPercentageOfTotalFormattedComp: function () {
        return licenceFeesCostPercentageOfTotalFormattedComp;
    },
    overheadsCostPercentageOfTotalFormattedComp: function () {
        return overheadsCostPercentageOfTotalFormattedComp;
    },
    fuelCostPercentageOfTotalFomattedComp: function () {
        return fuelCostPercentageOfTotalFomattedComp;
    },
    tyreCostPercentageOfTotalFormattedComp: function () {
        return tyreCostPercentageOfTotalFormattedComp;
    },
    maintenanceCostPercentageOfTotalFormattedComp: function () {
        return maintenanceCostPercentageOfTotalFormattedComp;
    },
    tollFeesCostPercentageOfTotalFormattedComp: function () {
        return tollFeesCostPercentageOfTotalFormattedComp;
    },
    //performance
    getPerformance: getActiveOfferPerformance,
    waypoints: waypoints,
    simulationResults: simulationResults,
    tripCosts: tripCosts,
    topLeftLatitude: topLeftLatitude,
    topLeftLongitude: topLeftLongitude,
    bottomRightLatitude: bottomRightLatitude,
    bottomRightLongitude: bottomRightLongitude,
    tripDistanceOutbound: tripDistanceOutbound,
    tripTimeOutbound: tripTimeOutbound,
    fuelConsumptionOutbound: fuelConsumptionOutbound,
    totalFuelOutbound: totalFuelOutbound,
    tripDistanceReturn: tripDistanceReturn,
    tripTimeReturn: tripTimeReturn,
    fuelConsumptionReturn: fuelConsumptionReturn,
    totalFuelReturn: totalFuelReturn,
    totalUnladen: function () {
        return totalUnladen;
    },
    maximumPayload: function () {
        return maximumPayload;
    },
    maximumGross: function () {
        return maximumGross;
    },
    //totalNetPrice: totalNetPrice,
    //totalNetPriceFormatted: totalNetPriceFormatted,
    //specification
    getSpecification: getSpecification,
    selectedCompetitor1: selectedCompetitor1,
    selectedCompetitor2: selectedCompetitor2,
    competitor1Array: competitor1Array,
    competitor2Array: competitor2Array,
    baseVehicle: baseVehicle,
    specificationHeadings: specificationHeadings,
    //summary
    vehicleNetPrice: vehicleNetPrice,
    totalOnRoadCostsLessCapitalCosts: totalOnRoadCostsLessCapitalCosts,
    totalOnRoadCostsDiscount: totalOnRoadCostsDiscount,
    totalOnRoadCostsLessDiscount: totalOnRoadCostsLessDiscount,
    totalFinanceCost: totalFinanceCost,
    totalOnRoadCostsFormatted: totalOnRoadCostsFormatted,
    totalOnRoadCostsLessDiscountFormatted: totalOnRoadCostsLessDiscountFormatted,
    totalOnRoadCosts: totalOnRoadCosts,
    //#endregion observables
    isNotCompleteVehicle: isNotCompleteVehicle,
    isNotStandardVehicle: isNotStandardVehicle,
    isTemplateVehicle: isTemplateVehicle,
    isTemplateBody: isTemplateBody,
    isCompleteTemplateVehicle: isCompleteTemplateVehicle,
    showAxle1FrontToAxle2Front: showAxle1FrontToAxle2Front,
    showAxle1RearToAxle2Rear: showAxle1RearToAxle2Rear,
    showAxle2RearToAxle3Rear: showAxle2RearToAxle3Rear,
    showAxle3RearToAxle4Rear: showAxle3RearToAxle4Rear,
    showWheelbaseTheoretical: showWheelbaseTheoretical,
    showAxle1FrontToWheelbaseTheoreticalStart: showAxle1FrontToWheelbaseTheoreticalStart,
    showCabTiltAttributes: showCabTiltAttributes,
    showChassisHeightFront: showChassisHeightFront,
    showFifthWheelHeight: showFifthWheelHeight,
    showCabAttributesNotCompleteVehicle: showCabAttributesNotCompleteVehicle,
    showCabTiltSection: showCabTiltSection,
    showFrontRoadFriendlySuspension: showFrontRoadFriendlySuspension,
    getWarnings: getWarnings,
    getDistinctWarnings: getDistinctWarnings,
    getDistinctWarningsPerLegislation: getDistinctWarningsPerLegislation,
    getNotes: getNotes,
    generateNotes: generateNotes,
    generateWarnings: generateWarnings,
    isNotStandardVehicleAndNotLoadingAdmin: isNotStandardVehicleAndNotLoadingAdmin,
    hideForStandardVehicleUnlessLoadingAdmin: hideForStandardVehicleUnlessLoadingAdmin,
    isTemplateVehicleOrStandardVehicleAndNotLoadingAdmin: isTemplateVehicleOrStandardVehicleAndNotLoadingAdmin,
    isStandardVehicleAndIsLoadingAdmin: isStandardVehicleAndIsLoadingAdmin,
    isTemplateVehicleAndShowSecondRow: isTemplateVehicleAndShowSecondRow,
    isStandardVehicleAndIsLoadingAdminAndShowSecondRow: isStandardVehicleAndIsLoadingAdminAndShowSecondRow,
    showCrewSecondRowOption: showCrewSecondRowOption,
    showCrewSecondRowSection: showCrewSecondRowSection,
    //setToolsIncluded: setToolsIncluded,
    //setSpareWheelIncluded: setSpareWheelIncluded,
    //setFrontRoadFriendlySuspension: setFrontRoadFriendlySuspension,
    //setRearRoadFriendlySuspension: setRearRoadFriendlySuspension,
    //setRolloverProtection: setRolloverProtection,
    //setFrontUnderrunProtection: setFrontUnderrunProtection,
    showCabAttributes: showCabAttributes,
    isTemplateTimberBody: isTemplateTimberBody,
    isTemplateOrCustomTimberBodyWithoutGraphic: isTemplateOrCustomTimberBodyWithoutGraphic,
    getAvailableBodies: getAvailableBodies,
    setAvailableBodies: setAvailableBodies,
    getAvailableAccessories: getAvailableAccessories,
    setAvailableAccessories: setAvailableAccessories,
    getAvailableTrailers: getAvailableTrailers,
    setAvailableTrailers: setAvailableTrailers,
    //deleteAccessoryFromOffer: deleteAccessoryFromOffer,
    updateDrawing: updateDrawing,
    setAccessoryValuesToOriginalValues: setAccessoryValuesToOriginalValues,
    //updateValue: updateValue,
    getAttributeGroup: getAttributeGroup,
    getAttributeGroupDir: getAttributeGroupDir,
    addBodyToCurrentChassis: addBodyToCurrentChassis,
    addAccessoryToCurrentChassis: addAccessoryToCurrentChassis,
    addTrailerToCurrentRig: addTrailerToCurrentRig,
    graphicsCallback: doGraphicsCallback,
    showAxleAttribute: showAxleAttribute,
    getNumberOfTyresOptions: getNumberOfTyresOptions,
    equipFactoryFittedFuelTank: equipFactoryFittedFuelTank,
    equipAxle: equipAxle,
    deleteObject: deleteObject,
    refreshUILiveLabels: function () {
        return refreshUILiveLabels;
    },
    displayError: displayError,
    getValidationConfig: getValidationConfig,
    hideErrorOnMenu: hideErrorOnMenu,
    isStandardVehicleAndLoadingAdminOrIsTemplateVehicle: isStandardVehicleAndLoadingAdminOrIsTemplateVehicle,
    doActionsAfterSuccesfulValidation: doActionsAfterSuccesfulValidation,
    refreshData: function () {
        return refreshData;
    },
    getSideDoorTypeOptions: getSideDoorTypeOptions,
    equipBodyUpright: equipBodyUpright,
    copyObject: copyObject,
    filterBodiesByType: filterBodiesByType,
    sortBodiesByType: sortBodiesByType,
    filterBodiesByName: filterBodiesByName,
    sortBodiesByName: sortBodiesByName,
    filterTrailersByTypeForTrailer1: filterTrailersByTypeForTrailer1,
    filterTrailersByTypeForTrailer2: filterTrailersByTypeForTrailer2,
    sortTrailersByType: sortTrailersByType,
    filterTrailersByName: filterTrailersByName,
    sortTrailersByName: sortTrailersByName,
    filterAccessoriesByType: filterAccessoriesByType,
    filterTrailerAccessoriesByType: filterTrailerAccessoriesByType,
    filterInterlinkTrailerAccessoriesByType: filterInterlinkTrailerAccessoriesByType,
    sortAccessoriesByType: sortAccessoriesByType,
    filterAccessoriesByName: filterAccessoriesByName,
    sortAccessoriesByName: sortAccessoriesByName,
    getCOGTypeOptions: getCOGTypeOptions,
    getResidualValueOptions: getResidualValueOptions,
    addBodyToAvailableBodiesArray: addBodyToAvailableBodiesArray,
    updateBodyOnAvailableBodiesArray: updateBodyOnAvailableBodiesArray,
    addAccessoryToAvailableAccessoriesArray: addAccessoryToAvailableAccessoriesArray,
    updateAccessoryOnAvailableAccessoriesArray: updateAccessoryOnAvailableAccessoriesArray,
    addTrailerToAvailableTrailersArray: addTrailerToAvailableTrailersArray,
    updateTrailerOnAvailableTrailersArray: updateTrailerOnAvailableTrailersArray,
    addPayloadToAvailablePayloadsArray: addPayloadToAvailablePayloadsArray,
    updatePayloadOnAvailablePayloadsArray: updatePayloadOnAvailablePayloadsArray,
    // createPdfDocumentDefinition: createPdfDocumentDefinition,
    createOrLoadVehicleDrawing: createOrLoadVehicleDrawing,
    generatePdfPreview: generatePdfPreview,
    getBody: getBody,
    getAccessory: getAccessory,
    getTrailer: getTrailer,
    getTotalPages: getTotalPages,
    setConfigDrawing: setConfigDrawing,
    setCostingChartDrawing: setCostingChartDrawing,
    checkAccessoryRules: checkAccessoryRules,
    checkObjectRules: checkObjectRules,
    checkAccessoryDeleteRules: checkAccessoryDeleteRules,
    checkDeleteRules: checkDeleteRules,
    incrementNumChanges: incrementNumChanges,
    checkShowVerifyEmailModal: checkShowVerifyEmailModal,
    checkIfTrialChangesAllowed: checkIfTrialChangesAllowed,
    checkVerifiedStatusOnline: checkVerifiedStatusOnline,
    showEmailVerificationModal: showEmailVerificationModal,
    emailVerified: emailVerified,
    getMissingValuesLookupObject: getMissingValuesLookupObject,
    registerMenuLinkExpansionRequestListener: registerMenuLinkExpansionRequestListener,
    getMissingValuesWarningText: getMissingValuesWarningText,
    getNumLinkableMissingValues: getNumLinkableMissingValues,
    getNumLinkableTurningCircleMissingValues: getNumLinkableTurningCircleMissingValues,
    doActionsAfterMenuLinkClickPriorToMenuExpansion: doActionsAfterMenuLinkClickPriorToMenuExpansion,
    getTrialUserObfuscationTextWithCurrentMassUnit: getTrialUserObfuscationTextWithCurrentMassUnit,
    getTrialUserXValuesInteractionMetaObject: getTrialUserXValuesInteractionMetaObject,
    getDimensionInteractionObject: getDimensionInteractionObject,
    getCOGElementInteractionObject: getCOGElementInteractionObject,
    isTemplateAccessory: isTemplateAccessory,
    isTemplateOnlyBody: isTemplateOnlyBody,
    isTemplateOrCustomBody: isTemplateOrCustomBody,
    isTemplateOrCustomAccessory: isTemplateOrCustomAccessory,
    isCustomAccessoryWithGraphic: isCustomAccessoryWithGraphic,
    isCustomBodyWithGraphic: isCustomBodyWithGraphic,
    isCustomBeaverTailBodyWithGraphic: isCustomBeaverTailBodyWithGraphic,
    isTemplateOrCustomPayload: isTemplateOrCustomPayload,
    setPreventSettingsUpdate: setPreventSettingsUpdate,
    registerUpdateBodyMenuCallback: registerUpdateBodyMenuCallback,
    registerUpdateVehicleMenuCallback: registerUpdateVehicleMenuCallback,
    registerUpdateAccessoryMenuLabelListener: registerUpdateAccessoryMenuLabelListener,
    registerCreatePdfWithPromiseCallback: registerCreatePdfWithPromiseCallback,
    getCreatePdfWithPromiseCallback: getCreatePdfWithPromiseCallback,
    getDecimalsHighPricedItems: getDecimalsHighPricedItems,
    getDecimalsMediumPricedItems: getDecimalsMediumPricedItems,
    getDecimalsLowPricedItems: getDecimalsLowPricedItems,
    registerSyncErrorToastHandler: registerSyncErrorToastHandler,
    registerSyncSuccessToastHandler: registerSyncSuccessToastHandler,
    registerSyncSavedCopyToastHandler: registerSyncSavedCopyToastHandler,
    registerNewSharedOffersToastHandler: registerNewSharedOffersToastHandler,
    registerNewOrUpdatedTeamItemsSyncToastHandler: registerNewOrUpdatedTeamItemsSyncToastHandler,
    registerValidationFailedWhileDraggingToastHandler: registerValidationFailedWhileDraggingToastHandler,
    isTemplateOrCustomTrailer: isTemplateOrCustomTrailer,
    getRigOps: getRigOps,
    showUnlessTrailerStandard: showUnlessTrailerStandard,
    showUnlessTrailerStandardOrCustomWithGraphic: showUnlessTrailerStandardOrCustomWithGraphic,
    isTrailerSourceStandard: isTrailerSourceStandard,
    getDrawingGroupInteractionObject: getDrawingGroupInteractionObject,
    //getCOGDimensionInteractionObject:getCOGDimensionInteractionObject,
    isAttributeGroupVisible: isAttributeGroupVisible,
    getStandardTableCellInteractionObject: getStandardTableCellInteractionObject,
    getMassTypeOptions: getMassTypeOptions,
    cachingCompleted: cachingCompleted,
    cachingNotCompleted: cachingNotCompleted,
    addWaypoint: addWaypoint,
    setDisplayCostingTab: setDisplayCostingTab,
    setDisplaySpecificationTab: setDisplaySpecificationTab,
    doActionsAfterFailedValidation: doActionsAfterFailedValidation,
    getDraggableObjectInteractionObject: getDraggableObjectInteractionObject,
    getTurningAbilitySpecifiedAsOptions: getTurningAbilitySpecifiedAsOptions,
    getTurningCircleMissingValuesLookupObject: getTurningCircleMissingValuesLookupObject,
    getFuelConsumptionMin: getFuelConsumptionMin,
    getFuelConsumptionMax: getFuelConsumptionMax,
    getFuelConsumptionForImperialMeasurementSystem: getFuelConsumptionForImperialMeasurementSystem,
    getAdblueConsumptionForImperialMeasurementSystem: getAdblueConsumptionForImperialMeasurementSystem,
    getFuelConsumptionForImperialMeasurementSystemWithValueParam: getFuelConsumptionForImperialMeasurementSystemWithValueParam,
    getFuelConsumptionForImperialMeasurementSystemValue: getFuelConsumptionForImperialMeasurementSystemValue,
    getAdblueConsumptionForImperialMeasurementSystemValue: getAdblueConsumptionForImperialMeasurementSystemValue,
    getCurTurningCircleVals: getCurTurningCircleVals,
    getWorstOverloadingStatus: getWorstOverloadingStatus,
    getWorstOverloadingStatusFromActiveLegislations: getWorstOverloadingStatusFromActiveLegislations,
    getLegislation: getLegislation,
    registerRefreshDataCallback: registerRefreshDataCallback,
    registerOpenSettingsModalRequestListener: registerOpenSettingsModalRequestListener,
    getComplianceDashboardInteractionObject: getComplianceDashboardInteractionObject,
    areModulesOtherThanConfigurationAvailable: areModulesOtherThanConfigurationAvailable,
    isActiveMeasurementSystemMetric: isActiveMeasurementSystemMetric,
    getUnitOfMeasureOptions: getUnitOfMeasureOptions,
    getAvailablePayloads: getAvailablePayloads,
    setAvailablePayloads: setAvailablePayloads,
    getPayload: getPayload,
    addPayloadToCurrentChassis: addPayloadToCurrentChassis,
    getPayloadTypeOptions: getPayloadTypeOptions,
    showPayloadDetailedMenu: showPayloadDetailedMenu,
    getDrawbarTypeOptions: getDrawbarTypeOptions,
    registerLogoutFunc: registerLogoutFunc,
    registerLoginFunc: registerLoginFunc,
    registerManageHiddenButtonsFunc: registerManageHiddenButtonsFunc,
    registerUpdateApplicationSelectionAndVehicleTypeSelectionFunc: registerUpdateApplicationSelectionAndVehicleTypeSelectionFunc,
    registerEvaulateShowSaveOptionFunc: registerEvaulateShowSaveOptionFunc,
    registerHandleEssentialsMessageResponseFunc: registerHandleEssentialsMessageResponseFunc,
    registerDoAppGeneralSetupSteps: registerDoAppGeneralSetupSteps,
    checkLoadingSummaryDisclaimerAndLoadIfMissing: checkLoadingSummaryDisclaimerAndLoadIfMissing,
    setAllowUpdateOfUnsavedChangesOnOffer: setAllowUpdateOfUnsavedChangesOnOffer,
    doActionsAfterOverrideChecked: doActionsAfterOverrideChecked,
    getWorstBridgeFormulaOverloadColour: getWorstBridgeFormulaOverloadColour,
    getWorstBridgeFormulaPermissibleOverloadColour: getWorstBridgeFormulaPermissibleOverloadColour,
    getWorstSteerAxleMinimumOverloadColour: getWorstSteerAxleMinimumOverloadColour,
    preventDoActionsAfterSuccessfulValidationCall: preventDoActionsAfterSuccessfulValidationCall,
    // preventUpdatesDuringCleanup: preventUpdatesDuringCleanup,
    // getPreventUpdatesDuringCleanup: getPreventUpdatesDuringCleanup,
    preventUpdateDuringAOGOptionIdWatch: preventUpdateDuringAOGOptionIdWatch,
    getPreventUpdateDuringAOGOptionIdWatch: getPreventUpdateDuringAOGOptionIdWatch,
    updateDynamicValidationGroups: updateDynamicValidationGroups,
    updateWaypointNetPayloads: updateWaypointNetPayloads,
    showAxlesTabForVehicle: showAxlesTabForVehicle,
    isConfigurationReportOptionAvailable: isConfigurationReportOptionAvailable,
    isSpecificationReportOptionAvailable: isSpecificationReportOptionAvailable,
    isCostingReportOptionAvailable: isCostingReportOptionAvailable,
    isPerformanceReportOptionAvailable: isPerformanceReportOptionAvailable,
    isAtLeastOneReportOptionChecked: isAtLeastOneReportOptionChecked,
    showShellSpinner: function () {
        return showShellSpinner;
    },
    clearActiveLegislationQuickLookupObjects: clearActiveLegislationQuickLookupObjects,
    setLegislationLookupObjectsCleared: setLegislationLookupObjectsCleared,
    clearBridgeWorstHighlightColour: clearBridgeWorstHighlightColour,
    clearBridgeFormulaPermissiblWorstHighlightColour: clearBridgeFormulaPermissiblWorstHighlightColour,
    clearSteerAxleMinimumWorstHighlightColour: clearSteerAxleMinimumWorstHighlightColour,
    setSkipGraphicsCallback: setSkipGraphicsCallback,
    createTotalCostOfOwnershipChart: createTotalCostOfOwnershipChart,
    updateTotalCostOfOwnershipChart: updateTotalCostOfOwnershipChart,
    setupUserObservables: setupUserObservables,
    deleteObservables: deleteObservables,
    refreshSWLabels: function () {
        return refreshSWLabels;
    },
    createRefreshSWLabelsObservable: createRefreshSWLabelsObservable,
    clearRefreshSWLabelsObservable: clearRefreshSWLabelsObservable,
    // quickSearchSortFunction: quickSearchSortFunction,
    quickSearchFilterFunction: quickSearchFilterFunction,
    addComputedsToVehicles: addComputedsToVehicles,
    getNewAndSavedOffersForSelectionList: getNewAndSavedOffersForSelectionList,
    getNewAndSavedOffersForVehicleSelectionInMenu: getNewAndSavedOffersForVehicleSelectionInMenu,
    swapCurrentVehicle: swapCurrentVehicle,
    filterVehiclesByName: filterVehiclesByName,
    registerClearSimulationResultsOnlyCallback: registerClearSimulationResultsOnlyCallback,
    preloadNewSpecForSwap: preloadNewSpecForSwap,
    sortVehiclesByName: sortVehiclesByName,
    clearPerformanceReportOptionIfNecessary: clearPerformanceReportOptionIfNecessary,
    updatePendingVehicle: updatePendingVehicle,
    getPostValidatedVehicleStatusOptions: getPostValidatedVehicleStatusOptions,
    shareOffer: shareOffer,
    openExistingOfferWrapper: openExistingOfferWrapper,
    getExistingOfferDetailsSuccess: getExistingOfferDetailsSuccess,
    routeToNextModule: routeToNextModule,
    openDeepLinkedOfferShare: openDeepLinkedOfferShare,
    checkShareStatusAndOpen: checkShareStatusAndOpen,
    handleDeepLinkAccessCode: handleDeepLinkAccessCode,
    getLegislationSubstitutedForSharedCalc: getLegislationSubstitutedForSharedCalc,
    getDefaultLegislationUsedDuToMissingLegislation: getDefaultLegislationUsedDuToMissingLegislation,
    getSharedOfferShareeUserId: getSharedOfferShareeUserId,
    getSharedOfferCustomerId: getSharedOfferCustomerId,
    getSendIntercomSharedOfferAcceptedEvent: getSendIntercomSharedOfferAcceptedEvent,
    resetSendIntercomSharedOfferAcceptedEvent: resetSendIntercomSharedOfferAcceptedEvent,
    getSpecifyWheelbaseAsOptions: getSpecifyWheelbaseAsOptions,
    getSpecifyWheelbaseAsTextUsingId: getSpecifyWheelbaseAsTextUsingId,
    languageId: languageId,
    registerOpenBuyNowDialog: registerOpenBuyNowDialog,
    registerOpenUserSettingsModalCallback: registerOpenUserSettingsModalCallback,
    isDetailedPayloadAvailableOnRig: isDetailedPayloadAvailableOnRig,
    getSummaryReportOptionUsingCode: getSummaryReportOptionUsingCode,
    updateCostingTyreRate: updateCostingTyreRate,
    updateCostingItems: updateCostingItems,
    createCraneObjectObservables: createCraneObjectObservables,
    createItemObjectObservablesForItemConfigWizard: createItemObjectObservablesForItemConfigWizard,
    createObservablesForFinanceDetailModal: createObservablesForFinanceDetailModal,
    createObservablesForLicenceFeesModal: createObservablesForLicenceFeesModal,
    createObservablesForTollFeesModal: createObservablesForTollFeesModal,
    createObservablesForFuelAndAdblueModal: createObservablesForFuelAndAdblueModal,
    createObservablesForSaveAccessoryModal: createObservablesForSaveAccessoryModal,
    createInternalStandardsObservablesForSettingsModal: createInternalStandardsObservablesForSettingsModal,
    createItemConfigWizardDrawingAOGs: createItemConfigWizardDrawingAOGs,
    createCostingDetailModalLicenceFeesAOGs: createCostingDetailModalLicenceFeesAOGs,
    getItemDraggableObjectInteractionObject: getItemDraggableObjectInteractionObject,
    //createHitchObjectObservablesForConfigurationModal: createHitchObjectObservablesForConfigurationModal,
    createTailliftObjectObservables: createTailliftObjectObservables,
    createAccessoryObject: createAccessoryObject,
    getActiveConfigurationWizardItem: getActiveConfigurationWizardItem,
    getActiveConfigurationWizardItemSize: getActiveConfigurationWizardItemSize,
    getOriginalConfigurationWizardItem: getOriginalConfigurationWizardItem,
    getOriginalConfigurationWizardItemSize: getOriginalConfigurationWizardItemSize,
    setConfigurationWizardItems: setConfigurationWizardItems,
    displayErrorOnMenu: displayErrorOnMenu,
    createItemObject: createItemObject,
    getHitchHeightFromGround: getHitchHeightFromGround,
    configurationActionResponseHandlerCallback: function () {
        return configurationActionResponseHandlerCallback;
    },
    getSpecifyCabDimensionsAsOptions: getSpecifyCabDimensionsAsOptions,
    getSpecifyChassisLengthAsOptions: getSpecifyChassisLengthAsOptions,
    registerRefreshMenuOnChangeOfCabDimensionsOrChassisLengthCallback: registerRefreshMenuOnChangeOfCabDimensionsOrChassisLengthCallback,
    getRefreshMenuOnChangeOfCabDimensionsOrChassisLengthCallback: getRefreshMenuOnChangeOfCabDimensionsOrChassisLengthCallback,
    getSpecifyFuelConsumptionAsOptions: getSpecifyFuelConsumptionAsOptions,
    getSpecifyAxleRatingAsOptions: getSpecifyAxleRatingAsOptions,
    getSpecifyBodyPositionAsOptions: getSpecifyBodyPositionAsOptions,
    getSpecifyEquipmentPositionAsOptions: getSpecifyEquipmentPositionAsOptions,
    setFuelConsumptionForImperialMeasurementSystem: setFuelConsumptionForImperialMeasurementSystem,
    getBodyLengthTypeOptions: getBodyLengthTypeOptions,
    menuItemFocusCallback: menuItemFocusCallback,
    deactivateSummaryReportOptionUsingCode: deactivateSummaryReportOptionUsingCode,
    getUndoHandler: function () {
        return undoHandler;
    },
    undoHandlerHasNoUndoOps: undoHandlerHasNoUndoOps,
    undoHandlerHasNoRedoOps: undoHandlerHasNoRedoOps,
    //disableUndo: disableUndo,
    //disableRedo: disableRedo,
    undo: undo,
    redo: redo,
    getBodyStub: getBodyStub,
    getAccessoryStub: getAccessoryStub,
    getPayloadStub: getPayloadStub,
    getTrailerStub: getTrailerStub,
    invalidatePdfAndDrawing: invalidatePdfAndDrawing,
    registerRigObjectRemovalHandler: registerRigObjectRemovalHandler,
    registerPostUndoCallback: registerPostUndoCallback,
    removeObjectFromRig: removeObjectFromRig,
    postUndoConfigurationCallback: function (path) {
        return postUndoConfigurationCallback(path);
    },
    notifyUndoRedoListeners: notifyUndoRedoListeners,
    updateFinanceCostPerMonthValues: updateFinanceCostPerMonthValues,
    displayLicenceDetailButtonOnCosting: displayLicenceDetailButtonOnCosting,
    getAverageFuelValue: getAverageFuelValue,
    getSearchResults: getSearchResults,
    dismissDontAskMeAgain: dismissDontAskMeAgain,
    clearStandardItemToast: clearStandardItemToast,
    getWeightTypeOptions: getWeightTypeOptions,
    generateOverloadingColColours: generateOverloadingColColours,
    getObjectReadOnlyPermission: getObjectReadOnlyPermission,
    displayTollFeesDetailOnCosting: displayTollFeesDetailOnCosting,
    getVehicleClassId: getVehicleClassId,
    getLicenceFeesMessageArray: getLicenceFeesMessageArray,
    removeItemFromLicenceFeesMessageArray: removeItemFromLicenceFeesMessageArray,
    updateCostingLicenceFeesAttrObvGroup: updateCostingLicenceFeesAttrObvGroup,
    getNotifyUserOfTollFeesChange: getNotifyUserOfTollFeesChange,
    setNotifyUserOfTollFeesChange: setNotifyUserOfTollFeesChange,
    getTollFeesFromUserRelatedToRoute: getTollFeesFromUserRelatedToRoute,
    getDefaultReportPdfPageSizeOptions: getDefaultReportPdfPageSizeOptions,
    getReportViewLayoutOptions: getReportViewLayoutOptions,
    // getReportLogo: getReportLogo,
    setupDefaultValidationConfigs: setupDefaultValidationConfigs,
    getMeasurementSystemToUse: getMeasurementSystemToUse,
    getLesserOfActiveLegislationVals: getLesserOfActiveLegislationVals,
    getGreaterOfActiveLegislationVals: getGreaterOfActiveLegislationVals,
    setLegislations: setLegislations,
    getLegislations: getLegislations,
    returnWorstColourStatus: returnWorstColourStatus,
    returnWorstIconStatus: returnWorstIconStatus,
    getBridgeFormulaUsedInAtLeastOneActiveLegislation: getBridgeFormulaUsedInAtLeastOneActiveLegislation,
    getBridgeGroupsVisibleForActiveLegislations: getBridgeGroupsVisibleForActiveLegislations,
    getWarningsByEncodedLegislationId: getWarningsByEncodedLegislationId,
    getOverloadingColoursForLegislation: getOverloadingColoursForLegislation,
    getOverloadingColoursForLegislations: getOverloadingColoursForLegislations,
    getInterpreterInstance: getInterpreterInstance,
    getActiveLegislationsCombinationPermissibleVals: getActiveLegislationsCombinationPermissibleVals,
    getMergedLegislationsCombinationPermissibleVals: getMergedLegislationsCombinationPermissibleVals,
    getInternalStandardFormatFunc: getInternalStandardFormatFunc,
    generateDataForActiveLegislations: generateDataForActiveLegislations,
    needToMaximise: needToMaximise,
    loadLegislationsDetails: loadLegislationsDetails,
    needToEvaluateDataPointOrInternalStandard: needToEvaluateDataPointOrInternalStandard,
    notifyGraphicsManager: function () { return changeListenerCallback; },
    unpinAllEquipmentItemDimensions: unpinAllEquipmentItemDimensions,
    registerRequestSalesToolCallback: registerRequestSalesToolCallback,
    getRouter: function () {return router; },
    hasLegislationARegulationWarning: hasLegislationARegulationWarning,
    hasLegislationARegulationCaution: hasLegislationARegulationCaution,
    hasLegislationARegulationUnknown: hasLegislationARegulationUnknown,
    hasLegislationABridgeWarning: hasLegislationABridgeWarning,
    hasLegislationABridgeCaution: hasLegislationABridgeCaution,
    hasLegislationABridgeUnknown: hasLegislationABridgeUnknown,
    hasLegislationADimensionWarning: hasLegislationADimensionWarning,
    hasLegislationATurningCircleWarning: hasLegislationATurningCircleWarning, 
    hasLegislationATurningCircleUnknown: hasLegislationATurningCircleUnknown,
    getTriggeredRuleContexts: getTriggeredRuleContexts,
    getBridgeFormulaGroupOverloadColour: getBridgeFormulaGroupOverloadColour,
    getLegislationUsesDataDrivenRuleMethod: getLegislationUsesDataDrivenRuleMethod,
    getDistinctTips: getDistinctTips,
    getTrailer1DrawbarLengthEqualToDrawbarLegislationLength: getTrailer1DrawbarLengthEqualToDrawbarLegislationLength,
    updateRefreshCostingData: updateRefreshCostingData,
    getPreventCostingTotalRevenueUpdate: getPreventCostingTotalRevenueUpdate,
    setPreventCostingTotalRevenueUpdate: setPreventCostingTotalRevenueUpdate,
    getDDRNotes: getDDRNotes,
    getUIFormattedFuelExpressedAsValue: getUIFormattedFuelExpressedAsValue,
    getUIFormattedWattValue: getUIFormattedWattValue,
    isFuelTypeWithExpressedASSupport: isFuelTypeWithExpressedASSupport,
    getUIFormattedEnergySourceUnitOfMeasure: getUIFormattedEnergySourceUnitOfMeasure,
    hasFuelInFuelAndCrew: hasFuelInFuelAndCrew,
    setPreventToastFromBeingDisplayed: setPreventToastFromBeingDisplayed,
    getOpenSettingsModalOnClickFunction: getOpenSettingsModalOnClickFunction,
    setOpenSettingsModalOnClickFunction: setOpenSettingsModalOnClickFunction,
    // getFirstMatchingVehicleStubForVehicleCode: getFirstMatchingVehicleStubForVehicleCode,
    getPerformanceMaximumSpeedMax: getPerformanceMaximumSpeedMax,
    getFirstMatchingVehicleStubForSpecDataPoints: getFirstMatchingVehicleStubForSpecDataPoints,
    isCustomItemWithDXFImportedGraphic: isCustomItemWithDXFImportedGraphic,
    checkSpecDataPoints: checkSpecDataPoints
};

export default offerManager;



function setSpecificationVehicles() {
    setSelectedCompetitor1.value = selectedCompetitor1Id.value;
    setSelectedCompetitor2.value = selectedCompetitor2Id.value;
    allowSpecificationUpdateOfUnsavedChangesOnOffer = true;
}

//#region Functions used in menu selection wizard

function filterVehiclesByName(row, value, filtersArray) {
    //return row.getType() === filtersArray[0].value;
    if (value.length !== undefined && value.length > 0 && value[0] !== '') {
        var returnVal = false,
            numberOfMatches = 0;
        for (var i = 0; i < value.length; i++) {
            if (comparison(row, filtersArray) && row.Description.toLowerCase().includes(value[i].toLowerCase())) {
                numberOfMatches += 1;
            }
        }
        return numberOfMatches === value.length;
    } else {
        return comparison(row, filtersArray);
    }

    function comparison(row, filtersArray) {
        var returnVal = true;
        for (var i = 0; i < filtersArray.length; i++) {
            if (filtersArray[i].value !== config.ACCESSORY_SOURCE_TYPES.ALL) {
                if (row[filtersArray[i].property] !== filtersArray[i].value) {
                    returnVal = false;
                    break;
                }
            }
        }
        return returnVal;
    }
}

function sortVehiclesByName(arrayToSort, columns) {

    var propertyName = columns[0].name; //'Description';
    var sortType = columns[0].sortType();
    return arrayToSort.sort(function (a, b) {
        if (sortType === config.SORT_OPTIONS.ASC) {
            if (typeof a[propertyName] === 'string' || typeof b[propertyName] === 'string') {
                if (a[propertyName].toUpperCase() < b[propertyName].toUpperCase()) {
                    return -1;
                }
                if (a[propertyName].toUpperCase() > b[propertyName].toUpperCase()) {
                    return 1;
                }
            } else {
                if (a[propertyName] < b[propertyName]) {
                    return -1;
                }
                if (a[propertyName] > b[propertyName]) {
                    return 1;
                }
            }
        } else {
            if (typeof a[propertyName] === 'string' || typeof b[propertyName] === 'string') {
                if (a[propertyName].toUpperCase() < b[propertyName].toUpperCase()) {
                    return 1;
                }
                if (a[propertyName].toUpperCase() > b[propertyName].toUpperCase()) {
                    return -1;
                }
            } else {
                if (a[propertyName] < b[propertyName]) {
                    return 1;
                }
                if (a[propertyName] > b[propertyName]) {
                    return -1;
                }
            }
        }
    });
}

function filterBodiesByType() {
    return true;
}

function sortBodiesByType(arrayToSort) {
    var cultureCode = globals.getActiveCultureCodeForTranslations();
    var sortedArray = arrayToSort.sort(function (a, b) {
        if (a.getBodyTypeSortValue(cultureCode) < b.getBodyTypeSortValue(cultureCode)) {
            return -1;
        }
        if (a.getBodyTypeSortValue(cultureCode) > b.getBodyTypeSortValue(cultureCode)) {
            return 1;
        }
        return 0;
    });
    return sortedArray;
}

function filterBodiesByName(row, value, filtersArray) {
    //return row.getType() === filtersArray[0].value;
    if (value.length !== undefined && value.length > 0 && value[0] !== '') {
        var returnVal = false,
            numberOfMatches = 0;
        for (var i = 0; i < value.length; i++) {
            if (comparison(row, filtersArray) && row.getDescription().toLowerCase().includes(value[i].toLowerCase())) {
                numberOfMatches += 1;
            }
        }
        return numberOfMatches === value.length;
    } else {
        return comparison(row, filtersArray);
    }

    function comparison(row, filtersArray) {
        var returnVal = true;
        for (var i = 0; i < filtersArray.length; i++) {
            if (filtersArray[i].value !== config.ACCESSORY_SOURCE_TYPES.ALL) {
                if (filtersArray[i].value === config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL || filtersArray[i].value === config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY) {
                    if (row.getSource() === config.ACCESSORY_SOURCE_TYPES.CUSTOM) {
                        if (row[filtersArray[i].property]() !== filtersArray[i].value) {
                            returnVal = false;
                            break;
                        }
                    } else {
                        returnVal = false;
                        break;
                    }
                } else {
                    if (row[filtersArray[i].property]() !== filtersArray[i].value) {
                        returnVal = false;
                        break;
                    }
                }
            }
        }
        return returnVal;
    }
}

function sortBodiesByName(arrayToSort, columns) {
    return globals.sortArrayInOrderCustomTemplateStandard(arrayToSort, columns);
}
//function filterTrailersByType() {
//    return true;
//}
function filterTrailersByTypeForTrailer1(row) {
    return allowedTrailerTypesTrailer1[row.getType()] !== undefined;
}

function filterTrailersByTypeForTrailer2(row) {
    return allowedTrailerTypesTrailer2[row.getType()] !== undefined;
}

function setupAllowedTrailerTypesTrailer1() {
    allowedTrailerTypesTrailer1 = {};

    allowedTrailerTypesTrailer1[config.TRAILER_TYPES.DRAWBAR] = {};
    allowedTrailerTypesTrailer1[config.TRAILER_TYPES.PUP] = {};

    //if (getActiveOffer().getRig().getVehicle().getType() !== config.VEHICLE_TYPES.COMPLETE_VEHICLE) {

    allowedTrailerTypesTrailer1[config.TRAILER_TYPES.SEMI] = {};
    allowedTrailerTypesTrailer1[config.TRAILER_TYPES.INTERLINK] = {};


    //}
}

function setupAllowedTrailerTypesTrailer2() {
    allowedTrailerTypesTrailer2 = {};

    allowedTrailerTypesTrailer2[config.TRAILER_TYPES.SEMI] = {};

}

function sortTrailersByType(arrayToSort) {
    var sortedArray = arrayToSort.sort(function (a, b) {
        if (a.getTrailerTypeSortValue() < b.getTrailerTypeSortValue()) {
            return -1;
        }
        if (a.getTrailerTypeSortValue() > b.getTrailerTypeSortValue()) {
            return 1;
        }
        return 0;
    });
    return sortedArray;
}

function filterTrailersByName(row, value, filtersArray) {
    if (value.length !== undefined && value.length > 0 && value[0] !== '') {
        var returnVal = false,
            numberOfMatches = 0;
        for (var i = 0; i < value.length; i++) {
            if (comparison(row, filtersArray) && row.getDescription().toLowerCase().includes(value[i].toLowerCase())) {
                numberOfMatches += 1;
            }
        }
        return numberOfMatches === value.length;
    } else {
        return comparison(row, filtersArray);
    }

    function comparison(row, filtersArray) {
        var returnVal = true;
        for (var i = 0; i < filtersArray.length; i++) {
            if (filtersArray[i].value !== config.ACCESSORY_SOURCE_TYPES.ALL) {
                if (filtersArray[i].value === config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL || filtersArray[i].value === config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY) {
                    if (row.getSource() === config.ACCESSORY_SOURCE_TYPES.CUSTOM) {
                        if (row[filtersArray[i].property]() !== filtersArray[i].value) {
                            returnVal = false;
                            break;
                        }
                    } else {
                        returnVal = false;
                        break;
                    }
                } else {
                    if (row[filtersArray[i].property]() !== filtersArray[i].value) {
                        returnVal = false;
                        break;
                    }
                }
            }
            //if (filtersArray[i].value !== config.ACCESSORY_SOURCE_TYPES.ALL) {
            //    if (row[filtersArray[i].property]() !== filtersArray[i].value) {
            //        returnVal = false;
            //        break;
            //    }
            //}
        }
        return returnVal;
    }
}

function sortTrailersByName(arrayToSort, columns) {
    return globals.sortArrayInOrderCustomTemplateStandard(arrayToSort, columns);
}

function filterAccessoriesByType(row) {


    return allowedAccessoryTypes[row.getAccessoryType()] !== undefined;
    //if (isFifthWheelIncludedOnVehicle()) {
    //    return row.getAccessoryType() !== config.ACCESSORY_TYPES.FIFTH_WHEEL;
    //} else {
    //    return true;
    //}
}

function setupAllowedAccessoryTypes() {
    allowedAccessoryTypes = {};
    allowedAccessoryTypes[config.ACCESSORY_TYPES.HITCH] = {};
    allowedAccessoryTypes[config.ACCESSORY_TYPES.HOOKLIFT] = {};
    allowedAccessoryTypes[config.ACCESSORY_TYPES.OTHER] = {};
    allowedAccessoryTypes[config.ACCESSORY_TYPES.CRANE] = {};
    if (!isFifthWheelIncludedOnVehicle()) {
        allowedAccessoryTypes[config.ACCESSORY_TYPES.FIFTH_WHEEL] = {};
    }
    allowedAccessoryTypes[config.ACCESSORY_TYPES.TAILLIFT] = {};
    if (getActiveOffer().getRig().getVehicle().getType() !== config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //if (!isFifthWheelIncludedOnVehicle()) {
        //    allowedAccessoryTypes[config.ACCESSORY_TYPES.FIFTH_WHEEL] = {};
        //}
        //allowedAccessoryTypes[config.ACCESSORY_TYPES.HITCH] = {};
        //allowedAccessoryTypes[config.ACCESSORY_TYPES.OTHER] = {};
        //allowedAccessoryTypes[config.ACCESSORY_TYPES.CRANE] = {};
        allowedAccessoryTypes[config.ACCESSORY_TYPES.FRIDGE] = {};
        //allowedAccessoryTypes[config.ACCESSORY_TYPES.TAILLIFT] = {};
    }
}

function filterTrailerAccessoriesByType(row, value, filtersArray, showAll) {
    return row.getAccessoryType() !== config.ACCESSORY_TYPES.FIFTH_WHEEL &&
        row.getAccessoryType() !== config.ACCESSORY_TYPES.HITCH &&
        row.getAccessoryTypeForSelectionWizard() !== config.ACCESSORY_TYPES.OTHER + '-' + config.ACCESSORY_TYPES.BULLBAR.toUpperCase() &&
        row.getAccessoryTypeForSelectionWizard() !== config.ACCESSORY_TYPES.OTHER + '-' + config.ACCESSORY_TYPES.AEROKIT.toUpperCase() &&
        row.getAccessoryTypeForSelectionWizard() !== config.ACCESSORY_TYPES.OTHER + '-' + config.ACCESSORY_TYPES.SNOWPLOW.toUpperCase() &&
        row.getAccessoryTypeForSelectionWizard() !== config.ACCESSORY_TYPES.OTHER + '-' + config.ACCESSORY_TYPES.AERIAL_PLATFORM.toUpperCase();
}

/**
    * Used to show/hide accessories in menu when interlink trailer has been added to 
    * Taillifts and hooklifts should not be options for the user to select
    * Called from SelectionWizardStep checkRow function
    * @param {object} row - accessoryStub object
    * @param {string[]} value - values entered into search bar in wizard if available. Broken into array based on spaces in text. Not used here.
    * @param {object} filtersArray - Items selected when drilling into selection wizard. Not used here.
    * @param {boolean} showAll - Show all rows. Not used here
    * @returns {boolean} - True to display row, false to hide it
    */
function filterInterlinkTrailerAccessoriesByType(row, value, filtersArray, showAll) {
    //return row.getAccessoryType() !== config.ACCESSORY_TYPES.FIFTH_WHEEL && row.getAccessoryType() !== config.ACCESSORY_TYPES.HITCH && row.getAccessoryType() !== config.ACCESSORY_TYPES.TAILLIFT;
    return filterTrailerAccessoriesByType(row) && row.getAccessoryType() !== config.ACCESSORY_TYPES.TAILLIFT && row.getAccessoryType() !== config.ACCESSORY_TYPES.HOOKLIFT;
}

function sortAccessoriesByType(arrayToSort) {
    return arrayToSort.sort(function (a, b) {
        if (a.getAccessoryTypeSortValue() < b.getAccessoryTypeSortValue()) {
            return -1;
        }
        if (a.getAccessoryTypeSortValue() > b.getAccessoryTypeSortValue()) {
            return 1;
        }
        return 0;
    });
}

function filterAccessoriesByName(row, value, filtersArray) {
    if (value.length !== undefined && value.length > 0 && value[0] !== '') {
        var returnVal = false,
            descriptionWithNoSpecialChars = row.getDescription().replace(/[^a-zA-Z0-9 ]/g, ""),
            numberOfMatches = 0;
        for (var i = 0; i < value.length; i++) {
            //if (comparison(row, filtersArray) && row.getDescription().toLowerCase().includes(value[i].toLowerCase())) {
            if (comparison(row, filtersArray) && descriptionWithNoSpecialChars.toLowerCase().includes(value[i].toLowerCase())) {
                numberOfMatches += 1;
            }
        }
        return numberOfMatches === value.length;
    } else {
        return comparison(row, filtersArray);
    }

    function comparison(row, filtersArray) {
        var returnVal = true;
        for (var i = 0; i < filtersArray.length; i++) {
            if (filtersArray[i].value !== config.ACCESSORY_SOURCE_TYPES.ALL) {
                if (filtersArray[i].value === config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL || filtersArray[i].value === config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY) {
                    if (row.getSource() === config.ACCESSORY_SOURCE_TYPES.CUSTOM) {
                        if (row[filtersArray[i].property]() !== filtersArray[i].value) {
                            returnVal = false;
                            break;
                        }
                    } else {
                        returnVal = false;
                        break;
                    }
                } else {
                    if (row[filtersArray[i].property]() !== filtersArray[i].value) {
                        returnVal = false;
                        break;
                    }
                }
                //if (row[filtersArray[i].property]() !== filtersArray[i].value) {
                //    returnVal = false;
                //    break;
                //}
            }
        }
        return returnVal;
    }
}

function sortAccessoriesByName(arrayToSort, columns) {
    return globals.sortArrayInOrderCustomTemplateStandard(arrayToSort, columns);

}
//#endregion Functions used in menu selection wizard

function createObjectWithObservables(source, object, path) {
    switch (source) {
        case config.OBJECT_TYPES.ACCESSORY:
            createAccessoryObjectWithObservables(object, path);
            break;
        case config.OBJECT_TYPES.FUEL_TANKS:
            createFuelTankObjectObservables(object, path);
            break;
        case config.OBJECT_TYPES.UPRIGHTS:
            createBodyUprightsObjectObservables(object, path);
            break;
        case config.OBJECT_TYPES.WAYPOINT:
            createWaypointObjectObservables(object, path);
            break;
        case config.OBJECT_TYPES.AXLES:
            createAxleObjectObservables(object, path);
            break;
        case config.OBJECT_TYPES.PAYLOAD:
            createPayloadObservables(object, path);
            break;
        default:
            break;
    }
}

function getObjectVisibilityPermission(object, optionalAttributeSpecificVisibiltyRuleFunc) {
    return function (path) {
        var showAttribute = true;
        if (optionalAttributeSpecificVisibiltyRuleFunc) {
            showAttribute = optionalAttributeSpecificVisibiltyRuleFunc(path);
        }
        //Rules relating to visibilty of attribute and things such as editions or trials etc could go here by doing e.g.
        // showAttribute = showAttribute && newRuleFunc()

        return showAttribute;
    }
}

function hideAttribute() {
    return false;
}

function getObjectReadOnlyPermission(object, menuSection, optionalAttributeSpecificReadOnlyRuleFunc) {
    return function (path) {
        var makeAttributeReadOnly = true;
        if (optionalAttributeSpecificReadOnlyRuleFunc) {
            makeAttributeReadOnly = optionalAttributeSpecificReadOnlyRuleFunc(path);
        }

        if (menuSection === config.MENU_SECTION.POSITION) {
            makeAttributeReadOnly = makeAttributeReadOnly && false;
        } else if (menuSection === config.MENU_SECTION.SPECIFICATION_DIMENSIONS) {
            if (object.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || object.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(object)) {
                makeAttributeReadOnly = true;
            } else if (object.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM){
                if (object.getEditLevel() === config.ITEM_EDIT_LEVEL_OPTIONS.NO_EDIT) {
                    makeAttributeReadOnly = true;
                } else {
                    makeAttributeReadOnly = false;
                }
            } else {
                makeAttributeReadOnly = optionalAttributeSpecificReadOnlyRuleFunc ? optionalAttributeSpecificReadOnlyRuleFunc(path) : false;
            }
        } else if (menuSection === config.MENU_SECTION.SPECIFICATION || menuSection === config.MENU_SECTION.SPECIFICATION_WEIGHTS) {
            if (object.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
                if (object.getEditLevel() === config.ITEM_EDIT_LEVEL_OPTIONS.NO_EDIT) {
                    makeAttributeReadOnly = true;
                } else {
                    makeAttributeReadOnly = optionalAttributeSpecificReadOnlyRuleFunc ? optionalAttributeSpecificReadOnlyRuleFunc(path) : false;
                }
            } else if (object.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM/* && utils.itemHasGraphic(object)*/) {
                if (object.getEditLevel() === config.ITEM_EDIT_LEVEL_OPTIONS.NO_EDIT) {
                    makeAttributeReadOnly = true;
                } else {
                    makeAttributeReadOnly = optionalAttributeSpecificReadOnlyRuleFunc ? optionalAttributeSpecificReadOnlyRuleFunc(path) : false;
                }
            } else {
                //makeAttributeReadOnly = makeAttributeReadOnly && false;
                makeAttributeReadOnly = optionalAttributeSpecificReadOnlyRuleFunc ? optionalAttributeSpecificReadOnlyRuleFunc(path) : false;
            }
        }


        return makeAttributeReadOnly; 
    }
}

function createAccessoryObjectWithObservables(accessory, path) {
    switch (accessory.getAccessoryType()) {
        case config.ACCESSORY_TYPES.BODY:
            if (path.indexOf(config.ACCESSORY_TYPES.BODY) === -1) {
                path += '.' + config.ACCESSORY_TYPES.BODY;
            }
            createBodyObjectWithObservables(accessory, path);

            break;
        case config.ACCESSORY_TYPES.CRANE:
            if (path.indexOf(config.OBJECT_TYPES.ACCESSORY) === -1) {
                path += '.' + config.OBJECT_TYPES.ACCESSORY;
            }
            if (path.indexOf(config.ACCESSORY_TYPES.CRANE) === -1) {
                path += '.' + config.ACCESSORY_TYPES.CRANE;
            }
            //path += '.' + config.ACCESSORY_TYPES.CRANE;
            createCraneObjectObservables(accessory, path);
            break;
        case config.ACCESSORY_TYPES.OTHER:
            if (path.indexOf(config.OBJECT_TYPES.ACCESSORY) === -1) {
                path += '.' + config.OBJECT_TYPES.ACCESSORY;
            }
            if (path.indexOf(config.ACCESSORY_TYPES.OTHER) === -1) {
                path += '.' + config.ACCESSORY_TYPES.OTHER;
            }
            //path += '.' + config.ACCESSORY_TYPES.CRANE;
            createOtherObjectObservables(accessory, path);
            break;
        case config.ACCESSORY_TYPES.FRIDGE:
            if (path.indexOf(config.OBJECT_TYPES.ACCESSORY) === -1) {
                path += '.' + config.OBJECT_TYPES.ACCESSORY;
            }
            if (path.indexOf(config.ACCESSORY_TYPES.FRIDGE) === -1) {
                path += '.' + config.ACCESSORY_TYPES.FRIDGE;
            }
            //path += '.' + config.ACCESSORY_TYPES.CRANE;
            createFridgeObjectObservables(accessory, path);
            break;
        case config.ACCESSORY_TYPES.TAILLIFT:
            if (path.indexOf(config.OBJECT_TYPES.ACCESSORY) === -1) {
                path += '.' + config.OBJECT_TYPES.ACCESSORY;
            }
            if (path.indexOf(config.ACCESSORY_TYPES.TAILLIFT) === -1) {
                path += '.' + config.ACCESSORY_TYPES.TAILLIFT;
            }
            //path += '.' + config.ACCESSORY_TYPES.CRANE;
            createTailliftObjectObservables(accessory, path);
            break;
        case config.ACCESSORY_TYPES.FIFTH_WHEEL:
            if (path.indexOf(config.OBJECT_TYPES.ACCESSORY) === -1) {
                path += '.' + config.OBJECT_TYPES.ACCESSORY;
            }
            if (path.indexOf(config.ACCESSORY_TYPES.FIFTH_WHEEL) === -1) {
                path += '.' + config.ACCESSORY_TYPES.FIFTH_WHEEL;
            }
            //path += '.' + config.ACCESSORY_TYPES.CRANE;
            createFifthWheelObjectObservables(accessory, path);
            break;
        case config.ACCESSORY_TYPES.HITCH:
            if (path.indexOf(config.OBJECT_TYPES.ACCESSORY) === -1) {
                path += '.' + config.OBJECT_TYPES.ACCESSORY;
            }
            if (path.indexOf(config.ACCESSORY_TYPES.HITCH) === -1) {
                path += '.' + config.ACCESSORY_TYPES.HITCH;
            }
            //path += '.' + config.ACCESSORY_TYPES.CRANE;
            createHitchObjectObservables(accessory, path);
            break;
        case config.ACCESSORY_TYPES.HOOKLIFT:
            if (path.indexOf(config.OBJECT_TYPES.ACCESSORY) === -1) {
                path += '.' + config.OBJECT_TYPES.ACCESSORY;
            }
            if (path.indexOf(config.ACCESSORY_TYPES.HOOKLIFT) === -1) {
                path += '.' + config.ACCESSORY_TYPES.HOOKLIFT;
            }
            //path += '.' + config.ACCESSORY_TYPES.CRANE;
            createHookliftObjectObservables(accessory, path);
            break;
        case config.ACCESSORY_TYPES.TRAILER:
            if (path === '' || path === undefined || path === null) {
                path = accessory.getChassisObjectName();
            }

            //path += '.' + config.ACCESSORY_TYPES.CRANE;
            createTrailerObjectObservables(accessory, path);
            break;
        default:
            break;
    }
}

function wrap(funcRef) {
    return function () {
        return funcRef();
    };
}

function createItemConfigWizardDrawingAOGs(length, height, width, path, originXOffset, originYOffset, originZOffset) {


    var position = new Position(originXOffset, originYOffset, originZOffset);
    var size = new Size(length, height, width);
    var startingSize = new Size(length, height, width);

    activeConfigurationWizardItemSize = size;
    originalConfigurationWizardItemSize = startingSize;


    //Dimensions
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMDRAWINGLENGTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return size.getLength();
        })
        .setter(function (newValue) {
            size.setLength(newValue);
        })
        .addOverrideMechanism(dummyFunc,
            function (val) {
                size.setLengthOverride(val);
                checkForDoubleOverrideAndPromptUserIfNecessary(path, config.VALUE_TYPE.ITEMLENGTH, activeConfigurationWizardItem);
            },
            function () {
                return startingSize.getLength();
            },
            function () {
                return size.getLengthOverride();
            },
            function () {
                if (!offerManager.getAttributeGroup(path + '.' + config.VALUE_TYPE.ITEMDRAWINGHEIGHT).override.value) {

                    size.setHeight(startingSize.getHeight());
                }
            })
        .visiblePermission(function () {
            return activeConfigurationWizardItem.getAccessoryType() !== config.ACCESSORY_TYPES.BODY;
        })
        .overridePermission(function () {
            return true;
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMDRAWINGLENGTH)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
               //changeListenerCallback('Item_X_Offset_Changed', deltaVal);
            //    size.setLength(size.getLength() + deltaVal);
            //    startingSize.setLength(size.getLength());
            //    if (activeConfigurationWizardItem) {
            //        if (activeConfigurationWizardItem.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
            //            activeConfigurationWizardItem.setLength(activeConfigurationWizardItem.getLength() + deltaVal);
            //            originalConfigurationWizardItem.setLength(originalConfigurationWizardItem.getLength() + deltaVal);
            //        }
            //        }
                var scaledProportionalHeight = originalConfigurationWizardItemSize.getHeight() * (activeConfigurationWizardItemSize.getLength() / originalConfigurationWizardItemSize.getLength());
                size.setHeight(scaledProportionalHeight);
            })
        ).addLinkedAOG(path + '.' + config.VALUE_TYPE.ITEMDRAWINGHEIGHT), path);


    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMDRAWINGHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return size.getHeight();
        })
        .setter(function (newValue) {
    size.setHeight(newValue);
        })
        //.visiblePermission(function () { return true; })
        .addOverrideMechanism(dummyFunc,
            function (val) {
                size.setHeightOverride(val);
                checkForDoubleOverrideAndPromptUserIfNecessary(path, config.VALUE_TYPE.ITEMHEIGHT, activeConfigurationWizardItem);
            },
            function () {
                return startingSize.getHeight();
            },
            function () {
                return size.getHeightOverride();
            },
            function () {
                if (!offerManager.getAttributeGroup(path + '.' + config.VALUE_TYPE.ITEMDRAWINGLENGTH).override.value) {
                    size.setLength(startingSize.getLength());
                }
            })
        .visiblePermission(function () {
            return activeConfigurationWizardItem.getAccessoryType() !== config.ACCESSORY_TYPES.BODY;
        })
        .overridePermission(function () {
            return true;
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMDRAWINGHEIGHT)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                var scaledProportionalLength = originalConfigurationWizardItemSize.getLength() * (activeConfigurationWizardItemSize.getHeight() / originalConfigurationWizardItemSize.getHeight());
                size.setLength(scaledProportionalLength);
            })
        ).addLinkedAOG(path + '.' + config.VALUE_TYPE.ITEMDRAWINGLENGTH), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMDRAWINGWIDTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return size.getWidth();
        })
        .setter(function (newValue) {
            size.setWidth(newValue);
        })
        //.visiblePermission(isTemplateOrCustomCraneWithoutGraphic)
        .visiblePermission(function () {
            return activeConfigurationWizardItem.getAccessoryType() !== config.ACCESSORY_TYPES.BODY;
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMDRAWINGWIDTH)), path);


    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETX, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return position.getX();
        })
        .setter(function (newValue) {
            position.setX(newValue);
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETX, config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_ONLY)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                //changeListenerCallback('Item_X_Offset_Changed', deltaVal);
                size.setLength(size.getLength() + deltaVal);
                startingSize.setLength(size.getLength());
                if (activeConfigurationWizardItem) {
                    if (activeConfigurationWizardItem.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
                        activeConfigurationWizardItem.setLength(activeConfigurationWizardItem.getLength() + deltaVal);
                        originalConfigurationWizardItem.setLength(originalConfigurationWizardItem.getLength() + deltaVal);
                    }
                }
            }, function (newValue, oldValue, deltaVal) {

                //changeListenerCallback('Item_X_Offset_Changed', -deltaVal);
                size.setLength(size.getLength() - deltaVal);
                startingSize.setLength(size.getLength());
                if (activeConfigurationWizardItem) {
                    if (activeConfigurationWizardItem.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
                        activeConfigurationWizardItem.setLength(activeConfigurationWizardItem.getLength() - deltaVal);
                        originalConfigurationWizardItem.setLength(originalConfigurationWizardItem.getLength() - deltaVal);
                    }
                }
            })
            .addUIAttributeValidationStep(path + '.' + config.VALUE_TYPE.ITEMDRAWINGLENGTH)
        ), path);



    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETY, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return position.getY();
        })
        .setter(function (newValue) {
            position.setY(newValue);
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETY, config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_ONLY)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                //changeListenerCallback('Item_Y_Offset_Changed', deltaVal);
                size.setHeight(size.getHeight() + deltaVal);
                startingSize.setHeight(size.getHeight());
                if (activeConfigurationWizardItem) {
                    if (activeConfigurationWizardItem.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
                        activeConfigurationWizardItem.setHeight(activeConfigurationWizardItem.getHeight() + deltaVal);
                        originalConfigurationWizardItem.setHeight(originalConfigurationWizardItem.getHeight() + deltaVal);
                    }
                }
            }, function (newValue, oldValue, deltaVal) {

                //changeListenerCallback('Item_Y_Offset_Changed', -deltaVal);
                size.setHeight(size.getHeight() - deltaVal);
                startingSize.setHeight(size.getHeight());
                if (activeConfigurationWizardItem) {
                    if (activeConfigurationWizardItem.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
                        activeConfigurationWizardItem.setHeight(activeConfigurationWizardItem.getHeight() - deltaVal);
                        originalConfigurationWizardItem.setHeight(originalConfigurationWizardItem.getHeight() - deltaVal);
                    }
                }
            })
            .addUIAttributeValidationStep(path + '.' + config.VALUE_TYPE.ITEMDRAWINGHEIGHT)
        ), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETZ, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return position.getZ();
        })
        .setter(function (newValue) {
            position.setZ(newValue);
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETZ, config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_ONLY)
            /*.addOpBlock(function (newValue, oldValue, deltaVal) {
                changeListenerCallback('Item_Z_Offset_Changed', deltaVal);
            })*/
            //#unfinished, need to support this for top view at later stage
        ), path);

    function dummyFunc() {
        return {};
    }
}

function createCostingDetailModalLicenceFeesAOGs(path, licenceFeesObject) {
    // activeConfigurationWizardItemSize = size;
    // originalConfigurationWizardItemSize = startingSize;

    // Vehicle                                                              Licence Fees
    //  Licence Mass    |   Province    |   Effective Date  |   Fees
    //  Text            |   SelectMenu  |   Text            |   Text
    // Trailer 1                                                              Licence Fees
    //  Licence Mass    |   Province    |   Effective Date  |   Fees
    //  Text            |   SelectMenu  |   Text            |   Text
    // Trailer 2                                                              Licence Fees
    //  Licence Mass    |   Province    |   Effective Date  |   Fees
    //  Text            |   SelectMenu  |   Text            |   Text
    //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMDRAWINGLENGTH, config.INPUT_TYPE.UPDOWNBOX)
    //    .getter(function () {
    //        return size.getLength();
    //    })
    //    .setter(function (newValue) {
    //        size.setLength(newValue);
    //    })
    //    .addOverrideMechanism(dummyFunc,
    //        function (val) {
    //            size.setLengthOverride(val);
    //        },
    //        function () {
    //            return startingSize.getLength();
    //        },
    //        function () {
    //            return size.getLengthOverride();
    //        }, function () {
    //            if (!offerManager.getAttributeGroup(path + '.' + config.VALUE_TYPE.ITEMDRAWINGHEIGHT).override()) {

    //                size.setHeight(startingSize.getHeight());
    //            }
    //        })
    //    .overridePermission(function () { return true; }), path);
    //    //.addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMDRAWINGLENGTH)
    //    //), path);


    //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMDRAWINGHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
    //    .getter(function () { return size.getHeight(); })
    //    .setter(function (newValue) { size.setHeight(newValue); })
    //    //.visiblePermission(function () { return true; })
    //    .addOverrideMechanism(dummyFunc,
    //        function (val) {
    //            size.setHeightOverride(val);
    //        },
    //        function () {
    //            return startingSize.getHeight();
    //        },
    //        function () {
    //            return size.getHeightOverride();
    //        }, function () {
    //            if (!offerManager.getAttributeGroup(path + '.' + config.VALUE_TYPE.ITEMDRAWINGLENGTH).override()) {
    //                size.setLength(startingSize.getLength());
    //            }
    //        })
    //    .overridePermission(function () { return true; }), path);
    //    //.addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMDRAWINGHEIGHT)
    //    //), path);

    //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMDRAWINGWIDTH, config.INPUT_TYPE.UPDOWNBOX)
    //    .getter(function () { return size.getWidth(); })
    //    .setter(function (newValue) { size.setWidth(newValue); }), path);
    //    //.visiblePermission(isTemplateOrCustomCraneWithoutGraphic)
    //    //.addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMDRAWINGWIDTH)
    //    //), path);


    //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETX, config.INPUT_TYPE.UPDOWNBOX)
    //    .getter(function () { return position.getX(); })
    //    .setter(function (newValue) {
    //        position.setX(newValue);
    //    })
    //    //.addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETX, config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_ONLY)
    //    //    .addOpBlock(function (newValue, oldValue, deltaVal) {
    //    //        //changeListenerCallback('Item_X_Offset_Changed', deltaVal);
    //    //        size.setLength(size.getLength() + deltaVal);
    //    //        startingSize.setLength(size.getLength());
    //    //    }, function (newValue, oldValue, deltaVal) {

    //    //        //changeListenerCallback('Item_X_Offset_Changed', -deltaVal);
    //    //        size.setLength(size.getLength() - deltaVal);
    //    //        startingSize.setLength(size.getLength());
    //    //    })
    //    //    .addUIAttributeValidationStep(path + '.' + config.VALUE_TYPE.ITEMDRAWINGLENGTH)
    //    ), path);



    //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETY, config.INPUT_TYPE.UPDOWNBOX)
    //    .getter(function () { return position.getY(); })
    //    .setter(function (newValue) {
    //        position.setY(newValue);
    //    })
    //    //.addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETY, config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_ONLY)
    //    //    .addOpBlock(function (newValue, oldValue, deltaVal) {
    //    //        //changeListenerCallback('Item_Y_Offset_Changed', deltaVal);
    //    //        size.setHeight(size.getHeight() + deltaVal);
    //    //        startingSize.setHeight(size.getHeight());
    //    //    }, function (newValue, oldValue, deltaVal) {

    //    //        //changeListenerCallback('Item_Y_Offset_Changed', -deltaVal);
    //    //        size.setHeight(size.getHeight() - deltaVal);
    //    //        startingSize.setHeight(size.getHeight());
    //    //    })
    //    //    .addUIAttributeValidationStep(path + '.' + config.VALUE_TYPE.ITEMDRAWINGHEIGHT)
    //    ), path);

    //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETZ, config.INPUT_TYPE.UPDOWNBOX)
    //    .getter(function () { return position.getZ(); })
    //    .setter(function (newValue) { position.setZ(newValue); })
    //    .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETZ, config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_ONLY)
    //        /*.addOpBlock(function (newValue, oldValue, deltaVal) {
    //            changeListenerCallback('Item_Z_Offset_Changed', deltaVal);
    //        })*/
    //        //#unfinished, need to support this for top view at later stage
    //    ), path);

    function dummyFunc() {
        return {};
    }
}

function createBodyObjectWithObservables(body, path) { 


    var isLoadingAdmin = globals.user.isLoadingAdministrator();

    var chassisObjectToUse = rigOps.getChassisObjectFromPath(path),
        originalChassisObjectToUse = rigOps.getOriginalChassisObjectFromPath(path);


    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYDESCRIPTION, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
        .setter(function (newValue) {
            body.setDescription(newValue);
        })
        .getter(function () {
            return body.getDescription();
        })
        //.visiblePermission(function () { return globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code); })
        .visiblePermission(getObjectVisibilityPermission(body))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION))
        .addSubscription(function (val) {
            //getActiveOffer().getConfiguration().getBody().setDescription(val);
            body.setDescription(val);
            updateBodyMenuCallback(val, path);
        })
        //.preventBlankEntry()
        //.addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYDESCRIPTION, config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_AND_OPS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYDESCRIPTION)
            .addOpBlock(function(newValue, oldValue){
                if (newValue === '') {
                    throw 1;
                }
            })
        ), path);



    var bodyCabGapAttributeGroup = new AttributeObservableGroup(config.VALUE_TYPE.BODYCABGAP, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getHorizontalGap();
        })
        .setter(function (newValue) {
            body.setHorizontalGap(newValue);
        })
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                body.setCabGapOverride(val);
                body.setFromAxle1FrontOverride(val);
            },
            function () {
                return originalChassisObjectToUse.getAccessoryHolder().getBody().getHorizontalGap();
            },
            function () {
                return body.getCabGapOverride();
            },
            function () {
                body.setFromAxle1Front(rigOps.calculateFromAxle1Front(body));
            }, true)
        .overridePermission(function () {
            return true;
        })
        .visiblePermission(getObjectVisibilityPermission(body, function () {
            return chassisObjectToUse.getChassisObjectName() !== config.CHASSIS_OBJECT_TYPES.VEHICLE || (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && (globals.user.getSpecifyBodyPositionAs() === config.SPECIFY_POSITION_AS_OPTIONS.FROM_BACK_OF_CAB || (chassisObjectToUse.getAccessoryHolder().getBody().getType() === config.BODY_TYPES.HOOKLIFT && chassisObjectToUse.getAccessoryHolder().getBody().getSubType() === config.BODY_SUB_TYPES.BODY_ONLY)));
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.POSITION));

    var bodyCabGapValidationObject = new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYCABGAP);
    //.addOp(config.VALIDATION_OP_TYPE.APPLY_NEW_VALUE, config.OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CABGAPRECOMMENDEDMIN)
    bodyCabGapValidationObject.addOpBlock(function (newValue, oldValue, deltaVal) {
        if (chassisObjectToUse.getAccessoryHolder().getTaillift() !== null) {
            chassisObjectToUse.getAccessoryHolder().getTaillift().setHorizontalGap(body.getHorizontalGap() + body.getActualLength());
        }
        if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            if (getActiveOffer().getKeepChassisFlushWithBody()) {
                handleKeepChassisAlignedWithBody();
            }
        }
        if (body.getCabGapOverride() === false) {
            body.setCabGapOverride(true);
        }
        if (body.getFromAxle1FrontOverride() === false) {
            body.setFromAxle1FrontOverride(true);
        }
        body.setFromAxle1Front(rigOps.calculateFromAxle1Front(body));
    });
    bodyCabGapValidationObject.reverseOpUndoOrder();
    //bodyCabGapValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.GAPBETWEENVEHICLEANDTRAILER, rigOps.getVehicleAndDrawbarGapBetweenVehicleAndTrailer);
    if (!isLoadingAdmin) {
        bodyCabGapValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTLENGTH, getChassisObjectOverallLength);
        bodyCabGapValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.CHASSISOBJECTREAROVERHANGTOTAL);
    }

    bodyCabGapAttributeGroup.addValidation(bodyCabGapValidationObject);

    addAttributeGroup(bodyCabGapAttributeGroup, path);



    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYFROMAXLE1FRONT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getFromAxle1Front();
        })
        .setter(function (newValue) {
            body.setFromAxle1Front(newValue);
        })
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                body.setFromAxle1FrontOverride(val);
                body.setCabGapOverride(val);
            },
            function () {
                //return chassisObjectToUse.getBumperToBackOfCab() - chassisObjectToUse.getFrontOverhang();
                return getDefaultFromAxle1Front(body);
            },
            function () {
                return body.getFromAxle1FrontOverride();
            },
            function () {
                body.setHorizontalGap(rigOps.calculateFromBackOfCab(body));
            }, true)
        .overridePermission(function () {
            return true;
        })
        .visiblePermission(getObjectVisibilityPermission(body, function () {
            return globals.user.getSpecifyBodyPositionAs() === config.SPECIFY_POSITION_AS_OPTIONS.FROM_FRONTMOST_AXLE && chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && !(chassisObjectToUse.getAccessoryHolder().getBody().getType() === config.BODY_TYPES.HOOKLIFT && chassisObjectToUse.getAccessoryHolder().getBody().getSubType() === config.BODY_SUB_TYPES.BODY_ONLY);
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.POSITION))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYFROMAXLE1FRONT)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if (body.getCabGapOverride() === false) {
                    body.setCabGapOverride(true);
                }
                if (body.getFromAxle1FrontOverride() === false) {
                    body.setFromAxle1FrontOverride(true);
                }
                body.setHorizontalGap(rigOps.calculateFromBackOfCab(body));
            })
        )
        .setAllowOpsWhileDragging(true), path);



    var bodyAboveBelowChassisAttributeGroup = new AttributeObservableGroup(config.VALUE_TYPE.BODYABOVEBELOWCHASSIS, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getAboveBelowChassis();
        })
        .setter(function (newValue) {
            body.setAboveBelowChassis(newValue);
        })
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                body.setAboveBelowChassisOverride(val);
            },
            function () {
                return originalChassisObjectToUse.getAccessoryHolder().getBody().getAboveBelowChassis();
            },
            function () {
                return body.getAboveBelowChassisOverride();
            })
        .overridePermission(function () {
            return true;
        })
        .visiblePermission(getObjectVisibilityPermission(body, function () { return body.getType() !== config.BODY_TYPES.SERVICE }))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.POSITION))
        //.visiblePermission(function () { return body.getType() !== config.BODY_TYPES.SERVICE});

    var bodyAboveBelowChassisValidationObject = new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYABOVEBELOWCHASSIS);
    //.addOp(config.VALIDATION_OP_TYPE.APPLY_NEW_VALUE, config.OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CABGAPRECOMMENDEDMIN)
    bodyAboveBelowChassisValidationObject.addOpBlock(function (newValue, oldValue, deltaVal) {
        if (chassisObjectToUse.getAccessoryHolder().getTaillift() !== null) {
            //chassisObjectToUse.getAccessoryHolder().getTaillift().setHorizontalGap(body.getHorizontalGap() + body.getActualLength());
            //#unfinished, might need to do something with vertical gap here
        }
        
        if (body.getAboveBelowChassisOverride() === false) {
            body.setAboveBelowChassisOverride(true);
        }
    });
    bodyAboveBelowChassisValidationObject.reverseOpUndoOrder();
    //bodyCabGapValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.GAPBETWEENVEHICLEANDTRAILER, rigOps.getVehicleAndDrawbarGapBetweenVehicleAndTrailer);
    if (!isLoadingAdmin) {
        bodyAboveBelowChassisValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, getChassisObjectOverallHeight);
    }

    bodyAboveBelowChassisAttributeGroup.addValidation(bodyAboveBelowChassisValidationObject);
    addAttributeGroup(bodyAboveBelowChassisAttributeGroup, path);



    var skipBodyLengthFirstStepValidationOnly;
    if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 || chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
        skipBodyLengthFirstStepValidationOnly = config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_ONLY;
    }


    var bodyLengthTypeOption = getBodyLengthTypeOptions()
        .filter(function (option) {
            if (option.id === body.getLengthType()) {
                return option;
            } else if (option.id === config.BODY_MEASUREMENT_TYPES.EXTERNAL && (body.getLengthType() === '' || body.getLengthType() === undefined || body.getLengthType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYLENGTHTYPE, config.INPUT_TYPE.SELECT)
        .setVal(bodyLengthTypeOption)
        .addSubscription(function (val) {
            if (val !== body.getLengthType()) {
                body.setLengthType(val);
                if (val === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                    body.setLength(body.getLength() + body.getHeadboardThickness() + body.getTailboardThickness());
                } else {
                    body.setLength(body.getLength() - body.getHeadboardThickness() - body.getTailboardThickness());
                }
            }

        })
        .visiblePermission(getObjectVisibilityPermission(body, function (attrPath) {
            return body.getType() !== config.BODY_TYPES.SERVICE && (body.getType() !== config.BODY_TYPES.HOOKLIFT && body.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY);
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS)), path);

    var bodyLengthAttributeGroup = new AttributeObservableGroup(config.VALUE_TYPE.BODYLENGTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getLength();
        })
        .setter(function (newvalue) {
            body.setLength(newvalue);
        })
        .visiblePermission(getObjectVisibilityPermission(body))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS));
        //.visiblePermission(isTemplateOrCustomBodyWithoutGraphic);


    var bodyLengthValidationObject = new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYLENGTH, skipBodyLengthFirstStepValidationOnly);

    bodyLengthValidationObject.addOpBlock(function (newValue, oldValue, deltaVal) {
        if (chassisObjectToUse.getAccessoryHolder().getTaillift() !== null) {
            chassisObjectToUse.getAccessoryHolder().getTaillift().setHorizontalGap(body.getHorizontalGap() + body.getActualLength());
        }
        if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {

            if (getActiveOffer().getKeepChassisFlushWithBody()) {

                handleKeepChassisAlignedWithBody();



                /*
                    
                    Public ReadOnly Property RearOverhangFlushWithBody() As Double
                        Get

                            If _Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
                                Return (_BumperToBackOfCab + _Accessories.Body.HorizontalGap + _Accessories.Body.ActualLength) -
                                        (_FrontOverhang + _Axle1FrontToAxle2Front + _Axle2FrontToAxle1Rear + _Axle1RearToAxle2Rear + _Axle2RearToAxle3Rear + _Axle3RearToAxle4Rear)
                            Else
                                Return 0
                            End If

                        End Get
                    End Property


                    Dim tempRO As Integer = RearOverhangFlushWithBody

                    If GetLengthInCurrentMeasurementSystem(_RearOverhang) > GetLengthInCurrentMeasurementSystem(tempRO) Then

                        If Not _RearOverhangAutoAdjustPrompt Then
                            RaiseEvent VehicleBodyLengthChanged("MSG7143")
                            _RearOverhangAutoAdjustPrompt = True
                        End If
                    */
            }
        }
    });
    bodyLengthValidationObject.reverseOpUndoOrder();
    //bodyLengthValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.GAPBETWEENVEHICLEANDTRAILER, rigOps.getVehicleAndDrawbarGapBetweenVehicleAndTrailer);
    if (!isLoadingAdmin) {
        if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            bodyLengthValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTLENGTH, getChassisObjectOverallLength);
        }
        bodyLengthValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.CHASSISOBJECTREAROVERHANGTOTAL);
    }


    bodyLengthAttributeGroup.addValidation(bodyLengthValidationObject);

    addAttributeGroup(bodyLengthAttributeGroup, path);

    function getFlushRearOverhang(vehicle, body) {
        var bodyRightMostPoint = 0;

        if (body.getType() === config.BODY_TYPES.HOOKLIFT && body.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
            bodyRightMostPoint = vehicle.getBumperToBackOfCab() + rigOps.getBodyGap(config.CHASSIS_OBJECT_TYPES.VEHICLE) + Math.max(body.getActualLength(), body.getSubframeLength()) + body.getRampLength() + body.getRampPlatesProtrusion();
        } else {
            bodyRightMostPoint = vehicle.getBumperToBackOfCab() + rigOps.getBodyGap(config.CHASSIS_OBJECT_TYPES.VEHICLE) + body.getActualLength() + body.getRampLength() + body.getRampPlatesProtrusion();
        }
        var vehicleChassisLengthToLastAxle = vehicle.getFrontOverhang() + vehicle.getAxle1FrontToAxle2Front() + vehicle.getAxle2FrontToAxle1Rear() + vehicle.getAxle1RearToAxle2Rear() + vehicle.getAxle2RearToAxle3Rear() + vehicle.getAxle3RearToAxle4Rear();
        var newRearOverhang = bodyRightMostPoint - vehicleChassisLengthToLastAxle;
        return newRearOverhang;
    }

    function handleKeepChassisAlignedWithBody() {
        var newRearOverhang = getFlushRearOverhang(chassisObjectToUse, body);
        var rearOverhang = chassisObjectToUse.getRearOverhang();
        var delta = rearOverhang - newRearOverhang;

        var vehicleRearOverhangValidationObject = new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REAROVERHANG);
        //vehicleRearOverhangValidationObject.addOpBlock(function (newValue, oldValue, deltaVal) {
        //    checkIfBodyAndBreakChassisLinkIfNecessary();
        //});
        if (!globals.user.isLoadingAdministrator()) {
            vehicleRearOverhangValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLVEHICLELENGTH, rigOps.getOverallVehicleLengthLegal);
            vehicleRearOverhangValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.REAROVERHANGTOTAL);
        }
        vehicleRearOverhangValidationObject.setOfferManager(offerManager);
        var passedValidation = vehicleRearOverhangValidationObject.validate(newRearOverhang, rearOverhang, delta);
        if (passedValidation) {
            chassisObjectToUse.setRearOverhang(newRearOverhang);
        } else {
            //prompt user and un link
            var keepChassisFlushWithBodyAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.KEEPCHASSISFLUSHWITHBODY);
            if (keepChassisFlushWithBodyAOG.value.value === true) {
                keepChassisFlushWithBodyAOG.value.value = false;
                configurationActionResponseHandlerCallback(config.ACTION_TYPE.ATTRIBUTE_VALUE_CHANGED, config.ACTION_ID.CHASSIS_BODY_ALIGNMENT_BROKEN_DUE_TO_VALIDATION_FAILURE);
            }
        }
        //getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REAROVERHANG).formatted(getFlushRearOverhang(chassisObjectToUse, body));
    }

    if (rigOps.getChassisTypeUsingPath(path) === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.KEEPCHASSISFLUSHWITHBODY, config.INPUT_TYPE.CHECKBOX)
            .setVal(getActiveOffer().getKeepChassisFlushWithBody())
            .addSubscription(function (val) {
                getActiveOffer().setKeepChassisFlushWithBody(val);
                if (val === true) {
                    //chassisObjectToUse.setRearOverhang(getFlushRearOverhang(chassisObjectToUse, body));
                    var oldValue = chassisObjectToUse.getRearOverhang();
                    handleKeepChassisAlignedWithBody();
                    var newValue = chassisObjectToUse.getRearOverhang();
                    if (oldValue !== newValue) {
                        undoHandler.newLinkedChangeValueOp(path + '.' + config.VALUE_TYPE.KEEPCHASSISFLUSHWITHBODY, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REAROVERHANG, oldValue, newValue);
                    }

                }
            })
            .visiblePermission(getObjectVisibilityPermission(body, function (path) {
                return chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && body.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY;
            }))
            .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS)), path);
    }

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYSUBFRAMELENGTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getSubframeLength();
        })
        .setter(function (newvalue) {
            body.setSubframeLength(newvalue);
        })
        .visiblePermission(getObjectVisibilityPermission(body, isHookliftBody))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(isTemplateOrCustomHookliftBodyWithoutGraphic)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYSUBFRAMELENGTH)), path);



    var bodyHeadboardThicknessAttributeGroup = new AttributeObservableGroup(config.VALUE_TYPE.BODYHEADBOARDTHICKNESS, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getHeadboardThickness();
        })
        .setter(function (newValue) {
            body.setHeadboardThickness(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(body, function () {
            return body.getType() !== config.BODY_TYPES.SERVICE;
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS));

    var bodyHeadboardThicknessValidationObject = new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYHEADBOARDTHICKNESS);
    if (!isLoadingAdmin) {
        bodyHeadboardThicknessValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTLENGTH, getChassisObjectOverallLength);
        bodyHeadboardThicknessValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.CHASSISOBJECTREAROVERHANGTOTAL);
    }


    bodyHeadboardThicknessAttributeGroup.addValidation(bodyHeadboardThicknessValidationObject);

    addAttributeGroup(bodyHeadboardThicknessAttributeGroup, path);



    var bodyTailboardThicknessAttributeGroup = new AttributeObservableGroup(config.VALUE_TYPE.BODYTAILBOARDTHICKNESS, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getTailboardThickness();
        })
        .setter(function (newValue) {
            body.setTailboardThickness(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(body, function () {
            return (body.getType() !== config.BODY_TYPES.HOOKLIFT || (body.getType() === config.BODY_TYPES.HOOKLIFT && body.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY)) && body.getType() !== config.BODY_TYPES.SERVICE && body.getType() !== config.BODY_TYPES.ROLLBACK;
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS));

    var bodyTailboardThicknessValidationObject = new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYTAILBOARDTHICKNESS);
    if (!isLoadingAdmin) {
        bodyTailboardThicknessValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTLENGTH, getChassisObjectOverallLength);
        bodyTailboardThicknessValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.CHASSISOBJECTREAROVERHANGTOTAL);
    }


    bodyTailboardThicknessAttributeGroup.addValidation(bodyTailboardThicknessValidationObject);

    addAttributeGroup(bodyTailboardThicknessAttributeGroup, path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYHINGEPOINT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getHingePoint();
        })
        .setter(function (newValue) {
            body.setHingePoint(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(body, function () {
            return body.getType() === config.BODY_TYPES.TIPPER && isTemplateBody(path);
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYHINGEPOINT)), path);




    var bodySubframeHeightAttributeGroup = new AttributeObservableGroup(config.VALUE_TYPE.BODYSUBFRAMEHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getSubframeHeight();
        })
        .setter(function (newValue) {
            body.setSubframeHeight(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(body, function () {
            return !(chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 || chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2);
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS));

    var bodySubframeHeightValidationObject = new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYSUBFRAMEHEIGHT);
    if (!isLoadingAdmin) {
        bodySubframeHeightValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, getChassisObjectOverallHeight);
    }
    bodySubframeHeightAttributeGroup.addValidation(bodySubframeHeightValidationObject);

    addAttributeGroup(bodySubframeHeightAttributeGroup, path);



    var bodySubstructureHeightAttributeGroup = new AttributeObservableGroup(config.VALUE_TYPE.BODYSUBSTRUCTUREHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getSubstructureHeight();
        })
        .setter(function (newValue) {
            body.setSubstructureHeight(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(body))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS));

    var bodySubstructureHeightValidationObject = new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYSUBSTRUCTUREHEIGHT);
    if (!isLoadingAdmin) {
        bodySubstructureHeightValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, getChassisObjectOverallHeight);
    }

    bodySubstructureHeightAttributeGroup.addValidation(bodySubstructureHeightValidationObject);

    addAttributeGroup(bodySubstructureHeightAttributeGroup, path);



    var bodyBottomOfBodyToTopOfChassisAttributeGroup = new AttributeObservableGroup(config.VALUE_TYPE.BODYBOTTOMOFBODYTOTOPOFCHASSIS, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getBottomOfBodyToTopOfChassis();
        })
        .setter(function (newValue) {
            body.setBottomOfBodyToTopOfChassis(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(body, function (attrPath) {
            return body.getType() === config.BODY_TYPES.SERVICE /*|| body.getType() === config.BODY_TYPES.BEAVERTAIL || body.getType() === config.BODY_TYPES.COMPACTOR || body.getType() === config.BODY_TYPES.OTHER*/;
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS));

    var bodyBottomOfBodyToTopOfChassisValidationObject = new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYBOTTOMOFBODYTOTOPOFCHASSIS);
    if (!isLoadingAdmin) {
        bodyBottomOfBodyToTopOfChassisValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, getChassisObjectOverallHeight);
    }

    bodyBottomOfBodyToTopOfChassisAttributeGroup.addValidation(bodyBottomOfBodyToTopOfChassisValidationObject);

    addAttributeGroup(bodyBottomOfBodyToTopOfChassisAttributeGroup, path);


    var bodyHeightTypeOption = getBodyLengthTypeOptions()
        .filter(function (option) {
            if (option.id === body.getHeightType()) {
                return option;
            } else if (option.id === config.BODY_MEASUREMENT_TYPES.EXTERNAL && (body.getHeightType() === '' || body.getHeightType() === undefined || body.getHeightType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYHEIGHTTYPE, config.INPUT_TYPE.SELECT)
        .setVal(bodyHeightTypeOption)
        .addSubscription(function (val) {
            if (val !== body.getHeightType()) {
                body.setHeightType(val);
                if (val === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                    //body.setHeight(body.getHeight() + body.getSubstructureHeight() + body.getRoofThickness());
                    if (body.getType() === config.BODY_TYPES.TIPPER || body.getType() === config.BODY_TYPES.DROPSIDE) {
                        body.setHeight(body.getHeight() + body.getSubstructureHeight());
                    } else if (body.getType() === config.BODY_TYPES.FLATDECK) {
                        body.setHeight(Math.max(body.getHeadboardHeight(), body.getTailboardHeight()));
                    } else if (body.getType() === config.BODY_TYPES.ROLLBACK) {
                        body.setHeight(body.getHeadboardHeight());
                    } else if (body.getType() === config.BODY_TYPES.TIMBER) {
                        body.setHeight(Math.max(body.getHeadboardHeight(), body.getTailboardHeight()));
                    } else if (body.getType() === config.BODY_TYPES.TANKER) {
                        body.setHeight(body.getHeight() + body.getSubstructureHeight());
                    } else if (body.getType() === config.BODY_TYPES.HOOKLIFT && body.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
                        body.setHeight(Math.max(body.getHeadboardHeight(), body.getHeight() + body.getSubstructureHeight()));
                    } else if (body.getType() === config.BODY_TYPES.SERVICE /*|| this._type === config.BODY_TYPES.BEAVERTAIL || this._type === config.BODY_TYPES.COMPACTOR || this._type === config.BODY_TYPES.OTHER*/) {
                        body.setHeight(body.getHeight() + body.getSubframeHeight() + body.getSubstructureHeight() + body.getBottomOfBodyToTopOfChassis());
                    } else {
                        //for van, cattle carrier, compactor, tautliner, pig carrier, sheep carrier
                        body.setHeight(body.getHeight() + body.getSubstructureHeight() + body.getRoofThickness());
                    }
                } else {

                    if (body.getType() === config.BODY_TYPES.TIPPER || body.getType() === config.BODY_TYPES.DROPSIDE) {
                        body.setHeight(body.getHeight() - body.getSubstructureHeight());
                    } else if (body.getType() === config.BODY_TYPES.FLATDECK) {
                        body.setHeight(Math.max(body.getHeadboardHeight(), body.getTailboardHeight()));
                    } else if (body.getType() === config.BODY_TYPES.ROLLBACK) {
                        body.setHeight(body.getHeadboardHeight());
                    } else if (body.getType() === config.BODY_TYPES.TIMBER) {
                        body.setHeight(Math.max(body.getHeadboardHeight(), body.getTailboardHeight()));
                    } else if (body.getType() === config.BODY_TYPES.TANKER) {
                        body.setHeight(body.getHeight() - body.getSubstructureHeight());
                    } else if (body.getType() === config.BODY_TYPES.HOOKLIFT && body.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
                        body.setHeight(Math.max(body.getHeadboardHeight(), body.getHeight() - body.getSubstructureHeight()));
                    } else if (body.getType() === config.BODY_TYPES.SERVICE /*|| this._type === config.BODY_TYPES.BEAVERTAIL || this._type === config.BODY_TYPES.COMPACTOR || this._type === config.BODY_TYPES.OTHER*/) {
                        body.setHeight(body.getHeight() - body.getSubframeHeight() - body.getSubstructureHeight() - body.getBottomOfBodyToTopOfChassis());
                    } else {
                        //for van, cattle carrier, compactor, tautliner, pig carrier, sheep carrier
                        body.setHeight(body.getHeight() - body.getSubstructureHeight() - body.getRoofThickness());
                    }
                }
            }

        })
        .visiblePermission(getObjectVisibilityPermission(body, function (attrPath) {
            return showBodyHeightTypeAttribute();
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS)), path);

    function showBodyHeightTypeAttribute() {
        return (body.getType() !== config.BODY_TYPES.HOOKLIFT || (body.getType() === config.BODY_TYPES.HOOKLIFT && body.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY)) && body.getType() !== config.BODY_TYPES.FLATDECK && body.getType() !== config.BODY_TYPES.ROLLBACK && body.getType() !== config.BODY_TYPES.TIMBER && body.getType() !== config.BODY_TYPES.SERVICE;
    }

    var bodyHeightAttributeGroup = new AttributeObservableGroup(config.VALUE_TYPE.BODYHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getHeight();
        })
        .setter(function (newValue) {
            body.setHeight(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(body, function () {
            var bodyType = body.getType();
            return !(bodyType === config.BODY_TYPES.FLATDECK || bodyType === config.BODY_TYPES.ROLLBACK || bodyType === config.BODY_TYPES.TIMBER);
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS));
        //.visiblePermission(function () {
        //    var bodyType = body.getType();
        //    return isTemplateOrCustomBodyWithoutGraphic(path) && !(bodyType === config.BODY_TYPES.FLATDECK || bodyType === config.BODY_TYPES.ROLLBACK || bodyType === config.BODY_TYPES.TIMBER);
        //});

    var bodyHeightValidationObject = new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYHEIGHT);
    if (!isLoadingAdmin) {
        bodyHeightValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, getChassisObjectOverallHeight);
    }

    bodyHeightValidationObject.addOpBlock(function (newValue, oldValue, deltaVal) {
        var tempTaillift = chassisObjectToUse.getAccessoryHolder().getTaillift();
        if (tempTaillift !== null && tempTaillift.getType() === config.TAILLIFT_TYPES.COLUMN_UPPER_BOX) {
            tempTaillift.setPlatformHeight(chassisObjectToUse.getAccessoryHolder().getBody().getActualExternalHeight() - chassisObjectToUse.getAccessoryHolder().getBody().getSubframeHeight());
        }

    });

    bodyHeightAttributeGroup.addValidation(bodyHeightValidationObject);

    addAttributeGroup(bodyHeightAttributeGroup, path);




    var bodyRoofThicknessAttributeGroup = new AttributeObservableGroup(config.VALUE_TYPE.BODYROOFTHICKNESS, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getRoofThickness();
        })
        .setter(function (newValue) {
            body.setRoofThickness(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(body, function () {
            var bodyType = body.getType();
            return (bodyType === config.BODY_TYPES.COMPACTOR || bodyType === config.BODY_TYPES.TAUTLINER ||
                bodyType === config.BODY_TYPES.VAN || bodyType === config.BODY_TYPES.PIGCARRIER || bodyType === config.BODY_TYPES.SHEEPCARRIER ||
                bodyType === config.BODY_TYPES.CATTLECARRIER || bodyType === config.BODY_TYPES.OTHER)
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS));

    var bodyRoofThicknessValidationObject = new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYROOFTHICKNESS);
    if (!isLoadingAdmin) {
        bodyRoofThicknessValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, getChassisObjectOverallHeight);
    }


    bodyRoofThicknessAttributeGroup.addValidation(bodyRoofThicknessValidationObject);

    addAttributeGroup(bodyRoofThicknessAttributeGroup, path);




    var bodyHeadboardHeightAttributeGroup = new AttributeObservableGroup(config.VALUE_TYPE.BODYHEADBOARDHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getHeadboardHeight();
        })
        .setter(function (newValue) {
            body.setHeadboardHeight(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(body, function () {
            var bodyType = body.getType();
            return (bodyType === config.BODY_TYPES.FLATDECK || bodyType === config.BODY_TYPES.DROPSIDE ||
                bodyType === config.BODY_TYPES.TIPPER || bodyType === config.BODY_TYPES.ROLLBACK || bodyType === config.BODY_TYPES.TIMBER ||
                bodyType === config.BODY_TYPES.BEAVERTAIL || (bodyType === config.BODY_TYPES.HOOKLIFT && body.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY));
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS));

    var bodyHeadboardHeightValidationObject = new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYHEADBOARDHEIGHT);
    if (!isLoadingAdmin) {
        bodyHeadboardHeightValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, getChassisObjectOverallHeight);
    }


    bodyHeadboardHeightAttributeGroup.addValidation(bodyHeadboardHeightValidationObject);

    addAttributeGroup(bodyHeadboardHeightAttributeGroup, path);



    var bodyTailboardHeightAttributeGroup = new AttributeObservableGroup(config.VALUE_TYPE.BODYTAILBOARDHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getTailboardHeight();
        })
        .setter(function (newValue) {
            body.setTailboardHeight(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(body, function () {
            var bodyType = body.getType();
            return (bodyType === config.BODY_TYPES.FLATDECK || bodyType === config.BODY_TYPES.TIMBER);
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS));


    var bodyTailboardHeightValidationObject = new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYTAILBOARDHEIGHT);
    if (!isLoadingAdmin) {
        bodyTailboardHeightValidationObject.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, getChassisObjectOverallHeight);
    }


    bodyTailboardHeightAttributeGroup.addValidation(bodyTailboardHeightValidationObject);

    addAttributeGroup(bodyTailboardHeightAttributeGroup, path);


    var bodyWidthTypeOption = getBodyLengthTypeOptions()
        .filter(function (option) {
            if (option.id === body.getWidthType()) {
                return option;
            } else if (option.id === config.BODY_MEASUREMENT_TYPES.EXTERNAL && (body.getWidthType() === '' || body.getWidthType() === undefined || body.getWidthType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYWIDTHTYPE, config.INPUT_TYPE.SELECT)
        .setVal(bodyWidthTypeOption)
        .addSubscription(function (val) {
            if (val !== body.getWidthType()) {
                body.setWidthType(val);
                if (val === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                    body.setWidth(body.getWidth() + body.getSidewallLeft() + body.getSidewallRight());
                } else {
                    body.setWidth(body.getWidth() - body.getSidewallLeft() - body.getSidewallRight());
                }
            }

        })
        .visiblePermission(getObjectVisibilityPermission(body, showBodyWidthRelatedAttribute))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS)), path);



    let bodyWidthAOG = new AttributeObservableGroup(config.VALUE_TYPE.BODYWIDTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getWidth();
        })
        .setter(function (newValue) {
            body.setWidth(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(body))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS));

    let bodyWidthAOGValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYWIDTH);
    bodyWidthAOGValidation.addOpBlock(function(newValue, oldValue, deltaVal){
        rigOps.updateFromLeftOfBodyWidthExternalForAllChassisItems(deltaVal, path);
    });
    if(!globals.user.isLoadingAdministrator()) {
        bodyWidthAOGValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.COMBINATIONOVERALLWIDTH, rigOps.getCombinationOverallWidth);
    }
    bodyWidthAOG.addValidation(bodyWidthAOGValidation);

    addAttributeGroup(bodyWidthAOG, path);



    let bodySidewallLeftAOG = new AttributeObservableGroup(config.VALUE_TYPE.BODYSIDEWALLLEFT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getSidewallLeft();
        })
        .setter(function (newValue) {
            body.setSidewallLeft(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(body, showBodySidewallAttribute))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS));

    let bodySidewallLeftAOGValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYSIDEWALLLEFT);
    if(!globals.user.isLoadingAdministrator()) {
        bodySidewallLeftAOGValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.COMBINATIONOVERALLWIDTH, rigOps.getCombinationOverallWidth);
    }
    bodySidewallLeftAOG.addValidation(bodySidewallLeftAOGValidation);
    
    addAttributeGroup(bodySidewallLeftAOG, path);



    let bodySidewallRightAOG = new AttributeObservableGroup(config.VALUE_TYPE.BODYSIDEWALLRIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getSidewallRight();
        })
        .setter(function (newValue) {
            body.setSidewallRight(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(body, showBodySidewallAttribute))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS));
        
        let bodySidewallRightAOGValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYSIDEWALLRIGHT);
        if(!globals.user.isLoadingAdministrator()) {
            bodySidewallRightAOGValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.COMBINATIONOVERALLWIDTH, rigOps.getCombinationOverallWidth);
        }
        bodySidewallRightAOG.addValidation(bodySidewallRightAOGValidation);

        addAttributeGroup(bodySidewallRightAOG, path);



    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYLEFTCOMPARTMENTWIDTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getLeftCompartmentWidth();
        })
        .setter(function (newValue) {
            body.setLeftCompartmentWidth(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(body, function () {
            return body.getType() === config.BODY_TYPES.SERVICE;
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYLEFTCOMPARTMENTWIDTH)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYRIGHTCOMPARTMENTWIDTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getRightCompartmentWidth();
        })
        .setter(function (newValue) {
            body.setRightCompartmentWidth(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(body, function () {
            return body.getType() === config.BODY_TYPES.SERVICE;
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYRIGHTCOMPARTMENTWIDTH)), path);




    

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYSIDEDOORS, config.INPUT_TYPE.CHECKBOX)
        .visiblePermission(getObjectVisibilityPermission(body, function () { return body.getType() === config.BODY_TYPES.VAN;}))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        .setVal(body.getSideDoors())
        .addSubscription(function (val) {
            body.setSideDoors(val);
        }), path);

    //var sideDoorTypeOption = getSideDoorTypeOptions()
    //    .filter(function (option) {
    //        if (option.id === body.getSideDoorType()) {
    //            return option;
    //        } else if (option.id === config.SIDE_DOOR_TYPES.SINGLE && (body.getSideDoorType() === '' || body.getSideDoorType() === undefined || body.getSideDoorType() === null)) {
    //            return option;
    //        }
    //    })[0];

    //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYSIDEDOORTYPE, config.INPUT_TYPE.SELECT)
    //    .addSubscription(function (val) {
    //        body.setSideDoorType(val);
    //    })
    //    .visiblePermission(showBodySideDoorAttribute)
    //    .setVal(sideDoorTypeOption), path);

    function getSideDoorTypeOption() {
        var sideDoorTypeOption = getSideDoorTypeOptions()
            .filter(function (option) {
                if (option.id === body.getSideDoorType()) {
                    return option;
                } else if (option.id === config.SIDE_DOOR_TYPES.SINGLE && (body.getSideDoorType() === '' || body.getSideDoorType() === undefined || body.getSideDoorType() === null)) {
                    return option;
                }
            })[0];
        return sideDoorTypeOption;
    }

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYSIDEDOORTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getSideDoorTypeOption())
        //.optionGetter(getSideDoorTypeOption)
        .visiblePermission(getObjectVisibilityPermission(body, showBodySideDoorAttribute))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        .addSubscription(function (val) {
            body.setSideDoorType(val);
        }), path);

    if (body.getSideDoorType() === '') {
        body.setSideDoorType(getSideDoorTypeOption().id);
    }


    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYSIDEDOORFRONTTODOOR, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getSideDoorFrontToDoor();
        })
        .setter(function (newValue) {
            body.setSideDoorFrontToDoor(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(body, showBodySideDoorAttribute))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYSIDEDOORFRONTTODOOR)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYSIDEDOORWIDTHPERDOOR, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getSideDoorWidthPerDoor();
        })
        .setter(function (newValue) {
            body.setSideDoorWidthPerDoor(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(body, showBodySideDoorAttribute))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYSIDEDOORWIDTHPERDOOR)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYSIDEDOORHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getSideDoorHeight();
        })
        .setter(function (newValue) {
            body.setSideDoorHeight(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(body, showBodySideDoorAttribute))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYSIDEDOORHEIGHT)), path);


    function getBodyMassTypeOption() {
        var massTypeOption = getMassTypeOptions()
            .filter(function (option) {
                if (option.id === body.getMassType()) {
                    return option;
                } else if (option.id === config.MASS_TYPES.MASS && (body.getMassType() === '' || body.getMassType() === undefined || body.getMassType() === null)) {
                    return option;
                }
            })[0];
        return massTypeOption;
    }


    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYMASSTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getBodyMassTypeOption())
        .optionGetter(getBodyMassTypeOption)
        .addSubscription(function (val) {
            if (val !== body.getMassType()) {
                body.setMassType(val);
                if (val === config.MASS_TYPES.MASSPERDISTANCE) {
                    if (body.getType() === config.BODY_TYPES.HOOKLIFT && body.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
                        body.setMass(utils.returnValueOrZeroIfNanOrInfinity(body.getMass() / utils.returnValueOrZeroIfNanOrInfinity((body.getSubframeLength() / 1000))));
                    } else {
                        body.setMass(utils.returnValueOrZeroIfNanOrInfinity(body.getMass() / utils.returnValueOrZeroIfNanOrInfinity((body.getActualLength() / 1000))));
                    }
                } else {
                    if (body.getType() === config.BODY_TYPES.HOOKLIFT && body.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
                        body.setMass(body.getMass() * (body.getSubframeLength() / 1000))
                    } else {
                        body.setMass(body.getMass() * (body.getActualLength() / 1000));
                    }
                }
            }
        })
        .visiblePermission(getObjectVisibilityPermission(body))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYMASSTYPE, config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_ONLY)
        //    .addOpBlock(function (newValue, oldValue, deltaVal) {

        //    })
        //)
        , path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(function () {
            return body.getMass();
        })
        .setter(function (newValue) {
            body.setMass(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                body.setMassOverride(val);
            },
            function () {
                return originalChassisObjectToUse.getAccessoryHolder().getBody().getMass();
            },
            function () {
                return body.getMassOverride();
            })
        .overridePermission(showOverrideForStandardBody)
        .visiblePermission(getObjectVisibilityPermission(body))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYMASS))
        .addFormattingRule(function () {
            return body.getMassType() === config.MASS_TYPES.MASSPERDISTANCE
        },
            getUIFormattedLinearDensityValueNoUnit,
            config.INCREMENT_TYPE.LINEARDENSITY,
            function (newValue) {
                if (body.getType() === config.BODY_TYPES.HOOKLIFT && body.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
                    return newValue * body.getSubframeLength() / 1000;
                } else {
                    return newValue * body.getActualLength() / 1000;
                }

            }), path);

    function getBodyHorizontalCOGTypeOption() {
        var horizontalCOGTypeOption = getCOGTypeOptions()
            .filter(function (option) {
                if (option.id === body.getCOGType()) {
                    return option;
                } else if (option.id === config.COG_TYPES.LENGTH && (body.getCOGType() === '' || body.getCOGType() === undefined || body.getCOGType() === null)) {
                    return option;
                }
            })[0];

        return horizontalCOGTypeOption;
    }


    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYHORIZONTALCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getBodyHorizontalCOGTypeOption())
        .optionGetter(getBodyHorizontalCOGTypeOption)
        .addSubscription(function (val) {

            if (val !== body.getCOGType()) {
                body.setCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    body.setHorizontalCOG(body.getActualLength() * body.getHorizontalCOG() / 100);
                } else {
                    body.setHorizontalCOG(utils.returnValueOrZeroIfNanOrInfinity((body.getHorizontalCOG() / body.getActualLength()) * 100));
                }
            }
        })
        .visiblePermission(getObjectVisibilityPermission(body))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        , path);

    function getBodyHorizontalCogFormattedValue(value) {
        if (body.getCOGType() === config.COG_TYPES.PERCENTAGE) {
            return getUIFormattedPercentageValueNoUnit(value);
        } else {
            return getUIFormattedLengthValueNoUnit(value);
        }
    }

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYHORIZONTALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getHorizontalCOG();
        })
        .setter(function (newValue) {
            body.setHorizontalCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                body.setHorizontalCOGOverride(val);
            },
            function () {
                //return originalChassisObjectToUse.getAccessoryHolder().getBody().getHorizontalCOG();
                var originalBody = originalChassisObjectToUse.getAccessoryHolder().getBody();
                if (body.getCOGType() === config.COG_TYPES.LENGTH && originalBody.getCOGType() === config.COG_TYPES.LENGTH || body.getCOGType() === config.COG_TYPES.PERCENTAGE && originalBody.getCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalBody.getHorizontalCOG();
                } else if (body.getCOGType() === config.COG_TYPES.LENGTH && originalBody.getCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalBody.getHorizontalCOG() * body.getActualLength() / 100;
                } else if (body.getCOGType() === config.COG_TYPES.PERCENTAGE && originalBody.getCOGType() === config.COG_TYPES.LENGTH) {
                    return originalBody.getHorizontalCOG() / body.getActualLength() * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return body.getHorizontalCOGOverride();
            })
        .overridePermission(showOverrideForStandardBody)
        .visiblePermission(getObjectVisibilityPermission(body))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        // .alwaysNotifyValueSubscribers()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYHORIZONTALCOG)
            //.addOpBlock(function (newValue, oldValue, deltaVal) {

            //})
        )
        .addFormattingRule(function () {
            return body.getCOGType() === config.COG_TYPES.PERCENTAGE
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);
    //.addFormattingRule(
    //    function () { return true; }, 
    //    getBodyHorizontalCogFormattedValue, 
    //    config.INCREMENT_TYPE.PERCENTAGE,
    //    function (newValue) {
    //        TScMessenger.writeDebugMessage('');
    //        if (body.getCOGType() === config.COG_TYPES.PERCENTAGE) {
    //            TScMessenger.writeDebugMessage('');
    //        }
    //    }
    //), path);

    function getBodyVerticalCOGTypeOption() {
        var verticalCOGTypeOption = getCOGTypeOptions()
            .filter(function (option) {
                if (option.id === body.getVerticalCOGType()) {
                    return option;
                } else if (option.id === config.COG_TYPES.LENGTH && (body.getVerticalCOGType() === '' || body.getVerticalCOGType() === undefined || body.getVerticalCOGType() === null)) {
                    return option;
                }
            })[0];

        return verticalCOGTypeOption;
    }

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYVERTICALCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getBodyVerticalCOGTypeOption())
        .optionGetter(getBodyVerticalCOGTypeOption)
        .visiblePermission(getObjectVisibilityPermission(body))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .addSubscription(function (val) {
            if (val !== body.getVerticalCOGType()) {
                body.setVerticalCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    if (body.getType() === config.BODY_TYPES.HOOKLIFT && body.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
                        body.setVerticalCOG((body.getHeadboardHeight() + body.getSubframeHeight() + body.getSubstructureHeight()) * body.getVerticalCOG() / 100);
                    } else {
                        body.setVerticalCOG(body.getActualExternalHeight() * body.getVerticalCOG() / 100);
                    }

                } else {
                    if (body.getType() === config.BODY_TYPES.HOOKLIFT && body.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
                        body.setVerticalCOG(utils.returnValueOrZeroIfNanOrInfinity((body.getVerticalCOG() / (body.getHeadboardHeight() + body.getSubframeHeight() + body.getSubstructureHeight())) * 100));
                    } else {
                        body.setVerticalCOG(utils.returnValueOrZeroIfNanOrInfinity((body.getVerticalCOG() / body.getActualExternalHeight()) * 100));
                    }

                }
            }
        }), path);

    function getBodyVerticalCogFormattedValue(value) {
        if (body.getVerticalCOG() === config.COG_TYPES.PERCENTAGE) {
            return getUIFormattedPercentageValueNoUnit(value);
        } else {
            return getUIFormattedLengthValueNoUnit(value);
        }
    }

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYVERTICALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getVerticalCOG();
        })
        .setter(function (newValue) {
            body.setVerticalCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                body.setVerticalCOGOverride(val);
            },
            function () {
                //return originalChassisObjectToUse.getAccessoryHolder().getBody().getVerticalCOG();
                var originalBody = originalChassisObjectToUse.getAccessoryHolder().getBody();
                if (body.getVerticalCOGType() === config.COG_TYPES.LENGTH && originalBody.getVerticalCOGType() === config.COG_TYPES.LENGTH || body.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE && originalBody.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalBody.getVerticalCOG();
                } else if (body.getVerticalCOGType() === config.COG_TYPES.LENGTH && originalBody.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalBody.getVerticalCOG() * body.getActualExternalHeight() / 100;
                } else if (body.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE && originalBody.getVerticalCOGType() === config.COG_TYPES.LENGTH) {
                    return originalBody.getVerticalCOG() / body.getActualExternalHeight() * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return body.getVerticalCOGOverride();
            })
        .overridePermission(showOverrideForStandardBody)
        .visiblePermission(getObjectVisibilityPermission(body))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        // .alwaysNotifyValueSubscribers()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYVERTICALCOG))
        .addFormattingRule(function () {
            return body.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE;
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);
    //.addFormattingRule(
    //    function () { return true; }, 
    //    getBodyVerticalCogFormattedValue, 
    //    function () { return body.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE ? config.INCREMENT_TYPE.PERCENTAGE : config.INCREMENT_TYPE.LENGTH; }
    //), path);

    function getBodyLateralCOGTypeOption() {
        var lateralCOGTypeOption = getCOGTypeOptions()
            .filter(function (option) {
                if (option.id === body.getLateralCOGType()) {
                    return option;
                } else if (option.id === config.COG_TYPES.LENGTH && (body.getLateralCOGType() === '' || body.getLateralCOGType() === undefined || body.getLateralCOGType() === null)) {
                    return option;
                }
            })[0];

        return lateralCOGTypeOption;
    }

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYLATERALCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getBodyLateralCOGTypeOption())
        .optionGetter(getBodyLateralCOGTypeOption)
        .visiblePermission(getObjectVisibilityPermission(body))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .addSubscription(function (val) {
            if (val !== body.getLateralCOGType()) {
                body.setLateralCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    body.setLateralCOG(body.getActualWidth() * body.getLateralCOG() / 100);
                } else {
                    body.setLateralCOG(utils.returnValueOrZeroIfNanOrInfinity((body.getLateralCOG() / body.getActualWidth()) * 100));
                }
            }
        }), path);

    function getBodyLateralCogFormattedValue(value) {
        if (body.getLateralCOG() === config.COG_TYPES.PERCENTAGE) {
            return getUIFormattedPercentageValueNoUnit(value);
        } else {
            return getUIFormattedLengthValueNoUnit(value);
        }
    }

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYLATERALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getLateralCOG();
        })
        .setter(function (newValue) {
            body.setLateralCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                body.setLateralCOGOverride(val);
            },
            function () {
                //return originalChassisObjectToUse.getAccessoryHolder().getBody().getLateralCOG();
                var originalBody = originalChassisObjectToUse.getAccessoryHolder().getBody();
                if (body.getLateralCOGType() === config.COG_TYPES.LENGTH && originalBody.getLateralCOGType() === config.COG_TYPES.LENGTH || body.getLateralCOGType() === config.COG_TYPES.PERCENTAGE && originalBody.getLateralCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalBody.getLateralCOG();
                } else if (body.getLateralCOGType() === config.COG_TYPES.LENGTH && originalBody.getLateralCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalBody.getLateralCOG() * body.getActualWidth() / 100;
                } else if (body.getLateralCOGType() === config.COG_TYPES.PERCENTAGE && originalBody.getLateralCOGType() === config.COG_TYPES.LENGTH) {
                    return originalBody.getLateralCOG() / body.getActualWidth() * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return body.getLateralCOGOverride();
            })
        .overridePermission(showOverrideForStandardBody)
        .visiblePermission(getObjectVisibilityPermission(body))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        // .alwaysNotifyValueSubscribers()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYLATERALCOG))
        .addFormattingRule(function () {
            return body.getLateralCOGType() === config.COG_TYPES.PERCENTAGE;
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);
    //.addFormattingRule(
    //    function () { return true; }, 
    //    getBodyLateralCogFormattedValue, 
    //    function () { return body.getLateralCOGType() === config.COG_TYPES.PERCENTAGE ? config.INCREMENT_TYPE.PERCENTAGE : config.INCREMENT_TYPE.LENGTH; }
    //), path);

    ////RAMP ATTRIBUTES
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.RAMPLENGTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getRampLength();
        })
        .setter(function (newvalue) {
            body.setRampLength(newvalue);
        })
        .visiblePermission(getObjectVisibilityPermission(body, isTemplateOrCustomBeaverTailBodyWithoutGraphic))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.RAMPLENGTH)
            .addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTLENGTH, getChassisObjectOverallLength)
            .addBackEndAttributeValidationStep(config.VALUE_TYPE.CHASSISOBJECTREAROVERHANGTOTAL)
            .addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, getChassisObjectOverallHeight)
        ), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.RAMPANGLE, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.DEGREES)
        .getter(function () {
            return body.getRampAngle();
        })
        .setter(function (newvalue) {
            body.setRampAngle(newvalue);
        })
        .visiblePermission(getObjectVisibilityPermission(body, isTemplateOrCustomBeaverTailBodyWithoutGraphic))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.RAMPANGLE)
            .addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, getChassisObjectOverallHeight)
        ), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.RAMPPLATESPROTRUSION, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getRampPlatesProtrusion();
        })
        .setter(function (newvalue) {
            body.setRampPlatesProtrusion(newvalue);
        })
        .visiblePermission(getObjectVisibilityPermission(body, isTemplateOrCustomBeaverTailBodyWithoutGraphic))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.RAMPPLATESPROTRUSION)
            .addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTLENGTH, getChassisObjectOverallLength)
            .addBackEndAttributeValidationStep(config.VALUE_TYPE.CHASSISOBJECTREAROVERHANGTOTAL)
        ), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.RAMPPLATESHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getRampPlatesHeight();
        })
        .setter(function (newvalue) {
            body.setRampPlatesHeight(newvalue);
        })
        .visiblePermission(getObjectVisibilityPermission(body, isTemplateOrCustomBeaverTailBodyWithoutGraphic))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.RAMPPLATESHEIGHT)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, getChassisObjectOverallHeight)
        ), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.RAMPPLATESMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(function () {
            return body.getRampPlatesMass();
        })
        .setter(function (newValue) {
            body.setRampPlatesMass(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(body, isTemplateOrCustomBeaverTailBodyWithoutGraphic))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.RAMPPLATESMASS)), path);

    var rampPlatesCOGTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === body.getRampPlatesCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (body.getRampPlatesCOGType() === '' || body.getRampPlatesCOGType() === undefined || body.getRampPlatesCOGType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.RAMPPLATESCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(rampPlatesCOGTypeOption)
        //.visiblePermission(function () { return body.getType() === config.BODY_TYPES.BEAVERTAIL && showUnlessStandardBodyAndNotAdmin(path); })
        .visiblePermission(getObjectVisibilityPermission(body, isTemplateOrCustomBeaverTailBodyWithoutGraphic))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .addSubscription(function (val) {
            if (val !== body.getRampPlatesCOGType()) {
                body.setRampPlatesCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    body.setRampPlatesHorizontalCOG(body.getRampPlatesProtrusion() * body.getRampPlatesHorizontalCOG() / 100);
                } else {
                    body.setRampPlatesHorizontalCOG(utils.returnValueOrZeroIfNanOrInfinity((body.getRampPlatesHorizontalCOG() / body.getRampPlatesProtrusion()) * 100));
                }
            }
        }), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.RAMPPLATESHORIZONTALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getRampPlatesHorizontalCOG();
        })
        .setter(function (newValue) {
            body.setRampPlatesHorizontalCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                body.setRampPlatesHorizontalCOGOverride(val);
            },
            function () {
                //return originalChassisObjectToUse.getAccessoryHolder().getBody().getRampPlatesHorizontalCOG();
                var originalBody = originalChassisObjectToUse.getAccessoryHolder().getBody();
                if (body.getRampPlatesCOGType() === config.COG_TYPES.LENGTH && originalBody.getRampPlatesCOGType() === config.COG_TYPES.LENGTH || body.getRampPlatesCOGType() === config.COG_TYPES.PERCENTAGE && originalBody.getRampPlatesCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalBody.getRampPlatesHorizontalCOG();
                } else if (body.getRampPlatesCOGType() === config.COG_TYPES.LENGTH && originalBody.getRampPlatesCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalBody.getRampPlatesHorizontalCOG() * body.getRampPlatesProtrusion() / 100;
                } else if (body.getRampPlatesCOGType() === config.COG_TYPES.PERCENTAGE && originalBody.getRampPlatesCOGType() === config.COG_TYPES.LENGTH) {
                    return originalBody.getRampPlatesHorizontalCOG() / body.getRampPlatesProtrusion() * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return body.getRampPlatesHorizontalCOGOverride();
            })
        .overridePermission(showOverrideForStandardBody)
        //.visiblePermission(function () { return body.getType() === config.BODY_TYPES.BEAVERTAIL && showUnlessStandardBodyAndNotAdmin(path); })
        .visiblePermission(getObjectVisibilityPermission(body, isTemplateOrCustomBeaverTailBodyWithoutGraphic))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        // .alwaysNotifyValueSubscribers()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.RAMPPLATESHORIZONTALCOG))
        .addFormattingRule(function () {
            return body.getRampPlatesCOGType() === config.COG_TYPES.PERCENTAGE;
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);


    var rampPlatesVerticalCOGTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === body.getRampPlatesVerticalCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (body.getRampPlatesVerticalCOGType() === '' || body.getRampPlatesVerticalCOGType() === undefined || body.getRampPlatesVerticalCOGType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.RAMPPLATESVERTICALCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(rampPlatesVerticalCOGTypeOption)
        //.visiblePermission(function () { return body.getType() === config.BODY_TYPES.BEAVERTAIL && showUnlessStandardBodyAndNotAdmin(path); })
        .visiblePermission(getObjectVisibilityPermission(body, isTemplateOrCustomBeaverTailBodyWithoutGraphic))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .addSubscription(function (val) {

            if (val !== body.getRampPlatesVerticalCOGType()) {
                body.setRampPlatesVerticalCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    body.setRampPlatesVerticalCOG(body.getRampPlatesHeight() * body.getRampPlatesVerticalCOG() / 100);
                } else {
                    body.setRampPlatesVerticalCOG(utils.returnValueOrZeroIfNanOrInfinity((body.getRampPlatesVerticalCOG() / body.getRampPlatesHeight()) * 100));
                }
            }
        }), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.RAMPPLATESVERTICALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getRampPlatesVerticalCOG();
        })
        .setter(function (newValue) {
            body.setRampPlatesVerticalCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                body.setRampPlatesVerticalCOGOverride(val);
            },
            function () {
                //return originalChassisObjectToUse.getAccessoryHolder().getBody().getRampPlatesVerticalCOG();
                var originalBody = originalChassisObjectToUse.getAccessoryHolder().getBody();
                if (body.getRampPlatesVerticalCOGType() === config.COG_TYPES.LENGTH && originalBody.getRampPlatesVerticalCOGType() === config.COG_TYPES.LENGTH || body.getRampPlatesVerticalCOGType() === config.COG_TYPES.PERCENTAGE && originalBody.getRampPlatesVerticalCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalBody.getRampPlatesVerticalCOG();
                } else if (body.getRampPlatesVerticalCOGType() === config.COG_TYPES.LENGTH && originalBody.getRampPlatesVerticalCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalBody.getRampPlatesVerticalCOG() * body.getRampPlatesHeight() / 100;
                } else if (body.getRampPlatesVerticalCOGType() === config.COG_TYPES.PERCENTAGE && originalBody.getRampPlatesVerticalCOGType() === config.COG_TYPES.LENGTH) {
                    return originalBody.getRampPlatesVerticalCOG() / body.getRampPlatesHeight() * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return body.getRampPlatesVerticalCOGOverride();
            })
        .overridePermission(showOverrideForStandardBody)
        //.visiblePermission(function () { return body.getType() === config.BODY_TYPES.BEAVERTAIL && showUnlessStandardBodyAndNotAdmin(path); })
        .visiblePermission(getObjectVisibilityPermission(body, isTemplateOrCustomBeaverTailBodyWithoutGraphic))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        // .alwaysNotifyValueSubscribers()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.RAMPPLATESVERTICALCOG))
        .addFormattingRule(function () {
            return body.getRampPlatesVerticalCOGType() === config.COG_TYPES.PERCENTAGE;
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);



    var rampPlatesLateralCOGTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === body.getRampPlatesLateralCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (body.getRampPlatesLateralCOGType() === '' || body.getRampPlatesLateralCOGType() === undefined || body.getRampPlatesLateralCOGType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.RAMPPLATESLATERALCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(rampPlatesLateralCOGTypeOption)
        //.visiblePermission(function () { return body.getType() === config.BODY_TYPES.BEAVERTAIL && showUnlessStandardBodyAndNotAdmin(path); })
        .visiblePermission(getObjectVisibilityPermission(body, isTemplateOrCustomBeaverTailBodyWithoutGraphic))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .addSubscription(function (val) {
            if (val !== body.getRampPlatesLateralCOGType()) {
                body.setRampPlatesLateralCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    body.setRampPlatesLateralCOG(body.getActualWidth() * body.getRampPlatesLateralCOG() / 100);
                } else {
                    body.setRampPlatesLateralCOG(utils.returnValueOrZeroIfNanOrInfinity((body.getRampPlatesLateralCOG() / body.getActualWidth()) * 100));
                }
            }
        }), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.RAMPPLATESLATERALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return body.getRampPlatesLateralCOG();
        })
        .setter(function (newValue) {
            body.setRampPlatesLateralCOG(newValue);
        })
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                body.setRampPlatesLateralCOGOverride(val);
            },
            function () {
                //return originalChassisObjectToUse.getAccessoryHolder().getBody().getRampPlatesLateralCOG();
                var originalBody = originalChassisObjectToUse.getAccessoryHolder().getBody();
                if (body.getRampPlatesLateralCOGType() === config.COG_TYPES.LENGTH && originalBody.getRampPlatesLateralCOGType() === config.COG_TYPES.LENGTH || body.getRampPlatesLateralCOGType() === config.COG_TYPES.PERCENTAGE && originalBody.getRampPlatesLateralCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalBody.getRampPlatesLateralCOG();
                } else if (body.getRampPlatesLateralCOGType() === config.COG_TYPES.LENGTH && originalBody.getRampPlatesLateralCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalBody.getRampPlatesLateralCOG() * body.getActualWidth() / 100;
                } else if (body.getRampPlatesLateralCOGType() === config.COG_TYPES.PERCENTAGE && originalBody.getRampPlatesLateralCOGType() === config.COG_TYPES.LENGTH) {
                    return originalBody.getRampPlatesLateralCOG() / body.getActualWidth() * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return body.getRampPlatesLateralCOGOverride();
            })
        .overridePermission(showOverrideForStandardBody)
        .visiblePermission(getObjectVisibilityPermission(body, isTemplateOrCustomBeaverTailBodyWithoutGraphic))
        .readOnlyPermission(getObjectReadOnlyPermission(body, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        // .alwaysNotifyValueSubscribers()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.RAMPPLATESLATERALCOG))
        .addFormattingRule(function () {
            return body.getRampPlatesLateralCOGType() === config.COG_TYPES.PERCENTAGE;
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);

    function getChassisObjectToUseFunc() {
        return chassisObjectToUse;
    }


    function getChassisObjectOverallLength() {
        return rigOps.getOverallChassisObjectLengthLegal(path);
    }

    function getChassisObjectOverallHeight() {
        return rigOps.getChassisObjectOverallHeight(path);
    }

    //function showBeaverTailBodyRampPlatesAttribute() {
    //    return body.getType() === config.BODY_TYPES.BEAVERTAIL && showUnlessStandardBodyAndNotAdmin(path);
    //}

}



function addBodyUprightsObservables(bodyUprights, pathRoot) {
    bodyUprights.forEach(function (upright) {
        createBodyUprightsObjectObservables(upright, pathRoot + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.OBJECT_TYPES.UPRIGHTS);
    });
}

function createBodyUprightsObjectObservables(upright, path) {

    path = path + '.' + upright.getId();

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYUPRIGHTINSIDEFRONTTOUPRIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(upright.getInsideFrontToUpright)
        .setter(upright.setInsideFrontToUpright)
        .visiblePermission(isTemplateOrCustomTimberBodyWithoutGraphic)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYUPRIGHTINSIDEFRONTTOUPRIGHT)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYUPRIGHTWIDTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(upright.getWidth)
        .setter(upright.setWidth)
        .visiblePermission(isTemplateOrCustomTimberBodyWithoutGraphic)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYUPRIGHTWIDTH)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.BODYUPRIGHTHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(upright.getHeight)
        .setter(upright.setHeight)
        .visiblePermission(isTemplateOrCustomTimberBodyWithoutGraphic)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYUPRIGHTHEIGHT)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, function () {
            //    return rigOps.getChassisObjectOverallHeight(path);
            //})
        ), path);
}

function addAxlesObservables(axles, pathRoot) {
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.AXLE1FRONTTOWHEELBASETHEORETICALSTART, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getAxle1FrontToWheelbaseTheoreticalStart)
        .setter(getConfiguration().setAxle1FrontToWheelbaseTheoreticalStart)
        .visiblePermission(showAxle1FrontToWheelbaseTheoreticalStart)
        .readOnly()
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.AXLE1FRONTTOWHEELBASETHEORETICALSTART)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if (getActiveOffer().getRig().getVehicle().getAccessoryHolder().getHitch() !== null) {
                    getActiveOffer().getRig().getVehicle().getAccessoryHolder().getHitch().setHorizontalGap(getActiveOffer().getRig().getVehicle().getWheelbaseTheoreticalEnd());
                }
            })
        ), pathRoot);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.WHEELBASETHEORETICAL, config.INPUT_TYPE.UPDOWNBOX)
        .getter(getConfiguration().getWheelbaseTheoretical)
        .setter(getConfiguration().setWheelbaseTheoretical)
        .visiblePermission(showUnlessStandardAndNotAdmin)
        .readOnly()
        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.WHEELBASETHEORETICAL)), pathRoot);

    axles.forEach(function (axle) {
        createAxleObjectObservables(axle, pathRoot + '.' + config.OBJECT_TYPES.AXLES);
    });
}

//function addAxlesTotalsObservables(path) {
//    ////WEIGHTS
//    //KERB WEIGHTS
//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TAREFRONT, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
//        .getter(rigOps.getVehicleTareFront)
//        .setter(getConfiguration().setTareFront)
//        .addOverrideMechanism(getConfiguration,
//            function (val) {
//                getConfiguration().setTareFront(getConfiguration().getTareFront());
//                getConfiguration().setTareFrontOverride(val);
//                if (val === true) {
//                    getConfiguration().getAxlesHolder().setAxleGroupWeightOverrides(config.AXLE_LOCATIONS.FRONT, false);
//                    //rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEWEIGHT, config.AXLE_LOCATIONS.FRONT, getConfiguration().getTareFront());
//                    rigOps.distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.FRONT, getConfiguration().getTareFront());
//                }
//                getConfiguration().getAxlesHolder().getAxles().forEach(function (axle) {
//                    if (rigOps.getAxleLocationFromAxle(axle) === config.AXLE_LOCATIONS.FRONT) {
//                        var frontAxleWeightAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + axle.getId() + '.' + config.VALUE_TYPE.AXLEWEIGHT);
//                        frontAxleWeightAOG.permissionObv(frontAxleWeightAOG.permission(frontAxleWeightAOG.getPath() + '.' + config.VALUE_TYPE.AXLEWEIGHT));
//                    }
//                });
//            },
//            function () {
//                return rigOps.getVehicleTareFront();
//            },
//            function () {
//                return getConfiguration().getTareFrontOverride();
//            },
//            function () {
//                rigOps.resetAxleValues(path, config.VALUE_TYPE.AXLEWEIGHT, config.AXLE_LOCATIONS.FRONT);
//            }, true)
//        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
//        .overridePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
//        //.readOnlyPermission(function () {
//        //    return getConfiguration().getAxlesHolder().getAxle1Front().getAxleWeightOverride();
//        //})
//        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TAREFRONT)
//            .addOpBlock(function (newValue, oldValue, deltaVal) {
//                //rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEWEIGHT, config.AXLE_LOCATIONS.FRONT, newValue);
//                rigOps.distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.FRONT, newValue);
//            })
//        ));

//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TAREREAR, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
//        .getter(rigOps.getVehicleTareRear)
//        .setter(getConfiguration().setTareRear)
//        .addOverrideMechanism(getConfiguration,
//            function (val) {
//                getConfiguration().setTareRear(getConfiguration().getTareRear());
//                getConfiguration().setTareRearOverride(val);
//                if (val === true) {
//                    getConfiguration().getAxlesHolder().setAxleGroupWeightOverrides(config.AXLE_LOCATIONS.REAR, false);
//                    //rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEWEIGHT, config.AXLE_LOCATIONS.REAR, getConfiguration().getTareRear());
//                    rigOps.distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.REAR, getConfiguration().getTareRear());
//                }
//                getConfiguration().getAxlesHolder().getAxles().forEach(function (axle) {
//                    if (rigOps.getAxleLocationFromAxle(axle) === config.AXLE_LOCATIONS.REAR) {
//                        var rearAxleWeightAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + axle.getId() + '.' + config.VALUE_TYPE.AXLEWEIGHT);
//                        rearAxleWeightAOG.permissionObv(rearAxleWeightAOG.permission(rearAxleWeightAOG.getPath() + '.' + config.VALUE_TYPE.AXLEWEIGHT));
//                    }
//                });
//            },
//            function () {
//                return rigOps.getVehicleTareRear();
//            },
//            function () {
//                return getConfiguration().getTareRearOverride();
//            },
//            function () {
//                rigOps.resetAxleValues(path, config.VALUE_TYPE.AXLEWEIGHT, config.AXLE_LOCATIONS.REAR);
//            }, true)
//        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
//        .overridePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
//        //.readOnlyPermission(function () {
//        //    return getConfiguration().getAxlesHolder().getAxle1Rear().getAxleWeightOverride();
//        //})
//        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TAREREAR)
//            .addOpBlock(function (newValue, oldValue, deltaVal) {
//                //rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEWEIGHT, config.AXLE_LOCATIONS.REAR, newValue);
//                rigOps.distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.REAR, newValue);
//            })
//        ));

//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HORIZONTALCOG, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.LENGTH)
//        .getter(rigOps.getVehicleHorizontalCOG)
//        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
//        .readOnly());

//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.VERTICALCOG, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.LENGTH)
//        .getter(rigOps.getVehicleVerticalCOG)
//        .setter(getConfiguration().setVehicleVerticalCOG)
//        .addOverrideMechanism(getConfiguration,
//            function (val) {
//                getConfiguration().setVehicleVerticalCOGOverride(val);
//            },
//            function () {
//                return rigOps.getVehicleVerticalCOG();
//            },
//            function () {
//                return getConfiguration().getVehicleVerticalCOGOverride();
//            })
//        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
//        .overridePermission(hideForStandardVehicleUnlessLoadingAdmin)
//        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.VERTICALCOG)));

//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.LATERALCOG, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.LENGTH)
//        .getter(rigOps.getVehicleLateralCOG)
//        .setter(getConfiguration().setVehicleLateralCOG)
//        .addOverrideMechanism(getConfiguration,
//            function (val) {
//                getConfiguration().setVehicleLateralCOGOverride(val);
//            },
//            function () {
//                return rigOps.getVehicleLateralCOG();
//            },
//            function () {
//                return getConfiguration().getVehicleLateralCOGOverride();
//            })
//        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
//        .overridePermission(hideForStandardVehicleUnlessLoadingAdmin)
//        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.LATERALCOG)));

//    var yesNoOption = getYesNoNotSpecifiedOptions()
//        .filter(function (option) {
//            if (option.id === getConfiguration().getTareToolsIncluded()) {
//                return option;
//            } else if (option.id === config.YES_NO_OPTIONS.NOT_SPECIFIED && getConfiguration().getTareToolsIncluded() === '') {
//                return option;
//            }
//        })[0];

//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TOOLSINCLUDED, config.INPUT_TYPE.SELECT)
//        .addSubscription(function (val) {
//            getConfiguration().setTareToolsIncluded(val);
//        })
//        .visiblePermission(isNotStandardVehicle)
//        .setVal(yesNoOption));


//    yesNoOption = getYesNoNotSpecifiedOptions()
//        .filter(function (option) {
//            if (option.id === getConfiguration().getTareSpareWheelIncluded()) {
//                return option;
//            } else if (option.id === config.YES_NO_OPTIONS.NOT_SPECIFIED && getConfiguration().getTareSpareWheelIncluded() === '') {
//                return option;
//            }
//        })[0];

//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SPAREWHEELINCLUDED, config.INPUT_TYPE.SELECT)
//        .addSubscription(function (val) {
//            getConfiguration().setTareSpareWheelIncluded(val);
//        })
//        .visiblePermission(isNotStandardVehicle)
//        .setVal(yesNoOption));

//    //MANUFACTURERSRATINGS
//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
//        .getter(getConfiguration().getGAFront)
//        .setter(getConfiguration().setGAFront)
//        .addOverrideMechanism(getConfiguration,
//            function (val) {
//                getConfiguration().setGAFront(getConfiguration().getGAFront());
//                getConfiguration().setManufacturersFrontAxlesMassOverride(val);
//                if (val === true) {
//                    getConfiguration().getAxlesHolder().setAxleGroupManufacturerRatingOverrides(config.AXLE_LOCATIONS.FRONT, false);
//                    getConfiguration().getAxlesHolder().setAxleGroupSuspensionMassRatingOverrides(config.AXLE_LOCATIONS.FRONT, false);
//                    getConfiguration().getAxlesHolder().setAxleGroupTyreMassRatingOverrides(config.AXLE_LOCATIONS.FRONT, false);
//                    rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEMANUFACTURERRATING, config.AXLE_LOCATIONS.FRONT, getConfiguration().getGAFront());
//                    rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.SUSPENSIONMASSRATING, config.AXLE_LOCATIONS.FRONT, getConfiguration().getGAFront());
//                    rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.TYREMASSRATING, config.AXLE_LOCATIONS.FRONT, getConfiguration().getGAFront());
//                    if (getConfiguration().getAxlesHolder().getAxle1Front().getAxleWeightOverride() === false) {
//                        rigOps.distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.FRONT, getConfiguration().getTareFront());
//                    }
//                }
//                getConfiguration().getAxlesHolder().getAxles().forEach(function (axle) {
//                    if (rigOps.getAxleLocationFromAxle(axle) === config.AXLE_LOCATIONS.FRONT) {
//                        var frontAxleManufacturerRatingAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + axle.getId() + '.' + config.VALUE_TYPE.AXLEMANUFACTURERRATING);
//                        frontAxleManufacturerRatingAOG.permissionObv(frontAxleManufacturerRatingAOG.permission(frontAxleManufacturerRatingAOG.getPath() + '.' + config.VALUE_TYPE.AXLEMANUFACTURERRATING));
//                        var frontAxleSuspensionMassRatingAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + axle.getId() + '.' + config.VALUE_TYPE.SUSPENSIONMASSRATING);
//                        frontAxleSuspensionMassRatingAOG.permissionObv(frontAxleSuspensionMassRatingAOG.permission(frontAxleSuspensionMassRatingAOG.getPath() + '.' + config.VALUE_TYPE.SUSPENSIONMASSRATING));
//                        var frontAxleTyreMassRatingAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + axle.getId() + '.' + config.VALUE_TYPE.TYREMASSRATING);
//                        frontAxleTyreMassRatingAOG.permissionObv(frontAxleTyreMassRatingAOG.permission(frontAxleTyreMassRatingAOG.getPath() + '.' + config.VALUE_TYPE.TYREMASSRATING));
//                    }
//                });
//            },
//            function () {
//                return getConfiguration().getGAFront();
//            },
//            function () {
//                return getConfiguration().getManufacturersFrontAxlesMassOverride();
//            },
//            function () {
//                rigOps.resetAxleValues(path, config.VALUE_TYPE.AXLEMANUFACTURERRATING, config.AXLE_LOCATIONS.FRONT);
//                rigOps.resetAxleValues(path, config.VALUE_TYPE.SUSPENSIONMASSRATING, config.AXLE_LOCATIONS.FRONT);
//                rigOps.resetAxleValues(path, config.VALUE_TYPE.TYREMASSRATING, config.AXLE_LOCATIONS.FRONT);
//            }, true)
//        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
//        .overridePermission(showUnlessStandardAndNotAdmin)
//        //.readOnlyPermission(function () {
//        //    return getConfiguration().getAxlesHolder().getAxle1Front().getManufacturerRatingOverride();
//        //})
//        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS)
//            .addOpBlock(function (newValue, oldValue, deltaVal) {
//                rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEMANUFACTURERRATING, config.AXLE_LOCATIONS.FRONT, newValue);
//                rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.SUSPENSIONMASSRATING, config.AXLE_LOCATIONS.FRONT, newValue);
//                rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.TYREMASSRATING, config.AXLE_LOCATIONS.FRONT, newValue);
//                if (getConfiguration().getAxlesHolder().getAxle1Front().getAxleWeightOverride() === false) {
//                    rigOps.distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.FRONT, getConfiguration().getTareFront());
//                }
//            })
//        ));

//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.MANUFACTURERSREARAXLEMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
//        .getter(getConfiguration().getGARear)
//        .setter(getConfiguration().setGARear)
//        .addOverrideMechanism(getConfiguration,
//            function (val) {
//                getConfiguration().setGARear(getConfiguration().getGARear());
//                getConfiguration().setManufacturersRearAxlesMassOverride(val);
//                if (val === true) {
//                    getConfiguration().getAxlesHolder().setAxleGroupManufacturerRatingOverrides(config.AXLE_LOCATIONS.REAR, false);
//                    getConfiguration().getAxlesHolder().setAxleGroupSuspensionMassRatingOverrides(config.AXLE_LOCATIONS.REAR, false);
//                    getConfiguration().getAxlesHolder().setAxleGroupTyreMassRatingOverrides(config.AXLE_LOCATIONS.REAR, false);
//                    rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEMANUFACTURERRATING, config.AXLE_LOCATIONS.REAR, getConfiguration().getGARear());
//                    rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.SUSPENSIONMASSRATING, config.AXLE_LOCATIONS.REAR, getConfiguration().getGARear());
//                    rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.TYREMASSRATING, config.AXLE_LOCATIONS.REAR, getConfiguration().getGARear());
//                    if (getConfiguration().getAxlesHolder().getAxle1Rear().getAxleWeightOverride() === false) {
//                        rigOps.distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.REAR, getConfiguration().getTareRear());
//                    }
//                }
//                getConfiguration().getAxlesHolder().getAxles().forEach(function (axle) {
//                    if (rigOps.getAxleLocationFromAxle(axle) === config.AXLE_LOCATIONS.REAR) {
//                        var rearAxleManufacturerRatingAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + axle.getId() + '.' + config.VALUE_TYPE.AXLEMANUFACTURERRATING);
//                        rearAxleManufacturerRatingAOG.permissionObv(rearAxleManufacturerRatingAOG.permission(rearAxleManufacturerRatingAOG.getPath() + '.' + config.VALUE_TYPE.AXLEMANUFACTURERRATING));
//                        var rearAxleSuspensionMassRatingAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + axle.getId() + '.' + config.VALUE_TYPE.SUSPENSIONMASSRATING);
//                        rearAxleSuspensionMassRatingAOG.permissionObv(rearAxleSuspensionMassRatingAOG.permission(rearAxleSuspensionMassRatingAOG.getPath() + '.' + config.VALUE_TYPE.SUSPENSIONMASSRATING));
//                        var rearAxleTyreMassRatingAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + axle.getId() + '.' + config.VALUE_TYPE.TYREMASSRATING);
//                        rearAxleTyreMassRatingAOG.permissionObv(rearAxleTyreMassRatingAOG.permission(rearAxleTyreMassRatingAOG.getPath() + '.' + config.VALUE_TYPE.TYREMASSRATING));
//                    }
//                });
//            },
//            function () {
//                return getConfiguration().getGARear();
//            },
//            function () {
//                return getConfiguration().getManufacturersRearAxlesMassOverride();
//            },
//            function () {
//                rigOps.resetAxleValues(path, config.VALUE_TYPE.AXLEMANUFACTURERRATING, config.AXLE_LOCATIONS.REAR);
//                rigOps.resetAxleValues(path, config.VALUE_TYPE.SUSPENSIONMASSRATING, config.AXLE_LOCATIONS.REAR);
//                rigOps.resetAxleValues(path, config.VALUE_TYPE.TYREMASSRATING, config.AXLE_LOCATIONS.REAR);
//            }, true)
//        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
//        .overridePermission(showUnlessStandardAndNotAdmin)
//        //.readOnlyPermission(function () {
//        //    return getConfiguration().getAxlesHolder().getAxle1Rear().getManufacturerRatingOverride();
//        //})
//        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.MANUFACTURERSREARAXLEMASS)
//            .addOpBlock(function (newValue, oldValue, deltaVal) {
//                rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEMANUFACTURERRATING, config.AXLE_LOCATIONS.REAR, newValue);
//                rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.SUSPENSIONMASSRATING, config.AXLE_LOCATIONS.REAR, newValue);
//                rigOps.distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.TYREMASSRATING, config.AXLE_LOCATIONS.REAR, newValue);
//                if (getConfiguration().getAxlesHolder().getAxle1Rear().getAxleWeightOverride() === false) {
//                    rigOps.distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.REAR, getConfiguration().getTareRear());
//                }
//            })
//        ));

//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.MANUFACTURERSGROSSVEHICLEMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
//        .getter(getConfiguration().getGVM)
//        .setter(getConfiguration().setGVM)
//        .addOverrideMechanism(getConfiguration,
//            function (val) {
//                getConfiguration().setManufacturersGrossVehicleMassOverride(val);
//            },
//            function () {
//                return getOriginalOffer().getConfiguration().getGVM();
//            },
//            function () {
//                return getConfiguration().getManufacturersGrossVehicleMassOverride();
//            })
//        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
//        .overridePermission(showUnlessStandardAndNotAdmin)
//        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.MANUFACTURERSGROSSVEHICLEMASS)));

//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.MANUFACTURERSGROSSCOMBINATIONMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
//        .getter(getConfiguration().getGCM)
//        .setter(getConfiguration().setGCM)
//        .addOverrideMechanism(getConfiguration,
//            function (val) {
//                getConfiguration().setManufacturersGrossCombinationMassOverride(val);
//            },
//            function () {
//                return getOriginalOffer().getConfiguration().getGCM();
//            },
//            function () {
//                return getConfiguration().getManufacturersGrossCombinationMassOverride();
//            })
//        .overridePermission(isStandardVehicleAndIsLoadingAdmin)
//        .visiblePermission(isStandardVehicleAndLoadingAdminOrIsTemplateVehicle)
//        .addValidation(new ValidationEngine(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.MANUFACTURERSGROSSCOMBINATIONMASS)));

//    yesNoOption = getYesNoOptions()
//        .filter(function (option) {
//            if (option.id === getConfiguration().getFrontRoadFriendlySuspension()) {
//                return option;
//            } else if (option.id === config.YES_NO_OPTIONS.NO && (getConfiguration().getFrontRoadFriendlySuspension() === '' || getConfiguration().getFrontRoadFriendlySuspension() === null || getConfiguration().getFrontRoadFriendlySuspension() === undefined)) {
//                return option;
//            }
//        })[0];

//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FRONTROADFRIENDLYSUSPENSION, config.INPUT_TYPE.SELECT)
//        .addSubscription(function (val) {
//            getConfiguration().setFrontRoadFriendlySuspension(val);
//        })
//        .visiblePermission(showFrontRoadFriendlySuspension)
//        .setVal(yesNoOption));

//    yesNoOption = getYesNoOptions()
//        .filter(function (option) {
//            if (option.id === getConfiguration().getRearRoadFriendlySuspension()) {
//                return option;
//            } else if (option.id === config.YES_NO_OPTIONS.NO && (getConfiguration().getRearRoadFriendlySuspension() === '' || getConfiguration().getRearRoadFriendlySuspension() === null || getConfiguration().getRearRoadFriendlySuspension() === undefined)) {
//                return option;
//            }
//        })[0];

//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.REARROADFRIENDLYSUSPENSION, config.INPUT_TYPE.SELECT)
//        .addSubscription(function (val) {
//            getConfiguration().setRearRoadFriendlySuspension(val);
//        })
//        //.visiblePermission(isNotStandardVehicleOrIsStandardVehicleAndShowHigherMassLimitsIsTrue)
//        .visiblePermission(evaluateShowSuspension)
//        .setVal(yesNoOption));

//    yesNoOption = getYesNoOptions()
//        .filter(function (option) {
//            if (option.id === getConfiguration().getRolloverProtection()) {
//                return option;
//            } else if (option.id === config.YES_NO_OPTIONS.NO && (getConfiguration().getRolloverProtection() === '' || getConfiguration().getRolloverProtection() === null || getConfiguration().getRolloverProtection() === undefined)) {
//                return option;
//            }
//        })[0];

//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ROLLOVERPROTECTION, config.INPUT_TYPE.SELECT)
//        .addSubscription(function (val) {
//            getConfiguration().setRolloverProtection(val);
//        })
//        //.visiblePermission(isNotStandardVehicleOrIsStandardVehicleAndShowHigherMassLimitsIsTrue)
//        .visiblePermission(evaluateShowSuspension)
//        .setVal(yesNoOption));

//    yesNoOption = getYesNoOptions()
//        .filter(function (option) {
//            if (option.id === getConfiguration().getFrontUnderrunProtection()) {
//                return option;
//            } else if (option.id === config.YES_NO_OPTIONS.NO && (getConfiguration().getFrontUnderrunProtection() === '' || getConfiguration().getFrontUnderrunProtection() === null || getConfiguration().getFrontUnderrunProtection() === undefined)) {
//                return option;
//            }
//        })[0];

//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FRONTUNDERRUNPROTECTION, config.INPUT_TYPE.SELECT)
//        .addSubscription(function (val) {
//            getConfiguration().setFrontUnderrunProtection(val);
//        })
//        //.visiblePermission(isNotStandardVehicleOrIsStandardVehicleAndShowHigherMassLimitsIsTrue)
//        .visiblePermission(evaluateShowSuspension)
//        .setVal(yesNoOption));
//}

function addAxleGroupsObservables(axleGroups, path) {
    axleGroups.forEach(function (axleGroup) {
        if (axleGroup) {
            createAxleObjectObservables(axleGroup, path + '.' + config.OBJECT_TYPES.AXLE_GROUPS);
        }
    });
}

function createAxleObjectObservables(axle, path) { 
    var isAxleGroup = path.includes(config.OBJECT_TYPES.AXLE_GROUPS);
    //Position letter
    path = path + '.' + axle.getId();
    //Type 

    var chassisObjectToUse = rigOps.getChassisObjectFromPath(path),
        originalChassisObjectToUse = rigOps.getOriginalChassisObjectFromPath(path);
    

    var isTrailerChassis = chassisObjectToUse.getChassisObjectName() !== config.CHASSIS_OBJECT_TYPES.VEHICLE;
    //// TC TODO 2020.1
    //if (isAxleGroup) {
    //    // Create axle group attribute observable groups
    //} else {
    //    // Create axle attribute observable groups
    //}
    //Axle Weight
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.AXLEWEIGHT, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(axle.getAxleWeight)
        .setter(function(val){
            if (axle.getIsAxleGroup()) {
                // Update the underlying axles
                var pathChassisObject = path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) === true ? config.CHASSIS_OBJECT_TYPES.VEHICLE : path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) === true ? config.CHASSIS_OBJECT_TYPES.TRAILER1 : config.CHASSIS_OBJECT_TYPES.TRAILER2;
                axle.getRelatedAxleIds().forEach(function (axleId) {
                    getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.AXLEWEIGHT).value.value = val;
                });
                axle.setAxleWeight(val);
            } else { 
                axle.setAxleWeight(val);
            }
        })
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                if (axle.getIsAxleGroup()) {
                    // Update the underlying axles
                    //var pathChassisObject = path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) === true ? config.CHASSIS_OBJECT_TYPES.VEHICLE : path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) === true ? config.CHASSIS_OBJECT_TYPES.TRAILER1 : config.CHASSIS_OBJECT_TYPES.TRAILER2;
                    axle.getRelatedAxleIds().forEach(function (axleId) {
                        //getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.AXLEWEIGHT).override(val);
                        var individualAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
                        individualAxle.setAxleWeightOverride(val);
                    });
                    axle.setAxleWeightOverride(val);
                } else { 
                    //if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
                    //    rigOps.getAxleHolderFromPath(path).setAxleGroupWeightOverrides(rigOps.getAxleLocationFromAxle(axle), val);
                    //    var tempVehicle = getConfiguration();

                    //    if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) {
                    //        if (tempVehicle.getTareFrontOverride() === true) {
                    //            tempVehicle.setTareFrontOverride(false);
                    //        }
                    //        var tareFrontAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TAREFRONT);
                    //        tareFrontAOG.permissionObv(tareFrontAOG.permission(tareFrontAOG.getPath()));
                    //    }

                    //    if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                    //        if (tempVehicle.getTareRearOverride() === true) {
                    //            tempVehicle.setTareRearOverride(false);
                    //        }
                    //        var tareRearAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TAREREAR);
                    //        tareRearAOG.permissionObv(tareRearAOG.permission(tareRearAOG.getPath()));
                    //    }

                    //} else {
                        axle.setAxleWeightOverride(val);
                    //}
                }
            },
            function () {
                return axle.getAxleWeight();
            },
            function () {
                return axle.getAxleWeightOverride();
            },
            function () {
                // rigOps.resetAxleValues(path, config.VALUE_TYPE.AXLEWEIGHT, rigOps.getAxleLocationFromAxle(axle));
            }, true)
        .overridePermission(showAxleAttributeIfStandardAndLoadingAdminAddAxlesOrIfTemplate)
        .visiblePermission(function (path) {
            if (isTrailerChassis) {
                return getObjectVisibilityPermission(chassisObjectToUse)(path);
            } else {
                return showAxleAttributeIfStandardAndLoadingAdminAddAxlesOrIfTemplate(path) && path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1;
            }
        })
        .readOnlyPermission(function (path) {
            if (isTrailerChassis) {
                return getObjectReadOnlyPermission(chassisObjectToUse, config.MENU_SECTION.SPECIFICATION_WEIGHTS)(path);
            } else {
                return false;
            }
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.AXLEWEIGHT)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                //#unfinished, need to update tare mass front and rear values
            })
        ), path);

    if(path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
        // Curb/Tare Weight Modified
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TAREWEIGHTMODIFIED, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
            .getter(function () {
                if(axle.getIsAxleGroup()) {
                    if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT) {
                        return rigOps.getDistributedTareFrontTotal() ;

                    } else if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
                        return getActiveOffer().getRig().getVehicle().getRearRearAxlesDistribution({ rearMassVal: rigOps.getDistributedTareRearTotal() });

                    }
                } else {
                    //var axlePosition = rigOps.getAxleHolderFromPath(path).getPosition(axle);
                    //if(axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
                    //    switch (axlePosition) {
                    //        case config.AXLE_POSSITIONS.AAXLE_1_REAR:
                    //            return getActiveOffer().getRig().getVehicle().getPusherOrTagRearAxleDistribution({axleNumber: axle.getNumber(), rearMassVal: rigOps.getDistributedTareRearTotal() });
                    //            //{ objectOrAccessoryType: config.CHASSIS_OBJECT_TYPES.VEHICLE, axleNumber: Number(axleNumber), vehicleValSubCode: 'tare', rearMassVal: massVal }
                    //        case config.AXLE_POSSITIONS.AAXLE_2_REAR:
                    //            break;
                    //        case config.AXLE_POSSITIONS.AAXLE_3_REAR:
                    //            break;
                    //        case config.AXLE_POSSITIONS.AAXLE_4_REAR:
                    //            break;
                    //    }
                    //} else if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                    //    switch (axlePosition) {
                    //        case config.AXLE_POSSITIONS.AAXLE_1_REAR:
                    //            break;
                    //        case config.AXLE_POSSITIONS.AAXLE_2_REAR:
                    //            break;
                    //        case config.AXLE_POSSITIONS.AAXLE_3_REAR:
                    //            break;
                    //        case config.AXLE_POSSITIONS.AAXLE_4_REAR:
                    //            break;
                    //    }
                    //}
                    if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                        return getActiveOffer().getRig().getVehicle().getPusherOrTagRearAxleDistribution({ axleNumber: axle.getNumber(), rearMassVal: rigOps.getDistributedTareRearTotal() });
                    }
                }
            
            
                //return rigOps.getDistributedTareRearTotal()
            })
            .visiblePermission(function (path) {
                if (isTrailerChassis) {
                    return getObjectVisibilityPermission(chassisObjectToUse)(path);
                } else {
                    var chassisObject = rigOps.getChassisObjectFromPath(path);
                    return chassisObject.getAxlesHolder().hasAddedAxle();
                }
            })
            //.visiblePermission(function (path) {
            //    var chassisObject = rigOps.getChassisObjectFromPath(path);
            //    return chassisObject.getAxlesHolder().hasAddedAxle();
            //})
            .readOnly(), path);
    }
    //Manufacturer Rating
    var manufacturerRatingAttrGroupVisiblePermissionFunc;
    switch (rigOps.getChassisTypeUsingPath(path)) {
        case config.CHASSIS_OBJECT_TYPES.VEHICLE:
            manufacturerRatingAttrGroupVisiblePermissionFunc = showAxleAttributeIfStandardAndLoadingAdminAddAxlesOrIfTemplate;
            break;
        case config.CHASSIS_OBJECT_TYPES.TRAILER1:
        case config.CHASSIS_OBJECT_TYPES.TRAILER2:
            manufacturerRatingAttrGroupVisiblePermissionFunc = function () {
                return false;
            };
            break;
    }

    var manufacturerRatingAttrGroup = new AttributeObservableGroup(config.VALUE_TYPE.AXLEMANUFACTURERRATING, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(axle.getManufacturerRating)
        .setter(function(val){
            axle.setManufacturerRating(val);
            //if (axle.getIsAxleGroup()) {
            //    // Calculate the value to update the underlying axle to
            //    var currentAxleGroupValue = axle.getManufacturerRating(),
            //        numberOfRelatedAxles = axle.getRelatedAxleIds().length,
            //        newRelatedAxleValue = val,
            //        deltaVal = val - currentAxleGroupValue,
            //        deltaValPerAxle = deltaVal / numberOfRelatedAxles;
                
            //    // Update the underlying axles
            //    var pathChassisObject = path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) === true ? config.CHASSIS_OBJECT_TYPES.VEHICLE : path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) === true ? config.CHASSIS_OBJECT_TYPES.TRAILER1 : config.CHASSIS_OBJECT_TYPES.TRAILER2;
            //    axle.getRelatedAxleIds().forEach(function (axleId) {
            //        var currentAxleValue = getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.AXLEMANUFACTURERRATING).value();
            //        getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.AXLEMANUFACTURERRATING).value(currentAxleValue + deltaValPerAxle);
            //        //var individualAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
            //        //individualAxle.setManufacturerRating(individualAxle.getManufacturerRating() + deltaValPerAxle);
            //    });
            //    axle.setManufacturerRating(val);
            //} else { 
            //    axle.setManufacturerRating(val);
            //}
        })
        .visiblePermission(function (path) {
            if (isTrailerChassis) {
                return getObjectVisibilityPermission(chassisObjectToUse, manufacturerRatingAttrGroupVisiblePermissionFunc)(path);
            } else {
                // return manufacturerRatingAttrGroupVisiblePermissionFunc(path) && globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED;
                return showAxleAttributeIfStandardAndLoadingAdminAddAxlesOrIfTemplate(path) && globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED;
            }
        })
        .readOnlyPermission(function (path) {
            if (isTrailerChassis) {
                return getObjectReadOnlyPermission(chassisObjectToUse, config.MENU_SECTION.SPECIFICATION_WEIGHTS)(path);
            } else {
                return false;
            }
        })
        //.visiblePermission(manufacturerRatingAttrGroupVisiblePermissionFunc)
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                if (axle.getIsAxleGroup()) {
                    // Update the underlying axles
                    var pathChassisObject = path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) === true ? config.CHASSIS_OBJECT_TYPES.VEHICLE : path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) === true ? config.CHASSIS_OBJECT_TYPES.TRAILER1 : config.CHASSIS_OBJECT_TYPES.TRAILER2;
                    axle.getRelatedAxleIds().forEach(function(axleId) {
                        //getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.AXLEMANUFACTURERRATING).override(val);
                        var individualAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
                        individualAxle.setManufacturerRatingOverride(val);
                    });
                    axle.setManufacturerRatingOverride(val);                            
                } else {
                    //if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
                    //    var tempVehicle = getConfiguration();
                    //    //rigOps.getAxleHolderFromPath(path).setAxleGroupManufacturerRatingOverrides(rigOps.getAxleLocationFromAxle(axle), val);

                    //    if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) {
                    //        if (tempVehicle.getManufacturersFrontAxlesMassOverride() === true) {
                    //            tempVehicle.setManufacturersFrontAxlesMassOverride(false);
                    //        }
                    //        var manufacturersFrontAxlesMassAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS);
                    //        manufacturersFrontAxlesMassAOG.permissionObv(manufacturersFrontAxlesMassAOG.permission(manufacturersFrontAxlesMassAOG.getPath()));
                    //    }

                    //    if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                    //        if (tempVehicle.getManufacturersRearAxlesMassOverride() === true) {
                    //            tempVehicle.setManufacturersRearAxlesMassOverride(false);
                    //        }
                    //        var manufacturersRearAxlesMassAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.MANUFACTURERSREARAXLEMASS);
                    //        manufacturersRearAxlesMassAOG.permissionObv(manufacturersRearAxlesMassAOG.permission(manufacturersRearAxlesMassAOG.getPath()));
                    //    }
                    //} else {
                    //    axle.setManufacturerRatingOverride(val);
                    //}
                    axle.setManufacturerRatingOverride(val);
                }
                //getConfiguration().setManufacturersFrontAxlesMassOverride(val);
            },
            function () {
                if (axle.getIsAxleGroup()) {
                    return originalChassisObjectToUse.getAxlesHolder().getAxleGroupById(axle.getId()).getManufacturerRating();
                }else {
                    return originalChassisObjectToUse.getAxlesHolder().getAxleById(axle.getId()).getManufacturerRating();
                }
            },
            function () {
                return axle.getManufacturerRatingOverride();
            },
            function (delta) {
                // rigOps.resetAxleValues(path, config.VALUE_TYPE.AXLEMANUFACTURERRATING, rigOps.getAxleLocationFromAxle(axle));
                if (axle.getIsAxleGroup()) {
                    // Calculate the value to update the underlying axle to
                    var valPerAxle = axle.getManufacturerRating() / axle.getRelatedAxleIds().length;
                    
                    axle.getRelatedAxleIds().forEach(function (axleId) {
                        var individualAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
                        individualAxle.setManufacturerRating(valPerAxle); 
                    });
                }
                handleGAWRUpdate(axle, chassisObjectToUse);
            }, true)
        .overridePermission(showAxleAttributeIfStandardAndLoadingAdminAddAxlesOrIfTemplate)

    if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
        var manufacturerVehicleValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.AXLEMANUFACTURERRATING)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if (axle.getIsAxleGroup()) {
                    // Calculate the value to update the underlying axle to
                    var valPerAxle = newValue / axle.getRelatedAxleIds().length;
                    
                    axle.getRelatedAxleIds().forEach(function (axleId) {
                        var individualAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
                        individualAxle.setManufacturerRating(valPerAxle); 
                    });
                }
                handleGAWRUpdate(axle, chassisObjectToUse);
                var tempVehicle = getConfiguration();
                //if (tempVehicle.getManufacturersFrontAxlesMassOverride() === false) {
                    if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) {

                        tempVehicle.setGAFront(tempVehicle.getAxlesHolder().getFrontAxleManufacturerRating());
                    }
                //}
                //if (tempVehicle.getManufacturersRearAxlesMassOverride() === false) {
                    if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {

                        tempVehicle.setGARear(tempVehicle.getAxlesHolder().getRearAxleManufacturerRating());
                    }
                //}
            });
        manufacturerRatingAttrGroup.addValidation(manufacturerVehicleValidation);
    } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        var manufacturerTrailer1Validation = new ValidationEngine(path + '.' + config.VALUE_TYPE.AXLEMANUFACTURERRATING)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                var trailer1 = rigOps.getChassisObjectFromPath(path);

                // var tempGVM = trailer1.getGVM();
                trailer1.setManufacturersFrontAxlesMass(trailer1.getAxlesHolder().getFrontAxleManufacturerRating());
                trailer1.setManufacturersRearAxlesMass(trailer1.getAxlesHolder().getRearAxleManufacturerRating());
            })
            .addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERWHEELBASETHEORETICAL);

        manufacturerRatingAttrGroup.addValidation(manufacturerTrailer1Validation);
    } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
        var manufacturerTrailer2Validation = new ValidationEngine(path + '.' + config.VALUE_TYPE.AXLEMANUFACTURERRATING)
            .addUIAttributeValidationStep(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.TRAILERWHEELBASETHEORETICAL)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                var trailer2 = rigOps.getChassisObjectFromPath(path);
                // var tempGVM = trailer2.getGVM();
                trailer2.setManufacturersFrontAxlesMass(trailer2.getAxlesHolder().getFrontAxleManufacturerRating());
                trailer2.setManufacturersRearAxlesMass(trailer2.getAxlesHolder().getRearAxleManufacturerRating());
            });
        manufacturerRatingAttrGroup.addValidation(manufacturerTrailer2Validation);
    }
    addAttributeGroup(manufacturerRatingAttrGroup, path);

    //Steering Axle
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.STEERINGAXLE, config.INPUT_TYPE.CHECKBOX)
        .visiblePermission(function (path) {
            if (isTrailerChassis) {
                return getObjectVisibilityPermission(chassisObjectToUse, function (path) {
                    return false; 
                })(path);
            } else {
                return true;
            }
        })
        .readOnlyPermission(function (path) {
            if (isTrailerChassis) {
                return getObjectReadOnlyPermission(chassisObjectToUse, config.MENU_SECTION.SPECIFICATION_WEIGHTS)(path);
            } else {
                return false;
            }
        })
        //.visiblePermission(function (path) {
        //    return false; /*showIfNotFrontOrSecondSteerAxle(path) && showAxleAttributeUnlessExistingAxleOnStandardAndNotLoadingAdmin(path) && path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1;*/
        //})
        .setVal(axle.getSteering())
        .addSubscription(function (val) {
            let pathChassisObject = path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) === true ? config.CHASSIS_OBJECT_TYPES.VEHICLE : path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) === true ? config.CHASSIS_OBJECT_TYPES.TRAILER1 : config.CHASSIS_OBJECT_TYPES.TRAILER2;
            if (axle.getIsAxleGroup()) {
                // Update the underlying axles
                
                axle.getRelatedAxleIds().forEach(function (axleId) {
                    // if (getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.STEERINGAXLE).value() !== val) {
                    //    getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.STEERINGAXLE).value(val);
                    // }
                });
                axle.setSteering(val);
            } else {
                // getAttributeGroup(path + '.' + config.VALUE_TYPE.STEERINGAXLE).value.value = val;
                axle.setSteering(val);
            }
            if(val === true) {
                
                // initialiseTurningAbilityVariables();
                if(!rigOps.getVehicleWheelbaseTurningChanged() && !axle.getRaised()) {
                    turningCircleLockedToSteeringAngleInner = false;
                    let tempVehicle = getActiveOffer().getRig().getVehicle();
                    let steeringAttributeType = rigOps.getDefaultSteeringAttribute();
                    tempVehicle.setTurningAbilitySpecifiedAs(steeringAttributeType); 
                    if (steeringAttributeType === config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER) {
                        tempVehicle.setSteeringAngleInnerCalculated(rigOps.getDefaultSteeringAttributeValue());
                        // tempVehicle.setTurningRadiusCurbToCurbCalculated(turningCircleVals.turningRadiusCurbToCurb);
                        // tempVehicle.setTurningRadiusWallToWallCalculated(turningCircleVals.turningRadiusWallToWall);
                    } else if (steeringAttributeType === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB) {
                        tempVehicle.setTurningRadiusCurbToCurbCalculated(rigOps.getDefaultSteeringAttributeValue());
                        // tempVehicle.setTurningRadiusWallToWallCalculated(turningCircleVals.turningRadiusWallToWall);
                        // tempVehicle.setSteeringAngleInnerCalculated(turningCircleVals.steeringAngleInnerInDegrees);
                    } else if (steeringAttributeType === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL) {
                        tempVehicle.setTurningRadiusWallToWallCalculated(rigOps.getDefaultSteeringAttributeValue());
                        // tempVehicle.setSteeringAngleInnerCalculated(turningCircleVals.steeringAngleInnerInDegrees);
                        // tempVehicle.setTurningRadiusCurbToCurbCalculated(turningCircleVals.turningRadiusCurbToCurb);
                    }
                    // getActiveOffer().getRig().getVehicle().setCurrentTurningAbilityVal();
                    // resetTurningAbilityVariables();
                    // initialiseTurningAbilityVariables();
                }
            }
        }), path);

    //Lifting Axle
    //Lifting Axle
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.LIFTINGAXLE, config.INPUT_TYPE.CHECKBOX)
        .visiblePermission(function (path) {
            if (isTrailerChassis) {
                return getObjectVisibilityPermission(chassisObjectToUse, function (path) {
                    return path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1 && showIfNotFrontOrRearAxle(path);
                })(path);
            } else {
                return showAxleAttributeUnlessExistingAxleOnStandardAndNotLoadingAdmin(path) && path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1 && showIfNotFrontOrRearAxle(path);
            }
        })
        .readOnlyPermission(function (path) {
            if (isTrailerChassis) {
                return getObjectReadOnlyPermission(chassisObjectToUse, config.MENU_SECTION.SPECIFICATION_WEIGHTS, function (path) {
                    return axle.getRaised();
                })(path);
            } else {
                return axle.getRaised();
            }
        })
        //.visiblePermission(function (path) {
        //    return showAxleAttributeUnlessExistingAxleOnStandardAndNotLoadingAdmin(path) && path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1 && showIfNotFrontOrRearAxle(path);
        //})
        //.readOnlyPermission(function (path) {
        //    return axle.getRaised();
        //})
        .setVal(axle.getLifting())
        .addSubscription(function (val) {
            if (axle.getIsAxleGroup()) {
                // Update the underlying axles
                var pathChassisObject = path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) === true ? config.CHASSIS_OBJECT_TYPES.VEHICLE : path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) === true ? config.CHASSIS_OBJECT_TYPES.TRAILER1 : config.CHASSIS_OBJECT_TYPES.TRAILER2;
                axle.getRelatedAxleIds().forEach(function (axleId) {
                    //getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.LIFTINGAXLE).value(val);
                });
                axle.setLifting(val);
            } else {
                axle.setLifting(val);
            }
        }), path);

    //Max Lifting Travel
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.AXLELIFTINGMAXTRAVEL, config.INPUT_TYPE.UPDOWNBOX)
        .getter(axle.getLiftingMaxTravel)
        .setter(axle.setLiftingMaxTravel)
        .visiblePermission(function (path) {
            if (isTrailerChassis) {
                return getObjectVisibilityPermission(chassisObjectToUse, function (path) {
                    return axle.getLifting() && path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1;
                })(path);
            } else {
                return axle.getLifting() && showAxleAttributeUnlessExistingAxleOnStandardAndNotLoadingAdmin(path) && path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1;
            }
        })
        .readOnlyPermission(function (path) {
            if (isTrailerChassis) {
                return getObjectReadOnlyPermission(chassisObjectToUse, config.MENU_SECTION.SPECIFICATION_WEIGHTS)(path);
            } else {
                return false;
            }
        })
        //.visiblePermission(function (path) {
        //    return axle.getLifting() && showAxleAttributeUnlessExistingAxleOnStandardAndNotLoadingAdmin(path) && path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1;
        //})
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.AXLELIFTINGMAXTRAVEL)), path);


    //Axle Raised
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.AXLERAISED, config.INPUT_TYPE.CHECKBOX)
        .setVal(axle.getRaised())
        .addSubscription(function (val) {
            var parent = chassisObjectToUse.getChassisObjectName();
            var objectType = config.OBJECT_TYPES.AXLES;

            var noOfFrontAxles = chassisObjectToUse.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT);
            var noOfRearAxles = chassisObjectToUse.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR);
            var rearAxleNumber = chassisObjectToUse.getAxlesHolder().getAxleNumber(axle) - noOfFrontAxles;

            if (val === true) {
                if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                    if (rearAxleNumber === noOfRearAxles) {
                        continueWithRaisingOrDroppingAxle();
                    } else if (chassisObjectToUse.getAxlesHolder().getAxleByNumber(noOfFrontAxles + rearAxleNumber + 1).getRaised() === true) {
                        continueWithRaisingOrDroppingAxle();
                    } else {
                        configurationActionFailedCallback(parent, objectType, config.ACTION_FAILURE_REASONS.INCORRECT_TAG_RAISE_ORDER);
                        refreshUIAxleRaisedCheckbox();
                    }
                } else if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
                    if (rearAxleNumber === 1) {
                        continueWithRaisingOrDroppingAxle();
                    } else if (chassisObjectToUse.getAxlesHolder().getAxleByNumber(noOfFrontAxles + rearAxleNumber - 1).getRaised() === true) {
                        continueWithRaisingOrDroppingAxle();
                    } else {
                        configurationActionFailedCallback(parent, objectType, config.ACTION_FAILURE_REASONS.INCORRECT_PUSHER_RAISE_ORDER);
                        refreshUIAxleRaisedCheckbox();
                    }
                } else {
                    continueWithRaisingOrDroppingAxle();
                }
            } else {
                if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                    if (chassisObjectToUse.getAxlesHolder().getAxleByNumber(noOfFrontAxles + rearAxleNumber - 1).getRaised() === false) {
                        continueWithRaisingOrDroppingAxle();
                    } else {
                        configurationActionFailedCallback(parent, objectType, config.ACTION_FAILURE_REASONS.INCORRECT_TAG_DROP_ORDER);
                        refreshUIAxleRaisedCheckbox();
                    }
                } else if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
                    if (chassisObjectToUse.getAxlesHolder().getAxleByNumber(noOfFrontAxles + rearAxleNumber + 1).getRaised() === false) {
                        continueWithRaisingOrDroppingAxle();
                    } else {
                        configurationActionFailedCallback(parent, objectType, config.ACTION_FAILURE_REASONS.INCORRECT_PUSHER_DROP_ORDER);
                        refreshUIAxleRaisedCheckbox();
                    }
                } else {
                    continueWithRaisingOrDroppingAxle();
                }
            }

            chassisObjectToUse.getAxlesHolder().calculatePercentagesOfWeightCarried();
            originalChassisObjectToUse.getAxlesHolder().calculatePercentagesOfWeightCarried();

            
            function refreshUIAxleRaisedCheckbox() {
                var axleRaisedAOG = getAttributeGroup(path + '.' + config.VALUE_TYPE.AXLERAISED);
                axleRaisedAOG.value.value = !val;
            }

            function continueWithRaisingOrDroppingAxle() {
                axle.setRaised(val);
                originalChassisObjectToUse.getAxlesHolder().getAxleById(axle.getId()).setRaised(val);
                // if(val === true) {
                
                    // initialiseTurningAbilityVariables();
                    if(!rigOps.getVehicleWheelbaseTurningChanged()) {
                        turningCircleLockedToSteeringAngleInner = false;
                        let tempVehicle = getActiveOffer().getRig().getVehicle();
                        let steeringAttributeType = rigOps.getDefaultSteeringAttribute();
                        tempVehicle.setTurningAbilitySpecifiedAs(steeringAttributeType); 
                        if (steeringAttributeType === config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER) {
                            tempVehicle.setSteeringAngleInnerCalculated(rigOps.getDefaultSteeringAttributeValue());
                            // tempVehicle.setTurningRadiusCurbToCurbCalculated(turningCircleVals.turningRadiusCurbToCurb);
                            // tempVehicle.setTurningRadiusWallToWallCalculated(turningCircleVals.turningRadiusWallToWall);
                        } else if (steeringAttributeType === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB) {
                            tempVehicle.setTurningRadiusCurbToCurbCalculated(rigOps.getDefaultSteeringAttributeValue());
                            // tempVehicle.setTurningRadiusWallToWallCalculated(turningCircleVals.turningRadiusWallToWall);
                            // tempVehicle.setSteeringAngleInnerCalculated(turningCircleVals.steeringAngleInnerInDegrees);
                        } else if (steeringAttributeType === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL) {
                            tempVehicle.setTurningRadiusWallToWallCalculated(rigOps.getDefaultSteeringAttributeValue());
                            // tempVehicle.setSteeringAngleInnerCalculated(turningCircleVals.steeringAngleInnerInDegrees);
                            // tempVehicle.setTurningRadiusCurbToCurbCalculated(turningCircleVals.turningRadiusCurbToCurb);
                        }
                        // getActiveOffer().getRig().getVehicle().setCurrentTurningAbilityVal();
                        // resetTurningAbilityVariables();
                        // initialiseTurningAbilityVariables();
                    }
                // }
                //if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) {
                //    chassisObjectToUse.setManufacturersFrontAxlesMassOverride(false);
                //    chassisObjectToUse.setPermissibleFrontOverride(false);
                //    chassisObjectToUse.setPermissibleTotalOverride(false);

                //} else {
                //    chassisObjectToUse.setManufacturersRearAxlesMassOverride(false);
                //    chassisObjectToUse.setPermissibleRearOverride(false);
                //    chassisObjectToUse.setPermissibleTotalOverride(false);
                //}
                notifyIntercomAxleRaisedOrDropped(axle);
            }


        })
        .visiblePermission(function (path) {
            if (isTrailerChassis) {
                return getObjectVisibilityPermission(chassisObjectToUse, function (path) {
                    return axle.getLifting() && path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1;
                })(path);
            } else {
                return axle.getLifting() && showAxleAttributeUnlessExistingAxleOnStandardAndNotLoadingAdmin(path) && path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1;
            }
        })
        .readOnlyPermission(function (path) {
            if (isTrailerChassis) {
                return getObjectReadOnlyPermission(chassisObjectToUse, config.MENU_SECTION.SPECIFICATION_WEIGHTS)(path);
            } else if ((axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) && axle.isAdded() === false) {
                return true;
            } else {
                return false;
            }
        }), path);
        //.visiblePermission(function (path) {
        //    return axle.getLifting() && showAxleAttributeUnlessExistingAxleOnStandardAndNotLoadingAdmin(path) && path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1;
        //}), path);


    var numOfTyres = getNumberOfTyresOptions()
        .filter(function (option) {
            if (option.description === axle.getNoOfTyresOnAxle()) {
                return option;
            } else if (option.description === 2 && (axle.getNoOfTyresOnAxle() === undefined || axle.getNoOfTyresOnAxle() === null || axle.getNoOfTyresOnAxle() === 0)) {
                return option;
            }
        })[0];


    //////SUSPENSION
    //Suspension Mass Rating
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SUSPENSIONMASSRATING, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(axle.getSuspensionMassRating)
        .setter(function(val){
            //if (axle.getIsAxleGroup()) {
            //    // Calculate the value to update the underlying axle to
            //    var currentAxleGroupValue = axle.getSuspensionMassRating(),
            //        numberOfRelatedAxles = axle.getRelatedAxleIds().length,
            //        newRelatedAxleValue = val,
            //        deltaVal = val - currentAxleGroupValue,
            //        deltaValPerAxle = deltaVal / numberOfRelatedAxles;

            //    // Update the underlying axles
            //    var pathChassisObject = path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) === true ? config.CHASSIS_OBJECT_TYPES.VEHICLE : path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) === true ? config.CHASSIS_OBJECT_TYPES.TRAILER1 : config.CHASSIS_OBJECT_TYPES.TRAILER2;
            //    axle.getRelatedAxleIds().forEach(function (axleId) {
            //        //var currentAxleValue = getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.SUSPENSIONMASSRATING).value();
            //        //getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.SUSPENSIONMASSRATING).formatted(currentAxleValue + deltaValPerAxle);
            //        var individualAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
            //        individualAxle.setManufacturerRating(individualAxle.getSuspensionMassRating() + deltaValPerAxle);
            //    });
            //    axle.setSuspensionMassRating(val);
            //} else {
                axle.setSuspensionMassRating(val);
            //}
        })
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                if (axle.getIsAxleGroup()) {
                    // Update the underlying axles
                    var pathChassisObject = path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) === true ? config.CHASSIS_OBJECT_TYPES.VEHICLE : path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) === true ? config.CHASSIS_OBJECT_TYPES.TRAILER1 : config.CHASSIS_OBJECT_TYPES.TRAILER2;
                    axle.getRelatedAxleIds().forEach(function (axleId) {
                        //getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.SUSPENSIONMASSRATING).override(val);
                        var individualAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
                        individualAxle.setSuspensionMassRatingOverride(val);
                    });
                    axle.setSuspensionMassRatingOverride(val);
                } else {
                    //if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
                    //    rigOps.getAxleHolderFromPath(path).setAxleGroupSuspensionMassRatingOverrides(rigOps.getAxleLocationFromAxle(axle), val);
                    //    var tempVehicle = getConfiguration();

                    //    if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) {
                    //        if (tempVehicle.getManufacturersFrontAxlesMassOverride() === true) {
                    //            tempVehicle.setManufacturersFrontAxlesMassOverride(false);
                    //        }
                    //        var manufacturersFrontAxlesMassAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS);
                    //        manufacturersFrontAxlesMassAOG.permissionObv(manufacturersFrontAxlesMassAOG.permission(manufacturersFrontAxlesMassAOG.getPath()));
                    //    }

                    //    if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                    //        if (tempVehicle.getManufacturersRearAxlesMassOverride() === true) {
                    //            tempVehicle.setManufacturersRearAxlesMassOverride(false);
                    //        }
                    //        var manufacturersRearAxlesMassAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.MANUFACTURERSREARAXLEMASS);
                    //        manufacturersRearAxlesMassAOG.permissionObv(manufacturersRearAxlesMassAOG.permission(manufacturersRearAxlesMassAOG.getPath()));
                    //    }

                    //} else {
                    //    axle.setSuspensionMassRatingOverride(val);
                    //}
                    axle.setSuspensionMassRatingOverride(val);
                }
            },
            function () {
                //return axle.getSuspensionMassRating();
                if (axle.getIsAxleGroup()) {
                    return originalChassisObjectToUse.getAxlesHolder().getAxleGroupById(axle.getId()).getSuspensionMassRating();
                } else {
                    return originalChassisObjectToUse.getAxlesHolder().getAxleById(axle.getId()).getSuspensionMassRating();
                }
            },
            function () {
                return axle.getSuspensionMassRatingOverride();
            },
            function () {
                // rigOps.resetAxleValues(path, config.VALUE_TYPE.SUSPENSIONMASSRATING, rigOps.getAxleLocationFromAxle(axle));
                if (axle.getIsAxleGroup()) {
                    // Calculate the value to update the underlying axle to
                    var valPerAxle = axle.getSuspensionMassRating() / axle.getRelatedAxleIds().length;
                    
                    axle.getRelatedAxleIds().forEach(function (axleId) {
                        var individualAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
                        individualAxle.setSuspensionMassRating(valPerAxle); 
                    });
                }
                handleGAWRUpdate(axle, chassisObjectToUse);
                //if (axle.getAxleWeightOverride() === false) {
                //    if (rigOps.getAxleLocationFromAxle(axle) === config.AXLE_LOCATIONS.FRONT) {
                //        rigOps.distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.FRONT, getConfiguration().getTareFront());
                //    } else {
                //        rigOps.distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.REAR, getConfiguration().getTareRear());
                //    }
                //}
                if (!axle.getIsAxleGroup()) {
                    //if (axle.getDesiredWeight() > axle.getSuspensionMassRating()) {
                        
                    //}
                    axle.setDesiredWeight(Math.max(config.vehicleAxleDefaultDesiredWeight, axle.getSuspensionMassRating()));
                }
            }, true)
        .overridePermission(showAxleAttributeIfStandardAndLoadingAdminAddAxlesOrIfTemplate)
        .visiblePermission(function (path) {
            if (isTrailerChassis) {
                // return getObjectVisibilityPermission(chassisObjectToUse, function (path) {
                //     return globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED;
                // })(path);
                return getObjectVisibilityPermission(chassisObjectToUse, manufacturerRatingAttrGroupVisiblePermissionFunc)(path);
            } else {
                return showAxleAttributeIfStandardAndLoadingAdminAddAxlesOrIfTemplate(path) && globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED;
            }
        })
        .readOnlyPermission(function (path) {
            if (isTrailerChassis) {
                return getObjectReadOnlyPermission(chassisObjectToUse, config.MENU_SECTION.SPECIFICATION_WEIGHTS)(path);
            } else {
                return false;
            }
        })
        //.visiblePermission(function (path) {
        //    return showAxleAttributeIfStandardAndLoadingAdminAddAxlesOrIfTemplate(path) && path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1;
        //})
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.SUSPENSIONMASSRATING)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if (axle.getIsAxleGroup()) {
                    // Calculate the value to update the underlying axle to
                    var valPerAxle = newValue / axle.getRelatedAxleIds().length;

                    axle.getRelatedAxleIds().forEach(function (axleId) {
                        var individualAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
                        individualAxle.setSuspensionMassRating(valPerAxle);
                    });

                }
                handleGAWRUpdate(axle, chassisObjectToUse);
                //if (axle.getAxleWeightOverride() === false) {
                //    if (rigOps.getAxleLocationFromAxle(axle) === config.AXLE_LOCATIONS.FRONT) {
                //        rigOps.distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.FRONT, getConfiguration().getTareFront());
                //    } else {
                //        rigOps.distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.REAR, getConfiguration().getTareRear());
                //    }
                //}
                if (!axle.getIsAxleGroup()) {
                    if (axle.getDesiredWeight() > newValue) {
                        axle.setDesiredWeight(newValue);
                    }
                }
            })
        ), path);


    //// TYRES
    //no of tyres on axle
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.NOOFTYRESONAXLE, config.INPUT_TYPE.SELECT)
        .addSubscription(function (val) {
            
            if (axle.getIsAxleGroup()) {
                // Update the underlying axles
                var pathChassisObject = path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) === true ? config.CHASSIS_OBJECT_TYPES.VEHICLE : path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) === true ? config.CHASSIS_OBJECT_TYPES.TRAILER1 : config.CHASSIS_OBJECT_TYPES.TRAILER2;
                axle.getRelatedAxleIds().forEach(function (axleId) {
                    var optionToUse = getNumberOfTyresOptions().find(function(option){
                        return option.id === val;
                    });
                    if (optionToUse !== undefined) {
                        getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.NOOFTYRESONAXLE).option.value = optionToUse;
                    }
                    var individualAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
                    individualAxle.setNoOfTyresOnAxle(val);
                });
                axle.setNoOfTyresOnAxle(val);
                if(!axle.getGrossAxleWeightRatingOverride()) {
                    axle.setGrossAxleWeightRating(axle.getGrossAxleWeightRatingDefaultValue());
                    chassisObjectToUse.getAxlesHolder().setGroupAndUnderlyingAxleValuesWhenGAWROverrideRemoved(axle, originalChassisObjectToUse.getAxlesHolder().getAxleGroupById(axle.getId()));
                } else {
                    chassisObjectToUse.getAxlesHolder().setGroupAndUnderlyingAxleValuesWhenGAWROverridden(axle, axle.getGrossAxleWeightRating());
                }
                // getActiveOffer().getRig().getVehicle().getAxlesHolder().updateValuesOnAllAxlesAndAxleGroups();
                
            } else {
                axle.setNoOfTyresOnAxle(val);
                //axle.setNoOfTyresOnAxleForCosting(val);
                var forCostingAttrObvGroup = getAttributeGroup(path + '.' + config.VALUE_TYPE.NOOFTYRESONAXLEFORCOSTING);

                if (forCostingAttrObvGroup !== null && typeof forCostingAttrObvGroup === 'object' && forCostingAttrObvGroup.override !== undefined) {
                    //update costing value
                    if (axle.getNoOfTyresOnAxleForCostingOverride() === false) {
                        axle.setNoOfTyresOnAxleForCosting(val);
                    }
                    updateTyreRate();
                }

                if (path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) || path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
                    var trailer = rigOps.getChassisObjectFromPath(path);

                    if ((trailer.getType() === config.TRAILER_TYPES.DRAWBAR) && trailer.getPermissibleFrontOverride() === false) {
                        trailer.setPermissibleFront(rigOps.getTrailer1PermissibleFront());
                    }

                    if (trailer.getPermissibleRearOverride() === false) {
                        trailer.setPermissibleRear(rigOps.getTrailer1PermissibleRear());
                    }

                    //#unfinished, need to support other trailer types which will have different axle layout e.g. axle number 1 could be axle1Front

                    switch (axle.getNumber()) {
                        case 1:
                            if (trailer.getType() === config.TRAILER_TYPES.SEMI || trailer.getType() === config.TRAILER_TYPES.PUP || trailer.getType() === config.TRAILER_TYPES.INTERLINK) {
                                trailer.setNumberOfTyresOnAxle1Rear(val)
                            } else if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                trailer.setNumberOfTyresOnAxle1Front(val);
                            }

                            break;
                        case 2:
                            if (trailer.getType() === config.TRAILER_TYPES.SEMI || trailer.getType() === config.TRAILER_TYPES.PUP || trailer.getType() === config.TRAILER_TYPES.INTERLINK) {
                                trailer.setNumberOfTyresOnAxle2Rear(val)
                            } else if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                if (trailer.getNumberOfAxlesFront() > 1) {
                                    trailer.setNumberOfTyresOnAxle2Front(val);
                                } else {
                                    trailer.setNumberOfTyresOnAxle1Rear(val);
                                }

                            }
                            break;
                        case 3:
                            if (trailer.getType() === config.TRAILER_TYPES.SEMI || trailer.getType() === config.TRAILER_TYPES.PUP || trailer.getType() === config.TRAILER_TYPES.INTERLINK) {
                                trailer.setNumberOfTyresOnAxle3Rear(val)
                            } else if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                if (trailer.getNumberOfAxlesFront() === 3) {
                                    trailer.setNumberOfTyresOnAxle3Front(val);
                                } else if (trailer.getNumberOfAxlesFront() === 2) {
                                    trailer.setNumberOfTyresOnAxle1Rear(val);
                                } else {
                                    trailer.setNumberOfTyresOnAxle2Rear(val);
                                }

                            }
                            break;
                        case 4:
                            if (trailer.getType() === config.TRAILER_TYPES.SEMI || trailer.getType() === config.TRAILER_TYPES.PUP || trailer.getType() === config.TRAILER_TYPES.INTERLINK) {
                                trailer.setNumberOfTyresOnAxle4Rear(val)
                            } else if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                if (trailer.getNumberOfAxlesFront() === 3) {
                                    trailer.setNumberOfTyresOnAxle1Rear(val);
                                } else if (trailer.getNumberOfAxlesFront() === 2) {
                                    trailer.setNumberOfTyresOnAxle2Rear(val);
                                } else {
                                    trailer.setNumberOfTyresOnAxle3Rear(val);
                                }

                            }
                            break;
                        case 5:
                            if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                if (trailer.getNumberOfAxlesFront() === 3) {
                                    trailer.setNumberOfTyresOnAxle2Rear(val);
                                } else if (trailer.getNumberOfAxlesFront() === 2) {
                                    trailer.setNumberOfTyresOnAxle3Rear(val);
                                } else {
                                    trailer.setNumberOfTyresOnAxle4Rear(val);
                                }

                            }
                            break;
                        case 6:
                            if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                if (trailer.getNumberOfAxlesFront() === 3) {
                                    trailer.setNumberOfTyresOnAxle3Rear(val);
                                } else if (trailer.getNumberOfAxlesFront() === 2) {
                                    trailer.setNumberOfTyresOnAxle4Rear(val);
                                }

                            }
                            break;
                        case 7:
                            if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                if (trailer.getNumberOfAxlesFront() === 3) {
                                    trailer.setNumberOfTyresOnAxle4Rear(val);
                                }
                            }
                            break;
                    }
                }
            }
        })
        .visiblePermission(function (path) {
            if (isTrailerChassis) {
                return getObjectVisibilityPermission(chassisObjectToUse, function (path) {
                    return showNumTyresAxleAttribute(path);
                })(path);
            } else {
                return showNumTyresAxleAttribute(path);
            }
        })
        .readOnlyPermission(function (path) {
            if (isTrailerChassis) {
                return getObjectReadOnlyPermission(chassisObjectToUse, config.MENU_SECTION.SPECIFICATION_WEIGHTS)(path);
            } else {
                return false;
            }
        })
        //.visiblePermission(showNumTyresAxleAttribute)
        .setVal(numOfTyres), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.NOOFTYRESONAXLEFORCOSTING, config.INPUT_TYPE.SELECT)
        .getter(axle.getNoOfTyresOnAxleForCosting)
        .setter(axle.setNoOfTyresOnAxleForCosting)
        .overridePermission(function () {
            return true;
        })
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                axle.setNoOfTyresOnAxleForCostingOverride(val);

                //offerManager.getAttributeGroup(path + '.' + config.VALUE_TYPE.COSTINGTYRES).override(val);
                //getActiveOffer().getCosting().setTyreRateOverride(val);
                //if (val === true) {
                //    dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.OVERWROTE_TYRE_RATE));
                //}
                //refreshUILiveLabels(new Date().getTime());

                //if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
                //    rigOps.getAxleHolderFromPath(path).setAxleGroupSuspensionMassRatingOverrides(rigOps.getAxleLocationFromAxle(axle), val);
                //    var tempVehicle = getConfiguration();
                //    if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) {
                //        if (tempVehicle.getManufacturersFrontAxlesMassOverride() === true) {
                //            tempVehicle.setManufacturersFrontAxlesMassOverride(false);
                //        }
                //        var manufacturersFrontAxlesMassAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS);
                //        manufacturersFrontAxlesMassAOG.permissionObv(manufacturersFrontAxlesMassAOG.permission(manufacturersFrontAxlesMassAOG.getPath()));
                //    }
                //    if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                //        if (tempVehicle.getManufacturersRearAxlesMassOverride() === true) {
                //            tempVehicle.setManufacturersRearAxlesMassOverride(false);
                //        }
                //        var manufacturersRearAxlesMassAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.MANUFACTURERSREARAXLEMASS);
                //        manufacturersRearAxlesMassAOG.permissionObv(manufacturersRearAxlesMassAOG.permission(manufacturersRearAxlesMassAOG.getPath()));
                //    }
                //} else {
                //    axle.setNoOfTyresOnAxleForCosting(val);
                //}
            },
            function () {
                return axle.getNoOfTyresOnAxleForCosting();
            },
            function () {
                return axle.getNoOfTyresOnAxleForCosting();
            }), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.AVERAGECOSTOFTYRE, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.CURRENCYTOTALPRICE)
        .getter(axle.getAverageCostOfTyre)
        .setter(axle.setAverageCostOfTyre)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.AVERAGECOSTOFTYRE)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.AVERAGELIFEOFTYRE, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.MONTHLYDISTANCE)
        .getter(axle.getAverageLifeOfTyre)
        .setter(axle.setAverageLifeOfTyre)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.AVERAGELIFEOFTYRE)), path);

    //tyre radius
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TYRERADIUS, config.INPUT_TYPE.UPDOWNBOX)
        .getter(axle.getTyreRadius)
        .setter(function(val){
            axle.setTyreRadius(val);
            //if (axle.getIsAxleGroup()) {
            //    // Update the underlying axles
            //    var pathChassisObject = path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) === true ? config.CHASSIS_OBJECT_TYPES.VEHICLE : path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) === true ? config.CHASSIS_OBJECT_TYPES.TRAILER1 : config.CHASSIS_OBJECT_TYPES.TRAILER2;
            //    //axle.getRelatedAxleIds().forEach(function (axleId) {
            //    //    getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.TYRERADIUS).formatted(val);
            //    //});
            //    axle.getRelatedAxleIds().forEach(function (axleId) {
            //        var individualAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
            //        individualAxle.setTyreRadius(val);
            //    });
            //    axle.setTyreRadius(val);
            //} else {
            //    axle.setTyreRadius(val);
            //}
        })
        .visiblePermission(function (path) {
            if (isTrailerChassis) {
                return getObjectVisibilityPermission(chassisObjectToUse, function (path) {
                    return showAxleAttribute(path);
                })(path);
            } else {
                return showAxleAttribute(path);
            }
        })
        .readOnlyPermission(function (path) {
            if (isTrailerChassis) {
                return getObjectReadOnlyPermission(chassisObjectToUse, config.MENU_SECTION.SPECIFICATION_WEIGHTS)(path);
            } else {
                return false;
            }
        })
        //.visiblePermission(showAxleAttribute)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TYRERADIUS)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if (axle.getIsAxleGroup()) {
                    
                    axle.getRelatedAxleIds().forEach(function (axleId) {
                        var individualAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
                        individualAxle.setTyreRadius(newValue);
                    });
                }
                if (path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) || path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
                    var axles = rigOps.getAxlesFromPath(path);
                    axles.forEach(function (axle) {
                        axle.setTyreRadius(newValue);
                    });
                    var trailer = rigOps.getChassisObjectFromPath(path);
                    trailer.setTyreRadius(newValue);
                }
            })
        ), path);

    //tyre width
    if (showTyreWidthOverride() === false) {
        axle.setTyreWidthOverride(true);
    }

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TYREWIDTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(axle.getTyreWidth)
        .setter(function(val){
            axle.setTyreWidth(val);
            //if (axle.getIsAxleGroup()) {
            //    // Update the underlying axles
            //    var pathChassisObject = path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) === true ? config.CHASSIS_OBJECT_TYPES.VEHICLE : path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) === true ? config.CHASSIS_OBJECT_TYPES.TRAILER1 : config.CHASSIS_OBJECT_TYPES.TRAILER2;
            //    axle.getRelatedAxleIds().forEach(function (axleId) {
            //        getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.TYREWIDTH).formatted(val);
            //    });
            //    axle.setTyreWidth(val);
            //} else {
            //    axle.setTyreWidth(val);
            //}
        })
        .visiblePermission(function (path) {
            if (isTrailerChassis) {
                return getObjectVisibilityPermission(chassisObjectToUse)(path);
            } else {
                return showAxleAttributeIfStandardAndLoadingAdminOrIfTemplate(path);
            }
        })
        .readOnlyPermission(function (path) {
            if (isTrailerChassis) {
                return getObjectReadOnlyPermission(chassisObjectToUse, config.MENU_SECTION.SPECIFICATION_WEIGHTS)(path);
            } else {
                return false;
            }
        })
        //.visiblePermission(showAxleAttributeIfStandardAndLoadingAdminOrIfTemplate)
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                if (axle.getIsAxleGroup()) {
                    // Update the underlying axles
                    var pathChassisObject = path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) === true ? config.CHASSIS_OBJECT_TYPES.VEHICLE : path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) === true ? config.CHASSIS_OBJECT_TYPES.TRAILER1 : config.CHASSIS_OBJECT_TYPES.TRAILER2;
                    axle.getRelatedAxleIds().forEach(function (axleId) {
                        getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.TYREWIDTH).override.value = val;
                    });
                    axle.setTyreWidthOverride(val);
                } else {
                    axle.setTyreWidthOverride(val);
                }
            },
            function () {
                if (axle.getIsAxleGroup()) {
                    return originalChassisObjectToUse.getAxlesHolder().getAxleGroupById(axle.getId()).getTyreWidth();
                } else {
                    return originalChassisObjectToUse.getAxlesHolder().getAxleById(axle.getId()).getTyreWidth();
                }
                
            },
            function () {
                return axle.getTyreWidthOverride();
            })
        .overridePermission(showTyreWidthOverride)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TYREWIDTH)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if (axle.getIsAxleGroup()) {
                    // Calculate the value to update the underlying axle to
                    //var valPerAxle = newValue / axle.getRelatedAxleIds().length;

                    axle.getRelatedAxleIds().forEach(function (axleId) {
                        var individualAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
                        individualAxle.setTyreWidth(newValue);
                    });
                }
                if (path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) || path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
                    //var axles = rigOps.getAxlesFromPath(path);
                    //axles.forEach(function (axle) {
                    //    axle.setTyreWidth(newValue);
                    //});
                    var trailer = rigOps.getChassisObjectFromPath(path);
                    trailer.setTyreWidth(newValue);
                }
            })
        ), path);

    function showTyreWidthOverride() {
        return globals.user.isLoadingAdministrator() && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && axle.isAdded() === false;
    }

    //Tyre Mass Rating
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TYREMASSRATING, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(function () {
            if (axle.getNoOfTyresOnAxle() === 2) {
                return axle.getTyreMassRatingSingle();
            } else {
                return axle.getTyreMassRatingDual();
            }
        })
        .setter(function (val) {
            if (axle.getNoOfTyresOnAxle() === 2) {
                axle.setTyreMassRatingSingle(val);
            } else {
                axle.setTyreMassRatingDual(val);
            }
            //if (axle.getIsAxleGroup()) {
            //    // Update the underlying axles
            //    var currentAxleGroupValue;
            //    if (axle.getNoOfTyresOnAxle() === 2) {
            //        currentAxleGroupValue = axle.getTyreMassRatingSingle()
            //    } else {
            //        currentAxleGroupValue = axle.getTyreMassRatingDual()
            //    }
            //    var numberOfRelatedAxles = axle.getRelatedAxleIds().length,
            //        newRelatedAxleValue = val,
            //        deltaVal = val - currentAxleGroupValue,
            //        deltaValPerAxle = deltaVal / numberOfRelatedAxles;
            //    var pathChassisObject = path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) === true ? config.CHASSIS_OBJECT_TYPES.VEHICLE : path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) === true ? config.CHASSIS_OBJECT_TYPES.TRAILER1 : config.CHASSIS_OBJECT_TYPES.TRAILER2;
            //    axle.getRelatedAxleIds().forEach(function (axleId) {
            //        //var currentAxleValue = getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.TYREMASSRATING).value();
            //        //getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.TYREMASSRATING).formatted(currentAxleValue + deltaValPerAxle);
            //        var individualAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
            //        individualAxle.setManufacturerRating(individualAxle.getTyreMassRating() + deltaValPerAxle);
            //    });
            //    if (axle.getNoOfTyresOnAxle() === 2) {
            //        axle.setTyreMassRatingSingle(val);
            //    } else {
            //        axle.setTyreMassRatingDual(val);
            //    }
            //} else {
            //    if (axle.getNoOfTyresOnAxle() === 2) {
            //        axle.setTyreMassRatingSingle(val);
            //    } else {
            //        axle.setTyreMassRatingDual(val);
            //    }
            //}
        })
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                if (axle.getIsAxleGroup()) {
                    // Update the underlying axles
                    var pathChassisObject = path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) === true ? config.CHASSIS_OBJECT_TYPES.VEHICLE : path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) === true ? config.CHASSIS_OBJECT_TYPES.TRAILER1 : config.CHASSIS_OBJECT_TYPES.TRAILER2;
                    axle.getRelatedAxleIds().forEach(function (axleId) {
                        //getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.TYREMASSRATING).override(val);
                        var individualAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
                        individualAxle.setTyreMassRatingSingleOverride(val);
                        individualAxle.setTyreMassRatingDualOverride(val);
                    });
                    axle.setTyreMassRatingSingleOverride(val);
                    axle.setTyreMassRatingDualOverride(val);
                } else {
                    //if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
                    //    rigOps.getAxleHolderFromPath(path).setAxleGroupTyreMassRatingOverrides(rigOps.getAxleLocationFromAxle(axle), val);
                    //    var tempVehicle = getConfiguration();

                    //    if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) {
                    //        if (tempVehicle.getManufacturersFrontAxlesMassOverride() === true) {
                    //            tempVehicle.setManufacturersFrontAxlesMassOverride(false);
                    //        }
                    //        var manufacturersFrontAxlesMassAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS);
                    //        manufacturersFrontAxlesMassAOG.permissionObv(manufacturersFrontAxlesMassAOG.permission(manufacturersFrontAxlesMassAOG.getPath()));
                    //    }

                    //    if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                    //        if (tempVehicle.getManufacturersRearAxlesMassOverride() === true) {
                    //            tempVehicle.setManufacturersRearAxlesMassOverride(false);
                    //        }
                    //        var manufacturersRearAxlesMassAOG = getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.MANUFACTURERSREARAXLEMASS);
                    //        manufacturersRearAxlesMassAOG.permissionObv(manufacturersRearAxlesMassAOG.permission(manufacturersRearAxlesMassAOG.getPath()));
                    //    }

                    //} else {
                    //    axle.setTyreMassRatingSingleOverride(val);
                    //    axle.setTyreMassRatingDualOverride(val);

                    //    //if (axle.getNoOfTyresOnAxle() === 2) {

                    //    //} else {

                    //    //}
                    //}
                    axle.setTyreMassRatingSingleOverride(val);
                    axle.setTyreMassRatingDualOverride(val);
                }
            },
            function () {
                if (axle.getNoOfTyresOnAxle() === 2) {
                    //return axle.getTyreMassRatingSingle();
                    if (axle.getIsAxleGroup()) {
                        return originalChassisObjectToUse.getAxlesHolder().getAxleGroupById(axle.getId()).getTyreMassRatingSingle();
                    } else {
                        return originalChassisObjectToUse.getAxlesHolder().getAxleById(axle.getId()).getTyreMassRatingSingle();
                    }
                } else {
                    //return axle.getTyreMassRatingDual();
                    if (axle.getIsAxleGroup()) {
                        return originalChassisObjectToUse.getAxlesHolder().getAxleGroupById(axle.getId()).getTyreMassRatingDual();
                    } else {
                        return originalChassisObjectToUse.getAxlesHolder().getAxleById(axle.getId()).getTyreMassRatingDual();
                    }
                }

            },
            function () {
                if (axle.getNoOfTyresOnAxle() === 2) {
                    return axle.getTyreMassRatingSingleOverride();
                } else {
                    return axle.getTyreMassRatingDualOverride();
                }

            },
            function () {
                // rigOps.resetAxleValues(path, config.VALUE_TYPE.TYREMASSRATING, rigOps.getAxleLocationFromAxle(axle));
                if (axle.getIsAxleGroup()) {
                    let tyreMassRatingValToUse = 0;
                    if (axle.getNoOfTyresOnAxle() === 2) {
                        tyreMassRatingValToUse = axle.getTyreMassRatingSingle();
                    } else {
                        tyreMassRatingValToUse = axle.getTyreMassRatingDual();
                    } 
                    // Calculate the value to update the underlying axle to
                    var valPerAxle = tyreMassRatingValToUse / axle.getRelatedAxleIds().length;
                    
                    axle.getRelatedAxleIds().forEach(function (axleId) {
                        var individualAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
                        if (axle.getNoOfTyresOnAxle() === 2) {
                            individualAxle.setTyreMassRatingSingle(valPerAxle);
                        } else {
                            individualAxle.setTyreMassRatingDual(valPerAxle);
                        } 
                    });
                }
                handleGAWRUpdate(axle, chassisObjectToUse);
            }, true)
        .overridePermission(showAxleAttributeIfStandardAndLoadingAdminAddAxlesOrIfTemplate)
        .visiblePermission(function (path) {
            if (isTrailerChassis) {
                // return getObjectVisibilityPermission(chassisObjectToUse, function (path) {
                //     return showAxleAttributeIfStandardAndLoadingAdminAddAxlesOrIfTemplate(path) && path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) === -1 && globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED;
                // })(path);
                return getObjectVisibilityPermission(chassisObjectToUse, manufacturerRatingAttrGroupVisiblePermissionFunc)(path);
            } else {
                return showAxleAttributeIfStandardAndLoadingAdminAddAxlesOrIfTemplate(path) && globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED;
            }
        })
        .readOnlyPermission(function (path) {
            if (isTrailerChassis) {
                return getObjectReadOnlyPermission(chassisObjectToUse, config.MENU_SECTION.SPECIFICATION_WEIGHTS)(path);
            } else {
                return false;
            }
        })
        //.visiblePermission(function (path) {
        //    return showAxleAttributeIfStandardAndLoadingAdminAddAxlesOrIfTemplate(path) && path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1;
        //})
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TYREMASSRATING)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if (axle.getIsAxleGroup()) {
                    // Calculate the value to update the underlying axle to
                    var valPerAxle = newValue / axle.getRelatedAxleIds().length;
                    
                    axle.getRelatedAxleIds().forEach(function (axleId) {
                        var individualAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
                        if (axle.getNoOfTyresOnAxle() === 2) {
                            individualAxle.setTyreMassRatingSingle(valPerAxle);
                        } else {
                            individualAxle.setTyreMassRatingDual(valPerAxle);
                        }
                        //individualAxle.setManufacturerRating(valPerAxle);
                    });
                }
                handleGAWRUpdate(axle, chassisObjectToUse);
            })
        ), path);

        //PERCENTAGE OF WEIGHT CARRIED
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.AXLEPERCENTAGEOFWEIGHTCARRIED, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.PERCENTAGE)
        .getter(axle.getPercentageOfWeightCarried)
        .setter(function (val) {
            axle.setPercentageOfWeightCarried(val);
            chassisObjectToUse.getAxlesHolder().calculateRearRearPercentageOfWeightCarried();
            //if (axle.getIsAxleGroup()) {
            //    // Calculate the value to update the underlying axle to
            //    var currentAxleGroupValue = axle.getManufacturerRating(),
            //        numberOfRelatedAxles = axle.getRelatedAxleIds().length,
            //        newRelatedAxleValue = val,
            //        deltaVal = val - currentAxleGroupValue,
            //        deltaValPerAxle = deltaVal / numberOfRelatedAxles;
            //    // Update the underlying axles
            //    var pathChassisObject = path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) === true ? config.CHASSIS_OBJECT_TYPES.VEHICLE : path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) === true ? config.CHASSIS_OBJECT_TYPES.TRAILER1 : config.CHASSIS_OBJECT_TYPES.TRAILER2;
            //    axle.getRelatedAxleIds().forEach(function (axleId) {
            //        var currentAxleValue = getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.AXLEDESIREDWEIGHT).value();
            //        getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.AXLEDESIREDWEIGHT).formatted(currentAxleValue + deltaValPerAxle);
            //    });
            //    axle.setDesiredWeight(val);
            //} else {
            //    axle.setDesiredWeight(val);
            //}
        })
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                // if (axle.getIsAxleGroup()) {
                //     // Update the underlying axles
                //     var pathChassisObject = path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) === true ? config.CHASSIS_OBJECT_TYPES.VEHICLE : path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) === true ? config.CHASSIS_OBJECT_TYPES.TRAILER1 : config.CHASSIS_OBJECT_TYPES.TRAILER2;
                //     axle.getRelatedAxleIds().forEach(function (axleId) {
                //         // getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.AXLEPERCENTAGEOFWEIGHTCARRIED).override.value = val;
                //         let individualAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
                //         individualAxle.setPercentageOfWeightCarriedOverride(val);
                //     });
                //     axle.setPercentageOfWeightCarriedOverride(val);
                // } else {
                    axle.setPercentageOfWeightCarriedOverride(val);
                // }
            },
            function () {
                return originalChassisObjectToUse.getAxlesHolder().getAxleById(axle.getId()).getPercentageOfWeightCarried();
            },
            function () {
                return axle.getPercentageOfWeightCarriedOverride();
            })
        .visiblePermission(function (path) {
            if ((!axle.getIsAxleGroup() && !isTrailerChassis) || (axle.getIsAxleGroup() && !isTrailerChassis && chassisObjectToUse.getAxlesHolder().getHasPusherOrTagAxleDown())) {
                return true;
            } else {
                return false;
            }
        })
        .readOnlyPermission(function (path) {
            if (axle.getIsAxleGroup()) {
                return true;
            } else {
                return false;
            }
        })
        .overridePermission(function(){ return true; })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.AXLEPERCENTAGEOFWEIGHTCARRIED)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                // if (axle.getIsAxleGroup()) {
                //     // Calculate the value to update the underlying axle to
                //     var valPerAxle = newValue / axle.getRelatedAxleIds().length;

                //     axle.getRelatedAxleIds().forEach(function (axleId) {
                //         var individualAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
                //         individualAxle.setPercentageOfWeightCarried(valPerAxle);
                //     });
                // }
            })
        ), path);

    // Desired Weight 
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.AXLEDESIREDWEIGHT, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(axle.getDesiredWeight)
        .setter(function (val) {
            axle.setDesiredWeight(val);
            //if (axle.getIsAxleGroup()) {
            //    // Calculate the value to update the underlying axle to
            //    var currentAxleGroupValue = axle.getManufacturerRating(),
            //        numberOfRelatedAxles = axle.getRelatedAxleIds().length,
            //        newRelatedAxleValue = val,
            //        deltaVal = val - currentAxleGroupValue,
            //        deltaValPerAxle = deltaVal / numberOfRelatedAxles;
            //    // Update the underlying axles
            //    var pathChassisObject = path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) === true ? config.CHASSIS_OBJECT_TYPES.VEHICLE : path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) === true ? config.CHASSIS_OBJECT_TYPES.TRAILER1 : config.CHASSIS_OBJECT_TYPES.TRAILER2;
            //    axle.getRelatedAxleIds().forEach(function (axleId) {
            //        var currentAxleValue = getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.AXLEDESIREDWEIGHT).value();
            //        getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.AXLEDESIREDWEIGHT).formatted(currentAxleValue + deltaValPerAxle);
            //    });
            //    axle.setDesiredWeight(val);
            //} else {
            //    axle.setDesiredWeight(val);
            //}
        })
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                if (axle.getIsAxleGroup()) {
                    // Update the underlying axles
                    var pathChassisObject = path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) === true ? config.CHASSIS_OBJECT_TYPES.VEHICLE : path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) === true ? config.CHASSIS_OBJECT_TYPES.TRAILER1 : config.CHASSIS_OBJECT_TYPES.TRAILER2;
                    axle.getRelatedAxleIds().forEach(function (axleId) {
                        getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.AXLEDESIREDWEIGHT).override.value = val;
                    });
                    axle.setDesiredWeightOverride(val);
                } else {
                    axle.setDesiredWeightOverride(val);
                }
            },
            function () {
                return Math.min(originalChassisObjectToUse.getAxlesHolder().getAxleById(axle.getId()).getDesiredWeight(), axle.getGrossAxleWeightRating());
            },
            function () {
                return axle.getDesiredWeightOverride();
            })
        .visiblePermission(function (path) {
            if (isTrailerChassis) {
                return getObjectVisibilityPermission(chassisObjectToUse)(path);
            } else {
                return true;
            }
        })
        .readOnlyPermission(function (path) {
            if (isTrailerChassis) {
                return getObjectReadOnlyPermission(chassisObjectToUse, config.MENU_SECTION.SPECIFICATION_WEIGHTS)(path);
            } else {
                return false;
            }
        })
        .overridePermission(function(){ return true; })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.AXLEDESIREDWEIGHT)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if (axle.getIsAxleGroup()) {
                    // Calculate the value to update the underlying axle to
                    var valPerAxle = newValue / axle.getRelatedAxleIds().length;

                    axle.getRelatedAxleIds().forEach(function (axleId) {
                        var individualAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
                        individualAxle.setDesiredWeight(valPerAxle);
                    });
                }
            })
        ), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.AXLEBRIDGEFORMULAPERMISSIBLE, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(axle.getBridgeFormulaPermissible)
        .setter(function(val){
            axle.setBridgeFormulaPermissible(val);
            //if (axle.getIsAxleGroup()) {
            //    // Calculate the value to update the underlying axle to
            //    var currentAxleGroupValue = axle.getManufacturerRating(),
            //        numberOfRelatedAxles = axle.getRelatedAxleIds().length,
            //        newRelatedAxleValue = val,
            //        deltaVal = val - currentAxleGroupValue,
            //        deltaValPerAxle = deltaVal / numberOfRelatedAxles;
            //    // Update the underlying axles
            //    var pathChassisObject = path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) === true ? config.CHASSIS_OBJECT_TYPES.VEHICLE : path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) === true ? config.CHASSIS_OBJECT_TYPES.TRAILER1 : config.CHASSIS_OBJECT_TYPES.TRAILER2;
            //    axle.getRelatedAxleIds().forEach(function (axleId) {
            //        var currentAxleValue = getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.AXLEBRIDGEFORMULAPERMISSIBLE).value();
            //        getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.AXLEBRIDGEFORMULAPERMISSIBLE).formatted(currentAxleValue + deltaValPerAxle);
            //    });
            //    axle.setBridgeFormulaPermissible(val);
            //} else {
            //    axle.setBridgeFormulaPermissible(val);
            //}
        })
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                if (axle.getIsAxleGroup()) {
                    // axle.setBridgeFormulaPermissible(axle.getBridgeFormulaPermissible());
                    // Update the underlying axles
                    var pathChassisObject = path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) === true ? config.CHASSIS_OBJECT_TYPES.VEHICLE : path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) === true ? config.CHASSIS_OBJECT_TYPES.TRAILER1 : config.CHASSIS_OBJECT_TYPES.TRAILER2;
                    axle.getRelatedAxleIds().forEach(function (axleId) {
                        getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.AXLEBRIDGEFORMULAPERMISSIBLE).override.value = val;
                    });
                    
                    axle.setBridgeFormulaPermissibleOverride(val);
                    if(val === true) {
                        chassisObjectToUse.getAxlesHolder().setGroupAndUnderlyingAxleValuesWhenBridgeFormulaPermissibleOverridden(axle, axle.getBridgeFormulaPermissible());
                    } else {
                        chassisObjectToUse.getAxlesHolder().setGroupAndUnderlyingAxleValuesWhenBridgeFormulaPermissibleOverrideRemoved(axle, originalChassisObjectToUse.getAxlesHolder().getAxleGroupById(axle.getId()));
                    }
                } else {
                    // axle.setBridgeFormulaPermissible(axle.getBridgeFormulaPermissible());
                    axle.setBridgeFormulaPermissibleOverride(val);
                    if(val === true) {
                        chassisObjectToUse.getAxlesHolder().setAxleValuesWhenBridgeFormulaPermissibleOverridden(axle);
                    } else {
                        chassisObjectToUse.getAxlesHolder().setAxleValuesWhenBridgeFormulaPermissibleOverrideRemoved(axle, originalChassisObjectToUse.getAxlesHolder().getAxleById(axle.getId()));
                    }
                }
            },
            function () {
                if (axle.getIsAxleGroup()) {
                    return originalChassisObjectToUse.getAxlesHolder().getAxleGroupById(axle.getId()).getBridgeFormulaPermissible();
                } else {
                    return originalChassisObjectToUse.getAxlesHolder().getAxleById(axle.getId()).getBridgeFormulaPermissible();
                }
            },
            function () {
                return axle.getBridgeFormulaPermissibleOverride();
            })
        .visiblePermission(function (path) {
            if (isTrailerChassis) {
                return getObjectVisibilityPermission(chassisObjectToUse)(path);
            } else {
                return true;
            }
        })
        .readOnlyPermission(function (path) {
            if (isTrailerChassis) {
                return getObjectReadOnlyPermission(chassisObjectToUse, config.MENU_SECTION.SPECIFICATION_WEIGHTS)(path);
            } else {
                return false;
            }
        })
        .overridePermission(function () { return true; })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.AXLEBRIDGEFORMULAPERMISSIBLE)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if (axle.getIsAxleGroup()) {
                    // Calculate the value to update the underlying axle to
                    var valPerAxle = newValue / axle.getRelatedAxleIds().length;

                    axle.getRelatedAxleIds().forEach(function (axleId) {
                        var individualAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
                        individualAxle.setBridgeFormulaPermissible(valPerAxle);
                    });
                }
            })
        ), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.AXLEPERMISSIBLEDETAILED, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(axle.getPermissibleDetailed)
        .visiblePermission(function() { return getObjectVisibilityPermission(chassisObjectToUse, function (path) {
            return globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED;
        })(path);})
        .readOnly(), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.AXLEPERMISSIBLESIMPLIFIED, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(axle.getPermissibleSimplified)
        .visiblePermission(function() { return getObjectVisibilityPermission(chassisObjectToUse, function (path) {
            return globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.SIMPLIFIED;
        })(path);})
        .readOnly(), path);

    // Gross Axle Weight Rating
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.GROSSAXLEWEIGHTRATING, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(axle.getGrossAxleWeightRating)
        .setter(function (val) {
            axle.setGrossAxleWeightRating(val);
            //if (axle.getIsAxleGroup()) {
            //    // Calculate the value to update the underlying axle to
            //    var currentAxleGroupValue = axle.getGrossAxleWeightRating(),
            //        numberOfRelatedAxles = axle.getRelatedAxleIds().length,
            //        newRelatedAxleValue = val,
            //        deltaVal = val - currentAxleGroupValue,
            //        deltaValPerAxle = deltaVal / numberOfRelatedAxles;
            //    // Update the underlying axles
            //    var pathChassisObject = path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) === true ? config.CHASSIS_OBJECT_TYPES.VEHICLE : path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) === true ? config.CHASSIS_OBJECT_TYPES.TRAILER1 : config.CHASSIS_OBJECT_TYPES.TRAILER2;
            //    axle.getRelatedAxleIds().forEach(function (axleId) {
            //        var currentAxleValue = getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.GROSSAXLEWEIGHTRATING).value();
            //        getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.GROSSAXLEWEIGHTRATING).formatted(currentAxleValue + deltaValPerAxle);
            //    });
            //    axle.setGrossAxleWeightRating(val);
            //} else {
            //    axle.getGrossAxleWeightRating(val);
            //}
        })
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                if (axle.getIsAxleGroup()) {
                    // Update the underlying axles
                    var pathChassisObject = path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE) === true ? config.CHASSIS_OBJECT_TYPES.VEHICLE : path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) === true ? config.CHASSIS_OBJECT_TYPES.TRAILER1 : config.CHASSIS_OBJECT_TYPES.TRAILER2;
                    axle.getRelatedAxleIds().forEach(function (axleId) {
                        //getAttributeGroup(pathChassisObject + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.GROSSAXLEWEIGHTRATING).override(val);
                        var individualAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
                        individualAxle.setGrossAxleWeightRatingOverride(val);
                    });
                    axle.setGrossAxleWeightRatingOverride(val);
                    if(val === true) {
                        chassisObjectToUse.getAxlesHolder().setGroupAndUnderlyingAxleValuesWhenGAWROverridden(axle, axle.getGrossAxleWeightRating());
                    } else {
                        chassisObjectToUse.getAxlesHolder().setGroupAndUnderlyingAxleValuesWhenGAWROverrideRemoved(axle, originalChassisObjectToUse.getAxlesHolder().getAxleGroupById(axle.getId()));
                    }
                    // getActiveOffer().getRig().getVehicle().getAxlesHolder().updateValuesOnAllAxlesAndAxleGroups();
                } else {
                    axle.setGrossAxleWeightRatingOverride(val);
                    if (val === true) {
                        chassisObjectToUse.getAxlesHolder().setAxleValuesWhenGAWROverridden(axle);
                    } else {
                        chassisObjectToUse.getAxlesHolder().setAxleValuesWhenGAWROverrideRemoved(axle, originalChassisObjectToUse.getAxlesHolder().getAxleById(axle.getId()));
                    }
                }
            },
            function () {
                // if (axle.getIsAxleGroup()) {
                //     return originalChassisObjectToUse.getAxlesHolder().getAxleGroupById(axle.getId()).getGrossAxleWeightRating();
                // } else {
                //     return originalChassisObjectToUse.getAxlesHolder().getAxleById(axle.getId()).getGrossAxleWeightRating();
                // }
                if (axle.getIsAxleGroup()) {
                    return chassisObjectToUse.getAxlesHolder().getAxleGroupById(axle.getId()).getGrossAxleWeightRating();
                } else {
                    return chassisObjectToUse.getAxlesHolder().getAxleById(axle.getId()).getGrossAxleWeightRating();
                }
            },
            function () {
                return axle.getGrossAxleWeightRatingOverride();
            },
            function () {
                if (!axle.getIsAxleGroup()) {
                    if (axle.getDesiredWeight() > axle.getGrossAxleWeightRating()) {
                        axle.setDesiredWeight(Math.min(config.vehicleAxleDefaultDesiredWeight, axle.getGrossAxleWeightRating()));
                    }
                    
                }
            })
        .visiblePermission(function (path) {
            if (isTrailerChassis) {
                return getObjectVisibilityPermission(chassisObjectToUse)(path);
            } else {
                return showAxleAttributeIfStandardAndLoadingAdminAddAxlesOrIfTemplate(path) && globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.SIMPLIFIED;
            }
        })
        .readOnlyPermission(function (path) {
            if (isTrailerChassis) {
                return getObjectReadOnlyPermission(chassisObjectToUse, config.MENU_SECTION.SPECIFICATION_WEIGHTS)(path);
            } else {
                return false;
            }
        })
        .overridePermission(function () { return true; })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.GROSSAXLEWEIGHTRATING)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if (axle.getIsAxleGroup()) {
                    // Calculate the value to update the underlying axle to
                    chassisObjectToUse.getAxlesHolder().setGroupAndUnderlyingAxleValuesWhenGAWROverridden(axle, newValue);
                    
                } else {
                    chassisObjectToUse.getAxlesHolder().setAxleValuesWhenGAWROverridden(axle);
                }
                if (!axle.getIsAxleGroup()) {
                    if (axle.getDesiredWeight() > newValue) {
                        axle.setDesiredWeight(newValue);
                    }
                }
            })
        ), path);

    function getChassisObjectToUseFunc() {
        return chassisObjectToUse;
    }
}



function addAxleRearTotalObjectObservables(path) {
    var chassisObjectToUse = rigOps.getChassisObjectFromPath(path),
        originalChassisObjectToUse = rigOps.getOriginalChassisObjectFromPath(path);
    
    function placeholderGetterFunction() {
        return 0;
    }
    // Curb/Tare Weight Original 
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TAREWEIGHTORIGINAL, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(function () {
            //return getActiveOffer().getRig().getVehicle().getTareRearRear();
            return getActiveOffer().getRig().getVehicle().getAxlesHolder().getRearAxleWeight();
        })
        .visiblePermission(function (path) {
            var chassisObject = rigOps.getChassisObjectFromPath(path);
            return !chassisObject.getAxlesHolder().hasAddedAxle();
        })
        .readOnly(), path);
    // Curb/Tare Weight Modified 
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TAREWEIGHTMODIFIED, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(function () {
            return rigOps.getDistributedTareRearTotal()
        })
        .visiblePermission(function (path) {
            var chassisObject = rigOps.getChassisObjectFromPath(path);
            return chassisObject.getAxlesHolder().hasAddedAxle();
        })
        .readOnly(), path);

    // Axle Manufacturer Rating
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.AXLEMANUFACTURERRATING, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(getActiveOffer().getRig().getVehicle().getAxlesHolder().getRearAxleManufacturerRating)
        .readOnly(), path);

    //Suspension Mass Rating
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SUSPENSIONMASSRATING, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(getActiveOffer().getRig().getVehicle().getAxlesHolder().getRearAxleSuspensionRating)
        .readOnly(), path);
    
    ////Rear Road Friendly Suspension
    //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.REARROADFRIENDLYSUSPENSION, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
    //    .getter(placeholderGetterFunction)
    //    .readOnly(), path);

    //Tyre Mass Rating
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TYREMASSRATING, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(getActiveOffer().getRig().getVehicle().getAxlesHolder().getRearAxleTyreRating)
        .readOnly(), path);

    // Bridge/Regulation
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.AXLEBRIDGEFORMULAPERMISSIBLE, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(getConfiguration().getBridgeFormulaRearPermissible)
        .setter(getConfiguration().setBridgeFormulaRearPermissible)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setBridgeFormulaRearPermissibleOverride(val);
            },
            function () {
                return getOriginalOffer().getConfiguration().getBridgeFormulaRearPermissible();
            },
            function () {
                return getConfiguration().getBridgeFormulaRearPermissibleOverride();
            })
        .overridePermission(function () { return true; })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.AXLEBRIDGEFORMULAPERMISSIBLE)
            //.addOpBlock(function (newValue, oldValue, deltaVal) {
            //    if (axle.getIsAxleGroup()) {
            //        // Calculate the value to update the underlying axle to
            //        var valPerAxle = newValue / axle.getRelatedAxleIds().length;

            //        axle.getRelatedAxleIds().forEach(function (axleId) {
            //            var individualAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
            //            individualAxle.setBridgeFormulaPermissible(valPerAxle);
            //        });
            //    }
            //})
        ), path);

    // Permissible - Detailed
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.AXLEPERMISSIBLEDETAILED, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(rigOps.getVehicleRearPermissible)
        .visiblePermission(function() { return getObjectVisibilityPermission(chassisObjectToUse, function (path) {
            return globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED;
        })(path);})
        .readOnly(), path);

    // Gross Axle Weight Rating
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.GROSSAXLEWEIGHTRATING, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(getActiveOffer().getRig().getVehicle().getAxlesHolder().getRearAxlesGrossAxleWeightRating)
        .visiblePermission(function() { return getObjectVisibilityPermission(chassisObjectToUse, function (path) {
            return globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.SIMPLIFIED;
        })(path);})
        .readOnly(), path);

    // Permissible - Simplified
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.AXLEPERMISSIBLESIMPLIFIED, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(rigOps.getVehicleRearPermissible)
        .readOnly(), path);

    

    //// No of Tyres on Rear Axle
    //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.NOOFTYRESONAXLE, config.INPUT_TYPE.UPDOWNBOX)
    //    .getter(function () { return 10; })
    //    .readOnly(), path);

    function getChassisObjectToUseFunc() {
        return chassisObjectToUse;
    }
}

function handleGAWRUpdate(axle, chassisObjectToUse) {
    if (axle.getIsAxleGroup()) {
        var groupGAWRVal = axle.getGrossAxleWeightRatingDefaultValue();
        var gawrPerAxle = groupGAWRVal / axle.getRelatedAxleIds().length;
        axle.getRelatedAxleIds().forEach(function (axleId) {
            var individualAxle = chassisObjectToUse.getAxlesHolder().getAxleById(axleId);
            individualAxle.setGrossAxleWeightRating(gawrPerAxle);
        });
        axle.setGrossAxleWeightRating(groupGAWRVal);
    } else {
        var gAWRVal = axle.getGrossAxleWeightRatingDefaultValue();
        axle.setGrossAxleWeightRating(gAWRVal);
    }
}

function addFuelTankObservables(fuelTanks) {

    var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.FUEL_TANKS;

    var fuelTypeOption = getFuelTypeOptions()
        .filter(function (option) {
            if (option.id === getConfiguration().getFuelType()) {
                return option;
            } else if (option.id === config.FUEL_TYPE_OPTIONS.DIESEL.CODE && (getConfiguration().getFuelType() === '' || getConfiguration().getFuelType() === undefined || getConfiguration().getFuelType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FUELTYPE, config.INPUT_TYPE.SELECT)
        .visiblePermission(function () { return true; })
        .readOnlyPermission(function () { 
            return ((isCostingAvailableForOffer() === true) || (getActiveOffer().getDataAvailability().ActivePerformance && getActiveOffer().getPerformance() !== undefined && globals.user.hasPermission(config.PERMISSIONS.PERFORMANCE.Code))) || (isStandardVehicle() && !globals.user.isLoadingAdministrator()); 
        })
        .setVal(fuelTypeOption)
        .addSubscription(function (val) {
            getConfiguration().setFuelType(val);
            getConfiguration().setFuelSpecificGravityOverride(false);
            getConfiguration().setFuelSpecificGravity(getConfiguration().getFuelSpecificGravity());
            getConfiguration().setFuelSpecificGravity(getConfiguration().getFuelSpecificGravity());
            let defaultAddedFuelTank = rigOps.resetFuelTanks(val);
            bus.emit('removeMenuStructuresForFuelTanks', { path: path, callback: function () {
                var source = config.OBJECT_TYPES.FUEL_TANKS;
                createObjectWithObservables(source, defaultAddedFuelTank, path);
                if(typeof objectAddedListenerCallback === 'function') {
                // nextTick(function (){
                    objectAddedListenerCallback(source, defaultAddedFuelTank, path);
                // });
                
                }
            }});
            
        }), path);


    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FUELEXPRESSEDAS, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
        .setter(getConfiguration().setExpressedAs)
        .getter(getConfiguration().getExpressedAs)
        .visiblePermission(function () { 
            let fuelType = getConfiguration().getFuelType();
            return fuelType === config.FUEL_TYPE_OPTIONS.CNG.CODE || fuelType === config.FUEL_TYPE_OPTIONS.LNG.CODE || fuelType === config.FUEL_TYPE_OPTIONS.HYDROGEN_FUEL_CELL.CODE;
        }).addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FUELEXPRESSEDAS)
            .addOpBlock(function (newValue, oldValue) {
                if (newValue === '') {
                    throw 1;
                }
                if(newValue !== oldValue) {
                    getConfiguration().setFuelSpecificGravityOverride(true);
                    getConfiguration().setFuelSpecificGravity(0);
                }
            })
        )
        .addLinkedAOG(path + '.' + config.VALUE_TYPE.FUELSPECIFICGRAVITY)
        .addLinkedMultiCardAOG(path, getConfiguration().getFuelTanks, config.VALUE_TYPE.FUELTANKCAPACITY)
        .addLinkedMultiCardAOG(path, getConfiguration().getFuelTanks, config.VALUE_TYPE.FUELTANKVOLUMEINCLUDEDINTARE)
        .addLinkedMultiCardAOG(path, getConfiguration().getFuelTanks, config.VALUE_TYPE.FUELTANKVOLUMEINCLUDEDINEXTRAS), path);


    var fuelSpecificGravityValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.FUELSPECIFICGRAVITY, config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_ONLY);
    var fuelSpecificGravityAOG = new AttributeObservableGroup(config.VALUE_TYPE.FUELSPECIFICGRAVITY, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.SPECIFICGRAVITY)
        .getter(getConfiguration().getFuelSpecificGravity)
        .setter(function(val) {
            getConfiguration().setFuelSpecificGravity(val)
        })
        .visiblePermission(energySourceIsNotBatteryElectric)
        .overridePermission(function () { return true})
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setFuelSpecificGravityOverride(val);
            },
            function () {
                return getConfiguration().getFuelSpecificGravity();
            },
            function () {
                return getConfiguration().getFuelSpecificGravityOverride();
            }
            )
            .addFormattingRule(function () {
                let tempFueltType = getActiveOffer().getRig().getVehicle().getFuelType();
                if(tempFueltType === config.FUEL_TYPE_OPTIONS.HYDROGEN_FUEL_CELL.CODE || tempFueltType === config.FUEL_TYPE_OPTIONS.LNG.CODE || tempFueltType === config.FUEL_TYPE_OPTIONS.CNG.CODE) {
                    return true;
                }
                return false;
            }, function (value) {
                var measurementSystemToUse = getMeasurementSystemToUse();
                let unitExpressedAs = measurementSystemToUse.unitMass;
                // if(globals.user.isUSOrCanadaBasedUser() || getActiveOffer().getRig().getVehicle().getFuelType() === config.FUEL_TYPE_OPTIONS.HYDROGEN_FUEL_CELL.CODE) {
                    unitExpressedAs = getActiveOffer().getRig().getVehicle().getExpressedAs();
                // }
                return Globalize.format(value * measurementSystemToUse.massConversion, "n" + measurementSystemToUse.densityPrecision) + ' ' + measurementSystemToUse.unitMass + '/' + unitExpressedAs; // User Story 17565
            }, config.INCREMENT_TYPE.MASS)
            .addValidation(fuelSpecificGravityValidation);
            //fuelSpecificGravityAOG.setValidationMode(config.VALIDATION_MODE.OFF);

    addAttributeGroup(fuelSpecificGravityAOG, path);


    
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FUELADBLUESPECIFICGRAVITY, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.SPECIFICGRAVITY)
        .getter(getConfiguration().getSpecificGravityAdBlue)
        .setter(function(val) {
            getConfiguration().setSpecificGravityAdBlue(val)
        })
        .visiblePermission(showAdBlueSpecficGravity)
        .overridePermission(function () { return true})
        .addOverrideMechanism(getConfiguration,
            function (val) {
                getConfiguration().setSpecificGravityAdBlueOverride(val);
            },
            function () {
                return getOriginalOffer().getRig().getVehicle().getSpecificGravityAdBlue();
            },
            function () {
                return getConfiguration().getSpecificGravityAdBlueOverride();
            }
            )
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FUELADBLUESPECIFICGRAVITY, config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_ONLY)), path);
    

    


    fuelTanks.forEach(function (fuelTank) {
        createFuelTankObjectObservables(fuelTank, path);
    });
}

function createFuelTankObjectObservables(fuelTank, path) {

    path = path + '.' + fuelTank.getId();

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FUELTANKINCLUDED, config.INPUT_TYPE.CHECKBOX)
        .readOnlyPermission(function () {
            return fuelTank.getOfferedAs() === config.OFFERED_AS_OPTIONS.STANDARD ;
        })
        .setVal(fuelTank.getIncludeInCalculation())
        .addSubscription(function (val) {
            fuelTank.setIncludeInCalculation(val);
        }), path);

    var offeredAsOption = getOfferedAsOptions()
        .filter(function (option) {
            if (option.id === fuelTank.getOfferedAs()) {
                return option;
            } else if (option.id === config.OFFERED_AS_OPTIONS.OPTIONAL && (fuelTank.getOfferedAs() === '' || fuelTank.getOfferedAs() === undefined || fuelTank.getOfferedAs() === null)) {
                return option;
            }
        })[0];


    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FUELTANKOFFEREDAS, config.INPUT_TYPE.SELECT)
        .readOnlyPermission(function () {
            return fuelTank.isFactoryFitted() === true;
        })
        .visiblePermission(function (){ return false; })
        .addSubscription(function (val) {
            if (val === config.OFFERED_AS_OPTIONS.STANDARD) {
                getAttributeGroup(path + '.' + config.VALUE_TYPE.FUELTANKINCLUDED).value.value = true;
            }
            fuelTank.setOfferedAs(val);
        })
        .setVal(offeredAsOption), path);


    if (isNotStandardVehicle()) {
        fuelTank.setHorizontalCogOverride(true);
        fuelTank.setVerticalCogOverride(true);
        fuelTank.setLateralCogOverride(true);
    }

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FUELTANKHORIZONTALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(fuelTank.getHorizontalCog)
        .setter(fuelTank.setHorizontalCog)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                fuelTank.setHorizontalCogOverride(val);
            },
            function () {
                return getOriginalOffer().getConfiguration().getFuelTankById(fuelTank.getId()).getHorizontalCog();
            },
            function () {
                return fuelTank.getHorizontalCogOverride();
            })
        //.overridePermission(hideForStandardVehicleUnlessLoadingAdmin)
        .overridePermission(function () {
            return !isNotStandardVehicle();
        })
        .visiblePermission(showUnlessStandardAndNotAdminAndEnergySourceNotBatteryElectric)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FUELTANKHORIZONTALCOG)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FUELTANKVERTICALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(fuelTank.getVerticalCog)
        .setter(fuelTank.setVerticalCog)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                fuelTank.setVerticalCogOverride(val);
            },
            function () {
                return getOriginalOffer().getConfiguration().getFuelTankById(fuelTank.getId()).getVerticalCog();
            },
            function () {
                return fuelTank.getVerticalCogOverride();
            })
        //.overridePermission(hideForStandardVehicleUnlessLoadingAdmin)
        .overridePermission(function () {
            return !isNotStandardVehicle();
        })
        .visiblePermission(showUnlessStandardAndNotAdminAndEnergySourceNotBatteryElectric)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FUELTANKVERTICALCOG)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FUELTANKLATERALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(fuelTank.getLateralCog)
        .setter(fuelTank.setLateralCog)
        .addOverrideMechanism(getConfiguration,
            function (val) {
                fuelTank.setLateralCogOverride(val);
            },
            function () {
                return getOriginalOffer().getConfiguration().getFuelTankById(fuelTank.getId()).getLateralCog();
            },
            function () {
                return fuelTank.getLateralCogOverride();
            })
        //.overridePermission(hideForStandardVehicleUnlessLoadingAdmin)
        .overridePermission(function () {
            return !isNotStandardVehicle();
        })
        .visiblePermission(showUnlessStandardAndNotAdminAndEnergySourceNotBatteryElectric)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FUELTANKLATERALCOG)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FUELTANKCAPACITY, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.VOLUME)
        .getter(fuelTank.getCapacity)
        .setter(fuelTank.setCapacity)
        .readOnlyPermission(fuelTankAttributeReadOnlyPermissionFunc)
        .addFormattingRule(function () {
            return isFuelTypeWithExpressedASSupport() || getActiveOffer().getRig().getVehicle().getFuelType() === config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE;
        }, function (value) {
            if(getActiveOffer().getRig().getVehicle().getFuelType() === config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE) {
                // return Globalize.format(value, "n" + getMeasurementSystemToUse().volumePrecision) + ' ' + config.getTranslationText('4658');
                return getUIFormattedWattValue(value);
            }else {
                return getUIFormattedFuelExpressedAsValue(value);
            }
            
        }, config.INCREMENT_TYPE.NUMBER)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FUELTANKCAPACITY)
            .addOpBlock(function () {
                fuelTank.setVolumeIncludedInExtras(fuelTank.calculateVolumeIncludedInExtras())
            })
        ), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FUELTANKVOLUMEINCLUDEDINTARE, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.VOLUME)
        .getter(fuelTank.getVolumeIncludedInTare)
        .setter(fuelTank.setVolumeIncludedInTare)
        .readOnlyPermission(fuelTankAttributeReadOnlyPermissionFunc)
        .visiblePermission(hideForBatteryElectric)
        .addFormattingRule(function () {
            return isFuelTypeWithExpressedASSupport();
        }, function (value) {
            return getUIFormattedFuelExpressedAsValue(value);
        }, config.INCREMENT_TYPE.NUMBER)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FUELTANKVOLUMEINCLUDEDINTARE)
            .addOpBlock(function () {
                fuelTank.setVolumeIncludedInExtras(fuelTank.calculateVolumeIncludedInExtras());
            })
        ), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FUELTANKVOLUMEINCLUDEDINEXTRAS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.VOLUME)
        .getter(fuelTank.getVolumeIncludedInExtras)
        .setter(fuelTank.setVolumeIncludedInExtras)
        //.readOnlyPermission(function(){return !(fuelTank.isFactoryFitted() === true && !isNotStandardVehicle() && !globals.user.isLoadingAdministrator());})
        .readOnlyPermission(fuelTankAttributeReadOnlyPermissionFunc)
        .visiblePermission(hideForBatteryElectric)
        .addFormattingRule(function () {
            return isFuelTypeWithExpressedASSupport();
        }, function (value) {
            return getUIFormattedFuelExpressedAsValue(value);
        }, config.INCREMENT_TYPE.NUMBER)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FUELTANKVOLUMEINCLUDEDINEXTRAS)
            // .addOpBlock(function () {
            //     fuelTank.setVolumeIncludedInTare(fuelTank.calculateVolumeIncludedInTare());
            // })
        ), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FUELTANKWEIGHTINCLUDEDINFUELANDCREW, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(function () { return getActiveOffer().getRig().getVehicle().getWeightIncludedInFuelAndCrew(fuelTank.getNumber()); })
        // .setter(function (val) { return getWeightIncludedInFuelAndCrew(fuelTank.getNumber()).setWeightIncludedInFuelAndCrew(val, fuelTank.getNumber()); })
        .visiblePermission(hideForBatteryElectric)
        .readOnlyPermission(function () { 
            return true;
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FUELTANKWEIGHTINCLUDEDINFUELANDCREW, config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_AND_OPS)
        ), path);

        

        
}
function isFuelTypeWithExpressedASSupport() {
    let tempFuelType = getActiveOffer().getRig().getVehicle().getFuelType();
    if(tempFuelType === config.FUEL_TYPE_OPTIONS.HYDROGEN_FUEL_CELL.CODE || tempFuelType === config.FUEL_TYPE_OPTIONS.LNG.CODE || tempFuelType === config.FUEL_TYPE_OPTIONS.CNG.CODE) {
        return true;
    }
    return false;
}
function createCraneObjectObservables(crane, path) {

    var chassisObjectToUse = rigOps.getChassisObjectFromPath(path),
        originalChassisObjectToUse = rigOps.getOriginalChassisObjectFromPath(path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CRANEDESCRIPTION, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
        .setter(function (newValue) {
            crane.setDescription(newValue);
        })
        .getter(function () {
            return crane.getDescription();
        })
        //.visiblePermission(function () { return globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code); })
        .visiblePermission(getObjectVisibilityPermission(crane))
        .readOnlyPermission(getObjectReadOnlyPermission(crane, config.MENU_SECTION.SPECIFICATION))
        .addSubscription(function (val) {
            crane.setDescription(val);
            updateAccessoryMenuLabelCallback(val, path, crane.getAccessoryType());
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.CRANEDESCRIPTION)
            .addOpBlock(function (newValue, oldValue) {
                if (newValue === '') {
                    throw 1;
                }
            })
        ), path);

    //Dimensions
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CRANECABGAP, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return crane.getHorizontalGap();
        })
        .setter(function (newValue) {
            crane.setHorizontalGap(newValue);
        })
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                crane.setCabGapOverride(val);
                crane.setFromAxle1FrontOverride(val);
            },
            function () {
                return originalChassisObjectToUse.getAccessoryHolder().getCrane().getHorizontalGap();
            },
            function () {
                return crane.getCabGapOverride();
            },
            function () {
                crane.setFromAxle1Front(rigOps.calculateFromAxle1Front(crane));
            }, true)
        .overridePermission(function () {
            return true;
        })
        .visiblePermission(getObjectVisibilityPermission(crane, function () {
            return chassisObjectToUse.getChassisObjectName() !== config.CHASSIS_OBJECT_TYPES.VEHICLE || (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && globals.user.getSpecifyEquipmentPositionAs() === config.SPECIFY_POSITION_AS_OPTIONS.FROM_BACK_OF_CAB);
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(crane, config.MENU_SECTION.POSITION))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.CRANECABGAP)
            .addOpBlock(function () {
                //var craneCabGapAOG = getAttributeGroup(path + '.' + config.VALUE_TYPE.CRANECABGAP);
                //if (craneCabGapAOG.override() === false) {
                //    craneCabGapAOG.override(true);
                //}
                if (crane.getCabGapOverride() === false) {
                    crane.setCabGapOverride(true);
                }
                if (crane.getFromAxle1FrontOverride() === false) {
                    crane.setFromAxle1FrontOverride(true);
                }
                crane.setFromAxle1Front(rigOps.calculateFromAxle1Front(crane));
            })
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.GAPBETWEENVEHICLEANDTRAILER, rigOps.getVehicleAndDrawbarGapBetweenVehicleAndTrailer)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTLENGTH, getChassisObjectOverallLength)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.CHASSISOBJECTREAROVERHANGTOTAL)
        ), path);


    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CRANEFROMAXLE1FRONT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return crane.getFromAxle1Front();
        })
        .setter(function (newValue) {
            crane.setFromAxle1Front(newValue);
        })
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                crane.setFromAxle1FrontOverride(val);
                crane.setCabGapOverride(val);
            },
            function () {
                //return originalChassisObjectToUse.getAccessoryHolder().getCrane().getFromAxle1Front();
                //crane.setFromAxle1Front(chassisObjectToUse.getCabGapRecommendedMin() + chassisObjectToUse.getBumperToBackOfCab() - chassisObjectToUse.getFrontOverhang());
                //return chassisObjectToUse.getCabGapRecommendedMin() + chassisObjectToUse.getBumperToBackOfCab() - chassisObjectToUse.getFrontOverhang(); 
                return getDefaultFromAxle1Front(crane);
            },
            function () {
                return crane.getFromAxle1FrontOverride();
            },
            function () {
                crane.setHorizontalGap(rigOps.calculateFromBackOfCab(crane));
            }, true)
        .overridePermission(function () {
            return true;
        })
        .visiblePermission(getObjectVisibilityPermission(crane, function () {
            return globals.user.getSpecifyEquipmentPositionAs() === config.SPECIFY_POSITION_AS_OPTIONS.FROM_FRONTMOST_AXLE && chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE;
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(crane, config.MENU_SECTION.POSITION))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.CRANEFROMAXLE1FRONT)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if (crane.getCabGapOverride() === false) {
                    crane.setCabGapOverride(true);
                }
                if (crane.getFromAxle1FrontOverride() === false) {
                    crane.setFromAxle1FrontOverride(true);
                }
                crane.setHorizontalGap(rigOps.calculateFromBackOfCab(crane));
            })
        )
        .setAllowOpsWhileDragging(true), path);



    let craneFromLeftOfVehicleRearWidthAOG = new AttributeObservableGroup(config.VALUE_TYPE.CRANEFROMLEFTOFVEHICLEREARWIDTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return crane.getFromLeftOfVehicleRearWidth();
        })
        .setter(function (newValue) {
            crane.setFromLeftOfVehicleRearWidth(newValue);
        })
        .visiblePermission(hideAttribute)
        .readOnlyPermission(getObjectReadOnlyPermission(crane, config.MENU_SECTION.POSITION))
        
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, getChassisObjectOverallHeight)
        
    let craneFromLeftOfVehicleRearWidthAOGValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.CRANEFROMLEFTOFVEHICLEREARWIDTH);
    craneFromLeftOfVehicleRearWidthAOGValidation.addOpBlock(function (newValue, oldValue, deltaValue){
        crane.setFromCentreOfVehicle(rigOps.calculateFromCentreOfChassisForFromLeftOfVehicleRearWidth(crane, path));
    });
    if(!globals.user.isLoadingAdministrator()) {
        craneFromLeftOfVehicleRearWidthAOGValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.COMBINATIONOVERALLWIDTH, rigOps.getCombinationOverallWidth);
    }
    craneFromLeftOfVehicleRearWidthAOG.addValidation(craneFromLeftOfVehicleRearWidthAOGValidation);
    addAttributeGroup(craneFromLeftOfVehicleRearWidthAOG, path);


    let craneFromCentreOfVehicleAOG = new AttributeObservableGroup(config.VALUE_TYPE.CRANEFROMCENTREOFVEHICLE, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return crane.getFromCentreOfVehicle();
        })
        .setter(function (newValue) {
            crane.setFromCentreOfVehicle(newValue);
        })
        // .addOverrideMechanism(getChassisObjectToUseFunc,
        //     function (val) {
        //         crane.setFromCentreOfVehicleOverride(val);
        //     },
        //     function () {
        //         //return originalChassisObjectToUse.getAccessoryHolder().getCrane().getFromAxle1Front();
        //         //crane.setFromAxle1Front(chassisObjectToUse.getCabGapRecommendedMin() + chassisObjectToUse.getBumperToBackOfCab() - chassisObjectToUse.getFrontOverhang());
        //         //return chassisObjectToUse.getCabGapRecommendedMin() + chassisObjectToUse.getBumperToBackOfCab() - chassisObjectToUse.getFrontOverhang(); 
        //         return 0;
        //     },
        //     function () {
        //         return crane.getFromCentreOfVehicleOverride();
        //     },
        //     function () {
        //         crane.setFromLeftOfVehicleRearWidth(rigOps.calculateFromLeftOfChassisRearWidth(crane, path));
        //     }, true)
        // .overridePermission(function () {
        //     return true;
        // })
        .visiblePermission(getObjectVisibilityPermission(crane))
        .readOnlyPermission(getObjectReadOnlyPermission(crane, config.MENU_SECTION.POSITION))
        
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, getChassisObjectOverallHeight)
        
    let craneFromCentreOfVehicleAOGValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.CRANEFROMCENTREOFVEHICLE);
    craneFromCentreOfVehicleAOGValidation.addOpBlock(function (newValue, oldValue, deltaVal) {
        crane.setFromLeftOfVehicleRearWidth(rigOps.calculateFromLeftOfChassisRearWidth(crane, path));
    });
    
    if(!globals.user.isLoadingAdministrator()) {
        craneFromCentreOfVehicleAOGValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.COMBINATIONOVERALLWIDTH, rigOps.getCombinationOverallWidth);
    }
    craneFromCentreOfVehicleAOG.addValidation(craneFromCentreOfVehicleAOGValidation);
    addAttributeGroup(craneFromCentreOfVehicleAOG, path);


    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CRANESUBFRAMEHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return crane.getSubframeHeight();
        })
        .setter(function (newValue) {
            crane.setSubframeHeight(newValue);
        })
        //.visiblePermission(isTemplateOrCustomCraneWithoutGraphic)
        .visiblePermission(getObjectVisibilityPermission(crane))
        .readOnlyPermission(getObjectReadOnlyPermission(crane, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.CRANESUBFRAMEHEIGHT)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, getChassisObjectOverallHeight)
        ), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CRANELENGTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return crane.getLength();
        })
        .setter(function (newValue) {
            crane.setLength(newValue);
        })
        //.visiblePermission(isTemplateOrCustomCraneWithoutGraphic)
        .visiblePermission(getObjectVisibilityPermission(crane))
        .readOnlyPermission(getObjectReadOnlyPermission(crane, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.CRANELENGTH)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.GAPBETWEENVEHICLEANDTRAILER, rigOps.getVehicleAndDrawbarGapBetweenVehicleAndTrailer)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTLENGTH, getChassisObjectOverallLength)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.CHASSISOBJECTREAROVERHANGTOTAL)
        ), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CRANEABOVEBELOWCHASSIS, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return crane.getAboveBelowChassis();
        })
        .setter(function (newValue) {
            crane.setAboveBelowChassis(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(crane))
        .readOnlyPermission(getObjectReadOnlyPermission(crane, config.MENU_SECTION.POSITION))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.CRANEABOVEBELOWCHASSIS)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CRANEHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return crane.getHeight();
        })
        .setter(function (newValue) {
            crane.setHeight(newValue);
        })
        //.visiblePermission(isTemplateOrCustomCraneWithoutGraphic)
        .visiblePermission(getObjectVisibilityPermission(crane))
        .readOnlyPermission(getObjectReadOnlyPermission(crane, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.CRANEHEIGHT)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, getChassisObjectOverallHeight)
        ), path);


    let craneWidthAOG = new AttributeObservableGroup(config.VALUE_TYPE.CRANEWIDTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return crane.getWidth();
        })
        .setter(function (newValue) {
            crane.setWidth(newValue);
        })
        //.visiblePermission(isTemplateOrCustomCraneWithoutGraphic)
        .visiblePermission(getObjectVisibilityPermission(crane))
        .readOnlyPermission(getObjectReadOnlyPermission(crane, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        

    let craneWidthAOGValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.CRANEWIDTH);
    craneWidthAOGValidation.addOpBlock(function (newValue, oldValue, deltaVal) {
        crane.setFromLeftOfVehicleRearWidth(rigOps.calculateFromLeftOfChassisRearWidth(crane, path));
    });
    if(!globals.user.isLoadingAdministrator()) {
        craneWidthAOGValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.COMBINATIONOVERALLWIDTH, rigOps.getCombinationOverallWidth);
    }
    craneWidthAOG.addValidation(craneWidthAOGValidation);
    addAttributeGroup(craneWidthAOG, path);

    //Weights
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CRANEMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(function () {
            return crane.getMass();
        })
        .setter(function (newValue) {
            crane.setMass(newValue);
        })
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                crane.setMassOverride(val);
            },
            function () {
                return originalChassisObjectToUse.getAccessoryHolder().getCrane().getMass();
            },
            function () {
                return crane.getMassOverride();
            })
        .overridePermission(showOverrideForStandardCrane)
        //.visiblePermission(showUnlessStandardCraneAndNotAdmin)
        .visiblePermission(getObjectVisibilityPermission(crane))
        .readOnlyPermission(getObjectReadOnlyPermission(crane, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(function () {
        //    return true;
        //})
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.CRANEMASS)), path);


    function getCraneHorizontalCOGTypeOption() {
        var craneHorizontalCOGTypeOption = getCOGTypeOptions()
            .filter(function (option) {
                if (option.id === crane.getCOGType()) {
                    return option;
                } else if (option.id === config.COG_TYPES.LENGTH && (crane.getCOGType() === '' || crane.getCOGType() === undefined || crane.getCOGType() === null)) {
                    return option;
                }
            })[0];
        return craneHorizontalCOGTypeOption;
    }


    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CRANEHORIZONTALCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getCraneHorizontalCOGTypeOption())
        .optionGetter(getCraneHorizontalCOGTypeOption)
        //.visiblePermission(showUnlessStandardCraneAndNotAdmin)
        .visiblePermission(getObjectVisibilityPermission(crane))
        .readOnlyPermission(getObjectReadOnlyPermission(crane, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .addSubscription(function (val) {
            if (val !== crane.getCOGType()) {
                crane.setCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    crane.setHorizontalCOG(crane.getLength() * crane.getHorizontalCOG() / 100);
                } else {
                    crane.setHorizontalCOG(utils.returnValueOrZeroIfNanOrInfinity((crane.getHorizontalCOG() / crane.getLength()) * 100));
                }
            }
        }), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CRANEHORIZONTALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return crane.getHorizontalCOG();
        })
        .setter(function (newValue) {
            crane.setHorizontalCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                crane.setHorizontalCOGOverride(val);
            },
            function () {
                //return originalChassisObjectToUse.getAccessoryHolder().getCrane().getHorizontalCOG();
                var originalCrane = originalChassisObjectToUse.getAccessoryHolder().getCrane();
                if (crane.getCOGType() === config.COG_TYPES.LENGTH && originalCrane.getCOGType() === config.COG_TYPES.LENGTH || crane.getCOGType() === config.COG_TYPES.PERCENTAGE && originalCrane.getCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalCrane.getHorizontalCOG();
                } else if (crane.getCOGType() === config.COG_TYPES.LENGTH && originalCrane.getCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalCrane.getHorizontalCOG() * crane.getLength() / 100;
                } else if (crane.getCOGType() === config.COG_TYPES.PERCENTAGE && originalCrane.getCOGType() === config.COG_TYPES.LENGTH) {
                    return originalCrane.getHorizontalCOG() / crane.getLength() * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return crane.getHorizontalCOGOverride();
            })
        .overridePermission(showOverrideForStandardCrane)
        //.visiblePermission(showUnlessStandardCraneAndNotAdmin)
        .visiblePermission(getObjectVisibilityPermission(crane))
        .readOnlyPermission(getObjectReadOnlyPermission(crane, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(function () {
        //    return true;
        //})
        // .alwaysNotifyValueSubscribers()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.CRANEHORIZONTALCOG))
        .addFormattingRule(function () {
            return crane.getCOGType() === config.COG_TYPES.PERCENTAGE;
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);


    function getCraneVerticalCOGTypeOption() {
        var craneVerticalCOGTypeOption = getCOGTypeOptions()
            .filter(function (option) {
                if (option.id === crane.getVerticalCOGType()) {
                    return option;
                } else if (option.id === config.COG_TYPES.LENGTH && (crane.getVerticalCOGType() === '' || crane.getVerticalCOGType() === undefined || crane.getVerticalCOGType() === null)) {
                    return option;
                }
            })[0];
        return craneVerticalCOGTypeOption;
    }


    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CRANEVERTICALCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getCraneVerticalCOGTypeOption())
        .optionGetter(getCraneVerticalCOGTypeOption)
        //.visiblePermission(showUnlessStandardCraneAndNotAdmin)
        .visiblePermission(getObjectVisibilityPermission(crane))
        .readOnlyPermission(getObjectReadOnlyPermission(crane, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .addSubscription(function (val) {
            if (val !== crane.getVerticalCOGType()) {
                crane.setVerticalCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    crane.setVerticalCOG((crane.getHeight() + crane.getSubframeHeight()) * crane.getVerticalCOG() / 100);
                } else {
                    crane.setVerticalCOG(utils.returnValueOrZeroIfNanOrInfinity((crane.getVerticalCOG() / (crane.getHeight() + crane.getSubframeHeight())) * 100));
                }
            }
        }), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CRANEVERTICALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return crane.getVerticalCOG();
        })
        .setter(function (newValue) {
            crane.setVerticalCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                crane.setVerticalCOGOverride(val);
            },
            function () {
                //return originalChassisObjectToUse.getAccessoryHolder().getCrane().getVerticalCOG();
                var originalCrane = originalChassisObjectToUse.getAccessoryHolder().getCrane();
                if (crane.getVerticalCOGType() === config.COG_TYPES.LENGTH && originalCrane.getVerticalCOGType() === config.COG_TYPES.LENGTH || crane.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE && originalCrane.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalCrane.getVerticalCOG();
                } else if (crane.getVerticalCOGType() === config.COG_TYPES.LENGTH && originalCrane.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalCrane.getVerticalCOG() * (crane.getHeight() + crane.getSubframeHeight()) / 100;
                } else if (crane.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE && originalCrane.getVerticalCOGType() === config.COG_TYPES.LENGTH) {
                    return originalCrane.getVerticalCOG() / (crane.getHeight() + crane.getSubframeHeight()) * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return crane.getVerticalCOGOverride();
            })
        .overridePermission(showOverrideForStandardCrane)
        //.visiblePermission(showUnlessStandardCraneAndNotAdmin)
        .visiblePermission(getObjectVisibilityPermission(crane))
        .readOnlyPermission(getObjectReadOnlyPermission(crane, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(function () {
        //    return true;
        //})
        // .alwaysNotifyValueSubscribers()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.CRANEVERTICALCOG))
        .addFormattingRule(function () {
            return crane.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE;
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);


    function getCraneLateralCOGTypeOption() {
        var craneLateralCOGTypeOption = getCOGTypeOptions()
            .filter(function (option) {
                if (option.id === crane.getLateralCOGType()) {
                    return option;
                } else if (option.id === config.COG_TYPES.LENGTH && (crane.getLateralCOGType() === '' || crane.getLateralCOGType() === undefined || crane.getLateralCOGType() === null)) {
                    return option;
                }
            })[0];

        return craneLateralCOGTypeOption;
    }


    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CRANELATERALCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getCraneLateralCOGTypeOption())
        .optionGetter(getCraneLateralCOGTypeOption)
        //.visiblePermission(showUnlessStandardCraneAndNotAdmin)
        .visiblePermission(getObjectVisibilityPermission(crane))
        .readOnlyPermission(getObjectReadOnlyPermission(crane, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .addSubscription(function (val) {
            if (val !== crane.getLateralCOGType()) {
                crane.setLateralCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    crane.setLateralCOG(crane.getWidth() * crane.getLateralCOG() / 100);
                } else {
                    crane.setLateralCOG(utils.returnValueOrZeroIfNanOrInfinity((crane.getLateralCOG() / crane.getWidth()) * 100));
                }
            }
        }), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CRANELATERALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return crane.getLateralCOG();
        })
        .setter(function (newValue) {
            crane.setLateralCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                crane.setLateralCOGOverride(val);
            },
            function () {
                //return originalChassisObjectToUse.getAccessoryHolder().getCrane().getLateralCOG();
                var originalCrane = originalChassisObjectToUse.getAccessoryHolder().getCrane();
                if (crane.getLateralCOGType() === config.COG_TYPES.LENGTH && originalCrane.getLateralCOGType() === config.COG_TYPES.LENGTH || crane.getLateralCOGType() === config.COG_TYPES.PERCENTAGE && originalCrane.getLateralCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalCrane.getLateralCOG();
                } else if (crane.getLateralCOGType() === config.COG_TYPES.LENGTH && originalCrane.getLateralCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalCrane.getLateralCOG() * crane.getWidth() / 100;
                } else if (crane.getLateralCOGType() === config.COG_TYPES.PERCENTAGE && originalCrane.getLateralCOGType() === config.COG_TYPES.LENGTH) {
                    return originalCrane.getLateralCOG() / crane.getWidth() * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return crane.getLateralCOGOverride();
            })
        .overridePermission(showOverrideForStandardCrane)
        //.visiblePermission(showUnlessStandardCraneAndNotAdmin)
        .visiblePermission(getObjectVisibilityPermission(crane))
        .readOnlyPermission(getObjectReadOnlyPermission(crane, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        // .alwaysNotifyValueSubscribers()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.CRANELATERALCOG))
        .addFormattingRule(function () {
            return crane.getLateralCOGType() === config.COG_TYPES.PERCENTAGE;
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);

    function getChassisObjectToUseFunc() {
        return chassisObjectToUse;
    }

    function getChassisObjectOverallLength() {
        return rigOps.getOverallChassisObjectLengthLegal(path);
    }

    function getChassisObjectOverallHeight() {
        return rigOps.getChassisObjectOverallHeight(path);
    }
}

function createItemObjectObservablesForItemConfigWizard(item, path) {

    var chassisObjectToUse = rigOps.getChassisObjectFromPath(path),
        originalChassisObjectToUse = rigOps.getOriginalChassisObjectFromPath(path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMDESCRIPTION, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
        .setter(function (newValue) {
            item.setDescription(newValue)
        })
        .getter(function () {
            return item.getDescription();
        })
        .addSubscription(function (val) {
            item.setDescription(val);
        }), path);



    ////Dimensions
    if (item.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {

        if (item.getType() === config.BODY_TYPES.HOOKLIFT && item.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
            addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMSUBFRAMELENGTH, config.INPUT_TYPE.UPDOWNBOX)
                .getter(function () {
                    return item.getSubframeLength();
                })
                .setter(function (newValue) {
                    item.setSubframeLength(newValue);
                })
                .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMSUBFRAMELENGTH)), path);
        }

        var itemLengthTypeOption = getBodyLengthTypeOptions()
            .filter(function (option) {
                if (option.id === item.getLengthType()) {
                    return option;
                } else if (option.id === config.BODY_MEASUREMENT_TYPES.EXTERNAL && (item.getLengthType() === '' || item.getLengthType() === undefined || item.getLengthType() === null)) {
                    return option;
                }
            })[0];

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMLENGTHTYPE, config.INPUT_TYPE.SELECT)
            .setVal(itemLengthTypeOption)
            .visiblePermission(function () {
                return item.getType() !== config.BODY_TYPES.SERVICE && item.getType() !== config.BODY_TYPES.HOOKLIFT
            })
            .addSubscription(function (val) {
                if (val !== item.getLengthType()) {
                    item.setLengthType(val);
                    if (val === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                        item.setLength(item.getLength() + item.getHeadboardThickness() + item.getTailboardThickness());
                        //activeConfigurationWizardItemSize.setLength(activeConfigurationWizardItemSize.getLength() + item.getHeadboardThickness() + item.getTailboardThickness());
                    } else {
                        item.setLength(item.getLength() - item.getHeadboardThickness() - item.getTailboardThickness());
                        //activeConfigurationWizardItemSize.setLength(activeConfigurationWizardItemSize.getLength() - item.getHeadboardThickness() - item.getTailboardThickness());
                    }
                }

            }), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMLENGTH, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getLength();
            })
            .setter(function (newValue) {
                //activeConfigurationWizardItemSize.setLength(activeConfigurationWizardItemSize.getLength() + (newValue - item.getLength()));
                item.setLength(newValue);

            })
            .addOverrideMechanism(dummyFunc,
                function (val) {
                    activeConfigurationWizardItemSize.setLengthOverride(val);
                    checkForDoubleOverrideAndPromptUserIfNecessary(path, config.VALUE_TYPE.ITEMLENGTH, item);
                },
                function () {
                    if (item.getLengthType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                        return originalConfigurationWizardItemSize.getLength() - item.getRampLength() - item.getRampPlatesProtrusion();
                    } else {
                        return originalConfigurationWizardItemSize.getLength() - item.getRampLength() - item.getRampPlatesProtrusion() - item.getHeadboardThickness() - item.getTailboardThickness();
                    }
                },
                function () {
                    return activeConfigurationWizardItemSize.getLengthOverride();
                },
                function () {
                    activeConfigurationWizardItemSize.setLength(originalConfigurationWizardItemSize.getLength());
                    var itemHeightAOG = offerManager.getAttributeGroup(path + '.' + config.VALUE_TYPE.ITEMHEIGHT);
                    if (!itemHeightAOG.override.value) {
                        if (activeConfigurationWizardItem.getHeightType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                            //itemHeightAOG.formatted(originalConfigurationWizardItemSize.getHeight());
                            activeConfigurationWizardItem.setHeight(originalConfigurationWizardItemSize.getHeight());
                        } else {
                            //itemHeightAOG.formatted(originalConfigurationWizardItemSize.getHeight() - activeConfigurationWizardItem);
                            activeConfigurationWizardItem.setHeight(originalConfigurationWizardItemSize.getHeight() - activeConfigurationWizardItem.getSubstructureHeight() - activeConfigurationWizardItem.getRoofThickness());
                        }

                        activeConfigurationWizardItemSize.setHeight(originalConfigurationWizardItemSize.getHeight());
                    }
                })
            .overridePermission(function () {
                return true;
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMLENGTH)
                .addOpBlock(function (newValue, oldValue, deltaVal) {
                    if (activeConfigurationWizardItemSize.getLengthOverride()) {
                        activeConfigurationWizardItemSize.setLength(activeConfigurationWizardItemSize.getLength() + deltaVal);
                    }
                    
                })
            ), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMHEADBOARDTHICKNESS, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getHeadboardThickness();
            })
            .setter(function (newValue) {
                item.setHeadboardThickness(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMHEADBOARDTHICKNESS)
                .addOpBlock(function (newValue, oldValue, deltaVal) {

                    //if (item.getLengthType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                    //    item.setLength(item.getLength() - deltaVal);
                    //    var itemLengthAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMLENGTH);
                    //    itemLengthAOG.value(itemLengthAOG.value() - deltaVal);
                    //    originalConfigurationWizardItem.setLength(originalConfigurationWizardItem.getLength() - deltaVal);
                    //} else if (item.getLengthType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
                    //    var itemDrawingLengthAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGLENGTH);
                    //    itemDrawingLengthAOG.formatted(itemDrawingLengthAOG.value() + deltaVal);
                    //}
                    var itemLengthAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMLENGTH);
                    if (itemLengthAOG.override.value && item.getLengthType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
                        var itemDrawingLengthAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGLENGTH);
                        itemDrawingLengthAOG.value.value = itemDrawingLengthAOG.value.value + deltaVal;
                        activeConfigurationWizardItemSize.setLength(itemDrawingLengthAOG.value.value);
                        changeListenerCallback('Scaling_Dimension_Changed', config.SCALING_DIMENSION_TYPES.LENGTH);
                    } else if (item.getLengthType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
                        item.setLength(item.getLength() - deltaVal);
                        itemLengthAOG.value.value = itemLengthAOG.value.value - deltaVal;

                    }
                })
            ), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMTAILBOARDTHICKNESS, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getTailboardThickness();
            })
            .setter(function (newValue) {
                item.setTailboardThickness(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMTAILBOARDTHICKNESS)
                .addOpBlock(function (newValue, oldValue, deltaVal) {
                    //if (item.getLengthType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                    //    item.setLength(item.getLength() - deltaVal);
                    //    var itemLengthAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMLENGTH);
                    //    itemLengthAOG.value(itemLengthAOG.value() - deltaVal);
                    //    originalConfigurationWizardItem.setLength(originalConfigurationWizardItem.getLength() - deltaVal);
                    //} else if (item.getLengthType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
                    //    var itemDrawingLengthAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGLENGTH);
                    //    itemDrawingLengthAOG.formatted(itemDrawingLengthAOG.value() + deltaVal);
                    //}
                    var itemLengthAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMLENGTH);
                    if (itemLengthAOG.override.value && item.getLengthType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
                        var itemDrawingLengthAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGLENGTH);
                        itemDrawingLengthAOG.value.value = itemDrawingLengthAOG.value.value + deltaVal;
                        activeConfigurationWizardItemSize.setLength(itemDrawingLengthAOG.value.value);
                        changeListenerCallback('Scaling_Dimension_Changed', config.SCALING_DIMENSION_TYPES.LENGTH);
                    } else if (item.getLengthType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
                        item.setLength(item.getLength() - deltaVal);
                        itemLengthAOG.value.value = itemLengthAOG.value.value - deltaVal;

                    }
                })
            )
            .visiblePermission(function () {
                return (item.getType() !== config.BODY_TYPES.HOOKLIFT || (item.getType() === config.BODY_TYPES.HOOKLIFT && item.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY)) && item.getType() !== config.BODY_TYPES.ROLLBACK;
            }), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMRAMPLENGTH, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getRampLength();
            })
            .setter(function (newValue) {
                item.setRampLength(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMRAMPLENGTH)
                .addOpBlock(function (newValue, oldValue, deltaVal) {
                    var itemLengthAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMLENGTH);
                    var itemDrawingLengthAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGLENGTH);
                    if (itemLengthAOG.override.value) {
                        //itemDrawingLengthAOG.formatted(itemDrawingLengthAOG.value() + deltaVal);
                        itemDrawingLengthAOG.value.value = itemDrawingLengthAOG.value.value + deltaVal;
                        activeConfigurationWizardItemSize.setLength(itemDrawingLengthAOG.value.value);
                    } else {
                        item.setLength(item.getLength() - deltaVal);
                        itemLengthAOG.value.value = itemLengthAOG.value.value - deltaVal;
                    }
                    changeListenerCallback('Scaling_Dimension_Changed', config.SCALING_DIMENSION_TYPES.LENGTH);
                })
            ), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMRAMPPLATESPROTRUSION, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getRampPlatesProtrusion();
            })
            .setter(function (newValue) {
                item.setRampPlatesProtrusion(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMRAMPPLATESPROTRUSION)
                .addOpBlock(function (newValue, oldValue, deltaVal) {
                    var itemLengthAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMLENGTH);
                    var itemDrawingLengthAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGLENGTH);
                    if (itemLengthAOG.override.value) {
                        //itemDrawingLengthAOG.formatted(itemDrawingLengthAOG.value() + deltaVal);
                        itemDrawingLengthAOG.value.value = itemDrawingLengthAOG.value.value + deltaVal;
                        activeConfigurationWizardItemSize.setLength(itemDrawingLengthAOG.value.value);
                    } else {
                        item.setLength(item.getLength() - deltaVal);
                        itemLengthAOG.value.value = itemLengthAOG.value.value - deltaVal;
                    }
                    changeListenerCallback('Scaling_Dimension_Changed', config.SCALING_DIMENSION_TYPES.LENGTH);
                })
            ), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMBOTTOMOFBODYTOTOPOFCHASSIS, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getBottomOfBodyToTopOfChassis();
            })
            .setter(function (newValue) {
                item.setBottomOfBodyToTopOfChassis(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMBOTTOMOFBODYTOTOPOFCHASSIS)), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMSUBFRAMEHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getSubframeHeight();
            })
            .setter(function (newValue) {
                item.setSubframeHeight(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMSUBFRAMEHEIGHT)
                .addOpBlock(function (newValue, oldValue, deltaVal) {
                    //if (item.getHeightType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                    //    //var itemDrawingHeightAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGHEIGHT);
                    //    //itemDrawingHeightAOG.formatted(itemDrawingHeightAOG.value() + deltaVal);
                    //    item.setHeight(item.getHeight() - deltaVal);
                    //    var itemHeightAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMHEIGHT);
                    //    itemHeightAOG.value(itemHeightAOG.value() - deltaVal);
                    //    originalConfigurationWizardItem.setHeight(originalConfigurationWizardItem.getHeight() - deltaVal);
                    //    //originalConfigurationWizardItem.setSubframeHeight(newValue);
                    //}else if (item.getHeightType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
                    if (item.getType() !== config.BODY_TYPES.SERVICE) {
                        var itemHeightAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMHEIGHT);
                        if (itemHeightAOG.override.value) {
                            var itemDrawingHeightAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGHEIGHT);
                            //itemDrawingHeightAOG.formatted(itemDrawingHeightAOG.value() + deltaVal);
                            itemDrawingHeightAOG.value.value = itemDrawingHeightAOG.value.value + deltaVal;
                            activeConfigurationWizardItemSize.setHeight(itemDrawingHeightAOG.value.value);
                        } else {
                            item.setHeight(item.getHeight() - deltaVal);
                            itemHeightAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMHEIGHT);
                            itemHeightAOG.value.value = itemHeightAOG.value.value - deltaVal;

                            //originalConfigurationWizardItem.setHeight(originalConfigurationWizardItem.getHeight() - deltaVal);
                        }
                        changeListenerCallback('Scaling_Dimension_Changed', config.SCALING_DIMENSION_TYPES.HEIGHT);
                    }
                    //}
                })
            ), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMSUBSTRUCTUREHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getSubstructureHeight();
            })
            .setter(function (newValue) {
                item.setSubstructureHeight(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMSUBSTRUCTUREHEIGHT)
                .addOpBlock(function (newValue, oldValue, deltaVal) {
                    //if (item.getHeightType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                    //    item.setHeight(item.getHeight() - deltaVal);
                    //    var itemHeightAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMHEIGHT);
                    //    itemHeightAOG.value(itemHeightAOG.value() - deltaVal);
                    //    originalConfigurationWizardItem.setHeight(originalConfigurationWizardItem.getHeight() - deltaVal);
                    //} else if (item.getHeightType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
                    //    var itemDrawingHeightAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGHEIGHT);
                    //    itemDrawingHeightAOG.formatted(itemDrawingHeightAOG.value() + deltaVal);
                    //}
                    if (item.getType() !== config.BODY_TYPES.SERVICE) {
                        var itemHeightAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMHEIGHT);

                        if (itemHeightAOG.override.value && item.getHeightType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
                            var itemDrawingHeightAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGHEIGHT);
                            //itemDrawingHeightAOG.formatted(itemDrawingHeightAOG.value() + deltaVal);
                            itemDrawingHeightAOG.value.value = itemDrawingHeightAOG.value.value + deltaVal;
                            activeConfigurationWizardItemSize.setHeight(itemDrawingHeightAOG.value.value);
                            changeListenerCallback('Scaling_Dimension_Changed', config.SCALING_DIMENSION_TYPES.HEIGHT);
                        } else if (item.getHeightType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
                            item.setHeight(item.getHeight() - deltaVal);
                            //var itemHeightAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMHEIGHT);
                            itemHeightAOG.value.value = itemHeightAOG.value.value - deltaVal;
                            //originalConfigurationWizardItem.setHeight(originalConfigurationWizardItem.getHeight() - deltaVal);
                        }
                    }
                })
            ), path);


        var itemHeightTypeOption = getBodyLengthTypeOptions()
            .filter(function (option) {
                if (option.id === item.getHeightType()) {
                    return option;
                } else if (option.id === config.BODY_MEASUREMENT_TYPES.EXTERNAL && (item.getHeightType() === '' || item.getHeightType() === undefined || item.getHeightType() === null)) {
                    return option;
                }
            })[0];

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMHEIGHTTYPE, config.INPUT_TYPE.SELECT)
            .setVal(itemHeightTypeOption)
            .addSubscription(function (val) {
                if (val !== item.getHeightType()) {
                    item.setHeightType(val);
                    //if (val === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                    //    item.setLength(item.getLength() + item.getHeadboardThickness() + item.getTailboardThickness());
                    //} else {
                    //    body.setLength(item.getLength() - item.getHeadboardThickness() - item.getTailboardThickness());
                    //}
                    var highestPoint;
                    if (item.getHeightType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                        if (item.getType() === config.BODY_TYPES.TIPPER || item.getType() === config.BODY_TYPES.DROPSIDE) {
                            item.setHeight(item.getSubstructureHeight() + Math.max(item.getHeadboardHeight(), item.getHeight() - item.getSubstructureHeight()));
                        } else if (item.getType() === config.BODY_TYPES.FLATDECK) {
                            item.setHeight(item.getSubstructureHeight() + Math.max(item.getHeadboardHeight(), item.getTailboardHeight()));
                        } else if (item.getType() === config.BODY_TYPES.ROLLBACK) {
                            item.setHeight(item.getSubstructureHeight() + item.getHeadboardHeight());
                        } else if (item.getType() === config.BODY_TYPES.TIMBER) {
                            item.setHeight(item.getActualExternalHeightForTimber());
                        } else if (item.getType() === config.BODY_TYPES.TANKER) {
                            item.setHeight(item.getHeight());
                        } else if (item.getType() === config.BODY_TYPES.BEAVERTAIL) {
                            highestPoint = Math.max(item.getHeadboardHeight(), item.getHeight() - item.getSubstructureHeight());
                            //var highestPoint = Math.max(this._headboardHeight, this._height);
                            highestPoint = Math.max(highestPoint, item.getHeightOfRampPlatesProtrudingAboveDeck(item.getRampAngle(), item.getRampLength(), item.getRampPlatesHeight()));

                            item.setHeight(item.getSubstructureHeight() + highestPoint);
                            //return highestPoint;
                        } else if (item.getType() === config.BODY_TYPES.HOOKLIFT && item.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
                            highestPoint = Math.max(item.getHeadboardHeight(), item.getHeight() - item.getSubstructureHeight());

                            item.setHeight(item.getSubstructureHeight() + highestPoint);
                        } else if (item.getType() === config.BODY_TYPES.SERVICE /*|| this._type === config.BODY_TYPES.COMPACTOR || this._type === config.BODY_TYPES.OTHER*/) {
                            item.setHeight(item.getHeight());
                        } else {
                            //for van, cattle carrier, compactor, tautliner, pig carrier, sheep carrier
                            item.setHeight(item.getHeight() + item.getSubstructureHeight() + item.getRoofThickness());
                        }

                    } else {
                        //if (item.getType() === config.BODY_TYPES.TIPPER || item.getType() === config.BODY_TYPES.DROPSIDE) {
                        //    item.setHeight(item.getSubframeHeight() + item.getSubstructureHeight() + Math.max(item.getHeight(), item.getHeadboardHeight()));
                        //} else if (item.getType() === config.BODY_TYPES.FLATDECK) {
                        //    item.setHeight(item.getSubframeHeight() + item.getSubstructureHeight() + Math.max(item.getHeadboardHeight(), item.getTailboardHeight()));
                        //} else if (item.getType() === config.BODY_TYPES.ROLLBACK) {
                        //    item.setHeight(item.getSubframeHeight() + item.getSubstructureHeight + item.getHeadboardHeight());
                        //} else if (item.getType() === config.BODY_TYPES.TIMBER) {

                        //    item.setHeight(item.getActualExternalHeightForTimber());

                        //} else if (item.getType() === config.BODY_TYPES.TANKER) {
                        //    item.setHeight(item.getSubframeHeight() + item.getSubstructureHeight() + item.getHeight());
                        //} else if (item.getType() === config.BODY_TYPES.BEAVERTAIL) {
                        //    var highestPoint = Math.max(item.getHeight(), item.getHeadboardHeight());
                        //    highestPoint = Math.max(highestPoint, getHeightOfRampPlatesProtrudingAboveDeck(item.getRampAngle(), item.getRampLength(), item.getRampPlatesHeight()));

                        //    item.setHeight(item.getSubframeHeight() + item.getSubstructureHeight() + highestPoint);
                        //    //return highestPoint + this._subframeHeight + this._substructureHeight + this._bottomOfBodyToTopOfChassis;
                        //} else if (item.getType() === config.BODY_TYPES.HOOKLIFT) {
                        //    var highestPoint = Math.max(item.getHeadboardHeight(), item.getHeight());

                        //    item.setHeight(item.getSubframeHeight() + item.getSubstructureHeight() + highestPoint);
                        //} else if (this._type === config.BODY_TYPES.SERVICE /*|| this._type === config.BODY_TYPES.COMPACTOR || this._type === config.BODY_TYPES.OTHER*/) {
                        //    item.setHeight(this.getHeight() + this.getSubframeHeight() + item.getSubstructureHeight() + item.getBottomOfBodyToTopOfChassis());
                        //} else {
                        //    //for van, cattle carrier, compactor, tautliner, pig carrier, sheep carrier
                        //    item.setHeight(item.getSubframeHeight() + item.getSubstructureHeight() + item.getHeight() + item.getRoofThickness());
                        //}

                        if (item.getType() === config.BODY_TYPES.TIPPER || item.getType() === config.BODY_TYPES.DROPSIDE) {
                            item.setHeight(item.getHeight() - item.getSubstructureHeight());
                        } else if (item.getType() === config.BODY_TYPES.FLATDECK) {
                            item.setHeight(Math.max(item.getHeadboardHeight(), item.getTailboardHeight()));
                        } else if (item.getType() === config.BODY_TYPES.ROLLBACK) {
                            item.setHeight(item.getHeadboardHeight());
                        } else if (item.getType() === config.BODY_TYPES.TIMBER) {
                            item.setHeight(Math.max(item.getHeadboardHeight(), item.getTailboardHeight()));
                        } else if (item.getType() === config.BODY_TYPES.TANKER) {
                            item.setHeight(item.getHeight() - item.getSubstructureHeight());
                        } else if (item.getType() === config.BODY_TYPES.HOOKLIFT && item.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
                            item.setHeight(Math.max(item.getHeadboardHeight(), item.getHeight() - item.getSubstructureHeight()));
                        } else if (item.getType() === config.BODY_TYPES.SERVICE /*|| this._type === config.BODY_TYPES.BEAVERTAIL || this._type === config.BODY_TYPES.COMPACTOR || this._type === config.BODY_TYPES.OTHER*/) {
                            item.setHeight(item.getHeight() - item.getSubframeHeight() - item.getSubstructureHeight() - item.getBottomOfBodyToTopOfChassis());
                        } else {
                            //for van, cattle carrier, compactor, tautliner, pig carrier, sheep carrier
                            item.setHeight(item.getHeight() - item.getSubstructureHeight() - item.getRoofThickness());
                        }

                    }
                }

            })
            .visiblePermission(showItemBodyHeightTypeAttribute), path);


        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getHeight();
            })
            .setter(function (newValue) {
                item.setHeight(newValue);
            })
            .addOverrideMechanism(dummyFunc,
                function (val) {
                    activeConfigurationWizardItemSize.setHeightOverride(val);
                    checkForDoubleOverrideAndPromptUserIfNecessary(path, config.VALUE_TYPE.ITEMHEIGHT, item);
                },
                function () {

                    if (item.getHeightType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                        return originalConfigurationWizardItemSize.getHeight() - item.getSubframeHeight();
                    } else {
                        return originalConfigurationWizardItemSize.getHeight() - item.getRoofThickness() - item.getSubstructureHeight() - item.getSubframeHeight();
                    }
                },
                function () {
                    return activeConfigurationWizardItemSize.getHeightOverride();
                },
                function () {
                    activeConfigurationWizardItemSize.setHeight(originalConfigurationWizardItemSize.getHeight());
                    var itemLengthAOG = offerManager.getAttributeGroup(path + '.' + config.VALUE_TYPE.ITEMLENGTH);
                    if (!itemLengthAOG.override.value) {

                        if (activeConfigurationWizardItem.getLengthType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                            activeConfigurationWizardItem.setLength(originalConfigurationWizardItemSize.getLength());
                        } else {
                            activeConfigurationWizardItem.setLength(originalConfigurationWizardItemSize.getLength() - activeConfigurationWizardItem.getHeadboardThickness() - activeConfigurationWizardItem.getTailboardThickness());
                        }

                        activeConfigurationWizardItemSize.setLength(originalConfigurationWizardItemSize.getLength());
                    }
                }
            )
            .overridePermission(function () {
                return true;
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMHEIGHT)
                .addOpBlock(function (newValue, oldValue, deltaVal) {
                    activeConfigurationWizardItemSize.setHeight(activeConfigurationWizardItemSize.getHeight() + deltaVal);
                    if (item.getType() === config.BODY_TYPES.TIMBER) {
                        if (item.getHeightType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                            item.setHeadboardHeight(newValue - item.getSubstructureHeight());
                        } else {
                            item.setHeadboardHeight(newValue);
                        }
                    }
                    
                })
            ), path);




        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMROOFTHICKNESS, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getRoofThickness();
            })
            .setter(function (newValue) {
                item.setRoofThickness(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMROOFTHICKNESS)
                .addOpBlock(function (newValue, oldValue, deltaVal) {
                    var itemHeightAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMHEIGHT);

                    if (itemHeightAOG.override.value && item.getHeightType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
                        var itemDrawingHeightAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGHEIGHT);
                        //itemDrawingHeightAOG.formatted(itemDrawingHeightAOG.value() + deltaVal);
                        itemDrawingHeightAOG.value.value = itemDrawingHeightAOG.value.value + deltaVal;
                        activeConfigurationWizardItemSize.setHeight(itemDrawingHeightAOG.value.value);
                        changeListenerCallback('Scaling_Dimension_Changed', config.SCALING_DIMENSION_TYPES.HEIGHT);
                    } else if (item.getHeightType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
                        item.setHeight(item.getHeight() - deltaVal);
                        //var itemHeightAOG = getAttributeGroup('configurationModal' + '.' + activeConfigurationWizardItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMHEIGHT);
                        itemHeightAOG.value.value = itemHeightAOG.value.value - deltaVal;
                        //originalConfigurationWizardItem.setHeight(originalConfigurationWizardItem.getHeight() - deltaVal);
                    }
                })
            )
            .visiblePermission(showBodyItemRoofThicknessAttribute), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMBODYDROPSIDEHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getDropsideHeight();
            })
            .setter(function (newValue) {
                item.setDropsideHeight(newValue);
            })
            .visiblePermission(function () {
                return item.getType() === config.BODY_TYPES.BEAVERTAIL || item.getType() === config.BODY_TYPES.DROPSIDE;
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMBODYDROPSIDEHEIGHT)), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMHEADBOARDHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getHeadboardHeight();
            })
            .setter(function (newValue) {
                item.setHeadboardHeight(newValue);
            })
            .visiblePermission(showItemHeadboardHeight)
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMHEADBOARDHEIGHT)), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMTAILBOARDHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getTailboardHeight();
            })
            .setter(function (newValue) {
                item.setTailboardHeight(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMTAILBOARDHEIGHT))
            .visiblePermission(function () {
                return item.getType() !== config.BODY_TYPES.FLATDECK;
            }), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMRAMPPLATESHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getRampPlatesHeight();
            })
            .setter(function (newValue) {
                item.setRampPlatesHeight(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMRAMPPLATESHEIGHT)), path);


        var itemWidthTypeOption = getBodyLengthTypeOptions()
            .filter(function (option) {
                if (option.id === item.getWidthType()) {
                    return option;
                } else if (option.id === config.BODY_MEASUREMENT_TYPES.EXTERNAL && (item.getWidthType() === '' || item.getWidthType() === undefined || item.getWidthType() === null)) {
                    return option;
                }
            })[0];

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMWIDTHTYPE, config.INPUT_TYPE.SELECT)
            .setVal(itemWidthTypeOption)
            .visiblePermission(showBodyItemWidthRelatedAttribute)
            .addSubscription(function (val) {
                if (val !== item.getWidthType()) {
                    item.setWidthType(val);

                    if (val === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                        item.setWidth(item.getWidth() + item.getSidewallLeft() + item.getSidewallRight());
                    } else {
                        item.setWidth(item.getWidth() - item.getSidewallLeft() - item.getSidewallRight());
                    }
                }

            })
            .visiblePermission(function () {
                return item.getType() !== config.BODY_TYPES.HOOKLIFT && item.getType() !== config.BODY_TYPES.FLATDECK
            }), path);


        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMWIDTH, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getWidth();
            })
            .setter(function (newValue) {
                item.setWidth(newValue);
            })
            .addOverrideMechanism(dummyFunc,
                function (val) {
                    activeConfigurationWizardItemSize.setWidthOverride(val);
                },
                function () {

                    if (item.getWidthType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                        return originalConfigurationWizardItemSize.getWidth();
                    } else {
                        return originalConfigurationWizardItemSize.getWidth() - item.getSidewallLeft() - item.getSidewallRight();
                    }
                },
                function () {
                    return activeConfigurationWizardItemSize.getWidthOverride();
                },
                function () {
                    activeConfigurationWizardItemSize.setWidth(originalConfigurationWizardItemSize.getWidth());
                }
            )
            .overridePermission(function () {
                return true;
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMWIDTH)
                .addOpBlock(function (newValue, oldValue, deltaVal) {
                    activeConfigurationWizardItemSize.setWidth(activeConfigurationWizardItemSize.getWidth() + deltaVal);
                })
            ), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMSIDEWALLLEFT, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getSidewallLeft();
            })
            .setter(function (newValue) {
                item.setSidewallLeft(newValue);
            })
            .visiblePermission(showBodyItemWidthRelatedAttribute)
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMSIDEWALLLEFT)), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMSIDEWALLRIGHT, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getSidewallRight();
            })
            .setter(function (newValue) {
                item.setSidewallRight(newValue);
            })
            .visiblePermission(showBodyItemWidthRelatedAttribute)
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMSIDEWALLRIGHT)), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMLEFTCOMPARTMENTWIDTH, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getLeftCompartmentWidth();
            })
            .setter(function (newValue) {
                item.setLeftCompartmentWidth(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMLEFTCOMPARTMENTWIDTH)), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMRIGHTCOMPARTMENTWIDTH, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getRightCompartmentWidth();
            })
            .setter(function (newValue) {
                item.setRightCompartmentWidth(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMRIGHTCOMPARTMENTWIDTH)), path);


        var bodyPayloadHorizontalCOGTypeOption = getCOGTypeOptions()
            .filter(function (option) {
                if (option.id === item.getPayloadCOGType()) {
                    return option;
                } else if (option.id === config.COG_TYPES.LENGTH && (item.getPayloadCOGType() === '' || item.getPayloadCOGType() === undefined || item.getPayloadCOGType() === null)) {
                    return option;
                }
            })[0];

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMBODYPAYLOADHORIZONTALCOGTYPE, config.INPUT_TYPE.SELECT)
            .setVal(bodyPayloadHorizontalCOGTypeOption)
            .addSubscription(function (val) {

                if (val !== item.getPayloadCOGType()) {
                    item.setPayloadCOGType(val);
                    if (val === config.COG_TYPES.LENGTH) {
                        item.setPayloadHorizontalCOG((item.getActualLength() - item.getHeadboardThickness() - item.getTailboardThickness()) * item.getPayloadHorizontalCOG() / 100);
                    } else {
                        item.setPayloadHorizontalCOG(utils.returnValueOrZeroIfNanOrInfinity((item.getPayloadHorizontalCOG() / (item.getActualLength() - item.getHeadboardThickness() - item.getTailboardThickness())) * 100));
                    }
                }
            }), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMBODYPAYLOADHORIZONTALCOG, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getPayloadHorizontalCOG();
            })
            .setter(function (newValue) {
                item.setPayloadHorizontalCOG(newValue);
            })
            //.visiblePermission(function () { return true; })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMBODYPAYLOADHORIZONTALCOG))
            .addFormattingRule(function () {
                return item.getPayloadCOGType() === config.COG_TYPES.PERCENTAGE
            }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);


        var bodyPayloadVerticalCOGTypeOption = getCOGTypeOptions()
            .filter(function (option) {
                if (option.id === item.getPayloadVerticalCOGType()) {
                    return option;
                } else if (option.id === config.COG_TYPES.LENGTH && (item.getPayloadVerticalCOGType() === '' || item.getPayloadVerticalCOGType() === undefined || item.getPayloadVerticalCOGType() === null)) {
                    return option;
                }
            })[0];

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMBODYPAYLOADVERTICALCOGTYPE, config.INPUT_TYPE.SELECT)
            .setVal(bodyPayloadVerticalCOGTypeOption)
            .addSubscription(function (val) {
                if (val !== item.getPayloadVerticalCOGType()) {
                    item.setPayloadVerticalCOGType(val);
                    if (val === config.COG_TYPES.LENGTH) {
                        item.setPayloadVerticalCOG(item.getActualInternalHeight() * item.getPayloadVerticalCOG() / 100);
                    } else {
                        item.setPayloadVerticalCOG(utils.returnValueOrZeroIfNanOrInfinity((item.getPayloadVerticalCOG() / item.getActualInternalHeight()) * 100));
                    }
                }
            }), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMBODYPAYLOADVERTICALCOG, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getPayloadVerticalCOG();
            })
            .setter(function (newValue) {
                item.setPayloadVerticalCOG(newValue);
            })
            //.visiblePermission(function () { return true; })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMBODYPAYLOADVERTICALCOG))
            .addFormattingRule(function () {
                return item.getPayloadVerticalCOGType() === config.COG_TYPES.PERCENTAGE
            }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);


        var bodyPayloadLateralCOGTypeOption = getCOGTypeOptions()
            .filter(function (option) {
                if (option.id === item.getPayloadLateralCOGType()) {
                    return option;
                } else if (option.id === config.COG_TYPES.LENGTH && (item.getPayloadLateralCOGType() === '' || item.getPayloadLateralCOGType() === undefined || item.getPayloadLateralCOGType() === null)) {
                    return option;
                }
            })[0];

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMBODYPAYLOADLATERALCOGTYPE, config.INPUT_TYPE.SELECT)
            .setVal(bodyPayloadLateralCOGTypeOption)
            .addSubscription(function (val) {
                if (val !== item.getPayloadLateralCOGType()) {
                    item.setPayloadLateralCOGType(val);
                    if (val === config.COG_TYPES.LENGTH) {
                        item.setPayloadLateralCOG((item.getActualWidth() - item.getSidewallLeft() - item.getSidewallRight()) * item.getPayloadLateralCOG() / 100);
                    } else {
                        item.setPayloadLateralCOG(utils.returnValueOrZeroIfNanOrInfinity((item.getPayloadLateralCOG() / (item.getActualWidth() - item.getSidewallLeft() - item.getSidewallRight())) * 100));
                    }
                }
            }), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMBODYPAYLOADLATERALCOG, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getPayloadLateralCOG();
            })
            .setter(function (newValue) {
                item.setPayloadLateralCOG(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMBODYPAYLOADLATERALCOG))
            .addFormattingRule(function () {
                return item.getPayloadLateralCOGType() === config.COG_TYPES.PERCENTAGE
            }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);



        var massTypeOption = getMassTypeOptions()
            .filter(function (option) {
                if (option.id === item.getMassType()) {
                    return option;
                } else if (option.id === config.MASS_TYPES.MASS && (item.getMassType() === '' || item.getMassType() === undefined || item.getMassType() === null)) {
                    return option;
                }
            })[0];

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMMASSTYPE, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                if (val !== item.getMassType()) {
                    item.setMassType(val);
                    if (val === config.MASS_TYPES.MASSPERDISTANCE) {
                        if (item.getType() === config.BODY_TYPES.HOOKLIFT && item.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
                            item.setMass(utils.returnValueOrZeroIfNanOrInfinity(item.getMass() / utils.returnValueOrZeroIfNanOrInfinity((item.getSubframeLength() / 1000))));
                        } else {
                            item.setMass(utils.returnValueOrZeroIfNanOrInfinity(item.getMass() / utils.returnValueOrZeroIfNanOrInfinity((item.getActualLength() / 1000))));
                        }
                    } else {
                        if (item.getType() === config.BODY_TYPES.HOOKLIFT && item.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
                            item.setMass(item.getMass() * (item.getSubframeLength() / 1000))
                        } else {
                            item.setMass(item.getMass() * (item.getActualLength() / 1000));
                        }
                    }
                }
            })
            .setVal(massTypeOption)
            //.addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.BODYMASSTYPE, config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_ONLY)
            //    .addOpBlock(function (newValue, oldValue, deltaVal) {

            //    })
            //)
            , path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
            .getter(function () {
                return item.getMass();
            })
            .setter(function (newValue) {
                item.setMass(newValue);
            })
            .doNotIncludeUnit()
            //.addOverrideMechanism(getChassisObjectToUseFunc,
            //function (val) {
            //    item.setMassOverride(val);
            //},
            //function () {
            //    return originalConfigurationWizardItem.getMass();
            //},
            //function () {
            //    return item.getMassOverride();
            //})
            //.overridePermission(showUnlessStandardBodyAndNotAdminOrIsTemplateBodyOrIsCustomBody)
            //.visiblePermission(showUnlessStandardBodyAndNotAdmin)
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMMASS))
            .addFormattingRule(function () {
                return item.getMassType() === config.MASS_TYPES.MASSPERDISTANCE
            },
                getUIFormattedLinearDensityValueNoUnit,
                config.INCREMENT_TYPE.LINEARDENSITY,
                function (newValue) {
                    if (item.getType() === config.BODY_TYPES.HOOKLIFT && item.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
                        return newValue * item.getSubframeLength() / 1000;
                    } else {
                        return newValue * item.getActualLength() / 1000;
                    }

                }), path);

        //var heightOverride = false;
        ////var originalCraneHeight = 3500;//crane.getHeight();
        //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        //    .getter(function () { return item.getHeight(); })
        //    .setter(function (newValue) { item.setHeight(newValue); })
        //    //.visiblePermission(function () { return true; })
        //    .addOverrideMechanism(getChassisObjectToUseFunc,
        //    function (val) {
        //        heightOverride = val;
        //    },
        //    function () {
        //        return originalConfigurationWizardItem.getHeight();
        //    },
        //    function () {
        //        return heightOverride;
        //    }, function () {
        //        if (!offerManager.getAttributeGroup(path + '.' + config.VALUE_TYPE.ITEMLENGTH).override()) {
        //            //crane.setLength(originalCraneLength);
        //            item.setLength(originalConfigurationWizardItem.getLength());
        //        }
        //    })
        //    .overridePermission(function () { return true; })
        //    .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMHEIGHT)
        //    ), path);

        //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMWIDTH, config.INPUT_TYPE.UPDOWNBOX)
        //    .getter(function () { return item.getWidth(); })
        //    .setter(function (newValue) { item.setWidth(newValue); })
        //    //.visiblePermission(isTemplateOrCustomCraneWithoutGraphic)
        //    .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMWIDTH)), path);
    }

    //Weights
    if (item.getAccessoryType() === config.ACCESSORY_TYPES.PAYLOAD) {
        //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMMASS, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.NONE)
        //.getter(function () { return item.getPayload(); })
        //.setter(function (newValue) { item.setPayload(newValue); })
        ////.visiblePermission(function () { return true; })
        //.addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMMASS)), path);
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMPAYLOAD, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.NONE)
            .getter(function () {
                return item.getPayload()
            })
            .setter(function (newValue) {
                item.setPayload(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMPAYLOAD))
            // .alwaysNotifyValueSubscribers() //this is required when the value might change based on selecting an option from an associated drop down
            .addFormattingRule(function () {
                return true
            }, function (value) {
                return Globalize.format(value, "n1") + ' ' + item.getUnitOfMeasure(); // User Story 17565
            }, config.INCREMENT_TYPE.NONE, function (newValue) {
                return newValue;
            }), path);


        //Unit Of Measure
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMUNITOFMEASURE, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
            .setter(function (newValue) {
                item.setUnitOfMeasure(newValue);
                refreshData.value = new Date().getTime();
            })
            .getter(function () {
                return item.getUnitOfMeasure();
            })
            .addLinkedAOG(path + '.' + config.VALUE_TYPE.ITEMDENSITY).addLinkedAOG(path + '.' + config.VALUE_TYPE.ITEMPAYLOAD), path);

        //Density 
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMDENSITY, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.MASS)
            .getter(function () {
                return item.getDensity()
            })
            .setter(function (newValue) {
                item.setDensity(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMDENSITY))
            // .alwaysNotifyValueSubscribers() //this is required when the value might change based on selecting an option from an associated drop down
            .addFormattingRule(function () {
                return true
            }, function (value) {
                var measurementSystemToUse = getMeasurementSystemToUse();
                return Globalize.format(value * measurementSystemToUse.massConversion, "n3") + ' ' + measurementSystemToUse.unitMass + '/' + item.getUnitOfMeasure(); // User Story 17565
            }, config.INCREMENT_TYPE.MASS), path);

        //Mass
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
            .getter(function () {
                return item.getMass();
            })
            .readOnly(), path);

    } else if (item.getAccessoryType() !== config.ACCESSORY_TYPES.BODY) {
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
            .getter(function () {
                return item.getMass();
            })
            .setter(function (newValue) {
                item.setMass(newValue);
            })
            //.visiblePermission(function () { return true; })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMMASS)), path);
    }


    var horizontalCOGTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === item.getCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (item.getCOGType() === '' || item.getCOGType() === undefined || item.getCOGType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMHORIZONTALCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(horizontalCOGTypeOption)
        .addSubscription(function (val) {

            handleCOGTypeChange(val);
        }), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMHORIZONTALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return item.getHorizontalCOG();
        })
        .setter(function (newValue) {
            item.setHorizontalCOG(newValue);
        })
        .doNotIncludeUnit()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMHORIZONTALCOG))
        .addFormattingRule(function () {
            return item.getCOGType() === config.COG_TYPES.PERCENTAGE
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);


    var verticalCOGTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === item.getVerticalCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (item.getVerticalCOGType() === '' || item.getVerticalCOGType() === undefined || item.getVerticalCOGType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMVERTICALCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(verticalCOGTypeOption)
        .addSubscription(function (val) {
            handleVerticalCOGTypeChange(val);
        }), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMVERTICALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return item.getVerticalCOG();
        })
        .setter(function (newValue) {
            item.setVerticalCOG(newValue);
        })
        .doNotIncludeUnit()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMVERTICALCOG))
        .addFormattingRule(function () {
            return item.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);


    var lateralCOGTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === item.getLateralCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (item.getLateralCOGType() === '' || item.getLateralCOGType() === undefined || item.getLateralCOGType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMLATERALCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(lateralCOGTypeOption)
        .addSubscription(function (val) {
            handleLateralCOGTypeChange(val);
        }), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMLATERALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return item.getLateralCOG();
        })
        .setter(function (newValue) {
            item.setLateralCOG(newValue);
        })
        .doNotIncludeUnit()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMLATERALCOG))
        .addFormattingRule(function () {
            return item.getLateralCOGType() === config.COG_TYPES.PERCENTAGE
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);

    if (item.getAccessoryType() === config.ACCESSORY_TYPES.FRIDGE) {
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMPROTRUSION, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getProtrusion();
            })
            .setter(function (newValue) {
                item.setProtrusion(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMPROTRUSION)), path);
    }

    if (item.getAccessoryType() === config.ACCESSORY_TYPES.FIFTH_WHEEL) {
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMFRONTOVERHANG, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getFrontOverhang();
            })
            .setter(function (newValue) {
                item.setFrontOverhang(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMFRONTOVERHANG)), path);
    }

    if (item.getAccessoryType() === config.ACCESSORY_TYPES.TAILLIFT) {
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMMECHANISMLENGTH, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getMechanismLength();
            })
            .setter(function (newValue) {
                item.setMechanismLength(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMMECHANISMLENGTH)), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMMECHANISMHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getMechanismHeight();
            })
            .setter(function (newValue) {
                item.setMechanismHeight(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMMECHANISMHEIGHT)), path);
    }

    if (item.getAccessoryType() === config.ACCESSORY_TYPES.HITCH) {
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMMOUNTINGLENGTH, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getMountingLength();
            })
            .setter(function (newValue) {
                item.setMountingLength(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMMOUNTINGLENGTH)), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMCOUPLINGOFFSET, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getCouplingOffset();
            })
            .setter(function (newValue) {
                item.setCouplingOffset(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMCOUPLINGOFFSET)
                .addOpBlock(function (newValue, oldValue, deltaVal) {
                    item.setOffset(item.getOffset() + deltaVal);
                })
            ), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMCOUPLINGHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getCouplingHeight();
            })
            .setter(function (newValue) {
                item.setCouplingHeight(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMCOUPLINGHEIGHT)), path);
    }

    if (item.getAccessoryType() === config.ACCESSORY_TYPES.HOOKLIFT) {
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMAFRAME, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getAFrame();
            })
            .setter(function (newValue) {
                item.setAFrame(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMAFRAME)), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMHOOKOFFSET, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getHookOffset();
            })
            .setter(function (newValue) {
                item.setHookOffset(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMHOOKOFFSET)), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMCENTREOFROLLER, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getCentreOfRoller();
            })
            .setter(function (newValue) {
                item.setCentreOfRoller(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMCENTREOFROLLER)), path);

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.ITEMPLATFORMHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return item.getPlatformHeight();
            })
            .setter(function (newValue) {
                item.setPlatformHeight(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.ITEMPLATFORMHEIGHT)), path);
    }

    function getChassisObjectToUseFunc() {
        return chassisObjectToUse;
    }

    function showBodyItemWidthRelatedAttribute() {
        return (item.getType() === config.BODY_TYPES.HOOKLIFT && item.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) || (item.getType() !== config.BODY_TYPES.FLATDECK && item.getType() !== config.BODY_TYPES.ROLLBACK && item.getType() !== config.BODY_TYPES.TANKER && item.getType() !== config.BODY_TYPES.SERVICE && item.getType() !== config.BODY_TYPES.HOOKLIFT);
    }

    function showBodyItemRoofThicknessAttribute() {
        return item.getType() !== config.BODY_TYPES.FLATDECK && item.getType() !== config.BODY_TYPES.ROLLBACK && item.getType() !== config.BODY_TYPES.TIPPER && item.getType() !== config.BODY_TYPES.SERVICE && item.getType() !== config.BODY_TYPES.TIMBER && item.getType() !== config.BODY_TYPES.BEAVERTAIL && item.getType() !== config.BODY_TYPES.DROPSIDE && item.getType() !== config.BODY_TYPES.HOOKLIFT;
    }

    function showItemBodyHeightTypeAttribute() {
        return item.getType() !== config.BODY_TYPES.HOOKLIFT && item.getType() !== config.BODY_TYPES.FLATDECK && item.getType() !== config.BODY_TYPES.ROLLBACK && item.getType() !== config.BODY_TYPES.TIMBER && item.getType() !== config.BODY_TYPES.SERVICE;
    }

    function showItemHeadboardHeight() {
        return item.getType() !== config.BODY_TYPES.FLATDECK && item.getType() !== config.BODY_TYPES.ROLLBACK && item.getType() !== config.BODY_TYPES.TIPPER && item.getType() !== config.BODY_TYPES.TIMBER &&
            item.getType() !== config.BODY_TYPES.BEAVERTAIL && item.getType() !== config.BODY_TYPES.DROPSIDE && item.getType() !== config.BODY_TYPES.SERVICE && item.getType() !== config.BODY_TYPES.VAN &&
            item.getType() !== config.BODY_TYPES.TAUTLINER && item.getType() !== config.BODY_TYPES.LIVESTOCK && item.getType() !== config.BODY_TYPES.TANKER && item.getType() !== config.BODY_TYPES.COMPACTOR &&
            item.getType() !== config.BODY_TYPES.OTHER && item.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY;
    }

    function handleCOGTypeChange(val) {
        if (item.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
            if (val !== item.getCOGType()) {
                item.setCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    if (item.getType() === config.BODY_TYPES.HOOKLIFT && item.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
                        item.setHorizontalCOG(item.getSubframeLength() * item.getHorizontalCOG() / 100);
                    } else {
                        item.setHorizontalCOG(item.getActualLength() * item.getHorizontalCOG() / 100);
                    }
                    
                } else {
                    if (item.getType() === config.BODY_TYPES.HOOKLIFT && item.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
                        item.setHorizontalCOG(utils.returnValueOrZeroIfNanOrInfinity((item.getHorizontalCOG() / item.getSubframeLength()) * 100));
                    } else {
                        item.setHorizontalCOG(utils.returnValueOrZeroIfNanOrInfinity((item.getHorizontalCOG() / item.getActualLength()) * 100));
                    }
                    
                }
            }
        } else {
            if (val !== item.getCOGType()) {
                item.setCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    item.setHorizontalCOG(item.getLength() * item.getHorizontalCOG() / 100);
                } else {
                    item.setHorizontalCOG(utils.returnValueOrZeroIfNanOrInfinity((item.getHorizontalCOG() / item.getLength()) * 100));
                }
            }
        }
    }

    function handleVerticalCOGTypeChange(val) {
        if (item.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
            if (val !== item.getVerticalCOGType()) {
                item.setVerticalCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    if (item.getType() === config.BODY_TYPES.HOOKLIFT && item.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
                        item.setVerticalCOG((item.getHeadboardHeight() + item.getSubframeHeight() + item.getSubstructureHeight()) * item.getVerticalCOG() / 100);
                    } else {
                        item.setVerticalCOG(item.getActualExternalHeight() * item.getVerticalCOG() / 100);
                    }
                } else {
                    if (item.getType() === config.BODY_TYPES.HOOKLIFT && item.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
                        item.setVerticalCOG(utils.returnValueOrZeroIfNanOrInfinity((item.getVerticalCOG() / (item.getHeadboardHeight() + item.getSubframeHeight() + item.getSubstructureHeight())) * 100));
                    } else {
                        item.setVerticalCOG(utils.returnValueOrZeroIfNanOrInfinity((item.getVerticalCOG() / item.getActualExternalHeight()) * 100));
                    }

                }
            }
        } else {
            if (val !== item.getVerticalCOGType()) {
                item.setVerticalCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    item.setVerticalCOG(item.getHeight() * item.getVerticalCOG() / 100);
                } else {
                    item.setVerticalCOG(utils.returnValueOrZeroIfNanOrInfinity((item.getVerticalCOG() / item.getHeight()) * 100));
                }
            }
        }
    }

    function handleLateralCOGTypeChange(val) {
        if (item.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
            if (val !== item.getLateralCOGType()) {
                item.setLateralCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    item.setLateralCOG(item.getActualWidth() * item.getLateralCOG() / 100);
                } else {
                    item.setLateralCOG(utils.returnValueOrZeroIfNanOrInfinity((item.getLateralCOG() / item.getActualWidth()) * 100));
                }
            }
        } else {
            if (val !== item.getLateralCOGType()) {
                item.setLateralCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    item.setLateralCOG(item.getWidth() * item.getLateralCOG() / 100);
                } else {
                    item.setLateralCOG(utils.returnValueOrZeroIfNanOrInfinity((item.getLateralCOG() / item.getWidth()) * 100));
                }
            }
        }
    }

    function dummyFunc() {
        return {};
    }
}

function checkForDoubleOverrideAndPromptUserIfNecessary(path, contextFlag, item) {

    var itemLengthAOG;
    var itemHeightAOG;
    if(item.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
        itemLengthAOG = getAttributeGroup(path + '.' + config.VALUE_TYPE.ITEMLENGTH);
        itemHeightAOG = getAttributeGroup(path + '.' + config.VALUE_TYPE.ITEMHEIGHT);
    }else {
        itemLengthAOG = getAttributeGroup(path + '.' + config.VALUE_TYPE.ITEMDRAWINGLENGTH);
        itemHeightAOG = getAttributeGroup(path + '.' + config.VALUE_TYPE.ITEMDRAWINGHEIGHT);
    }
    

    var heightLabel = config.getTranslationText('180');
    var lengthLabel = config.getTranslationText('217')
    
    if (item.getAccessoryType() === config.ACCESSORY_TYPES.BODY && item.getType() === config.BODY_TYPES.HOOKLIFT && item.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
        heightLabel = config.getTranslationText('1545');
        lengthLabel = config.getTranslationText('1544');
    } else if (item.getAccessoryType() === config.ACCESSORY_TYPES.BODY && item.getType() === config.BODY_TYPES.TANKER) {
        heightLabel = config.getTranslationText('2904');
    }

    if (itemLengthAOG.override.value && itemHeightAOG.override.value) {
        var replaceStrings;
        if (contextFlag === config.VALUE_TYPE.ITEMLENGTH) {
            replaceStrings = {
                ReplaceString1: heightLabel,
                ReplaceString2: lengthLabel
            };
            configurationActionResponseHandlerCallback(config.ACTION_TYPE.ATTRIBUTE_VALUE_CHANGED, config.ACTION_ID.IMPORT_WIZARD_ASPECT_RATIO_BROKEN_LENGTH, function (){
                itemLengthAOG.override.value = false;
            }, replaceStrings);
        } else {
            replaceStrings = {
                ReplaceString1: lengthLabel,
                ReplaceString2: heightLabel
            };
            configurationActionResponseHandlerCallback(config.ACTION_TYPE.ATTRIBUTE_VALUE_CHANGED, config.ACTION_ID.IMPORT_WIZARD_ASPECT_RATIO_BROKEN_HEIGHT, function (){
                itemHeightAOG.override.value = false;
            }, replaceStrings);
        }
    }
}

function createObservablesForFinanceDetailModal(path, item) {
    var chassisTypeToUse,
        originalItem = item.clone();
    //if (path.includes('TRAILER')) {
    if (path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE)) {
        chassisTypeToUse = 'VEHICLE';
    } else {
        chassisTypeToUse = 'TRAILER';
    }

    //if (isVehicleRow === true) {
    //    originalItem = item.clone();
    //    // include override
    //    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGFINANCEDETAILLISTPRICE, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.HIGHCURRENCY)
    //        .setter(function (newValue) {
    //            item.setListPrice(newValue);
    //        })
    //        .getter(function () {
    //            return item.getListPrice();
    //        })
    //        .addOverrideMechanism(function () { return {}; },
    //            function (val) {
    //                item.setListPriceOverride(val);                            
    //                refreshUILiveLabels(new Date().getTime());
    //            },
    //            function () {
    //                return originalItem.getListPrice();
    //            },
    //            function () {
    //                return item.getListPriceOverride();
    //            })
    //        .overridePermission(function () { return true; })
    //        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGFINANCEDETAILLISTPRICE)), path);
    //} else {
    //    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGFINANCEDETAILLISTPRICE, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.HIGHCURRENCY)
    //        .setter(function (newValue) {
    //            item.setListPrice(newValue);
    //        })
    //        .getter(function () {
    //            return item.getListPrice();
    //        })
    //        .addOverrideMechanism(function () { return {}; },
    //            function (val) {
    //                item.setListPriceOverride(val);
    //                refreshUILiveLabels(new Date().getTime());
    //            },
    //            function () {
    //                return originalItem.getListPrice();
    //            },
    //            function () {
    //                return item.getListPriceOverride();
    //            })
    //        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGFINANCEDETAILLISTPRICE)), path);
    //}

    // include override
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGFINANCEDETAILLISTPRICE, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.HIGHCURRENCY)
        .setter(function (newValue) {
            item.setListPrice(newValue);
        })
        .getter(function () {
            return item.getListPrice();
        })
        .addOverrideMechanism(function () {
            return {};
        },
            function (val) {
                item.setListPriceOverride(val);
                refreshUILiveLabels.value = new Date().getTime();
            },
            function () {
                if (item.getOriginalListPrice() !== null) {
                    return item.getOriginalListPrice();
                } else {
                    return originalItem.getListPrice();
                }
            },
            function () {
                return item.getListPriceOverride();
            })
        .overridePermission(function () {
            return true;
        })
        .disableUndoOpCreation()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGFINANCEDETAILLISTPRICE)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGFINANCEDETAILDISCOUNT, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.PERCENTAGE)
        .setter(function (newValue) {
            item.setDiscount(newValue);
        })
        .getter(function () {
            return item.getDiscount();
        })
        .disableUndoOpCreation()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGFINANCEDETAILDISCOUNT)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGFINANCEDETAILPERIOD, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.MONTHS)
        .setter(function (newValue) {
            item.setPeriod(newValue);
        })
        .getter(function () {
            return item.getPeriod();
        })
        .disableUndoOpCreation()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGFINANCEDETAILPERIOD)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGFINANCEDETAILINTERESTRATE, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.PERCENTAGEDOUBLEPLACE)
        .setter(function (newValue) {
            item.setInterestRate(newValue);
        })
        .getter(function () {
            return item.getInterestRate();
        })
        .disableUndoOpCreation()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGFINANCEDETAILINTERESTRATE)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGFINANCEDETAILRESIDUAL, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.PERCENTAGE)
        .setter(function (newValue) {
            item.setResidualRate(newValue);
        })
        .getter(function () {
            return item.getResidualRate();
        })
        .disableUndoOpCreation()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGFINANCEDETAILRESIDUAL)), path);

    var residualValueOption = getResidualValueOptions().find(function (option) {
        return option.id === item.getResidualRateType();
    });

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGFINANCEDETAILRESIDUALTYPE, config.INPUT_TYPE.SELECT)
        .setVal(residualValueOption)
        .addSubscription(function (val) {
            item.setResidualRateType(val);
            //handleResidualTypeChange(val);
        })
        .disableUndoOpCreation(), path);

    // function handleResidualTypeChange() {
    //     TScMessenger.writeDebugMessage('');
    // }
}

function getResidualValueOptions() {
    return [{
        id: config.RESIDUAL_RATE_TYPES.NET_PRICE,
        description: config.getTranslationText('1503')
    },
    {
        id: config.RESIDUAL_RATE_TYPES.LIST_PRICE,
        description: config.getTranslationText('1504')
    }
    ];
}

function createObservablesForLicenceFeesModal(path, item) {
    var chassisTypeToUse,
        originalItem = item.clone();
    if (path.includes('TRAILER')) {
        chassisTypeToUse = 'TRAILER';
    } else {
        chassisTypeToUse = 'VEHICLE';
    }

    // Need to add override
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGLICENCEFEES, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.CURRENCYPERYEAR)
        .setter(function (newValue) {
            item.setLicenceFees(newValue);
        })
        .getter(function () {
            return item.getLicenceFees();
        })
        .addOverrideMechanism(function () {
            return {};
        },
            function (val) {
                item.setLicenceFeesOverride(val);
                if (val === true) {
                    item.disableSelectMenu();
                } else {
                    item.enableSelectMenu();
                }
                //getActiveOffer().getCosting().setLicenceFeesOverride(val);
                //if (val === true) {
                //    dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.OVERWROTE_LICENCE_FEES));
                //}
                refreshUILiveLabels.value = new Date().getTime();
            },
            function () {
                return item.getRegionPrice();
                //var region = getAttributeGroup(path + '.' + config.VALUE_TYPE.COSTINGLICENCEFEESREGION);
                //var test = globals.getFilteredLicenceCategoriesArray(getActiveOffer().getCosting().getLicenceCategories(), item.getChassisObjectType(), item.getTotalUnladenMass());
                //return originalItem.getLicenceFees();
            },
            function () {
                return item.getLicenceFeesOverride();
            })
        .overridePermission(function () {
            return true;
        })
        .disableUndoOpCreation()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGLICENCEFEES)), path);


    //var provinceOptionsForChassisType = getActiveOffer().getCosting().getLicenceCategories().filter(function(licenceCategory){
    //    return licenceCategory.CategoryType === chassisTypeToUse;
    //});
    //var provinceOption = provinceOptionsForChassisType[0];
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGLICENCEFEESREGION, config.INPUT_TYPE.SELECT)
        //.setVal(item.getRegionId())
        .setVal(item.getRegionForSelect())
        .addSubscription(function (val) {
            if (typeof val === 'number') {
                item.refreshData(val);
            }
        })
        .disableUndoOpCreation(), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGLICENCEFEESREGIONPRICE, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.CURRENCYPERYEAR)
        .getter(function () {
            return item.getRegionPrice();
        })
        .disableUndoOpCreation(), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGLICENCEFEESREGIONMASS, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.MASS)
        .getter(function () {
            return item.getRegionMass();
        })
        .disableUndoOpCreation(), path);
}

function createObservablesForTollFeesModal(path, item) {
    var chassisTypeToUse,
        originalItem = item.clone();
    if (path.includes('TRAILER')) {
        chassisTypeToUse = 'TRAILER';
    } else {
        chassisTypeToUse = 'VEHICLE';
    }

    // Vehicle Class & Override
    // Toll Cost Per Trip & Override
    // Permit Cost Per Trip

    // Need to add override
    
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGVEHICLECLASS, config.INPUT_TYPE.SELECT)
        .setVal(item.getVehicleClassForSelect())
        .addSubscription(function (val) {
            if (typeof val === 'number') {
                item.refreshData.value = val;
            }
        })
        .addOverrideMechanism(function () {
            return {};
        },
            function (val) {
                item.setVehicleClassIdOverride(val);
                //if (val === true) {
                //    item.disableSelectMenu();
                //} else {
                //    item.enableSelectMenu();
                //}
                refreshUILiveLabels.value = new Date().getTime();
            },
            function () {
                //return {
                //    id: originalItem.getVehicleClassId(),
                //    description: originalItem.getVehicleClassIdDescription()
                //};
                return {
                    id: getVehicleClassId()
                };
            },
            function () {
                return item.getVehicleClassIdOverride();
            })
        .overridePermission(function () {
            return true;
        })
        .disableUndoOpCreation(), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGTOLLFEESPERTRIP, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.CURRENCYPERTRIP)
        .setter(function (newValue) {
            item.setTollFeesPerTrip(newValue);
        })
        .getter(function () {
            return item.getTollFeesPerTrip();
        })
        .addOverrideMechanism(function () {
            return {};
        },
            function (val) {
                item.setTollFeesPerTripOverride(val);
                //if (val === true) {
                //    item.disableSelectMenu();
                //} else {
                //    item.enableSelectMenu();
                //}
                refreshUILiveLabels.value = new Date().getTime();
            },
            function () {
                var value = 0;
                getActiveOffer().getPerformance().getTolls().forEach(function(toll){
                    value += toll.getActiveTollFee();
                });
                return value;
            },
            function () {
                return item.getTollFeesPerTripOverride();
            })
        .overridePermission(function () {
            return true;
        })
        .disableUndoOpCreation()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGTOLLFEESPERTRIP)), path);


    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGPERMITCOSTPERTRIP, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.CURRENCYPERTRIP)
        .setter(function (newValue) {
            item.setPermitCostPerTrip(newValue);
        })
        .getter(function () {
            return item.getPermitCostPerTrip();
        })
        .disableUndoOpCreation()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGPERMITCOSTPERTRIP)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGOVERALLCOSTPERTRIP, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.CURRENCYPERTRIP)
        //.setter(function (newValue) {
        //    item.setPermitCostPerTrip(newValue);
        //})
        .getter(function () {
            return item.getTollFeesPerTrip() + item.getPermitCostPerTrip();
        })
        .readOnlyPermission(function () {
            return true;
        })
        .disableUndoOpCreation(), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGTRIPS, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.TRIPSPERMONTH)
        .getter(function () {
            return item.getTrips();
        })
        .readOnlyPermission(function () {
            return true;
        })
        .disableUndoOpCreation(), path);
        
}

function createObservablesForFuelAndAdblueModal(path, item) {
    // var chassisTypeToUse,
    //     originalItem = item.clone();
    var chassisTypeToUse;
    if (path.includes('TRAILER')) {
        chassisTypeToUse = 'TRAILER';
    } else {
        chassisTypeToUse = 'VEHICLE';
    }

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILCOSTPERMONTH, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.CURRENCYPERMONTH)
        .setter(function (newValue) {
            item.setTotal(newValue);
        })
        .getter(function () {
            return item.getTotal();
        })
        .addOverrideMechanism(function () {
            return {};
        },
            function (val) {
                item.setTotalOverride(val);
                refreshUILiveLabels.value = new Date().getTime();
            },
            function () {
                return item.getTotalFromRowObjects();
            },
            function () {
                return item.getTotalOverride();
            })
        .overridePermission(function () {
            return true;
        })
        .disableUndoOpCreation()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILCOSTPERMONTH)), path);

    item.getRowObjects().forEach(function (row) {
        createRow(row.getPath(), row);
    });

    function createRow(rowPath, rowItem) {
        // Consumption
        // Override on vehicle only. No override on Trailer
        if(rowPath.includes(config.COSTING_FUEL_AND_ADBLUE_ROW_TYPE.VEHICLE_FUEL)) {
            // addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILFUELCONSUMPTION, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.VOLUMEPER100KMS)
            addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILFUELCONSUMPTION, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.FUELCONSUMPTIONNOCONVERSION)
            //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILFUELCONSUMPTION, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.FUELCONSUMPTION)
            .setter(function (newValue) {
                rowItem.setConsumption(newValue);
            })
            .getter(function () {
                return rowItem.getConsumption();
            })
            .addOverrideMechanism(function () {
                return {};
            },
                function (val) {
                    rowItem.setConsumptionOverride(val);
                    refreshUILiveLabels.value = new Date().getTime();
                },
                function () {
                    return rowItem.getOriginalConsumption();
                },
                function () {
                    return rowItem.getConsumptionOverride();
                })
            .overridePermission(function () {
                return true;
            })
            .disableUndoOpCreation()
            .addValidation(new ValidationEngine(rowPath + '.' + config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILFUELCONSUMPTION)), rowPath);
        } else if(rowPath.includes(config.COSTING_FUEL_AND_ADBLUE_ROW_TYPE.VEHICLE_ADBLUE)) {
            addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILADBLUECONSUMPTION, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.PERCENTAGEOFFUEL)
                .setter(function (newValue) {
                    rowItem.setConsumption(newValue);
                })
                .getter(function () {
                    return rowItem.getConsumption();
                })
                .addOverrideMechanism(function () {
                    return {};
                },
                    function (val) {
                        rowItem.setConsumptionOverride(val);
                        refreshUILiveLabels.value = new Date().getTime();
                    },
                    function () {
                        return rowItem.getOriginalConsumption();
                    },
                    function () {
                        return rowItem.getConsumptionOverride();
                    })
                .overridePermission(function () {
                    return true;
                })
                .disableUndoOpCreation()
                .addValidation(new ValidationEngine(rowPath + '.' + config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILADBLUECONSUMPTION)), rowPath);
        } else {
            addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGFUELANDADBLUEEQUIPMENTFUELCONSUMPTION, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.VOLUMEPERHOUR)
            .setter(function (newValue) {
                rowItem.setConsumption(newValue);
            })
            .getter(function () {
                return rowItem.getConsumption();
            })
            .disableUndoOpCreation()
            .addValidation(new ValidationEngine(rowPath + '.' + config.VALUE_TYPE.COSTINGFUELANDADBLUEEQUIPMENTFUELCONSUMPTION)), rowPath);
        }

        // Cost/litre/gallon
        // Override on all
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILCOSTPERVOLUME, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.MEDIUMCURRENCY)
            .setter(function (newValue) {
                rowItem.setCostPerVolume(newValue);
            })
            .getter(function () {
                return rowItem.getCostPerVolume();
            })
            .addOverrideMechanism(function () {
                return {};
            },
                function (val) {
                    rowItem.setCostPerVolumeOverride(val);
                    refreshUILiveLabels.value = new Date().getTime();
                },
                function () {
                    return rowItem.getOriginalCostPerVolume();
                },
                function () {
                    return rowItem.getCostPerVolumeOverride();
                })
            .overridePermission(function () {
                return true;
            })
            .disableUndoOpCreation()
            .addValidation(new ValidationEngine(rowPath + '.' + config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILCOSTPERVOLUME)), rowPath);

        // Utilisation
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILUTILISATION, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.HOURSPERMONTH)
            .setter(function (newValue) {
                rowItem.setUtilisation(newValue);
            })
            .getter(function () {
                return rowItem.getUtilisation();
            })
            .disableUndoOpCreation()
            .addValidation(new ValidationEngine(rowPath + '.' + config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILUTILISATION)), rowPath);
    }
}

function createObservablesForSaveAccessoryModal(path, item) {
    // Description
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SAVEACCESSORYDESCRIPTION, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
        .setter(function (newValue) {
            item.setDescription(newValue)
        })
        .getter(function () {
            return item.getDescription();
        }), path);

    // Save to library
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SAVEACCESSORYACCESSLEVEL, config.INPUT_TYPE.RADIO, config.INCREMENT_TYPE.NONE)
        .addSubscription(function (val) {
            if (val === config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY) {
                var rollbackUpdate = false;
                //if (globals.user.isTrialUser() && !globals.user.isVipTrialUser() || globals.user.getCompanyName() === config.trialRegistrationCompanyNameDefault) {
                //    rollbackUpdate = true;
                //    app.showDialog(new CustomMessageBox(config.getMessageText('1591', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1591')));
                //} else {
                //    if (!globals.user.isUserAllowedFullAccessToTeamLibrary()) {
                //        rollbackUpdate = true;
                //        app.showDialog(new CustomMessageBox(config.getMessageText('4493', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271'), config.getTranslationText('65')], config.getMessageReference('4493')))
                //            .then(shell.handleEssentialsMessageResponse);
                //    }
                //}
                
                if (!globals.user.isUserAllowedFullAccessToTeamLibrary()) {
                    rollbackUpdate = true;
                    var messageCode = '4522';
                    if (item.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
                        messageCode = '4521';
                    } else if (item.getAccessoryType() === config.ACCESSORY_TYPES.TRAILER) {
                        messageCode = '4524';
                    } else if (item.getAccessoryType() === config.ACCESSORY_TYPES.PAYLOAD) {
                        messageCode = '4523';
                    }
                    nextTick(function(){
                        globals.showDialog(new CustomMessageBox(config.getMessageText(messageCode, globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271'), config.getTranslationText('65')], config.getMessageReference(messageCode)).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                        .addCallback(function(dialogResult){
                            nextTick(function(){
                                bus.emit('handleEssentialsMessageResponse', { clickedButtonText: dialogResult, source: config.ESSENTIALS_CHECK_LOCATION.SAVE_ACCESSORY_TEAM_LIBRARY, modalDisplayMode: config.MODAL_DISPLAY_MODE.OVERLAY });
                            });
                            // setTimeout(function(){
                            //     bus.emit('handleEssentialsMessageResponse', { clickedButtonText: dialogResult, source: config.ESSENTIALS_CHECK_LOCATION.SAVE_ACCESSORY_TEAM_LIBRARY, modalDisplayMode: config.MODAL_DISPLAY_MODE.OVERLAY });
                            // }, 100);
                        });
                    })
                }

                if (!rollbackUpdate) {
                    item.setAccessLevel(val);
                } else {
                    nextTick(function(){
                        getAttributeGroup(path + '.' + config.VALUE_TYPE.SAVEACCESSORYACCESSLEVEL).value.value = item.getAccessLevel();
                    });
                }
            } else {
                item.setAccessLevel(val);
            }
        })
        .setter(function (newValue) {
            item.setAccessLevel(newValue)
        })
        .getter(function () {
            return item.getAccessLevel();
        }), path);


    // Save to public library
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SAVEACCESSORYPUBLISHTOPUBLICLIBRARY, config.INPUT_TYPE.SELECT)
        .setVal(item.getPublicEditLevelForSelect())
        .addSubscription(function (val) {
            item.setPublicPublishEditLevel(val);
        })
        .addOverrideMechanism(function () {
            return {};
        },
            function (val) {
                item.setPublishToPublicLibrary(val);
                refreshUILiveLabels.value = new Date().getTime();
            },
            function () {
                return {
                    id: config.ITEM_EDIT_LEVEL_OPTIONS.NO_EDIT
                };
            },
            function () {
                return item.getPublishToPublicLibrary();
            })
        .overridePermission(function () {
            return true;
        })
        .disableUndoOpCreation(), path);

        // Save to public library
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SAVEACCESSORYPUBLISHTODISTRIBUTORLIBRARY, config.INPUT_TYPE.SELECT)
        .setVal(item.getDistributorEditLevelForSelect())
        .addSubscription(function (val) {
            item.setDistributorPublishEditLevel(val);
        })
        .addOverrideMechanism(function () {
            return {};
        },
            function (val) {
                item.setPublishToDistributorLibrary(val);
                refreshUILiveLabels.value = new Date().getTime();
            },
            function () {
                return {
                    id: config.ITEM_EDIT_LEVEL_OPTIONS.NO_EDIT
                };
            },
            function () {
                return item.getPublishToDistributorLibrary();
            })
        .overridePermission(function () {
            return true;
        })
        .disableUndoOpCreation(), path);

    
}

//function createInternalStandardsObservablesForSettingsModal(path, item, incrementType, valueTypeMin, valueTypeMax, genericValidationType) {
function createInternalStandardsObservablesForSettingsModal(path, item, incrementType, genericValidationType) {
    // Minimum
    //addAttributeGroup(new AttributeObservableGroup(valueTypeMin, config.INPUT_TYPE.TEL, incrementType)
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.INTERNALSTANDARDMIN, config.INPUT_TYPE.TEL, incrementType)
        .setter(function (newValue) {
            item.setRangeMin(newValue);
        })
        .getter(function () {
            return item.getRangeMin();
        })
        //.disableUndoOpCreation()
        //.addValidation(new ValidationEngine(genericValidationType, config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_AND_OPS)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.INTERNALSTANDARDMIN)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if (typeof item.getRangeMax() === 'number' && item.getRangeMax() < newValue) {
                    throw 1;
                }
            })
            .addCustomValidationConfig(genericValidationType)
            ), path);

    // Maximum
    //addAttributeGroup(new AttributeObservableGroup(valueTypeMin, config.INPUT_TYPE.TEL, incrementType)
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.INTERNALSTANDARDMAX, config.INPUT_TYPE.TEL, incrementType)
        .setter(function (newValue) {
            item.setRangeMax(newValue);
        })
        .getter(function () {
            return item.getRangeMax();
        })
        //.disableUndoOpCreation()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.INTERNALSTANDARDMAX)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if (typeof item.getRangeMin() === 'number' && item.getRangeMin() > newValue) {
                    throw 1;
                }
            })
            //.addCustomValidationConfig(new ValidationEngine(genericValidationType, config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_AND_OPS))
            .addCustomValidationConfig(genericValidationType)
            ), path);

    
}

function createOtherObjectObservables(other, path) {

    var chassisObjectToUse = rigOps.getChassisObjectFromPath(path),
        originalChassisObjectToUse = rigOps.getOriginalChassisObjectFromPath(path);

    path = path + '.' + other.getId();

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.OTHERDESCRIPTION, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
        .setter(function (newValue) {
            other.setDescription(newValue)
        })
        .getter(function () {
            return other.getDescription();
        })
        .visiblePermission(getObjectVisibilityPermission(other))
        .readOnlyPermission(getObjectReadOnlyPermission(other, config.MENU_SECTION.SPECIFICATION))
        //.visiblePermission(function () { return globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code); })
        .addSubscription(function (val) {
            if (other.getDescription() !== val) {
                var intercomObject = {};
                intercomObject[config.INTERCOM_METADATA_ITEM.OLD_DESCRIPTION] = other.getDescription();
                intercomObject[config.INTERCOM_METADATA_ITEM.NEW_DESCRIPTION] = val;
                dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.RENAMED_OTHER_EQUIPMENT, intercomObject));
            }
            other.setDescription(val);
            updateAccessoryMenuLabelCallback(val, path, other.getAccessoryType());
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.OTHERDESCRIPTION)
            .addOpBlock(function (newValue, oldValue) {
                if (newValue === '') {
                    throw 1;
                }
            })
        ), path);

    //Dimensions
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.OTHERCABGAP, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return other.getHorizontalGap();
        })
        .setter(function (newValue) {
            other.setHorizontalGap(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(other, function () {
            return chassisObjectToUse.getChassisObjectName() !== config.CHASSIS_OBJECT_TYPES.VEHICLE || (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && globals.user.getSpecifyEquipmentPositionAs() === config.SPECIFY_POSITION_AS_OPTIONS.FROM_BACK_OF_CAB);
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(other, config.MENU_SECTION.POSITION))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.OTHERCABGAP)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                other.setFromAxle1Front(rigOps.calculateFromAxle1Front(other));
            })
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.GAPBETWEENVEHICLEANDTRAILER, rigOps.getVehicleAndDrawbarGapBetweenVehicleAndTrailer)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTLENGTH, getChassisObjectOverallLength)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.CHASSISOBJECTREAROVERHANGTOTAL)
        ), path);


    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.OTHERFROMAXLE1FRONT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return other.getFromAxle1Front();
        })
        .setter(function (newValue) {
            other.setFromAxle1Front(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(other, function (){
            return globals.user.getSpecifyEquipmentPositionAs() === config.SPECIFY_POSITION_AS_OPTIONS.FROM_FRONTMOST_AXLE && chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE;
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(other, config.MENU_SECTION.POSITION))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.OTHERFROMAXLE1FRONT)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                other.setHorizontalGap(rigOps.calculateFromBackOfCab(other));
            })
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.GAPBETWEENVEHICLEANDTRAILER, rigOps.getVehicleAndDrawbarGapBetweenVehicleAndTrailer)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTLENGTH, getChassisObjectOverallLength)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.CHASSISOBJECTREAROVERHANGTOTAL)
        )
        .setAllowOpsWhileDragging(true), path);

    var otherAboveBelowChassisAOG = new AttributeObservableGroup(config.VALUE_TYPE.OTHERABOVEBELOWCHASSIS, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return other.getVerticalGap();
        })
        .setter(function (newValue) {
            other.setVerticalGap(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(other))
        .readOnlyPermission(getObjectReadOnlyPermission(other, config.MENU_SECTION.POSITION));

    var otherAboveBelowChassisValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.OTHERABOVEBELOWCHASSIS);
    if (!globals.user.isLoadingAdministrator()) {
        otherAboveBelowChassisValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, getChassisObjectOverallHeight);
    }
    otherAboveBelowChassisAOG.addValidation(otherAboveBelowChassisValidation);
    addAttributeGroup(otherAboveBelowChassisAOG, path);



    let otherFRomLeftOfVehicleRearWidthAOG = new AttributeObservableGroup(config.VALUE_TYPE.OTHERFROMLEFTOFVEHICLEREARWIDTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return other.getFromLeftOfVehicleRearWidth();
        })
        .setter(function (newValue) {
            other.setFromLeftOfVehicleRearWidth(newValue);
        })
        .visiblePermission(hideAttribute)
        .readOnlyPermission(getObjectReadOnlyPermission(other, config.MENU_SECTION.POSITION))
        

    let otherFromLeftOfVehicleRearWidthAOGValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.OTHERFROMLEFTOFVEHICLEREARWIDTH);
    otherFromLeftOfVehicleRearWidthAOGValidation.addOpBlock(function (newValue, oldValue, deltaValue){
        other.setFromCentreOfVehicle(rigOps.calculateFromCentreOfChassisForFromLeftOfVehicleRearWidth(other, path));
    });
    if(!globals.user.isLoadingAdministrator()) {
        otherFromLeftOfVehicleRearWidthAOGValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.COMBINATIONOVERALLWIDTH, rigOps.getCombinationOverallWidth);
    }
    otherFRomLeftOfVehicleRearWidthAOG.addValidation(otherFromLeftOfVehicleRearWidthAOGValidation);
    addAttributeGroup(otherFRomLeftOfVehicleRearWidthAOG, path);



    let otherFromCentreOfVehicleAOG = new AttributeObservableGroup(config.VALUE_TYPE.OTHERFROMCENTREOFVEHICLE, config.INPUT_TYPE.UPDOWNBOX)
    .getter(function () {
        return other.getFromCentreOfVehicle();
    })
    .setter(function (newValue) {
        other.setFromCentreOfVehicle(newValue);
    })
    .visiblePermission(getObjectVisibilityPermission(other))
    .readOnlyPermission(getObjectReadOnlyPermission(other, config.MENU_SECTION.POSITION))
    
        //.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, getChassisObjectOverallHeight)
    
    let otherFromCentreOfVehicleAOGValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.OTHERFROMCENTREOFVEHICLE);
    otherFromCentreOfVehicleAOGValidation.addOpBlock(function (newValue, oldValue, deltaVal) {
        other.setFromLeftOfVehicleRearWidth(rigOps.calculateFromLeftOfChassisRearWidth(other, path));
    });

    if(!globals.user.isLoadingAdministrator()) {
        otherFromCentreOfVehicleAOGValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.COMBINATIONOVERALLWIDTH, rigOps.getCombinationOverallWidth);
    }
    otherFromCentreOfVehicleAOG.addValidation(otherFromCentreOfVehicleAOGValidation);
    addAttributeGroup(otherFromCentreOfVehicleAOG, path);


    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.OTHERLENGTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return other.getLength();
        })
        .setter(function (newValue) {
            other.setLength(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(other))
        .readOnlyPermission(getObjectReadOnlyPermission(other, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.OTHERLENGTH)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.GAPBETWEENVEHICLEANDTRAILER, rigOps.getVehicleAndDrawbarGapBetweenVehicleAndTrailer)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTLENGTH, getChassisObjectOverallLength)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.CHASSISOBJECTREAROVERHANGTOTAL)
        ), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.OTHERHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return other.getHeight();
        })
        .setter(function (newValue) {
            other.setHeight(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(other))
        .readOnlyPermission(getObjectReadOnlyPermission(other, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.OTHERHEIGHT)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, getChassisObjectOverallHeight)
        ), path);




    let otherWidthAOG = new AttributeObservableGroup(config.VALUE_TYPE.OTHERWIDTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return other.getWidth();
        })
        .setter(function (newValue) {
            other.setWidth(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(other))
        .readOnlyPermission(getObjectReadOnlyPermission(other, config.MENU_SECTION.SPECIFICATION_DIMENSIONS));
        

    let otherWidthAOGValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.OTHERWIDTH); 
    otherWidthAOGValidation.addOpBlock(function (newValue, oldValue, deltaVal) {
        other.setFromLeftOfVehicleRearWidth(rigOps.calculateFromLeftOfChassisRearWidth(other, path));
    });
    if(!globals.user.isLoadingAdministrator()) {
        otherWidthAOGValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.COMBINATIONOVERALLWIDTH, rigOps.getCombinationOverallWidth);
    }
    otherWidthAOG.addValidation(otherWidthAOGValidation);
    addAttributeGroup(otherWidthAOG, path);


    //Weights
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.OTHERMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(function () {
            return other.getMass();
        })
        .setter(function (newValue) {
            other.setMass(newValue);
        })
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                other.setMassOverride(val);
            },
            function () {
                return originalChassisObjectToUse.getAccessoryHolder().getAccessoryById(other.getId()).getMass();
            },
            function () {
                return other.getMassOverride();
            })
        //.visiblePermission(function (thePath) { return isTemplateOrCustomOther(thePath, 2); })
        .visiblePermission(getObjectVisibilityPermission(other))
        .readOnlyPermission(getObjectReadOnlyPermission(other, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .overridePermission(function (attrPath) {
            return showOverrideForStandardOther(attrPath, 2);
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.OTHERMASS)), path);


    var cogTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === other.getCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (other.getCOGType() === '' || other.getCOGType() === undefined || other.getCOGType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.OTHERCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getItemHorizontalCOGTypeOption(other))
        .optionGetter(function () {
            return getItemHorizontalCOGTypeOption(other);
        })
        //.visiblePermission(function (attrPath) {
        //    return showUnlessStandardOtherAndNotAdmin(attrPath, 2);
        //})
        .visiblePermission(getObjectVisibilityPermission(other))
        .readOnlyPermission(getObjectReadOnlyPermission(other, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .addSubscription(function (val) {
            if (val !== other.getCOGType()) {
                other.setCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    other.setHorizontalCOG(other.getLength() * other.getHorizontalCOG() / 100);
                } else {
                    other.setHorizontalCOG(utils.returnValueOrZeroIfNanOrInfinity((other.getHorizontalCOG() / other.getLength()) * 100));
                }
            }
        }), path);


    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.OTHERHORIZONTALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return other.getHorizontalCOG();
        })
        .setter(function (newValue) {
            other.setHorizontalCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                other.setHorizontalCOGOverride(val);
            },
            function () {
                //return originalChassisObjectToUse.getAccessoryHolder().getAccessoryById(other.getId()).getHorizontalCOG();
                var originalOther = originalChassisObjectToUse.getAccessoryHolder().getAccessoryById(other.getId());
                if (other.getCOGType() === config.COG_TYPES.LENGTH && originalOther.getCOGType() === config.COG_TYPES.LENGTH || other.getCOGType() === config.COG_TYPES.PERCENTAGE && originalOther.getCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalOther.getHorizontalCOG();
                } else if (other.getCOGType() === config.COG_TYPES.LENGTH && originalOther.getCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalOther.getHorizontalCOG() * other.getLength() / 100;
                } else if (other.getCOGType() === config.COG_TYPES.PERCENTAGE && originalOther.getCOGType() === config.COG_TYPES.LENGTH) {
                    return originalOther.getHorizontalCOG() / other.getLength() * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return other.getHorizontalCOGOverride();
            })
        //.visiblePermission(function (thePath) { return isTemplateOrCustomOther(thePath, 2); })
        //.visiblePermission(function (attrPath) {
        //    return showUnlessStandardOtherAndNotAdmin(attrPath, 2);
        //})
        .visiblePermission(getObjectVisibilityPermission(other))
        .readOnlyPermission(getObjectReadOnlyPermission(other, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .overridePermission(function (attrPath) {
            return showOverrideForStandardOther(attrPath, 2);
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.OTHERHORIZONTALCOG))
        .addFormattingRule(function () {
            return other.getCOGType() === config.COG_TYPES.PERCENTAGE;
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);



    var verticalCOGTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === other.getVerticalCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (other.getVerticalCOGType() === '' || other.getVerticalCOGType() === undefined || other.getVerticalCOGType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.OTHERVERTICALCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getItemVerticalCOGTypeOption(other))
        .optionGetter(function () {
            return getItemVerticalCOGTypeOption(other);
        })
        //.visiblePermission(function (attrPath) {
        //    return showUnlessStandardOtherAndNotAdmin(attrPath, 2);
        //})
        .visiblePermission(getObjectVisibilityPermission(other))
        .readOnlyPermission(getObjectReadOnlyPermission(other, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .addSubscription(function (val) {
            if (val !== other.getVerticalCOGType()) {
                other.setVerticalCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    other.setVerticalCOG(other.getHeight() * other.getVerticalCOG() / 100);
                } else {
                    other.setVerticalCOG(utils.returnValueOrZeroIfNanOrInfinity((other.getVerticalCOG() / other.getHeight()) * 100));
                }
            }
        }), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.OTHERVERTICALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return other.getVerticalCOG();
        })
        .setter(function (newValue) {
            other.setVerticalCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                other.setVerticalCOGOverride(val);
            },
            function () {
                //return originalChassisObjectToUse.getAccessoryHolder().getAccessoryById(other.getId()).getVerticalCOG();
                var originalOther = originalChassisObjectToUse.getAccessoryHolder().getAccessoryById(other.getId());
                if (other.getVerticalCOGType() === config.COG_TYPES.LENGTH && originalOther.getVerticalCOGType() === config.COG_TYPES.LENGTH || other.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE && originalOther.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalOther.getVerticalCOG();
                } else if (other.getVerticalCOGType() === config.COG_TYPES.LENGTH && originalOther.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalOther.getVerticalCOG() * other.getHeight() / 100;
                } else if (other.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE && originalOther.getVerticalCOGType() === config.COG_TYPES.LENGTH) {
                    return originalOther.getVerticalCOG() / other.getHeight() * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return other.getVerticalCOGOverride();
            })
        .overridePermission(function (attrPath) {
            return showOverrideForStandardOther(attrPath, 2);
        })
        //.visiblePermission(function (thePath) { return isTemplateOrCustomOther(thePath, 2); })
        //.visiblePermission(function (attrPath) {
        //    return showUnlessStandardOtherAndNotAdmin(attrPath, 2);
        //})
        .visiblePermission(getObjectVisibilityPermission(other))
        .readOnlyPermission(getObjectReadOnlyPermission(other, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.OTHERVERTICALCOG))
        .addFormattingRule(function () {
            return other.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE;
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);



    var lateralCOGTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === other.getLateralCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (other.getLateralCOGType() === '' || other.getLateralCOGType() === undefined || other.getLateralCOGType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.OTHERLATERALCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getItemLateralCOGTypeOption(other))
        .optionGetter(function () {
            return getItemLateralCOGTypeOption(other);
        })
        //.visiblePermission(function (attrPath) {
        //    return showUnlessStandardOtherAndNotAdmin(attrPath, 2);
        //})
        .visiblePermission(getObjectVisibilityPermission(other))
        .readOnlyPermission(getObjectReadOnlyPermission(other, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .addSubscription(function (val) {
            if (val !== other.getLateralCOGType()) {
                other.setLateralCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    other.setLateralCOG(other.getWidth() * other.getLateralCOG() / 100);
                } else {
                    other.setLateralCOG(utils.returnValueOrZeroIfNanOrInfinity((other.getLateralCOG() / other.getWidth()) * 100));
                }
            }
        }), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.OTHERLATERALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return other.getLateralCOG();
        })
        .setter(function (newValue) {
            other.setLateralCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                other.setLateralCOGOverride(val);
            },
            function () {
                //return originalChassisObjectToUse.getAccessoryHolder().getAccessoryById(other.getId()).getLateralCOG();
                var originalOther = originalChassisObjectToUse.getAccessoryHolder().getAccessoryById(other.getId());
                if (other.getLateralCOGType() === config.COG_TYPES.LENGTH && originalOther.getLateralCOGType() === config.COG_TYPES.LENGTH || other.getLateralCOGType() === config.COG_TYPES.PERCENTAGE && originalOther.getLateralCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalOther.getLateralCOG();
                } else if (other.getLateralCOGType() === config.COG_TYPES.LENGTH && originalOther.getLateralCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalOther.getLateralCOG() * other.getWidth() / 100;
                } else if (other.getLateralCOGType() === config.COG_TYPES.PERCENTAGE && originalOther.getLateralCOGType() === config.COG_TYPES.LENGTH) {
                    return originalOther.getLateralCOG() / other.getWidth() * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return other.getLateralCOGOverride();
            })
        .overridePermission(function (attrPath) {
            return showOverrideForStandardOther(attrPath, 2);
        })
        //.visiblePermission(function (thePath) { return isTemplateOrCustomOther(thePath, 2); })
        //.visiblePermission(function (attrPath) {
        //    return showUnlessStandardOtherAndNotAdmin(attrPath, 2);
        //})
        .visiblePermission(getObjectVisibilityPermission(other))
        .readOnlyPermission(getObjectReadOnlyPermission(other, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.OTHERLATERALCOG))
        .addFormattingRule(function () {
            return other.getLateralCOGType() === config.COG_TYPES.PERCENTAGE
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);


    function getChassisObjectToUseFunc() {
        return chassisObjectToUse;
    }

    function getChassisObjectOverallLength() {
        return rigOps.getOverallChassisObjectLengthLegal(path);
    }

    function getChassisObjectOverallHeight() {
        return rigOps.getChassisObjectOverallHeight(path);
    }

}

function createHookliftObjectObservables(hooklift, path) {

    var chassisObjectToUse = rigOps.getChassisObjectFromPath(path),
        originalChassisObjectToUse = rigOps.getOriginalChassisObjectFromPath(path);

    //path = path + '.' + hooklift.getId();

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HOOKLIFTDESCRIPTION, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
        .setter(function (newValue) {
            hooklift.setDescription(newValue)
        })
        .getter(function () {
            return hooklift.getDescription();
        })
        .visiblePermission(getObjectVisibilityPermission(hooklift))
        .readOnlyPermission(getObjectReadOnlyPermission(hooklift, config.MENU_SECTION.SPECIFICATION))
        .addSubscription(function (val) {
            //if (hooklift.getDescription() !== val) {
            //    var intercomObject = {};
            //    intercomObject[config.INTERCOM_METADATA_ITEM.OLD_DESCRIPTION] = hooklift.getDescription();
            //    intercomObject[config.INTERCOM_METADATA_ITEM.NEW_DESCRIPTION] = val;
            //    dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.RENAMED_HOOKLIFT_EQUIPMENT, intercomObject));
            //}
            hooklift.setDescription(val);
            updateAccessoryMenuLabelCallback(val, path, hooklift.getAccessoryType());
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HOOKLIFTDESCRIPTION)
            .addOpBlock(function (newValue, oldValue) {
                if (newValue === '') {
                    throw 1;
                }
            })
        ), path);

    //Dimensions 
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HOOKLIFTCABGAP, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hooklift.getHorizontalGap();
        })
        .setter(function (newValue) {
            hooklift.setHorizontalGap(newValue);
        })
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                hooklift.setCabGapOverride(val);
                hooklift.setFromAxle1FrontOverride(val);
            },
            function () {
                return originalChassisObjectToUse.getAccessoryHolder().getAccessoryById(hooklift.getId()).getHorizontalGap();
            },
            function () {
                return hooklift.getCabGapOverride();
            }, function () {
                hooklift.setFromAxle1Front(rigOps.calculateFromAxle1Front(hooklift));
            }, true)
        .overridePermission(function (attrPath) {
            //return showCabGapOverrideForHooklift(attrPath);
            return true;
        })
        .visiblePermission(getObjectVisibilityPermission(hooklift, function () {
            return chassisObjectToUse.getChassisObjectName() !== config.CHASSIS_OBJECT_TYPES.VEHICLE || (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && globals.user.getSpecifyEquipmentPositionAs() === config.SPECIFY_POSITION_AS_OPTIONS.FROM_BACK_OF_CAB);
        }))
        //.readOnlyPermission(getObjectReadOnlyPermission(hooklift, config.MENU_SECTION.POSITION))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HOOKLIFTCABGAP)
            .addOpBlock(function () {
                if (hooklift.getCabGapOverride() === false) {
                    hooklift.setCabGapOverride(true);
                }
                if (hooklift.getFromAxle1FrontOverride() === false) {
                    hooklift.setFromAxle1FrontOverride(true);
                }
                hooklift.setFromAxle1Front(rigOps.calculateFromAxle1Front(hooklift));
            })
        ), path);


    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HOOKLIFTFROMAXLE1FRONT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hooklift.getFromAxle1Front();
        })
        .setter(function (newValue) {
            hooklift.setFromAxle1Front(newValue);
        })
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                hooklift.setFromAxle1FrontOverride(val);
                hooklift.setCabGapOverride(val);
            },
            function () {
                //return chassisObjectToUse.getBumperToBackOfCab() - chassisObjectToUse.getFrontOverhang();
                return getDefaultFromAxle1Front(hooklift);
            },
            function () {
                return hooklift.getFromAxle1FrontOverride();
            },
            function () {
                hooklift.setHorizontalGap(rigOps.calculateFromBackOfCab(hooklift));
            }, true)
        .overridePermission(function () {
            return true;
        })
        .visiblePermission(getObjectVisibilityPermission(hooklift, function () {
            return globals.user.getSpecifyEquipmentPositionAs() === config.SPECIFY_POSITION_AS_OPTIONS.FROM_FRONTMOST_AXLE && chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE;
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(hooklift, config.MENU_SECTION.POSITION))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HOOKLIFTFROMAXLE1FRONT)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if (hooklift.getCabGapOverride() === false) {
                    hooklift.setCabGapOverride(true);
                }
                if (hooklift.getFromAxle1FrontOverride() === false) {
                    hooklift.setFromAxle1FrontOverride(true);
                }
                hooklift.setHorizontalGap(rigOps.calculateFromBackOfCab(hooklift));
            })
        )
        .setAllowOpsWhileDragging(true), path);

    var hookliftAboveBelowChassisAOG = new AttributeObservableGroup(config.VALUE_TYPE.HOOKLIFTABOVEBELOWCHASSIS, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hooklift.getVerticalGap();
        })
        .setter(function (newValue) {
            hooklift.setVerticalGap(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(hooklift))
        .readOnlyPermission(getObjectReadOnlyPermission(hooklift, config.MENU_SECTION.POSITION));

    var hookliftAboveBelowChassisValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.HOOKLIFTABOVEBELOWCHASSIS);
    if (!globals.user.isLoadingAdministrator()) {
        hookliftAboveBelowChassisValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, getChassisObjectOverallHeight);
    }
    hookliftAboveBelowChassisAOG.addValidation(hookliftAboveBelowChassisValidation);
    addAttributeGroup(hookliftAboveBelowChassisAOG, path);



    let hookliftFromLeftOfVehicleRearWidthAOG = new AttributeObservableGroup(config.VALUE_TYPE.HOOKLIFTFROMLEFTOFVEHICLEREARWIDTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hooklift.getFromLeftOfVehicleRearWidth();
        })
        .setter(function (newValue) {
            hooklift.setFromLeftOfVehicleRearWidth(newValue);
        })
        .visiblePermission(hideAttribute)
        .readOnlyPermission(getObjectReadOnlyPermission(hooklift, config.MENU_SECTION.POSITION))
        

    let hookliftFromLeftOfVehicleRearWidthAOGValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.HOOKLIFTFROMLEFTOFVEHICLEREARWIDTH);
    hookliftFromLeftOfVehicleRearWidthAOGValidation.addOpBlock(function (newValue, oldValue, deltaValue){
        hooklift.setFromCentreOfVehicle(rigOps.calculateFromCentreOfChassisForFromLeftOfVehicleRearWidth(hooklift, path));
    });
    if(!globals.user.isLoadingAdministrator()) {
        hookliftFromLeftOfVehicleRearWidthAOGValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.COMBINATIONOVERALLWIDTH, rigOps.getCombinationOverallWidth);
    }
    hookliftFromLeftOfVehicleRearWidthAOG.addValidation(hookliftFromLeftOfVehicleRearWidthAOGValidation);
    addAttributeGroup(hookliftFromLeftOfVehicleRearWidthAOG, path);


    let hookliftFromCentreOfVehicleAOG = new AttributeObservableGroup(config.VALUE_TYPE.HOOKLIFTFROMCENTREOFVEHICLE, config.INPUT_TYPE.UPDOWNBOX)
    .getter(function () {
        return hooklift.getFromCentreOfVehicle();
    })
    .setter(function (newValue) {
        hooklift.setFromCentreOfVehicle(newValue);
    })
    .visiblePermission(getObjectVisibilityPermission(hooklift))
    .readOnlyPermission(getObjectReadOnlyPermission(hooklift, config.MENU_SECTION.POSITION))
    
        //.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, getChassisObjectOverallHeight)
    
    let hookliftFromCentreOfVehicleAOGValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.HOOKLIFTFROMCENTREOFVEHICLE);
    hookliftFromCentreOfVehicleAOGValidation.addOpBlock(function (newValue, oldValue, deltaVal) {
        hooklift.setFromLeftOfVehicleRearWidth(rigOps.calculateFromLeftOfChassisRearWidth(hooklift, path));
    });

    if(!globals.user.isLoadingAdministrator()) {
        hookliftFromCentreOfVehicleAOGValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.COMBINATIONOVERALLWIDTH, rigOps.getCombinationOverallWidth);
    }
    hookliftFromCentreOfVehicleAOG.addValidation(hookliftFromCentreOfVehicleAOGValidation);
    addAttributeGroup(hookliftFromCentreOfVehicleAOG, path);



    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HOOKLIFTLENGTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hooklift.getLength();
        })
        .setter(function (newValue) {
            hooklift.setLength(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(hooklift))
        .readOnlyPermission(getObjectReadOnlyPermission(hooklift, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HOOKLIFTLENGTH)
        ), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HOOKLIFTHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hooklift.getHeight();
        })
        .setter(function (newValue) {
            hooklift.setHeight(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(hooklift))
        .readOnlyPermission(getObjectReadOnlyPermission(hooklift, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HOOKLIFTHEIGHT)
        ), path);



    let hookliftWidthAOG = new AttributeObservableGroup(config.VALUE_TYPE.HOOKLIFTWIDTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hooklift.getWidth();
        })
        .setter(function (newValue) {
            hooklift.setWidth(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(hooklift))
        .readOnlyPermission(getObjectReadOnlyPermission(hooklift, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        

    let hookliftWidthAOGValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.HOOKLIFTWIDTH);
    hookliftWidthAOGValidation.addOpBlock(function (newValue, oldValue, deltaVal) {
        hooklift.setFromLeftOfVehicleRearWidth(rigOps.calculateFromLeftOfChassisRearWidth(hooklift, path));
    });
    if(!globals.user.isLoadingAdministrator()) {
        hookliftWidthAOGValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.COMBINATIONOVERALLWIDTH, rigOps.getCombinationOverallWidth);
    }
    hookliftWidthAOG.addValidation(hookliftWidthAOGValidation);
    addAttributeGroup(hookliftWidthAOG, path);

    //Weights
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HOOKLIFTMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(function () {
            return hooklift.getMass();
        })
        .setter(function (newValue) {
            hooklift.setMass(newValue);
        })
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                hooklift.setMassOverride(val);
            },
            function () {
                return originalChassisObjectToUse.getAccessoryHolder().getAccessoryById(hooklift.getId()).getMass();
            },
            function () {
                return hooklift.getMassOverride();
            })
        .visiblePermission(getObjectVisibilityPermission(hooklift))
        .readOnlyPermission(getObjectReadOnlyPermission(hooklift, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .overridePermission(function (attrPath) {
            return showOverrideForStandardHooklift(attrPath);
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HOOKLIFTMASS)), path);

    var cogTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === hooklift.getCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (hooklift.getCOGType() === '' || hooklift.getCOGType() === undefined || hooklift.getCOGType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HOOKLIFTCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getItemHorizontalCOGTypeOption(hooklift))
        .optionGetter(function () {
            return getItemHorizontalCOGTypeOption(hooklift);
        })
        .visiblePermission(getObjectVisibilityPermission(hooklift))
        .readOnlyPermission(getObjectReadOnlyPermission(hooklift, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .addSubscription(function (val) {
            if (val !== hooklift.getCOGType()) {
                hooklift.setCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    hooklift.setHorizontalCOG(hooklift.getLength() * hooklift.getHorizontalCOG() / 100);
                } else {
                    hooklift.setHorizontalCOG(utils.returnValueOrZeroIfNanOrInfinity((hooklift.getHorizontalCOG() / hooklift.getLength()) * 100));
                }
            }
        }), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HOOKLIFTHORIZONTALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hooklift.getHorizontalCOG();
        })
        .setter(function (newValue) {
            hooklift.setHorizontalCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                hooklift.setHorizontalCOGOverride(val);
            },
            function () {
                var originalOther = originalChassisObjectToUse.getAccessoryHolder().getAccessoryById(hooklift.getId());
                if (hooklift.getCOGType() === config.COG_TYPES.LENGTH && originalOther.getCOGType() === config.COG_TYPES.LENGTH || hooklift.getCOGType() === config.COG_TYPES.PERCENTAGE && originalOther.getCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalOther.getHorizontalCOG();
                } else if (hooklift.getCOGType() === config.COG_TYPES.LENGTH && originalOther.getCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalOther.getHorizontalCOG() * hooklift.getLength() / 100;
                } else if (hooklift.getCOGType() === config.COG_TYPES.PERCENTAGE && originalOther.getCOGType() === config.COG_TYPES.LENGTH) {
                    return originalOther.getHorizontalCOG() / hooklift.getLength() * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return hooklift.getHorizontalCOGOverride();
            })
        .visiblePermission(getObjectVisibilityPermission(hooklift))
        .readOnlyPermission(getObjectReadOnlyPermission(hooklift, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .overridePermission(function (attrPath) {
            return showOverrideForStandardHooklift(attrPath, 2);
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HOOKLIFTHORIZONTALCOG))
        .addFormattingRule(function () {
            return hooklift.getCOGType() === config.COG_TYPES.PERCENTAGE;
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);

    var verticalCOGTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === hooklift.getVerticalCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (hooklift.getVerticalCOGType() === '' || hooklift.getVerticalCOGType() === undefined || hooklift.getVerticalCOGType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HOOKLIFTVERTICALCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getItemVerticalCOGTypeOption(hooklift))
        .optionGetter(function () {
            return getItemVerticalCOGTypeOption(hooklift);
        })
        .visiblePermission(getObjectVisibilityPermission(hooklift))
        .readOnlyPermission(getObjectReadOnlyPermission(hooklift, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .addSubscription(function (val) {
            if (val !== hooklift.getVerticalCOGType()) {
                hooklift.setVerticalCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    hooklift.setVerticalCOG(hooklift.getHeight() * hooklift.getVerticalCOG() / 100);
                } else {
                    hooklift.setVerticalCOG(utils.returnValueOrZeroIfNanOrInfinity((hooklift.getVerticalCOG() / hooklift.getHeight()) * 100));
                }
            }
        }), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HOOKLIFTVERTICALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hooklift.getVerticalCOG();
        })
        .setter(function (newValue) {
            hooklift.setVerticalCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                hooklift.setVerticalCOGOverride(val);
            },
            function () {
                var originalOther = originalChassisObjectToUse.getAccessoryHolder().getAccessoryById(hooklift.getId());
                if (hooklift.getVerticalCOGType() === config.COG_TYPES.LENGTH && originalOther.getVerticalCOGType() === config.COG_TYPES.LENGTH || hooklift.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE && originalOther.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalOther.getVerticalCOG();
                } else if (hooklift.getVerticalCOGType() === config.COG_TYPES.LENGTH && originalOther.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalOther.getVerticalCOG() * hooklift.getHeight() / 100;
                } else if (hooklift.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE && originalOther.getVerticalCOGType() === config.COG_TYPES.LENGTH) {
                    return originalOther.getVerticalCOG() / hooklift.getHeight() * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return hooklift.getVerticalCOGOverride();
            })
        .overridePermission(function (attrPath) {
            return showOverrideForStandardHooklift(attrPath, 2);
        })
        .visiblePermission(getObjectVisibilityPermission(hooklift))
        .readOnlyPermission(getObjectReadOnlyPermission(hooklift, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HOOKLIFTVERTICALCOG))
        .addFormattingRule(function () {
            return hooklift.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE;
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);

    var lateralCOGTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === hooklift.getLateralCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (hooklift.getLateralCOGType() === '' || hooklift.getLateralCOGType() === undefined || hooklift.getLateralCOGType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HOOKLIFTLATERALCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getItemLateralCOGTypeOption(hooklift))
        .optionGetter(function () {
            return getItemLateralCOGTypeOption(hooklift);
        })
        .visiblePermission(getObjectVisibilityPermission(hooklift))
        .readOnlyPermission(getObjectReadOnlyPermission(hooklift, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .addSubscription(function (val) {
            if (val !== hooklift.getLateralCOGType()) {
                hooklift.setLateralCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    hooklift.setLateralCOG(hooklift.getWidth() * hooklift.getLateralCOG() / 100);
                } else {
                    hooklift.setLateralCOG(utils.returnValueOrZeroIfNanOrInfinity((hooklift.getLateralCOG() / hooklift.getWidth()) * 100));
                }
            }
        }), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HOOKLIFTLATERALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hooklift.getLateralCOG();
        })
        .setter(function (newValue) {
            hooklift.setLateralCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                hooklift.setLateralCOGOverride(val);
            },
            function () {
                var originalOther = originalChassisObjectToUse.getAccessoryHolder().getAccessoryById(hooklift.getId());
                if (hooklift.getLateralCOGType() === config.COG_TYPES.LENGTH && originalOther.getLateralCOGType() === config.COG_TYPES.LENGTH || hooklift.getLateralCOGType() === config.COG_TYPES.PERCENTAGE && originalOther.getLateralCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalOther.getLateralCOG();
                } else if (hooklift.getLateralCOGType() === config.COG_TYPES.LENGTH && originalOther.getLateralCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalOther.getLateralCOG() * hooklift.getWidth() / 100;
                } else if (hooklift.getLateralCOGType() === config.COG_TYPES.PERCENTAGE && originalOther.getLateralCOGType() === config.COG_TYPES.LENGTH) {
                    return originalOther.getLateralCOG() / hooklift.getWidth() * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return hooklift.getLateralCOGOverride();
            })
        .overridePermission(function (attrPath) {
            return showOverrideForStandardHooklift(attrPath, 2);
        })
        .visiblePermission(getObjectVisibilityPermission(hooklift))
        .readOnlyPermission(getObjectReadOnlyPermission(hooklift, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HOOKLIFTLATERALCOG))
        .addFormattingRule(function () {
            return hooklift.getLateralCOGType() === config.COG_TYPES.PERCENTAGE
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HOOKLIFTAFRAME, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hooklift.getAFrame();
        })
        .setter(function (newValue) {
            hooklift.setAFrame(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(hooklift))
        .readOnlyPermission(getObjectReadOnlyPermission(hooklift, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HOOKLIFTAFRAME)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HOOKLIFTHOOKOFFSET, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hooklift.getHookOffset();
        })
        .setter(function (newValue) {
            hooklift.setHookOffset(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(hooklift))
        .readOnlyPermission(getObjectReadOnlyPermission(hooklift, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HOOKLIFTHOOKOFFSET)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HOOKLIFTCENTREOFROLLER, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hooklift.getCentreOfRoller();
        })
        .setter(function (newValue) {
            hooklift.setCentreOfRoller(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(hooklift))
        .readOnlyPermission(getObjectReadOnlyPermission(hooklift, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HOOKLIFTCENTREOFROLLER)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HOOKLIFTPLATFORMHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hooklift.getPlatformHeight();
        })
        .setter(function (newValue) {
            hooklift.setPlatformHeight(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(hooklift))
        .readOnlyPermission(getObjectReadOnlyPermission(hooklift, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HOOKLIFTPLATFORMHEIGHT)), path);

    function getChassisObjectToUseFunc() {
        return chassisObjectToUse;
    }

    function getChassisObjectOverallLength() {
        return rigOps.getOverallChassisObjectLengthLegal(path);
    }

    function getChassisObjectOverallHeight() {
        return rigOps.getChassisObjectOverallHeight(path);
    }
}

function createFridgeObjectObservables(fridge, path) {

    var chassisObjectToUse = rigOps.getChassisObjectFromPath(path),
        originalChassisObjectToUse = rigOps.getOriginalChassisObjectFromPath(path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FRIDGEDESCRIPTION, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
        .setter(function (newValue) {
            fridge.setDescription(newValue)
        })
        .getter(function () {
            return fridge.getDescription();
        })
        //.visiblePermission(function () { return globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code); })
        .visiblePermission(getObjectVisibilityPermission(fridge))
        .readOnlyPermission(getObjectReadOnlyPermission(fridge, config.MENU_SECTION.SPECIFICATION))
        .addSubscription(function (val) {
            fridge.setDescription(val);
            updateAccessoryMenuLabelCallback(val, path, fridge.getAccessoryType());
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FRIDGEDESCRIPTION)
            .addOpBlock(function (newValue, oldValue) {
                if (newValue === '') {
                    throw 1;
                }
            })
        ), path);

    //Dimensions
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FRIDGEMOUNTINGPOSITION, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return fridge.getMountingPosition();
        })
        .setter(function (newValue) {
            fridge.setMountingPosition(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(fridge))
        .readOnlyPermission(getObjectReadOnlyPermission(fridge, config.MENU_SECTION.POSITION))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FRIDGEMOUNTINGPOSITION)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FRIDGEFROMFRONTOFBODY, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return fridge.getFromFrontOfBody();
        })
        .setter(function (newValue) {
            fridge.setFromFrontOfBody(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(fridge))
        .readOnlyPermission(getObjectReadOnlyPermission(fridge, config.MENU_SECTION.POSITION))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FRIDGEFROMFRONTOFBODY)
            // .addOpBlock(function (newValue, oldValue, deltaVal) {
            //     fridge.setProtrusion(fridge.getProtrusion() + deltaVal);
            // })
        ), path);



        
    let fridgeFromLeftOfBodyWidthExternalAOG = new AttributeObservableGroup(config.VALUE_TYPE.FRIDGEFROMLEFTOFBODYWIDTHEXTERNAL, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return fridge.getFromLeftOfBodyWidthExternal();
        })
        .setter(function (newValue) {
            fridge.setFromLeftOfBodyWidthExternal(newValue);
        })
        .visiblePermission(hideAttribute)
        .readOnlyPermission(getObjectReadOnlyPermission(fridge, config.MENU_SECTION.POSITION))
            
    let fridgeFromLeftOfBodyWidthExternalAOGValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.FRIDGEFROMLEFTOFBODYWIDTHEXTERNAL);
    fridgeFromLeftOfBodyWidthExternalAOGValidation.addOpBlock(function (newValue, oldValue, deltaValue){
        fridge.setFromCentreOfVehicle(rigOps.calculateFromCentreOfChassisForFromLeftOfBodyWidthExternal(fridge, path));
    });
    if(!globals.user.isLoadingAdministrator()) {
        fridgeFromLeftOfBodyWidthExternalAOGValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.COMBINATIONOVERALLWIDTH, rigOps.getCombinationOverallWidth);
    }

    fridgeFromLeftOfBodyWidthExternalAOG.addValidation(fridgeFromLeftOfBodyWidthExternalAOGValidation);
    addAttributeGroup(fridgeFromLeftOfBodyWidthExternalAOG, path);



    let fridgeFromCentreOfVehicleAOG = new AttributeObservableGroup(config.VALUE_TYPE.FRIDGEFROMCENTREOFVEHICLE, config.INPUT_TYPE.UPDOWNBOX)
    .getter(function () {
        return fridge.getFromCentreOfVehicle();
    })
    .setter(function (newValue) {
        fridge.setFromCentreOfVehicle(newValue);
    })
    .visiblePermission(getObjectVisibilityPermission(fridge))
    .readOnlyPermission(getObjectReadOnlyPermission(fridge, config.MENU_SECTION.POSITION))
    
        //.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, getChassisObjectOverallHeight)
    
    let fridgeFromCentreOfVehicleAOGValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.FRIDGEFROMCENTREOFVEHICLE);
    fridgeFromCentreOfVehicleAOGValidation.addOpBlock(function (newValue, oldValue, deltaVal) {
        fridge.setFromLeftOfBodyWidthExternal(rigOps.calculateFromLeftOfBodyWidthExternal(fridge, path));
    });

    if(!globals.user.isLoadingAdministrator()) {
        fridgeFromCentreOfVehicleAOGValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.COMBINATIONOVERALLWIDTH, rigOps.getCombinationOverallWidth);
    }
    fridgeFromCentreOfVehicleAOG.addValidation(fridgeFromCentreOfVehicleAOGValidation);
    addAttributeGroup(fridgeFromCentreOfVehicleAOG, path);



    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FRIDGEPROTRUSION, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return fridge.getProtrusion();
        })
        .setter(function (newValue) {
            fridge.setProtrusion(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(fridge))
        .readOnlyPermission(getObjectReadOnlyPermission(fridge, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(isTemplateOrCustomFridgeWithoutGraphic)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FRIDGEPROTRUSION)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FRIDGEHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return fridge.getHeight();
        })
        .setter(function (newValue) {
            fridge.setHeight(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(fridge))
        .readOnlyPermission(getObjectReadOnlyPermission(fridge, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(isTemplateOrCustomFridgeWithoutGraphic)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FRIDGEHEIGHT)), path);



    let fridgeWidthAOG = new AttributeObservableGroup(config.VALUE_TYPE.FRIDGEWIDTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return fridge.getWidth();
        })
        .setter(function (newValue) {
            fridge.setWidth(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(fridge))
        .readOnlyPermission(getObjectReadOnlyPermission(fridge, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(isTemplateOrCustomFridgeWithoutGraphic)
        
    let fridgeWidthAOGValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.FRIDGEWIDTH)
    fridgeWidthAOGValidation.addOpBlock(function (newValue, oldValue, deltaVal) {
        fridge.setFromLeftOfBodyWidthExternal(rigOps.calculateFromLeftOfBodyWidthExternal(fridge, path));
    });
    if(!globals.user.isLoadingAdministrator()) {
        fridgeWidthAOGValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.COMBINATIONOVERALLWIDTH, rigOps.getCombinationOverallWidth);
    }
    fridgeWidthAOG.addValidation(fridgeWidthAOGValidation);
    addAttributeGroup(fridgeWidthAOG, path);

    //Weights
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FRIDGEMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(function () {
            return fridge.getMass();
        })
        .setter(function (newValue) {
            fridge.setMass(newValue);
        })
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                fridge.setMassOverride(val);
            },
            function () {
                return originalChassisObjectToUse.getAccessoryHolder().getAccessoryById(fridge.getId()).getMass();
            },
            function () {
                return fridge.getMassOverride();
            })
        .visiblePermission(getObjectVisibilityPermission(fridge))
        .readOnlyPermission(getObjectReadOnlyPermission(fridge, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessStandardFridgeAndNotAdmin)
        .overridePermission(showOverrideForStandardFridge)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FRIDGEMASS)), path);


    //Horizontal COG Type
    var cogTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === fridge.getCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (fridge.getCOGType() === '' || fridge.getCOGType() === undefined || fridge.getCOGType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FRIDGECOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getItemHorizontalCOGTypeOption(fridge))
        .optionGetter(function () {
            return getItemHorizontalCOGTypeOption(fridge);
        })
        .visiblePermission(getObjectVisibilityPermission(fridge))
        .readOnlyPermission(getObjectReadOnlyPermission(fridge, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessStandardFridgeAndNotAdmin)
        .addSubscription(function (val) {
            if (val !== fridge.getCOGType()) {
                fridge.setCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    fridge.setHorizontalCOG(fridge.getProtrusion() * fridge.getHorizontalCOG() / 100);
                } else {
                    fridge.setHorizontalCOG(utils.returnValueOrZeroIfNanOrInfinity((fridge.getHorizontalCOG() / fridge.getProtrusion()) * 100));
                }
            }
        }), path);

    //Horizontal COG
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FRIDGEHORIZONTALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return fridge.getHorizontalCOG();
        })
        .setter(function (newValue) {
            fridge.setHorizontalCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                fridge.setHorizontalCOGOverride(val);
            },
            function () {
                //return originalChassisObjectToUse.getAccessoryHolder().getAccessoryById(fridge.getId()).getHorizontalCOG();
                var originalFridge = originalChassisObjectToUse.getAccessoryHolder().getFridge();
                if (fridge.getCOGType() === config.COG_TYPES.LENGTH && originalFridge.getCOGType() === config.COG_TYPES.LENGTH || fridge.getCOGType() === config.COG_TYPES.PERCENTAGE && originalFridge.getCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalFridge.getHorizontalCOG();
                } else if (fridge.getCOGType() === config.COG_TYPES.LENGTH && originalFridge.getCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalFridge.getHorizontalCOG() * fridge.getProtrusion() / 100;
                } else if (fridge.getCOGType() === config.COG_TYPES.PERCENTAGE && originalFridge.getCOGType() === config.COG_TYPES.LENGTH) {
                    return originalFridge.getHorizontalCOG() / fridge.getProtrusion() * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return fridge.getHorizontalCOGOverride();
            })
        .visiblePermission(getObjectVisibilityPermission(fridge))
        .readOnlyPermission(getObjectReadOnlyPermission(fridge, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessStandardFridgeAndNotAdmin)
        .overridePermission(showOverrideForStandardFridge)
        // .alwaysNotifyValueSubscribers()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FRIDGEHORIZONTALCOG))
        .addFormattingRule(function () {
            return fridge.getCOGType() === config.COG_TYPES.PERCENTAGE;
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);


    // Vertical COG Type
    var verticalCOGTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === fridge.getVerticalCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (fridge.getVerticalCOGType() === '' || fridge.getVerticalCOGType() === undefined || fridge.getVerticalCOGType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FRIDGEVERTICALCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getItemVerticalCOGTypeOption(fridge))
        .optionGetter(function () {
            return getItemVerticalCOGTypeOption(fridge);
        })
        .visiblePermission(getObjectVisibilityPermission(fridge))
        .readOnlyPermission(getObjectReadOnlyPermission(fridge, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessStandardFridgeAndNotAdmin)
        .addSubscription(function (val) {
            if (val !== fridge.getVerticalCOGType()) {
                fridge.setVerticalCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    fridge.setVerticalCOG(fridge.getHeight() * fridge.getVerticalCOG() / 100);
                } else {
                    fridge.setVerticalCOG(utils.returnValueOrZeroIfNanOrInfinity((fridge.getVerticalCOG() / fridge.getHeight()) * 100));
                }
            }
        }), path);


    // Vertical COG
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FRIDGEVERTICALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return fridge.getVerticalCOG();
        })
        .setter(function (newValue) {
            fridge.setVerticalCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                fridge.setVerticalCOGOverride(val);
            },
            function () {
                //return originalChassisObjectToUse.getAccessoryHolder().getAccessoryById(fridge.getId()).getVerticalCOG();
                var originalFridge = originalChassisObjectToUse.getAccessoryHolder().getFridge();
                if (fridge.getVerticalCOGType() === config.COG_TYPES.LENGTH && originalFridge.getVerticalCOGType() === config.COG_TYPES.LENGTH || fridge.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE && originalFridge.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalFridge.getVerticalCOG();
                } else if (fridge.getVerticalCOGType() === config.COG_TYPES.LENGTH && originalFridge.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalFridge.getVerticalCOG() * fridge.getHeight() / 100;
                } else if (fridge.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE && originalFridge.getVerticalCOGType() === config.COG_TYPES.LENGTH) {
                    return originalFridge.getVerticalCOG() / fridge.getHeight() * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return fridge.getVerticalCOGOverride();
            })
        .overridePermission(showOverrideForStandardFridge)
        .visiblePermission(getObjectVisibilityPermission(fridge))
        .readOnlyPermission(getObjectReadOnlyPermission(fridge, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessStandardFridgeAndNotAdmin)
        // .alwaysNotifyValueSubscribers()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FRIDGEVERTICALCOG))
        .addFormattingRule(function () {
            return fridge.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE;
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);



    // Lateral COG Type
    var lateralCOGTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === fridge.getLateralCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (fridge.getLateralCOGType() === '' || fridge.getLateralCOGType() === undefined || fridge.getLateralCOGType() === null)) {
                return option;
            }
        })[0];

    //Lateral COG
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FRIDGELATERALCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getItemLateralCOGTypeOption(fridge))
        .optionGetter(function () {
            return getItemLateralCOGTypeOption(fridge);
        })
        .visiblePermission(getObjectVisibilityPermission(fridge))
        .readOnlyPermission(getObjectReadOnlyPermission(fridge, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessStandardFridgeAndNotAdmin)
        .addSubscription(function (val) {
            if (val !== fridge.getLateralCOGType()) {
                fridge.setLateralCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    fridge.setLateralCOG(fridge.getWidth() * fridge.getLateralCOG() / 100);
                } else {
                    fridge.setLateralCOG(utils.returnValueOrZeroIfNanOrInfinity((fridge.getLateralCOG() / fridge.getWidth()) * 100));
                }
            }
        }), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FRIDGELATERALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return fridge.getLateralCOG();
        })
        .setter(function (newValue) {
            fridge.setLateralCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                fridge.setLateralCOGOverride(val);
            },
            function () {
                //return originalChassisObjectToUse.getAccessoryHolder().getAccessoryById(fridge.getId()).getLateralCOG();
                var originalFridge = originalChassisObjectToUse.getAccessoryHolder().getFridge();
                if (fridge.getLateralCOGType() === config.COG_TYPES.LENGTH && originalFridge.getLateralCOGType() === config.COG_TYPES.LENGTH || fridge.getLateralCOGType() === config.COG_TYPES.PERCENTAGE && originalFridge.getLateralCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalFridge.getLateralCOG();
                } else if (fridge.getLateralCOGType() === config.COG_TYPES.LENGTH && originalFridge.getLateralCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalFridge.getLateralCOG() * fridge.getWidth() / 100;
                } else if (fridge.getLateralCOGType() === config.COG_TYPES.PERCENTAGE && originalFridge.getLateralCOGType() === config.COG_TYPES.LENGTH) {
                    return originalFridge.getLateralCOG() / fridge.getWidth() * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return fridge.getLateralCOGOverride();
            })
        .overridePermission(showOverrideForStandardFridge)
        .visiblePermission(getObjectVisibilityPermission(fridge))
        .readOnlyPermission(getObjectReadOnlyPermission(fridge, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessStandardFridgeAndNotAdmin)
        // .alwaysNotifyValueSubscribers()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FRIDGELATERALCOG))
        .addFormattingRule(function () {
            return fridge.getLateralCOGType() === config.COG_TYPES.PERCENTAGE;
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);


    function getChassisObjectToUseFunc() {
        return chassisObjectToUse;
    }

    function getChassisObjectOverallLength() {
        return rigOps.getOverallChassisObjectLengthLegal(path);
    }

    function getChassisObjectOverallHeight() {
        return rigOps.getChassisObjectOverallHeight(path);
    }
}

function createTailliftObjectObservables(taillift, path) {

    var chassisObjectToUse = rigOps.getChassisObjectFromPath(path),
        originalChassisObjectToUse = rigOps.getOriginalChassisObjectFromPath(path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TAILLIFTDESCRIPTION, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
        .setter(function (newValue) {
            taillift.setDescription(newValue)
        })
        .getter(function () {
            return taillift.getDescription();
        })
        //.visiblePermission(function () { return globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code); })
        .visiblePermission(getObjectVisibilityPermission(taillift))
        .readOnlyPermission(getObjectReadOnlyPermission(taillift, config.MENU_SECTION.SPECIFICATION))
        .addSubscription(function (val) {
            taillift.setDescription(val);
            updateAccessoryMenuLabelCallback(val, path, taillift.getAccessoryType());
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TAILLIFTDESCRIPTION)
            .addOpBlock(function (newValue, oldValue) {
                if (newValue === '') {
                    throw 1;
                }
            })
        ), path);

    //Dimensions
    // rear clearance = from back of body, changed 2018.8
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TAILLIFTFROMBACKOFBODY, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return taillift.getFromBackOfBody();
        })
        .setter(function (newValue) {
            taillift.setFromBackOfBody(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(taillift))
        .readOnlyPermission(getObjectReadOnlyPermission(taillift, config.MENU_SECTION.POSITION))
        //.visiblePermission(function () {
        //    return taillift.getType() === config.TAILLIFT_TYPES.CANTILEVER;
        //})
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TAILLIFTFROMBACKOFBODY)), path);

    
    
    
    let tailliftFromLeftOfBodyWidthExternalAOG = new AttributeObservableGroup(config.VALUE_TYPE.TAILLIFTFROMLEFTOFBODYWIDTHEXTERNAL, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return taillift.getFromLeftOfBodyWidthExternal();
        })
        .setter(function (newValue) {
            taillift.setFromLeftOfBodyWidthExternal(newValue);
        })
        .visiblePermission(hideAttribute)
        .readOnlyPermission(getObjectReadOnlyPermission(taillift, config.MENU_SECTION.POSITION));
        

    let tailliftFromLeftOfBodyWidthExternalAOGValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.TAILLIFTFROMLEFTOFBODYWIDTHEXTERNAL);
    tailliftFromLeftOfBodyWidthExternalAOGValidation.addOpBlock(function (newValue, oldValue, deltaValue){
        taillift.setFromCentreOfVehicle(rigOps.calculateFromCentreOfChassisForFromLeftOfBodyWidthExternal(taillift, path));
    });
    if(!globals.user.isLoadingAdministrator()) {
        tailliftFromLeftOfBodyWidthExternalAOGValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.COMBINATIONOVERALLWIDTH, rigOps.getCombinationOverallWidth);
    }
    tailliftFromLeftOfBodyWidthExternalAOG.addValidation(tailliftFromLeftOfBodyWidthExternalAOGValidation);
    addAttributeGroup(tailliftFromLeftOfBodyWidthExternalAOG, path);

    
    let tailliftFromCentreOfVehicleAOG = new AttributeObservableGroup(config.VALUE_TYPE.TAILLIFTFROMCENTREOFVEHICLE, config.INPUT_TYPE.UPDOWNBOX)
    .getter(function () {
        return taillift.getFromCentreOfVehicle();
    })
    .setter(function (newValue) {
        taillift.setFromCentreOfVehicle(newValue);
    })
    .visiblePermission(getObjectVisibilityPermission(taillift))
    .readOnlyPermission(getObjectReadOnlyPermission(taillift, config.MENU_SECTION.POSITION))
    
        //.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, getChassisObjectOverallHeight)
    
    let tailliftFromCentreOfVehicleAOGValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.TAILLIFTFROMCENTREOFVEHICLE);
    tailliftFromCentreOfVehicleAOGValidation.addOpBlock(function (newValue, oldValue, deltaVal) {
        taillift.setFromLeftOfBodyWidthExternal(rigOps.calculateFromLeftOfBodyWidthExternal(taillift, path));
    });

    if(!globals.user.isLoadingAdministrator()) {
        tailliftFromCentreOfVehicleAOGValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.COMBINATIONOVERALLWIDTH, rigOps.getCombinationOverallWidth);
    }
    tailliftFromCentreOfVehicleAOG.addValidation(tailliftFromCentreOfVehicleAOGValidation);
    addAttributeGroup(tailliftFromCentreOfVehicleAOG, path);



    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TAILLIFTMECHANISMLENGTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return taillift.getMechanismLength();
        })
        .setter(function (newValue) {
            taillift.setMechanismLength(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(taillift))
        .readOnlyPermission(getObjectReadOnlyPermission(taillift, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(isTemplateOrCustomTailliftWithoutGraphic)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TAILLIFTMECHANISMLENGTH)), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TAILLIFTPLATFORMPROTRUSION, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return taillift.getPlatformProtrusion();
        })
        .setter(function (newValue) {
            taillift.setPlatformProtrusion(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(taillift))
        .readOnlyPermission(getObjectReadOnlyPermission(taillift, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(isTemplateOrCustomTailliftWithoutGraphic)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TAILLIFTPLATFORMPROTRUSION)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.GAPBETWEENVEHICLEANDTRAILER, rigOps.getVehicleAndDrawbarGapBetweenVehicleAndTrailer)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTLENGTH, getChassisObjectOverallLength)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.CHASSISOBJECTREAROVERHANGTOTAL)
        ), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TAILLIFTABOVEORBELOWFLOOR, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return taillift.getAboveOrBelowFloor();
        })
        .setter(function (newValue) {
            taillift.setAboveOrBelowFloor(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(taillift))
        .readOnlyPermission(getObjectReadOnlyPermission(taillift, config.MENU_SECTION.POSITION))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TAILLIFTABOVEORBELOWFLOOR)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if (taillift.getType() === config.TAILLIFT_TYPES.COLUMN_UPPER_BOX) {
                    taillift.setPlatformHeight(taillift.getPlatformHeight() - deltaVal);
                }
            })
        ), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TAILLIFTMECHANISMHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return taillift.getMechanismHeight();
        })
        .setter(function (newValue) {
            taillift.setMechanismHeight(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(taillift))
        .readOnlyPermission(getObjectReadOnlyPermission(taillift, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(isTemplateOrCustomTailliftWithoutGraphic)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TAILLIFTMECHANISMHEIGHT)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, getChassisObjectOverallHeight)
        ), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TAILLIFTPLATFORMHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return taillift.getPlatformHeight();
        })
        .setter(function (newValue) {
            taillift.setPlatformHeight(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(taillift, isNotUpperBox))
        .readOnlyPermission(getObjectReadOnlyPermission(taillift, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(isTemplateOrCustomTailliftWithoutGraphicAndNotUpperBox)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TAILLIFTPLATFORMHEIGHT)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTHEIGHT, getChassisObjectOverallHeight)
        ), path);

    
    
    
    
    let tailliftWidthAOG = new AttributeObservableGroup(config.VALUE_TYPE.TAILLIFTWIDTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return taillift.getWidth();
        })
        .setter(function (newValue) {
            taillift.setWidth(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(taillift))
        .readOnlyPermission(getObjectReadOnlyPermission(taillift, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(isTemplateOrCustomTailliftWithoutGraphic)
        

    let tailliftWidthAOGValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.TAILLIFTWIDTH);
    tailliftWidthAOGValidation.addOpBlock(function (newValue, oldValue, deltaVal) {
        taillift.setFromLeftOfBodyWidthExternal(rigOps.calculateFromLeftOfBodyWidthExternal(taillift, path));
    });
    if(!globals.user.isLoadingAdministrator()) {
        tailliftWidthAOGValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.COMBINATIONOVERALLWIDTH, rigOps.getCombinationOverallWidth);
    }
    tailliftWidthAOG.addValidation(tailliftWidthAOGValidation);
    addAttributeGroup(tailliftWidthAOG, path);


    //Weights
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TAILLIFTMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(function () {
            return taillift.getMass();
        })
        .setter(function (newValue) {
            taillift.setMass(newValue);
        })
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                taillift.setMassOverride(val);
            },
            function () {
                return originalChassisObjectToUse.getAccessoryHolder().getAccessoryById(taillift.getId()).getMass();
            },
            function () {
                return taillift.getMassOverride();
            })
        .visiblePermission(getObjectVisibilityPermission(taillift))
        .readOnlyPermission(getObjectReadOnlyPermission(taillift, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessStandardTailliftAndNotAdmin)
        .overridePermission(showOverrideForStandardTaillift)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TAILLIFTMASS)), path);


    var cogTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === taillift.getCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (taillift.getCOGType() === '' || taillift.getCOGType() === undefined || taillift.getCOGType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TAILLIFTCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getItemHorizontalCOGTypeOption(taillift))
        .optionGetter(function () {
            return getItemHorizontalCOGTypeOption(taillift);
        })
        .addSubscription(function (val) {

            if (val !== taillift.getCOGType()) {
                taillift.setCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    taillift.setHorizontalCOG((taillift.getMechanismLength() + taillift.getPlatformProtrusion()) * taillift.getHorizontalCOG() / 100);
                } else {
                    taillift.setHorizontalCOG(utils.returnValueOrZeroIfNanOrInfinity((taillift.getHorizontalCOG() / (taillift.getMechanismLength() + taillift.getPlatformProtrusion())) * 100));
                }
            }
        })
        .visiblePermission(getObjectVisibilityPermission(taillift))
        .readOnlyPermission(getObjectReadOnlyPermission(taillift, config.MENU_SECTION.SPECIFICATION_WEIGHTS)), path);
        //.visiblePermission(showUnlessStandardTailliftAndNotAdmin), path);


    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TAILLIFTHORIZONTALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return taillift.getHorizontalCOG();
        })
        .setter(function (newValue) {
            taillift.setHorizontalCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                taillift.setHorizontalCOGOverride(val);
            },
            function () {
                //return originalChassisObjectToUse.getAccessoryHolder().getAccessoryById(taillift.getId()).getHorizontalCOG();
                var originalTaillift = originalChassisObjectToUse.getAccessoryHolder().getTaillift();
                if (taillift.getCOGType() === config.COG_TYPES.LENGTH && originalTaillift.getCOGType() === config.COG_TYPES.LENGTH || taillift.getCOGType() === config.COG_TYPES.PERCENTAGE && originalTaillift.getCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalTaillift.getHorizontalCOG();
                } else if (taillift.getCOGType() === config.COG_TYPES.LENGTH && originalTaillift.getCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalTaillift.getHorizontalCOG() * (taillift.getMechanismLength() + taillift.getPlatformProtrusion()) / 100;
                } else if (taillift.getCOGType() === config.COG_TYPES.PERCENTAGE && originalTaillift.getCOGType() === config.COG_TYPES.LENGTH) {
                    return originalTaillift.getHorizontalCOG() / (taillift.getMechanismLength() + taillift.getPlatformProtrusion()) * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return taillift.getHorizontalCOGOverride();
            })
        .visiblePermission(getObjectVisibilityPermission(taillift))
        .readOnlyPermission(getObjectReadOnlyPermission(taillift, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessStandardTailliftAndNotAdmin)
        .overridePermission(showOverrideForStandardTaillift)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TAILLIFTHORIZONTALCOG))
        .addFormattingRule(function () {
            return taillift.getCOGType() === config.COG_TYPES.PERCENTAGE;
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);




    var verticalCOGTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === taillift.getVerticalCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (taillift.getVerticalCOGType() === '' || taillift.getVerticalCOGType() === undefined || taillift.getVerticalCOGType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TAILLIFTVERTICALCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getItemVerticalCOGTypeOption(taillift))
        .optionGetter(function () {
            return getItemVerticalCOGTypeOption(taillift);
        })
        .addSubscription(function (val) {

            if (val !== taillift.getVerticalCOGType()) {
                taillift.setVerticalCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    taillift.setVerticalCOG(taillift.getHeight() * taillift.getVerticalCOG() / 100);
                } else {
                    taillift.setVerticalCOG(utils.returnValueOrZeroIfNanOrInfinity((taillift.getVerticalCOG() / taillift.getHeight()) * 100));
                }
            }
        })
        .visiblePermission(getObjectVisibilityPermission(taillift))
        .readOnlyPermission(getObjectReadOnlyPermission(taillift, config.MENU_SECTION.SPECIFICATION_WEIGHTS)), path);
        //.visiblePermission(showUnlessStandardTailliftAndNotAdmin), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TAILLIFTVERTICALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return taillift.getVerticalCOG();
        })
        .setter(function (newValue) {
            taillift.setVerticalCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                taillift.setVerticalCOGOverride(val);
            },
            function () {
                //return originalChassisObjectToUse.getAccessoryHolder().getAccessoryById(taillift.getId()).getVerticalCOG();
                var originalTaillift = originalChassisObjectToUse.getAccessoryHolder().getTaillift();
                if (taillift.getVerticalCOGType() === config.COG_TYPES.LENGTH && originalTaillift.getVerticalCOGType() === config.COG_TYPES.LENGTH || taillift.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE && originalTaillift.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalTaillift.getVerticalCOG();
                } else if (taillift.getVerticalCOGType() === config.COG_TYPES.LENGTH && originalTaillift.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalTaillift.getVerticalCOG() * taillift.getHeight() / 100;
                } else if (taillift.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE && originalTaillift.getVerticalCOGType() === config.COG_TYPES.LENGTH) {
                    return originalTaillift.getVerticalCOG() / taillift.getHeight() * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return taillift.getVerticalCOGOverride();
            })
        .overridePermission(showOverrideForStandardTaillift)
        .visiblePermission(getObjectVisibilityPermission(taillift))
        .readOnlyPermission(getObjectReadOnlyPermission(taillift, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessStandardTailliftAndNotAdmin)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TAILLIFTVERTICALCOG))
        .addFormattingRule(function () {
            return taillift.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE;
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);



    var lateralCOGTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === taillift.getLateralCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (taillift.getLateralCOGType() === '' || taillift.getLateralCOGType() === undefined || taillift.getLateralCOGType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TAILLIFTLATERALCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getItemLateralCOGTypeOption(taillift))
        .optionGetter(function () {
            return getItemLateralCOGTypeOption(taillift);
        })
        .addSubscription(function (val) {

            if (val !== taillift.getLateralCOGType()) {
                taillift.setLateralCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    taillift.setLateralCOG(taillift.getWidth() * taillift.getLateralCOG() / 100);
                } else {
                    taillift.setLateralCOG(utils.returnValueOrZeroIfNanOrInfinity((taillift.getLateralCOG() / taillift.getWidth()) * 100));
                }
            }
        })
        .visiblePermission(getObjectVisibilityPermission(taillift))
        .readOnlyPermission(getObjectReadOnlyPermission(taillift, config.MENU_SECTION.SPECIFICATION_WEIGHTS)), path);
        //.visiblePermission(showUnlessStandardTailliftAndNotAdmin), path);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TAILLIFTLATERALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return taillift.getLateralCOG();
        })
        .setter(function (newValue) {
            taillift.setLateralCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getChassisObjectToUseFunc,
            function (val) {
                taillift.setLateralCOGOverride(val);
            },
            function () {
                //return originalChassisObjectToUse.getAccessoryHolder().getAccessoryById(taillift.getId()).getLateralCOG();
                var originalTaillift = originalChassisObjectToUse.getAccessoryHolder().getTaillift();
                if (taillift.getLateralCOGType() === config.COG_TYPES.LENGTH && originalTaillift.getLateralCOGType() === config.COG_TYPES.LENGTH || taillift.getLateralCOGType() === config.COG_TYPES.PERCENTAGE && originalTaillift.getLateralCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalTaillift.getLateralCOG();
                } else if (taillift.getLateralCOGType() === config.COG_TYPES.LENGTH && originalTaillift.getLateralCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalTaillift.getLateralCOG() * taillift.getWidth() / 100;
                } else if (taillift.getLateralCOGType() === config.COG_TYPES.PERCENTAGE && originalTaillift.getLateralCOGType() === config.COG_TYPES.LENGTH) {
                    return originalTaillift.getLateralCOG() / taillift.getWidth() * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return taillift.getLateralCOGOverride();
            })
        .overridePermission(showOverrideForStandardTaillift)
        .visiblePermission(getObjectVisibilityPermission(taillift))
        .readOnlyPermission(getObjectReadOnlyPermission(taillift, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessStandardTailliftAndNotAdmin)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TAILLIFTLATERALCOG))
        .addFormattingRule(function () {
            return taillift.getLateralCOGType() === config.COG_TYPES.PERCENTAGE;
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);

    function getChassisObjectToUseFunc() {
        return chassisObjectToUse;
    }

    function getChassisObjectOverallLength() {
        return rigOps.getOverallChassisObjectLengthLegal(path);
    }

    function getChassisObjectOverallHeight() {
        return rigOps.getChassisObjectOverallHeight(path);
    }
}

function createFifthWheelObjectObservables(fifthWheel, path) {
    ////Dimensions
    //Description
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FIFTHWHEELDESCRIPTION, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
        .setter(function (newValue) {
            fifthWheel.setDescription(newValue)
        })
        .getter(function () {
            return fifthWheel.getDescription();
        })
        .visiblePermission(getObjectVisibilityPermission(fifthWheel))
        .readOnlyPermission(getObjectReadOnlyPermission(fifthWheel, config.MENU_SECTION.SPECIFICATION))
        //.visiblePermission(function () { return globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code); })
        .addSubscription(function (val) {
            fifthWheel.setDescription(val);
            updateAccessoryMenuLabelCallback(val, path, fifthWheel.getAccessoryType());
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FIFTHWHEELDESCRIPTION)
            .addOpBlock(function (newValue, oldValue) {
                if (newValue === '') {
                    throw 1;
                }
            })
        ), path);

    //Offset
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FIFTHWHEELOFFSET, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return fifthWheel.getOffset();
        })
        .setter(function (newValue) {
            fifthWheel.setOffset(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(fifthWheel))
        .readOnlyPermission(getObjectReadOnlyPermission(fifthWheel, config.MENU_SECTION.POSITION))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FIFTHWHEELOFFSET)), path);

    ////From Left of Vehicle Rear Width
    //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FIFTHWHEELFROMLEFTOFVEHICLEREARWIDTH, config.INPUT_TYPE.UPDOWNBOX)
    //    .getter(function () { return fifthWheel.getFromLeftOfVehicleRearWidth(); })
    //    .setter(function (newValue) { fifthWheel.setFromLeftOfVehicleRearWidth(newValue); })
    //    .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FIFTHWHEELFROMLEFTOFVEHICLEREARWIDTH)), path);

    //FrontOverhang
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FIFTHWHEELFRONTOVERHANG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return fifthWheel.getFrontOverhang();
        })
        .setter(function (newValue) {
            fifthWheel.setFrontOverhang(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(fifthWheel))
        .readOnlyPermission(getObjectReadOnlyPermission(fifthWheel, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(isTemplateOrCustomFifthWheelWithoutGraphic)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FIFTHWHEELFRONTOVERHANG)), path);

    //Length
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FIFTHWHEELLENGTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return fifthWheel.getLength();
        })
        .setter(function (newValue) {
            fifthWheel.setLength(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(fifthWheel))
        .readOnlyPermission(getObjectReadOnlyPermission(fifthWheel, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(isTemplateOrCustomFifthWheelWithoutGraphic)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FIFTHWHEELLENGTH)), path);

    //SubframeHeight
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FIFTHWHEELSUBFRAMEHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return fifthWheel.getSubframeHeight();
        })
        .setter(function (newValue) {
            fifthWheel.setSubframeHeight(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(fifthWheel))
        .readOnlyPermission(getObjectReadOnlyPermission(fifthWheel, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(isTemplateOrCustomFifthWheelWithoutGraphic)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FIFTHWHEELSUBFRAMEHEIGHT)), path);

    //Height
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FIFTHWHEELHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return fifthWheel.getHeight();
        })
        .setter(function (newValue) {
            fifthWheel.setHeight(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(fifthWheel))
        .readOnlyPermission(getObjectReadOnlyPermission(fifthWheel, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(isTemplateOrCustomFifthWheelWithoutGraphic)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FIFTHWHEELHEIGHT)), path);

    //Width
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FIFTHWHEELWIDTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return fifthWheel.getWidth();
        })
        .setter(function (newValue) {
            fifthWheel.setWidth(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(fifthWheel))
        .readOnlyPermission(getObjectReadOnlyPermission(fifthWheel, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(isTemplateOrCustomFifthWheelWithoutGraphic)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FIFTHWHEELWIDTH)), path);

    ////Weights
    //Weight
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FIFTHWHEELMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(function () {
            return fifthWheel.getMass();
        })
        .setter(function (newValue) {
            fifthWheel.setMass(newValue);
        })
        .addOverrideMechanism(getConfiguration,
            function (val) {
                fifthWheel.setMassOverride(val);
            },
            function () {
                return getOriginalOffer().getConfiguration().getAccessoryHolder().getAccessoryById(fifthWheel.getId()).getMass();
            },
            function () {
                return fifthWheel.getMassOverride();
            })
        .visiblePermission(getObjectVisibilityPermission(fifthWheel))
        .readOnlyPermission(getObjectReadOnlyPermission(fifthWheel, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessStandardFifthWheelAndNotAdmin)
        .overridePermission(showOverrideForStandardFifthWheel)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FIFTHWHEELMASS)), path);




    function getFifthWheelHorizontalCOGTypeOption() {
        var cogTypeOption = getCOGTypeOptions()
            .filter(function (option) {
                if (option.id === fifthWheel.getCOGType()) {
                    return option;
                } else if (option.id === config.COG_TYPES.LENGTH && (fifthWheel.getCOGType() === '' || fifthWheel.getCOGType() === undefined || fifthWheel.getCOGType() === null)) {
                    return option;
                }
            })[0];

        return cogTypeOption;
    }
    //COG Type


    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FIFTHWHEELCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getItemHorizontalCOGTypeOption(fifthWheel))
        .optionGetter(function () {
            return getItemHorizontalCOGTypeOption(fifthWheel);
        })
        .visiblePermission(getObjectVisibilityPermission(fifthWheel))
        .readOnlyPermission(getObjectReadOnlyPermission(fifthWheel, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessStandardFifthWheelAndNotAdmin)
        .addSubscription(function (val) {

            if (val !== fifthWheel.getCOGType()) {
                fifthWheel.setCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    fifthWheel.setHorizontalCOG(fifthWheel.getLength() * fifthWheel.getHorizontalCOG() / 100);
                } else {
                    fifthWheel.setHorizontalCOG(utils.returnValueOrZeroIfNanOrInfinity((fifthWheel.getHorizontalCOG() / fifthWheel.getLength()) * 100));
                }
            }
        }), path);

    //Horizontal COG
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FIFTHWHEELHORIZONTALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return fifthWheel.getHorizontalCOG();
        })
        .setter(function (newValue) {
            fifthWheel.setHorizontalCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getConfiguration,
            function (val) {
                fifthWheel.setHorizontalCOGOverride(val);
            },
            function () {
                //return getOriginalOffer().getConfiguration().getAccessoryHolder().getAccessoryById(fifthWheel.getId()).getHorizontalCOG();
                var originalFifthWheel = getOriginalOffer().getConfiguration().getAccessoryHolder().getFifthWheel();
                if (fifthWheel.getCOGType() === config.COG_TYPES.LENGTH && originalFifthWheel.getCOGType() === config.COG_TYPES.LENGTH || fifthWheel.getCOGType() === config.COG_TYPES.PERCENTAGE && originalFifthWheel.getCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalFifthWheel.getHorizontalCOG();
                } else if (fifthWheel.getCOGType() === config.COG_TYPES.LENGTH && originalFifthWheel.getCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalFifthWheel.getHorizontalCOG() * fifthWheel.getLength() / 100;
                } else if (fifthWheel.getCOGType() === config.COG_TYPES.PERCENTAGE && originalFifthWheel.getCOGType() === config.COG_TYPES.LENGTH) {
                    return originalFifthWheel.getHorizontalCOG() / fifthWheel.getLength() * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return fifthWheel.getHorizontalCOGOverride();
            })
        .visiblePermission(getObjectVisibilityPermission(fifthWheel))
        .readOnlyPermission(getObjectReadOnlyPermission(fifthWheel, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessStandardFifthWheelAndNotAdmin)
        .overridePermission(showOverrideForStandardFifthWheel)
        // .alwaysNotifyValueSubscribers()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FIFTHWHEELHORIZONTALCOG))
        .addFormattingRule(function () {
            return fifthWheel.getCOGType() === config.COG_TYPES.PERCENTAGE;
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);



    //Vertical COG Type
    //var verticalCOGTypeOption = getCOGTypeOptions()
    //  .filter(function (option) {
    //      if (option.id === fifthWheel.getVerticalCOGType()) {
    //          return option;
    //      } else if (option.id === config.COG_TYPES.LENGTH && (fifthWheel.getVerticalCOGType() === '' || fifthWheel.getVerticalCOGType() === undefined || fifthWheel.getVerticalCOGType() === null)) {
    //          return option;
    //      }
    //  })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FIFTHWHEELVERTICALCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getItemVerticalCOGTypeOption(fifthWheel))
        .optionGetter(function () {
            return getItemVerticalCOGTypeOption(fifthWheel);
        })
        .visiblePermission(getObjectVisibilityPermission(fifthWheel))
        .readOnlyPermission(getObjectReadOnlyPermission(fifthWheel, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessStandardFifthWheelAndNotAdmin)
        .addSubscription(function (val) {

            if (val !== fifthWheel.getVerticalCOGType()) {
                fifthWheel.setVerticalCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    fifthWheel.setVerticalCOG((fifthWheel.getSubframeHeight() + fifthWheel.getHeight()) * fifthWheel.getVerticalCOG() / 100);
                } else {
                    fifthWheel.setVerticalCOG(utils.returnValueOrZeroIfNanOrInfinity((fifthWheel.getVerticalCOG() / (fifthWheel.getSubframeHeight() + fifthWheel.getHeight())) * 100));
                }
            }
        }), path);

    //Vertical COG
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FIFTHWHEELVERTICALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return fifthWheel.getVerticalCOG();
        })
        .setter(function (newValue) {
            fifthWheel.setVerticalCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getConfiguration,
            function (val) {
                fifthWheel.setVerticalCOGOverride(val);
            },
            function () {
                //return getOriginalOffer().getConfiguration().getAccessoryHolder().getAccessoryById(fifthWheel.getId()).getVerticalCOG();
                var originalFifthWheel = getOriginalOffer().getConfiguration().getAccessoryHolder().getFifthWheel();
                if (fifthWheel.getVerticalCOGType() === config.COG_TYPES.LENGTH && originalFifthWheel.getVerticalCOGType() === config.COG_TYPES.LENGTH || fifthWheel.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE && originalFifthWheel.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalFifthWheel.getVerticalCOG();
                } else if (fifthWheel.getVerticalCOGType() === config.COG_TYPES.LENGTH && originalFifthWheel.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalFifthWheel.getVerticalCOG() * (fifthWheel.getSubframeHeight() + fifthWheel.getHeight()) / 100;
                } else if (fifthWheel.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE && originalFifthWheel.getVerticalCOGType() === config.COG_TYPES.LENGTH) {
                    return originalFifthWheel.getVerticalCOG() / (fifthWheel.getSubframeHeight() + fifthWheel.getHeight()) * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return fifthWheel.getVerticalCOGOverride();
            })
        .visiblePermission(getObjectVisibilityPermission(fifthWheel))
        .readOnlyPermission(getObjectReadOnlyPermission(fifthWheel, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.overridePermission(showUnlessStandardFifthWheelAndNotAdminOrIsTemplateFifthWheelOrIsCustomFifthWheel)
        .visiblePermission(showUnlessStandardFifthWheelAndNotAdmin)
        // .alwaysNotifyValueSubscribers()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FIFTHWHEELVERTICALCOG))
        .addFormattingRule(function () {
            return fifthWheel.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE;
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);


    //Lateral COG Type
    var lateralCOGTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === fifthWheel.getLateralCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (fifthWheel.getLateralCOGType() === '' || fifthWheel.getLateralCOGType() === undefined || fifthWheel.getLateralCOGType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FIFTHWHEELLATERALCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getItemLateralCOGTypeOption(fifthWheel))
        .optionGetter(function () {
            return getItemLateralCOGTypeOption(fifthWheel);
        })
        .visiblePermission(getObjectVisibilityPermission(fifthWheel))
        .readOnlyPermission(getObjectReadOnlyPermission(fifthWheel, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessStandardFifthWheelAndNotAdmin)
        .addSubscription(function (val) {
            if (val !== fifthWheel.getLateralCOGType()) {
                fifthWheel.setLateralCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    fifthWheel.setLateralCOG(fifthWheel.getWidth() * fifthWheel.getLateralCOG() / 100);
                } else {
                    fifthWheel.setLateralCOG(utils.returnValueOrZeroIfNanOrInfinity((fifthWheel.getLateralCOG() / fifthWheel.getWidth()) * 100));
                }
            }
        }), path);

    //Lateral COG
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.FIFTHWHEELLATERALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return fifthWheel.getLateralCOG();
        })
        .setter(function (newValue) {
            fifthWheel.setLateralCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getConfiguration,
            function (val) {
                fifthWheel.setLateralCOGOverride(val);
            },
            function () {
                //return getOriginalOffer().getConfiguration().getAccessoryHolder().getAccessoryById(fifthWheel.getId()).getLateralCOG();
                var originalFifthWheel = getOriginalOffer().getConfiguration().getAccessoryHolder().getFifthWheel();
                if (fifthWheel.getLateralCOGType() === config.COG_TYPES.LENGTH && originalFifthWheel.getLateralCOGType() === config.COG_TYPES.LENGTH || fifthWheel.getLateralCOGType() === config.COG_TYPES.PERCENTAGE && originalFifthWheel.getLateralCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalFifthWheel.getLateralCOG();
                } else if (fifthWheel.getLateralCOGType() === config.COG_TYPES.LENGTH && originalFifthWheel.getLateralCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalFifthWheel.getLateralCOG() * fifthWheel.getWidth() / 100;
                } else if (fifthWheel.getLateralCOGType() === config.COG_TYPES.PERCENTAGE && originalFifthWheel.getLateralCOGType() === config.COG_TYPES.LENGTH) {
                    return originalFifthWheel.getLateralCOG() / fifthWheel.getWidth() * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return fifthWheel.getLateralCOGOverride();
            })
        .overridePermission(showOverrideForStandardFifthWheel)
        .visiblePermission(getObjectVisibilityPermission(fifthWheel))
        .readOnlyPermission(getObjectReadOnlyPermission(fifthWheel, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessStandardFifthWheelAndNotAdmin)
        // .alwaysNotifyValueSubscribers()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.FIFTHWHEELLATERALCOG))
        .addFormattingRule(function () {
            return fifthWheel.getLateralCOGType() === config.COG_TYPES.PERCENTAGE;
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);
}

function getItemHorizontalCOGTypeOption(item) {
    var horizontalCOGTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === item.getCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (item.getCOGType() === '' || item.getCOGType() === undefined || item.getCOGType() === null)) {
                return option;
            }
        })[0];

    return horizontalCOGTypeOption;
}

function getItemVerticalCOGTypeOption(item) {
    var verticalCOGTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === item.getVerticalCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (item.getVerticalCOGType() === '' || item.getVerticalCOGType() === undefined || item.getVerticalCOGType() === null)) {
                return option;
            }
        })[0];

    return verticalCOGTypeOption;
}

function getItemLateralCOGTypeOption(item) {
    var lateralCOGTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === item.getLateralCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (item.getLateralCOGType() === '' || item.getLateralCOGType() === undefined || item.getLateralCOGType() === null)) {
                return option;
            }
        })[0];

    return lateralCOGTypeOption;
}

function createHitchObjectObservables(hitch, path) {

    var chassisObjectToUse = rigOps.getChassisObjectFromPath(path),
        originalChassisObjectToUse = rigOps.getOriginalChassisObjectFromPath(path);

    ////Dimensions
    //Description
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHDESCRIPTION, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
        .setter(function (newValue) {
            hitch.setDescription(newValue);
        })
        .getter(function () {
            return hitch.getDescription();
        })
        .visiblePermission(getObjectVisibilityPermission(hitch))
        .readOnlyPermission(getObjectReadOnlyPermission(hitch, config.MENU_SECTION.SPECIFICATION))
        //.visiblePermission(function () { return globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code); })
        .addSubscription(function (val) {
            hitch.setDescription(val);
            updateAccessoryMenuLabelCallback(val, path, hitch.getAccessoryType());
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HITCHDESCRIPTION)
            .addOpBlock(function (newValue, oldValue) {
                if (newValue === '') {
                    throw 1;
                }
            })
        ), path);

    //Offset
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHOFFSET, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hitch.getOffset();
        })
        .setter(function (newValue) {
            hitch.setOffset(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(hitch))
        .readOnlyPermission(getObjectReadOnlyPermission(hitch, config.MENU_SECTION.POSITION))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HITCHOFFSET)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.GAPBETWEENVEHICLEANDTRAILER, rigOps.getVehicleAndDrawbarGapBetweenVehicleAndTrailer)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTLENGTH, getChassisObjectOverallLength)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.CHASSISOBJECTREAROVERHANGTOTAL)
        ), path);

    ////From Left of Vehicle Rear Width
    //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHFROMLEFTOFVEHICLEREARWIDTH, config.INPUT_TYPE.UPDOWNBOX)
    //    .getter(function () { return hitch.getFromLeftOfVehicleRearWidth(); })
    //    .setter(function (newValue) { hitch.setFromLeftOfVehicleRearWidth(newValue); })
    //    .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HITCHFROMLEFTOFVEHICLEREARWIDTH)), path);


    //mounting length
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHMOUNTINGLENGTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hitch.getMountingLength();
        })
        .setter(function (newValue) {
            hitch.setMountingLength(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(hitch))
        .readOnlyPermission(getObjectReadOnlyPermission(hitch, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(isTemplateOrCustomHitchWithoutGraphic)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HITCHMOUNTINGLENGTH)), path);

    //coupling offset
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHCOUPLINGOFFSET, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hitch.getCouplingOffset();
        })
        .setter(function (newValue) {
            hitch.setCouplingOffset(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(hitch))
        .readOnlyPermission(getObjectReadOnlyPermission(hitch, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(isTemplateOrCustomHitchWithoutGraphic)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HITCHCOUPLINGOFFSET)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                hitch.setOffset(hitch.getOffset() + deltaVal);
            })
        ), path);

    //funnel length
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHFUNNELLENGTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hitch.getFunnelLength();
        })
        .setter(function (newValue) {
            hitch.setFunnelLength(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(hitch))
        .readOnlyPermission(getObjectReadOnlyPermission(hitch, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(isTemplateOrCustomHitchWithoutGraphic)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HITCHFUNNELLENGTH)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.GAPBETWEENVEHICLEANDTRAILER, rigOps.getVehicleAndDrawbarGapBetweenVehicleAndTrailer)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.OVERALLCHASSISOBJECTLENGTH, getChassisObjectOverallLength)
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.CHASSISOBJECTREAROVERHANGTOTAL)
        ), path);

    //coupling height
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHCOUPLINGHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hitch.getCouplingHeight();
        })
        .setter(function (newValue) {
            hitch.setCouplingHeight(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(hitch))
        .readOnlyPermission(getObjectReadOnlyPermission(hitch, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(isTemplateOrCustomHitchWithoutGraphic)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HITCHCOUPLINGHEIGHT)), path);

    //htich overall height
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHOVERALLHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hitch.getHeight();
        })
        .setter(function (newValue) {
            hitch.setHeight(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(hitch))
        .readOnlyPermission(getObjectReadOnlyPermission(hitch, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(isTemplateOrCustomHitchWithoutGraphic)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HITCHOVERALLHEIGHT)), path);

    //hitch width
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHWIDTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hitch.getWidth();
        })
        .setter(function (newValue) {
            hitch.setWidth(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(hitch))
        .readOnlyPermission(getObjectReadOnlyPermission(hitch, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(isTemplateOrCustomHitchWithoutGraphic)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HITCHWIDTH)), path);

    //height from ground
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHHEIGHTFROMGROUND, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hitch.getHeightFromGround();
        })
        .setter(function (newValue) {
            hitch.setHeightFromGround(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(hitch))
        .readOnlyPermission(getObjectReadOnlyPermission(hitch, config.MENU_SECTION.POSITION))
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HITCHHEIGHTFROMGROUND)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                var tempTrailer1 = getActiveOffer().getRig().getTrailer1();
                if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR || tempTrailer1.getType() === config.TRAILER_TYPES.PUP) && tempTrailer1.getSource() !== config.VEHICLE_SOURCE_TYPES.STANDARD) {
                    tempTrailer1.setDrawbarHeight(newValue);
                }
            })
        ), path);


    ////Weights
    //Weight
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(function () {
            return hitch.getMass();
        })
        .setter(function (newValue) {
            hitch.setMass(newValue);
        })
        .addOverrideMechanism(getConfiguration,
            function (val) {
                hitch.setMassOverride(val);
            },
            function () {
                return getOriginalOffer().getConfiguration().getAccessoryHolder().getAccessoryById(hitch.getId()).getMass();
            },
            function () {
                return hitch.getMassOverride();
            })
        .visiblePermission(getObjectVisibilityPermission(hitch))
        .readOnlyPermission(getObjectReadOnlyPermission(hitch, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessStandardHitchAndNotAdmin)
        .overridePermission(showOverrideForStandardHitch)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HITCHMASS)), path);

    //COG Type (hidden)
    var cogTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === hitch.getCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (hitch.getCOGType() === '' || hitch.getCOGType() === undefined || hitch.getCOGType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getItemHorizontalCOGTypeOption(hitch))
        .optionGetter(function () {
            return getItemHorizontalCOGTypeOption(hitch);
        })
        .visiblePermission(getObjectVisibilityPermission(hitch))
        .readOnlyPermission(getObjectReadOnlyPermission(hitch, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessStandardHitchAndNotAdmin)
        .addSubscription(function (val) {
            if (val !== hitch.getCOGType()) {
                hitch.setCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    hitch.setHorizontalCOG((hitch.getMountingLength() + hitch.getCouplingOffset() + hitch.getFunnelLength()) * hitch.getHorizontalCOG() / 100);
                } else {
                    hitch.setHorizontalCOG(utils.returnValueOrZeroIfNanOrInfinity((hitch.getHorizontalCOG() / (hitch.getMountingLength() + hitch.getCouplingOffset() + hitch.getFunnelLength())) * 100));
                }
            }
        }), path);

    //Horizontal COG
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHHORIZONTALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hitch.getHorizontalCOG();
        })
        .setter(function (newValue) {
            hitch.setHorizontalCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getConfiguration,
            function (val) {
                hitch.setHorizontalCOGOverride(val);
            },
            function () {
                //return getOriginalOffer().getConfiguration().getAccessoryHolder().getAccessoryById(hitch.getId()).getHorizontalCOG();
                var originalHitch = getOriginalOffer().getConfiguration().getAccessoryHolder().getHitch();
                if (hitch.getCOGType() === config.COG_TYPES.LENGTH && originalHitch.getCOGType() === config.COG_TYPES.LENGTH || hitch.getCOGType() === config.COG_TYPES.PERCENTAGE && originalHitch.getCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalHitch.getHorizontalCOG();
                } else if (hitch.getCOGType() === config.COG_TYPES.LENGTH && originalHitch.getCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalHitch.getHorizontalCOG() * (hitch.getMountingLength() + hitch.getCouplingOffset() + hitch.getFunnelLength()) / 100;
                } else if (hitch.getCOGType() === config.COG_TYPES.PERCENTAGE && originalHitch.getCOGType() === config.COG_TYPES.LENGTH) {
                    return originalHitch.getHorizontalCOG() / (hitch.getMountingLength() + hitch.getCouplingOffset() + hitch.getFunnelLength()) * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return hitch.getHorizontalCOGOverride();
            })
        .visiblePermission(getObjectVisibilityPermission(hitch))
        .readOnlyPermission(getObjectReadOnlyPermission(hitch, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessStandardHitchAndNotAdmin)
        .overridePermission(showOverrideForStandardHitch)
        // .alwaysNotifyValueSubscribers()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HITCHHORIZONTALCOG))
        .addFormattingRule(function () {
            return hitch.getCOGType() === config.COG_TYPES.PERCENTAGE;
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);



    //Vertical COG Type
    var verticalCOGTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === hitch.getVerticalCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (hitch.getVerticalCOGType() === '' || hitch.getVerticalCOGType() === undefined || hitch.getVerticalCOGType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHVERTICALCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getItemVerticalCOGTypeOption(hitch))
        .optionGetter(function () {
            return getItemVerticalCOGTypeOption(hitch);
        })
        .visiblePermission(getObjectVisibilityPermission(hitch))
        .readOnlyPermission(getObjectReadOnlyPermission(hitch, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessStandardHitchAndNotAdmin)
        .addSubscription(function (val) {
            if (val !== hitch.getVerticalCOGType()) {
                hitch.setVerticalCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    hitch.setVerticalCOG(hitch.getHeight() * hitch.getVerticalCOG() / 100);
                } else {
                    hitch.setVerticalCOG(utils.returnValueOrZeroIfNanOrInfinity((hitch.getVerticalCOG() / hitch.getHeight()) * 100));
                }
            }
        }), path);

    //Vertical COG
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHVERTICALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hitch.getVerticalCOG();
        })
        .setter(function (newValue) {
            hitch.setVerticalCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getConfiguration,
            function (val) {
                hitch.setVerticalCOGOverride(val);
            },
            function () {
                //return getOriginalOffer().getConfiguration().getAccessoryHolder().getAccessoryById(hitch.getId()).getVerticalCOG();
                var originalHitch = getOriginalOffer().getConfiguration().getAccessoryHolder().getHitch();
                if (hitch.getVerticalCOGType() === config.COG_TYPES.LENGTH && originalHitch.getVerticalCOGType() === config.COG_TYPES.LENGTH || hitch.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE && originalHitch.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalHitch.getVerticalCOG();
                } else if (hitch.getVerticalCOGType() === config.COG_TYPES.LENGTH && originalHitch.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalHitch.getVerticalCOG() * hitch.getHeight() / 100;
                } else if (hitch.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE && originalHitch.getVerticalCOGType() === config.COG_TYPES.LENGTH) {
                    return originalHitch.getVerticalCOG() / hitch.getHeight() * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return hitch.getVerticalCOGOverride();
            })
        .overridePermission(showOverrideForStandardHitch)
        .visiblePermission(getObjectVisibilityPermission(hitch))
        .readOnlyPermission(getObjectReadOnlyPermission(hitch, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessStandardHitchAndNotAdmin)
        // .alwaysNotifyValueSubscribers()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HITCHVERTICALCOG))
        .addFormattingRule(function () {
            return hitch.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE;
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);



    //Lateral COG Type
    var lateralCOGTypeOption = getCOGTypeOptions()
        .filter(function (option) {
            if (option.id === hitch.getLateralCOGType()) {
                return option;
            } else if (option.id === config.COG_TYPES.LENGTH && (hitch.getLateralCOGType() === '' || hitch.getLateralCOGType() === undefined || hitch.getLateralCOGType() === null)) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHLATERALCOGTYPE, config.INPUT_TYPE.SELECT)
        .setVal(getItemLateralCOGTypeOption(hitch))
        .optionGetter(function () {
            return getItemLateralCOGTypeOption(hitch);
        })
        .visiblePermission(getObjectVisibilityPermission(hitch))
        .readOnlyPermission(getObjectReadOnlyPermission(hitch, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessStandardHitchAndNotAdmin)
        .addSubscription(function (val) {
            if (val !== hitch.getLateralCOGType()) {
                hitch.setLateralCOGType(val);
                if (val === config.COG_TYPES.LENGTH) {
                    hitch.setLateralCOG(hitch.getWidth() * hitch.getLateralCOG() / 100);
                } else {
                    hitch.setLateralCOG(utils.returnValueOrZeroIfNanOrInfinity((hitch.getLateralCOG() / hitch.getWidth()) * 100));
                }
            }
        }), path);

    //Lateral COG
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHLATERALCOG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return hitch.getLateralCOG();
        })
        .setter(function (newValue) {
            hitch.setLateralCOG(newValue);
        })
        .doNotIncludeUnit()
        .addOverrideMechanism(getConfiguration,
            function (val) {
                hitch.setLateralCOGOverride(val);
            },
            function () {
                //return getOriginalOffer().getConfiguration().getAccessoryHolder().getAccessoryById(hitch.getId()).getLateralCOG();
                var originalHitch = getOriginalOffer().getConfiguration().getAccessoryHolder().getHitch();
                if (hitch.getLateralCOGType() === config.COG_TYPES.LENGTH && originalHitch.getLateralCOGType() === config.COG_TYPES.LENGTH || hitch.getLateralCOGType() === config.COG_TYPES.PERCENTAGE && originalHitch.getLateralCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalHitch.getLateralCOG();
                } else if (hitch.getLateralCOGType() === config.COG_TYPES.LENGTH && originalHitch.getLateralCOGType() === config.COG_TYPES.PERCENTAGE) {
                    return originalHitch.getLateralCOG() * hitch.getWidth() / 100;
                } else if (hitch.getLateralCOGType() === config.COG_TYPES.PERCENTAGE && originalHitch.getLateralCOGType() === config.COG_TYPES.LENGTH) {
                    return originalHitch.getLateralCOG() / hitch.getWidth() * 100;
                } else {
                    return 0;
                }
            },
            function () {
                return hitch.getLateralCOGOverride();
            })
        .overridePermission(showOverrideForStandardHitch)
        .visiblePermission(getObjectVisibilityPermission(hitch))
        .readOnlyPermission(getObjectReadOnlyPermission(hitch, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessStandardHitchAndNotAdmin)
        // .alwaysNotifyValueSubscribers()
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.HITCHLATERALCOG))
        .addFormattingRule(function () {
            return hitch.getLateralCOGType() === config.COG_TYPES.PERCENTAGE;
        }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);

    function getChassisObjectOverallLength() {
        return rigOps.getOverallChassisObjectLengthLegal(path);
    }
}


//function createHitchObjectObservablesForConfigurationModal(hitch, path) {

//    var chassisObjectToUse = rigOps.getChassisObjectFromPath(path),
//        originalChassisObjectToUse = rigOps.getOriginalChassisObjectFromPath(path);

//    ////Dimensions
//    //Description
//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHDESCRIPTION, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
//        .setter(function (newValue) {
//            hitch.setDescription(newValue)
//        })
//        .getter(function () { return hitch.getDescription(); }), path);

//    //Offset
//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHOFFSET, config.INPUT_TYPE.UPDOWNBOX)
//        .getter(function () { return hitch.getOffset(); })
//        .setter(function (newValue) { hitch.setOffset(newValue); }), path);

//    //mounting length
//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHMOUNTINGLENGTH, config.INPUT_TYPE.UPDOWNBOX)
//        .getter(function () { return hitch.getMountingLength(); })
//        .setter(function (newValue) { hitch.setMountingLength(newValue); }), path);

//    //coupling offset
//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHCOUPLINGOFFSET, config.INPUT_TYPE.UPDOWNBOX)
//        .getter(function () { return hitch.getCouplingOffset(); })
//        .setter(function (newValue) { hitch.setCouplingOffset(newValue); }), path);

//    //funnel length
//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHFUNNELLENGTH, config.INPUT_TYPE.UPDOWNBOX)
//        .getter(function () { return hitch.getFunnelLength(); })
//        .setter(function (newValue) { hitch.setFunnelLength(newValue); }), path);

//    //coupling height
//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHCOUPLINGHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
//        .getter(function () { return hitch.getCouplingHeight(); })
//        .setter(function (newValue) { hitch.setCouplingHeight(newValue); }), path);

//    //htich overall height
//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHOVERALLHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
//        .getter(function () { return hitch.getHeight(); })
//        .setter(function (newValue) { hitch.setHeight(newValue); }), path);

//    //hitch width
//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHWIDTH, config.INPUT_TYPE.UPDOWNBOX)
//        .getter(function () { return hitch.getWidth(); })
//        .setter(function (newValue) { hitch.setWidth(newValue); }), path);

//    //height from ground
//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHHEIGHTFROMGROUND, config.INPUT_TYPE.UPDOWNBOX)
//        .getter(function () { return hitch.getHeightFromGround(); })
//        .setter(function (newValue) { hitch.setHeightFromGround(newValue); }), path);


//    ////Weights
//    //Weight
//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
//        .getter(function () { return hitch.getMass(); })
//        .setter(function (newValue) { hitch.setMass(newValue); }), path);

//    //Horizontal COG
//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHHORIZONTALCOG, config.INPUT_TYPE.UPDOWNBOX)
//        .getter(function () { return hitch.getHorizontalCOG(); })
//        .setter(function (newValue) {
//            hitch.setHorizontalCOG(newValue);
//        }), path);

//    //Vertical COG
//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHVERTICALCOG, config.INPUT_TYPE.UPDOWNBOX)
//        .getter(function () { return hitch.getVerticalCOG(); })
//        .setter(function (newValue) { hitch.setVerticalCOG(newValue); }), path);

//    //Lateral COG
//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.HITCHLATERALCOG, config.INPUT_TYPE.UPDOWNBOX)
//        .getter(function () { return hitch.getLateralCOG(); })
//        .setter(function (newValue) { hitch.setLateralCOG(newValue); }), path);

//    function getChassisObjectOverallLength() {
//        return rigOps.getOverallChassisObjectLengthLegal(path);
//    }
//}

function createTrailerObjectObservables(trailer, path) {

    //Description
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERDESCRIPTION, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
        .setter(function (newValue) {
            trailer.setDescription(newValue)
        })
        .getter(function () {
            return trailer.getDescription();
        })
        .visiblePermission(getObjectVisibilityPermission(trailer))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION))
        //.visiblePermission(function () { return globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code); })
        .addSubscription(function (val) {
            trailer.setDescription(val);
            //updateAccessoryMenuLabelCallback(val, path);
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERDESCRIPTION)
            .addOpBlock(function (newValue, oldValue) {
                if (newValue === '') {
                    throw 1;
                }
            })
        ), path);


    if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR || trailer.getType() === config.TRAILER_TYPES.PUP) {
        //TRAILERDRAWBARTYPE: 'trailerDrawbarType',
        var drawbarTypeOption = getDrawbarTypeOptions()
            .filter(function (option) {
                if (option.id === trailer.getDrawbarType()) {
                    return option;
                }
            })[0];

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERDRAWBARTYPE, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                trailer.setDrawbarType(val);
            })
            .visiblePermission(getObjectVisibilityPermission(trailer, function () {
                return (trailer.getType() === config.TRAILER_TYPES.DRAWBAR || trailer.getType() === config.TRAILER_TYPES.PUP);
            }))
            .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
            //.visiblePermission(function () {
            //    return showUnlessTrailerStandardOrCustomWithGraphic(path) && (trailer.getType() === config.TRAILER_TYPES.DRAWBAR || trailer.getType() === config.TRAILER_TYPES.PUP);
            //})
            .setVal(drawbarTypeOption), path);

    }

    let minusDeltaForUndo = true;
    //TRAILERDRAWBARPROTRUSION: 'trailerDrawbarProtrusion',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERDRAWBARPROTRUSION, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return trailer.getDrawbarLength();
        })
        .setter(function (newValue) {
            trailer.setDrawbarLength(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer, function () {
            return (trailer.getType() === config.TRAILER_TYPES.DRAWBAR || trailer.getType() === config.TRAILER_TYPES.PUP);
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(function () {
        //    return showUnlessTrailerStandardOrCustomWithGraphic(path) && (trailer.getType() === config.TRAILER_TYPES.DRAWBAR || trailer.getType() === config.TRAILER_TYPES.PUP);
        //})
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERDRAWBARPROTRUSION)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                trailer.setOverallLengthExclAccessories(trailer.getOverallLengthExclAccessories() + deltaVal);
                trailer.setDrawbarProtrusionChangeIncrement(trailer.getDrawbarProtrusionChangeIncrement() + deltaVal);
                if (trailer.getType() === config.TRAILER_TYPES.PUP) {
                    trailer.setWheelbaseTheoretical(trailer.getWheelbaseTheoretical() + deltaVal);
                }
            }, undefined, minusDeltaForUndo)
            .addBackEndAttributeValidationStep(config.VALUE_TYPE.TRAILERDRAWBARLENGTH, function () {
                return getLesserOfActiveLegislationVals(legislationVals.variableTypes.Trailer1DrawbarLengthLegislation);
            })
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.GAPBETWEENVEHICLEANDTRAILER, function () {
            //    return rigOps.getVehicleAndDrawbarGapBetweenVehicleAndTrailer();
            //})
            .addUIAttributeValidationStep(path + '.' + config.VALUE_TYPE.TRAILERWHEELBASETHEORETICAL)
        ), path);


    var attrPath = path + '.' + config.VALUE_TYPE.TRAILERLENGTH;
    //TRAILERLENGTH: 'trailerLength',
    var trailerLengthAttribteGroup = new AttributeObservableGroup(config.VALUE_TYPE.TRAILERLENGTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {

            return trailer.getLength();
        })
        .setter(function (newValue) {
            trailer.setLength(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(showUnlessTrailerStandardOrCustomWithGraphic)
        // .readOnlyPermission(function () {
        //     return (trailer.getTrailerLengthBreakpoint() > 0 && trailer.getTrailerLengthMaxCut() > 0);
        // })

    var trailerLengthValidation = getTrailerLengthValidationObject(trailer, path);

    trailerLengthAttribteGroup.addValidation(trailerLengthValidation);
    addDynamicValidationGroup(path, config.VALUE_TYPE.TRAILERLENGTH, getTrailerLengthValidationObject, trailer);

    addAttributeGroup(trailerLengthAttribteGroup, path);




    //TRAILERFRONTOVERHANG: 'trailerFrontOverhang',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERFRONTOVERHANG, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return trailer.getFrontOverhang();
        })
        .setter(function (newValue) {
            trailer.setFrontOverhang(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer, function (path) {
            return trailer.getType() !== config.TRAILER_TYPES.PUP;
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(function (path) {
        //    return showUnlessTrailerStandardOrCustomWithGraphic(path) && trailer.getType() !== config.TRAILER_TYPES.PUP;
        //})
        //.readOnlyPermission(function () {
        //    return (trailer.getFrontOverhangBreakpoint() > 0 && trailer.getFrontOverhangMaxCut() > 0);
        //})
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERFRONTOVERHANG)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                trailer.setFrontOverhangChangeIncrement(trailer.getFrontOverhangChangeIncrement() + deltaVal);
            })
            .addBackEndAttributeValidationStep(config.VALUE_TYPE.TRAILERAXLELASTTOCHASSISEND, function () {
                return trailer.getAxleLastToChassisEnd()
            })
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.TRAILERREAROVERHANGMIN, function () { return trailer.getRearOverhang() })
        ), path);

    //TRAILERFRONTTOSTARTOFTAPER: 'trailerFrontToStartOfTaper',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERFRONTTOSTARTOFTAPER, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return trailer.getFrontToStartOfTaper();
        })
        .setter(function (newValue) {
            trailer.setFrontToStartOfTaper(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer, function (path) {
            return trailer.getType() === config.TRAILER_TYPES.INTERLINK;
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(function (path) {
        //    return showUnlessTrailerStandardOrCustomWithGraphic(path) && trailer.getType() === config.TRAILER_TYPES.INTERLINK;
        //})
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERFRONTTOSTARTOFTAPER)), path);

    //TRAILERAXLE1FRONTTOAXLE2FRONT: 'trailerAxle1FrontToAxle2Front',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERAXLE1FRONTTOAXLE2FRONT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return trailer.getAxle1FrontToAxle2Front();
        })
        .setter(function (newValue) {
            trailer.setAxle1FrontToAxle2Front(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer, function () {
            return trailer.getAxle1FrontToAxle2Front() > 0;
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(function () {
        //    return trailer.getAxle1FrontToAxle2Front() > 0 && showUnlessTrailerStandardOrCustomWithGraphic(path)
        //})
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERAXLE1FRONTTOAXLE2FRONT)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if (trailer.getNumberOfAxlesFront() === 2) {
                    trailer.setAxle1FrontToWheelbaseTheoreticalStart(trailer.getAxle1FrontToWheelbaseTheoreticalStart() + deltaVal / 2);
                    trailer.setWheelbaseTheoretical(trailer.getWheelbaseTheoretical() + deltaVal / 2);
                } else if (trailer.getNumberOfAxlesFront() === 3) {
                    trailer.setAxle1FrontToWheelbaseTheoreticalStart(trailer.getAxle1FrontToWheelbaseTheoreticalStart() + deltaVal);
                    trailer.setWheelbaseTheoretical(trailer.getWheelbaseTheoretical() + deltaVal);
                }
                trailer.setLength(trailer.getLength() + deltaVal);

            })
            .addBackEndAttributeValidationStep(config.VALUE_TYPE.TRAILERAXLELASTTOCHASSISEND, function () {
                return trailer.getAxleLastToChassisEnd()
            })
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.TRAILERREAROVERHANGMIN, function () { return trailer.getRearOverhang() })
        ), path);

    //TRAILERAXLE2FRONTTOAXLE3FRONT: 'trailerAxle2FrontToAxle3Front',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERAXLE2FRONTTOAXLE3FRONT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return trailer.getAxle2FrontToAxle3Front();
        })
        .setter(function (newValue) {
            trailer.setAxle2FrontToAxle3Front(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer, function () {
            return trailer.getAxle2FrontToAxle3Front() > 0;
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(function () {
        //    return trailer.getAxle2FrontToAxle3Front() > 0 && showUnlessTrailerStandardOrCustomWithGraphic(path)
        //})
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERAXLE2FRONTTOAXLE3FRONT)
            .addBackEndAttributeValidationStep(config.VALUE_TYPE.TRAILERAXLELASTTOCHASSISEND, function () {
                return trailer.getAxleLastToChassisEnd()
            })
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.TRAILERREAROVERHANGMIN, function () { return trailer.getRearOverhang() })
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if (trailer.getNumberOfAxlesFront() === 2) {
                    trailer.setAxle1FrontToWheelbaseTheoreticalStart(trailer.getAxle1FrontToWheelbaseTheoreticalStart() + deltaVal / 2);
                    trailer.setWheelbaseTheoretical(trailer.getWheelbaseTheoretical() + deltaVal / 2);
                } else if (trailer.getNumberOfAxlesFront() === 3) {
                    trailer.setAxle1FrontToWheelbaseTheoreticalStart(trailer.getAxle1FrontToWheelbaseTheoreticalStart() + deltaVal);
                    trailer.setWheelbaseTheoretical(trailer.getWheelbaseTheoretical() + deltaVal);
                }
                trailer.setLength(trailer.getLength() + deltaVal);

            })
        ), path);

    //TRAILERREARMOSTFRONTAXLETOAXLE1REAR: 'trailerRearmostFrontAxleToAxle1Rear',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERREARMOSTAXLEFRONTTOAXLE1REAR, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return trailer.getRearMostFrontAxleToAxle1Rear();
        })
        .setter(function (newValue) {
            trailer.setRearMostFrontAxleToAxle1Rear(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer, function () {
            return false;
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(function () {
        //    return false;
        //})
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERREARMOSTAXLEFRONTTOAXLE1REAR)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                trailer.setWheelbaseTheoretical(trailer.getWheelbaseTheoretical() + deltaVal);
                trailer.setLength(trailer.getLength() + deltaVal);
            })
            .addBackEndAttributeValidationStep(config.VALUE_TYPE.TRAILERAXLELASTTOCHASSISEND, function () {
                return trailer.getAxleLastToChassisEnd()
            })
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.TRAILERREAROVERHANGMIN, function () { return trailer.getRearOverhang() })
        ), path);

    //TRAILERAXLE1FRONTTOWHEELBASETHEORETICALSTART: 'trailerAxle1FrontToWheelbasetheoreticalStart',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERAXLE1FRONTTOWHEELBASETHEORETICALSTART, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return trailer.getAxle1FrontToWheelbaseTheoreticalStart();
        })
        .setter(function (newValue) {
            trailer.setAxle1FrontToWheelbaseTheoreticalStart(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer, function () {
            return trailer.getType() === config.TRAILER_TYPES.DRAWBAR && trailer.getAxle1FrontToAxle2Front() > 0;
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(function () {
        //    return showUnlessTrailerStandardOrCustomWithGraphic(path) && trailer.getType() === config.TRAILER_TYPES.DRAWBAR && trailer.getAxle1FrontToAxle2Front() > 0;
        //})
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERAXLE1FRONTTOWHEELBASETHEORETICALSTART)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if (trailer.getNumberOfAxlesFront() === 2) {
                    trailer.setAxle1FrontToAxle2Front(trailer.getAxle1FrontToAxle2Front() + deltaVal * 2);
                } else if (trailer.getNumberOfAxlesFront() === 3) {
                    trailer.setAxle1FrontToAxle2Front(trailer.getAxle1FrontToAxle2Front() + deltaVal);
                    trailer.setAxle2FrontToAxle3Front(trailer.getAxle2FrontToAxle3Front() + deltaVal);
                }
            })
            .addBackEndAttributeValidationStep(config.VALUE_TYPE.TRAILERAXLELASTTOCHASSISEND, function () {
                return trailer.getAxleLastToChassisEnd()
            })
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.TRAILERREAROVERHANGMIN, function () { return trailer.getRearOverhang() })
        ), path);

    //TRAILERWHEELBASETHEORETICAL: 'trailerWheelbaseTheoretical',

    var trailerWheelbaseTheoreticalAOG = new AttributeObservableGroup(config.VALUE_TYPE.TRAILERWHEELBASETHEORETICAL, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return trailer.getWheelbaseTheoretical();
        })
        .setter(function (newValue) {
            trailer.setWheelbaseTheoretical(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(showUnlessTrailerStandardOrCustomWithGraphic)
        //.readOnlyPermission(function () {
        //    return (trailer.getWheelbaseTheoreticalBreakpoint() > 0 && trailer.getWheelbaseTheoreticalMaxCut() > 0);
        //})


    var trailerWheelbaseTheoreticalValidation = getTrailerWheelbaseTheoreticalValidationObject(trailer, path);



    trailerWheelbaseTheoreticalAOG.addValidation(trailerWheelbaseTheoreticalValidation);
    addDynamicValidationGroup(path, config.VALUE_TYPE.TRAILERWHEELBASETHEORETICAL, getTrailerWheelbaseTheoreticalValidationObject, trailer);

    addAttributeGroup(trailerWheelbaseTheoreticalAOG, path);





    //TRAILERKINGPINTOAXLE1REAR: 'trailerKingpinToAxle1Rear',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERKINGPINTOAXLE1REAR, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return trailer.getKingpinToAxle1Rear();
        })
        .setter(function (newValue) {
            trailer.setKingpinToAxle1Rear(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer, function (){ return false;}))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visible(false)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERKINGPINTOAXLE1REAR)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                trailer.setWheelbaseTheoretical(trailer.getWheelbaseTheoretical() + deltaVal);
                trailer.setLength(trailer.getLength() + deltaVal);
            })
        ), path);

    //TRAILERAXLE1REARTOAXLE2REAR: 'trailerAxle1RearToAxle2Rear',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERAXLE1REARTOAXLE2REAR, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return trailer.getAxle1RearToAxle2Rear();
        })
        .setter(function (newValue) {
            trailer.setAxle1RearToAxle2Rear(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer, function (path) {
            return showTrailerAxleSection(path, config.VALUE_TYPE.TRAILERAXLE1REARTOAXLE2REAR);
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(function (path) {
        //    return showTrailerAxleSection(path, config.VALUE_TYPE.TRAILERAXLE1REARTOAXLE2REAR);
        //})
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERAXLE1REARTOAXLE2REAR)
            .addOpBlock(function (newValue, oldValue, deltaVal) {

                if (trailer.getNumberOfAxlesRear() === 2) {

                    trailer.setWheelbaseTheoretical(trailer.getWheelbaseTheoretical() + deltaVal / 2);

                } else if (trailer.getNumberOfAxlesRear() === 3) {

                    trailer.setWheelbaseTheoretical(trailer.getWheelbaseTheoretical() + deltaVal);
                } else if (trailer.getNumberOfAxlesRear() === 4) {

                    trailer.setWheelbaseTheoretical(trailer.getWheelbaseTheoretical() + deltaVal);
                }

                trailer.setLength(trailer.getLength() + deltaVal);

            })
            .addBackEndAttributeValidationStep(config.VALUE_TYPE.TRAILERAXLELASTTOCHASSISEND, function () {
                return trailer.getAxleLastToChassisEnd()
            })
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.TRAILERREAROVERHANGMIN, function () { return trailer.getRearOverhang() })
        ), path);

    //TRAILERAXLE2REARTOAXLE3REAR: 'trailerAxle2RearToAxle3Rear',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERAXLE2REARTOAXLE3REAR, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return trailer.getAxle2RearToAxle3Rear();
        })
        .setter(function (newValue) {
            trailer.setAxle2RearToAxle3Rear(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer, function (path) {
            return showTrailerAxleSection(path, config.VALUE_TYPE.TRAILERAXLE2REARTOAXLE3REAR);
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(function (path) {
        //    return showTrailerAxleSection(path, config.VALUE_TYPE.TRAILERAXLE2REARTOAXLE3REAR);
        //})
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERAXLE2REARTOAXLE3REAR)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if (trailer.getNumberOfAxlesRear() === 2) {

                    trailer.setWheelbaseTheoretical(trailer.getWheelbaseTheoretical() + deltaVal / 2);
                } else if (trailer.getNumberOfAxlesRear() === 3) {

                    //trailer.setWheelbaseTheoretical(trailer.getWheelbaseTheoretical() + deltaVal);
                } else if (trailer.getNumberOfAxlesRear() === 4) {
                    trailer.setWheelbaseTheoretical(trailer.getWheelbaseTheoretical() + deltaVal / 2);
                    //trailer.setWheelbaseTheoretical(trailer.getWheelbaseTheoretical() + deltaVal);
                }
                trailer.setLength(trailer.getLength() + deltaVal);

            })
            .addBackEndAttributeValidationStep(config.VALUE_TYPE.TRAILERAXLELASTTOCHASSISEND, function () {
                return trailer.getAxleLastToChassisEnd()
            })
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.TRAILERREAROVERHANGMIN, function () { return trailer.getRearOverhang() })
        ), path);

    //TRAILERAXLE3REARTOAXLE4REAR: 'trailerAxle3RearToAxle4Rear',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERAXLE3REARTOAXLE4REAR, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return trailer.getAxle3RearToAxle4Rear();
        })
        .setter(function (newValue) {
            trailer.setAxle3RearToAxle4Rear(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer, function (path) {
            return showTrailerAxleSection(path, config.VALUE_TYPE.TRAILERAXLE3REARTOAXLE4REAR);
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(function (path) {
        //    return showTrailerAxleSection(path, config.VALUE_TYPE.TRAILERAXLE3REARTOAXLE4REAR);
        //})
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERAXLE3REARTOAXLE4REAR)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if (trailer.getNumberOfAxlesRear() === 2) {

                    trailer.setWheelbaseTheoretical(trailer.getWheelbaseTheoretical() + deltaVal / 2);
                } else if (trailer.getNumberOfAxlesRear() === 3) {

                    //trailer.setWheelbaseTheoretical(trailer.getWheelbaseTheoretical() + deltaVal);
                }
                trailer.setLength(trailer.getLength() + deltaVal);

            })
            .addBackEndAttributeValidationStep(config.VALUE_TYPE.TRAILERAXLELASTTOCHASSISEND, function () {
                return trailer.getAxleLastToChassisEnd()
            })
            //.addBackEndAttributeValidationStep(config.VALUE_TYPE.TRAILERREAROVERHANGMIN, function () { return trailer.getRearOverhang() })
        ), path);

    //TRAILERAXLELASTTOCHASSISEND: 'trailerAxleLastToChassisEnd',


    //TRAILERKINGPINTOLANDINGLEGS: 'trailerKingpinToLandingLegs',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERKINGPINTOLANDINGLEGS, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return trailer.getKingpinToLandingLegs();
        })
        .setter(function (newValue) {
            trailer.setKingpinToLandingLegs(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer, function () {
            return (trailer.getType() === config.TRAILER_TYPES.SEMI || trailer.getType() === config.TRAILER_TYPES.INTERLINK);
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(function () {
        //    return showUnlessTrailerStandardOrCustomWithGraphic(path) && (trailer.getType() === config.TRAILER_TYPES.SEMI || trailer.getType() === config.TRAILER_TYPES.INTERLINK);
        //})
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERKINGPINTOLANDINGLEGS)), path);


    //TRAILERFIFTHWHEELOFFSET: 'trailerFifthWheelOffset',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERFIFTHWHEELOFFSET, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return trailer.getFifthWheelOffset();
        })
        .setter(function (newValue) {
            trailer.setFifthWheelOffset(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer, function (path) {
            return trailer.getType() === config.TRAILER_TYPES.INTERLINK;
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(function (path) {
        //    return showUnlessTrailerStandardOrCustomWithGraphic(path) && trailer.getType() === config.TRAILER_TYPES.INTERLINK;
        //})
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERFIFTHWHEELOFFSET)), path);

    //TRAILEROVERALLHEIGHT: 'trailerOverallHeight',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILEROVERALLHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return trailer.getOverallHeight();
        })
        .setter(function (newValue) {
            trailer.setOverallHeight(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer, function () {
            return false;
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(function () {
        //    return false;
        //})
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILEROVERALLHEIGHT)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                trailer.setOverallHeightChangeIncrement(trailer.getOverallHeightChangeIncrement() + deltaVal);
            })
        ), path);

    //TRAILERKINGPINHEIGHT: 'trailerKingpinHeight',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERKINGPINHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return trailer.getKingpinHeight();
        })
        .setter(function (newValue) {
            trailer.setKingpinHeight(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer, function () {
            return (trailer.getType() === config.TRAILER_TYPES.SEMI || trailer.getType() === config.TRAILER_TYPES.INTERLINK);
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(function () {
        //    return showUnlessTrailerStandardOrCustomWithGraphic(path) && (trailer.getType() === config.TRAILER_TYPES.SEMI || trailer.getType() === config.TRAILER_TYPES.INTERLINK);
        //})
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERKINGPINHEIGHT)), path);

    //TRAILERCHASSISHEIGHT: 'trailerChassisHeight',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERCHASSISHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return trailer.getChassisHeight();
        })
        .setter(function (newValue) {
            trailer.setChassisHeight(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(showUnlessTrailerStandardOrCustomWithGraphic)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERCHASSISHEIGHT)), path);

    //TRAILERFIFTHWHEELHEIGHT: 'trailerFifthWheelHeight',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERFIFTHWHEELHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return trailer.getFifthWheelHeight();
        })
        .setter(function (newValue) {
            trailer.setFifthWheelHeight(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer, function (path) {
            return trailer.getType() === config.TRAILER_TYPES.INTERLINK;
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(function (path) {
        //    return showUnlessTrailerStandardOrCustomWithGraphic(path) && trailer.getType() === config.TRAILER_TYPES.INTERLINK;
        //})
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERFIFTHWHEELHEIGHT)), path);

    ////TRAILERDRAWBARHEIGHT: 'trailerDrawbarHeight',
    //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERDRAWBARHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
    //  .getter(function () { return trailer.getDrawbarHeight(); })
    //  .setter(function (newValue) { trailer.setDrawbarHeight(newValue); })
    //  .visiblePermission(function () { return showUnlessTrailerStandard(path) && trailer.getType() === config.TRAILER_TYPES.DRAWBAR; })
    //  .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERDRAWBARHEIGHT)), path);

    //TRAILERTURNTABLEHEIGHT: 'trailerTurntableHeight',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERTURNTABLEHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return trailer.getTurntableHeight();
        })
        .setter(function (newValue) {
            trailer.setTurntableHeight(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer, function () {
            return trailer.getType() === config.TRAILER_TYPES.DRAWBAR;
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(function () {
        //    return showUnlessTrailerStandardOrCustomWithGraphic(path) && trailer.getType() === config.TRAILER_TYPES.DRAWBAR;
        //})
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERTURNTABLEHEIGHT)), path);


    //TRAILEROVERALLWIDTH: 'trailerOverallWidth',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILEROVERALLWIDTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return trailer.getOverallWidth();
        })
        .setter(function (newValue) {
            trailer.setOverallWidth(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(showUnlessTrailerStandardOrCustomWithGraphic)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILEROVERALLWIDTH)
        .addOpBlock(function (newValue, oldValue, deltaVal){
            rigOps.updateFromLeftOfChassisRearWidthForAllChassisItems(deltaVal, path);
        })
        ), path);

    //TRAILERTYRERADIUS: 'trailerTyreRadius',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERTYRERADIUS, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return trailer.getTyreRadius();
        })
        .setter(function (newValue) {
            trailer.setTyreRadius(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(showUnlessTrailerStandardOrCustomWithGraphic)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERTYRERADIUS)), path);

    //TRAILERTYREWIDTH: 'trailerTyreWidth',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERTYREWIDTH, config.INPUT_TYPE.UPDOWNBOX)
        .getter(function () {
            return trailer.getTyreWidth();
        })
        .setter(function (newValue) {
            trailer.setTyreWidth(newValue);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(showUnlessTrailerStandardOrCustomWithGraphic)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERTYREWIDTH)), path);

    //TRAILERNUMBEROFTYRESONAXLE1REAR: 'trailerNumberOfTyresOnAxle1Rear',
    var numOfTyresOption = getNumberOfTyresOptions()
        .filter(function (option) {
            if (option.description === trailer.getNumberOfTyresOnAxle1Rear()) {
                return option;
            }
        })[0];

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERNUMBEROFTYRESONAXLE1REAR, config.INPUT_TYPE.SELECT)
        .addSubscription(function (val) {
            trailer.setNumberOfTyresOnAxle1Rear(val);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
        //.visiblePermission(showUnlessTrailerStandardOrCustomWithGraphic)
        .setVal(numOfTyresOption), path);

    if (trailer.getNumberOfTyresOnAxle2Rear() > 0) {
        //TRAILERNUMBEROFTYRESONAXLE2REAR: 'trailerNumberOfTyresOnAxle2Rear',
        numOfTyresOption = getNumberOfTyresOptions()
            .filter(function (option) {
                if (option.description === trailer.getNumberOfTyresOnAxle2Rear()) {
                    return option;
                }
            })[0];

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERNUMBEROFTYRESONAXLE2REAR, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                trailer.setNumberOfTyresOnAxle2Rear(val);
            })
            .visiblePermission(getObjectVisibilityPermission(trailer))
            .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
            //.visiblePermission(showUnlessTrailerStandardOrCustomWithGraphic)
            .setVal(numOfTyresOption), path);
    }

    if (trailer.getNumberOfTyresOnAxle3Rear() > 0) {
        //TRAILERNUMBEROFTYRESONAXLE3REAR: 'trailerNumberOfTyresOnAxle3Rear',
        numOfTyresOption = getNumberOfTyresOptions()
            .filter(function (option) {
                if (option.description === trailer.getNumberOfTyresOnAxle3Rear()) {
                    return option;
                }
            })[0];

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERNUMBEROFTYRESONAXLE3REAR, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                trailer.setNumberOfTyresOnAxle3Rear(val);
            })
            .visiblePermission(getObjectVisibilityPermission(trailer))
            .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
            //.visiblePermission(showUnlessTrailerStandardOrCustomWithGraphic)
            .setVal(numOfTyresOption), path);
    }

    if (trailer.getNumberOfTyresOnAxle4Rear() > 0) {
        //TRAILERNUMBEROFTYRESONAXLE4REAR: 'trailerNumberOfTyresOnAxle4Rear',
        numOfTyresOption = getNumberOfTyresOptions()
            .filter(function (option) {
                if (option.description === trailer.getNumberOfTyresOnAxle4Rear()) {
                    return option;
                }
            })[0];

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERNUMBEROFTYRESONAXLE4REAR, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                trailer.setNumberOfTyresOnAxle4Rear(val);
            })
            .visiblePermission(getObjectVisibilityPermission(trailer))
            .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
            //.visiblePermission(showUnlessTrailerStandardOrCustomWithGraphic)
            .setVal(numOfTyresOption), path);
    }


    //TRAILERTAREFRONTOVERRIDE: 'trailerTareFrontOverride',
    //TRAILERTAREFRONT: 'trailerTareFront',
    //var trailerTareFrontPathToUse = ? path + FRONT + '.' + config.VALUE_TYPE.TRAILERTAREFRONT : path + '.' + config.OBJECT_TYPES.AXLES_TRAILER_FRONT + '.' + config.VALUE_TYPE.TRAILERTAREFRONT;
    var trailerTareFrontPathToUse = trailer.getType() === config.TRAILER_TYPES.DRAWBAR ? path : path + '.' + config.OBJECT_TYPES.AXLES_TRAILER_FRONT;
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERTAREFRONT, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(function () {
            return rigOps.getTrailerTareFront(path);
        })
        .setter(function (newValue) {
            trailer.setTareFront(newValue);
        })
        .addOverrideMechanism(function () {
            return getActiveOffer().getTrailer(path);
        },
            function (val) {
                // var trailerPath = getActiveOffer().getTrailer(path).getType() === config.TRAILER_TYPES.DRAWBAR ? path : path + '.' + config.OBJECT_TYPES.AXLES_TRAILER_FRONT;
                getActiveOffer().getTrailer(path).setTareFrontOverride(val);
                getActiveOffer().getTrailer(path).setTareRearOverride(val);
                // getAttributeGroup(trailerTareFrontPathToUse + '.' + config.VALUE_TYPE.TRAILERTAREFRONT).override.value = val;
                // getAttributeGroup(path + '.' + config.VALUE_TYPE.TRAILERTAREREAR).override.value = val;
            },
            function () {
                return rigOps.getTrailerTareFront(path);
            },
            function () {
                return getActiveOffer().getTrailer(path).getTareFrontOverride();
            },
            function () {
                if (needToMaximise(rigOps)) {
                    rigOps.maximisePayloads();
                }
            })
        .visiblePermission(getObjectVisibilityPermission(trailer))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessTrailerStandardOrCustomWithGraphicAndNotAdmin)
        .overridePermission(function () { return true; })
        .addValidation(new ValidationEngine(trailerTareFrontPathToUse + '.' + config.VALUE_TYPE.TRAILERTAREFRONT)), trailerTareFrontPathToUse);


    //TRAILERTAREREAROVERRIDE: 'trailerTareRearOverride',
    //TRAILERTAREREAR: 'trailerTareRear',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERTAREREAR, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(function () {
            return rigOps.getTrailerTareRear(path);
        })
        .setter(function (newValue) {
            trailer.setTareRear(newValue);
        })
        .addOverrideMechanism(function () {
            return getActiveOffer().getTrailer(path);
        },
            function (val) {
                getActiveOffer().getTrailer(path).setTareRearOverride(val);
                getActiveOffer().getTrailer(path).setTareFrontOverride(val);
                //getAttributeGroup(path + '.' + config.OBJECT_TYPES.AXLES_TRAILER_FRONT + '.' + config.VALUE_TYPE.TRAILERTAREFRONT).override(val);
                // getAttributeGroup(path + '.' + config.VALUE_TYPE.TRAILERTAREREAR).override.value = val;
                // getAttributeGroup(trailerTareFrontPathToUse + '.' + config.VALUE_TYPE.TRAILERTAREFRONT).override.value = val;
            },
            function () {
                return rigOps.getTrailerTareRear(path);
            },
            function () {
                return getActiveOffer().getTrailer(path).getTareRearOverride();
            },
            function () {
                if (needToMaximise(rigOps)) {
                    rigOps.maximisePayloads();
                }
            })
        .visiblePermission(getObjectVisibilityPermission(trailer))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessTrailerStandardOrCustomWithGraphicAndNotAdmin)
        .overridePermission(function () { return true;})
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERTAREREAR)), path);


    //TRAILERMANUFACTURERSFRONTAXLESMASS: 'trailerManufacturersFrontAxlesMass',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERMANUFACTURERSFRONTAXLESMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(function () {
            return trailer.getManufacturersFrontAxlesMass();
        })
        .setter(function (newValue) {
            trailer.setManufacturersFrontAxlesMass(newValue);
        })
        .addOverrideMechanism(function () {
            return getActiveOffer().getTrailer(path);
        },
            function (val) {
                getActiveOffer().getTrailer(path).setManufacturersFrontAxlesMassOverride(val);
            },
            function () {
                return getOriginalOffer().getTrailer(path).getManufacturersFrontAxlesMass();
            },
            function () {
                return getActiveOffer().getTrailer(path).getManufacturersFrontAxlesMassOverride();
            })
        .visiblePermission(getObjectVisibilityPermission(trailer, function () {
            return trailer.getType() === config.TRAILER_TYPES.DRAWBAR;
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(function () {
        //    return showUnlessTrailerStandardOrCustomWithGraphicAndNotAdmin(path) && trailer.getType() === config.TRAILER_TYPES.DRAWBAR;
        //})
        .overridePermission(function () {
            return showOverrideForStandardTrailer(path) && trailer.getType() === config.TRAILER_TYPES.DRAWBAR;
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERMANUFACTURERSFRONTAXLESMASS)
            .addUIAttributeValidationStep(path + '.' + config.VALUE_TYPE.TRAILERPERMISSIBLEFRONT)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if (trailer.getPermissibleFrontOverride() === false) {
                    trailer.setPermissibleFront(rigOps.getTrailer1PermissibleFront());
                }
                //trailer.setPermissibleFront(oldValue + deltaVal);
            })
        ), path);


    //TRAILERMANUFACTURERSREARAXLESMASS: 'trailerManufacturersRearAxlesMass',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERMANUFACTURERSREARAXLESMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(function () {
            return trailer.getManufacturersRearAxlesMass();
        })
        .setter(function (newValue) {
            trailer.setManufacturersRearAxlesMass(newValue);
        })
        .addOverrideMechanism(function () {
            return getActiveOffer().getTrailer(path);
        },
            function (val) {
                getActiveOffer().getTrailer(path).setManufacturersRearAxlesMassOverride(val);
            },
            function () {
                return getOriginalOffer().getTrailer(path).getManufacturersRearAxlesMass();
            },
            function () {
                return getActiveOffer().getTrailer(path).getManufacturersRearAxlesMassOverride();
            })
        .visiblePermission(getObjectVisibilityPermission(trailer))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessTrailerStandardOrCustomWithGraphicAndNotAdmin)
        .overridePermission(function () {
            return showOverrideForStandardTrailer(path);
        })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERMANUFACTURERSREARAXLESMASS)
            .addUIAttributeValidationStep(path + '.' + config.VALUE_TYPE.TRAILERPERMISSIBLEREAR)
            .addOpBlock(function (newValue, oldValue, deltaVal) {
                if (trailer.getPermissibleRearOverride() === false) {
                    trailer.setPermissibleRear(rigOps.getTrailer1PermissibleRear());
                }
                //trailer.setPermissibleRear(oldValue + deltaVal);
            })
        ), path);


    //TRAILERFRONTROADFRIENDLYSUSPENSION: 'trailerFrontRoadFriendlySuspension',
    //var frontRoadFriendlySuspensionOption = getYesNoOptions()
    //    .filter(function (option) {
    //        if (option.id === trailer.getFrontRoadFriendlySuspension()) {
    //            return option;
    //        } else if (option.id === config.YES_NO_OPTIONS.NO && trailer.getFrontRoadFriendlySuspension() === '' || trailer.getFrontRoadFriendlySuspension() === undefined || trailer.getFrontRoadFriendlySuspension() === null) {
    //            return option;
    //        } else if (trailer.getFrontRoadFriendlySuspension() === config.YES_NO_OPTIONS.NOT_SPECIFIED) {
    //            return getYesNoOptions()[1];
    //        }
    //    })[0];

    function getFrontRoadFriendlySuspensionOption() {
        return getYesNoOptions()
            .filter(function (option) {
                if (option.id === trailer.getFrontRoadFriendlySuspension()) {
                    return option;
                } else if (option.id === config.YES_NO_OPTIONS.NO && trailer.getFrontRoadFriendlySuspension() === '' || trailer.getFrontRoadFriendlySuspension() === undefined || trailer.getFrontRoadFriendlySuspension() === null) {
                    return option;
                } else if (trailer.getFrontRoadFriendlySuspension() === config.YES_NO_OPTIONS.NOT_SPECIFIED) {
                    return getYesNoOptions()[1];
                }
            })[0];
    }

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERFRONTROADFRIENDLYSUSPENSION, config.INPUT_TYPE.SELECT)
        .addSubscription(function (val) {
            trailer.setFrontRoadFriendlySuspension(val);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer, function (path) {
            //return evaluateLegislation('SHOWSUSPENSION') !== 0 && showUnlessTrailerStandardOrCustomWithGraphicAndNotAdmin(path) && trailer.getType() === config.TRAILER_TYPES.DRAWBAR;
            return evaluateShowSuspension() && trailer.getType() === config.TRAILER_TYPES.DRAWBAR;
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(function (path) {
        //    //return evaluateLegislation('SHOWSUSPENSION') !== 0 && showUnlessTrailerStandardOrCustomWithGraphicAndNotAdmin(path) && trailer.getType() === config.TRAILER_TYPES.DRAWBAR;
        //    return evaluateShowSuspension() && showUnlessTrailerStandardOrCustomWithGraphicAndNotAdmin(path) && trailer.getType() === config.TRAILER_TYPES.DRAWBAR;
        //})
        .setVal(getFrontRoadFriendlySuspensionOption())
        .optionGetter(getFrontRoadFriendlySuspensionOption), path);

    //TRAILERREARROADFRIENDLYSUSPENSION: 'trailerRearRoadFriendlySuspension',
    //var rearRoadFriendlySuspensionOption = getYesNoOptions()
    //    .filter(function (option) {
    //        if (option.id === trailer.getRearRoadFriendlySuspension()) {
    //            return option;
    //        } else if (option.id === config.YES_NO_OPTIONS.NO && trailer.getRearRoadFriendlySuspension() === '' || trailer.getRearRoadFriendlySuspension() === undefined || trailer.getRearRoadFriendlySuspension() === null) {
    //            return option;
    //        } else if (trailer.getRearRoadFriendlySuspension() === config.YES_NO_OPTIONS.NOT_SPECIFIED) {
    //            return getYesNoOptions()[1];
    //        }
    //    })[0];

    function getRearRoadFriendlySuspensionOption() {
        return getYesNoOptions()
            .filter(function (option) {
                if (option.id === trailer.getRearRoadFriendlySuspension()) {
                    return option;
                } else if (option.id === config.YES_NO_OPTIONS.NO && trailer.getRearRoadFriendlySuspension() === '' || trailer.getRearRoadFriendlySuspension() === undefined || trailer.getRearRoadFriendlySuspension() === null) {
                    return option;
                } else if (trailer.getRearRoadFriendlySuspension() === config.YES_NO_OPTIONS.NOT_SPECIFIED) {
                    return getYesNoOptions()[1];
                }
            })[0];
    }

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERREARROADFRIENDLYSUSPENSION, config.INPUT_TYPE.SELECT)
        .setVal(getRearRoadFriendlySuspensionOption())
        .optionGetter(getRearRoadFriendlySuspensionOption)
        .addSubscription(function (val) {
            trailer.setRearRoadFriendlySuspension(val);
        })
        .visiblePermission(getObjectVisibilityPermission(trailer, function (path) {
            //return evaluateLegislation('SHOWSUSPENSION') !== 0 && showUnlessTrailerStandardOrCustomWithGraphicAndNotAdmin(path)
            return evaluateShowSuspension();
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_WEIGHTS)), path);
        //.visiblePermission(function (path) {
        //    //return evaluateLegislation('SHOWSUSPENSION') !== 0 && showUnlessTrailerStandardOrCustomWithGraphicAndNotAdmin(path)
        //    return evaluateShowSuspension() && showUnlessTrailerStandardOrCustomWithGraphicAndNotAdmin(path);
        //}), path);

    //TRAILERPERMISSIBLEFRONTOVERRIDE: 'trailerPermissibleFrontOverride',
    //TRAILERPERMISSIBLEFRONT: 'trailerPermissibleFront',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERPERMISSIBLEFRONT, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(function () {
            return rigOps.getTrailerPermissibleFront(path);
        })
        .setter(function (newValue) {
            trailer.setPermissibleFront(newValue);
        })
        .addOverrideMechanism(function () {
            return getActiveOffer().getTrailer(path);
        },
            function (val) {
                getActiveOffer().getTrailer(path).setPermissibleFrontOverride(val);
            },
            function () {
                return rigOps.getTrailerPermissibleFront(path);
            },
            function () {
                return getActiveOffer().getTrailer(path).getPermissibleFrontOverride();
            })
        .visiblePermission(getObjectVisibilityPermission(trailer, function () {
            return trailer.getAxlesHolder().getAxle1Front() !== null;
        }))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(function () {
        //    return trailer.getAxlesHolder().getAxle1Front() !== null && showUnlessTrailerStandardOrCustomWithGraphicAndNotAdmin(path);
        //})
        .overridePermission(showOverrideForStandardTrailer)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERPERMISSIBLEFRONT)), path);

    //TRAILERPERMISSIBLEREAROVERRIDE: 'trailerPermissibleRearOverride',
    //TRAILERPERMISSIBLEREAR: 'trailerPermissibleRear',
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERPERMISSIBLEREAR, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(function () {
            return rigOps.getTrailerPermissibleRear(path);
        })
        .setter(function (newValue) {
            trailer.setPermissibleRear(newValue);
        })
        .addOverrideMechanism(function () {
            return getActiveOffer().getTrailer(path);
        },
            function (val) {
                getActiveOffer().getTrailer(path).setPermissibleRearOverride(val);
            },
            function () {
                return rigOps.getTrailerPermissibleRear(path);
            },
            function () {
                return getActiveOffer().getTrailer(path).getPermissibleRearOverride();
            })
        .visiblePermission(getObjectVisibilityPermission(trailer))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(showUnlessTrailerStandardOrCustomWithGraphicAndNotAdmin)
        .overridePermission(showOverrideForStandardTrailer)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERPERMISSIBLEREAR)), path);

    
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERGROSSVEHICLEMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(function () { return trailer.getGVM(); })
        .setter(function (val) { trailer.setGVM(val); })
        .addOverrideMechanism(getConfiguration,
            function (val) {
                trailer.setGVMOverride(val);
            },
            function () {
                return rigOps.getTrailerGVMUsingPath(path);
            },
            function () {
                return trailer.getGVMOverride();
            })
        .visiblePermission(getObjectVisibilityPermission(trailer))
        .overridePermission(function (path) {
            return !isTemplateTrailer(path);
        })
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        .addValidation(new ValidationEngine(trailer.getChassisObjectName() + '.' + config.OBJECT_TYPES.AXLES_TOTAL + '.' + config.VALUE_TYPE.TRAILERGROSSVEHICLEMASS)), path + '.' + config.OBJECT_TYPES.AXLES_TOTAL);



    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERHORIZONTALCOG, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.LENGTH)
        .getter(function () {
            return rigOps.getTrailerHorizontalCOG(path);
        })
        .visiblePermission(function () {
            return true;
        })
        .readOnly(), path + '.' + config.OBJECT_TYPES.AXLES_TOTAL);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERVERTICALCOG, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.LENGTH)
        .getter(function () {
            return rigOps.getTrailerVerticalCOG(path);
        })
        .setter(function (newValue) {
            trailer.setVerticalCOG(newValue);
        })
        .addOverrideMechanism(function () {
            return getActiveOffer().getTrailer(path);
        },
            function (val) {
                getActiveOffer().getTrailer(path).setVerticalCOGOverride(val);
            },
            function () {
                //if (path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) { return getOriginalOffer().getRig().getTrailer1().getVerticalCOG(); } else { return getOriginalOffer().getRig().getTrailer2().getVerticalCOG() };
                return rigOps.getTrailerVerticalCOG(path);
            },
            function () {
                return getActiveOffer().getTrailer(path).getVerticalCOGOverride();
            })
        .visiblePermission(getObjectVisibilityPermission(trailer))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(function () {
        //    return true;
        //})
        .overridePermission(function (path) {
            return !isTemplateTrailer(path)
        })
        .addValidation(new ValidationEngine(trailer.getChassisObjectName() + '.' + config.OBJECT_TYPES.AXLES_TOTAL + '.' + config.VALUE_TYPE.TRAILERVERTICALCOG)), path + '.' + config.OBJECT_TYPES.AXLES_TOTAL);

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.TRAILERLATERALCOG, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.LENGTH)
        .getter(function () {
            return rigOps.getTrailerLateralCOG(path);
        })
        .setter(function (newValue) {
            trailer.setLateralCOG(newValue);
        })
        .addOverrideMechanism(function () {
            return getActiveOffer().getTrailer(path);
        },
            function (val) {
                getActiveOffer().getTrailer(path).setLateralCOGOverride(val);
            },
            function () {
                //if (path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) { return getOriginalOffer().getRig().getTrailer1().getLateralCOG(); } else { return getOriginalOffer().getRig().getTrailer2().getLateralCOG() };
                return rigOps.getTrailerLateralCOG(path);
            },
            function () {
                return getActiveOffer().getTrailer(path).getLateralCOGOverride();
            })
        .visiblePermission(getObjectVisibilityPermission(trailer))
        .readOnlyPermission(getObjectReadOnlyPermission(trailer, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
        //.visiblePermission(function () {
        //    return true;
        //})
        .overridePermission(function (path) {
            return !isTemplateTrailer(path)
        })
        .addValidation(new ValidationEngine(trailer.getChassisObjectName() + '.' + config.OBJECT_TYPES.AXLES_TOTAL + '.' + config.VALUE_TYPE.TRAILERLATERALCOG)), path + '.' + config.OBJECT_TYPES.AXLES_TOTAL);

}

function getTrailerLengthValidationObject(trailer, path) {
    var trailerLengthValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERLENGTH, config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_ONLY);
    if (getLesserOfActiveLegislationVals(legislationVals.variableTypes.KingpinToRearOfTrailerUsed) === 1) {
        if (trailer.getType() === config.TRAILER_TYPES.SEMI || trailer.getType() === config.TRAILER_TYPES.INTERLINK) {
            trailerLengthValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.KINGPINTOREAROFTRAILER, function () {
                return trailer.getArticulationPointToRearOfTrailer();
            })
        } else if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR) {
            if (globals.user.getActiveLegislation().countryId === 3) {
                trailerLengthValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.TURNTABLETOREAROFTRAILER, function () {
                    return trailer.getArticulationPointToRearOfTrailer();
                })
            }

        }
    }
    trailerLengthValidation.addUIAttributeValidationStep(path + '.' + config.VALUE_TYPE.TRAILERLENGTH);
    trailerLengthValidation.addOpBlock(function (newValue, oldValue, deltaVal) {
        trailer.setLengthChangeIncrement(trailer.getLengthChangeIncrement() + deltaVal);
    })
    //trailerLengthValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.CHASSISOBJECTREAROVERHANGTOTAL);
    //trailerLengthValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.TRAILERAXLELASTTOCHASSISEND, function () { return trailer.getAxleLastToChassisEnd() });

    return trailerLengthValidation;
}

function getTrailerWheelbaseTheoreticalValidationObject(trailer, path) {
    var trailerWheelbaseTheoreticalValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.TRAILERWHEELBASETHEORETICAL)
        .addOpBlock(function (newValue, oldValue, deltaVal) {
            trailer.setWheelbaseTheoreticalChangeIncrement(trailer.getWheelbaseTheoreticalChangeIncrement() + deltaVal);
            trailer.setLength(trailer.getLength() + deltaVal);
        }, function (newValue, oldValue, deltaVal) {
            trailer.setWheelbaseTheoreticalChangeIncrement(trailer.getWheelbaseTheoreticalChangeIncrement() - deltaVal);
            trailer.setLength(trailer.getLength() - deltaVal);
        })
        .addBackEndAttributeValidationStep(config.VALUE_TYPE.TRAILERAXLELASTTOCHASSISEND, function () {
            return trailer.getAxleLastToChassisEnd()
        })
    //.addBackEndAttributeValidationStep(config.VALUE_TYPE.TRAILERREAROVERHANGMIN, function () { return trailer.getRearOverhang() });

    if (trailer.getType() === config.TRAILER_TYPES.SEMI || trailer.getType() === config.TRAILER_TYPES.INTERLINK) {
        trailerWheelbaseTheoreticalValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.TRAILERKINGPINTOAXLE1REAR, function () {
            return trailer.getKingpinToAxle1Rear()
        });
    } else if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR) {
        trailerWheelbaseTheoreticalValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.TRAILERREARMOSTAXLEFRONTTOAXLE1REAR, function () {
            return trailer.getRearMostFrontAxleToAxle1Rear()
        });
    } else if (trailer.getType() === config.TRAILER_TYPES.PUP) {
        trailerWheelbaseTheoreticalValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.TRAILERHITCHPOINTTOAXLE1REAR, function () {
            return trailer.getHitchPointToAxle1Rear()
        });
    }
    //.addBackEndAttributeValidationStep(config.VALUE_TYPE.TRAILERAXLELASTTOCHASSISEND, function () { return trailer.getAxleLastToChassisEnd() })
    return trailerWheelbaseTheoreticalValidation;
}

function addPayloadObservables(payloads, path) {

    var chassisObjectToUse = rigOps.getChassisObjectFromPath(path);

    function getPayloadTypeOption() {
        var payloadType = chassisObjectToUse.getPayloadHolder().getPayloadType();
        //Payload Type
        var payloadTypeOption = getPayloadTypeOptions()
            .filter(function (option) {
                if (option.id === payloadType) {
                    return option;
                } else if (option.id === config.PAYLOAD_TYPES.SIMPLE && (payloadType === '' || payloadType === undefined)) {
                    return option;
                }
            })[0];

        return payloadTypeOption;
    }

    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CHASSISPAYLOADTYPE, config.INPUT_TYPE.SELECT)
        .readOnlyPermission(function(){
            return getAvailablePayloads().length === 0;
        })
        .optionGetter(getPayloadTypeOption)
        .addSubscription(function (val) {
            var intercomMetaData = {};
            intercomMetaData[config.INTERCOM_METADATA_ITEM.FROM_TYPE] = chassisObjectToUse.getPayloadHolder().getPayloadType();
            intercomMetaData[config.INTERCOM_METADATA_ITEM.TO_TYPE] = val;
            intercomMetaData[config.INTERCOM_METADATA_ITEM.CHASSIS_TYPE] = chassisObjectToUse.getChassisObjectName();

            chassisObjectToUse.getPayloadHolder().setPayloadType(val);
            dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.CHANGED_PAYLOAD_TYPE, intercomMetaData));
        }), path);

    payloads.forEach(function (payload) {
        createPayloadObservables(payload, path);
    });
}

function createPayloadObservables(payload, path) {

    var chassisObjectToUse = rigOps.getChassisObjectFromPath(path),
        originalChassisObjectToUse = rigOps.getOriginalChassisObjectFromPath(path);



    //var payloadType = chassisObjectToUse.getPayloadHolder().getPayloadType();
    ////Payload Type
    //var payloadTypeOption = getPayloadTypeOptions()
    //     .filter(function (option) {
    //         if (option.id === payloadType) {
    //             return option;
    //         } else if (option.id === config.PAYLOAD_TYPES.SIMPLE && (payloadType === '' || payloadType === undefined)) {
    //             return option;
    //         }
    //     })[0];

    //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.CHASSISPAYLOADTYPE, config.INPUT_TYPE.SELECT)
    //.addSubscription(function (val) {

    //    var intercomMetaData = {};
    //    intercomMetaData[config.INTERCOM_METADATA_ITEM.FROM_TYPE] = chassisObjectToUse.getPayloadHolder().getPayloadType();
    //    intercomMetaData[config.INTERCOM_METADATA_ITEM.TO_TYPE] = val;

    //    chassisObjectToUse.getPayloadHolder().setPayloadType(val);

    //    dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.CHANGED_PAYLOAD_TYPE, intercomMetaData));
    //})
    //.setVal(payloadTypeOption), path);


    path = path + '.' + payload.getId();

    if (payload.getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {

                    

        //Description
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.DETAILEDPAYLOADDESCRIPTION, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
            .setter(function (newValue) {
                payload.setDescription(newValue)
            })
            .getter(function () {
                return payload.getDescription();
            })
            .visiblePermission(getObjectVisibilityPermission(payload))
            .readOnlyPermission(getObjectReadOnlyPermission(payload, config.MENU_SECTION.SPECIFICATION))
            .addSubscription(function (val) {
                var intercomMetaData = {};
                intercomMetaData[config.INTERCOM_METADATA_ITEM.OLD_DESCRIPTION] = payload.getDescription();
                intercomMetaData[config.INTERCOM_METADATA_ITEM.NEW_DESCRIPTION] = val;
                intercomMetaData[config.INTERCOM_METADATA_ITEM.CHASSIS_TYPE] = chassisObjectToUse.getChassisObjectName();

                payload.setDescription(val);
                updateAccessoryMenuLabelCallback(val, path, payload.getAccessoryType());

                dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.RENAMED_PAYLOAD_ITEM, intercomMetaData));

            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.DETAILEDPAYLOADDESCRIPTION)
                .addOpBlock(function (newValue, oldValue) {
                    if (newValue === '') {
                        throw 1;
                    }
                })
            ), path);

        //////Dimensions

        ////Size

        //Length
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.DETAILEDPAYLOADLENGTH, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return payload.getLength();
            })
            .setter(function (newValue) {
                payload.setLength(newValue);
            })
            .visiblePermission(getObjectVisibilityPermission(payload))
            .readOnlyPermission(getObjectReadOnlyPermission(payload, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
            //.visiblePermission(function () {
            //    return payload.getSource() !== config.VEHICLE_SOURCE_TYPES.STANDARD && !(payload.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(payload));
            //})
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.DETAILEDPAYLOADLENGTH)), path);

        //Height
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.DETAILEDPAYLOADHEIGHT, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return payload.getHeight();
            })
            .setter(function (newValue) {
                payload.setHeight(newValue);
            })
            .visiblePermission(getObjectVisibilityPermission(payload))
            .readOnlyPermission(getObjectReadOnlyPermission(payload, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
            //.visiblePermission(function () {
            //    return payload.getSource() !== config.VEHICLE_SOURCE_TYPES.STANDARD && !(payload.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(payload));
            //})
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.DETAILEDPAYLOADHEIGHT)), path);





        //Width
        let detailedPayloadWidthAOG = new AttributeObservableGroup(config.VALUE_TYPE.DETAILEDPAYLOADWIDTH, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return payload.getWidth();
            })
            .setter(function (newValue) {
                payload.setWidth(newValue);
            })
            .visiblePermission(getObjectVisibilityPermission(payload))
            .readOnlyPermission(getObjectReadOnlyPermission(payload, config.MENU_SECTION.SPECIFICATION_DIMENSIONS))
            //.visiblePermission(function () {
            //    return payload.getSource() !== config.VEHICLE_SOURCE_TYPES.STANDARD && !(payload.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(payload));
            //})
            

        let detailedPayloadWidthAOGValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.DETAILEDPAYLOADWIDTH);
        if(!globals.user.isLoadingAdministrator()) {
            detailedPayloadWidthAOGValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.COMBINATIONOVERALLWIDTH, rigOps.getCombinationOverallWidth);
        }
        detailedPayloadWidthAOG.addValidation(detailedPayloadWidthAOGValidation);
        addAttributeGroup(detailedPayloadWidthAOG, path);

        ////Position

        //From Headboard
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.DETAILEDPAYLOADFROMHEADBOARD, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return payload.getStartX();
            })
            .setter(function (newValue) {
                payload.setStartX(newValue);
            })
            .visiblePermission(getObjectVisibilityPermission(payload))
            .readOnlyPermission(getObjectReadOnlyPermission(payload, config.MENU_SECTION.POSITION))
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.DETAILEDPAYLOADFROMHEADBOARD)), path);

        
        

            //From Left Sidewall
        let detailedPayloadFromLeftSidewallAOG = new AttributeObservableGroup(config.VALUE_TYPE.DETAILEDPAYLOADFROMLEFTSIDEWALL, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return payload.getStartZ();
            })
            .setter(function (newValue) {
                payload.setStartZ(newValue);
            })
            .visiblePermission(getObjectVisibilityPermission(payload))
            .readOnlyPermission(getObjectReadOnlyPermission(payload, config.MENU_SECTION.POSITION))
            


        let detailedPayloadFromLeftSidewallAOGValidation = new ValidationEngine(path + '.' + config.VALUE_TYPE.DETAILEDPAYLOADFROMLEFTSIDEWALL);
        if(!globals.user.isLoadingAdministrator()) {
            detailedPayloadFromLeftSidewallAOGValidation.addBackEndAttributeValidationStep(config.VALUE_TYPE.COMBINATIONOVERALLWIDTH, rigOps.getCombinationOverallWidth);
        }
        detailedPayloadFromLeftSidewallAOG.addValidation(detailedPayloadFromLeftSidewallAOGValidation);
        addAttributeGroup(detailedPayloadFromLeftSidewallAOG, path);


        //Above Deck
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.DETAILEDPAYLOADABOVEDECK, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return payload.getStartY();
            })
            .setter(function (newValue) {
                payload.setStartY(newValue);
            })
            .visiblePermission(getObjectVisibilityPermission(payload))
            .readOnlyPermission(getObjectReadOnlyPermission(payload, config.MENU_SECTION.POSITION))
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.DETAILEDPAYLOADABOVEDECK)), path);


        //////Weights

        


        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.DETAILEDPAYLOADWEIGHTTYPE, config.INPUT_TYPE.SELECT)
            .addSubscription(function (val) {
                if (val === config.PAYLOAD_WEIGHT_TYPES.WEIGHT) {
                    payload.setMass(payload.getPayload() * payload.getDensity());
                } else {
                    if (payload.getUnitOfMeasure().toUpperCase() === globals.user.getActiveMeasurementSystem().unitMass.toUpperCase()) {
                        payload.setUnitOfMeasure(globals.user.getActiveMeasurementSystem().unitVolume);
                    }
                    payload.setPayload(payload.getMass() / payload.getDensity());
                }
                payload.setWeightType(val);
            })
            .visiblePermission(getObjectVisibilityPermission(payload))
            .readOnlyPermission(getObjectReadOnlyPermission(payload, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
            .addLinkedAOG(path + '.' + config.VALUE_TYPE.DETAILEDPAYLOADDENSITY)
            .optionGetter(function () { return getWeightTypeOption(payload); }), path);

        //Payload
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.DETAILEDPAYLOADPAYLOAD, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.NONE)
            .getter(function () {
                return payload.getPayload()
            })
            .setter(function (newValue) {
                payload.setPayload(newValue);
            })
            .visiblePermission(getObjectVisibilityPermission(payload, showDetailedPayloadDensityRelatedAttribute))
            .readOnlyPermission(getObjectReadOnlyPermission(payload, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
            //.visiblePermission(showDetailedPayloadDensityRelatedAttribute)
            //.addOverrideMechanism(getChassisObjectToUseFunc,
            //    function (val) { payload.setSpecifyMassOverride(val); },
            //    function () {
            //        //if (getConfiguration().getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            //        //    return getOriginalOffer().getConfiguration().getPayloadHolder().getPayloadObjects()[0].getMass();
            //        //} else {
            //        //    return getOriginalOffer().getConfiguration().getBody().getPayloadHolder().getPayloadObjects()[0].getMass();
            //        //}
            //        return rigOps.getMaximisedLoad();//getOriginalOffer().getConfiguration().getPayloadHolder().getPayloadObjects()[0].getMass();
            //        //#unfinished, this function will need to cater for retrieving original val from Trailer1 or Trailer2 also depending on path
            //    },
            //    function () { return payload.getSpecifyMassOverride(); })
            //.overridePermission(function () { return chassisObjectToUse.getPayloadHolder().getPayloadObjects().length === 1; })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.DETAILEDPAYLOADPAYLOAD))
            // .alwaysNotifyValueSubscribers() //this is required when the value might change based on selecting an option from an associated drop down
            , path);

            // .addFormattingRule(function () {
            //     return true
            // }, function (value) {
            //     //return Globalize.format(value, "n1") + ' ' + payload.getUnitOfMeasure();
            //     return getUIFormattedPayloadValue(value, payload);
            // }, config.INCREMENT_TYPE.NONE/*, function (newValue) {
            //     return newValue;
            // }????*/)

            
        //Unit Of Measure

        //var unitOfMeasureOption = getUnitOfMeasureOptions()
        //     .filter(function (option) {
        //         if (option.id === payload.getUnitOfMeasure()) {
        //             return option;
        //         } else if (option.id === config.PAYLOAD_UNIT_OF_MEASURE_TYPES.MASS && (payload.getUnitOfMeasure() === '' || payload.getUnitOfMeasure() === undefined)) {
        //             return option;
        //         } else if (option.description === payload.getUnitOfMeasure()) {
        //             return option;
        //         }
        //     })[0];

        //if (unitOfMeasureOption === undefined) {

        //    var id = payload.getUnitOfMeasure();//payload.getUnitOfMeasure().toUpperCase().replace(' ', '');
        //    var description = payload.getUnitOfMeasure();

        //    unitOfMeasureOption = { id: id, description: description };
        //    unitOfMeasureOptions.push(unitOfMeasureOption);
        //}

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.DETAILEDPAYLOADUNITOFMEASURE, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
            .setter(function (newValue, aog) {
                payload.setUnitOfMeasure(newValue);
                doActionsAfterSuccesfulValidation();
                // aog.getLinkedAOGs().forEach(function (linkedAOGID) {
                //     var linkedAOG = getAttributeGroup(linkedAOGID);
                //     linkedAOG.formatted.value = linkedAOG.value.value;
                // });
            })
            .getter(function () {
                return payload.getUnitOfMeasure();
            })
            .visiblePermission(getObjectVisibilityPermission(payload, showDetailedPayloadDensityRelatedAttribute))
            .readOnlyPermission(getObjectReadOnlyPermission(payload, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
            .addLinkedAOG(path + '.' + config.VALUE_TYPE.DETAILEDPAYLOADDENSITY)
            .addLinkedAOG(path + '.' + config.VALUE_TYPE.DETAILEDPAYLOADPAYLOAD)
            //.visiblePermission(showDetailedPayloadDensityRelatedAttribute)
            /*.addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.DETAILEDPAYLOADUNITOFMEASURE, config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_AND_OPS))*/
            , path);


        //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.DETAILEDPAYLOADUNITOFMEASURE, config.INPUT_TYPE.SELECT)
        //.addSubscription(function (val) {
        //    var predefinedItem = getUnitOfMeasureOptions().filter(function (untiOfMeasureOption) {
        //        return untiOfMeasureOption.id === val;
        //    })[0];
        //    if (predefinedItem !== undefined) {
        //        payload.setUnitOfMeasure(predefinedItem.description);
        //    } else {
        //        payload.setUnitOfMeasure(val);
        //    }

        //    //getAttributeGroup(path + '.' + config.VALUE_TYPE.PAYLOADPAYLOAD).formatted(payload.getPayload());
        //    //payload.setPayload(payload.getPayload());
        //})
        //.setVal(unitOfMeasureOption), path);

        //Density
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.DETAILEDPAYLOADDENSITY, config.INPUT_TYPE.TEL, config.INCREMENT_TYPE.MASS)
            .getter(function () {
                return payload.getDensity()
            })
            .setter(function (newValue) {
                payload.setDensity(newValue);
            })
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.DETAILEDPAYLOADDENSITY))
            //.visiblePermission(function () {
            //    return payload.getUnitOfMeasure() !== getActiveOffer().getMeasurementSystem().unitMass;
            //})
            .visiblePermission(getObjectVisibilityPermission(payload, showDetailedPayloadDensityRelatedAttribute))
            .readOnlyPermission(getObjectReadOnlyPermission(payload, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
            //.visiblePermission(showDetailedPayloadDensityRelatedAttribute)
            // .alwaysNotifyValueSubscribers() //this is required when the value might change based on selecting an option from an associated drop down
            .addFormattingRule(function () {
                return true
            }, function (value) {
                //return Globalize.format(value * getActiveOffer().getMeasurementSystem().massConversion, "n3") + ' ' + getActiveOffer().getMeasurementSystem().unitMass + '/' + payload.getUnitOfMeasure();
                return getUIFormattedPayloadDensityValue(value, payload);
            }, config.INCREMENT_TYPE.MASS), path);

        //Mass
        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.DETAILEDPAYLOADMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
            .getter(function () {
                return payload.getMass();
            })
            .setter(function (newValue) {
                payload.setMass(newValue);
            })
            /*.visiblePermission(function () {
                return payload.getUnitOfMeasure() !== getActiveOffer().getMeasurementSystem().unitMass;
            })*/
            .visiblePermission(getObjectVisibilityPermission(payload))
            .readOnlyPermission(getObjectReadOnlyPermission(payload, config.MENU_SECTION.SPECIFICATION_WEIGHTS, function () {
                return showDetailedPayloadDensityRelatedAttribute();
            }))
            //.readOnlyPermission(function () {
            //    return showDetailedPayloadDensityRelatedAttribute();
            //})
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.DETAILEDPAYLOADMASS, config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_ONLY)), path);

        setupPayloadCOGObservableGroups();

        
    } else {


        //SinglePoint, Maximised, Uniform etc.

        addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SIMPLEPAYLOADMASS, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
            .getter(function () {
                return payload.getMass()
            })
            .setter(function (newValue) {
                payload.setMass(newValue);
            })
            .addOverrideMechanism(getChassisObjectToUseFunc,
                function (val) {
                    payload.setSpecifyMassOverride(val);
                    //getActiveOffer().getRig().getChassisObjects().forEach(function (chassisObject) {
                    //    chassisObject.getPayloadHolder().getSimplePayloadObject().setSpecifyMassOverride(val);
                    //});
                    var tempVehicle, tempTrailer1;
                    if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
                        tempTrailer1 = getActiveOffer().getRig().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getPayloadHolder().getPayloadObjects().length > 0) {
                            getAttributeGroup(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.ACCESSORY_TYPES.PAYLOAD + '.' + tempTrailer1.getPayloadHolder().getSimplePayloadObject().getId() + '.' + config.VALUE_TYPE.SIMPLEPAYLOADMASS).override.value = val;
                        }

                    } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
                        tempVehicle = getActiveOffer().getRig().getVehicle();
                        if (tempVehicle.getPayloadHolder().getPayloadObjects().length > 0) {
                            getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.PAYLOAD + '.' + tempVehicle.getPayloadHolder().getSimplePayloadObject().getId() + '.' + config.VALUE_TYPE.SIMPLEPAYLOADMASS).override.value = val;
                        }
                        var tempTrailer2 = getActiveOffer().getRig().getTrailer2();
                        if (tempTrailer2 !== null && tempTrailer2.getPayloadHolder().getPayloadObjects().length > 0) {
                            getAttributeGroup(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.ACCESSORY_TYPES.PAYLOAD + '.' + tempTrailer2.getPayloadHolder().getSimplePayloadObject().getId() + '.' + config.VALUE_TYPE.SIMPLEPAYLOADMASS).override.value = val;
                        }
                    } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
                        tempVehicle = getActiveOffer().getRig().getVehicle();
                        if (tempVehicle.getPayloadHolder().getPayloadObjects().length > 0) {
                            getAttributeGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.PAYLOAD + '.' + tempVehicle.getPayloadHolder().getSimplePayloadObject().getId() + '.' + config.VALUE_TYPE.SIMPLEPAYLOADMASS).override.value = val;
                        }
                        tempTrailer1 = getActiveOffer().getRig().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getPayloadHolder().getPayloadObjects().length > 0) {
                            getAttributeGroup(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.ACCESSORY_TYPES.PAYLOAD + '.' + tempTrailer1.getPayloadHolder().getSimplePayloadObject().getId() + '.' + config.VALUE_TYPE.SIMPLEPAYLOADMASS).override.value = val;
                        }
                    }


                    //refreshData(new Date().getTime());
                },
                function () {
                    //return rigOps.getMaximisedLoad();
                    clearActiveLegislationQuickLookupObjects();
                    if (needToMaximise(rigOps)) {
                        rigOps.maximisePayloads();
                    }
                    return rigOps.getMaximisedPayloadForChassis(path);
                },
                function () {
                    return payload.getSpecifyMassOverride();
                })
            .overridePermission(function () {
                return true;
            })
            .visiblePermission(getObjectVisibilityPermission(payload, showPayloadSimpleMenu))
            .readOnlyPermission(getObjectReadOnlyPermission(payload, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
            //.visiblePermission(showPayloadSimpleMenu)
            .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.SIMPLEPAYLOADMASS)), path);

        setupPayloadCOGObservableGroups();

        //var cogTypeOption = getCOGTypeOptions()
        //      .filter(function (option) {
        //          if (option.id === payload.getCOGType()) {
        //              return option;
        //          } else if (option.id === config.COG_TYPES.LENGTH && (payload.getCOGType() === '' || payload.getCOGType() === undefined)) {
        //              return option;
        //          }
        //      })[0];

        //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SIMPLEPAYLOADCOGTYPE, config.INPUT_TYPE.SELECT)
        //.addSubscription(function (val) {
        //    if (val !== payload.getCOGType()) {
        //        payload.setCOGType(val);
        //        if (val === config.COG_TYPES.LENGTH) {
        //            payload.setHorizontalCOG(payload.getLength() * payload.getHorizontalCOG() / 100);
        //        } else {
        //            payload.setHorizontalCOG(utils.returnValueOrZeroIfNanOrInfinity((payload.getHorizontalCOG() / payload.getLength()) * 100));
        //        }
        //    }
        //})
        //.visiblePermission(function () { return false })
        //.setVal(cogTypeOption), path);

        //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SIMPLEPAYLOADHORIZONTALCOG, config.INPUT_TYPE.UPDOWNBOX)
        //    .getter(function () { return payload.getHorizontalCOG(); })
        //    .setter(function (newValue) { payload.setHorizontalCOG(newValue); })
        //    .addOverrideMechanism(getChassisObjectToUseFunc,
        //    function (val) {
        //        payload.setHorizontalCOGOverride(val);
        //    },
        //    function () {
        //        //if (getConfiguration().getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //        //    return originalChassisObjectToUse.getPayloadHorizontalCOG();
        //        //} else {
        //        //    return originalChassisObjectToUse.getAccessoryHolder().getBody().getPayloadHorizontalCOG();
        //        //}
        //        if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE)) {
        //            return originalChassisObjectToUse.getPayloadHorizontalCOG();
        //        } else if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) ||
        //                    (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD)) {
        //            return originalChassisObjectToUse.getPayloadHorizontalCOG();
        //        } else if (((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM) ||
        //            (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM)) &&
        //            utils.itemHasGraphic(chassisObjectToUse)) {
        //            return originalChassisObjectToUse.getPayloadHorizontalCOG();
        //        } else {
        //            return originalChassisObjectToUse.getAccessoryHolder().getBody().getPayloadHorizontalCOG();
        //        }

        //    },
        //    function () { return payload.getHorizontalCOGOverride(); })
        //    .overridePermission(payloadCOGOverridePermissionFunc)
        //    .visiblePermission(showPayloadSimpleMenu)
        //    .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.SIMPLEPAYLOADHORIZONTALCOG))
        //    .addFormattingRule(function () { return payload.getCOGType() === config.COG_TYPES.PERCENTAGE }, getUIFormattedPercentageValue, config.INCREMENT_TYPE.PERCENTAGE), path);

        //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SIMPLEPAYLOADVERTICALCOG, config.INPUT_TYPE.UPDOWNBOX)
        //    .getter(function () { return payload.getVerticalCOG(); })
        //    .setter(function (newValue) { payload.setVerticalCOG(newValue); })
        //    .addOverrideMechanism(getChassisObjectToUseFunc,
        //    function (val) {
        //        payload.setVerticalCOGOverride(val);
        //    },
        //    function () {
        //        //if (getConfiguration().getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //        //    return originalChassisObjectToUse.getPayloadVerticalCOG();
        //        //} else {
        //        //    return originalChassisObjectToUse.getAccessoryHolder().getBody().getPayloadVerticalCOG();
        //        //}
        //        if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE)) {
        //            return originalChassisObjectToUse.getPayloadVerticalCOG();
        //        } else if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) ||
        //                    (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD)) {
        //            return originalChassisObjectToUse.getPayloadVerticalCOG();
        //        } else if (((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM) ||
        //            (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM)) &&
        //            utils.itemHasGraphic(chassisObjectToUse)) {
        //            return originalChassisObjectToUse.getPayloadVerticalCOG();
        //        } else {
        //            return originalChassisObjectToUse.getAccessoryHolder().getBody().getPayloadVerticalCOG();
        //        }

        //    },
        //    function () { return payload.getVerticalCOGOverride(); })
        //    .overridePermission(payloadCOGOverridePermissionFunc)
        //    .visiblePermission(/*function () {
        //        if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) ||
        //                    (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD)) {
        //            return true;
        //        } else {
        //            return true;
        //        }
        //    }*/showPayloadSimpleMenu)
        //    .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.SIMPLEPAYLOADVERTICALCOG))
        //    .addFormattingRule(function () { return payload.getCOGType() === config.COG_TYPES.PERCENTAGE }, getUIFormattedPercentageValue, config.INCREMENT_TYPE.PERCENTAGE), path);


        //addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SIMPLEPAYLOADLATERALCOG, config.INPUT_TYPE.UPDOWNBOX)
        //    .getter(function () { return payload.getLateralCOG(); })
        //    .setter(function (newValue) { payload.setLateralCOG(newValue); })
        //    .addOverrideMechanism(getChassisObjectToUseFunc,
        //    function (val) {
        //        payload.setLateralCOGOverride(val);
        //    },
        //    function () {
        //        //if (getConfiguration().getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //        //    return originalChassisObjectToUse.getPayloadVerticalCOG();
        //        //} else {
        //        //    return originalChassisObjectToUse.getAccessoryHolder().getBody().getPayloadVerticalCOG();
        //        //}
        //        if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE)) {
        //            return originalChassisObjectToUse.getPayloadLateralCOG();
        //        } else if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) ||
        //                    (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD)) {
        //            return originalChassisObjectToUse.getPayloadLateralCOG();
        //        } else if (((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM) ||
        //            (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM)) &&
        //            utils.itemHasGraphic(chassisObjectToUse)) {
        //            return originalChassisObjectToUse.getPayloadLateralCOG();
        //        } else {
        //            return originalChassisObjectToUse.getAccessoryHolder().getBody().getPayloadLateralCOG();
        //        }
        //        //#unfinished, this function will need to cater for retrieving original val from Trailer1 or Trailer2 also depending on path
        //    },
        //    function () { return payload.getLateralCOGOverride(); })
        //    .overridePermission(payloadCOGOverridePermissionFunc)
        //    .visiblePermission(showPayloadSimpleMenu)
        //    .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.SIMPLEPAYLOADLATERALCOG))
        //    .addFormattingRule(function () { return payload.getCOGType() === config.COG_TYPES.PERCENTAGE }, getUIFormattedPercentageValue, config.INCREMENT_TYPE.PERCENTAGE), path);

        
    }
    function getChassisObjectToUseFunc() {
        return chassisObjectToUse;
    }
   
    function showDetailedPayloadDensityRelatedAttribute() {
        return payload.getWeightType() === config.PAYLOAD_WEIGHT_TYPES.VOLUME;
    }
    function getWeightTypeOption(payload) {
        var weightType = payload.getWeightType();
        //Payload Type
        var weightTypeOption = getWeightTypeOptions()
            .filter(function (option) {
                if (option.id === weightType) {
                    return option;
                } else if (option.id === config.PAYLOAD_WEIGHT_TYPES.WEIGHT && (weightType === '' || weightType === undefined)) {
                    return option;
                }
            })[0];

        return weightTypeOption;
    }
    function setupPayloadCOGObservableGroups() {

        var horizontalPayloadCOGTypeValueType, horizontalPayloadCOGValueType, verticalPayloadCOGTypeValueType, verticalPayloadCOGValueType, lateralPayloadCOGTypeValueType, lateralPayloadCOGValueType;
        if (payload.getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {
            horizontalPayloadCOGTypeValueType = config.VALUE_TYPE.DETAILEDPAYLOADCOGTYPE;
            horizontalPayloadCOGValueType = config.VALUE_TYPE.DETAILEDPAYLOADHORIZONTALCOG;
            verticalPayloadCOGTypeValueType = config.VALUE_TYPE.DETAILEDPAYLOADVERTICALCOGTYPE;
            verticalPayloadCOGValueType = config.VALUE_TYPE.DETAILEDPAYLOADVERTICALCOG;
            lateralPayloadCOGTypeValueType = config.VALUE_TYPE.DETAILEDPAYLOADLATERALCOGTYPE;
            lateralPayloadCOGValueType = config.VALUE_TYPE.DETAILEDPAYLOADLATERALCOG;
        } else {
            horizontalPayloadCOGTypeValueType = config.VALUE_TYPE.SIMPLEPAYLOADCOGTYPE;
            horizontalPayloadCOGValueType = config.VALUE_TYPE.SIMPLEPAYLOADHORIZONTALCOG;
            verticalPayloadCOGTypeValueType = config.VALUE_TYPE.SIMPLEPAYLOADVERTICALCOGTYPE;
            verticalPayloadCOGValueType = config.VALUE_TYPE.SIMPLEPAYLOADVERTICALCOG;
            lateralPayloadCOGTypeValueType = config.VALUE_TYPE.SIMPLEPAYLOADLATERALCOGTYPE;
            lateralPayloadCOGValueType = config.VALUE_TYPE.SIMPLEPAYLOADLATERALCOG;
        }

        //COG Type 
        function getPayloadHorizontalCOGTypeOption() {
            var cogTypeOption = getCOGTypeOptions()
                .filter(function (option) {
                    if (option.id === payload.getCOGType()) {
                        return option;
                    } else if (option.id === config.COG_TYPES.LENGTH && (payload.getCOGType() === '' || payload.getCOGType() === undefined || payload.getCOGType() === null)) {
                        return option;
                    }
                })[0];
            return cogTypeOption;
        }

        addAttributeGroup(new AttributeObservableGroup(horizontalPayloadCOGTypeValueType, config.INPUT_TYPE.SELECT)
            .setVal(getPayloadHorizontalCOGTypeOption())
            .optionGetter(getPayloadHorizontalCOGTypeOption)
            .visiblePermission(getObjectVisibilityPermission(payload, payloadVisibleFunc))
            .readOnlyPermission(getObjectReadOnlyPermission(payload, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
            //.visiblePermission(payloadVisibleFunc)
            .addSubscription(function (val) {

                if (val !== payload.getCOGType()) {
                    payload.setCOGType(val);
                    if (val === config.COG_TYPES.LENGTH) {
                        payload.setHorizontalCOG(payload.getLength() * payload.getHorizontalCOG() / 100);
                    } else {
                        payload.setHorizontalCOG(utils.returnValueOrZeroIfNanOrInfinity((payload.getHorizontalCOG() / payload.getLength()) * 100));
                    }
                }
            }), path);

        if (payload.getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {
            if (!payloadCOGOverridePermissionFunc(path)) {
                payload.setHorizontalCOGOverride(true);
                payload.setVerticalCOGOverride(true);
                payload.setLateralCOGOverride(true);
            }
        }
        //Horizontal COG
        addAttributeGroup(new AttributeObservableGroup(horizontalPayloadCOGValueType, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return payload.getHorizontalCOG();
            })
            .setter(function (newValue) {
                payload.setHorizontalCOG(newValue);
            })
            .doNotIncludeUnit()
            .addOverrideMechanism(getChassisObjectToUseFunc,
                function (val) {
                    payload.setHorizontalCOGOverride(val);
                },
                function () {

                    var payloadHorizontalCOG;
                    if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE)) {
                        payloadHorizontalCOG = originalChassisObjectToUse.getPayloadHorizontalCOG();
                    } else if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) ||
                        (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD)) {
                        payloadHorizontalCOG = originalChassisObjectToUse.getPayloadHorizontalCOG();
                    } else if (((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM) ||
                        (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM)) &&
                        utils.itemHasGraphic(chassisObjectToUse)) {
                        payloadHorizontalCOG = originalChassisObjectToUse.getPayloadHorizontalCOG();
                    } else {
                        payloadHorizontalCOG = originalChassisObjectToUse.getAccessoryHolder().getBody().getPayloadHorizontalCOG();
                    }

                    var originalPayload = originalChassisObjectToUse.getPayloadHolder().getPayloadById(payload.getId());
                    if (payload.getCOGType() === config.COG_TYPES.LENGTH && originalPayload.getCOGType() === config.COG_TYPES.LENGTH || payload.getCOGType() === config.COG_TYPES.PERCENTAGE && originalPayload.getCOGType() === config.COG_TYPES.PERCENTAGE) {
                        return payloadHorizontalCOG;
                    } else if (payload.getCOGType() === config.COG_TYPES.LENGTH && originalPayload.getCOGType() === config.COG_TYPES.PERCENTAGE) {
                        return payloadHorizontalCOG * payload.getLength() / 100;
                    } else if (payload.getCOGType() === config.COG_TYPES.PERCENTAGE && originalPayload.getCOGType() === config.COG_TYPES.LENGTH) {
                        return payloadHorizontalCOG / payload.getLength() * 100;
                    } else {
                        return 0;
                    }


                },
                function () {
                    return payload.getHorizontalCOGOverride();
                })
            .overridePermission(payloadCOGOverridePermissionFunc)
            .visiblePermission(getObjectVisibilityPermission(payload, payloadVisibleFunc))
            .readOnlyPermission(getObjectReadOnlyPermission(payload, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
            //.visiblePermission(payloadVisibleFunc)
            .addValidation(new ValidationEngine(path + '.' + horizontalPayloadCOGValueType))
            .addFormattingRule(function () {
                return payload.getCOGType() === config.COG_TYPES.PERCENTAGE;
            }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);


        //Vertical COG Type 
        function getPayloadVerticalCOGTypeOption() {
            var verticalCOGTypeOption = getCOGTypeOptions()
                .filter(function (option) {
                    if (option.id === payload.getVerticalCOGType()) {
                        return option;
                    } else if (option.id === config.COG_TYPES.LENGTH && (payload.getVerticalCOGType() === '' || payload.getVerticalCOGType() === undefined || payload.getVerticalCOGType() === null)) {
                        return option;
                    }
                })[0];
            return verticalCOGTypeOption;
        }

        addAttributeGroup(new AttributeObservableGroup(verticalPayloadCOGTypeValueType, config.INPUT_TYPE.SELECT)
            .setVal(getPayloadVerticalCOGTypeOption())
            .optionGetter(getPayloadVerticalCOGTypeOption)
            .visiblePermission(getObjectVisibilityPermission(payload, payloadVisibleFunc))
            .readOnlyPermission(getObjectReadOnlyPermission(payload, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
            //.visiblePermission(payloadVisibleFunc)
            .addSubscription(function (val) {

                if (val !== payload.getVerticalCOGType()) {
                    payload.setVerticalCOGType(val);
                    if (val === config.COG_TYPES.LENGTH) {
                        payload.setVerticalCOG(payload.getHeight() * payload.getVerticalCOG() / 100);
                    } else {
                        payload.setVerticalCOG(utils.returnValueOrZeroIfNanOrInfinity((payload.getVerticalCOG() / payload.getHeight()) * 100));
                    }
                }
            }), path);

        //Vertical COG
        addAttributeGroup(new AttributeObservableGroup(verticalPayloadCOGValueType, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return payload.getVerticalCOG();
            })
            .setter(function (newValue) {
                payload.setVerticalCOG(newValue);
            })
            .doNotIncludeUnit()
            .addOverrideMechanism(getChassisObjectToUseFunc,
                function (val) {
                    payload.setVerticalCOGOverride(val);
                },
                function () {

                    var payloadVerticalCOG;

                    if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE)) {
                        payloadVerticalCOG = originalChassisObjectToUse.getPayloadVerticalCOG();
                    } else if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) ||
                        (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD)) {
                        payloadVerticalCOG = originalChassisObjectToUse.getPayloadVerticalCOG();
                    } else if (((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM) ||
                        (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM)) &&
                        utils.itemHasGraphic(chassisObjectToUse)) {
                        payloadVerticalCOG = originalChassisObjectToUse.getPayloadVerticalCOG();
                    } else {
                        payloadVerticalCOG = originalChassisObjectToUse.getAccessoryHolder().getBody().getPayloadVerticalCOG();
                    }

                    var originalPayload = originalChassisObjectToUse.getPayloadHolder().getPayloadById(payload.getId());
                    if (payload.getVerticalCOGType() === config.COG_TYPES.LENGTH && originalPayload.getVerticalCOGType() === config.COG_TYPES.LENGTH || payload.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE && originalPayload.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE) {
                        return payloadVerticalCOG;
                    } else if (payload.getVerticalCOGType() === config.COG_TYPES.LENGTH && originalPayload.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE) {
                        return payloadVerticalCOG * payload.getHeight() / 100;
                    } else if (payload.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE && originalPayload.getVerticalCOGType() === config.COG_TYPES.LENGTH) {
                        return payloadVerticalCOG / payload.getHeight() * 100;
                    } else {
                        return 0;
                    }
                },
                function () {
                    return payload.getVerticalCOGOverride();
                })
            .overridePermission(payloadCOGOverridePermissionFunc)
            .visiblePermission(getObjectVisibilityPermission(payload, payloadVisibleFunc))
            .readOnlyPermission(getObjectReadOnlyPermission(payload, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
            //.visiblePermission(payloadVisibleFunc)
            .addValidation(new ValidationEngine(path + '.' + verticalPayloadCOGValueType))
            .addFormattingRule(function () {
                return payload.getVerticalCOGType() === config.COG_TYPES.PERCENTAGE;
            }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);



        //Lateral COG Type 
        function getPayloadLateralCOGTypeOption() {
            var lateralCOGTypeOption = getCOGTypeOptions()
                .filter(function (option) {
                    if (option.id === payload.getLateralCOGType()) {
                        return option;
                    } else if (option.id === config.COG_TYPES.LENGTH && (payload.getLateralCOGType() === '' || payload.getLateralCOGType() === undefined || payload.getLateralCOGType() === null)) {
                        return option;
                    }
                })[0];
            return lateralCOGTypeOption;
        }

        addAttributeGroup(new AttributeObservableGroup(lateralPayloadCOGTypeValueType, config.INPUT_TYPE.SELECT)
            .setVal(getPayloadLateralCOGTypeOption())
            .optionGetter(getPayloadLateralCOGTypeOption)
            .visiblePermission(getObjectVisibilityPermission(payload, payloadVisibleFunc))
            .readOnlyPermission(getObjectReadOnlyPermission(payload, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
            //.visiblePermission(payloadVisibleFunc)
            .addSubscription(function (val) {

                if (val !== payload.getLateralCOGType()) {
                    payload.setLateralCOGType(val);
                    if (val === config.COG_TYPES.LENGTH) {
                        payload.setLateralCOG(payload.getWidth() * payload.getLateralCOG() / 100);
                    } else {
                        payload.setLateralCOG(utils.returnValueOrZeroIfNanOrInfinity((payload.getLateralCOG() / payload.getWidth()) * 100));
                    }
                }
            }), path);

        //Lateral COG
        addAttributeGroup(new AttributeObservableGroup(lateralPayloadCOGValueType, config.INPUT_TYPE.UPDOWNBOX)
            .getter(function () {
                return payload.getLateralCOG();
            })
            .setter(function (newValue) {
                payload.setLateralCOG(newValue);
            })
            .doNotIncludeUnit()
            .addOverrideMechanism(getChassisObjectToUseFunc,
                function (val) {
                    payload.setLateralCOGOverride(val);
                },
                function () {

                    var payloadLateralCOG;

                    if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE)) {
                        payloadLateralCOG = originalChassisObjectToUse.getPayloadLateralCOG();
                    } else if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) ||
                        (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD)) {
                        payloadLateralCOG = originalChassisObjectToUse.getPayloadLateralCOG();
                    } else if (((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM) ||
                        (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM)) &&
                        utils.itemHasGraphic(chassisObjectToUse)) {
                        payloadLateralCOG = originalChassisObjectToUse.getPayloadLateralCOG();
                    } else {
                        payloadLateralCOG = originalChassisObjectToUse.getAccessoryHolder().getBody().getPayloadLateralCOG();
                    }

                    var originalPayload = originalChassisObjectToUse.getPayloadHolder().getPayloadById(payload.getId());
                    if (payload.getLateralCOGType() === config.COG_TYPES.LENGTH && originalPayload.getLateralCOGType() === config.COG_TYPES.LENGTH || payload.getLateralCOGType() === config.COG_TYPES.PERCENTAGE && originalPayload.getLateralCOGType() === config.COG_TYPES.PERCENTAGE) {
                        return payloadLateralCOG;
                    } else if (payload.getLateralCOGType() === config.COG_TYPES.LENGTH && originalPayload.getLateralCOGType() === config.COG_TYPES.PERCENTAGE) {
                        return payloadLateralCOG * payload.getWidth() / 100;
                    } else if (payload.getLateralCOGType() === config.COG_TYPES.PERCENTAGE && originalPayload.getLateralCOGType() === config.COG_TYPES.LENGTH) {
                        return payloadLateralCOG / payload.getWidth() * 100;
                    } else {
                        return 0;
                    }

                },
                function () {
                    return payload.getLateralCOGOverride();
                })
            .overridePermission(payloadCOGOverridePermissionFunc)
            .visiblePermission(getObjectVisibilityPermission(payload, payloadVisibleFunc))
            .readOnlyPermission(getObjectReadOnlyPermission(payload, config.MENU_SECTION.SPECIFICATION_WEIGHTS))
            //.visiblePermission(payloadVisibleFunc)
            .addValidation(new ValidationEngine(path + '.' + lateralPayloadCOGValueType))
            .addFormattingRule(function () {
                return payload.getLateralCOGType() === config.COG_TYPES.PERCENTAGE;
            }, getUIFormattedPercentageValueNoUnit, config.INCREMENT_TYPE.PERCENTAGE), path);
    }

    function payloadVisibleFunc() {
        if (payload.getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {
            return true;
        } else {
            return showPayloadSimpleMenu(path);
        }
    }

    function payloadCOGOverridePermissionFunc() {
        if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE)) {
            return chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD;
        } else if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) ||
            (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD)) {
            return true;
        } else if (((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM) ||
            (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM)) &&
            utils.itemHasGraphic(chassisObjectToUse)) {
            return true;
        } else {
            return chassisObjectToUse.getAccessoryHolder().getBody().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD;
        }
    }

   
}

//function createSinglePointPayloadObservables(payload, path) {

//    var chassisObjectToUse = rigOps.getChassisObjectFromPath(path),
//        originalChassisObjectToUse = rigOps.getOriginalChassisObjectFromPath(path);

//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SINGLEPOINTPAYLOAD, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
//        .getter(function () { return payload.getMass() })
//        .setter(function (newValue) { payload.setMass(newValue); })
//        .addOverrideMechanism(getChassisObjectToUseFunc,
//            function (val) { payload.setSpecifyMassOverride(val); },
//            function () {
//                //if (getConfiguration().getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
//                //    return getOriginalOffer().getConfiguration().getPayloadHolder().getPayloadObjects()[0].getMass();
//                //} else {
//                //    return getOriginalOffer().getConfiguration().getBody().getPayloadHolder().getPayloadObjects()[0].getMass();
//                //}
//                return rigOps.getMaximisedLoad();//getOriginalOffer().getConfiguration().getPayloadHolder().getPayloadObjects()[0].getMass();
//                //#unfinished, this function will need to cater for retrieving original val from Trailer1 or Trailer2 also depending on path
//            },
//            function () { return payload.getSpecifyMassOverride(); })
//        .overridePermission(function () { return true; })
//        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.SINGLEPOINTPAYLOAD)), path);

//    var cogTypeOption = getCOGTypeOptions()
//          .filter(function (option) {
//              if (option.id === payload.getCOGType()) {
//                  return option;
//              } else if (option.id === config.COG_TYPES.LENGTH && (payload.getCOGType() === '' || payload.getCOGType() === undefined)) {
//                  return option;
//              }
//          })[0];

//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.PAYLOADCOGTYPE, config.INPUT_TYPE.SELECT)
//    .addSubscription(function (val) { payload.setCOGType(val); })
//        .visiblePermission(function () { return false})
//    .setVal(cogTypeOption), path);

//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SINGLEPOINTHORIZONTALCOG, config.INPUT_TYPE.UPDOWNBOX)
//        .getter(function () { return payload.getHorizontalCOG(); })
//        .setter(function (newValue) { payload.setHorizontalCOG(newValue); })
//        .addOverrideMechanism(getChassisObjectToUseFunc,
//        function (val) {
//            payload.setHorizontalCOGOverride(val);
//        },
//        function () {
//            //if (getConfiguration().getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
//            //    return originalChassisObjectToUse.getPayloadHorizontalCOG();
//            //} else {
//            //    return originalChassisObjectToUse.getAccessoryHolder().getBody().getPayloadHorizontalCOG();
//            //}
//            if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE)) {
//                return originalChassisObjectToUse.getPayloadHorizontalCOG();
//            } else if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) ||
//                        (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD)) {
//                return originalChassisObjectToUse.getPayloadHorizontalCOG();
//            } else {
//                return originalChassisObjectToUse.getAccessoryHolder().getBody().getPayloadHorizontalCOG();
//            }
//            //#unfinished, this function will need to cater for retrieving original val from Trailer1 or Trailer2 also depending on path
//        },
//        function () { return payload.getHorizontalCOGOverride(); })
//        .overridePermission(singlePointCOGOverridePermissionFunc)
//        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.SINGLEPOINTHORIZONTALCOG))
//        .addFormattingRule(function () { return payload.getCOGType() === config.COG_TYPES.PERCENTAGE }, getUIFormattedPercentageValue, config.INCREMENT_TYPE.PERCENTAGE), path);

//    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.SINGLEPOINTVERTICALCOG, config.INPUT_TYPE.UPDOWNBOX)
//        .getter(function () { return payload.getVerticalCOG(); })
//        .setter(function (newValue) { payload.setVerticalCOG(newValue); })
//        .addOverrideMechanism(getChassisObjectToUseFunc,
//        function (val) {
//            payload.setVerticalCOGOverride(val);
//        },
//        function () {
//            //if (getConfiguration().getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
//            //    return originalChassisObjectToUse.getPayloadVerticalCOG();
//            //} else {
//            //    return originalChassisObjectToUse.getAccessoryHolder().getBody().getPayloadVerticalCOG();
//            //}
//            if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE)) {
//                return originalChassisObjectToUse.getPayloadVerticalCOG();
//            } else if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) ||
//                        (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD)) {
//                return originalChassisObjectToUse.getPayloadVerticalCOG();
//            } else {
//                return originalChassisObjectToUse.getAccessoryHolder().getBody().getPayloadVerticalCOG();
//            }
//            //#unfinished, this function will need to cater for retrieving original val from Trailer1 or Trailer2 also depending on path
//        },
//        function () { return payload.getVerticalCOGOverride(); })
//        .overridePermission(function () {
//            if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE)) {
//                return chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD;
//            } else if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) ||
//                        (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD)) {
//                return true;
//            } else {
//                return chassisObjectToUse.getAccessoryHolder().getBody().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD;
//            }
//        })
//        .visiblePermission(function () {
//            if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) ||
//                        (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD)) {
//                return true;
//            } else {
//                return true;
//            }
//        })
//        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.SINGLEPOINTVERTICALCOG))
//        .addFormattingRule(function () { return payload.getCOGType() === config.COG_TYPES.PERCENTAGE }, getUIFormattedPercentageValue, config.INCREMENT_TYPE.PERCENTAGE), path);

//    function getChassisObjectToUseFunc() {
//        return chassisObjectToUse;
//    }

//    function singlePointCOGOverridePermissionFunc() {
//        if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE)) {
//            return chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD;
//        } else if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) || 
//                    (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD)) {
//            return true;
//        }else {
//            return chassisObjectToUse.getAccessoryHolder().getBody().getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD;
//        }
//    }
//}

function addWaypointObservables(waypoints) {
    waypoints.forEach(function (waypoint) {
        createWaypointObjectObservables(waypoint, 'PERFORMANCE.WAYPOINTS');
    });
}

function createWaypointObjectObservables(waypoint, path) {

    path = path + '.' + waypoint.getInternalId();

    //Waypoint Address
    //Description
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.WAYPOINTDESCRIPTION, config.INPUT_TYPE.TEXT, config.INCREMENT_TYPE.NONE)
        .setter(waypoint.setDescription)
        .getter(waypoint.getDescription)
        .addSubscription(function (val) {
            waypoint.setDescription(val);
        }), path);

    //Payload Loaded
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.WAYPOINTPAYLOADLOADED, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(waypoint.getPayloadLoaded)
        .setter(waypoint.setPayloadLoaded)
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.WAYPOINTPAYLOADLOADED)
            .addOpBlock(function () {
                updateWaypointNetPayloads();
            })
        ), path);

    //Payload offloaded
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.WAYPOINTPAYLOADOFFLOADED, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(waypoint.getPayloadOffloaded)
        .setter(waypoint.setPayloadOffloaded)
        //.overridePermission(function () { return true; })
        .addValidation(new ValidationEngine(path + '.' + config.VALUE_TYPE.WAYPOINTPAYLOADOFFLOADED)
            .addOpBlock(function () {
                updateWaypointNetPayloads();
            })
        ), path);

    //Net payload
    addAttributeGroup(new AttributeObservableGroup(config.VALUE_TYPE.WAYPOINTNETPAYLOAD, config.INPUT_TYPE.UPDOWNBOX, config.INCREMENT_TYPE.MASS)
        .getter(waypoint.getNetPayload)
        .setter(waypoint.setNetPayload)
        .readOnly(), path);

}

function updateWaypointNetPayloads() {
    var previousNetPayload = 0;
    waypoints.value.forEach(function (waypoint) {
        waypoint.setNetPayload(previousNetPayload + waypoint.getPayloadLoaded() - waypoint.getPayloadOffloaded());
        previousNetPayload = waypoint.getNetPayload();
    });
}

function updateDrawing() {
    //validPdfOnLocalStorage.value = false;
    //dataManager.remove(OPERATION.ConfigDrawing, vehicleId.value + "_configurationDrawing");
    //changeListenerCallback("ACCESSORY");
    doActionsAfterSuccesfulValidation();
}

function deleteObject(path, id) {

    var chassisObjectToUse, accessoryHolderToUse, originalChassisObjectToUse, originalAccessoryHolderToUse, payloadHolderToUse, originalPayloadHolderToUse;
    if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
        chassisObjectToUse = getActiveOffer().getRig().getVehicle();
        originalChassisObjectToUse = getOriginalOffer().getRig().getVehicle();
    } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        chassisObjectToUse = getActiveOffer().getRig().getTrailer1();
        originalChassisObjectToUse = getOriginalOffer().getRig().getTrailer1();
    } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
        chassisObjectToUse = getActiveOffer().getRig().getTrailer2();
        originalChassisObjectToUse = getOriginalOffer().getRig().getTrailer2();
    }

    if (chassisObjectToUse !== undefined) {
        accessoryHolderToUse = chassisObjectToUse.getAccessoryHolder();
        payloadHolderToUse = chassisObjectToUse.getPayloadHolder();
        originalAccessoryHolderToUse = originalChassisObjectToUse.getAccessoryHolder();
        originalPayloadHolderToUse = originalChassisObjectToUse.getPayloadHolder();
    }
    //var objectId = getObjectNumberOrIdFromPathToObject(path);
    if (path.indexOf(config.OBJECT_TYPES.ACCESSORY) !== -1) {
        notifyIntercomAccessoryRemoved(config.INTERCOM_EVENT.REMOVED_EQUIPMENT, id, accessoryHolderToUse.getAccessoryById(id));
        chassisObjectToUse.deleteAccessory(id);
        originalChassisObjectToUse.deleteAccessory(id);
    } else if (path.indexOf(config.OBJECT_TYPES.AXLES) !== -1) {
        dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.REMOVED_AXLE, rigOps.getAxleFromPath(path).getIntercomMetadata()));
        rigOps.removeAxle(path, id);
        updateTyreRate();
        rigOps.resequenceTrailerAxles();
    } else if (path.indexOf(config.OBJECT_TYPES.FUEL_TANKS) !== -1) {
        //deleteFuelTank(objectId);
        chassisObjectToUse.deleteFuelTank(id);
    } else if (path.indexOf(config.ACCESSORY_TYPES.BODY) !== -1) {
        notifyIntercomAccessoryRemoved(config.INTERCOM_EVENT.REMOVED_BODY, id, accessoryHolderToUse.getAccessoryById(id));
        if (path.indexOf(config.OBJECT_TYPES.UPRIGHTS) !== -1) {
            //deleteAccessoryFromOffer(objectId);
            accessoryHolderToUse.getBody().getBodyUprightHolder().removeUprightById(id);
            originalAccessoryHolderToUse.getBody().getBodyUprightHolder().removeUprightById(id);
        } else {
            chassisObjectToUse.deleteAccessory(id);
            originalChassisObjectToUse.deleteAccessory(id);
        }

    } else if (path.indexOf(config.ACCESSORY_TYPES.PAYLOAD) !== -1) {
        notifyIntercomAccessoryRemoved(config.INTERCOM_EVENT.REMOVED_PAYLOAD_ITEM, id, payloadHolderToUse.getPayloadById(id));
        payloadHolderToUse.removePayloadById(id);
        originalPayloadHolderToUse.removePayloadById(id);
    } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        var trailer1ToRemove = getActiveOffer().getTrailer1();
        if (trailer1ToRemove !== null && trailer1ToRemove !== undefined) {
            getActiveOffer().getRig().removeChassisObject(trailer1ToRemove);
            getOriginalOffer().getRig().removeChassisObject(getOriginalOffer().getTrailer1());
        }
        getActiveOffer().setTrailer1Null();
        getOriginalOffer().setTrailer1Null();
        getActiveOffer().getRig().getVehicle().setPermissibleTotal(rigOps.getCombinationTotalPermissible());
        changeListenerCallback('Combination_Changed');
        refreshCombinationTotalAOGPermissions();
    } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
        var trailer2ToRemove = getActiveOffer().getTrailer2();
        if (trailer2ToRemove !== null && trailer2ToRemove !== undefined) {
            getActiveOffer().getRig().removeChassisObject(trailer2ToRemove);
            getOriginalOffer().getRig().removeChassisObject(getOriginalOffer().getTrailer2());
        }
        getActiveOffer().setTrailer2Null();
        getOriginalOffer().setTrailer2Null();
        getActiveOffer().getRig().getVehicle().setPermissibleTotal(rigOps.getCombinationTotalPermissible());
        changeListenerCallback('Combination_Changed');
        refreshCombinationTotalAOGPermissions();
    }

    deleteObservables(path);
    //refreshData(new Date().getTime());
    //refreshUILiveLabels(new Date().getTime());
    removeDeletedObjectsFromDynamicValidationGroups(path);
    doActionsAfterSuccesfulValidation('ITEM_DELETED', path);
    //if (getActiveOffer().getDataAvailability().ActiveCosting && getActiveOffer().getCosting() !== undefined) {
    //    updateFinanceCostPerMonthValues(true);
    //}
    updateCostingItems(true);
    clearPerformanceResults();
    //showShellSpinner(false);
    globals.hideSpinner();
}

function notifyIntercomAccessoryRemoved(event, id, accessoryItem) {
    var intercomObject;
    //if (event === config.INTERCOM_EVENT.REMOVED_BODY) {
    //    // get body
    //    intercomObject = getActiveOffer().getConfiguration().getBody().getIntercomMetadata();
    //} else {
    // get accessory
    //accessoryItem = accessoryHolder.getAccessoryById(id);
    if (accessoryItem !== null) {
        intercomObject = accessoryItem.getIntercomMetadata();
        dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(event, intercomObject));
    }
    //}
}

function notifyIntercomAxleRaisedOrDropped(axle) {
    var intercomObject, event;

    if (axle !== null) {
        intercomObject = axle.getIntercomMetadata();
        if (axle.getRaised()) {
            event = config.INTERCOM_EVENT.RAISED_AXLE;
        } else {
            event = config.INTERCOM_EVENT.DROPPED_AXLE;
        }
        dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(event, intercomObject));
    }

}

function copyObject(path, id) {
    var chassisObjectToUse = rigOps.getChassisObjectFromPath(path);
    if (path.indexOf(config.OBJECT_TYPES.ACCESSORY) !== -1) {
        //not yet supported
    } else if (path.indexOf(config.OBJECT_TYPES.AXLES) !== -1) {
        //not yet supported
    } else if (path.indexOf(config.OBJECT_TYPES.FUEL_TANKS) !== -1) {
        //not yet supported
    } else if (path.indexOf(config.ACCESSORY_TYPES.BODY) !== -1) {

        if (path.indexOf(config.OBJECT_TYPES.UPRIGHTS) !== -1) {
            
            var uprightToCopy = chassisObjectToUse.getAccessoryHolder().getBody().getBodyUprightHolder().getUprightById(id);
            var bodyLength = chassisObjectToUse.getAccessoryHolder().getBody().getLength();
            var newUpright = uprightToCopy.clone();
            newUpright.setId(globals.nextId());
            if (newUpright.getInsideFrontToUpright() + newUpright.getWidth() + newUpright.getWidth() < bodyLength) {
                newUpright.setInsideFrontToUpright(newUpright.getInsideFrontToUpright() + newUpright.getWidth());
            }
            chassisObjectToUse.getAccessoryHolder().getBody().getBodyUprightHolder().add(newUpright);
            var source = config.OBJECT_TYPES.UPRIGHTS;
            createObjectWithObservables(source, newUpright, path);
            if(typeof objectAddedListenerCallback === 'function') {
                objectAddedListenerCallback(source, newUpright, path, false, true);
            }
        } else {
            //not yet supported
        }
    } else if (path.indexOf(config.ACCESSORY_TYPES.PAYLOAD) !== -1) {
        
        var originalChassisObjectToUse = rigOps.getOriginalChassisObjectFromPath(path);
        var payloadToCopy = chassisObjectToUse.getPayloadHolder().getPayloadById(id);
        var payloadDeckLength;
        if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            payloadDeckLength = chassisObjectToUse.getBodyLengthInternal();
        } else if (chassisObjectToUse.getChassisObjectName() !== config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
            payloadDeckLength = chassisObjectToUse.getPayloadLength();
        } else {
            payloadDeckLength = chassisObjectToUse.getAccessoryHolder().getBody().getLength();
        }
        var newPayload = payloadToCopy.clone();
        //newUpright.setId(globals.nextId());
        if (newPayload.getStartX() + newPayload.getLength() + newPayload.getLength() < payloadDeckLength) {
            newPayload.setStartX(newPayload.getStartX() + newPayload.getLength());
        }
        chassisObjectToUse.getPayloadHolder().add(newPayload);
        originalChassisObjectToUse.getPayloadHolder().add(newPayload.clone(true));

        //updatePayload();
        //var source = config.OBJECT_TYPES.ACCESSORY;
        doCommonUpdateActions();
        preventUpdateDuringAOGOptionIdWatch = true;
        createObjectWithObservables(config.OBJECT_TYPES.PAYLOAD, newPayload, path);
        if(typeof objectAddedListenerCallback === 'function') {
            objectAddedListenerCallback(config.OBJECT_TYPES.ACCESSORY, newPayload, path, false, true);
        }
        nextTick(function(){
            preventUpdateDuringAOGOptionIdWatch = false;
        });
    }
}

function setAccessoryValuesToOriginalValues() {
    //cabGapRecommendedMin(getOriginalOffer().getConfiguration().getCabGapRecommendedMin());
    getAttributeGroup('VEHICLE.' + config.VALUE_TYPE.CABGAPRECOMMENDEDMIN).value.value = getOriginalOffer().getConfiguration().getCabGapRecommendedMin();
    getConfiguration().setCabGapRecommendedMin((getOriginalOffer().getConfiguration().getCabGapRecommendedMin()));
}

function deleteObservables(path) {
    var pathParts = path.split('.');
    var i;
    var curLevel = null;
    if (pathParts.length > 2) {
        for (i = 0; i < pathParts.length - 1; i++) {
            if (i === 0) {
                curLevel = attributeObservableGroups[pathParts[i]];
            } else {
                curLevel = curLevel[pathParts[i]];
            }

        }
    }

    if (curLevel !== null) {
        if (curLevel[pathParts[pathParts.length - 1]] !== undefined) {
            cleanUpPathLevel(curLevel[pathParts[pathParts.length - 1]])
            delete curLevel[pathParts[pathParts.length - 1]];
        }

    } else {
        if (attributeObservableGroups[pathParts[pathParts.length - 1]] !== undefined) {
            cleanUpPathLevel(attributeObservableGroups[pathParts[pathParts.length - 1]]);
            delete attributeObservableGroups[pathParts[pathParts.length - 1]];
        } else if (attributeObservableGroups[pathParts[0]] !== undefined && attributeObservableGroups[pathParts[0]][pathParts[pathParts.length - 1]] !== undefined) {
            cleanUpPathLevel(attributeObservableGroups[pathParts[0]][pathParts[pathParts.length - 1]]);
            delete attributeObservableGroups[pathParts[0]][pathParts[pathParts.length - 1]];
        }

    }

    function cleanUpPathLevel(pathLevel) {
        Object.keys(pathLevel).forEach(function (key) {
            if (pathLevel[key].cleanUp) {
                pathLevel[key].cleanUp();
            } else {
                cleanUpPathLevel(pathLevel[key]);
            }
        });
    }
}

function removeAttributeGroup(valueType) {
    for (var i = 0; i < attributeObservableGroups.length; i++) {
        if (attributeObservableGroups[i].valueType === valueType) {
            attributeObservableGroups.splice(i, 1);
            break;
        }
    }

}

function clearPDFImagesArray() {
    imagesArray = undefined;
}

function generatePdfPreview(generateEmail, pdfObv, pdfDfd) {
    var docDef;
    $.when(createPdfDocumentDefinition()).done(function (doc) {
        docDef = doc;
        $.when(pdfManager.createPdf(docDef)).done(function (outDoc) {
            var pdfClassName = 'pdfjs-canvas-landscape';
            pdfObv.value = outDoc;
            if (generateEmail) {
                pdfDfd.resolve();
            } else {
                pdfManager.displayPdf(outDoc, 5, 'pdf-container', pdfClassName, pdfDfd);
                // pdfManager.displayPdf(outDoc, totalPages, 'pdf-container', pdfClassName, pdfDfd);
                // pdfDfd.resolve();
            }
        });
    })
        .fail(function (error) {
            var breakHere = 0;
        });
}

function createPdfDocumentDefinition() {
    var dfdDocDefPromise = $.Deferred();

    var IMAGE_STATES = {
        LOADED: "loaded",
        NOT_LOADED: "notLoaded",
        FAILED: "failed"
    };
    var imageUrlsRequested = [];

    function loadImage(url, asynchGeneratePDFWhenImagesHaveAllLoaded) {
        // check if image is already loaded
        // if (hasImageBeenRequestedAlready(url) === null) {
        if (hasImageBeenRequestedAlready(url) === undefined) {
            imageUrlsRequested.push(url);
            var img = new Image();
            var imgDataURL, tempLocalStorageData;
            var scaleFactor;
            var imageType = 'image/jpeg';
            img.crossOrigin = "Anonymous";

            img.onload = function () {
                var canvas = document.createElement('canvas');
                canvas.width = img.width * scaleFactor;
                canvas.height = img.height * scaleFactor;

                var context = canvas.getContext('2d');
                context.scale(scaleFactor, scaleFactor);
                context.drawImage(img, 0, 0);
                imgDataURL = canvas.toDataURL(imageType);
                imageDataByUrl[url] = imgDataURL;
                imageHasBeenAddedToPdfMap[url] = IMAGE_STATES.LOADED;

                asynchGeneratePDFWhenImagesHaveAllLoaded();
            }

            img.onerror = function () {
                imageHasBeenAddedToPdfMap[url] = IMAGE_STATES.FAILED;
                asynchGeneratePDFWhenImagesHaveAllLoaded();
            }

            if (url.indexOf("unicode") < 0) {
                img.src = url; ///This requests the image from the server
            }
            if (url === vehicleBodyImgUrl || url === chassisImgUrl || url === logoImgUrl) {
                scaleFactor = 0.8;
            } else if (url === baseVehicleImgUrl || url === baseVehicleLogoImgUrl || url === competitor1ImgUrl || url === competitor1LogoImgUrl || url === competitor2ImgUrl || url === competitor2LogoImgUrl) {
                scaleFactor = 1;
                imageType = 'image/png';
            } else {
                scaleFactor = 0.5;
            }
            //This executes when the image has loaded
        }

        function hasImageBeenRequestedAlready(url) {
            // return imageUrlsRequested.first(function (imageUrl) {
            //     return imageUrl === url;
            // });
            return imageUrlsRequested.find(function (imageUrl) {
                return imageUrl === url;
            });
        }
    }

    function getBlankPlaceHolderImage(key, w, h) {
        var width = w || 1;
        var height = h || 30;

        var imgDataURL;

        switch (key) {
            case "headerImgUrl":
                height = 100;
                width = 600;
                break;
            case "footerImgUrl":
                width = 520;
                height = 100;
                break;
            case "vehicleBodyImgUrl":
                width = 216,
                    height = 162;
                break;
            case "logoImgUrl":
                width = 200;
                height = 70;
                break;
            case "chassisImgUrl":
                width = 200;
                height = 150;
                break;
            case "poweredByImgUrl":
                width = 100;
                height = 40;
                break;

        }
        var img = new Image(width, height);

        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        var context = canvas.getContext('2d');
        context.fillStyle = "#FFFFFF";
        context.fillRect(0, 0, width, height);
        context.drawImage(img, 0, 0);
        imgDataURL = canvas.toDataURL('image/jpeg');
        return imgDataURL;
    }

    var asynchGeneratePDFWhenImagesHaveAllLoaded = function () {
        var i; 
        var allImagesHaveLoaded = true;
        //Loop through map and if any false, set allImagesHaveLoaded to false and break out
        for (i in imageHasBeenAddedToPdfMap) {
            if (imageHasBeenAddedToPdfMap[i] === IMAGE_STATES.NOT_LOADED) {
                allImagesHaveLoaded = false;
                break;
            }
        }

        var showImagesFailedMessage = false;
        //Test allImagesHaveLoaded and if true, convert to iframe text
        if (allImagesHaveLoaded) {
            for (var j in imageHasBeenAddedToPdfMap) {
                if (imageHasBeenAddedToPdfMap[j] === IMAGE_STATES.FAILED) {
                    showImagesFailedMessage = true;
                    imageDataByUrl[j] = getBlankPlaceHolderImage(mageURLToReportImageMappings[j]);
                }
            }

            if (showImagesFailedMessage === true) {
                var cultureCode;
                if (globals.user === undefined) {
                    cultureCode = 'en';
                } else {
                    cultureCode = globals.getActiveCultureCodeForTranslations();
                }
                logger.log(config.getMessageText('1580', cultureCode), 'summary', 'summary', true, config.toastrOptionsDefault);
            }

            

            // 
            // $.when(createOrLoadVehicleDrawing(), createCostingChartUsingPromise(), createAltitudeChartUsingPromise()).done(function () {

            var test = [];
            // display, viewDetail, viewType, elevation
            if(globals.user.getReportViewLayout() === config.REPORT_VIEW_LAYOUT_OPTIONS.SIMPLIFIED) {
                test.push(
                    createVehicleDrawingParametersObject(
                        activeConfigurationDisplay.value.itemId,
                        activeConfigurationViewDetail.value.itemId,
                        activeConfigurationViewType.value.itemId,
                        activeConfigurationElevation.value.itemId
                    )
                );
            } else {
                globals.user.getReportViews().forEach(function(rv) {
                    if(rv.getIsActive() === true) {
                        test.push(
                            createVehicleDrawingParametersObject(
                                rv.getDisplay(),
                                rv.getViewDetail(),
                                rv.getViewType(),
                                rv.getElevation()
                            )
                        );
                    }
                });
            }

            $.when(createOrLoadAllNecessaryVehicleDrawings(test), createCostingChartUsingPromise(), createAltitudeChartUsingPromise()).done(function () {
                TScMessenger.writeDebugMessage("OfferManager.js, createPdfDocumentDefinition, asynchGeneratePDFWhenImagesHaveAllLoaded, before generatePdfMake 3");
                generatePdfMake();
                TScMessenger.writeDebugMessage("OfferManager.js, createPdfDocumentDefinition, asynchGeneratePDFWhenImagesHaveAllLoaded, after generatePdfMake 3");
            });
        }
    };
    
    function checkToAllowCostingPages() {
        var costingReportOption = getSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.COSTING);
        if (isCostingReportOptionAvailable() === true && costingReportOption !== null && costingReportOption.getIsChecked()) {
            return true;
        } else {
            return false;
        }
    }

    function checkToAllowSpecificationPages() {
        if (isSpecificationReportOptionAvailable() === true && specificationReportOption !== null && specificationReportOption.getIsChecked()) {
            return true;
        } else {
            return false;
        }
    }

    try {
        var specificationReportOption = getSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.SPECIFICATION),
            specificationImagesToBeAdded = false;
        //Page 1
        var mageURLToReportImageMappings = {};
        var headerImgUrl;
        var headerImgHeight = 21.98; //83.2575757
        var headerImgWidth = 209; //791.666667
        
        var footerImgUrl;
        var footerImgHeight = 31.1861;
        var footerImgWidth = 209;

        //NOTE: Add images for body builder
        //headerImgUrl = globals.user.getReportGraphics().landscapeReportHeaderLeftURL;
        headerImgUrl = globals.user.getReportLogo();
        mageURLToReportImageMappings[headerImgUrl] = 'headerImgUrl';
        headerImgHeight = 35;
        headerImgWidth = 200;
        footerImgUrl = config.cdnAddress + 'img/app/PdfReport_PoweredBy_2.jpg';
        mageURLToReportImageMappings[footerImgUrl] = 'footerImgUrl';

        footerImgWidth = 200;

        // if (specificationReportOption !== null && specificationReportOption.getIsChecked()) {
        if(checkToAllowSpecificationPages()) {
            var baseVehicleImgUrl = baseVehicle.value.vehicleChassisImage;
            var baseVehicleLogoImgUrl = baseVehicle.value.manufacturerLogo;
            var competitor1ImgUrl = getSpecification().getSelectedCompetitor1() !== null ? getSpecification().getSelectedCompetitor1().getVehicleChassisImage() : '';
            var competitor1LogoImgUrl = getSpecification().getSelectedCompetitor1() !== null ? getSpecification().getSelectedCompetitor1().getManufacturerLogo() : '';
            var competitor2ImgUrl = getSpecification().getSelectedCompetitor2() !== null ? getSpecification().getSelectedCompetitor2().getVehicleChassisImage() : '';
            var competitor2LogoImgUrl = getSpecification().getSelectedCompetitor2() !== null ? getSpecification().getSelectedCompetitor2().getManufacturerLogo() : '';
            //var competitor1ImgUrl = selectedCompetitor1.value.vehicleChassisImage;
            //var competitor1LogoImgUrl = selectedCompetitor1.value.manufacturerLogo;
            //var competitor2ImgUrl = selectedCompetitor2.value.vehicleChassisImage;
            //var competitor2LogoImgUrl = selectedCompetitor2.value.manufacturerLogo;
            if (typeof imageDataByUrl !== 'undefined') {
                if (imageDataByUrl[baseVehicleImgUrl] === undefined || imageDataByUrl[baseVehicleLogoImgUrl] === undefined ||
                    imageDataByUrl[competitor1ImgUrl] === undefined || imageDataByUrl[competitor1LogoImgUrl] === undefined ||
                    imageDataByUrl[competitor2ImgUrl] === undefined || imageDataByUrl[competitor2LogoImgUrl] === undefined) {
                    specificationImagesToBeAdded = true;
                }
            } else {
                specificationImagesToBeAdded = true;
            }

        }
        
        var manufacturerLogoImgHeight = 20;
        var manufacturerLogoImgWidth = 100;
        
        //Page 2
        var vehicleBodyImgUrl = vehiclePlusBodyImageURL.value;
        mageURLToReportImageMappings[vehicleBodyImgUrl] = 'vehicleBodyImgUrl';
        var vehicleBodyImgHeight = 47.625; //180 pixels
        var vehicleBodyImgWidth = 63.5; //240 pixels

        //Page 4
        var logoImgUrl;
        if (baseVehicle.value !== undefined) {
            logoImgUrl = baseVehicle.value.manufacturerReportLogo;
            mageURLToReportImageMappings[logoImgUrl] = 'logoImgUrl';
        } else {
            logoImgUrl = '';
        }

        var logoImgHeight = 13.2; // 50 pixels
        var logoImgWidth = 66.1; // 250 pixels

        var chassisImgUrl;
        if (baseVehicle.value !== undefined) {
            chassisImgUrl = baseVehicle.value.vehicleChassisReportImage;
            mageURLToReportImageMappings[chassisImgUrl] = 'chassisImgUrl';
        } else {
            chassisImgUrl = '';
        }

        var chassisImgHeight = 49.742; // 188 pixels
        var chassisImgWidth = 66.146; // 250 pixels

        var poweredByImgUrl = config.cdnAddress + "img/app/PdfReport_PoweredBy.jpg";
        mageURLToReportImageMappings[poweredByImgUrl] = 'poweredByImgUrl';
        var poweredByImgHeight = 6.43; //193
        var poweredByImgWidth = 37.63; //1129

        var letterImgHeight = 3.5;
        var letterImgWidth = 2;

        var letterLowerCaseImgHeight = 4;
        var letterLowerCaseImgWidth = 2;
        var letterUpperCaseImgHeight = 4;
        var letterUpperCaseImgWidth = 2.5;
        //#endregion

        var addressArray = globals.user.getAddress().split(",");
        var addressArrayForDisplay = [];
        var tempAddressVal;

        for (var i = 0; i < addressArray.length; i++) {
            addressArrayForDisplay.push(['', addressArray[i]]);
        }
        addressArrayForDisplay.push(['', {
            text: '',
            margin: [0, 0, 0, 20]
        }]);

        var todaysDate = globals.getTodaysDate(globals.user);
        addressArrayForDisplay.push(['', todaysDate]);

        var vehicleName = vehicleModel.value;
        var bodyTypePdf = bodyType.value;
        var bodyManufacturerPdf = bodyManufacturer.value;

        if (bodyManufacturerPdf === '' || bodyManufacturerPdf === ' ' || bodyManufacturerPdf === undefined || bodyManufacturerPdf === null) {
            bodyManufacturerPdf = '';
        } else {
            bodyManufacturerPdf = '('.concat(bodyManufacturerPdf, ')');
        }

        if (imagesArray === undefined || specificationImagesToBeAdded === true) {
            if (imagesArray === undefined) {
                imagesArray = new Array();
            }

            imageDataByUrl = {};
            var tempDataUrl;
            
            imageHasBeenAddedToPdfMap = {};
            
            if (headerImgUrl !== '') {
                if (headerImgUrl.indexOf("data") === -1) {
                    imagesArray.push(headerImgUrl);
                } else {
                    tempDataUrl = headerImgUrl;
                    headerImgUrl = "headerImgUrl";
                    imageDataByUrl[headerImgUrl] = tempDataUrl;
                    imageHasBeenAddedToPdfMap[headerImgUrl] = IMAGE_STATES.LOADED;
                }
            } else {
                tempDataUrl = headerImgUrl;
                headerImgUrl = "headerImgUrl";
                imageDataByUrl[headerImgUrl] = tempDataUrl;
                imageHasBeenAddedToPdfMap[headerImgUrl] = IMAGE_STATES.FAILED;
            }
            if (footerImgUrl !== '') {
                if (footerImgUrl.indexOf("data") === -1) {
                    imagesArray.push(footerImgUrl);
                } else {
                    tempDataUrl = footerImgUrl;
                    footerImgUrl = "footerImgUrl";
                    imageDataByUrl[footerImgUrl] = tempDataUrl;
                    imageHasBeenAddedToPdfMap[footerImgUrl] = IMAGE_STATES.LOADED;
                }
            } else {
                tempDataUrl = footerImgUrl;
                footerImgUrl = "footerImgUrl";
                imageDataByUrl[footerImgUrl] = tempDataUrl;
                imageHasBeenAddedToPdfMap[footerImgUrl] = IMAGE_STATES.FAILED;
            }

            // if (specificationReportOption !== null && specificationReportOption.getIsChecked()) {
            if(checkToAllowSpecificationPages()) {
                //var competitor2ImgUrl = selectedCompetitor2.value.vehicleChassisImage;
                //var competitor2LogoImgUrl = selectedCompetitor2.value.manufacturerLogo;
                competitor2ImgUrl = getSpecification().getSelectedCompetitor2() !== null ? getSpecification().getSelectedCompetitor2().getVehicleChassisImage() : '';
                competitor2LogoImgUrl = getSpecification().getSelectedCompetitor2() !== null ? getSpecification().getSelectedCompetitor2().getManufacturerLogo() : '';

                if (baseVehicleImgUrl !== '') {
                    if (baseVehicleImgUrl.indexOf("data") === -1) {
                        imagesArray.push(baseVehicleImgUrl);
                    } else {
                        tempDataUrl = baseVehicleImgUrl;
                        baseVehicleImgUrl = "baseVehicleImgUrl";
                        imageDataByUrl[baseVehicleImgUrl] = tempDataUrl;
                        imageHasBeenAddedToPdfMap[baseVehicleImgUrl] = IMAGE_STATES.LOADED;
                    }
                } else {
                    tempDataUrl = baseVehicleImgUrl;
                    baseVehicleImgUrl = "baseVehicleImgUrl";
                    imageDataByUrl[baseVehicleImgUrl] = tempDataUrl;
                    imageHasBeenAddedToPdfMap[baseVehicleImgUrl] = IMAGE_STATES.FAILED;
                }

                if (baseVehicleLogoImgUrl !== '') {
                    if (baseVehicleLogoImgUrl.indexOf("data") === -1) {
                        imagesArray.push(baseVehicleLogoImgUrl);
                    } else {
                        tempDataUrl = baseVehicleLogoImgUrl;
                        baseVehicleLogoImgUrl = "baseVehicleLogoImgUrl";
                        imageDataByUrl[baseVehicleLogoImgUrl] = tempDataUrl;
                        imageHasBeenAddedToPdfMap[baseVehicleLogoImgUrl] = IMAGE_STATES.LOADED;
                    }
                } else {
                    tempDataUrl = baseVehicleLogoImgUrl;
                    baseVehicleLogoImgUrl = "baseVehicleLogoImgUrl";
                    imageDataByUrl[baseVehicleLogoImgUrl] = tempDataUrl;
                    imageHasBeenAddedToPdfMap[baseVehicleLogoImgUrl] = IMAGE_STATES.FAILED;
                }

                if (competitor1ImgUrl !== '') {
                    if (competitor1ImgUrl.indexOf("data") === -1) {
                        imagesArray.push(competitor1ImgUrl);
                    } else {
                        tempDataUrl = competitor1ImgUrl;
                        competitor1ImgUrl = "competitor1ImgUrl";
                        imageDataByUrl[competitor1ImgUrl] = tempDataUrl;
                        imageHasBeenAddedToPdfMap[competitor1ImgUrl] = IMAGE_STATES.LOADED;
                    }
                } else {
                    //var tempDataUrl = competitor1ImgUrl;
                    //competitor1ImgUrl = "competitor1ImgUrl";
                    //imageDataByUrl[competitor1ImgUrl] = tempDataUrl;
                    //imageHasBeenAddedToPdfMap[competitor1ImgUrl] = IMAGE_STATES.FAILED;
                }

                if (competitor1LogoImgUrl !== '') {
                    if (competitor1LogoImgUrl.indexOf("data") === -1) {
                        imagesArray.push(competitor1LogoImgUrl);
                    } else {
                        tempDataUrl = competitor1LogoImgUrl;
                        competitor1LogoImgUrl = "competitor1LogoImgUrl";
                        imageDataByUrl[competitor1LogoImgUrl] = tempDataUrl;
                        imageHasBeenAddedToPdfMap[competitor1LogoImgUrl] = IMAGE_STATES.LOADED;
                    }
                } else {
                    //var tempDataUrl = competitor1LogoImgUrl;
                    //competitor1LogoImgUrl = "competitor1LogoImgUrl";
                    //imageDataByUrl[competitor1LogoImgUrl] = tempDataUrl;
                    //imageHasBeenAddedToPdfMap[competitor1LogoImgUrl] = IMAGE_STATES.FAILED;
                }

                if (competitor2ImgUrl !== '') {
                    if (competitor2ImgUrl.indexOf("data") === -1) {
                        imagesArray.push(competitor2ImgUrl);
                    } else {
                        tempDataUrl = competitor2ImgUrl;
                        competitor2ImgUrl = "competitor2ImgUrl";
                        imageDataByUrl[competitor2ImgUrl] = tempDataUrl;
                        imageHasBeenAddedToPdfMap[competitor2ImgUrl] = IMAGE_STATES.LOADED;
                    }
                } else {
                    //var tempDataUrl = competitor2ImgUrl;
                    //competitor2ImgUrl = "competitor2ImgUrl";
                    //imageDataByUrl[competitor2ImgUrl] = tempDataUrl;
                    //imageHasBeenAddedToPdfMap[competitor2ImgUrl] = IMAGE_STATES.FAILED;
                }

                if (competitor2LogoImgUrl !== '') {
                    if (competitor2LogoImgUrl.indexOf("data") === -1) {
                        imagesArray.push(competitor2LogoImgUrl);
                    } else {
                        tempDataUrl = competitor2LogoImgUrl;
                        competitor2LogoImgUrl = "competitor2LogoImgUrl";
                        imageDataByUrl[competitor2LogoImgUrl] = tempDataUrl;
                        imageHasBeenAddedToPdfMap[competitor2LogoImgUrl] = IMAGE_STATES.LOADED;
                    }
                } else {
                    //var tempDataUrl = competitor2LogoImgUrl;
                    //competitor2LogoImgUrl = "competitor2LogoImgUrl";
                    //imageDataByUrl[competitor2LogoImgUrl] = tempDataUrl;
                    //imageHasBeenAddedToPdfMap[competitor2LogoImgUrl] = IMAGE_STATES.FAILED;
                }
            }
            
            //Put these in a map so that an infinite number of flags can be checked and if any still true, don't transform pdf to iframe text yet
            //Key is the image url.  Value is false to begin with, then true when image has loaded and been added to the document.
            if (imagesArray.length > 0) {
                
                for (i = 0; i < imagesArray.length; i++) {
                    imageHasBeenAddedToPdfMap[imagesArray[i]] = IMAGE_STATES.NOT_LOADED; //URL
                }

                //***************** Load images now *****************
                for (i = 0; i < imagesArray.length; i++) {
                    loadImage(imagesArray[i], asynchGeneratePDFWhenImagesHaveAllLoaded);
                }
            } else {
                asynchGeneratePDFWhenImagesHaveAllLoaded();
            }

            //********************************************************************************************************************
        } else {
            if (headerImgUrl.indexOf("data") !== -1) {
                tempDataUrl = headerImgUrl;
                headerImgUrl = "headerImgUrl";
                imageDataByUrl[headerImgUrl] = tempDataUrl;
            }
            if (footerImgUrl.indexOf("data") !== -1) {
                tempDataUrl = footerImgUrl;
                footerImgUrl = "footerImgUrl";
                imageDataByUrl[footerImgUrl] = tempDataUrl;
            }
            // if (specificationReportOption !== null && specificationReportOption.getIsChecked()) {
            if(checkToAllowSpecificationPages()) {
                if (baseVehicleImgUrl.indexOf("data") !== -1) {
                    tempDataUrl = baseVehicleImgUrl;
                    baseVehicleImgUrl = "baseVehicleImgUrl";
                    imageDataByUrl[baseVehicleImgUrl] = tempDataUrl;
                }
                if (baseVehicleLogoImgUrl.indexOf("data") !== -1) {
                    tempDataUrl = baseVehicleLogoImgUrl;
                    baseVehicleLogoImgUrl = "baseVehicleLogoImgUrl";
                    imageDataByUrl[baseVehicleLogoImgUrl] = tempDataUrl;
                }
                if (competitor1ImgUrl.indexOf("data") !== -1) {
                    tempDataUrl = competitor1ImgUrl;
                    competitor1ImgUrl = "competitor1ImgUrl";
                    imageDataByUrl[competitor1ImgUrl] = tempDataUrl;
                }
                if (competitor1LogoImgUrl.indexOf("data") !== -1) {
                    tempDataUrl = competitor1LogoImgUrl;
                    competitor1LogoImgUrl = "competitor1LogoImgUrl";
                    imageDataByUrl[competitor1LogoImgUrl] = tempDataUrl;
                }
                if (competitor2ImgUrl.indexOf("data") !== -1) {
                    tempDataUrl = competitor2ImgUrl;
                    competitor2ImgUrl = "competitor2ImgUrl";
                    imageDataByUrl[competitor2ImgUrl] = tempDataUrl;
                }
                if (competitor2LogoImgUrl.indexOf("data") !== -1) {
                    tempDataUrl = competitor2LogoImgUrl;
                    competitor2LogoImgUrl = "competitor2LogoImgUrl";
                    imageDataByUrl[competitor2LogoImgUrl] = tempDataUrl;
                }
            }
            
            // if (configDrawing === null) {
            //     var test = [createOrLoadVehicleDrawing(), createCostingChartUsingPromise(), createAltitudeChartUsingPromise()];
            //     // $.when(createOrLoadVehicleDrawing(), createCostingChartUsingPromise(), createAltitudeChartUsingPromise()).done(function () {
            //     $.when(test).done(function () {
            //         TScMessenger.writeDebugMessage("before generatePdfMake 1");
            //         generatePdfMake();
            //         TScMessenger.writeDebugMessage("after generatePdfMake 1");
            //     });
            // } else {
            //     $.when(createCostingChartUsingPromise(), createAltitudeChartUsingPromise()).done(function () {
            //         TScMessenger.writeDebugMessage("before generatePdfMake 2");
            //         generatePdfMake();
            //         TScMessenger.writeDebugMessage("after generatePdfMake 2");
            //     });
            // }

            var test = [];
            // display, viewDetail, viewType, elevation
            if(globals.user.getReportViewLayout() === config.REPORT_VIEW_LAYOUT_OPTIONS.SIMPLIFIED) {
                test.push(
                    createVehicleDrawingParametersObject(
                        activeConfigurationDisplay.value.itemId,
                        activeConfigurationViewDetail.value.itemId,
                        activeConfigurationViewType.value.itemId,
                        activeConfigurationElevation.value.itemId
                    )
                );
            } else {
                globals.user.getReportViews().forEach(function(rv) {
                    if(rv.getIsActive() === true) {
                        test.push(
                            createVehicleDrawingParametersObject(
                                rv.getDisplay(),
                                rv.getViewDetail(),
                                rv.getViewType(),
                                rv.getElevation()
                            )
                        );
                    }
                });
            }

            $.when(createOrLoadAllNecessaryVehicleDrawings(test), createCostingChartUsingPromise(), createAltitudeChartUsingPromise()).done(function () {
                TScMessenger.writeDebugMessage("OfferManager.js, createPdfDocumentDefinition, before generatePdfMake 2");
                generatePdfMake();
                TScMessenger.writeDebugMessage("OfferManager.js, createPdfDocumentDefinition, after generatePdfMake 2");
            });
        }
    } catch (exception) {
        TScMessenger.writeErrorMessage('generatePdfMakePreview: Error setting up report images: ' + exception.message);
        TScMessenger.writeErrorMessage('error: ' + exception);
        dataManager.log("generatePdfMakePreview, setting up report images", exception, 'offerManager', config.LOG_MESSAGE_TYPE.ERROR);
    }

    function generatePdfMake() {
        // #region Functions for PDF

        function createBodyBuilderReportDocumentDefinition() {

            function setPageCount(currentPage, pageCount, documentDetails) {
                totalPages = pageCount;
            }

            function bodyBuilderReportFooter(currentPage, pageCount, documentDetails) {
                totalPages = pageCount;

                var maxNameWidthForConfiguration = 195,
                    maxPhoneNumberWidthForConfiguration = 175,
                    footerTableBody = [],
                    footerTableRow = [];

                var detailsTableBody = [],
                    detailsTableRow = [],
                    disclaimerTableBody = [],
                    disclaimerTableRow = [];

                var configurationReportOption = getSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.CONFIGURATION),
                    displayConfigurationReportFooter = configurationReportOption !== null ? configurationReportOption.getIsChecked() : false,
                    specificationReportOption = getSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.SPECIFICATION),
                    displaySpecificationReportFooter = specificationReportOption !== null ? specificationReportOption.getIsChecked() : false,
                    costingReportOption = getSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.COSTING),
                    displayCostingReportFooter = costingReportOption !== null ? costingReportOption.getIsChecked() : false,
                    performanceReportOption = getSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.PERFORMANCE),
                    displayPerformanceReportFooter = performanceReportOption !== null ? performanceReportOption.getIsChecked() : false;

                if (currentPage === 1) {
                    if (configurationReportOption !== null && configurationReportOption.getIsChecked()) {
                        displayConfigurationReportFooter = true;
                        displaySpecificationReportFooter = false;
                    }
                } else {
                    displayConfigurationReportFooter = false;
                }

                if (globals.user.getDefaultReportPdfPageSize() === config.PDFMAKE_PAGE_SIZE_OPTIONS.LETTER) {
                    maxNameWidthForConfiguration = 125;
                    maxPhoneNumberWidthForConfiguration = 150;
                }

                detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                    .newTextElement(config.getTranslationText('308') + ': ')
                    .setStyle('footerCellBoldStyle')
                    .build()
                );
                detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                    //.newTextElement(globals.trimTextAndAddEllipsisIfRequired(globals.user.getDisplayName()))
                    .newTextElement(globals.trimTextAndAddEllipsisIfRequired(globals.user.getDisplayName(), maxNameWidthForConfiguration))
                    .setStyle('footerCellPreparedStyle')
                    .build()
                );
                detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                    .newTextElement(config.getTranslationText('5240'))
                    .setStyle('footerCellBoldStyle')
                    .build()
                );
                detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                    .newTextElement(globals.trimTextAndAddEllipsisIfRequired(globals.user.getPhoneNumberForReport(), displayConfigurationReportFooter === true ? maxPhoneNumberWidthForConfiguration : 175))
                    .setStyle('footerCellStyle')
                    .build()
                );
                if (displayConfigurationReportFooter && globals.user.isUserAllowedToAddPreparedForDetails()) {
                    detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement(config.getTranslationText('309') + ': ')
                        .setStyle('footerCellBoldStyle')
                        .addBorderToCell([true, false, false, false])
                        .build()
                    );
                    detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        //.newTextElement(customerName)
                        //.newTextElement(globals.trimTextAndAddEllipsisIfRequired(customerName))
                        .newTextElement(globals.trimTextAndAddEllipsisIfRequired(customerName, maxNameWidthForConfiguration))
                        .setStyle('footerCellPreparedStyle')
                        .build()
                    );
                    detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement(config.getTranslationText('5241'))
                        .setStyle('footerCellBoldStyle')
                        .build()
                    );
                    detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement(globals.trimTextAndAddEllipsisIfRequired(customerNumber, maxPhoneNumberWidthForConfiguration))
                        .setStyle('footerCellStyle')
                        .build()
                    );
                } else {
                    detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement('')
                        .setStyle('footerCellBoldStyle')
                        .build()
                    );
                    detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement('')
                        .setStyle('footerCellPreparedStyle')
                        .build()
                    );
                    detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement('')
                        .setStyle('footerCellBoldStyle')
                        .build()
                    );
                    detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement('')
                        .setStyle('footerCellStyle')
                        .build()
                    );
                }

                detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                    .newTextElement(config.getTranslationText('108') + ': ')
                    .setStyle('footerCellBoldStyle')
                    .addBorderToCell([true, false, false, false])
                    .build()
                );
                detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                    .newTextElement(globals.getTodaysDate(globals.user))
                    .setStyle('footerCellStyle')
                    .build()
                );

                detailsTableBody.push(detailsTableRow);
                detailsTableRow = [];

                if (displayConfigurationReportFooter) {
                    detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement(config.getTranslationText('878') + ': ')
                        .setStyle('footerBottomRowCellStyle')
                        .addBold()
                        .build()
                    );
                    detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement('')
                        .setStyle('footerBottomRowCellStyle')
                        .build()
                    );
                    detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement(config.getTranslationText('107') + ': ')
                        .setStyle('footerBottomRowCellStyle')
                        .addBold()
                        .build()
                    );
                    detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement('')
                        .setStyle('footerBottomRowCellStyle')
                        .build()
                    );
                    if(globals.user.isUserAllowedToAddPreparedForDetails()) {
                        detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('878') + ': ')
                            .setStyle('footerBottomRowCellStyle')
                            .addBorderToCell([true, false, false, false])
                            .addBold()
                            .build()
                        );
                        detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .setStyle('footerBottomRowCellStyle')
                            .build()
                        );
                        detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('107') + ': ')
                            .setStyle('footerBottomRowCellStyle')
                            .addBold()
                            .build()
                        );
                        detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .setStyle('footerBottomRowCellStyle')
                            .build()
                        );
                    } else {
                        detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement('')
                        .build()
                        );
                        detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build()
                        );
                        detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build()
                        );
                        detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build()
                        );
                    }
                } else {
                    if(globals.user.isUserAllowedToAddPreparedForDetails()) {
                        detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('309') + ': ')
                            .setStyle('footerBottomRowCellStyle')
                            .addBold()
                            .build()
                        );
                        detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            //.newTextElement(customerName)
                            .newTextElement(globals.trimTextAndAddEllipsisIfRequired(customerName, maxNameWidthForConfiguration))
                            .setStyle('footerCellPreparedStyleRow2')
                            .build()
                        );
                        detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('5242'))
                            .setStyle('footerBottomRowCellStyle')
                            .addBold()
                            .build()
                        );
                        detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(globals.trimTextAndAddEllipsisIfRequired(customerNumber, 175))
                            .setStyle('footerCellPreparedStyleRow2')
                            .build()
                        );
                    } else {
                        detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement('')
                        .build()
                    );
                        detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build()
                        );
                        detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build()
                        );
                        detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build()
                        );
                    }
                    detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement('')
                        .build()
                    );
                    detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement('')
                        .build()
                    );
                    detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement('')
                        .build()
                    );
                    detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement('')
                        .build()
                    );
                }
                detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                    // .newTextElement('Page:')
                    .newTextElement(config.getTranslationText('4869'))
                    .setStyle('footerBottomRowCellStyle')
                    .addBold()
                    .addBorderToCell([true, false, false, false])
                    .build()
                );
                detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                    // .newTextElement(currentPage + ' of ' + pageCount)
                    .newTextElement(config.getTranslationText('4870', [currentPage, pageCount]))
                    .setStyle('footerBottomRowCellStyle')
                    .setFontSize(fontSizeGeneral - 1)
                    .build()
                );

                detailsTableBody.push(detailsTableRow);
                detailsTableRow = [];

                var disclaimerText = config.loadingSummaryDisclaimer;

                if (displaySpecificationReportFooter) {
                    disclaimerText = config.specificationSummaryDisclaimer;
                }

                detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                    .newTextElement(config.getTranslationText('877').toUpperCase() + ': ' + disclaimerText)
                    .setStyle('disclaimerStyle')
                    .setColSpan(9)
                    .setMargin([0, 0, 0, 0])
                    .build()
                );
                detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                    .newTextElement('')
                    .build()
                );
                detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                    .newTextElement('')
                    .build()
                );
                detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                    .newTextElement('')
                    .build()
                );
                detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                    .newTextElement('')
                    .build()
                );
                detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                    .newTextElement('')
                    .build()
                );
                detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                    .newTextElement('')
                    .build()
                );
                detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                    .newTextElement('')
                    .build()
                );
                detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                    .newTextElement('')
                    .build()
                );
                detailsTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                    .newImageElement(imageDataByUrl[footerImgUrl])
                    .setWidth(80)
                    .build()
                );

                detailsTableBody.push(detailsTableRow);

                var preparedWidth = 59,
                    phoneWidth = 35,
                    //phoneNumberWidth = 120,
                    phoneNumberWidth = 90,
                    dateValueWidth = 70,
                    preparedByWidth = preparedWidth,
                    preparedForWidth = preparedWidth + 3, // 62
                    dateWidth = phoneWidth + 37;

                var detailsTableElement = new pdfBuilders.PdfMakeElementBuilder()
                    .newTableElement(detailsTableBody)
                    .setColumnWidthsForTable([preparedForWidth, '*', phoneWidth, phoneNumberWidth, preparedForWidth, '*', phoneWidth, phoneNumberWidth, dateWidth, dateValueWidth])
                    .setMargin([10, 0, 10, 0])
                    .removeDefaultBorder()
                    .build();
                footerTableRow.push(detailsTableElement);

                footerTableBody.push(footerTableRow);

                var footerTableElement = new pdfBuilders.PdfMakeElementBuilder()
                    .newTableElement(footerTableBody)
                    .setColumnWidthsForTable(['*'])
                    .addNoBordersLayout()
                    .build();

                var footerContainerTableBody = [],
                    footerContainerTableRow = [];

                footerContainerTableRow.push(footerTableElement);
                footerContainerTableBody.push(footerContainerTableRow);

                var footerContainerTableElement = new pdfBuilders.PdfMakeElementBuilder()
                    .newTableElement(footerContainerTableBody)
                    .setColumnWidthsForTable(['*'])
                    .sethLineWidth(function (i, node) {
                        return i === 0 ? 1 : 0;
                    })
                    .setvLineWidth(function (i, node) {
                        return 0;
                    })
                    .sethLineColor(function () {
                        return config.pdfDocumentColour;
                    })
                    .build();

                return footerContainerTableElement;
            }

            function createHeaderObject(currentPage, pageCount, documentDetails) {
                var index = 0,
                    configurationReportStartPage = null,
                    configurationReportEndPage = null,
                    isConfigurationEndPageUpdated = false,
                    loadPlanStartPage = null,
                    loadPlanEndPage = null,
                    weightAndCogStartPage = null,
                    weightAndCogEndPage = null,
                    specificationReportStartPage = null,
                    specificationReportEndPage = null,
                    costingReportStartPage = null,
                    costingReportEndPage = null,
                    performanceReportStartPage = null,
                    performanceReportEndPage = null;

                var HEADER_TYPE = {
                    CONFIGURATION_DRAWING: 'configurationDrawing',
                    LOAD_PLAN: 'loadPlan',
                    WEIGHT_AND_COG: 'weightAndCog',
                    PERFORMANCE: 'performance',
                    COSTING: 'costing'
                };

                var configurationReportOption = getSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.CONFIGURATION),
                    displayConfigurationReportFooter = configurationReportOption !== null ? configurationReportOption.getIsChecked() : false,
                    specificationReportOption = getSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.SPECIFICATION),
                    displaySpecificationReportFooter = specificationReportOption !== null ? specificationReportOption.getIsChecked() : false,
                    costingReportOption = getSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.COSTING),
                    displayCostingReportFooter = costingReportOption !== null ? costingReportOption.getIsChecked() : false,
                    performanceReportOption = getSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.PERFORMANCE),
                    displayPerformanceReportFooter = performanceReportOption !== null ? performanceReportOption.getIsChecked() : false,
                    drawingViewOfferOption = getSummaryReportOfferOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.CONFIGURATION, config.SUMMARY_REPORT_OFFER_OPTIONS.DRAWING_VIEW),
                    headerType = null;

                // What needs to be done
                // Function is called on each page
                // currentPage is the current page number
                // pageCount is the total number of pages
                // documentDetails is the document definition as a JSON object
                // document.content[0] is page 1
                // document.content[1] is page 2
                // first page of index = documentDefinition.content[index].positions[0].pageNumber;
                // last page of index = documentDefinition.content[index].positions[documentDefinition.content[costingIndex].positions.length - 1].pageNumber
                var numberOfSectionsInDocument = documentDefinition.content.length,
                    sectionDetails = [];
                
                for (var i = 0; i < numberOfSectionsInDocument; i += 1) {
                    var section = documentDefinition.content[i];

                    // Create a variable that uses a copy of the documentDefinition.content[i].positions array
                    // This is so that the original array is not modified
                    var positionsCopy = documentDefinition.content[i].positions.slice(0);
                    // Sort this array by page number
                    positionsCopy.sort(function (a, b) {
                        return a.pageNumber - b.pageNumber;
                    });

                    sectionDetails.push({
                        id: i,
                        startPage: documentDefinition.content[i].positions[0].pageNumber,
                        endPage: positionsCopy[positionsCopy.length - 1].pageNumber
                    });
                }

                var sectionForCurrentPage = null;
                if(sectionDetails.length === 1) {
                    sectionForCurrentPage = sectionDetails[0];
                } else {
                    sectionForCurrentPage = sectionDetails.find(function (section) {
                        return currentPage >= section.startPage && currentPage <= section.endPage;
                    });
                }
                

                // What are the sections in the report
                var sectionsOnTheReport = [],
                    sectionId = 0;
                // Configuration
                if (configurationReportOption !== null && configurationReportOption.getIsChecked()) {
                    drawingViewOfferOption = getSummaryReportOfferOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.CONFIGURATION, config.SUMMARY_REPORT_OFFER_OPTIONS.DRAWING_VIEW);
                    var loadPlanOfferOption = getSummaryReportOfferOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.CONFIGURATION, config.SUMMARY_REPORT_OFFER_OPTIONS.LOAD_PLAN);
                    var weightAndCogOfferOption = getSummaryReportOfferOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.CONFIGURATION, config.SUMMARY_REPORT_OFFER_OPTIONS.WEIGHT_AND_COG);

                    // Is drawing displayed
                    if (drawingViewOfferOption !== null && drawingViewOfferOption.getIsChecked()) {
                        if(globals.user.getReportViewLayout() === config.REPORT_VIEW_LAYOUT_OPTIONS.SIMPLIFIED) {
                            sectionsOnTheReport.push({
                                sectionId: sectionId,
                                headerType: HEADER_TYPE.CONFIGURATION_DRAWING,
                                rightTopText: config.getTranslationText('2700'),
                                rightBottomText: viewTypeMenuValue + ' - ' + viewDetailMenuValue + ' (' + elevationMenuValue + ')',
                                displayConfigurationReportFooter: true
                            });
                            sectionId++;
                        } else {
                            globals.user.getReportViews().forEach(function (reportView) {
                                var stringVal = `${reportView.getDisplay()}_${reportView.getViewDetail()}_${reportView.getViewType()}_${reportView.getElevation()}`;
                                        
                                if (reportView.getIsActive() === true && vehicleConfigurationDrawingObjects[stringVal] !== undefined) {
                                    sectionsOnTheReport.push({
                                        sectionId: sectionId,
                                        headerType: HEADER_TYPE.CONFIGURATION_DRAWING,
                                        rightTopText: config.getTranslationText('2700'),
                                        rightBottomText: reportView.getViewTypeText() + ' - ' + reportView.getViewDetailText(getActiveOffer().getRig().getVehicle().getFuelType()) + ' (' + reportView.getElevationText() + ')',
                                        displayConfigurationReportFooter: true
                                    });
                                    sectionId++;
                                }
                            });
                        }
                    }

                    if (isDetailedPayloadAvailableOnRig() && loadPlanOfferOption !== null && loadPlanOfferOption.getIsChecked()) {
                        sectionsOnTheReport.push({
                            sectionId: sectionId,
                            headerType: HEADER_TYPE.LOAD_PLAN,
                            rightTopText: config.getTranslationText('2700'),
                            rightBottomText: config.getTranslationText('2881'),
                            displayConfigurationReportFooter: true
                        });
                        sectionId++;
                    }
                    // Is weight and cog displayed
                    if (weightAndCogOfferOption !== null && weightAndCogOfferOption.getIsChecked()) {
                        sectionsOnTheReport.push({
                            sectionId: sectionId,
                            headerType: HEADER_TYPE.WEIGHT_AND_COG,
                            rightTopText: config.getTranslationText('2700'),
                            rightBottomText: config.getTranslationText('2882'),
                            displayConfigurationReportFooter: true
                        });
                        sectionId++;
                    }
                    sectionsOnTheReport.push({
                        sectionId: sectionId,
                        headerType: HEADER_TYPE.WEIGHT_AND_COG,
                        rightTopText: config.getTranslationText('2700'),
                        rightBottomText: config.getTranslationText('2882'),
                        displayConfigurationReportFooter: true
                    });
                    sectionId++;
                }

                if (performanceReportOption !== null && performanceReportOption.getIsChecked()) {
                    sectionsOnTheReport.push({
                        sectionId: sectionId,
                        headerType: HEADER_TYPE.PERFORMANCE,
                        rightTopText: config.getTranslationText('1093'),
                        displayConfigurationReportFooter: false
                    });
                    sectionId++;
                }
                if (checkToAllowCostingPages()) {
                    sectionsOnTheReport.push({
                        sectionId: sectionId,
                        headerType: HEADER_TYPE.COSTING,
                        rightTopText: config.getTranslationText('1054'),
                        displayConfigurationReportFooter: false
                    });
                    sectionId++;
                }
                if(checkToAllowSpecificationPages()) {
                    sectionsOnTheReport.push({
                        sectionId: sectionId,
                        headerType: HEADER_TYPE.SPECIFICATION,
                        rightTopText: config.getTranslationText('1040'),
                        displayConfigurationReportFooter: false
                    });
                    sectionId++;
                }
                var sectionToUse = sectionsOnTheReport[sectionForCurrentPage.id];

                var headerImageElement = new pdfBuilders.PdfMakeElementBuilder()
                    .newImageElement(imageDataByUrl[headerImgUrl])
                    .setFit([225, 32])
                    .setMargin([5, 0, 0, 0])
                    .build();
                var headerTableBody = [],
                    headerTableBodyRow = [],
                    cellHeight = 10;

                // if (displayConfigurationReportFooter === true) {
                if (sectionToUse.displayConfigurationReportFooter === true) {
                    headerTableBodyRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement(config.getTranslationText('2700'))
                        .setStyle('weightDistStyle')
                        .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT)
                        .addBold()
                        .build()
                    );

                    headerTableBody.push(headerTableBodyRow);
                    headerTableBodyRow = [];

                    headerTableBodyRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        // .newTextElement(rightBottomText)
                        .newTextElement(sectionToUse.rightBottomText)
                        .setMargin([0, -5, 0, 0])
                        .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT)
                        .build()
                    );
                    headerTableBody.push(headerTableBodyRow);
                } else {
                    cellHeight = (cellHeight * 2) + 8;
                    headerTableBodyRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        // .newTextElement(rightTopText)
                        .newTextElement(sectionToUse.rightTopText)
                        .setStyle('weightDistStyle')
                        .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT)
                        .addBold()
                        .setMargin([0, 4, 0, 0])
                        .build()
                    );

                    headerTableBody.push(headerTableBodyRow);
                }

                var headerTableElement = new pdfBuilders.PdfMakeElementBuilder()
                    .newTableElement(headerTableBody)
                    .setColumnWidthsForTable(['*'])
                    .addNoBordersLayout()
                    .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT)
                    .addCellHeightsToTable(cellHeight)
                    .build();

                var headerContainerTableBody = [],
                    headerContainerTableRow = [];

                headerContainerTableRow.push(headerImageElement);

                var overallDescriptionTextObject,
                    descriptionTableRows = [],
                    descriptionTableElement;

                if (getBaseVehicleDescriptionForPdf() !== '') {
                    descriptionTableRows = [];
                    overallDescriptionTextObject = getOverallDescriptionForPdf(1);

                    descriptionTableRows.push([new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement(overallDescriptionTextObject.text)
                        .setFontSize(fontSizeGeneral + 2)
                        .addBold()
                        .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE)
                        .setMargin([0, 0, 0, 0])
                        .build()
                    ]);

                    descriptionTableRows.push([new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement(getBaseVehicleDescriptionForPdf())
                        .setFontSize(fontSizeGeneral)
                        .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE)
                        .setMargin([0, -5, 0, 0])
                        .build()
                    ]);

                    descriptionTableElement = new pdfBuilders.PdfMakeElementBuilder()
                        .newTableElement(descriptionTableRows)
                        .setColumnWidthsForTable(['*'])
                        .addNoBordersLayout()
                        .addCellHeightsToTable(cellHeight)
                        .build();

                    headerContainerTableRow.push(descriptionTableElement);

                } else {
                    overallDescriptionTextObject = getOverallDescriptionForPdf();

                    if(overallDescriptionTextObject.numberOfLines === 1) {
                        headerContainerTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(overallDescriptionTextObject.text)
                            .setFontSize(fontSizeGeneral + 2)
                            .addBold()
                            .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE)
                            .setMargin([0, 7, 0, 0])
                            .build()
                        );
                    } else {
                        descriptionTableRows = [];
                        descriptionTableRows.push([new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(overallDescriptionTextObject.textLine1)
                            .setFontSize(fontSizeGeneral + 2)
                            .addBold()
                            .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE)
                            .setMargin([0, 0, 0, 0])
                            .build()
                        ]);

                        descriptionTableRows.push([new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(overallDescriptionTextObject.textLine2)
                            .setFontSize(fontSizeGeneral + 2)
                            .addBold()
                            .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE)
                            .setMargin([0, -5, 0, 0])
                            .build()
                        ]);
    
                        descriptionTableElement = new pdfBuilders.PdfMakeElementBuilder()
                            .newTableElement(descriptionTableRows)
                            .setColumnWidthsForTable(['*'])
                            .addNoBordersLayout()
                            .addCellHeightsToTable(cellHeight)
                            .build();
    
                        headerContainerTableRow.push(descriptionTableElement);
                    }
                }

                headerContainerTableRow.push(headerTableElement);
                headerContainerTableBody.push(headerContainerTableRow);
                var widthsForLeftRightColumns = 230;
                var headerContainerTableElement = new pdfBuilders.PdfMakeElementBuilder()
                    .newTableElement(headerContainerTableBody)
                    .setColumnWidthsForTable([widthsForLeftRightColumns, '*', widthsForLeftRightColumns])
                    .addNoBordersLayout()
                    .setMargin([10, 5, 10, -1])
                    .build();

                var headerContainerOverallTableBody = [],
                    headerContainerOverallTableRow = [];

                headerContainerOverallTableRow.push(headerContainerTableElement);
                headerContainerOverallTableBody.push(headerContainerOverallTableRow);

                var headerContainerOverallTableElement = new pdfBuilders.PdfMakeElementBuilder()
                    .newTableElement(headerContainerOverallTableBody)
                    .setColumnWidthsForTable(['*'])
                    .sethLineWidth(function (i, node) {
                        return i === node.table.body.length ? 1 : 0;
                    })
                    .setvLineWidth(function (i, node) {
                        return 0;
                    })
                    .sethLineColor(function () {
                        return config.pdfDocumentColour;
                    })
                    .build();

                return headerContainerOverallTableElement;
            }

            function createBodyBuilderReportContent() {
                var bodyBuilderContentArray = [];

                function createConfigurationReportPage() {
                    // #region Vehicle Image section
                    if (globals.user.getDefaultReportPdfPageSize() === config.PDFMAKE_PAGE_SIZE_OPTIONS.LETTER) {
                        landscapeImageWidth -= 45;
                        landscapeImageHeight += 20;
                    }
                    
                    var stringForAttribute = `${activeConfigurationDisplay.value.itemId}_${activeConfigurationViewDetail.value.itemId}_${activeConfigurationViewType.value.itemId}_${activeConfigurationElevation.value.itemId}`;
                    var vehicleImageElement = new pdfBuilders.PdfMakeElementBuilder()
                        // .newImageElement(configDrawing)
                        .newImageElement(vehicleConfigurationDrawingObjects[stringForAttribute])
                        .setFit([landscapeImageWidth, landscapeImageHeight])
                        .setMargin([0, 5, 0, 5])
                        .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE)
                        .build();

                    var vehicleTableBody = [],
                        vehicleTableRow = [];

                    vehicleTableRow.push(vehicleImageElement);
                    vehicleTableBody.push(vehicleTableRow);
                    var vehicleImageTableElement = new pdfBuilders.PdfMakeElementBuilder()
                        .newTableElement(vehicleTableBody)
                        .setColumnWidthsForTable(['*'])
                        .addNoBordersLayout()
                        .build();
                    bodyBuilderContentArray.push(vehicleImageTableElement);
                    // #endregion Vehicle Image section
                }

                function updateLandscapeImageDimensionsIfNecessary() {
                    if (globals.user.getDefaultReportPdfPageSize() === config.PDFMAKE_PAGE_SIZE_OPTIONS.LETTER) {
                        landscapeImageWidth -= 45;
                        landscapeImageHeight += 20;
                    }
                }

                function createConfigurationReportImagePage(imageDataUrl) {
                    if(imageDataUrl !== undefined) {
                        // #region Vehicle Image section
                        var vehicleImageElement = new pdfBuilders.PdfMakeElementBuilder()
                        .newImageElement(imageDataUrl)
                        .setFit([landscapeImageWidth, landscapeImageHeight])
                        .setMargin([0, 5, 0, 5])
                        .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE)
                        .build();

                        var vehicleTableBody = [],
                        vehicleTableRow = [];

                        vehicleTableRow.push(vehicleImageElement);
                        vehicleTableBody.push(vehicleTableRow);
                        var vehicleImageTableElement = new pdfBuilders.PdfMakeElementBuilder()
                        .newTableElement(vehicleTableBody)
                        .setColumnWidthsForTable(['*'])
                        .addNoBordersLayout()
                        .addPageBreakAfterElement()
                        .build();
                        bodyBuilderContentArray.push(vehicleImageTableElement);
                        // #endregion Vehicle Image section
                    }
                }

                function createLoadPlan(addPageBreak) {
                    var pageBreakAdded = false;

                    var containerTableBody = [],
                        containerTableRow = [];

                    // vehicle
                    createTableForPayloadHolder(getActiveOffer().getRig().getVehicle().getPayloadHolder(), config.getTranslationText('1247'), false);

                    // trailers
                    if (getActiveOffer().getRig().getTrailer1() !== null) {
                        createTableForPayloadHolder(getActiveOffer().getRig().getTrailer1().getPayloadHolder(), config.getTranslationText('1248'), true);
                    }

                    if (getActiveOffer().getRig().getTrailer2() !== null) {
                        createTableForPayloadHolder(getActiveOffer().getRig().getTrailer2().getPayloadHolder(), config.getTranslationText('1249'), true);
                    }

                    // var containerTable = new pdfBuilders.PdfMakeElementBuilder()
                    //     .newTableElement(containerTableBody)
                    //     .setColumnWidthsForTable(['*'])
                    //     .addNoBordersLayout()
                    //     .build();
                    //     addPageBreak
                    // if (isDrawingPageDisplayed) {
                    //     containerTable.pageBreak = 'before';
                    // }

                    var containerTable;
                    if (addPageBreak) {
                        containerTable = new pdfBuilders.PdfMakeElementBuilder()
                            .newTableElement(containerTableBody)
                            .addPageBreakBeforeElement()
                            .setColumnWidthsForTable(['*'])
                            .addNoBordersLayout()
                            .build();
                    } else {
                        containerTable = new pdfBuilders.PdfMakeElementBuilder()
                            .newTableElement(containerTableBody)
                            .setColumnWidthsForTable(['*'])
                            .addNoBordersLayout()
                            .build();
                    }

                    bodyBuilderContentArray.push(containerTable);

                    function createTableForPayloadHolder(payloadHolderToUse, tableHeaderText, addMarginToTable) {
                        var tableHeaderTextTable,
                            tableBody = [],
                            tableRow = [],
                            measurementSystemIdToUse = globals.user.getActiveMeasurementSystemId();

                        if (payloadHolderToUse.getPayloadType() === config.PAYLOAD_TYPES.DETAILED && payloadHolderToUse.getDetailedPayloadItems().length > 0) {
                            tableHeaderText = new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(tableHeaderText)
                                .setStyle('payloadHeaderStyle')
                                .build();

                            tableRow.push(tableHeaderText);
                            tableBody.push(tableRow);

                            tableHeaderTextTable = new pdfBuilders.PdfMakeElementBuilder()
                                .newTableElement(tableBody)
                                .setColumnWidthsForTable(['*'])
                                .addHeaderRows()
                                .sethLineWidth(function (i, node) {
                                    return (i === 1) ? 1 : 0;
                                })
                                .setvLineWidth(function (i, node) {
                                    return 0;
                                })
                                .setStyle('payloadTableStyle')
                                .build();

                            // if (pageBreakAdded === false && isDrawingPageDisplayed === true) {
                            if (pageBreakAdded === false && addPageBreak === true) {
                                //tableHeaderTextTable.pageBreak = 'before';
                                pageBreakAdded = true;
                            } else {
                                if (addMarginToTable) {
                                    tableHeaderTextTable.margin = [12, 20, 12, 5];
                                }
                            }

                            containerTableRow.push(tableHeaderTextTable);
                            containerTableBody.push(containerTableRow);
                            containerTableRow = [];

                            tableRow = [];
                            tableBody = [];
                            tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('2596'))
                                .setStyle('tableHeader')
                                .build());
                            tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('2695'))
                                .setStyle('tableHeader')
                                .build());
                            tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('2694'))
                                .setStyle('tableHeader')
                                .build());
                            tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('896'))
                                .setStyle('tableHeader')
                                .build());
                            tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('1179'))
                                .setStyle('tableHeader')
                                .build());
                            tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('217'))
                                .setStyle('tableHeader')
                                .build());
                            tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('180'))
                                .setStyle('tableHeader')
                                .build());
                            tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('573'))
                                .setStyle('tableHeader')
                                .build());
                            tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('566'))
                                .setStyle('tableHeader')
                                .build());
                            tableBody.push(tableRow);

                            payloadHolderToUse.getDetailedPayloadItems().forEach(function (payloadItem, index) {
                                var fillColour = index % 2 === 1 ? '#F6F6F6' : null;
                                tableRow = [];
                                tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement((index + 1).toString())
                                    .setAlignment('center')
                                    .setFillColour(fillColour)
                                    .build());

                                var descriptionText = payloadItem.getDescription();
                                if (payloadItem.getWeightType() === config.PAYLOAD_WEIGHT_TYPES.VOLUME) {
                                    //descriptionText += ' (' + payloadItem.getPayload() + ' ' + payloadItem.getUnitOfMeasure() + ' @ ' + getUIFormattedPayloadDensityValue(payloadItem.getDensity(), payloadItem) + ')';
                                    descriptionText += ' (' + getUIFormattedPayloadValue(payloadItem.getPayload(), payloadItem) + ' @ ' + getUIFormattedPayloadDensityValue(payloadItem.getDensity(), payloadItem) + ')';
                                }

                                tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement(descriptionText)
                                    .setFillColour(fillColour)
                                    .build());
                                tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement(globals.getUIFormattedLengthValue(payloadItem.getStartX(), measurementSystemIdToUse))
                                    .setStyle('payloadTableNumberStyle')
                                    .setFillColour(fillColour)
                                    .build());
                                tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement(globals.getUIFormattedLengthValue(payloadItem.getStartY(), measurementSystemIdToUse))
                                    .setStyle('payloadTableNumberStyle')
                                    .setFillColour(fillColour)
                                    .build());
                                tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement(globals.getUIFormattedLengthValue(payloadItem.getStartZ(), measurementSystemIdToUse))
                                    .setStyle('payloadTableNumberStyle')
                                    .setFillColour(fillColour)
                                    .build());
                                tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement(globals.getUIFormattedLengthValue(payloadItem.getLength(), measurementSystemIdToUse))
                                    .setStyle('payloadTableNumberStyle')
                                    .setFillColour(fillColour)
                                    .build());
                                tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement(globals.getUIFormattedLengthValue(payloadItem.getHeight(), measurementSystemIdToUse))
                                    .setStyle('payloadTableNumberStyle')
                                    .setFillColour(fillColour)
                                    .build());
                                tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement(globals.getUIFormattedLengthValue(payloadItem.getWidth(), measurementSystemIdToUse))
                                    .setStyle('payloadTableNumberStyle')
                                    .setFillColour(fillColour)
                                    .build());

                                tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement(globals.getUIFormattedMassValue(payloadItem.getMass(), measurementSystemIdToUse))
                                    .setStyle('payloadTableNumberStyle')
                                    .setFillColour(fillColour)
                                    .build());

                                tableBody.push(tableRow);
                            });

                            var payloadResultsTable = new pdfBuilders.PdfMakeElementBuilder()
                                .newTableElement(tableBody)
                                .setColumnWidthsForTable(['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'])
                                .addHeaderRows()
                                .sethLineWidth(function (i, node) {
                                    return 0;
                                })
                                .setvLineWidth(function (i, node) {
                                    return i === 0 || i === node.table.body[0].length ? 0 : 1;
                                })
                                .setFillColourForTable(function (rowIndex, node, columnIndex) {
                                    return (rowIndex % 2 === 0) ? '#F6F6F6' : null;
                                })
                                .setStyle('payloadTableStyle')
                                .build();

                            containerTableRow.push(payloadResultsTable);
                            containerTableBody.push(containerTableRow);
                            containerTableRow = [];

                            //bodyBuilderContentArray.push(payloadResultsTable);
                        }
                    }
                }

                function createWeightAndCog(isDrawingOrLoadPlanDisplayed) {
                    var containerTableBody = [],
                        containerTableRow = [];
                    // Title = Weight and Center of Gravity Calculations
                    // Table
                    // Technical Wheelbase
                    // Track Width Front
                    // Track Width Rear
                    createTopTable();

                    // vehicle
                    // Vehicle Table
                    // Vehicle | H COG from Front | V COG from Ground | L COG From Ceter || Front | Rear | Total
                    //createTableForChassisType(getActiveOffer().getConfiguration().getPayloadHolder(), config.getTranslationText('1252'));
                    createTableForChassisObjectType(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.getTranslationText('1252'));

                    // trailers
                    // Trailer 1 Table
                    // Trailer | H COG from Front | V COG from Ground | L COG From Ceter || Front | Rear | Total
                    if (getActiveOffer().getRig().getTrailer1() !== null) {
                        //createTableForChassisType(getActiveOffer().getRig().getTrailer1().getPayloadHolder(), config.getTranslationText('1253'));
                        createTableForChassisObjectType(config.CHASSIS_OBJECT_TYPES.TRAILER1, config.getTranslationText('1253'));
                    }

                    if (getActiveOffer().getRig().getTrailer2() !== null) {
                        //createTableForChassisType(getActiveOffer().getRig().getTrailer1().getPayloadHolder(), config.getTranslationText('1253'));
                        createTableForChassisObjectType(config.CHASSIS_OBJECT_TYPES.TRAILER2, config.getTranslationText('1254'));
                    }

                    var containerTable = new pdfBuilders.PdfMakeElementBuilder()
                        .newTableElement(containerTableBody)
                        .setColumnWidthsForTable(['*'])
                        .addNoBordersLayout()
                        .setMargin([6, 0, 0, 0])
                        .build();

                    bodyBuilderContentArray.push(containerTable);

                    function createTopTable() {
                        var tableHeaderText,
                            tableHeaderTextTable,
                            tableBody = [],
                            tableRow = [],
                            measurementSystemIdToUse = globals.user.getActiveMeasurementSystemId();

                        tableHeaderText = new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('1251'))
                            .setStyle('payloadHeaderStyle')
                            .build();

                        tableRow.push(tableHeaderText);
                        tableBody.push(tableRow);

                        tableHeaderTextTable = new pdfBuilders.PdfMakeElementBuilder()
                            .newTableElement(tableBody)
                            .setColumnWidthsForTable(['*'])
                            .addHeaderRows()
                            //.addPageBreakBeforeElement()
                            .sethLineWidth(function (i, node) {
                                return (i === 1) ? 1 : 0;
                            })
                            .setvLineWidth(function (i, node) {
                                return 0;
                            })
                            .setStyle('payloadTableStyle')
                            .build();

                        if (isDrawingOrLoadPlanDisplayed) {
                            tableHeaderTextTable.margin = [12, 20, 12, 5];
                        }

                        //bodyBuilderContentArray.push(tableHeaderTextTable);
                        containerTableRow.push(tableHeaderTextTable);
                        containerTableBody.push(containerTableRow);
                        containerTableRow = [];

                        tableRow = [];
                        tableBody = [];
                        tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('1258'))
                            .build());
                        tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(globals.getUIFormattedLengthValue(getConfiguration().getWheelbaseTheoretical(), measurementSystemIdToUse))
                            .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT)
                            .build());
                        tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build());
                        tableBody.push(tableRow);

                        tableRow = [];
                        tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2618'))
                            .build());
                        tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(globals.getUIFormattedLengthValue(getConfiguration().getTrackWidthFront(), measurementSystemIdToUse))
                            .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT)
                            .build());
                        tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build());
                        tableBody.push(tableRow);

                        tableRow = [];
                        tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('503'))
                            .build());
                        tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(globals.getUIFormattedLengthValue(getConfiguration().getTrackWidthRear(), measurementSystemIdToUse))
                            .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT)
                            .build());
                        tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build());
                        tableBody.push(tableRow);

                        var topResultsTable = new pdfBuilders.PdfMakeElementBuilder()
                            .newTableElement(tableBody)
                            .setColumnWidthsForTable([200, 75, '*'])
                            //.addNoBordersLayout()
                            .sethLineWidth(function (i, node) {
                                return 0;
                            })
                            .setvLineWidth(function (i, node) {
                                return i === 0 || i === node.table.body[0].length ? 0 : 1;
                                //return i === 0 || i === 2 || i === node.table.body[0].length ? 0 : 1;
                            })
                            .setFillColourForTable(function (rowIndex, node, columnIndex) {
                                return (rowIndex % 2 === 0) ? '#F6F6F6' : null;
                            })
                            .setStyle('payloadTableStyle')
                            .build();

                        //bodyBuilderContentArray.push(topResultsTable);
                        containerTableRow.push(topResultsTable);
                        containerTableBody.push(containerTableRow);
                        containerTableRow = [];
                    }

                    function createTableForChassisObjectType(chassisType, tableHeaderText) {
                        var tableHeaderTextTable,
                            tableBody = [],
                            tableRow = [],
                            measurementSystemIdToUse = globals.user.getActiveMeasurementSystemId(),
                            weightColumnWidth = 60,
                            displayChassisTypeColumn = false,
                            combinationType = getRigOps().getCombinationType(),
                            weightAndCentreOfGravityColumnWidths = ['*', 'auto', 'auto', 'auto', -3, weightColumnWidth, weightColumnWidth, weightColumnWidth],
                            cell1, cell2, cell3, cell4, cell5, cell6, cell7, chassisCell;

                        if (chassisType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            if (combinationType !== config.COMBINATION_TYPES.VEHICLE && combinationType !== config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR) {
                                displayChassisTypeColumn = true;
                                weightAndCentreOfGravityColumnWidths = ['*', 'auto', 'auto', 'auto', 'auto', -3, weightColumnWidth, weightColumnWidth, weightColumnWidth];
                            }
                        } else if (chassisType === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                            if (getActiveOffer().getRig().getTrailer2() !== null) {
                                displayChassisTypeColumn = true;
                                weightAndCentreOfGravityColumnWidths = ['*', 'auto', 'auto', 'auto', 'auto', -3, weightColumnWidth, weightColumnWidth, weightColumnWidth];
                            }
                        }

                        tableHeaderText = new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(tableHeaderText)
                            .setStyle('payloadHeaderStyle')
                            .build();

                        tableRow.push(tableHeaderText);
                        tableBody.push(tableRow);

                        tableHeaderTextTable = new pdfBuilders.PdfMakeElementBuilder()
                            .newTableElement(tableBody)
                            .setColumnWidthsForTable(['*'])
                            .addHeaderRows()
                            .sethLineWidth(function (i, node) {
                                return (i === 1) ? 1 : 0;
                            })
                            .setvLineWidth(function (i, node) {
                                return 0;
                            })
                            .setStyle('payloadTableStyle')
                            //.setMargin([10, 10, 10, 0])
                            .setMargin([12, 20, 12, 5])
                            .build();

                        //bodyBuilderContentArray.push(tableHeaderTextTable);
                        containerTableRow.push(tableHeaderTextTable);
                        containerTableBody.push(containerTableRow);
                        containerTableRow = [];

                        tableRow = [];
                        tableBody = [];

                        cell1 = config.getTranslationText('2545');
                        if (displayChassisTypeColumn) {
                            chassisCell = config.getTranslationText('75');
                        } else {
                            chassisCell = null;
                        }
                        cell2 = {
                            text: config.getTranslationText('1255') + ' *',
                            alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE,
                            style: 'payloadHeaderTextStyle'
                        };
                        cell3 = {
                            text: config.getTranslationText('1256') + ' *',
                            alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE,
                            style: 'payloadHeaderTextStyle'
                        };
                        cell4 = {
                            text: config.getTranslationText('1257') + ' *',
                            alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE,
                            style: 'payloadHeaderTextStyle'
                        };
                        cell5 = {
                            text: config.getTranslationText('2546'),
                            alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE
                        };
                        cell6 = {
                            text: config.getTranslationText('2547'),
                            alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE
                        };
                        cell7 = {
                            text: config.getTranslationText('2548'),
                            alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE
                        };
                        tableBody.push(createRow(cell1, chassisCell, cell2, cell3, cell4, cell5, cell6, cell7, 'tableHeader'));

                        if (chassisType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            // Vehicle
                            cell1 = {
                                text: getActiveOffer().getRig().getVehicle().getDescription(),
                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT
                            };
                            if (displayChassisTypeColumn) {
                                chassisCell = {
                                    text: config.getTranslationText('2549'),
                                    alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT
                                };
                            } else {
                                chassisCell = null;
                            }
                            cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.VEHICLE_COG, config.CHASSIS_OBJECT_TYPES.VEHICLE, config.COG_AXIS_TYPES.HORIZONTAL), measurementSystemIdToUse);
                            cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.VEHICLE_COG, config.CHASSIS_OBJECT_TYPES.VEHICLE, config.COG_AXIS_TYPES.VERTICAL), measurementSystemIdToUse);
                            cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.VEHICLE_COG, config.CHASSIS_OBJECT_TYPES.VEHICLE, config.COG_AXIS_TYPES.LATERAL), measurementSystemIdToUse);
                            cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightFront(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.CHASSIS_OBJECT_TYPES.VEHICLE), measurementSystemIdToUse);
                            cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightRear(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.CHASSIS_OBJECT_TYPES.VEHICLE), measurementSystemIdToUse);
                            cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTotal(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.CHASSIS_OBJECT_TYPES.VEHICLE), measurementSystemIdToUse);
                            tableBody.push(createRow(cell1, chassisCell, cell2, cell3, cell4, cell5, cell6, cell7, 'payloadTableNumberStyle'));

                            // Fuel Tanks
                            if(getActiveOffer().getRig().getVehicle().getFuelType() !== config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE) {
                                let numDieselTanks = offerManager.getActiveOffer().getRig().getVehicle().countFuelTankType(config.FUEL_TYPE_OPTIONS.DIESEL.CODE);
                                getConfiguration().getFuelTanks().forEach(function (fuelTank) {
                                    var cogElement = 'FUELTANK' + fuelTank.getNumber() + '_COG';

                                    let fuelTankText;
                                    if(fuelTank.getContents() === config.FUEL_TYPE_OPTIONS.ADBLUE.CODE) {
                                        fuelTankText = config.getTranslationText('4673', [fuelTank.getNumber() - numDieselTanks]);
                                    } else {
                                        fuelTankText = config.getTranslationText('2550', [fuelTank.getNumber()]);
                                    }
                                    cell1 = {
                                        // text: config.getTranslationText('2550') + ' ' + fuelTank.getNumber(),
                                        text: fuelTankText,
                                        alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT
                                    };
                                    if (displayChassisTypeColumn) {
                                        chassisCell = {
                                            text: config.getTranslationText('2549'),
                                            alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT
                                        };
                                    } else {
                                        chassisCell = null;
                                    }
                                    cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS[cogElement], config.CHASSIS_OBJECT_TYPES.VEHICLE, config.COG_AXIS_TYPES.HORIZONTAL), measurementSystemIdToUse);
                                    cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS[cogElement], config.CHASSIS_OBJECT_TYPES.VEHICLE, config.COG_AXIS_TYPES.VERTICAL), measurementSystemIdToUse);
                                    cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS[cogElement], config.CHASSIS_OBJECT_TYPES.VEHICLE, config.COG_AXIS_TYPES.LATERAL), measurementSystemIdToUse);
                                    cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightFront(config.OBJECT_TYPES.FUEL_TANKS, config.CHASSIS_OBJECT_TYPES.VEHICLE, fuelTank.getNumber()), measurementSystemIdToUse);
                                    cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightRear(config.OBJECT_TYPES.FUEL_TANKS, config.CHASSIS_OBJECT_TYPES.VEHICLE, fuelTank.getNumber()), measurementSystemIdToUse);
                                    cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTotal(config.OBJECT_TYPES.FUEL_TANKS, config.CHASSIS_OBJECT_TYPES.VEHICLE, fuelTank.getNumber()), measurementSystemIdToUse);
                                    tableBody.push(createRow(cell1, chassisCell, cell2, cell3, cell4, cell5, cell6, cell7, 'payloadTableNumberStyle'));
                                });
                            }

                            // Crew
                            cell1 = {
                                text: config.getTranslationText('2551'),
                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT
                            };
                            if (displayChassisTypeColumn) {
                                chassisCell = {
                                    text: config.getTranslationText('2549'),
                                    alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT
                                };
                            } else {
                                chassisCell = null;
                            }
                            cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.CREWFIRSTROW_COG, config.CHASSIS_OBJECT_TYPES.VEHICLE, config.COG_AXIS_TYPES.HORIZONTAL), measurementSystemIdToUse);
                            cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.CREWFIRSTROW_COG, config.CHASSIS_OBJECT_TYPES.VEHICLE, config.COG_AXIS_TYPES.VERTICAL), measurementSystemIdToUse);
                            cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.CREWFIRSTROW_COG, config.CHASSIS_OBJECT_TYPES.VEHICLE, config.COG_AXIS_TYPES.LATERAL), measurementSystemIdToUse);
                            cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightFront(config.OBJECT_TYPES.CREW_FIRST, config.CHASSIS_OBJECT_TYPES.VEHICLE), measurementSystemIdToUse);
                            cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightRear(config.OBJECT_TYPES.CREW_FIRST, config.CHASSIS_OBJECT_TYPES.VEHICLE), measurementSystemIdToUse);
                            cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTotal(config.OBJECT_TYPES.CREW_FIRST, config.CHASSIS_OBJECT_TYPES.VEHICLE), measurementSystemIdToUse);
                            tableBody.push(createRow(cell1, chassisCell, cell2, cell3, cell4, cell5, cell6, cell7, 'payloadTableNumberStyle'));

                            if (getConfiguration().getCrewSecondRowIncluded() === true) {
                                cell1 = {
                                    text: config.getTranslationText('2552'),
                                    alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT
                                };
                                if (displayChassisTypeColumn) {
                                    chassisCell = {
                                        text: config.getTranslationText('2549'),
                                        alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT
                                    };
                                } else {
                                    chassisCell = null;
                                }
                                cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.CREWSECONDROW_COG, config.CHASSIS_OBJECT_TYPES.VEHICLE, config.COG_AXIS_TYPES.HORIZONTAL), measurementSystemIdToUse);
                                cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.CREWSECONDROW_COG, config.CHASSIS_OBJECT_TYPES.VEHICLE, config.COG_AXIS_TYPES.VERTICAL), measurementSystemIdToUse);
                                cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.CREWSECONDROW_COG, config.CHASSIS_OBJECT_TYPES.VEHICLE, config.COG_AXIS_TYPES.LATERAL), measurementSystemIdToUse);
                                cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightFront(config.OBJECT_TYPES.CREW_SECOND, config.CHASSIS_OBJECT_TYPES.VEHICLE), measurementSystemIdToUse);
                                cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightRear(config.OBJECT_TYPES.CREW_SECOND, config.CHASSIS_OBJECT_TYPES.VEHICLE), measurementSystemIdToUse);
                                cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTotal(config.OBJECT_TYPES.CREW_SECOND, config.CHASSIS_OBJECT_TYPES.VEHICLE), measurementSystemIdToUse);
                                tableBody.push(createRow(cell1, chassisCell, cell2, cell3, cell4, cell5, cell6, cell7, 'payloadTableNumberStyle'));
                            }

                            // Body
                            if (getConfiguration().getBody() !== null) {
                                createBodyRow(getConfiguration().getBody(), undefined, config.CHASSIS_OBJECT_TYPES.VEHICLE, tableBody, displayChassisTypeColumn);
                            }
                            // Equipment
                            createAccessoryRows(getActiveOffer().getRig().getVehicle().getAccessories(), undefined, config.CHASSIS_OBJECT_TYPES.VEHICLE, tableBody, displayChassisTypeColumn);

                            // Payload
                            createPayloadRows(getActiveOffer().getRig().getVehicle().getPayloadHolder(), undefined, config.CHASSIS_OBJECT_TYPES.VEHICLE, tableBody, displayChassisTypeColumn);

                            // Add section for combination types not vehicle or vehicle and drawbar
                            if (displayChassisTypeColumn) {
                                // Trailer 1
                                cell1 = {
                                    text: getActiveOffer().getRig().getTrailer1().getDescription(),
                                    alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT
                                };
                                chassisCell = {
                                    text: config.getTranslationText('2553'),
                                    alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT
                                };
                                //cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.TRAILER_COG, config.CHASSIS_OBJECT_TYPES.TRAILER1, config.COG_AXIS_TYPES.HORIZONTAL), measurementSystemIdToUse);
                                //cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.TRAILER_COG, config.CHASSIS_OBJECT_TYPES.TRAILER1, config.COG_AXIS_TYPES.VERTICAL), measurementSystemIdToUse);
                                //cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.TRAILER_COG, config.CHASSIS_OBJECT_TYPES.TRAILER1, config.COG_AXIS_TYPES.LATERAL), measurementSystemIdToUse);
                                //cell2 = { text: '-', alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE };
                                //cell3 = { text: '-', alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE };
                                //cell4 = { text: '-', alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE };
                                cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.CHASSIS_OBJECT_TYPES.TRAILER1, config.COG_AXIS_TYPES.HORIZONTAL), measurementSystemIdToUse);
                                cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.CHASSIS_OBJECT_TYPES.TRAILER1, config.COG_AXIS_TYPES.VERTICAL), measurementSystemIdToUse);
                                cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.CHASSIS_OBJECT_TYPES.TRAILER1, config.COG_AXIS_TYPES.LATERAL), measurementSystemIdToUse);
                                cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferFront(config.CHASSIS_OBJECT_TYPES.TRAILER1, config.CHASSIS_OBJECT_TYPES.VEHICLE, config.CHASSIS_OBJECT_TYPES.TRAILER1), measurementSystemIdToUse);
                                cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferRear(config.CHASSIS_OBJECT_TYPES.TRAILER1, config.CHASSIS_OBJECT_TYPES.VEHICLE, config.CHASSIS_OBJECT_TYPES.TRAILER1), measurementSystemIdToUse);
                                cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferTotal(config.CHASSIS_OBJECT_TYPES.TRAILER1, config.CHASSIS_OBJECT_TYPES.VEHICLE, config.CHASSIS_OBJECT_TYPES.TRAILER1), measurementSystemIdToUse);
                                tableBody.push(createRow(cell1, chassisCell, cell2, cell3, cell4, cell5, cell6, cell7, 'payloadTableNumberStyle'));

                                // Body
                                if (getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getBody() !== null) {
                                    createBodyRow(getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getBody(), config.CHASSIS_OBJECT_TYPES.VEHICLE, config.CHASSIS_OBJECT_TYPES.TRAILER1, tableBody, displayChassisTypeColumn)
                                }
                                // Equipment
                                createAccessoryRows(getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getAccessories(), config.CHASSIS_OBJECT_TYPES.VEHICLE, config.CHASSIS_OBJECT_TYPES.TRAILER1, tableBody, displayChassisTypeColumn);

                                // Payload
                                createPayloadRows(getActiveOffer().getRig().getTrailer1().getPayloadHolder(), config.CHASSIS_OBJECT_TYPES.VEHICLE, config.CHASSIS_OBJECT_TYPES.TRAILER1, tableBody, displayChassisTypeColumn);

                                if (getActiveOffer().getRig().getTrailer2() !== null) {
                                    // Trailer 2
                                    cell1 = {
                                        text: getActiveOffer().getRig().getTrailer2().getDescription(),
                                        alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT
                                    };
                                    chassisCell = {
                                        text: config.getTranslationText('2554'),
                                        alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT
                                    };
                                    //cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.TRAILER_COG, config.CHASSIS_OBJECT_TYPES.TRAILER1, config.COG_AXIS_TYPES.HORIZONTAL), measurementSystemIdToUse);
                                    //cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.TRAILER_COG, config.CHASSIS_OBJECT_TYPES.TRAILER1, config.COG_AXIS_TYPES.VERTICAL), measurementSystemIdToUse);
                                    //cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.TRAILER_COG, config.CHASSIS_OBJECT_TYPES.TRAILER1, config.COG_AXIS_TYPES.LATERAL), measurementSystemIdToUse);
                                    //cell2 = { text: '-', alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE };
                                    //cell3 = { text: '-', alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE };
                                    //cell4 = { text: '-', alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE };
                                    cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.CHASSIS_OBJECT_TYPES.TRAILER2, config.COG_AXIS_TYPES.HORIZONTAL), measurementSystemIdToUse);
                                    cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.CHASSIS_OBJECT_TYPES.TRAILER2, config.COG_AXIS_TYPES.VERTICAL), measurementSystemIdToUse);
                                    cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.CHASSIS_OBJECT_TYPES.TRAILER2, config.COG_AXIS_TYPES.LATERAL), measurementSystemIdToUse);
                                    cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferFront(config.CHASSIS_OBJECT_TYPES.TRAILER2, config.CHASSIS_OBJECT_TYPES.VEHICLE, config.CHASSIS_OBJECT_TYPES.TRAILER2), measurementSystemIdToUse);
                                    cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferRear(config.CHASSIS_OBJECT_TYPES.TRAILER2, config.CHASSIS_OBJECT_TYPES.VEHICLE, config.CHASSIS_OBJECT_TYPES.TRAILER2), measurementSystemIdToUse);
                                    cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferTotal(config.CHASSIS_OBJECT_TYPES.TRAILER2, config.CHASSIS_OBJECT_TYPES.VEHICLE, config.CHASSIS_OBJECT_TYPES.TRAILER2), measurementSystemIdToUse);
                                    tableBody.push(createRow(cell1, chassisCell, cell2, cell3, cell4, cell5, cell6, cell7, 'payloadTableNumberStyle'));

                                    // Body
                                    if (getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getBody() !== null) {
                                        createBodyRow(getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getBody(), config.CHASSIS_OBJECT_TYPES.VEHICLE, config.CHASSIS_OBJECT_TYPES.TRAILER2, tableBody, displayChassisTypeColumn)
                                    }
                                    // Equipment
                                    createAccessoryRows(getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getAccessories(), config.CHASSIS_OBJECT_TYPES.VEHICLE, config.CHASSIS_OBJECT_TYPES.TRAILER2, tableBody, displayChassisTypeColumn);

                                    // Payload
                                    createPayloadRows(getActiveOffer().getRig().getTrailer2().getPayloadHolder(), config.CHASSIS_OBJECT_TYPES.VEHICLE, config.CHASSIS_OBJECT_TYPES.TRAILER2, tableBody, displayChassisTypeColumn);
                                }
                            }

                            cell1 = config.getTranslationText('493');
                            if (displayChassisTypeColumn) {
                                chassisCell = '';
                            } else {
                                chassisCell = null;
                            }
                            cell2 = {
                                text: globals.getUIFormattedLengthValue(getRigOps().getOverallHorizontalCOG(), measurementSystemIdToUse),
                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT
                            };
                            cell3 = {
                                text: globals.getUIFormattedLengthValue(getRigOps().getOverallVerticalCOG(), measurementSystemIdToUse),
                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT
                            };
                            cell4 = {
                                text: globals.getUIFormattedLengthValue(getRigOps().getOverallLateralCOG(), measurementSystemIdToUse),
                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT
                            };
                            cell5 = {
                                text: globals.getUIFormattedMassValue(getRigOps().getGrossAxleWeightFront(config.CHASSIS_OBJECT_TYPES.VEHICLE), measurementSystemIdToUse),
                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT
                            };
                            cell6 = {
                                text: globals.getUIFormattedMassValue(getRigOps().getGrossAxleWeightRear(config.CHASSIS_OBJECT_TYPES.VEHICLE), measurementSystemIdToUse),
                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT
                            };
                            cell7 = {
                                text: globals.getUIFormattedMassValue(getRigOps().getGrossAxleWeightTotal(config.CHASSIS_OBJECT_TYPES.VEHICLE), measurementSystemIdToUse),
                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT
                            };
                            tableBody.push(createRow(cell1, chassisCell, cell2, cell3, cell4, cell5, cell6, cell7, 'tableHeader'));

                        } else if (chassisType === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                            // Trailer 1
                            cell1 = {
                                text: getActiveOffer().getRig().getTrailer1().getDescription(),
                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT
                            };
                            if (displayChassisTypeColumn) {
                                chassisCell = {
                                    text: config.getTranslationText('2553'),
                                    alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT
                                };
                            } else {
                                chassisCell = null;
                            }
                            cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.TRAILER_COG, config.CHASSIS_OBJECT_TYPES.TRAILER1, config.COG_AXIS_TYPES.HORIZONTAL), measurementSystemIdToUse);
                            cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.TRAILER_COG, config.CHASSIS_OBJECT_TYPES.TRAILER1, config.COG_AXIS_TYPES.VERTICAL), measurementSystemIdToUse);
                            cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.TRAILER_COG, config.CHASSIS_OBJECT_TYPES.TRAILER1, config.COG_AXIS_TYPES.LATERAL), measurementSystemIdToUse);
                            cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightFront(config.CHASSIS_OBJECT_TYPES.TRAILER1, config.CHASSIS_OBJECT_TYPES.TRAILER1), measurementSystemIdToUse);
                            cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightRear(config.CHASSIS_OBJECT_TYPES.TRAILER1, config.CHASSIS_OBJECT_TYPES.TRAILER1), measurementSystemIdToUse);
                            cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTotal(config.CHASSIS_OBJECT_TYPES.TRAILER1, config.CHASSIS_OBJECT_TYPES.TRAILER1), measurementSystemIdToUse);
                            tableBody.push(createRow(cell1, chassisCell, cell2, cell3, cell4, cell5, cell6, cell7, 'payloadTableNumberStyle'));

                            // Body
                            if (getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getBody() !== null) {
                                createBodyRow(getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getBody(), undefined, config.CHASSIS_OBJECT_TYPES.TRAILER1, tableBody, displayChassisTypeColumn)
                            }
                            // Equipment
                            createAccessoryRows(getActiveOffer().getRig().getTrailer1().getAccessoryHolder().getAccessories(), undefined, config.CHASSIS_OBJECT_TYPES.TRAILER1, tableBody, displayChassisTypeColumn);

                            // Payload
                            createPayloadRows(getActiveOffer().getRig().getTrailer1().getPayloadHolder(), undefined, config.CHASSIS_OBJECT_TYPES.TRAILER1, tableBody, displayChassisTypeColumn);

                            // Add section for trailer 2
                            if (displayChassisTypeColumn) {
                                // Trailer 2
                                cell1 = {
                                    text: getActiveOffer().getRig().getTrailer2().getDescription(),
                                    alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT
                                };
                                chassisCell = {
                                    text: config.getTranslationText('2554'),
                                    alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT
                                };
                                cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(config.CHASSIS_OBJECT_TYPES.TRAILER1, config.CHASSIS_OBJECT_TYPES.TRAILER2, config.COG_AXIS_TYPES.HORIZONTAL), measurementSystemIdToUse);
                                cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(config.CHASSIS_OBJECT_TYPES.TRAILER1, config.CHASSIS_OBJECT_TYPES.TRAILER2, config.COG_AXIS_TYPES.VERTICAL), measurementSystemIdToUse);
                                cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(config.CHASSIS_OBJECT_TYPES.TRAILER1, config.CHASSIS_OBJECT_TYPES.TRAILER2, config.COG_AXIS_TYPES.LATERAL), measurementSystemIdToUse);
                                cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferFront(config.CHASSIS_OBJECT_TYPES.TRAILER2, config.CHASSIS_OBJECT_TYPES.TRAILER1, config.CHASSIS_OBJECT_TYPES.TRAILER2), measurementSystemIdToUse);
                                cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferRear(config.CHASSIS_OBJECT_TYPES.TRAILER2, config.CHASSIS_OBJECT_TYPES.TRAILER1, config.CHASSIS_OBJECT_TYPES.TRAILER2), measurementSystemIdToUse);
                                cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferTotal(config.CHASSIS_OBJECT_TYPES.TRAILER2, config.CHASSIS_OBJECT_TYPES.TRAILER1, config.CHASSIS_OBJECT_TYPES.TRAILER2), measurementSystemIdToUse);
                                tableBody.push(createRow(cell1, chassisCell, cell2, cell3, cell4, cell5, cell6, cell7, 'payloadTableNumberStyle'));

                                // Body
                                if (getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getBody() !== null) {
                                    createBodyRow(getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getBody(), config.CHASSIS_OBJECT_TYPES.TRAILER1, config.CHASSIS_OBJECT_TYPES.TRAILER2, tableBody, displayChassisTypeColumn)
                                }
                                // Equipment
                                createAccessoryRows(getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getAccessories(), config.CHASSIS_OBJECT_TYPES.TRAILER1, config.CHASSIS_OBJECT_TYPES.TRAILER2, tableBody, displayChassisTypeColumn);

                                // Payload
                                createPayloadRows(getActiveOffer().getRig().getTrailer2().getPayloadHolder(), config.CHASSIS_OBJECT_TYPES.TRAILER1, config.CHASSIS_OBJECT_TYPES.TRAILER2, tableBody, displayChassisTypeColumn);
                            }

                            cell1 = config.getTranslationText('493');
                            if (displayChassisTypeColumn) {
                                chassisCell = '';
                            } else {
                                chassisCell = null;
                            }
                            cell2 = {
                                text: globals.getUIFormattedLengthValue(getRigOps().getTrailer1OverallHorizontalCOG(), measurementSystemIdToUse),
                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT
                            };
                            cell3 = {
                                text: globals.getUIFormattedLengthValue(getRigOps().getTrailer1OverallVerticalCOG(), measurementSystemIdToUse),
                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT
                            };
                            cell4 = {
                                text: globals.getUIFormattedLengthValue(getRigOps().getTrailer1OverallLateralCOG(), measurementSystemIdToUse),
                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT
                            };
                            cell5 = {
                                text: globals.getUIFormattedMassValue(getRigOps().getGrossAxleWeightFront(config.CHASSIS_OBJECT_TYPES.TRAILER1), measurementSystemIdToUse),
                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT
                            };
                            cell6 = {
                                text: globals.getUIFormattedMassValue(getRigOps().getGrossAxleWeightRear(config.CHASSIS_OBJECT_TYPES.TRAILER1), measurementSystemIdToUse),
                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT
                            };
                            cell7 = {
                                text: globals.getUIFormattedMassValue(getRigOps().getGrossAxleWeightTotal(config.CHASSIS_OBJECT_TYPES.TRAILER1), measurementSystemIdToUse),
                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT
                            };
                            tableBody.push(createRow(cell1, chassisCell, cell2, cell3, cell4, cell5, cell6, cell7, 'tableHeader'));
                        } else if (chassisType === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                            // Trailer 2
                            cell1 = {
                                text: getActiveOffer().getRig().getTrailer2().getDescription(),
                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT
                            };
                            cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.TRAILER_COG, config.CHASSIS_OBJECT_TYPES.TRAILER2, config.COG_AXIS_TYPES.HORIZONTAL), measurementSystemIdToUse);
                            cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.TRAILER_COG, config.CHASSIS_OBJECT_TYPES.TRAILER2, config.COG_AXIS_TYPES.VERTICAL), measurementSystemIdToUse);
                            cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.TRAILER_COG, config.CHASSIS_OBJECT_TYPES.TRAILER2, config.COG_AXIS_TYPES.LATERAL), measurementSystemIdToUse);
                            cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightFront(config.CHASSIS_OBJECT_TYPES.TRAILER2, config.CHASSIS_OBJECT_TYPES.TRAILER2), measurementSystemIdToUse);
                            cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightRear(config.CHASSIS_OBJECT_TYPES.TRAILER2, config.CHASSIS_OBJECT_TYPES.TRAILER2), measurementSystemIdToUse);
                            cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTotal(config.CHASSIS_OBJECT_TYPES.TRAILER2, config.CHASSIS_OBJECT_TYPES.TRAILER2), measurementSystemIdToUse);
                            tableBody.push(createRow(cell1, chassisCell, cell2, cell3, cell4, cell5, cell6, cell7, 'payloadTableNumberStyle'));

                            // Body
                            if (getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getBody() !== null) {
                                createBodyRow(getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getBody(), undefined, config.CHASSIS_OBJECT_TYPES.TRAILER2, tableBody)
                            }
                            // Equipment
                            createAccessoryRows(getActiveOffer().getRig().getTrailer2().getAccessoryHolder().getAccessories(), undefined, config.CHASSIS_OBJECT_TYPES.TRAILER2, tableBody);

                            // Payload
                            createPayloadRows(getActiveOffer().getRig().getTrailer2().getPayloadHolder(), undefined, config.CHASSIS_OBJECT_TYPES.TRAILER2, tableBody);

                            cell1 = config.getTranslationText('493');
                            cell2 = {
                                text: globals.getUIFormattedLengthValue(getRigOps().getTrailer2OverallHorizontalCOG(), measurementSystemIdToUse),
                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT
                            };
                            cell3 = {
                                text: globals.getUIFormattedLengthValue(getRigOps().getTrailer2OverallVerticalCOG(), measurementSystemIdToUse),
                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT
                            };
                            cell4 = {
                                text: globals.getUIFormattedLengthValue(getRigOps().getTrailer2OverallLateralCOG(), measurementSystemIdToUse),
                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT
                            };
                            cell5 = {
                                text: globals.getUIFormattedMassValue(getRigOps().getGrossAxleWeightFront(config.CHASSIS_OBJECT_TYPES.TRAILER2), measurementSystemIdToUse),
                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT
                            };
                            cell6 = {
                                text: globals.getUIFormattedMassValue(getRigOps().getGrossAxleWeightRear(config.CHASSIS_OBJECT_TYPES.TRAILER2), measurementSystemIdToUse),
                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT
                            };
                            cell7 = {
                                text: globals.getUIFormattedMassValue(getRigOps().getGrossAxleWeightTotal(config.CHASSIS_OBJECT_TYPES.TRAILER2), measurementSystemIdToUse),
                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT
                            };
                            tableBody.push(createRow(cell1, chassisCell, cell2, cell3, cell4, cell5, cell6, cell7, 'tableHeader'));
                        }

                        function createBodyRow(bodyObject, toChassisObjectType, fromChassisObjectType, tableBodyToAddTo, displayTransferCells) {
                            cell1 = {
                                text: bodyObject.getDescription(),
                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT
                            };
                            if (displayTransferCells) {
                                var chassisText;
                                switch (fromChassisObjectType) {
                                    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                                        chassisText = config.getTranslationText('2553');
                                        break;
                                    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                                        chassisText = config.getTranslationText('2554');
                                        break;
                                    default:
                                        chassisText = config.getTranslationText('2549');
                                        break;
                                }
                                chassisCell = {
                                    text: chassisText,
                                    alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT
                                };
                                if ((fromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER1 || fromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2) && toChassisObjectType !== undefined) {
                                    //cell2 = { text: '-', alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE };
                                    //cell3 = { text: '-', alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE };
                                    //cell4 = { text: '-', alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE };
                                    cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(toChassisObjectType, fromChassisObjectType, config.COG_AXIS_TYPES.HORIZONTAL), measurementSystemIdToUse);
                                    cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(toChassisObjectType, fromChassisObjectType, config.COG_AXIS_TYPES.VERTICAL), measurementSystemIdToUse);
                                    cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(toChassisObjectType, fromChassisObjectType, config.COG_AXIS_TYPES.LATERAL), measurementSystemIdToUse);
                                    cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferFront(config.ACCESSORY_TYPES.BODY, toChassisObjectType, fromChassisObjectType), measurementSystemIdToUse);
                                    cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferRear(config.ACCESSORY_TYPES.BODY, toChassisObjectType, fromChassisObjectType), measurementSystemIdToUse);
                                    cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferTotal(config.ACCESSORY_TYPES.BODY, toChassisObjectType, fromChassisObjectType), measurementSystemIdToUse);
                                }
                                /*else if (fromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                                                                            cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(toChassisObjectType, fromChassisObjectType, config.COG_AXIS_TYPES.HORIZONTAL), measurementSystemIdToUse);
                                                                            cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(toChassisObjectType, fromChassisObjectType, config.COG_AXIS_TYPES.VERTICAL), measurementSystemIdToUse);
                                                                            cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(toChassisObjectType, fromChassisObjectType, config.COG_AXIS_TYPES.LATERAL), measurementSystemIdToUse);
                                                                            cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferFront(config.ACCESSORY_TYPES.BODY, toChassisObjectType, fromChassisObjectType), measurementSystemIdToUse);
                                                                            cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferRear(config.ACCESSORY_TYPES.BODY, toChassisObjectType, fromChassisObjectType), measurementSystemIdToUse);
                                                                            cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferTotal(config.ACCESSORY_TYPES.BODY, toChassisObjectType, fromChassisObjectType), measurementSystemIdToUse);
                                                                        }*/
                                else {
                                    cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.BODY_COG, fromChassisObjectType, config.COG_AXIS_TYPES.HORIZONTAL), measurementSystemIdToUse);
                                    cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.BODY_COG, fromChassisObjectType, config.COG_AXIS_TYPES.VERTICAL), measurementSystemIdToUse);
                                    cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.BODY_COG, fromChassisObjectType, config.COG_AXIS_TYPES.LATERAL), measurementSystemIdToUse);
                                    cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightFront(config.ACCESSORY_TYPES.BODY, fromChassisObjectType), measurementSystemIdToUse);
                                    cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightRear(config.ACCESSORY_TYPES.BODY, fromChassisObjectType), measurementSystemIdToUse);
                                    cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTotal(config.ACCESSORY_TYPES.BODY, fromChassisObjectType), measurementSystemIdToUse);
                                }
                            } else {
                                chassisCell = null;
                                cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.BODY_COG, fromChassisObjectType, config.COG_AXIS_TYPES.HORIZONTAL), measurementSystemIdToUse);
                                cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.BODY_COG, fromChassisObjectType, config.COG_AXIS_TYPES.VERTICAL), measurementSystemIdToUse);
                                cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.BODY_COG, fromChassisObjectType, config.COG_AXIS_TYPES.LATERAL), measurementSystemIdToUse);
                                cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightFront(config.ACCESSORY_TYPES.BODY, fromChassisObjectType), measurementSystemIdToUse);
                                cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightRear(config.ACCESSORY_TYPES.BODY, fromChassisObjectType), measurementSystemIdToUse);
                                cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTotal(config.ACCESSORY_TYPES.BODY, fromChassisObjectType), measurementSystemIdToUse);
                            }

                            tableBodyToAddTo.push(createRow(cell1, chassisCell, cell2, cell3, cell4, cell5, cell6, cell7, 'payloadTableNumberStyle'));
                        }

                        function createPayloadRows(payloadHolder, toChassisObjectType, fromChassisObjectType, tableBodyToAddTo, displayTransferCells) {
                            if (payloadHolder.getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {
                                payloadHolder.getDetailedPayloadItems().forEach(createPayloadRow);
                            } else {
                                createPayloadRow(payloadHolder.getSimplePayloadObject(), config.getTranslationText('2868'));
                            }

                            function createPayloadRow(payloadItem, description) {
                                if (payloadItem !== null) {
                                    var descriptionToUse = typeof description === 'string' ? description : payloadItem.getDescription();
                                    if (payloadItem.getWeightType() === config.PAYLOAD_WEIGHT_TYPES.VOLUME) {
                                        descriptionToUse += ' (' + payloadItem.getPayload() + ' ' + payloadItem.getUnitOfMeasure() + ')';
                                    }
                                    cell1 = {
                                        text: descriptionToUse,
                                        alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT
                                    };
                                    if (displayTransferCells) {
                                        var chassisText;
                                        switch (fromChassisObjectType) {
                                            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                                                chassisText = config.getTranslationText('2553');
                                                break;
                                            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                                                chassisText = config.getTranslationText('2554');
                                                break;
                                            default:
                                                chassisText = config.getTranslationText('2549');
                                                break;
                                        }
                                        chassisCell = {
                                            text: chassisText,
                                            alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT
                                        };
                                        if ((fromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER1 || fromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2) && toChassisObjectType !== undefined) {
                                            cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(toChassisObjectType, fromChassisObjectType, config.COG_AXIS_TYPES.HORIZONTAL), measurementSystemIdToUse);
                                            cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(toChassisObjectType, fromChassisObjectType, config.COG_AXIS_TYPES.VERTICAL), measurementSystemIdToUse);
                                            cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(toChassisObjectType, fromChassisObjectType, config.COG_AXIS_TYPES.LATERAL), measurementSystemIdToUse);
                                            cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferFront(config.ACCESSORY_TYPES.PAYLOAD, toChassisObjectType, fromChassisObjectType, payloadItem.getId()), measurementSystemIdToUse);
                                            cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferRear(config.ACCESSORY_TYPES.PAYLOAD, toChassisObjectType, fromChassisObjectType, payloadItem.getId()), measurementSystemIdToUse);
                                            cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferTotal(config.ACCESSORY_TYPES.PAYLOAD, toChassisObjectType, fromChassisObjectType, payloadItem.getId()), measurementSystemIdToUse);
                                        }
                                        /*else if (fromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                                                                                            cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(toChassisObjectType, fromChassisObjectType, config.COG_AXIS_TYPES.HORIZONTAL), measurementSystemIdToUse);
                                                                                            cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(toChassisObjectType, fromChassisObjectType, config.COG_AXIS_TYPES.VERTICAL), measurementSystemIdToUse);
                                                                                            cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(toChassisObjectType, fromChassisObjectType, config.COG_AXIS_TYPES.LATERAL), measurementSystemIdToUse);
                                                                                            cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferFront(config.ACCESSORY_TYPES.PAYLOAD, toChassisObjectType, fromChassisObjectType, payloadItem.getId()), measurementSystemIdToUse);
                                                                                            cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferRear(config.ACCESSORY_TYPES.PAYLOAD, toChassisObjectType, fromChassisObjectType, payloadItem.getId()), measurementSystemIdToUse);
                                                                                            cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferTotal(config.ACCESSORY_TYPES.PAYLOAD, toChassisObjectType, fromChassisObjectType, payloadItem.getId()), measurementSystemIdToUse);
                                                                                        }*/
                                        else {
                                            cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.PAYLOAD_COG, fromChassisObjectType, config.COG_AXIS_TYPES.HORIZONTAL, payloadItem.getId()), measurementSystemIdToUse);
                                            cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.PAYLOAD_COG, fromChassisObjectType, config.COG_AXIS_TYPES.VERTICAL, payloadItem.getId()), measurementSystemIdToUse);
                                            cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.PAYLOAD_COG, fromChassisObjectType, config.COG_AXIS_TYPES.LATERAL, payloadItem.getId()), measurementSystemIdToUse);
                                            cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightFront(config.ACCESSORY_TYPES.PAYLOAD, fromChassisObjectType, payloadItem.getId()), measurementSystemIdToUse);
                                            cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightRear(config.ACCESSORY_TYPES.PAYLOAD, fromChassisObjectType, payloadItem.getId()), measurementSystemIdToUse);
                                            cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTotal(config.ACCESSORY_TYPES.PAYLOAD, fromChassisObjectType, payloadItem.getId()), measurementSystemIdToUse);
                                        }
                                    } else {
                                        chassisCell = null;
                                        cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.PAYLOAD_COG, fromChassisObjectType, config.COG_AXIS_TYPES.HORIZONTAL, payloadItem.getId()), measurementSystemIdToUse);
                                        cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.PAYLOAD_COG, fromChassisObjectType, config.COG_AXIS_TYPES.VERTICAL, payloadItem.getId()), measurementSystemIdToUse);
                                        cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(config.COG_ELEMENTS.PAYLOAD_COG, fromChassisObjectType, config.COG_AXIS_TYPES.LATERAL, payloadItem.getId()), measurementSystemIdToUse);
                                        cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightFront(config.ACCESSORY_TYPES.PAYLOAD, fromChassisObjectType, payloadItem.getId()), measurementSystemIdToUse);
                                        cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightRear(config.ACCESSORY_TYPES.PAYLOAD, fromChassisObjectType, payloadItem.getId()), measurementSystemIdToUse);
                                        cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTotal(config.ACCESSORY_TYPES.PAYLOAD, fromChassisObjectType, payloadItem.getId()), measurementSystemIdToUse);
                                    }
                                    tableBodyToAddTo.push(createRow(cell1, chassisCell, cell2, cell3, cell4, cell5, cell6, cell7, 'payloadTableNumberStyle'));
                                }
                            }
                        }

                        function createAccessoryRows(listOfAccessories, toChassisObjectType, fromChassisObjectType, tableBodyToAddTo, displayTransferCells) {
                            listOfAccessories.forEach(function (accessory) {
                                var cogElement;
                                if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY) {
                                    switch (accessory.getAccessoryType()) {
                                        case config.ACCESSORY_TYPES.CRANE:
                                            cogElement = config.COG_ELEMENTS.CRANE_COG;
                                            break;
                                        case config.ACCESSORY_TYPES.OTHER:
                                            cogElement = config.COG_ELEMENTS.OTHER_COG;
                                            break;
                                        case config.ACCESSORY_TYPES.FRIDGE:
                                            cogElement = config.COG_ELEMENTS.FRIDGE_COG;
                                            break;
                                        case config.ACCESSORY_TYPES.TAILLIFT:
                                            cogElement = config.COG_ELEMENTS.TAILLIFT_COG;
                                            break;
                                        case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                                            cogElement = config.COG_ELEMENTS.FIFTH_WHEEL_COG;
                                            break;
                                        case config.ACCESSORY_TYPES.HITCH:
                                            cogElement = config.COG_ELEMENTS.HITCH_COG;
                                            break;
                                        case config.ACCESSORY_TYPES.HOOKLIFT:
                                            cogElement = config.COG_ELEMENTS.HOOKLIFT_COG;
                                            break;
                                    }

                                    cell1 = {
                                        text: accessory.getDescription(),
                                        alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT
                                    };
                                    if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                                        if (displayTransferCells) {
                                            var chassisText;
                                            switch (fromChassisObjectType) {
                                                case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                                                    chassisText = config.getTranslationText('2553');
                                                    break;
                                                case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                                                    chassisText = config.getTranslationText('2554');
                                                    break;
                                                default:
                                                    chassisText = config.getTranslationText('2549');
                                                    break;
                                            }
                                            chassisCell = {
                                                text: chassisText,
                                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT
                                            };
                                            if ((fromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER1 || fromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2) && toChassisObjectType !== undefined) {
                                                //cell2 = { text: '-', alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE };
                                                //cell3 = { text: '-', alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE };
                                                //cell4 = { text: '-', alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE };
                                                cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(toChassisObjectType, fromChassisObjectType, config.COG_AXIS_TYPES.HORIZONTAL), measurementSystemIdToUse);
                                                cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(toChassisObjectType, fromChassisObjectType, config.COG_AXIS_TYPES.VERTICAL), measurementSystemIdToUse);
                                                cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(toChassisObjectType, fromChassisObjectType, config.COG_AXIS_TYPES.LATERAL), measurementSystemIdToUse);
                                                cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferFront(accessory.getAccessoryType(), toChassisObjectType, fromChassisObjectType, accessory.getId()), measurementSystemIdToUse);
                                                cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferRear(accessory.getAccessoryType(), toChassisObjectType, fromChassisObjectType, accessory.getId()), measurementSystemIdToUse);
                                                cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferTotal(accessory.getAccessoryType(), toChassisObjectType, fromChassisObjectType, accessory.getId()), measurementSystemIdToUse);
                                            }
                                            /*else if (fromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                                                                                                    cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(toChassisObjectType, fromChassisObjectType, config.COG_AXIS_TYPES.HORIZONTAL), measurementSystemIdToUse);
                                                                                                    cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(toChassisObjectType, fromChassisObjectType, config.COG_AXIS_TYPES.VERTICAL), measurementSystemIdToUse);
                                                                                                    cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(toChassisObjectType, fromChassisObjectType, config.COG_AXIS_TYPES.LATERAL), measurementSystemIdToUse);
                                                                                                    cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferFront(accessory.getAccessoryType(), toChassisObjectType, fromChassisObjectType, accessory.getId()), measurementSystemIdToUse);
                                                                                                    cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferRear(accessory.getAccessoryType(), toChassisObjectType, fromChassisObjectType, accessory.getId()), measurementSystemIdToUse);
                                                                                                    cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferTotal(accessory.getAccessoryType(), toChassisObjectType, fromChassisObjectType, accessory.getId()), measurementSystemIdToUse);
                                                                                                }*/
                                            else {
                                                cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(cogElement, fromChassisObjectType, config.COG_AXIS_TYPES.HORIZONTAL, accessory.getId()), measurementSystemIdToUse);
                                                cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(cogElement, fromChassisObjectType, config.COG_AXIS_TYPES.VERTICAL, accessory.getId()), measurementSystemIdToUse);
                                                cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(cogElement, fromChassisObjectType, config.COG_AXIS_TYPES.LATERAL, accessory.getId()), measurementSystemIdToUse);
                                                cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightFront(accessory.getAccessoryType(), fromChassisObjectType, accessory.getId()), measurementSystemIdToUse);
                                                cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightRear(accessory.getAccessoryType(), fromChassisObjectType, accessory.getId()), measurementSystemIdToUse);
                                                cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTotal(accessory.getAccessoryType(), fromChassisObjectType, accessory.getId()), measurementSystemIdToUse);
                                            }
                                        } else {
                                            cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(cogElement, fromChassisObjectType, config.COG_AXIS_TYPES.HORIZONTAL, accessory.getId()), measurementSystemIdToUse);
                                            cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(cogElement, fromChassisObjectType, config.COG_AXIS_TYPES.VERTICAL, accessory.getId()), measurementSystemIdToUse);
                                            cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(cogElement, fromChassisObjectType, config.COG_AXIS_TYPES.LATERAL, accessory.getId()), measurementSystemIdToUse);
                                            cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightFront(accessory.getAccessoryType(), fromChassisObjectType, accessory.getId()), measurementSystemIdToUse);
                                            cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightRear(accessory.getAccessoryType(), fromChassisObjectType, accessory.getId()), measurementSystemIdToUse);
                                            cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTotal(accessory.getAccessoryType(), fromChassisObjectType, accessory.getId()), measurementSystemIdToUse);
                                        }
                                    } else {
                                        if (displayTransferCells) {
                                            
                                            switch (fromChassisObjectType) {
                                                case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                                                    chassisText = config.getTranslationText('2553');
                                                    break;
                                                case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                                                    chassisText = config.getTranslationText('2554');
                                                    break;
                                                default:
                                                    chassisText = config.getTranslationText('2549');
                                                    break;
                                            }
                                            chassisCell = {
                                                text: chassisText,
                                                alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT
                                            };
                                            if ((fromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER1 || fromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2) && toChassisObjectType !== undefined) {
                                                //cell2 = { text: '-', alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE };
                                                //cell3 = { text: '-', alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE };
                                                //cell4 = { text: '-', alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE };
                                                cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(toChassisObjectType, fromChassisObjectType, config.COG_AXIS_TYPES.HORIZONTAL), measurementSystemIdToUse);
                                                cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(toChassisObjectType, fromChassisObjectType, config.COG_AXIS_TYPES.VERTICAL), measurementSystemIdToUse);
                                                cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(toChassisObjectType, fromChassisObjectType, config.COG_AXIS_TYPES.LATERAL), measurementSystemIdToUse);
                                                cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferFront(accessory.getAccessoryType(), toChassisObjectType, fromChassisObjectType), measurementSystemIdToUse);
                                                cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferRear(accessory.getAccessoryType(), toChassisObjectType, fromChassisObjectType), measurementSystemIdToUse);
                                                cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferTotal(accessory.getAccessoryType(), toChassisObjectType, fromChassisObjectType), measurementSystemIdToUse);
                                            }
                                            /*else if (fromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                                                                                                    cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(toChassisObjectType, fromChassisObjectType, config.COG_AXIS_TYPES.HORIZONTAL), measurementSystemIdToUse);
                                                                                                    cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(toChassisObjectType, fromChassisObjectType, config.COG_AXIS_TYPES.VERTICAL), measurementSystemIdToUse);
                                                                                                    cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFromForTransferItems(toChassisObjectType, fromChassisObjectType, config.COG_AXIS_TYPES.LATERAL), measurementSystemIdToUse);
                                                                                                    cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferFront(accessory.getAccessoryType(), toChassisObjectType, fromChassisObjectType), measurementSystemIdToUse);
                                                                                                    cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferRear(accessory.getAccessoryType(), toChassisObjectType, fromChassisObjectType), measurementSystemIdToUse);
                                                                                                    cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTransferTotal(accessory.getAccessoryType(), toChassisObjectType, fromChassisObjectType), measurementSystemIdToUse);
                                                                                                }*/
                                            else {
                                                cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(cogElement, fromChassisObjectType, config.COG_AXIS_TYPES.HORIZONTAL), measurementSystemIdToUse);
                                                cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(cogElement, fromChassisObjectType, config.COG_AXIS_TYPES.VERTICAL), measurementSystemIdToUse);
                                                cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(cogElement, fromChassisObjectType, config.COG_AXIS_TYPES.LATERAL), measurementSystemIdToUse);
                                                cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightFront(accessory.getAccessoryType(), fromChassisObjectType), measurementSystemIdToUse);
                                                cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightRear(accessory.getAccessoryType(), fromChassisObjectType), measurementSystemIdToUse);
                                                cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTotal(accessory.getAccessoryType(), fromChassisObjectType), measurementSystemIdToUse);
                                            }
                                        } else {
                                            cell2 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(cogElement, fromChassisObjectType, config.COG_AXIS_TYPES.HORIZONTAL), measurementSystemIdToUse);
                                            cell3 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(cogElement, fromChassisObjectType, config.COG_AXIS_TYPES.VERTICAL), measurementSystemIdToUse);
                                            cell4 = globals.getUIFormattedLengthValue(rigOps.getCOGFrom(cogElement, fromChassisObjectType, config.COG_AXIS_TYPES.LATERAL), measurementSystemIdToUse);
                                            cell5 = globals.getUIFormattedMassValue(rigOps.getAxleWeightFront(accessory.getAccessoryType(), fromChassisObjectType, accessory.getId()), measurementSystemIdToUse);
                                            cell6 = globals.getUIFormattedMassValue(rigOps.getAxleWeightRear(accessory.getAccessoryType(), fromChassisObjectType, accessory.getId()), measurementSystemIdToUse);
                                            cell7 = globals.getUIFormattedMassValue(rigOps.getAxleWeightTotal(accessory.getAccessoryType(), fromChassisObjectType, accessory.getId()), measurementSystemIdToUse);
                                        }
                                    }

                                    tableBodyToAddTo.push(createRow(cell1, chassisCell, cell2, cell3, cell4, cell5, cell6, cell7, 'payloadTableNumberStyle'));
                                }
                            });
                        }

                        function createRow(description, chassisCol, hCogString, vCogString, lCogString, frontString, rearString, totalString, style) {
                            var returnRow = [];
                            returnRow.push(createCell(description, style));
                            if (chassisCol !== null && chassisCol !== undefined) {
                                returnRow.push(createCell(chassisCol, style));
                            }
                            returnRow.push(createCell(hCogString, style));
                            returnRow.push(createCell(vCogString, style));
                            returnRow.push(createCell(lCogString, style));
                            returnRow.push(createCell('', style));
                            returnRow.push(createCell(frontString, style));
                            returnRow.push(createCell(rearString, style));
                            returnRow.push(createCell(totalString, style));

                            return returnRow;
                        }

                        function createCell(text, style) {
                            var returnObject;

                            if (typeof text === 'string') {
                                if (typeof style === 'string') {
                                    returnObject = new pdfBuilders.PdfMakeElementBuilder()
                                        .newTextElement(text)
                                        .setStyle(style)
                                        .build()
                                } else {
                                    returnObject = new pdfBuilders.PdfMakeElementBuilder()
                                        .newTextElement(text)
                                        .build()
                                }
                            } else {
                                if (typeof style === 'string') {
                                    returnObject = new pdfBuilders.PdfMakeElementBuilder()
                                        .newTextElement(text.text)
                                        .setStyle(style)
                                        .build()
                                } else {
                                    returnObject = new pdfBuilders.PdfMakeElementBuilder()
                                        .newTextElement(text.text)
                                        .build()
                                }
                                if (text.style !== undefined) {
                                    returnObject.style = text.style;
                                }
                                if (text.alignment !== undefined) {
                                    returnObject.alignment = text.alignment;
                                }
                                if (text.italics !== undefined) {
                                    returnObject.italics = text.italics;
                                }
                            }

                            return returnObject;
                        }

                        var payloadResultsTable = new pdfBuilders.PdfMakeElementBuilder()
                            .newTableElement(tableBody)
                            .setColumnWidthsForTable(weightAndCentreOfGravityColumnWidths)
                            .addHeaderRows()
                            .sethLineWidth(function (i, node) {
                                return 0;
                            })
                            .setvLineWidth(function (i, node) {
                                return i === 0 || i === node.table.body[0].length ? 0 : 1;
                            })
                            .setFillColourForTable(function (rowIndex, node, columnIndex) {
                                return (rowIndex % 2 === 0) ? '#F6F6F6' : null;
                            })
                            .setStyle('payloadTableStyle')
                            .build();


                        //bodyBuilderContentArray.push(payloadResultsTable);
                        containerTableRow.push(payloadResultsTable);
                        containerTableBody.push(containerTableRow);
                        containerTableRow = [];

                        tableBody = [];
                        tableRow = [];

                        tableRow.push(createCell({
                            text: config.getTranslationText('1259'),
                            italics: true
                        }, 'smallItalicizedText'));
                        tableBody.push(tableRow);

                        var payloadResultsMessageTable = new pdfBuilders.PdfMakeElementBuilder()
                            .newTableElement(tableBody)
                            .setColumnWidthsForTable(['*'])
                            .addNoBordersLayout()
                            .setStyle('payloadTableStyle')
                            .build();

                        containerTableRow.push(payloadResultsMessageTable);
                        containerTableBody.push(containerTableRow);
                        containerTableRow = [];
                    }
                }

                function createNotesAndWarnings(addPageBreak) {
                    var tableBody = [],
                        tableRow = [],
                        notesList = [],
                        warningsList = [],
                        pageBreakAdded = true,
                        listMargins = [0, -3, 0, 2];

                    tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement(config.getTranslationText('1068'))
                        .setStyle('payloadHeaderStyle')
                        .build());
                    tableBody.push(tableRow);

                    var notesAndWarningsTitleTable = new pdfBuilders.PdfMakeElementBuilder()
                        .newTableElement(tableBody)
                        .setColumnWidthsForTable(['*'])
                        .sethLineWidth(function (i, node) {
                            return i === 1 ? 1 : 0;
                        })
                        .setvLineWidth(function (i, node) {
                            return 0;
                        })
                        //.setMargin(12)
                        //.setMargin([12, 20, 12, 5])
                        .setMargin([12, 0, 12, 5])
                        .build();

                    tableRow = [];
                    tableBody = [];

                    // create notes table start
                    tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement(config.getTranslationText('4330'))
                        .build());
                    tableBody.push(tableRow);

                    tableRow = [];
                    notesList = [];
                    getNotes().getAsArrayOfStrings(function (stringTrackerObj){ return returnSectionNotes(stringTrackerObj, config.NOTES_HEADINGS.MANUFACTURER_RATINGS); }).forEach(function (text) {
                        notesList.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(text)
                            .build());
                    });
                    // notesList.push(new pdfBuilders.PdfMakeElementBuilder()
                    //     .newTextElement(config.getTranslationText('4331'))
                    //     .build());
                    tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newBulletedListElement(notesList)
                        .setMargin(listMargins)
                        .build()
                    );
                    tableBody.push(tableRow);

                    tableRow = [];
                    tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement(config.getTranslationText('4312'))
                        //.setMargin([0, 0, 0, 2])
                        .build());
                    tableBody.push(tableRow);

                    var legislationStubsArray = globals.getDistinctLegislationStubsArray(getLegislations(), globals.user.getLegislationStubObjectUsingId);
                    var legislationSectionStack = [],
                        legislationBulletPointsArray = [];

                    // legislationStubsArray.forEach(function(stub, index){
                        
                    //     legislationBulletPointsArray.push(new pdfBuilders.PdfMakeElementBuilder()
                    //         .newTextElement(stub.roadNames !== '' ? stub.description + ' (' + stub.roadNames + ')' : stub.description)
                    //         .build());                                
                    // });
                    notesList = [];
                    getNotes().getStringTrackerObjects(function (stringTrackerObj){ return returnSectionNotes(stringTrackerObj, config.NOTES_HEADINGS.REGULATIONS_APPLIED); }).forEach(function (legislationNoteStringTrackerObj) {
                        notesList.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(legislationNoteStringTrackerObj.getStringVal())
                            .build());

                            let encodeLegislationId = legislationNoteStringTrackerObj.getId().split('_')[1];
                            let legislationdDRNotes = getDDRNotes()[encodeLegislationId];
                            if (legislationdDRNotes) {
                                let ddrNotesList = [];
                                legislationdDRNotes.getStringTrackerObjects().forEach(function (dDRNoteStringTrackerObj) {
                                    ddrNotesList.push(new pdfBuilders.PdfMakeElementBuilder()
                                                    .newTextElement(dDRNoteStringTrackerObj.getStringVal())
                                                    .build());
                                });
                                if(ddrNotesList.length > 0) {
                                    notesList.push(new pdfBuilders.PdfMakeElementBuilder()
                                                .newBulletedListElement(ddrNotesList)
                                                // .setMargin(listMargins)
                                                .setMargin([0, 1, 0, 2])
                                                .build());
                                }
                            } 
                    });

                    tableRow = [];
                    // getNotes().getAsArrayOfStrings(globals.returnApplicationItemsOnly).forEach(function (text) {
                    //     legislationBulletPointsArray.push(new pdfBuilders.PdfMakeElementBuilder()
                    //         .newTextElement(text)
                    //         .build());
                    // });
                    legislationSectionStack.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newBulletedListElement(notesList)
                        .setMargin(listMargins)
                        .build()
                    );

                    tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newStackElement(legislationSectionStack)
                        //.setMargin(listMargins)
                        .setMargin([0, 0, 0, 2])
                        .build()
                    );
                    tableBody.push(tableRow);
                    
                    tableRow = [];
                    tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement(config.getTranslationText('4313'))
                        //.setMargin([0, 10, 0, 2])
                        //.setMargin([0, 5, 0, 2])
                        //.setMargin(listMargins)
                        .build());
                    tableBody.push(tableRow);

                    notesList = [];
                    getNotes().getAsArrayOfStrings(function (stringTrackerObj){ return returnSectionNotes(stringTrackerObj, config.NOTES_HEADINGS.GENERAL); }).forEach(function (text) {
                        notesList.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(text)
                            .build());
                    });
                    tableRow = [];
                    tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newBulletedListElement(notesList)
                        //.setMargin([0, 2, 0, 0])
                        .setMargin(listMargins)
                        .build()
                    );
                    tableBody.push(tableRow);
                    
                    var notesTable = new pdfBuilders.PdfMakeElementBuilder()
                        .newTableElement(tableBody)
                        .setColumnWidthsForTable(['*'])
                        .addNoBordersLayout()
                        //.setMargin([5, 0, 0, 0])
                        .build();

                    tableRow = [];
                    tableBody = [];
                    // create notes table end

                    // create warnings table start
                    
                    if (getWarnings()[config.WARNINGS_HEADINGS.MANUFACTURER_RATINGS].getStringTrackerObjects().length > 0) {
                        warningsList = [];
                        tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('4637'))
                            //.setStyle('tableHeader')
                            .build());
                        tableBody.push(tableRow);

                        tableRow = [];
                        getWarnings()[config.WARNINGS_HEADINGS.MANUFACTURER_RATINGS].getAsArrayOfStrings().forEach(function (text) {
                            warningsList.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(text)
                                .build());
                        });
                        tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newBulletedListElement(warningsList)
                            .setMargin(listMargins)
                            .build()
                        );
                        tableBody.push(tableRow);
                        tableRow = [];
                    }

                    _legislations.forEach(function(leg){
                        if (getWarnings()[leg.id].getStringTrackerObjects().length > 0) {
                            let warningsList = [];
                            tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(globals.user.getLegislationDescriptionUsingId(parseInt(leg.id)))
                                //.setStyle('tableHeader')
                                .build());
                            tableBody.push(tableRow);

                            tableRow = [];
                            getWarnings()[leg.id].getAsArrayOfStrings().forEach(function (text) {
                                warningsList.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement(text)
                                    .build());
                            });
                            tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newBulletedListElement(warningsList)
                                .setMargin(listMargins)
                                .build()
                            );
                            tableBody.push(tableRow);
                            tableRow = [];
                        }
                    });
                    if (getWarnings()[config.WARNINGS_HEADINGS.INTERNAL_STANDARDS].getStringTrackerObjects().length > 0) {
                        warningsList = [];
                        tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('4321'))
                            //.setStyle('tableHeader')
                            .build());
                        tableBody.push(tableRow);

                        tableRow = [];
                        getWarnings()[config.WARNINGS_HEADINGS.INTERNAL_STANDARDS].getAsArrayOfStrings().forEach(function (text) {
                            warningsList.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(text)
                                .build());
                        });
                        tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newBulletedListElement(warningsList)
                            .setMargin(listMargins)
                            .build()
                        );
                        tableBody.push(tableRow);
                        tableRow = [];
                    }

                    if (tableBody.length === 0) {
                        tableBody.push([]);
                    }

                    var warningsTable = new pdfBuilders.PdfMakeElementBuilder()
                        .newTableElement(tableBody)
                        .setColumnWidthsForTable(['*'])
                        .addNoBordersLayout()
                        .setMargin([5, 0, 0, 0])
                        .build();

                    tableRow = [];
                    tableBody = [];
                    // create warnings table end

                    // Add header text
                    tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement(config.getTranslationText('2797'))
                        .setStyle('tableHeader')
                        .build());
                    tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        .newTextElement(config.getTranslationText('2794'))
                        .setStyle('tableHeader')
                        .setMargin([5, 0, 0, 0])
                        .build());
                    tableBody.push(tableRow);

                    //// Create notes list
                    //ko.utils.arrayForEach(getNotes().getAsArrayOfStrings(removeAdditionalNote), function (text) {
                    //    notesList.push(new pdfBuilders.PdfMakeElementBuilder()
                    //        .newTextElement(text)
                    //        .build());
                    //});

                    function removeAdditionalNote(stringObj) {
                        return stringObj.getId() !== config.NOTE_ID.ADDITIONAL_NOTE && stringObj.getId() !== config.NOTE_ID.APPLICATION && stringObj.getId() !== config.NOTE_ID.APPLICATION_MESSAGE;
                    }
                    function returnSectionNotes(stringTrackerObj, section) {
                        return stringTrackerObj.getState(config.TRACKER_STATE_KEY.SECTION) === section;
                    }
                    // Create warnings list
                    //ko.utils.arrayForEach(getWarnings().getAsArrayOfStrings(), function (text) {
                    //    warningsList.push(new pdfBuilders.PdfMakeElementBuilder()
                    //        .newTextElement(text)
                    //        .build());
                    //});

                    // Add notes and warnings to row
                    tableRow = [];
                    //tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                    //    .newBulletedListElement(notesList)
                    //    .setMargin([0, 2, 0, 0])
                    //    .build()
                    //);
                    //tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                    //    .newBulletedListElement(warningsList)
                    //    .build()
                    //);
                    tableRow.push(notesTable);
                    tableRow.push(warningsTable);
                    tableBody.push(tableRow);

                    var notesWarningsTable = new pdfBuilders.PdfMakeElementBuilder()
                        .newTableElement(tableBody)
                        .setColumnWidthsForTable(['*', '*'])
                        .addHeaderRows()
                        .sethLineWidth(function (i, node) {
                            return 0;
                        })
                        .setvLineWidth(function (i, node) {
                            return i === 1 ? 1 : 0;
                        })
                        //.removeDefaultBorder()
                        .setMargin()
                        //.setMargin([12, 0, 12, 0])
                        .setMargin([12, 0, 12, 2])
                        .build();

                    //if (getActiveOffer().getAdditionalNote() !== '') {
                    if (displayAdditionalNotesSectionOnReport()) {
                        // Add additional notes header
                        tableRow = [];
                        tableBody = [];
                        tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('3073'))
                            .setStyle('payloadHeaderStyle')
                            //.setStyle('tableHeader')
                            .build());
                        tableBody.push(tableRow);

                        tableRow = [];
                        tableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(getAdditionalNotesForReport())
                            .setMargin([0,5])
                            .build()
                        );
                        tableBody.push(tableRow);

                        var additionalNotesTable = new pdfBuilders.PdfMakeElementBuilder()
                            .newTableElement(tableBody)
                            .setColumnWidthsForTable(['*'])
                            .sethLineWidth(function (i, node) {
                                return i === 1 ? 1 : 0;
                            })
                            .setvLineWidth(function (i, node) {
                                return 0;
                            })
                            .setMargin([12, 0, 12, 5])
                            .addHeaderRows(1)
                            .build();

                    }

                    var containerTableBody = [],
                        containerTableRow = [];

                    containerTableRow.push(notesAndWarningsTitleTable);
                    containerTableBody.push(containerTableRow);

                    containerTableRow = [];
                    containerTableRow.push(notesWarningsTable);
                    containerTableBody.push(containerTableRow);

                    //if (getActiveOffer().getAdditionalNote() !== '') {
                    if (displayAdditionalNotesSectionOnReport()) {
                        containerTableRow = [];
                        containerTableRow.push(additionalNotesTable);
                        containerTableBody.push(containerTableRow);
                    }

                    var containerTable;

                    if (addPageBreak) {
                        containerTable = new pdfBuilders.PdfMakeElementBuilder()
                        .newTableElement(containerTableBody)
                        .addPageBreakBeforeElement()
                        .setColumnWidthsForTable(['*'])
                        .addNoBordersLayout()
                        .build();
                    } else {
                        containerTable = new pdfBuilders.PdfMakeElementBuilder()
                        .newTableElement(containerTableBody)
                        .setColumnWidthsForTable(['*'])
                        .addNoBordersLayout()
                        .build();
                    }


                    //bodyBuilderContentArray.push(notesWarningsTable);
                    bodyBuilderContentArray.push(containerTable);
                }

                function createSpecificationReportPages(addPageBreakBeforePage) {
                    createPdfMakeObjects(createArrayOfSpecificationForPdfMake());

                    function createArrayOfSpecificationForPdfMake() {
                        var returnArray = [],
                            specificationObject = getActiveOffer().getSpecification(),
                            headingsArray = specificationObject.getHeadings(),
                            hasFirstHeadingPassed = false,
                            headingIndex = 0,
                            measurementSystemId = getMeasurementSystemToUse().id;

                            headingsArray.forEach(function (heading) {
                            var objectToAdd = {},
                                codeToUse = heading.getHeadingCode(),
                                headingUnit = heading.getUnit();

                            objectToAdd.Description = heading.getDescription();
                            objectToAdd.HeadingIndicator = heading.getHeadingIndicator();
                            objectToAdd.HeadingUnit = headingUnit;
                            objectToAdd.BaseVehicleAttribute = headingUnit !== '' ? addFormattedValue(specificationObject.getBaseVehicleAttributeUsingCode(codeToUse, headingUnit, measurementSystemId), heading.getUnitTranslation(measurementSystemId)) : specificationObject.getBaseVehicleAttributeUsingCode(codeToUse, headingUnit, measurementSystemId);
                            objectToAdd.Competitor1Attribute = getActiveOffer().getSpecification().getSelectedCompetitor1() !== null ? headingUnit !== '' ? addFormattedValue(specificationObject.getCompetitor1AttributeUsingCode(codeToUse, headingUnit, measurementSystemId), heading.getUnitTranslation(measurementSystemId)) : specificationObject.getCompetitor1AttributeUsingCode(codeToUse, headingUnit, measurementSystemId) : '';
                            objectToAdd.Competitor2Attribute = getActiveOffer().getSpecification().getSelectedCompetitor2() !== null ? headingUnit !== '' ? addFormattedValue(specificationObject.getCompetitor2AttributeUsingCode(codeToUse, headingUnit, measurementSystemId), heading.getUnitTranslation(measurementSystemId)) : specificationObject.getCompetitor2AttributeUsingCode(codeToUse, headingUnit, measurementSystemId) : '';

                            if (objectToAdd.Description === '' && objectToAdd.BaseVehicleAttribute === '' && objectToAdd.Competitor1Attribute === '' && objectToAdd.Competitor2Attribute === '') {
                                TScMessenger.writeDebugMessage('OfferManager.js, createPdfDocumentDefinition, generatePdfMake, createBodyBuilderReportContent, createBodyBuilderReportDocumentDefinition, createArrayOfSpecificationForPdfMake, row empty so do not add to array');
                            } else {
                                returnArray.push(objectToAdd);
                            }
                        });

                        function addFormattedValue(value, unit) {
                            if (value === '-') {
                                return value;
                            } else {
                                return value + ' ' + unit;
                            }
                        }

                        return returnArray;
                    }

                    function createPdfMakeObjects(specificationObjectsArray) {
                        var specificationTableBody = [],
                            specificationTableRow = [],
                            specificationContainerTableBody = [],
                            specificationContainerTableRow = [],
                            specificationObject = getActiveOffer().getSpecification();

                        function stripedTableFormatting(i, node) {
                            //return (i > 0) && (i % 2 === 0) ? '#F2F1F1' : null;
                            return (i > 0) && (i % 2 === 0) ? '#F6F6F6' : null;
                        }

                        function tableTitleHorizontalLine(i, node) {
                            //return i === 1 ? 1 : 0;
                            return 1;
                        }

                        function horizontalLineColour(i, node) {
                            return '#FFFFFF';
                        }

                        // Add row with manufacturer logos
                        specificationTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build());
                        specificationTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newImageElement(imageDataByUrl[baseVehicleLogoImgUrl])
                            .setHeight(manufacturerLogoImgHeight)
                            .setWidth(manufacturerLogoImgWidth)
                            .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE)
                            .build());
                        //specificationTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        //    .newImageElement(imageDataByUrl[competitor1LogoImgUrl])
                        //    .setHeight(manufacturerLogoImgHeight)
                        //    .setWidth(manufacturerLogoImgWidth)
                        //    .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE)
                        //    .build());
                        if (competitor1LogoImgUrl !== '') {
                            specificationTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newImageElement(imageDataByUrl[competitor1LogoImgUrl])
                                .setHeight(manufacturerLogoImgHeight)
                                .setWidth(manufacturerLogoImgWidth)
                                .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE)
                                .build());
                        } else {
                            specificationTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement('')
                                .build());
                        }
                        if (competitor2LogoImgUrl !== '') {
                            specificationTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newImageElement(imageDataByUrl[competitor2LogoImgUrl])
                                .setHeight(manufacturerLogoImgHeight)
                                .setWidth(manufacturerLogoImgWidth)
                                .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE)
                                .build());
                        } else {
                            specificationTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement('')
                                .build());
                        }


                        specificationTableBody.push(specificationTableRow);

                        //bodyBuilderContentArray.push(new pdfBuilders.PdfMakeElementBuilder()
                        //    .newTableElement(specificationTableBody)
                        //    .setColumnWidthsForTable(['*', '*', '*', '*'])
                        //    //.addNoBordersLayout()
                        //    .setStyle('specificationTableStyle')
                        //    .setMargin([15, 0, 15, 5])
                        //    .build());

                        specificationContainerTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTableElement(specificationTableBody)
                            .setColumnWidthsForTable(['*', '*', '*', '*'])
                            .addNoBordersLayout()
                            .setStyle('specificationTableStyle')
                            .setMargin([15, 0, 15, 5])
                            .build());

                        specificationContainerTableBody.push(specificationContainerTableRow);
                        specificationContainerTableRow = [];

                        // Add row with vehicle images
                        specificationTableBody = [];
                        specificationTableRow = [];
                        specificationTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build());
                        specificationTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newImageElement(imageDataByUrl[baseVehicleImgUrl])
                            .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE)
                            .build());
                        //specificationTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        //    .newImageElement(imageDataByUrl[competitor1ImgUrl])
                        //    .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE)
                        //    .build());

                        if (competitor1ImgUrl !== '') {
                            specificationTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newImageElement(imageDataByUrl[competitor1ImgUrl])
                                .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE)
                                .build());
                        } else {
                            specificationTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement('')
                                .build());
                        }
                        if (competitor2ImgUrl !== '') {
                            specificationTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newImageElement(imageDataByUrl[competitor2ImgUrl])
                                .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE)
                                .build());
                        } else {
                            specificationTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement('')
                                .build());
                        }


                        specificationTableBody.push(specificationTableRow);

                        //bodyBuilderContentArray.push(new pdfBuilders.PdfMakeElementBuilder()
                        //    .newTableElement(specificationTableBody)
                        //    .setColumnWidthsForTable(['*', '*', '*', '*'])
                        //    //.addNoBordersLayout()
                        //    .setStyle('specificationTableStyle')
                        //    .setMargin([15, 0, 15, 5])
                        //    .build());
                        specificationContainerTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTableElement(specificationTableBody)
                            .setColumnWidthsForTable(['*', '*', '*', '*'])
                            .addNoBordersLayout()
                            .setStyle('specificationTableStyle')
                            .setMargin([15, 0, 15, 0])
                            .build());

                        specificationContainerTableBody.push(specificationContainerTableRow);
                        specificationContainerTableRow = [];

                        specificationTableBody = [];
                        specificationTableRow = [];
                        // Add detail rows
                        specificationObjectsArray.forEach(function (specObj) {
                            specificationTableRow = [];
                            if (specObj.HeadingIndicator === true) {
                                // Check if table needs to be closed
                                if (specificationTableBody.length > 0) {
                                    // Close current table
                                    //bodyBuilderContentArray.push(new pdfBuilders.PdfMakeElementBuilder()
                                    //    .newTableElement(specificationTableBody)
                                    //    .setColumnWidthsForTable(['*', '*', '*', '*'])
                                    //    .setFillColourForTable(stripedTableFormatting)
                                    //    .addHeaderRows(1)
                                    //    .sethLineWidth(tableTitleHorizontalLine)
                                    //    .setvLineWidth(function () { return 0; })
                                    //    .setStyle('specificationTableStyle')
                                    //    .build());
                                    specificationContainerTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                        .newTableElement(specificationTableBody)
                                        .setColumnWidthsForTable(['*', '*', '*', '*'])
                                        .setFillColourForTable(stripedTableFormatting)
                                        .addHeaderRows(1)
                                        .sethLineWidth(tableTitleHorizontalLine)
                                        .sethLineColor(horizontalLineColour)
                                        .setvLineWidth(function () {
                                            return 0;
                                        })
                                        .setStyle('specificationTableStyle')
                                        .build());

                                    specificationContainerTableBody.push(specificationContainerTableRow);
                                    specificationContainerTableRow = [];

                                    // Clear table for restart
                                    specificationTableBody = [];

                                }

                                specificationTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement(specObj.Description)
                                    .setStyle('tableHeader')
                                    .setColSpan(4)
                                    .build());
                                specificationTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement('')
                                    .build());
                                specificationTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement('')
                                    .build());
                                specificationTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement('')
                                    .build());

                                specificationTableBody.push(specificationTableRow);
                            } else {
                                // Add to row

                                // .Description
                                specificationTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement(specObj.Description)
                                    //.setStyle('specificationTableColumnContentStyle')
                                    .build());
                                // .BaseVehicleAttribute
                                specificationTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement(specObj.BaseVehicleAttribute)
                                    .setStyle('specificationTableColumnContentStyle')
                                    .build());
                                // .Competitor1Attribute
                                specificationTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement(specObj.Competitor1Attribute)
                                    .setStyle('specificationTableColumnContentStyle')
                                    .build());
                                // .Competitor2Attribute
                                specificationTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement(specObj.Competitor2Attribute)
                                    .setStyle('specificationTableColumnContentStyle')
                                    .build());

                                // Add to table body
                                specificationTableBody.push(specificationTableRow);
                            }
                        });

                        if (specificationTableBody.length > 0) {
                            //bodyBuilderContentArray.push(new pdfBuilders.PdfMakeElementBuilder()
                            //    .newTableElement(specificationTableBody)
                            //    .setColumnWidthsForTable(['*', '*', '*', '*'])
                            //    .setFillColourForTable(stripedTableFormatting)
                            //    .addHeaderRows(1)
                            //    .sethLineWidth(tableTitleHorizontalLine)
                            //    .setvLineWidth(function () { return 0; })
                            //    .setStyle('specificationTableStyle')
                            //    .build());
                            specificationContainerTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTableElement(specificationTableBody)
                                .setColumnWidthsForTable(['*', '*', '*', '*'])
                                .setFillColourForTable(stripedTableFormatting)
                                .addHeaderRows(1)
                                .sethLineWidth(tableTitleHorizontalLine)
                                .sethLineColor(horizontalLineColour)
                                .setvLineWidth(function () {
                                    return 0;
                                })
                                .setStyle('specificationTableStyle')
                                .build());

                            specificationContainerTableBody.push(specificationContainerTableRow);
                            specificationContainerTableRow = [];
                        }
                        specificationTableBody = [];

                        // check if config report is available then add pagebreak
                        //if (configurationReportOption !== null && configurationReportOption.getIsChecked()) {
                        if (addPageBreakBeforePage === true) {
                            bodyBuilderContentArray.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTableElement(specificationContainerTableBody)
                                .addHeaderRows(1)
                                .setColumnWidthsForTable(['*'])
                                .addNoBordersLayout()
                                .setMargin([0, 0, 0, 5])
                                .addPageBreakBeforeElement()
                                .build());
                        } else {
                            bodyBuilderContentArray.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTableElement(specificationContainerTableBody)
                                .addHeaderRows(1)
                                .setColumnWidthsForTable(['*'])
                                .addNoBordersLayout()
                                .setMargin([0, 0, 0, 5])
                                .build());
                        }

                    }
                }

                function createCostingReportPages(addPageBreakBeforePage) {
                    createPdfMakeObjects();

                    function createPdfMakeObjects() {
                        var costingTableBody = [],
                            costingTableRow = [],
                            costingReportTableBody = [],
                            costingReportTableRow = [],
                            costingChartTableBody = [],
                            costingChartTableRow = [],
                            costingContainerTableBody = [],
                            costingContainerTableRow = [],
                            // costingTableColumnWidths = ['*', 150, 75, 75];
                            // costingTableColumnWidths = ['*', 200, 75, 75];
                            costingTableColumnWidths = ['*', 200, 70, 70];


                        function stripedTableFormatting(i, node) {
                            //return (i > 0) && (i % 2 === 0) ? '#F2F1F1' : null;
                            return (i > 0) && (i % 2 === 0) ? '#F6F6F6' : null;
                        }

                        function tableTitleHorizontalLine(i, node) {
                            //return i === 1 ? 1 : 0;
                            return 1;
                        }

                        function horizontalLineColour(i, node) {
                            return '#FFFFFF';
                        }
                        //#region Adding Costing Report
                        //#region Adding Favourites section
                        var costingSectionTableRow = [],
                            costingSectionTableBody = [];
                        // Favourites
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2646'))
                            .setColSpan(4)
                            .setStyle('tableHeader')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build());
                        costingSectionTableBody.push(costingSectionTableRow);
                        // Utilisation
                        costingSectionTableRow = [];
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2647'))
                            .setColSpan(2)
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(getUIFormattedMonthlyDistanceValue(getActiveOffer().getCosting().getMonthlyDistance()))
                            .setStyle('payloadTableNumberStyle')
                            .setColSpan(2)
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build());

                        costingSectionTableBody.push(costingSectionTableRow);
                        //// Finance Period
                        //costingSectionTableRow = [];
                        //costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        //    .newTextElement(config.getTranslationText('2648'))
                        //    .setColSpan(2)
                        //    .build());
                        //costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        //    .newTextElement('')
                        //    .build());
                        //costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        //    .newTextElement(getUIFormattedMonthsValue(getActiveOffer().getCosting().getRepaymentTerm()))
                        //    .setStyle('payloadTableNumberStyle')
                        //    .setColSpan(2)
                        //    .build());
                        //costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        //    .newTextElement('')
                        //    .build());
                        //costingSectionTableBody.push(costingSectionTableRow);
                        //// Interest Rate
                        //costingSectionTableRow = [];
                        //costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        //    .newTextElement(config.getTranslationText('2649'))
                        //    .setColSpan(2)
                        //    .build());
                        //costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        //    .newTextElement('')
                        //    .build());
                        //costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        //    .newTextElement(getUIFormattedPercentageDoublePlaceValue(getActiveOffer().getCosting().getInterestRate()))
                        //    .setStyle('payloadTableNumberStyle')
                        //    .setColSpan(2)
                        //    .build());
                        //costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        //    .newTextElement('')
                        //    .build());
                        //costingSectionTableBody.push(costingSectionTableRow);
                        //// Residual Value
                        //costingSectionTableRow = [];
                        //costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        //    .newTextElement(config.getTranslationText('2650'))
                        //    .setColSpan(2)
                        //    .build());
                        //costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        //    .newTextElement('')
                        //    .build());
                        //costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        //    .newTextElement(getUIFormattedPercentageSinglePlaceValue(getActiveOffer().getCosting().getResidualRate()))
                        //    .setStyle('payloadTableNumberStyle')
                        //    .setColSpan(2)
                        //    .build());
                        //costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        //    .newTextElement('')
                        //    .build());
                        //costingSectionTableBody.push(costingSectionTableRow);
                        // // Fuel Price
                        // costingSectionTableRow = [];
                        // costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        //     .newTextElement(config.getTranslationText('2651'))
                        //     .setColSpan(2)
                        //     .build());
                        // costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        //     .newTextElement('')
                        //     .build());
                        // costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        //     //.newTextElement(globals.returnFloat(getActiveOffer().getCosting().getFuelCost()) * getActiveOffer().getMeasurementSystem().volumeConversion)
                        //     .newTextElement(getUIFormattedCurrencyPerVolumeValue(getActiveOffer().getCosting().getFuelCost()))
                        //     .setStyle('payloadTableNumberStyle')
                        //     .setColSpan(2)
                        //     .build());
                        // costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                        //     .newTextElement('')
                        //     .build());
                        // costingSectionTableBody.push(costingSectionTableRow);
                        // Number of Trips
                        costingSectionTableRow = [];
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2651'))
                            .setColSpan(2)
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(getUIFormattedValuePerMonthValue(getActiveOffer().getCosting().getMonthlyTrips()))
                            .setStyle('payloadTableNumberStyle')
                            .setColSpan(2)
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build());
                        costingSectionTableBody.push(costingSectionTableRow);

                        costingReportTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTableElement(costingSectionTableBody)
                            .setColumnWidthsForTable(costingTableColumnWidths)
                            .setFillColourForTable(stripedTableFormatting)
                            .addHeaderRows(1)
                            .sethLineWidth(tableTitleHorizontalLine)
                            .sethLineColor(horizontalLineColour)
                            .setvLineWidth(function () {
                                return 0;
                            })
                            .setStyle('specificationTableStyle')
                            .build());
                        costingReportTableBody.push(costingReportTableRow);
                        //#endregion Adding Favourites section

                        //#region Adding Fixed Costs section
                        costingReportTableRow = [];
                        costingSectionTableBody = [];
                        // Fixed Costs
                        costingSectionTableRow = [];
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2652'))
                            .setColSpan(2)
                            .setStyle('tableHeader')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('4199', [currencySymbolMajor.value]))
                            .setStyle('tableHeaderRight')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(currencySymbolMajor.value + '/' + getMeasurementSystemToUse().unitDistance)
                            .setStyle('tableHeaderRight')
                            .build());
                        costingSectionTableBody.push(costingSectionTableRow);
                        // Finance
                        costingSectionTableRow = [];
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2653'))
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            //.newTextElement(getUIFormattedCurrencyTotalPrice(getActiveOffer().getCosting().getVehiclePrice()))
                            .newTextElement(getUIFormattedCurrencyTotalPrice(getActiveOffer().getCosting().getFinanceTotalPrice()))
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(financeCostPerMonthFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(financeCostPerKMFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableBody.push(costingSectionTableRow);

                        if (getActiveOffer().getCosting().getFinanceTotalPriceOverride() === true) {
                            // Finance Period
                            costingSectionTableRow = [];
                            costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('2648'))
                                .addItalics()
                                .setStyle('costingTableChildRowStyle')
                                .build());
                            costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(getUIFormattedMonthsValue(getActiveOffer().getCosting().getRepaymentTerm()))
                                .setStyle('payloadTableNumberStyle')
                                .build());
                            costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement('')
                                .setColSpan(2)
                                .build());
                            costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement('')
                                .build());
                            costingSectionTableBody.push(costingSectionTableRow);
                            // Interest Rate
                            costingSectionTableRow = [];
                            costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('2649'))
                                .addItalics()
                                .setStyle('costingTableChildRowStyle')
                                .build());
                            costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(getUIFormattedPercentageDoublePlaceValue(getActiveOffer().getCosting().getInterestRate()))
                                .setStyle('payloadTableNumberStyle')
                                .build());
                            costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement('')
                                .setColSpan(2)
                                .build());
                            costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement('')
                                .build());
                            costingSectionTableBody.push(costingSectionTableRow);
                            // Residual Value
                            costingSectionTableRow = [];
                            costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('2650'))
                                .addItalics()
                                .setStyle('costingTableChildRowStyle')
                                .build());
                            costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(getUIFormattedPercentageSinglePlaceValue(getActiveOffer().getCosting().getResidualRate()))
                                .setStyle('payloadTableNumberStyle')
                                .build());
                            costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement('')
                                .setColSpan(2)
                                .build());
                            costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement('')
                                .build());
                            costingSectionTableBody.push(costingSectionTableRow);
                        }

                        // Crew
                        costingSectionTableRow = [];
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2654'))
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(getUIFormattedCurrencyPerMonthValue(getActiveOffer().getCosting().getCrewCostPerMonth()))
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(crewCostPerMonthFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(crewCostPerKMFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableBody.push(costingSectionTableRow);
                        // Insurance
                        costingSectionTableRow = [];
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2655'))
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(getUIFormattedPercentageOfTotalPrice(getActiveOffer().getCosting().getInsuranceRate()))
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(insuranceCostPerMonthFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(insuranceCostPerKMFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableBody.push(costingSectionTableRow);
                        // Licence Fees
                        costingSectionTableRow = [];
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2656'))
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(getUIFormattedCurrencyPerYearValue(getActiveOffer().getCosting().getLicenceFees()))
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(licenceFeesCostPerMonthFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(licenceFeesCostPerKMFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableBody.push(costingSectionTableRow);
                        // Overheads
                        costingSectionTableRow = [];
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2657'))
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(getUIFormattedPercentageOfFixedCostValue(getActiveOffer().getCosting().getOverheadRate()))
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(overheadsCostPerMonthFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(overheadsCostPerKMFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableBody.push(costingSectionTableRow);
                        // Total
                        costingSectionTableRow = [];
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(fixedCostsTotalCostsPerMonthFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .addBold()
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(fixedCostsTotalCostsPerKMFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .addBold()
                            .build());
                        costingSectionTableBody.push(costingSectionTableRow);

                        costingReportTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTableElement(costingSectionTableBody)
                            .setColumnWidthsForTable(costingTableColumnWidths)
                            .setFillColourForTable(stripedTableFormatting)
                            .addHeaderRows(1)
                            .sethLineWidth(tableTitleHorizontalLine)
                            .sethLineColor(horizontalLineColour)
                            .setvLineWidth(function () {
                                return 0;
                            })
                            .setStyle('specificationTableStyle')
                            .build());

                        costingReportTableBody.push(costingReportTableRow);
                        //#endregion Adding Fixed Costs section

                        //#region Adding Variable Costs section
                        costingReportTableRow = [];
                        costingSectionTableBody = [];
                        // Variable Costs
                        costingSectionTableRow = [];
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2658'))
                            .setStyle('tableHeader')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('4200', [offerManager.currencySymbolMajor.value]))
                            .setStyle('tableHeaderRight')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(offerManager.currencySymbolMajor.value + '/' + offerManager.getMeasurementSystemToUse().unitDistance)
                            .setStyle('tableHeaderRight')
                            .build());
                        costingSectionTableBody.push(costingSectionTableRow);
                        // Fuel & AdBlue
                        costingSectionTableRow = [];
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2659'))
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            // .newTextElement(getUIFormattedVolumePer100KMsValue(getActiveOffer().getCosting().getFuelConsumption()))
                            // .newTextElement(getUIFormattedFuelConsumptionValue(getActiveOffer().getCosting().getFuelConsumption()))
                            .newTextElement(getUIFormattedCurrencyPerMonthValue(getActiveOffer().getCosting().getFuelAndAdblueCostPerMonth()))
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(fuelCostPerMonthFomattedComp.value)
                            // .newTextElement(getUIFormattedCurrencyPerMonthValueNoUnit(getActiveOffer().getCosting().getFuelAndAdblueCostPerMonth()))
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(fuelCostPerKMFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableBody.push(costingSectionTableRow);

                        if(getActiveOffer().getCosting().getFuelAndAdblueCostPerMonthOverride() === false) {
                            if(getActiveOffer().getCosting().getVehicleFuelAndAdblueTotalCostPerMonthOverride() === false) {
                                // Vehicle Fuel
                                if(globals.getMonthlyFuelCostUsingFuelConsumptionPerDistance(getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelCostPerVolume(), getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelConsumption(), getActiveOffer().getCosting().getMonthlyDistance())) {
                                    costingSectionTableRow = [];
                                    costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                        .newTextElement(config.getTranslationText('4606'))
                                        .addItalics()
                                        .setStyle('costingTableChildRowStyle')
                                        .build());
                                    costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                        .newTextElement(config.getTranslationText('4601', [
                                            getUIFormattedFuelConsumptionValueNoConversion(globals.getConvertedConsumptionValue(getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelConsumption(), getActiveOffer().getMeasurementSystem(), globals.user.getSpecifyFuelConsumptionAs())), 
                                            globals.getFuelPricePerVolumeValue(
                                                globals.getFuelPricePerVolumeConvertedUsingMeasurementSystem(
                                                    getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelCostPerVolume(), 
                                                    getActiveOffer().getMeasurementSystem()),
                                                getActiveOffer().getMeasurementSystem(),
                                                currencySymbolMajor.value,
                                                getDecimalsMediumPricedItems())
                                        ]))
                                        .setStyle('payloadTableNumberStyle')
                                        .build());
                                    costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                        .newTextElement('')
                                        .setColSpan(2)
                                        .build());
                                    costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                        .newTextElement('')
                                        .build());
                                    costingSectionTableBody.push(costingSectionTableRow);
                                }
                                // Vehicle AdBlue
                                if(globals.getVehicleAdBlueCostPerMonthValue(getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelConsumption(), getActiveOffer().getCosting().getVehicleFuelAndAdblueAdblueConsumption(), getActiveOffer().getCosting().getVehicleFuelAndAdblueAdblueCostPerVolume(), getActiveOffer().getCosting().getMonthlyDistance()) > 0) {
                                    costingSectionTableRow = [];
                                    costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                        .newTextElement(config.getTranslationText('4607'))
                                        .addItalics()
                                        .setStyle('costingTableChildRowStyle')
                                        .build());
                                    costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                        .newTextElement(config.getTranslationText('4602', [
                                            getUIFormattedPercentageOfFuelValue(getActiveOffer().getCosting().getVehicleFuelAndAdblueAdblueConsumption()),
                                            globals.getFuelPricePerVolumeValue(
                                                globals.getFuelPricePerVolumeConvertedUsingMeasurementSystem(
                                                    getActiveOffer().getCosting().getVehicleFuelAndAdblueAdblueCostPerVolume(), 
                                                    getActiveOffer().getMeasurementSystem()),
                                                    getActiveOffer().getMeasurementSystem(),
                                                    currencySymbolMajor.value, 
                                                    getDecimalsMediumPricedItems())
                                        ]))
                                        .setStyle('payloadTableNumberStyle')
                                        .build());
                                    costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                        .newTextElement('')
                                        .setColSpan(2)
                                        .build());
                                    costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                        .newTextElement('')
                                        .build());
                                    costingSectionTableBody.push(costingSectionTableRow);
                                }

                                // Vehicle Equipment
                                if(globals.getMonthlyFuelCostUsingFuelConsumptionPerHour(getActiveOffer().getCosting().getVehicleFuelAndAdblueEquipmentFuelConsumption(), getActiveOffer().getCosting().getVehicleFuelAndAdblueEquipmentFuelCostPerVolume(), getActiveOffer().getCosting().getVehicleFuelAndAdblueEquipmentUtilisation()) > 0) {
                                    costingSectionTableRow = [];
                                    costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                        .newTextElement(config.getTranslationText('4608'))
                                        .addItalics()
                                        .setStyle('costingTableChildRowStyle')
                                        .build());
                                    costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                        .newTextElement(config.getTranslationText('4603', [
                                            getUIFormattedVolumePerHourValue(globals.getConvertedConsumptionValue(getActiveOffer().getCosting().getVehicleFuelAndAdblueEquipmentFuelConsumption(), getActiveOffer().getMeasurementSystem(), globals.user.getSpecifyFuelConsumptionAs())),
                                            // getUIFormattedMediumCurrencyValue(globals.getFuelPricePerVolumeConvertedUsingMeasurementSystem(getActiveOffer().getCosting().getVehicleFuelAndAdblueEquipmentFuelCostPerVolume(), getActiveOffer().getMeasurementSystem())),
                                            globals.getFuelPricePerVolumeValue(
                                                globals.getFuelPricePerVolumeConvertedUsingMeasurementSystem(
                                                    getActiveOffer().getCosting().getVehicleFuelAndAdblueEquipmentFuelCostPerVolume(), 
                                                    getActiveOffer().getMeasurementSystem()),
                                                    getActiveOffer().getMeasurementSystem(),
                                                    currencySymbolMajor.value, 
                                                    getDecimalsMediumPricedItems()),
                                            getUIFormattedHoursPerMonthValue(getActiveOffer().getCosting().getVehicleFuelAndAdblueEquipmentUtilisation())
                                        ]))
                                        .setStyle('payloadTableNumberStyle')
                                        .build());
                                    costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                        .newTextElement('')
                                        .setColSpan(2)
                                        .build());
                                    costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                        .newTextElement('')
                                        .build());
                                    costingSectionTableBody.push(costingSectionTableRow);
                                }
                            }
                            
                            // Trailer 1 Equipment
                            // if (getActiveOffer().getTrailer1() !== null && globals.getMonthlyFuelCostUsingFuelConsumptionPerHour(getActiveOffer().getTrailer1().getFuelAndAdblueEquipmentFuelCostPerVolume(), getActiveOffer().getTrailer1().getFuelAndAdblueEquipmentFuelConsumption(), getActiveOffer().getTrailer1().getFuelAndAdblueEquipmentUtilisation()) > 0) {
                            if (getActiveOffer().getTrailer1() !== null && displayTrailerFuelAndAdblueOnReport(getActiveOffer().getTrailer1().getFuelAndAdblueEquipmentFuelCostPerVolume(), getActiveOffer().getTrailer1().getFuelAndAdblueEquipmentFuelConsumption(), getActiveOffer().getTrailer1().getFuelAndAdblueEquipmentUtilisation(), getActiveOffer().getTrailer1().getFuelAndAdblueTotalCostPerMonth(), getActiveOffer().getTrailer1().getFuelAndAdblueTotalCostPerMonthOverride())) {
                                costingSectionTableRow = [];
                                costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement(config.getTranslationText('4609'))
                                    .addItalics()
                                    .setStyle('costingTableChildRowStyle')
                                    .build());
                                costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement(config.getTranslationText('4604', [
                                        getUIFormattedVolumePerHourValue(globals.getConvertedConsumptionValue(getActiveOffer().getTrailer1().getFuelAndAdblueEquipmentFuelConsumption(), getActiveOffer().getMeasurementSystem(), globals.user.getSpecifyFuelConsumptionAs())),
                                        //getUIFormattedMediumCurrencyValue(globals.getFuelPricePerVolumeConvertedUsingMeasurementSystem(getActiveOffer().getTrailer1().getFuelAndAdblueEquipmentFuelCostPerVolume(), getActiveOffer().getMeasurementSystem())),
                                        globals.getFuelPricePerVolumeValue(
                                            globals.getFuelPricePerVolumeConvertedUsingMeasurementSystem(
                                                getActiveOffer().getTrailer1().getFuelAndAdblueEquipmentFuelCostPerVolume(), 
                                                getActiveOffer().getMeasurementSystem()),
                                                getActiveOffer().getMeasurementSystem(),
                                                currencySymbolMajor.value, 
                                                getDecimalsMediumPricedItems()),
                                        getUIFormattedHoursPerMonthValue(getActiveOffer().getTrailer1().getFuelAndAdblueEquipmentUtilisation())
                                    ]))
                                    .setStyle('payloadTableNumberStyle')
                                    .build());
                                costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement('')
                                    .setColSpan(2)
                                    .build());
                                costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement('')
                                    .build());
                                costingSectionTableBody.push(costingSectionTableRow);
                            }
                            
                            // Trailer 2 Equipment
                            // if (getActiveOffer().getTrailer2() !== null && globals.getMonthlyFuelCostUsingFuelConsumptionPerHour(getActiveOffer().getTrailer2().getFuelAndAdblueEquipmentFuelCostPerVolume(), getActiveOffer().getTrailer2().getFuelAndAdblueEquipmentFuelConsumption(), getActiveOffer().getTrailer2().getFuelAndAdblueEquipmentUtilisation()) > 0) {
                            if (getActiveOffer().getTrailer2() !== null && displayTrailerFuelAndAdblueOnReport(getActiveOffer().getTrailer2().getFuelAndAdblueEquipmentFuelCostPerVolume(), getActiveOffer().getTrailer2().getFuelAndAdblueEquipmentFuelConsumption(), getActiveOffer().getTrailer2().getFuelAndAdblueEquipmentUtilisation(), getActiveOffer().getTrailer2().getFuelAndAdblueTotalCostPerMonth(), getActiveOffer().getTrailer2().getFuelAndAdblueTotalCostPerMonthOverride())) {
                                costingSectionTableRow = [];
                                costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement(config.getTranslationText('4610'))
                                    .addItalics()
                                    .setStyle('costingTableChildRowStyle')
                                    .build());
                                costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement(config.getTranslationText('4605', [
                                        getUIFormattedVolumePerHourValue(globals.getConvertedConsumptionValue(getActiveOffer().getTrailer2().getFuelAndAdblueEquipmentFuelConsumption(), getActiveOffer().getMeasurementSystem(), globals.user.getSpecifyFuelConsumptionAs())),
                                        // getUIFormattedMediumCurrencyValue(globals.getFuelPricePerVolumeConvertedUsingMeasurementSystem(getActiveOffer().getTrailer2().getFuelAndAdblueEquipmentFuelCostPerVolume(), getActiveOffer().getMeasurementSystem())),
                                        globals.getFuelPricePerVolumeValue(
                                            globals.getFuelPricePerVolumeConvertedUsingMeasurementSystem(
                                                getActiveOffer().getTrailer2().getFuelAndAdblueEquipmentFuelCostPerVolume(), 
                                                getActiveOffer().getMeasurementSystem()),
                                                getActiveOffer().getMeasurementSystem(),
                                                currencySymbolMajor.value, 
                                                getDecimalsMediumPricedItems()),
                                        getUIFormattedHoursPerMonthValue(getActiveOffer().getTrailer2().getFuelAndAdblueEquipmentUtilisation())
                                    ]))
                                    .setStyle('payloadTableNumberStyle')
                                    .build());
                                costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement('')
                                    .setColSpan(2)
                                    .build());
                                costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement('')
                                    .build());
                                costingSectionTableBody.push(costingSectionTableRow);
                            }
                        }
                        
                        // Tyres
                        costingSectionTableRow = [];
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2660'))
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(getUIFormattedMinorCurrencyPerDistanceValue(getActiveOffer().getCosting().getTyreRate()))
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(tyreCostPerMonthFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(tyreCostPerKMFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableBody.push(costingSectionTableRow);
                        // Maintenance
                        costingSectionTableRow = [];
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2661'))
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(getUIFormattedMinorCurrencyPerDistanceValue(getActiveOffer().getCosting().getMaintenanceRate()))
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(maintenanceCostPerMonthFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(maintenanceCostPerKMFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableBody.push(costingSectionTableRow);
                        // Toll Fees
                        costingSectionTableRow = [];
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2662'))
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(getUIFormattedCurrencyPerMonthValue(getActiveOffer().getCosting().getTollFees()))
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(tollFeesCostPerMonthFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(tollFeesCostPerKMFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .build());
                        costingSectionTableBody.push(costingSectionTableRow);
                        // Totals
                        costingSectionTableRow = [];
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(variableCostsTotalCostPerMonthFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .addBold()
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(variableCostsTotalCostPerKMFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .addBold()
                            .build());
                        costingSectionTableBody.push(costingSectionTableRow);

                        costingReportTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTableElement(costingSectionTableBody)
                            .setColumnWidthsForTable(costingTableColumnWidths)
                            .setFillColourForTable(stripedTableFormatting)
                            .addHeaderRows(1)
                            .sethLineWidth(tableTitleHorizontalLine)
                            .sethLineColor(horizontalLineColour)
                            .setvLineWidth(function () {
                                return 0;
                            })
                            .setStyle('specificationTableStyle')
                            .build());
                        costingReportTableBody.push(costingReportTableRow);

                        // Totals Section
                        costingReportTableRow = [];
                        costingSectionTableBody = [];
                        // Total Costs
                        costingSectionTableRow = [];
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2663'))
                            .addBold()
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(totalCostsPerMonthFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .addBold()
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(totalCostsPerKMFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .addBold()
                            .build());
                        costingSectionTableBody.push(costingSectionTableRow);
                        // Total Revenue
                        costingSectionTableRow = [];
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2664'))
                            .addBold()
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(getUIFormattedCurrencyPerDistanceValue(getActiveOffer().getCosting().getTotalRevenue()))
                            .setStyle('payloadTableNumberStyle')
                            .addBold()
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(totalRevenuePerMonthFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .addBold()
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(totalRevenuePerKMFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .addBold()
                            .build());
                        costingSectionTableBody.push(costingSectionTableRow);
                        // Profit
                        costingSectionTableRow = [];
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2665'))
                            .addBold()
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(totalProfitPerMonthFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .addBold()
                            .build());
                        costingSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(totalProfitPerKMFormattedComp.value)
                            .setStyle('payloadTableNumberStyle')
                            .addBold()
                            .build());
                        costingSectionTableBody.push(costingSectionTableRow);

                        costingReportTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTableElement(costingSectionTableBody)
                            .setColumnWidthsForTable(costingTableColumnWidths)
                            .setFillColourForTable(stripedTableFormatting)
                            .addHeaderRows(1)
                            .sethLineWidth(tableTitleHorizontalLine)
                            .sethLineColor(horizontalLineColour)
                            .setvLineWidth(function () {
                                return 0;
                            })
                            .setStyle('specificationTableStyle')
                            .build());

                        costingReportTableBody.push(costingReportTableRow);

                        costingContainerTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTableElement(costingReportTableBody)
                            .setColumnWidthsForTable(['*'])
                            .addNoBordersLayout()
                            .build());
                        //#endregion Adding Variable Costs section
                        //#endregion Adding Costing Report
                        if (costingChartDrawing !== null) {
                            costingChartTableBody = [];
                            costingChartTableRow = [];

                            costingChartTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(globals.user.getActiveMeasurementSystem().id === config.MEASUREMENT_SYSTEM.METRIC ? config.getTranslationText('2642') : config.getTranslationText('2643'))
                                //.setStyle('costingChartHeaderStyle')
                                .setStyle('tableHeader')
                                .setMargin([0, 5, 0, 0])
                                .build());
                            costingChartTableBody.push(costingChartTableRow)
                            costingChartTableRow = [];

                            costingChartTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(totalCostsPerKMFormattedComp.value)
                                .setStyle('costingChartTextStyle')
                                .build());
                            costingChartTableBody.push(costingChartTableRow)
                            costingChartTableRow = [];

                            costingChartTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('2644'))
                                //.setStyle('costingChartHeaderStyle')
                                .setStyle('tableHeader')
                                .setMargin([0, 5, 0, 0])
                                .build());
                            costingChartTableBody.push(costingChartTableRow)
                            costingChartTableRow = [];

                            costingChartTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(totalProfitPerMonthFormattedComp.value)
                                .setStyle('costingChartTextStyle')
                                .build());
                            costingChartTableBody.push(costingChartTableRow)
                            costingChartTableRow = [];

                            costingChartTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('2645'))
                                //.setStyle('costingChartHeaderStyle')
                                .setStyle('tableHeader')
                                .setMargin([0, 5, 0, 0])
                                .build());
                            costingChartTableBody.push(costingChartTableRow)
                            costingChartTableRow = [];

                            costingChartTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newImageElement(costingChartDrawing)
                                //.setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE)
                                .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT)
                                .setHeight(300)
                                .setWidth(300)
                                .setMargin([-30, 10, 0, 10])
                                .build());

                            costingChartTableBody.push(costingChartTableRow);

                            costingContainerTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTableElement(costingChartTableBody)
                                .setColumnWidthsForTable(['*'])
                                //.addNoBordersLayout()
                                .sethLineWidth(function (i, node) {
                                    return (i === 2) || (i === 4) ? 1 : 0;
                                })
                                .setvLineWidth(function (i, node) {
                                    return 0;
                                })
                                .sethLineColor(function () {
                                    //return config.pdfDocumentColour;
                                    return '#d7dee6';
                                })
                                .build());
                        }

                        costingContainerTableBody.push(costingContainerTableRow);

                        var columWidths = ['*'];
                        if (costingContainerTableBody[0].length > 1) {
                            columWidths = ['*', 300];
                        }

                        // check if config report is available then add pagebreak
                        //if (configurationReportOption !== null && configurationReportOption.getIsChecked()) {
                        if (addPageBreakBeforePage === true) {
                            //// setup for portrait
                            //bodyBuilderContentArray.push(new pdfBuilders.PdfMakeElementBuilder()
                            //    .newTableElement(costingContainerTableBody)
                            //    .addHeaderRows(1)
                            //    .setColumnWidthsForTable(['*'])
                            //    .addNoBordersLayout()
                            //    .setMargin([0, 0, 0, 5])
                            //    .addPageBreakBeforeElement()
                            //    .setPageOrientationToPortrait()
                            //    .build());

                            // setup for landscape
                            bodyBuilderContentArray.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTableElement(costingContainerTableBody)
                                .setColumnWidthsForTable(columWidths)
                                .addNoBordersLayout()
                                .setMargin([0, 0, 0, 5])
                                .addPageBreakBeforeElement()
                                .build());

                        } else {
                            //// setup for portrait
                            //bodyBuilderContentArray.push(new pdfBuilders.PdfMakeElementBuilder()
                            //    .newTableElement(costingContainerTableBody)
                            //    .addHeaderRows(1)
                            //    .setColumnWidthsForTable(['*'])
                            //    .addNoBordersLayout()
                            //    .setMargin([0, 0, 0, 5])
                            //    .build());

                            // setup for landscape
                            bodyBuilderContentArray.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTableElement(costingContainerTableBody)
                                .setColumnWidthsForTable(columWidths)
                                .addNoBordersLayout()
                                .setMargin([0, 0, 0, 5])
                                .build());
                        }

                    }
                }

                function createPerformanceReportPages(addPageBreakBeforePage) {
                    createPdfMakeObjects();

                    function createPdfMakeObjects() {
                        var performanceTableBody = [],
                            performanceTableRow = [],
                            performanceReportTableBody = [],
                            performanceReportTableRow = [],
                            performanceChartTableBody = [],
                            performanceChartTableRow = [],
                            performanceContainerTableBody = [],
                            performanceContainerTableRow = [],
                            performanceTableColumnWidths = ['*', 100, 75, 75],
                            vehicleAndTrailerColumnWidths = [100, 200],
                            vehicleAndTrailerColumnWidth = 125;


                        function stripedTableFormatting(i, node) {
                            //return (i > 1) && (i % 2 === 1) ? '#F2F1F1' : null;
                            return (i > 0) && (i % 2 === 0) ? '#F6F6F6' : null;
                        }

                        function tableTitleHorizontalLine(i, node) {
                            return 1;
                        }

                        function horizontalLineColour(i, node) {
                            return '#FFFFFF';
                        }
                        //#region Adding Vehicle table
                        var performanceSectionTableRow = [],
                            performanceSectionTableBody = [];
                        // Vehicle
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('4213'))
                            .setStyle('payloadHeaderStyle')
                            .build());
                        performanceSectionTableBody.push(performanceSectionTableRow);
                        performanceReportTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTableElement(performanceSectionTableBody)
                            .setColumnWidthsForTable(['*'])
                            .addHeaderRows()
                            .sethLineWidth(function (i, node) {
                                return (i === 1) ? 1 : 0;
                            })
                            .setvLineWidth(function (i, node) {
                                return 0;
                            })
                            .setStyle('payloadTableStyle')
                            .setMargin([10, 10, 10, 0])
                            .build());
                        performanceReportTableBody.push(performanceReportTableRow);

                        // Subheader
                        //performanceReportTableBody = [];
                        performanceReportTableRow = [];
                        performanceSectionTableRow = [];
                        performanceSectionTableBody = [];

                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2667'))
                            .setStyle('tableHeader')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2668'))
                            .setStyle('tableHeader')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2669'))
                            .setStyle('tableHeader')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2670'))
                            .setStyle('tableHeader')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2671'))
                            .setStyle('tableHeader')
                            .build());
                        performanceSectionTableBody.push(performanceSectionTableRow);
                        // Values
                        performanceSectionTableRow = [];
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(getUIFormattedMassValue(getActiveOffer().getPerformance().getVehicleTotalUnladen()))
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(getUIFormattedAreaValue(getActiveOffer().getPerformance().getFrontalArea()))
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(getCustomPrecisionRoundedValue(getActiveOffer().getPerformance().getCoefficientOfDrag(), 2))
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(getCustomPrecisionRoundedValue(getActiveOffer().getPerformance().getDriveAxleRatio(), 3))
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(getUIFormattedSpeedValue(getActiveOffer().getPerformance().getSpeedMaxLoaded()))
                            .build());
                        performanceSectionTableBody.push(performanceSectionTableRow);

                        performanceReportTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTableElement(performanceSectionTableBody)
                            .setColumnWidthsForTable([vehicleAndTrailerColumnWidth, vehicleAndTrailerColumnWidth, vehicleAndTrailerColumnWidth, vehicleAndTrailerColumnWidth, vehicleAndTrailerColumnWidth])
                            .setFillColourForTable(stripedTableFormatting)
                            .addHeaderRows()
                            .sethLineWidth(tableTitleHorizontalLine)
                            .sethLineColor(horizontalLineColour)
                            //.setvLineWidth(function () { return 0; })
                            .setvLineWidth(function (i, node) {
                                return i === 0 || i === node.table.body[0].length ? 0 : 1;
                            })
                            //.setStyle('specificationTableStyle')
                            .setStyle('payloadTableStyle')
                            .build());
                        performanceReportTableBody.push(performanceReportTableRow);
                        //#endregion Adding Vehicle table

                        //#region Adding Trailer section
                        // Show only if trailer is attached
                        if (getActiveOffer().getTrailer1() !== null || getActiveOffer().getTrailer2() !== null || getActiveOffer().getPerformance().getTrailerTotalUnladenOverride() === true || getActiveOffer().getPerformance().getTrailer2TotalUnladenOverride() === true) {
                            performanceReportTableRow = [];
                            performanceSectionTableRow = [];
                            performanceSectionTableBody = [];
                            // Trailer
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('673'))
                                .setStyle('payloadHeaderStyle')
                                .build());
                            performanceSectionTableBody.push(performanceSectionTableRow);
                            performanceReportTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTableElement(performanceSectionTableBody)
                                .setColumnWidthsForTable(['*'])
                                .addHeaderRows()
                                .sethLineWidth(function (i, node) {
                                    return (i === 1) ? 1 : 0;
                                })
                                .setvLineWidth(function (i, node) {
                                    return 0;
                                })
                                .setStyle('payloadTableStyle')
                                .setMargin([10, 10, 10, 0])
                                .build());
                            performanceReportTableBody.push(performanceReportTableRow);
                            // Subheader
                            performanceReportTableRow = [];
                            performanceSectionTableRow = [];
                            performanceSectionTableBody = [];

                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('705'))
                                .setStyle('tableHeader')
                                .build());
                            performanceSectionTableBody.push(performanceSectionTableRow);
                            // Values
                            performanceSectionTableRow = [];
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(getUIFormattedMassValue(getActiveOffer().getPerformance().getTrailerTotalUnladen() + getActiveOffer().getPerformance().getTrailer2TotalUnladen()))
                                .build());
                            performanceSectionTableBody.push(performanceSectionTableRow);

                            performanceReportTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTableElement(performanceSectionTableBody)
                                .setColumnWidthsForTable([vehicleAndTrailerColumnWidth])
                                .setFillColourForTable(stripedTableFormatting)
                                .addHeaderRows()
                                .sethLineWidth(tableTitleHorizontalLine)
                                .sethLineColor(horizontalLineColour)
                                .setvLineWidth(function (i, node) {
                                    return i === 0 || i === node.table.body[0].length ? 0 : 1;
                                })
                                .setStyle('payloadTableStyle')
                                .build());

                            performanceReportTableBody.push(performanceReportTableRow);
                        }
                        //#endregion Adding Trailer section

                        //#region Adding Waypoints table
                        performanceReportTableRow = [];
                        performanceSectionTableBody = [];
                        performanceSectionTableRow = [];
                        // Waypoints                                
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2903'))
                            .setStyle('payloadHeaderStyle')
                            .build());
                        performanceSectionTableBody.push(performanceSectionTableRow);
                        performanceReportTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTableElement(performanceSectionTableBody)
                            .setColumnWidthsForTable(['*'])
                            .addHeaderRows()
                            .sethLineWidth(function (i, node) {
                                return (i === 1) ? 1 : 0;
                            })
                            .setvLineWidth(function (i, node) {
                                return 0;
                            })
                            .setStyle('payloadTableStyle')
                            .setMargin([10, 10, 10, 0])
                            .build());
                        performanceReportTableBody.push(performanceReportTableRow);


                        // Subheader                                
                        performanceReportTableRow = [];
                        performanceSectionTableBody = [];
                        performanceSectionTableRow = [];

                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('')
                            .setStyle('tableHeader')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2676'))
                            .setStyle('tableHeader')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('1095'))
                            .setStyle('tableHeaderNumberColumn')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('1096'))
                            .setStyle('tableHeaderNumberColumn')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2677'))
                            .setStyle('tableHeaderNumberColumn')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('815'))
                            .setStyle('tableHeaderNumberColumn')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2674'))
                            .setStyle('tableHeaderNumberColumn')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2675'))
                            .setStyle('tableHeaderNumberColumn')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('1097'))
                            .setStyle('tableHeaderNumberColumn')
                            .build());
                        performanceSectionTableBody.push(performanceSectionTableRow);

                        // Loop through waypoints and add rows
                        getActiveOffer().getPerformance().getWaypoints().forEach(function (waypoint, index, arrayInUse) {
                            performanceSectionTableRow = [];
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(globals.getLetterFromInteger(index))
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(waypoint.getDescription())
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(getCustomPrecisionRoundedValue(waypoint.getLatitude(), 6))
                                .setStyle('tableNumberCell')
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(getCustomPrecisionRoundedValue(waypoint.getLongitude(), 6))
                                .setStyle('tableNumberCell')
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(getUIFormattedMassValue(getActiveOffer().getPerformance().getVehicleTotalUnladen() + getActiveOffer().getPerformance().getTrailerTotalUnladen() + getActiveOffer().getPerformance().getTrailer2TotalUnladen()))
                                .setStyle('tableNumberCell')
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(getUIFormattedMassValue(waypoint.getPayloadLoaded()))
                                .setStyle('tableNumberCell')
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(getUIFormattedMassValue(waypoint.getPayloadOffloaded()))
                                .setStyle('tableNumberCell')
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(getUIFormattedMassValue(waypoint.getNetPayload()))
                                .setStyle('tableNumberCell')
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(getUIFormattedMassValue(getActiveOffer().getPerformance().getVehicleTotalUnladen() + getActiveOffer().getPerformance().getTrailerTotalUnladen() + getActiveOffer().getPerformance().getTrailer2TotalUnladen() + waypoint.getNetPayload()))
                                .setStyle('tableNumberCell')
                                .build());
                            performanceSectionTableBody.push(performanceSectionTableRow);
                        });

                        var colWidths = 75;

                        performanceReportTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTableElement(performanceSectionTableBody)
                            .setColumnWidthsForTable(['auto', '*', colWidths, colWidths, colWidths, colWidths, colWidths, colWidths, colWidths])
                            .setFillColourForTable(stripedTableFormatting)
                            .addHeaderRows()
                            .sethLineWidth(tableTitleHorizontalLine)
                            .sethLineColor(horizontalLineColour)
                            .setvLineWidth(function (i, node) {
                                return i === 0 || i === node.table.body[0].length ? 0 : 1;
                            })
                            .setStyle('payloadTableStyle')
                            .build());

                        performanceReportTableBody.push(performanceReportTableRow);
                        //#endregion Adding Waypoints table

                        //#region Adding Toll Fees table
                        if (Array.isArray(getActiveOffer().getPerformance().getTolls()) && getActiveOffer().getPerformance().getTolls().length > 0) {
                            performanceReportTableRow = [];
                            performanceSectionTableBody = [];
                            performanceSectionTableRow = [];
                            // Waypoints                                
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('4095'))
                                .setStyle('payloadHeaderStyle')
                                .build());
                            performanceSectionTableBody.push(performanceSectionTableRow);
                            performanceReportTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTableElement(performanceSectionTableBody)
                                .setColumnWidthsForTable(['*'])
                                .addHeaderRows()
                                .sethLineWidth(function (i, node) {
                                    return (i === 1) ? 1 : 0;
                                })
                                .setvLineWidth(function (i, node) {
                                    return 0;
                                })
                                .setStyle('payloadTableStyle')
                                .setMargin([10, 10, 10, 0])
                                .build());
                            performanceReportTableBody.push(performanceReportTableRow);


                            // Subheader
                            performanceReportTableRow = [];
                            performanceSectionTableBody = [];
                            performanceSectionTableRow = [];

                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('4096'))
                                .setStyle('tableHeader')
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('4097'))
                                .setStyle('tableHeader')
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('4098'))
                                .setStyle('tableHeader')
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('4099'))
                                .setStyle('tableHeaderNumberColumn')
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('4100'))
                                .setStyle('tableHeaderNumberColumn')
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('4101'))
                                .setStyle('tableHeaderNumberColumn')
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('4102'))
                                .setStyle('tableHeaderNumberColumn')
                                .build());
                            performanceSectionTableBody.push(performanceSectionTableRow);

                            // Loop through toll fees and add rows
                            var class1Total = 0,
                                class2Total = 0,
                                class3Total = 0,
                                class4Total = 0;
                            getActiveOffer().getPerformance().getTolls().forEach(function (toll, index, arrayInUse) {
                                class1Total += toll.getTollFees()[0].getFee();
                                class2Total += toll.getTollFees()[1].getFee();
                                class3Total += toll.getTollFees()[2].getFee();
                                class4Total += toll.getTollFees()[3].getFee();
                                performanceSectionTableRow = [];
                                performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement(toll.getRoute())
                                    .build());
                                performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement(toll.getPlaza())
                                    .build());
                                performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    .newTextElement(toll.getGate())
                                    .build());
                                performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    //.newTextElement(toll.getTollFees()[0].getFeeFormatted(currencySymbolMajor.value))
                                    .newTextElement(toll.getTollFees()[0].getFeeFormatted(currencySymbolMajor.value))
                                    .setStyle('tableNumberCell')
                                    .build());
                                performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    //.newTextElement(toll.getTollFees()[1].getFeeFormatted(currencySymbolMajor.value))
                                    .newTextElement(toll.getTollFees()[1].getFeeFormatted(currencySymbolMajor.value))
                                    .setStyle('tableNumberCell')
                                    .build());
                                performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    //.newTextElement(toll.getTollFees()[2].getFeeFormatted(currencySymbolMajor.value))
                                    .newTextElement(toll.getTollFees()[2].getFeeFormatted(currencySymbolMajor.value))
                                    .setStyle('tableNumberCell')
                                    .build());
                                performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                    //.newTextElement(toll.getTollFees()[3].getFeeFormatted(currencySymbolMajor.value))
                                    .newTextElement(toll.getTollFees()[3].getFeeFormatted(currencySymbolMajor.value))
                                    .setStyle('tableNumberCell')
                                    .build());
                                performanceSectionTableBody.push(performanceSectionTableRow);
                            });

                            // Add total row
                            performanceSectionTableRow = [];
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('4103'))
                                .setColSpan(3)
                                .setStyle('tableHeader')
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement('')
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement('')
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                //.newTextElement(currencySymbolMajor.value + ' ' + Globalize.format(class1Total, 'n2'))
                                .newTextElement({
                                        text: currencySymbolMajor.value + ' ' + Globalize.format(class1Total, 'n2'), // User Story 17565
                                        alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT
                                    })
                                .setStyle('tableHeader')
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                //.newTextElement(currencySymbolMajor.value + ' ' + Globalize.format(class2Total, 'n2'))
                                .newTextElement({
                                        text: currencySymbolMajor.value + ' ' + Globalize.format(class2Total, 'n2'), // User Story 17565
                                        alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT
                                    })
                                .setStyle('tableHeader')
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                //.newTextElement(currencySymbolMajor.value + ' ' + Globalize.format(class3Total, 'n2'))
                                .newTextElement({
                                        text: currencySymbolMajor.value + ' ' + Globalize.format(class3Total, 'n2'), // User Story 17565
                                        alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT
                                    })
                                .setStyle('tableHeader')
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                //.newTextElement(currencySymbolMajor.value + ' ' + Globalize.format(class4Total, 'n2'))
                                .newTextElement({
                                        text: currencySymbolMajor.value + ' ' + Globalize.format(class4Total, 'n2'), // User Story 17565
                                        alignment: config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT
                                    })
                                .setStyle('tableHeader')
                                .build());
                            performanceSectionTableBody.push(performanceSectionTableRow);

                            var performanceTollsColWidths = 75;

                            performanceReportTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTableElement(performanceSectionTableBody)
                                //.setColumnWidthsForTable(['auto', 'auto', colWidths, colWidths, colWidths, colWidths])
                                .setColumnWidthsForTable([performanceTollsColWidths, '*', '*', performanceTollsColWidths, performanceTollsColWidths, performanceTollsColWidths, performanceTollsColWidths])
                                .setFillColourForTable(stripedTableFormatting)
                                .addHeaderRows()
                                .sethLineWidth(tableTitleHorizontalLine)
                                .sethLineColor(horizontalLineColour)
                                .setvLineWidth(function (i, node) {
                                    return i === 0 || i === node.table.body[0].length ? 0 : 1;
                                })
                                .setStyle('payloadTableStyle')
                                .build());

                            performanceReportTableBody.push(performanceReportTableRow);

                            performanceReportTableRow = [];
                            performanceReportTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('4104'))
                                .addBold()
                                .setStyle('payloadTableStyle')
                                .build());
                            performanceReportTableBody.push(performanceReportTableRow);

                            performanceReportTableRow = [];
                            performanceSectionTableBody = [];
                            performanceSectionTableRow = [];

                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('5249'))
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(getActiveOffer().getPerformance().getTolls()[0].getTollFees()[0].getVehicleClassDescription())
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('5250'))
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(getActiveOffer().getPerformance().getTolls()[0].getTollFees()[2].getVehicleClassDescription())
                                .build());
                            performanceSectionTableBody.push(performanceSectionTableRow);

                            performanceSectionTableRow = [];
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('5251'))
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(getActiveOffer().getPerformance().getTolls()[0].getTollFees()[1].getVehicleClassDescription())
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('5252'))
                                .build());
                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(getActiveOffer().getPerformance().getTolls()[0].getTollFees()[3].getVehicleClassDescription())
                                .build());
                            performanceSectionTableBody.push(performanceSectionTableRow);

                            performanceReportTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTableElement(performanceSectionTableBody)
                                .setColumnWidthsForTable(['auto', 'auto', 'auto', 'auto'])
                                //.setFillColourForTable(stripedTableFormatting)
                                //.addHeaderRows()
                                //.sethLineWidth(tableTitleHorizontalLine)
                                //.sethLineColor(horizontalLineColour)
                                //.setvLineWidth(function (i, node) {
                                //    return i === 0 || i === node.table.body[0].length ? 0 : 1;
                                //})
                                .setStyle('payloadTableStyle')
                                .addNoBordersLayout()
                                //.setFontSize(fontSizeGeneral - 1)
                                .build());
                            performanceReportTableBody.push(performanceReportTableRow);
                        }
                        
                        //#endregion Adding Toll Fees table

                        //#region Adding Altitude Report Chart
                        if (performanceChartDrawing !== null) {
                            performanceReportTableRow = [];
                            performanceSectionTableRow = [];
                            performanceSectionTableBody = [];

                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTextElement(config.getTranslationText('1099'))
                                .setStyle('payloadHeaderStyle')
                                .build());
                            performanceSectionTableBody.push(performanceSectionTableRow);
                            performanceReportTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTableElement(performanceSectionTableBody)
                                .setColumnWidthsForTable(['*'])
                                .addHeaderRows()
                                .sethLineWidth(function (i, node) {
                                    return (i === 1) ? 1 : 0;
                                })
                                .setvLineWidth(function (i, node) {
                                    return 0;
                                })
                                .setStyle('payloadTableStyle')
                                .setMargin([10, 10, 10, 0])
                                .build());
                            performanceReportTableBody.push(performanceReportTableRow);

                            performanceReportTableRow = [];
                            performanceSectionTableBody = [];
                            performanceSectionTableRow = [];

                            performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newImageElement(performanceChartDrawing)
                                .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.LEFT)
                                .setWidth(795)
                                // .setWidth(760)
                                .build());

                            performanceSectionTableBody.push(performanceSectionTableRow)
                            performanceReportTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTableElement(performanceSectionTableBody)
                                .setColumnWidthsForTable(['*'])
                                .setStyle('payloadTableStyle')
                                .addNoBordersLayout()
                                .build());

                            performanceReportTableBody.push(performanceReportTableRow);
                        }
                        //#endregion Adding Altitude Report Chart

                        //#region Adding Results table
                        performanceReportTableRow = [];
                        performanceSectionTableBody = [];
                        performanceSectionTableRow = [];
                        // Results
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('1098'))
                            .setStyle('payloadHeaderStyle')
                            .build());
                        performanceSectionTableBody.push(performanceSectionTableRow);
                        performanceReportTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTableElement(performanceSectionTableBody)
                            .setColumnWidthsForTable(['*'])
                            .addHeaderRows()
                            .sethLineWidth(function (i, node) {
                                return (i === 1) ? 1 : 0;
                            })
                            .setvLineWidth(function (i, node) {
                                return 0;
                            })
                            .setStyle('payloadTableStyle')
                            .setMargin([10, 10, 10, 0])
                            .build());
                        performanceReportTableBody.push(performanceReportTableRow);


                        // Subheader
                        performanceReportTableRow = [];
                        performanceSectionTableBody = [];
                        performanceSectionTableRow = [];

                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('819'))
                            .setStyle('tableHeaderNumberColumn')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('1094'))
                            .setStyle('tableHeaderNumberColumn')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('1100'))
                            .setStyle('tableHeaderNumberColumn')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2678'))
                            .setStyle('tableHeaderNumberColumn')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('1101'))
                            .setStyle('tableHeaderNumberColumn')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2679'))
                            .setStyle('tableHeaderNumberColumn')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('1102'))
                            .setStyle('tableHeaderNumberColumn')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('4119'))
                            .setStyle('tableHeaderNumberColumn')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('4120'))
                            .setStyle('tableHeaderNumberColumn')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2680'))
                            .setStyle('tableHeaderNumberColumn')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('1103'))
                            .setStyle('tableHeaderNumberColumn')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('2681'))
                            .setStyle('tableHeaderNumberColumn')
                            .build());
                        performanceSectionTableBody.push(performanceSectionTableRow);

                        // Add total row
                        var formatFunction = isActiveMeasurementSystemMetric() === true ? offerManager.getUIFormattedFuelConsumptionValue : offerManager.getFuelConsumptionForImperialMeasurementSystemValue,
                            formatFunctionForAdblue = isActiveMeasurementSystemMetric() === true ? offerManager.getUIFormattedAdblueConsumptionValue : offerManager.getAdblueConsumptionForImperialMeasurementSystemValue;
                        performanceSectionTableRow = [];
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(getUIFormattedDistanceValue(getActiveOffer().getPerformance().getSimResTripDistance()))
                            .setStyle('tableNumberCell')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(globals.formatSeconds(getActiveOffer().getPerformance().getSimResTripTime()))
                            .setStyle('tableNumberCell')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(getUIFormattedSpeedValue(getActiveOffer().getPerformance().getSimResAverageSpeed(), 1))
                            .setStyle('tableNumberCell')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(getUIFormattedVolumeValue(getActiveOffer().getPerformance().getSimResTotalFuel(), 1))
                            .setStyle('tableNumberCell')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(formatFunction(getActiveOffer().getPerformance().getSimResAverageFuel(), globals.user.getActiveMeasurementSystem(), true))
                            .setStyle('tableNumberCell')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(getUIFormattedVolumeValue(getActiveOffer().getPerformance().getSimResTotalAdblue(), 1))
                            .setStyle('tableNumberCell')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            // .newTextElement(formatFunctionForAdblue(getActiveOffer().getPerformance().getSimResAverageAdblue()))
                            .newTextElement(getUIFormattedPercentageSinglePlaceValue(getActiveOffer().getPerformance().getAdblueRate()))
                            .setStyle('tableNumberCell')
                            .build());

                        var totalGearChangesValue,
                            totalGearChangesStyle;
                        if (getActiveOffer().getPerformance().getSimResTotalGearChanges() === 0) {
                            totalGearChangesValue = '-';
                            totalGearChangesStyle = 'tableNumberBlankCell';
                        } else {
                            totalGearChangesValue = getActiveOffer().getPerformance().getSimResTotalGearChanges()
                            totalGearChangesStyle = 'tableNumberCell';
                        }
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(totalGearChangesValue)
                            .setStyle(totalGearChangesStyle)
                            .build());

                        var totalTimeInTopGearValue,
                            totalTimeInTopGearStyle;
                        if (getActiveOffer().getPerformance().getSimResTotalTimeInTopGear() === 0) {
                            totalTimeInTopGearValue = '-';
                            totalTimeInTopGearStyle = 'tableNumberBlankCell';
                        } else {
                            totalTimeInTopGearValue = globals.formatSeconds(getActiveOffer().getPerformance().getSimResTotalTimeInTopGear());
                            totalTimeInTopGearStyle = 'tableNumberCell';
                        }
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(totalTimeInTopGearValue)
                            .setStyle(totalTimeInTopGearStyle)
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(getUIFormattedMassValue(getActiveOffer().getPerformance().getSimResTotalCO2Emissions(), 1))
                            .setStyle('tableNumberCell')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(getUIFormattedMinorMassPerDistanceValue(getActiveOffer().getPerformance().getSimResAverageCO2Emissions()))
                            .setStyle('tableNumberCell')
                            .build());
                        performanceSectionTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(Globalize.format(getActiveOffer().getPerformance().getSimResProductivity(), "n1")) // User Story 17565
                            .setStyle('tableNumberCell')
                            .build());
                        performanceSectionTableBody.push(performanceSectionTableRow);

                        var performanceResultsColWidths = 55;
                        performanceReportTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTableElement(performanceSectionTableBody)
                            //.setColumnWidthsForTable(['*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*'])
                            //.setColumnWidthsForTable(['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'])
                            .setColumnWidthsForTable([performanceResultsColWidths, performanceResultsColWidths, performanceResultsColWidths, performanceResultsColWidths, '*', performanceResultsColWidths, '*', performanceResultsColWidths, performanceResultsColWidths, performanceResultsColWidths, performanceResultsColWidths, performanceResultsColWidths])
                            
                            .setFillColourForTable(stripedTableFormatting)
                            .addHeaderRows()
                            .sethLineWidth(tableTitleHorizontalLine)
                            .sethLineColor(horizontalLineColour)
                            .setvLineWidth(function (i, node) {
                                return i === 0 || i === node.table.body[0].length ? 0 : 1;
                            })
                            .setStyle('payloadTableStyle')
                            .build());

                        performanceReportTableBody.push(performanceReportTableRow);
                        //#endregion Adding Results table

                        // #region Explanation Section
                        // NumberOfGearChangesMessage
                        performanceReportTableRow = [];
                        performanceSectionTableBody = [];

                        performanceReportTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('5253'))
                            .setFontSize(fontSizeGeneral - 1)
                            .addItalics()
                            .build());
                        performanceReportTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('4117'))
                            .setFontSize(fontSizeGeneral - 1)
                            .addItalics()
                            .build());
                        performanceSectionTableBody.push(performanceReportTableRow);
                        
                        // TimeInTopGearMessage
                        performanceReportTableRow = [];
                        performanceReportTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('5254'))
                            .setFontSize(fontSizeGeneral - 1)
                            .addItalics()
                            .build());
                        performanceReportTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement(config.getTranslationText('4118'))
                            .setFontSize(fontSizeGeneral - 1)
                            .addItalics()
                            .build());
                        performanceSectionTableBody.push(performanceReportTableRow);

                        // ProductivityMessage
                        performanceReportTableRow = [];
                        performanceReportTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTextElement('***')
                            .newTextElement(config.getTranslationText('5255'))
                            .setFontSize(fontSizeGeneral - 1)
                            .addItalics()
                            .build());
                        performanceReportTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            // .newTextElement(config.getTranslationText('2714', [config.getTranslationText('2717'), getMeasurementSystemToUse().unitSpeed, getMeasurementSystemToUse().unitVolumePer100Distance]))
                            // .newTextElement(config.getTranslationText('1154'))
                            .newTextElement(config.getTranslationText('2714'))
                            .setFontSize(fontSizeGeneral - 1)
                            .addItalics()
                            .build());
                        performanceSectionTableBody.push(performanceReportTableRow);

                        // add table to array performanceReportTableBody
                        performanceReportTableBody.push([new pdfBuilders.PdfMakeElementBuilder()
                            .newTableElement(performanceSectionTableBody)
                            .setColumnWidthsForTable(['auto', '*'])
                            .addNoBordersLayout()
                            .setStyle('payloadTableStyle')
                            .build()]);
                        // #endregion Explanation Section
                        
                        performanceContainerTableRow.push(new pdfBuilders.PdfMakeElementBuilder()
                            .newTableElement(performanceReportTableBody)
                            .setColumnWidthsForTable(['*'])
                            .addNoBordersLayout()
                            .build());

                        performanceContainerTableBody.push(performanceContainerTableRow);

                        // check if config report is available then add pagebreak
                        //if (configurationReportOption !== null && configurationReportOption.getIsChecked()) {
                        if (addPageBreakBeforePage === true) {
                            //// setup for portrait
                            //bodyBuilderContentArray.push(new pdfBuilders.PdfMakeElementBuilder()
                            //    .newTableElement(performanceContainerTableBody)
                            //    .addHeaderRows(1)
                            //    .setColumnWidthsForTable(['*'])
                            //    .addNoBordersLayout()
                            //    .setMargin([0, 0, 0, 5])
                            //    .addPageBreakBeforeElement()
                            //    .setPageOrientationToPortrait()
                            //    .build());

                            // setup for landscape
                            bodyBuilderContentArray.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTableElement(performanceContainerTableBody)
                                .setColumnWidthsForTable(['*'])
                                .addNoBordersLayout()
                                .setMargin([0, 0, 0, 0])
                                .addPageBreakBeforeElement()
                                .build());

                        } else {
                            //// setup for portrait
                            //bodyBuilderContentArray.push(new pdfBuilders.PdfMakeElementBuilder()
                            //    .newTableElement(performanceContainerTableBody)
                            //    .addHeaderRows(1)
                            //    .setColumnWidthsForTable(['*'])
                            //    .addNoBordersLayout()
                            //    .setMargin([0, 0, 0, 5])
                            //    .build());

                            // setup for landscape
                            bodyBuilderContentArray.push(new pdfBuilders.PdfMakeElementBuilder()
                                .newTableElement(performanceContainerTableBody)
                                .setColumnWidthsForTable(['*'])
                                .addNoBordersLayout()
                                //.setMargin([0, 0, 0, 5])
                                .build());
                        }

                    }
                }

                try {
                    var configurationReportOption = getSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.CONFIGURATION),
                        specificationReportOption = getSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.SPECIFICATION),
                        costingReportOption = getSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.COSTING),
                        performanceReportOption = getSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.PERFORMANCE),
                        displayConfigurationReport = configurationReportOption !== null ? configurationReportOption.getIsChecked() : false,
                        displaySpecificationReport = specificationReportOption !== null ? specificationReportOption.getIsChecked() : false,
                        displayCostingReport = costingReportOption !== null ? costingReportOption.getIsChecked() : false,
                        displayPerformanceReport = performanceReportOption !== null ? performanceReportOption.getIsChecked() : false,
                        drawingPageDisplayed = false,
                        loadPlanDisplayed = false,
                        detailedPayloadAvailable = false;

                    if (configurationReportOption !== null && configurationReportOption.getIsChecked()) {
                        var drawingViewOfferOption = getSummaryReportOfferOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.CONFIGURATION, config.SUMMARY_REPORT_OFFER_OPTIONS.DRAWING_VIEW),
                            loadPlanOfferOption = getSummaryReportOfferOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.CONFIGURATION, config.SUMMARY_REPORT_OFFER_OPTIONS.LOAD_PLAN),
                            weightAndCogOfferOption = getSummaryReportOfferOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.CONFIGURATION, config.SUMMARY_REPORT_OFFER_OPTIONS.WEIGHT_AND_COG),
                            addPageBreakBeforeNotesAndWarnings = false;

                        updateLandscapeImageDimensionsIfNecessary();
                        if (drawingViewOfferOption !== null && drawingViewOfferOption.getIsChecked()) {                            
                            if(globals.user.getReportViewLayout() === config.REPORT_VIEW_LAYOUT_OPTIONS.SIMPLIFIED) {
                                createConfigurationReportPage();
                                addPageBreakBeforeNotesAndWarnings = true;
                                drawingPageDisplayed = true;
                            } else {
                                // createConfigurationReportImagePage(vehicleConfigurationDrawingObjects[`${config.DISPLAY_TYPE.DETAILED}_${config.VIEW_DETAIL.SUMMARY}_${config.VIEW_TYPE.OVERVIEW}_${config.ELEVATION.SIDE}`]);
                                globals.user.getReportViews().forEach(function (reportView) {
                                    if (reportView.getIsActive()) {
                                        var stringVal = `${reportView.getDisplay()}_${reportView.getViewDetail()}_${reportView.getViewType()}_${reportView.getElevation()}`;
                                        if(vehicleConfigurationDrawingObjects[stringVal] !== undefined) {
                                            createConfigurationReportImagePage(vehicleConfigurationDrawingObjects[stringVal]);
                                            // createConfigurationReportImagePage(vehicleConfigurationDrawingObjects[`${reportView.getViewType()}_${reportView.getViewDetail()}_${reportView.getDisplay()}_${reportView.getElevation()}`]);
                                        }
                                    }
                                });
                            }
                        }

                        // Add check if the a detailed payload is available
                        if (isDetailedPayloadAvailableOnRig()) {
                            detailedPayloadAvailable = true;
                        }
                        if (loadPlanOfferOption !== null && loadPlanOfferOption.getIsChecked() && detailedPayloadAvailable) {
                            loadPlanDisplayed = true;
                            addPageBreakBeforeNotesAndWarnings = true;
                            createLoadPlan(drawingPageDisplayed);
                        }

                        if (weightAndCogOfferOption !== null && weightAndCogOfferOption.getIsChecked()) {
                            addPageBreakBeforeNotesAndWarnings = true;
                            createWeightAndCog(drawingPageDisplayed || loadPlanDisplayed);
                        }

                        createNotesAndWarnings(addPageBreakBeforeNotesAndWarnings);
                    }

                    if (performanceReportOption !== null && performanceReportOption.getIsChecked()) {
                        createPerformanceReportPages(displayConfigurationReport === true);
                    }

                    // if (costingReportOption !== null && costingReportOption.getIsChecked()) {
                    if (checkToAllowCostingPages()) {
                        createCostingReportPages(displayConfigurationReport === true || displayPerformanceReport === true);
                    }

                    // if (specificationReportOption !== null && specificationReportOption.getIsChecked()) {
                    if(checkToAllowSpecificationPages()) {
                        createSpecificationReportPages(displayConfigurationReport === true || displayPerformanceReport === true || displayCostingReport === true);
                    }

                    return bodyBuilderContentArray;

                } catch (innerEx) {
                    dataManager.log("generatePdfMake.createBodyBuilderReportContent, setting up body builder report content", innerEx, 'offerManager', config.LOG_MESSAGE_TYPE.ERROR);
                }
            }

            try {
                var customerName = getActiveOffer().getPreparedForName(),
                    customerNumber = getActiveOffer().getPreparedForTelephone(),
                    elevationMenuValue,
                    viewTypeMenuValue,
                    viewDetailMenuValue;
                if (activeConfigurationElevation.value !== undefined) {
                    elevationMenuValue = activeConfigurationElevation.value.label;
                } else {
                    elevationMenuValue = config.getTranslationText('2608');
                }
                if (offerManager.activeConfigurationViewDetail.value !== undefined) {
                    viewDetailMenuValue = isRef(offerManager.activeConfigurationViewDetail.value.label) === true ? offerManager.activeConfigurationViewDetail.value.label.value : offerManager.activeConfigurationViewDetail.value.label;
                    viewTypeMenuValue = offerManager.activeConfigurationViewType.value.label;
                } else {
                    viewDetailMenuValue = config.getTranslationText('462');
                    viewTypeMenuValue = config.getTranslationText('1245');
                }

                var defaultStyleObject = new pdfBuilders.PdfMakeElementBuilder()
                    .setColour(config.pdfDocumentColour)
                    .setFontSize(fontSizeGeneral)
                    .setFont('Roboto')
                    .build();
                var weightDistStyle = new pdfBuilders.PdfMakeElementBuilder()
                    .newStyleElement('weightDistStyle')
                    .setFontSize(fontSizeGeneral + 2)
                    .addBold()
                    .build();
                var disclaimerStyle = new pdfBuilders.PdfMakeElementBuilder()
                    .newStyleElement('disclaimerStyle')
                    .setFontSize(fontSizeGeneral - 4)
                    .setMargin([0, 2, 0, 0])
                    .setColour('gray')
                    .build();
                var footerCellBoldStyle = new pdfBuilders.PdfMakeElementBuilder()
                    .newStyleElement('footerCellBoldStyle')
                    .addBold()
                    .setMargin([0, -3, 0, 0])
                    .build();
                var footerCellStyle = new pdfBuilders.PdfMakeElementBuilder()
                    .newStyleElement('footerCellStyle')
                    .setFontSize(fontSizeGeneral - 1)
                    .setMargin([0, -2, 0, 0])
                    .build();
                var footerBottomRowCellStyle = new pdfBuilders.PdfMakeElementBuilder()
                    .newStyleElement('footerBottomRowCellStyle')
                    .setMargin([0, 0, 0, 0])
                    .build();
                var footerCellPreparedStyle = new pdfBuilders.PdfMakeElementBuilder()
                    .newStyleElement('footerCellPreparedStyle')
                    .setFontSize(fontSizeGeneral - 1)
                    .setMargin([-4, -2, 0, 0])
                    //.setMargin([-4, -3, 0, 0])
                    .build();
                var footerCellPreparedStyleRow2 = new pdfBuilders.PdfMakeElementBuilder()
                    .newStyleElement('footerCellPreparedStyleRow2')
                    .setFontSize(fontSizeGeneral - 1)
                    .setMargin([-4, 0, 0, 0])
                    .build();
                var payloadHeaderStyle = new pdfBuilders.PdfMakeElementBuilder()
                    .newStyleElement('payloadHeaderStyle')
                    .setFontSize(fontSizeGeneral + 2)
                    .addBold()
                    .setMargin([0, 0, 0, 3])
                    .build();
                var payloadTableStyle = new pdfBuilders.PdfMakeElementBuilder()
                    .newStyleElement('payloadTableStyle')
                    .setMargin([12, 3, 12, 0])
                    .build();
                var payloadTableNumberStyle = new pdfBuilders.PdfMakeElementBuilder()
                    .newStyleElement('payloadTableNumberStyle')
                    .setAlignment('right')
                    .build();
                var tableHeader = new pdfBuilders.PdfMakeElementBuilder()
                    .newStyleElement('tableHeader')
                    .addBold()
                    .setFontSize(fontSizeGeneral + 1)
                    .build();
                var tableHeaderNumberColumn = new pdfBuilders.PdfMakeElementBuilder()
                    .newStyleElement('tableHeaderNumberColumn')
                    .addBold()
                    .setFontSize(fontSizeGeneral + 1)
                    .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE)
                    .build();
                var tableHeaderRight = new pdfBuilders.PdfMakeElementBuilder()
                    .newStyleElement('tableHeaderRight')
                    .addBold()
                    .setFontSize(fontSizeGeneral + 1)
                    .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT)
                    .build();
                var specificationTableColumnContentStyle = new pdfBuilders.PdfMakeElementBuilder()
                    .newStyleElement('specificationTableColumnContentStyle')
                    .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE)
                    .build();
                var specificationTableStyle = new pdfBuilders.PdfMakeElementBuilder()
                    .newStyleElement('specificationTableStyle')
                    .setMargin([15, 0, 15, 15])
                    .build();
                var costingChartHeaderStyle = new pdfBuilders.PdfMakeElementBuilder()
                    .newStyleElement('costingChartHeaderStyle')
                    .addBold()
                    .build();
                var costingChartTextStyle = new pdfBuilders.PdfMakeElementBuilder()
                    .newStyleElement('costingChartTextStyle')
                    .setFontSize(fontSizeGeneral + 8)
                    .addBold()
                    .setMargin([0, 10])
                    .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE)
                    .build();
                var payloadHeaderTextStyle = new pdfBuilders.PdfMakeElementBuilder()
                    .newStyleElement('payloadHeaderTextStyle')
                    .setFontSize(fontSizeGeneral + 1)
                    .addBold()
                    .setMargin([5, 0])
                    .build();
                var smallItalicizedText = new pdfBuilders.PdfMakeElementBuilder()
                    .newStyleElement('smallItalicizedText')
                    .setFontSize(fontSizeGeneral - 1)
                    .addItalics()
                    .build();
                var costingTableChildRowStyle = new pdfBuilders.PdfMakeElementBuilder()
                    .newStyleElement('costingTableChildRowStyle')
                    .addItalics()
                    .setMargin([5, 0, 0, 0])
                    .build();
                var tableNumberCell = new pdfBuilders.PdfMakeElementBuilder()
                    .newStyleElement('tableNumberCell')
                    .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.RIGHT)
                    .build();
                var tableNumberBlankCell = new pdfBuilders.PdfMakeElementBuilder()
                    .newStyleElement('tableNumberBlankCell')
                    .setAlignment(config.PDFMAKE_ALIGNMENT_OPTIONS.CENTRE)
                    .build();

                var content = createBodyBuilderReportContent();

                //var documentDefinition = new pdfBuilders.DocumentDefinitionBuilder()
                documentDefinition = new pdfBuilders.DocumentDefinitionBuilder()
                    .newDocumentDefinition()
                    .setPageOrientationToLandscape()
                    .setContent(content)
                    .setFooter(setPageCount)
                    .setFooter(bodyBuilderReportFooter)
                    .setHeader(createHeaderObject)
                    .setDefaultStyle(defaultStyleObject)
                    .addStyle(weightDistStyle)
                    .addStyle(disclaimerStyle)
                    .addStyle(footerCellStyle)
                    .addStyle(footerCellBoldStyle)
                    .addStyle(footerBottomRowCellStyle)
                    .addStyle(footerCellPreparedStyle)
                    .addStyle(footerCellPreparedStyleRow2)
                    .addStyle(payloadHeaderStyle)
                    .addStyle(payloadTableStyle)
                    .addStyle(tableHeader)
                    .addStyle(tableHeaderNumberColumn)
                    .addStyle(tableHeaderRight)
                    .addStyle(payloadTableNumberStyle)
                    .addStyle(specificationTableColumnContentStyle)
                    .addStyle(specificationTableStyle)
                    .addStyle(costingChartHeaderStyle)
                    .addStyle(costingChartTextStyle)
                    .addStyle(payloadHeaderTextStyle)
                    .addStyle(smallItalicizedText)
                    .addStyle(costingTableChildRowStyle)
                    .addStyle(tableNumberCell)
                    .addStyle(tableNumberBlankCell)
                    .setPageMargins([1, 50, 1, 60])
                    .setPageSize(globals.user.getDefaultReportPdfPageSize())
                    .build();

                return documentDefinition;
            } catch (innerEx) {
                dataManager.log("generatePdfMake.createBodyBuilderReportDocumentDefinition, setting up body builder report document definition", innerEx, 'offerManager', config.LOG_MESSAGE_TYPE.ERROR);
            }

        }

        // #endregion Functions for PDF
        TScMessenger.writeDebugMessage("OfferManager.js, createPdfDocumentDefinition, generatePdfMake, createBodyBuilderReportDocumentDefinition, start of generatePdfMake");
        try {
            var contentArray = [];
            var fontSizeGeneral = 10;
            var fontSizeHeader = fontSizeGeneral + 6;
            var empty = {
                Id: shallowRef(0),
                ContactName: shallowRef(''),
                Company: shallowRef(''),
                Mobile: shallowRef(''),
                DirectNumber: shallowRef(''),
                Email: shallowRef(''),
                UpdateCounter: shallowRef(0),
                OverallUpdateCounter: shallowRef(0)
            };
            var customer = shallowRef();
            if (selectedCustomer.value !== null) {
                if (customer.value === undefined) {
                    customer.value = empty;
                }
                utils.populateUIObservablesFromCustomer(customer.value, selectedCustomer.value);
            } else {
                customer.value = selectedCustomer.value;
            }

            try {
                var documentDefinition;
                TScMessenger.writeDebugMessage("OfferManager.js, createPdfDocumentDefinition, generatePdfMake, createBodyBuilderReportDocumentDefinition, before documentDefinition");
                documentDefinition = createBodyBuilderReportDocumentDefinition();
            } catch (innerEx5) {
                dataManager.log("generatePdfMake, setting up document object", innerEx5, 'offerManager', config.LOG_MESSAGE_TYPE.ERROR);
            }

            TScMessenger.writeDebugMessage("OfferManager.js, createPdfDocumentDefinition, generatePdfMake, before dfdDocDefPromise.resolve(documentDefinition);");
            dfdDocDefPromise.resolve(documentDefinition);

        } catch (ex) {
            //dataManager.log("generatePdfMake, creating pdf", ex, system.getModuleId(vm), config.LOG_MESSAGE_TYPE.ERROR);
            dataManager.log("generatePdfMake, creating pdf", ex, 'offerManager', config.LOG_MESSAGE_TYPE.ERROR);
        }
    }

    return dfdDocDefPromise.promise();
}

function createOrLoadVehicleDrawing() {
    var dfdCreateOrLoad = $.Deferred();

    TScMessenger.writeDebugMessage("OfferManager.js, createPdfDocumentDefinition, generatePdfMake, createOrLoadVehicleDrawing, start of createOrLoadVehicleDrawing");
    if (configDrawing === undefined || configDrawing === null) {
        dataManager.retrieve(config.OPERATION.ConfigDrawing, offerManager.vehicleId.value + "_configurationDrawing")
            .then(function (data) {
                TScMessenger.writeDebugMessage("OfferManager.js, createPdfDocumentDefinition, generatePdfMake, createOrLoadVehicleDrawing, after try to get configurationDrawing");
                if (data === null) {
                    var requests = [
                        writeVehicleDrawingToLocalStorage()
                    ];
                    $.when(...requests).done(function (dataUrl, dataUrlTopView, dataUrlCogView) {
                        configDrawing = dataUrl;
                        configDrawingTopView = dataUrlTopView;
                        configDrawingCogView = dataUrlCogView;
                        dfdCreateOrLoad.resolve(configDrawing);
                    });
                    
                    // $.when(writeVehicleDrawingToLocalStorage()).done(function (dataUrl) {
                    //     configDrawing = dataUrl;
                    //     dfdCreateOrLoad.resolve(configDrawing);
                    // });

                    // 
                    //     .then(function (dataUrl) {
                    //         configDrawing = dataUrl;
                    //         dfdCreateOrLoad.resolve(configDrawing);
                    //     });
                } else {
                    configDrawing = data;
                    dfdCreateOrLoad.resolve(configDrawing);
                }
            });
    } else {
        dfdCreateOrLoad.resolve(configDrawing);
    }

    return dfdCreateOrLoad.promise();
}

function createVehicleDrawingParametersObject(display, viewDetail, viewType, elevation) {
    var vehicleDrawingParametersObject = {
        display: display,
        viewDetail: viewDetail,
        viewType: viewType,
        elevation: elevation
    };

    return vehicleDrawingParametersObject;
}

/**
 * 
 * @param {Array[{}]} listOfVehicleDrawings 
 * @returns {Promise}
 */
function createOrLoadAllNecessaryVehicleDrawings(listOfVehicleDrawings) {
    var dfdCreateOrLoad = $.Deferred(),
        drawingDetails = [];

    if(Array.isArray(listOfVehicleDrawings) === true && listOfVehicleDrawings.length > 0) {
        vehicleConfigurationDrawingObjects = {};

        listOfVehicleDrawings.forEach(function (vehicleDrawing) {
            drawingDetails.push(createOrLoadVehicleDrawingWithParameters(
                vehicleDrawing.display,
                vehicleDrawing.viewDetail,
                vehicleDrawing.viewType,
                vehicleDrawing.elevation
            ));
        });

        let promiseCounter = 0;
        let promiseExecution = async () => {
            for (let promise of [...drawingDetails]) {
                try {
                    promiseCounter++;
                    const drawing = await promise;
                    if(drawing.dataUrl !== null) {
                        vehicleConfigurationDrawingObjects[drawing.attributeName] = drawing.dataUrl;
                    }
                    if(promiseCounter === listOfVehicleDrawings.length) {
                        dfdCreateOrLoad.resolve({});
                    }
                } catch (error) {
                    console.log(error.message);
                }
            }
        };
        promiseExecution();
        // $.when(...drawingDetails).done(function (...drawings) {
        //     drawings.forEach(function (drawing) {
        //         if(drawing.dataUrl !== null) {
        //             vehicleConfigurationDrawingObjects[drawing.attributeName] = drawing.dataUrl;
        //         }
        //     });
        //     dfdCreateOrLoad.resolve({});
        // });
    } else {
        dfdCreateOrLoad.resolve({});
    }

    return dfdCreateOrLoad.promise();
}

/**
 * 
 * @param {string} display 
 * @param {string} viewDetail 
 * @param {string} viewType 
 * @param {string} elevation 
 * @returns {Promise}
 */
function createOrLoadVehicleDrawingWithParameters(
    display,
    viewDetail,
    viewType,
    elevation
) {
    var dfdGetDrawing = $.Deferred(),
        returnAttributeName = `${display}_${viewDetail}_${viewType}_${elevation}`,
        stringPath = `${offerManager.vehicleId.value}_configurationDrawing_${display}_${viewDetail}_${viewType}_${elevation}`;

    dataManager.retrieve(config.OPERATION.ConfigDrawing, stringPath)
        .then(function (data) {
            if (data === null) {
                $.when(writeVehicleDrawingToLocalStorageWithParameters(display, viewDetail, viewType, elevation)).done(function (dataUrl) {
                    dfdGetDrawing.resolve({
                        attributeName: returnAttributeName,
                        dataUrl: dataUrl
                    });
                });
            } else {
                dfdGetDrawing.resolve({
                    attributeName: returnAttributeName,
                    dataUrl: data
                });
            }
        });

    return dfdGetDrawing.promise();
}

function writeVehicleDrawingToLocalStorage() {
    var dfdImage = $.Deferred();

    var divHeight, divWidth;
    divHeight = 934; //934;//900;
    divWidth = 1600; //1400;//900 / 0.6;
    
    var canvas = document.createElement('canvas');
    canvas.width = divWidth;
    canvas.height = divHeight;

    if (globals.user.getDefaultReportPdfPageSize() === config.PDFMAKE_PAGE_SIZE_OPTIONS.LETTER) {
        canvas.width -= 90;
        canvas.height += 40;
    }

    var context = canvas.getContext('2d');
    var fontName = window.getComputedStyle(document.body, null).getPropertyValue('font-family');

    //#unfinished, temporary hardcode fix to allow navigation to summary without first navigating to configuration where thes variable are initialised - needs to be moved to offerManager
    var displayId, viewDetailId, viewType, elevation;

    if (offerManager.activeConfigurationDisplay.value !== undefined) {
        displayId = offerManager.activeConfigurationDisplay.value.itemId;
        viewDetailId = offerManager.activeConfigurationViewDetail.value.itemId;
        viewType = offerManager.activeConfigurationViewType.value.itemId;
        elevation = offerManager.activeConfigurationElevation.value.itemId;
    } else {
        viewDetailId = 'summary';
        if (globals.user.overrideDefaultMenuDislpay(getConfiguration().getDBUnladenTotal()) === true) {
            displayId = config.DISPLAY_TYPE.DETAILED;
        } else {
            displayId = config.DISPLAY_TYPE.SIMPLIFIED;
        }
        viewType = config.VIEW_TYPE.OVERVIEW;
        elevation = config.ELEVATION.SIDE;
    }

    // Clean up here
    if (typeof graphicsManagerCleanUpCallback === 'function') {
        graphicsManagerCleanUpCallback();
    }

    var gmConfig = {
        renderMode: config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG,
        objectToRender: offerManager.getActiveOffer().getRig(),
        displayType: displayId,
        viewDetail: viewDetailId,
        viewType: viewType,
        elevation: elevation,
        fontName: fontName,
        isPdf: true

    };

    var drawingIsShowing = addToLocalStorageCallback(canvas, canvas.width, canvas.height, gmConfig);

    detachGraphicsManagerOnResizeCallback();

    var dataUrl;

    landscapeImageWidth = 800;
    landscapeImageHeight = 467;

    // if (globals.user.isTrialUser() === true && globals.user.isVipTrialUser() === false && globals.trialStampWatermarkIsAvailable.value !== true) {
    //     watch(globals.trialStampWatermarkIsAvailable, function (newValue) {
    //         if (newValue === true) {
    //             dataUrl = getDataUrlCallback();
    //             storeDrawingAndResolve();
    //         }
    //     });
    // } else {
    //     dataUrl = getDataUrlCallback();
    //     storeDrawingAndResolve();
    // }
    dataUrl = getDataUrlCallback();
    storeDrawingAndResolve();
    //storeDrawingAndResolve();

    function storeDrawingAndResolve() {
        localStorage.setItem("configurationDrawing", dataUrl);
        dataManager.persist(config.OPERATION.ConfigDrawing, dataUrl, offerManager.vehicleId.value + "_configurationDrawing")
            .then(function () {
                if (dfdImage !== undefined) {
                    dfdImage.resolve(dataUrl);
                }
            });
    }

    return dfdImage.promise();
}

/**
 * 
 * @param {*} display - From config.DISPLAY_TYPE enum
 * @param {*} viewDetail - From config.VIEW_DETAIL enum
 * @param {*} viewType - From config.VIEW_TYPE enum
 * @param {*} elevation - Side/top from config.ELEVATION enum
 * @returns {Promise}
 */
function writeVehicleDrawingToLocalStorageWithParameters(
    display,
    viewDetail,
    viewType,
    elevation,
) {
    var dfdImage = $.Deferred();

    var divHeight, divWidth;
    divHeight = 934; //934;//900;
    divWidth = 1600; //1400;//900 / 0.6;
    
    var canvas = document.createElement('canvas');
    canvas.width = divWidth;
    canvas.height = divHeight;

    if (globals.user.getDefaultReportPdfPageSize() === config.PDFMAKE_PAGE_SIZE_OPTIONS.LETTER) {
        canvas.width -= 90;
        canvas.height += 40;
    }

    var context = canvas.getContext('2d');
    var fontName = window.getComputedStyle(document.body, null).getPropertyValue('font-family');

    //#unfinished, temporary hardcode fix to allow navigation to summary without first navigating to configuration where thes variable are initialised - needs to be moved to offerManager
    var displayId, viewDetailId, viewTypeId, elevationId;

    if(display !== undefined) {
        displayId = display;
    } else {
        if (offerManager.activeConfigurationDisplay.value !== undefined) {
            displayId = offerManager.activeConfigurationDisplay.value.itemId;
        } else {
            if (globals.user.overrideDefaultMenuDislpay(getConfiguration().getDBUnladenTotal()) === true) {
                displayId = config.DISPLAY_TYPE.DETAILED;
            } else {
                displayId = config.DISPLAY_TYPE.SIMPLIFIED;
            }
        }    
    }

    if(viewDetail !== undefined) {
        viewDetailId = viewDetail;
    } else {
        if (offerManager.activeConfigurationDisplay.value !== undefined) {
            viewDetailId = offerManager.activeConfigurationViewDetail.value.itemId;
        } else {
            viewDetailId = 'summary';
        }    
    }

    if(viewType !== undefined) {
        viewTypeId = viewType;
    } else {
        if (offerManager.activeConfigurationDisplay.value !== undefined) {
            viewTypeId = offerManager.activeConfigurationViewType.value.itemId;
        } else {
            viewTypeId = config.VIEW_TYPE.OVERVIEW;
        }    
    }

    if(elevation !== undefined) {
        elevationId = elevation;
    } else {
        if (offerManager.activeConfigurationDisplay.value !== undefined) {
            elevationId = offerManager.activeConfigurationElevation.value.itemId;
        } else {
            elevationId = config.ELEVATION.SIDE;
        }    
    }

    // Clean up here
    if (typeof graphicsManagerCleanUpCallback === 'function') {
        graphicsManagerCleanUpCallback();
    }

    var gmConfig = {
        renderMode: config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG,
        objectToRender: offerManager.getActiveOffer().getRig(),
        displayType: displayId,
        viewDetail: viewDetailId,
        viewType: viewTypeId,
        elevation: elevationId,
        fontName: fontName,
        isPdf: true

    };

    try {
        var drawingIsShowing = addToLocalStorageCallback(canvas, canvas.width, canvas.height, gmConfig);

        detachGraphicsManagerOnResizeCallback();
    
        var dataUrl;
    
        landscapeImageWidth = 800;
        landscapeImageHeight = 467;
    
        if (globals.user.isTrialUser() === true && globals.user.isVipTrialUser() === false && globals.trialStampWatermarkIsAvailable.value !== true) {
            let watchCleanup = watch(globals.trialStampWatermarkIsAvailable, function (newValue) {
                if (newValue === true) {
                    watchCleanup();
                    dataUrl = getDataUrlCallback();
                    storeDrawingAndResolve();
                }
            });
        } else {
            dataUrl = getDataUrlCallback();
            storeDrawingAndResolve();
        }
    } catch (e) {
        detachGraphicsManagerOnResizeCallback();
        dfdImage.resolve(null);
    }
    
    function storeDrawingAndResolve() {
        localStorage.setItem("configurationDrawing", dataUrl);
        var saveString = `${offerManager.vehicleId.value}_configurationDrawing_${displayId}_${viewDetailId}_${viewTypeId}_${elevationId}`;
        dataManager.persist(config.OPERATION.ConfigDrawing, dataUrl, saveString)
            .then(function () {
                if (dfdImage !== undefined) {
                    dfdImage.resolve(dataUrl);
                }
            });
    }

    return dfdImage.promise();
}

function writePdfToLocalStorage(pdfDataUri) {
    createBlobForDownloadCallback(pdfDataUri);
    try {
        localStorage.setItem('lastCreatedPdf', pdfDataUri);
    } catch (e) {
        //private browsing is enabled so can't store the pdf
    }
}

function isActiveMeasurementSystemMetric() {
    return getActiveOffer().getMeasurementSystem().id === config.MEASUREMENT_SYSTEM.METRIC;
}

function isActiveMeasurementSystemImperialUK() {
    return getActiveOffer().getMeasurementSystem().id === config.MEASUREMENT_SYSTEM.IMPERIALUK;
}

function isActiveMeasurementSystemImperialUS() {
    return getActiveOffer().getMeasurementSystem().id === config.MEASUREMENT_SYSTEM.IMPERIALUS;
}

function isActiveMeasurementSystemImperial() {
    return isActiveMeasurementSystemImperialUK() || isActiveMeasurementSystemImperialUS();
}

function checkLoadingSummaryDisclaimerAndLoadIfMissing() {
    var dfd = $.Deferred();

    if (config.loadingSummaryDisclaimer !== '') {
        dfd.resolve(true);
    } else {
        $.when(dataManager.loadProperties()).done(function () {
            if (config.loadingSummaryDisclaimer !== '') {
                dfd.resolve(true);
            } else {
                dfd.resolve(false);
            }
        });
    }
    return dfd.promise();
}

/**
    * Checks if config.SUMMARY_REPORT_OPTIONS.CONFIGURATION is available on active offer
    */
function isConfigurationReportOptionAvailable() {
    return isReportOptionAvailable(config.SUMMARY_REPORT_OPTIONS.CONFIGURATION);
}

/**
    * Checks if config.SUMMARY_REPORT_OPTIONS.SPECIFICATION is available on active offer
    */
function isSpecificationReportOptionAvailable() {
    return isReportOptionAvailable(config.SUMMARY_REPORT_OPTIONS.SPECIFICATION) && (globals.user.hasPermission(config.PERMISSIONS.SPECIFICATION.Code) || globals.user.allowSpecificationModule());
}

/**
    * Checks if config.SUMMARY_REPORT_OPTIONS.COSTING is available on active offer
    */
function isCostingReportOptionAvailable() {
    return isReportOptionAvailable(config.SUMMARY_REPORT_OPTIONS.COSTING) && globals.user.hasPermission(config.PERMISSIONS.COSTING.Code);
}

/**
    * Checks if config.SUMMARY_REPORT_OPTIONS.PERFORMANCE is available on active offer
    */
function isPerformanceReportOptionAvailable() {
    return isReportOptionAvailable(config.SUMMARY_REPORT_OPTIONS.PERFORMANCE) && globals.user.hasPermission(config.PERMISSIONS.PERFORMANCE.Code);
}

/**
    * Checks if report option is available on active offer
    * Returns boolean
    * @param {string} code - value from config.SUMMARY_REPORT_OPTIONS enum
    */
function isReportOptionAvailable(code) {
    return getSummaryReportOptionUsingCode(code) !== null;
}

/**
    * Returns report option object code if it exists
    * Return null if it doesn't
    * @param {string} code - value from config.SUMMARY_REPORT_OPTIONS enum
    */
function getSummaryReportOptionUsingCode(code) {
    var codeToUse = typeof code === 'object' ? code.Code : code;
    return getActiveOffer().getSummaryReportOptions().filter(function (item) {
        return item.getCode() === codeToUse;
    })[0] || null;
    // })[0] || 0;
}

/**
    * Returns report offer option object code if it exists
    * Return null if it doesn't
    * @param {string} reportOptionCode - value from config.SUMMARY_REPORT_OPTIONS enum
    * @param {string} reportOfferOptionCode - value from config.SUMMARY_REPORT_OFFER_OPTIONS enum
    */
function getSummaryReportOfferOptionUsingCode(reportOptionCode, reportOfferOptionCode) {
    var codeToUse = typeof reportOfferOptionCode === 'object' ? reportOfferOptionCode.Code : reportOfferOptionCode,
        reportOptionObject = getSummaryReportOptionUsingCode(reportOptionCode);
    if (reportOptionObject !== null) {
        return reportOptionObject.getSummaryReportOfferOptions().filter(function (item) {
            return item.getCode() === codeToUse;
        })[0] || null;
    } else {
        return null;
    }

}

/**
    * 
    * @param {object|string} code - value from config.SUMMARY_REPORT_OPTIONS enum
    */
function activateSummaryReportOptionUsingCode(code) {
    var reportOptionToUpdate = getSummaryReportOptionUsingCode(code);
    if (reportOptionToUpdate !== null) {
        reportOptionToUpdate.setIsChecked(true);
    }
    if (Array.isArray(code.Options) && reportOptionToUpdate !== null) {
        code.Options.forEach(function (offerOption) {
            activateSummaryReportOfferOptionUsingCode(reportOptionToUpdate, offerOption);
        });
    }
}

/**
    * 
    * @param {object} reportOption
    * @param {object} code
    */
function activateSummaryReportOfferOptionUsingCode(reportOption, code) {
    var reportOfferOptionToUpdate = reportOption.getSummaryReportOfferOptionUsingCode(code);
    if (reportOfferOptionToUpdate !== null) {
        reportOfferOptionToUpdate.setIsChecked(true);
    }
}

/**
    * 
    * @param {string} code - value from config.SUMMARY_REPORT_OPTIONS enum
    */
function deactivateSummaryReportOptionUsingCode(code) {
    var reportOptionToUpdate = getSummaryReportOptionUsingCode(code);
    if (reportOptionToUpdate !== null) {
        reportOptionToUpdate.setIsChecked(false);
    }
    if (Array.isArray(code.Options)) {
        code.Options.forEach(function (offerOption) {
            deactivateSummaryReportOfferOptionUsingCode(reportOptionToUpdate, offerOption);
        });
    }
}

/**
    * 
    * @param {object} reportOption
    * @param {object} code
    */
function deactivateSummaryReportOfferOptionUsingCode(reportOption, code) {
    var reportOfferOptionToUpdate = reportOption.getSummaryReportOfferOptionUsingCode(code);
    if (reportOfferOptionToUpdate !== null) {
        reportOfferOptionToUpdate.setIsChecked(false);
    }
}

/**
    * 
    * @param {boolean} updateAttrObvGroup
    */
function clearPerformanceReportOptionIfNecessary(updateAttrObvGroup) {
    var reportOptionToUpdate = getSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.PERFORMANCE);
    if (reportOptionToUpdate !== null && reportOptionToUpdate.getIsChecked() === true) {
        if (updateAttrObvGroup) {
            // Update attribute observable group
            getAttributeGroup(config.OBJECT_TYPES.REPORT_OPTIONS + '.' + config.SUMMARY_REPORT_OPTIONS.PERFORMANCE).summaryReportOption.value.value = false;
        }
        // clear local items
        clearLocalStorageItems();
    }
}

/**
    * 
    * @param {string[]} reportOptionCodesArray - Array containing string values from config.SUMMARY_REPORT_OPTIONS enum
    */
function activateSummaryReportOptionUsingList(reportOptionCodesArray) {
    reportOptionCodesArray.forEach(function (summaryReportOptionCode) {
        activateSummaryReportOptionUsingCode(summaryReportOptionCode);
    });
}

/**
    * Loop through report options and return true if at least one has getIsChecked === true else return false
    */
function isAtLeastOneReportOptionChecked() {
    var summaryReports = getActiveOffer().getSummaryReportOptions();
    for (var i = 0; i < summaryReports.length; i++) {
        if (summaryReports[i].getIsChecked() === true) {
            return true;
        }
    }
    return false;
}

/**
    * 
    * @param {string} containerElementId - container identifier. Include #/. for id/class
    * @param {function} containerElementDimensionsCallback - function that returns height/width dimensions
    * @param {function} getDataCallback - function to populate list to display 
    * @param {string|number} fontSize - Size of font for labels. If using string include px eg. '10px'. Otherwise use number value eg. 10
    */
function createTotalCostOfOwnershipChart(containerElementId, containerElementDimensionsCallback, getDataCallback, fontSize, pm, fontFamily) {
    createStackedBarChart(containerElementId, containerElementDimensionsCallback, getDataCallback, createChartDataUrl, getTotalCostOfOwnershipD3AttrObject, fontSize, pm, fontFamily);
}

/**
    *
    * @param {string} containerElementId - container identifier. Include #/. for id/class
    * @param {function} getDataCallback - function to populate list to display
    * @param {string|number} fontSize - Size of font for labels. If using string include px eg. '10px'. Otherwise use number value eg. 10
    */
function updateTotalCostOfOwnershipChart(containerElementId, getDataCallback, fontSize, pm, fontFamily) {
    updateStackedBarChart(containerElementId, getDataCallback, createChartDataUrl, getTotalCostOfOwnershipD3AttrObject, fontSize, pm, fontFamily);
}

/**
    * 
    * @param {any} containerElementId
    * @param {any} containerElementDimensionsCallback
    * @param {any} getDataCallback
    * @param {any} postChartCreationCallback
    * @param {any} attrObject
    * @param {any} fontSize
    */
function createStackedBarChart(containerElementId, containerElementDimensionsCallback, getDataCallback, postChartCreationCallback, attrObject, fontSize, pm, fontFamily) {
    var w, h,
        heightCallbackObj = typeof containerElementDimensionsCallback === 'function' ? containerElementDimensionsCallback(containerElementId) : null;

    if (heightCallbackObj !== null) {
        w = heightCallbackObj.width;
        h = heightCallbackObj.height;
    } else {
        w = $(containerElementId).width();
        h = $(containerElementId).height();
    }

    stackedBarChartSvg = d3.select(containerElementId)
        .append("svg")
        .attr("width", w)
        .attr("height", h)
        .attr("id", "svg-to-use");

    updateStackedBarChart(containerElementId, getDataCallback, postChartCreationCallback, attrObject, fontSize, pm, fontFamily);
}

/**
 * @param {any} getDataCallback
 * @param {any} postChartCreationCallback
 * @param {any} attrObject
 * @param {any} fontSize
 * @param {Promise} pm
 * @param {string} fontFamily
 * 
 */
function updateStackedBarChart(containerElementId, getDataCallback, postChartCreationCallback, attrObject, fontSize, pm, fontFamily) {
    var dataToChart = getDataCallback();
    var attrObjectToUse = typeof attrObject === 'function' ? attrObject(containerElementId, getDataCallback, fontSize, fontFamily) : attrObject;
    var rects = stackedBarChartSvg.selectAll("rect").remove().exit().data(dataToChart);
    var text = stackedBarChartSvg.selectAll("text").remove().exit().data(dataToChart);
    var labelTest = stackedBarChartSvg.selectAll('.label').remove().exit().data(dataToChart);

    rects
        .enter()
        .append("rect")
        .attrs(attrObjectToUse.d3BarChartAttrObject);

    var label = stackedBarChartSvg.selectAll('.label')
        .data(dataToChart)
        .enter()
        .append('g')
        .attrs(attrObjectToUse.d3LabelGroupAttrObject);
    //.attr('class', 'label')
    //.attr('transform', function (d, i) {
    //    var dataRect = document.getElementById('chart-rect-' + d.id).getBBox(),
    //        xPos, yPos;
    //    yPos = dataRect.y + (dataRect.height / 2)
    //    if (i % 2 === 0) {
    //        // left
    //        xPos = 100;
    //        return "translate(" + xPos + ", " + yPos + ")"
    //    } else {
    //        // right
    //        //return "translate(100, 20)"
    //        xPos = dataRect.x + dataRect.width;
    //        return "translate(" + xPos + ", " + yPos + ")"
    //    }
    //})
    //.attr('x', 500)
    //.attr('y', function (d) { return d.value; })
    //.attr('id', function (d) { return 'label-container-g-' + d.id; });


    label.append('text')
        .text(function (d) {
            return d.text;
        })
        .attrs(attrObjectToUse.d3LabelTransparentTextAttrObject);
    //.attr('fill', 'transparent')
    //.attr('id', function (d) { return 'tr-label-' + d.id; })
    //.attr('x', 0)
    //.attr('y', 0)
    //.attr('font-size', fontSize);

    label.append('rect')
        .attrs(attrObjectToUse.d3LabelRectAttrObject);
    //.attr('x', function (d, i) {
    //    if (i % 2 === 0) {
    //        return 0;
    //    } else {
    //        return 10;
    //    }
    //})
    //.attr('y', 0)
    //.attr('fill', 'white')
    //.attr('stroke', '#b3b4b6')
    //.attr('height', function (d) {
    //    var textElement = document.getElementById('tr-label-' + d.id);
    //    return textElement.getBBox().height + 12;
    //})
    //.attr('width', function (d) {
    //    var textElement = document.getElementById('tr-label-' + d.id);
    //    return textElement.getBBox().width + 20;
    //})
    //.attr('id', function (d) { return 'tr-label-rect-' + d.id; });


    label.append('polygon')
        .attrs(attrObjectToUse.d3LabelPolygonAttrObject);
    //.attr('fill', '#b3b4b6')
    //.attr('stroke', '#b3b4b6')
    //.attr('stroke-width', 1)
    ////.attr('points', "20, 10 20, 0 0, 5 ")
    //.attr('points', function (d, i) {
    //    var rectElement = document.getElementById('tr-label-rect-' + d.id),
    //        xPos = rectElement.getBBox().x + rectElement.getBBox().width,
    //        rectMiddleYPos = rectElement.getBBox().height / 2,
    //        arrowTopYPos = rectMiddleYPos - 5,
    //        arrowBottomYPos = rectMiddleYPos + 5,
    //        pointX = xPos + 10,
    //        rectHeight = rectElement.getBBox().height;
    //    if (i % 2 === 0) {
    //        //return "0, 0 0, 8 16, 4 ";
    //        return xPos + "," + arrowTopYPos + " " + xPos + "," + arrowBottomYPos + " " + pointX + "," + rectMiddleYPos;
    //    } else {
    //        //return "20, 10 20, 0 0, 5 ";
    //        xPos = rectElement.getBBox().x;
    //        pointX = xPos - 10;
    //        return xPos + "," + arrowTopYPos + " " + xPos + "," + arrowBottomYPos + " " + pointX + "," + rectMiddleYPos;
    //    }
    //})
    //.attr('id', function (d) { return 'label-arrow' + d.id; })
    //.attr('x', function (d, i) {
    //    var rectElement = document.getElementById('tr-label-rect-' + d.id);
    //    return 12 + rectElement.getBBox().x;
    //});

    label.append('text')
        .text(function (d) {
            return d.text;
        })
        .attrs(attrObjectToUse.d3LabelTextAttrObject);
    //.attr('fill', '#444444')
    //.attr('x', function (d) {
    //    var rectElement = document.getElementById('tr-label-rect-' + d.id);
    //    return 12 + rectElement.getBBox().x;
    //})
    //.attr('y', function (d, i) {
    //    // reposition g on left depending on length
    //    var gElement = document.getElementById('label-container-g-' + d.id).getBBox(),
    //        dataRect = document.getElementById('chart-rect-' + d.id).getBBox(),
    //        trElement = document.getElementById('tr-label-' + d.id).getBBox(),
    //        containerRectElement = document.getElementById('tr-label-rect-' + d.id).getBBox();
    //    if (i % 2 === 0) {
    //        var xPos = dataRect.x - gElement.width,
    //            yPos = dataRect.y + (dataRect.height / 2) - gElement.height + containerRectElement.height;
    //        document.getElementById('label-container-g-' + d.id).setAttribute('transform', 'translate(' + xPos + ',' + yPos + ')');
    //    } else {
    //        var xPos = dataRect.x + dataRect.width,
    //            yPos = dataRect.y + (dataRect.height / 2) - gElement.height + containerRectElement.height
    //        document.getElementById('label-container-g-' + d.id).setAttribute('transform', 'translate(' + xPos + ',' + yPos + ')');
    //    }

    //    // position text in label
    //    var rectElement = document.getElementById('tr-label-rect-' + d.id).getBBox(),
    //        textElement = document.getElementById('tr-label-' + d.id).getBBox(),
    //        bottomOfRect = rectElement.height,
    //        rectMiddle = bottomOfRect / 2,
    //        textMiddle = textElement.height / 2;
    //    return rectElement.y + textElement.height + 3;
    //})
    //.attr('font-size', fontSize);

    if (typeof postChartCreationCallback === 'function') {
        var elementIdToUse = containerElementId !== ".bar-chart-container" ? containerElementId : null;
        postChartCreationCallback(pm, setCostingChartDrawing, 'svg-to-use', elementIdToUse);
    }
}

function getTotalCostOfOwnershipD3AttrObject(containerElementId, getDataCallback, fontSize, fontFamily) {
    //#region Internal Functions
    function getBarWidth(containerElementId) {
        var costingChartDivWidth = containerElementId === ".bar-chart-container" ? $(".chart-container").width() : $(containerElementId).width(),
            barWidth = 50;

        if (costingChartDivWidth !== 300) {
            if (costingChartDivWidth > 325) {
                barWidth = 70;
            }

            if (costingChartDivWidth > 375) {
                barWidth = 90;
            }

            if (costingChartDivWidth > 425) {
                barWidth = 110;
            }

            if (costingChartDivWidth > 475) {
                barWidth = 130;
            }

            if (costingChartDivWidth > 500) {
                barWidth = 150;
            }

            if (costingChartDivWidth > 750) {
                barWidth = 200;
            }
        }
        return barWidth;
    }

    function returnHeightAdjustment() {
        //var h = $(".bar-chart-container").height();
        var h = $(containerElementId).height();
        return h / 100;
    }
    //#endregion Internal Functions
    var getData = getDataCallback;
    var fontSizeToUse = fontSize !== undefined ? typeof fontSize === 'number' ? fontSize + 'px' : fontSize : '10px';
    var fontFamilyToUse = fontFamily !== undefined ? fontFamily : '"Helvetica Neue", Helvetica, Arial, sans-serif'; // User Story 17565
    var d3BarChartAttrObject = {
        x: function (d, i) { // i= index, d = dataset
            var w = $(containerElementId).width();
            return (w / 2) - 25;
        },
        y: function (d, i) {
            var heightOfPreviousValues = 0;
            var w = $(containerElementId).width(),
                h = $(containerElementId).height() - 10;
            var containerXPosition = $(containerElementId).position().left,
                rectPositionLeft = $($("rect")[0]).offset().left,
                rectPositionRight = rectPositionLeft + getBarWidth(containerElementId);
            for (var j = 0; j < i; j++) {
                heightOfPreviousValues += (getData()[j].value * returnHeightAdjustment());
            }

            var adjustToLeftLabelPostionToContainerLeft = containerXPosition;
            var newLeftPosition;

            if ($('#' + d.id + '-label').hasClass('right')) {
                newLeftPosition = (w / 2) + 25 + containerXPosition;
            } else {
                newLeftPosition = (w / 2) - 50 + containerXPosition;
            }

            return h - (d.value * returnHeightAdjustment()) - heightOfPreviousValues;
        },
        //width:  w / dataset.length - padding,
        width: function () {
            return getBarWidth(containerElementId);
        },
        height: function (d, i) {
            return d.value * returnHeightAdjustment();
        },
        id: function (d) {
            return 'chart-rect-' + d.id;
        },
        fill: function (d, i) {
            return d.colour;
        }
    };
    var d3LabelGroupAttrObject = {
        x: 500,
        y: function (d) {
            return d.value;
        },
        id: function (d) {
            return 'label-container-g-' + d.id;
        },
        class: 'label',
        transform: function (d, i) {
            var dataRect = document.getElementById('chart-rect-' + d.id).getBBox(),
                xPos, yPos;
            yPos = dataRect.y + (dataRect.height / 2)
            if (i % 2 === 0) {
                // left
                xPos = 100;
                return "translate(" + xPos + ", " + yPos + ")"
            } else {
                // right
                //return "translate(100, 20)"
                xPos = dataRect.x + dataRect.width;
                return "translate(" + xPos + ", " + yPos + ")"
            }
        }
    };
    var d3LabelTransparentTextAttrObject = {
        x: 0,
        y: 0,
        id: function (d) {
            return 'tr-label-' + d.id;
        },
        fill: 'transparent',
        'font-size': fontSizeToUse,
        'font-family': fontFamilyToUse
    }
    var d3LabelRectAttrObject = {
        x: function (d, i) {
            if (i % 2 === 0) {
                return 0;
            } else {
                return 10;
            }
        },
        y: 0,
        fill: 'white',
        stroke: '#b3b4b6',
        height: function (d) {
            var textElement = document.getElementById('tr-label-' + d.id);
            return textElement.getBBox().height + 12;
        },
        width: function (d) {
            var textElement = document.getElementById('tr-label-' + d.id);
            return textElement.getBBox().width + 20;
        },
        id: function (d) {
            return 'tr-label-rect-' + d.id;
        }
    };
    var d3LabelPolygonAttrObject = {
        x: function (d, i) {
            var rectElement = document.getElementById('tr-label-rect-' + d.id);
            return 12 + rectElement.getBBox().x;
        },
        id: function (d) {
            return 'label-arrow' + d.id;
        },
        fill: '#b3b4b6',
        stroke: '#b3b4b6',
        points: function (d, i) {
            var rectElement = document.getElementById('tr-label-rect-' + d.id),
                xPos = rectElement.getBBox().x + rectElement.getBBox().width,
                rectMiddleYPos = rectElement.getBBox().height / 2,
                arrowTopYPos = rectMiddleYPos - 5,
                arrowBottomYPos = rectMiddleYPos + 5,
                pointX = xPos + 10,
                rectHeight = rectElement.getBBox().height;
            if (i % 2 === 0) {
                //return "0, 0 0, 8 16, 4 ";
                return xPos + "," + arrowTopYPos + " " + xPos + "," + arrowBottomYPos + " " + pointX + "," + rectMiddleYPos;
            } else {
                //return "20, 10 20, 0 0, 5 ";
                xPos = rectElement.getBBox().x;
                pointX = xPos - 10;
                return xPos + "," + arrowTopYPos + " " + xPos + "," + arrowBottomYPos + " " + pointX + "," + rectMiddleYPos;
            }
        }
    };
    var d3LabelTextAttrObject = {
        x: function (d) {
            var rectElement = document.getElementById('tr-label-rect-' + d.id);
            return 12 + rectElement.getBBox().x;
        },
        y: function (d, i) {
            var xPos, yPos;
            // reposition g on left depending on length
            var gElement = document.getElementById('label-container-g-' + d.id).getBBox(),
                dataRect = document.getElementById('chart-rect-' + d.id).getBBox(),
                trElement = document.getElementById('tr-label-' + d.id).getBBox(),
                containerRectElement = document.getElementById('tr-label-rect-' + d.id).getBBox();
            if (i % 2 === 0) {
                xPos = dataRect.x - gElement.width,
                    yPos = dataRect.y + (dataRect.height / 2) - gElement.height + containerRectElement.height;
                document.getElementById('label-container-g-' + d.id).setAttribute('transform', 'translate(' + xPos + ',' + yPos + ')');
            } else {
                xPos = dataRect.x + dataRect.width,
                    yPos = dataRect.y + (dataRect.height / 2) - gElement.height + containerRectElement.height
                document.getElementById('label-container-g-' + d.id).setAttribute('transform', 'translate(' + xPos + ',' + yPos + ')');
            }

            // position text in label
            var rectElement = document.getElementById('tr-label-rect-' + d.id).getBBox(),
                textElement = document.getElementById('tr-label-' + d.id).getBBox(),
                bottomOfRect = rectElement.height,
                rectMiddle = bottomOfRect / 2,
                textMiddle = textElement.height / 2;
            return rectElement.y + textElement.height + 3;
        },
        fill: '#444444',
        'font-size': fontSizeToUse,
        'font-family': fontFamilyToUse
    };

    return {
        d3BarChartAttrObject: d3BarChartAttrObject,
        d3LabelGroupAttrObject: d3LabelGroupAttrObject,
        d3LabelTransparentTextAttrObject: d3LabelTransparentTextAttrObject,
        d3LabelRectAttrObject: d3LabelRectAttrObject,
        d3LabelPolygonAttrObject: d3LabelPolygonAttrObject,
        d3LabelTextAttrObject: d3LabelTextAttrObject
    }
}

function createCostingChartUsingPromise() {
    var pm = $.Deferred();

    var costingReportOption = getSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.COSTING),
        displayCostingReport = costingReportOption !== null ? costingReportOption.getIsChecked() : false;

    setCostingChartDrawing(null);
    if (isCostingReportOptionAvailable() === true && costingReportOption !== null && costingReportOption.getIsChecked()) {
        if (globals.getBrowser() !== config.BROWSER_NAME.INTERNETEXPLORER) {
            createTotalCostOfOwnershipChart('#costing-chart-creation-div', null, getCostingTotalCostOfOwnershipArray, '20px', pm, '"Roboto-Regular","Helvetica Neue", Helvetica, Arial, sans-serif')
        } else {
            pm.resolve({});
        }
    } else {
        pm.resolve({});
    }

    return pm.promise();
}

function createAltitudeChartUsingPromise() {
    var pm = $.Deferred();

    var performanceReportOption = getSummaryReportOptionUsingCode(config.SUMMARY_REPORT_OPTIONS.PERFORMANCE),
        displayPerformanceReport = performanceReportOption !== null ? performanceReportOption.getIsChecked() : false;

    setPerformanceChartDrawing(null);
    if (performanceReportOption !== null && performanceReportOption.getIsChecked()) {
        if (globals.getBrowser() !== config.BROWSER_NAME.INTERNETEXPLORER) {
            //createAltitudeChart('#performance-chart-creation-div', null, getCostingTotalCostOfOwnershipArray, '20px', pm, '"Roboto-Regular","Helvetica Neue", Helvetica, Arial, sans-serif')
            createAltitudeChart('#altitude-chart-creation-div', getActiveOffer().getPerformance().getAltitudeProfileEntriesArray(), pm, createChartDataUrl)
        } else {
            pm.resolve({});
        }
    } else {
        pm.resolve({});
    }

    return pm.promise();
}

/**
    * 
    * @param {string} containerId
    * @param {Array} altitudeProfileArray
    * @param {Promise} pm
    * @param {}
    */
function createAltitudeChart(containerId, altitudeProfileArray, pm, postChartCreationCallback) {
    var fontSize = 18,
        fontSizeAltitude = fontSize + 4;

    // gridlines in x axis function
    function make_x_gridlines() {
        return d3.axisBottom(x)
            .ticks(numberOfTicksOnXAxis)
    }

    // gridlines in y axis function
    function make_y_gridlines() {
        return d3.axisLeft(y)
            .ticks(numberOfTicksOnYAxis)
    }

    function positionBoxAroundText(gElement, i) {
        // https://bl.ocks.org/mbostock/1160929
        // http://bl.ocks.org/andreaskoller/7674031
        var textBBox,
            paddingLeftRight = 18, // adjust the padding values depending on font and font size
            paddingTopBottom = 5,
            containerRect;

        gElement.selectAll("text").remove();
        gElement.selectAll("rect").remove();
        
        gElement.append("text")
            .text(parseInt(data[i].Altitude * getMeasurementSystemToUse().altitudeConversion) + getMeasurementSystemToUse().unitAltitude)
            .attr("text-anchor", "middle")
            .attr("font-size", fontSize + "px")
            .attr("x", "-3em")
            .attr("y", "0.25em");

        gElement.append("rect")
            .attr("class", "text-container-rect");

        textBBox = gElement.select("text").node().getBBox();
        containerRect = gElement.select(".text-container-rect");

        containerRect.attr("x", textBBox.x - (paddingLeftRight / 2))
            .attr("y", textBBox.y - (paddingTopBottom / 2))
            .attr("width", textBBox.width + paddingLeftRight)
            .attr("height", textBBox.height + paddingTopBottom);
        
        gElement.append("text")
            .text(parseInt(data[i].Altitude * getMeasurementSystemToUse().altitudeConversion) + getMeasurementSystemToUse().unitAltitude)
            .attr("text-anchor", "middle")
            .attr("font-size", fontSize + "px")
            .attr("x", "-3em")
            .attr("dy", "0.25em");
    }

    function bisectDistanceFunction(d) {
        return d.Distance * offerManager.getMeasurementSystemToUse().distanceConversion;
    }

    if (altitudeProfileArray.length > 0) {
        // Area Chart Example with Grid
        // https://bl.ocks.org/d3noob/c506ac45617cf9ed39337f99f8511218

        // Chart with Axis labels
        // https://bl.ocks.org/d3noob/23e42c8f67210ac6c678db2cd07a747e

        // Array structure is Altitude, Distance, Latitude, Longitude
        // x value will be Distance
        // y value will be Altitude
        // offerManager.getActiveOffer().getPerformance().getAltitudeProfileEntriesArray()
        $(containerId).show();

        var container = $(containerId),
            margin = {
                top: 10,
                right: 0,
                bottom: 23,
                left: 65
            },
            width = container.width() - margin.left - margin.right,
            height = container.height() - margin.top - margin.bottom,
            bisectData = d3.bisector(bisectDistanceFunction).left,
            numberOfTicksOnXAxis = 3,
            numberOfTicksOnYAxis = 2,
            hoverWaypointCount = 0,
            allowFirstWaypointToBeDisplayed = true;

        //createHoverLayer();
        //createClickLayer();

        // set the ranges
        var x = d3.scaleLinear().range([0, width]);
        var y = d3.scaleLinear().range([height, 0]);
        
        // define the area
        var areaDistance = 0;
        var area = d3.area()
            .x(function (d, index) {
                return x(d.Distance * getMeasurementSystemToUse().distanceConversion);
            })
            .y0(height)
            .y1(function (d) {
                return y(d.Altitude * getMeasurementSystemToUse().altitudeConversion);
            });

        // define the line
        var valueline = d3.line()
            //.x(function(d, index) { return x(index); })
            .x(function (d, index) {
                return x(index);
            })
            .y(function (d) {
                return y(d.Altitude);
            });

        // append the svg obgect to the body of the page
        // appends a 'group' element to 'svg'
        // moves the 'group' element to the top left margin
        var svgIdToUse = 'performance-svg-id';
        var svg = d3.select(containerId).append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .attr("id", svgIdToUse)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        var data = altitudeProfileArray;

        // scale the range of the data
        var distance = 0;
        x.domain(d3.extent(data, function (d, index) {
            return d.Distance * getMeasurementSystemToUse().distanceConversion;
        }));
        y.domain([0, d3.max(data, function (d) {
            return d.Altitude * getMeasurementSystemToUse().altitudeConversion;
        })]);

        // add the area
        svg.append("path")
            .data([data])
            .attr("class", "altitude-area")
            .attr("d", area);

        // add the X gridlines
        svg.append("g")
            .attr("class", "altitude-grid")
            .attr("transform", "translate(0," + height + ")")
            .call(make_x_gridlines()
                .tickSize(-height)
                .tickFormat("")
            )

        // add the Y gridlines
        svg.append("g")
            .attr("class", "altitude-grid")
            .call(make_y_gridlines()
                .tickSize(-width)
                .tickFormat("")
            )

        // add the X Axis
        svg.append("g")
            .attr("transform", "translate(0," + height + ")")
            .attr("class", "axis")
            .call(d3.axisBottom(x)
                .ticks(numberOfTicksOnXAxis)
                .tickFormat(function (d) {
                    return d + " " + offerManager.getMeasurementSystemToUse().unitDistance;
                }))
            .attr("font-size", "18");
        
        // add the Y Axis
        svg.append("g")
            .attr("class", "axis")
            .attr("font-size", "18")
            .call(d3.axisLeft(y)
                .ticks(numberOfTicksOnYAxis)
                .tickFormat(function (d) {
                    return d + " " + offerManager.getMeasurementSystemToUse().unitAltitude;
                }))
            .attr("font-size", "18");
    }

    if (typeof postChartCreationCallback === 'function') {
        postChartCreationCallback(pm, setPerformanceChartDrawing, svgIdToUse, containerId);
    }
}

/**
    * 
    * @param {Promise} pm
    * @param {function} setterToUse
    * @param {string} idToGet
    * @param {string} containerId
    */
function createChartDataUrl(pm, setterToUse, idToGet, containerId) {
    var options = globals.getBrowser() === config.BROWSER_NAME.INTERNETEXPLORER ? {
        // canvg: canvg,
        backgroundColor: 'white'
    } : {};
    try {
        svgAsPngUri(document.getElementById(idToGet), options, function (uri) {
            TScMessenger.writeDebugMessage('OfferManager.js, createChartDataUrl, new uri');
            setterToUse(uri);

            if (typeof containerId === 'string') {
                // Remove svg elements from the DOM
                //d3.select(containerId).selectAll('svg').remove();
            }

            if (pm !== undefined) {
                pm.resolve({});
            }
        });
    } catch (e) {
        TScMessenger.writeDebugMessage('OfferManager.js, createChartDataUrl, error');
        if (pm !== undefined) {
            pm.resolve({});
        }
    }
}

function getCostingTotalCostOfOwnershipArray() {
    var costing = offerManager.getActiveOffer().getCosting();
    var dataset = [];

    if (costing.getTollFeesCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id) > 0) {
        dataset.push({
            id: "toll-fees",
            text: offerManager.tollFeesCostPercentageOfTotalFormattedComp().value,
            value: costing.getTollFeesCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id),
            colour: "#FFC71C"
        });
    }
    if (costing.getMaintenanceCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id) > 0) {
        dataset.push({
            id: "maintenance",
            text: offerManager.maintenanceCostPercentageOfTotalFormattedComp().value,
            value: costing.getMaintenanceCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id),
            colour: "#FBA405"
        });
    }
    if (costing.getTyreCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id) > 0) {
        dataset.push({
            id: "tyres",
            text: offerManager.tyreCostPercentageOfTotalFormattedComp().value,
            value: costing.getTyreCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id),
            colour: "#739B17"
        });
    }
    if (costing.getFuelCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id) > 0) {
        dataset.push({
            id: "fuel",
            text: offerManager.fuelCostPercentageOfTotalFomattedComp().value,
            value: costing.getFuelCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id),
            colour: "#8DBC1E"
        });
    }
    if (costing.getOverheadsCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id) > 0) {
        dataset.push({
            id: "overheads",
            text: offerManager.overheadsCostPercentageOfTotalFormattedComp().value,
            value: costing.getOverheadsCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id),
            colour: "#49D28E"
        });
    }
    if (costing.getLicenceFeesCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id) > 0) {
        dataset.push({
            id: "licence-fees",
            text: offerManager.licenceFeesCostPercentageOfTotalFormattedComp().value,
            value: costing.getLicenceFeesCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id),
            colour: "#5BDBC2"
        });
    }
    if (costing.getInsuranceCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id) > 0) {
        dataset.push({
            id: "insurance",
            text: offerManager.insuranceCostPercentageOfTotalFormattedComp().value,
            value: costing.getInsuranceCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id),
            colour: "#8EE5D5"
        });
    }
    if (costing.getCrewCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id) > 0) {
        dataset.push({
            id: "crew",
            text: offerManager.crewCostPercentageOfTotalFormattedComp().value,
            value: costing.getCrewCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id),
            colour: "#4990E2"
        });
    }
    if (costing.getFinanceCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id) > 0) {
        dataset.push({
            id: "finance",
            text: offerManager.financeCostPercentageOfTotalFormattedComp().value,
            value: costing.getFinanceCostPercentageOfTotal(globals.user.getActiveMeasurementSystem().id),
            colour: "#84B3EB"
        });
    }

    return dataset;
}

function getTyreRateValueFromTyreDetail() {
    // Update the tyre rate from axles
    var costPerKmValue = 0;
    getConfiguration().getAxles().forEach(function (axle) {
        costPerKmValue += axle.getCostPerKm();
    });
    if (getActiveOffer().getRig().getTrailer1() !== null) {
        getActiveOffer().getRig().getTrailer1().getAxlesHolder().getAxles().forEach(function (axle) {
            costPerKmValue += axle.getCostPerKm();
        });
    }
    if (getActiveOffer().getRig().getTrailer2() !== null) {
        getActiveOffer().getRig().getTrailer2().getAxlesHolder().getAxles().forEach(function (axle) {
            costPerKmValue += axle.getCostPerKm();
        });
    }
    return costPerKmValue;
}

function isDetailedPayloadAvailableOnRig() {
    var returnValue = false;
    if (getActiveOffer().getRig().getVehicle().getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED && getActiveOffer().getRig().getVehicle().getPayloadHolder().getDetailedPayloadItems().length > 0) {
        returnValue = true;
    } else if (getActiveOffer().getRig().getTrailer1() !== null && getActiveOffer().getRig().getTrailer1().getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED && getActiveOffer().getRig().getTrailer1().getPayloadHolder().getDetailedPayloadItems().length > 0) {
        returnValue = true;
    } else if (getActiveOffer().getRig().getTrailer2() !== null && getActiveOffer().getRig().getTrailer2().getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED && getActiveOffer().getRig().getTrailer2().getPayloadHolder().getDetailedPayloadItems().length > 0) {
        returnValue = true;
    }
    return returnValue;
}

function displayLicenceDetailButtonOnCosting() {
    //return globals.user.getSpecifyLicencingRegionAs() > 0 || globals.getFilteredLicenceCategoriesArray(offerManager.getActiveOffer().getCosting().getLicenceCategories(), 'VEHICLE').length > 1;
    return true;
}

function getAverageFuelValue(value) {
    var convertedValue = value;
    if (isActiveMeasurementSystemMetric() && globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME && value !== 0) {
        convertedValue = 100 / convertedValue;
    }
    return convertedValue;
}

/**
    * Determines whether the tolls & permit detail button should be displayed on costing
    * @returns {boolean}
        * */
function displayTollFeesDetailOnCosting() {
    return globals.user.displayPerformance() && Array.isArray(globals.user.getTolls()) && globals.user.getTolls().length > 0 && getActiveOffer().getPerformance() !== undefined && getActiveOffer().getPerformance() !== null  && globals.user.hasPermission(config.PERMISSIONS.PERFORMANCE.Code);
}

/**
    * Check overrride on Costing otherwise return the generated value
    * @returns {number} - Vehicle class (1-4?)
        * */
function getVehicleClassId() {
    //return 1;
    return rigOps.getVehicleClassBasedOnRigConfiguration();
}

/**
    * Gets the licenceFeesMessageArray
    * @returns {object[]}
    */
function getLicenceFeesMessageArray() {
    return licenceFeesMessageArray;
}

/**
    * Updates the licenceFeesMessageArray variable
    * @param {object|object[]} newValue 
    */
function setLicenceFeesMessageArray(newValue) {
    if(Array.isArray(newValue)) {
        licenceFeesMessageArray = newValue;
    } else {
        licenceFeesMessageArray.push(newValue);
    }
}

/**
    * Creates object and adds it to the licenceFeesMessageArray
    * @param {string} chassisObjectType - From CHASSIS_OBJECT_TYPES enum
    * @param {number} valueFromLicenceFee - Value from licence fee
    * @param {number} valueSavedOnOffer - Value that was saved by user on database
    */
function addItemToLicenceFeesMessageObject(chassisObjectType, valueFromLicenceFee, valueSavedOnOffer) {
    var licenceFeesObject = {};
    licenceFeesObject.chassisObjectType = chassisObjectType;
    licenceFeesObject.valueFromLicenceFee = valueFromLicenceFee;
    licenceFeesObject.valueSavedOnOffer = valueSavedOnOffer;

    setLicenceFeesMessageArray(licenceFeesObject);
}

/**
    * Clears the licenceFeesMessageArray
    */
function clearLicenceFeesMessageObject() {
    setLicenceFeesMessageArray([]);
}

/**
    * Removes an item from licenceFeesMessageArray using chassisObjectType
    * @param {string} chassisObjectType - from CHASSIS_OBJECT_TYPES enum
    */
function removeItemFromLicenceFeesMessageArray(chassisObjectType) {
    var indexToRemove = licenceFeesMessageArray.findIndex(function (item) {
        return item.chassisObjectType === chassisObjectType;
    })
    licenceFeesMessageArray.splice(indexToRemove, 1);
}

function getNotifyUserOfTollFeesChange() {
    return notifyUserOfTollFeesChange;
}

function setNotifyUserOfTollFeesChange(newValue) {
    notifyUserOfTollFeesChange = typeof newValue === 'boolean' ? newValue : false;
}

function getTollFeesFromUserRelatedToRoute() {
    var tollFeesFromUser = 0;
    getActiveOffer().getPerformance().getTolls().forEach(function (toll) {
        var tollToUse = globals.user.getTolls().find(function (userToll) { return userToll.Id === toll.getId(); });
        if (tollToUse !== undefined) {
            var tollFeeToUse = tollToUse.Fees.find(function (tollFee) { return tollFee.TollClassId === getActiveOffer().getCosting().getVehicleClassId(); });
            tollFeesFromUser += tollFeeToUse.Fee;
        }
    });  
    return tollFeesFromUser;
}


/**
    * Checks if additional notes will be displayed on report
    * @returns {boolean} - Returns true if additional notes to be displayed on report is not '' else false
    */
function displayAdditionalNotesSectionOnReport() {
    return getAdditionalNotesForReport() !== '';
}

/**
    * Get the additional notes to be displayed on report
    * @returns {string} - Returns string based on company additional notes and offer additional notes
    */
function getAdditionalNotesForReport() {
    var returnString = '',
        addNewLines = false;
        //hasCompanyAddtionalNoteText = false;

    //if (globals.user.getCompanyAdditionalNote() !== '') {
    //    hasCompanyAddtionalNoteText = true;
    //    returnString += globals.user.getCompanyAdditionalNote();
    //}

    //if(getActiveOffer().getAdditionalNote() !== '') {
    //    if (hasCompanyAddtionalNoteText) {
    //        returnString += '\n\n';
    //    }
    //    returnString += getActiveOffer().getAdditionalNote();
    //}

    if (getActiveOffer().getAdditionalNote() !== '') {                
        addNewLines = true;
        returnString += getActiveOffer().getAdditionalNote();
    }

    if (globals.user.getCompanyAdditionalNote() !== '') {                
        if (addNewLines) {
            returnString += '\n\n';
        }
        returnString += globals.user.getCompanyAdditionalNote();
    }

    return returnString;
}

function getPreventUpdateDuringAOGOptionIdWatch() {
    return preventUpdateDuringAOGOptionIdWatch;
}

function getFuelAndAdblueCostPerMonthMin() {
    return 0;
}
function getFuelAndAdblueCostPerMonthMax() {
    return 900000000;
}
/**
 * Returns the minimum value for fuel and adblue cost per month for the vehicle on the detail form
 * @returns {number} - Returns 0
 */
function getVehicleFuelAndAdblueCostPerMonthMin() {
    return 0;
}
/**
 * Returns the maximum value for fuel and adblue cost per month for the vehicle on the detail form
 * @returns {number} - Returns 500000000
 */
function getVehicleFuelAndAdblueCostPerMonthMax() {
    return 500000000;
}
/**
 * Returns the minimum value for fuel and adblue cost per month for trailer 1/2 on the detail form
 * @returns {number} - Returns 0
 */
function getTrailerFuelAndAdblueCostPerMonthMin() {
    return 0;
}
/**
 * Returns the maximum value for fuel and adblue cost per month for trailer 1/2 on the detail form
 * @returns {number} - Returns 200000000
 */
function getTrailerFuelAndAdblueCostPerMonthMax() {
    return 200000000;
}
/**
 * Returns the minimum value for fuel and adblue cost per month for the vehicle or trailer on the detail form
 * @param {string} mainAttrPath - Path to the element
 * @returns {number} - Returns the minimum value for fuel and adblue cost per month for the vehicle or trailer on the detail form
 */
function getFuelAndAdblueDetailCostPerMonthMin(mainAttrPath) {
    // return getActiveOffer().getCosting().getVehicleFuelAndAdblueCostPerMonthMin();
    if(mainAttrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) || mainAttrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
        return getTrailerFuelAndAdblueCostPerMonthMin();
    } else {
        return getVehicleFuelAndAdblueCostPerMonthMin();
    }
}
/**
 * Returns the maximum value for fuel and adblue cost per month for the vehicle or trailer on the detail form
 * @param {string} mainAttrPath - Path to the element
 * @returns {number} - Returns the maximum value for fuel and adblue cost per month for the vehicle or trailer on the detail form
 */
function getFuelAndAdblueDetailCostPerMonthMax(mainAttrPath) {
    if(mainAttrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1) || mainAttrPath.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
        return getTrailerFuelAndAdblueCostPerMonthMax();
    } else {
        return getVehicleFuelAndAdblueCostPerMonthMax();
    }
}
function getFuelAndAdblueDetailFuelConsumptionMin() {
    return getActiveOffer().getCosting().getFuelConsumptionMin();
}
function getFuelAndAdblueDetailFuelConsumptionMax() {
    return getActiveOffer().getCosting().getFuelConsumptionMax();
}
function getFuelAndAdblueDetailAdblueConsumptionMin() {
    return getActiveOffer().getCosting().getVehicleFuelAndAdblueAdblueConsumptionMin();
}
function getFuelAndAdblueDetailAdblueConsumptionMax() {
    return getActiveOffer().getCosting().getVehicleFuelAndAdblueAdblueConsumptionMax();
}
function getFuelAndAdblueDetailEquipmentFuelConsumptionMin() {
    return getActiveOffer().getCosting().getFuelConsumptionMin();
}
function getFuelAndAdblueDetailEquipmentFuelConsumptionMax() {
    return getActiveOffer().getCosting().getFuelConsumptionMax();
}
function getFuelAndAdblueDetailCostPerVolumeMin() {
    // return getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelCostPerVolumeMin();
    return globals.getFuelPricePerHourConvertedUsingMeasurementSystem(
        getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelCostPerVolumeMin(),
        getActiveOffer().getMeasurementSystem()
    );
}
function getFuelAndAdblueDetailCostPerVolumeMax() {
    // return getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelCostPerVolumeMax();
    return globals.getFuelPricePerHourConvertedUsingMeasurementSystem(
        getActiveOffer().getCosting().getVehicleFuelAndAdblueFuelCostPerVolumeMax(),
        getActiveOffer().getMeasurementSystem()
    );
}
/**
 * Min value for utilisation is 0
 * @returns {number} - Returns 0 as min value for utilisation
 */
function getFuelAndAdblueDetailUtilisationMin() {
    return 0;
}
/**
 * Max value for utilisation is 744
 * @returns {number} - Returns 744 as max value for utilisation
 */
function getFuelAndAdblueDetailUtilisationMax() {
    return 744;
}

function displayTrailerFuelAndAdblueOnReport(fuelPrice, fuelConsumption, distance, totalCost, totalCostOverride) {
    if(totalCostOverride) {
        return false;
    } else {
        if (fuelPrice === 0 || fuelConsumption === 0 || distance === 0) {
            return false;
        }
        return (distance * fuelConsumption * fuelPrice) > 0;
    }
}

function setterForTyreRateOnAttributeObservableGroup(newVal) {
    var measurementSystemToCheck = getActiveOffer().getMeasurementSystem();
    if(measurementSystemToCheck.id !== globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.METRIC).id) {
        getActiveOffer().getCosting().setTyreRateAsCentsPerKm(newVal * measurementSystemToCheck.distanceConversion);
    } else {
        getActiveOffer().getCosting().setTyreRateAsCentsPerKm(newVal);
    }
    getActiveOffer().getCosting().setTyreRate(newVal);
    getActiveOffer().getCosting().setTyreRateSetUsingMeasurementSystemId(measurementSystemToCheck.id);
}

function setterForTotalRevenueOnAttributeObservableGroup(newVal) {
    var measurementSystemToCheck = getActiveOffer().getMeasurementSystem();
    if(measurementSystemToCheck.id !== globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.METRIC).id) {
        getActiveOffer().getCosting().setTotalRevenueAsCurrencyPerKm(newVal * measurementSystemToCheck.distanceConversion);
    } else {
        getActiveOffer().getCosting().setTotalRevenueAsCurrencyPerKm(newVal);
    }
    getActiveOffer().getCosting().setTotalRevenue(newVal);
    getActiveOffer().getCosting().setTotalRevenueSetUsingMeasurementSystemId(measurementSystemToCheck.id);
}

function updateRefreshCostingData(newVal) {
    if(isRef(refreshCostingData)) {
        refreshCostingData.value = newVal;
    }
}

function getPreventCostingTotalRevenueUpdate() {
    return preventCostingTotalRevenueUpdate;
}

function setPreventCostingTotalRevenueUpdate(newValue) {
    preventCostingTotalRevenueUpdate = newValue;
}

function getPreventToastFromBeingDisplayed() {
    return preventToastFromBeingDisplayed;
}

function setPreventToastFromBeingDisplayed(newValue) {
    preventToastFromBeingDisplayed = typeof newValue === 'boolean' ? newValue : false;
}

/**
 * Checks if the offer has a costing object and if the user has permission to view costing
 * @returns {boolean}
 */
function isCostingAvailableForOffer() {
    return getActiveOffer().getDataAvailability().ActiveCosting && getActiveOffer().getCosting() !== undefined && globals.user.hasPermission(config.PERMISSIONS.COSTING.Code);
}
