import { LogLevel, PublicClientApplication,InteractionRequiredAuthError } from '@azure/msal-browser';
import { msalConfig, loginRequest, silentRequest } from './authConfig';

const $ = window.$;
const TScMessenger = window.TScMessenger;

let signInType;
let accountId = "";

let myMSALObj = null;
let isAuthenticated = false;
function getAuthContext() {
    return myMSALObj;
}
let curMsalAuthOpDfd = null;
// Create the main myMSALObj instance
// configuration parameters are located at authConfig.js
function setupAndInitialiseMsalPublicClientApplication() {

    if(curMsalAuthOpDfd) {
        return curMsalAuthOpDfd.promise();
    }else {
        curMsalAuthOpDfd = $.Deferred();
    }
    if(!myMSALObj) {
        myMSALObj = new PublicClientApplication(msalConfig);
    }
    try {
        myMSALObj.initialize().then(() => {
            // Redirect: once login is successful and redirects with tokens, call Graph API
            myMSALObj.handleRedirectPromise()
                .then(handleResponse)
                // .then(function (){
                //     myMSALObj.acquireTokenSilent({ scopes: ["User.Read"]}).then(
                //         function (accessToken) {
                //             var breakHere = 0;
                //             // globals.user.updateUser({ securityToken: '', password: accessToken });
                //             // globals.user.setMicrosoftLoggedInUserAccessTokenAsPassword(accessToken);
                //             // activate();
                //             // compositionComplete();
                //         },
                //         function (data) {
                //             var breakHere = 0;
                //             // globals.user.updateUser({ securityToken: '' });
                //             // activate();
                //             // compositionComplete();
                //         }
                //     )
                // })
                .catch(err => {
                    console.error(err);
                    curMsalAuthOpDfd.reject(err);
                    curMsalAuthOpDfd = null;
                });
            
        }).catch((error) => {
            var breakHere = 0
        });
    }catch(ex) {
        var breakHere = 0
    }
    
    return curMsalAuthOpDfd.promise();
}

function handleResponse(resp) {
    TScMessenger.writeDebugMessage('auth.js, msalAuth handleResponse');
    if (resp !== null) {
        accountId = resp.account.homeAccountId;
        localStorage.setItem('loginHint', resp.account.idTokenClaims.login_hint);
        isAuthenticated = true;
        myMSALObj.setActiveAccount(resp.account);
        curMsalAuthOpDfd.resolve({
            msalObj: myMSALObj, 
            accountId: accountId, 
            email: resp.account.username, 
            accessToken: resp.accessToken,
            fullName: resp.account.name});
        curMsalAuthOpDfd = null;
    } else {
        // need to call getAccount here?
        const currentAccounts = myMSALObj.getAllAccounts();
        if (!currentAccounts || currentAccounts.length < 1 || currentAccounts.length > 1) {
            if(localStorage.getItem('loginHint')) {
                myMSALObj.ssoSilent(silentRequest)
                    .then(handleResponse)
                    .catch(() => {
                        signIn();
                    });
            } else {
                signIn();
            }
            
            // myMSALObj.acquireTokenSilent({ scopes: ["User.Read"]}).then(
            //     function (accessToken) {
            //         // globals.user.updateUser({ securityToken: '', password: accessToken });
            //         // globals.user.setMicrosoftLoggedInUserAccessTokenAsPassword(accessToken);
            //         // activate();
            //         // compositionComplete();
            //     },
            //     function (data) {
                    
            //         // globals.user.updateUser({ securityToken: '' });
            //         // activate();
            //         // compositionComplete();
            //     }
            // );
            // myMSALObj.loginRedirect(loginRequest).then(function (loginResponse) {
                
            //     myMSALObj.acquireTokenSilent(loginRequest).then(function (tokenResponse) {
            //       var breakHere = 0;
            //     });
            // });
        } else if (currentAccounts.length === 1) {
            // const activeAccount = currentAccounts[0];
            // myMSALObj.setActiveAccount(activeAccount);
            // accountId = activeAccount.homeAccountId;
            // // isAuthenticated = true;
            // localStorage.setItem('loginHint', activeAccount.idTokenClaims.login_hint);
            myMSALObj.acquireTokenSilent(loginRequest)
                .then(handleResponse)
                .catch(() => {
                    signIn();
                });
        }
    }
}

function signIn(internalLoginCall) {
    
    // if(!internalLoginCall) {
    //     if(curMsalAuthOpDfd) {
    //         return curMsalAuthOpDfd.promise();
    //     }else {
    //         curMsalAuthOpDfd = $.Deferred();
    //     }
    // }

    return myMSALObj.loginPopup(loginRequest)
        .then(handleResponse)
        .catch(function (error) {
            // console.log(error);
            curMsalAuthOpDfd.reject(error);
            curMsalAuthOpDfd = null;
        });
    
}

// function signOut(interactionType) {
//     const logoutRequest = {
//         account: myMSALObj.getAccountByHomeId(accountId)
//     };

//     if (interactionType === "popup") {
//         myMSALObj.logoutPopup(logoutRequest).then(() => {
//             window.location.reload();
//         });
//     } else {
//         myMSALObj.logoutRedirect(logoutRequest);
//     }
// }

// async function getTokenPopup(request, account) {
//     request.redirectUri = "/redirect"
//     return await myMSALObj
//         .acquireTokenSilent(request)
//         .catch(async (error) => {
//             console.log("silent token acquisition fails.");
//             if (error instanceof InteractionRequiredAuthError) {
//                 console.log("acquiring token using popup");
//                 return myMSALObj.acquireTokenPopup(request).catch((error) => {
//                     console.error(error);
//                 });
//             } else {
//                 console.error(error);
//             }
//         });
// }

// This function can be removed if you do not need to support IE
// async function getTokenRedirect(request, account) {
//     return await myMSALObj.acquireTokenSilent(request).catch(async (error) => {
//         console.log("silent token acquisition fails.");
//         if (error instanceof InteractionRequiredAuthError) {
//             // fallback to interaction when silent call fails
//             console.log("acquiring token using redirect");
//             myMSALObj.acquireTokenRedirect(request);
//         } else {
//             console.error(error);
//         }
//     });
// }

export {
    isAuthenticated,
    getAuthContext,
    setupAndInitialiseMsalPublicClientApplication,
    signIn
}