import config from '../../../services/config';
import globals from '../../../services/globals';
// import { config, globals } from '../../../services';
import Axle from '../../offer/configuration/model.axle';

var TScMessenger = window.TScMessenger;

var AxlesHolder = function (parent, frontAxleAverageTyreLife, rearAxleAverageTyreLife, secondsteerAverageTyreLife, pusherAxleAverageTyreLife, tagAxleAverageTyreLife, tyrePriceFistSet, tyrePriceRemainingSets) {

    var self = this;

    var _axles = [], _axleGroups = [], _parent = parent, _startingNumberForAxles = 0,
        _frontAxleAverageTyreLife = frontAxleAverageTyreLife, _rearAxleAverageTyreLife = rearAxleAverageTyreLife, _secondsteerAverageTyreLife = secondsteerAverageTyreLife,
        _pusherAxleAverageTyreLife = pusherAxleAverageTyreLife, _tagAxleAverageTyreLife = tagAxleAverageTyreLife, _tyrePriceFistSet = tyrePriceFistSet, _tyrePriceRemainingSets = tyrePriceRemainingSets;


    function getFrontAxleAverageTyreLife() {
        return _frontAxleAverageTyreLife;
    }
    function setFrontAxleAverageTyreLife(newValue) {
        _frontAxleAverageTyreLife = newValue;
    }
    function getSecondsteerAverageTyreLife() {
        return _secondsteerAverageTyreLife;
    }
    function setSecondsteerAverageTyreLife(newValue) {
        _secondsteerAverageTyreLife = newValue;
    }
    function getPusherAxleAverageTyreLife() {
        return _pusherAxleAverageTyreLife;
    }
    function setPusherAxleAverageTyreLife(newValue) {
        _pusherAxleAverageTyreLife = newValue;
    }
    function getRearAxleAverageTyreLife() {
        return _rearAxleAverageTyreLife;
    }
    function setRearAxleAverageTyreLife(newValue) {
        _rearAxleAverageTyreLife = newValue;
    }
    function getTagAxleAverageTyreLife() {
        return _tagAxleAverageTyreLife;
    }
    function setTagAxleAverageTyreLife(newValue) {
        _tagAxleAverageTyreLife = newValue;
    }
    function getTyrePriceFirstSet() {
        return _tyrePriceFistSet;
    }
    function setTyrePriceFistSet(newValue) {
        _tyrePriceFistSet = newValue;
    }
    function getTyrePriceRemainingSets() {
        return _tyrePriceRemainingSets;
    }
    function setTyrePriceRemainingSets(newValue) {
        _tyrePriceRemainingSets = newValue;
    }



    function getAxles() {
        return _axles;
    }

    function setAxles(newValue) {
        _axles = newValue;
    }

    /**
     * @returns {object[]} - Returns objects on _axleGroups array
     * */
    function getAxleGroups() {
        return _axleGroups;
    }
    function setAxleGroups(newValue) {
        _axleGroups = newValue;
    }

    function add(axle) {
        var isVehicle = _parent === config.CHASSIS_OBJECT_TYPES.VEHICLE ? true : false;
        axle.setNumber(_axles.length + 1);

        //'now we need to decide where to insert the new axle
        if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) {
            axle.setAverageLifeOfTyre(getSecondsteerAverageTyreLife());
            if (isVehicle) {
                axle.setAverageCostOfTyre(getTyrePriceFirstSet());
            } else {
                axle.setAverageCostOfTyre(getTyrePriceRemainingSets());
            }
            //'the second steer will always follow the front axle
            _axles.splice(1, 0, axle);
        } else if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
            axle.setAverageLifeOfTyre(getPusherAxleAverageTyreLife());
            axle.setAverageCostOfTyre(getTyrePriceRemainingSets());
            //'the pusher will always follow either a second steer or a front axle, as we are inserting from the left
            if (containsSecondSteerAxle()) {
                if (_axles.length >= 3 && _axles[2].getRaised()) {
                    //'if the existing last pusher is raised, also raise the one we are adding, overriding what the caller of this function requested
                    axle.setRaised(true);
                }
                _axles.splice(2, 0, axle);
            } else {
                //'there is no second steer, so it is the one immediately after the front
                if (_axles.length >= 2 && _axles[1].getRaised()) {
                    //'if the existing last pusher is raised, also raise the one we are adding, overriding what the caller of this function requested
                    axle.setRaised(true);
                }
                _axles.splice(1, 0, axle);
            }
        } else if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            axle.setAverageLifeOfTyre(getTagAxleAverageTyreLife());
            axle.setAverageCostOfTyre(getTyrePriceRemainingSets());
            if (_axles[_axles.length - 1].getRaised()) {
                //'if the existing last tag is raised, also raise the one we are adding, overriding what teh caller of this function requested
                axle.setRaised(true);
            }
            _axles.push(axle);
        } else if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT) {
            axle.setAverageLifeOfTyre(getFrontAxleAverageTyreLife());
            if (isVehicle) {
                axle.setAverageCostOfTyre(getTyrePriceFirstSet());
            } else {
                axle.setAverageCostOfTyre(getTyrePriceRemainingSets());
            }
            _axles.splice(0, 0, axle);
        } else {
            axle.setAverageLifeOfTyre(getRearAxleAverageTyreLife());
            axle.setAverageCostOfTyre(getTyrePriceRemainingSets());
            //'it has to be a rear, so add it on to the end before any tags
            var rearInsertIndex;

            for (var k = _axles.length - 1; k >= 0; k--) {
                if (_axles[k].getType() !== config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                    rearInsertIndex = k;
                    break;
                }
            }

            if (rearInsertIndex === _axles.length - 1) {
                _axles.push(axle);
            } else {
                _axles.splice(rearInsertIndex, 0, axle);
            }

        }

        //'need to reorder numbers
        resequence();

        if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) {
            addAxleToAxleGroup(axle, config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        }
        
        updateValuesOnAllAxleGroups();
        //_axles.push(axle);

        /*
            item.Number = MyBase.Count + 1
    AddHandler item.AxleChanged, AddressOf AxleChangedChildEventHandler
    AddHandler item.AxleRaiseToggled, AddressOf AxleRaiseToggledChildEventHandler
    AddHandler item.ManufacturerRatingChanged, AddressOf AxleManufacturerRatingChangedChildEventHandler

    'now we need to decide where to insert the new axle
    If item.Type = Web.VehicleAxlePositionTypes.SecondSteer Then
        'the second steer will always follow the front axle
        MyBase.Insert(1, item)
    ElseIf item.Type = Web.VehicleAxlePositionTypes.Pusher Then
        'the pusher will always follow either a second steer or a front axle, as we are inserting from the left
        If Me.ContainsSecondSteerAxle Then
            If Me.Count >= 3 AndAlso Me(2).Raised Then
                'if the existing last pusher is raised, also raise the one we are adding, overriding what the caller of this function requested
                item.Raised = True
            End If
            MyBase.Insert(2, item)
        Else
            'there is no second steer, so it is the one immediately after the front
            If Me.Count >= 2 AndAlso Me(1).Raised Then
                'if the existing last pusher is raised, also raise the one we are adding, overriding what the caller of this function requested
                item.Raised = True
            End If
            MyBase.Insert(1, item)
        End If
    ElseIf item.Type = Web.VehicleAxlePositionTypes.Tag Then
        If Me(Me.Count - 1).Raised Then
            'if the existing last tag is raised, also raise the one we are adding, overriding what teh caller of this function requested
            item.Raised = True
        End If
        MyBase.Add(item)
    ElseIf item.Type = Web.VehicleAxlePositionTypes.Front Then
        MyBase.Insert(0, item)
    Else
        'it has to be a rear, so add it on to the end before any tags
        Dim rearInsertIndex As Integer

        For k As Integer = Me.Count - 1 To 0 Step -1
            If Me(k).Type <> Web.VehicleAxlePositionTypes.Tag Then
                rearInsertIndex = k
                Exit For
            End If
        Next

        If rearInsertIndex = Me.Count - 1 Then
            MyBase.Add(item)
        Else
            MyBase.Insert(rearInsertIndex, item)
        End If

    End If

    'need to reorder numbers
    Resequence()

    RaisePropertyChanged("CanAddSecondSteer")
    RaisePropertyChanged("CanAddTag")
    RaisePropertyChanged("CanAddPusher")
    RaisePropertyChanged("CanAddAxle")

    RaiseEvent AxleChanged()
        */
    }

    function remove(axle) {
        var indexOfElementToRemove = _axles.map(function (item) { return item.getId(); }).indexOf(axle.getId());
        _axles.splice(indexOfElementToRemove, 1);
        resequence();
        // Check if axle exists on an axle group
        if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) {
            removeAxleFromAxleGroup(axle, config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        }
        updateValuesOnAllAxleGroups();
    }

    function resequence() {
        var axleNumber;
        if (_parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            axleNumber = 1;
        } else {
            axleNumber = _startingNumberForAxles + 1;
        }
        

        _axles.forEach(function (axle) {
            axle.setNumber(axleNumber);
            axleNumber++;
        });
    }

    function hasPusherOrTag() {
        var hasPusherOrTagAxle = false;
        _axles.forEach(function (axle) {
            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                hasPusherOrTagAxle = true;
            }
        });
        return hasPusherOrTagAxle;
    }

    function hasPreconfiguredPusherOrTag() {
        var hasPreconfiguredPusherOrTagAxle = false;
        _axles.forEach(function (axle) {
            if ((axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) && !axle.isAdded()) {
                hasPreconfiguredPusherOrTagAxle = true;
            }
        });
        return hasPreconfiguredPusherOrTagAxle;
    }

    function hasAddedPusherOrTag() {
        var hasAddedPusherOrTag = false;
        _axles.forEach(function (axle) {
            if ((axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) && axle.isAdded()) {
                hasAddedPusherOrTag = true;
            }
        });
        return hasAddedPusherOrTag;
    }

    function hasAddedPusherOrTagDown() {
        var hasAddedPusherOrTagDown = false;
        _axles.forEach(function (axle) {
            if ((axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) && axle.isAdded() && !axle.getRaised()) {
                hasAddedPusherOrTagDown = true;
            }
        });
        return hasAddedPusherOrTagDown;
    }

    function hasAddedAxle() {
        var hasAddedAxle = false;
        _axles.forEach(function (axle) {
            if (axle.isAdded()) {
                hasAddedAxle = true;
            }
        });
        return hasAddedAxle;
    }

    function hasPusher1() {
        var axle1Rear = getAxle1Rear();
        if (axle1Rear && axle1Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
            return true;
        }
        return false;
    }

    function getPusher1() {
        var tempPusher1 = getAxle1Rear();
        if (tempPusher1 !== null && tempPusher1.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
            return tempPusher1;
        }
        return null;
    }

    function hasPusher2() {
        var axle2Rear = getAxle2Rear();
        if (axle2Rear && axle2Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
            return true;
        }
        return false;
    }

    function getPusher2() {
        var tempPusher2 = getAxle2Rear();
        if (tempPusher2 !== null && tempPusher2.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
            return tempPusher2;
        }
        return null;
    }

    function hasPusher3() {
        var axle3Rear = getAxle3Rear();
        if (axle3Rear && axle3Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
            return true;
        }
        return false;
    }

    function getPusher3() {
        var tempPusher3 = getAxle3Rear();
        if (tempPusher3 !== null && tempPusher3.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
            return tempPusher3;
        }
        return null;
    }

    function hasTag1() {
        var numAxleBeforeTag = getNumAxlesOnVehicleBeforeTags();
        var axleNumToGet = numAxleBeforeTag + 1;
        var tagAxle = getAxleByNumber(axleNumToGet);
        
        if (tagAxle && tagAxle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            return true;
        }
        return false;
    }

    function getTag1() {
        var numAxleBeforeTag = getNumAxlesOnVehicleBeforeTags();
        var axleNumToGet = numAxleBeforeTag + 1;
        var tagAxle = getAxleByNumber(axleNumToGet);

        if (tagAxle && tagAxle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            return tagAxle;
        }
        return null;
    }

    function hasTag2() {
        var numAxleBeforeTag = getNumAxlesOnVehicleBeforeTags();
        var axleNumToGet = numAxleBeforeTag + 2;
        var tagAxle = getAxleByNumber(axleNumToGet);

        if (tagAxle && tagAxle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            return true;
        }
        return false;
    }

    function getTag2() {
        var numAxleBeforeTag = getNumAxlesOnVehicleBeforeTags();
        var axleNumToGet = numAxleBeforeTag + 2;
        var tagAxle = getAxleByNumber(axleNumToGet);

        if (tagAxle && tagAxle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            return tagAxle;
        }
        return null;
    }

    function hasTag3() {
        var numAxleBeforeTag = getNumAxlesOnVehicleBeforeTags();
        var axleNumToGet = numAxleBeforeTag + 3;
        var tagAxle = getAxleByNumber(axleNumToGet);

        if (tagAxle && tagAxle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            return true;
        }
        return false;
    }

    function getTag3() {
        var numAxleBeforeTag = getNumAxlesOnVehicleBeforeTags();
        var axleNumToGet = numAxleBeforeTag + 3;
        var tagAxle = getAxleByNumber(axleNumToGet);

        if (tagAxle && tagAxle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            return tagAxle;
        }
        return null;
    }

    function getMinus1() {
        let numMinusAxles = countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);
        let tempMinus1 = null;
        switch(numMinusAxles) {
            case 1:
                tempMinus1 = getAxle1Rear();
                break;
            case 2:
                tempMinus1 = getAxle2Rear();
                break;
            case 3:
                tempMinus1 = getAxle3Rear();
                break;
        }
        
        return tempMinus1;
    }

    function getMinus2() {
        let numMinusAxles = countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);
        let tempMinus2 = null;
        switch(numMinusAxles) {
            case 2:
                tempMinus2 = getAxle1Rear();
                break;
            case 3:
                tempMinus2 = getAxle2Rear();
                break;
        }
        
        return tempMinus2;
    }

    function getMinus3() {
        let numMinusAxles = countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);
        let tempMinus3 = null;
        switch(numMinusAxles) {
            case 3:
                tempMinus3 = getAxle1Rear();
                break;
        }
        
        return tempMinus3;
    }

    function hasLiftingAxle() {
        var hasLiftingAxle = false;
        _axles.forEach(function (axle) {
            if (axle.getLifting() === true) {
                hasLiftingAxle = true;
            }
        });
        return hasLiftingAxle;
    }

    function getAxle(location, position) {
        var numFront = 0, numRear = 0;
        _axles.forEach(function (a) {
            if (a.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || a.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) {
                numFront++;
            } else {
                numRear++;
            }
        });

        if (location === 0 && numFront >= position) {
            return _axles[position - 1];
        } else if (location === 1 && numRear >= position) {
            return _axles[numFront + position - 1];
        } else {
            return null;
        }
    }

    function getAxleById(id) {
        return _axles.filter(function (axle) {
            return axle.getId() === id;
        })[0] || null;
    }

    function getAxleGroupById(id) {
        return _axleGroups.filter(function (axle) {
            return axle.getId() === id;
        })[0] || null;
    }

    function getAxleGroupByPostition(position) {
        return _axleGroups.filter(function (axle) {
            return axle.getType() === position;
        })[0] || null;
    }

    function getAxleByNumber(number) {
        return _axles.filter(function (axle) {
            return axle.getNumber() === number;
        })[0] || null;
    }

    function getAxleByPosition(axlePosition) {
        switch (axlePosition) {
            case config.AXLE_POSITIONS.AXLE_1_FRONT:
                return getAxle1Front();
            case config.AXLE_POSITIONS.AXLE_2_FRONT:
                return getAxle2Front();
            case config.AXLE_POSITIONS.AXLE_3_FRONT:
                return getAxle3Front();
            case config.AXLE_POSITIONS.AXLE_1_REAR:
                return getAxle1Rear();
            case config.AXLE_POSITIONS.AXLE_2_REAR:
                return getAxle2Rear();
            case config.AXLE_POSITIONS.AXLE_3_REAR:
                return getAxle3Rear();
            case config.AXLE_POSITIONS.AXLE_4_REAR:
                return getAxle4Rear();
        }

        return null;
    }

    function getNextAxlePosition(axlePosition) {
        switch (axlePosition) {
            case config.AXLE_POSITIONS.AXLE_1_FRONT:
                return config.AXLE_POSITIONS.AXLE_2_FRONT;
            case config.AXLE_POSITIONS.AXLE_2_FRONT:
                return config.AXLE_POSITIONS.AXLE_3_FRONT;
            case config.AXLE_POSITIONS.AXLE_3_FRONT:
                return config.AXLE_POSITIONS.AXLE_1_REAR;
            case config.AXLE_POSITIONS.AXLE_1_REAR:
                return config.AXLE_POSITIONS.AXLE_2_REAR;
            case config.AXLE_POSITIONS.AXLE_2_REAR:
                return config.AXLE_POSITIONS.AXLE_3_REAR;
            case config.AXLE_POSITIONS.AXLE_3_REAR:
                return config.AXLE_POSITIONS.AXLE_4_REAR;
            case config.AXLE_POSITIONS.AXLE_4_REAR:
                return null;
        }

        return null;
    }

    function getNextAxleByPosition(axlePosition) {
        switch (axlePosition) {
            case config.AXLE_POSITIONS.AXLE_1_FRONT:
                return getAxle2Front();
            case config.AXLE_POSITIONS.AXLE_2_FRONT:
                return getAxle3Front();
            case config.AXLE_POSITIONS.AXLE_3_FRONT:
                return getAxle1Rear();
            case config.AXLE_POSITIONS.AXLE_1_REAR:
                return getAxle2Rear();
            case config.AXLE_POSITIONS.AXLE_2_REAR:
                return getAxle3Rear();
            case config.AXLE_POSITIONS.AXLE_3_REAR:
                return getAxle4Rear();
            case config.AXLE_POSITIONS.AXLE_4_REAR:
                return null;
        }

        return null;
    }

    function getAxleNumber(axle) {

        var axleNumber = 1;

        for (var i = 0; i < _axles.length; i++) {
            if (_axles[i] === axle) {
                break;
            }
            axleNumber++;
        }

        return axleNumber;
    }

    function countAxleType(type) {
        var numOfAxles = 0;

        _axles.forEach(function (axle) {
            if (axle.getType() === type) {
                numOfAxles += 1;
            }
        });

        return numOfAxles;
    }

    function countAxleLocation(location, onlyCountDownAxles) {
        var noOfAxles = 0;

        _axles.forEach(function (axle) {
            if (location === config.AXLE_LOCATIONS.FRONT && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER)) {
                if (typeof onlyCountDownAxles !== 'undefined' && onlyCountDownAxles === true) {
                    if (axle.getRaised() === false) {
                        noOfAxles += 1;
                    }
                } else {
                    noOfAxles += 1;
                }
            } else if (location === config.AXLE_LOCATIONS.REAR && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG)) {
                if (typeof onlyCountDownAxles !== 'undefined' && onlyCountDownAxles === true) {
                    if (axle.getRaised() === false) {
                        noOfAxles += 1;
                    }
                } else {
                    noOfAxles += 1;
                }
            }
        });



        return noOfAxles;
    }

    function getAxlesByLocation(location, onlyReturnDownAxles) {
        var locationAxles = [];

        _axles.forEach(function (axle) {
            if (location === config.AXLE_LOCATIONS.FRONT && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER)) {
                if (typeof onlyReturnDownAxles !== 'undefined' && onlyReturnDownAxles === true) {
                    if (axle.getRaised() === false) {
                        locationAxles.push(axle);
                    }
                } else {
                    locationAxles.push(axle);
                }
            } else if (location === config.AXLE_LOCATIONS.REAR && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG)) {
                if (typeof onlyReturnDownAxles !== 'undefined' && onlyReturnDownAxles === true) {
                    if (axle.getRaised() === false) {
                        locationAxles.push(axle);
                    }
                } else {
                    locationAxles.push(axle);
                }
            }
        });



        return locationAxles;
    }

    function getAxlePosition(axle) {
        if (getAxle1Front() === axle) {
            return config.AXLE_POSITIONS.AXLE_1_FRONT;
        } else if (getAxle2Front() === axle) {
            return config.AXLE_POSITIONS.AXLE_2_FRONT;
        } else if (getAxle3Front() === axle) {
            return config.AXLE_POSITIONS.AXLE_3_FRONT;
        } else if (getAxle1Rear() === axle) {
            return config.AXLE_POSITIONS.AXLE_1_REAR;
        } else if (getAxle2Rear() === axle) {
            return config.AXLE_POSITIONS.AXLE_2_REAR;
        } else if (getAxle3Rear() === axle) {
            return config.AXLE_POSITIONS.AXLE_3_REAR;
        } else if (getAxle4Rear() === axle) {
            return config.AXLE_POSITIONS.AXLE_4_REAR;
        }
        return null;
    }

    function getNumberOfAxles() {
        return _axles.length;
    }

    function setStartingNumberForAxles(startingNumberForAxles) {
        _startingNumberForAxles = startingNumberForAxles;
        
        refreshAxleGroupAxleNumbers();
    }

    function getStartingNumberForAxles() {
        return _startingNumberForAxles;
    }

    function createAxles(axlesArray) {

        var temp = [],
            isVehicle = _parent === config.CHASSIS_OBJECT_TYPES.VEHICLE ? true : false;
        axlesArray.forEach(function (axle) {
            var axleAverageLife, axleAverageLifeToUse, axleAverageCostToUse, axleAverageCost, axleAverageLifeCost;
            switch (axle.Type) {
                case config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER:
                    axleAverageLife = getSecondsteerAverageTyreLife();
                    if (isVehicle) {
                        axleAverageCost = getTyrePriceFirstSet();
                    } else {
                        axleAverageCost = getTyrePriceRemainingSets();
                    }
                    break;
                case config.VEHICLE_AXLE_POSITION_TYPES.PUSHER:
                    axleAverageLife = getPusherAxleAverageTyreLife();
                    axleAverageCost = getTyrePriceRemainingSets();
                    break;
                case config.VEHICLE_AXLE_POSITION_TYPES.TAG:
                    axleAverageLife = getTagAxleAverageTyreLife();
                    axleAverageCost = getTyrePriceRemainingSets();
                    break;
                case config.VEHICLE_AXLE_POSITION_TYPES.FRONT:
                    axleAverageLife = getFrontAxleAverageTyreLife();
                    if (isVehicle) {
                        axleAverageCost = getTyrePriceFirstSet();
                    } else {
                        axleAverageCost = getTyrePriceRemainingSets();
                    }
                    break;
                case config.VEHICLE_AXLE_POSITION_TYPES.REAR:
                    axleAverageLife = getRearAxleAverageTyreLife();
                    axleAverageCost = getTyrePriceRemainingSets();
                    break;
                default:
                    axleAverageLife = getRearAxleAverageTyreLife();
                    axleAverageCost = getTyrePriceRemainingSets();
                    break;
            }

            axleAverageLifeToUse = axle.AverageLifeOfTyre !== undefined && axle.AverageLifeOfTyre ? axle.AverageLifeOfTyre : axleAverageLife;
            axleAverageCostToUse = axle.AverageCostOfTyre !== undefined && axle.AverageCostOfTyre !== 0 ? axle.AverageCostOfTyre : axleAverageCost;

            if (axle.SuspensionMassRating === undefined || axle.SuspensionMassRating === 0) {
                axle.SuspensionMassRating = axle.ManufacturerRating;
            }
            if (axle.TyreMassRatingDual === undefined || axle.TyreMassRatingDual === 0) {
                axle.TyreMassRatingDual = axle.ManufacturerRating;
            }
            if (axle.TyreMassRatingSingle === undefined || axle.TyreMassRatingSingle === 0) {
                axle.TyreMassRatingSingle = axle.ManufacturerRating;
            }
            if (axle.DesiredWeightOverride === false && axle.DesiredWeight === 0) {

                var tyreMassRatingToUse;
                if (axle.NoOfTyresOnAxle === config.NUMBER_OF_TYRES_ON_AXLE_OPTIONS.TWO.val) {
                    tyreMassRatingToUse = axle.TyreMassRatingSingle;
                } else {
                    tyreMassRatingToUse = axle.TyreMassRatingDual;
                }

                axle.DesiredWeight = Math.min(axle.SuspensionMassRating, axle.ManufacturerRating, tyreMassRatingToUse);
            }

            var tempAxle = new Axle(globals.nextId(), _parent, axle.Number, axle.Type, axle.Added || false, axle.SteeringAxle || axle.Steering || false, axle.LiftingAxle || axle.Lifting || false, axle.MaxLiftingTravel || config.vehicleAxleDefaultLiftingMaxTravel, axle.ManufacturerRating || 0, axle.ManufacturerRatingOverride || false, axle.AxleWeight || 0, axle.AxleWeightOverride || false,
                axle.SuspensionMassRating || 0, axle.SuspensionMassRatingOverride || false, axle.TyreMassRatingDual || 0, axle.TyreMassRatingDualOverride || false, axle.TyreMassRatingSingle || 0, axle.TyreMassRatingSingleOverride || false,
                axle.AxleRaised || false, axle.TyreRadius, axle.TyreWidth, axle.NoOfTyresOnAxle, axle.DrivenAxle, axle.StandardRatio, axle.RevsPerKM, axle.TyreWidthOverride || false, axle.Fk_Dummy, axleAverageCostToUse, axleAverageLifeToUse, axle.NoOfTyresOnAxleForCosting || axle.NoOfTyresOnAxle, axle.NoOfTyresOnAxleForCostingOverride || false,
                axle.DesiredWeight, axle.DesiredWeightOverride || false, axle.BridgeFormulaPermissible || 0, axle.BridgeFormulaPermissibleOverride || false, axle.GrossAxleWeightRating || null, axle.GrossAxleWeightRatingOverride || false, axle.PercentageOfWeightCarried || 0, axle.PercentageOfWeightCarriedOverride || false, axle.TyreStaticLoadedRadius);
            //tempAxle.setAdded(false);
            temp.push(tempAxle);
        });
        setAxles(temp);
        resequence();
        // Create axle groups from created axles
        createAxleGroups();
    }

    function resetOverrides() {
        _axles.forEach(function (axle) {
            resetAxleOverride(axle);
        });
        _axleGroups.forEach(function (axle) {
            resetAxleOverride(axle);
        });
        function resetAxleOverride(axle) {
            axle.setManufacturerRatingOverride(false);
            axle.setSuspensionMassRatingOverride(false);
            axle.setTyreMassRatingSingleOverride(false);
            axle.setTyreMassRatingDualOverride(false);
            axle.setTyreWidthOverride(false);
            axle.setNoOfTyresOnAxleForCostingOverride(false);
            axle.setDesiredWeightOverride(false);
            axle.setBridgeFormulaPermissibleOverride(false);
            axle.setGrossAxleWeightRatingOverride(false);
            axle.setPercentageOfWeightCarriedOverride(false);
        }
    }

    function createAxleGroups() {
        var temp = [],
            axleToUse,
            frontAxleGroup = null,
            rearAxleGroup = null;

        // Create front axle groups
        var frontAxles = [];
        getAxles().forEach(function (axle) {
            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) {
                frontAxles.push(axle);
            }
        });

        if (frontAxles.length > 0) {
            frontAxleGroup = frontAxles[0].clone();
            frontAxleGroup.setIsAxleGroup(true);
            frontAxleGroup.setAxleGroupLocation(config.AXLE_LOCATIONS.FRONT);

            if (frontAxles.length > 1) {
                // Update values in frontAxleGroup by using calculations using other axle
                updateAxleGroup(frontAxleGroup, frontAxles);
            } else {
                frontAxleGroup.setRelatedAxleIds([frontAxles[0].getId()]);
            }
            temp.push(frontAxleGroup);
        }
        

        // Create rear axle groups            
        var rearAxles = [];
        getAxles().forEach(function (axle) {
            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
                rearAxles.push(axle);
            }
        });

        if (rearAxles.length > 0) {
            rearAxleGroup = rearAxles[0].clone();
            rearAxleGroup.setIsAxleGroup(true);
            rearAxleGroup.setAxleGroupLocation(config.AXLE_LOCATIONS.REAR);

            if (rearAxles.length > 1) {
                // Update values in frontAxleGroup by using calculations using other axle
                updateAxleGroup(rearAxleGroup, rearAxles);
            } else {
                rearAxleGroup.setRelatedAxleIds([rearAxles[0].getId()]);
            }
            temp.push(rearAxleGroup);
        }
        

        setAxleGroups(temp);

        function updateAxleGroup(axleGroup, axlesToUse) {
            
            var firstAxleNumber, lastAxleNumber, axleIds = [],
                axleGroupWeight = 0,
                axleGroupManufacturerRating = 0,
                axleGroupSuspensionMassRating = 0,
                axleGroupTyreMassRatingSingle = 0,
                axleGroupTyreMassRatingDual = 0,
                axleGroupBridgeFormulaPermissible = 0,
                axleGroupGrossAxleWeightRating = 0;
            axlesToUse.forEach(function (axle, index, arr) {
                if (index === 0) {
                    firstAxleNumber = getStartingNumberForAxles() + axle.getNumber();
                }

                if (index + 1 === arr.length) {
                    lastAxleNumber = getStartingNumberForAxles() + axle.getNumber();
                }
                axleIds.push(axle.getId());
                axleGroupWeight += axle.getAxleWeight();
                axleGroupManufacturerRating += axle.getManufacturerRating();
                axleGroupSuspensionMassRating += axle.getSuspensionMassRating();
                axleGroupTyreMassRatingSingle += axle.getTyreMassRatingSingle();
                axleGroupTyreMassRatingDual += axle.getTyreMassRatingDual();
                axleGroupBridgeFormulaPermissible += axle.getBridgeFormulaPermissible();
                if (axle.getGrossAxleWeightRatingOverride()) {
                    axleGroupGrossAxleWeightRating += axle.getGrossAxleWeightRating();
                } else {
                    var tyreRatingToUse;
                    if (axle.getNoOfTyresOnAxle() === 2) {
                        tyreRatingToUse = axle.getTyreMassRatingSingle();
                    } else {
                        tyreRatingToUse = axle.getTyreMassRatingDual();
                    }
                    axleGroupGrossAxleWeightRating += Math.min(axle.getManufacturerRating(), axle.getSuspensionMassRating(), tyreRatingToUse);
                }
                
                
            });

            axleGroup.setNumber(firstAxleNumber + ' - ' + lastAxleNumber);
            axleGroup.setRelatedAxleIds(axleIds);
            axleGroup.setAxleWeight(axleGroupWeight);
            axleGroup.setManufacturerRating(axleGroupManufacturerRating);
            axleGroup.setSuspensionMassRating(axleGroupSuspensionMassRating);
            axleGroup.setTyreMassRatingSingle(axleGroupTyreMassRatingSingle);
            axleGroup.setTyreMassRatingDual(axleGroupTyreMassRatingDual);
            if (axleGroup.getBridgeFormulaPermissibleOverride() === true) {
                axleGroup.setBridgeFormulaPermissible(axleGroupBridgeFormulaPermissible);
            }
            axleGroup.setGrossAxleWeightRating(axleGroupGrossAxleWeightRating);
        }
    }

    function updateValuesOnAllAxleGroups() {
        getAxleGroups().forEach(function (axleGroup) {
            updateValuesOnAxleGroup(axleGroup);
        });
    }

    function updateValueOnAxleGroupUsingType(type) {
        updateValuesOnAxleGroup(getAxleGroupUsingType(type));
    }

    function addAxleToAxleGroup(axle, type) {
        var axleGroupToUse = getAxleGroupUsingType(type);
        var relatedAxleIds = axleGroupToUse.getRelatedAxleIds();
        relatedAxleIds.push(axle.getId());
        axleGroupToUse.setRelatedAxleIds(relatedAxleIds);
        //updateValuesOnAxleGroup(axleGroupToUse);
    }

    function removeAxleFromAxleGroup(axle, type) {
        var axleGroupToUse = getAxleGroupUsingType(type);
        var relatedAxleIds = axleGroupToUse.getRelatedAxleIds();
        var filteredIds = relatedAxleIds.filter(function (id) {
            return id !== axle.getId();
        });
        axleGroupToUse.setRelatedAxleIds(filteredIds);
    }

    function updateValuesOnAxleGroup(axleGroup) {
        resequence();

        var firstAxleNumber, lastAxleNumber, axleIds = [], axleGroupContainsAddedAxle = false,
            axleGroupWeight = 0,
            axleGroupManufacturerRating = 0,
            axleGroupSuspensionMassRating = 0,
            axleGroupTyreMassRatingSingle = 0,
            axleGroupTyreMassRatingDual = 0,
            axleGroupGrossAxleWeightRating = 0;

        axleGroup.getRelatedAxleIds().forEach(function (id, index, arr) {
            var axle = getAxleById(id);
            if (index === 0) {
                firstAxleNumber = /*getStartingNumberForAxles() +*/ axle.getNumber();
            }

            if (index + 1 === arr.length) {
                lastAxleNumber = /*getStartingNumberForAxles() +*/ axle.getNumber();
            }
            axleIds.push(axle.getId());
            axleGroupWeight += axle.getAxleWeight();
            axleGroupManufacturerRating += axle.getManufacturerRating();
            axleGroupSuspensionMassRating += axle.getSuspensionMassRating();
            axleGroupTyreMassRatingSingle += axle.getTyreMassRatingSingle();
            axleGroupTyreMassRatingDual += axle.getTyreMassRatingDual();
            //axleGroupGrossAxleWeightRating += axle.getGrossAxleWeightRating();
            if (axle.getGrossAxleWeightRatingOverride()) {
                axleGroupGrossAxleWeightRating += axle.getGrossAxleWeightRating();
            } else {
                var tyreRatingToUse;
                if (axle.getNoOfTyresOnAxle() === 2) {
                    tyreRatingToUse = axle.getTyreMassRatingSingle();
                } else {
                    tyreRatingToUse = axle.getTyreMassRatingDual();
                }
                axleGroupGrossAxleWeightRating += Math.min(axle.getManufacturerRating(), axle.getSuspensionMassRating(), tyreRatingToUse);
            }
            if (axle.isAdded() === true) {
                axleGroupContainsAddedAxle = true;
            }
        });

        if (axleGroup.getRelatedAxleIds().length === 1) {
            axleGroup.setNumber(firstAxleNumber);
        } else {
            axleGroup.setNumber(firstAxleNumber + ' - ' + lastAxleNumber);
        }
        axleGroup.setRelatedAxleIds(axleIds);
        axleGroup.setAxleWeight(axleGroupWeight);
        axleGroup.setManufacturerRating(axleGroupManufacturerRating);
        axleGroup.setSuspensionMassRating(axleGroupSuspensionMassRating);
        axleGroup.setTyreMassRatingSingle(axleGroupTyreMassRatingSingle);
        axleGroup.setTyreMassRatingDual(axleGroupTyreMassRatingDual);
        axleGroup.setGrossAxleWeightRating(axleGroupGrossAxleWeightRating);
        axleGroup.setAxleGroupIncludesAddedAxle(axleGroupContainsAddedAxle);
    }

    function updateValuesOnAllAxlesAndAxleGroups() {
        updateValuesOnAllAxleGroups();
        _axles.forEach(function (axle) {
            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG && !axle.getGrossAxleWeightRatingOverride()) {
                axle.setGrossAxleWeightRating(getLesserOfPusherOrTagAxleManufacturerRatings(axle.getNumber()));
            }
        });
    }

    function refreshAxleGroupAxleNumbers() {

        var frontAxleGroup = getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        if(frontAxleGroup !== null) {
            frontAxleGroup.setNumber(getAxleGroupNumber(config.AXLE_LOCATIONS.FRONT));
        }

        var rearAxleGroup = getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        if(rearAxleGroup !== null) {
            rearAxleGroup.setNumber(getAxleGroupNumber(config.AXLE_LOCATIONS.REAR));
        }

        function getAxleGroupNumber(axleGroupLocation) {
            
            var groupAxles = getAxlesByLocation(axleGroupLocation);
            if(groupAxles.length > 0) {
                
                var firstGroupAxleNumber = /*getStartingNumberForAxles() + */groupAxles[0].getNumber();

                if(groupAxles.length > 1) {
                    var lastGroupAxleNumber = /*getStartingNumberForAxles() +*/ groupAxles[groupAxles.length - 1].getNumber();
                    return firstGroupAxleNumber + ' - ' + lastGroupAxleNumber;
                }
                return firstGroupAxleNumber;
            }
        }
    }

    function getAxle1Front() {
        return getAxle(0, 1);
    }

    function getAxle2Front() {
        return getAxle(0, 2);
    }

    function getAxle3Front() {
        return getAxle(0, 3);
    }

    function getAxle1Rear() {
        return getAxle(1, 1);
    }

    function getAxle2Rear() {
        return getAxle(1, 2);
    }

    function getAxle3Rear() {
        return getAxle(1, 3);
    }

    function getAxle4Rear() {
        return getAxle(1, 4);
    }

    function getRearMostFrontAxle() {
        var axle3Front = getAxle3Front(),
            axle2Front = getAxle2Front(),
            axle1Front = getAxle1Front();

        if (axle3Front !== null) {
            return axle3Front;
        } else if (axle2Front !== null) {
            return axle2Front;
        } else {
            return axle1Front;
        }
    }

    function getRearMostRearAxle() {
        var axle4Rear = getAxle4Rear(),
            axle3Rear = getAxle3Rear(),
            axle2Rear = getAxle2Rear();

        if (axle4Rear !== null) {
            return axle4Rear;
        } else if (axle3Rear !== null) {
            return axle3Rear;
        } else if (axle2Rear !== null) {
            return axle2Rear;
        } else {
            return getAxle1Rear();
        }
    }

    function getAxleSaveObjects() {
        var axleSaveObjects = [];

        _axles.forEach(function (axle) {
            axleSaveObjects.push(axle.returnObjectToBeSaved());
        });

        return axleSaveObjects;
    }

    function getAxleSaveObjectsForAppData() {
        var axleSaveObjects = [];

        _axles.forEach(function (axle) {
            axleSaveObjects.push(axle.returnAppDataObjectToBeSaved());
        });

        return axleSaveObjects;
    }

    function getFrontAxleWeight() {
        var overallWeight = 0;
        _axles.forEach(function (axle) {
            if (/*axle.getRaised() === false && */(axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER)) {
                overallWeight += axle.getAxleWeight();
            }
        });
        return overallWeight;
    }
    function getRearAxleWeight() {
        var overallWeight = 0;
        _axles.forEach(function (axle) {
            if (/*axle.getRaised() === false && */(axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG)) {
                overallWeight += axle.getAxleWeight();
            }
        });
        return overallWeight;
    }
    function getPusherAndTagOnlyRearAxleWeight() {
        var overallPusherAndTagWeight = 0;
        _axles.forEach(function (axle) {
            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                overallPusherAndTagWeight += axle.getAxleWeight();
            }
        });
        return overallPusherAndTagWeight;
    }
    function getRearAxleWeightOfDownAxles() {
        var overallWeight = 0;
        _axles.forEach(function (axle) {
            if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG)) {
                overallWeight += axle.getAxleWeight();
            }
        });
        return overallWeight;
    }
    function getRearRearAxleWeight() {
        var overallWeight = 0;
        _axles.forEach(function (axle) {
            if (/*axle.getRaised() === false && */(axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR)) {
                overallWeight += axle.getAxleWeight();
            }
        });
        return overallWeight;
    }
    function getFrontAxleManufacturerRating() {
        var overallManufacturerRating = 0;
        _axles.forEach(function (axle) {
            if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER)) {
                overallManufacturerRating += axle.getManufacturerRating();
            }
        });
        return overallManufacturerRating;
    }
    function getRearAxleManufacturerRating() {
        var overallManufacturerRating = 0;
        _axles.forEach(function (axle) {
            if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG)) {
                overallManufacturerRating += axle.getManufacturerRating();
            }
        });
        return overallManufacturerRating;
    }
    function getRearAxleSuspensionRating() {
        var overallSuspensionRating = 0;
        _axles.forEach(function (axle) {
            if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG)) {
                overallSuspensionRating += axle.getSuspensionMassRating();
            }
        });
        return overallSuspensionRating;
    }
    function getSumRearAxlesManufacturerRatingForWheelbaseCalculation() {
        var overallRating = 0;
        _axles.forEach(function (axle) {
            if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG)) {
                overallRating += getManufacturerRatingForWheelbaseCalculation(axle);
            }
        });
        return overallRating;
    }
    function getRearAxleTyreRating() {
        var overallTyreRating = 0;
        _axles.forEach(function (axle) {
            if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG)) {
                if (axle.getNoOfTyresOnAxle() === 2) {
                    overallTyreRating += axle.getTyreMassRatingSingle();
                } else {
                    overallTyreRating += axle.getTyreMassRatingDual();
                }
                
            }
        });
        return overallTyreRating;
    }
    function getLesserOfFrontAxleManufacturerRatings() {
        //var totalGroupAxleMassRating = 0, totalGroupSuspensionMassRating = 0, totalGroupTyreMassRating = 0;

        //_axles.forEach(function (axle) {
        //    if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER)) {
        //        totalGroupAxleMassRating += axle.getManufacturerRating();
        //        totalGroupSuspensionMassRating += axle.getSuspensionMassRating();
        //        if (axle.getNoOfTyresOnAxle() === 2) {
        //            totalGroupTyreMassRating += axle.getTyreMassRatingSingle();
        //        } else {
        //            totalGroupTyreMassRating += axle.getTyreMassRatingDual();
        //        }
        //    }
        //});

        //return Math.min(totalGroupAxleMassRating, Math.min(totalGroupSuspensionMassRating, totalGroupTyreMassRating));

        var totalLesserRatings = 0;

        _axles.forEach(function (axle) {
            if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER)) {

                var tyreMassRatingToUse = 0;
                if (axle.getNoOfTyresOnAxle() === 2) {
                    tyreMassRatingToUse = axle.getTyreMassRatingSingle();
                } else {
                    tyreMassRatingToUse = axle.getTyreMassRatingDual();
                }

                totalLesserRatings += Math.min(axle.getManufacturerRating(), Math.min(axle.getSuspensionMassRating(), tyreMassRatingToUse));
            }
        });

        return totalLesserRatings;
    }
    function getFrontAxlesGrossAxleWeightRating() {
        
        var totalGrossAxleWeightRating = 0;

        _axles.forEach(function (axle) {
            if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER)) {
                totalGrossAxleWeightRating += axle.getGrossAxleWeightRating();
            }
        });

        return totalGrossAxleWeightRating;
    }
    function getLesserOfRearAxleManufacturerRatings() {
        //var totalGroupAxleMassRating = 0, totalGroupSuspensionMassRating = 0, totalGroupTyreMassRating = 0;

        //_axles.forEach(function (axle) {
        //    if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG)) {
        //        totalGroupAxleMassRating += axle.getManufacturerRating();
        //        totalGroupSuspensionMassRating += axle.getSuspensionMassRating();
        //        if (axle.getNoOfTyresOnAxle() === 2) {
        //            totalGroupTyreMassRating += axle.getTyreMassRatingSingle();
        //        } else {
        //            totalGroupTyreMassRating += axle.getTyreMassRatingDual();
        //        }
        //    }
        //});

        //return Math.min(totalGroupAxleMassRating, Math.min(totalGroupSuspensionMassRating, totalGroupTyreMassRating));

        var totalLesserRatings = 0;

        _axles.forEach(function (axle) {
            if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG)) {

                var tyreMassRatingToUse = 0;
                if (axle.getNoOfTyresOnAxle() === 2) {
                    tyreMassRatingToUse = axle.getTyreMassRatingSingle();
                } else {
                    tyreMassRatingToUse = axle.getTyreMassRatingDual();
                }

                totalLesserRatings += Math.min(axle.getManufacturerRating(), Math.min(axle.getSuspensionMassRating(), tyreMassRatingToUse));

            }
        });

        return totalLesserRatings;
    }
    function getRearAxlesGrossAxleWeightRating() {
        var totalGrossAxleWeightRating = 0;

        _axles.forEach(function (axle) {
            if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG)) {
                totalGrossAxleWeightRating += axle.getGrossAxleWeightRating();
            }
        });

        return totalGrossAxleWeightRating;
    }
    function getLesserOfRearRearAxleManufacturerRatings() {
        
        var totalLesserRatings = 0;

        _axles.forEach(function (axle) {
            if (axle.getRaised() === false && axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {

                var tyreMassRatingToUse = 0;
                if (axle.getNoOfTyresOnAxle() === 2) {
                    tyreMassRatingToUse = axle.getTyreMassRatingSingle();
                } else {
                    tyreMassRatingToUse = axle.getTyreMassRatingDual();
                }

                totalLesserRatings += Math.min(axle.getManufacturerRating(), Math.min(axle.getSuspensionMassRating(), tyreMassRatingToUse));

            }
        });

        return totalLesserRatings;
    }
    function getRearRearAxlesGrossAxleWeightRating() {
        var totalGrossAxleWeightRating = 0;

        _axles.forEach(function (axle) {
            if (axle.getRaised() === false && axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
                totalGrossAxleWeightRating += axle.getGrossAxleWeightRating();
            }
        });

        return totalGrossAxleWeightRating;
    }
    function getLesserOfPusherOrTagAxleManufacturerRatings(axleNumber) {
        
        var axle = getAxleByNumber(axleNumber);

        var totalLesserRatings = 0;

        var tyreMassRatingToUse = 0;
        if (axle.getNoOfTyresOnAxle() === 2) {
            tyreMassRatingToUse = axle.getTyreMassRatingSingle();
        } else {
            tyreMassRatingToUse = axle.getTyreMassRatingDual();
        }

        totalLesserRatings += Math.min(axle.getManufacturerRating(), Math.min(axle.getSuspensionMassRating(), tyreMassRatingToUse));

        return totalLesserRatings;
    }
    
    function getSumOfManufacturerRatingRearRear() {
        var sumManufacturerRatingRearRear = 0;
        _axles.forEach(function (axle) {
            if (axle.getRaised() === false && axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
                sumManufacturerRatingRearRear += axle.getManufacturerRating();
            }
        });
        return sumManufacturerRatingRearRear;
    }

    function getSumOfGAWRRearRear(){
        var sumGAWRRearRear = 0;
        _axles.forEach(function (axle) {
            if (axle.getRaised() === false && axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
                sumGAWRRearRear += axle.getGrossAxleWeightRating();
            }
        });
        return sumGAWRRearRear;
    }

    function getPusherOrTagGrossAxleWeightRating(axleNumber) {
        var axle = getAxleByNumber(axleNumber);

        
        if (axle !== null) {
            return axle.getGrossAxleWeightRating();
        }

        return 0;
    }

    function getPusherOrTagRearAxleGross(axleNumber, grossRear) { 
        //var axle = getAxleByNumber(axleNumber);

        //var grossVal = 0;
        //if (axle !== null && !axle.getRaised()) {
        //    grossVal = grossRear * (axle.getSuspensionMassRating() / getRearAxleSuspensionRating());
        //}

        //return grossVal;
        return getPusherOrTagRearAxleDistribution(axleNumber, grossRear);
    }

    function getRearRearAxlesGross(grossRear) {
        var grossVal = 0;
        var sumOfTypeRearRearAxles = 0;
        _axles.forEach(function (axle) {
            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
                sumOfTypeRearRearAxles += axle.getSuspensionMassRating();
            }

        });

        grossVal = grossRear * (sumOfTypeRearRearAxles / getRearAxleSuspensionRating());

        return grossVal;
    }

    function getRearRearAxlesSuspensionRating(grossRear) {
            
        var sumOfTypeRearRearAxles = 0;
        _axles.forEach(function (axle) {
            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
                sumOfTypeRearRearAxles += axle.getSuspensionMassRating();
            }

        });

        return sumOfTypeRearRearAxles;
    }

    function getSumPusherAndTagAxlesDesiredWeight() {
        
        var sumPusherAndTagAxlesDesiredWeight = 0;
        _axles.forEach(function (axle) {
            if ((axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) && !axle.getRaised()) {
                sumPusherAndTagAxlesDesiredWeight += axle.getDesiredWeight();
            }

        });

        return sumPusherAndTagAxlesDesiredWeight;
    }

    function getSumPusherAndTagAxlesSuspensionRating() {

        var sumPusherAndTagAxlesSuspensionRating = 0;
        _axles.forEach(function (axle) {
            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                sumPusherAndTagAxlesSuspensionRating += axle.getSuspensionMassRating();
            }

        });

        return sumPusherAndTagAxlesSuspensionRating;
    }

    function getPusherOrTagRearAxleDistribution(axleNumber, massValue) {
        //var axle = getAxleByNumber(axleNumber);

        //var tareVal = 0;
        //if (axle !== null && !axle.getRaised()) {
            
        //    tareVal = axle.getSuspensionMassRating() / getRearAxleSuspensionRating() * tareRear;
        //}

        //return tareVal;
        var axle = getAxleByNumber(axleNumber);

        var massVal = 0;
        if (axle !== null && !axle.getRaised()) {

            massVal = getManufacturerRatingForWheelbaseCalculation(axle) / getSumRearAxlesManufacturerRatingForWheelbaseCalculation() * massValue;
        }

        return massVal;
        
    }

    function getRearRearAxlesDistribution(massValue) {
        //var tareVal = 0;
        //var sumOfTypeRearRearAxles = 0;
        //_axles.forEach(function (axle) {
        //    if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
        //        sumOfTypeRearRearAxles += axle.getSuspensionMassRating();
        //    }

        //});

        //tareVal = (sumOfTypeRearRearAxles / getRearAxleSuspensionRating()) * tareRear;

        //return tareVal;
        var massVal = 0;
        var sumOfTypeRearRearAxles = 0;
        _axles.forEach(function (axle) {
            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
                sumOfTypeRearRearAxles += getManufacturerRatingForWheelbaseCalculation(axle);
            }

        });

        massVal = (sumOfTypeRearRearAxles / getSumRearAxlesManufacturerRatingForWheelbaseCalculation()) * massValue;

        return massVal;
    }

    function getManufacturerRatingForWheelbaseCalculation(axle, raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised) {
        if (axle.getRaised() || (raiseSteerablePusherOrTagForWheelbaseTurning && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) && axle.getSteering()) || (forceAddedRaised && axle.isAdded())) {
            return 0;
        } else {
            //return getManufacturerRating();
            if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED) {
                return axle.getSuspensionMassRating();
            } else {
                // if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                //     // return getDesiredWeight();
                //     return axle.getPercentageOfWeightCarried() ;
                // } else {
                //     //return getGrossAxleWeightRating();
                //     return axle.getSuspensionMassRating();
                // }
                return axle.getPercentageOfWeightCarried();
            }
        }
    }

    function calculatePercentagesOfWeightCarried() {

        let sumPercentageOfWeightCarriedByPushersAndTags = 0;

        _axles.forEach(function (axle) {
            if ((axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) && !axle.getRaised()) {
                axle.setPercentageOfWeightCarriedOverride(false);
                if(axle.getRaised()) {
                    axle.setPercentageOfWeightCarried(0);    
                } else {
                    let percentageOfWeightCarried = calculateDefaultPercentageOfWeightCarried(axle);
                    sumPercentageOfWeightCarriedByPushersAndTags += percentageOfWeightCarried; 
                    axle.setPercentageOfWeightCarried(percentageOfWeightCarried);
                }
            }

        });

        let rearRearAxleGroup = getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        let rearRearPercentageOfWeightCarried = 100 - sumPercentageOfWeightCarriedByPushersAndTags;
        rearRearAxleGroup.setPercentageOfWeightCarried(rearRearPercentageOfWeightCarried);
        var valPerAxle = rearRearPercentageOfWeightCarried / rearRearAxleGroup.getRelatedAxleIds().length;

        rearRearAxleGroup.getRelatedAxleIds().forEach(function (axleId) {
            var individualAxle = getAxleById(axleId);
            individualAxle.setPercentageOfWeightCarried(valPerAxle);
        });
    }
    
    function calculatePercentagesOfWeightCarriedPreviouslySavedCalculation() {

        let sumPercentageOfWeightCarriedByPushersAndTags = 0;

        _axles.forEach(function (axle) {
            if ((axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) && !axle.getRaised()) {
                axle.setPercentageOfWeightCarriedOverride(false);
                if(axle.getRaised()) {
                    axle.setPercentageOfWeightCarried(0);    
                } else {
                    let percentageOfWeightCarried = calculateDefaultPercentageOfWeightCarriedPreviouslySavedCalculation(axle);
                    sumPercentageOfWeightCarriedByPushersAndTags += percentageOfWeightCarried; 
                    axle.setPercentageOfWeightCarried(percentageOfWeightCarried);
                }
            }

        });

        let rearRearAxleGroup = getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        let rearRearPercentageOfWeightCarried = 100 - sumPercentageOfWeightCarriedByPushersAndTags;
        rearRearAxleGroup.setPercentageOfWeightCarried(rearRearPercentageOfWeightCarried);
        var valPerAxle = rearRearPercentageOfWeightCarried / rearRearAxleGroup.getRelatedAxleIds().length;

        rearRearAxleGroup.getRelatedAxleIds().forEach(function (axleId) {
            var individualAxle = getAxleById(axleId);
            individualAxle.setPercentageOfWeightCarried(valPerAxle);
        });
    }

    function calculateDefaultPercentageOfWeightCarried(axle) {
        return axle.getGrossAxleWeightRating() / getRearAxlesGrossAxleWeightRating() * 100;
    }

    function calculateDefaultPercentageOfWeightCarriedPreviouslySavedCalculation(axle) {
        return axle.getDesiredWeight() / (getSumPusherAndTagAxlesDesiredWeight() + getRearRearAxlesGrossAxleWeightRating()) * 100;
    }

    function getSumOfOtherPusherAndTagAxlesPercentageOfWeightCarried(pusherOrTagAxleToExclude) {
        let sumPercentageOfWeightCarriedByPushersAndTags = 0;

        _axles.forEach(function (axle) {
            if ((axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) && axle.getId() !== pusherOrTagAxleToExclude.getId()) {
                sumPercentageOfWeightCarriedByPushersAndTags += axle.getPercentageOfWeightCarried();
            }
        });
        return sumPercentageOfWeightCarriedByPushersAndTags;
    }

    function getSumOfDownPusherAndTagAxlesPercentageOfWeightCarried() {
        let sumPercentageOfWeightCarriedByPushersAndTags = 0;

        _axles.forEach(function (axle) {
            if ((axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) && !axle.getRaised()) {
                sumPercentageOfWeightCarriedByPushersAndTags += axle.getPercentageOfWeightCarried();
            }
        });
        return sumPercentageOfWeightCarriedByPushersAndTags;
    }

    function calculateRearRearPercentageOfWeightCarried() {
        // getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).setPercentageOfWeightCarried(100 - getSumOfDownPusherAndTagAxlesPercentageOfWeightCarried());

        let rearRearAxleGroup = getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        let rearRearPercentageOfWeightCarried = 100 - getSumOfDownPusherAndTagAxlesPercentageOfWeightCarried();
        rearRearAxleGroup.setPercentageOfWeightCarried(rearRearPercentageOfWeightCarried);
        var valPerAxle = rearRearPercentageOfWeightCarried / rearRearAxleGroup.getRelatedAxleIds().length;

        rearRearAxleGroup.getRelatedAxleIds().forEach(function (axleId) {
            var individualAxle = getAxleById(axleId);
            individualAxle.setPercentageOfWeightCarried(valPerAxle);
        });
    }

    function getStandardRatioOfDrivenAxle() {

        var standardRatio = 0;

        var drivenAxle = _axles.filter(function (axle) {
            return axle.getDrivenAxle() === true;
        });

        if (drivenAxle.length > 0) {
            standardRatio = drivenAxle[0].getStandardRatio();
        }

        return standardRatio;
    }

    function getTyreStaticLoadedRadiusOfDrivenAxle() {

        var staticLoadedRadius = 0;

        var drivenAxle = _axles.filter(function (axle) {
            return axle.getDrivenAxle() === true;
        });

        if (drivenAxle.length > 0) {
            staticLoadedRadius = drivenAxle[0].getTyreStaticLoadedRadius();
        }

        return staticLoadedRadius;
    }

    function getRevsPerKMOfDrivenAxleTyre() {
        var revsPerKM = 0;

        var drivenAxle = _axles.filter(function (axle) {
            return axle.getDrivenAxle() === true;
        });

        if (drivenAxle.length > 0) {
            revsPerKM = drivenAxle[0].getRevsPerKM();
        }

        return revsPerKM;
    }

    function canAddSecondSteer() {
        if (_axles.length < config.maxTotalAxles && countAxleLocation(config.AXLE_LOCATIONS.FRONT) < config.maxFrontAxles && countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) < 1) {
            return true;
        }
        return false;
    }

    function canAddPusher() {
        if (_axles.length < config.maxTotalAxles && countAxleLocation(config.AXLE_LOCATIONS.REAR) < config.maxRearAxles && countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) < 3 && !hasPreconfiguredPusherOrTag()) {
            return true;
        }
        return false;
    }

    function canAddTag() {
        if (_axles.length < config.maxTotalAxles && countAxleLocation(config.AXLE_LOCATIONS.REAR) < config.maxRearAxles && countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.TAG) < 3 && !hasPreconfiguredPusherOrTag()) {
            return true;
        }
        return false;
    }

    function canAddAxle() {
        return canAddSecondSteer() || canAddPusher() || canAddTag();
    }

    function containsSecondSteerAxle() {
        var secondSteerFound = false;

        _axles.forEach(function (axle) {
            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) {
                secondSteerFound = true;
            }
        });

        return secondSteerFound;
    }

    function setAxleGroupWeightOverrides(location, value) {
        _axles.forEach(function (axle) {
            if (location === config.AXLE_LOCATIONS.FRONT) {
                if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER)) {
                    axle.setAxleWeightOverride(value);
                }
            } else {
                if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG)) {
                    axle.setAxleWeightOverride(value);
                }
            }
        });
    }

    function setAxleGroupManufacturerRatingOverrides(location, value) {
        _axles.forEach(function (axle) {
            if (location === config.AXLE_LOCATIONS.FRONT) {
                if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER)) {
                    axle.setManufacturerRatingOverride(value);
                }
            } else {
                if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG)) {
                    axle.setManufacturerRatingOverride(value);
                }
            }
        });
    }

    function setAxleGroupTyreMassRatingOverrides(location, value) {
        _axles.forEach(function (axle) {
            if (location === config.AXLE_LOCATIONS.FRONT) {
                if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER)) {
                    axle.setTyreMassRatingSingleOverride(value);
                    axle.setTyreMassRatingDualOverride(value);
                }
            } else {
                if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG)) {
                    axle.setTyreMassRatingSingleOverride(value);
                    axle.setTyreMassRatingDualOverride(value);
                }
            }
        });
    }

    function setAxleGroupSuspensionMassRatingOverrides(location, value) {
        _axles.forEach(function (axle) {
            if (location === config.AXLE_LOCATIONS.FRONT) {
                if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER)) {
                    axle.setSuspensionMassRatingOverride(value);
                }
            } else {
                if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG)) {
                    axle.setSuspensionMassRatingOverride(value);
                }
            }
        });
    }

    function setGroupAndUnderlyingAxleValuesWhenGAWROverridden(axleGroup, newValue) {
        var valPerAxle = newValue / axleGroup.getRelatedAxleIds().length;
        axleGroup.getRelatedAxleIds().forEach(function (axleId) {
            var individualAxle = getAxleById(axleId);
            individualAxle.setGrossAxleWeightRating(valPerAxle);
            individualAxle.setManufacturerRating(valPerAxle);
            individualAxle.setSuspensionMassRating(valPerAxle);
            //individualAxle.setDesiredWeight(valPerAxle);
            individualAxle.setGrossAxleWeightRatingOverride(true);
            individualAxle.setManufacturerRatingOverride(true);
            individualAxle.setSuspensionMassRatingOverride(true);
            //individualAxle.setDesiredWeightOverride(true);
            // if (axleGroup.getNoOfTyresOnAxle() === 2) {
                individualAxle.setTyreMassRatingSingle(valPerAxle);
                individualAxle.setTyreMassRatingSingleOverride(true);
            // } else {
                individualAxle.setTyreMassRatingDual(valPerAxle);
                individualAxle.setTyreMassRatingDualOverride(true);
            // }
        });
        axleGroup.setGrossAxleWeightRating(newValue);
        axleGroup.setManufacturerRating(newValue);
        axleGroup.setSuspensionMassRating(newValue);
        //axleGroup.setDesiredWeight(newValue);
        axleGroup.setGrossAxleWeightRatingOverride(true);
        axleGroup.setManufacturerRatingOverride(true);
        axleGroup.setSuspensionMassRatingOverride(true);
        //axleGroup.setDesiredWeightOverride(true);
        // if (axleGroup.getNoOfTyresOnAxle() === 2) {
            axleGroup.setTyreMassRatingSingle(newValue);
            axleGroup.setTyreMassRatingSingleOverride(true);
        // } else {
            axleGroup.setTyreMassRatingDual(newValue);
            axleGroup.setTyreMassRatingDualOverride(true);
        // }
    }

    function setGroupAndUnderlyingAxleValuesWhenBridgeFormulaPermissibleOverridden(axleGroup, newValue) {
        var valPerAxle = newValue / axleGroup.getRelatedAxleIds().length;
        axleGroup.getRelatedAxleIds().forEach(function (axleId) {
            var individualAxle = getAxleById(axleId);
            individualAxle.setBridgeFormulaPermissible(valPerAxle);
        });
        
        axleGroup.setBridgeFormulaPermissible(newValue);
    }

    function setGroupAndUnderlyingAxleValuesWhenGAWROverrideRemoved(axleGroup, originalAxleGroup) {
        var manufacturerValPerAxle = originalAxleGroup.getManufacturerRating() / axleGroup.getRelatedAxleIds().length;
        var suspensionValPerAxle = originalAxleGroup.getSuspensionMassRating() / axleGroup.getRelatedAxleIds().length;
        var tyreSingleValPerAxle = originalAxleGroup.getTyreMassRatingSingle() / axleGroup.getRelatedAxleIds().length;
        var tyreDualValPerAxle = originalAxleGroup.getTyreMassRatingDual() / axleGroup.getRelatedAxleIds().length;
        axleGroup.getRelatedAxleIds().forEach(function (axleId) {
            var individualAxle = getAxleById(axleId);
            
            individualAxle.setManufacturerRating(manufacturerValPerAxle);
            individualAxle.setSuspensionMassRating(suspensionValPerAxle);
            //individualAxle.setDesiredWeight(valPerAxle);
            individualAxle.setGrossAxleWeightRatingOverride(false);
            individualAxle.setManufacturerRatingOverride(false);
            individualAxle.setSuspensionMassRatingOverride(false);
            //individualAxle.setDesiredWeightOverride(false);
            // if (axleGroup.getNoOfTyresOnAxle() === 2) {
                individualAxle.setTyreMassRatingSingle(tyreSingleValPerAxle);
                individualAxle.setTyreMassRatingSingleOverride(false);
            // } else {
                individualAxle.setTyreMassRatingDual(tyreDualValPerAxle);
                individualAxle.setTyreMassRatingDualOverride(false);
            // }
            individualAxle.setGrossAxleWeightRating(individualAxle.getGrossAxleWeightRatingDefaultValue());
        });

        axleGroup.setManufacturerRating(originalAxleGroup.getManufacturerRating());
        axleGroup.setSuspensionMassRating(originalAxleGroup.getSuspensionMassRating());
        //axleGroup.setDesiredWeight(originalAxleGroup.getDesiredWeight());
        axleGroup.setGrossAxleWeightRatingOverride(false);
        axleGroup.setManufacturerRatingOverride(false);
        axleGroup.setSuspensionMassRatingOverride(false);
        //axleGroup.setDesiredWeightOverride(false);
        // if (axleGroup.getNoOfTyresOnAxle() === 2) {
            axleGroup.setTyreMassRatingSingle(originalAxleGroup.getTyreMassRatingSingle());
            axleGroup.setTyreMassRatingSingleOverride(false);
        // } else {
            axleGroup.setTyreMassRatingDual(originalAxleGroup.getTyreMassRatingDual());
            axleGroup.setTyreMassRatingDualOverride(false);
        // }
        axleGroup.setGrossAxleWeightRating(axleGroup.getGrossAxleWeightRatingDefaultValue());
        
    }

    function setGroupAndUnderlyingAxleValuesWhenBridgeFormulaPermissibleOverrideRemoved(axleGroup, originalAxleGroup) {
        var bridgeFormulaPermissiblePerAxle = originalAxleGroup.getBridgeFormulaPermissible() / axleGroup.getRelatedAxleIds().length;
        
        axleGroup.getRelatedAxleIds().forEach(function (axleId) {
            var individualAxle = getAxleById(axleId);
            
            individualAxle.setBridgeFormulaPermissible(bridgeFormulaPermissiblePerAxle);
            
        });

        axleGroup.setBridgeFormulaPermissible(originalAxleGroup.getBridgeFormulaPermissible());
    }

    function setAxleValuesWhenGAWROverridden(axle) {
        axle.setGrossAxleWeightRating(axle.getGrossAxleWeightRating());
        axle.setManufacturerRating(axle.getGrossAxleWeightRating());
        axle.setSuspensionMassRating(axle.getGrossAxleWeightRating());
        //individualAxle.setDesiredWeight(axle.getGrossAxleWeightRating());
        axle.setGrossAxleWeightRatingOverride(true);
        axle.setManufacturerRatingOverride(true);
        axle.setSuspensionMassRatingOverride(true);
        //individualAxle.setDesiredWeightOverride(true);
        // if (axle.getNoOfTyresOnAxle() === 2) {
            axle.setTyreMassRatingSingle(axle.getGrossAxleWeightRating());
            axle.setTyreMassRatingSingleOverride(true);
        // } else {
            axle.setTyreMassRatingDual(axle.getGrossAxleWeightRating());
            axle.setTyreMassRatingDualOverride(true);
        // }
    }

    function setAxleValuesWhenBridgeFormulaPermissibleOverridden(axle) {
        axle.setBridgeFormulaPermissible(axle.getBridgeFormulaPermissible());
        
    }

    function setAxleValuesWhenGAWROverrideRemoved(axle, originalAxle) {
        axle.setManufacturerRating(originalAxle.getManufacturerRating());
        axle.setSuspensionMassRating(originalAxle.getSuspensionMassRating());
        //individualAxle.setDesiredWeight(valPerAxle);
        axle.setGrossAxleWeightRatingOverride(false);
        axle.setManufacturerRatingOverride(false);
        axle.setSuspensionMassRatingOverride(false);
        //individualAxle.setDesiredWeightOverride(false);
        // if (axle.getNoOfTyresOnAxle() === 2) {
            axle.setTyreMassRatingSingle(originalAxle.getTyreMassRatingSingle());
            axle.setTyreMassRatingSingleOverride(false);
        // } else {
            axle.setTyreMassRatingDual(originalAxle.getTyreMassRatingDual());
            axle.setTyreMassRatingDualOverride(false);
        // }
    }

    function setAxleValuesWhenBridgeFormulaPermissibleOverrideRemoved(axle, originalAxle) {
        axle.setBridgeFormulaPermissible(originalAxle.getBridgeFormulaPermissible());
    }

    // function convertManufacturerRatingsFromDetailedToSimplified() {
    //     _axles.forEach(function (axle) {
    //         if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG && (axle.getSuspensionMassRatingOverride() || axle.getTyreMassRatingOverride() || axle.getManufacturerRatingOverride())) {
    //             axle.setGrossAxleWeightRatingOverride(true);
    //             setAxleValuesWhenGAWROverridden(axle);
    //         } else {
    //             axle.setGrossAxleWeightRating(axle.getGrossAxleWeightRatingDefaultValue());
    //         }
    //     });

    //     var frontAxleGroup = getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
    //     if (frontAxleGroup.getSuspensionMassRatingOverride() || frontAxleGroup.getTyreMassRatingOverride() || frontAxleGroup.getManufacturerRatingOverride()) {
    //         frontAxleGroup.setGrossAxleWeightRatingOverride(true);
    //         setGroupAndUnderlyingAxleValuesWhenGAWROverridden(frontAxleGroup, frontAxleGroup.getGrossAxleWeightRating());
    //     }else {
    //         frontAxleGroup.setGrossAxleWeightRating(frontAxleGroup.getGrossAxleWeightRatingDefaultValue());
    //     }

    //     var rearAxleGroup = getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
    //     if (rearAxleGroup.getSuspensionMassRatingOverride() || rearAxleGroup.getTyreMassRatingOverride() || rearAxleGroup.getManufacturerRatingOverride()) {
    //         rearAxleGroup.setGrossAxleWeightRatingOverride(true);
    //         setGroupAndUnderlyingAxleValuesWhenGAWROverridden(rearAxleGroup, rearAxleGroup.getGrossAxleWeightRating());
    //     }else {
    //         rearAxleGroup.setGrossAxleWeightRating(rearAxleGroup.getGrossAxleWeightRatingDefaultValue());
                    
    //     }
    // }

    function convertManufacturerRatingsFromDetailedToSimplified() {
        _axles.forEach(function (axle) {
            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                if (axle.getSuspensionMassRatingOverride() || axle.getTyreMassRatingOverride() || axle.getManufacturerRatingOverride()) {
                    axle.setGrossAxleWeightRatingOverride(true);
                    setAxleValuesWhenGAWROverridden(axle);
                }else {
                    axle.setGrossAxleWeightRating(axle.getGrossAxleWeightRatingDefaultValue());
                }
            } 
        });

        var frontAxleGroup = getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        if (frontAxleGroup.getSuspensionMassRatingOverride() || frontAxleGroup.getTyreMassRatingOverride() || frontAxleGroup.getManufacturerRatingOverride()) {
            frontAxleGroup.setGrossAxleWeightRatingOverride(true);
            setGroupAndUnderlyingAxleValuesWhenGAWROverridden(frontAxleGroup, frontAxleGroup.getGrossAxleWeightRating());
        }else {
            frontAxleGroup.setGrossAxleWeightRating(frontAxleGroup.getGrossAxleWeightRatingDefaultValue());
        }

        var rearAxleGroup = getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        if (rearAxleGroup.getSuspensionMassRatingOverride() || rearAxleGroup.getTyreMassRatingOverride() || rearAxleGroup.getManufacturerRatingOverride()) {
            rearAxleGroup.setGrossAxleWeightRatingOverride(true);
            setGroupAndUnderlyingAxleValuesWhenGAWROverridden(rearAxleGroup, rearAxleGroup.getGrossAxleWeightRating());
        }else {
            rearAxleGroup.setGrossAxleWeightRating(rearAxleGroup.getGrossAxleWeightRatingDefaultValue());
                    
        }
    }

    function allFrontAxleWeightsAreZero() {
        var axleHasWeight = false;
        _axles.forEach(function (axle) {
            if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER)) {
                if (axle.getAxleWeight() > 0) {
                    axleHasWeight = true;
                }
            }
        });
        return !axleHasWeight;
    }

    function allRearAxleWeightsAreZero() {
        var axleHasWeight = false;
        _axles.forEach(function (axle) {
            if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG)) {
                if (axle.getAxleWeight() > 0) {
                    axleHasWeight = true;
                }
            }
        });
        return !axleHasWeight;
    }

    function getAxleUsingType(type) {
        var axlesArray = getAxles(),
            returnObject = null;
        axlesArray.forEach(function (axle) {
            if (axle.getType() === type) {
                returnObject = axle;
            }
        });

        return returnObject;
    }

    function getAllAxlesOfType(type) {
        var axlesArray = getAxles(),
            returnArray = [];
        axlesArray.forEach(function (axle) {
            if (axle.getType() === type) {
                returnArray.push(axle);
            }
        });

        return returnArray;
    }

    function getAnyAxleOverloaded() {

        var hasOverloadedAxle = false;

        _axles.forEach(function (axle) {

            var tyreMassRating;
            if (axle.getNoOfTyresOnAxle() === 2) {
                tyreMassRating = axle.getTyreMassRatingSingle();
            } else {
                tyreMassRating = axle.getTyreMassRatingDual();
            }

            if (axle.getAxleWeight() > Math.min(axle.getManufacturerRating(), axle.getSuspensionMassRating(), tyreMassRating)) {
                hasOverloadedAxle = true;
            }
        });

        return hasOverloadedAxle;
    }

    function getAnyPusherOrTagAxleOverloaded(roundingPrecision, massConversion) {

        var hasOverloadedAxle = false;

        _axles.forEach(function (axle) {

            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                var tyreMassRating;
                if (axle.getNoOfTyresOnAxle() === 2) {
                    tyreMassRating = axle.getTyreMassRatingSingle();
                } else {
                    tyreMassRating = axle.getTyreMassRatingDual();
                }
                if (roundingPrecision) {

                    var compareVal = axle.getAxleWeight() * massConversion;
                    var refVAl = Math.min(axle.getManufacturerRating(), axle.getSuspensionMassRating(), tyreMassRating) * massConversion;

                    if (parseFloat((compareVal).toFixed(roundingPrecision)) > parseFloat((refVAl.toFixed(roundingPrecision)))) {
                        hasOverloadedAxle = true;
                    }
                } else {
                    if (axle.getAxleWeight() > Math.min(axle.getManufacturerRating(), axle.getSuspensionMassRating(), tyreMassRating)) {
                        hasOverloadedAxle = true;
                    }
                }
                
            }
        });

        return hasOverloadedAxle;
    }

    function getRearRearOnlyAxlesOverloaded(roundingPrecision, massConversion) {

        var rearDrivenAxleWeightTotal = 0;
        var rearDrivenManufacturerRatingTotal = 0;

        var hasOverloadedAxle = false;

        _axles.forEach(function (axle) {

            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
                var tyreMassRating;
                if (axle.getNoOfTyresOnAxle() === 2) {
                    tyreMassRating = axle.getTyreMassRatingSingle();
                } else {
                    tyreMassRating = axle.getTyreMassRatingDual();
                }
                
                rearDrivenAxleWeightTotal += axle.getAxleWeight();
                rearDrivenManufacturerRatingTotal += Math.min(axle.getManufacturerRating(), axle.getSuspensionMassRating(), tyreMassRating);
            }
        });

        if (roundingPrecision) {

            var compareVal = rearDrivenAxleWeightTotal * massConversion;
            var refVAl = rearDrivenManufacturerRatingTotal * massConversion;

            if (parseFloat((compareVal).toFixed(roundingPrecision)) > parseFloat((refVAl).toFixed(roundingPrecision))) {
                hasOverloadedAxle = true;
            }
        } else {
            if (rearDrivenAxleWeightTotal > rearDrivenManufacturerRatingTotal) {
                hasOverloadedAxle = true;
            }
        }

        return hasOverloadedAxle;
    }

    function getAxleOverloaded(axleNum) {
        var axle = getAxleByNumber(axleNum);

        var hasOverloadedAxle = false;

        var tyreMassRating;
        if (axle.getNoOfTyresOnAxle() === 2) {
            tyreMassRating = axle.getTyreMassRatingSingle();
        } else {
            tyreMassRating = axle.getTyreMassRatingDual();
        }

        if (axle.getAxleWeight() > Math.min(axle.getManufacturerRating(), axle.getSuspensionMassRating(), tyreMassRating)) {
            hasOverloadedAxle = true;
        }

        return hasOverloadedAxle;
    }

    /**
     * Returns array with axle groups
     * Pusher/Tag must always be shown separately
     * @returns {object[]} - Return list of axle groups to be used in menu in configuration
     * */
    function getAxleObjectsForMenu() {
        var returnArray = [];
        // Add front axle group
        if (getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT) !== null) {
            returnArray.push(getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT));
        }

        //// Add second steer
        //if (getAllAxlesOfType(config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER).length > 0) {
        //    getAllAxlesOfType(config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER).forEach(function (axle) {
        //        returnArray.push(axle);
        //    });
        //}
        // Add pusher
        if (getAllAxlesOfType(config.VEHICLE_AXLE_POSITION_TYPES.PUSHER).length > 0) {
            getAllAxlesOfType(config.VEHICLE_AXLE_POSITION_TYPES.PUSHER).forEach(function (axle) {
                returnArray.push(axle);
            });
        }

        // Add rear axle group
        if (getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR) !== null) {
            returnArray.push(getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR));
        }

        // Add tag
        if (getAllAxlesOfType(config.VEHICLE_AXLE_POSITION_TYPES.TAG).length > 0) {
            getAllAxlesOfType(config.VEHICLE_AXLE_POSITION_TYPES.TAG).forEach(function (axle) {
                returnArray.push(axle);
            });
        }

        return returnArray;
    }

    function getAxleGroupUsingType(type) {
        var axlesArray = getAxleGroups(),
            returnObject = null;
        axlesArray.forEach(function (axle) {
            if (axle && axle.getType() === type) {
                returnObject = axle;
            }
        });

        return returnObject;
    }

    function getAxleGroupByAxleNumber(axleNumber) {
        var axlesArray = getAxleGroups(),
            returnObject = null;

        axlesArray.forEach(function (axle) {
            axle.getRelatedAxleIds().forEach(function (individualAxleId) {
                var individualAxle = getAxleById(individualAxleId);
                if (individualAxle.getNumber() === axleNumber) {
                    returnObject = axle;
                }
            });
            
        });

        return returnObject;
    }

    function getRearMostDownAxlePositionAtRear() {

        var axle4Rear = getAxle4Rear();
        if (axle4Rear !== null && axle4Rear.getRaised() === false) {
            return config.AXLE_POSITIONS.AXLE_4_REAR;
        }

        var axle3Rear = getAxle3Rear();
        if (axle3Rear !== null && axle3Rear.getRaised() === false) {
            return config.AXLE_POSITIONS.AXLE_3_REAR;
        }

        var axle2Rear = getAxle2Rear();
        if (axle2Rear !== null && axle2Rear.getRaised() === false) {
            return config.AXLE_POSITIONS.AXLE_2_REAR;
        }

        var axle1Rear = getAxle1Rear();
        if (axle1Rear !== null && axle1Rear.getRaised() === false) {
            return config.AXLE_POSITIONS.AXLE_1_REAR;
        }
    }

    function getRearMostDownAxlePositionAtFront() {

        var axle3Front = getAxle3Front();
        if (axle3Front !== null && axle3Front.getRaised() === false) {
            return config.AXLE_POSITIONS.AXLE_3_FRONT;
        }
        
        var axle2Front = getAxle2Front();
        if (axle2Front !== null && axle2Front.getRaised() === false) {
            return config.AXLE_POSITIONS.AXLE_2_FRONT;
        }

        var axle1Front = getAxle1Front();
        if (axle1Front !== null && axle1Front.getRaised() === false) {
            return config.AXLE_POSITIONS.AXLE_1_FRONT;
        }
        
    }

    function getFrontMostDownAxlePositionAtRear() {

        var axle1Rear = getAxle1Rear();
        if (axle1Rear !== null && axle1Rear.getRaised() === false) {
            return config.AXLE_POSITIONS.AXLE_1_REAR;
        }

        var axle2Rear = getAxle2Rear();
        if (axle2Rear !== null && axle2Rear.getRaised() === false) {
            return config.AXLE_POSITIONS.AXLE_2_REAR;
        }

        var axle3Rear = getAxle3Rear();
        if (axle3Rear !== null && axle3Rear.getRaised() === false) {
            return config.AXLE_POSITIONS.AXLE_3_REAR;
        }

        var axle4Rear = getAxle4Rear();
        if (axle4Rear !== null && axle4Rear.getRaised() === false) {
            return config.AXLE_POSITIONS.AXLE_4_REAR;
        }
    }

    function getFrontMostDownAxlePositionAtFront() {

        var axle1Front = getAxle1Front();
        if (axle1Front !== null && axle1Front.getRaised() === false) {
            return config.AXLE_POSITIONS.AXLE_1_FRONT;
        }

        var axle2Front = getAxle2Front();
        if (axle2Front !== null && axle2Front.getRaised() === false) {
            return config.AXLE_POSITIONS.AXLE_2_FRONT;
        }

        var axle3Front = getAxle3Front();
        if (axle3Front !== null && axle3Front.getRaised() === false) {
            return config.AXLE_POSITIONS.AXLE_3_FRONT;
        }

    }

    function getFirstRearDrivenAxlePosition() {
        var axle1Rear = getAxle1Rear();
        if (axle1Rear !== null && axle1Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
            return config.AXLE_POSITIONS.AXLE_1_REAR;
        }

        var axle2Rear = getAxle2Rear();
        if (axle2Rear !== null && axle2Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
            return config.AXLE_POSITIONS.AXLE_2_REAR;
        }

        var axle3Rear = getAxle3Rear();
        if (axle3Rear !== null && axle3Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
            return config.AXLE_POSITIONS.AXLE_3_REAR;
        }

        var axle4Rear = getAxle4Rear();
        if (axle4Rear !== null && axle4Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
            return config.AXLE_POSITIONS.AXLE_4_REAR;
        }
    }

    function getLastRearDrivenAxlePosition() {

        var axle4Rear = getAxle4Rear();
        if (axle4Rear !== null && axle4Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
            return config.AXLE_POSITIONS.AXLE_4_REAR;
        }

        var axle3Rear = getAxle3Rear();
        if (axle3Rear !== null && axle3Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
            return config.AXLE_POSITIONS.AXLE_3_REAR;
        }

        var axle2Rear = getAxle2Rear();
        if (axle2Rear !== null && axle2Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
            return config.AXLE_POSITIONS.AXLE_2_REAR;
        }

        var axle1Rear = getAxle1Rear();
        if (axle1Rear !== null && axle1Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
            return config.AXLE_POSITIONS.AXLE_1_REAR;
        }
    }

    function getNumAxlesOnVehicleBeforeTags() {


        var numAxlesBeforeTags = 0;

        var lastRearDrivenAxlePosition = getLastRearDrivenAxlePosition();

        switch (lastRearDrivenAxlePosition) {
            case config.AXLE_POSITIONS.AXLE_1_REAR:
                numAxlesBeforeTags = getAxle1Rear().getNumber();
                break;
            case config.AXLE_POSITIONS.AXLE_2_REAR:
                numAxlesBeforeTags = getAxle2Rear().getNumber();
                break;
            case config.AXLE_POSITIONS.AXLE_3_REAR:
                numAxlesBeforeTags = getAxle3Rear().getNumber();
                break;
            case config.AXLE_POSITIONS.AXLE_4_REAR:
                numAxlesBeforeTags = getAxle4Rear().getNumber();
                break;
        }

        return numAxlesBeforeTags;
    }

    function getHasPusherAxleDown() {
        var hasPusherAxleDown = false;

        _axles.forEach(function (axle) {
            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER && axle.getRaised() === false) {
                hasPusherAxleDown = true;
            }
        });

        return hasPusherAxleDown;
    }

    function getHasTagAxleDown() {
        var hasTagAxleDown = false;

        _axles.forEach(function (axle) {
            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG && axle.getRaised() === false) {
                hasTagAxleDown = true;
            }
        });

        return hasTagAxleDown;
    }

    function getHasPusherOrTagAxleDown() {
        var hasPusherOrTagAxleDown = false;

        _axles.forEach(function (axle) {
            if ((axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) && axle.getRaised() === false) {
                hasPusherOrTagAxleDown = true;
            }
        });

        return hasPusherOrTagAxleDown;
    }

    function getCountSteeringPushersOrTags() {
        var countOfSteeringPushersOrTags = 0;

        _axles.forEach(function (axle) {
            if ((axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) && axle.getRaised() === false && axle.getSteering()) {
                countOfSteeringPushersOrTags++;
            }
        });

        return countOfSteeringPushersOrTags;
    }

    function syncIdsOnAxleGroups() {
        // Check if front axle group
        if (getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT) !== null) {
            var frontAxleIds = [];
            getAxles().forEach(function (axle) {
                if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) {
                    frontAxleIds.push(axle.getId());
                }
            });
            getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).setRelatedAxleIds(frontAxleIds);
        }
        
        // Check if rear axle group
        if (getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR) !== null) {
            var rearAxleIds = [];
            getAxles().forEach(function (axle) {
                if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
                    rearAxleIds.push(axle.getId());
                }
            });
            getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).setRelatedAxleIds(rearAxleIds);
        }
    }

    function getNumbersForRearAxleGroup() {
        var firstAxleNumber = null, lastAxleNumber = null;

        getAxles().forEach(function (axle) {
            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                if (firstAxleNumber === null) {
                    firstAxleNumber = axle.getNumber();
                }

                if (lastAxleNumber === null || lastAxleNumber < axle.getNumber()) {
                    lastAxleNumber = axle.getNumber();
                }
            }
        });

        return {
            firstAxleNumber: firstAxleNumber,
            lastAxleNumber: lastAxleNumber
        };
    }

    function getAnyRearAxlesOverridden() {
        var rearAxleOverridden = false;

        getAxles().forEach(function (axle) {
            if (!axle.getRaised() && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG)) {
                if (axle.getAxleWeightOverride()) {
                    rearAxleOverridden = true;
                }
            }
        });


        return rearAxleOverridden;
    }

    function getAllRearAxlesNumTyresPerAxle() {
        var allRearAxles = getAxlesByLocation(config.AXLE_LOCATIONS.REAR);
        var numAxles = allRearAxles.length;
        var totalNumTyres = 0;
        allRearAxles.forEach(function (axle) {
            totalNumTyres += axle.getNoOfTyresOnAxle();
        });

        return totalNumTyres / numAxles;
    }

    function getAllRearAxlesWidthPerTyre() {
        var allRearAxles = getAxlesByLocation(config.AXLE_LOCATIONS.REAR);
        var numAxles = allRearAxles.length;
        var totalTyreWidth = 0;
        allRearAxles.forEach(function (axle) {
            totalTyreWidth += axle.getTyreWidth();
        });

        return totalTyreWidth / numAxles;
    }
    

    self.getAxles = getAxles;
    self.setAxles = setAxles;
    self.hasPusherOrTag = hasPusherOrTag;
    self.getAxle = getAxle;
    self.getAxleById = getAxleById;
    self.getAxleGroupById = getAxleGroupById;
    self.getAxleByNumber = getAxleByNumber;
    self.countAxleType = countAxleType;
    self.getAxle1Front = getAxle1Front;
    self.getAxle2Front = getAxle2Front;
    self.getAxle3Front = getAxle3Front;
    self.getAxle1Rear = getAxle1Rear;
    self.getAxle2Rear = getAxle2Rear;
    self.getAxle3Rear = getAxle3Rear;
    self.getAxle4Rear = getAxle4Rear;
    self.getAxleSaveObjects = getAxleSaveObjects;
    self.createAxles = createAxles;
    self.getFrontAxleManufacturerRating = getFrontAxleManufacturerRating;
    self.getRearAxleManufacturerRating = getRearAxleManufacturerRating;
    self.getSumOfManufacturerRatingRearRear = getSumOfManufacturerRatingRearRear;
    self.getPusherOrTagRearAxleGross = getPusherOrTagRearAxleGross;
    self.getRearRearAxlesGross = getRearRearAxlesGross;
    self.setStartingNumberForAxles = setStartingNumberForAxles;
    self.getStartingNumberForAxles = getStartingNumberForAxles;
    self.getNumberOfAxles = getNumberOfAxles;
    self.getStandardRatioOfDrivenAxle = getStandardRatioOfDrivenAxle;
    self.getRevsPerKMOfDrivenAxleTyre = getRevsPerKMOfDrivenAxleTyre;
    self.getRearMostFrontAxle = getRearMostFrontAxle;

    //2018.7
    self.canAddSecondSteer = canAddSecondSteer;
    self.canAddPusher = canAddPusher;
    self.canAddTag = canAddTag;
    self.add = add;
    self.countAxleLocation = countAxleLocation;
    self.getAxleNumber = getAxleNumber;
    self.remove = remove;
    self.canAddAxle = canAddAxle;
    self.setAxleGroupWeightOverrides = setAxleGroupWeightOverrides;
    self.setAxleGroupManufacturerRatingOverrides = setAxleGroupManufacturerRatingOverrides;
    self.getFrontAxleWeight = getFrontAxleWeight;
    self.getRearAxleWeight = getRearAxleWeight;
    self.setAxleGroupTyreMassRatingOverrides = setAxleGroupTyreMassRatingOverrides;
    self.setAxleGroupSuspensionMassRatingOverrides = setAxleGroupSuspensionMassRatingOverrides;
    self.getLesserOfFrontAxleManufacturerRatings = getLesserOfFrontAxleManufacturerRatings;
    self.getLesserOfRearAxleManufacturerRatings = getLesserOfRearAxleManufacturerRatings;
    self.getAxlePosition = getAxlePosition;
    self.hasLiftingAxle = hasLiftingAxle;
    self.allFrontAxleWeightsAreZero = allFrontAxleWeightsAreZero;
    self.allRearAxleWeightsAreZero = allRearAxleWeightsAreZero;
    self.getRearMostRearAxle = getRearMostRearAxle;

    //2018.10
    self.getAxleSaveObjectsForAppData = getAxleSaveObjectsForAppData;

    //2018.11
    self.getFrontAxleAverageTyreLife = getFrontAxleAverageTyreLife;
    self.getSecondsteerAverageTyreLife = getSecondsteerAverageTyreLife;
    self.getPusherAxleAverageTyreLife = getPusherAxleAverageTyreLife;
    self.getRearAxleAverageTyreLife = getRearAxleAverageTyreLife;
    self.getTagAxleAverageTyreLife = getTagAxleAverageTyreLife;
    self.getAxleUsingType = getAxleUsingType;

    //2019.1
    self.getAnyAxleOverloaded = getAnyAxleOverloaded;
    self.getAxleOverloaded = getAxleOverloaded;

    //2019.3
    self.getAnyPusherOrTagAxleOverloaded = getAnyPusherOrTagAxleOverloaded;

    //2020.1
    self.getRearMostDownAxlePositionAtRear = getRearMostDownAxlePositionAtRear;
    self.getFrontMostDownAxlePositionAtRear = getFrontMostDownAxlePositionAtRear;
    self.getFirstRearDrivenAxlePosition = getFirstRearDrivenAxlePosition;
    self.getLastRearDrivenAxlePosition = getLastRearDrivenAxlePosition;
    self.getNumAxlesOnVehicleBeforeTags = getNumAxlesOnVehicleBeforeTags;
    self.getHasPusherAxleDown = getHasPusherAxleDown;
    self.getHasTagAxleDown = getHasTagAxleDown;
    self.getHasPusherOrTagAxleDown = getHasPusherOrTagAxleDown;
    self.getAxleByPosition = getAxleByPosition;
    self.getNextAxleByPosition = getNextAxleByPosition;
    self.getPusherOrTagRearAxleDistribution = getPusherOrTagRearAxleDistribution;
    self.getRearRearAxlesDistribution = getRearRearAxlesDistribution;
    self.getAxleObjectsForMenu = getAxleObjectsForMenu;
    self.getAxleGroups = getAxleGroups;
    self.setAxleGroups = setAxleGroups;
    self.updateValuesOnAllAxleGroups = updateValuesOnAllAxleGroups;
    self.updateValueOnAxleGroupUsingType = updateValueOnAxleGroupUsingType;
    self.addAxleToAxleGroup = addAxleToAxleGroup;
    self.syncIdsOnAxleGroups = syncIdsOnAxleGroups;
    self.getAxleGroupUsingType = getAxleGroupUsingType;
    self.getAllAxlesOfType = getAllAxlesOfType;
    self.getAxleGroupById = getAxleGroupById;
    self.hasAddedPusherOrTag = hasAddedPusherOrTag;
    self.getRearRearAxleWeight = getRearRearAxleWeight;
    self.getNumbersForRearAxleGroup = getNumbersForRearAxleGroup;
    self.getLesserOfPusherOrTagAxleManufacturerRatings = getLesserOfPusherOrTagAxleManufacturerRatings;
    self.getLesserOfRearRearAxleManufacturerRatings = getLesserOfRearRearAxleManufacturerRatings;
    self.hasPusher1 = hasPusher1;
    self.hasPusher2 = hasPusher2;
    self.hasPusher3 = hasPusher3;
    self.hasTag1 = hasTag1;
    self.hasTag2 = hasTag2;
    self.hasTag3 = hasTag3;
    self.getRearAxleManufacturerRating = getRearAxleManufacturerRating;
    self.getRearAxleSuspensionRating = getRearAxleSuspensionRating;
    self.getRearAxleTyreRating = getRearAxleTyreRating;
    self.getPusherOrTagGrossAxleWeightRating = getPusherOrTagGrossAxleWeightRating;
    self.getFrontAxlesGrossAxleWeightRating = getFrontAxlesGrossAxleWeightRating;
    self.getRearAxlesGrossAxleWeightRating = getRearAxlesGrossAxleWeightRating;
    self.getRearRearAxlesGrossAxleWeightRating = getRearRearAxlesGrossAxleWeightRating;
    self.hasAddedAxle = hasAddedAxle;
    self.getRearAxleWeightOfDownAxles = getRearAxleWeightOfDownAxles;
    self.hasAddedPusherOrTagDown = hasAddedPusherOrTagDown;
    self.getAnyRearAxlesOverridden = getAnyRearAxlesOverridden;
    self.hasPreconfiguredPusherOrTag = hasPreconfiguredPusherOrTag;
    self.setGroupAndUnderlyingAxleValuesWhenGAWROverridden = setGroupAndUnderlyingAxleValuesWhenGAWROverridden;
    self.setGroupAndUnderlyingAxleValuesWhenGAWROverrideRemoved = setGroupAndUnderlyingAxleValuesWhenGAWROverrideRemoved;
    self.convertManufacturerRatingsFromDetailedToSimplified = convertManufacturerRatingsFromDetailedToSimplified;
    self.setAxleValuesWhenGAWROverridden = setAxleValuesWhenGAWROverridden;
    self.setAxleValuesWhenGAWROverrideRemoved = setAxleValuesWhenGAWROverrideRemoved;
    self.updateValuesOnAllAxlesAndAxleGroups = updateValuesOnAllAxlesAndAxleGroups;
    self.getAxleGroupByAxleNumber = getAxleGroupByAxleNumber;
    self.resequence = resequence;

    //2020.5
    self.getRearRearOnlyAxlesOverloaded = getRearRearOnlyAxlesOverloaded;

    //2021.1
    self.getRearMostDownAxlePositionAtFront = getRearMostDownAxlePositionAtFront;
    self.getFrontMostDownAxlePositionAtFront = getFrontMostDownAxlePositionAtFront;
    self.getPusherAndTagOnlyRearAxleWeight = getPusherAndTagOnlyRearAxleWeight;

    //2022.1
    self.getPusher1 = getPusher1;
    self.getPusher2 = getPusher2;
    self.getPusher3 = getPusher3;
    self.getTag1 = getTag1;
    self.getTag2 = getTag2;
    self.getTag3 = getTag3;
    self.getAllRearAxlesNumTyresPerAxle = getAllRearAxlesNumTyresPerAxle;
    self.getAllRearAxlesWidthPerTyre = getAllRearAxlesWidthPerTyre;
    self.getSumPusherAndTagAxlesDesiredWeight = getSumPusherAndTagAxlesDesiredWeight;
    self.getRearRearAxlesSuspensionRating = getRearRearAxlesSuspensionRating;
    self.getSumPusherAndTagAxlesSuspensionRating = getSumPusherAndTagAxlesSuspensionRating;

    //2023.1.1
    self.setGroupAndUnderlyingAxleValuesWhenBridgeFormulaPermissibleOverridden = setGroupAndUnderlyingAxleValuesWhenBridgeFormulaPermissibleOverridden;
    self.setGroupAndUnderlyingAxleValuesWhenBridgeFormulaPermissibleOverrideRemoved = setGroupAndUnderlyingAxleValuesWhenBridgeFormulaPermissibleOverrideRemoved;
    self.setAxleValuesWhenBridgeFormulaPermissibleOverridden = setAxleValuesWhenBridgeFormulaPermissibleOverridden;
    self.setAxleValuesWhenBridgeFormulaPermissibleOverrideRemoved = setAxleValuesWhenBridgeFormulaPermissibleOverrideRemoved;

    //2023.3
    self.getMinus1 = getMinus1;
    self.getMinus2 = getMinus2;
    self.getMinus3 = getMinus3;
    self.getManufacturerRatingForWheelbaseCalculation = getManufacturerRatingForWheelbaseCalculation;
    self.calculatePercentagesOfWeightCarried = calculatePercentagesOfWeightCarried;
    self.getSumOfOtherPusherAndTagAxlesPercentageOfWeightCarried = getSumOfOtherPusherAndTagAxlesPercentageOfWeightCarried;
    self.calculateRearRearPercentageOfWeightCarried = calculateRearRearPercentageOfWeightCarried;
    self.getSumOfDownPusherAndTagAxlesPercentageOfWeightCarried = getSumOfDownPusherAndTagAxlesPercentageOfWeightCarried;
    self.calculatePercentagesOfWeightCarriedPreviouslySavedCalculation = calculatePercentagesOfWeightCarriedPreviouslySavedCalculation;
    self.getCountSteeringPushersOrTags = getCountSteeringPushersOrTags;

    //2023.4
    self.resetOverrides = resetOverrides;

    //2024.2
    self.getTyreStaticLoadedRadiusOfDrivenAxle = getTyreStaticLoadedRadiusOfDrivenAxle;

};

export default AxlesHolder;
