import config from './config';
import globals from './globals';
import LZString from '../assets/scripts/lz-string-1.3.3.min';
// import $ from '../assets/scripts/jquery-3.6.0.min';
import moment from '../assets/scripts/moment.min';
import { isRef, isReactive } from 'vue';

const $ = window.$;
var TScMessenger = window.TScMessenger;
var OPERATION = config.OPERATION;


var db;

var dbName = 'truckscience_db';

var cacheListenerCallback;
var batchJobHandlerBusy = false;
var batchJobQueue = [];
var curBatchJob = null;

var STORE = {
    Images: 'images',
    Properties: 'properties',
    Vehicles: 'vehicles',
    NewOffers: 'newOffers',
    SavedOffers: 'savedOffers',
    Customers: 'customers',
    SalesPeople: 'salesPeople',
    SyncQueue: 'syncQueue',
    DebugLog: 'debugLog',
    UsageLog: 'usageLog',
    Bodies: 'bodies',
    Accessories: 'accessories',
    Trailers: 'trailers',
    Payloads: 'payloads',
    SharedOfferStubs: 'sharedOfferStubs',
    //SharedOffers: 'sharedOffers'
};

var KEY = {
    Images: 'imageurl',
    Properties: 'property',
    Vehicles: 'Id',
    NewOffers: 'VehicleId',
    SavedOffers: 'OfferId',
    Customers: 'Id',
    SalesPeople: 'Id',
    SyncQueue: 'TimeStamp',
    DebugLog: 'TimeStamp',
    UsageLog: 'TimeStamp',
    Bodies: 'Id',
    Accessories: 'Id',
    Trailers: 'Id',
    Payloads: 'Id',
    SharedOfferStubs: 'Id',
    //SharedOffers: 'Id',
};

var PROPERTY = {
    Versions: 'versions',
    LicenceLeaseExpiryDate: 'licence_lease_expiry',
    OnRoadCosts: 'onroad_costs',
    Translations: 'translations',
    CommonPackDetails: 'commonpackdetails',
    Overview: 'overview',
    LastDebugLogUpload: 'last_debuglog_upload',
    LastUsageLogUpload: 'last_usagelog_upload',
    AppConfig: 'app_config',
    LegislationDetails: 'legislationdetails',
    UserSettings: 'user_settings',
    VehiclePackData: 'vehicle_pack_data',
    NumVehicleOpens: 'num_vehicle_opens',
    LastUpdateCheck: 'last_update_check',
    UserShareAssociations: 'user_share_associations',
    SharedOfferSyncTimestamp: 'sharedOffer_sync_timestamp',
    TeamItemsSyncTimestamp: 'teamItems_sync_timestamp',
    BodyMasses: 'body_masses',
    LicenceCategories: 'licence_categories'
};

var PACK = {
    Vehicle: 'vehicle',
    Graphic: 'graphic',
    Company: 'company'
};

var loggedInCdg, loggedInUsername;

var localDatalayer = {
    setupDBAndObjectStore: setupDBAndObjectStore,
    openDB: openDB,
    STORE: STORE,
    PROPERTY: PROPERTY,
    PACK: PACK,
    getSelectionListLocal: getSelectionListLocal,
    getFromCache: getFromCache,
    addToCache: addToCache,
    getImageIfInDB: getImageIfInDB,
    putImageInDb: putImageInDb,
    clearStore: clearStore,
    clearProperty: clearProperty,
    deleteRecord: deleteRecord,
    getSalesPeopleLocal: getSalesPeopleLocal,
    getCustomersLocal: getCustomersLocal,
    getAllCustomersOffers: getAllCustomersOffers,
    checkIfSyncItems: checkIfSyncItems,
    batchUpdate: batchUpdate,
    queueBatchJob: queueBatchJob,
    getCustomersSyncList: getCustomersSyncList,
    clearCustomersCachedOffers: clearCustomersCachedOffers,
    clearAllCachedData: clearAllCachedData,
    isDBReady: isDBReady,
    resetVehiclesOfferCachedFlag: resetVehiclesOfferCachedFlag,
    getObjectStore: getObjectStore,
    setIdentity: setIdentity,
    getLogDumpForPost: getLogDumpForPost,
    getUsageInfoForPost: getUsageInfoForPost,
    clearOldDebugLogs: clearOldDebugLogs,
    putConfigDrawingInDb: putConfigDrawingInDb,
    getLocalLegislationDetailsById: getLocalLegislationDetailsById,
    registerCacheListener: registerCacheListener,
    getBodiesLocal: getBodiesLocal,
    //getCranesLocal: getCranesLocal,
    getAccessoriesLocal: getAccessoriesLocal,
    getTrailersLocal: getTrailersLocal,
    getPayloadsLocal: getPayloadsLocal,
    getSharedOfferStubsLocal: getSharedOfferStubsLocal,
    clearBatchJobQueue: clearBatchJobQueue
};

export default localDatalayer;


//#region code

function getDBVersionNumber() {

    return globals.getPaddedAppVersionNumber();
    //var versionNumParts = config.appVersionNumber.split('.');
    //var sprintPart = ('00' + versionNumParts[1]).slice(-2);
    //var buildPart = ('00' + versionNumParts[2]).slice(-2);

    //var paddedVersion = versionNumParts[0] + sprintPart + buildPart + versionNumParts[3];


    ////return parseInt(Number(paddedVersion.replace(/\./g, '')));
    //return parseInt(Number(paddedVersion));
    //// 1073741823 is maximum indexeddb version number
}

/**
 * Opens the db and if it's the first time or if the dbVersion of the existing DB does not match the dbVersion specified, the DB structure is recreated.
 * @method setupDBAndObjectStore
 */
function setupDBAndObjectStore() {
    var dfd = $.Deferred();

    var dbVersion = getDBVersionNumber();
    TScMessenger.writeToConsole("dbVersion = " + dbVersion);
    //window.shimIndexedDB.__useShim()
    var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

    var request = indexedDB.open(dbName, dbVersion);

    request.onsuccess = function (event) {
        dfd.resolve();
    };

    request.onerror = function (event) {
        TScMessenger.writeToConsole("Error setting up IndexedDB:" + request.error);
        dfd.reject();
    };
    request.onupgradeneeded = function (event) {
        db = event.target.result;

        try {
            //db.deleteObjectStore(STORE.Requests);
            db.deleteObjectStore(STORE.Images);
            db.deleteObjectStore(STORE.Properties);
            db.deleteObjectStore(STORE.Vehicles);
            db.deleteObjectStore(STORE.NewOffers);
            db.deleteObjectStore(STORE.SavedOffers);
            db.deleteObjectStore(STORE.Customers);
            db.deleteObjectStore(STORE.SalesPeople);
            db.deleteObjectStore(STORE.SyncQueue);
            db.deleteObjectStore(STORE.DebugLog);
            db.deleteObjectStore(STORE.UsageLog);
            db.deleteObjectStore(STORE.Bodies);
            db.deleteObjectStore(STORE.Accessories);
            db.deleteObjectStore(STORE.Trailers);
            db.deleteObjectStore(STORE.Payloads);
            db.deleteObjectStore(STORE.SharedOfferStubs);
            //db.deleteObjectStore(STORE.SharedOffers);
        } catch (exception) { 

            TScMessenger.writeDebugMessage("LocalDatalayer.js, setupDBAndObjectStore, catch, error deleting objectstores");
        }

        //var objectStore = db.createObjectStore(STORE.Requests, { keyPath: "request" });
        //objectStore.createIndex("request", "request", { unique: false });
        //objectStore.createIndex("identity", "identity", { unique: false });
        //objectStore.createIndex("cdg", "cdg", { unique: false });
        //objectStore.createIndex("username", "username", { unique: false });
        ////objectStore.createIndex("data", "data", { unique: false });

        var imagesObjectStore = db.createObjectStore(STORE.Images, {
            keyPath: "imageurl"
        });
        imagesObjectStore.createIndex("imageurl", "imageurl", {
            unique: false
        });
        //imagesObjectStore.createIndex("dataurl", "dataurl", { unique: false });
        imagesObjectStore.createIndex("pack", "pack", {
            unique: false
        });

        var propertiesObjectStore = db.createObjectStore(STORE.Properties, {
            keyPath: "property"
        });
        propertiesObjectStore.createIndex("property", "property", {
            unique: false
        });
        propertiesObjectStore.createIndex("identity", "identity", {
            unique: false
        });
        propertiesObjectStore.createIndex("cdg", "cdg", {
            unique: false
        });
        propertiesObjectStore.createIndex("username", "username", {
            unique: false
        });
        //propertiesObjectStore.createIndex("data", "data", { unique: false });

        var vehiclesObjectStore = db.createObjectStore(STORE.Vehicles, {
            keyPath: "Id"
        });
        vehiclesObjectStore.createIndex("Id", "Id", {
            unique: true
        });
        vehiclesObjectStore.createIndex("identity", "identity", {
            unique: false
        });
        vehiclesObjectStore.createIndex("cdg", "cdg", {
            unique: false
        });
        vehiclesObjectStore.createIndex("username", "username", {
            unique: false
        });
        //vehiclesObjectStore.createIndex("VehicleDistributionOptionId", "VehicleDistributionOptionId", { unique: false });
        //vehiclesObjectStore.createIndex("VehicleDistributionGroupId", "VehicleDistributionGroupId", { unique: false });
        //vehiclesObjectStore.createIndex("Name", "Name", { unique: false });
        //vehiclesObjectStore.createIndex("AxleLayout", "AxleLayout", { unique: false });
        //vehiclesObjectStore.createIndex("Power", "Power", { unique: false });
        //vehiclesObjectStore.createIndex("BodyTypeTranslation", "BodyTypeTranslation", { unique: false });
        //vehiclesObjectStore.createIndex("BodyManufacturerId", "BodyManufacturerId", { unique: false });
        //vehiclesObjectStore.createIndex("BodyManufacturerDescription", "BodyManufacturerDescription", { unique: false });
        //vehiclesObjectStore.createIndex("GVW", "GVW", { unique: false });
        //vehiclesObjectStore.createIndex("GCW", "GCW", { unique: false });
        //vehiclesObjectStore.createIndex("Payload", "Payload", { unique: false });
        //vehiclesObjectStore.createIndex("BodyLength", "BodyLength", { unique: false });
        //vehiclesObjectStore.createIndex("Volume", "Volume", { unique: false });
        //vehiclesObjectStore.createIndex("Image", "Image", { unique: false });
        //vehiclesObjectStore.createIndex("Application", "Application", { unique: false });
        //vehiclesObjectStore.createIndex("Type", "Type", { unique: false });
        //vehiclesObjectStore.createIndex("OfferCached", "OfferCached", { unique: false });

        var newOffersObjectStore = db.createObjectStore(STORE.NewOffers, {
            keyPath: "VehicleId"
        });
        newOffersObjectStore.createIndex("VehicleId", "VehicleId", {
            unique: true
        });
        newOffersObjectStore.createIndex("identity", "identity", {
            unique: false
        });
        newOffersObjectStore.createIndex("cdg", "cdg", {
            unique: false
        });
        newOffersObjectStore.createIndex("username", "username", {
            unique: false
        });
        newOffersObjectStore.createIndex("CachedDate", "CachedDate", {
            unique: false
        });
        newOffersObjectStore.createIndex("Complete", "Complete", {
            unique: false
        });
        //newOffersObjectStore.createIndex("Offer", "Offer", { unique: false });
        //newOffersObjectStore.createIndex("Specification", "Specification", { unique: false });
        //newOffersObjectStore.createIndex("Advantages", "Advantages", { unique: false });

        var savedOffersObjectStore = db.createObjectStore(STORE.SavedOffers, {
            keyPath: "OfferId"
        });
        savedOffersObjectStore.createIndex("OfferId", "OfferId", {
            unique: true
        });
        savedOffersObjectStore.createIndex("identity", "identity", {
            unique: false
        });
        savedOffersObjectStore.createIndex("cdg", "cdg", {
            unique: false
        });
        savedOffersObjectStore.createIndex("username", "username", {
            unique: false
        });
        savedOffersObjectStore.createIndex("UpdateCounter", "UpdateCounter", {
            unique: false
        });
        savedOffersObjectStore.createIndex("VehicleId", "VehicleId", {
            unique: false
        });
        savedOffersObjectStore.createIndex("CustomerId", "CustomerId", {
            unique: false
        });
        //savedOffersObjectStore.createIndex("SalesPersonId", "SalesPersonId", { unique: false });
        savedOffersObjectStore.createIndex("CachedDate", "CachedDate", {
            unique: false
        });
        savedOffersObjectStore.createIndex("Complete", "Complete", {
            unique: false
        });
        var customersObjectStore = db.createObjectStore(STORE.Customers, {
            keyPath: "Id"
        });
        customersObjectStore.createIndex("Id", "Id", {
            unique: true
        });
        customersObjectStore.createIndex("identity", "identity", {
            unique: false
        });
        customersObjectStore.createIndex("cdg", "cdg", {
            unique: false
        });
        customersObjectStore.createIndex("username", "username", {
            unique: false
        });
        customersObjectStore.createIndex("Mobile", "Mobile", {
            unique: false
        });
        customersObjectStore.createIndex("Email", "Email", {
            unique: false
        });
        customersObjectStore.createIndex("DirectNumber", "DirectNumber", {
            unique: false
        });
        customersObjectStore.createIndex("ContactName", "ContactName", {
            unique: false
        });
        customersObjectStore.createIndex("Company", "Company", {
            unique: false
        });
        //customersObjectStore.createIndex("UpdateCounter", "UpdateCounter", { unique: false });
        //customersObjectStore.createIndex("Actions", "Actions", { unique: false });
        //customersObjectStore.createIndex("Notes", "Notes", { unique: false });
        //customersObjectStore.createIndex("Offers", "Offers", { unique: false });

        var salesPeopleObjectStore = db.createObjectStore(STORE.SalesPeople, {
            keyPath: "Id"
        });
        salesPeopleObjectStore.createIndex("Id", "Id", {
            unique: true
        });
        salesPeopleObjectStore.createIndex("identity", "identity", {
            unique: false
        });
        salesPeopleObjectStore.createIndex("cdg", "cdg", {
            unique: false
        });
        salesPeopleObjectStore.createIndex("username", "username", {
            unique: false
        });
        salesPeopleObjectStore.createIndex("FirstName", "FirstName", {
            unique: false
        });
        salesPeopleObjectStore.createIndex("LastName", "LastName", {
            unique: false
        });

        var syncQueueObjectStore = db.createObjectStore(STORE.SyncQueue, {
            keyPath: "TimeStamp"
        });
        syncQueueObjectStore.createIndex("TimeStamp", "TimeStamp", {
            unique: true
        });
        syncQueueObjectStore.createIndex("identity", "identity", {
            unique: false
        });
        syncQueueObjectStore.createIndex("cdg", "cdg", {
            unique: false
        });
        syncQueueObjectStore.createIndex("username", "username", {
            unique: false
        });
        //syncQueueObjectStore.createIndex("Options", "Options", { unique: false });
        //syncQueueObjectStore.createIndex("Operation", "Operation", { unique: false });
        syncQueueObjectStore.createIndex("ExistingId", "ExistingId", {
            unique: false
        });
        syncQueueObjectStore.createIndex("ParentId", "ParentId", {
            unique: false
        });

        var debugLogObjectStore = db.createObjectStore(STORE.DebugLog, {
            keyPath: "TimeStamp"
        });
        debugLogObjectStore.createIndex("TimeStamp", "TimeStamp", {
            unique: false
        });
        debugLogObjectStore.createIndex("identity", "identity", {
            unique: false
        });
        debugLogObjectStore.createIndex("cdg", "cdg", {
            unique: false
        });
        debugLogObjectStore.createIndex("username", "username", {
            unique: false
        });
        debugLogObjectStore.createIndex("MessageType", "MessageType", {
            unique: false
        });
        debugLogObjectStore.createIndex("Origin", "Origin", {
            unique: false
        });

        var usageLogObjectStore = db.createObjectStore(STORE.UsageLog, {
            keyPath: "TimeStamp"
        });
        usageLogObjectStore.createIndex("TimeStamp", "TimeStamp", {
            unique: false
        });
        usageLogObjectStore.createIndex("identity", "identity", {
            unique: false
        });
        usageLogObjectStore.createIndex("cdg", "cdg", {
            unique: false
        });
        usageLogObjectStore.createIndex("username", "username", {
            unique: false
        });
        usageLogObjectStore.createIndex("UsageType", "UsageType", {
            unique: false
        });

        var bodiesObjectStore = db.createObjectStore(STORE.Bodies, {
            keyPath: "Id"
        });
        bodiesObjectStore.createIndex("Id", "Id", {
            unique: true
        });
        bodiesObjectStore.createIndex("identity", "identity", {
            unique: false
        });
        bodiesObjectStore.createIndex("cdg", "cdg", {
            unique: false
        });
        bodiesObjectStore.createIndex("username", "username", {
            unique: false
        });

        var accessoriesObjectStore = db.createObjectStore(STORE.Accessories, {
            keyPath: "Id"
        });
        accessoriesObjectStore.createIndex("Id", "Id", {
            unique: true
        });
        accessoriesObjectStore.createIndex("identity", "identity", {
            unique: false
        });
        accessoriesObjectStore.createIndex("cdg", "cdg", {
            unique: false
        });
        accessoriesObjectStore.createIndex("username", "username", {
            unique: false
        });
        accessoriesObjectStore.createIndex("AccessoryType", "AccessoryType", {
            unique: false
        });

        var trailersObjectStore = db.createObjectStore(STORE.Trailers, {
            keyPath: "Id"
        });
        trailersObjectStore.createIndex("Id", "Id", {
            unique: true
        });
        trailersObjectStore.createIndex("identity", "identity", {
            unique: false
        });
        trailersObjectStore.createIndex("cdg", "cdg", {
            unique: false
        });
        trailersObjectStore.createIndex("username", "username", {
            unique: false
        });

        var payloadsObjectStore = db.createObjectStore(STORE.Payloads, {
            keyPath: "Id"
        });
        payloadsObjectStore.createIndex("Id", "Id", {
            unique: true
        });
        payloadsObjectStore.createIndex("identity", "identity", {
            unique: false
        });
        payloadsObjectStore.createIndex("cdg", "cdg", {
            unique: false
        });
        payloadsObjectStore.createIndex("username", "username", {
            unique: false
        });

        var sharedOfferStubsObjectStore = db.createObjectStore(STORE.SharedOfferStubs, {
            keyPath: "Id"
        });
        sharedOfferStubsObjectStore.createIndex("Id", "Id", {
            unique: true
        });
        sharedOfferStubsObjectStore.createIndex("identity", "identity", {
            unique: false
        });
        sharedOfferStubsObjectStore.createIndex("cdg", "cdg", {
            unique: false
        });
        sharedOfferStubsObjectStore.createIndex("username", "username", {
            unique: false
        });

        //var sharedOffersObjectStore = db.createObjectStore(STORE.SharedOffers, { keyPath: "Id" });
        //sharedOffersObjectStore.createIndex("Id", "Id", { unique: true });
        //sharedOffersObjectStore.createIndex("identity", "identity", { unique: false });
        //sharedOffersObjectStore.createIndex("cdg", "cdg", { unique: false });
        //sharedOffersObjectStore.createIndex("username", "username", { unique: false });


    };

    return dfd.promise();
}

/**
 * Opens the db.
 * @method openDB
 */
function openDB() {
    var dfd = $.Deferred();

    var dbVersion = getDBVersionNumber();

    var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
    var req = indexedDB.open(dbName, dbVersion);
    req.onsuccess = function (evt) {
        db = this.result;
        db.onerror = function () {
            var breakHere = 0;
        }
        db.onclose = function () {
            var breakHere = 0;
        }
        db.onabort = function () {
            var breakHere = 0;
        }
        dfd.resolve();
    };
    req.onerror = function (evt) {
        TScMessenger.writeToConsole("Error opening DB:" + req.error);
        dfd.reject();
    };
    return dfd.promise();
}

function isDBReady() {
    return db !== undefined;
}

function registerCacheListener(cacheListener) {
    cacheListenerCallback = cacheListener;
}


function setIdentity(cdg, username) {
    loggedInCdg = cdg;
    loggedInUsername = username;
}

/**
 * Opens a transaction object on the specified obejct store in the specified mode.
 * @method getObjectStore
 * @param {String} store_name The name of the object store to get a transaction object on
 * @param {String} mode The access mode for the object store transaction e.g. readonly, readwrite
 * @return {Object} Resolves a transaction object for the specified object store.
 */
function getObjectStore(store_name, mode) {
    var dfd = $.Deferred();
    if (db === undefined) {
        openDB().then(function () {
            dfd.resolve(getStore(store_name, mode));
        }).fail(function (error) {
            dfd.reject();
        });
    } else {
        dfd.resolve(getStore(store_name, mode));
    }

    return dfd.promise();
}

/**
 * Helper function for getObjectStore.
 * @method getStore
 * @param {String} store_name The name of the object store to get a transaction object on
 * @param {String} mode The access mode for the object store transaction e.g. readonly, readwrite
 * @return {Object} Resolves a transaction object for the specified object store.
 */
function getStore(store_name, mode) {
    var tx = db.transaction(store_name, mode);
    // tx.oncomplete = function (event) {
    //     var breakHere = 0;
    // }
    // tx.onerror = function (event) {
    //     var breakHere = 0;
    // }
    // tx.onabort = function () {
    //    TScMessenger.writeDebugMessage(tx.error);
    // };
    return tx.objectStore(store_name);
}

/**
 * Adds data to the different DB object stores depending on which operation is specified.
 * @method addToCache
 * @param {String} operation The being carried out.
 * @param {String} key The key to cache the data under. This is not always necessary as some object stores are setup with inline keys meaning the db searches the object being cached for the expected key.
 * @param {Object} dataToCache The data to cache.
 */
function addToCache(operation, key, dataToCache) {
    var dfd = $.Deferred();
    var store = getStoreFromOp(operation);

    // var data = JSON.parse(JSON.stringify(dataToCache));
    var data;
    if(typeof dataToCache === 'object') {
        // data = Object.assign({}, dataToCache);
        data = $.extend(true, {}, dataToCache);
    } else {
        data = JSON.parse(JSON.stringify(dataToCache));
    }
    cleanData(data);
    
    key = adjustKeysAndDataForAdd(operation, key, data);
    var objToCache;
    switch (store) {
        case STORE.Properties:
            if (operation === OPERATION.GetLegislationDetails) {
                getAllLegislations(operation, key)
                    .then(function (existingPropertyDataArray) {
                        if (existingPropertyDataArray === null) {
                            existingPropertyDataArray = [];
                        }
                        existingPropertyDataArray.push(data);
                        objToCache = getPropertyCacheObj(key, existingPropertyDataArray);
                        doAddToCache(store, objToCache);
                    });

            } else {
                objToCache = getPropertyCacheObj(key, data);
                doAddToCache(store, objToCache);
            }

            break;
        case STORE.Vehicles:
            objToCache = data;
            doAddToCache(store, objToCache);
            break;
        case STORE.NewOffers:
            if (operation === OPERATION.GetNewOfferDetails) {
                //manageLimitIfNecessary(store, getStoreKeyFromOp(operation), config.maxNewOffersCached, key)
                //    .then(function () {
                //        objToCache = getNewOffersCacheObj(key, null, data);
                //        doAddToCache(store, objToCache);
                //    });
                objToCache = getNewOffersCacheObj(key, null, data);
                doAddToCache(store, objToCache);
            } else {
                getFromCache(operation, key)
                    .then(function (storedData) {
                        objToCache = getNewOffersCacheObj(key, storedData, data);
                        doAddToCache(store, objToCache);
                    });
            }
            break;
        case STORE.SyncQueue:
            objToCache = getSyncObjectToCache(data);
            doAddToCache(store, objToCache);
            break;
        case STORE.Customers:
            objToCache = getCustomersCacheObj(data);
            doAddToCache(store, objToCache);
            break;
        case STORE.SalesPeople:
            objToCache = getSalesPersonCacheObj(data);
            doAddToCache(store, objToCache);
            break;
        case STORE.SavedOffers:
            if (operation === OPERATION.GetExistingOfferDetails || operation === OPERATION.SaveOffer.Code || operation === OPERATION.GetSharedOfferDetails.Code) {
                //manageLimitIfNecessary(store, getStoreKeyFromOp(operation), config.maxSavedOffersCached, key)
                //    .then(function () {
                //        objToCache = getExistingOffersObjectToCache(key, null, data);
                //        doAddToCache(store, objToCache);
                //    });
                objToCache = getExistingOffersObjectToCache(key, null, data);
                doAddToCache(store, objToCache);
            } else {
                getFromCache(operation, key)
                    .then(function (storedData) {
                        objToCache = getExistingOffersObjectToCache(key, storedData, data);
                        doAddToCache(store, objToCache);
                    });
            }
            break;
        case STORE.DebugLog:
            objToCache = getDebugMessageObjectToCache(key, data);
            doAddToCache(store, objToCache);
            break;
        case STORE.UsageLog:
            objToCache = getAppUsageObjectToCache(key, data);
            doAddToCache(store, objToCache);
            break;
        case STORE.Bodies:
        case STORE.Accessories:
        case STORE.Trailers:
        case STORE.Payloads:
            objToCache = getAccessoryObjectToCache(key, data);
            doAddToCache(store, objToCache);
            break;
        case STORE.SharedOfferStubs:
            objToCache = getSharedOfferStubObjectToCache(key, data);
            doAddToCache(store, objToCache);
            break;
        //case STORE.SharedOffers:
        //    objToCache = getSharedOfferObjectToCache(key, data);
        //    doAddToCache(store, objToCache);
        //    break;
    }

    function cleanData(data) {
        Object.keys(data).forEach(function (key) {
            if(typeof data[key] === 'function') {
                delete data[key];
            }else if(isRef(data[key])) {
                delete data[key];
            }else if(isReactive(data[key])) {
                delete data[key];
            }

        });
    }

    function doAddToCache(storeToUse, objectToCache) { 
        var txn = db.transaction(storeToUse, "readwrite");
        txn.oncomplete = function (event) {
            var breakHere = 0;
        }
        txn.onerror = function (event) {
            var breakHere = 0;
        }
        txn.onabort = function () {
            TScMessenger.writeDebugMessage('LocalDatalayer.js, addToCache, doAddToCache, txn.onabort, txn.error: ' + txn.error);
        };
        var objectStoreRequest = txn.objectStore(storeToUse).put(objectToCache);
        txn.commit();
        // var objectStoreRequest = getStore(storeToUse, "readwrite").put(objectToCache);
            objectStoreRequest.onsuccess = function (event) {
                
                // TScMessenger.writeDebugMessage("added to object store");
                // console.log("added object to store, transaction commited");
                if (cacheListenerCallback !== undefined) {
                    cacheListenerCallback(operation);
                }
                dfd.resolve();
            };
            objectStoreRequest.onerror = function (error) {
                TScMessenger.writeErrorMessage("LocalDatalayer.js, addToCache, doAddToCache, objectStoreRequest.onerror, failed to add object to store: " + objectStoreRequest);
                dfd.reject();
            }
        // getObjectStore(storeToUse, "readwrite").then(function (objectStore) {

        //     var objectStoreRequest = objectStore.put(objectToCache);
        //     objectStoreRequest.onsuccess = function (event) {
        //         TScMessenger.writeDebugMessage("added to object store");
        //         if (cacheListenerCallback !== undefined) {
        //             cacheListenerCallback(operation);
        //         }
        //         dfd.resolve();
        //     };
        //     objectStoreRequest.onerror = function (error) {
        //         TScMessenger.writeErrorMessage("failed to add object to store: " + objectStoreRequest);
        //         dfd.reject();
        //     }
        // }).fail(function (error) {
        //     TScMessenger.writeErrorMessage('error getting object store for write');
        //     dfd.reject(error);
        // });
    }
    return dfd.promise();

}

function adjustKeysAndDataForAdd(operation, key, data) {
    var keyToChange = getStoreKeyFromOp(operation);
    if (data[keyToChange]) {
        data[keyToChange] = changeKeyIfNecessary("IN", data[keyToChange]);
    }
    if(typeof data === 'object') {
        data.cdg = loggedInCdg;
        data.username = loggedInUsername;
        data.identity = loggedInCdg + "/" + loggedInUsername;
    }
    return changeKeyIfNecessary("IN", key);
}

function adjustKeyAndDataOnWayOut(operation, data) {
    var keyToChange;
    if (typeof operation === 'string') {
        keyToChange = getStoreKeyFromOp(operation);
    } else {
        keyToChange = getStoreKeyFromOp(operation.Code);
    }

    data[keyToChange] = changeKeyIfNecessary("OUT", data[keyToChange]);
    delete data.cdg;
    delete data.username;
    delete data.identity;
}

function getCorrectKeyIn(key) {
    return loggedInCdg + "/" + loggedInUsername + "/" + key;
}

function getCorrectKeyOut(key) {
    var idBits = key.split("/");
    var retKey;
    if (idBits.length === 3) {

        retKey = idBits[2];
        if (isNaN(retKey)) {
            return retKey;
        } else {
            return Number(idBits[2]);
        }

    } else {

        for (var i = 2; i < idBits.length; i++) {
            if (retKey === undefined) {
                retKey = idBits[i];
            } else {
                retKey += "/" + idBits[i];
            }

        }
        return retKey;
        //return idBits[2] + "_" + idBits[3];
    }
    //return key.split("_")[2];
}

function changeKeyIfNecessary(dirFlag, key) {
    if (dirFlag === "IN") {
        if (typeof key !== 'string') {
            return getCorrectKeyIn(key);
        } else {
            if (key.indexOf(loggedInUsername) === -1) {
                return getCorrectKeyIn(key);
            }
        }
        return key;
    } else {
        if (typeof key === 'string') {
            if (key.indexOf(loggedInUsername) !== -1) {
                return getCorrectKeyOut(key);
            }
        }
        return key;
    }
}

/**
 * Clears all the data from a given object store.
 * @method clearStore
 * @param {String} storeName The name of the store to clear data from.
 */
function clearStore(storeName) {
    var dfd = $.Deferred();

    getObjectStore(storeName, "readwrite")
        .then(function (objectStore) {
            var clearRequest = objectStore.clear();
            clearRequest.onsuccess = function (event) {
                var clearSucceeded = 0;
                dfd.resolve();
            }

            clearRequest.onerror = function (error) {
                TScMessenger.writeToConsole("Error clearing DB store:" + clearRequest.error);
                dfd.reject();
            }
        });

    return dfd.promise();
}

/**
 * Clears the given property from the properties object store.
 * @method clearProperty
 * @param {String} propertyName The name of the property to clear the value of.
 */
function clearProperty(propertyName) {
    propertyName = getCorrectKeyIn(propertyName);
    deleteRecord(STORE.Properties, propertyName);
}

/**
 * Clears all the data from all object stores.
 * @method clearAllCachedData
 */
function clearAllCachedData() {
    var dfd = $.Deferred();
    var clearStoreOpsArray = [];
    for (var key in STORE) {
        clearStoreOpsArray.push(clearStore(STORE[key]));
    }

    $.when.apply($, clearStoreOpsArray).done(function () {
        dfd.resolve();
    }).fail(function () {
        dfd.reject();
    });

    return dfd.promise();
}



function clearOldDebugLogs(cutOffTimeStamp) {
    var dfd = $.Deferred();

    var logsToDelete = [];

    getAllItems(STORE.DebugLog, function (logMessages) {
        if (logMessages.length > 0) {
            for (var i = 0; i < logMessages.length; i++) {
                if (changeKeyIfNecessary("OUT", logMessages[i].TimeStamp) < cutOffTimeStamp) {
                    logsToDelete.push(logMessages[i].TimeStamp);
                }
            }
            if (logsToDelete.length > 0) {
                batchUpdate(OPERATION.DeleteDebugLogs, logsToDelete)
                    .then(function () {
                        dfd.resolve();
                    });
            } else {
                dfd.resolve();
            }
        } else {
            dfd.resolve();
        }
    });


    return dfd.promise();
}

/**
 * Limits the number of cached items to the specified amount. If limit has been reached the oldest one is removed from cache.
 * @method manageLimitIfNecessary
 * @param {String} storeName The name of the store to manage limit on.
 * @param {String} key The name of the index to use in order to get a count of records in specified store.
 * @return resolves when record(s) deleted or rejects if operation failed.
 */
function manageLimitIfNecessary(storeName, key, limit, curKey) {
    var dfd = $.Deferred();
    //var limit = config.maxNewOffersCached;
    getCountOfRecordsInStore(storeName, key)
        .then(function (countResult) {
            if (countResult >= limit) {
                deleteOldestRecords(storeName, key, countResult - (limit - 1), curKey)
                    .then(function () {
                        dfd.resolve();
                    })
                    .fail(function () {
                        dfd.reject();
                    });
            } else {
                dfd.resolve();
            }
        })
        .fail(function () {
            dfd.reject();
        });

    return dfd.promise();
}

/**
 * Deletes the specified number of records from the given store starting from the oldest first.
 * @method deleteOldestRecords
 * @param {String} storeName The name of the store to delete record(s) from.
 * @param {String} deleteKey The name of the index to use to identify the record to be deleted.
 * @param {Number} numRecordsToDelete The number of records to delete from the store.
 * @return resolves when records deleted.
 */
function deleteOldestRecords(storeName, deleteKey, numRecordsToDelete, curKey) {
    var dfd = $.Deferred();
    var deletedIds = [];
    var effectedCustomers = [];
    getAllItems(storeName, function (items) {

        var alreadyCached = false;
        var i;
        for (i = 0; i < items.length; i++) {
            if (items[i][deleteKey] === curKey) {
                alreadyCached = true;
                break;
            }
        }
        if (alreadyCached === false) {
            items.sort(function (a, b) {
                return a.CachedDate - b.CachedDate;
            });
            var deletePromises = [];

            for (i = 0; i < numRecordsToDelete; i++) {
                deletedIds.push(items[i][deleteKey]);
                if (storeName === STORE.SavedOffers) {
                    effectedCustomers.push(items[i].CustomerId);
                }
                deletePromises.push(deleteRecord(storeName, items[i][deleteKey]));
            }
            $.when.apply($, deletePromises).done(function () {
                switch (storeName) {
                    case STORE.NewOffers:
                        for (i = 0; i < deletedIds.length; i++) {
                            var vId = deletedIds[i];
                            getFromCache(OPERATION.GetVehicle, vId)
                                .then(function (storedVehicle) {
                                    if (storedVehicle) {
                                        storedVehicle.OfferCached = 0;
                                        addToCache(OPERATION.SaveVehicle, vId, storedVehicle)
                                            .then(function () {
                                                dfd.resolve();
                                            });

                                    } else {
                                        dfd.resolve();
                                    }

                                });
                        }

                        break;
                    case STORE.SavedOffers:
                        for (var i = 0; i < effectedCustomers.length; i++) {
                            var cId = effectedCustomers[i];
                            var oId = deletedIds[i];
                            getFromCache(OPERATION.GetCustomer, cId)
                                .then(function (custData) {
                                    if (custData !== null) {

                                        for (var j = 0; j < custData.Offers.length; j++) {
                                            if (custData.Offers[j].Id === changeKeyIfNecessary("OUT", oId)) {
                                                custData.Offers[j].OfferCached = 0;
                                                break;
                                            }
                                        }

                                        addToCache(OPERATION.SaveCustomer.Code, cId, custData)
                                            .then(function () {
                                                dfd.resolve();
                                            });
                                    } else {
                                        dfd.resolve();
                                    }
                                });
                        }

                        break;
                    default:
                        dfd.resolve();
                        break;
                }

            });
        } else {
            dfd.resolve();
        }

    });

    return dfd.promise();
}

/**
 * Deletes the specified record from the given store.
 * @method deleteRecord
 * @param {String} storeName The name of the store to delete record from.
 * @param {String} key The key of the record to be deleted.
 */
function deleteRecord(storeName, key) {

    //if (key.indexOf(loggedInUsername) === -1) {
    //    key = getCorrectKeyIn(key);
    //}
    key = changeKeyIfNecessary("IN", key);

    var dfd = $.Deferred();
    getObjectStore(storeName, "readwrite")
        .then(function (objectStore) {
            var deleteRequest = objectStore.delete(key);

            deleteRequest.onsuccess = function (event) {
                dfd.resolve();
            };

            deleteRequest.onerror = function (error) {
                TScMessenger.writeToConsole("Error deleting record:" + deleteRequest.error);
                dfd.reject();
            };
        })
        .fail(function () {
            dfd.reject();
        });


    return dfd.promise();
}

/**
 * Gets a count of the records in the specified store.
 * @method getCountOfRecordsInStore
 * @param {String} storeName The name of the store to get a record count on.
 * @param {String} indexName The name of the key in the store to do a count on.
 * @return Resolves the number of records in the store or rejects if the operation failed.
 */
function getCountOfRecordsInStore(storeName, indexName) {
    var dfd = $.Deferred();

    getObjectStore(storeName, 'readonly')
        .then(function (objectStore) {
            var index = objectStore.index(indexName);
            var indexCountRequest = index.count();

            indexCountRequest.onsuccess = function () {
                dfd.resolve(indexCountRequest.result);
            };

            indexCountRequest.onerror = function () {
                TScMessenger.writeToConsole("Error getting record count:" + indexCountRequest.error);
                dfd.reject();
            };
        });

    return dfd.promise();
}

/**
 * Return all items(records) from an object store
 * @method getAllItems
 * @param {String} storeName The name of the store to get all items from.
 * @param {Function} callback The function provided bythe caller to pass the items back to.
 */
function getAllItems(storeName, callback) {
    var idbKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange;
    getObjectStore(storeName, "readonly")
        .then(function (objectStore) {
            var items = [];

            var index = objectStore.index('identity');
            var keyRange = idbKeyRange.only(loggedInCdg + "/" + loggedInUsername);
            var userItemsOnlyCursorRequest = index.openCursor(keyRange);

            //var cursorRequest = objectStore.openCursor();

            userItemsOnlyCursorRequest.onsuccess = function (evt) {
                var cursor = evt.target.result;
                if (cursor) {
                    items.push(cursor.value);
                    cursor.continue();
                } else {
                    callback(items);
                }
            };

            userItemsOnlyCursorRequest.onerror = function (error) {
                TScMessenger.writeErrorMessage(error);
                TScMessenger.writeToConsole("Error getting all items:" + userItemsOnlyCursorRequest.error);
                callback([]);
            };

        }).fail(function (error) {
            callback([]);
        });

}

/**
 * Helper method for massaging syncItem before caching. Params was causing an issue with indexed db that is sorted by compressing the problem.
 * @method getSyncObjectToCache
 * @param {Object} data The data to be manipulated before being cached.
 * @return Returns the data with compressed Params property.
 */
function getSyncObjectToCache(data) {
    //if (typeof data.Details === 'object') {
    //    data.Details = LZString.compressToUTF16(JSON.stringify(data.Details));
    //}
    //if (typeof data.Operation === 'object') {
    //    data.Operation = LZString.compressToUTF16(JSON.stringify(data.Operation));
    //}
    data.Params = LZString.compressToUTF16(JSON.stringify(data.Params));
    //if (typeof data.Params === 'object' && !$.isEmptyObject(data.Params)) {
    //    data.Params = LZString.compressToUTF16(JSON.stringify(data.Params));
    //} else {
    //    delete data.Params;
    //}
    return data;

}

/**
 * Helper method for massaging requests cache data before caching. In this instance the data from the request is compressed.
 * @method getRequestsCacheObj
 * @param {String} key The key to cache the data under.
 * @param {Object} data The data to be manipulated before being cached.
 * @return Returns the object to be cached.
 */
function getRequestsCacheObj(key, data) {
    var reqObj = {
        request: key,
        cdg: loggedInCdg,
        username: loggedInUsername,
        identity: loggedInCdg + "/" + loggedInUsername,
        data: LZString.compressToUTF16(JSON.stringify(data))
    };

    return reqObj;
}

/**
 * Helper method for massaging accessory(e.g. bodies) cache data before caching. In this instance the data from the request is compressed.
 * @method getRequestsCacheObj
 * @param {String} key The key to cache the data under.
 * @param {Object} data The data to be manipulated before being cached.
 * @return Returns the object to be cached.
 */
function getAccessoryObjectToCache(key, data) {
    var accessoryObj = {
        Id: key,
        cdg: loggedInCdg,
        username: loggedInUsername,
        identity: loggedInCdg + "/" + loggedInUsername,
        data: LZString.compressToUTF16(JSON.stringify(data))
    };

    return accessoryObj;
}

function getSharedOfferStubObjectToCache(key, data) {
    var obj = {
        Id: key,
        cdg: loggedInCdg,
        username: loggedInUsername,
        identity: loggedInCdg + "/" + loggedInUsername,
        data: LZString.compressToUTF16(JSON.stringify(data))
    };

    return obj;
}

/**
 * Helper method for massaging customer cache data before caching. In this instance the notes/actions/offers are compressed.
 * @method getCustomersCacheObj
 * @param {Object} data The customer data to be processed.
 * @return Returns the object to be cached.
 */
function getCustomersCacheObj(data) {

    data.Notes = LZString.compressToUTF16(JSON.stringify(data.Notes));
    data.Actions = LZString.compressToUTF16(JSON.stringify(data.Actions));
    data.Offers = LZString.compressToUTF16(JSON.stringify(data.Offers));

    return data;
}

/**
 * Helper method for massaging salesperson cache data before caching.
 * @method getSalesPersonCacheObj
 * @param {Object} data The salesperson data to be processed.
 * @return Returns the object to be cached.
 */
function getSalesPersonCacheObj(data) {
    return data;
}

/**
 * Helper method for massaging existing offer cache data before caching. The purpose of this method is to compress parts of the data, and to enable the separate existingOffer/existingSpec calls to
 * be linked into the one record, and finally to mark the associated offer stub in the relevant Customer as fully cached for offline access in offers screen.
 * @method getExistingOffersObjectToCache
 * @param {String} key The id to save the cached data under.
 * @param {Object} storedData The existing offer details if any were already cached.
 * @param {Object} data The existing offer/spec cache data to manipulate prior to caching.
 * @return Returns the object to be cached.
 */
function getExistingOffersObjectToCache(key, storedData, data) {

    var isSharedOffer = false;

    data.CachedDate = Date.now();
    if (!data.Complete) {
        data.Complete = 0;
    }

    if (!data.OfferId) {
        data.OfferId = key;
    }

    if (data.Result) {
        delete data.Result;
    }

    if (data.Offer) {
        data.VehicleId = data.Offer.VehicleId;
        data.Offer = LZString.compressToUTF16(JSON.stringify(data.Offer));
    } else {
        if (storedData && storedData.Offer) {
            data.VehicleId = storedData.Offer.VehicleId;
            data.Offer = LZString.compressToUTF16(JSON.stringify(storedData.Offer));
        }
    }
    if (data.Changes) {
        data.UpdateCounter = data.Changes.UpdateCounter;
        if (data.Changes.IsSharedOffer === true) {
            isSharedOffer = true;
        }
        data.Changes = LZString.compressToUTF16(JSON.stringify(data.Changes));
    } else {
        if (storedData && storedData.Changes) {
            data.UpdateCounter = storedData.Changes.UpdateCounter;
            data.Changes = LZString.compressToUTF16(JSON.stringify(storedData.Changes));
        }
    }
    if (data.Customer) {
        data.CustomerId = data.Customer.Id;
        data.Customer = LZString.compressToUTF16(JSON.stringify(data.Customer));
    } else {
        if (storedData && storedData.Customer) {
            data.CustomerId = storedData.Customer.Id;
            data.Customer = LZString.compressToUTF16(JSON.stringify(storedData.Customer));
        }
    }
    if (data.Specification) {
        data.Specification = LZString.compressToUTF16(JSON.stringify(data.Specification));
    } else {
        if (storedData && storedData.Specification) {
            data.Specification = LZString.compressToUTF16(JSON.stringify(storedData.Specification));
        }
    }
    if (data.Advantages) {
        data.Advantages = LZString.compressToUTF16(JSON.stringify(data.Advantages));
    } else {
        if (storedData && storedData.Advantages) {
            data.Advantages = LZString.compressToUTF16(JSON.stringify(storedData.Advantages));
        }
    }


    if ((globals.user.hasPermission(config.PERMISSIONS.SPECIFICATION.Code) || globals.user.allowSpecificationModule()) && storedData && storedData.Offer && storedData.Offer.DataAvailability && storedData.Offer.DataAvailability.ActiveSpecification === true) {
        if (data.Offer && data.Changes && data.Customer && data.Specification && data.Advantages) {
            if (isSharedOffer === true) {
                doSharedOfferStubOfferCachedPropertyUpdate();
            } else {
                doCustomersOfferOfferCachedPropertyUpdate();
            }
        }
    } else {
        if (data.Offer && data.Changes && data.Customer) {
            if (isSharedOffer === true) {
                doSharedOfferStubOfferCachedPropertyUpdate();

            } else {
                doCustomersOfferOfferCachedPropertyUpdate();
            }
        }
    }

    function doCustomersOfferOfferCachedPropertyUpdate() {
        if (data.Complete === 2) {
            var cId = data.CustomerId;
            var oId = data.OfferId;
            getFromCache(OPERATION.GetCustomer, cId)
                .then(function (storedCust) {
                    if (storedCust != null) {
                        for (var i = 0; i < storedCust.Offers.length; i++) {
                            if (storedCust.Offers[i].Id === changeKeyIfNecessary("OUT", oId)) {
                                storedCust.Offers[i].OfferCached = data.Complete;
                                break;
                            }
                        }
                        addToCache(OPERATION.SaveCustomer.Code, cId, storedCust);
                    }
                });
        }
    }

    function doSharedOfferStubOfferCachedPropertyUpdate() {
        if (data.Complete === 2) {
            var oId = data.OfferId;
            getSharedOfferStubsLocal()
                .then(function (storedSharedOfferStubs) {
                    if (storedSharedOfferStubs !== null) {
                        for (var i = 0; i < storedSharedOfferStubs.Offers.length; i++) {
                            if (storedSharedOfferStubs.Offers[i].Id === changeKeyIfNecessary("OUT", oId)) {
                                storedSharedOfferStubs.Offers[i].OfferCached = data.Complete;
                                addToCache(OPERATION.SaveSharedOfferStub, oId, storedSharedOfferStubs.Offers[i]);
                                break;
                            }
                        }

                    }
                });
        }
    }
    return data;
}

/**
 * Helper method for massaging new offer cache data before caching. The purpose of this method is to compress parts of the data, and to enable the separate newOffer/newSpec calls to
 * be linked into the one record, and finally to mark the associated vehicle item record as fully cached for offline access in selection.
 * @method getNewOffersCacheObj
 * @param {String} key The id to save the cached data under.
 * @param {Object} storedData The new offer details if any were already cached.
 * @param {Object} data The new offer/spec cache data to manipulate prior to caching.
 * @return Returns the object to be cached.
 */
function getNewOffersCacheObj(key, storedData, data) {

    data.CachedDate = Date.now();

    if (storedData === null) {
        if (!data.VehicleId) {
            data.VehicleId = key;
        }

        if (!data.Complete) {
            data.Complete = 0;
        }
    } else {
        data.VehicleId = changeKeyIfNecessary("IN", storedData.VehicleId);
        if (!data.Complete) {
            data.Complete = storedData.Complete;
        }

    }


    if (data.Offer) {
        data.Offer = LZString.compressToUTF16(JSON.stringify(data.Offer));
    } else {
        if (storedData && storedData.Offer) {
            data.Offer = LZString.compressToUTF16(JSON.stringify(storedData.Offer));
        }
    }

    if (data.Specification) {
        data.Specification = LZString.compressToUTF16(JSON.stringify(data.Specification));
    } else {
        if (storedData && storedData.Specification) {
            data.Specification = LZString.compressToUTF16(JSON.stringify(storedData.Specification));
        }
    }
    if (data.Advantages) {
        data.Advantages = LZString.compressToUTF16(JSON.stringify(data.Advantages));
    } else {
        if (storedData && storedData.Advantages) {
            data.Advantages = LZString.compressToUTF16(JSON.stringify(storedData.Advantages));
        }
    }


    if ((globals.user.hasPermission(config.PERMISSIONS.SPECIFICATION.Code) || globals.user.allowSpecificationModule()) && storedData && storedData.Offer && storedData.Offer.DataAvailability && storedData.Offer.DataAvailability.ActiveSpecification === true) {
        if (data.Offer && data.Specification && data.Advantages) {
            doSelectionListVehicleOfferCachedPropertyUpdate();
        }
    } else {
        if (data.Offer) {
            doSelectionListVehicleOfferCachedPropertyUpdate();
        }
    }

    function doSelectionListVehicleOfferCachedPropertyUpdate() {
        if (data.Complete && data.Complete === 2) {
            var vId = key;
            getFromCache(OPERATION.GetVehicle, vId)
                .then(function (storedVehicle) {
                    if (storedVehicle) {
                        storedVehicle.OfferCached = data.Complete;
                        addToCache(OPERATION.SaveVehicle, vId, storedVehicle);

                    } else {
                        //future log info?
                    }

                });
        }
    }


    return data;
}

/**
 * Helper method for massaging property cache data before caching. The purpose of this method is to compress the data.
 * @method getPropertyCacheObj
 * @param {String} key The id to save the cached data under.
 * @param {Object} data The property cache data to manipulate prior to caching.
 * @return Returns the object to be cached.
 */
function getPropertyCacheObj(key, data) {
    var reqObj = {};

    reqObj.property = key;
    reqObj.cdg = loggedInCdg;
    reqObj.username = loggedInUsername;
    reqObj.identity = loggedInCdg + "/" + loggedInUsername;
    reqObj.data = LZString.compressToUTF16(JSON.stringify(data));

    return reqObj;
}

/**
 * Helper method for massaging debug message data before caching. The purpose of this method is to compress the data and setup the key.
 * @method getDebugMessageObjectToCache
 * @param {String} key The id to save the cached data under.
 * @param {Object} data The property cache data to manipulate prior to caching.
 * @return Returns the object to be cached.
 */
function getDebugMessageObjectToCache(key, data) {
    data.TimeStamp = key;
    data.Description = LZString.compressToUTF16(JSON.stringify(data.Description));
    data.StackTrace = LZString.compressToUTF16(JSON.stringify(data.StackTrace));

    return data;
}

/**
 * Helper method for massaging app usage data before caching. Currently simply adding key to data.
 * @method getAppUsageObjectToCache
 * @param {String} key The id to save the cached data under.
 * @param {Object} data The property cache data to manipulate prior to caching.
 * @return Returns the object to be cached.
 */
function getAppUsageObjectToCache(key, data) {
    data.TimeStamp = key;


    return data;
}

/**
 * Gets data from indexed db cache.
 * @method getFromCache
 * @param {String} operation The operation being carried out.
 * @param {String} key The id of the record/item to retrieve.
 * @return Returns the requested object(s) from the cache or null if nothing found.
 */
function getFromCache(operation, key) {
    var dfd = $.Deferred();

    var store = getStoreFromOp(operation);
    key = changeKeyIfNecessary("IN", key);
    getObjectStore(store, "readonly").then(function (objectStore) {
        var objectStoreRequest = objectStore.get(key);
        objectStoreRequest.onsuccess = function (event) {
            if (event.target.result === undefined) {
                dfd.resolve(null);
            } else {
                var cachedObj = event.target.result;
                adjustKeyAndDataOnWayOut(operation, cachedObj);
                switch (store) {
                    case STORE.User:
                        handleUserStoreCallback(cachedObj, dfd);
                        break;
                    case STORE.Properties:
                        handlePropertiesStoreCallback(cachedObj, changeKeyIfNecessary("OUT", key), dfd);
                        break;
                    case STORE.NewOffers:
                        handleNewOffersStoreCallback(cachedObj, operation, dfd);
                        break;
                    case STORE.Vehicles:
                        handleVehiclesStoreCallback(cachedObj, dfd);
                        break;
                    case STORE.SalesPeople:
                        break;
                    case STORE.SavedOffers:
                        handleSavedOffersStoreCallback(cachedObj, operation, dfd);
                        break;
                    case STORE.Customers:
                        handleCustomersStoreCallback(cachedObj, dfd);
                        break;
                    case STORE.DebugLog:
                        handleDebugLogStoreCallback(cachedObj, dfd);
                        break;
                    case STORE.Bodies:
                    case STORE.Accessories:
                    case STORE.Trailers:
                    case STORE.Payloads:
                        handleAccessoriesStoreCallback(cachedObj, operation, dfd);
                        break;
                    case STORE.SharedOfferStubs:
                        handleSharedOfferStubsStoreCallback(cachedObj, operation, dfd);
                        break;
                    //case STORE.SharedOffers:
                    //    handleSharedOffersStoreCallback(cachedObj, operation, dfd);
                    //    break;

                }

            }
        };
        objectStoreRequest.onerror = function (event) {
            dfd.resolve(null);
        }
    }).fail(function () {
        dfd.resolve(null);
    });



    return dfd.promise();
}

/**
 * Helper method for massaging cache data before passing back to caller. The purpose of this method is to decompress the data.
 * @method handleRequestsStoreCallback
 * @param {Object} cachedObj The cached object.
 * @param {Object} dfd The Deferred used to pass the data back to the caller.
 * @return Returns requested cached data.
 */
function handleRequestsStoreCallback(cachedObj, dfd) {

    if (cachedObj && cachedObj !== null) {
        var cachedData = LZString.decompressFromUTF16(cachedObj.data);
        var jsonObj = JSON.parse(cachedData);

        dfd.resolve(jsonObj);
    } else {

        dfd.resolve(null);
    }
}

/**
 * Helper method for massaging cache data before passing back to caller. The purpose of this method is to decompress the data.
 * @method handleBodiesStoreCallback
 * @param {Object} cachedObj The cached object.
 * @param {Object} dfd The Deferred used to pass the data back to the caller.
 * @return Returns requested cached data.
 */
function handleAccessoriesStoreCallback(cachedObj, op, dfd) {

    if (cachedObj && cachedObj !== null) {
        var cachedData = LZString.decompressFromUTF16(cachedObj.data);
        var jsonObj = JSON.parse(cachedData);
        adjustKeyAndDataOnWayOut(op, jsonObj);
        var data = {};
        data.Result = {};
        if (op === config.OPERATION.GetBody) {
            data.Body = jsonObj;
        } else if (op === config.OPERATION.GetTrailer) {
            data.Trailer = jsonObj;
        } else if (op === config.OPERATION.GetPayload) {
            data.Payload = jsonObj;
        } else {
            data.Accessory = jsonObj;
            //data.Accessory.Id = Number(data.Accessory.Id.split('_')[0]);
        }

        if ((jsonObj.Name !== undefined && jsonObj.AccessLevel === undefined) || (jsonObj.Name !== undefined && jsonObj.AccessLevel !== undefined && jsonObj.AccessLevel === config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL)) {
            jsonObj.cached = 2;
            data.cached = 2;
            data.Result.ReturnCode = 1;
        } else {
            jsonObj.cached = 0;
            data.cached = 0;
            data.Result.ReturnCode = -1;
        }
        dfd.resolve(data);
    } else {

        dfd.resolve(null);
    }
}

function handleSharedOfferStubsStoreCallback(cachedObj, op, dfd) {
    if (cachedObj && cachedObj !== null) {
        var cachedData = LZString.decompressFromUTF16(cachedObj.data);
        var jsonObj = JSON.parse(cachedData);
        adjustKeyAndDataOnWayOut(op, jsonObj);
        var data = {};
        data.Result = {};
        data.Result.ReturnCode = 1;
        data.Offer = jsonObj;


        dfd.resolve(data);
    } else {
        dfd.resolve(null);
    }
}

function handleSharedOffersStoreCallback(cachedObj, op, dfd) {
    var data = {};
    if (cachedObj.Offer === undefined) {
        if (dfd) {
            dfd.resolve(null);
        } else {
            return null;
        }

    }
    data.Result = {};
    data.Result.ReturnCode = 1;
    if (cachedObj.Offer) {
        var cachedOffer = LZString.decompressFromUTF16(cachedObj.Offer);
        var jsonOffer = JSON.parse(cachedOffer);
        data.Offer = jsonOffer;
        data.VehicleId = cachedObj.VehicleId;
    }
    if (cachedObj.Changes) {
        var cachedChanges = LZString.decompressFromUTF16(cachedObj.Changes);
        var jsonChanges = JSON.parse(cachedChanges);
        data.Changes = jsonChanges;
        data.OfferId = cachedObj.OfferId;
    }
    if (cachedObj.Customer) {
        var cachedCustomer = LZString.decompressFromUTF16(cachedObj.Customer);
        var jsonCustomer = JSON.parse(cachedCustomer);
        data.Customer = jsonCustomer;
        data.CustomerId = cachedObj.CustomerId;
    }
    if (cachedObj.Specification) {
        var cachedSpecification = LZString.decompressFromUTF16(cachedObj.Specification);
        var jsonSpecification = JSON.parse(cachedSpecification);
        data.Specification = jsonSpecification;
    }
    if (cachedObj.Advantages) {
        var cachedAdvantages = LZString.decompressFromUTF16(cachedObj.Advantages);
        var jsonAdvantages = JSON.parse(cachedAdvantages);
        data.Advantages = jsonAdvantages;
    }
    data.UpdateCounter = cachedObj.UpdateCounter;
    data.CachedDate = cachedObj.CachedDate;
    if (cachedObj.Complete === undefined) {
        data.Complete = 0;
    } else {
        data.Complete = cachedObj.Complete;
    }

    if (dfd) {
        dfd.resolve(data);
    } else {
        return data;
    }
}

/**
 * Helper method for massaging cache data before passing back to caller. The purpose of this method is to decompress the various parts of the data and/or assess the completeness of the data depending on the op.
 * @method handleSavedOffersStoreCallback
 * @param {Object} cachedObj The cached object.
 * @param {String} operation The operation being carried out.
 * @param {Object} dfd The Deferred used to pass the data back to the caller.
 * @return Returns requested cached data or null depending on state of completeness and operation being carried out.
 */
function handleSavedOffersStoreCallback(cachedObj, operation, dfd) {
    var data = {};
    if (operation === OPERATION.GetExistingOfferDetails || OPERATION.GetSharedOfferDetails.Code) {
        if (cachedObj.Offer === undefined) {
            if (dfd) {
                dfd.resolve(null);
            } else {
                return null;
            }

        }
    }
    data.Result = {};
    data.Result.ReturnCode = 1;
    if (cachedObj.Offer) {
        var cachedOffer = LZString.decompressFromUTF16(cachedObj.Offer);
        var jsonOffer = JSON.parse(cachedOffer);
        data.Offer = jsonOffer;
        data.VehicleId = cachedObj.VehicleId;
    }
    if (cachedObj.Changes) {
        var cachedChanges = LZString.decompressFromUTF16(cachedObj.Changes);
        var jsonChanges = JSON.parse(cachedChanges);
        data.Changes = jsonChanges;
        data.OfferId = cachedObj.OfferId;
    }
    if (cachedObj.Customer) {
        var cachedCustomer = LZString.decompressFromUTF16(cachedObj.Customer);
        var jsonCustomer = JSON.parse(cachedCustomer);
        data.Customer = jsonCustomer;
        data.CustomerId = cachedObj.CustomerId;
    }
    if (cachedObj.Specification) {
        var cachedSpecification = LZString.decompressFromUTF16(cachedObj.Specification);
        var jsonSpecification = JSON.parse(cachedSpecification);
        data.Specification = jsonSpecification;
    }
    if (cachedObj.Advantages) {
        var cachedAdvantages = LZString.decompressFromUTF16(cachedObj.Advantages);
        var jsonAdvantages = JSON.parse(cachedAdvantages);
        data.Advantages = jsonAdvantages;
    }
    data.UpdateCounter = cachedObj.UpdateCounter;
    data.CachedDate = cachedObj.CachedDate;
    if (cachedObj.Complete === undefined) {
        data.Complete = 0;
    } else {
        data.Complete = cachedObj.Complete;
    }

    if (dfd) {
        dfd.resolve(data);
    } else {
        return data;
    }

}

/**
 * Helper method for massaging cache data before passing back to caller. The purpose of this method is to decompress the various parts of the data and/or assess the completeness of the data depending on the op.
 * @method handleNewOffersStoreCallback
 * @param {Object} cachedObj The cached object.
 * @param {String} operation The operation being carried out.
 * @param {Object} dfd The Deferred used to pass the data back to the caller.
 * @return Returns requested cached data or null depending on state of completeness and operation being carried out.
 */
function handleNewOffersStoreCallback(cachedObj, operation, dfd) {

    if (operation === OPERATION.GetNewOfferDetails) {
        if (cachedObj.Offer === undefined) {
            dfd.resolve(null);
        }
    }
    cachedObj.Result = {};
    cachedObj.Result.ReturnCode = 1;
    if (cachedObj.Offer) {
        var cachedOffer = LZString.decompressFromUTF16(cachedObj.Offer);
        var jsonOffer = JSON.parse(cachedOffer);
        cachedObj.Offer = jsonOffer;
    }
    if (cachedObj.Specification) {
        var cachedSpecification = LZString.decompressFromUTF16(cachedObj.Specification);
        var jsonSpecification = JSON.parse(cachedSpecification);
        cachedObj.Specification = jsonSpecification;
    }
    if (cachedObj.Advantages) {
        var cachedAdvantages = LZString.decompressFromUTF16(cachedObj.Advantages);
        var jsonAdvantages = JSON.parse(cachedAdvantages);
        cachedObj.Advantages = jsonAdvantages;
    }


    dfd.resolve(cachedObj);
    //var data = {};
    //if(operation === OPERATION.GetNewOfferDetails) {
    //    if(cachedObj.Offer === undefined) {
    //        dfd.resolve(null);
    //    }
    //}
    //data.Result = {};
    //data.Result.ReturnCode = 1;
    //if (cachedObj.Offer) {
    //    var cachedOffer = LZString.decompressFromUTF16(cachedObj.Offer);
    //    var jsonOffer = JSON.parse(cachedOffer);
    //    data.Offer = jsonOffer;
    //}
    //if (cachedObj.Specification) {
    //    var cachedSpecification = LZString.decompressFromUTF16(cachedObj.Specification);
    //    var jsonSpecification = JSON.parse(cachedSpecification);
    //    data.Specification = jsonSpecification;
    //}
    //if (cachedObj.Advantages) {
    //    var cachedAdvantages = LZString.decompressFromUTF16(cachedObj.Advantages);
    //    var jsonAdvantages = JSON.parse(cachedAdvantages);
    //    data.Advantages = jsonAdvantages;
    //}
    //if (cachedObj.Complete) {
    //    data.Complete = cachedObj.Complete;
    //}

    //dfd.resolve(data);
}

/**
 * Helper method for deciding which object store to use depending on what operation is being carried out.
 * @method getStoreFromOp
 * @param {String} operation The operation being carried out.
 * @return Returns the store to use.
 */
function getStoreFromOp(operation) {
    switch (operation) {
        case OPERATION.GetSelectionList:
        case OPERATION.SaveVehicle:
        case OPERATION.GetVehicle:
            return STORE.Vehicles;
        case OPERATION.GetNewOfferDetails:
        case OPERATION.GetNewOfferSpecificationDetails:
            return STORE.NewOffers;
        case OPERATION.LatestVersions:
        case OPERATION.GetTranslations:
        case OPERATION.GetOnRoadCosts:
        case OPERATION.LicenceLease:
        case OPERATION.LastDebugLogUpload:
        case OPERATION.LastUsageLogUpload:
        case OPERATION.GetAppConfig.Code:
        case OPERATION.GetCommonPackDetails:
        case OPERATION.GetLegislationList:
        case OPERATION.GetLegislationDetails:
        case OPERATION.GetUserSettings:
        case OPERATION.SaveUserSettings.Code:
        case OPERATION.GetVehiclePackData:
        case OPERATION.NumVehicleOpens:
        case OPERATION.LastUpdateCheck:
        case OPERATION.GetLastSharedOfferSyncTimestamp:
        case OPERATION.GetLastTeamItemsSyncTimestamp:
        case OPERATION.GetBodyMasses.Code:
        case OPERATION.GetLicenceCategories.Code:
            return STORE.Properties;
        case OPERATION.SyncQueue:
            return STORE.SyncQueue;
        case OPERATION.Customer:
        case OPERATION.GetCustomer:
        case OPERATION.SaveCustomer.Code:
            return STORE.Customers;
        case OPERATION.SalesPerson:
            return STORE.SalesPeople;
        case OPERATION.GetExistingOfferDetails:
        case OPERATION.GetExistingOfferSpecificationDetails:
        case OPERATION.GetSharedOfferDetails.Code:
            return STORE.SavedOffers;
        case OPERATION.SaveOffer.Code:
            return STORE.SavedOffers;
        case OPERATION.LogDebugMessage:
        case OPERATION.PostDebugInfo.Code:
        case OPERATION.GetDebugMessage:
        case OPERATION.DeleteDebugLogs:
            return STORE.DebugLog;
        case OPERATION.PostUsageInfo.Code:
        case OPERATION.DeleteUsageLogs:
        case OPERATION.LogAppUsage:
            return STORE.UsageLog;
        case OPERATION.GetBodies:
        case OPERATION.GetBody:
        case OPERATION.SaveBody:
        case OPERATION.GetBodyStubs:
            return STORE.Bodies;
        case OPERATION.GetAccessories:
        case OPERATION.GetAccessory:
        case OPERATION.SaveAccessory:
        case OPERATION.GetAccessoryStubs:
            return STORE.Accessories;
        case OPERATION.GetTrailerStubs:
        case OPERATION.GetTrailer:
        case OPERATION.GetTrailers:
        case OPERATION.SaveTrailer:
            return STORE.Trailers;
        case OPERATION.GetPayloadStubs:
        case OPERATION.GetPayload:
        case OPERATION.SavePayload:
        case OPERATION.GetPayloads:
            return STORE.Payloads;
        case OPERATION.GetSharedOfferStubs.Code:
        case OPERATION.SaveSharedOfferStub:
        case OPERATION.GetSharedOfferStub:
            return STORE.SharedOfferStubs;
        default:
            var ifThisBreakpointHit = "potential bug";
            break;
    }
}

function getStoreKeyFromOp(operation) {
    switch (operation) {
        case OPERATION.GetSelectionList:
        case OPERATION.SaveVehicle:
        case OPERATION.GetVehicle:
            return KEY.Vehicles;
        case OPERATION.GetNewOfferDetails:
        case OPERATION.GetNewOfferSpecificationDetails:
            return KEY.NewOffers;
        case OPERATION.LatestVersions:
        case OPERATION.GetTranslations:
        case OPERATION.GetOnRoadCosts:
        case OPERATION.LicenceLease:
        case OPERATION.LastDebugLogUpload:
        case OPERATION.LastUsageLogUpload:
        case OPERATION.GetAppConfig.Code:
        case OPERATION.GetCommonPackDetails:
        case OPERATION.GetLegislationList:
        case OPERATION.GetLegislationDetails:
        case OPERATION.GetUserSettings:
        case OPERATION.SaveUserSettings.Code:
        case OPERATION.GetVehiclePackData:
        case OPERATION.NumVehicleOpens:
        case OPERATION.LastUpdateCheck:
        case OPERATION.GetLastSharedOfferSyncTimestamp:
        case OPERATION.GetLastTeamItemsSyncTimestamp:
        case OPERATION.GetBodyMasses.Code:
        case OPERATION.GetLicenceCategories.Code:
            return KEY.Properties;
        case OPERATION.SyncQueue:
            return KEY.SyncQueue;
        case OPERATION.Customer:
        case OPERATION.GetCustomer:
        case OPERATION.SaveCustomer.Code:
            return KEY.Customers;
        case OPERATION.SalesPerson:
            return KEY.SalesPeople;
        case OPERATION.GetExistingOfferDetails:
        case OPERATION.GetExistingOfferSpecificationDetails:
        case OPERATION.GetSharedOfferDetails.Code:
            return KEY.SavedOffers;
        case OPERATION.SaveOffer.Code:
            return KEY.SavedOffers;
        case OPERATION.LogDebugMessage:
        case OPERATION.PostDebugInfo.Code:
        case OPERATION.GetDebugMessage:
        case OPERATION.DeleteDebugLogs:
            return KEY.DebugLog;
        case OPERATION.PostUsageInfo.Code:
        case OPERATION.DeleteUsageLogs:
        case OPERATION.LogAppUsage:
            return KEY.UsageLog;
        case OPERATION.GetBodies:
        case OPERATION.GetBody:
        case OPERATION.SaveBody:
        case OPERATION.GetBodyStubs:
            return KEY.Bodies;
        case OPERATION.GetAccessories:
        case OPERATION.GetAccessory:
        case OPERATION.SaveAccessory:
        case OPERATION.GetAccessoryStubs:
            return KEY.Accessories;
        case OPERATION.GetTrailerStubs:
        case OPERATION.GetTrailer:
        case OPERATION.GetTrailers:
        case OPERATION.SaveTrailer:
            return KEY.Trailers;
        case OPERATION.GetPayloadStubs:
        case OPERATION.GetPayload:
        case OPERATION.SavePayload:
        case OPERATION.GetPayloads:
            return KEY.Payloads;
        case OPERATION.GetSharedOfferStubs.Code:
        case OPERATION.SaveSharedOfferStub:
        case OPERATION.GetSharedOfferStub:
            return KEY.SharedOfferStubs;
        default:
            var ifThisBreakpointHit = "potential bug";
            break;
    }
}

/**
 * Helper method for massaging cache data before passing back to caller. The purpose of this method is to decompress the various parts of the data.
 * @method handleCustomersStoreCallback
 * @param {Object} cachedObj The cached object.
 * @param {Object} dfd The Deferred used to pass the data back to the caller.
 * @return Returns requested cached data or null.
 */
function handleCustomersStoreCallback(cachedObj, dfd) {
    if (cachedObj === undefined || cachedObj === null) {
        dfd.resolve(null);
    } else {

        if (cachedObj.Notes) {
            cachedObj.Notes = JSON.parse(LZString.decompressFromUTF16(cachedObj.Notes));
        } else {
            cachedObj.Notes = [];
        }
        if (cachedObj.Actions) {
            cachedObj.Actions = JSON.parse(LZString.decompressFromUTF16(cachedObj.Actions));
        } else {
            cachedObj.Actions = [];
        }
        if (cachedObj.Offers) {
            cachedObj.Offers = JSON.parse(LZString.decompressFromUTF16(cachedObj.Offers));
        } else {
            cachedObj.Offers = [];
        }

        if (cachedObj.Notes === null) {
            cachedObj.Notes = [];
        }
        if (cachedObj.Actions === null) {
            cachedObj.Actions = [];
        }
        if (cachedObj.Offers === null) {
            cachedObj.Offers = [];
        }
        dfd.resolve(cachedObj);
    }
}

function handleUserStoreCallback(cachedObj, dfd) {

}

/**
 * Helper method for massaging cache data before passing back to caller. The purpose of this method is to decompress the data.
 * @method handlePropertiesStoreCallback
 * @param {Object} cachedObj The cached object.
 * @param {String} prop The name of the property.
 * @param {Object} dfd The Deferred used to pass the data back to the caller.
 * @return Returns requested cached data.
 */
function handlePropertiesStoreCallback(cachedObj, prop, dfd) {
    var cachedData = LZString.decompressFromUTF16(cachedObj.data);
    var retVal;
    switch (prop) {

        case PROPERTY.LastDebugLogUpload:
        case PROPERTY.LastUsageLogUpload:
        case PROPERTY.LicenceLeaseExpiryDate:
        case PROPERTY.NumVehicleOpens:
        case PROPERTY.LastUpdateCheck:
            retVal = cachedData;
            break;
        case PROPERTY.Versions:
        case PROPERTY.Translations:
        case PROPERTY.OnRoadCosts:
        case PROPERTY.AppConfig:
        case PROPERTY.CommonPackDetails:
        case PROPERTY.LegislationDetails:
        case PROPERTY.VehiclePackData:
        case PROPERTY.UserSettings:
        case PROPERTY.BodyMasses:
        case PROPERTY.LicenceCategories:
            retVal = JSON.parse(cachedData);
            break;
        default:
            retVal = cachedData;
            break;
    }

    dfd.resolve(retVal);
}

/**
 * Helper method for massaging cache data before passing back to caller. Not currently any actual work been done here but that may change at some point.
 * @method handleVehiclesStoreCallabck
 * @param {Object} cachedObj The cached object.
 * @param {Object} dfd The Deferred used to pass the data back to the caller.
 * @return Resolves requested cached data.
 */
function handleVehiclesStoreCallback(cachedObj, dfd) {
    dfd.resolve(cachedObj);
}

/**
 * Helper method for massaging cache data before passing back to caller. Decompresses description and stacktrace fields.
 * @method handleVehiclesStoreCallabck
 * @param {Object} cachedObj The cached object.
 * @param {Object} dfd The Deferred used to pass the data back to the caller.
 * @return Resolves requested cached data.
 */
function handleDebugLogStoreCallback(cachedObj, dfd) {
    cachedObj.Description = JSON.parse(LZString.decompressFromUTF16(cachedObj.Description));
    cachedObj.StackTrace = JSON.parse(LZString.decompressFromUTF16(cachedObj.StackTrace));
    dfd.resolve(cachedObj);
}

/**
 * Method for storing images(dataUrl) in the DB.
 * @method putImageInDb
 * @param {String} dataUrl The dataUrl of the image to be stored.
 * @param {String} imageURL The url of the image being stored, used as the key for the image in the DB/Images objectstore.
 * @param {String} pack The pack the image is associated with.
 * @return Resolves or rejects depending on whether image successfully added or not.
 */
function putImageInDb(dataURL, imageURL, pack) {
    var dfd = $.Deferred();

    var imgObj = {
        imageurl: imageURL,
        dataurl: dataURL,
        pack: pack || 'not_set'
    };
    // Open a transaction to the database
    var readWriteMode = typeof IDBTransaction.READ_WRITE == "undefined" ? "readwrite" : IDBTransaction.READ_WRITE;
    var transaction = db.transaction("images", readWriteMode);

    // Put the image object into the database
    var put = transaction.objectStore("images").put(imgObj);
    put.onsuccess = function (event) {
        dfd.resolve();
    };

    put.onerror = function (event) {
        dfd.reject();
    };

    return dfd.promise();

}

/**
 * Method for handling special case of config drawing i.e. standard images will be the same across users however config drawings potentially won't be so need username_cdg id massaging to differentiate them. Wraps putImageInDb
 * changing the key along the way.
 * @method putConfigDrawingInDb
 * @param {String} dataUrl The dataUrl of the image to be stored.
 * @param {String} imageURL The url of the image being stored, used as the key for the image in the DB/Images objectstore.
 * @param {String} pack The pack the image is associated with.
 * @return Resolves or rejects depending on whether image successfully added or not.
 */
function putConfigDrawingInDb(dataURL, imageURL, pack) {
    imageURL = changeKeyIfNecessary("IN", imageURL);
    return putImageInDb(dataURL, imageURL, PACK.Vehicle);
}

/**
 * Method for retrieving images(dataUrl) from the DB.
 * @method getImageIfInDB
 * @param {String} imageURL The url of the image to be retrieved.
 * @param {Object} optionalDataObj Convenience param that allows the possibility of passing the object that the retrieved dataUrl will be used with. Makes life easier with promise arrays and closure scopes!
 * @return Resolves the original params plus the retrieved dataUrl/image if image found or just resolves the original imageUrl if not.
 */
function getImageIfInDB(imageURL, optionalDataObj) {
    var dfd = $.Deferred();

    var readWriteMode = typeof IDBTransaction.READ_WRITE == "undefined" ? "readwrite" : IDBTransaction.READ_WRITE;
    var transaction = db.transaction(["images"], readWriteMode);
    var objectStoreRequest = transaction.objectStore("images").get(imageURL);

    objectStoreRequest.onsuccess = function (event) {
        var imgFile = event.target.result;
        if (imgFile === undefined) {
            dfd.resolve(imageURL);
        } else {
            dfd.resolve(imageURL, imgFile.dataurl, optionalDataObj);
        }
    };

    objectStoreRequest.onerror = function (event) {
        dfd.resolve(imageURL);
    };

    return dfd.promise();
}

/**
 * Method for retrieving all customers from the DB. Decompresses Notes/Actions/Offers on the way past. Wraps/uses helper function 'getAllItems' to actually retrieve the customers.
 * @method getCustomersLocal
 * @return Resolves the array of customers or errorMessage if no customers found.
 */
function getCustomersLocal() {
    var dfd = $.Deferred();

    var data = {};
    data.Customers = [];

    data.Result = {};
    data.Result.ReturnCode = -1;
    getAllItems(STORE.Customers, function (custs) {
        if (custs === undefined) {
            dfd.reject({
                errorMessage: "no data"
            });
        } else {
            for (var i = 0; i < custs.length; i++) {
                adjustKeyAndDataOnWayOut(OPERATION.Customer, custs[i]);
                custs[i].Notes = JSON.parse(LZString.decompressFromUTF16(custs[i].Notes));
                custs[i].Actions = JSON.parse(LZString.decompressFromUTF16(custs[i].Actions));
                custs[i].Offers = JSON.parse(LZString.decompressFromUTF16(custs[i].Offers));
            }
            data.Customers = custs;
            data.Result.ReturnCode = 1;
            dfd.resolve(data);
        }

    });


    return dfd.promise();
}

/**
 * Method for retrieving array of all customers Id and OverallUpdateCounter pairs to be used by the server to determine what needs to be updated. Wraps 'getCustomersLocal' and returns an array of stub customers.
 * @method getCustomersSyncList
 * @return Resolves the array of customers stubs containing Id and OverallUpdateCounter or empty array if no customers found.
 */
function getCustomersSyncList() {
    var dfd = $.Deferred();

    var custSyncList = [];
    var fullCustomers = [];
    getCustomersLocal()
        .then(function (customers) {
            for (var i = 0; i < customers.Customers.length; i++) {
                custSyncList.push({
                    Id: customers.Customers[i].Id,
                    OverallUpdateCounter: customers.Customers[i].OverallUpdateCounter
                });
                fullCustomers.push(customers.Customers[i]);
            }
            dfd.resolve({
                syncList: custSyncList,
                fullCustomers: fullCustomers
            });
        })
        .fail(function () {
            dfd.resolve({
                syncList: custSyncList,
                fullCustomers: fullCustomers
            });
        });

    return dfd.promise();
}

/**
 * Method for retrieving all offers associated with a customerId. Uses CustomerId index on SavedOffers object store to open cursor of only those offers relating to the customer. Decompresses on the way past.
 * @method getAllCustomersOffers
 * @param {Number} customerId The id of the customer whose offers are to be retrieved.
 * @return Resolves the array of offers relating to the customerId or empty array if no offers found. 
 */
function getAllCustomersOffers(customerId) {
    var dfd = $.Deferred();
    var customersOffers = [];

    //if (customerId.indexOf(loggedInUsername) !== -1) {
    //    customerId = getCorrectKeyOut(customerId);
    //}
    customerId = changeKeyIfNecessary("OUT", customerId);

    getObjectStore(STORE.SavedOffers, "readonly")
        .then(function (objectStore) {
            var index = objectStore.index('CustomerId');
            var keyRange = IDBKeyRange.only(customerId);
            var custOfferCursorRequest = index.openCursor(keyRange);

            custOfferCursorRequest.onsuccess = function (event) {
                var cursor = event.target.result;
                if (cursor) {
                    var decompressedCustOffer = handleSavedOffersStoreCallback(cursor.value, OPERATION.GetExistingOfferDetails);
                    customersOffers.push(decompressedCustOffer);
                    cursor["continue"]();
                } else {
                    dfd.resolve(customersOffers);
                }

            };

            custOfferCursorRequest.onerror = function (event) {
                dfd.resolve(customersOffers);
            };

        });

    return dfd.promise();
}

/**
 * Method for retrieving all the customers and users required for the offers screen. Decompresses on the way past.
 * @method getSalesPeopleLocal
 * @return Resolves data object containing array of Users, Customers and ReturnCode as this is what is expected by viewmodel. An empty customers array may be returned as no customers is a possibilty
 * however no users is not possible as at the vary least the should be one user i.e. the logged in user, so if that part fails the method rejects with error message.
 */
function getSalesPeopleLocal() {
    var dfd = $.Deferred();

    var data = {};
    data.Customers = [];
    data.Users = [];
    data.Result = {};
    data.Result.ReturnCode = -1;
    getAllItems(STORE.Customers, function (custs) {
        if (custs === undefined || custs.length === 0) {
            doGetUsersPart();
        } else {
            for (var i = 0; i < custs.length; i++) {
                adjustKeyAndDataOnWayOut(OPERATION.Customer, custs[i]);
                custs[i].Notes = JSON.parse(LZString.decompressFromUTF16(custs[i].Notes));
                custs[i].Actions = JSON.parse(LZString.decompressFromUTF16(custs[i].Actions));
                custs[i].Offers = JSON.parse(LZString.decompressFromUTF16(custs[i].Offers));
            }
            data.Customers = custs;
            doGetUsersPart();
        }

    });

    function doGetUsersPart() {
        getAllItems(STORE.SalesPeople, function (salespeople) {
            if (salespeople === undefined || salespeople.length === 0) {
                dfd.reject({
                    errorMessage: "no data"
                });
            } else {
                for (var i = 0; i < salespeople.length; i++) {
                    adjustKeyAndDataOnWayOut(OPERATION.SalesPerson, salespeople[i]);
                }
                salespeople.reverse();
                data.Users = salespeople;
                data.Result.ReturnCode = 1;
                data.UserId = data.Users[0].Id;
                dfd.resolve(data);
            }
        });
    }
    return dfd.promise();
}

/**
 * Method for retrieving the vehicles for the selection list based on application and vehicleType filters. Matched vehicles are sorted on GVW ascending.
 * @method getSelectionListLocal
 * @param {String} application The application grouping of the requested vehicles.
 * @param {String} vehicleType The vehicle type of the requested vehicles.
 * @return Resolves data object containing array of matching vehicles and a ReturnCode.
 */
function getSelectionListLocal(application, vehicleType) {
    var dfd = $.Deferred();

    var data = {};
    data.Offers = [];
    data.Result = {};
    data.Result.ReturnCode = -1;
    getAllItems(STORE.Vehicles, function (vehicles) {
        if (vehicles.length > 0) {
            for (var i = 0; i < vehicles.length; i++) {
                if (application === "" && vehicleType === "") {
                    adjustKeyAndDataOnWayOut(OPERATION.GetSelectionList, vehicles[i]);
                    data.Offers.push(vehicles[i]);
                    //data.Result.ReturnCode = 1;
                } else if ((($.inArray(application, vehicles[i]["Applications"])) !== -1) && (vehicles[i]["VehicleType"] === vehicleType)) {
                    adjustKeyAndDataOnWayOut(OPERATION.GetSelectionList, vehicles[i]);
                    data.Offers.push(vehicles[i]);

                }
            }
            data.Result.ReturnCode = 1;
            //dfd.resolve(data);
        }
        //else {
        //    //dfd.reject({ errorMessage: "no data" });

        //}
        dfd.resolve(data);
    });


    return dfd.promise();
}


function getSharedOfferStubsLocal() {
    var dfd = $.Deferred();

    var data = {};
    data.Offers = [];
    data.Result = {};
    data.Result.ReturnCode = -1;
    getAllItems(STORE.SharedOfferStubs, function (sharedOfferStubs) {
        if (sharedOfferStubs.length > 0) {
            for (var i = 0; i < sharedOfferStubs.length; i++) {
                sharedOfferStubs[i].data = JSON.parse(LZString.decompressFromUTF16(sharedOfferStubs[i].data));
                adjustKeyAndDataOnWayOut(OPERATION.GetSharedOfferStubs, sharedOfferStubs[i].data);
                data.Offers.push(sharedOfferStubs[i].data);
            }
            data.Result.ReturnCode = 1;
            //dfd.resolve(data);
        }
        //else {
        //    //dfd.reject({ errorMessage: "no data" });

        //}
        dfd.resolve(data);
    });


    return dfd.promise();
}

function getSharedOfferStubLocal() {
    var dfd = $.Deferred();

    var data = {};
    data.Offers = [];
    data.Result = {};
    data.Result.ReturnCode = -1;
    getAllItems(STORE.SharedOfferStubs, function (sharedOfferStubs) {
        if (sharedOfferStubs.length > 0) {
            for (var i = 0; i < sharedOfferStubs.length; i++) {
                sharedOfferStubs[i].data = JSON.parse(LZString.decompressFromUTF16(sharedOfferStubs[i].data));
                adjustKeyAndDataOnWayOut(OPERATION.GetSharedOfferStubs, sharedOfferStubs[i].data);
                data.Offers.push(sharedOfferStubs[i].data);
            }
            data.Result.ReturnCode = 1;
            //dfd.resolve(data);
        }
        //else {
        //    //dfd.reject({ errorMessage: "no data" });

        //}
        dfd.resolve(data);
    });


    return dfd.promise();
}


/**
 * Method for retrieving the cached bodies. 
 * @method getBodiesLocal
 * @return Resolves data object containing array of bodies and a ReturnCode.
 */
function getBodiesLocal() {
    var dfd = $.Deferred();

    var data = {};
    data.Bodies = [];
    data.Result = {};
    data.Result.ReturnCode = -1;
    getAllItems(STORE.Bodies, function (bodies) {
        if (bodies.length > 0) {
            for (var i = 0; i < bodies.length; i++) {
                //adjustKeyAndDataOnWayOut(OPERATION.GetBodies, bodies[i]);
                bodies[i].data = JSON.parse(LZString.decompressFromUTF16(bodies[i].data));
                if ((bodies[i].data.Name !== undefined && bodies[i].data.AccessLevel === undefined) || (bodies[i].data.Name !== undefined && bodies[i].data.AccessLevel !== undefined && bodies[i].data.AccessLevel === config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL)) {
                    bodies[i].data.cached = 2;
                } else {
                    bodies[i].data.cached = 0;
                }
                adjustKeyAndDataOnWayOut(OPERATION.GetBodies, bodies[i].data);
                data.Bodies.push(bodies[i].data);
            }
            data.Result.ReturnCode = 1;
        }
        dfd.resolve(data);
    });


    return dfd.promise();
}

/**
 * Method for retrieving the cached trailers. 
 * @method getTrailersLocal
 * @return Resolves data object containing array of trailers and a ReturnCode.
 */
function getTrailersLocal() {
    var dfd = $.Deferred();

    var data = {};
    data.Trailers = [];
    data.Result = {};
    data.Result.ReturnCode = -1;
    getAllItems(STORE.Trailers, function (trailers) {
        if (trailers.length > 0) {
            for (var i = 0; i < trailers.length; i++) {
                //adjustKeyAndDataOnWayOut(OPERATION.GetBodies, bodies[i]);
                trailers[i].data = JSON.parse(LZString.decompressFromUTF16(trailers[i].data));
                if ((trailers[i].data.Name !== undefined && trailers[i].data.AccessLevel === undefined) || (trailers[i].data.Name !== undefined && trailers[i].data.AccessLevel !== undefined && trailers[i].data.AccessLevel === config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL)) {
                    trailers[i].data.cached = 2;
                } else {
                    trailers[i].data.cached = 0;
                }
                adjustKeyAndDataOnWayOut(OPERATION.GetTrailers, trailers[i].data);
                data.Trailers.push(trailers[i].data);
            }
            data.Result.ReturnCode = 1;
        }
        dfd.resolve(data);
    });


    return dfd.promise();
}

/**
 * Method for retrieving the cached trailers. 
 * @method getTrailersLocal
 * @return Resolves data object containing array of trailers and a ReturnCode.
 */
function getPayloadsLocal() {
    var dfd = $.Deferred();

    var data = {};
    data.Payloads = [];
    data.Result = {};
    data.Result.ReturnCode = -1;
    getAllItems(STORE.Payloads, function (payloads) {
        if (payloads.length > 0) {
            for (var i = 0; i < payloads.length; i++) {
                //adjustKeyAndDataOnWayOut(OPERATION.GetBodies, bodies[i]);
                payloads[i].data = JSON.parse(LZString.decompressFromUTF16(payloads[i].data));
                if ((payloads[i].data.Name !== undefined && payloads[i].data.AccessLevel === undefined) || (payloads[i].data.Name !== undefined && payloads[i].data.AccessLevel !== undefined && payloads[i].data.AccessLevel === config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL)) {
                    payloads[i].data.cached = 2;
                } else {
                    payloads[i].data.cached = 0;
                }
                adjustKeyAndDataOnWayOut(OPERATION.GetPayloads, payloads[i].data);
                data.Payloads.push(payloads[i].data);
            }
            data.Result.ReturnCode = 1;
        }
        dfd.resolve(data);
    });


    return dfd.promise();
}

/**
 * Method for retrieving the cached accessories. 
 * @method getAccessoriesLocal
 * @return Resolves data object containing array of a specific type of accessory and a ReturnCode.
 */
function getSpecificAccessoryLocal(op, propertyName) {
    var dfd = $.Deferred();

    var data = {};
    data[propertyName] = [];
    data.Result = {};
    data.Result.ReturnCode = -1;
    getAllItems(getStoreFromOp(op), function (accessories) {
        if (accessories.length > 0) {
            for (var i = 0; i < accessories.length; i++) {
                accessories[i].data = JSON.parse(LZString.decompressFromUTF16(accessories[i].data));
                adjustKeyAndDataOnWayOut(op, accessories[i].data);
                data[propertyName].push(accessories[i].data);
            }
            data.Result.ReturnCode = 1;
        }
        dfd.resolve(data);
    });


    return dfd.promise();
}

//function getCranesLocal() {
//    return getSpecificAccessoryLocal(OPERATION.GetCranes, 'Cranes');
//}

function getAccessoriesLocal() {
    var dfd = $.Deferred();

    var data = {};
    data.Result = {};
    //data.Cranes = [];
    //data.Others = [];
    //data.Fridges = [];
    //data.Taillifts = [];
    data.Accessories = [];
    data.Result.ReturnCode = -1;

    //$.when(getCranesLocal())
    //    .done(function (cranes) {
    //        if (cranes.Result.ReturnCode !== -1) {
    //            data['Cranes'] = cranes.Cranes;
    //            data.Result.ReturnCode = 1;
    //        }

    //        dfd.resolve(data);
    //    });
    getAllItems(STORE.Accessories, function (accessories) {
        if (accessories.length > 0) {
            for (var i = 0; i < accessories.length; i++) {

                accessories[i].data = JSON.parse(LZString.decompressFromUTF16(accessories[i].data));
                adjustKeyAndDataOnWayOut(OPERATION.GetAccessories, accessories[i].data);
                //data[propertyName].push(accessories[i].data);
                //if (accessories[i].data.AccessoryType === config.ACCESSORY_TYPES.CRANE) {
                //    data['Cranes'].push(accessories[i].data);
                //    data.Result.ReturnCode = 1;
                //} else if (accessories[i].data.AccessoryType === config.ACCESSORY_TYPES.OTHER) {
                //    data['Others'].push(accessories[i].data);
                //    data.Result.ReturnCode = 1;
                //} else if (accessories[i].data.AccessoryType === config.ACCESSORY_TYPES.FRIDGE) {
                //    data['Fridges'].push(accessories[i].data);
                //    data.Result.ReturnCode = 1;
                //} else if (accessories[i].data.AccessoryType === config.ACCESSORY_TYPES.TAILLIFT) {
                //    data['Taillifts'].push(accessories[i].data);
                //    data.Result.ReturnCode = 1;
                //}
                data.Accessories.push(accessories[i].data);
                if ((accessories[i].data.Name !== undefined && accessories[i].data.AccessLevel === undefined) || (accessories[i].data.Name !== undefined && accessories[i].data.AccessLevel !== undefined && accessories[i].data.AccessLevel === config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL)) {
                    accessories[i].data.cached = 2;
                } else {
                    accessories[i].data.cached = 0;
                }
            }
            data.Result.ReturnCode = 1;
        }
        dfd.resolve(data);
    });

    return dfd.promise();
}

function getAllLegislations() {
    var dfd = $.Deferred();

    var store = getStoreFromOp(OPERATION.GetLegislationDetails);
    var key = changeKeyIfNecessary("IN", PROPERTY.LegislationDetails);
    getObjectStore(store, "readonly").then(function (objectStore) {
        var objectStoreRequest = objectStore.get(key);
        objectStoreRequest.onsuccess = function (event) {
            if (event.target.result === undefined) {
                dfd.resolve(null);
            } else {
                var cachedObj = event.target.result;
                handlePropertiesStoreCallback(cachedObj, changeKeyIfNecessary("OUT", key), dfd);
            }
        };
        objectStoreRequest.onerror = function (event) {
            dfd.resolve(null);
        }
    }).fail(function () {
        dfd.resolve(null);
    });

    return dfd.promise();
}

function getLocalLegislationDetailsById(legislationId, countryId) {
    var dfd = $.Deferred();
    getAllLegislations()
        .then(function (legislations) {
            if (legislations !== null) {
                var theLegislationDetails = legislations.filter(function (legislationDetails) {
                    return legislationDetails.LegislationId === legislationId && legislationDetails.CountryId === countryId;
                });
                if (theLegislationDetails !== undefined && theLegislationDetails.length > 0) {
                    dfd.resolve(theLegislationDetails[0]);
                } else {
                    dfd.resolve(null);
                }
            } else {
                dfd.resolve(null);
            }

        });


    return dfd.promise();
}


function getLogDumpForPost() {
    var dfd = $.Deferred();

    var data = {};
    data.Messages = [];

    getAllItems(STORE.DebugLog, function (logMessages) {
        if (logMessages.length > 0) {
            for (var i = 0; i < logMessages.length; i++) {
                adjustKeyAndDataOnWayOut(OPERATION.GetDebugMessage, logMessages[i]);
                logMessages[i].MessageLoggedDate = moment.utc(Number(logMessages[i].TimeStamp)).format();
                delete logMessages[i].TimeStamp;
                logMessages[i].Description = JSON.parse(LZString.decompressFromUTF16(logMessages[i].Description));
                logMessages[i].StackTrace = JSON.parse(LZString.decompressFromUTF16(logMessages[i].StackTrace));
                data.Messages.push(logMessages[i]);
            }
            dfd.resolve(data);
        } else {
            dfd.reject({
                errorMessage: "no data"
            });
        }
    });


    return dfd.promise();
}

function getUsageInfoForPost() {
    var dfd = $.Deferred();

    var data = {};
    data.Usage = [];
    data.Snapshot = {};

    getAllItems(STORE.UsageLog, function (appUsageLogs) {
        if (appUsageLogs.length > 0) {

            for (var i = 0; i < appUsageLogs.length; i++) {
                adjustKeyAndDataOnWayOut(OPERATION.PostUsageInfo.Code, appUsageLogs[i]);
                var date = new Date(Number(appUsageLogs[i].TimeStamp));
                var month = date.getMonth() + 1;
                var year = date.getFullYear();
                var curUsageMonth = null;
                for (var j = 0; j < data.Usage.length; j++) {
                    var tempMonth = data.Usage[j];
                    if (tempMonth.month === month && tempMonth.year === year) {
                        curUsageMonth = tempMonth;
                        break;
                    }
                }
                if (curUsageMonth === null) {
                    curUsageMonth = new usageMonth(month, year);
                    data.Usage.push(curUsageMonth);
                }

                updateUsage(curUsageMonth, appUsageLogs[i]);

            }
            getFromCache(OPERATION.LatestVersions, PROPERTY.Versions)
                .then(function (versions) {
                    data.Snapshot.AppConfigVersion = getVersionVal(versions, "appConfigVersion");
                    data.Snapshot.CommonVersion = getVersionVal(versions, "commonVersion");
                    data.Snapshot.VehicleVersion = getVersionVal(versions, "vehicleVersion");
                    data.Snapshot.GraphicVersion = getVersionVal(versions, "graphicVersion");
                    data.Snapshot.MapVersion = getVersionVal(versions, "mapVersion");
                    data.Snapshot.CompanyVersion = getVersionVal(versions, "companyVersion");
                    data.Snapshot.OnRoadVersion = getVersionVal(versions, "onroadVersion");

                    data.Snapshot.HTML5Version = config.appVersionNumber;
                    data.Snapshot.AvailableResolution = globals.getBrowserDimensions().width + "x" + globals.getBrowserDimensions().height;
                    var width = screen.width || '0';
                    var height = screen.height || '0';
                    data.Snapshot.ScreenResolution = width + "x" + height;
                    data.Snapshot.BrowserType = globals.getBrowser();
                    data.Snapshot.Standalone = window.navigator.standalone || false;
                    data.Snapshot.OperatingSystem = globals.getOperatingSystem();
                    data.Snapshot.BrowserVersion = globals.getBrowserVersion();

                    dfd.resolve(data);

                    function getVersionVal(objectGroup, keyToGet) {
                        for (var key in objectGroup) {
                            if (objectGroup[key]) {
                                if (typeof objectGroup[key] === 'object') {
                                    if (objectGroup[key]["Key"] === keyToGet) {
                                        return objectGroup[key]["Value"];
                                    }

                                }
                            }
                        }
                        return null;
                    }
                });


        } else {
            dfd.reject({
                errorMessage: "no data"
            });
        }
    });

    function usageMonth(month, year,
        online, offline,
        performanceOpened, performanceUsed,
        costingOpened, costingUsed,
        specificationOpened, specificationUsed,
        summaryOpened, summaryUsed,
        companyOverviewOpened, companyOverviewUsed,
        brochureOpened, brochureUsed,
        configurationOpened, configurationUsed,
        trainingOpened, trainingUsed,
        offersOpened, offersUsed) {


        this.month = month;
        this.year = year;
        this.online = online || 0;
        this.offline = offline || 0;
        this.performanceOpened = performanceOpened || 0;
        this.performanceUsed = performanceUsed || 0;
        this.costingOpened = costingOpened || 0;
        this.costingUsed = costingUsed || 0;
        this.specificationOpened = specificationOpened || 0;
        this.specificationUsed = specificationUsed || 0;
        this.summaryOpened = summaryOpened || 0;
        this.summaryUsed = summaryUsed || 0;
        this.companyOverviewOpened = companyOverviewOpened || 0;
        this.companyOverviewUsed = companyOverviewUsed || 0;
        this.brochureOpened = brochureOpened || 0;
        this.brochureUsed = brochureUsed || 0;
        this.configurationOpened = configurationOpened || 0;
        this.configurationUsed = configurationUsed || 0;
        this.trainingOpened = trainingOpened || 0;
        this.trainingUsed = trainingUsed || 0;
        this.offersOpened = offersOpened || 0;
        this.offersUsed = offersUsed || 0;
    }

    function updateUsage(usageAggregator, usageLog) {
        switch (usageLog.UsageType) {
            case config.USAGE_TYPE.ONLINE:
                usageAggregator.online++;
                break;
            case config.USAGE_TYPE.OFFLINE:
                usageAggregator.offline++;
                break;
            case config.USAGE_TYPE.PERFORMANCE_OPENED:
                usageAggregator.performanceOpened++;
                break;
            case config.USAGE_TYPE.PERFORMANCE_USED:
                usageAggregator.performanceUsed++;
                break;
            case config.USAGE_TYPE.COSTING_OPENED:
                usageAggregator.costingOpened++;
                break;
            case config.USAGE_TYPE.COSTING_USED:
                usageAggregator.costingUsed++;
                break;
            case config.USAGE_TYPE.SPECIFICATION_OPENED:
                usageAggregator.specificationOpened++;
                break;
            case config.USAGE_TYPE.SPECIFICATION_USED:
                usageAggregator.specificationUsed++;
                break;
            case config.USAGE_TYPE.SUMMARY_OPENED:
                usageAggregator.summaryOpened++;
                break;
            case config.USAGE_TYPE.SUMMARY_USED:
                usageAggregator.summaryUsed++;
                break;
            case config.USAGE_TYPE.COMPANY_OVERVIEW_OPENED:
                usageAggregator.companyOverviewOpened++;
                break;
            case config.USAGE_TYPE.COMPANY_OVERVIEW_USED:
                usageAggregator.companyOverviewUsed++;
                break;
            case config.USAGE_TYPE.OFFERS_OPENED:
                usageAggregator.offersOpened++;
                break;
            case config.USAGE_TYPE.OFFERS_USED:
                usageAggregator.offersUsed++;
                break;
            case config.USAGE_TYPE.BROCHURE_OPENED:
                usageAggregator.brochureOpened++;
                break;
            case config.USAGE_TYPE.BROCHURE_USED:
                usageAggregator.brochureUsed++;
                break;
            case config.USAGE_TYPE.CONFIGURATION_OPENED:
                usageAggregator.configurationOpened++;
                break;
            case config.USAGE_TYPE.CONFIGURATION_USED:
                usageAggregator.configurationUsed++;
                break;
            case config.USAGE_TYPE.TRAINING_OPENED:
                usageAggregator.trainingOpened++;
                break;
            case config.USAGE_TYPE.TRAINING_USED:
                usageAggregator.trainingUsed++;
                break;
        }


    }
    return dfd.promise();
}

/**
    * Method for retrieving any Sync Items that have been queued. Wraps 'getAllItems'. Decompresses sync item Params on the way past.
    * @method checkIfSyncItems
    * @return Resolves array of sync items or empty array if none found.
    */
function checkIfSyncItems() {
    var dfd = $.Deferred();

    getAllItems(STORE.SyncQueue, function (syncItems) {
        for (var i = 0; i < syncItems.length; i++) {
            adjustKeyAndDataOnWayOut(OPERATION.SyncQueue, syncItems[i]);
            syncItems[i].Params = JSON.parse(LZString.decompressFromUTF16(syncItems[i].Params));
        }
        dfd.resolve(syncItems);
    });


    return dfd.promise();
}

/**
    * Method for resetting the 'OfferCached' flag on vehicles when cached new offers are cleared. Used in handleVersions in dataManager. Wraps 'getAllItems', resets flag and writes vehicle back to db.
    * @method resetVehiclesOfferCachedFlag
    */
function resetVehiclesOfferCachedFlag() {
    getAllItems(STORE.Vehicles, function (vehicles) {
        for (var i = 0; i < vehicles.length; i++) {
            vehicles[i].OfferCached = 0;
            addToCache(OPERATION.SaveVehicle, vehicles[i].id, vehicles[i]);
        }
    });
}

/**
    * Method for clearing all cached offers for any updated customers returned by the server from a sync operation. Uses 'getAllCustomersCachedOffers' for each updated customer
    * and then deletes the saved offers if any.
    * @method clearCustomersCachedOffers
    * @param {Array} updateCusts An array of customers.
    */
function clearCustomersCachedOffers(updatedCusts) {
    var dfd = $.Deferred();

    doClearCustomersCachedOffers(updatedCusts);

    function doClearCustomersCachedOffers(customerArr) {

        var curCust = customerArr.shift();

        if (curCust !== undefined && curCust !== null) {
            getAllCustomersOffers(curCust.Id)
                .then(function (custsOffers) {
                    for (var i = 0; i < custsOffers.length; i++) {
                        deleteRecord(STORE.SavedOffers, custsOffers[i].OfferId);
                    }
                    if (customerArr.length > 0) {
                        doClearCustomersCachedOffers(customerArr);
                    } else {
                        dfd.resolve();
                    }

                });
        } else {
            dfd.resolve();
        }
    }

    return dfd.promise();
}

/**
    * Method for carrying out batch jobs and notifying the caller when jobs have completed.
    * @method batchUpdate
    * @param {String} operation The operation being carried out.
    * @param {Array} arr The array of objects the operation is to be carried out on.
    * @return Resolves when the batch update has completed.
    */
function batchUpdate(operation, arr, existingDfd, startIndex) {

    var dfd;
    if (existingDfd === undefined) {
        dfd = $.Deferred();
    } else {
        dfd = existingDfd;
    }


    var jobs = [];
    var breakingOut = false;

    var i = startIndex || 0;

    switch (operation) {
        case OPERATION.SaveCustomer.Code:
        case OPERATION.SalesPerson:
            for (i = 0; i < arr.length; i++) {
                jobs.push(addToCache(operation, null, arr[i]));
            }
            $.when.apply($, jobs)
                .done(function () {
                    dfd.resolve();
                });
            break;
        case OPERATION.GetBodies:
        case OPERATION.GetBodyStubs:
        case OPERATION.GetSelectionList:
        case OPERATION.GetAccessories:
        case OPERATION.GetAccessoryStubs:
        case OPERATION.GetTrailerStubs:
        case OPERATION.GetPayloadStubs:
        case OPERATION.GetSharedOfferStubs.Code:
            var breakOutIndex = 0;
            if (i !== 0) {
                i++;
            }
            for (; i < arr.length; i++) {
                if (curBatchJob.getIsCancelled() === false) {
                    if (operation === OPERATION.GetAccessoryStubs || operation === OPERATION.GetBodyStubs) {
                        jobs.push(addToCache(operation, arr[i].Id + '_' + arr[i].AccessoryType + '_' + arr[i].Source, arr[i]));
                    } else if (operation === OPERATION.GetTrailerStubs) {
                        jobs.push(addToCache(operation, arr[i].Id + '_' + config.ACCESSORY_TYPES.TRAILER + '_' + arr[i].Source, arr[i]));
                    } else if (operation === OPERATION.GetPayloadStubs) {
                        jobs.push(addToCache(operation, arr[i].Id + '_' + config.ACCESSORY_TYPES.PAYLOAD + '_' + arr[i].Source, arr[i]));
                    } else {
                        jobs.push(addToCache(operation, arr[i].Id, arr[i]));
                    }

                    if (i !== 0 && i % 400 === 0) {
                        breakingOut = true;
                        breakOutIndex = i;
                        break;
                    }
                } else {
                    jobs = [];
                    curBatchJob = null;
                    break;
                }
            }
            if (jobs.length > 0) {
                $.when.apply($, jobs)
                    .done(function () {
                        if (breakingOut === true) {
                            var millisecondsToWait = 750;
                            setTimeout(function () {
                                batchUpdate(operation, arr, dfd, breakOutIndex);
                            }, millisecondsToWait);

                        } else {
                            dfd.resolve();
                        }

                    });
            } else {
                dfd.resolve();
            }
            break;
        case OPERATION.DeleteDebugLogs:
            var store = getStoreFromOp(operation);
            for (i = 0; i < arr.length; i++) {
                jobs.push(deleteRecord(store, arr[i]));
            }
            $.when.apply($, jobs)
                .done(function () {
                    dfd.resolve();
                });
            break;
        default:
            dfd.resolve();
            break;
    }



    return dfd.promise();



}

function queueBatchJob(operation, arr) {
    var newBatchJob = new batchJob(operation, arr);
    batchJobQueue.push(newBatchJob);
    if (batchJobHandlerBusy === false && batchJobQueue.length === 1) {
        handleQueue();
    }
    return newBatchJob.promise;
    //if (batchJobHandlerBusy === true || batchJobQueue.length > 0) {


    //    batchJobQueue.push(newBatchJob);
    //    return newBatchJob.promise;

    //} else {
    //    return batchUpdate(operation, arr);
    //}
}

function handleQueue() {
    if (batchJobHandlerBusy === false && batchJobQueue.length > 0) {
        curBatchJob = batchJobQueue.shift();
        curBatchJob.execute();
    }
}

function batchJob(operation, arr) {
    var dfd = $.Deferred();
    var op = operation,
        array = arr;

    var cancelled = false;

    function cancel() {
        cancelled = true;
    }

    function getIsCancelled() {
        return cancelled;
    }

    function execute() {
        batchJobHandlerBusy = true;
        if (cancelled === false) {
            batchUpdate(op, array).then(function () {
                batchJobHandlerBusy = false;

                dfd.resolve();
                setTimeout(function () {
                    handleQueue();
                }, 400);
            });
        } else {
            curBatchJob = null;
        }
    }

    return {
        promise: dfd.promise(),
        execute: execute,
        cancel: cancel,
        getIsCancelled: getIsCancelled
    }
}

function clearBatchJobQueue() {
    var dfd = $.Deferred();

    batchJobQueue = [];
    if (curBatchJob !== null && curBatchJob.getIsCancelled() === false) {
        curBatchJob.promise.then(function () {
            dfd.resolve();
        });
        curBatchJob.cancel();
    } else {
        dfd.resolve();
    }


    return dfd.promise();
}

//#endregion
