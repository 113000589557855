import config from './config';
import logger from './logger';
// import  { router } from '../router';
import Globalize from '../assets/scripts/globalize/globalize';
// import $ from '../assets/scripts/jquery-3.6.0.min';
import moment from '../assets/scripts/moment.min';
// import WebuiPopovers from '../assets/scripts/jquery.webui-popover.min';
// import toastr from '../assets/scripts/toastr.min';
// import Msal from '../assets/scripts/msal.min';
import { ref, shallowRef, triggerRef, watch, computed, isRef } from 'vue';
import bus from '@/main';
// import * as msalAuth from './msal/auth';


const $ = window.$;
const WebuiPopovers = window.WebuiPopovers;
const toastr = window.toastr;
const TScMessenger = window.TScMessenger;
const CustomTruckScienceRequirements = window.CustomTruckScienceRequirements;


var
    //variables
    mapCredentials,
    user,
    displayOnlineMessage,
    cloudServicesVersion1 = 'v1',
    cloudServicesVersion2 = 'v2',
    cloudServicesVersion3 = 'v3',
    cloudServicesVersion4 = 'v4',
    isOnline = shallowRef(true),
    isSyncing = shallowRef(false),
    offerSyncing = shallowRef(false),
    appLogosDownloaded = true,
    timersAlreadySetup = false,
    onlineOfflineEventListenersAlreadySetup = shallowRef(false),
    navigatedInFromSelectionOrOffers = shallowRef(false),
    passedThroughShell = false,
    screenCounter = 0,
    justLoggedOut = false,
    lastIsOnlineValue,
    successfulBackgroundReAuth = shallowRef(false),
    nextIdCounter = 0,
    drawTrialWatermark = shallowRef(false),
    updateStatus = 'NOT_SET',
    // alreadyAuthenticated = shallowRef(false),
    eventFiredFromDrawingElement = false,
    quickSearchWithSearchTermsCompleted = shallowRef(false),
    numSearchResults,
    machineCodeFlag = shallowRef('NOT_SET'),
    forceLicenceTransferMessage,
    refreshDfd = null,
    refreshDfdCallback = null,
    refreshDfdFailCallback = null,
    sendEventInfoToIntercomCallback,
    trialStampWatermarkIsAvailable = shallowRef(false),
    authContext = null,
    registerUserUsingMicrosoft = false,
    accessCode,
    wheelbaseSpecifiedOptionJustChanged = false,
    registeredServiceWorkerContainer = null,
    openTsvModalOnSelection = false,
    costingAverageCostOfTyreMin = 0,
    costingAverageCostOfTyreMax = 500000,
    costingAverageLifeOfTyreMin = 0,
    costingAverageLifeOfTyreMax = 900000,
    costingTyreRateMin = 0,
    costingTyreRateMax = 5000,
    preventClearingOfPerformanceResultsWhenOpeningTSV = false,
    navigatingHome = false,
    // showNavButtonVars = shallowRef([false, false, false]),
    areTabsOtherThanConfigurationAndSummaryDisplayed = false,
    spinnerText = shallowRef(''),
    loginScreenActive = shallowRef(false),
    paymentMenuStatus = shallowRef(config.PAYMENT_MENU_OPTIONS.DO_NOT_DISPLAY),
    navBarTextObservable = shallowRef(),
    showYourAreAlreadySubscribedMessageFlag = false,
    showYouHaveSuccessfullySubscribedMessageFlag = false,
    openedSharedOfferToastFlag = false,
    signalRConnection = null,
    salesModeActive = shallowRef(false),
    trainingModeActive = shallowRef(false),
    isFullScreen = shallowRef(false),
    dependentActionDfd = null,
    trialStatusMessageAlreadyShownForOpenOffer = false,
    dataRefreshConcurrentPath1Complete = false,
    dataRefreshConcurrentPath2Complete = false,
    indexedDbReady = ref(false),
    dataModelReady = ref(false),
    checkingForUpdates = false,
    preventHideSpinner = false,
    isOnlineTimerRef, 
    postDebugLogsTimerRef, 
    postUsageTimerRef,
    watchersArr,
    modulesAddedOrDroppedAfterSwap = shallowRef(false),
    appConfigApplied = false,
    curWarnings,
    curTips,
    debugLegislation = false,
    curDDRNotes,
    blobStorage,
    userSettingsDfd = null,
    selectionListDfd = null,
    // passesManufacturerLimits, passesRegulatoryCompliance are temporary hack to save time for Ford demo,    
    // will eventually be moved to or replaced by something more suitable on future legislation manager object
    passesManufacturerLimits = false,
    passesRegulatoryCompliance = false,
    loginDfd = null,
    dmLoginDfd = null,
    accessToken = null,
    storageKey = '',
    preventReAuthAttempt = false,
    incomingSWVersionNumber = '',
    fullScreenButtonTooltip = shallowRef(''),
    serviceWorkerScope = '',
    activeCultureCode = null,
    currentCombinationType = null,


    // methods
    calculateMonthlyRepaymentWithInterestRate = function (totalCapitalCosts, depositRate, residualRate, interestRate, repaymentTerm) {
        var residualInterestRateCalc = totalCapitalCosts * residualRate / 100;
        var depositAmount = (totalCapitalCosts / 100) * depositRate;
        var interestRateCalc = interestRate / 12 / 100;
        return (totalCapitalCosts - depositAmount - residualInterestRateCalc) * interestRateCalc / (1 - Math.pow(1 + (interestRateCalc), (-1 * repaymentTerm))) + residualInterestRateCalc * interestRateCalc;
    },

    calculateMonthlyRepaymentWithoutInterestRate = function (totalCapitalCosts, depositRate, residualRate, repaymentTerm) {
        var depositAmount = (totalCapitalCosts / 100) * depositRate;
        return (totalCapitalCosts - depositAmount - totalCapitalCosts * residualRate / 100) / repaymentTerm;
    },

    calculateMonthlyRepaymentWithoutRepaymentTerm = function (totalCapitalCosts, depositRate, residualRate) {
        var depositAmount = (totalCapitalCosts / 100) * depositRate;
        return totalCapitalCosts - depositAmount - totalCapitalCosts * residualRate / 100;
    },

    calculateDepositAmount = function (totalCapitalCosts, depositRate) {
        return totalCapitalCosts * depositRate / 100;
    },

    calculateResidualAmount = function (totalCapitalCosts, residualRate) {
        return totalCapitalCosts * residualRate / 100;
    },

    isPrivateBrowsingDisabled = function () {
        try {
            localStorage.setItem("privateBrowsingEnabled", "true");
            localStorage.removeItem("privateBrowsingEnabled");
        } catch (ex) {
            return false;
        }
        return true;
    },

    hexToRgb = function (hex, defaultValue) {
        var white = {
            r: 255,
            g: 255,
            b: 255
        },
            defaultToUse;
        if (defaultValue !== undefined) {
            defaultToUse = typeof defaultValue === 'object' ? defaultValue : hexToRgb(defaultValue);
        } else {
            defaultToUse = white;
        }

        if (hex !== undefined) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.trim());
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : defaultToUse;
        } else {
            return defaultToUse;
        }
    },

    // rgbToHex = function (rgb) {
    //     var parts = colorval.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    //     delete (parts[0]);
    //     for (var i = 1; i <= 3; ++i) {
    //         parts[i] = parseInt(parts[i]).toString(16);
    //         if (parts[i].length == 1) parts[i] = '0' + parts[i];
    //     }
    //     color = '#' + parts.join('');
    //     return color;
    // },

    isValidEmailAddress = function (checkStr) {
        // old regex
        //if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(checkStr)) {
        // new regex from Cloud Services -> Utils -> ItilEmail -> IsValidEmailAddress
        //if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-␣]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(checkStr)) {
        //    var expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        //    if (expression.test(String(checkStr).toLowerCase())) {
        //        return true;
        //    } else {
        //        return false;
        //    }
        //} else {
        //    return false;
        //}

        // Updated regex to match regex in Cloud Services -> Utils -> ItilEmail -> IsValidEmailAddress
        return (/^[a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~-␣]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(checkStr));
    },

    checkInputNumberAndUpdateObservable = function (inputValue, inputValueType, observableToUpdate) {
        var returnVal, temp, tempInputValue;

        returnVal = false;

        if (inputValueType === "int") {
            temp = Globalize.parseInt(inputValue);
        } else {
            tempInputValue = inputValue;
            if (typeof inputValue === 'number') {
                tempInputValue = tempInputValue.toString();
            }
            temp = Globalize.parseFloat(tempInputValue);
        }

        if (!isNaN(temp) && temp !== undefined) {
            if (temp !== observableToUpdate()) {
                if (temp < 0) {
                    temp = 0;
                }
                observableToUpdate(temp);
                returnVal = true;
            }
        }

        return returnVal;
    },

    checkInputNumberAndUpdateOnRoadCost = function (inputValue, inputValueType, onRoadCost) {
        var returnVal, temp;

        returnVal = false;

        if (inputValueType === "int") {
            temp = Globalize.parseInt(inputValue);
        } else {
            temp = Globalize.parseFloat(inputValue);
        }

        if (!isNaN(temp) && temp !== undefined) {
            if (temp !== onRoadCost.Value) {
                if (temp < 0) {
                    temp = 0;
                }
                onRoadCost.value = temp;
                onRoadCost.valueObv(temp);
                returnVal = true;
            }
        }

        return returnVal;
    },

    updateObservableArray = function (obvArray) {

    },

    doesCustomerAlreadyExist = function (customerList, customerToSave) {
        var returnVal;

        var existingCustomers = customerList().filter(function (customer) {
            if (customer.contactName().toUpperCase() === customerToSave.tempContactName().toUpperCase() && customer.company().toUpperCase() === customerToSave.tempCompany().toUpperCase()) {
                return true;
            }
        });

        if (existingCustomers.length > 0) {
            returnVal = existingCustomers[0];
        } else {
            returnVal = 0;
        }

        return returnVal;
    },

    checkForExistingCustomerOverwrite = function (customerList, customerToSave) {
        var returnVal;

        var existingCustomers = customerList().filter(function (customer) {
            if (customerToSave.id() === 0 || customer.id() === customerToSave.id()) {
                if (customer.contactName().toUpperCase() !== customerToSave.tempContactName().toUpperCase() || customer.company().toUpperCase() !== customerToSave.tempCompany().toUpperCase() ||
                    customer.mobile().toUpperCase() !== customerToSave.tempMobile().toUpperCase() ||
                    customer.directNumber().toUpperCase() !== customerToSave.tempDirectNumber().toUpperCase() ||
                    customer.email().toUpperCase() !== customerToSave.tempEmail().toUpperCase()) {
                    return true;
                }
            }
        });

        if (existingCustomers.length > 0) {
            returnVal = existingCustomers[0];
        } else {
            returnVal = 0;
        }

        return returnVal;
    },

    /**
     * Function to convert number of seconds to hours and minutes
     * @param {number} seconds - Seconds to be converted
     * @param {string} [seperator] - String to be placed between hours and minutes
     * @returns {string} - Value converted to hours and minutes
     */
    formatSeconds = function (seconds, seperator) {

        var hours, minutes, formattedTime,
            seperatorToUse = typeof seperator === 'string' ? seperator : 'h';
            //seperatorToUse = typeof seperator === 'string' ? seperator : ':';

        //get the hours
        hours = Math.floor(seconds / 3600);
        minutes = Math.round((seconds / 3600 - hours) * 60);
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        //formattedTime = hours + ':' + minutes;
        formattedTime = hours + seperatorToUse + minutes;

        return formattedTime;

    },

    CCSStylesheetRuleStyle = function (stylesheet, selectorText, style, value) {
        /* returns the value of the element style of the rule in the stylesheet
            *  If no value is given, reads the value
            *  If value is given, the value is changed and returned
            *  If '' (empty string) is given, erases the value.
            *  The browser will apply the default one
            *
            * string stylesheet: part of the .css name to be recognized, e.g. 'default'
            * string selectorText: css selector, e.g. '#myId', '.myClass', 'thead td'
            * string style: camelCase element style, e.g. 'fontSize'
            * string value optionnal : the new value
            */
        var CCSstyle = undefined,
            rules;
        for (var m in document.styleSheets) {
            if (document.styleSheets[m].href.indexOf(stylesheet) !== -1) {
                rules = document.styleSheets[m][document.all ? 'rules' : 'cssRules'];
                for (var n in rules) {
                    if (rules[n].selectorText == selectorText) {
                        CCSstyle = rules[n].style;
                        //break;
                    }
                }
                break;
            }
        }
        if (CCSstyle !== undefined) {
            if (value === undefined) {
                return CCSstyle[style]
            } else {
                return CCSstyle[style] = value
            }
        } else {
            TScMessenger.writeDebugMessage('Globals.js, CCSStylesheetRuleStyle, Could not update ' + selectorText + ' & ' + style);
        }
    },

    setPageContainerClass = function () {
        if (isiPad() === true) {
            if (window.navigator.standalone === true) {
                // remove classes
                if (isiOS9() && !isiOS92()) {
                    $('#page-container-id').removeClass('normal-page-container').addClass('ipad-page-container-ios9');
                } else {
                    $('#page-container-id').removeClass('normal-page-container').addClass('ipad-page-container');
                }
            } else {
                $('#page-container-id').removeClass('ipad-page-container').addClass('normal-page-container');
            }
        } else {
            $('#page-container-id').removeClass('ipad-page-container').addClass('normal-page-container');
        }
    },

    formatDateToBritishFormat = function (dateVal) {
        return moment(dateVal, ["DD-MM-YYYY", "MM-DD-YYYY", "DD/MM/YYYY", "YYYY-MM-DD", "YYYY/MM/DD"]).format("DD/MM/YYYY");
    },

    formatDateToUsFormat = function (dateVal) {
        return moment(dateVal, ["DD-MM-YYYY", "MM-DD-YYYY", "DD/MM/YYYY", "YYYY-MM-DD", "YYYY/MM/DD"]).format("MM/DD/YYYY");
    },

    formatDateToIso8601Format = function (dateVal) {
        return moment(dateVal, ["DD/MM/YYYY", "MM-DD-YYYY", "DD/MM/YYYY", "YYYY-MM-DD", "YYYY/MM/DD"]).format("YYYY-MM-DD");
    },

    formatDateUsingCultureCode = function (dateVal, cultureCode, useFormatDateToIso8601Format) {
        if (cultureCode.toUpperCase() === 'EN-US') {
            return formatDateToUsFormat(dateVal);
        } else {
            if (useFormatDateToIso8601Format === true) {
                return formatDateToIso8601Format(dateVal);
            } else {
                return formatDateToBritishFormat(dateVal);
            }
        }
    },

    compareDateToTodayInDays = function (dateVal) {
        var now = moment().format("DD/MM/YYYY"),
            temp;
        if (dateVal === '') {
            return 1;
        } else {
            temp = moment(dateVal, "DD/MM/YYYY").diff(moment(now, "DD/MM/YYYY"), 'days');
            if (temp < 0) {
                return -1
            } else if (temp === 0) {
                return 0;
            } else {
                return 1;
            }
        }
    },

    compareDatesInDays = function (dateVal1, dateVal2) {
        var temp = moment(dateVal1, "DD/MM/YYYY").diff(moment(dateVal2, "DD/MM/YYYY"), 'days');

        if (temp < 0) {
            return -1
        } else if (temp === 0) {
            return 0;
        } else {
            return 1;
        }
    },

isInt = function (n) {
    return n % 1 === 0;
},

    formatLongString = function (str, insert, strMaxLength) {
        var result = '';
        while (str.length > 0) {
            var pattern = new RegExp(/([a-zA-Z0-9])/);
            var addbind = "";
            if (pattern.test(str.substr(strMaxLength, 1)) && pattern.test(str.substr(strMaxLength - 1, 1))) {
                addbind = "-";
            }
            result += str.substring(0, strMaxLength) + addbind + insert;
            str = str.substring(strMaxLength);
        }
        return result;
    },

    checkForPresenceOfElementCode = function (arr, elementCode) {
        var tempArr = arr.filter(function (el) {
            return el.Code === elementCode;
        });

        return tempArr.length > 0 ? true : false;
    },

    isiOS8 = function () {
        return navigator.userAgent.indexOf('OS 8') != -1;
    },

    isiOS9 = function () {
        return navigator.userAgent.indexOf('OS 9') != -1;
    },

    isiOS92 = function () {
        return navigator.userAgent.indexOf('OS 9_2') != -1;
    },

    isiOS93 = function () {
        return navigator.userAgent.indexOf('OS 9_3') != -1;
    },

    isiOS10 = function () {
        return navigator.userAgent.indexOf('OS 10') != -1;
    },

    isiOS11 = function () {
        return navigator.userAgent.indexOf('OS 11') != -1;
    },

    getiOSVersion = function () {
        return parseInt(navigator.userAgent.substr(navigator.userAgent.indexOf('OS ') + 3, 3));
    },

    

    isiPad = function () {
        //return (navigator.platform.indexOf("iPad") != -1);
        return (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || (navigator.platform.indexOf("iPad") != -1);
    },

    getOperatingSystem = function () {
        var osName = "Unknown";

        if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1) osName = config.osNameWindows10;
        if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1) osName = config.osNameWindows81;
        if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) osName = config.osNameWindows8;
        if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) osName = config.osNameWindows7;
        if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) osName = "Windows Vista";
        if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) osName = "Windows XP";
        if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) osName = "Windows 2000";
        if (window.navigator.userAgent.indexOf("Mac") != -1) osName = config.osNameMac;
        if (navigator.platform.indexOf("iPad") != -1) osName = config.osNameiOS;
        if (window.navigator.userAgent.indexOf("X11") != -1) osName = "UNIX";
        if (window.navigator.userAgent.indexOf("Linux") != -1) osName = "Linux";
        if (window.navigator.userAgent.indexOf("Android") != -1) osName = config.osNameAndroid;

        return osName;
    },

    getBrowser = function () {
        //http://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
        //// Opera 8.0+
        //var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
        //// Firefox 1.0+
        //var isFirefox = typeof InstallTrigger !== 'undefined';
        //// At least Safari 3+: "[object HTMLElementConstructor]"
        //var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
        //// Internet Explorer 6-11
        //var isIE = /*@cc_on!@*/false || !!document.documentMode;
        //// Edge 20+
        //var isEdge = !isIE && !!window.StyleMedia;
        //// Chrome 1+
        //var isChrome = !!window.chrome && !!window.chrome.webstore;
        //// Blink engine detection
        //var isBlink = (isChrome || isOpera) && !!window.CSS;
        var isInternetExplorer = /*@cc_on!@*/ false || !!document.documentMode;

        // Opera 8.0+
        if ((!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0) {
            return config.BROWSER_NAME.OPERA;
        }
        // Firefox 1.0+
        if (typeof InstallTrigger !== 'undefined') {
            return config.BROWSER_NAME.FIREFOX;
        }
        // At least Safari 3+: "[object HTMLElementConstructor]"
        //if (Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0) {
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            return config.BROWSER_NAME.SAFARI;
        }
        // Internet Explorer 6-11
        if ( /*@cc_on!@*/ false || !!document.documentMode) {
            return config.BROWSER_NAME.INTERNETEXPLORER;
        }
        // Edge 20+
        if (!isInternetExplorer && !!window.StyleMedia) {
            return config.BROWSER_NAME.EDGE;
        }
        // Chromium Edge
        if (navigator.userAgent.toLowerCase().indexOf('edg/') > -1) {
            return config.BROWSER_NAME.EDGE;
        }
        // Chrome 1+
        if (!!window.chrome && !!window.chrome.webstore) {
            return config.BROWSER_NAME.CHROME;
        }
        // Chrome on Android
        var regex = new RegExp('Chrome/[.0-9]* (?!Mobile)');
        if (regex.test(navigator.userAgent)) {
            return config.BROWSER_NAME.CHROMEONANDROID;
        }
    },

    getAndroidVersion = function (ua) {
        ua = (ua || navigator.userAgent).toLowerCase();
        var match = ua.match(/android\s([0-9]*)/);
        return match ? match[1] : false;
    },

    getBrowserVersion = function (ua) {
        ua = (ua || navigator.userAgent).toLowerCase();

        function getFirstMatch(regex) {
            var match = ua.match(regex);
            return (match && match.length > 1 && match[1]) || '';
        }

        function getSecondMatch(regex) {
            var match = ua.match(regex);
            return (match && match.length > 1 && match[2]) || '';
        }



        var iosdevice = getFirstMatch(/(ipod|iphone|ipad)/i).toLowerCase(),
            likeAndroid = /like android/i.test(ua),
            android = !likeAndroid && /android/i.test(ua),
            chromeBook = /CrOS/.test(ua),
            edgeVersion = getFirstMatch(/edge\/(\d+(\.\d+)?)/i),
            versionIdentifier = getFirstMatch(/version\/(\d+(\.\d+)?)/i),
            tablet = /tablet/i.test(ua),
            mobile = !tablet && /[^-]mobi/i.test(ua),
            result, isEdge, isOpera;



        if (/opera|opr/i.test(ua)) {
            result = versionIdentifier || getFirstMatch(/(?:opera|opr)[\s](\d+(\.\d+)?)/i);
            isOpera = true;
        } else if (/yabrowser/i.test(ua)) {
            result = versionIdentifier || getFirstMatch(/(?:yabrowser)[\s](\d+(\.\d+)?)/i);
        } else if (/windows phone/i.test(ua)) {
            if (edgeVersion) {
                result = edgeVersion;
                isEdge = true;
            } else {
                result = getFirstMatch(/iemobile\/(\d+(\.\d+)?)/i);
            }
        } else if (/msie|trident/i.test(ua)) {
            result = getFirstMatch(/(?:msie |rv:)(\d+(\.\d+)?)/i);
        } else if (chromeBook) {
            result = getFirstMatch(/(?:chrome|crios|crmo)\/(\d+(\.\d+)?)/i);
        } else if (/chrome.+? edge/i.test(ua)) {
            result = edgeVersion;
            isEdge = true;
        } else if (/chrome|crios|crmo/i.test(ua)) {
            result = getFirstMatch(/(?:chrome|crios|crmo)\/(\d+(\.\d+)?)/i);
        } else if (iosdevice) {

            if (versionIdentifier) {
                result = versionIdentifier;
            } else {
                result = getFirstMatch(/applewebkit\/(\d+(\.\d+)?)/i);
            }
        } else if (/sailfish/i.test(ua)) {
            result = getFirstMatch(/sailfish\s?browser\/(\d+(\.\d+)?)/i);
        } else if (/seamonkey\//i.test(ua)) {
            result = getFirstMatch(/seamonkey\/(\d+(\.\d+)?)/i);
        } else if (/firefox|iceweasel/i.test(ua)) {
            result = getFirstMatch(/(?:firefox|iceweasel)[ ](\d+(\.\d+)?)/i);
        } else if (/silk/i.test(ua)) {
            result = getFirstMatch(/silk\/(\d+(\.\d+)?)/i);
        } else if (android) {
            result = versionIdentifier;
        } else if (/phantom/i.test(ua)) {
            result = getFirstMatch(/phantomjs\/(\d+(\.\d+)?)/i);
        } else if (/blackberry|\bbb\d+/i.test(ua) || /rim\stablet/i.test(ua)) {
            result = versionIdentifier || getFirstMatch(/blackberry[\d]+\/(\d+(\.\d+)?)/i);
        } else if (/(web|hpw)os/i.test(ua)) {
            result = versionIdentifier || getFirstMatch(/w(?:eb)?osbrowser\/(\d+(\.\d+)?)/i);
        } else if (/bada/i.test(ua)) {
            result = getFirstMatch(/dolfin\/(\d+(\.\d+)?)/i);
        } else if (/tizen/i.test(ua)) {
            result = getFirstMatch(/(?:tizen\s?)?browser\/(\d+(\.\d+)?)/i) || versionIdentifier;
        } else if (/safari/i.test(ua)) {
            result = versionIdentifier;
        } else {
            result = getSecondMatch(/^(.*)\/(.*) /);
        }

        if (!result) {
            if (!isEdge && /(apple)?webkit/i.test(ua)) {
                result = versionIdentifier;
            } else if (!isOpera && /gecko\//i.test(ua)) {

                result = getFirstMatch(/gecko\/(\d+(\.\d+)?)/i);
            }
        }



        return result;
    },

getBrowserDimensions = function () {
    return {
        height: window.innerHeight,
        width: window.innerWidth
    };
},

    isValidWindowsOS = function () {
        //var os = getOperatingSystem();
        //return config.validWindowsOperatingSystems.includes(getOperatingSystem());
        return config.validWindowsOperatingSystems.indexOf(getOperatingSystem()) > -1;
    },

    isTouchWindowsOS = function () {
        return config.validWindowsOperatingSystems.indexOf(getOperatingSystem()) > -1;
    },

    isValidAndroidOS = function () {
        var version = getAndroidVersion();
        return parseFloat(version) >= config.minAndroidVersion;
    },

    meetsMinimumBrowserDimensions = function () {
        var dimensions = getBrowserDimensions();
        return (dimensions.height >= config.minBrowserHeight && dimensions.width >= config.minBrowserWidth);
    },

    meetsMinimumScreenDimensions = function () {
        return (screen.height >= config.minScreenHeight && screen.width >= config.minBrowserWidth);
    },

    meetsMinimumScreenDimensionsForiOS = function () {
        if (Math.abs(window.orientation) === 90) {
            return (screen.height >= config.minScreenWidth && screen.width >= config.minScreenHeight);
        } else {
            return (screen.height >= config.minScreenHeight && screen.width >= config.minScreenWidth);
        }
    },

    isAndroidStandalone = function () {
        return (window.matchMedia('(display-mode: standalone)').matches);
    },

    isiPadStandalone = function () {
        return window.navigator.standalone && isiPad();
    },

    isOpenInTabletBrowser = function () {
        return (isiPad() && !window.navigator.standalone) || (getOperatingSystem() === config.osNameAndroid && !isAndroidStandalone());
    },

    displayMessageAndTimeInConsole = function (message) {
        var tempDate = new Date();
        TScMessenger.writeDebugMessage('**********************************************************');
        TScMessenger.writeDebugMessage(message);
        TScMessenger.writeDebugMessage('Time = ' + tempDate.getHours() + ":" + tempDate.getMinutes() + ":" + tempDate.getSeconds() + "." + tempDate.getMilliseconds());
    },

    measurementSystemConversionValueLength = function (measurementSystemId) {
        switch (measurementSystemId) {
            case 1:
                //Metric
                return 1;
            case 2:
                //Imperial UK
                return 0.0393700787402;
            case 3:
                //Imperial US
                return 0.0393700787402;
            default:
                return 1;
        }
    },

    measurementSystemConversionUnitLength = function (measurementSystemId) {
        switch (measurementSystemId) {
            case 1:
                //Metric
                return 'mm';
            case 2:
                //Imperial UK
                return 'in';
            case 3:
                //Imperial US
                return 'in';
            default:
                return 'mm';
        }
    },

    measurementSystemConversionValueDensity = function (measurementSystemId) {
        switch (measurementSystemId) {
            case 1:
                //Metric
                return 1;
            case 2:
                //Imperial UK
                return 10.02241312246;
            case 3:
                //Imperial US
                return 8.345404374;
            default:
                return 1;
        }
    },

    measurementSystemConversionUnitDensity = function (measurementSystemId) {
        switch (measurementSystemId) {
            case 1:
                //Metric
                return 'kg/l';
            case 2:
                //Imperial UK
                return 'lb/gal';
            case 3:
                //Imperial US
                return 'lb/gal';
            default:
                return 'kg/l';
        }
    },

    measurementSystemConversionValueMass = function (measurementSystemId) {
        switch (measurementSystemId) {
            case 1:
                //Metric
                return 1;
            case 2:
                //Imperial UK
                return 2.20462262;
            case 3:
                //Imperial US
                return 2.20462262;
            default:
                return 1;
        }
    },

    measurementSystemConversionUnitMass = function (measurementSystemId) {
        switch (measurementSystemId) {
            case 1:
                //Metric
                return 'kg';
            case 2:
                //Imperial UK
                return 'lb';
            case 3:
                //Imperial US
                return 'lb';
            default:
                return 'kg';
        }
    },

    measurementSystemConversionValueLinearMassDensity = function (measurementSystemId) {
        switch (measurementSystemId) {
            case 1:
                //Metric
                return 1;
            case 2:
                //Imperial UK
                return 0.671968975;
            case 3:
                //Imperial US
                return 0.671968975;
            default:
                return 1;
        }
    },

    measurementSystemConversionUnitLinearMassDensity = function (measurementSystemId) {
        switch (measurementSystemId) {
            case 1:
                //Metric
                return 'kg/m';
            case 2:
                //Imperial UK
                return 'lb/ft';
            case 3:
                //Imperial US
                return 'lb/ft';
            default:
                return 'kg/m';
        }
    },

    measurementSystemConversionValueVolume = function (measurementSystemId) {
        switch (measurementSystemId) {
            case 1:
                //Metric
                return 1;
            case 2:
                //Imperial UK
                return 0.21997;
            case 3:
                //Imperial US
                return 0.26417;
            default:
                return 1;
        }
    },

    measurementSystemConversionUnitVolume = function (measurementSystemId) {
        switch (measurementSystemId) {
            case 1:
                //Metric
                return 'l';
            case 2:
                //Imperial UK
                return 'gal';
            case 3:
                //Imperial US
                return 'gal';
            default:
                return 'l';
        }
    },

    formatValue = function (value, decimals, unitLength) {
        return Globalize.format(value, "n" + decimals) + ' ' + unitLength;
    },

    displayMenuItem = function () {
        return true;
    },

    meetsMinimumRequirements = function () {
        var osName = getOperatingSystem(),
            returnVal = true;

        switch (osName) {
            // Android
            case config.osNameAndroid:
                // Android version < 4.4
                if (!isValidAndroidOS()) {
                    returnVal = false;
                }
                // Checks that the tablet meets the minimum screen resolution requirements
                if (!meetsMinimumScreenDimensions()) {
                    returnVal = false;
                }
                // Android is standalone and the dimensions of the tablet are too small
                if (isAndroidStandalone() && !meetsMinimumBrowserDimensions()) {
                    returnVal = false;
                }
                break;
            // iOS
            case config.osNameiOS:
                //if (!isiOS8() && !isiOS9() && !isiOS10()) {
                if (getiOSVersion() <= config.osNameiOSMinimumVersion) {
                    returnVal = false;
                }
                // Checks that the screen meets the minimum screen resolution requirements
                if (!meetsMinimumScreenDimensionsForiOS()) {
                    returnVal = false;
                }
                break;
            // Windows
            case config.osNameMac:
            case config.osNameWindows10:
            case config.osNameWindows81:
            case config.osNameWindows8:
            case config.osNameWindows7:
                // Checks that the screen meets the minimum screen resolution requirements
                if (!meetsMinimumScreenDimensions()) {
                    returnVal = false;
                }
                break;
            default:
                returnVal = false;
                break;
        }

        return returnVal;
    },

    returnMenuValueBaseObject = function (id, label, value, valueType) {
        return {
            id: id,
            label: label,
            value: value,
            itemType: config.ITEM_TYPE.VALUE,
            valueType: valueType,
            inputType: config.INPUT_TYPE.UPDOWNBOX
        };
    },

    returnMenuValueUpDownBox = function (id, label, value, valueType, errorMessage, errorMessageObv, override, permissionFunction, resetValueFunction, valueTypeName, resetValue) {
        var returnObj = returnMenuValueBaseObject(id, label, value, valueType);
        returnObj.inputType = config.INPUT_TYPE.UPDOWNBOX;

        if (errorMessage !== undefined) {
            returnObj.errorMessage = errorMessage;
        }
        if (permissionFunction !== undefined) {
            returnObj.permissionFunction = permissionFunction;
        } else {
            returnObj.permissionFunction = function () {
                return true;
            };
        }
        if (errorMessageObv !== undefined) {
            returnObj.errorMessageObv = errorMessageObv;
        }
        if (override !== undefined) {
            returnObj.override = override;
        }
        if (resetValueFunction !== undefined) {
            returnObj.resetValueFunction = resetValueFunction;
        }
        if (valueTypeName !== undefined) {
            returnObj.valueTypeName = valueTypeName;
        }
        if (resetValue !== undefined) {
            returnObj.resetValue = resetValue;
        }

        return returnObj;
    },

    returnMenuValueTel = function (id, label, value, valueType, errorMessage, errorMessageObv, override, permissionFunction, resetValueFunction, valueTypeName, resetValue) {
        var returnObj = returnMenuValueBaseObject(id, label, value, valueType);
        returnObj.inputType = config.INPUT_TYPE.TEL;

        if (errorMessage !== undefined) {
            returnObj.errorMessage = errorMessage;
        }
        if (permissionFunction !== undefined) {
            returnObj.permissionFunction = permissionFunction;
        } else {
            returnObj.permissionFunction = function () {
                return true;
            };
        }
        if (errorMessageObv !== undefined) {
            returnObj.errorMessageObv = errorMessageObv;
        }
        if (override !== undefined) {
            returnObj.override = override;
        }
        if (resetValueFunction !== undefined) {
            returnObj.resetValueFunction = resetValueFunction;
        }
        if (valueTypeName !== undefined) {
            returnObj.valueTypeName = valueTypeName;
        }
        if (resetValue !== undefined) {
            returnObj.resetValue = resetValue;
        }

        return returnObj;
    },

    returnMenuItemType = function (item) {
        return {
            label: item.label,
            itemType: item.itemType,
            nestedStep: item.nestedStep
        };
    },

    returnMenuSelectionGroupItem = function () {

        return {
            label: '',
            nestedStep: {},
            itemType: config.ITEM_TYPE.SELECTIONGROUPITEM
        };
    },

    returnInteger = function (value) {
        var reg = /[^0-9]+/;
        if (typeof value === 'number') {
            return value;
        } else {
            return parseInt(value.replace(reg, ''));
        }
    },

    returnFloat = function (value) {
        var regex,
            seperatorRegex;
        if (Globalize.culture().numberFormat['.'] === '.') {
            regex = /[+-.]?\d+(\.\d+)?/g;
            seperatorRegex = /[,]/g;
        } else {
            regex = /[+-,]?\d+(\.\d+)?/g;
            seperatorRegex = /[.]/g;
        }
        if (typeof value === 'number') {
            return value;
        } else {
            var valueToMatch = value.replace(seperatorRegex, '');
            var returnObj = valueToMatch.match(regex).map(function (v) {
                return parseFloat(v);
            });
            return returnObj[0];
        }
    },

    isValidNumberVal = function (value) {
        var regex = /[+-]?\d+(\.\d+)?/g;

        if (typeof value === 'number') {
            return true;
        } else {
            return value.match(regex) !== null;
        }
    },

    returnMenuEquipmentItemType = function (equipment, equipmentItemFunction, closeMenuFunction, graphicsCallbackFunction, graphicsCallbackValue) {
        return {
            label: equipment.getDescription(),
            itemType: config.ITEM_TYPE.TEXT,
            equipmentItem: true,
            equipmentItemFunction: equipmentItemFunction,
            equipmentObject: equipment,
            closeMenuFunction: closeMenuFunction,
            graphicsCallbackFunction: graphicsCallbackFunction,
            graphicsCallbackValue: graphicsCallbackValue
        };
    },

    returnMenuFuelTankItemType = function (number) {
        return {
            //label: config.ltlgetTranslationText('163') + ' ' + number
            label: config.getTranslationText('3062', [number])
        };
    },

    selectAllTextById = function (id) {
        if (isiPad()) {
            document.getElementById(id).selectionStart = 0;
            document.getElementById(id).selectionEnd = 999;
        } else {
            $('#' + id).select();
        }
    },

    // selectAllTextByClass = function (className) {
    //     if (isiPad()) {
    //         document.getElementById(id).selectionStart = 0;
    //         document.getElementById(id).selectionEnd = 999;
    //     } else {
    //         $('.' + className).select();
    //     }
    // },

    closeMenu = function (id, closeEditMenu, closeEditMenuCallback) {
        $(id).hide("slide", {
            direction: "right"
        }, function () {
            $(id + '-button').removeClass('active');
            if (closeEditMenu) {
                $('#menu-button').removeClass('active');
                closeEditMenuCallback();
            }
        });
    },

    closeAllMenus = function (closeEditMenuCallback) {
        closeMenu('#display-options');
        closeMenu('#elevation-options');
        closeMenu('#view-type-options');
        closeMenu('#view-detail-options');
        closeMenu('#colour-options');
        closeMenu('#menu-container', true, closeEditMenuCallback);
    },

    setUpTooltips = function () {
        $('[data-toggle="tooltip"]').each(function () {
            if ($(this)[0].scrollWidth > $(this).innerWidth()) {
                $(this).tooltip('destroy');
                $(this).tooltip();
            }
        });
    },

    nextId = function () {
        return nextIdCounter++;
    },

    getDummyBodyBuilderCustomerLiteral = function (userName, firstName, lastName) {
        var cust = JSON.parse(JSON.stringify(config.dummyEmptyCustomer));

        cust.ContactName = firstName;
        cust.Company = lastName;
        cust.Email = isValidEmailAddress(userName) ? userName : userName + '@defaultcustomer.com';


        return cust;
    },

    //getUniqueObjectsFromArrayUsingProperty = function (array, property) {
    //    var unique = {};
    //    var distinct = [];
    //    for (var i in array) {
    //        if (typeof array[i][property] === "function") {
    //            if (typeof (unique[array[i][property]()]) == "undefined") {
    //                distinct.push(array[i]);
    //            }
    //            unique[array[i][property]()] = 0;
    //        } else {
    //            if (typeof (unique[array[i][property]]) == "undefined") {
    //                distinct.push(array[i]);
    //            }
    //            unique[array[i][property]] = 0;
    //        }
    //    }
    //    return distinct;
    //},

    //getUniqueObjectsFromArrayUsingPropertyFunction = function (array, property) {
    //    var unique = {};
    //    var distinct = [];
    //    for (var i in array) {
    //        if (typeof (unique[array[i][property]()]) == "undefined") {
    //            distinct.push(array[i]);
    //        }
    //        unique[array[i][property]()] = 0;
    //    }
    //    return distinct;
    //},

    resetMenuPosition = function (partialId, verticalPosition, objectToCheck, verticalButtonPosition, menuHeight, outerContainerId) {
        var bufferFromTop = 10,
            buttonId = '#' + partialId + '-button',
            containerId = '#' + partialId + '-container',
            buttonIdToUse = typeof outerContainerId === 'string' ? '#' + outerContainerId + ' ' + buttonId : buttonId,
            containerIdToUse = typeof outerContainerId === 'string' ? '#' + outerContainerId + ' ' + containerId : containerId,
            menuToolbarHeight, contentHeight, menuTopPosition, valueToRemoveFromMaxHeight;

        if (typeof $(containerIdToUse + ' #menu-content').attr('style') === 'string' && $(containerIdToUse + ' #menu-content').attr('style').includes('height:') === true) {
            $(containerIdToUse + ' #menu-content').css('height', '');
        }
        if (objectToCheck !== undefined) {
            var newPosition;
            if (verticalPosition + objectToCheck.menuHeight < objectToCheck.contentHeight - 10) {
                newPosition = verticalPosition;
            } else {
                newPosition = objectToCheck.contentHeight - 10 - objectToCheck.menuHeight;
            }

            if (newPosition < bufferFromTop) {
                valueToRemoveFromMaxHeight = newPosition - bufferFromTop;
                newPosition = bufferFromTop;
            }

            if ((newPosition + objectToCheck.menuHeight) > objectToCheck.verticalButtonPosition) {
                //$("#" + partialId + "-container").css("top", newPosition);
            } else {
                var verticalButtonHeight = $(buttonIdToUse).parent().height();
                newPosition = verticalButtonPosition + verticalButtonHeight - menuHeight;
            }
            $(containerIdToUse).css("top", newPosition);

            menuHeight = $(containerIdToUse).height(),
                menuToolbarHeight = $(containerIdToUse + ' .breadcrumbs-flex-container').height(),
                contentHeight = $("#content").height(),
                menuTopPosition = $(containerIdToUse).position().top;
            if (menuHeight + menuTopPosition > contentHeight) {
                valueToRemoveFromMaxHeight = contentHeight - menuToolbarHeight - menuTopPosition - bufferFromTop;

                $(containerIdToUse + ' > div > .template-menu-content-div > #menu-content').height(valueToRemoveFromMaxHeight);
            }
        } else {
            if($(containerIdToUse).length > 0) {
                $(containerIdToUse).css("top", verticalPosition);

                menuHeight = $(containerIdToUse).height(),
                    menuToolbarHeight = $(containerIdToUse + ' .breadcrumbs-flex-container').height(),
                    contentHeight = $("#content").height(),
                    menuTopPosition = $(containerIdToUse).position().top;
                if (menuHeight + menuTopPosition > contentHeight) {
                    valueToRemoveFromMaxHeight = contentHeight - menuToolbarHeight - menuTopPosition - bufferFromTop;
    
                    $(containerIdToUse + ' > div > .template-menu-content-div > #menu-content').height(valueToRemoveFromMaxHeight);
                }
            }
        }
    },

    /**
     * Function used to position menus
     * @param {string} partialId
     * @param {function} callback
     * @param {boolean} useButtonPosition
     * @param {string} outerContainerId
     */
    repositionMenu = function (partialId, callback, useButtonPosition, outerContainerId) {
        var bufferFromTop = 10,
            buttonId = '#' + partialId + '-button',
            containerId = '#' + partialId + '-container',
            buttonIdToUse = typeof outerContainerId === 'string' ? outerContainerId + ' ' + buttonId : buttonId,
            containerIdToUse = typeof outerContainerId === 'string' ? outerContainerId + ' ' + containerId : containerId,
            performanceMenuBuffer = 0, menuToolbarHeight, menuTopPosition;

        // This is required because of the margin applied to the top of the menu button section 
        if ($('.performance-menu-left').length > 0) {
            performanceMenuBuffer = 20;
        }

        if ($(buttonIdToUse).length > 0 && $(containerIdToUse).is(":visible") === true) {
            if (typeof $(containerIdToUse + ' #menu-content').attr('style') === 'string' && $(containerIdToUse + ' #menu-content').attr('style').includes('height:') === true) {
                $(containerIdToUse + ' #menu-content').css('height', '');
            }

            // Check the position of the menu
            var verticalButtonPosition = $(buttonIdToUse).parent().position().top;
            var verticalPosition = useButtonPosition === true ? verticalButtonPosition : $("#" + partialId + "-container").position().top;
            // Get the height of the menu
            var menuHeight = $(containerIdToUse).height();
            // Check against the size of the viewport
            var contentHeight = $("#content").height();
            // Change the poition to bottom 10 if required
            if ((verticalPosition + menuHeight) > contentHeight - 10) {
                var newPosition = contentHeight - 10 - menuHeight - performanceMenuBuffer;
                if ((newPosition + menuHeight) > verticalButtonPosition) {
                    //$("#" + partialId + "-container").css("top", newPosition);
                } else {
                    var verticalButtonHeight = $(buttonIdToUse).parent().height();
                    newPosition = verticalButtonPosition + verticalButtonHeight - menuHeight;
                }
                if (newPosition < bufferFromTop - performanceMenuBuffer) {
                    newPosition = bufferFromTop - performanceMenuBuffer;
                }

                $(containerIdToUse).css("top", newPosition);

                menuHeight = $(containerIdToUse).height(),
                    menuToolbarHeight = $(containerIdToUse + ' .breadcrumbs-flex-container').height(),
                    contentHeight = $("#content").height(),
                    menuTopPosition = $(containerIdToUse).position().top;
                if (menuHeight + menuTopPosition > contentHeight) {
                    var valueToRemoveFromMaxHeight = contentHeight - menuToolbarHeight - menuTopPosition - performanceMenuBuffer - bufferFromTop;
                    var pathToElement;
                    if ($(containerIdToUse + ' > div > .template-menu-content-div > #menu-content').length > 0) {
                        pathToElement = containerIdToUse + ' > div > .template-menu-content-div > #menu-content';
                    } else {
                        pathToElement = containerIdToUse + ' > .template-menu-content-div > #menu-content';
                    }

                    //$(containerIdToUse + ' > div > .template-menu-content-div > #menu-content').height(valueToRemoveFromMaxHeight);
                    $(pathToElement).height(valueToRemoveFromMaxHeight);
                }
            } else {
                if (verticalButtonPosition !== verticalPosition) {
                    var objectToCheck = {};
                    objectToCheck.verticalButtonPosition = verticalButtonPosition;
                    objectToCheck.verticalPosition = verticalPosition;
                    objectToCheck.menuHeight = menuHeight;
                    objectToCheck.contentHeight = contentHeight;

                    resetMenuPosition(partialId, verticalButtonPosition, objectToCheck, verticalButtonPosition, menuHeight, outerContainerId);
                }
            }
            WebuiPopovers.hideAll();
            if (typeof callback === 'function') {
                callback();
            }
        }
    },

    /**
     * Displays WebUI Popover for element
     * @param {string} - id of the element to display the tooltip
     */
    displayTooltip = function (id, onShowCallback, onHideCallback) {
        var popoverObject = {
            trigger: 'manual',
            closeable: true,
            animation: 'pop'
        };
        if (typeof onShowCallback === 'function') {
            popoverObject.onShow = onShowCallback;
        }
        if (typeof onHideCallback === 'function') {
            popoverObject.onHide = onHideCallback;
        }
        $(id).webuiPopover(popoverObject);
        $(id).webuiPopover('show');
    },

    hideTooltip = function (id) {
        $(id).webuiPopover('hide');
    },

    replaceNewLineWithBreaks = function (valueToConvert) {
        return typeof valueToConvert === 'string' ? valueToConvert.replace(/(?:\r\n|\r|\n)/g, '<br />') : '';
    },

    fadeAndShowElement = function (selector, duration, callback, data) {
        $(selector).fadeOut(duration, function () {
            completionCallback(callback, data)
        });

        function completionCallback(callback, data) {
            callback(data);
            $(selector).fadeIn(duration);
        }
    },

    checkCanActivateAfterRefreshOrReload = function (user, routeName, ROUTES) {
        if (user.getLastUrlHash() === routeName) {
            return {
                name: ROUTES.SELECTION.name
            };
        } else {
            return true;
        }
    },

setRegisteredServiceWorkerContainer = function (regServiceWorkerContainer) {
    registeredServiceWorkerContainer = regServiceWorkerContainer;
},

getRegisteredServiceWorkerContainer = function () {
    return registeredServiceWorkerContainer;
},

registerServiceWorker = function () {
    var dfd = $.Deferred();
    if (navigator.serviceWorker) {
        //console.log('********************* Registering Service Worker *********************');
        navigator.serviceWorker.register('service-worker.js').then(function (registration) {
            setRegisteredServiceWorkerContainer(registration);
            //console.log('********************* Service Worker Registered *********************');
            dfd.resolve(registration);
        },function (error) {
            dfd.reject();
        });
    } else {
        dfd.reject();
    }

    return dfd.promise();
},

getPaddedAppVersionNumber = function () {
    var versionNumParts = config.appVersionNumber.split('.');
    var sprintPart = ('00' + versionNumParts[1]).slice(-2);
    var buildPart = ('00' + versionNumParts[2]).slice(-2);

    var paddedVersion = versionNumParts[0] + sprintPart + buildPart + '0';//versionNumParts[3];

    return parseInt(Number(paddedVersion));
    // 1073741823 is maximum indexeddb version number
},

getPaddedStructureVersionNumber = function () {
    var versionNumParts = config.appVersionNumber.split('.');
    var sprintPart = ('00' + versionNumParts[1]).slice(-2);
    
    var paddedVersion = versionNumParts[0] + sprintPart;
    
    return parseInt(Number(paddedVersion));
},

setPreventClearingOfPerformanceResultsWhenOpeningTSV = function(newValue) {
    preventClearingOfPerformanceResultsWhenOpeningTSV = newValue;
},

getPreventClearingOfPerformanceResultsWhenOpeningTSV = function () {
    return preventClearingOfPerformanceResultsWhenOpeningTSV;
},

getDefaultEditLevel = function(objectAsJSON) {
    if (objectAsJSON.Source === config.VEHICLE_SOURCE_TYPES.STANDARD) {
        return config.ITEM_EDIT_LEVEL_OPTIONS.NO_EDIT;
    } else {
        return config.ITEM_EDIT_LEVEL_OPTIONS.FULL_EDIT;
    }
},

setNavigatingHome = function (newValue) {
    navigatingHome = newValue;
},

getNavigatingHome = function () {
    return navigatingHome;
},

checkMultiSelectArrays = function (arrayOfVehicles) {
    var manufacturerVehiclesArray = [], genericVehiclesArray = [];
    // Check if array of makes has been populated in user
    if (!globals.user.checkAvailableManufacturerMakesArray()) {
        createDistinctArrayAndUpdateUser(getManufacturerVehiclesArray(arrayOfVehicles), config.SELECTION_DISTINCT_PROPERTY_NAME.VEHICLE_MAKE);
    }
    if (!globals.user.checkAvailableManufacturerRangesArray()) {
        createDistinctArrayAndUpdateUser(getManufacturerVehiclesArray(arrayOfVehicles), config.SELECTION_DISTINCT_PROPERTY_NAME.VEHICLE_RANGE);
    }
    if (!globals.user.checkAvailableManufacturerAxleLayoutsArray()) {
        createDistinctArrayAndUpdateUser(getManufacturerVehiclesArray(arrayOfVehicles), config.SELECTION_DISTINCT_PROPERTY_NAME.AXLE_LAYOUT, sortByAxleLayout, true);
    }
    if (!globals.user.checkAvailableManufacturerVehicleTypesArray()) {
        createDistinctArrayAndUpdateUser(getManufacturerVehiclesArray(arrayOfVehicles), config.SELECTION_DISTINCT_PROPERTY_NAME.VEHICLE_TYPE);
    }
    if (!globals.user.checkAvailableManufacturerStatusesArray()) {
        createDistinctArrayAndUpdateUser(getManufacturerVehiclesArray(arrayOfVehicles), config.SELECTION_DISTINCT_PROPERTY_NAME.STATUS, undefined, undefined, undefined, getManufacturerStatusDescriptionText);
    }
    if (!globals.user.checkAvailableManufacturerCountriesArray()) {
        createDistinctArrayAndUpdateUser(getManufacturerVehiclesArray(arrayOfVehicles), config.SELECTION_DISTINCT_PROPERTY_NAME.COUNTRY);
    }
    if (!globals.user.checkAvailableGenericAxleLayoutsArray()) {
        createDistinctArrayAndUpdateUser(getGenericVehiclesArray(arrayOfVehicles), config.SELECTION_DISTINCT_PROPERTY_NAME.AXLE_LAYOUT, sortByAxleLayout, true, globals.user.setAvailableGenericAxleLayoutsArray);
    }
    if (!globals.user.checkAvailableGenericVehicleTypesArray()) {
        createDistinctArrayAndUpdateUser(getGenericVehiclesArray(arrayOfVehicles), config.SELECTION_DISTINCT_PROPERTY_NAME.VEHICLE_TYPE, null, null, globals.user.setAvailableGenericVehicleTypesArray);
    }

    function getManufacturerVehiclesArray(arrayToUse) {
        if (manufacturerVehiclesArray.length === 0) {
            manufacturerVehiclesArray = arrayToUse.filter(function (row) {
                return row.Source === config.VEHICLE_SOURCE_TYPES.STANDARD && !row.isSavedOffer && !row.isSharedOffer;
            });
        }
        return manufacturerVehiclesArray;
    }

    function getGenericVehiclesArray(arrayToUse) {
        if (genericVehiclesArray.length === 0) {
            genericVehiclesArray = arrayToUse.filter(function (row) {
                return row.Source === config.VEHICLE_SOURCE_TYPES.TEMPLATE && !row.isSavedOffer && !row.isSharedOffer;
            });
        }
        return genericVehiclesArray;
    }

    function getManufacturerStatusDescriptionText(status) {                
        switch (status) {
            case config.VEHICLE_STATUS_TYPES.CURRENT:
                return config.getTranslationText('4930');
            case config.VEHICLE_STATUS_TYPES.DISCONTINUED:
            return config.getTranslationText('4973');
            case config.VEHICLE_STATUS_TYPES.PENDING:
            return config.getTranslationText('4974');
            case config.VEHICLE_STATUS_TYPES.VALIDATED:
            return config.getTranslationText('4975');
            case config.VEHICLE_STATUS_TYPES.PROTOTYPE:
            return config.getTranslationText('4976');
            case config.VEHICLE_STATUS_TYPES.SPECIAL:
                return config.getTranslationText('4977');       
            default:
                return status;
        }
    }
},

createDistinctArrayAndUpdateUser = function (arrayToUse, propertyName, sortFunctionToUse, passInArrayToSortFunction, callback, descriptionTextFunction) {
    //var updateArray = [],
    //    defaultSortFunction = function (a, b) {
    //        if (a[propertyName] > b[propertyName]) {
    //            return 1;
    //        } else if (a[propertyName] < b[propertyName]) {
    //            return -1;
    //        } else {
    //            return 0;
    //        }
    //    },
    //    passInArray = typeof passInArrayToSortFunction === 'boolean' ? passInArrayToSortFunction : false,
    //    sortFunction = typeof sortFunctionToUse === 'function' ? sortFunctionToUse : defaultSortFunction;
    //// Get distinct objects
    //var distinctObjects = globals.getUniqueObjectsFromArrayUsingProperty(arrayToUse, propertyName);
    //// sort distinctObjects alpabetically using propertyName
    //if (passInArray) {
    //    distinctObjects = sortFunction(distinctObjects);
    //} else {
    //    distinctObjects.sort(sortFunction);
    //}

    //// Create array of make object
    //distinctObjects.forEach(function (element) {
    //    if (element[propertyName]) {
    //        updateArray.push({
    //            description: element[propertyName],
    //            value: element[propertyName]
    //        });
    //    }
    //});
    var updateArray = globals.createDistinctArray(arrayToUse, propertyName, sortFunctionToUse, passInArrayToSortFunction, descriptionTextFunction);
    // Update user using setter
    if (typeof callback === 'function') {
        callback(updateArray);
    } else {
        switch (propertyName) {
            case config.SELECTION_DISTINCT_PROPERTY_NAME.VEHICLE_MAKE:
                globals.user.setAvailableManufacturerMakesArray(updateArray);
                break;
            case config.SELECTION_DISTINCT_PROPERTY_NAME.VEHICLE_RANGE:
                globals.user.setAvailableManufacturerRangesArray(updateArray);
                break;
            case config.SELECTION_DISTINCT_PROPERTY_NAME.AXLE_LAYOUT:
                globals.user.setAvailableManufacturerAxleLayoutsArray(updateArray);
                break;
            case config.SELECTION_DISTINCT_PROPERTY_NAME.VEHICLE_TYPE:
                globals.user.setAvailableManufacturerVehicleTypesArray(updateArray);
                break;
            case config.SELECTION_DISTINCT_PROPERTY_NAME.STATUS:
                globals.user.setAvailableManufacturerStatusesArray(updateArray);
                break;
            case config.SELECTION_DISTINCT_PROPERTY_NAME.COUNTRY:
                globals.user.setAvailableManufacturerCountriesArray(updateArray);
                break;
            default:
                break;
        }
    }
},

sortByAxleLayoutWithType = function (a, b, sortType) {
    var aFrontAxles = parseInt(a.AxleLayout.split("X")[0]),
        aBackAxles = parseInt(a.AxleLayout.split("X")[1]),
        bFrontAxles = parseInt(b.AxleLayout.split("X")[0]),
        bBackAxles = parseInt(b.AxleLayout.split("X")[1]);

    if (sortType === config.SORT_OPTIONS.ASC) {
        if (aFrontAxles < bFrontAxles) {
            return -1;
        } else {
            if (aFrontAxles > bFrontAxles) {
                return 1;
            }
        }
        if (aBackAxles < bBackAxles) {
            return -1;
        } else {
            if (aBackAxles > bBackAxles) {
                return 1;
            }
        }
        return 0;
    } else {
        if (aFrontAxles < bFrontAxles) {
            return 1;
        } else {
            if (aFrontAxles > bFrontAxles) {
                return -1;
            }
        }
        if (aBackAxles < bBackAxles) {
            return 1;
        } else {
            if (aBackAxles > bBackAxles) {
                return -1;
            }
        }
        return 0;
    }
},

sortByAxleLayout = function (arrayToSort) {
    return arrayToSort.sort(function (a, b) {
        return sortByAxleLayoutWithType(a, b, config.SORT_OPTIONS.ASC);

    });
},

getIdOrNumberFromPath = function (path, posFromEnd) {
    if (path === undefined) {
        TScMessenger.writeDebugMessage('Globals.js, getIdOrNumberFromPath, path undefined when looking for id');
        return null;
    } else {
        var pathParts = path.split('.');
        return parseInt(pathParts[pathParts.length - posFromEnd]);
    }
},

showDialog = function (customDialogInfo) {
    var data = customDialogInfo.getData ? customDialogInfo.getData() : customDialogInfo;
    
    bus.emit('displayMessage', {
        // title: customDialogInfo.getTitle(),
        // message: customDialogInfo.getMessage(),
        // messageRef: customDialogInfo.getMessageRef(),
        // buttons: customDialogInfo.getButtons(),
        type: customDialogInfo.type ? customDialogInfo.type : "CustomMessageDialogue", 
        data: data,
        callback: customDialogInfo.callback ? function(){ return customDialogInfo.callback; } : getCallback,
        displayMode: customDialogInfo.getModalDisplayMode ? customDialogInfo.getModalDisplayMode() : customDialogInfo.displayMode ? customDialogInfo.displayMode : config.MODAL_DISPLAY_MODE.QUEUE
    });

    function getCallback() {
        if(customDialogInfo.getCallback) {
            return customDialogInfo.getCallback.call(customDialogInfo);
        }else {
            return null;
        }
    }
    // return customDialogInfo.addCallback;
    return { 
        addCallback: function (callback) { 
                customDialogInfo.addCallback(callback); 
              }
    }
},

getAreTabsOtherThanConfigurationAndSummaryDisplayed = function() {
    return areTabsOtherThanConfigurationAndSummaryDisplayed;
},

setAreTabsOtherThanConfigurationAndSummaryDisplayed = function(value) {
    if (value === true) {
        areTabsOtherThanConfigurationAndSummaryDisplayed = true;
    }
},

setAreTabsOtherThanConfigurationAndSummaryDisplayedToFalse = function() {
    areTabsOtherThanConfigurationAndSummaryDisplayed = false;
},

// manageHiddenButtons = function(dataAvailability, router) {
//     showNavButtonVars.value = [];
//     setAreTabsOtherThanConfigurationAndSummaryDisplayedToFalse();
//     router.getRoutes().forEach(function (route) {
//         if (route.meta.nav) {
//             //route.nav = true;
//             if (route.meta.id === "brochure-id") {
//                 showNavButtonVars.value.push(dataAvailability.ActiveBrochure);
//                 setAreTabsOtherThanConfigurationAndSummaryDisplayed(dataAvailability.ActiveBrochure);
//             } else if (route.meta.id === "configuration-id") {
//                 showNavButtonVars.value.push(dataAvailability.ActiveConfiguration);
//             } else if (route.meta.id === "costing-id") {
//                 showNavButtonVars.value.push(dataAvailability.ActiveCosting);
//                 setAreTabsOtherThanConfigurationAndSummaryDisplayed(dataAvailability.ActiveCosting);
//             } else if (route.meta.id === "performance-id") {
//                 showNavButtonVars.value.push(dataAvailability.ActivePerformance);
//                 setAreTabsOtherThanConfigurationAndSummaryDisplayed(dataAvailability.ActivePerformance);
//             } else if (route.meta.id === "specification-id") {
//                 showNavButtonVars.value.push(dataAvailability.ActiveSpecification);
//                 setAreTabsOtherThanConfigurationAndSummaryDisplayed(dataAvailability.ActiveSpecification);
//             } else {
//                 showNavButtonVars.value.push(true);
//             }

//         }else {
//             showNavButtonVars.value.push(false);
//         }

//     });
    
//     triggerRef(showNavButtonVars);

// },

updateNavBarTextObservable = function() {
    navBarTextObservable.value = {
        homeButtonTooltip: config.getTranslationText('2397'),
        saveButtonTooltip: config.getTranslationText('2340'),
        saveText: config.getTranslationText('2398'),
        saveTextTooltip: config.getTranslationText('2532'),
        saveAsText: config.getTranslationText('2338'),
        saveAsTextTooltip: config.getTranslationText('2533'),
        updateButtonTooltip: config.getTranslationText('2351'),
        updateText: config.getTranslationText('1112'),
        updateAndSubmitText: config.getTranslationText('1113'),
        shareButtonTooltip: config.getTranslationText('2399'),
        undoTooltip: config.getTranslationText('2395'),
        redoTooltip: config.getTranslationText('2396'),
        settingsButtonTooltip: config.getTranslationText('2351'),
        changePasswordText: config.getTranslationText('2352'),
        verifyEmailText: config.getTranslationText('2353'),
        referAFriendText: config.getTranslationText('2383'),
        refreshDataText: config.getTranslationText('2384'),
        uploadVehicleDataText: config.getTranslationText('2708'),
        openTsvFileText: config.getTranslationText('4081'),
        manageAccountText: config.getTranslationText('1210'),
        helpText: config.getTranslationText('2385'),
        logoutText: config.getTranslationText('2386')
    };
    TScMessenger.writeDebugMessage('Globals.js, updateNavBarTextObservable, NavBarTextObservable updated, cultureCode = ' + globals.getActiveCultureCodeForTranslations());
    updateFullScreenTooltipText(isFullScreen.value);
    globals.user.setAvailableMeasurementSystemsDefault();
},

navigationIsFromDataRefreshScenario = function (passedInValToCheck) {

    let valToCheck = passedInValToCheck !== undefined ? passedInValToCheck : globals.user.getLastUrlHash();

    return valToCheck === config.APP_DATA_REFRESH_SCENARIO.APP_UPDATE ||
    valToCheck === config.APP_DATA_REFRESH_SCENARIO.USER_REFRESH ||
    valToCheck === config.APP_DATA_REFRESH_SCENARIO.ERROR_UPGRADING_USER_ALREADY_SUBSCRIBED ||
    valToCheck === config.APP_DATA_REFRESH_SCENARIO.ERROR_UPGRADING_USER_AFTER_SUCCESSFUL_SUBSCRIBE ||
    valToCheck === config.APP_DATA_REFRESH_SCENARIO.FORCED_REFRESH_MISSING_DATA ||
    valToCheck === config.APP_DATA_REFRESH_SCENARIO.CHANGING_LANGUAGE ||
    valToCheck === config.APP_DATA_REFRESH_SCENARIO.FORCED_REFRESH_AFTER_BUY;
},

chassisHasAddedEquipment = function (chassisAsJSON) {
    return chassisAsJSON.Accessories !== undefined && chassisAsJSON.Accessories !== null && (chassisAsJSON.Accessories.Body !== undefined || chassisAsJSON.Accessories.Bodies !== undefined || chassisAsJSON.Accessories.Crane !== undefined || chassisAsJSON.Accessories.Cranes !== undefined ||
        chassisAsJSON.Accessories.Fridge !== undefined || chassisAsJSON.Accessories.Fridges !== undefined || chassisAsJSON.Accessories.Taillift !== undefined || chassisAsJSON.Accessories.Taillifts !== undefined ||
        (chassisAsJSON.Accessories.Others !== undefined && chassisAsJSON.Accessories.Others.length > 0))
},

clearTimers = function () {
    if (isOnlineTimerRef !== undefined) {
        clearInterval(isOnlineTimerRef);
        isOnlineTimerRef = undefined;
    }
    if (postDebugLogsTimerRef !== undefined) {
        clearInterval(postDebugLogsTimerRef);
        postDebugLogsTimerRef = undefined;
    }
    if (postUsageTimerRef !== undefined) {
        clearInterval(postUsageTimerRef);
        postUsageTimerRef = undefined;
    }
},

setIsOnlineTimerRef = function (intervalId) {
    isOnlineTimerRef = intervalId;
},

setPostDebugLogsTimerRef = function (intervalId) {
    postDebugLogsTimerRef = intervalId;
},

setPostUsageTimerRef = function (intervalId) {
    postUsageTimerRef = intervalId;
},

getAccessToken = function () {
    return accessToken;
},

setAccessToken = function (token) {
    accessToken = token;
},

setStorageKey = function(newValue) {
    storageKey = newValue;
},

getStorageKey = function() {
    return storageKey;
},

setPreventReAuthAttempt = function(newValue) {
    preventReAuthAttempt = newValue;
},

getPreventReAuthAttempt = function() {
    return preventReAuthAttempt;
},

getIncomingSWVersionNumber = function() {
    return incomingSWVersionNumber;
},

setIncomingSWVersionNumber = function (newValue) {
    incomingSWVersionNumber = newValue;
},

updateFullScreenTooltipText = function(isFullScreenParam) {
    if (isFullScreenParam === true) {
        fullScreenButtonTooltip.value = config.getTranslationText('2345');
    } else {
        fullScreenButtonTooltip.value = config.getTranslationText('1057');
    }
},

showUpgradeToolTipForEssentialsUser = function () {
    return globals.user.getEdition() === config.APP_EDITION.ESSENTIALS;
},

setServiceWorkerScope = function (newValue) {
    serviceWorkerScope = newValue;
},

getServiceWorkerScope = function () {
    return serviceWorkerScope;
},

checkForUpdate = function(route) {
    let updateCheckDfd = $.Deferred();

    if (navigator.serviceWorker) {
            
        TScMessenger.writeDebugMessage('Globals.js, checkForUpdate, checking for updates');
        
        navigator.serviceWorker.getRegistration(globals.getServiceWorkerScope()).then((registration) => {
            if (registration) {
                registration.update().then(function (registrationFromUpdate){
                    if(!registrationFromUpdate.waiting && !registrationFromUpdate.installing && registrationFromUpdate.active) {
                        updateCheckDfd.resolve({ UpdateCheckResult: 'continue_with_externalAccess'});
                        // globals.spinnerText.value = 'Validating Access Token...';
                        TScMessenger.writeDebugMessage('Globals.js, checkForUpdate, active&&!waiting&&!installing, proceed with validating access token');
                    }else if(registrationFromUpdate.waiting) {
                        localStorage.setItem('externalAccessAPIUser', route.hash);
                    
                        // globals.spinnerText.value = 'Applying waiting update...';
                        TScMessenger.writeDebugMessage('Globals.js, checkForUpdate, waiting');
                        navigator.serviceWorker.addEventListener('controllerchange', function (event) {
                            //console.log('controlling event detected');
                            TScMessenger.writeDebugMessage('Globals.js, checkForUpdate, waiting, controllerchange, calling forceDataRefresh');
                            bus.emit('forceDataRefresh', { APP_DATA_REFRESH_SCENARIO: config.APP_DATA_REFRESH_SCENARIO.APP_UPDATE });
                        });
                        TScMessenger.writeDebugMessage('Globals.js, checkForUpdate, waiting, calling skipwaiting...');
                        registrationFromUpdate.waiting.postMessage({ type: 'SKIP_WAITING' });
                        // globals.spinnerText.value = 'Update detected, please wait while update is installed...';
                        updateCheckDfd.resolve({ UpdateCheckResult: 'abort_externalAccess_update_waiting'});
                        
                    }else if(registrationFromUpdate.installing) {
                        localStorage.setItem('externalAccessAPIUser', route.hash);
                        registrationFromUpdate.installing.addEventListener('statechange', function (event) {
                            TScMessenger.writeDebugMessage('Globals.js, checkForUpdate, installing, statechange');
                            if (event.target.state === 'installed') {
                                if (registrationFromUpdate.active) {
                                    if(registrationFromUpdate.waiting) {
                                        TScMessenger.writeDebugMessage('Globals.js, checkForUpdate, statechange, installed,  active&&waiting, emitting handleAppUpdate');
                                        registrationFromUpdate.waiting.postMessage({ type: 'NEW_APP_ACKNOWLEDGE_RESPONSE'});
                                        // globals.spinnerText.value = '/externalAccess, Applying update...';
                                        navigator.serviceWorker.addEventListener('controllerchange', function (event) {
                                            //console.log('controlling event detected');
                                            TScMessenger.writeDebugMessage('Globals.js, checkForUpdate, statechange, installed,  active&&waiting, controllerchange, calling forceDataRefresh');
                                            bus.emit('forceDataRefresh', { APP_DATA_REFRESH_SCENARIO: config.APP_DATA_REFRESH_SCENARIO.APP_UPDATE });
                                            // forceDataRefresh(config.APP_DATA_REFRESH_SCENARIO.APP_UPDATE);
                                        });
                                        //console.log('sending skip waiting message...');
                                        TScMessenger.writeDebugMessage('Globals.js, checkForUpdate, statechange, installed, active&&waiting, calling skipwaiting');
                                        registrationFromUpdate.waiting.postMessage({ type: 'SKIP_WAITING' });
                                    }
                                    
                                    //doUpdateCode();
                                } else {
                                    //do nothing - first time install
                                }
                            }
                        });
                        // globals.spinnerText.value = 'Update detected, please wait while update is installed...';
                        updateCheckDfd.resolve({ UpdateCheckResult: 'abort_externalAccess_update_installing'});
                        
                    }

                    TScMessenger.writeDebugMessage('Globals.js, checkForUpdate, waiting: ' + registrationFromUpdate.waiting );
                    TScMessenger.writeDebugMessage('Globals.js, checkForUpdate, active: ' + registrationFromUpdate.active );
                    TScMessenger.writeDebugMessage('Globals.js, checkForUpdate, installing: ' + registrationFromUpdate.installing );
                })
                .catch(function () {
                    updateCheckDfd.resolve({ UpdateCheckResult: 'continue_with_externalAccess'});
                });
            }else {
                updateCheckDfd.resolve({ UpdateCheckResult: 'continue_with_externalAccess'});
            }
        }); 
    
    } else {
        updateCheckDfd.resolve({ UpdateCheckResult: 'continue_with_externalAccess'});
    }
    return updateCheckDfd.promise();
}
;

function getActiveCultureCode() {
    return activeCultureCode;
}

/**
 * Sets activeCultureCode to newValue if it is a string. Otherwise it calls clearActiveCultureCode which sets activeCultureCode to null.
 * @param {string} newValue - New culture code
 */
function setActiveCultureCode(newValue) {    
    if(typeof newValue === 'string') {
        activeCultureCode = newValue;
    } else {
        clearActiveCultureCode();
    }
    localStorage.setItem('activeCultureCode', activeCultureCode);
}

/**
 * Sets activeCultureCode to null which means to use culture code on user for translations
 */
function clearActiveCultureCode() {
    activeCultureCode = null;
}

/**
 * Returns activeCultureCode if it is a string. Otherwise it returns the user's culture code.
 * @returns {string}
 */
function getActiveCultureCodeForTranslations() {
    if(typeof getActiveCultureCode() === 'string') {
        return getActiveCultureCode();
    }
    return globals.user.getCultureCode();
}

/**
 * Update the active culture code on globals and set the language culture code afterwards
 * @param {string} newValue - New culture code value
 */
function updateActiveCultureCode(newValue) {
    setActiveCultureCode(newValue);
    config.setLanguageCultureCode(getActiveCultureCodeForTranslations());
}

function getCurrentCombinationType() {
    return currentCombinationType;
}

function setCurrentCombinationType(newValue) {
    currentCombinationType = newValue;
}

var globals = {
    calculateMonthlyRepaymentWithInterestRate: calculateMonthlyRepaymentWithInterestRate,
    calculateMonthlyRepaymentWithoutInterestRate: calculateMonthlyRepaymentWithoutInterestRate,
    calculateMonthlyRepaymentWithoutRepaymentTerm: calculateMonthlyRepaymentWithoutRepaymentTerm,
    calculateDepositAmount: calculateDepositAmount,
    calculateResidualAmount: calculateResidualAmount,
    isPrivateBrowsingDisabled: isPrivateBrowsingDisabled,
    hexToRgb: hexToRgb,
    // rgbToHex: rgbToHex,
    isValidEmailAddress: isValidEmailAddress,
    checkInputNumberAndUpdateObservable: checkInputNumberAndUpdateObservable,
    doesCustomerAlreadyExist: doesCustomerAlreadyExist,
    checkForExistingCustomerOverwrite: checkForExistingCustomerOverwrite,
    setPageContainerClass: setPageContainerClass,
    isiPad: isiPad,
    CCSStylesheetRuleStyle: CCSStylesheetRuleStyle,
    mapCredentials: mapCredentials,
    formatDateToBritishFormat: formatDateToBritishFormat,
    formatDateToIso8601Format: formatDateToIso8601Format,
    formatDateUsingCultureCode: formatDateUsingCultureCode,
    compareDateToTodayInDays: compareDateToTodayInDays,
    compareDatesInDays: compareDatesInDays,
    isInt: isInt,
    formatSeconds: formatSeconds,
    formatLongString: formatLongString,
    cloudServicesVersion1: cloudServicesVersion1,
    cloudServicesVersion2: cloudServicesVersion2,
    cloudServicesVersion3: cloudServicesVersion3,
    cloudServicesVersion4: cloudServicesVersion4,
    checkForPresenceOfElementCode: checkForPresenceOfElementCode,
    isOnline: isOnline,
    isiOS8: isiOS8,
    isiOS9: isiOS9,
    isiOS92: isiOS92,
    isiOS93: isiOS93,
    isiOS10: isiOS10,
    isiOS11: isiOS11,
    checkInputNumberAndUpdateOnRoadCost: checkInputNumberAndUpdateOnRoadCost,
    isSyncing: isSyncing,
    displayOnlineMessage: displayOnlineMessage,
    getOperatingSystem: getOperatingSystem,
    getAndroidVersion: getAndroidVersion,
    getBrowser: getBrowser,
    getBrowserDimensions: getBrowserDimensions,
    meetsMinimumRequirements: meetsMinimumRequirements,
    meetsMinimumBrowserDimensions: meetsMinimumBrowserDimensions,
    isValidAndroidOS: isValidAndroidOS,
    isAndroidStandalone: isAndroidStandalone,
    isiPadStandalone: isiPadStandalone,
    isOpenInTabletBrowser: isOpenInTabletBrowser,
    isTouchWindowsOS: isTouchWindowsOS,
    appLogosDownloaded: appLogosDownloaded,
    timersAlreadySetup: timersAlreadySetup,
    navigatedInFromSelectionOrOffers: navigatedInFromSelectionOrOffers,
    getBrowserVersion: getBrowserVersion,
    setupIsOnlineSubscription: setupIsOnlineSubscription,
    passedThroughShell: passedThroughShell,
    screenCounter: screenCounter,
    displayMessageAndTimeInConsole: displayMessageAndTimeInConsole,
    justLoggedOut: justLoggedOut,
    successfulBackgroundReAuth: successfulBackgroundReAuth,
    measurementSystemConversionValueLength: measurementSystemConversionValueLength,
    measurementSystemConversionUnitLength: measurementSystemConversionUnitLength,
    measurementSystemConversionValueDensity: measurementSystemConversionValueDensity,
    measurementSystemConversionUnitDensity: measurementSystemConversionUnitDensity,
    measurementSystemConversionValueMass: measurementSystemConversionValueMass,
    measurementSystemConversionUnitMass: measurementSystemConversionUnitMass,
    measurementSystemConversionValueLinearMassDensity: measurementSystemConversionValueLinearMassDensity,
    measurementSystemConversionUnitLinearMassDensity: measurementSystemConversionUnitLinearMassDensity,
    measurementSystemConversionValueVolume: measurementSystemConversionValueVolume,
    measurementSystemConversionUnitVolume: measurementSystemConversionUnitVolume,
    formatValue: formatValue,
    displayMenuItem: displayMenuItem,
    displayOnlineOrOfflineMessage: displayOnlineOrOfflineMessage,
    returnMenuEquipmentItemType: returnMenuEquipmentItemType,
    returnMenuFuelTankItemType: returnMenuFuelTankItemType,
    returnMenuValueUpDownBox: returnMenuValueUpDownBox,
    returnMenuValueTel: returnMenuValueTel,
    returnInteger: returnInteger,
    returnFloat: returnFloat,
    selectAllTextById: selectAllTextById,
    closeAllMenus: closeAllMenus,
    setUpTooltips: setUpTooltips,
    nextId: nextId,
    getiOSVersion: getiOSVersion,
    getDummyBodyBuilderCustomerLiteral: getDummyBodyBuilderCustomerLiteral,
    getUniqueObjectsFromArrayUsingProperty: getUniqueObjectsFromArrayUsingProperty,
    getUniqueObjectsFromArrayUsingPropertyFunction: getUniqueObjectsFromArrayUsingPropertyFunction,
    fadeAndShowElement: fadeAndShowElement,
    base64ToArrayBuffer: base64ToArrayBuffer,
    base64ToUint8Array: base64ToUint8Array,
    base64ToBlob: base64ToBlob,
    createBlobFromBase64: createBlobFromBase64,
    getBlobStorage: getBlobStorage,
    getTodaysDate: getTodaysDate,
    getFormattedDate: getFormattedDate,
    repositionMenu: repositionMenu,
    resetMenuPosition: resetMenuPosition,
    displayTooltip: displayTooltip,
    hideTooltip: hideTooltip,
    isAmericanCultureCode: isAmericanCultureCode,
    isFrenchCultureCode: isFrenchCultureCode,
    handleFrenchText: handleFrenchText,
    addAmericanPageContainer: addAmericanPageContainer,
    removeAmericanPageContainer: removeAmericanPageContainer,
    addFrenchPageContainer: addFrenchPageContainer,    
    removeFrenchPageContainer: removeFrenchPageContainer,
    drawTrialWatermark: drawTrialWatermark,
    updateStatus: updateStatus,
    getAlreadyAuthenticated: function () {
        return localStorage.getItem('alreadyAuthenticated');
    },
    setAlreadyAuthenticated: function (val) {
        localStorage.setItem('alreadyAuthenticated', val);
    },
    isValidDate: isValidDate,
    replaceNewLineWithBreaks: replaceNewLineWithBreaks,
    eventFiredFromDrawingElement: eventFiredFromDrawingElement,
    isValidPassword: isValidPassword,
    quickSearchWithSearchTermsCompleted: quickSearchWithSearchTermsCompleted,
    isValidNumberVal: isValidNumberVal,
    numSearchResults: numSearchResults,
    checkCanActivateAfterRefreshOrReload: checkCanActivateAfterRefreshOrReload,
    removeUnneccessaryCharactersFromGlobalizeNumberString: removeUnneccessaryCharactersFromGlobalizeNumberString,
    getBodyTypeText: getBodyTypeText,
    getBodyStubTypeText: getBodyStubTypeText,
    getAccessoryTypeTranslation: getAccessoryTypeTranslation,
    getAccessorySubTypeTranslation: getAccessorySubTypeTranslation,
    machineCodeFlag: machineCodeFlag,
    forceLicenceTransferMessage: forceLicenceTransferMessage,
    getDatabaseDateFormattedDDMMYYYY: getDatabaseDateFormattedDDMMYYYY,
    getConnectivityStatusForIntercom: getConnectivityStatusForIntercom,
    getIntercomMetadataObject: getIntercomMetadataObject,
    getIntercomObject: getIntercomObject,
    setSendEventInfoToIntercomCallback: setSendEventInfoToIntercomCallback,
    getSendEventInfoToIntercomCallback: getSendEventInfoToIntercomCallback,
    getModuleObjectForIntercom: getModuleObjectForIntercom,
    sendModuleDataToIntercom: sendModuleDataToIntercom,
    getIntercomMetadataForIsOnline: getIntercomMetadataForIsOnline,
    refreshDfd: refreshDfd,
    scriptTagWithSrcExistsInHtml: scriptTagWithSrcExistsInHtml,
    getTrailerTypeText: getTrailerTypeText,
    isGoogleAnalyticsKeyAvailable: isGoogleAnalyticsKeyAvailable,
    isValidEmailAddressForGoogleAnalytics: isValidEmailAddressForGoogleAnalytics,
    convertStringToTitleCase: convertStringToTitleCase,
    trialStampWatermarkIsAvailable: trialStampWatermarkIsAvailable,
    offerSyncing: offerSyncing,
    sortArrayInOrderCustomTemplateStandard: sortArrayInOrderCustomTemplateStandard,
    getAccessoryTypeRequestTitle: getAccessoryTypeRequestTitle,
    getAccessoryTypeAddTitle: getAccessoryTypeAddTitle,
    runEffect: runEffect,
    setTopPositionOfContainerRelativeToButton: setTopPositionOfContainerRelativeToButton,
    getBodyTypeAddTitle: getBodyTypeAddTitle,
    getTrailerTypeAddTitle: getTrailerTypeAddTitle,
    getMeasurementSystemUsingType: getMeasurementSystemUsingType,
    convertToLength: convertToLength,
    convertToMass: convertToMass,
    convertToVolume: convertToVolume,
    convertToSpeed: convertToSpeed,
    getUIFormattedLengthValue: getUIFormattedLengthValue,
    getUIFormattedMassValue: getUIFormattedMassValue,
    addComboboxCodeIfNotAvailable: addComboboxCodeIfNotAvailable,
    preventSendingOfIntercomEvent: preventSendingOfIntercomEvent,
    getAccessoryTypeRemoveTooltip: getAccessoryTypeRemoveTooltip,
    getAccessoryTypeSaveTooltip: getAccessoryTypeSaveTooltip,
    // hasRoute: hasRoute,
    removeQuotationMarks: removeQuotationMarks,
    returnValidFileName: returnValidFileName,
    // getAuthContext: getAuthContext,
    // setupAuthContext: setupAuthContext,
    getRegisterUserUsingMicrosoft: getRegisterUserUsingMicrosoft,
    setRegisterUserUsingMicrosoft: setRegisterUserUsingMicrosoft,
    isInternetExplorer: isInternetExplorer,
    trimTextAndAddEllipsisIfRequired: trimTextAndAddEllipsisIfRequired,
    getLetterFromInteger: getLetterFromInteger,
    decodeAccessCode: decodeAccessCode,
    isRecommendedBrowser: isRecommendedBrowser,
    accessCode: accessCode,
    convertToNumber: convertToNumber,
    convertRpmValue: convertRpmValue,
    getFridayFortnightDateString: getFridayFortnightDateString,
    wheelbaseSpecifiedOptionJustChanged: wheelbaseSpecifiedOptionJustChanged,
    fadeModuleView: fadeModuleView,
    fadeInAll: fadeInAll,
    hideAll: hideAll,
    fadeOutAll: fadeOutAll,
    closeOfferVisually: closeOfferVisually,
    openOfferVisually: openOfferVisually,
    showModuleView: showModuleView,
    isVariableREf: isVariableRef,
    checkIfValueExistsOnObject: checkIfValueExistsOnObject,
    checkIfValueExistsOnObjectUsingKeys: checkIfValueExistsOnObjectUsingKeys,
    getPageContainerClass: getPageContainerClass,
    getFileSizeInMBAsString: getFileSizeInMBAsString,
    getFileSizeInMB: getFileSizeInMB,
    checkFileSizeDoesNotExceedMaximumMB: checkFileSizeDoesNotExceedMaximumMB,
    checkFileSizeDoesNotExceedMaximumKB: checkFileSizeDoesNotExceedMaximumKB,
    getAccessoryTypeSaveModalText: getAccessoryTypeSaveModalText,
    onlineOfflineEventListenersAlreadySetup: onlineOfflineEventListenersAlreadySetup,
    getFinanceCostPerMonth: getFinanceCostPerMonth,
    getPricePerMonth: getPricePerMonth,
    getFilteredLicenceCategoriesArray: getFilteredLicenceCategoriesArray,
    getLicenceCategoryObjectToUse: getLicenceCategoryObjectToUse,
    getBodyLengthTooltipText: getBodyLengthTooltipText,
    getBodyHeightTooltipText: getBodyHeightTooltipText,
    getBodyWidthTooltipText: getBodyWidthTooltipText,
    calculateFinanceCostPerMonth: calculateFinanceCostPerMonth,
    doesPathIncludeAxleGroupItemThatExistsOnParentChassis: doesPathIncludeAxleGroupItemThatExistsOnParentChassis,
    getRegisteredServiceWorkerContainer: getRegisteredServiceWorkerContainer,
    setRegisteredServiceWorkerContainer: setRegisteredServiceWorkerContainer,
    registerServiceWorker: registerServiceWorker,
    getWidthOfText: getWidthOfText,
    getWheelbaseItemToSortOn: getWheelbaseItemToSortOn,
    getCabToAxleItemToSortOn: getCabToAxleItemToSortOn,
    getDefaultManufacturerSelectionWizardMultiSelectFilterObject: getDefaultManufacturerSelectionWizardMultiSelectFilterObject,
    createDistinctArray: createDistinctArray,
    getOpenTsvModalOnSelection: getOpenTsvModalOnSelection,
    setOpenTsvModalOnSelection: setOpenTsvModalOnSelection,
    costingAverageCostOfTyreMin: costingAverageCostOfTyreMin,
    costingAverageCostOfTyreMax: costingAverageCostOfTyreMax,
    costingAverageLifeOfTyreMin: costingAverageLifeOfTyreMin,
    costingAverageLifeOfTyreMax: costingAverageLifeOfTyreMax,
    costingTyreRateMin: costingTyreRateMin,
    costingTyreRateMax: costingTyreRateMax,
    getPaddedAppVersionNumber: getPaddedAppVersionNumber,
    getPaddedStructureVersionNumber: getPaddedStructureVersionNumber,
    setPreventClearingOfPerformanceResultsWhenOpeningTSV: setPreventClearingOfPerformanceResultsWhenOpeningTSV,
    getPreventClearingOfPerformanceResultsWhenOpeningTSV: getPreventClearingOfPerformanceResultsWhenOpeningTSV,
    getDefaultEditLevel: getDefaultEditLevel,
    encodeUniqueLegislationId: encodeUniqueLegislationId,
    decodeUniqueLegislationId: decodeUniqueLegislationId,
    sortLegislationsArray: sortLegislationsArray,
    getDistinctLegislationStubsArray: getDistinctLegislationStubsArray,
    sortDataPointsArray: sortDataPointsArray,
    isObjectValid: isObjectValid,
    excludeApplicationItems: excludeApplicationItems,
    returnApplicationItemsOnly: returnApplicationItemsOnly,
    getNavigatingHome: getNavigatingHome,
    setNavigatingHome: setNavigatingHome,
    checkMultiSelectArrays: checkMultiSelectArrays,
    sortByAxleLayout: sortByAxleLayout,
    sortByAxleLayoutWithType: sortByAxleLayoutWithType,
    roundedToFixed: roundedToFixed,
    getMessageCodeForTrialUsers: getMessageCodeForTrialUsers,
    getIdOrNumberFromPath: getIdOrNumberFromPath,
    showSpinner: showSpinner,
    hideSpinner: hideSpinner,
    showDialog: showDialog,
    // manageHiddenButtons: manageHiddenButtons,
    // showNavButtonVars: showNavButtonVars,
    setSpinnerVisible: setSpinnerVisible,
    spinnerText: spinnerText,
    addItemToShallowRefArray: addItemToShallowRefArray,
    clearShallowRefArrayAndAddItems: clearShallowRefArrayAndAddItems,
    loginScreenActive: loginScreenActive,
    paymentMenuStatus: paymentMenuStatus,
    navBarTextObservable: navBarTextObservable,
    updateNavBarTextObservable: updateNavBarTextObservable,
    showYourAreAlreadySubscribedMessageFlag: showYourAreAlreadySubscribedMessageFlag,
    showYouHaveSuccessfullySubscribedMessageFlag: showYouHaveSuccessfullySubscribedMessageFlag,
    openedSharedOfferToastFlag: openedSharedOfferToastFlag,
    signalRConnection: signalRConnection,
    salesModeActive: salesModeActive,
    trainingModeActive: trainingModeActive,
    isFullScreen: isFullScreen,
    navigationIsFromDataRefreshScenario: navigationIsFromDataRefreshScenario,
    dependentActionDfd: dependentActionDfd,
    getTrialStatusMessageAlreadyShownForOpenOffer: getTrialStatusMessageAlreadyShownForOpenOffer,
    setTrialStatusMessageAlreadyShownForOpenOffer: setTrialStatusMessageAlreadyShownForOpenOffer,
    dataRefreshConcurrentPath1Complete: dataRefreshConcurrentPath1Complete,
    dataRefreshConcurrentPath2Complete: dataRefreshConcurrentPath2Complete,
    indexedDbReady: indexedDbReady,
    dataModelReady: dataModelReady,
    checkingForUpdates: checkingForUpdates,
    chassisHasAddedEquipment: chassisHasAddedEquipment,
    preventHideSpinner: preventHideSpinner,
    isOnlineTimerRef: isOnlineTimerRef,
    postDebugLogsTimerRef: postDebugLogsTimerRef, 
    postUsageTimerRef: postUsageTimerRef,
    clearTimers: clearTimers,
    watchersArr: watchersArr,
    modulesAddedOrDroppedAfterSwap: modulesAddedOrDroppedAfterSwap,
    appConfigApplied: appConfigApplied,
    setIsOnlineTimerRef: setIsOnlineTimerRef,
    setPostDebugLogsTimerRef: setPostDebugLogsTimerRef,
    setPostUsageTimerRef: setPostUsageTimerRef,
    getFuelConsumptionForMetricMeasurementSystem: getFuelConsumptionForMetricMeasurementSystem,
    getFuelConsumptionForImperialMeasurementSystem: getFuelConsumptionForImperialMeasurementSystem,
    getFuelPricePerVolumeConvertedUsingMeasurementSystem: getFuelPricePerVolumeConvertedUsingMeasurementSystem,
    getFuelPricePerHourConvertedUsingMeasurementSystem: getFuelPricePerHourConvertedUsingMeasurementSystem,
    getFuelConsumptionForVolumePer100Distance: getFuelConsumptionForVolumePer100Distance,
    getMonthlyFuelCostUsingFuelConsumptionPerDistance: getMonthlyFuelCostUsingFuelConsumptionPerDistance,
    getMonthlyFuelCostUsingFuelConsumptionPerHour: getMonthlyFuelCostUsingFuelConsumptionPerHour,
    getFuelConsumptionAsLitresPer100Km: getFuelConsumptionAsLitresPer100Km,
    getCostPerVolumeAsCostPerLitre: getCostPerVolumeAsCostPerLitre,
    getMonthlyDistanceForMeasurementSystem: getMonthlyDistanceForMeasurementSystem,
    getFuelConsumptionPerHourAsLitresPerHour: getFuelConsumptionPerHourAsLitresPerHour,
    getConvertedConsumptionValue: getConvertedConsumptionValue,
    getFuelPricePerVolumeValue: getFuelPricePerVolumeValue,
    getVehicleAdBlueCostPerMonthValue: getVehicleAdBlueCostPerMonthValue,
    refreshDfdCallback: refreshDfdCallback,
    refreshDfdFailCallback: refreshDfdFailCallback,
    curWarnings: curWarnings,
    curTips: curTips,
    debugLegislation: debugLegislation,
    getAverageLifeOfTyresValueForMeasurementSystem: getAverageLifeOfTyresValueForMeasurementSystem,
    getConvertedFuelPriceValue: getConvertedFuelPriceValue,
    getConvertedFuelPricePerHourValue: getConvertedFuelPriceValue,
    curDDRNotes: curDDRNotes,
    getGrossAxleWeightRatingDefaultValue: getGrossAxleWeightRatingDefaultValue,
    getNetPrice: getNetPrice,
    userSettingsDfd: userSettingsDfd,
    selectionListDfd: selectionListDfd,
    passesManufacturerLimits: passesManufacturerLimits,
    passesRegulatoryCompliance: passesRegulatoryCompliance,
    loginDfd: loginDfd,
    dmLoginDfd: dmLoginDfd,
    setAccessToken: setAccessToken,
    getAccessToken: getAccessToken,
    setStorageKey: setStorageKey,
    getStorageKey: getStorageKey,
    setPreventReAuthAttempt: setPreventReAuthAttempt,
    getPreventReAuthAttempt: getPreventReAuthAttempt,
    getManufacturerTextIncludeInMessageObject: getManufacturerTextIncludeInMessageObject,
    getManufacturerRequestItemTooltipTextCode: getManufacturerRequestItemTooltipTextCode,
    checkIfStringIsUpperCase: checkIfStringIsUpperCase,
    checkIfStringIsLowerCase: checkIfStringIsLowerCase,
    getIncomingSWVersionNumber: getIncomingSWVersionNumber,
    setIncomingSWVersionNumber: setIncomingSWVersionNumber,
    fullScreenButtonTooltip: fullScreenButtonTooltip,
    updateFullScreenTooltipText: updateFullScreenTooltipText,
    showUpgradeToolTipForEssentialsUser: showUpgradeToolTipForEssentialsUser,
    getAvailableCountriesList: getAvailableCountriesList,
    getCountryNameUsingAbbreviation: getCountryNameUsingAbbreviation,
    getCountryCodeUsingCountryName: getCountryCodeUsingCountryName,
    getCoreMarketsFromAvailableCountriesList: getCoreMarketsFromAvailableCountriesList,
    setServiceWorkerScope: setServiceWorkerScope,
    getServiceWorkerScope: getServiceWorkerScope,
    checkForUpdate: checkForUpdate,
    customDescriptionFunctionForMakeMultiselects: customDescriptionFunctionForMakeMultiselects,
    customDescriptionFunctionForAxleLayoutMultiselects: customDescriptionFunctionForAxleLayoutMultiselects,
    getActiveCultureCode: getActiveCultureCode,    
    setActiveCultureCode: setActiveCultureCode,
    clearActiveCultureCode: clearActiveCultureCode,
    getActiveCultureCodeForTranslations: getActiveCultureCodeForTranslations,
    getCurrentCombinationType: getCurrentCombinationType,
    setCurrentCombinationType: setCurrentCombinationType,
    updateActiveCultureCode: updateActiveCultureCode
};

export default globals;

function setupIsOnlineSubscription(theUser) {
    var isOnlineMessageSubscriptionRef = watch(isOnline, function (newValue) {
        var cultureCode;
        if (theUser === undefined) {
            cultureCode = 'en';
        } else {
            cultureCode = theUser.getCultureCode();
        }
        var showMsg = false;
        if (lastIsOnlineValue !== undefined) {
            if (newValue !== lastIsOnlineValue) {
                showMsg = true;
            }
        } else {
            showMsg = true;
        }
        lastIsOnlineValue = newValue;
        if (showMsg === true) {
            displayOnlineOrOfflineMessage(newValue, cultureCode);

            //if (!newValue) {
            //    logger.log(config.getMessageText('1574', cultureCode), 'globals', 'globals', true, config.toastrOptionsOffline);
            //    displayOnlineMessage = true;
            //} else {
            //    toastr.clear();
            //    if (displayOnlineMessage) {
            //        logger.log(config.getMessageText('1575', cultureCode), 'globals', 'globals', true, config.toastrOptionsOffline);
            //        displayOnlineMessage = false;
            //    }
            //}
        }
        //var intercomObj = {
        //    FromStatus: newValue === true ? config.CONNECTIVITY_STATUS.OFFLINE : config.CONNECTIVITY_STATUS.ONLINE,
        //    ToStatus: newValue === true ? config.CONNECTIVITY_STATUS.ONLINE : config.CONNECTIVITY_STATUS.OFFLINE
        //};
        if (newValue !== true) {
            setTimeout(function () {
                //sendEventInfoToIntercomCallback(config.OPERATION.SendIntercomEventData, getIntercomObject(config.INTERCOM_EVENT.CHANGED_CONNECTIVITY, intercomObj));
                sendEventInfoToIntercomCallback(config.OPERATION.SendIntercomEventData, getIntercomObject(config.INTERCOM_EVENT.CHANGED_CONNECTIVITY, getIntercomMetadataForIsOnline(newValue)));
            }, 1000);
        }
    });
    return isOnlineMessageSubscriptionRef;
}

function getIntercomMetadataForIsOnline(newValue) {
    return {
        FromStatus: newValue === true ? config.CONNECTIVITY_STATUS.OFFLINE : config.CONNECTIVITY_STATUS.ONLINE,
        ToStatus: newValue === true ? config.CONNECTIVITY_STATUS.ONLINE : config.CONNECTIVITY_STATUS.OFFLINE
    };
}


function displayOnlineOrOfflineMessage(onlineStatus, cultureCode) {
    if (onlineStatus === false) {
        logger.log(config.getMessageText('1574', cultureCode), 'globals', 'globals', true, config.toastrOptionsOffline);
        displayOnlineMessage = true;
    } else {
        toastr.clear();
        if (displayOnlineMessage) {
            logger.log(config.getMessageText('1575', cultureCode), 'globals', 'globals', true, config.toastrOptionsOffline);
            displayOnlineMessage = false;
        }
    }
}

function base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

function base64ToUint8Array(base64) {
    var raw = atob(base64);
    var uint8Array = new Uint8Array(raw.length);
    for (var i = 0; i < raw.length; i++) {
        uint8Array[i] = raw.charCodeAt(i);
    }
    setBlobStorage(createBlobFromUint8Array(uint8Array, 'application/pdf'));
    return uint8Array;
}

function createBlobFromUint8Array(uint8Array, contentType) {
    return new Blob([uint8Array], { type: contentType });
}

function getBlobStorage() {
    return blobStorage;
}

function setBlobStorage(value) {
    blobStorage = value;
}


/**
 * Function takes in base64 string and converts to Blob. From PDFJS Express documentation (https://pdfjs.express/documentation/basic-operations/open-document/base64)
 * @param {string} base64 - Base64 string to convert to Blob
 * @returns {Blob}
 */
function base64ToBlob(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    return new Blob([bytes], { type: 'application/pdf' });
}

function createBlobFromBase64(base64, contentType) {
    var sliceSize = 512;
    var byteCharacters = atob(base64);
    var byteArrays = [];
    var offset;
    for (offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        var i;
        for (i = 0; i < slice.length; i += 1) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

function getTodaysDate(user) {
    var today = new Date(),
        options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        };

    return today.toLocaleDateString(user.getCultureCode(), options).replace(/[^\x20-\x7E]/g, '');

    //var dd = today.getDate();
    //var mm = today.getMonth() + 1; //January is 0!
    //var yyyy = today.getFullYear();

    //if (dd < 10) {
    //    dd = '0' + dd
    //}

    //if (mm < 10) {
    //    mm = '0' + mm
    //}

    ////http://en.wikipedia.org/wiki/Date_format_by_country
    ////Vietnam:
    ////Little-endian (day, month, year), e.g. 22.04.96 or 22/04/96 or 22 April 1996
    //todaysDate = dd + '/' + mm + '/' + yyyy;
}

function getFormattedDate(cultureCode, date) {
    var options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    };

    return date.toLocaleDateString(cultureCode, options).replace(/[^\x20-\x7E]/g, '');
}

function isValidDate(dateString) {
    try {
        var dateArray = dateString.split('/');
        var year = Number(dateArray[2]),
            month = Number(dateArray[1]) - 1,
            day = Number(dateArray[0]);
        var d = new Date(year, month, day);
        //var d = new Date(Number(dateArray[2]), Number(dateArray[1]), Number(dateArray[0]));
        if (d.getFullYear() == year && d.getMonth() == month && d.getDate() == day) {
            return true;
        }
        return false;
    } catch (e) {
        return false;
    }
}

function getDatabaseDateFormattedDDMMYYYY(stringDateToFormat) {
    var dateArray = stringDateToFormat.split('-');
    var returnDate = new Date(parseInt(dateArray[0]), parseInt(dateArray[1]), parseInt(dateArray[2].substr(0, 2)));
    var year = returnDate.getFullYear(),
        month = returnDate.getMonth() + 1,
        day = returnDate.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return day + '/' + month + '/' + year;
}

function isValidPassword(value) {
    if (value === undefined) {
        return false;
    }
    if (value.length < 8 || value.length > 20) {
    // if (value.length < 6) {
        return false;
    }
    if (!(/[a-z]/.test(value) && /[0-9]/.test(value) && /[A-Z]/.test(value) && /[!@#$%^&*]/.test(value))) {
       return false;
    }

    return true;
}

/**
 * Checks if culture code is US/CA and returns boolean
 * @param {string} cultureCode - Culture code to check
 * @returns {boolean} - True if US/CA else False
 */
function isAmericanCultureCode(cultureCode) {
    return cultureCode.toLowerCase() === config.LANGUAGE_PACK.ENGLISH.US.cultureCode.toLowerCase() || cultureCode.toLowerCase() === config.LANGUAGE_PACK.ENGLISH.CA.cultureCode.toLowerCase() || cultureCode.toLowerCase() === config.LANGUAGE_PACK.FRENCH.CA.cultureCode.toLowerCase();
}

function isFrenchCultureCode(cultureCode) {
    return cultureCode.toLowerCase() === config.LANGUAGE_PACK.FRENCH.CA.cultureCode.toLowerCase();
}

function addAmericanPageContainer() {
    $('#shell-main-view').removeClass('en-us-page-container').addClass('en-us-page-container');
}

function removeAmericanPageContainer() {
    if ($('#shell-main-view').hasClass('en-us-page-container')) {
        $('#shell-main-view').removeClass('en-us-page-container');
    }
}

function addFrenchPageContainer() {
    $('#shell-main-view').removeClass('fr-page-container').addClass('fr-page-container');
}

function removeFrenchPageContainer() {
    if ($('#shell-main-view').hasClass('fr-page-container')) {
        $('#shell-main-view').removeClass('fr-page-container');
    }
}

function handleFrenchText(cultureCode) {
    return isFrenchCultureCode(cultureCode);
}

function removeUnneccessaryCharactersFromGlobalizeNumberString(value) {
    var returnVal, tempVal = value;
    tempVal = tempVal.replace(/\s/g, '');
    // Get the decimal place symbol            
    var splitter = Globalize.culture().numberFormat['.'];
    tempVal = tempVal.replace(/[A-Z][a-z]/g, '');
    tempVal = tempVal.split(splitter);
    var integer = tempVal[0].replace(/[^\d-]/g, "");
    if (tempVal.length > 1) {
        var fraction = tempVal[1].replace(/\D+/g, "");
        tempVal = integer + splitter + fraction;
    } else {
        tempVal = integer;
    }
    returnVal = tempVal;
    return returnVal;
}

function getBodyTypeText(bodyType) {
    switch (bodyType) {
        case config.BODY_TYPES.VAN:
        case config.TRAILER_BODY_TYPES.VAN:
            return config.getTranslationText('530');
        case config.BODY_TYPES.TIPPER:
        case config.TRAILER_BODY_TYPES.TIPPER:
            return config.getTranslationText('487');
        case config.BODY_TYPES.DROPSIDE:
        case config.TRAILER_BODY_TYPES.DROPSIDE:
            return config.getTranslationText('120');
        case config.BODY_TYPES.TAUTLINER:
        case config.TRAILER_BODY_TYPES.TAUTLINER:
            return config.getTranslationText('475');
        case config.BODY_TYPES.FLATDECK:
        case config.TRAILER_BODY_TYPES.FLATDECK:
            return config.getTranslationText('145');
        case config.BODY_TYPES.TANKER:
        case config.TRAILER_BODY_TYPES.TANKER:
            return config.getTranslationText('473');
        case config.BODY_TYPES.PIGCARRIER:
            return config.getTranslationText('304');
        case config.BODY_TYPES.SHEEPCARRIER:
            return config.getTranslationText('423');
        case config.BODY_TYPES.CATTLECARRIER:
        case config.TRAILER_BODY_TYPES.CATTLECARRIER:
            return config.getTranslationText('69');
        case config.BODY_TYPES.COMPACTOR:
            return config.getTranslationText('84');
        case config.BODY_TYPES.ROLLBACK:
            return config.getTranslationText('396');
        case config.BODY_TYPES.TIMBER:
        case config.TRAILER_BODY_TYPES.TIMBER:
            return config.getTranslationText('486');
        case config.BODY_TYPES.BEAVERTAIL:
            return config.getTranslationText('587');
        case config.BODY_TYPES.HOOKLIFT:
            return config.getTranslationText('601');
        case config.BODY_TYPES.OTHER:
            return config.getTranslationText('2768');
        case config.BODY_TYPES.SERVICE:
            return config.getTranslationText('1211');
        case config.TRAILER_BODY_TYPES.CANE:
            return config.getTranslationText('4621');
        case config.TRAILER_BODY_TYPES.BULKER:
            return config.getTranslationText('4622');
        case config.TRAILER_BODY_TYPES.DOLLY:
            return config.getTranslationText('4623');
        case config.TRAILER_BODY_TYPES.LOWBED:
            return config.getTranslationText('4624');
        case config.TRAILER_BODY_TYPES.REEFER:
            return config.getTranslationText('4625');
        case config.TRAILER_BODY_TYPES.SKELETAL:
            return config.getTranslationText('4626');
        case config.TRAILER_BODY_TYPES.WALKINGFLOOR:
            return config.getTranslationText('4627');
        case config.BODY_TYPES.NONE:
        case config.TRAILER_BODY_TYPES.NONE:
            return config.getTranslationText('4044');
    }

    return '';
}

function getBodyStubTypeText(bodyType) {
    switch (bodyType) {
        case config.BODY_TYPES.VAN:
            return config.getTranslationText('530');
        case config.BODY_TYPES.TIPPER:
            return config.getTranslationText('487');
        case config.BODY_TYPES.DROPSIDE:
            return config.getTranslationText('120');
        case config.BODY_TYPES.TAUTLINER:
            return config.getTranslationText('475');
        case config.BODY_TYPES.FLATDECK:
            return config.getTranslationText('145');
        case config.BODY_TYPES.TANKER:
            return config.getTranslationText('473');
        case config.BODY_TYPES.PIGCARRIER:
        case config.BODY_TYPES.SHEEPCARRIER:
        case config.BODY_TYPES.CATTLECARRIER:
            return config.getTranslationText('224');
        case config.BODY_TYPES.COMPACTOR:
            return config.getTranslationText('84');
        case config.BODY_TYPES.ROLLBACK:
            return config.getTranslationText('396');
        case config.BODY_TYPES.TIMBER:
            return config.getTranslationText('486');
        case config.BODY_TYPES.BEAVERTAIL:
            return config.getTranslationText('587');
        case config.BODY_TYPES.HOOKLIFT:
            return config.getTranslationText('601');
        case config.BODY_TYPES.OTHER:
            return config.getTranslationText('2768');
        case config.BODY_TYPES.SERVICE:
            return config.getTranslationText('1211');
    }
}

function getTrailerTypeText(trailerType, path) {
    switch (trailerType) {
        case config.TRAILER_TYPES.DRAWBAR:
            return config.getTranslationText('920');
        case config.TRAILER_TYPES.PUP:
            return config.getTranslationText('1042');
        case config.TRAILER_TYPES.INTERLINK:
            return config.getTranslationText('1263');
        case config.TRAILER_TYPES.SEMI:
            if (typeof path === 'string') {
                if (path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
                    return config.getTranslationText('674');
                } else if (path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
                    return config.getTranslationText('1404');
                }
            }
            return config.getTranslationText('669');
    }
}

function getAccessoryTypeTranslation(accessoryType, accessorySubType) {
    switch (accessoryType) {
        case config.ACCESSORY_TYPES.CRANE:
            return config.getTranslationText('93');
        case config.ACCESSORY_TYPES.FRIDGE:
            return config.getTranslationText('149');
        case config.ACCESSORY_TYPES.TAILLIFT:
            return config.getTranslationText('470');
        case config.ACCESSORY_TYPES.FAIRING:
            return config.getTranslationText('136');
        case config.ACCESSORY_TYPES.HEADBOARD:
            return config.getTranslationText('178');
        case config.ACCESSORY_TYPES.CREW_CAB:
            return config.getTranslationText('99');
        case config.ACCESSORY_TYPES.BULLBAR:
            return config.getTranslationText('50');
        case config.ACCESSORY_TYPES.FUEL_TANK:
            return config.getTranslationText('163');
        case config.ACCESSORY_TYPES.ROLLOVER:
            return config.getTranslationText('397');
        case config.ACCESSORY_TYPES.OTHER:
            switch (accessorySubType) {
                case config.ACCESSORY_TYPES.BULLBAR:
                case config.ACCESSORY_TYPES.BULLBAR.toUpperCase():
                    return config.getTranslationText('50');
                case config.ACCESSORY_TYPES.FAIRING:
                case config.ACCESSORY_TYPES.FAIRING.toUpperCase():
                    return config.getTranslationText('136');
                case config.ACCESSORY_TYPES.REAR_BUMPER:
                case config.ACCESSORY_TYPES.REAR_BUMPER.toUpperCase():
                    return config.getTranslationText('1420');
                case config.ACCESSORY_TYPES.TOOLBOX:
                case config.ACCESSORY_TYPES.TOOLBOX.toUpperCase():
                    return config.getTranslationText('1421');
                case config.ACCESSORY_TYPES.AEROKIT:
                case config.ACCESSORY_TYPES.AEROKIT.toUpperCase():
                    return config.getTranslationText('1422');
                case config.ACCESSORY_TYPES.SNOWPLOW:
                case config.ACCESSORY_TYPES.SNOWPLOW.toUpperCase():
                    return config.getTranslationText('1423');
                case config.ACCESSORY_TYPES.AERIAL_PLATFORM:
                case config.ACCESSORY_TYPES.AERIAL_PLATFORM.toUpperCase():
                    return config.getTranslationText('1424');
                case config.ACCESSORY_TYPES.OUTRIGGER:
                case config.ACCESSORY_TYPES.OUTRIGGER.toUpperCase():
                    return config.getTranslationText('1425');
                case config.ACCESSORY_TYPES.OTHER:
                case config.ACCESSORY_TYPES.OTHER.toUpperCase():
                    return config.getTranslationText('281');
                default:
                    return config.getTranslationText('281');
            }
        case config.ACCESSORY_TYPES.BODY:
            return config.getTranslationText('39');
        case config.ACCESSORY_TYPES.FIFTH_WHEEL:
            return config.getTranslationText('1456');
        case config.ACCESSORY_TYPES.PAYLOAD:
            return config.getTranslationText('290');
        case config.ACCESSORY_TYPES.HITCH:
            return config.getTranslationText('188');
        case config.ACCESSORY_TYPES.HOOKLIFT:
            return config.getTranslationText('4367');
    }
}

function getAccessorySubTypeTranslation(accessoryType, accessorySubType) {
    switch (accessoryType) {
        case config.ACCESSORY_TYPES.CRANE:
        case config.ACCESSORY_TYPES.FRIDGE:
        case config.ACCESSORY_TYPES.FAIRING:
        case config.ACCESSORY_TYPES.HEADBOARD:
        case config.ACCESSORY_TYPES.CREW_CAB:
        case config.ACCESSORY_TYPES.BULLBAR:
        case config.ACCESSORY_TYPES.FUEL_TANK:
        case config.ACCESSORY_TYPES.ROLLOVER:
        case config.ACCESSORY_TYPES.OTHER:
        case config.ACCESSORY_TYPES.BODY:
        case config.ACCESSORY_TYPES.FIFTH_WHEEL:
        case config.ACCESSORY_TYPES.PAYLOAD:
        case config.ACCESSORY_TYPES.HOOKLIFT:
            break;
        // case config.ACCESSORY_TYPES.HITCH:            
        //     switch (accessorySubType) {
        //         case config.ACCESSORY_SUB_TYPES.CONVENTIONAL:
        //             return config.getTranslationText('2698');
        //         case config.ACCESSORY_SUB_TYPES.UNDERSLUNG:
        //             return config.getTranslationText('2699');
        //     }
        //     break;
        // case config.ACCESSORY_TYPES.TAILLIFT:
        //     switch (accessorySubType) {
        //         case config.TAILLIFT_TYPES.CANTILEVER:
        //             return config.getTranslationText('1336');
        //         case config.TAILLIFT_TYPES.COLUMN_UPPER_BOX:
        //             return config.getTranslationText('1337');
        //         case config.TAILLIFT_TYPES.COLUMN_LOWER_BOX:
        //             return config.getTranslationText('1338');
        //     }
        //     break;
        case config.ACCESSORY_TYPES.HITCH:
        case config.ACCESSORY_TYPES.TAILLIFT:
            return this.user.getTranslationForAccessoryCode(accessoryType, accessorySubType);
    }
}

function getAccessoryTypeRequestTitle(accessoryType, accessorySubType) {
    switch (accessoryType) {
        case config.ACCESSORY_TYPES.CRANE:
            return config.getTranslationText('731');
        case config.ACCESSORY_TYPES.FRIDGE:
            return config.getTranslationText('732');
        case config.ACCESSORY_TYPES.TAILLIFT:
            return config.getTranslationText('733');
        case config.ACCESSORY_TYPES.FAIRING:
            return config.getTranslationText('734');
        case config.ACCESSORY_TYPES.HEADBOARD:
            return config.getTranslationText('735');
        case config.ACCESSORY_TYPES.CREW_CAB:
            return config.getTranslationText('736');
        case config.ACCESSORY_TYPES.BULLBAR:
            return config.getTranslationText('737');
        case config.ACCESSORY_TYPES.FUEL_TANK:
            return config.getTranslationText('738');
        case config.ACCESSORY_TYPES.ROLLOVER:
            return config.getTranslationText('739');
        case config.ACCESSORY_TYPES.OTHER:
            switch (accessorySubType) {
                case config.ACCESSORY_TYPES.BULLBAR:
                case config.ACCESSORY_TYPES.BULLBAR.toUpperCase():
                    return config.getTranslationText('737');
                case config.ACCESSORY_TYPES.FAIRING:
                case config.ACCESSORY_TYPES.FAIRING.toUpperCase():
                    return config.getTranslationText('734');
                case config.ACCESSORY_TYPES.REAR_BUMPER:
                case config.ACCESSORY_TYPES.REAR_BUMPER.toUpperCase():
                    return config.getTranslationText('1429');
                case config.ACCESSORY_TYPES.TOOLBOX:
                case config.ACCESSORY_TYPES.TOOLBOX.toUpperCase():
                    return config.getTranslationText('1430');
                case config.ACCESSORY_TYPES.AEROKIT:
                case config.ACCESSORY_TYPES.AEROKIT.toUpperCase():
                    return config.getTranslationText('1431');
                case config.ACCESSORY_TYPES.SNOWPLOW:
                case config.ACCESSORY_TYPES.SNOWPLOW.toUpperCase():
                    return config.getTranslationText('1432');
                case config.ACCESSORY_TYPES.AERIAL_PLATFORM:
                case config.ACCESSORY_TYPES.AERIAL_PLATFORM.toUpperCase():
                    return config.getTranslationText('1433');
                case config.ACCESSORY_TYPES.OUTRIGGER:
                case config.ACCESSORY_TYPES.OUTRIGGER.toUpperCase():
                    return config.getTranslationText('1434');
                case config.ACCESSORY_TYPES.OTHER:
                case config.ACCESSORY_TYPES.OTHER.toUpperCase():
                    return config.getTranslationText('740');
                default:
                    return config.getTranslationText('740');
            }
        case config.ACCESSORY_TYPES.FIFTH_WHEEL:
            return config.getTranslationText('741');
        case config.ACCESSORY_TYPES.PAYLOAD:
            return config.getTranslationText('901');
        case config.ACCESSORY_TYPES.HITCH:
            return config.getTranslationText('909');
        case config.ACCESSORY_TYPES.HOOKLIFT:
            return config.getTranslationText('4535');//'Hooklift';
    }
}

function getAccessoryTypeAddTitle(accessoryType, accessorySubType) {
    switch (accessoryType) {
        case config.ACCESSORY_TYPES.CRANE:
            return config.getTranslationText('719');
        case config.ACCESSORY_TYPES.FRIDGE:
            return config.getTranslationText('720');
        case config.ACCESSORY_TYPES.TAILLIFT:
            return config.getTranslationText('721');
        case config.ACCESSORY_TYPES.FAIRING:
            return config.getTranslationText('722');
        case config.ACCESSORY_TYPES.HEADBOARD:
            return config.getTranslationText('723');
        case config.ACCESSORY_TYPES.CREW_CAB:
            return config.getTranslationText('724');
        case config.ACCESSORY_TYPES.BULLBAR:
            return config.getTranslationText('725');
        case config.ACCESSORY_TYPES.FUEL_TANK:
            return config.getTranslationText('726');
        case config.ACCESSORY_TYPES.ROLLOVER:
            return config.getTranslationText('727');
        case config.ACCESSORY_TYPES.OTHER:
            switch (accessorySubType) {
                case config.ACCESSORY_TYPES.BULLBAR:
                case config.ACCESSORY_TYPES.BULLBAR.toUpperCase():
                    return config.getTranslationText('725');
                case config.ACCESSORY_TYPES.FAIRING:
                case config.ACCESSORY_TYPES.FAIRING.toUpperCase():
                    return config.getTranslationText('722');
                case config.ACCESSORY_TYPES.REAR_BUMPER:
                case config.ACCESSORY_TYPES.REAR_BUMPER.toUpperCase():
                    return config.getTranslationText('1435');
                case config.ACCESSORY_TYPES.TOOLBOX:
                case config.ACCESSORY_TYPES.TOOLBOX.toUpperCase():
                    return config.getTranslationText('1436');
                case config.ACCESSORY_TYPES.AEROKIT:
                case config.ACCESSORY_TYPES.AEROKIT.toUpperCase():
                    return config.getTranslationText('1437');
                case config.ACCESSORY_TYPES.SNOWPLOW:
                case config.ACCESSORY_TYPES.SNOWPLOW.toUpperCase():
                    return config.getTranslationText('1438');
                case config.ACCESSORY_TYPES.AERIAL_PLATFORM:
                case config.ACCESSORY_TYPES.AERIAL_PLATFORM.toUpperCase():
                    return config.getTranslationText('1439');
                case config.ACCESSORY_TYPES.OUTRIGGER:
                case config.ACCESSORY_TYPES.OUTRIGGER.toUpperCase():
                    return config.getTranslationText('1440');
                case config.ACCESSORY_TYPES.OTHER:
                case config.ACCESSORY_TYPES.OTHER.toUpperCase():
                    return config.getTranslationText('728');
                default:
                    return config.getTranslationText('728');
            }
        case config.ACCESSORY_TYPES.FIFTH_WHEEL:
            return config.getTranslationText('689');
        case config.ACCESSORY_TYPES.PAYLOAD:
            return config.getTranslationText('889');
        case config.ACCESSORY_TYPES.HITCH:
            return config.getTranslationText('910');
        case config.ACCESSORY_TYPES.HOOKLIFT:
            return config.getTranslationText('4332');
    }
}

function getAccessoryTypeSaveModalText(accessoryType, accessorySubType) {
    switch (accessoryType) {
        case config.ACCESSORY_TYPES.CRANE:
            //return config.getTranslationText('1441');
            return {
                //titleText: config.getTranslationText('1441'),
                //footerText: config.getTranslationText('1509'),
                //footerTooltipText: config.getTranslationText('1525')
                titleText: config.getTranslationText('1441'),
                footerText: config.getTranslationText('1509'),
                footerTooltipText: config.getTranslationText('1525')
            };
        case config.ACCESSORY_TYPES.FRIDGE:
            //return config.getTranslationText('1443');
            return {
                //titleText: config.getTranslationText('1443'),
                //footerText: config.getTranslationText('1510'),
                //footerTooltipText: config.getTranslationText('1526')
                titleText: config.getTranslationText('1443'),
                footerText: config.getTranslationText('1510'),
                footerTooltipText: config.getTranslationText('1526')
            };
        case config.ACCESSORY_TYPES.TAILLIFT:
            //return config.getTranslationText('1444');
            return {
                //titleText: config.getTranslationText('1444'),
                //footerText: config.getTranslationText('1511'),
                //footerTooltipText: config.getTranslationText('1527')
                titleText: config.getTranslationText('1444'),
                footerText: config.getTranslationText('1511'),
                footerTooltipText: config.getTranslationText('1527')
            };
        case config.ACCESSORY_TYPES.FAIRING:
            //return config.getTranslationText('722');
            return {
                //titleText: config.getTranslationText('722')
                titleText: config.getTranslationText('722')
            };
        case config.ACCESSORY_TYPES.HEADBOARD:
            //return config.getTranslationText('723');
            return {
                //titleText: config.getTranslationText('723')
                titleText: config.getTranslationText('723')
            };
        case config.ACCESSORY_TYPES.CREW_CAB:
            //return config.getTranslationText('724');
            return {
                //titleText: config.getTranslationText('724')
                titleText: config.getTranslationText('724')
            };
        case config.ACCESSORY_TYPES.BULLBAR:
            //return config.getTranslationText('725');
            return {
                //titleText: config.getTranslationText('725')
                titleText: config.getTranslationText('725')
            };
        case config.ACCESSORY_TYPES.FUEL_TANK:
            //return config.getTranslationText('726');
            return {
                //titleText: config.getTranslationText('726')
                titleText: config.getTranslationText('726')
            };
        case config.ACCESSORY_TYPES.ROLLOVER:
            //return config.getTranslationText('727');
            return {
                titleText: config.getTranslationText('727')
            };
        case config.ACCESSORY_TYPES.OTHER:
            //return config.getTranslationText('728');
            switch (accessorySubType) {
                case config.ACCESSORY_TYPES.BULLBAR:
                case config.ACCESSORY_TYPES.BULLBAR.toUpperCase():
                    //return config.getTranslationText('1448');
                    return {
                        //titleText: config.getTranslationText('1448'),
                        //footerText: config.getTranslationText('1514'),
                        //footerTooltipText: config.getTranslationText('1530')
                        titleText: config.getTranslationText('1448'),
                        footerText: config.getTranslationText('1514'),
                        footerTooltipText: config.getTranslationText('1530')
                    };
                case config.ACCESSORY_TYPES.FAIRING:
                case config.ACCESSORY_TYPES.FAIRING.toUpperCase():
                    //return config.getTranslationText('1449');
                    return {
                        //titleText: config.getTranslationText('1449'),
                        //footerText: config.getTranslationText('1518'),
                        //footerTooltipText: config.getTranslationText('1534')
                        titleText: config.getTranslationText('1449'),
                        footerText: config.getTranslationText('1518'),
                        footerTooltipText: config.getTranslationText('1534')
                    };
                case config.ACCESSORY_TYPES.REAR_BUMPER:
                case config.ACCESSORY_TYPES.REAR_BUMPER.toUpperCase():
                    //return config.getTranslationText('1450');
                    return {
                        //titleText: config.getTranslationText('1450'),
                        //footerText: config.getTranslationText('1521'),
                        //footerTooltipText: config.getTranslationText('1537')
                        titleText: config.getTranslationText('1450'),
                        footerText: config.getTranslationText('1521'),
                        footerTooltipText: config.getTranslationText('1537')
                    };
                case config.ACCESSORY_TYPES.TOOLBOX:
                case config.ACCESSORY_TYPES.TOOLBOX.toUpperCase():
                    //return config.getTranslationText('1451');
                    return {
                        //titleText: config.getTranslationText('1451'),
                        //footerText: config.getTranslationText('1519'),
                        //footerTooltipText: config.getTranslationText('1535')
                        titleText: config.getTranslationText('1451'),
                        footerText: config.getTranslationText('1519'),
                        footerTooltipText: config.getTranslationText('1535')
                    };
                case config.ACCESSORY_TYPES.AEROKIT:
                case config.ACCESSORY_TYPES.AEROKIT.toUpperCase():
                    //return config.getTranslationText('1452');
                    return {
                        //titleText: config.getTranslationText('1452'),
                        //footerText: config.getTranslationText('1515'),
                        //footerTooltipText: config.getTranslationText('1531')
                        titleText: config.getTranslationText('1452'),
                        footerText: config.getTranslationText('1515'),
                        footerTooltipText: config.getTranslationText('1531')
                    };
                case config.ACCESSORY_TYPES.SNOWPLOW:
                case config.ACCESSORY_TYPES.SNOWPLOW.toUpperCase():
                    //return config.getTranslationText('1453');
                    return {
                        //titleText: config.getTranslationText('1453'),
                        //footerText: config.getTranslationText('1516'),
                        //footerTooltipText: config.getTranslationText('1532')
                        titleText: config.getTranslationText('1453'),
                        footerText: config.getTranslationText('1516'),
                        footerTooltipText: config.getTranslationText('1532')
                    };
                case config.ACCESSORY_TYPES.AERIAL_PLATFORM:
                case config.ACCESSORY_TYPES.AERIAL_PLATFORM.toUpperCase():
                    //return config.getTranslationText('1454');
                    return {
                        //titleText: config.getTranslationText('1454'),
                        //footerText: config.getTranslationText('1517'),
                        //footerTooltipText: config.getTranslationText('1533')
                        titleText: config.getTranslationText('1454'),
                        footerText: config.getTranslationText('1517'),
                        footerTooltipText: config.getTranslationText('1533')
                    };
                case config.ACCESSORY_TYPES.OUTRIGGER:
                case config.ACCESSORY_TYPES.OUTRIGGER.toUpperCase():
                    //return config.getTranslationText('1455');
                    return {
                        //titleText: config.getTranslationText('1455'),
                        //footerText: config.getTranslationText('1520'),
                        //footerTooltipText: config.getTranslationText('1536')
                        titleText: config.getTranslationText('1455'),
                        footerText: config.getTranslationText('1520'),
                        footerTooltipText: config.getTranslationText('1536')
                    };
                case config.ACCESSORY_TYPES.OTHER:
                case config.ACCESSORY_TYPES.OTHER.toUpperCase():
                    //return config.getTranslationText('1442');
                    return {
                        //titleText: config.getTranslationText('1442'),
                        //footerText: config.getTranslationText('1522'),
                        //footerTooltipText: config.getTranslationText('1538')
                        titleText: config.getTranslationText('1442'),
                        footerText: config.getTranslationText('1522'),
                        footerTooltipText: config.getTranslationText('1538')
                    };
                default:
                    //return config.getTranslationText('1442');
                    return {
                        //titleText: config.getTranslationText('1442'),
                        //footerText: config.getTranslationText('1522'),
                        //footerTooltipText: config.getTranslationText('1538')
                        titleText: config.getTranslationText('1442'),
                        footerText: config.getTranslationText('1522'),
                        footerTooltipText: config.getTranslationText('1538')
                    };
            }
        case config.ACCESSORY_TYPES.FIFTH_WHEEL:
            //return config.getTranslationText('1445');
            return {
                //titleText: config.getTranslationText('1445'),
                //footerText: config.getTranslationText('1512'),
                //footerTooltipText: config.getTranslationText('1528')
                titleText: config.getTranslationText('1445'),
                footerText: config.getTranslationText('1512'),
                footerTooltipText: config.getTranslationText('1528')
            };
        case config.ACCESSORY_TYPES.PAYLOAD:
            //return config.getTranslationText('1447');
            return {
                //titleText: config.getTranslationText('1447'),
                //footerText: config.getTranslationText('1523'),
                //footerTooltipText: config.getTranslationText('1539')
                titleText: config.getTranslationText('1447'),
                footerText: config.getTranslationText('1523'),
                footerTooltipText: config.getTranslationText('1539')
            };
        case config.ACCESSORY_TYPES.HITCH:
            //return config.getTranslationText('1446');
            return {
                //titleText: config.getTranslationText('1446'),
                //footerText: config.getTranslationText('1513'),
                //footerTooltipText: config.getTranslationText('1529')
                titleText: config.getTranslationText('1446'),
                footerText: config.getTranslationText('1513'),
                footerTooltipText: config.getTranslationText('1529')
            };
        case config.ACCESSORY_TYPES.HOOKLIFT:
            return {
                titleText: config.getTranslationText('4368'),
                footerText: config.getTranslationText('4369'),
                footerTooltipText: config.getTranslationText('4370')
            };
    }
}

function getAccessoryTypeRemoveTooltip(accessoryType, accessorySubType) {
    switch (accessoryType) {
        case config.ACCESSORY_TYPES.CRANE:
            return config.getTranslationText('974');
        case config.ACCESSORY_TYPES.FRIDGE:
            return config.getTranslationText('975');
        case config.ACCESSORY_TYPES.TAILLIFT:
            return config.getTranslationText('976');
        case config.ACCESSORY_TYPES.FAIRING:
            return config.getTranslationText('977');
        case config.ACCESSORY_TYPES.HEADBOARD:
            return config.getTranslationText('978');
        case config.ACCESSORY_TYPES.CREW_CAB:
            return config.getTranslationText('979');
        case config.ACCESSORY_TYPES.BULLBAR:
            return config.getTranslationText('980');
        case config.ACCESSORY_TYPES.FUEL_TANK:
            return config.getTranslationText('981');
        case config.ACCESSORY_TYPES.ROLLOVER:
            return config.getTranslationText('982');
        case config.ACCESSORY_TYPES.OTHER:
            switch (accessorySubType) {
                case config.ACCESSORY_TYPES.BULLBAR:
                case config.ACCESSORY_TYPES.BULLBAR.toUpperCase():
                    return config.getTranslationText('980');
                case config.ACCESSORY_TYPES.FAIRING:
                case config.ACCESSORY_TYPES.FAIRING.toUpperCase():
                    return config.getTranslationText('977');
                case config.ACCESSORY_TYPES.REAR_BUMPER:
                case config.ACCESSORY_TYPES.REAR_BUMPER.toUpperCase():
                    return config.getTranslationText('1464');
                case config.ACCESSORY_TYPES.TOOLBOX:
                case config.ACCESSORY_TYPES.TOOLBOX.toUpperCase():
                    return config.getTranslationText('1462');
                case config.ACCESSORY_TYPES.AEROKIT:
                case config.ACCESSORY_TYPES.AEROKIT.toUpperCase():
                    return config.getTranslationText('1461');
                case config.ACCESSORY_TYPES.SNOWPLOW:
                case config.ACCESSORY_TYPES.SNOWPLOW.toUpperCase():
                    return config.getTranslationText('1459');
                case config.ACCESSORY_TYPES.AERIAL_PLATFORM:
                case config.ACCESSORY_TYPES.AERIAL_PLATFORM.toUpperCase():
                    return config.getTranslationText('1463');
                case config.ACCESSORY_TYPES.OUTRIGGER:
                case config.ACCESSORY_TYPES.OUTRIGGER.toUpperCase():
                    return config.getTranslationText('1460');
                case config.ACCESSORY_TYPES.OTHER:
                case config.ACCESSORY_TYPES.OTHER.toUpperCase():
                    return config.getTranslationText('983');
                default:
                    return config.getTranslationText('983');
            }
        case config.ACCESSORY_TYPES.FIFTH_WHEEL:
            return config.getTranslationText('984');
        case config.ACCESSORY_TYPES.PAYLOAD:
            return config.getTranslationText('971');
        case config.ACCESSORY_TYPES.HITCH:
            return config.getTranslationText('985');
        case config.ACCESSORY_TYPES.HOOKLIFT:
            return config.getTranslationText('4371');
    }
}

function getAccessoryTypeSaveTooltip(accessoryType, accessorySubType) {
    switch (accessoryType) {
        case config.ACCESSORY_TYPES.CRANE:
            //return config.getTranslationText('986');
            return config.getTranslationText('986');
        case config.ACCESSORY_TYPES.FRIDGE:
            //return config.getTranslationText('987');
            return config.getTranslationText('987');
        case config.ACCESSORY_TYPES.TAILLIFT:
            //return config.getTranslationText('988');
            return config.getTranslationText('988');
        case config.ACCESSORY_TYPES.FAIRING:
            //return config.getTranslationText('989');
            return config.getTranslationText('989');
        case config.ACCESSORY_TYPES.HEADBOARD:
            //return config.getTranslationText('990');
            return config.getTranslationText('990');
        case config.ACCESSORY_TYPES.CREW_CAB:
            //return config.getTranslationText('991');
            return config.getTranslationText('991');
        case config.ACCESSORY_TYPES.BULLBAR:
            //return config.getTranslationText('992');
            return config.getTranslationText('992');
        case config.ACCESSORY_TYPES.FUEL_TANK:
            //return config.getTranslationText('993');
            return config.getTranslationText('993');
        case config.ACCESSORY_TYPES.ROLLOVER:
            //return config.getTranslationText('994');
            return config.getTranslationText('994');
        case config.ACCESSORY_TYPES.OTHER:
            //return config.getTranslationText('995');
            switch (accessorySubType) {
                case config.ACCESSORY_TYPES.BULLBAR:
                case config.ACCESSORY_TYPES.BULLBAR.toUpperCase():
                    //return config.getTranslationText('992');
                    return config.getTranslationText('992');
                case config.ACCESSORY_TYPES.FAIRING:
                case config.ACCESSORY_TYPES.FAIRING.toUpperCase():
                    //return config.getTranslationText('989');
                    return config.getTranslationText('989');
                case config.ACCESSORY_TYPES.REAR_BUMPER:
                case config.ACCESSORY_TYPES.REAR_BUMPER.toUpperCase():
                    //return config.getTranslationText('1470');
                    return config.getTranslationText('1470');
                case config.ACCESSORY_TYPES.TOOLBOX:
                case config.ACCESSORY_TYPES.TOOLBOX.toUpperCase():
                    //return config.getTranslationText('1471');
                    return config.getTranslationText('1471');
                case config.ACCESSORY_TYPES.AEROKIT:
                case config.ACCESSORY_TYPES.AEROKIT.toUpperCase():
                    //return config.getTranslationText('1472');
                    return config.getTranslationText('1472');
                case config.ACCESSORY_TYPES.SNOWPLOW:
                case config.ACCESSORY_TYPES.SNOWPLOW.toUpperCase():
                    //return config.getTranslationText('1473');
                    return config.getTranslationText('1473');
                case config.ACCESSORY_TYPES.AERIAL_PLATFORM:
                case config.ACCESSORY_TYPES.AERIAL_PLATFORM.toUpperCase():
                    //return config.getTranslationText('1474');
                    return config.getTranslationText('1474');
                case config.ACCESSORY_TYPES.OUTRIGGER:
                case config.ACCESSORY_TYPES.OUTRIGGER.toUpperCase():
                    //return config.getTranslationText('1475');
                    return config.getTranslationText('1475');
                case config.ACCESSORY_TYPES.OTHER:
                case config.ACCESSORY_TYPES.OTHER.toUpperCase():
                    //return config.getTranslationText('995');
                    return config.getTranslationText('995');
                default:
                    //return config.getTranslationText('995');
                    return config.getTranslationText('995');
            }
        case config.ACCESSORY_TYPES.FIFTH_WHEEL:
            //return config.getTranslationText('996');
            return config.getTranslationText('996');
        case config.ACCESSORY_TYPES.PAYLOAD:
            //return config.getTranslationText('973');
            return config.getTranslationText('973');
        case config.ACCESSORY_TYPES.HITCH:
            //return config.getTranslationText('997');
            return config.getTranslationText('997');
        case config.ACCESSORY_TYPES.HOOKLIFT:
            return config.getTranslationText('4372');
    }
}

function getBodyTypeAddTitle(bodyType) {
    switch (bodyType) {
        case config.BODY_TYPES.COMPACTOR:
            return config.getTranslationText('834');
        case config.BODY_TYPES.DROPSIDE:
            return config.getTranslationText('835');
        case config.BODY_TYPES.FLATDECK:
            return config.getTranslationText('836');
        case config.BODY_TYPES.TAUTLINER:
            return config.getTranslationText('837');
        case config.BODY_TYPES.TANKER:
            return config.getTranslationText('838');
        case config.BODY_TYPES.TIPPER:
            return config.getTranslationText('839');
        case config.BODY_TYPES.VAN:
            return config.getTranslationText('840');
        case config.BODY_TYPES.ROLLBACK:
            return config.getTranslationText('841');
        case config.BODY_TYPES.TIMBER:
            return config.getTranslationText('842');
        case config.BODY_TYPES.LIVESTOCKCARRIER:
        case config.BODY_TYPES.SHEEPCARRIER:
        case config.BODY_TYPES.PIGCARRIER:
        case config.BODY_TYPES.CATTLECARRIER:
        case config.BODY_TYPES.LIVESTOCK:
            return config.getTranslationText('843');
        case config.BODY_TYPES.BEAVERTAIL:
            return config.getTranslationText('844');
        case config.BODY_TYPES.HOOKLIFT:
            return config.getTranslationText('845');
        case config.BODY_TYPES.OTHER:
            return config.getTranslationText('2687');
        case config.BODY_TYPES.SERVICE:
            return config.getTranslationText('1212');
    }
}

function getTrailerTypeAddTitle(trailerType, path) {
    switch (trailerType) {
        case config.TRAILER_TYPES.SEMI:
            if (path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
                return config.getTranslationText('847');
            }
            return config.getTranslationText('846');
        case config.TRAILER_TYPES.INTERLINK:
            return config.getTranslationText('848');
        case config.TRAILER_TYPES.PUP:
            return config.getTranslationText('849');
        case config.TRAILER_TYPES.DRAWBAR:
            return config.getTranslationText('850');
    }
}

function getConnectivityStatusForIntercom() {
    return isOnline.value === true ? config.CONNECTIVITY_STATUS.ONLINE : config.CONNECTIVITY_STATUS.OFFLINE
}

function preventSendingOfIntercomEvent(eventName, metadataObject) {
    switch (eventName) {
        // case config.INTERCOM_EVENT.CHANGED_VIEW:
        //     if (metadataObject[config.INTERCOM_METADATA_ITEM.FROM_VIEW] === metadataObject[config.INTERCOM_METADATA_ITEM.TO_VIEW]) {
        //         return true;
        //     } else {
        //         return false;
        //     }
        case config.INTERCOM_EVENT.CHANGED_PAYLOAD_TYPE:
            if (metadataObject[config.INTERCOM_METADATA_ITEM.FROM_TYPE] === metadataObject[config.INTERCOM_METADATA_ITEM.TO_TYPE]) {
                return true;
            } else {
                return false;
            }
        default:
            return false;
    }
}

function getIntercomMetadataObject(eventName, metadataObject, doNotIncludeChangedConnectivity) {
    var returnObject = {};

    switch (eventName) {
        case config.INTERCOM_EVENT.LOGGED_IN:
            returnObject[config.INTERCOM_METADATA_ITEM.AUTHENTICATION_MODE] = metadataObject[config.INTERCOM_METADATA_ITEM.AUTHENTICATION_MODE];
            break;
        case config.INTERCOM_EVENT.REQUESTED_ITEM:
        case config.INTERCOM_EVENT.REQUESTED_EQUIPMENT:
            returnObject[config.INTERCOM_METADATA_ITEM.TYPE] = metadataObject.Type.toUpperCase();
            returnObject[config.INTERCOM_METADATA_ITEM.MAKE] = metadataObject.Make.toUpperCase();
            returnObject[config.INTERCOM_METADATA_ITEM.MODEL] = metadataObject.Model.toUpperCase();
            break;
        case config.INTERCOM_EVENT.REQUESTED_VEHICLE:
        case config.INTERCOM_EVENT.REQUESTED_PAYLOAD:
        case config.INTERCOM_EVENT.REQUESTED_BODY:
            returnObject[config.INTERCOM_METADATA_ITEM.MAKE] = metadataObject.Make.toUpperCase();
            returnObject[config.INTERCOM_METADATA_ITEM.MODEL] = metadataObject.Model.toUpperCase();
            break;
        case config.INTERCOM_EVENT.DELETED_TEAM_EQUIPMENT:
        case config.INTERCOM_EVENT.DELETED_PERSONAL_EQUIPMENT:
            returnObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = metadataObject.Description;
            returnObject[config.INTERCOM_METADATA_ITEM.EQUIPMENT_TYPE] = metadataObject[config.INTERCOM_METADATA_ITEM.EQUIPMENT_TYPE].toUpperCase();
            break;
        case config.INTERCOM_EVENT.REMOVED_BODY:
            returnObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = metadataObject.Description;
            returnObject[config.INTERCOM_METADATA_ITEM.SOURCE_TYPE] = getUpperCase(metadataObject.Source);
            returnObject[config.INTERCOM_METADATA_ITEM.CHASSIS_TYPE] = getUpperCase(metadataObject.ChassisType);
            break;
        case config.INTERCOM_EVENT.DELETED_TEAM_BODY:
        case config.INTERCOM_EVENT.DELETED_PERSONAL_BODY:
        case config.INTERCOM_EVENT.SAVED_TEAM_BODY:
        case config.INTERCOM_EVENT.SAVED_PERSONAL_BODY:
            returnObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = metadataObject.Description;
            returnObject[config.INTERCOM_METADATA_ITEM.BODY_TYPE] = metadataObject.SubType.toUpperCase();
            break;
        case config.INTERCOM_EVENT.REMOVED_EQUIPMENT:
            returnObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = metadataObject.Description;
            returnObject[config.INTERCOM_METADATA_ITEM.EQUIPMENT_TYPE] = getUpperCase(metadataObject.Type);
            returnObject[config.INTERCOM_METADATA_ITEM.EQUIPMENT_SUB_TYPE] = getUpperCase(metadataObject.SubType);
            returnObject[config.INTERCOM_METADATA_ITEM.SOURCE_TYPE] = getUpperCase(metadataObject.Source);
            returnObject[config.INTERCOM_METADATA_ITEM.CHASSIS_TYPE] = getUpperCase(metadataObject.ChassisType);
            break;
        case config.INTERCOM_EVENT.SAVED_TEAM_EQUIPMENT:
        case config.INTERCOM_EVENT.SAVED_PERSONAL_EQUIPMENT:
            returnObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = metadataObject.Description;
            returnObject[config.INTERCOM_METADATA_ITEM.EQUIPMENT_TYPE] = getUpperCase(metadataObject.Type);
            returnObject[config.INTERCOM_METADATA_ITEM.EQUIPMENT_SUB_TYPE] = getUpperCase(metadataObject.SubType);
            returnObject[config.INTERCOM_METADATA_ITEM.SUBMIT_TO_PUBLIC_LIBRARY] = metadataObject[config.INTERCOM_METADATA_ITEM.SUBMIT_TO_PUBLIC_LIBRARY];
            break;
        case config.INTERCOM_EVENT.CHANGED_CONNECTIVITY:
            returnObject[config.INTERCOM_METADATA_ITEM.FROM_STATUS] = getUpperCase(metadataObject.FromStatus);
            returnObject[config.INTERCOM_METADATA_ITEM.TO_STATUS] = getUpperCase(metadataObject.ToStatus);
            break;
        case config.INTERCOM_EVENT.CHANGED_SETTINGS:
            returnObject[config.INTERCOM_METADATA_ITEM.SETTING_NAME] = getUpperCase(metadataObject.SettingName);
            break;
        case config.INTERCOM_EVENT.ADDED_BODY:
            returnObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = metadataObject.Description;
            returnObject[config.INTERCOM_METADATA_ITEM.BODY_TYPE] = getUpperCase(metadataObject.SubType);
            returnObject[config.INTERCOM_METADATA_ITEM.SOURCE_TYPE] = getUpperCase(metadataObject.Source);
            // Add Chassis Type Vehicle/Trailer
            returnObject[config.INTERCOM_METADATA_ITEM.CHASSIS_TYPE] = metadataObject.ChassisType;
            break;
        case config.INTERCOM_EVENT.ADDED_EQUIPMENT:
            returnObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = metadataObject.Description;
            returnObject[config.INTERCOM_METADATA_ITEM.EQUIPMENT_TYPE] = getUpperCase(metadataObject.Type);
            returnObject[config.INTERCOM_METADATA_ITEM.EQUIPMENT_SUB_TYPE] = getUpperCase(metadataObject.SubType);
            returnObject[config.INTERCOM_METADATA_ITEM.SOURCE_TYPE] = getUpperCase(metadataObject.Source);
            // Add Chassis Type Vehicle/Trailer
            returnObject[config.INTERCOM_METADATA_ITEM.CHASSIS_TYPE] = metadataObject.ChassisType;
            break;
        case config.INTERCOM_EVENT.VERIFIED_EMAIL:
            returnObject[config.INTERCOM_METADATA_ITEM.VERIFIED_FROM] = getUpperCase(metadataObject.VerifiedFrom);
            break;
        case config.INTERCOM_EVENT.CHANGED_MODULE:
            returnObject[config.INTERCOM_METADATA_ITEM.FROM_MODULE] = getUpperCase(metadataObject.From);
            returnObject[config.INTERCOM_METADATA_ITEM.TO_MODULE] = getUpperCase(metadataObject.To);
            break;
        case config.INTERCOM_EVENT.OPENED_OFFER:
            returnObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = metadataObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION];
            returnObject[config.INTERCOM_METADATA_ITEM.AXLE_LAYOUT] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.AXLE_LAYOUT]);
            returnObject[config.INTERCOM_METADATA_ITEM.SOURCE_TYPE] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.SOURCE_TYPE]);
            returnObject[config.INTERCOM_METADATA_ITEM.OFFER_TYPE] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.OFFER_TYPE]);
            returnObject[config.INTERCOM_METADATA_ITEM.VEHICLE_TYPE] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.VEHICLE_TYPE]);
            returnObject[config.INTERCOM_METADATA_ITEM.ORIGIN] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.ORIGIN]);
            returnObject[config.INTERCOM_METADATA_ITEM.ORIGIN_SOURCE] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.ORIGIN_SOURCE]);
            returnObject[config.INTERCOM_METADATA_ITEM.SHARED_BY] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.SHARED_BY]);
            if(metadataObject[config.INTERCOM_METADATA_ITEM.SPEC_DATA_POINTS]) {
                returnObject[config.INTERCOM_METADATA_ITEM.SPEC_DATA_POINTS] = metadataObject[config.INTERCOM_METADATA_ITEM.SPEC_DATA_POINTS];
            }
            break;
        case config.INTERCOM_EVENT.ERROR_RAISED:
            //returnObject[config.INTERCOM_METADATA_ITEM.ORIGIN] = metadataObject.Origin;
            //returnObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = metadataObject.Description;
            returnObject = metadataObject;
            break;
        case config.INTERCOM_EVENT.VIEWED_PRODUCT_TOUR:
            returnObject[config.INTERCOM_METADATA_ITEM.TOUR_NAME] = metadataObject.tourName;
            break;
        case config.INTERCOM_EVENT.SYNC_FAILED:
            returnObject[config.INTERCOM_METADATA_ITEM.FAILED_OPERATION] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.FAILED_OPERATION]);
            returnObject[config.INTERCOM_METADATA_ITEM.ORIGIN] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.ORIGIN]);
            returnObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION]);
            returnObject[config.INTERCOM_METADATA_ITEM.CS_TRANSACTION_MESSAGE] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.CS_TRANSACTION_MESSAGE]);
            break;
        case config.INTERCOM_EVENT.ADDED_TRAILER_1:
        case config.INTERCOM_EVENT.ADDED_TRAILER_2:
        case config.INTERCOM_EVENT.REMOVED_TRAILER_1:
        case config.INTERCOM_EVENT.REMOVED_TRAILER_2:
            returnObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = metadataObject.Description;
            returnObject[config.INTERCOM_METADATA_ITEM.TRAILER_TYPE] = metadataObject.SubType;
            returnObject[config.INTERCOM_METADATA_ITEM.SOURCE_TYPE] = metadataObject.Source;
            break;
        case config.INTERCOM_EVENT.SAVED_TEAM_TRAILER:
        case config.INTERCOM_EVENT.SAVED_PERSONAL_TRAILER:
        case config.INTERCOM_EVENT.DELETED_TEAM_TRAILER:
        case config.INTERCOM_EVENT.DELETED_PERSONAL_TRAILER:
            returnObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = metadataObject.Description;
            returnObject[config.INTERCOM_METADATA_ITEM.TRAILER_TYPE] = metadataObject.SubType;
            break;
        case config.INTERCOM_EVENT.CHANGED_VIEW:
            // returnObject[config.INTERCOM_METADATA_ITEM.VIEW_TYPE] = metadataObject[config.INTERCOM_METADATA_ITEM.VIEW_TYPE];
            // returnObject[config.INTERCOM_METADATA_ITEM.FROM_VIEW] = metadataObject[config.INTERCOM_METADATA_ITEM.FROM_VIEW];
            // returnObject[config.INTERCOM_METADATA_ITEM.TO_VIEW] = metadataObject[config.INTERCOM_METADATA_ITEM.TO_VIEW];
            returnObject[config.INTERCOM_METADATA_ITEM.FROM_DISPLAY_VIEW] = metadataObject[config.INTERCOM_METADATA_ITEM.FROM_DISPLAY_VIEW];
            returnObject[config.INTERCOM_METADATA_ITEM.FROM_ELEVATION_VIEW] = metadataObject[config.INTERCOM_METADATA_ITEM.FROM_ELEVATION_VIEW];
            returnObject[config.INTERCOM_METADATA_ITEM.FROM_DETAIL_VIEW] = metadataObject[config.INTERCOM_METADATA_ITEM.FROM_DETAIL_VIEW];
            returnObject[config.INTERCOM_METADATA_ITEM.TO_DISPLAY_VIEW] = metadataObject[config.INTERCOM_METADATA_ITEM.TO_DISPLAY_VIEW];
            returnObject[config.INTERCOM_METADATA_ITEM.TO_ELEVATION_VIEW] = metadataObject[config.INTERCOM_METADATA_ITEM.TO_ELEVATION_VIEW];
            returnObject[config.INTERCOM_METADATA_ITEM.TO_DETAIL_VIEW] = metadataObject[config.INTERCOM_METADATA_ITEM.TO_DETAIL_VIEW];
            break;
        case config.INTERCOM_EVENT.SEARCHED_FOR_EQUIPMENT:
            returnObject[config.INTERCOM_METADATA_ITEM.TYPE] = metadataObject[config.INTERCOM_METADATA_ITEM.TYPE];
            break;
        case config.INTERCOM_EVENT.SEARCHED_FOR_VEHICLE:
            returnObject[config.INTERCOM_METADATA_ITEM.SEARCH_TERM] = metadataObject[config.INTERCOM_METADATA_ITEM.SEARCH_TERM];
            returnObject[config.INTERCOM_METADATA_ITEM.SEARCH_LOCATION] = metadataObject[config.INTERCOM_METADATA_ITEM.SEARCH_LOCATION];
            break;
        case config.INTERCOM_EVENT.RENAMED_OTHER_EQUIPMENT:
            returnObject[config.INTERCOM_METADATA_ITEM.OLD_DESCRIPTION] = metadataObject[config.INTERCOM_METADATA_ITEM.OLD_DESCRIPTION];
            returnObject[config.INTERCOM_METADATA_ITEM.NEW_DESCRIPTION] = metadataObject[config.INTERCOM_METADATA_ITEM.NEW_DESCRIPTION];
            break;
        case config.INTERCOM_EVENT.SELECTED_PLAN:
            returnObject[config.INTERCOM_METADATA_ITEM.PLAN_TYPE] = metadataObject[config.INTERCOM_METADATA_ITEM.PLAN_TYPE];
            returnObject[config.INTERCOM_METADATA_ITEM.EDITION_NAME] = metadataObject[config.INTERCOM_METADATA_ITEM.EDITION_NAME];
            break;
        case config.INTERCOM_EVENT.CONSIDERED_EQUIPMENT_REQUEST:
            returnObject[config.INTERCOM_METADATA_ITEM.SOURCE] = metadataObject[config.INTERCOM_METADATA_ITEM.SOURCE];
            returnObject[config.INTERCOM_METADATA_ITEM.TYPE] = metadataObject[config.INTERCOM_METADATA_ITEM.TYPE];
            break;
        case config.INTERCOM_EVENT.CONSIDERED_VEHICLE_REQUEST:
        case config.INTERCOM_EVENT.CONSIDERED_BODY_REQUEST:
        case config.INTERCOM_EVENT.CONSIDERED_PAYLOAD_REQUEST:
        case config.INTERCOM_EVENT.CONSIDERED_TRAILER_REQUEST:
        case config.INTERCOM_EVENT.CONSIDERED_NTEA_INTEGRATION:
        case config.INTERCOM_EVENT.UPGRADE_CONSIDERED:
        case config.INTERCOM_EVENT.UPGRADE_REQUESTED:
        case config.INTERCOM_EVENT.OPENED_REQUEST_UPGRADE_DIALOG:
            returnObject[config.INTERCOM_METADATA_ITEM.SOURCE] = metadataObject[config.INTERCOM_METADATA_ITEM.SOURCE];
            break;
        case config.INTERCOM_EVENT.SIMULATED_VEHICLE:
            returnObject[config.INTERCOM_METADATA_ITEM.VEHICLE_UNLADEN] = metadataObject[config.INTERCOM_METADATA_ITEM.VEHICLE_UNLADEN];
            returnObject[config.INTERCOM_METADATA_ITEM.TRAILER_UNLADEN] = metadataObject[config.INTERCOM_METADATA_ITEM.TRAILER_UNLADEN];
            returnObject[config.INTERCOM_METADATA_ITEM.TRAILER2_UNLADEN] = metadataObject[config.INTERCOM_METADATA_ITEM.TRAILER2_UNLADEN];
            returnObject[config.INTERCOM_METADATA_ITEM.FRONTAL_AREA] = metadataObject[config.INTERCOM_METADATA_ITEM.FRONTAL_AREA];
            returnObject[config.INTERCOM_METADATA_ITEM.COEFFICIENT_OF_DRAG] = metadataObject[config.INTERCOM_METADATA_ITEM.COEFFICIENT_OF_DRAG];
            returnObject[config.INTERCOM_METADATA_ITEM.DRIVE_AXLE_RATIO] = metadataObject[config.INTERCOM_METADATA_ITEM.DRIVE_AXLE_RATIO];
            returnObject[config.INTERCOM_METADATA_ITEM.MAXIMUM_SPEED] = metadataObject[config.INTERCOM_METADATA_ITEM.MAXIMUM_SPEED];
            returnObject[config.INTERCOM_METADATA_ITEM.NO_OF_WAYPOINTS] = metadataObject[config.INTERCOM_METADATA_ITEM.NO_OF_WAYPOINTS];
            break;
        case config.INTERCOM_EVENT.BUY_PLAN_FAILED:
            returnObject[config.INTERCOM_METADATA_ITEM.CHARGEBEE_ERROR_MESSAGE] = metadataObject[config.INTERCOM_METADATA_ITEM.CHARGEBEE_ERROR_MESSAGE];
            returnObject[config.INTERCOM_METADATA_ITEM.CHARGEBEE_ERROR_TYPE] = metadataObject[config.INTERCOM_METADATA_ITEM.CHARGEBEE_ERROR_TYPE];
            break;
        case config.INTERCOM_EVENT.CHANGED_PAYLOAD_TYPE:
            returnObject[config.INTERCOM_METADATA_ITEM.FROM_TYPE] = metadataObject[config.INTERCOM_METADATA_ITEM.FROM_TYPE];
            returnObject[config.INTERCOM_METADATA_ITEM.TO_TYPE] = metadataObject[config.INTERCOM_METADATA_ITEM.TO_TYPE];
            returnObject[config.INTERCOM_METADATA_ITEM.CHASSIS_TYPE] = metadataObject[config.INTERCOM_METADATA_ITEM.CHASSIS_TYPE];
            break;
        case config.INTERCOM_EVENT.ADDED_PAYLOAD_ITEM:
            returnObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = metadataObject.Description;
            returnObject[config.INTERCOM_METADATA_ITEM.SOURCE_TYPE] = metadataObject.Source;
            returnObject[config.INTERCOM_METADATA_ITEM.CHASSIS_TYPE] = getUpperCase(metadataObject.ChassisType);
            break;
        case config.INTERCOM_EVENT.REMOVED_PAYLOAD_ITEM:
            returnObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = metadataObject.Description;
            returnObject[config.INTERCOM_METADATA_ITEM.SOURCE_TYPE] = metadataObject.Source;
            returnObject[config.INTERCOM_METADATA_ITEM.CHASSIS_TYPE] = getUpperCase(metadataObject.ChassisType);
            break;
        case config.INTERCOM_EVENT.RENAMED_PAYLOAD_ITEM:
            returnObject[config.INTERCOM_METADATA_ITEM.OLD_DESCRIPTION] = metadataObject[config.INTERCOM_METADATA_ITEM.OLD_DESCRIPTION];
            returnObject[config.INTERCOM_METADATA_ITEM.NEW_DESCRIPTION] = metadataObject[config.INTERCOM_METADATA_ITEM.NEW_DESCRIPTION];
            returnObject[config.INTERCOM_METADATA_ITEM.CHASSIS_TYPE] = metadataObject[config.INTERCOM_METADATA_ITEM.CHASSIS_TYPE];
            break;
        case config.INTERCOM_EVENT.SAVED_TEAM_PAYLOAD_ITEM:
        case config.INTERCOM_EVENT.SAVED_PERSONAL_PAYLOAD_ITEM:
        case config.INTERCOM_EVENT.DELETED_TEAM_PAYLOAD_ITEM:
        case config.INTERCOM_EVENT.DELETED_PERSONAL_PAYLOAD_ITEM:
            returnObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = metadataObject.Description;
            returnObject[config.INTERCOM_METADATA_ITEM.SOURCE_TYPE] = metadataObject.Source;
            returnObject[config.INTERCOM_METADATA_ITEM.TYPE] = metadataObject.Type;
            returnObject[config.INTERCOM_METADATA_ITEM.CHASSIS_TYPE] = getUpperCase(metadataObject.ChassisType);
            returnObject[config.INTERCOM_METADATA_ITEM.SUBMIT_TO_PUBLIC_LIBRARY] = metadataObject[config.INTERCOM_METADATA_ITEM.SUBMIT_TO_PUBLIC_LIBRARY];
            break;
        case config.INTERCOM_EVENT.REFERRAL_INITIATED:
            returnObject[config.INTERCOM_METADATA_ITEM.PROMPT_METHOD] = metadataObject[config.INTERCOM_METADATA_ITEM.PROMPT_METHOD];
            break;
        case config.INTERCOM_EVENT.ATTEMPTED_AXLE_ADDITION:
            returnObject[config.INTERCOM_METADATA_ITEM.CHASSIS_TYPE] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.CHASSIS_TYPE]);
            break;
        case config.INTERCOM_EVENT.ADDED_AXLE:
        case config.INTERCOM_EVENT.REMOVED_AXLE:
        case config.INTERCOM_EVENT.RAISED_AXLE:
        case config.INTERCOM_EVENT.DROPPED_AXLE:
            returnObject[config.INTERCOM_METADATA_ITEM.AXLE_TYPE] = getUpperCase(metadataObject.AxleType);
            returnObject[config.INTERCOM_METADATA_ITEM.CHASSIS_TYPE] = getUpperCase(metadataObject.ChassisType);
            break;
        case config.INTERCOM_EVENT.REFRESHED_DATA:
            returnObject[config.INTERCOM_METADATA_ITEM.DATA_REFRESH_SCENARIO] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.DATA_REFRESH_SCENARIO]);
            if (metadataObject[config.INTERCOM_METADATA_ITEM.OLD_APP_VERSION] !== undefined) {
                returnObject[config.INTERCOM_METADATA_ITEM.OLD_APP_VERSION] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.OLD_APP_VERSION]);
                returnObject[config.INTERCOM_METADATA_ITEM.NEW_APP_VERSION] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.NEW_APP_VERSION]);
            }
            break;
        case config.INTERCOM_EVENT.APP_UPDATE_DETECTED:
            returnObject[config.INTERCOM_METADATA_ITEM.OLD_APP_VERSION] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.OLD_APP_VERSION]);
            returnObject[config.INTERCOM_METADATA_ITEM.NEW_APP_VERSION] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.NEW_APP_VERSION]);
            break;
        case config.INTERCOM_EVENT.VEHICLE_SWAP_SUCCEEDED:
        case config.INTERCOM_EVENT.VEHICLE_SWAP_FAILED:
        case config.INTERCOM_EVENT.VEHICLE_SWAP_CANCELLED:
            returnObject[config.INTERCOM_METADATA_ITEM.FROM_VEHICLE] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.FROM_VEHICLE]);
            returnObject[config.INTERCOM_METADATA_ITEM.TO_VEHICLE] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.TO_VEHICLE]);
            break;
        case config.INTERCOM_EVENT.PENDING_VEHICLE_UPDATED:
        case config.INTERCOM_EVENT.PENDING_VEHICLE_SUBMITTED:
        case config.INTERCOM_EVENT.DOWNLOAD_VEHICLE_CSV:
            returnObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = metadataObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION];
            break;
        case config.INTERCOM_EVENT.SHARED_OFFER:
            returnObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = metadataObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION];
            returnObject[config.INTERCOM_METADATA_ITEM.ACCESS_PERMISSION] = metadataObject[config.INTERCOM_METADATA_ITEM.ACCESS_PERMISSION];
            break;
        case config.INTERCOM_EVENT.UNSHARED_OFFER:
            returnObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = metadataObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION];
            returnObject[config.INTERCOM_METADATA_ITEM.INITIATED_BY] = metadataObject[config.INTERCOM_METADATA_ITEM.INITIATED_BY];
            break;
        case config.INTERCOM_EVENT.SHARED_OFFER_ACCEPTED:
            returnObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = metadataObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION];
            break;
        case config.INTERCOM_EVENT.ATTEMPTED_TRAILER_2_ADDITION:
            returnObject[config.INTERCOM_METADATA_ITEM.TRAILER_1_TYPE] = metadataObject[config.INTERCOM_METADATA_ITEM.TRAILER_1_TYPE];
            break;
        case config.INTERCOM_EVENT.CONSIDERED_DXF_IMPORT:
        case config.INTERCOM_EVENT.IMPORTED_DXF:
            returnObject[config.INTERCOM_METADATA_ITEM.TYPE] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.TYPE]);
            returnObject[config.INTERCOM_METADATA_ITEM.WIZARD_OPERATION_TYPE] = metadataObject[config.INTERCOM_METADATA_ITEM.WIZARD_OPERATION_TYPE];
            break;
        case config.INTERCOM_EVENT.CREATED_FOLDER:
            returnObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.DESCRIPTION]);
            break;
        case config.INTERCOM_EVENT.DELETED_ITEMS:
        case config.INTERCOM_EVENT.MOVED_ITEMS:
            returnObject[config.INTERCOM_METADATA_ITEM.TYPE] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.TYPE]);
            break;
        case config.INTERCOM_EVENT.RENAMED_ITEM:
            returnObject[config.INTERCOM_METADATA_ITEM.OLD_DESCRIPTION] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.OLD_DESCRIPTION]);
            returnObject[config.INTERCOM_METADATA_ITEM.NEW_DESCRIPTION] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.NEW_DESCRIPTION]);
            returnObject[config.INTERCOM_METADATA_ITEM.TYPE] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.TYPE]);
            break;
        case config.INTERCOM_EVENT.OPENED_RESOURCE:
            returnObject[config.INTERCOM_METADATA_ITEM.TITLE] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.TITLE]);
            break;
        case config.INTERCOM_EVENT.FAILED_TO_IMPORT_DXF:
            returnObject[config.INTERCOM_METADATA_ITEM.TYPE] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.TYPE]);
            returnObject[config.INTERCOM_METADATA_ITEM.WIZARD_OPERATION_TYPE] = metadataObject[config.INTERCOM_METADATA_ITEM.WIZARD_OPERATION_TYPE];
            returnObject[config.INTERCOM_METADATA_ITEM.MESSAGE_CODE] = metadataObject[config.INTERCOM_METADATA_ITEM.MESSAGE_CODE];
            returnObject[config.INTERCOM_METADATA_ITEM.INTERCOM_MESSAGE] = metadataObject[config.INTERCOM_METADATA_ITEM.INTERCOM_MESSAGE];
            break;
        case config.INTERCOM_EVENT.DATA_REFRESH_FAILED:
            returnObject[config.INTERCOM_METADATA_ITEM.DATA_REFRESH_SCENARIO] = getUpperCase(metadataObject[config.INTERCOM_METADATA_ITEM.DATA_REFRESH_SCENARIO]);
            break;
        case config.INTERCOM_EVENT.ACCESS_TOKEN_VALIDATED:
        case config.INTERCOM_EVENT.NO_SPEC_DATA_POINTS_PASSED:
        case config.INTERCOM_EVENT.BROWSER_CONSOLE_OUTPUT:
            returnObject = metadataObject;
            break;
            
    }
    if (eventName !== config.INTERCOM_EVENT.CHANGED_CONNECTIVITY && doNotIncludeChangedConnectivity !== true) {
        returnObject[config.INTERCOM_METADATA_ITEM.CONNECTIVITY_STATUS] = getConnectivityStatusForIntercom();
    }

    function getUpperCase(value) {
        return typeof value === 'string' ? value.toUpperCase() : undefined;
    }

    return returnObject;
}

function setSendEventInfoToIntercomCallback(value) {
    sendEventInfoToIntercomCallback = value;
}

function getSendEventInfoToIntercomCallback() {
    return sendEventInfoToIntercomCallback
}

function getIntercomObject(eventName, metadataObject, doNotIncludeChangedConnectivity) {
    var returnObject = {};
    returnObject.eventName = eventName;

    switch (eventName) {
        case config.INTERCOM_EVENT.FORCE_RELOGIN:
            break;
        case config.INTERCOM_EVENT.LOGGED_IN:
        case config.INTERCOM_EVENT.OPENED_OFFER:
        case config.INTERCOM_EVENT.REQUESTED_ITEM:
        case config.INTERCOM_EVENT.REQUESTED_EQUIPMENT:
        case config.INTERCOM_EVENT.REQUESTED_BODY:
        case config.INTERCOM_EVENT.REQUESTED_PAYLOAD:
        case config.INTERCOM_EVENT.REQUESTED_TRAILER:
        case config.INTERCOM_EVENT.REQUESTED_VEHICLE:
        case config.INTERCOM_EVENT.DELETED_TEAM_BODY:
        case config.INTERCOM_EVENT.DELETED_PERSONAL_BODY:
        case config.INTERCOM_EVENT.DELETED_TEAM_EQUIPMENT:
        case config.INTERCOM_EVENT.DELETED_PERSONAL_EQUIPMENT:
        case config.INTERCOM_EVENT.REMOVED_BODY:
        case config.INTERCOM_EVENT.REMOVED_EQUIPMENT:
        case config.INTERCOM_EVENT.CHANGED_CONNECTIVITY:
        case config.INTERCOM_EVENT.CHANGED_SETTINGS:
        case config.INTERCOM_EVENT.ADDED_BODY:
        case config.INTERCOM_EVENT.SAVED_TEAM_BODY:
        case config.INTERCOM_EVENT.SAVED_PERSONAL_BODY:
        case config.INTERCOM_EVENT.ADDED_EQUIPMENT:
        case config.INTERCOM_EVENT.SAVED_TEAM_EQUIPMENT:
        case config.INTERCOM_EVENT.SAVED_PERSONAL_EQUIPMENT:
        case config.INTERCOM_EVENT.VERIFIED_EMAIL:
        case config.INTERCOM_EVENT.CHANGED_MODULE:
        case config.INTERCOM_EVENT.ERROR_RAISED:
        case config.INTERCOM_EVENT.VIEWED_PRODUCT_TOUR:
        case config.INTERCOM_EVENT.SYNC_FAILED:
        case config.INTERCOM_EVENT.ADDED_TRAILER_1:
        case config.INTERCOM_EVENT.ADDED_TRAILER_2:
        case config.INTERCOM_EVENT.CHANGED_VIEW:
        case config.INTERCOM_EVENT.SELECTED_PLAN:
        case config.INTERCOM_EVENT.RENAMED_OTHER_EQUIPMENT:
        case config.INTERCOM_EVENT.REMOVED_TRAILER_1:
        case config.INTERCOM_EVENT.REMOVED_TRAILER_2:
        case config.INTERCOM_EVENT.SAVED_TEAM_TRAILER:
        case config.INTERCOM_EVENT.SAVED_PERSONAL_TRAILER:
        case config.INTERCOM_EVENT.DELETED_TEAM_TRAILER:
        case config.INTERCOM_EVENT.DELETED_PERSONAL_TRAILER:
        case config.INTERCOM_EVENT.SEARCHED_FOR_EQUIPMENT:
        case config.INTERCOM_EVENT.SEARCHED_FOR_VEHICLE:
        case config.INTERCOM_EVENT.CONSIDERED_EQUIPMENT_REQUEST:
        case config.INTERCOM_EVENT.CONSIDERED_VEHICLE_REQUEST:
        case config.INTERCOM_EVENT.CONSIDERED_BODY_REQUEST:
        case config.INTERCOM_EVENT.CONSIDERED_PAYLOAD_REQUEST:
        case config.INTERCOM_EVENT.CONSIDERED_TRAILER_REQUEST:
        case config.INTERCOM_EVENT.SIMULATED_VEHICLE:
        case config.INTERCOM_EVENT.OVERWROTE_LICENCE_FEES:
        case config.INTERCOM_EVENT.OVERWROTE_FUEL_CONSUMPTION:
        case config.INTERCOM_EVENT.OVERWROTE_TYRE_RATE:
        case config.INTERCOM_EVENT.BUY_PLAN_FAILED:
        case config.INTERCOM_EVENT.CHANGED_PAYLOAD_TYPE:
        case config.INTERCOM_EVENT.ADDED_PAYLOAD_ITEM:
        case config.INTERCOM_EVENT.REMOVED_PAYLOAD_ITEM:
        case config.INTERCOM_EVENT.RENAMED_PAYLOAD_ITEM:
        case config.INTERCOM_EVENT.SAVED_TEAM_PAYLOAD_ITEM:
        case config.INTERCOM_EVENT.SAVED_PERSONAL_PAYLOAD_ITEM:
        case config.INTERCOM_EVENT.DELETED_TEAM_PAYLOAD_ITEM:
        case config.INTERCOM_EVENT.DELETED_PERSONAL_PAYLOAD_ITEM:
        case config.INTERCOM_EVENT.REFERRAL_INITIATED:
        case config.INTERCOM_EVENT.ATTEMPTED_AXLE_ADDITION:
        case config.INTERCOM_EVENT.ADDED_AXLE:
        case config.INTERCOM_EVENT.REMOVED_AXLE:
        case config.INTERCOM_EVENT.RAISED_AXLE:
        case config.INTERCOM_EVENT.DROPPED_AXLE:
        case config.INTERCOM_EVENT.REFRESHED_DATA:
        case config.INTERCOM_EVENT.APP_UPDATE_DETECTED:
        case config.INTERCOM_EVENT.CLOSED_OFFER:
        case config.INTERCOM_EVENT.VEHICLE_SWAP_SUCCEEDED:
        case config.INTERCOM_EVENT.VEHICLE_SWAP_FAILED:
        case config.INTERCOM_EVENT.VEHICLE_SWAP_CANCELLED:
        case config.INTERCOM_EVENT.PENDING_VEHICLE_UPDATED:
        case config.INTERCOM_EVENT.PENDING_VEHICLE_SUBMITTED:
        case config.INTERCOM_EVENT.DOWNLOAD_VEHICLE_CSV:
        case config.INTERCOM_EVENT.CSV_UPLOADED:
        case config.INTERCOM_EVENT.SHARED_OFFER:
        case config.INTERCOM_EVENT.UNSHARED_OFFER:
        case config.INTERCOM_EVENT.SHARED_OFFER_ACCEPTED:
        case config.INTERCOM_EVENT.CONSIDERED_NTEA_INTEGRATION:
        case config.INTERCOM_EVENT.ATTEMPTED_TRAILER_2_ADDITION:
        case config.INTERCOM_EVENT.CONSIDERED_DXF_IMPORT:
        case config.INTERCOM_EVENT.IMPORTED_DXF:
        case config.INTERCOM_EVENT.CREATED_FOLDER:
        case config.INTERCOM_EVENT.DELETED_ITEMS:
        case config.INTERCOM_EVENT.MOVED_ITEMS:
        case config.INTERCOM_EVENT.RENAMED_ITEM:
        case config.INTERCOM_EVENT.OPENED_RESOURCE:
        case config.INTERCOM_EVENT.FAILED_TO_IMPORT_DXF:
        case config.INTERCOM_EVENT.UPGRADE_CONSIDERED:
        case config.INTERCOM_EVENT.UPGRADE_REQUESTED:
        case config.INTERCOM_EVENT.OPENED_REQUEST_UPGRADE_DIALOG:
        case config.INTERCOM_EVENT.DATA_REFRESH_FAILED:
        case config.INTERCOM_EVENT.ACCESS_TOKEN_VALIDATED:
        case config.INTERCOM_EVENT.NO_SPEC_DATA_POINTS_PASSED:
        case config.INTERCOM_EVENT.BROWSER_CONSOLE_OUTPUT:
            
            returnObject.metadata = getIntercomMetadataObject(eventName, metadataObject, doNotIncludeChangedConnectivity);
            break;
        default:
            returnObject.metadata = getIntercomMetadataObject(eventName);
            break;
    }

    return returnObject;
}

function getModuleObjectForIntercom(fromModule, toModule) {
    return {
        From: fromModule,
        To: toModule
    };
}

// function sendModuleDataToIntercom(router, callback) {
//     var fromTitle = router.currentRoute !== null ? router.currentRoute.name !== undefined ? router.currentRoute.name : '' : '',
//         toTitle = router.activeInstruction() !== null ? router.activeInstruction().fragment : '';
//     if (fromTitle.toLowerCase() !== toTitle.toLowerCase() && fromTitle.toLowerCase() !== 'selection' && toTitle.toLowerCase() !== 'selection') {
//         callback(config.OPERATION.SendIntercomEventData, getIntercomObject(config.INTERCOM_EVENT.CHANGED_MODULE, getModuleObjectForIntercom(fromTitle, toTitle)));
//     }
// }

function sendModuleDataToIntercom(to, from, sendToIntercomFunc) {
    var fromTitle = from.name !== undefined ? from.name : '',
        toTitle = to.name !== undefined ? to.name : '';
    if (fromTitle.toLowerCase() !== toTitle.toLowerCase() && fromTitle.toLowerCase() !== 'selection' && toTitle.toLowerCase() !== 'selection') {
        sendToIntercomFunc(config.OPERATION.SendIntercomEventData, getIntercomObject(config.INTERCOM_EVENT.CHANGED_MODULE, getModuleObjectForIntercom(fromTitle, toTitle)));
    }
}

function scriptTagWithSrcExistsInHtml(srcName) {
    var len = $('script').filter(function () {
        return ($(this).attr('src') == srcName);
    }).length;

    return len > 0;
}

function isGoogleAnalyticsKeyAvailable() {
    return CustomTruckScienceRequirements.getMetaTagContent('ga-key') !== 'LOCALHOST';
}

function isValidEmailAddressForGoogleAnalytics(emailAddress) {
    return !(emailAddress.toLowerCase().includes('@truckscience.com') || emailAddress.toLowerCase().includes('@htm.co.za'));
}

function convertStringToTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

function sortArrayInOrderCustomTemplateStandard(arrayToSort, columns) {
    function sortUsingColumns(a, b, columns) {
        for (var i = 0; i < columns.length; i++) {
            var propertyName = typeof columns[i].itemToSort === 'function' ? columns[i].itemToSort() : columns[i].itemToSort;
            var sortType = columns[i].sortType.value;
            var aItemToSort = typeof a[propertyName] === 'function' ? a[propertyName]() : a[propertyName];
            var bItemToSort = typeof b[propertyName] === 'function' ? b[propertyName]() : b[propertyName];

            if (sortType === config.SORT_OPTIONS.ASC) {
                if (typeof a[propertyName] === 'string' || typeof b[propertyName] === 'string') {
                    if (aItemToSort.toUpperCase() < bItemToSort.toUpperCase()) {
                        return -1;
                    }
                    if (aItemToSort.toUpperCase() > bItemToSort.toUpperCase()) {
                        return 1;
                    }
                } else {
                    if (aItemToSort < bItemToSort) {
                        return -1;
                    }
                    if (aItemToSort > bItemToSort) {
                        return 1;
                    }
                }
            } else {
                if (typeof a[propertyName] === 'string' || typeof b[propertyName] === 'string') {
                    if (aItemToSort.toUpperCase() < bItemToSort.toUpperCase()) {
                        return 1;
                    }
                    if (aItemToSort.toUpperCase() > bItemToSort.toUpperCase()) {
                        return -1;
                    }
                } else {
                    if (aItemToSort < bItemToSort) {
                        return 1;
                    }
                    if (aItemToSort > bItemToSort) {
                        return -1;
                    }
                }
            }
        }
        return 0;
    }

    return arrayToSort.sort(function (a, b) {
        var aSource = typeof a.getSource === 'function' ? a.getSource() : a.Source,
            bSource = typeof b.getSource === 'function' ? b.getSource() : b.Source;
        switch (aSource) {
            case config.ACCESSORY_SOURCE_TYPES.CUSTOM:
                switch (bSource) {
                    case config.ACCESSORY_SOURCE_TYPES.CUSTOM:
                        // check columns
                        switch (a.getAccessLevel()) {
                            case config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL:
                                switch (b.getAccessLevel()) {
                                    case config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL:
                                        return sortUsingColumns(a, b, columns);
                                    case config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY:
                                        return -1;
                                }
                                break;
                            case config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY:
                                switch (b.getAccessLevel()) {
                                    case config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL:
                                        return 1;
                                    case config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY:
                                        return sortUsingColumns(a, b, columns);
                                }
                                break;
                        }
                        break;
                    case config.ACCESSORY_SOURCE_TYPES.STANDARD:
                        return -1;
                    case config.ACCESSORY_SOURCE_TYPES.TEMPLATE:
                        return 1;
                }
                break;
            case config.ACCESSORY_SOURCE_TYPES.STANDARD:
                switch (bSource) {
                    case config.ACCESSORY_SOURCE_TYPES.CUSTOM:
                        return 1;
                    case config.ACCESSORY_SOURCE_TYPES.STANDARD:
                        return sortUsingColumns(a, b, columns);
                    case config.ACCESSORY_SOURCE_TYPES.TEMPLATE:
                        return 1;
                }
                break;
            case config.ACCESSORY_SOURCE_TYPES.TEMPLATE:
                switch (bSource) {
                    case config.ACCESSORY_SOURCE_TYPES.CUSTOM:
                        return -1;
                    case config.ACCESSORY_SOURCE_TYPES.STANDARD:
                        return -1;
                    case config.ACCESSORY_SOURCE_TYPES.TEMPLATE:
                        return sortUsingColumns(a, b, columns);
                }
                break;
        }
    });
}

function runEffect(showMenu, menuLocation, partialId, obv, pathArray) {
    var selectedEffect = 'slide';
    var optionsShow, optionsHide, options;
    if (menuLocation === config.MENU_LOCATION.LEFT) {
        optionsShow = {
            direction: "left"
        };
        optionsHide = {
            direction: "right"
        };
    } else {
        optionsShow = {
            direction: "right"
        };
        optionsHide = {
            direction: "left"
        };
    }
    // some effects have required parameters
    if (selectedEffect === "scale") {
        options = {
            percent: 100
        };
    } else if (selectedEffect === "size") {
        options = {
            to: {
                width: 280,
                height: 185
            }
        };
    } /*else if (selectedEffect === "slide") {

    }*/
    if (obv !== undefined && !obv.value.menuDocked.value) {
        selectedEffect = 'fade';
    }
    // run the effect
    if (showMenu) {
        resetMenuPosition(partialId);
        $("#" + partialId + "-container").show(selectedEffect, optionsShow, config.menuSlideDuration, function () {
            if (obv !== undefined && obv.value.activeMenuStep.value !== undefined) {
                obv.value.updateJsElements(true, pathArray);
                setTimeout(function () {
                    obv.value.activeMenuStep.value.tempAddSliderToStep();
                    if (obv.value.menuDocked.value === true) {
                        repositionMenu(partialId);
                    }
                }, 500);

                pathArray = undefined;
                $('.breadcrumbs-container').on('mouseenter click touchstart', function () {
                    createDraggableMenu(obv);
                }).mouseleave(removeDraggableMenu);
            }
        });
    } else {
        WebuiPopovers.hideAll();
        $("#" + partialId + "-container").hide(selectedEffect, optionsShow, config.menuSlideDuration, function () {
            if (obv !== undefined && obv.value.activeMenuStep.value !== undefined) {
                resetMenuPosition(partialId, "");
                $('.breadcrumbs-container').on('mouseenter click touchstart', function () {
                    createDraggableMenu(obv);
                }).mouseleave(removeDraggableMenu);
            }
        });
    }

    function createDraggableMenu(obv) {
        removeDraggableMenu();
        $('.draggable').draggable({
            containment: '#configuration-section',
            drag: function () {
                // Change icon to pop in
                obv.value.menuDocked.value = false;
                obv.value.popMenuTitle.value = config.getTranslationText('966');
            },
            stop: function () {
                $(this).css({
                    'height': 'auto'
                });
            }
        });
    }

    function removeDraggableMenu() {
        try {
            $('.draggable').draggable('destroy');
        } catch (e) { 
            // TScMessenger.writeDebugMessage("Error removing dragging from menu");
        }
    }
}

function setTopPositionOfContainerRelativeToButton(id) {
    var idToUse = id.includes('#') ? id : '#' + id;
    if ($(idToUse + '-button').length > 0) {
        //var topPosition = $(idToUse + '-button').position().top - $(idToUse + '-button').height() - 5;
        var topPosition = $(idToUse + '-button').offset().top - $(idToUse + '-button').height();
        $(idToUse + '-container').position({
            top: topPosition
        });
    }
}

function ApplicationSettingsMeasurementSystem(type) {
    var measurementSystem = {};

    measurementSystem.lengthPrecisionForSpecification = 0;
    measurementSystem.massPrecisionForSpecification = 0;
    measurementSystem.volumePrecisionForSpecification = 0;
    measurementSystem.densityPrecisionForSpecification = 0;
    measurementSystem.linearDensityPrecisionForSpecification = 0;
    measurementSystem.areaPrecisionForSpecification = 0;
    measurementSystem.speedPrecisionForSpecification = 0;

    switch (type) {
        case config.MEASUREMENT_SYSTEM.METRIC:
            measurementSystem.id = config.MEASUREMENT_SYSTEM.METRIC;
            measurementSystem.description = config.getTranslationText('244');
            measurementSystem.unitLength = config.getTranslationText('246');
            measurementSystem.unitMass = config.getTranslationText('203');
            measurementSystem.unitDensity = config.getTranslationText('204');
            measurementSystem.unitVolume = config.getTranslationText('221');
            measurementSystem.unitLinearDensity = config.getTranslationText('205');
            measurementSystem.unitDistance = config.getTranslationText('206');
            measurementSystem.unitPerVolume = config.getTranslationText('2588');
            measurementSystem.unitVolumePer100Distance = config.getTranslationText('222');
            measurementSystem.unitMonthlyDistance = config.getTranslationText('4195');
            measurementSystem.unitSpeed = config.getTranslationText('771');
            measurementSystem.unitArea = config.getTranslationText('773');
            measurementSystem.unitMassMinor = config.getTranslationText('884');
            measurementSystem.unitAltitude = config.getTranslationText('821');
            measurementSystem.unitFuelConsumption = config.getTranslationText('860');
            measurementSystem.unitCubicVolume = config.getTranslationText('886');
            measurementSystem.unitVolumePlural = config.getTranslationText('902');
            measurementSystem.unitVolumeSingular = config.getTranslationText('220');
            measurementSystem.unitVolumePerHour = config.getTranslationText('4595');
            measurementSystem.unitPricePerVolume = config.getTranslationText('4596');
            measurementSystem.defaultLengthIncrement = 100;
            measurementSystem.defaultMassIncrement = 100;
            measurementSystem.defaultPercentageIncrement = 5;
            measurementSystem.lengthConversion = 1;
            measurementSystem.massConversion = 1;
            measurementSystem.densityConversion = 1;
            measurementSystem.distanceConversion = 1;
            measurementSystem.volumeConversion = 1;
            measurementSystem.linearDensityConversion = 1;
            measurementSystem.areaConversion = 1;
            measurementSystem.speedConversion = 1;
            measurementSystem.lengthPrecision = 0;
            measurementSystem.massPrecision = 0;
            measurementSystem.volumePrecision = 0;
            measurementSystem.densityPrecision = 3;
            measurementSystem.linearDensityPrecision = 2;
            measurementSystem.areaPrecision = 1;
            measurementSystem.speedPrecision = 0;
            measurementSystem.defaultCoefficientOfDragIncrement = 0.05;
            measurementSystem.defaultAreaIncrement = 0.2;
            measurementSystem.defaultDriveAxleRatioIncrement = 0.1;
            measurementSystem.defaultSpeedIncrement = 5;
            measurementSystem.altitudeConversion = 1;
            break;
        case config.MEASUREMENT_SYSTEM.IMPERIALUK:
            measurementSystem.id = config.MEASUREMENT_SYSTEM.IMPERIALUK;
            measurementSystem.description = config.getTranslationText('191');
            measurementSystem.unitLength = config.getTranslationText('193');
            measurementSystem.unitMass = config.getTranslationText('212');
            measurementSystem.unitDensity = config.getTranslationText('214');
            measurementSystem.unitVolume = config.getTranslationText('166');
            measurementSystem.unitLinearDensity = config.getTranslationText('213');
            measurementSystem.unitDistance = config.getTranslationText('245');
            measurementSystem.unitPerVolume = ' / ' + config.getTranslationText('903');
            measurementSystem.unitVolumePer100Distance = config.getTranslationText('2574');
            measurementSystem.unitMonthlyDistance = config.getTranslationText('4196');
            measurementSystem.unitSpeed = config.getTranslationText('770');
            measurementSystem.unitArea = config.getTranslationText('772');
            measurementSystem.unitMassMinor = config.getTranslationText('884');
            measurementSystem.unitAltitude = config.getTranslationText('822');
            measurementSystem.unitFuelConsumption = config.getTranslationText('859');
            measurementSystem.unitCubicVolume = config.getTranslationText('887');
            measurementSystem.unitVolumePlural = config.getTranslationText('903');
            measurementSystem.unitVolumeSingular = config.getTranslationText('165');
            measurementSystem.unitVolumePerHour = config.getTranslationText('4597');
            measurementSystem.unitPricePerVolume = config.getTranslationText('4598');
            measurementSystem.defaultLengthIncrement = 126.9999999998628;
            measurementSystem.defaultMassIncrement = 45.35923703803783;
            measurementSystem.defaultPercentageIncrement = 5;
            measurementSystem.lengthConversion = 25.4;//0.03937007874;//0.0393700787402;
            measurementSystem.massConversion = 2.20462262;
            measurementSystem.densityConversion = 10.02241312246;
            measurementSystem.distanceConversion = 0.6213712;
            measurementSystem.volumeConversion = 0.2199692;
            measurementSystem.linearDensityConversion = 0.671968975;
            measurementSystem.areaConversion = 10.76391041671;
            measurementSystem.speedConversion = 0.62137119;
            measurementSystem.lengthPrecision = 1;
            measurementSystem.massPrecision = 0;
            measurementSystem.volumePrecision = 1;
            measurementSystem.densityPrecision = 3;
            measurementSystem.linearDensityPrecision = 1;
            measurementSystem.areaPrecision = 1;
            measurementSystem.speedPrecision = 0;
            measurementSystem.defaultCoefficientOfDragIncrement = 0.05;
            measurementSystem.defaultAreaIncrement = 0.2;
            measurementSystem.defaultDriveAxleRatioIncrement = 0.1;
            measurementSystem.defaultSpeedIncrement = 5;
            measurementSystem.altitudeConversion = 3.2808;
            break;
        case config.MEASUREMENT_SYSTEM.IMPERIALUS:
            measurementSystem.id = config.MEASUREMENT_SYSTEM.IMPERIALUS;
            measurementSystem.description = config.getTranslationText('192');
            measurementSystem.unitLength = config.getTranslationText('2581');
            measurementSystem.unitMass = config.getTranslationText('2582');
            measurementSystem.unitDensity = config.getTranslationText('2583');
            measurementSystem.unitVolume = config.getTranslationText('2575');
            measurementSystem.unitLinearDensity = config.getTranslationText('2584');
            measurementSystem.unitDistance = config.getTranslationText('2585');
            measurementSystem.unitPerVolume = ' / ' + config.getTranslationText('903');
            measurementSystem.unitVolumePer100Distance = config.getTranslationText('2576');
            measurementSystem.unitMonthlyDistance = config.getTranslationText('4197');
            measurementSystem.unitSpeed = config.getTranslationText('2638');
            measurementSystem.unitArea = config.getTranslationText('772');
            measurementSystem.unitMassMinor = config.getTranslationText('884');
            measurementSystem.unitAltitude = config.getTranslationText('2682');
            measurementSystem.unitFuelConsumption = config.getTranslationText('2688');
            measurementSystem.unitCubicVolume = config.getTranslationText('2689');
            measurementSystem.unitVolumePlural = config.getTranslationText('2696');
            measurementSystem.unitVolumeSingular = config.getTranslationText('2573');
            measurementSystem.unitVolumePerHour = config.getTranslationText('4599');
            measurementSystem.unitPricePerVolume = config.getTranslationText('4600');
            measurementSystem.defaultLengthIncrement = 126.9999999998628;
            measurementSystem.defaultMassIncrement = 45.35923703803783;
            measurementSystem.defaultPercentageIncrement = 5;
            measurementSystem.lengthConversion = 25.4;// 0.03937007874;//0.0393700787402;
            measurementSystem.massConversion = 2.20462262;
            measurementSystem.densityConversion = 8.345404374;
            measurementSystem.distanceConversion = 0.6213712;
            measurementSystem.volumeConversion = 0.2641729;
            measurementSystem.linearDensityConversion = 0.671968975;
            measurementSystem.areaConversion = 10.76391041671;
            measurementSystem.speedConversion = 0.62137119;
            measurementSystem.lengthPrecision = 1;
            measurementSystem.massPrecision = 0;
            measurementSystem.volumePrecision = 1;
            measurementSystem.densityPrecision = 3;
            measurementSystem.linearDensityPrecision = 1;
            measurementSystem.areaPrecision = 1;
            measurementSystem.speedPrecision = 0;
            measurementSystem.defaultCoefficientOfDragIncrement = 0.05;
            measurementSystem.defaultAreaIncrement = 0.2;
            measurementSystem.defaultDriveAxleRatioIncrement = 0.1;
            measurementSystem.defaultSpeedIncrement = 5;
            measurementSystem.altitudeConversion = 3.2808;
            break;
    }

    return measurementSystem;
}

function getMeasurementSystemUsingType(type) {
    return ApplicationSettingsMeasurementSystem(type);
}

function convertToNumber(value) {
    return typeof value === 'string' ? parseFloat(removeUnneccessaryCharactersFromGlobalizeNumberString(value)) : value;
}

/**
    * Example string "162.0 @ 2,400 - 2,400"
    * @param {string} value
    */
function convertRpmValue(value) {
    var returnString;

    var firstSplit = value.split('@');
    if (firstSplit.length > 1) {
        var num1 = Globalize.format(convertToNumber(firstSplit[0]), "n1");
        var secondSplit = firstSplit[1].split('-');
        var num2 = Globalize.format(convertToNumber(secondSplit[0]), "n0"),
            num3 = Globalize.format(convertToNumber(secondSplit[1]), "n0");
        return num1 + ' @ ' + num2 + ' - ' + num3;
    } else {
        return value;
    }


    
}

function convertToLength(value, measurementSystemId) {
    var measurementSystem = getMeasurementSystemUsingType(measurementSystemId),
        valueToConvert = typeof value === 'string' ? parseFloat(this.removeUnneccessaryCharactersFromGlobalizeNumberString(value)) : value;
    //valueToConvert = typeof value === 'string' ? parseFloat(value.replace(/\s+/g, '')) : value;
    return valueToConvert / measurementSystem.lengthConversion;
}

function convertToMass(value, measurementSystemId) {
    var measurementSystem = getMeasurementSystemUsingType(measurementSystemId),
        valueToConvert = typeof value === 'string' ? parseFloat(this.removeUnneccessaryCharactersFromGlobalizeNumberString(value)) : value;
    //valueToConvert = typeof value === 'string' ? parseFloat(value.replace(/\s+/g, '')) : value;
    return valueToConvert * measurementSystem.massConversion;
}

function convertToVolume(value, measurementSystemId) {
    var measurementSystem = getMeasurementSystemUsingType(measurementSystemId),
        valueToConvert = typeof value === 'string' ? parseFloat(this.removeUnneccessaryCharactersFromGlobalizeNumberString(value)) : value;
    //valueToConvert = typeof value === 'string' ? parseFloat(value.replace(/\s+/g, '')) : value;
    return valueToConvert * measurementSystem.volumeConversion;
}

function convertToSpeed(value, measurementSystemId) {
    var measurementSystem = getMeasurementSystemUsingType(measurementSystemId),
        valueToConvert = typeof value === 'string' ? parseFloat(this.removeUnneccessaryCharactersFromGlobalizeNumberString(value)) : value;
    //valueToConvert = typeof value === 'string' ? parseFloat(value.replace(/\s+/g, '')) : value;
    return valueToConvert * measurementSystem.speedConversion;
}

function getUIFormattedLengthValue(value, measurementSystemId) {
    var measurementSystem = getMeasurementSystemUsingType(measurementSystemId);
    return Globalize.format((value / measurementSystem.lengthConversion), "n" + measurementSystem.lengthPrecision) + ' ' + measurementSystem.unitLength;
}

function getUIFormattedMassValue(value, measurementSystemId) {
    var measurementSystem = getMeasurementSystemUsingType(measurementSystemId);
    return Globalize.format((value * measurementSystem.massConversion), "n" + measurementSystem.massPrecision) + ' ' + measurementSystem.unitMass;
}

function addComboboxCodeIfNotAvailable() {
    if ($('div:first').combobox === undefined) {
        $.widget("custom.combobox", {
            _create: function () {
                this.wrapper = $("<span>")
                    .addClass("custom-combobox")
                    .insertAfter(this.element);

                this.element.hide();
                this._createAutocomplete();
                this._createShowAllButton();
            },

            _createAutocomplete: function () {
                var selected = this.element.children(":selected"),
                    value = selected.val() ? selected.text() : "";

                this.input = $("<input>")
                    .appendTo(this.wrapper)
                    .val(value)
                    .attr("title", "")
                    .addClass("custom-combobox-input ui-widget ui-widget-content ui-state-default ui-corner-left")
                    .autocomplete({
                        delay: 0,
                        minLength: 0,
                        source: $.proxy(this, "_source")
                    })
                    .tooltip({
                        classes: {
                            "ui-tooltip": "ui-state-highlight"
                        }
                    });

                this._on(this.input, {
                    autocompleteselect: function (event, ui) {
                        ui.item.option.selected = true;
                        this._trigger("select", event, {
                            item: ui.item.option
                        });
                    },

                    //autocompletechange: "_removeIfInvalid",
                    autocompletechange: function (event, ui) {
                        this._trigger("change", event, {
                            text: event.target.value
                        });
                    }
                });
            },

            _createShowAllButton: function () {
                var input = this.input,
                    wasOpen = false;

                $("<a>")
                    .attr("tabIndex", -1)
                    //.attr("title", "Show All Items")
                    //.tooltip()
                    .appendTo(this.wrapper)
                    .button({
                        icons: {
                            primary: "ui-icon-triangle-1-s"
                        },
                        text: false
                    })
                    .removeClass("ui-corner-all")
                    .addClass("ui-button ui-widget ui-button-icon-only custom-combobox-toggle ui-corner-right")
                    .append('<span class="ui-button-icon ui-icon ui-icon-triangle-1-s"></span>')
                    .append('<span class="ui-button-icon-space"></span>')
                    .on("mousedown", function () {
                        wasOpen = input.autocomplete("widget").is(":visible");
                    })
                    .on("click", function () {
                        input.trigger("focus");

                        // Close if already visible
                        if (wasOpen) {
                            return;
                        }

                        // Pass empty string as value to search for, displaying all results
                        input.autocomplete("search", "");
                    });
            },

            _source: function (request, response) {
                var matcher = new RegExp($.ui.autocomplete.escapeRegex(request.term), "i");
                response(this.element.children("option").map(function () {
                    var text = $(this).text();
                    if (this.value && (!request.term || matcher.test(text)))
                        return {
                            label: text,
                            value: text,
                            option: this
                        };
                }));
            },

            _removeIfInvalid: function (event, ui) {
                return;
                //    // Selected an item, nothing to do
                //    if (ui.item) {
                //        return;
                //    }

                //    // Search for a match (case-insensitive)
                //    var value = this.input.val(),
                //        valueLowerCase = value.toLowerCase(),
                //        valid = false;
                //    this.element.children("option").each(function () {
                //        if ($(this).text().toLowerCase() === valueLowerCase) {
                //            this.selected = valid = true;
                //            return false;
                //        }
                //    });

                //    // Found a match, nothing to do
                //    if (valid) {
                //        return;
                //    }

                //    // Remove invalid value
                //    this.input
                //        .val("")
                //        .attr("title", value + " didn't match any item")
                //        .tooltip("open");
                //    this.element.val("");
                //    this._delay(function () {
                //        this.input.tooltip("close").attr("title", "");
                //    }, 2500);
                //    this.input.autocomplete("instance").term = "";
            },

            _destroy: function () {
                this.wrapper.remove();
                this.element.show();
            }
        });
    }
}

/**
    * Returns string with quotation marks (" or ') stripped out
    * @param {string} stringToChange - String to be converted
    * @returns {string} string without quotation marks
    */
function removeQuotationMarks(stringToChange) {
    return stringToChange.replace(/[^A-Za-z0-9 &\\#,+()$~%.?<>{}]/g, '');
}

/**
    * Removes special characters from file name
    * Used this answer from Stack Overflow to remove characters (https://stackoverflow.com/a/31976060)
    * @param {string} fileName - file name to be converted
    * @returns {string} Converted file name
    */
function returnValidFileName(fileName) {
    return fileName.replace(/[<>:"'/*~#%&?{}\\]/g, '');
    //var returnString = '';
    //returnString = fileName.replace(/[<>:"'/\|*]/g, '');
    //return returnString;
}

// function getAuthContext() {
//     return authContext;
// }
// function setupAuthContext() {
//     authContext = msalAuth.setupAndInitialiseMsalPublicClientApplication();
// }
// function setupAuthContext(loginCallback) {
//     //var authContextConfig = {
//     //    instance: 'https://login.microsoftonline.com/',
//     //    tenant: CustomTruckScienceRequirements.getMetaTagContent('microsoft-signin-tenant'),
//     //    clientId: CustomTruckScienceRequirements.getMetaTagContent('microsoft-signin-client-id'),
//     //    postLogoutRedirectUri: window.location.origin,
//     //    cacheLocation: 'localStorage' // enable this for IE, as sessionStorage does not work for localhost.
//     //};
//     //authContext = new AuthenticationContext(authContextConfig);

//     if (getBrowser() !== config.BROWSER_NAME.INTERNETEXPLORER && typeof window.Msal !== 'undefined') {
//         var authContextConfig = {
//             clientId: CustomTruckScienceRequirements.getMetaTagContent('microsoft-signin-client-id'),
//             redirectUri: location.origin + '/mslogin.html'
//             //redirectUri: "https://localhost:44333/mslogin.html"
//             //location.origin + '/login.html'
//         };
//          authContext = new window.Msal.UserAgentApplication(authContextConfig.clientId, null, function (errorDesc, token, error, tokenType){
//             var breakHere = 0;
//          }, {
//             redirectUri: authContextConfig.redirectUri,
//             // scopes: ["User.Read"],
//             // validateAuthority: false,
//             // cacheLocation: 'localStorage',
//             storeAuthStateInCookie: true,
//             // forceRefresh: true,
//             // cacheLookupPolicy: window.Msal.cacheLookupPolicy.RefreshToken,
//             refreshTokenExpirationOffsetSeconds: 86400,
//             // prompt: 'consent'
//         });

//         //Previous version of msal uses redirect url via a property
//         if (authContext.redirectUri) {
//             authContext.redirectUri = authContextConfig.redirectUri;
//         }
//     }
// }

function getRegisterUserUsingMicrosoft() {
    return registerUserUsingMicrosoft;
}

function setRegisterUserUsingMicrosoft(newValue) {
    registerUserUsingMicrosoft = newValue;
}

function isInternetExplorer() {
    return getBrowser() === config.BROWSER_NAME.INTERNETEXPLORER;
}

/**
    * 
    * @param {string} textToBeTrimmed
    * @param {number} maxTextWidth - Max length of text in pixels
    * @returns {string}
    */
function trimTextAndAddEllipsisIfRequired(textToBeTrimmed, maxTextWidth) {
    var textToBeReturned = getTextToBeDisplayed(textToBeTrimmed, maxTextWidth);
    return textToBeReturned === textToBeTrimmed ? textToBeTrimmed : textToBeReturned.concat('...');

    function getTextToBeDisplayed(text, maxTextWidth) {
        var maxWidthToUse = typeof maxTextWidth === 'number' ? maxTextWidth : 195;
        if (getWidthOfText(text) < maxWidthToUse) {
            return text;
        } else {
            return getTextToBeDisplayed(text.substring(0, text.length - 1), maxTextWidth);
        }
    }

    function getWidthOfText(txt, fontname, fontsize) {
        var getWidthOfText, length = 0;
        if (getWidthOfText === undefined) {
            getWidthOfText = document.createElement('span');
            getWidthOfText.className = 'width-of-text-span';
            document.body.appendChild(getWidthOfText);
        }
        getWidthOfText.innerText = txt;
        length = getWidthOfText.offsetWidth;

        var elem = document.querySelector('.width-of-text-span');
        elem.parentNode.removeChild(elem);
        return length;
    }
}

/**
    * 
    * @param {number} num
    * @returns {string}
    */
function getLetterFromInteger(num) {
    return String.fromCharCode(65 + num).toUpperCase();
}

function decodeAccessCode(accessCode) {
    return b64DecodeUnicode(accessCode);

    function b64DecodeUnicode(str) {
        return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
    }
}

function isRecommendedBrowser() {
    var browserName = getBrowser();

    switch (browserName) {
        case config.BROWSER_NAME.CHROME:
        case config.BROWSER_NAME.EDGE:
        case config.BROWSER_NAME.FIREFOX:
        case config.BROWSER_NAME.SAFARI:
        case config.BROWSER_NAME.CHROMEONANDROID:
            return true;
        default:
            return false;
    }
}

function getFridayFortnightDateString(cultureCode) {
    var dayINeed = 5, // for Friday
        today = moment().isoWeekday(),
        momentInstance = moment().add(2, 'weeks').isoWeekday(dayINeed);

    //// if we haven't yet passed the day of the week that I need:
    //if (today < dayINeed) {
    //    // then just give me this week's instance of that day
    //    return moment().add(1, 'weeks').isoWeekday(dayINeed);
    //} else {
    //    // otherwise, give me *next week's* instance of that same day
    //    return moment().add(2, 'weeks').isoWeekday(dayINeed);
    //}

    //return Globalize.format(new Date(momentInstance.year(), momentInstance.month(), momentInstance.date()), Globalize.culture().calendar.patterns.d, cultureCode)
    //return Globalize.format(new Date(momentInstance.year(), momentInstance.month(), momentInstance.date()), Globalize.cultures[cultureCode].calendar.patterns.d, cultureCode)
    return getFormattedDate(cultureCode, new Date(momentInstance.year(), momentInstance.month(), momentInstance.date()));
}

/**
    * Hide module view for the likes of selection, configuration, summary, performance, etc.
    */
function fadeModuleView() {
    hideElementUsingTransition('.view');
}

/**
    * Show module view for the likes of selection, configuration, summary, performance, etc.
    */
function showModuleView() {
    showElementUsingTransition('.view');
}

function fadeNavBarMenu() {
    //$('.header-menu-right').addClass('fade-out-from-view').removeClass('fade-into-view');
    hideElementUsingTransition('.header-menu-right');
}

function showNavBarMenuUsingTransition() {
    showElementUsingTransition('.header-menu-right');
}

function showElementUsingTransition(elementIdentifier) {
    //changeClassOnElement(elementIdentifier, 'fade-into-view', 'hide-from-view');
    changeClassOnElement(elementIdentifier, 'fade-into-view', 'fade-out-from-view');
}

//function hideElementAndRemoveTransitionClass(elementIdentifier) {
function hideElementUsingTransition(elementIdentifier) {
    //changeClassOnElement(elementIdentifier, 'hide-from-view', 'fade-into-view');
    changeClassOnElement(elementIdentifier, 'fade-out-from-view', 'fade-into-view');
}

function changeClassOnElement(elementIdentifier, addClassName, removeClassName) {
    var addClassNameToUse = typeof addClassName === 'string' ? addClassName : 'fade-into-view',
        removeClassNameToUse = typeof removeClassName === 'string' ? removeClassName : 'fade-out-from-view';
    //removeClassNameToUse = typeof removeClassName === 'string' ? removeClassName : 'hide-from-view';
    if (!$(elementIdentifier).hasClass(addClassNameToUse)) {
        $(elementIdentifier).addClass(addClassNameToUse).removeClass(removeClassNameToUse);

        //setTimeout(function () {
        //    $(elementIdentifier).removeClass(removeClassNameToUse);
        //}, 1000);
    }
}

function fadeOutAll() {
    fadeModuleView();
    fadeNavBarMenu();
}

function hideAll() {
    hideNavBarMenuUsingTransition();
    hideViewUsingTransition();
}

function fadeInAll() {
    showNavBarMenuUsingTransition();
    showModuleView();
}

function hideViewUsingTransition() {
    hideNavBarMenuUsingTransition('.view');
}

function hideNavBarMenuUsingTransition() {
    hideElementUsingTransition('.header-menu-right');
}

function closeOfferVisually() {
    // hide view
    fadeModuleView();
    // hide offer menu
    hideElementUsingTransition('.offer-nav-bar-menu');
}

function openOfferVisually() {
    // fade in view
    showModuleView();
    if ($('.header-menu-right').hasClass('hide-from-view')) {
        showNavBarMenuUsingTransition();
    }
    // fade in offer menu
    showElementUsingTransition('.offer-nav-bar-menu');
}

/**
    * Check if the variable is a Knockout computed or pureComputed
    * @param {*} obv - Check if this is Knockout computed or pureComputed
    * @returns {boolean}
    */
function isVariableRef(refVal) {
    if (isRef(refVal)) {
        return true;
    }
    return false;
}

/**
    * Use if observable value could be a computed/pureComputed. Value will not be used if the observable is a computed/pureComputed.
    * @param {KnockoutObservable} obv - observable to update
    * @param {any} value - value to update the observable to
    */
function updateObservableIfRef(refVal, value) {
    if (isRef(refVal)) {
        refVal.value = value;
    }
}

/**
    * Check if value exists on an object
    * @param {any} valueToCheck
    * @param {object} objectToCheck
    * @returns {boolean} True if the value exists on the object, False if it doesn't
    */
function checkIfValueExistsOnObject(valueToCheck, objectToCheck) {
    if (typeof objectToCheck !== 'object') {
        throw 'checkIfValueExistsOnObject objectToCheck should be an object';
    }
    if (Object.values) {
        return Object.values(objectToCheck).indexOf(valueToCheck) >= 0;
    } else {
        return checkIfValueExistsOnObjectUsingKeys(valueToCheck, objectToCheck);
    }
}

/**
    * Check if value exists on an object if Object.values() is not available (IE)
    * @param {any} valueToCheck
    * @param {object} objectToCheck
    * @returns {boolean} True if the value exists on the object, False if it doesn't
    */
function checkIfValueExistsOnObjectUsingKeys(valueToCheck, objectToCheck) {
    if (typeof objectToCheck !== 'object') {
        throw 'checkIfValueExistsOnObjectUsingKeys objectToCheck should be an object';
    }
    var returnValue = false;

    Object.keys(objectToCheck).forEach(function (key) {
        if (objectToCheck[key] === valueToCheck) {
            returnValue = true;
        }
    });

    return returnValue;
}

function getPageContainerClass() {
    switch (getBrowser()) {
        case config.BROWSER_NAME.INTERNETEXPLORER:
            return 'ie-page-container';
        case config.BROWSER_NAME.FIREFOX:
            return 'firefox-page-container';
        case config.BROWSER_NAME.EDGE:
            return 'edge-page-container';
        case config.BROWSER_NAME.SAFARI:
            if (CustomTruckScienceRequirements.getOperatingSystem() === CustomTruckScienceRequirements.OS_NAME.Mac) {
                return 'mac-safari-page-container';
            }
            return '';
        default:
            return 'normal-page-container';
    }
    //if (getBrowser() === config.BROWSER_NAME.INTERNETEXPLORER) {
    //    return 'ie-page-container';
    //} else if (getBrowser() === config.BROWSER_NAME.FIREFOX) {
    //    return 'firefox-page-container';
    //} else if (getBrowser() === config.BROWSER_NAME.EDGE) {
    //    return 'edge-page-container';
    //} else if (getBrowser() === config.BROWSER_NAME.SAFARI && CustomTruckScienceRequirements.getOperatingSystem() === CustomTruckScienceRequirements.OS_NAME.Mac) {
    //    return 'mac-safari-page-container';
    //} else {
    //    return 'normal-page-container';
    //}
}

function getFileSizeInMBAsString(file) {
    try {
        return getFileSizeInMB(file).toFixed(2) + 'MB';
    } catch (e) {
        return '0MB';
    }
}

function getFileSizeInMB(file) {
    try {
        if (file === undefined) {
            throw 'getFileSizeInMB file param is not passed into function';
        }
        if (file.constructor !== File) {
            throw 'getFileSizeInMB file param is not a File';
        }
        return file.size / 1024 / 1024;
    } catch (e) {
        // TODOTC: Write error messages
        return 0;
    }
}

/**
    * Check if file size exceeds maximum size in MB
    * @param {File} file - File to be checked
    * @param {number} [maxSize=1] - The maximum file size in MB. Defaults to 1.
    */
function checkFileSizeDoesNotExceedMaximumMB(file, maxSize) {
    try {
        if (file === undefined) {
            throw 'checkFileSizeDoesNotExceedMaximumMB file param is not passed into function';
        }
        if (file.constructor !== File) {
            throw 'checkFileSizeDoesNotExceedMaximumMB file param is not a File';
        }
        var maxSizeToUse = typeof maxSize === 'number' ? maxSize : 1;
        return (file.size / 1024 / 1024) < maxSizeToUse;
    } catch (e) {
        // TODOTC: Write error messages
        return false;
    }
}

/**
    * Check if file size exceeds maximum size in KB
    * @param {File} file - File to be checked
    * @param {number} [maxSize=1] - The maximum file size in KB. Defaults to 1.
    */
function checkFileSizeDoesNotExceedMaximumKB(file, maxSize) {
    try {
        if (file === undefined) {
            throw 'checkFileSizeDoesNotExceedMaximumKB file param is not passed into function';
        }
        if (file.constructor !== File) {
            throw 'checkFileSizeDoesNotExceedMaximumKB file param is not a File';
        }
        var maxSizeToUse = typeof maxSize === 'number' ? maxSize : 1;
        return (file.size / 1024) < maxSizeToUse;
    } catch (e) {
        // TODOTC: Write error messages
        return false;
    }
}

/**
    * Get R/momth value
    * @param {number} price - Price of vehicle/body/trailer/accessory
    * @param {number} residualRate - Residual Rate
    * @param {number} interestRate - Interest Rate
    * @param {number} repaymentTerm - Repayment term in months
    * @returns {number} - Returns the calculated value
    */
function getFinanceCostPerMonth(price, residualRate, interestRate, repaymentTerm) {
    var financeCostPerMonth = 0;

    if (interestRate > 0) {
        financeCostPerMonth = (((price - (price * residualRate / 100)) *
            (interestRate / 12 / 100) / (1 - Math.pow((1 + (interestRate / 12 / 100)), (repaymentTerm * -1))) +
            (price * residualRate / 100) * (interestRate / 12 / 100)));
    }

    if (interestRate === 0) {
        financeCostPerMonth = (price - (price * residualRate / 100)) / repaymentTerm;
    }

    if (repaymentTerm === 0) {
        financeCostPerMonth = price - price * residualRate / 100;
    }

    return financeCostPerMonth;
}

function getPricePerMonth(listPrice, netPrice, period, interestRate, residualRate, residualRateType) {
    var financeCostPerMonth = 0,
        priceToUseForResidualCalc = residualRateType === config.RESIDUAL_RATE_TYPES.NET_PRICE ? netPrice : listPrice;
    if (interestRate > 0) {
        financeCostPerMonth = (((netPrice - (priceToUseForResidualCalc * residualRate / 100)) *
            (interestRate / 12 / 100) / (1 - Math.pow((1 + (interestRate / 12 / 100)), (period * -1))) +
            (priceToUseForResidualCalc * residualRate / 100) * (interestRate / 12 / 100)));
    }

    if (interestRate === 0) {
        financeCostPerMonth = ((netPrice - (priceToUseForResidualCalc * residualRate / 100)) / period);
    }

    if (period === 0) {
        financeCostPerMonth = ((netPrice - priceToUseForResidualCalc * residualRate / 100));
    }

    return financeCostPerMonth;
}

/**
    * 
    * @param {Array} licenceCategories - Array of licence category objects
    * @param {string} categoryType - category type to user when filtering. Defaults to VEHICLE
    * @param {number} mass - mass of the chassis (VEHICLE/TRAILER1/TRAILER2)
    * @returns {Array} - Return array
    */
function getFilteredLicenceCategoriesArray(licenceCategories, categoryType, mass) {
    var returnArray = [],
        categoryTypeToUse = typeof categoryType === 'string' ? categoryType : 'VEHICLE';
    if (Array.isArray(licenceCategories)) {
        licenceCategories.forEach(function (lc) {
            //if (lc.CategoryType === categoryTypeToUse) {
            if (licenceCategoryCheck(lc, categoryTypeToUse, mass)) {
                var alreadyExists = false;
                returnArray.forEach(function (alc) {
                    if (alc.RegionId === lc.RegionId && alc.RegionName === lc.RegionName) {
                        alreadyExists = true;
                    }
                });
                if (!alreadyExists) {
                    //returnArray.push({
                    //    id: lc.RegionId,
                    //    description: lc.RegionName
                    //});
                    returnArray.push(lc);
                }
            }
        });

        if (returnArray.length === 0) {
            // Mass did not fit into licence category so use the max
            returnArray = getMaxLicenceCategoryForEachRegion(licenceCategories);
        }
    }

    function licenceCategoryCheck(lc, categoryTypeToUse, massToUse) {
        if (typeof massToUse === 'number') {
            if (lc.CategoryType === categoryTypeToUse && lc.MassLow === 0 && lc.MassHigh === 0) {
                return true;
            } else if (lc.CategoryType === categoryTypeToUse && massToUse > lc.MassLow && massToUse <= lc.MassHigh) {
                return true;
            }
        } else {
            if (lc.CategoryType === categoryTypeToUse) {
                return true;
            }
        }

        return false;
    }

    function getMaxLicenceCategoryForEachRegion(lcArray) {
        var arrayToReturn = [],
            maxLicenceCategoryRegions = [],
            regionIdArray = [],
            tempRegionArray = [];

        lcArray.forEach(function (lc) {
            if (lc.CategoryType === categoryTypeToUse && !regionIdArray.includes(lc.RegionId)) {
                regionIdArray.push(lc.RegionId);
            }
        });

        regionIdArray.forEach(function (regionId) {
            var maxMassHighForRegion = 0,
                newArray = [];
            lcArray.forEach(function (lc) {
                if (lc.CategoryType === categoryTypeToUse && lc.RegionId === regionId) {
                    // Get the max value
                    newArray.push(lc.MassHigh);
                }
            });

            var maxVal = newArray.reduce(function (a, b) {
                return Math.max(a, b);
            });

            lcArray.forEach(function (lc) {
                if (lc.CategoryType === categoryTypeToUse && lc.RegionId === regionId && lc.MassHigh === maxVal) {
                    // Get the max value
                    arrayToReturn.push(lc);
                }
            });
        });

        return arrayToReturn;
    }

    return returnArray;
}

function getLicenceCategoryObjectToUse(licenceCategories, categoryType, mass, licenceCategoryRegionId) {
    var licenceCategoryObject,
        massNotCoveredByLegislation = 0;
    var arrayToUse = getFilteredLicenceCategoriesArray(licenceCategories, categoryType, mass);
    licenceCategoryObject = arrayToUse.find(function (lc) {
        return lc.RegionId === licenceCategoryRegionId;
    });
    if (licenceCategoryObject === undefined) {
        licenceCategoryObject = arrayToUse[0];
    }
    if (licenceCategoryObject.MassHigh < mass) {
        massNotCoveredByLegislation = mass - licenceCategoryObject.MassHigh;
    }
    if (categoryType.includes('TRAILER')) {
        if (typeof licenceCategoryObject.TrailerLicenceAdditionalAmount !== 'number' || typeof licenceCategoryObject.TrailerLicenceAdditionalIncrement !== 'number' || licenceCategoryObject.TrailerLicenceAdditionalAmount === 0 || licenceCategoryObject.TrailerLicenceAdditionalIncrement === 0) {
            licenceCategoryObject.Overrage = 0;
        } else {
            //            myFee = myFee + (massNotCoveredByLegislation / _
            //                    myDataRow("trailer_licence_additional_increment")) * _
            //                    myDataRow("trailer_licence_additional_amount")
            licenceCategoryObject.Overrage = (massNotCoveredByLegislation / licenceCategoryObject.TrailerLicenceAdditionalIncrement) * licenceCategoryObject.TrailerLicenceAdditionalAmount;
        }
    } else {
        if (typeof licenceCategoryObject.VehicleLicenceAdditionalAmount !== 'number' || typeof licenceCategoryObject.VehicleLicenceAdditionalIncrement !== 'number' || licenceCategoryObject.VehicleLicenceAdditionalAmount === 0 || licenceCategoryObject.VehicleLicenceAdditionalIncrement === 0) {
            licenceCategoryObject.Overrage = 0;
        } else {
            //            myFee = myFee + myUtil.RoundUp((massNotCoveredByLegislation / _
            //                    myDataRow.Item("vehicle_licence_additional_increment"))) * _
            //                    myDataRow.Item("vehicle_licence_additional_amount")
            licenceCategoryObject.Overrage = (massNotCoveredByLegislation / licenceCategoryObject.VehicleLicenceAdditionalIncrement) * licenceCategoryObject.VehicleLicenceAdditionalAmount;
        }
    }
    licenceCategoryObject.TotalPrice = licenceCategoryObject.Price + licenceCategoryObject.Overrage;
    //'find the max
    //maxMassCoveredByLegislation = myDataTable.Rows(myDataTable.Rows.Count - 1)("mass_high")

    //If maxMassCoveredByLegislation < licenceMass Then
    //    massNotCoveredByLegislation = licenceMass - maxMassCoveredByLegislation
    //    licenceMass = maxMassCoveredByLegislation
    //End If

    //myFee = 0

    //'when a vehicle has no mass it should not be charged a licence fee
    //If licenceMass > 0 Then

    //    For Each currentRow As DataRow In myDataTable.Rows

    //        If licenceMass >= currentRow("mass_low") And _
    //           licenceMass <= currentRow("mass_high") Then
    //            myFee = currentRow("price")
    //        End If

    //    Next

    //End If

    //Dim myDataRow As DataRow
    //Dim myUtil As New TruckScience.Business.TransSolve.UtilGeneral

    //myDataRow = AdditionalLicenceInfo(countryId, regionId, connection)

    //Select Case licenceType

    //    Case FileFinanceLicenceDetail.LicenceType.Trailer1, FileFinanceLicenceDetail.LicenceType.Trailer2

    //        If myDataRow("trailer_licence_additional_increment") Is DBNull.Value Or _
    //           myDataRow("trailer_licence_additional_amount") Is DBNull.Value Or _
    //           myDataRow("trailer_licence_additional_increment") = 0 Or _
    //           myDataRow("trailer_licence_additional_amount") = 0 Then
    //            myFee = 0
    //        Else
    //            myFee = myFee + (massNotCoveredByLegislation / _
    //                    myDataRow("trailer_licence_additional_increment")) * _
    //                    myDataRow("trailer_licence_additional_amount")
    //        End If

    //        licenceDate = myDataRow("trailer_licence_date")

    //    Case FileFinanceLicenceDetail.LicenceType.Vehicle

    //        If myDataRow("vehicle_licence_additional_increment") Is DBNull.Value Or _
    //           myDataRow("vehicle_licence_additional_amount") Is DBNull.Value Or _
    //           myDataRow("vehicle_licence_additional_increment") = 0 Or _
    //           myDataRow("vehicle_licence_additional_amount") = 0 Then
    //            myFee = 0
    //        Else
    //            myFee = myFee + myUtil.RoundUp((massNotCoveredByLegislation / _
    //                    myDataRow.Item("vehicle_licence_additional_increment"))) * _
    //                    myDataRow.Item("vehicle_licence_additional_amount")
    //        End If

    //        licenceDate = myDataRow("vehicle_licence_date")

    //End Select

    return licenceCategoryObject;
}

function getBodyLengthTooltipText(lengthType, cultureCode) {
    if (lengthType === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
        //return config.getTranslationText('1540');
        return config.getTranslationText('1540');
    }
    return config.getTooltipText('1784', cultureCode);
}

function getBodyHeightTooltipText(lengthType, cultureCode) {
    if (lengthType === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
        //return config.getTranslationText('1541');
        return config.getTranslationText('1541');
    }
    return config.getTooltipText('1795', cultureCode);
}

function getBodyWidthTooltipText(lengthType, cultureCode) {
    if (lengthType === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
        //return config.getTranslationText('1542');
        return config.getTranslationText('1542');
    }
    return config.getTooltipText('1801', cultureCode);
}

function calculateFinanceCostPerMonth(price, residualRate, interestRate, repaymentTerm) {
    var financeCostPerMonth = 0;

    if (interestRate > 0) {
        financeCostPerMonth = (((price - (price * residualRate / 100)) *
            (interestRate / 12 / 100) / (1 - Math.pow((1 + (interestRate / 12 / 100)), (repaymentTerm * -1))) +
            (price * residualRate / 100) * (interestRate / 12 / 100)));
    }

    if (interestRate === 0) {
        financeCostPerMonth = ((price - (price * residualRate / 100)) / repaymentTerm);
    }

    if (repaymentTerm === 0) {
        financeCostPerMonth = ((price - price * residualRate / 100));
    }
    return financeCostPerMonth;
}

function doesPathIncludeAxleGroupItemThatExistsOnParentChassis(path) {
    return path.includes(config.VALUE_TYPE.TAREFRONT)
        || path.includes(config.VALUE_TYPE.TAREREAR)
        || path.includes(config.VALUE_TYPE.TRAILERTAREFRONT)
        || path.includes(config.VALUE_TYPE.TRAILERTAREREAR)
        || path.includes(config.VALUE_TYPE.VEHICLETOTALPERMISSIBLE)
        || path.includes(config.VALUE_TYPE.COMBINATIONTOTALPERMISSIBLE);
}

function getWidthOfText(txt, fontname, fontsize, isBold) {
    // Create a dummy canvas (render invisible with css)
    var c = document.createElement('canvas');
    // Get the context of the dummy canvas
    var ctx = c.getContext('2d');
    // Set the context.font to the font that you are using
    if (isBold) {
        ctx.font = 'bold ' + fontsize + 'px ' + fontname;
    } else {
        ctx.font = fontsize + 'px ' + fontname;
    }

    // Measure the string 
    // !!! <CRUCIAL>  !!!
    var length = ctx.measureText(txt).width;
    // !!! </CRUCIAL> !!!
    // Return width
    return length;
}

function getWheelbaseItemToSortOn(specifyWheelbaseAs) {
    var specifyWheelbaseAsToUse = typeof specifyWheelbaseAs === 'string' ? specifyWheelbaseAs : globals.user.getSpecifyWheelbaseAs();
    switch (specifyWheelbaseAsToUse) {
        case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTER_OF_REAR_DRIVEN_AXLES:
            return 'WheelbaseFMSACRDA';
        case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTRE_OF_REAR_AXLES:
            return 'WheelbaseFMSACRA';
        case config.SPECIFY_WHEELBASE_AS_OPTIONS.CENTRE_OF_FRONT_AXLES_TO_CENTRE_OF_REAR_AXLES:
            return 'WheelbaseCFACRA';
        case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_FIRST_REAR_DRIVEN_AXLE:
            return 'WheelbaseFMSAFRDA';
        default:
            return 'WheelbaseTheoretical';
    }
}

function getCabToAxleItemToSortOn(specifyWheelbaseAs) {
    var specifyWheelbaseAsToUse = typeof specifyWheelbaseAs === 'string' ? specifyWheelbaseAs : globals.user.getSpecifyWheelbaseAs();
    switch (specifyWheelbaseAsToUse) {
        case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTER_OF_REAR_DRIVEN_AXLES:
            return 'CabToAxlesFMSACRDA';
        case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTRE_OF_REAR_AXLES:
            return 'CabToAxlesFMSACRA';
        case config.SPECIFY_WHEELBASE_AS_OPTIONS.CENTRE_OF_FRONT_AXLES_TO_CENTRE_OF_REAR_AXLES:
            return 'CabToAxlesCFACRA';
        case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_FIRST_REAR_DRIVEN_AXLE:
            return 'CabToAxlesFMSAFRDA';
        default:
            return 'CabToAxlesFMSACRDA';
    }
}

function getDefaultManufacturerSelectionWizardMultiSelectFilterObject() {
    return [{
        property: 'Status',
        // values: [config.getTranslationText('4930')]
        values: [config.VEHICLE_STATUS_TYPES.CURRENT]
    }];
}

function getUniqueObjectsFromArrayUsingProperty(array, property) {
    var unique = {};
    var distinct = [];
    for (var i in array) {
        if (typeof array[i][property] === "function") {
            if (typeof (unique[array[i][property]()]) == "undefined") {
                distinct.push(array[i]);
            }
            unique[array[i][property]()] = 0;
        } else {
            if (typeof (unique[array[i][property]]) == "undefined") {
                distinct.push(array[i]);
            }
            unique[array[i][property]] = 0;
        }
    }
    return distinct;
}

function getUniqueObjectsFromArrayUsingPropertyFunction(array, property) {
    var unique = {};
    var distinct = [];
    for (var i in array) {
        if (typeof (unique[array[i][property]()]) == "undefined") {
            distinct.push(array[i]);
        }
        unique[array[i][property]()] = 0;
    }
    return distinct;
}

function createDistinctArray(arrayToUse, propertyName, sortFunctionToUse, passInArrayToSortFunction, descriptionTextFunction) {
    var updateArray = [],
        defaultSortFunction = function (a, b) {
            var aValue = typeof a[propertyName] === 'function' ? a[propertyName]() : a[propertyName],
                bValue = typeof b[propertyName] === 'function' ? b[propertyName]() : b[propertyName];
            if (aValue > bValue) {
                return 1;
            } else if (aValue < bValue) {
                return -1;
            } else {
                return 0;
            }
        },
        passInArray = typeof passInArrayToSortFunction === 'boolean' ? passInArrayToSortFunction : false,
        sortFunction = typeof sortFunctionToUse === 'function' ? sortFunctionToUse : defaultSortFunction;
    // Get distinct objects
    var distinctObjects = getUniqueObjectsFromArrayUsingProperty(arrayToUse, propertyName);
    // sort distinctObjects alpabetically using propertyName
    if (passInArray) {
        distinctObjects = sortFunction(distinctObjects);
    } else {
        distinctObjects.sort(sortFunction);
    }

    // Create array of make object
    distinctObjects.forEach(function (element) {
        if (element[propertyName]) {
            var initialDescription = typeof element[propertyName] === 'function' ? element[propertyName]() : element[propertyName],
                descriptionText = typeof descriptionTextFunction === 'function' ? descriptionTextFunction(initialDescription) : initialDescription;
            if(typeof descriptionText === 'string') {
                updateArray.push({
                    // description: typeof element[propertyName] === 'function' ? element[propertyName]() : element[propertyName],
                    description: descriptionText,
                    value: typeof element[propertyName] === 'function' ? element[propertyName]() : element[propertyName]
                });
            }
            
        }
    });

    return updateArray;
}

function getOpenTsvModalOnSelection() {
    return openTsvModalOnSelection
}

function setOpenTsvModalOnSelection(newValue) {
    openTsvModalOnSelection = newValue;
}

/**
    * Returns unique legislation ID based on country ID and legislation ID
    * Any changes here should be reflected in EncodeUniqueLegislationId in Globals on Cloud Services
    * @param {number} countryId - ID of country
    * @param {number} legislationId - ID of legislation
    * @returns {number} - Returns unique legislation ID
    */
function encodeUniqueLegislationId(countryId, legislationId) {
    return countryId * 10000 + legislationId;
}

/**
    * Decodes unique legislation ID into the corresponding LegislationId and CountryId
    * Any changes here should be reflected in DecodeUniqueLegislationId in Globals on Cloud Services
    * @param {number} uniqueLegislationId - Legislation ID to be decoded
    * @returns {object} - Returns object containing LegislationId and CountryId
    */
function decodeUniqueLegislationId(uniqueLegislationId) {
    return {
        LegislationId: uniqueLegislationId % 10000,
        CountryId: parseInt(uniqueLegislationId / 10000)
    }
}

function sortLegislationsArray(arrayToSort, userCountryAbbreviation) {
    return arrayToSort.sort(function (a, b) {
        // Move unrestricted to the bottom
        if (a.countryId === 194) {
            return 1;
        }
        if (b.countryId === 194) {
            return -1;
        }

        // Move user's country to top
        if (a.countryAbbreviation === userCountryAbbreviation && b.countryAbbreviation !== userCountryAbbreviation) {
            return -1;
        }
        if (a.countryAbbreviation !== userCountryAbbreviation && b.countryAbbreviation === userCountryAbbreviation) {
            return 1;
        }

        if (a.countryId === b.countryId) {
            if (a.isDefault === true) {
                return -1;
            }

            if (b.isDefault === true) {
                return 1;
            }

            if (a.description < b.description) {
                return -1;
            }

            if (a.description > b.description) {
                return 1;
            }

            return 0;
        }

        return 0;
    });
}

/**
    * Takes in a list of legislation objects and returns a list of legislation stub objects
    * @param {object[]} legislationsArray - List of legislation objects. Typically offerManager.gerLegislations
    * @param {object[]} getLegislationStubFunction - Function to get legislation stub. Typically globals.user.getLegislationStubObjectUsingId
    * @returns {object[]} - Array of legislation stubs
    */
function getDistinctLegislationStubsArray(legislationsArray, getLegislationStubFunction) {
    var returnArray = [];

    legislationsArray.forEach(function (leg) {
        var doesItemAlreadyExistOnArray = returnArray.findIndex(function (check) {
            return check.id === leg.id;
        });
        if (doesItemAlreadyExistOnArray < 0) {
            returnArray.push(getLegislationStubFunction(leg.id));
        }
    });

    return returnArray;
}

/**
    * Function to sort dashboard data points array on user. Also used in settingsModal
    * @param {object} a - 1st object to compare
    * @param {object} b - 2nd object to compare
    * @returns {Number}
    */
function sortDataPointsArray(a, b) {
    if (a.description > b.description) {
        return 1;
    }
    if (a.description < b.description) {
        return -1;
    }
    return 0;
}

/**
    * Checks if item passed in argument is a valid object to be used by the app
    * @param {any} objToCheck
    * @returns {boolean} - True if valid, False if not
    */
function isObjectValid(objToCheck) {
    return typeof objToCheck === 'object' && objToCheck !== undefined && objToCheck !== null && objToCheck !== '';
}

/**
    * Used in notes and warnings modal and offerManager
    * @param {StringTrackerObject} stringObj - From offerManager
    * @returns {boolean} - True if not application and not application message else False
    */
function excludeApplicationItems(stringObj) {
    return stringObj.getId() !== config.NOTE_ID.APPLICATION && stringObj.getId() !== config.NOTE_ID.APPLICATION_MESSAGE;
}

/**
    * Used in notes and warnings modal and offerManager
    * @param {StringTrackerObject} stringObj - From offerManager
    * @returns {boolean} - True if application or application message else False
    */
function returnApplicationItemsOnly(stringObj) {
    return stringObj.getId() === config.NOTE_ID.APPLICATION || stringObj.getId() === config.NOTE_ID.APPLICATION_MESSAGE;
}

/**
    * From https://stackoverflow.com/questions/7342957/how-do-you-round-to-1-decimal-place-in-javascript
    * @param {number} input - Number to round
    * @param {number} digits - Number of decimal places to round to
    * @returns {number} Rounded number
    */
function roundedToFixed(input, digits) {
    var rounded = Math.pow(10, digits);
    return parseFloat((Math.round(input * rounded) / rounded).toFixed(digits));
}

function getMessageCodeForTrialUsers(userToCheck) {
    //var messageCode = !globals.user.isVipTrialUser() && globals.user.displayNteaOptionsToUser() ? '1566' : '1562';
    var messageCode = '1562';
    if (!userToCheck.isVipTrialUser() && userToCheck.displayNteaOptionsToUser()) {
        messageCode = '1566';
    } else if (!userToCheck.isVipTrialUser() && userToCheck.displaySalesToolOptionsToUser()) {
        if (userToCheck.getProduct() === config.APP_PRODUCT.AXLE_WEIGHT_CALCULATOR) {
            messageCode = '4483';
        }                 
    }
    return messageCode;
}

function showSpinner() {
    $('.spinner-page-splash').show();
    $('.spinner-page-splash-foreground').show();
    $('.spinner-message').show();
}

function hideSpinner() {
    if(!preventHideSpinner) {
        $('.spinner-page-splash').hide();
        $('.spinner-page-splash-foreground').hide();
        $('.spinner-message').hide();
    }
}

function setSpinnerVisible(setVisible) {
    if(setVisible) {
        showSpinner();
    }else {
        hideSpinner();
    }
}


/**
 * Add a new item to a shallowRef array and update UI/trigger watches
 * @param {ShallowRef} shallowRefArr - ShallowRef array to add items to and trigger update
 * @param {(any|any[])} newItems - Item or array of items to add to array 
 */
function addItemToShallowRefArray(shallowRefArr, newItems) {
    let newItemsToUse = Array.isArray(newItems) ? newItems : [newItems];
    shallowRefArr.value = [...shallowRefArr.value, ...newItemsToUse]
}

/**
 * Clears shallowRef array and adds item(s) to update UI
 * @param {ShallowRef} arr - ShallowRef array to add items to and trigger update
 * @param {(any|any[])} newItems - Item or array of items to add to array 
 */
function clearShallowRefArrayAndAddItems(shallowRefArr, newItems) {
    let newItemsToUse = Array.isArray(newItems) ? newItems : [newItems];
    shallowRefArr.value = [...[], ...newItemsToUse]
}

function getTrialStatusMessageAlreadyShownForOpenOffer() {
    return trialStatusMessageAlreadyShownForOpenOffer;
}

function setTrialStatusMessageAlreadyShownForOpenOffer(newValue) {
    trialStatusMessageAlreadyShownForOpenOffer = newValue;
}

/**
 * If user has specified fuel consumption as distance per volume, convert to fuel consumption per 100km
 * @param {number} fuelConsumptionValue - Fuel consumption value to convert
 * @param {string} specifyFuelConsumptionAsValue - Specify fuel consumption as. From Settings
 * @returns {number} - Converted fuel consumption value
 */
function getFuelConsumptionForMetricMeasurementSystem(fuelConsumptionValue, specifyFuelConsumptionAsValue) {
    if (specifyFuelConsumptionAsValue === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
        if(fuelConsumptionValue === 0) {
            return 0;
        }
        return 100 / fuelConsumptionValue;
    } else {
        return fuelConsumptionValue;
    }
}

/**
 * if user has specified fuel consumption as distance per volume, convert to fuel consumption per 100mi
 * @param {number} fuelConsumptionValue - Fuel consumption value to convert 
 * @param {object} measurementSystem - Measurement system to use
 * @param {string} specifyFuelConsumptionAsValue - Specify fuel consumption as. From Settings
 * @returns 
 */
function getFuelConsumptionForImperialMeasurementSystem(fuelConsumptionValue, measurementSystem, specifyFuelConsumptionAsValue) {
    var returnVal = 0,
        imperialMeasurementSystem = measurementSystem.id === config.MEASUREMENT_SYSTEM.IMPERIALUK ? getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUK) : getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUS);
    if(typeof fuelConsumptionValue === 'number' && fuelConsumptionValue > 0) {
        if (specifyFuelConsumptionAsValue === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
            // mpg
            returnVal = 100 / fuelConsumptionValue * imperialMeasurementSystem.distanceConversion / imperialMeasurementSystem.volumeConversion;
        } else {
            // gal/100mi
            returnVal = 100 / (100 / fuelConsumptionValue * imperialMeasurementSystem.distanceConversion / imperialMeasurementSystem.volumeConversion);
        }
    }
    
    return returnVal;
}
/**
 * Depending on measurement system, convert fuel price per volume to fuel price per 100km or fuel price per 100mi
 * @param {number} fuelPriceValue - Fuel price value to convert
 * @param {object} measurementSystem - Measurement system to use
 * @returns {number} - Converted fuel price value
 */
function getFuelPricePerVolumeConvertedUsingMeasurementSystem(fuelPriceValue, measurementSystem) {
    var returnVal = fuelPriceValue,
        imperialMeasurementSystem = measurementSystem.id === config.MEASUREMENT_SYSTEM.IMPERIALUK ? getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUK) : getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUS);
    if(measurementSystem.id === config.MEASUREMENT_SYSTEM.METRIC) {
        return returnVal;
    }
    if(typeof fuelPriceValue === 'number' && fuelPriceValue > 0) {
        returnVal = fuelPriceValue / imperialMeasurementSystem.volumeConversion;
    }
    
    return returnVal;
}

/**
 * Depending on measurement system, convert l/hr to gal/hr
 * @param {number} fuelPriceValue - Fuel price value to convert
 * @param {object} measurementSystem - Measurement system to use
 * @returns {number} - Converted fuel price value
 */
function getFuelPricePerHourConvertedUsingMeasurementSystem(fuelPriceValue, measurementSystem) {
    var returnVal = fuelPriceValue,
        imperialMeasurementSystem = measurementSystem.id === config.MEASUREMENT_SYSTEM.IMPERIALUK ? getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUK) : getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUS);
    if(measurementSystem.id === config.MEASUREMENT_SYSTEM.METRIC) {
        return returnVal;
    }
    if(typeof fuelPriceValue === 'number' && fuelPriceValue > 0) {
        returnVal = fuelPriceValue / imperialMeasurementSystem.volumeConversion;
    }
    
    return returnVal;
}

/**
 * Convert fuel consumption per volume (litre/gallon) to fuel consumption per 100 km/mi
 * @param {number} fuelConsumptionValue - Fuel consumption value to convert
 * @param {string} specifyFuelConsumptionAsValue - Specify fuel consumption as. From Settings
 * @returns {number} - Converted fuel consumption value
 */
function getFuelConsumptionForVolumePer100Distance(fuelConsumptionValue, specifyFuelConsumptionAsValue) {
    if (specifyFuelConsumptionAsValue === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
        if(fuelConsumptionValue === 0) {
            return 0;
        }
        // km/l or mi/gal
        return 100 / fuelConsumptionValue;
    } else {
        // l/100km or gal/100mi
        return fuelConsumptionValue;
    }
}

/**
 * Calculate fuel cost per month where fuel consumption is specified as litres per 100km/gallons per 100mi
 * @param {number} fuelPrice - Fuel price
 * @param {number} fuelConsumption - Fuel consumption as litres per 100km/gallons per 100mi
 * @param {number} distance - Monthly distance
 * @returns {number} - Fuel cost per month
 */
function getMonthlyFuelCostUsingFuelConsumptionPerDistance(fuelPrice, fuelConsumption, distance) {
    if (fuelPrice === 0 || fuelConsumption === 0 || distance === 0) {
        return 0;
    }
    // return fuelPrice * fuelConsumption * distance / 100;
    return distance / 100 * fuelConsumption * fuelPrice;
}

/**
 * Calculate fuel cost per month where fuel consumption is specified as litres per hour/gallons per hour
 * @param {number} fuelPrice - Fuel price
 * @param {number} fuelConsumptionPerHour - Fuel consumption as litres per hour/gallons per hour
 * @param {number} hoursInUse - Monthly distance
 * @returns {number} - Fuel cost per month
 */
function getMonthlyFuelCostUsingFuelConsumptionPerHour(fuelPrice, fuelConsumptionPerHour, hoursInUse) {
    if (fuelPrice === 0 || fuelConsumptionPerHour === 0 || hoursInUse === 0) {
        return 0;
    }
    return hoursInUse * fuelConsumptionPerHour * fuelPrice;
}

/**
 * 
 * @param {number} fuelConsumption 
 * @param {object} measurementSystem 
 * @param {string} specifyFuelConsumptionAs 
 * @returns {number}
 */
function getFuelConsumptionAsLitresPer100Km(fuelConsumption, measurementSystem, specifyFuelConsumptionAs) {
    if(fuelConsumption === 0) {
        return 0;
    }
    if (measurementSystem.id === config.MEASUREMENT_SYSTEM.METRIC) {
        if (specifyFuelConsumptionAs === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
            // km/l
            return 100 / globals.returnFloat(fuelConsumption);
        } else {
            // l/100km
            return globals.returnFloat(fuelConsumption);
        }
    } else {
        if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
            // mi/gal
            return 100 / (globals.returnFloat(fuelConsumption) / measurementSystem.distanceConversion * measurementSystem.volumeConversion);
        } else {
            // gal/100mi
            return 100 / (100 / (globals.returnFloat(fuelConsumption) * measurementSystem.distanceConversion / measurementSystem.volumeConversion));
        }
    }
}

/**
 * 
 * @param {number} fuelPrice 
 * @param {object} measurementSystem 
 * @returns {number}
 */
function getCostPerVolumeAsCostPerLitre(fuelPrice, measurementSystem) {
    return fuelPrice * measurementSystem.volumeConversion;
}

/**
 * 
 * @param {number} distance 
 * @param {object} measurementSystem 
 * @returns {number}
 */
function getMonthlyDistanceForMeasurementSystem(distance, measurementSystem) {    
    return globals.returnFloat(distance) * measurementSystem.distanceConversion;
}

function getFuelConsumptionPerHourAsLitresPerHour(fuelConsumption, measurementSystem) {
    return globals.returnFloat(fuelConsumption) * measurementSystem.volumeConversion;
}

function getConvertedConsumptionValue(consumptionValue, measurementSystem, specifyFuelConsumptionAs) {
    if (measurementSystem.id === config.MEASUREMENT_SYSTEM.METRIC) {
        return getFuelConsumptionForMetricMeasurementSystem(consumptionValue, specifyFuelConsumptionAs);
    } else {
        return getFuelConsumptionForImperialMeasurementSystem(consumptionValue, measurementSystem, specifyFuelConsumptionAs)
    }
}

function getFuelPricePerVolumeValue(fuelPriceValue, measurementSystem, currencySymbol, precision) {
    return measurementSystem.unitPricePerVolume.replace('$S1', currencySymbol).replace('$S2', Globalize.format(fuelPriceValue, "n" + precision));
}

/**
 * Get adblue cost per month value
 * @param {number} fuelConsumption - Fuel consumption from vehicle
 * @param {number} adblueConsumption - adblue consumption from vehicle as percentage of fuel consumption
 * @param {number} costPerVolume - Cost per volume of adblue
 * @param {number} utilisation - Utilisation of vehicle
 * @returns {number} - adblue cost per month value
 */
function getVehicleAdBlueCostPerMonthValue(fuelConsumption, adblueConsumption, costPerVolume, utilisation) {
    var consumptionValue = fuelConsumption * adblueConsumption / 100;
    return getMonthlyFuelCostUsingFuelConsumptionPerDistance(costPerVolume, consumptionValue, utilisation);
}

/**
 * 
 * @param {number} avgLifeOfTyresValue - Average life of tyres value
 * @param {object} measurementSystem - Measurement system
 * @returns {number} - Average life of tyres value
 */
function getAverageLifeOfTyresValueForMeasurementSystem(avgLifeOfTyresValue, measurementSystem) {
    return avgLifeOfTyresValue * measurementSystem.distanceConversion;
}

/**
 * Returns the fuel price value converted to the measurement system of the offer
 * @param {number} fuelPriceValue 
 * @param {object} measurementSystem
 * @returns {number} - The converted fuel price value
 */
function getConvertedFuelPriceValue(fuelPriceValue, measurementSystem) {
    return getFuelPricePerVolumeConvertedUsingMeasurementSystem(fuelPriceValue, measurementSystem)
}

/**
 * Returns the fuel price per hour value converted to the measurement system of the offer
 * @param {number} fuelPriceValue 
 * @param {object} measurementSystem
 * @returns {number} - The converted fuel price value
 */
function getConvertedFuelPricePerHourValue(fuelPriceValue, measurementSystem) {
    return globals.getFuelPricePerHourConvertedUsingMeasurementSystem(fuelPriceValue, measurementSystem)
}

/**
 * Function to get the value for GAWR for a given axle type
 * @param {number} manufacturerRating - Manufacturer rating for the given axle type
 * @param {number} suspensionMassRating - Suspension mass rating for the given axle type
 * @param {number} tyreMassRatingDual - Dual tyre mass rating for the given axle type
 * @param {number} tyreMassRatingSingle - Single tyre mass rating for the given axle type
 * @param {number} numberOfTyresOnAxle - Number of tyres on axle
 * @returns {number} - The value for GAWR for the given axle type
 */
function getGrossAxleWeightRatingDefaultValue(manufacturerRating, suspensionMassRating, tyreMassRatingDual, tyreMassRatingSingle, numberOfTyresOnAxle) {
    var returnValue = manufacturerRating,
        tyreMassToUse = numberOfTyresOnAxle === 2 ? tyreMassRatingSingle : tyreMassRatingDual;

    if (returnValue > suspensionMassRating) {
        returnValue = suspensionMassRating;
    }

    if (returnValue > tyreMassToUse) {
        returnValue = tyreMassToUse;
    }
    return returnValue;
}

/**
 * Returns the net price
 * @param {number} listPrice - List price
 * @param {number} discount - Discount percentage
 * @returns {number} - The net price
 */
function getNetPrice(listPrice, discount) {
    var discountValue = 0;
    if (discount > 0) {
        discountValue = (listPrice / 100) * discount;
    }
    return listPrice - discountValue;
}

function getManufacturerTextIncludeInMessageObject(availableStatuses, checkedValues) {
    var returnValue = {
        includeCurrentVehiclesInMessage: false,
        includeDiscontinuedVehiclesInMessage: false,
        includePrototypeVehiclesInMessage: false,
        includeSpecialVehiclesInMessage: false
    };

    if(Array.isArray(availableStatuses) === false || Array.isArray(checkedValues) === false) {
        return returnValue;
    }
    // Get a list of unchecked statuses
    var uncheckedStatuses = availableStatuses.filter(function(statusObj) {
        return !checkedValues.includes(statusObj.value);
    });

    if (availableStatuses.some(statusObj => statusObj.value === config.VEHICLE_STATUS_TYPES.CURRENT) &&
        uncheckedStatuses.some(statusObj => statusObj.value === config.VEHICLE_STATUS_TYPES.CURRENT)) {
            returnValue.includeCurrentVehiclesInMessage = true;
    }

    if (availableStatuses.some(statusObj => statusObj.value === config.VEHICLE_STATUS_TYPES.DISCONTINUED) &&
        uncheckedStatuses.some(statusObj => statusObj.value === config.VEHICLE_STATUS_TYPES.DISCONTINUED)) {
            returnValue.includeDiscontinuedVehiclesInMessage = true;
    }

    if (availableStatuses.some(statusObj => statusObj.value === config.VEHICLE_STATUS_TYPES.PROTOTYPE) &&
        uncheckedStatuses.some(statusObj => statusObj.value === config.VEHICLE_STATUS_TYPES.PROTOTYPE)) {
            returnValue.includePrototypeVehiclesInMessage = true;
    }

    if (availableStatuses.some(statusObj => statusObj.value === config.VEHICLE_STATUS_TYPES.SPECIAL) &&
        uncheckedStatuses.some(statusObj => statusObj.value === config.VEHICLE_STATUS_TYPES.SPECIAL)) {
            returnValue.includeSpecialVehiclesInMessage = true;
    }

    return returnValue;
}

function getManufacturerRequestItemTooltipTextCode(availableStatuses, checkedValues) {
    var returnValue = '4825',
        includeInMessageObject = getManufacturerTextIncludeInMessageObject(availableStatuses, checkedValues);

    if(includeInMessageObject.includeCurrentVehiclesInMessage) {
        if(includeInMessageObject.includeDiscontinuedVehiclesInMessage) {
            if(includeInMessageObject.includePrototypeVehiclesInMessage) {
                if(includeInMessageObject.includeSpecialVehiclesInMessage) {
                    // Current, discontinued, prototype and special
                    returnValue = '4826';
                } else {
                    // current, discontinued and prototype
                    returnValue = '4827';
                }
            } else if(includeInMessageObject.includeSpecialVehiclesInMessage) {
                // current, discontinued and special are available
                returnValue = '4828';
            } else {
                // current and discontinued
                returnValue = '4829';
            }
        } else if(includeInMessageObject.includePrototypeVehiclesInMessage) {
            if(includeInMessageObject.includeSpecialVehiclesInMessage) {
                // current, prototype and special are available
                returnValue = '4830';
            } else {
                // current and prototype
                returnValue = '4831';
            }
        } else if(includeInMessageObject.includeSpecialVehiclesInMessage) {
            // current and special are available
            returnValue = '4832';
        } else {
            // Current only
            returnValue = '4833';
        }
    } else if(includeInMessageObject.includeDiscontinuedVehiclesInMessage) {
        if(includeInMessageObject.includePrototypeVehiclesInMessage) {
            if(includeInMessageObject.includeSpecialVehiclesInMessage) {
                // discontinued, prototype and special are available
                returnValue = '4834';
            } else {
                // discontinued and prototype are available
                returnValue = '4835';
            }
        } else if(includeInMessageObject.includeSpecialVehiclesInMessage) {
            // discontinued and special are available
            returnValue = '4836';
        } else {
            // discontinued only
            returnValue = '4837';
        }
    } else if(includeInMessageObject.includePrototypeVehiclesInMessage) {
        if(includeInMessageObject.includeSpecialVehiclesInMessage) {
            // prototype and special are available
            returnValue = '4838';
        } else {
            // prototype only
            returnValue = '4839';
        }
    } else if(includeInMessageObject.includeSpecialVehiclesInMessage){
        // special are available
        returnValue = '4840';
    } else {
        // all statuses are available
        returnValue = '4825';
    }

    return returnValue;
}

/**
 * Checks if all the characters in the string are all uppercase
 * @param {string} stringToCheck 
 * @returns {boolean}
 */
function checkIfStringIsUpperCase(stringToCheck) {
    return stringToCheck === stringToCheck.toUpperCase();
}

/**
 * Checks if all the characters in the string are all lowercase
 * @param {string} stringToCheck 
 * @returns {boolean}
 */
function checkIfStringIsLowerCase(stringToCheck) {
    return stringToCheck === stringToCheck.toLowerCase();
}

function getAvailableCountriesList() {
    return [
        {
            "Abbreviation": "AF",
            "Name": config.getTranslationText('4982'),
            "disable": false
        },
        {
            "Abbreviation": "AX",
            "Name": config.getTranslationText('4983'),
            "disable": false
        },
        {
            "Abbreviation": "AL",
            "Name": config.getTranslationText('4984'),
            "disable": false
        },
        {
            "Abbreviation": "DZ",
            "Name": config.getTranslationText('4985'),
            "disable": false
        },
        {
            "Abbreviation": "AS",
            "Name": config.getTranslationText('4986'),
            "disable": false
        },
        {
            "Abbreviation": "AD",
            "Name": config.getTranslationText('4987'),
            "disable": false
        },
        {
            "Abbreviation": "AO",
            "Name": config.getTranslationText('4988'),
            "disable": false
        },
        {
            "Abbreviation": "AI",
            "Name": config.getTranslationText('4989'),
            "disable": false
        },
        {
            "Abbreviation": "AQ",
            "Name": config.getTranslationText('4990'),
            "disable": false
        },
        {
            "Abbreviation": "AG",
            "Name": config.getTranslationText('4991'),
            "disable": false
        },
        {
            "Abbreviation": "AR",
            "Name": config.getTranslationText('4992'),
            "disable": false
        },
        {
            "Abbreviation": "AM",
            "Name": config.getTranslationText('4993'),
            "disable": false
        },
        {
            "Abbreviation": "AW",
            "Name": config.getTranslationText('4994'),
            "disable": false
        },
        {
            "Abbreviation": "AU",
            "Name": config.getTranslationText('4995'),
            "disable": false
        },
        {
            "Abbreviation": "AT",
            "Name": config.getTranslationText('4996'),
            "disable": false
        },
        {
            "Abbreviation": "AZ",
            "Name": config.getTranslationText('4997'),
            "disable": false
        },
        {
            "Abbreviation": "BS",
            "Name": config.getTranslationText('4998'),
            "disable": false
        },
        {
            "Abbreviation": "BH",
            "Name": config.getTranslationText('4999'),
            "disable": false
        },
        {
            "Abbreviation": "BD",
            "Name": config.getTranslationText('5000'),
            "disable": false
        },
        {
            "Abbreviation": "BB",
            "Name": config.getTranslationText('5001'),
            "disable": false
        },
        {
            "Abbreviation": "BY",
            "Name": config.getTranslationText('5002'),
            "disable": false
        },
        {
            "Abbreviation": "BE",
            "Name": config.getTranslationText('5003'),
            "disable": false
        },
        {
            "Abbreviation": "BZ",
            "Name": config.getTranslationText('5004'),
            "disable": false
        },
        {
            "Abbreviation": "BJ",
            "Name": config.getTranslationText('5005'),
            "disable": false
        },
        {
            "Abbreviation": "BM",
            "Name": config.getTranslationText('5006'),
            "disable": false
        },
        {
            "Abbreviation": "BT",
            "Name": config.getTranslationText('5007'),
            "disable": false
        },
        {
            "Abbreviation": "BO",
            "Name": config.getTranslationText('5008'),
            "disable": false
        },
        {
            "Abbreviation": "BA",
            "Name": config.getTranslationText('5009'),
            "disable": false
        },
        {
            "Abbreviation": "BW",
            "Name": config.getTranslationText('5010'),
            "disable": false
        },
        {
            "Abbreviation": "BV",
            "Name": config.getTranslationText('5011'),
            "disable": false
        },
        {
            "Abbreviation": "BR",
            "Name": config.getTranslationText('5012'),
            "disable": false
        },
        {
            "Abbreviation": "BQ",
            "Name": config.getTranslationText('5013'),
            "disable": false
        },
        {
            "Abbreviation": "IO",
            "Name": config.getTranslationText('5014'),
            "disable": false
        },
        {
            "Abbreviation": "VG",
            "Name": config.getTranslationText('5015'),
            "disable": false
        },
        {
            "Abbreviation": "BN",
            "Name": config.getTranslationText('5016'),
            "disable": false
        },
        {
            "Abbreviation": "BG",
            "Name": config.getTranslationText('5017'),
            "disable": false
        },
        {
            "Abbreviation": "BF",
            "Name": config.getTranslationText('5018'),
            "disable": false
        },
        {
            "Abbreviation": "BI",
            "Name": config.getTranslationText('5019'),
            "disable": false
        },
        {
            "Abbreviation": "KH",
            "Name": config.getTranslationText('5020'),
            "disable": false
        },
        {
            "Abbreviation": "CM",
            "Name": config.getTranslationText('5021'),
            "disable": false
        },
        {
            "Abbreviation": "CA",
            "Name": config.getTranslationText('5022'),
            "disable": false
        },
        {
            "Abbreviation": "CT",
            "Name": config.getTranslationText('5023'),
            "disable": false
        },
        {
            "Abbreviation": "CV",
            "Name": config.getTranslationText('5024'),
            "disable": false
        },
        {
            "Abbreviation": "KY",
            "Name": config.getTranslationText('5025'),
            "disable": false
        },
        {
            "Abbreviation": "CF",
            "Name": config.getTranslationText('5026'),
            "disable": false
        },
        {
            "Abbreviation": "TD",
            "Name": config.getTranslationText('5027'),
            "disable": false
        },
        {
            "Abbreviation": "CL",
            "Name": config.getTranslationText('5028'),
            "disable": false
        },
        {
            "Abbreviation": "CN",
            "Name": config.getTranslationText('5029'),
            "disable": false
        },
        {
            "Abbreviation": "CX",
            "Name": config.getTranslationText('5030'),
            "disable": false
        },
        {
            "Abbreviation": "CC",
            "Name": config.getTranslationText('5031'),
            "disable": false
        },
        {
            "Abbreviation": "CO",
            "Name": config.getTranslationText('5032'),
            "disable": false
        },
        {
            "Abbreviation": "KM",
            "Name": config.getTranslationText('5033'),
            "disable": false
        },
        {
            "Abbreviation": "CG",
            "Name": config.getTranslationText('5034'),
            "disable": false
        },
        {
            "Abbreviation": "CD",
            "Name": config.getTranslationText('5035'),
            "disable": false
        },
        {
            "Abbreviation": "CK",
            "Name": config.getTranslationText('5036'),
            "disable": false
        },
        {
            "Abbreviation": "CR",
            "Name": config.getTranslationText('5037'),
            "disable": false
        },
        {
            "Abbreviation": "CI",
            "Name": config.getTranslationText('5038'),
            "disable": false
        },
        {
            "Abbreviation": "HR",
            "Name": config.getTranslationText('5039'),
            "disable": false
        },
        {
            "Abbreviation": "CU",
            "Name": config.getTranslationText('5040'),
            "disable": false
        },
        {
            "Abbreviation": "CY",
            "Name": config.getTranslationText('5041'),
            "disable": false
        },
        {
            "Abbreviation": "CZ",
            "Name": config.getTranslationText('5042'),
            "disable": false
        },
        {
            "Abbreviation": "DK",
            "Name": config.getTranslationText('5043'),
            "disable": false
        },
        {
            "Abbreviation": "DJ",
            "Name": config.getTranslationText('5044'),
            "disable": false
        },
        {
            "Abbreviation": "DM",
            "Name": config.getTranslationText('5045'),
            "disable": false
        },
        {
            "Abbreviation": "DO",
            "Name": config.getTranslationText('5046'),
            "disable": false
        },
        {
            "Abbreviation": "NQ",
            "Name": config.getTranslationText('5047'),
            "disable": false
        },
        {
            "Abbreviation": "EC",
            "Name": config.getTranslationText('5048'),
            "disable": false
        },
        {
            "Abbreviation": "EG",
            "Name": config.getTranslationText('5049'),
            "disable": false
        },
        {
            "Abbreviation": "SV",
            "Name": config.getTranslationText('5050'),
            "disable": false
        },
        {
            "Abbreviation": "GQ",
            "Name": config.getTranslationText('5051'),
            "disable": false
        },
        {
            "Abbreviation": "ER",
            "Name": config.getTranslationText('5052'),
            "disable": false
        },
        {
            "Abbreviation": "EE",
            "Name": config.getTranslationText('5053'),
            "disable": false
        },
        {
            "Abbreviation": "ET",
            "Name": config.getTranslationText('5054'),
            "disable": false
        },
        {
            "Abbreviation": "FK",
            "Name": config.getTranslationText('5055'),
            "disable": false
        },
        {
            "Abbreviation": "FO",
            "Name": config.getTranslationText('5056'),
            "disable": false
        },
        {
            "Abbreviation": "FJ",
            "Name": config.getTranslationText('5057'),
            "disable": false
        },
        {
            "Abbreviation": "FI",
            "Name": config.getTranslationText('5058'),
            "disable": false
        },
        {
            "Abbreviation": "FR",
            "Name": config.getTranslationText('5059'),
            "disable": false
        },
        {
            "Abbreviation": "GF",
            "Name": config.getTranslationText('5060'),
            "disable": false
        },
        {
            "Abbreviation": "PF",
            "Name": config.getTranslationText('5061'),
            "disable": false
        },
        {
            "Abbreviation": "FQ",
            "Name": config.getTranslationText('5062'),
            "disable": false
        },
        {
            "Abbreviation": "TF",
            "Name": config.getTranslationText('5063'),
            "disable": false
        },
        {
            "Abbreviation": "GA",
            "Name": config.getTranslationText('5064'),
            "disable": false
        },
        {
            "Abbreviation": "GM",
            "Name": config.getTranslationText('5065'),
            "disable": false
        },
        {
            "Abbreviation": "GE",
            "Name": config.getTranslationText('5066'),
            "disable": false
        },
        {
            "Abbreviation": "DE",
            "Name": config.getTranslationText('5067'),
            "disable": false
        },
        {
            "Abbreviation": "GH",
            "Name": config.getTranslationText('5068'),
            "disable": false
        },
        {
            "Abbreviation": "GI",
            "Name": config.getTranslationText('5069'),
            "disable": false
        },
        {
            "Abbreviation": "GR",
            "Name": config.getTranslationText('5070'),
            "disable": false
        },
        {
            "Abbreviation": "GL",
            "Name": config.getTranslationText('5071'),
            "disable": false
        },
        {
            "Abbreviation": "GD",
            "Name": config.getTranslationText('5072'),
            "disable": false
        },
        {
            "Abbreviation": "GP",
            "Name": config.getTranslationText('5073'),
            "disable": false
        },
        {
            "Abbreviation": "GU",
            "Name": config.getTranslationText('5074'),
            "disable": false
        },
        {
            "Abbreviation": "GT",
            "Name": config.getTranslationText('5075'),
            "disable": false
        },
        {
            "Abbreviation": "GG",
            "Name": config.getTranslationText('5076'),
            "disable": false
        },
        {
            "Abbreviation": "GN",
            "Name": config.getTranslationText('5077'),
            "disable": false
        },
        {
            "Abbreviation": "GW",
            "Name": config.getTranslationText('5078'),
            "disable": false
        },
        {
            "Abbreviation": "GY",
            "Name": config.getTranslationText('5079'),
            "disable": false
        },
        {
            "Abbreviation": "HT",
            "Name": config.getTranslationText('5080'),
            "disable": false
        },
        {
            "Abbreviation": "HM",
            "Name": config.getTranslationText('5081'),
            "disable": false
        },
        {
            "Abbreviation": "VA",
            "Name": config.getTranslationText('5082'),
            "disable": false
        },
        {
            "Abbreviation": "HN",
            "Name": config.getTranslationText('5083'),
            "disable": false
        },
        {
            "Abbreviation": "HK",
            "Name": config.getTranslationText('5084'),
            "disable": false
        },
        {
            "Abbreviation": "HU",
            "Name": config.getTranslationText('5085'),
            "disable": false
        },
        {
            "Abbreviation": "IS",
            "Name": config.getTranslationText('5086'),
            "disable": false
        },
        {
            "Abbreviation": "IN",
            "Name": config.getTranslationText('5087'),
            "disable": false
        },
        {
            "Abbreviation": "ID",
            "Name": config.getTranslationText('5088'),
            "disable": false
        },
        {
            "Abbreviation": "IR",
            "Name": config.getTranslationText('5089'),
            "disable": false
        },
        {
            "Abbreviation": "IQ",
            "Name": config.getTranslationText('5090'),
            "disable": false
        },
        {
            "Abbreviation": "IE",
            "Name": config.getTranslationText('5091'),
            "disable": false
        },
        {
            "Abbreviation": "IM",
            "Name": config.getTranslationText('5092'),
            "disable": false
        },
        {
            "Abbreviation": "IL",
            "Name": config.getTranslationText('5093'),
            "disable": false
        },
        {
            "Abbreviation": "IT",
            "Name":  config.getTranslationText('5094'),
            "disable": false
        },
        {
            "Abbreviation": "JM",
            "Name": config.getTranslationText('5095'),
            "disable": false
        },
        {
            "Abbreviation": "JP",
            "Name": config.getTranslationText('5096'),
            "disable": false
        },
        {
            "Abbreviation": "JE",
            "Name": config.getTranslationText('5097'),
            "disable": false
        },
        {
            "Abbreviation": "JT",
            "Name": config.getTranslationText('5098'),
            "disable": false
        },
        {
            "Abbreviation": "JO",
            "Name": config.getTranslationText('5099'),
            "disable": false
        },
        {
            "Abbreviation": "KZ",
            "Name": config.getTranslationText('5100'),
            "disable": false
        },
        {
            "Abbreviation": "KE",
            "Name": config.getTranslationText('5101'),
            "disable": false
        },
        {
            "Abbreviation": "KI",
            "Name": config.getTranslationText('5102'),
            "disable": false
        },
        {
            "Abbreviation": "KP",
            "Name": config.getTranslationText('5103'),
            "disable": false
        },
        {
            "Abbreviation": "KR",
            "Name": config.getTranslationText('5104'),
            "disable": false
        },
        {
            "Abbreviation": "KW",
            "Name": config.getTranslationText('5105'),
            "disable": false
        },
        {
            "Abbreviation": "KG",
            "Name": config.getTranslationText('5106'),
            "disable": false
        },
        {
            "Abbreviation": "LA",
            "Name": config.getTranslationText('5107'),
            "disable": false
        },
        {
            "Abbreviation": "LV",
            "Name": config.getTranslationText('5108'),
            "disable": false
        },
        {
            "Abbreviation": "LB",
            "Name": config.getTranslationText('5109'),
            "disable": false
        },
        {
            "Abbreviation": "LS",
            "Name": config.getTranslationText('5110'),
            "disable": false
        },
        {
            "Abbreviation": "LR",
            "Name": config.getTranslationText('5111'),
            "disable": false
        },
        {
            "Abbreviation": "LY",
            "Name": config.getTranslationText('5112'),
            "disable": false
        },
        {
            "Abbreviation": "LI",
            "Name": config.getTranslationText('5113'),
            "disable": false
        },
        {
            "Abbreviation": "LT",
            "Name": config.getTranslationText('5114'),
            "disable": false
        },
        {
            "Abbreviation": "LU",
            "Name": config.getTranslationText('5115'),
            "disable": false
        },
        {
            "Abbreviation": "MO",
            "Name": config.getTranslationText('5116'),
            "disable": false
        },
        {
            "Abbreviation": "MK",
            "Name": config.getTranslationText('5117'),
            "disable": false
        },
        {
            "Abbreviation": "MG",
            "Name": config.getTranslationText('5118'),
            "disable": false
        },
        {
            "Abbreviation": "MW",
            "Name": config.getTranslationText('5119'),
            "disable": false
        },
        {
            "Abbreviation": "MY",
            "Name": config.getTranslationText('5120'),
            "disable": false
        },
        {
            "Abbreviation": "MV",
            "Name": config.getTranslationText('5121'),
            "disable": false
        },
        {
            "Abbreviation": "ML",
            "Name": config.getTranslationText('5122'),
            "disable": false
        },
        {
            "Abbreviation": "MT",
            "Name": config.getTranslationText('5123'),
            "disable": false
        },
        {
            "Abbreviation": "MH",
            "Name": config.getTranslationText('5124'),
            "disable": false
        },
        {
            "Abbreviation": "MR",
            "Name": config.getTranslationText('5125'),
            "disable": false
        },
        {
            "Abbreviation": "MU",
            "Name": config.getTranslationText('5126'),
            "disable": false
        },
        {
            "Abbreviation": "YT",
            "Name": config.getTranslationText('5127'),
            "disable": false
        },
        {
            "Abbreviation": "FX",
            "Name": config.getTranslationText('5128'),
            "disable": false
        },
        {
            "Abbreviation": "MX",
            "Name": config.getTranslationText('5129'),
            "disable": false
        },
        {
            "Abbreviation": "FM",
            "Name": config.getTranslationText('5130'),
            "disable": false
        },
        {
            "Abbreviation": "MI",
            "Name": config.getTranslationText('5131'),
            "disable": false
        },
        {
            "Abbreviation": "MD",
            "Name": config.getTranslationText('5132'),
            "disable": false
        },
        {
            "Abbreviation": "MC",
            "Name": config.getTranslationText('5133'),
            "disable": false
        },
        {
            "Abbreviation": "MN",
            "Name": config.getTranslationText('5134'),
            "disable": false
        },
        {
            "Abbreviation": "ME",
            "Name": config.getTranslationText('5135'),
            "disable": false
        },
        {
            "Abbreviation": "MS",
            "Name": config.getTranslationText('5136'),
            "disable": false
        },
        {
            "Abbreviation": "MA",
            "Name": config.getTranslationText('5137'),
            "disable": false
        },
        {
            "Abbreviation": "MZ",
            "Name": config.getTranslationText('5138'),
            "disable": false
        },
        {
            "Abbreviation": "MM",
            "Name": config.getTranslationText('5139'),
            "disable": false
        },
        {
            "Abbreviation": "NA",
            "Name": config.getTranslationText('5140'),
            "disable": false
        },
        {
            "Abbreviation": "NR",
            "Name": config.getTranslationText('5141'),
            "disable": false
        },
        {
            "Abbreviation": "NP",
            "Name": config.getTranslationText('5142'),
            "disable": false
        },
        {
            "Abbreviation": "NL",
            "Name": config.getTranslationText('5143'),
            "disable": false
        },
        {
            "Abbreviation": "AN",
            "Name": config.getTranslationText('5144'),
            "disable": false
        },
        {
            "Abbreviation": "NT",
            "Name": config.getTranslationText('5145'),
            "disable": false
        },
        {
            "Abbreviation": "NC",
            "Name": config.getTranslationText('5146'),
            "disable": false
        },
        {
            "Abbreviation": "NZ",
            "Name": config.getTranslationText('5147'),
            "disable": false
        },
        {
            "Abbreviation": "NI",
            "Name": config.getTranslationText('5148'),
            "disable": false
        },
        {
            "Abbreviation": "NE",
            "Name": config.getTranslationText('5149'),
            "disable": false
        },
        {
            "Abbreviation": "NG",
            "Name": config.getTranslationText('5150'),
            "disable": false
        },
        {
            "Abbreviation": "NU",
            "Name": config.getTranslationText('5151'),
            "disable": false
        },
        {
            "Abbreviation": "NF",
            "Name": config.getTranslationText('5152'),
            "disable": false
        },
        {
            "Abbreviation": "VD",
            "Name": config.getTranslationText('5153'),
            "disable": false
        },
        {
            "Abbreviation": "MP",
            "Name": config.getTranslationText('5154'),
            "disable": false
        },
        {
            "Abbreviation": "NO",
            "Name": config.getTranslationText('5155'),
            "disable": false
        },
        {
            "Abbreviation": "OM",
            "Name": config.getTranslationText('5156'),
            "disable": false
        },
        {
            "Abbreviation": "PC",
            "Name": config.getTranslationText('5157'),
            "disable": false
        },
        {
            "Abbreviation": "PK",
            "Name": config.getTranslationText('5158'),
            "disable": false
        },
        {
            "Abbreviation": "PW",
            "Name": config.getTranslationText('5159'),
            "disable": false
        },
        {
            "Abbreviation": "PS",
            "Name": config.getTranslationText('5160'),
            "disable": false
        },
        {
            "Abbreviation": "PA",
            "Name": config.getTranslationText('5161'),
            "disable": false
        },
        {
            "Abbreviation": "PZ",
            "Name": config.getTranslationText('5162'),
            "disable": false
        },
        {
            "Abbreviation": "PG",
            "Name": config.getTranslationText('5163'),
            "disable": false
        },
        {
            "Abbreviation": "PY",
            "Name": config.getTranslationText('5164'),
            "disable": false
        },
        {
            "Abbreviation": "YD",
            "Name": config.getTranslationText('5165'),
            "disable": false
        },
        {
            "Abbreviation": "PE",
            "Name": config.getTranslationText('5166'),
            "disable": false
        },
        {
            "Abbreviation": "PH",
            "Name": config.getTranslationText('5167'),
            "disable": false
        },
        {
            "Abbreviation": "PN",
            "Name": config.getTranslationText('5168'),
            "disable": false
        },
        {
            "Abbreviation": "PL",
            "Name": config.getTranslationText('5169'),
            "disable": false
        },
        {
            "Abbreviation": "PT",
            "Name": config.getTranslationText('5170'),
            "disable": false
        },
        {
            "Abbreviation": "PR",
            "Name": config.getTranslationText('5171'),
            "disable": false
        },
        {
            "Abbreviation": "QA",
            "Name": config.getTranslationText('5172'),
            "disable": false
        },
        {
            "Abbreviation": "RE",
            "Name": config.getTranslationText('5173'),
            "disable": false
        },
        {
            "Abbreviation": "RO",
            "Name": config.getTranslationText('5174'),
            "disable": false
        },
        {
            "Abbreviation": "RU",
            "Name": config.getTranslationText('5175'),
            "disable": false
        },
        {
            "Abbreviation": "RW",
            "Name": config.getTranslationText('5176'),
            "disable": false
        },
        {
            "Abbreviation": "BL",
            "Name": config.getTranslationText('5177'),
            "disable": false
        },
        {
            "Abbreviation": "SH",
            "Name": config.getTranslationText('5178'),
            "disable": false
        },
        {
            "Abbreviation": "KN",
            "Name": config.getTranslationText('5179'),
            "disable": false
        },
        {
            "Abbreviation": "LC",
            "Name": config.getTranslationText('5180'),
            "disable": false
        },
        {
            "Abbreviation": "MF",
            "Name": config.getTranslationText('5181'),
            "disable": false
        },
        {
            "Abbreviation": "VC",
            "Name": config.getTranslationText('5182'),
            "disable": false
        },
        {
            "Abbreviation": "WS",
            "Name": config.getTranslationText('5183'),
            "disable": false
        },
        {
            "Abbreviation": "SM",
            "Name": config.getTranslationText('5184'),
            "disable": false
        },
        {
            "Abbreviation": "ST",
            "Name": config.getTranslationText('5185'),
            "disable": false
        },
        {
            "Abbreviation": "SA",
            "Name": config.getTranslationText('5186'),
            "disable": false
        },
        {
            "Abbreviation": "SN",
            "Name": config.getTranslationText('5187'),
            "disable": false
        },
        {
            "Abbreviation": "RS",
            "Name": config.getTranslationText('5188'),
            "disable": false
        },
        {
            "Abbreviation": "CS",
            "Name": config.getTranslationText('5189'),
            "disable": false
        },
        {
            "Abbreviation": "SC",
            "Name": config.getTranslationText('5190'),
            "disable": false
        },
        {
            "Abbreviation": "SL",
            "Name": config.getTranslationText('5191'),
            "disable": false
        },
        {
            "Abbreviation": "SG",
            "Name": config.getTranslationText('5192'),
            "disable": false
        },
        {
            "Abbreviation": "SK",
            "Name": config.getTranslationText('5193'),
            "disable": false
        },
        {
            "Abbreviation": "SI",
            "Name": config.getTranslationText('5194'),
            "disable": false
        },
        {
            "Abbreviation": "SB",
            "Name": config.getTranslationText('5195'),
            "disable": false
        },
        {
            "Abbreviation": "SO",
            "Name": config.getTranslationText('5196'),
            "disable": false
        },
        {
            "Abbreviation": "ZA",
            "Name": config.getTranslationText('5197'),
            "disable": false
        },
        {
            "Abbreviation": "GS",
            "Name": config.getTranslationText('5198'),
            "disable": false
        },
        {
            "Abbreviation": "ES",
            "Name": config.getTranslationText('5199'),
            "disable": false
        },
        {
            "Abbreviation": "LK",
            "Name": config.getTranslationText('5200'),
            "disable": false
        },
        {
            "Abbreviation": "SD",
            "Name": config.getTranslationText('5201'),
            "disable": false
        },
        {
            "Abbreviation": "SR",
            "Name": config.getTranslationText('5202'),
            "disable": false
        },
        {
            "Abbreviation": "SJ",
            "Name": config.getTranslationText('5203'),
            "disable": false
        },
        {
            "Abbreviation": "SZ",
            "Name": config.getTranslationText('5204'),
            "disable": false
        },
        {
            "Abbreviation": "SE",
            "Name": config.getTranslationText('5205'),
            "disable": false
        },
        {
            "Abbreviation": "CH",
            "Name": config.getTranslationText('5206'),
            "disable": false
        },
        {
            "Abbreviation": "SY",
            "Name": config.getTranslationText('5207'),
            "disable": false
        },
        {
            "Abbreviation": "TW",
            "Name": config.getTranslationText('5208'),
            "disable": false
        },
        {
            "Abbreviation": "TJ",
            "Name": config.getTranslationText('5209'),
            "disable": false
        },
        {
            "Abbreviation": "TZ",
            "Name": config.getTranslationText('5210'),
            "disable": false
        },
        {
            "Abbreviation": "TH",
            "Name": config.getTranslationText('5211'),
            "disable": false
        },
        {
            "Abbreviation": "TL",
            "Name": config.getTranslationText('5212'),
            "disable": false
        },
        {
            "Abbreviation": "TG",
            "Name": config.getTranslationText('5213'),
            "disable": false
        },
        {
            "Abbreviation": "TO",
            "Name": config.getTranslationText('5214'),
            "disable": false
        },
        {
            "Abbreviation": "TT",
            "Name": config.getTranslationText('5215'),
            "disable": false
        },
        {
            "Abbreviation": "TN",
            "Name": config.getTranslationText('5216'),
            "disable": false
        },
        {
            "Abbreviation": "TR",
            "Name": config.getTranslationText('5217'),
            "disable": false
        },
        {
            "Abbreviation": "TM",
            "Name": config.getTranslationText('5218'),
            "disable": false
        },
        {
            "Abbreviation": "TC",
            "Name": config.getTranslationText('5219'),
            "disable": false
        },
        {
            "Abbreviation": "TV",
            "Name": config.getTranslationText('5220'),
            "disable": false
        },
        {
            "Abbreviation": "UG",
            "Name": config.getTranslationText('5221'),
            "disable": false
        },
        {
            "Abbreviation": "UA",
            "Name": config.getTranslationText('5222'),
            "disable": false
        },
        {
            "Abbreviation": "AE",
            "Name": config.getTranslationText('5223'),
            "disable": false
        },
        {
            "Abbreviation": "GB",
            "Name": config.getTranslationText('5224'),
            "disable": false
        },
        {
            "Abbreviation": "US",
            "Name": config.getTranslationText('5225'),
            "disable": false
        },
        {
            "Abbreviation": "UY",
            "Name": config.getTranslationText('5226'),
            "disable": false
        },
        {
            "Abbreviation": "PU",
            "Name": config.getTranslationText('5227'),
            "disable": false
        },
        {
            "Abbreviation": "VI",
            "Name": config.getTranslationText('5228'),
            "disable": false
        },
        {
            "Abbreviation": "UZ",
            "Name": config.getTranslationText('5229'),
            "disable": false
        },
        {
            "Abbreviation": "VU",
            "Name": config.getTranslationText('5230'),
            "disable": false
        },
        {
            "Abbreviation": "VE",
            "Name": config.getTranslationText('5231'),
            "disable": false
        },
        {
            "Abbreviation": "VN",
            "Name": config.getTranslationText('5232'),
            "disable": false
        },
        {
            "Abbreviation": "WK",
            "Name": config.getTranslationText('5233'),
            "disable": false
        },
        {
            "Abbreviation": "WF",
            "Name": config.getTranslationText('5234'),
            "disable": false
        },
        {
            "Abbreviation": "EH",
            "Name": config.getTranslationText('5235'),
            "disable": false
        },
        {
            "Abbreviation": "YE",
            "Name": config.getTranslationText('5236'),
            "disable": false
        },
        {
            "Abbreviation": "ZM",
            "Name": config.getTranslationText('5237'),
            "disable": false
        },
        {
            "Abbreviation": "ZW",
            "Name": config.getTranslationText('5238'),
            "disable": false
        }
    ];
}

function getCountryNameUsingAbbreviation(abbrev) {
    var returnValue = getAvailableCountriesList().find(function (element) {
        return element.Abbreviation === abbrev;
    });

    return returnValue !== undefined ? returnValue.Name : '';
}

function getCountryCodeUsingCountryName(countryName) {
    var returnValue = getAvailableCountriesList().find(function (element) {
        return element.Name === countryName;
    });

    return returnValue !== undefined ? returnValue.Abbreviation : '';
}

/**
 * Use coreMarkets to trim availableCountriesList to core market countries. Used in requestItemModal.js
 * @returns {object[]} - Array of core market country objects from availableCountriesList
 * */
function getCoreMarketsFromAvailableCountriesList() {
    var returnArray = [];

    config.coreMarkets.forEach(function (abbrev) {
        var countryToAdd = getAvailableCountriesList().find(function (country) {
            return country.Abbreviation === abbrev;
        });
        if (countryToAdd !== undefined) {
            returnArray.push(countryToAdd);
        }
    });

    return returnArray.sort(function (a, b) {
        if (a.Name < b.Name) {
            return -1;
        }
        if (a.Name > b.Name) {
            return 1;
        }
        return 0;
    });
}

/**
 * Function to be used with multiselects for makes. Default function capitalizes the first letter in each word.
 * See getDescriptionForOption function in multiselect.js for use.
 * @param {string} text - Name of the make
 * @returns {string} - Returns the make name
 */
function customDescriptionFunctionForMakeMultiselects(text) {
    return text;
}

/**
 * Function to be used with multiselects for axle layouts. Default function capitalizes the first letter in each word.
 * See getDescriptionForOption function in multiselect.js for use.
 * @param {string} text - Name of the make
 * @returns {string} - Returns the make name
 */
function customDescriptionFunctionForAxleLayoutMultiselects(text) {
    return text;
}