import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
// import '../service-worker';

import MenuContentItem from '@/components/menu/MenuContentItem.vue';
import MenuContainer from '@/components/menu/MenuContainer.vue';
import InputTypesTable from '@/components/modals/settingsModal/InputTypesTable.vue';
// import PopupModal from '@/components/modals/PopupModal.vue';
import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
import CustomMessageDialogue from '@/components/modals/CustomMessageDialogue.vue';
import VerifyTransferLicenceModal from '@/components/modals/VerifyTransferLicenceModal';
import RequestTrialExtensionModal from '@/components/modals/RequestTrialExtensionModal';
import BodyBuilderOfferSaveModal from '@/components/modals/BodyBuilderOfferSaveModal';
import SaveAccessoryModal from '@/components/modals/SaveAccessoryModal';
import ResourceModal from '@/components/modals/ResourceModal';
import RequestItemModal from '@/components/modals/RequestItemModal';
import ItemConfigurationWizardModal from '@/components/modals/itemConfigurationWizardModal/ItemConfigurationWizardModal';
import SettingsModal from '@/components/modals/settingsModal/SettingsModal';
import ShareModal from '@/components/modals/ShareModal';
import HelpModal from '@/components/modals/HelpModal';
import TermsAndConditionsModal from '@/components/modals/TermsAndConditionsModal';
import CreateFolderModal from '@/components/modals/CreateFolderModal';
import RenameModal from '@/components/modals/RenameModal';
import SendEmailModal from '@/components/modals/summary/SendEmailModal';
import UploadFileModal from '@/components/modals/UploadFileModal';
import NotesAndWarningsModal from '@/components/modals/configuration/NotesAndWarningsModal';
import NotifyOtherUsersOfSaveModal from '@/components/modals/NotifyOtherUsersOfSaveModal';
import BuyNowModal from '@/components/modals/BuyNowModal';
import ChangePasswordModal from '@/components/modals/ChangePasswordModal';
import OpenTsvFileModal from '@/components/modals/OpenTsvFileModal';
import ReferralModal from '@/components/modals/ReferralModal';
import MoveToModal from '@/components/modals/MoveToModal';
import SubmitPendingVehicleModal from '@/components/modals/SubmitPendingVehicleModal';
import VerifyEmailModal from '@/components/modals/VerifyEmailModal.vue';
import NearlyThereModal from '@/components/modals/NearlyThereModal.vue';
import CostingDetailModal from '@/components/modals/costing/CostingDetailModal.vue';
import CostingTyreDetailModal from '@/components/modals/costing/CostingTyreDetailModal.vue';
import ForgotPasswordModal from '@/components/modals/ForgotPasswordModal.vue';
import SwapItemModal from '@/components/modals/SwapItemModal.vue';
import mitt from 'mitt';
var TScMessenger = window.TScMessenger;
TScMessenger.writeDebugMessage('In main.js');
import { router, ROUTES } from './router';

const app = createApp(App).use(router);
//app.config.performance = true;
TScMessenger.writeDebugMessage('In main.js, router added');

app.component('MenuContentItem', MenuContentItem);
app.component('MenuContainer', MenuContainer);
app.component('InputTypesTable', InputTypesTable);
// app.component('PopupModal', PopupModal);
app.component('ConfirmDialogue', ConfirmDialogue);
app.component('CustomMessageDialogue', CustomMessageDialogue);
app.component('VerifyTransferLicenceModal', VerifyTransferLicenceModal);
app.component('RequestTrialExtensionModal', RequestTrialExtensionModal);
app.component('BodyBuilderOfferSaveModal', BodyBuilderOfferSaveModal);
app.component('ResourceModal', ResourceModal);
app.component('RequestItemModal', RequestItemModal);
app.component('ItemConfigurationWizardModal', ItemConfigurationWizardModal);
app.component('SettingsModal', SettingsModal);
app.component('HelpModal', HelpModal);
app.component('TermsAndConditionsModal', TermsAndConditionsModal);
app.component('ShareModal', ShareModal);
app.component('SaveAccessoryModal', SaveAccessoryModal);
app.component('CreateFolderModal', CreateFolderModal);
app.component('RenameModal', RenameModal);
app.component('SendEmailModal', SendEmailModal);
app.component('UploadFileModal', UploadFileModal);
app.component('NotesAndWarningsModal', NotesAndWarningsModal);
app.component('NotifyOtherUsersOfSaveModal', NotifyOtherUsersOfSaveModal);
app.component('BuyNowModal', BuyNowModal);

app.component('ChangePasswordModal', ChangePasswordModal);
app.component('OpenTsvFileModal', OpenTsvFileModal);
app.component('ReferralModal', ReferralModal);
app.component('MoveToModal', MoveToModal);
app.component('SubmitPendingVehicleModal', SubmitPendingVehicleModal);
app.component('VerifyEmailModal', VerifyEmailModal);
app.component('NearlyThereModal', NearlyThereModal);
app.component('CostingDetailModal', CostingDetailModal);
app.component('CostingTyreDetailModal', CostingTyreDetailModal);
app.component('ForgotPasswordModal', ForgotPasswordModal);
app.component('SwapItemModal', SwapItemModal);

const bus = mitt();
export default bus;
app.provide('bus', bus);
app.provide('ROUTES', ROUTES);
// #region Custom Directives
app.directive('visible', {
    beforeMount (el, binding, vnode) {
        el.style.display = binding.value === true ? '' : 'none';
        el.style.visibility = binding.value === true ? 'visible' : 'hidden';
        el.style.opacity = binding.value === true ? 1 : 0;
    },
    beforeUpdate (el, binding, vnode) {
        el.style.display = binding.value === true ? '' : 'none';
        el.style.visibility = binding.value === true ? 'visible' : 'hidden';
        el.style.opacity = binding.value === true ? 1 : 0;
    }
});
app.directive('scroll', {
    mounted (el, binding) {
        // Add scroll event to element
        el.addEventListener('scroll', function(evt) {
            // Function wrapped in ref so unwrap and call
            binding.value(evt);
        });
    }
});
// #endregion Custom Directives
TScMessenger.writeDebugMessage('In main.js, mounting app');

const splashScreenElement = document.getElementById('splashScreen');
if (splashScreenElement) {
    splashScreenElement.remove();
}
const appElement = document.getElementById('app');
if (appElement) {
    // appElement.style.display = 'block';
    appElement.style.setProperty('display', 'block');
}

app.mount('#app');