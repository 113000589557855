import config from '../../services/config';
// import $ from '../../assets/scripts/jquery-3.6.0.min';

const $ = window.$;
var minMaxVals;

var TScMessenger = window.TScMessenger;

function ValidationEngine(mainAttrPath, skipFirstStepFlag) {
    var self = this;

    var offerManager;
    var cultureCode;

    var validationObjects = [];
    var passedvalidationObjects = [];
    var currentValidationObjectAppliedOps = [];
    var prevalidationValPaths = [];
    var prevalidationFuncs = [];
    var prevalidationValsObject = {};
    var curValidationObject;

    init();

    function init() {
        var mainValidationObj = new ValidationObject();


        if (mainAttrPath !== undefined) {
            if (skipFirstStepFlag && skipFirstStepFlag === config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_AND_OPS) {
                mainValidationObj.validationSteps.push(new ValidationStep(config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_AND_OPS, mainAttrPath));
                mainValidationObj.afterStepsOps.push(new Op(config.VALIDATION_OP_TYPE.DUMMY_OP, mainAttrPath));
            } else if (skipFirstStepFlag && skipFirstStepFlag === config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_ONLY) {
                mainValidationObj.validationSteps.push(new ValidationStep(config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_ONLY, mainAttrPath));
                mainValidationObj.afterStepsOps.push(new Op(config.VALIDATION_OP_TYPE.APPLY_NEW_VALUE, mainAttrPath));
            } else {
                mainValidationObj.validationSteps.push(new ValidationStep(config.VALIDATION_STEP_TYPES.UI, mainAttrPath));
                mainValidationObj.afterStepsOps.push(new Op(config.VALIDATION_OP_TYPE.APPLY_NEW_VALUE, mainAttrPath));
            }
            
        } 
        

        

        validationObjects.push(mainValidationObj);
        curValidationObject = mainValidationObj;
    }

    function addUIAttributeValidationStep(validationStepPath) {
        addValidationStep(new ValidationStep(config.VALIDATION_STEP_TYPES.UI, validationStepPath));

        return self;
    }

    function addBackEndAttributeValidationStep(valueType, stepComparisonValFunc, formattingType) {
        addValidationStep(new ValidationStep(config.VALIDATION_STEP_TYPES.BACKEND, valueType, stepComparisonValFunc, formattingType));

        return self;
    }

    function addValidationStep(validationStep/*validationStepPath, optionalStepComparisonVal*/) {
        if (curValidationObject.afterStepsOps.length > 0) {
            
            curValidationObject = new ValidationObject();
            validationObjects.push(curValidationObject);
        }

        curValidationObject.validationSteps.push(validationStep);

    }

    function addOp(opType, opAttrPath, optionalFuncToPassParamsTo, optionalParams) {
        if (curValidationObject !== undefined) {
            curValidationObject.afterStepsOps.push(new Op(opType, opAttrPath, optionalFuncToPassParamsTo, optionalParams));
        }

        return self;
    }

    function addOpBlock(opFuncDo, opFuncUndo, minusDeltaForUndo) {
        if (curValidationObject !== undefined) {
            let newOp = new Op(opFuncDo, opFuncUndo);
            curValidationObject.afterStepsOps.push(newOp);
            if(minusDeltaForUndo) {
                newOp.minusDeltaForUndo = minusDeltaForUndo;
            }
        }

        return self;
    }
    /**
     * Allows validation engine to use value type other than the one related to attribute observable group when checking values
     * @param {string} cvc - Should be value from VALUE_TYPE enum on config
     */
    function addCustomValidationConfig(cvc) {
        if (curValidationObject !== undefined) {
            curValidationObject.customValidationConfig = cvc;
        }
        return self;
    }

    function updateMainAttrValidationStepType(newStepType) {
        validationObjects[0].validationSteps[0].type = newStepType;
    }

    function getMainAttrValidationStepType() {
        return validationObjects[0].validationSteps[0].type;
    }

    function addPrevalidationValPath(attrPath) {

        prevalidationValPaths.push(attrPath);

        return self;
    }

    function addPrevalidationValFunc(key, func) {

        prevalidationFuncs.push({ key: key, func: func });

        return self;
    }

    function reverseOpUndoOrder() {
        curValidationObject.opUndoOrder = config.OP_UNDO_ORDER.REVERSE;

        return self;
    }

    function validate(newValue, oldValue, deltaVal, overrideFormatType, comparisonValConversionFunc) {
        var mainValidationObject = validationObjects[0];
        
        passedvalidationObjects = [];
        currentValidationObjectAppliedOps = [];

        if (prevalidationValPaths.length > 0 || prevalidationFuncs.length > 0) {
            setupPrevalidationVals();
        }

        var mainAttribute, errorMessageObv, resetFlagObv, uiInputId;

        if (mainValidationObject.validationSteps[0] !== undefined) {
            mainAttribute = offerManager.getAttributeGroup(mainValidationObject.validationSteps[0].attrIdentifier);
            errorMessageObv = mainAttribute.errorMessage;
            resetFlagObv = mainAttribute.reset;
            uiInputId = mainAttribute.getInputName();
        }
        

        var passedValidation = doValidation(mainValidationObject, newValue);

        if (passedValidation === true) {
            if (validationObjects.length > 1) {
                passedvalidationObjects.push(validationObjects[0]);
                for (var i = 1; i < validationObjects.length; i++) {
                    var nextValidationObject = validationObjects[i];

                    passedValidation = doValidation(nextValidationObject);

                    if (passedValidation === true) {
                        passedvalidationObjects.push(nextValidationObject);
                        currentValidationObjectAppliedOps = [];
                    } else {
                        break;
                    }
                }

            }
        }

        return passedValidation;

        function doValidation(validationObject, newValue) {
            //var current = validationObjects.shift();
            var skipOpCheck = false;
            var skipValidation = false;
            try {
                validationObject.validationSteps.forEach(function (step) {
                    var attribute;
                    
                    //= offerManager.getAttributeGroup(step.attrIdentifier);
                    mainAttribute.setFailedValidationInfo(null);
                    var validationConfig, formattingType;
                    if (step.type === config.VALIDATION_STEP_TYPES.UI) {
                        attribute = offerManager.getAttributeGroup(step.attrIdentifier);
                        if (typeof curValidationObject.customValidationConfig === 'string') {
                            validationConfig = offerManager.getValidationConfig(curValidationObject.customValidationConfig);
                        } else {
                            validationConfig = offerManager.getValidationConfig(attribute.getName());
                        }
                        formattingType = attribute.getUnitType();
                    } else if (step.type === config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_AND_OPS) {
                        skipOpCheck = true;
                        return true;
                    } else if (step.type === config.VALIDATION_STEP_TYPES.SKIP_MAIN_ATTR_VALIDATION_ONLY) {
                        return true;
                    } else if (step.type === config.VALIDATION_STEP_TYPES.SKIP_VALIDATION_ALLOW_OPS) {
                        skipValidation = true;
                    } else {//config.VALIDATION_STEP_TYPES.BACKEND
                        if (typeof curValidationObject.customValidationConfig === 'string') {
                            validationConfig = offerManager.getValidationConfig(curValidationObject.customValidationConfig);
                        } else {
                            validationConfig = offerManager.getValidationConfig(step.attrIdentifier);
                        }
                        formattingType = step.formattingType;
                    }
                    
                    if (skipValidation === false) {
                        var valForComparison = newValue !== undefined ? newValue : step.comparisonVal !== undefined ? step.comparisonVal() : attribute !== undefined ? attribute.getAttrVal() : 0;

                        var passedValidation, validationMessageType;

                        var minVal, maxVal;

                        if (overrideFormatType === undefined) {
                            minVal = getMinVal(validationConfig);
                            maxVal = getMaxVal(validationConfig);

                        } else {
                            if (overrideFormatType === config.INCREMENT_TYPE.PERCENTAGE) {
                                minVal = 0;
                                maxVal = 100;
                                formattingType = overrideFormatType;
                            } else if (overrideFormatType === config.INCREMENT_TYPE.LINEARDENSITY) {
                                minVal = getMinVal(validationConfig);
                                maxVal = getMaxVal(validationConfig);
                                valForComparison = comparisonValConversionFunc(valForComparison);
                            } else {
                                minVal = getMinVal(validationConfig);
                                maxVal = getMaxVal(validationConfig);
                            }
                        }

                        
                    
                        switch (validationConfig.type) {
                            case 'standard':
                                if (minVal !== undefined && maxVal !== undefined) {
                                    passedValidation = compareGTLT(valForComparison, minVal, maxVal, validationConfig.customPrecisionFunc);
                                    validationMessageType = config.VALIDATION_MESSAGE_TYPE.BETWEEN;
                                } else if (minVal === undefined && maxVal !== undefined) {
                                    passedValidation = compareLT(valForComparison, maxVal, validationConfig.customPrecisionFunc);
                                    validationMessageType = config.VALIDATION_MESSAGE_TYPE.LESSTHAN;
                                } else {
                                    passedValidation = compareGT(valForComparison, minVal, validationConfig.customPrecisionFunc);
                                    validationMessageType = config.VALIDATION_MESSAGE_TYPE.GREATERTHAN;
                                }
                                break;
                            case config.VALIDATION_MESSAGE_TYPE.LESSTHAN:
                                passedValidation = compareLT(minVal, maxVal);
                                validationMessageType = config.VALIDATION_MESSAGE_TYPE.LESSTHAN;
                                break;
                            case config.VALIDATION_MESSAGE_TYPE.GREATERTHAN:
                                passedValidation = compareLT(minVal, maxVal);
                                validationMessageType = config.VALIDATION_MESSAGE_TYPE.GREATERTHAN;
                                break;
                        }



                        //resetFlagObv(!passedValidation);

                        if (passedValidation === false) {
                            mainAttribute.setFailedValidationInfo({ attrIdentifier: step.attrIdentifier, minVal: minVal, maxVal: maxVal, compareVal: valForComparison, type: step.type });
                            var errorText,
                                messageCode = validationMessageType,                                
                                baseText = validationConfig.baseText;
                            switch (validationMessageType) {
                                case config.VALIDATION_MESSAGE_TYPE.BETWEEN:
                                    // errorText = config.getValidationMessageText(parseInt(validationMessageType), cultureCode, validationConfig.baseText, getFormattedVal(minVal, formattingType, attribute), getFormattedVal(maxVal, formattingType, attribute));
                                    if(validationConfig.useValidationBaseTextObject === true) {
                                        baseText = validationConfig.baseTextObject[config.VALIDATION_MESSAGE_TYPE.BETWEEN].baseText;
                                        messageCode = validationConfig.baseTextObject[config.VALIDATION_MESSAGE_TYPE.BETWEEN].messageCode;
                                    }
                                    errorText = config.getValidationMessageText(parseInt(messageCode), cultureCode, baseText, getFormattedVal(minVal, formattingType, attribute), getFormattedVal(maxVal, formattingType, attribute));
                                    break;
                                case config.VALIDATION_MESSAGE_TYPE.LESSTHAN:
                                    // errorText = config.getValidationMessageText(parseInt(validationMessageType), cultureCode, validationConfig.baseText, undefined, getFormattedVal(maxVal, formattingType, attribute));
                                    if(validationConfig.useValidationBaseTextObject === true) {
                                        baseText = validationConfig.baseTextObject[config.VALIDATION_MESSAGE_TYPE.LESSTHAN].baseText;
                                        messageCode = validationConfig.baseTextObject[config.VALIDATION_MESSAGE_TYPE.LESSTHAN].messageCode;
                                    }
                                    errorText = config.getValidationMessageText(parseInt(messageCode), cultureCode, baseText, undefined, getFormattedVal(maxVal, formattingType, attribute));
                                    break;
                                case config.VALIDATION_MESSAGE_TYPE.GREATERTHAN:
                                    // errorText = config.getValidationMessageText(parseInt(validationMessageType), cultureCode, validationConfig.baseText, getFormattedVal(minVal, formattingType, attribute));
                                    if(validationConfig.useValidationBaseTextObject === true) {
                                        baseText = validationConfig.baseTextObject[config.VALIDATION_MESSAGE_TYPE.GREATERTHAN].baseText;
                                        messageCode = validationConfig.baseTextObject[config.VALIDATION_MESSAGE_TYPE.GREATERTHAN].messageCode;
                                    }
                                    errorText = config.getValidationMessageText(parseInt(messageCode), cultureCode, baseText, getFormattedVal(minVal, formattingType, attribute));
                                    break;
                            }
                            //errorMessageObv(errorText);
                            //offerManager.displayError(uiInputId, errorText);
                            mainAttribute.addError(errorText);
                            throw 1;
                        }
                    }

                });
                //mainAttribute.clearErrors();
            } catch (validationException) {
                if (passedvalidationObjects.length > 0) {
                    reversePreviousValidationObjectsAppliedOps();
                }
                return false;
            }

            if (skipOpCheck === false) {
                try {
                    validationObject.afterStepsOps.forEach(function (op) {
                        //switch(op.opType) {
                        //    case config.VALIDATION_OP_TYPE.SET_TO_RESULT_FUNC_WITH_PARAM:
                        //        var evaluatedParams = convertParamsToVals(op.params);
                        //        op.do(evaluatedParams);
                        //        break;
                        //    default:
                        //        op.do(newValue, oldValue, deltaVal);
                        //}
                        handleOp(op);
                        currentValidationObjectAppliedOps.push(op);
                    });
                } catch (operationException) {
                    if (currentValidationObjectAppliedOps.length > 0) {
                        reverseCurrentValidationObjectAppliedOps(validationObject);
                    }
                    if (passedvalidationObjects.length > 0) {
                        reversePreviousValidationObjectsAppliedOps();
                    }
                    TScMessenger.writeDebugMessage('ValidationEngine.js, validate, doValidation, catch, operationException => Error executingOps: ' + operationException.message);
                    return false;
                }
            }
            

            return true;

            function getMinVal(validationConfig) {
                var minVal = 0;
                
                if (typeof validationConfig.minVal === "function") {
                    minVal = validationConfig.minVal(mainAttrPath);
                } else {
                    minVal = validationConfig.minVal;
                }

                return minVal;
            }

            function getMaxVal(validationConfig) {
                var maxVal = 0;

                if (typeof validationConfig.maxVal === "function") {
                    maxVal = validationConfig.maxVal(mainAttrPath);
                } else {
                    maxVal = validationConfig.maxVal;
                }

                return maxVal;
            }

            function reversePreviousValidationObjectsAppliedOps() {
                for (var i = passedvalidationObjects.length - 1; i >= 0; i--) {
                    var tempValidationObject = passedvalidationObjects[i];
                    if (tempValidationObject.opUndoOrder === config.OP_UNDO_ORDER.NORMAL) {
                        doUndo(tempValidationObject.afterStepsOps);
                        //for (var j = tempValidationObject.afterStepsOps.length - 1; j >= 0; j--) {
                        //    doUndo(tempValidationObject.afterStepsOps, j);
                        //    ////tempValidationObject.afterStepsOps[j].undo(newValue, oldValue, deltaVal);
                        //    //var op = tempValidationObject.afterStepsOps[j];
                        //    //handleOp(op, true);
                        //    //switch (op.opType) {
                        //    //    case config.VALIDATION_OP_TYPE.SET_TO_RESULT_FUNC_WITH_PARAM:
                        //    //        var evaluatedParams = convertParamsToVals(op.params);
                        //    //        op.undo(evaluatedParams);
                        //    //        break;
                        //    //    default:
                        //    //        op.undo(newValue, oldValue, deltaVal);
                        //    //}
                        //}
                    } else {
                        doUndoReverseOrder(tempValidationObject.afterStepsOps);
                    }

                }
            }

            function reverseCurrentValidationObjectAppliedOps(curValidationObj) {
                if (curValidationObj.opUndoOrder === config.OP_UNDO_ORDER.NORMAL) {
                    doUndo(currentValidationObjectAppliedOps);
                } else {
                    doUndoReverseOrder(currentValidationObjectAppliedOps);
                }
                //for (var i = currentValidationObjectAppliedOps.length - 1; i >= 0; i--) {
                //    doUndo(currentValidationObjectAppliedOps, i);
                //    //var op = currentValidationObjectAppliedOps[i];
                //    ////currentValidationObjectAppliedOps[i].undo(newValue, oldValue, deltaVal);
                //    //handleOp(op, true);
                //    //switch (op.opType) {
                //    //    case config.VALIDATION_OP_TYPE.SET_TO_RESULT_FUNC_WITH_PARAM:
                //    //        var evaluatedParams = convertParamsToVals(op.params);
                //    //        op.undo(evaluatedParams);
                //    //        break;
                //    //    default:
                //    //        op.undo(newValue, oldValue, deltaVal);
                //    //}
                //}

            }

            function doUndo(opsArray) {
                for (var i = opsArray.length - 1; i >= 0; i--) {
                    var op = opsArray[i];
                    handleOp(op, true);
                }

            }

            function doUndoReverseOrder(opsArray) {
                for (var i = 0; i < opsArray.length; i++) {
                    var op = opsArray[i];
                    handleOp(op, true);
                }

            }

            function handleOp(op, isUndo) {
                
                switch (op.opType) {
                    case config.VALIDATION_OP_TYPE.SET_TO_RESULT_FUNC_WITH_PARAM:
                        var evaluatedParams = convertParamsToVals(op.params);
                        if (isUndo && isUndo === true) {
                            op.undo(evaluatedParams);
                        } else {
                            op.do(evaluatedParams);
                        }

                        break;
                    case config.VALIDATION_OP_TYPE.DUMMY_OP:
                        break;
                    default:

                        if (isUndo && isUndo === true) {
                            if(op.minusDeltaForUndo && op.undo === op.do) {
                                op.undo(newValue, oldValue, -deltaVal);
                            }else {
                                op.undo(newValue, oldValue, deltaVal);
                            }
                            
                        } else {
                            op.do(newValue, oldValue, deltaVal);
                        }
                }
            }
        }

        
    }

    

    function getFormattedVal(val, formatType, attribute) {


        if (attribute && attribute.getFormattingRuleFunc() && attribute.getFormattingRuleFunc()() === true) {
            return attribute.getCustomFormattingFunc()(val);
        }

        switch (formatType) {
            case config.INCREMENT_TYPE.LENGTH:
                return offerManager.getUIFormattedLengthValue(val);
            case config.INCREMENT_TYPE.MASS:
                return offerManager.getUIFormattedMassValue(val);
            case config.INCREMENT_TYPE.PERCENTAGE:
                return offerManager.getUIFormattedPercentageValue(val);
            case config.INCREMENT_TYPE.VOLUME:
                return offerManager.getUIFormattedVolumeValue(val);
            case config.INCREMENT_TYPE.DEGREES:
                return offerManager.getUIFormattedDegreeValue(val);
            case config.INCREMENT_TYPE.PERCENTAGEINCREMENT:
                return offerManager.getUIFormattedPercentageIncrementValue(val);
            case config.INCREMENT_TYPE.NONE:
            case config.INCREMENT_TYPE.COEFFICIENTOFDRAG:
            case config.INCREMENT_TYPE.DRIVEAXLERATIO:
                return val;
            case config.INCREMENT_TYPE.AREA:
                return offerManager.getUIFormattedAreaValue(val);
            case config.INCREMENT_TYPE.SPEED:
                return offerManager.getUIFormattedSpeedValue(val);
            case config.INCREMENT_TYPE.VOLUMEPER100KMS:
                return offerManager.getUIFormattedVolumePer100KMsValue(val);
            case config.INCREMENT_TYPE.MONTHLYDISTANCE:
                return offerManager.getUIFormattedMonthlyDistanceValue(val);
            case config.INCREMENT_TYPE.MONTHS:
                return offerManager.getUIFormattedMonthsValue(val);
            case config.INCREMENT_TYPE.VALUEPERMONTH:
                return offerManager.getUIFormattedValuePerMonthValue(val);
            case config.INCREMENT_TYPE.TRIPSPERMONTH:
                return offerManager.getUIFormattedTripsPerMonthValue(val);
            case config.INCREMENT_TYPE.CURRENCYPERVOLUME:
                return offerManager.getUIFormattedCurrencyPerVolumeValue(val);
            case config.INCREMENT_TYPE.HIGHCURRENCY:
                return offerManager.getUIFormattedHighCurrencyValue(val);
            case config.INCREMENT_TYPE.MEDIUMCURRENCY:
                return offerManager.getUIFormattedMediumCurrencyValue(val);
            case config.INCREMENT_TYPE.CURRENCYTOTALPRICE:
                return offerManager.getUIFormattedHighCurrencyValue(val);
                //return offerManager.getUIFormattedCurrencyTotalPrice(val);
            case config.INCREMENT_TYPE.CURRENCYPERMONTH:
                return offerManager.getUIFormattedCurrencyPerMonthValue(val);
            case config.INCREMENT_TYPE.PERCENTAGEOFTOTALPRICE:
                return offerManager.getUIFormattedPercentageOfTotalPrice(val);
            case config.INCREMENT_TYPE.PERCENTAGESINGLEPLACE:
                return offerManager.getUIFormattedPercentageSinglePlaceValue(val);
            case config.INCREMENT_TYPE.PERCENTAGEDOUBLEPLACE:
                return offerManager.getUIFormattedPercentageDoublePlaceValue(val);
            case config.INCREMENT_TYPE.CURRENCYPERYEAR:
                return offerManager.getUIFormattedCurrencyPerYearValue(val);
            case config.INCREMENT_TYPE.PERCENTAGEOFFIXEDCOST:
                return offerManager.getUIFormattedPercentageOfFixedCostValue(val);
            case config.INCREMENT_TYPE.CURRENCYPERDISTANCE:
                return offerManager.getUIFormattedCurrencyPerDistanceValue(val);
            case config.INCREMENT_TYPE.CURRENCYMINORPERDISTANCE:
                return offerManager.getUIFormattedMinorCurrencyPerDistanceValue(val);
            case config.INCREMENT_TYPE.CURRENCYSINGLEPLACE:
                return offerManager.getUIFormattedCurrencySinglePlaceValue(val);
            case config.INCREMENT_TYPE.CURRENCYPERTRIP:
                return offerManager.getUIFormattedCurrencyPerTripValue(val);
            case config.INCREMENT_TYPE.FUELCONSUMPTIONNOCONVERSION:
                return offerManager.getUIFormattedFuelConsumptionValueNoConversion(val);
            case config.INCREMENT_TYPE.PERCENTAGEOFFUEL:
                return offerManager.getUIFormattedPercentageOfFuelValue(val);
            case config.INCREMENT_TYPE.HOURSPERMONTH:
                return offerManager.getUIFormattedHoursPerMonthValue(val);
            case config.INCREMENT_TYPE.VOLUMEPERHOUR:
                return offerManager.getUIFormattedVolumePerHourValue(val);
        }
        
    }

    function setupPrevalidationVals() {
        if (prevalidationValPaths.length > 0) {
            prevalidationValPaths.forEach(function (attrPath) {
                prevalidationValsObject[attrPath] = offerManager.getAttributeGroup(attrPath).getAttrVal();
            });
        }
        if (prevalidationFuncs.length > 0) {
            prevalidationFuncs.forEach(function (funcHolder) {
                prevalidationValsObject[funcHolder.key] = funcHolder.func();
            });
        }
    }

    function convertParamsToVals(params) {
        var paramVals = [];

        params.forEach(function(paramToEvaluate) {
            if(typeof paramToEvaluate === 'function') {
                paramVals.push(paramToEvaluate());
            }else {
                var val = getPreValidationVal(paramToEvaluate);
                if(val === undefined) {
                    var attr = offerManager.getAttributeGroup(paramToEvaluate);
                    if(attr !== null && attr !== undefined) {
                        val = attr.getAttrVal();
                        
                    }else {
                        val = 0;
                    }   
                }
                paramVals.push(val);
            }
        });

        return paramVals;
    }

    function getPreValidationVal(paramToEvaluate) {
        return prevalidationValsObject[paramToEvaluate];
    }

    function ValidationObject() {
        return {
            validationSteps: [],
            afterStepsOps: [],
            opUndoOrder: config.OP_UNDO_ORDER.NORMAL
        }
    }
    function ValidationStep(type, attrIdentifier, stepValForComparisonFunc, formattingType) { 
        return {
            type: type,
            attrIdentifier: attrIdentifier,
            comparisonVal: stepValForComparisonFunc !== undefined ? function () { return stepValForComparisonFunc() } : undefined,
            formattingType: formattingType || config.INCREMENT_TYPE.LENGTH
        }
    }

    function Op(opTypeOrDoFunction, opAttrPathOrUndoFunction, funcToPassParamsTo, params) {
        var newOp = {
            opType: typeof opTypeOrDoFunction === 'string' ? opTypeOrDoFunction : funcToPassParamsTo !== undefined ? config.VALIDATION_OP_TYPE.SET_TO_RESULT_FUNC_WITH_PARAM : config.VALIDATION_OP_TYPE.CUSTOM,
            params: params
        };
        if (typeof opTypeOrDoFunction === 'string' && params === undefined) {
            newOp.do = getOpFunc(opTypeOrDoFunction, opAttrPathOrUndoFunction, params);
            newOp.undo = getUndoOpFunc(opTypeOrDoFunction, opAttrPathOrUndoFunction, params);
        } else if (typeof opTypeOrDoFunction === 'string' && params !== undefined) {
            
            newOp.do = getParameterisedOpFunc(opTypeOrDoFunction, opAttrPathOrUndoFunction, funcToPassParamsTo);
            newOp.undo = getParameterisedOpFunc(opTypeOrDoFunction, opAttrPathOrUndoFunction, funcToPassParamsTo);
        }else {
            newOp.do = opTypeOrDoFunction;
            newOp.undo = opAttrPathOrUndoFunction || opTypeOrDoFunction;
        }
        return newOp;
        
    }

    function getUndoOpFunc(opType, opAttrPath) {
        var reverseOpType;
        switch (opType) {
            case config.VALIDATION_OP_TYPE.INC_BY_DELTA:
                reverseOpType = config.VALIDATION_OP_TYPE.DEC_BY_DELTA;
                break;
            case config.VALIDATION_OP_TYPE.DEC_BY_DELTA:
                reverseOpType = config.VALIDATION_OP_TYPE.INC_BY_DELTA;
                break;
            case config.VALIDATION_OP_TYPE.APPLY_NEW_VALUE:
                reverseOpType = config.VALIDATION_OP_TYPE.APPLY_OLD_VALUE;
                break;
        }

        return getOpFunc(reverseOpType, opAttrPath);
    }

    function getOpFunc(opType, opAttrPath) {
        

        switch (opType) {
            case config.VALIDATION_OP_TYPE.INC_BY_DELTA:
                return function (newValue, oldValue, deltaVal) {
                    var oppAttr = offerManager.getAttributeGroup(opAttrPath);
                    oppAttr.setAttrVal(oppAttr.getAttrVal() + deltaVal);
                };
            case config.VALIDATION_OP_TYPE.DEC_BY_DELTA:
                return function (newValue, oldValue, deltaVal) {
                    var oppAttr = offerManager.getAttributeGroup(opAttrPath);
                    oppAttr.setAttrVal(oppAttr.getAttrVal() - deltaVal);
                };
            case config.VALIDATION_OP_TYPE.APPLY_NEW_VALUE:
                return function (newValue, oldValue, deltaVal) {
                    var oppAttr = offerManager.getAttributeGroup(opAttrPath);
                    if (newValue === oppAttr.getAttrVal()) {
                        oppAttr.setAttrVal(newValue);
                        $('#' + oppAttr.getInputName()).val(oppAttr.formatted.value);
                    } else {
                        oppAttr.setAttrVal(newValue);
                    }
                };
            case config.VALIDATION_OP_TYPE.APPLY_OLD_VALUE:
                return function (newValue, oldValue, deltaVal) {
                    var oppAttr = offerManager.getAttributeGroup(opAttrPath);
                    oppAttr.setAttrVal(oldValue);
                };
            
        }
    }

    function getParameterisedOpFunc(opType, opAttrPath, funcToPassParamsTo) {


        switch (opType) {
            case config.VALIDATION_OP_TYPE.SET_TO_RESULT_FUNC_WITH_PARAM:
                return function (params) {
                    var oppAttr = offerManager.getAttributeGroup(opAttrPath);

                    oppAttr.setAttrVal(funcToPassParamsTo(params));
                }
        }
    }

    function compareGTLT(val, minVal, maxVal, customPrecisionFunc) {
        
        var valCompareVal, maxValCompareVal, minValCompareVal;
        if (customPrecisionFunc === undefined) {
            valCompareVal = offerManager.getCurMeasurementSysRoundedLength(val);
            maxValCompareVal = offerManager.getCurMeasurementSysRoundedLength(maxVal);
            minValCompareVal = offerManager.getCurMeasurementSysRoundedLength(minVal);
        } else {
            valCompareVal = customPrecisionFunc(val); 
            maxValCompareVal = customPrecisionFunc(maxVal);
            minValCompareVal = customPrecisionFunc(minVal);
        }
        

        if (valCompareVal < minValCompareVal || valCompareVal > maxValCompareVal) {
            return false;
        }

        return true;
    }

    function compareLT(val, maxVal, customPrecisionFunc) {

        var valCompareVal, maxValCompareVal;

        if (customPrecisionFunc === undefined) {
            valCompareVal = offerManager.getCurMeasurementSysRoundedLength(val);
            maxValCompareVal = offerManager.getCurMeasurementSysRoundedLength(maxVal);
        } else {
            valCompareVal = customPrecisionFunc(val);
            maxValCompareVal = customPrecisionFunc(maxVal);
        }
        
        if (valCompareVal > maxValCompareVal) {
            return false;
        }

        return true;
    }

    function compareGT(val, minVal, customPrecisionFunc) {

        var valCompareVal, minValCompareVal;

        if (customPrecisionFunc === undefined) {
            valCompareVal = offerManager.getCurMeasurementSysRoundedLength(val);
            minValCompareVal = offerManager.getCurMeasurementSysRoundedLength(minVal);
        } else {
            valCompareVal = customPrecisionFunc(val);
            minValCompareVal = customPrecisionFunc(minVal);
        }

        if (valCompareVal < minValCompareVal) {
            return false;
        }

        return true;
    }

    function setOfferManager(OM) {
        offerManager = OM;
    }

    function setCultureCode(cc) {
        cultureCode = cc;
    }

    function cleanUp() {
        validationObjects = null;
        passedvalidationObjects = null;
        currentValidationObjectAppliedOps = null;
        prevalidationValPaths = null;
        prevalidationFuncs = null;
        prevalidationValsObject = null;
        if(curValidationObject) {
            if (curValidationObject.afterStepsOps) {
                curValidationObject.afterStepsOps = null;
            }
            if (curValidationObject.validationSteps) {
                curValidationObject.validationSteps = null;
            }
            curValidationObject = null;
        }
        offerManager = null;
        self = null;
    }

    this.validate = validate;
    this.addUIAttributeValidationStep = addUIAttributeValidationStep;
    this.addBackEndAttributeValidationStep = addBackEndAttributeValidationStep;
    this.addOp = addOp;
    this.addOpBlock = addOpBlock;
    this.addCustomValidationConfig = addCustomValidationConfig;
    this.addPrevalidationValPath = addPrevalidationValPath;
    this.addPrevalidationValFunc = addPrevalidationValFunc;
    this.reverseOpUndoOrder = reverseOpUndoOrder;
    this.setOfferManager = setOfferManager;
    this.setCultureCode = setCultureCode;
    this.updateMainAttrValidationStepType = updateMainAttrValidationStepType;
    this.getMainAttrValidationStepType = getMainAttrValidationStepType;
    this.cleanUp = cleanUp;
}

    






export default ValidationEngine;

