import config from './config';
import globals from './globals';
import PermissionObject from './permissionObject';
// import { config, globals, PermissionObject, utils } from './';
// import $ from '../assets/scripts/jquery-3.6.0.min';
import { shallowRef, watch, computed, isRef, nextTick, watchEffect } from 'vue';

const $ = window.$;
var TScMessenger = window.TScMessenger;

function AttributeObservableGroup(valType, inputTypeVal, unitTypeVal) {
    var self = this;
    var valueType = valType;
    var inputType = inputTypeVal;
    var unitType = unitTypeVal || config.INCREMENT_TYPE.LENGTH;

    var offerManager = null, inputName = null, path = null, validator = null;
    var formattingRule, customFormattingFunction, customConversionIncrementType, customConversionFunction;
    var passedThroughIncrementOrDecrement = false;
    var failedValidationInfo = null, errorsArray = [];
    
    var createUndoOpForValueChange = true, preventUndoValueUpdate = false;
    var selectOptions = null;
    //this.inputName = null;
    //this.path = null;

    var validationMode = config.VALIDATION_MODE.ON, doActionsCallEnabled = false, refreshingSelect = false, valueChanged = false, preventOverrideUpdate = false, byPassConversionToMM = false;

    var allowOpsWhileDragging = false, originalMainValidationStepType;
    // let alwaysNotifyValueSubscribersBool = false;
    let hasLinkedAOGs = false, linkedAOGs = [], hasLinkedMultiCardAOG = false, linkedMultiCardAOGs = [];
    var permissionObject = new PermissionObject();

    //////////////var visiblePermissionFunc;
    //////////////var overridePermissionFunc;
    //////////////var readOnlyPermissionFunc;
    //////////////var visibleDependencyFunc;
    //////////////var overrideDependencyFunc;
    //////////////var readOnlyDependencyFunc;

    //////////////var overridePermissionVal = false;
    //////////////var readOnlyPermissionVal = false;
    //////////////var visiblePermissionVal = true;

    var attrGetFunc, attrSetFunc, optionGetFunc, overrideGetFunc;

    function permission(path) {
        var evaluatedPermissionObject = permissionObject.permission(path);

        if (evaluatedPermissionObject.override === false) {
            preventOverrideUpdate = true;
            self.override.value = true;
            nextTick(function () { preventOverrideUpdate = false; });
        }

        if (evaluatedPermissionObject.readOnly === true) {
            if (self.override.value) {
                preventOverrideUpdate = true;
                self.override.value = false;
                nextTick(function () { preventOverrideUpdate = false; });
            }
        }

        return evaluatedPermissionObject;
    }
    ////////function permission(path) {
    ////////    return {
    ////////        override: evaluateOverridePermission(path),
    ////////        readOnly: evaluateReadOnlyPermission(path),
    ////////        visible: eveluateVisiblePermission(path)
    ////////    }
    ////////}

    ////////function evaluateOverridePermission(path) {
    ////////    var val = evaluatePermission(overridePermissionFunc, overrideDependencyFunc, overridePermissionVal, path);

    ////////    if (val === false) {
    ////////        self.override(true);
    ////////    }

    ////////    return val;
    ////////}

    ////////function evaluateReadOnlyPermission(path) {
    ////////    var val = evaluatePermission(readOnlyPermissionFunc, readOnlyDependencyFunc, readOnlyPermissionVal, path);

    ////////    if (val === true) {
    ////////        if (self.override) {
    ////////            self.override(false);
    ////////        }
    ////////    }

    ////////    //return evaluatePermission(readOnlyPermissionFunc, readOnlyDependencyFunc, readOnlyPermissionVal, path);
    ////////    return val;
    ////////}

    ////////function eveluateVisiblePermission(path) {
    ////////    //var resultFromPermissionFunc, resultFromDependencyFunc, functionResult;

    ////////    //resultFromPermissionFunc = visiblePermissionFunc !== undefined ? visiblePermissionFunc() : undefined;
    ////////    //resultFromDependencyFunc = visibleDependencyFunc !== undefined ? visibleDependencyFunc() : undefined;

    ////////    //if (resultFromDependencyFunc !== undefined && resultFromPermissionFunc !== undefined) {
    ////////    //    functionResult = resultFromDependencyFunc && resultFromPermissionFunc;
    ////////    //} else if (resultFromDependencyFunc !== undefined) {
    ////////    //    functionResult = resultFromDependencyFunc;
    ////////    //} else if (resultFromPermissionFunc !== undefined) {
    ////////    //    functionResult = resultFromPermissionFunc;
    ////////    //}

    ////////    //return functionResult !== undefined ? functionResult : visiblePermissionVal;
    ////////    return evaluatePermission(visiblePermissionFunc, visibleDependencyFunc, visiblePermissionVal, path);
    ////////}

    ////////function evaluatePermission(permissionFunc, dependencyFunc, permissionVal, path) {
    ////////    var resultFromPermissionFunc, resultFromDependencyFunc, functionResult;

    ////////    resultFromPermissionFunc = permissionFunc !== undefined ? permissionFunc(path) : undefined;
    ////////    resultFromDependencyFunc = dependencyFunc !== undefined ? dependencyFunc(path) : undefined;

    ////////    if (resultFromDependencyFunc !== undefined && resultFromPermissionFunc !== undefined) {
    ////////        functionResult = resultFromDependencyFunc && resultFromPermissionFunc;
    ////////    } else if (resultFromDependencyFunc !== undefined) {
    ////////        functionResult = resultFromDependencyFunc;
    ////////    } else if (resultFromPermissionFunc !== undefined) {
    ////////        functionResult = resultFromPermissionFunc;
    ////////    }

    ////////    return functionResult !== undefined ? functionResult : permissionVal;
    ////////}
    function readOnly(val) {
        permissionObject.readOnly(val);

        return self;
    }
    ////////function readOnly(val) {
    ////////    readOnlyPermissionVal = val || true;

    ////////    return self;
    ////////}
    function readOnlyPermission(func) {
        permissionObject.readOnlyPermission(func);

        return self;
    }
    ////////function readOnlyPermission(func) {
    ////////    readOnlyPermissionFunc = func;

    ////////    return self;
    ////////}
    function readOnlyDependency(dependencyPath, comparisonOperator, compareVal) {
        permissionObject.readOnlyDependency(dependencyPath, comparisonOperator, compareVal);

        return self;
    }
    ////////function readOnlyDependency(dependencyPath, comparisonOperator, compareVal) {
    ////////    readOnlyDependencyFunc = getFunction(dependencyPath, comparisonOperator, compareVal);

    ////////    return self;
    ////////}
    function overridePermission(func) {
        permissionObject.overridePermission(func);

        return self;
    }
    ////////function overridePermission(func) {
    ////////    overridePermissionFunc = func;

    ////////    return self;
    ////////}
    function overrideDependency(dependencyPath, comparisonOperator, compareVal) {
        permissionObject.overrideDependencyFunc(dependencyPath, comparisonOperator, compareVal);

        return self;
    }
    ////////function overrideDependency(dependencyPath, comparisonOperator, compareVal) {
    ////////    overrideDependencyFunc = getFunction(dependencyPath, comparisonOperator, compareVal);

    ////////    return self;
    ////////}
    function visible(val) {
        permissionObject.visible(val);

        return self;
    }
    ////////function visible(val) {
    ////////    visiblePermissionVal = val || true;

    ////////    return self;
    ////////}
    function visiblePermission(func) {
        permissionObject.visiblePermission(func);

        return self;
    }
    ////////function visiblePermission(func) {
    ////////    visiblePermissionFunc = func;

    ////////    return self;
    ////////}
    function visibleDependency(dependencyPath, comparisonOperator, compareVal) {
        permissionObject.visibleDependency(dependencyPath, comparisonOperator, compareVal);

        return self;
    }
    ////////function visibleDependency(dependencyPath, comparisonOperator, compareVal) {
    ////////    visibleDependencyFunc = getFunction(dependencyPath, comparisonOperator, compareVal);

    ////////    return self;
    ////////}

    //function preventBlankEntry() {
    //    self.doNotAllowBlankEntry = true;
    //    return self;
    //}

    this.permissionObv = shallowRef();
    this.override = shallowRef(false);
    this.reset = shallowRef(false);
    this.errorMessage = shallowRef();
    this.undoVal = -1;

    switch (inputType) {
        case config.INPUT_TYPE.TEXT:
        case config.INPUT_TYPE.TEL:
        case config.INPUT_TYPE.UPDOWNBOX:

            self.value = shallowRef();
            self.formatRefresh = shallowRef(new Date().getTime());
            self.doNotIncludeUnit = false;
            self.formatted = computed({
                get: function () {
                    self.formatRefresh.value;
                    getOfferManager().activeMeasurementSystem.value;
                    //if (inputType === config.INPUT_TYPE.TEL) {
                    //    return self.value.value;
                    //} else {
                    if (formattingRule !== undefined) {
                        if (formattingRule() === true) {
                            return customFormattingFunction(self.value.value);
                        }
                    }
                    switch (unitType) {
                        case config.INCREMENT_TYPE.LENGTH:
                            return getOfferManager().getUIFormattedLengthValue(self.value.value, self.doNotIncludeUnit);
                        case config.INCREMENT_TYPE.MASS:
                            return getOfferManager().getUIFormattedMassValue(self.value.value, null, self.doNotIncludeUnit);
                        case config.INCREMENT_TYPE.VOLUME:
                            return getOfferManager().getUIFormattedVolumeValue(self.value.value);
                        case config.INCREMENT_TYPE.PERCENTAGE:
                            return getOfferManager().getUIFormattedPercentageValue(self.value.value, null, self.doNotIncludeUnit);
                        case config.INCREMENT_TYPE.SPECIFICGRAVITY:
                            return getOfferManager().getUIFormattedDensityValue(self.value.value);
                        case config.INCREMENT_TYPE.LINEARDENSITY:
                            return getOfferManager().getUIFormattedLinearDensityValue(self.value.value, self.doNotIncludeUnit);
                            //return getOfferManager().getUIFormattedLinearDensityValueNoUnit(self.value.value);
                        case config.INCREMENT_TYPE.DEGREES:
                            return getOfferManager().getUIFormattedDegreeValue(self.value.value);
                        case config.INCREMENT_TYPE.PERCENTAGEINCREMENT:
                            return getOfferManager().getUIFormattedPercentageIncrementValue(self.value.value);
                        case config.INCREMENT_TYPE.MONTHLYDISTANCE:
                            return getOfferManager().getUIFormattedMonthlyDistanceValue(self.value.value);
                        case config.INCREMENT_TYPE.MONTHS:
                            return getOfferManager().getUIFormattedMonthsValue(self.value.value);
                        case config.INCREMENT_TYPE.VALUEPERMONTH:
                            return getOfferManager().getUIFormattedValuePerMonthValue(self.value.value);
                        case config.INCREMENT_TYPE.TRIPSPERMONTH:
                            return getOfferManager().getUIFormattedTripsPerMonthValue(self.value.value);
                        case config.INCREMENT_TYPE.CURRENCYPERVOLUME:
                            return getOfferManager().getUIFormattedCurrencyPerVolumeValue(self.value.value);
                        case config.INCREMENT_TYPE.HIGHCURRENCY:
                            return getOfferManager().getUIFormattedHighCurrencyValue(self.value.value);
                        case config.INCREMENT_TYPE.MEDIUMCURRENCY:
                            return getOfferManager().getUIFormattedMediumCurrencyValue(self.value.value);
                        case config.INCREMENT_TYPE.CURRENCYTOTALPRICE:
                            return getOfferManager().getUIFormattedCurrencyTotalPrice(self.value.value);
                        case config.INCREMENT_TYPE.CURRENCYPERMONTH:
                            return getOfferManager().getUIFormattedCurrencyPerMonthValue(self.value.value);
                        case config.INCREMENT_TYPE.PERCENTAGEOFTOTALPRICE:
                            return getOfferManager().getUIFormattedPercentageOfTotalPrice(self.value.value);
                        case config.INCREMENT_TYPE.PERCENTAGESINGLEPLACE:
                            return getOfferManager().getUIFormattedPercentageSinglePlaceValue(self.value.value);
                        case config.INCREMENT_TYPE.PERCENTAGEDOUBLEPLACE:
                            return getOfferManager().getUIFormattedPercentageDoublePlaceValue(self.value.value);
                        case config.INCREMENT_TYPE.CURRENCYPERYEAR:
                            return getOfferManager().getUIFormattedCurrencyPerYearValue(self.value.value);
                        case config.INCREMENT_TYPE.VOLUMEPER100KMS:
                            return getOfferManager().getUIFormattedVolumePer100KMsValue(self.value.value);
                        case config.INCREMENT_TYPE.PERCENTAGEOFFIXEDCOST:
                            return getOfferManager().getUIFormattedPercentageOfFixedCostValue(self.value.value);
                        case config.INCREMENT_TYPE.CURRENCYPERDISTANCE:
                            return getOfferManager().getUIFormattedCurrencyPerDistanceValue(self.value.value);
                        case config.INCREMENT_TYPE.CURRENCYMINORPERDISTANCE:
                            return getOfferManager().getUIFormattedMinorCurrencyPerDistanceValue(self.value.value);
                        case config.INCREMENT_TYPE.CURRENCYSINGLEPLACE:
                            return getOfferManager().getUIFormattedCurrencySinglePlaceValue(self.value.value);
                        case config.INCREMENT_TYPE.AREA:
                            return getOfferManager().getUIFormattedAreaValue(self.value.value);
                        case config.INCREMENT_TYPE.SPEED:
                            return getOfferManager().getUIFormattedSpeedValue(self.value.value);
                        case config.INCREMENT_TYPE.COEFFICIENTOFDRAG:
                            return getOfferManager().getCustomPrecisionRoundedValue(self.value.value, 2);
                        case config.INCREMENT_TYPE.DRIVEAXLERATIO:
                            return getOfferManager().getCustomPrecisionRoundedValue(self.value.value, 3);
                        case config.INCREMENT_TYPE.FUELCONSUMPTION:
                            return getOfferManager().getUIFormattedFuelConsumptionValue(self.value.value);
                        case config.INCREMENT_TYPE.FUELCONSUMPTIONNOCONVERSION:
                            return getOfferManager().getUIFormattedFuelConsumptionValueNoConversion(self.value.value);
                        case config.INCREMENT_TYPE.NUMBER:
                            return globals.returnFloat(self.value.value);
                        case config.INCREMENT_TYPE.CURRENCYPERTRIP:
                            return getOfferManager().getUIFormattedCurrencyPerTripValue(self.value.value);
                        case config.INCREMENT_TYPE.PERCENTAGEOFFUEL:
                            return getOfferManager().getUIFormattedPercentageOfFuelValue(self.value.value);
                        case config.INCREMENT_TYPE.HOURSPERMONTH:
                                return getOfferManager().getUIFormattedHoursPerMonthValue(self.value.value);
                        case config.INCREMENT_TYPE.VOLUMEPERHOUR:
                                return getOfferManager().getUIFormattedVolumePerHourValue(self.value.value);
                        default:
                            return self.value.value;
                    }
                    //}
                },
                set: function (newValue) {
                    //if (inputType !== config.INPUT_TYPE.TEL) {
                    if (offerManager.checkIfTrialChangesAllowed() === false) {
                        offerManager.checkVerifiedStatusOnline()
                            .then(function (verifiedNotVerified) {
                                if (verifiedNotVerified === config.EMAIL_VERIFICATION_STATUS_OPTIONS.VERIFIED) {
                                    doWriteBehaviour();
                                } else {
                                    var tempOldValue = self.value.value;
                                    self.value.value = newValue;
                                    self.value.value = tempOldValue;
                                    offerManager.showEmailVerificationModal();
                                }
                            });
                    } else {
                        doWriteBehaviour();
                    }
                    function doWriteBehaviour() {
                        if (inputType === config.INPUT_TYPE.TEXT || globals.isValidNumberVal(newValue)) {
                            var unitTypeForSwitch;

                            if (formattingRule !== undefined) {
                                if (formattingRule() === true) {
                                    unitTypeForSwitch = customConversionIncrementType;
                                }
                            }

                            //if (inputType === config.INPUT_TYPE.TEXT && self.doNotAllowBlankEntry === true) {
                            //    if (newValue === '') {
                            //        self.reset(true);
                            //        self.errorMessage(offerManager.getValidationConfig(valueType).baseText);
                            //    }
                            //} else {
                                if (inputType !== config.INPUT_TYPE.TEXT) {
                                    if (typeof newValue === 'string') {
                                        newValue = globals.removeUnneccessaryCharactersFromGlobalizeNumberString(newValue);
                                    }
                                }
                                if (byPassConversionToMM === false) {
                                    var measurementSystem = getOfferManager().getActiveOffer() !== '' && getOfferManager().getActiveOffer() !== undefined ? getOfferManager().getActiveOffer().getMeasurementSystem() : globals.user.getActiveMeasurementSystem();
                                    switch (unitTypeForSwitch || unitType) {
                                        case config.INCREMENT_TYPE.LENGTH:
                                            //newValue = globals.returnFloat(newValue) / getOfferManager().getActiveOffer().getMeasurementSystem().lengthConversion;
                                            newValue = globals.returnFloat(newValue) * measurementSystem.lengthConversion;
                                            break;
                                        case config.INCREMENT_TYPE.MASS:
                                            //newValue = globals.returnFloat(newValue) / getOfferManager().getActiveOffer().getMeasurementSystem().massConversion;
                                            newValue = globals.returnFloat(newValue) / measurementSystem.massConversion;
                                            break;
                                        case config.INCREMENT_TYPE.VOLUME:
                                        case config.INCREMENT_TYPE.VOLUMEPER100KMS:
                                            //newValue = globals.returnFloat(newValue) / getOfferManager().getActiveOffer().getMeasurementSystem().volumeConversion;
                                            newValue = globals.returnFloat(newValue) / measurementSystem.volumeConversion;
                                            break;
                                        case config.INCREMENT_TYPE.CURRENCYPERVOLUME:
                                            //newValue = globals.returnFloat(newValue) * getOfferManager().getActiveOffer().getMeasurementSystem().volumeConversion;
                                            newValue = globals.returnFloat(newValue) * measurementSystem.volumeConversion;
                                            break;
                                        case config.INCREMENT_TYPE.SPECIFICGRAVITY:
                                            //newValue = globals.returnFloat(newValue) / getOfferManager().getActiveOffer().getMeasurementSystem().densityConversion;
                                            newValue = globals.returnFloat(newValue) / measurementSystem.densityConversion;
                                            break;
                                        case config.INCREMENT_TYPE.LINEARDENSITY:
                                            //newValue = globals.returnFloat(newValue) / getOfferManager().getActiveOffer().getMeasurementSystem().linearDensityConversion;
                                            newValue = globals.returnFloat(newValue) / measurementSystem.linearDensityConversion;
                                            break;
                                        case config.INCREMENT_TYPE.PERCENTAGE:
                                        case config.INCREMENT_TYPE.PERCENTAGEINCREMENT:
                                        case config.INCREMENT_TYPE.NUMBER:
                                            newValue = globals.returnFloat(newValue);
                                            break;
                                        case config.INCREMENT_TYPE.MONTHLYDISTANCE:
                                            //newValue = globals.returnFloat(newValue) / getOfferManager().getActiveOffer().getMeasurementSystem().distanceConversion;
                                            newValue = globals.returnFloat(newValue) / measurementSystem.distanceConversion;
                                            break;
                                        case config.INCREMENT_TYPE.AREA:
                                            //newValue = globals.returnFloat(newValue) / getOfferManager().getActiveOffer().getMeasurementSystem().areaConversion;
                                            newValue = globals.returnFloat(newValue) / measurementSystem.areaConversion;
                                            break;
                                        case config.INCREMENT_TYPE.SPEED:
                                            //newValue = globals.returnFloat(newValue) / getOfferManager().getActiveOffer().getMeasurementSystem().speedConversion;
                                            newValue = globals.returnFloat(newValue) / measurementSystem.speedConversion;
                                            break;
                                        case config.INCREMENT_TYPE.FUELCONSUMPTION:
                                            if (measurementSystem.id === config.MEASUREMENT_SYSTEM.METRIC) {
                                                if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME || offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSSPECIFYFUELCONSUMPTIONAS).optionId() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
                                                    // km/l
                                                    newValue = 100 / globals.returnFloat(newValue);
                                                } else {
                                                    // l/100km
                                                    newValue = globals.returnFloat(newValue);
                                                }
                                            } else {
                                                if (globals.user.getSpecifyFuelConsumptionAs() === config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.DISTANCE_PER_VOLUME) {
                                                    newValue = 100 / (globals.returnFloat(newValue) / measurementSystem.distanceConversion * measurementSystem.volumeConversion);
                                                } else {
                                                    newValue = 100 / (100 / (globals.returnFloat(newValue) * measurementSystem.distanceConversion / measurementSystem.volumeConversion));
                                                }
                                            }
                                            // newValue = globals.returnFloat(newValue);
                                            break;
                                        default:
                                            if (unitTypeForSwitch !== config.INCREMENT_TYPE.NONE && unitType !== config.INCREMENT_TYPE.NONE /*&& unitTypeForSwitch !== config.INCREMENT_TYPE.MONTHS && unitType !== config.INCREMENT_TYPE.MONTHS*/) {
                                                newValue = globals.returnFloat(newValue);
                                            }

                                    }
                                }
                                //}
                                //var roundedVal = Number((Number((newValue * getConversionForAttribute(measurementSystem)).toFixed(getPrecisionForAttribute(measurementSystem))) / getConversionForAttribute(measurementSystem)).toFixed(1));
                                if (validationMode === config.VALIDATION_MODE.ON || validationMode === config.VALIDATION_MODE.ALLOW_OPS) {
                                    self.value.value = updateValue(newValue, self.value.value);
                                    if (validator === null) {
                                        setAttrVal(self.value.value);
                                    }
                                    if (preventUndoValueUpdate === false) {
                                        self.undoVal = -1;
                                    }
                                    checkIfSaveNeeded();

                                } else {
                                    self.value.value = newValue;
                                    setAttrVal(newValue);
                                }
                            //}
                        } else {
                            self.reset.value = true;
                            //The value <entered value> is invalid for <attribute name>
                            var errorText = config.getTranslationText('485', [newValue, offerManager.getValidationConfig(valueType).baseText]);
                            self.errorMessage.value = errorText;
                            //offerManager.displayError(inputName);
                        }
                        if (offerManager !== null && offerManager !== undefined) {
                            offerManager.clearActiveLegislationQuickLookupObjects();
                        }
                    }
                },
                owner: this

            });


            break;
        case config.INPUT_TYPE.CHECKBOX:
        case config.INPUT_TYPE.RADIO:
            self.value = shallowRef();
            break;
        case config.INPUT_TYPE.SELECT:
            self.optionProxy = computed({
                get: function () {


                    return self.option.value;

                },
                set: function (newValue) {

                    if (validationMode === config.VALIDATION_MODE.ON) {
                        self.option.value = updateSelect(newValue, self.option.value);
                        checkIfSaveNeeded();
                    } else {
                        self.option.value = newValue;
                    }

                },
                owner: this

            });
            self.option = shallowRef();
            self.optionId = shallowRef();
            self.refreshObv = shallowRef();
            break;
    }

    function getValue() {
        switch (inputType) {
            case config.INPUT_TYPE.TEXT:
            case config.INPUT_TYPE.TEL:
            case config.INPUT_TYPE.UPDOWNBOX:
            case config.INPUT_TYPE.CHECKBOX:
                return self.value.value;
            case config.INPUT_TYPE.SELECT:
                return self.option.value;
        }
    }

    function getMetricPrecisionForAttribute() {

        var measurementSystem = globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.METRIC);

        var unitTypeForSwitch;

        if (formattingRule !== undefined) {
            if (formattingRule() === true) {
                unitTypeForSwitch = customConversionIncrementType;
            }
        }

        switch (unitTypeForSwitch || unitType) {
            case config.INCREMENT_TYPE.LENGTH:
                return measurementSystem.lengthPrecision;
            case config.INCREMENT_TYPE.MASS:
                return measurementSystem.massPrecision;
            case config.INCREMENT_TYPE.VOLUME:
            case config.INCREMENT_TYPE.VOLUMEPER100KMS:
            case config.INCREMENT_TYPE.CURRENCYPERVOLUME:
                return measurementSystem.volumePrecision;
            case config.INCREMENT_TYPE.SPECIFICGRAVITY:
                return measurementSystem.densityPrecision;
            case config.INCREMENT_TYPE.LINEARDENSITY:
                return measurementSystem.linearDensityPrecision;
            case config.INCREMENT_TYPE.PERCENTAGE:
            case config.INCREMENT_TYPE.PERCENTAGEINCREMENT:
            case config.INCREMENT_TYPE.NUMBER:
            case config.INCREMENT_TYPE.FUELCONSUMPTION:
                return 0;
            case config.INCREMENT_TYPE.MONTHLYDISTANCE:
                return measurementSystem.distancePrecision;
            case config.INCREMENT_TYPE.AREA:
                return measurementSystem.areaPrecision;
            case config.INCREMENT_TYPE.SPEED:
                return measurementSystem.speedPrecision;
            default:
                return 0;

        }
    }

    function getPrecisionForAttribute(measurementSystem) {
        var unitTypeForSwitch;

        if (formattingRule !== undefined) {
            if (formattingRule() === true) {
                unitTypeForSwitch = customConversionIncrementType;
            }
        }

        switch (unitTypeForSwitch || unitType) {
            case config.INCREMENT_TYPE.LENGTH:
                return measurementSystem.lengthPrecision;
            case config.INCREMENT_TYPE.MASS:
                return measurementSystem.massPrecision;
            case config.INCREMENT_TYPE.VOLUME:
            case config.INCREMENT_TYPE.VOLUMEPER100KMS:
            case config.INCREMENT_TYPE.CURRENCYPERVOLUME:
                return measurementSystem.volumePrecision;
            case config.INCREMENT_TYPE.SPECIFICGRAVITY:
                return measurementSystem.densityPrecision;
            case config.INCREMENT_TYPE.LINEARDENSITY:
                return measurementSystem.linearDensityPrecision;
            case config.INCREMENT_TYPE.PERCENTAGE:
            case config.INCREMENT_TYPE.PERCENTAGEINCREMENT:
            case config.INCREMENT_TYPE.NUMBER:
            case config.INCREMENT_TYPE.FUELCONSUMPTION:
                return 0;
            case config.INCREMENT_TYPE.MONTHLYDISTANCE:
                return measurementSystem.distancePrecision;
            case config.INCREMENT_TYPE.AREA:
                return measurementSystem.areaPrecision;
            case config.INCREMENT_TYPE.SPEED:
                return measurementSystem.speedPrecision;
            default:
                return 0;

        }
    }

    function getConversionForAttribute(measurementSystem) {
        var unitTypeForSwitch;

        if (formattingRule !== undefined) {
            if (formattingRule() === true) {
                unitTypeForSwitch = customConversionIncrementType;
            }
        }

        switch (unitTypeForSwitch || unitType) {
            case config.INCREMENT_TYPE.LENGTH:
                return measurementSystem.lengthConversion;
            case config.INCREMENT_TYPE.MASS:
                return measurementSystem.massConversion;
            case config.INCREMENT_TYPE.VOLUME:
            case config.INCREMENT_TYPE.VOLUMEPER100KMS:
            case config.INCREMENT_TYPE.CURRENCYPERVOLUME:
                return measurementSystem.volumeConversion;
            case config.INCREMENT_TYPE.SPECIFICGRAVITY:
                return measurementSystem.densityConversion;
            case config.INCREMENT_TYPE.LINEARDENSITY:
                return measurementSystem.linearDensityConversion;
            case config.INCREMENT_TYPE.PERCENTAGE:
            case config.INCREMENT_TYPE.PERCENTAGEINCREMENT:
            case config.INCREMENT_TYPE.NUMBER:
            case config.INCREMENT_TYPE.FUELCONSUMPTION:
                return 1;
            case config.INCREMENT_TYPE.MONTHLYDISTANCE:
                return measurementSystem.distanceConversion;
            case config.INCREMENT_TYPE.AREA:
                return measurementSystem.areaConversion;
            case config.INCREMENT_TYPE.SPEED:
                return measurementSystem.speedConversion;
            default:
                return 1;

        }
    }

    function checkIfSaveNeeded() {
        if (valueChanged === true && self.saveFunc !== undefined) {

            setTimeout(function () {
                self.saveFunc();
            }, 600);
        }
    }

    function setter(setterFunc) {
        attrSetFunc = setterFunc;

        return self;
    }

    function getSetterRef() {
        return attrSetFunc;
    }

    function getter(getterFunc) {
        attrGetFunc = getterFunc;
        //bypassValidation = true;
        if (self.value) {
            self.value.value = attrGetFunc();
        }
        //self.formatted(attrGetFunc());
        //bypassValidation = false;
        return self;
    }

    function optionGetter(optionGetterFunc) {
        optionGetFunc = optionGetterFunc;

        setVal(optionGetterFunc());

        return self;
    }

    //function updateValue(newValue, oldValue) {
    //    valueChanged = false;
    //    if (validator !== null) {
    //        var overrideFormatType;

    //        if (formattingRule !== undefined) {
    //            if (formattingRule() === true) {
    //                overrideFormatType = customConversionIncrementType;
    //            }
    //        }

    //        var deltaVal = newValue - oldValue;
    //        var passedValidation = validator.validate(newValue, oldValue, deltaVal, overrideFormatType, customConversionFunction);
    //        if (passedValidation === true) {
    //            failedValidationInfo = null;
    //            if (errorsArray.length > 0) {
    //                setTimeout(function () {
    //                    self.reset(true);
    //                    var errorText = errorsArray[errorsArray.length - 1];
    //                    self.errorMessage(errorText);
    //                    offerManager.displayError(getInputName(), errorText);
    //                    errorsArray = [];
    //                    self.reset(false);
    //                }, 250);
    //                //self.reset(false);

    //            }
    //            //offerManager.doActionsAfterSuccesfulValidation(valueType, newValue, deltaVal);
    //            if (offerManager !== null && offerManager !== undefined) {
    //                offerManager.doActionsAfterSuccesfulValidation(valueType, newValue, deltaVal);
    //                offerManager.incrementNumChanges();
    //                //offerManager.checkShowVerifyEmailModal();
    //            }
    //            valueChanged = true;
    //            passedThroughIncrementOrDecrement = false;

                



    //            return newValue;

    //        } else {
    //            if (passedThroughIncrementOrDecrement === true) {
    //                self.reset(false);
    //            }

    //            //var valToUse = self.undoVal && self.undoVal !== -1 ? self.undoVal : oldValue;
    //            var valToUse = getResetValue(newValue, oldValue);
    //            setAttrVal(valToUse);



    //            passedThroughIncrementOrDecrement = false;

    //            //return valToUse;
    //            var valToReturn = updateValue(valToUse, oldValue);
    //            //if (valToReturn === valToUse) {
    //            //    //if (offerManager !== null && offerManager !== undefined) {
    //            //    //    offerManager.doActionsAfterFailedValidation(valueType, oldValue);
    //            //    //}
    //            //    if (errorsArray.length > 0) {
    //            //        setTimeout(function () {
    //            //            self.reset(!passedValidation);
    //            //            var errorText = errorsArray[errorsArray.length - 1];
    //            //            self.errorMessage(errorText);
    //            //            offerManager.displayError(getInputName(), errorText);
    //            //            errorsArray = [];
    //            //        }, 10);
    //            //        //self.reset(false);

    //            //    }

    //            //}
    //            return valToReturn;
    //        }
    //    }
    //    passedThroughIncrementOrDecrement = false;
    //    valueChanged = true;
    //    if (offerManager !== null && offerManager !== undefined) {
    //        offerManager.incrementNumChanges();
    //        //offerManager.checkShowVerifyEmailModal();
    //    }
    //    return newValue;
    //}

    function updateValue(newValue, oldValue) {
        valueChanged = false;
        var overrideFormatType;
        if (validator !== null) {
            if (offerManager !== null && offerManager !== undefined) {
                offerManager.setLegislationLookupObjectsCleared(false);
                offerManager.clearActiveLegislationQuickLookupObjects();
            }
            

            if (formattingRule !== undefined) {
                if (formattingRule() === true) {
                    overrideFormatType = customConversionIncrementType;
                }
            }
            
            var valToUse = doValidationUntilValid(newValue, oldValue);
            

            if (errorsArray.length > 0 && validationMode !== config.VALIDATION_MODE.ALLOW_OPS) {
                
                self.reset.value = true;
                var errorText = errorsArray[errorsArray.length - 1];
                self.errorMessage.value = errorText;
                clearErrors();
                offerManager.displayError(getInputName(), errorText);
                // clearErrors();
            }

            var deltaVal = valToUse - oldValue;
            var newOp;
            if (offerManager !== null && offerManager !== undefined && validationMode !== config.VALIDATION_MODE.ALLOW_OPS) {
                offerManager.doActionsAfterSuccesfulValidation(valueType, newValue, deltaVal);
                if (createUndoOpForValueChange === true) {
                    newOp = offerManager.getUndoHandler().newChangeValueOp(path + '.' + valueType, oldValue, newValue, unitType);
                    if (newOp !== null) {
                        if (self.linkedSliderId) {
                            newOp.addValueLinkedSliderId(self.linkedSliderId);
                        }
                        if (formattingRule !== undefined) {
                            newOp.addCustomFormattingRuleFunc(formattingRule);
                            newOp.addCustomUnitType(customConversionIncrementType);
                        }
                    }
                }
                offerManager.incrementNumChanges();
            }
            valueChanged = true;
            passedThroughIncrementOrDecrement = false;
            
            return valToUse;

            

        } else {
            if (offerManager !== null && offerManager !== undefined) {
                if (createUndoOpForValueChange === true) {
                    newOp = offerManager.getUndoHandler().newChangeValueOp(path + '.' + valueType, oldValue, newValue, unitType);
                    if (newOp !== null && self.linkedSliderId) {
                        newOp.addValueLinkedSliderId(self.linkedSliderId);
                    }
                }
            }
        }
        
        passedThroughIncrementOrDecrement = false;
        valueChanged = true;
        if (offerManager !== null && offerManager !== undefined) {
            offerManager.incrementNumChanges();
            //offerManager.checkShowVerifyEmailModal();
        }
        return newValue;

        function doValidationUntilValid(newValue, oldValue) {
            var deltaVal = newValue - oldValue;
            var passedValidation = validator.validate(newValue, oldValue, deltaVal, overrideFormatType, customConversionFunction);
            //var passedValidation;
            //if (inputType === config.INPUT_TYPE.TEXT && self.doNotAllowBlankEntry === true && newValue === '') {
            //    passedValidation = false;
            //} else {
            //    passedValidation = validator.validate(newValue, oldValue, deltaVal, overrideFormatType, customConversionFunction);
            //}
            if (passedValidation === true) {
                return newValue;
            } else {
                if (offerManager !== null && offerManager !== undefined) {
                    offerManager.setLegislationLookupObjectsCleared(false);
                    offerManager.clearActiveLegislationQuickLookupObjects();
                }
                var valToUse = getResetValue(newValue, oldValue);
                setAttrVal(valToUse);

                return doValidationUntilValid(valToUse, oldValue);
            }
        }
    }

    /*
    function updateValue(newValue, oldValue) {
        valueChanged = false;
        if (validator !== null) {
            var overrideFormatType;

            if (formattingRule !== undefined) {
                if (formattingRule() === true) {
                    overrideFormatType = customConversionIncrementType;
                }
            }

            var deltaVal = newValue - oldValue;
            var passedValidation = validator.validate(newValue, oldValue, deltaVal, overrideFormatType, customConversionFunction);
            if (passedValidation === true) {
                //offerManager.doActionsAfterSuccesfulValidation(valueType, newValue, deltaVal);
                if (offerManager !== null && offerManager !== undefined) {
                    offerManager.doActionsAfterSuccesfulValidation(valueType, newValue, deltaVal);
                    offerManager.incrementNumChanges();
                    //offerManager.checkShowVerifyEmailModal();
                }
                valueChanged = true;
                passedThroughIncrementOrDecrement = false;
                return newValue;

            } else {
                if (passedThroughIncrementOrDecrement === true) {
                    self.reset(false);
                }

                var valToUse = self.undoVal && self.undoVal !== -1 ? self.undoVal : oldValue;
                setAttrVal(valToUse);

                if (offerManager !== null && offerManager !== undefined) {
                    offerManager.doActionsAfterFailedValidation(valueType, oldValue);
                }
                
                passedThroughIncrementOrDecrement = false;
                
                return valToUse;
            }
        }
        passedThroughIncrementOrDecrement = false;
        valueChanged = true;
        if (offerManager !== null && offerManager !== undefined) {
            offerManager.incrementNumChanges();
            //offerManager.checkShowVerifyEmailModal();
        }
        return newValue;
    }
    */

    function getResetValue(newValue, oldValue) {
        if (failedValidationInfo) {
            var validationConfigId;
            if(failedValidationInfo.type === 'BACKEND') {
                validationConfigId = failedValidationInfo.attrIdentifier;
            }else {
                
                var failedAttribute = offerManager.getAttributeGroup(failedValidationInfo.attrIdentifier);
                if(failedAttribute.getName) {
                    validationConfigId = failedAttribute.getName();
                }
            }
            
            
            if (validationConfigId !== undefined) {
                var failedAttributeValidationConfig = offerManager.getValidationConfig(validationConfigId);
                var valCompareVal, maxValCompareVal, minValCompareVal;
                if (failedAttributeValidationConfig.customPrecisionFunc === undefined) {
                    valCompareVal = offerManager.getCurMeasurementSysRoundedLength(failedValidationInfo.compareVal);
                    maxValCompareVal = offerManager.getCurMeasurementSysRoundedLength(failedValidationInfo.maxVal);
                    minValCompareVal = offerManager.getCurMeasurementSysRoundedLength(failedValidationInfo.minVal);
                } else {
                    valCompareVal = failedAttributeValidationConfig.customPrecisionFunc(failedValidationInfo.compareVal);
                    maxValCompareVal = failedAttributeValidationConfig.customPrecisionFunc(failedValidationInfo.maxVal);
                    minValCompareVal = failedAttributeValidationConfig.customPrecisionFunc(failedValidationInfo.minVal);
                }
                if (minValCompareVal > maxValCompareVal) {
                    var breakHere = 0;
                }

                if (minValCompareVal !== undefined && !isNaN(minValCompareVal) && valCompareVal < minValCompareVal) {
                    if (failedAttributeValidationConfig.type === 'standard') {
                        return newValue + (failedValidationInfo.minVal - failedValidationInfo.compareVal);
                    } else if (failedAttributeValidationConfig.type === config.VALIDATION_MESSAGE_TYPE.LESSTHAN) {
                        return newValue - (failedValidationInfo.minVal - failedValidationInfo.maxVal);
                    } else if (failedAttributeValidationConfig.type === config.VALIDATION_MESSAGE_TYPE.GREATERTHAN) {
                        return newValue + (failedValidationInfo.maxVal - failedValidationInfo.minVal);
                    }
                } else if (maxValCompareVal !== undefined && !isNaN(maxValCompareVal) && valCompareVal > maxValCompareVal) {
                    if (failedAttributeValidationConfig.type === 'standard') {
                        if(newValue < 0) {
                            return newValue + (failedValidationInfo.compareVal - failedValidationInfo.maxVal);
                        }else {
                            return newValue - (failedValidationInfo.compareVal - failedValidationInfo.maxVal);
                        }
                        
                    } else if (failedAttributeValidationConfig.type === config.VALIDATION_MESSAGE_TYPE.LESSTHAN) {
                        return newValue - (failedValidationInfo.minVal - failedValidationInfo.maxVal);
                    } else if (failedAttributeValidationConfig.type === config.VALIDATION_MESSAGE_TYPE.GREATERTHAN) {
                        return newValue + (failedValidationInfo.maxVal - failedValidationInfo.minVal);
                    }
                }
            }
            
            return oldValue;
        } else {
            return self.undoVal && self.undoVal !== -1 ? self.undoVal : oldValue;
        }
    }

    function setFailedValidationInfo(newValue) {
        failedValidationInfo = newValue;
    }

    function addError(errorText) {
        errorsArray.push(errorText);
    }

    function clearErrors() {
        errorsArray = [];
    }

    function getErrorsArray() {
        return errorsArray;
    }

    function updateLinkedAOGs() {
        if(hasLinkedAOGs) {
            getLinkedAOGs().forEach(function (linkedAOGID) {
                let linkedAOG = offerManager.getAttributeGroup(linkedAOGID);
                // linkedAOG.formatted.value = linkedAOG.value.value;
                linkedAOG.formatRefresh.value = new Date().getTime();
            });
        }
        if(hasLinkedMultiCardAOG) {
            linkedMultiCardAOGs.forEach(function (linkedMultiCardAOG) {
                linkedMultiCardAOG.arrayFunc().forEach(function (linkedObj) {
                    let linkedAOG = offerManager.getAttributeGroup(linkedMultiCardAOG.path + '.' + linkedObj.getId() + '.' + linkedMultiCardAOG.valueType);
                    // linkedAOG.formatted.value = linkedAOG.value.value;
                    linkedAOG.formatRefresh.value = new Date().getTime();
                });
                
            });
        }
    }

    function updateSelect(newValue, oldValue) {
        valueChanged = false;
        if (validator !== null) {
            var passedValidation = validator.validate(newValue, oldValue);
            if (passedValidation === true) {
                //offerManager.doActionsAfterSuccesfulValidation(valueType, newValue, deltaVal);
                if (offerManager !== null && offerManager !== undefined) {
                    offerManager.doActionsAfterSuccesfulValidation(valueType, newValue);
                    if (createUndoOpForValueChange === true) {
                        offerManager.getUndoHandler().newChangeSelectOp(path + '.' + valueType, oldValue, newValue);
                    }
                }
                updateLinkedAOGs();
                valueChanged = true;
                return newValue;

            } else {
                offerManager.displayError(inputName, self.errorMessage.value);
                
                return oldValue;
            }
        } else {
            if (offerManager !== null && offerManager !== undefined) {
                if (createUndoOpForValueChange === true) {
                    offerManager.getUndoHandler().newChangeSelectOp(path + '.' + valueType, oldValue, newValue);
                }
            }
        }
        updateLinkedAOGs();
        valueChanged = true;
        return newValue;
    }

    function getUpdateFunction(array) {
        selectOptions = array;
        return function (id, isTextFromCombo) {
            var dfd = $.Deferred();

            //newElement.array.forEach(function (option) {
            //    if (id === option.id) {
            //        if (newElement.attributeGroup.extraUpdate !== undefined) {
            //            newElement.attributeGroup.extraUpdate(option.id)
            //                .then(function () {
            //                    newElement.attributeGroup.option(option);
            //                    newElement.attributeGroup.optionId(option.id);
            //                    dfd.resolve();
            //                });
            //        } else {
            //            newElement.attributeGroup.option(option);
            //            newElement.attributeGroup.optionId(option.id);
            //            dfd.resolve();
            //        }
            //    }
            //});
            if (refreshingSelect === false) {
                //if (isTextFromCombo === true && id !== '' && id !== undefined && id !== null) {
                //    var newOptionId = id;
                //    var description = id;

                //    var newOption = { id: newOptionId, description: description };
                //    //offerManager.unitOfMeasureOptions.push(newOption);
                //    self.option(newOption);
                //    self.optionId(newOption.id);
                //    //self.optionProxy(newOption)
                //    dfd.resolve();
                //} else {
                    array.forEach(function (option) {
                        if (id === option.id) {

                            if (self.extraUpdate !== undefined) {
                                self.extraUpdate(option.id)
                                    .then(function () {

                                        var tempRef = watch(self.option, function (newValue) {
                                            tempRef();
                                            if (newValue.id === self.optionId.value) {
                                                self.extraUpdate(newValue.id)
                                                    .then(function () {
                                                        resetValue();
                                                        dfd.resolve();
                                                    });
                                            } else {
                                                self.optionId.value = option.id;
                                                dfd.resolve();
                                            }

                                        });
                                        self.optionProxy.value = option;

                                        //self.option(option);


                                    });
                            } else {
                                self.option.value = option;
                                self.optionId.value = option.id;
                                dfd.resolve();


                            }


                        }
                    });
                //}
                

            } else {
                dfd.resolve();
            }


            return dfd.promise();
        }
    }

    function resetValue(inputId, fromRevertErrorValueOnBlur) {
        self.reset.value = false;

        if (self.formatted) {
            byPassConversionToMM = true;
            self.formatted.value = attrGetFunc();
            byPassConversionToMM = false;
        }
        if (errorsArray.length > 0 || fromRevertErrorValueOnBlur) {
            if (inputId !== undefined) {
                $('#' + inputId).val(self.undoVal !== -1 ? self.undVal : self.formatted !== undefined ? self.formatted.value : self.option.value.description);
                offerManager.displayError(inputId, self.errorMessage.value);
            } else {
                refreshingSelect = true;
                $('#' + inputName).val(self.undoVal !== -1 ? self.undVal : self.formatted !== undefined ? self.formatted.value : self.option.value.id).selectmenu("refresh");

                refreshingSelect = false;
                offerManager.displayError(inputName, self.errorMessage.value);
            }
        }
    }

    function handleErrorResetIfNecessary() {
        if (self.reset !== undefined && self.reset.value) {
            self.resetValue.value = inputName;
        }
    }

    function addOverrideMechanism(getModuleDataFunction, overrideSetFunction, originalValueGetFunction, overrideGetFunction, optionalOverrideRemovedCleanUpFunc, executeCleanUpAfterFlag) {
        if (overrideGetFunction !== undefined) {
            overrideGetFunc = overrideGetFunction;
            self.override.value = overrideGetFunction();
        } else {
            self.override.value = false;
        }
        
        self.overrideSubscriptionRef = watch(self.override, function (newValue) {
            if (preventOverrideUpdate === false) {

                if (getModuleDataFunction() !== undefined && getModuleDataFunction() !== '') {
                    if (offerManager !== null && offerManager !== undefined) {
                        if (createUndoOpForValueChange === true) {
                            offerManager.getUndoHandler().newChangeCheckboxOp(path + '.' + valueType, !newValue, newValue, config.CHECKBOX_TYPE.OVERRIDE);
                        }
                    }
                    overrideSetFunction(newValue);

                    if (inputType !== config.INPUT_TYPE.SELECT) {
                        var delta = originalValueGetFunction() - attrGetFunc();
                    }
                    
                    if (newValue === false) {
                        if (executeCleanUpAfterFlag !== true) {
                            if (optionalOverrideRemovedCleanUpFunc) {
                                optionalOverrideRemovedCleanUpFunc(delta);
                            }
                        }
                        
                        if (inputType === config.INPUT_TYPE.SELECT) {
                            var originalSelectVal = originalValueGetFunction();
                            self.option.value = originalSelectVal;
                            self.optionId.value = originalSelectVal.id;
                        } else {
                            self.value.value = originalValueGetFunction();
                            setAttrVal(originalValueGetFunction());
                        }

                        if (executeCleanUpAfterFlag === true) {
                            if (optionalOverrideRemovedCleanUpFunc) {
                                optionalOverrideRemovedCleanUpFunc(delta);
                            }
                        }
                        //if (graphicsCallback !== undefined) {
                        //    graphicsCallback(valueType);
                        //}

                    }
                    if (offerManager !== null && offerManager !== undefined) {
                        //if (self.override() === false) {
                        //offerManager.setSkipGraphicsCallback(true);
                            offerManager.doActionsAfterSuccesfulValidation(valueType, newValue);
                        //} else {
                        //    offerManager.doActionsAfterOverrideChecked();
                            
                        //}
                    } 
                }
            }

        });
        //self.value.subscribe(function (newValue) {
        //    setAttrVal(newValue);
        //});
        permissionObject.overridePermissionVal = true;
        return self;
    }

    function addSubscription(modelValueUpdateFunction) {
        if (self.value !== undefined) {
            self.valueSubscriptionRef = watch(self.value, function (newValue, oldValue) {
                if (offerManager !== null && offerManager !== undefined) {
                    //offerManager.doActionsAfterSuccesfulValidation(valueType);
                    if (createUndoOpForValueChange === true) {
                        offerManager.getUndoHandler().newChangeCheckboxOp(path + '.' + valueType, oldValue, newValue, config.CHECKBOX_TYPE.MENU_ITEM);
                    }
                }
                modelValueUpdateFunction(newValue);
                if (self.saveFunc !== undefined) {
                    self.saveFunc();
                }
                if (offerManager !== null && offerManager !== undefined) {
                    offerManager.doActionsAfterSuccesfulValidation(valueType);
                    //if (createUndoOpForValueChange === true) {
                    //    offerManager.getUndoHandler().newChangeCheckboxOp(path + '.' + valueType, oldValue, newValue);
                    //}
                }
            });
        } else {
            self.optionIdSubscriptionRef = watch(self.optionId, function (newValue, oldValue) {
                if (offerManager !== null && offerManager !== undefined && offerManager.preventUpdateDuringAOGOptionIdWatch === false && offerManager.getPreventUpdateDuringAOGOptionIdWatch() === false) {
                    modelValueUpdateFunction(newValue);
                    updateLinkedAOGs();

                    if (offerManager.preventDoActionsAfterSuccessfulValidationCall === false) {

                        offerManager.doActionsAfterSuccesfulValidation(valueType);

                        if (createUndoOpForValueChange === true) {
                            offerManager.getUndoHandler().newChangeSelectOp(path + '.' + valueType, oldValue, newValue);
                        }
                    }
                }
            });
        }

        return self;
    }

    function addExtraUpdateFunction(extraUpdateFunction) {
        self.extraUpdate = extraUpdateFunction;

        return self;
    }

    function addImmediateSaveFunction(saveFunc) {
        self.saveFunc = saveFunc;

        return self;
    }

    function addFormattingRule(ruleEvaluateFunction, altFormattingFunc, altConversionType, altConversionFunc) {
        formattingRule = ruleEvaluateFunction;
        customFormattingFunction = altFormattingFunc;
        customConversionIncrementType = altConversionType;
        customConversionFunction = altConversionFunc;

        return self;
    }

    function getFormattingRuleFunc() {
        return formattingRule;
    }

    function getCustomFormattingFunc() {
        return customFormattingFunction;
    }

    function setOfferManager(oM) {
        offerManager = oM;
        if (validator !== null) {
            validator.setOfferManager(oM);
        }
        //if (self.value) {
        //    self.valueSubscriptionRef = self.value.beforeAndAfterSubscribe(function (oldValue, newValue) {
        //        offerManager.getUndoHandler().newChangeValueOp(path + '.' + valueType, oldValue, newValue);
        //    });
        //}
        if (self.formatted !== undefined && self.dataRefreshMechanism === undefined) {
            //self.dataRefreshMechanism = ko.computed(function () {

            //    if (typeof offerManager.refreshData() === 'function') {
            //        offerManager.refreshData()();
            //    }
            //    validationMode = config.VALIDATION_MODE.OFF;
            //    if (attrGetFunc !== undefined) {
            //        self.value(attrGetFunc());
            //    }
            //    if (self.overrideSubscriptionRef) {
            //        preventOverrideUpdate = true;
            //        if (overrideGetFunc !== undefined) {
            //            self.override(overrideGetFunc());
            //        }
            //        preventOverrideUpdate = false;
            //    }
            //    //if (overrideGetFunc !== undefined) {
            //    //    self.override(overrideGetFunc());
            //    //}

            //    validationMode = config.VALIDATION_MODE.ON;
            //    return true;

            //});
            if (isRef(offerManager.refreshData())) {
                setTimeout(function () {
                    self.dataRefreshMechanism = watch(offerManager.refreshData(), function () {

                        var turnValidationModeBackOn = false;
                        if (validationMode === config.VALIDATION_MODE.ON) {
                            validationMode = config.VALIDATION_MODE.OFF;
                            turnValidationModeBackOn = true;
                        }
                        if (attrGetFunc !== undefined) {
                            // nextTick(function () {
                            //     self.value.value = attrGetFunc();
                            // });
                            self.value.value = attrGetFunc();
                            // if(alwaysNotifyValueSubscribersBool) {
                            //     self.formatted.value = self.value.value;
                            // }
                        }
                        if (self.overrideSubscriptionRef) {
                            preventOverrideUpdate = true;
                            if (overrideGetFunc !== undefined) {
                                self.override.value = overrideGetFunc();
                            }
                            nextTick(function () { preventOverrideUpdate = false; });
                        }
                        //if (overrideGetFunc !== undefined) {
                        //    self.override(overrideGetFunc());
                        //}
                        if (turnValidationModeBackOn) {
                            validationMode = config.VALIDATION_MODE.ON;
                        }
                        return true;
    
                    });
                    globals.watchersArr.push(self.dataRefreshMechanism);
                }, 0);
                
            }
        } else if (self.optionProxy !== undefined && self.dataRefreshMechanism === undefined) {
            if (isRef(offerManager.refreshData())) {
                setTimeout(function () {
                    self.dataRefreshMechanism = watch(offerManager.refreshData(), function () {

                
                        var turnValidationModeBackOn = false;
                        if (validationMode === config.VALIDATION_MODE.ON) {
                            validationMode = config.VALIDATION_MODE.OFF;
                            turnValidationModeBackOn = true;
                        }
                        //doActionsCallEnabled = false;
                        if (optionGetFunc !== undefined) {
                            var option = optionGetFunc();
                            self.option.value = option;
                            if (self.refreshObv) {
                                self.refreshObv.value = new Date().getTime();
                            }
                            self.optionId.value = option.id;
                        }
                        if (turnValidationModeBackOn) {
                            validationMode = config.VALIDATION_MODE.ON;
                        }
                        //doActionsCallEnabled = true;
                        return true;
    
                    });
                    globals.watchersArr.push(self.dataRefreshMechanism);
                }, 0);
            }
            
            // self.dataRefreshMechanism = computed(function () {

            //     if (isRef(offerManager.refreshData())) {
            //         offerManager.refreshData().value;
            //     }
            //     var turnValidationModeBackOn = false;
            //     if (validationMode === config.VALIDATION_MODE.ON) {
            //         validationMode = config.VALIDATION_MODE.OFF;
            //         turnValidationModeBackOn = true;
            //     }
            //     //doActionsCallEnabled = false;
            //     if (optionGetFunc !== undefined) {
            //         var option = optionGetFunc();
            //         self.option.value = option;
            //         if (self.refreshObv) {
            //             self.refreshObv.value = new Date().getTime();
            //         }
            //         self.optionId.value = option.id;
            //     }
            //     if (turnValidationModeBackOn) {
            //         validationMode = config.VALIDATION_MODE.ON;
            //     }
            //     //doActionsCallEnabled = true;
            //     return true;

            // });
        }/*else if(self.value !== undefined && self.addCheckboxRefreshMechanism === true) {
            self.dataRefreshMechanism = ko.computed(function () {

                offerManager.refreshData()();
                bypassValidation = true;
                if (attrGetFunc !== undefined) {
                    self.value(attrGetFunc());
                }
                //if (overrideGetFunc !== undefined) {
                //    self.override(overrideGetFunc());
                //}

                bypassValidation = false;
                return true;

            });
        }*/
        //if (self.overrideSubscriptionRef && self.overrideRefreshMechanism === undefined) {
        //    self.overrideRefreshMechanism = ko.computed(function () {
        //        offerManager.refreshData()();
        //        preventOverrideUpdate = true;
        //        if (overrideGetFunc !== undefined) {
        //            self.override(overrideGetFunc());
        //        }
        //        preventOverrideUpdate = false;
        //    });
        //}
    }

    function getOfferManager() {
        return offerManager;
    }
    function setInputName(inputNameVal) {
        inputName = inputNameVal;
    }
    function getInputName() {
        return inputName;
    }
    function getInputType() {

    }
    function setPath(p) {
        path = p;
    }

    function getPath() {
        return path;
    }

    function setVal(val) {
        if (self.value !== undefined) {
            self.value.value = val;
        } else {
            self.option.value = val;
            self.optionId.value = val.id;
        }
        return self;
    }
    function getName() {
        return valueType;
    }
    function getUnitType() {
        return unitType;
    }
    function addValidation(validationEngineInstance) {
        validator = validationEngineInstance;
        originalMainValidationStepType = validator.getMainAttrValidationStepType();
        validator.setCultureCode(globals.getActiveCultureCodeForTranslations());
        return self;
    }

    function setValidationMode(validationMOdeVal) {
        validationMode = validationMOdeVal;
    }
    function preventUndoValueUpdateWhileDragging(newValue) {
        preventUndoValueUpdate = newValue;
    }
    function setUndoVal(optionalVal) {
        self.undoVal = optionalVal || self.value.value;
    }
    function getUndoVal() {
        return self.undoVal;
    }
    function getUndoValSet() {
        return self.undoVal !== undefined && self.undoVal !== -1;
    }
    function setPreDraggingUndoVal(newValue) {
        self.preDraggingUndoVal = newValue;
    }
    function getPreDraggingUndoVal() {
        return self.preDraggingUndoVal;
    }
    function validate(optionalNewValue) {
        validationMode = config.VALIDATION_MODE.ON;
        validator.updateMainAttrValidationStepType(originalMainValidationStepType);
        if (self.undoVal !== -1) {
            self.value.value = self.undoVal;
        }
        
        if (optionalNewValue !== undefined) {
            self.formatted.value = optionalNewValue;
        } else {
            let newVal = self.formatted.value;
            self.formatted.value = newVal;
        }
        
    }

    function getValidator() {
        return validator;
    }
    ////////function getFunction(dependencyPath, comparisonOperator, compareVal) {
    ////////    var val1;
    ////////    if (inputType === config.INPUT_TYPE.SELECT) {
    ////////        val1 = self.offerManager.getAttributeGroup(dependencyPath).optionId;
    ////////    } else {
    ////////        val1 = self.offerManager.getAttributeGroup(dependencyPath).value;
    ////////    }

    ////////    switch (comparisonOperator) {
    ////////        case '===':
    ////////            return function () { return equalTo(val1(), compareVal) };
    ////////        case '>':
    ////////            return function () { return greaterThan(val1(), compareVal) };
    ////////        case '<':
    ////////            return function () { return lessThan(val1(), compareVal) };
    ////////        case '>=':
    ////////            return function () { return greaterThanOrEqualTo(val1(), compareVal) };
    ////////        case '<=':
    ////////            return function () { return lessThanOrEqualTo(val1(), compareVal) };
    ////////    }
    ////////}

    ////////function equalTo(val1, val2) {
    ////////    return val1 === val2;
    ////////}

    ////////function greaterThan(val1, val2) {
    ////////    return val1 > val2;
    ////////}

    ////////function lessThan(val1, val2) {
    ////////    return val1 < val2;
    ////////}

    ////////function greaterThanOrEqualTo(val1, val2) {
    ////////    return val1 >= val2;
    ////////}

    ////////function lessThanOrEqualTo(val1, val2) {
    ////////    return val1 <= val2;
    ////////}

    function getAttrVal() {
        return attrGetFunc();
    }

    function setAttrVal(newVal) {
        if (attrSetFunc !== undefined) {
            attrSetFunc(newVal, self);
            updateLinkedAOGs();
        }
    }

    // function alwaysNotifyValueSubscribers() {
    //     // Peter comment >> not clear if this is necessary, need to see if anything comes back from testing as not working correctly, seems to be used mainly or only in relation to COG values, if needed then watchEffect may be the solution as it's 'eager' instead of 'lazy' like watch
    //     // if (self.value) {
    //     //     self.value.extend({ notify: 'always' });
    //     // }
    //     // watchEffect(function(){
    //     //     self.value.value;
    //     //     TScMessenger.writeDebugMessage('In alwaysNotifyValueSubscribers')
    //     // });
    //     // alwaysNotifyValueSubscribersBool = true;
    //     return self;
    // }

    function setAllowOpsWhileDragging(allowOpsBoolVal) {
        allowOpsWhileDragging = allowOpsBoolVal;
        return self;
    }

    function getAllowOpsWhileDragging() {
        return allowOpsWhileDragging;
    }

    function doNotIncludeUnit() {
        self.doNotIncludeUnit = true;
        return self;
    }

    function disableUndoOpCreation() {
        createUndoOpForValueChange = false;
        return self;
    }

    function enableUndoOpCreation() {
        createUndoOpForValueChange = true;
        return self;
    }

    function getSelectOptions() {
        return selectOptions;
    }

    function getSelectOptionById(optionId) {
        var foundOption = null;
        selectOptions.forEach(function (option) {
            if (optionId === option.id) {
                foundOption = option;
            }
        });
        return foundOption;
    }

    function refreshPermission() {
        self.permissionObv.value = self.permission(self.getPath() + '.' + valueType);
    }

    function addLinkedSliderId(linkedSliderId) {
        self.linkedSliderId = linkedSliderId;
        return self;
    }

    function addLinkedAOG(linkedAODID) {
        hasLinkedAOGs = true;
        linkedAOGs.push(linkedAODID);

        return self;
    }

    function addLinkedMultiCardAOG(path, arrayFunc, valueType) {
        hasLinkedMultiCardAOG = true;
        linkedMultiCardAOGs.push({path: path, arrayFunc: arrayFunc, valueType: valueType});
        return self;
    }

    function getLinkedAOGs() {
        return linkedAOGs;
    }
    //#region Increment/Decrement Observable
    function incrementObservable(incrementType) {

        if (self.override === undefined || self.override.value) {
            passedThroughIncrementOrDecrement = true;

            var unitTypeForSwitch;

            

            if (formattingRule !== undefined) {
                if (formattingRule() === true) {
                    unitTypeForSwitch = customConversionIncrementType;
                }
            }
            
            var temp = self.formatted.value;
            
            var minusValue = isMinusValue(temp);

            if (typeof temp === 'string') {
                temp = parseFloat(temp.replace(/[^.\d]/g, ""));
            }

            if (minusValue) {
                temp = -temp;
            }



            switch (unitTypeForSwitch || unitType) {
                case config.INCREMENT_TYPE.LENGTH:
                    var tempLengthIncrement = parseFloat((offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSLENGTHINCREMENT).formatted.value).replace(/[^.\d]/g, ""));
                    temp += tempLengthIncrement;
                    break;
                case config.INCREMENT_TYPE.MASS:
                    var tempMassIncrement = parseFloat((offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSMASSINCREMENT).formatted.value).replace(/[^.\d]/g, ""));
                    temp += tempMassIncrement;
                    break;
                case config.INCREMENT_TYPE.LINEARDENSITY:
                    var tempLinearDensityIncrement = parseFloat((offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSMASSINCREMENT).formatted.value).replace(/[^.\d]/g, ""));
                    temp += tempLinearDensityIncrement;
                    break;
                case config.INCREMENT_TYPE.DEGREES:
                case config.INCREMENT_TYPE.PERCENTAGE:
                    temp += offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSPERCENTAGEINCREMENT).value.value;
                    break;
                case config.INCREMENT_TYPE.AREA:
                    temp += offerManager.activeMeasurementSystem.value.defaultAreaIncrement;
                    break;
                case config.INCREMENT_TYPE.COEFFICIENTOFDRAG:
                    temp += offerManager.getActiveOffer().getMeasurementSystem().defaultCoefficientOfDragIncrement;
                    temp = parseFloat(temp.toFixed(2));
                    break;
                case config.INCREMENT_TYPE.DRIVEAXLERATIO:
                    temp += offerManager.getActiveOffer().getMeasurementSystem().defaultDriveAxleRatioIncrement;
                    temp = parseFloat(temp.toFixed(3));
                    break;
                case config.INCREMENT_TYPE.SPEED:
                    temp += offerManager.activeMeasurementSystem.value.defaultSpeedIncrement;
                    break;
                default:
                    break;
            }
            self.formatted.value = temp;
        }
    }

    function decrementObservable() {
        if (self.override === undefined || self.override.value) {
            passedThroughIncrementOrDecrement = true;

            var unitTypeForSwitch;

            if (formattingRule !== undefined) {
                if (formattingRule() === true) {
                    unitTypeForSwitch = customConversionIncrementType;
                }
            }
            
            var temp = self.formatted.value;
            

            var minusValue = isMinusValue(temp);

            if (typeof temp === 'string') {
                temp = parseFloat(temp.replace(/[^.\d]/g, ""));
            }

            if (minusValue) {
                temp = -temp;
            }



            switch (unitTypeForSwitch || unitType) {
                case config.INCREMENT_TYPE.LENGTH:
                    var tempLengthIncrement = parseFloat((offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSLENGTHINCREMENT).formatted.value).replace(/[^.\d]/g, ""));
                    temp -= tempLengthIncrement;
                    break;
                case config.INCREMENT_TYPE.MASS:
                    var tempMassIncrement = parseFloat((offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSMASSINCREMENT).formatted.value).replace(/[^.\d]/g, ""));
                    temp -= tempMassIncrement;
                    break;
                case config.INCREMENT_TYPE.LINEARDENSITY:
                    var tempLinearDensityIncrement = parseFloat((offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSMASSINCREMENT).formatted.value).replace(/[^.\d]/g, ""));
                    temp -= tempLinearDensityIncrement;
                    break;
                case config.INCREMENT_TYPE.DEGREES:
                case config.INCREMENT_TYPE.PERCENTAGE:
                    temp -= offerManager.getAttributeGroup(config.OBJECT_TYPES.SETTINGS + '.' + config.VALUE_TYPE.SETTINGSPERCENTAGEINCREMENT).value.value;
                    break;
                case config.INCREMENT_TYPE.AREA:
                    temp -= offerManager.activeMeasurementSystem.value.defaultAreaIncrement;
                    break;
                case config.INCREMENT_TYPE.COEFFICIENTOFDRAG:
                    temp -= offerManager.getActiveOffer().getMeasurementSystem().defaultCoefficientOfDragIncrement;
                    temp = parseFloat(temp.toFixed(2));
                    break;
                case config.INCREMENT_TYPE.DRIVEAXLERATIO:
                    temp -= offerManager.getActiveOffer().getMeasurementSystem().defaultDriveAxleRatioIncrement;
                    temp = parseFloat(temp.toFixed(3));
                    break;
                case config.INCREMENT_TYPE.SPEED:
                    temp -= offerManager.activeMeasurementSystem.value.defaultSpeedIncrement;
                    break;
                default:
                    break;
            }
            self.formatted.value = temp;
        }
    }

    function isMinusValue(valToCheck) {
        if (typeof valToCheck === 'string') {
            if (valToCheck.indexOf('-') !== -1) {
                return true;
            }
        } else {
            if (valToCheck < 0) {
                return true;
            }
        }
        return false;
    }
    //#endregion Increment/Decrement Observable

    function cleanUp() {
        try {
            offerManager = null;
            if (validator !== null) {
                validator.cleanUp();
                validator = null;
            }


            permissionObject.cleanUp();
            permissionObject = null;
            
            
            var valueType = null;
            var inputType = null;
            var unitType = null;

            inputName = null;
            path = null;
            formattingRule = null;
            customFormattingFunction = null;
            customConversionIncrementType = null;
            customConversionFunction = null;
            passedThroughIncrementOrDecrement = null;
            failedValidationInfo = null;
            errorsArray = null;
            
            validationMode = null;
            doActionsCallEnabled = null;
            refreshingSelect = null;
            valueChanged = null;
            preventOverrideUpdate = null;
            byPassConversionToMM = null;

            
            attrGetFunc = null;
            attrSetFunc = null;
            optionGetFunc = null;
            overrideGetFunc = null;


            if (self.dataRefreshMechanism) {
                if(typeof self.dataRefreshMechanism === 'function') {
                    self.dataRefreshMechanism();
                }
                //utils.clearSubscriptions(self.dataRefreshMechanism._target);
                self.dataRefreshMechanism = null;
            }
            // if (self.formatted) {
            //     self.formatted.dispose();
            // }
            // if (self.optionProxy) {
            //     self.optionProxy.dispose();
            // }
            if (self.valueSubscriptionRef) {
                self.valueSubscriptionRef()
                
                // clearBeforeAndAfterSubscriptions(self.valueSubscriptionRef);
                
            }
            if (self.optionIdSubscriptionRef) {
                self.optionIdSubscriptionRef();
                // clearBeforeAndAfterSubscriptions(self.optionIdSubscriptionRef);
            }
            if (self.overrideSubscriptionRef) {
                self.overrideSubscriptionRef();
            }
            if (self.overrideRefreshMechanism) {
                self.overrideRefreshMechanism();
            }
            if (self.errorMessage) {
                if (self.errorMessage.value !== undefined) {
                    self.errorMessage.value = null;
                }
                self.errorMessage = null;
            }
            if (self.override) {
                self.override.value = null;
                self.override = null;
            }
            if (self.permissionObv) {
                if (self.permissionObv.value !== undefined) {
                    try {
                        self.permissionObv.value = null;
                    }catch(settingNullEx) {
                        TScMessenger.writeDebugMessage('AttributeObservableGroup.js, cleanUp, error cleaning up permission ref');
                    }
                }
                self.permissionObv = null;
            }

            if (self.reset) {
                // if (self.reset.value !== undefined) {
                //     self.reset.value = null;
                // }
                self.reset = null;
            }

            if (self.value) {
                // if (self.value.value !== undefined) {
                //     self.value.value = null;
                // }
                self.value = null;
            }

            if (self.valueSubscriptionRef) {
                self.valueSubscriptionRef();
            }
            if (selectOptions !== null) {
                selectOptions = null;
            }
            if (self.refreshObv) {
                //self.refreshObv(null);
                self.refreshObv = null;
            }
            self = null;
        }catch(ex) {
            var breakHere = 0;
        }
    }

    function clearBeforeAndAfterSubscriptions(ref) {
        ref.dispose();
        //utils.clearSubscriptions(ref.beforeRef._target);
        //utils.clearSubscriptions(ref.afterRef._target);
    }

    
    //function setDoActionsCallEnabled(newValue) {
    //    doActionsCallEnabled = newValue;
    //}

    this.setOfferManager = setOfferManager;
    this.getOfferManager = getOfferManager;
    this.setInputName = setInputName;
    this.getInputName = getInputName;
    this.getPath = getPath;
    this.setPath = setPath;
    this.getter = getter;
    this.setter = setter;
    this.getName = getName;
    this.addOverrideMechanism = addOverrideMechanism;
    this.addSubscription = addSubscription;
    this.addFormattingRule = addFormattingRule;
    this.setVal = setVal;
    this.permission = permission;
    this.readOnly = readOnly;
    this.readOnlyPermission = readOnlyPermission;
    this.readOnlyDependency = readOnlyDependency;
    this.visiblePermission = visiblePermission;
    this.visibleDependency = visibleDependency;
    this.visible = visible;
    this.overridePermission = overridePermission;
    this.overrideDependency = overrideDependency;
    this.resetValue = resetValue;
    this.getAttrVal = getAttrVal;
    this.setAttrVal = setAttrVal;
    this.addValidation = addValidation;
    this.getUnitType = getUnitType;
    this.incrementObservable = incrementObservable;
    this.decrementObservable = decrementObservable;
    this.addExtraUpdateFunction = addExtraUpdateFunction;
    this.getUpdateFunction = getUpdateFunction;
    this.addImmediateSaveFunction = addImmediateSaveFunction;
    this.cleanUp = cleanUp;
    this.setValidationMode = setValidationMode;
    this.setUndoVal = setUndoVal;
    this.validate = validate;
    this.handleErrorResetIfNecessary = handleErrorResetIfNecessary;
    this.getUndoValSet = getUndoValSet;
    this.getValidator = getValidator;
    // this.alwaysNotifyValueSubscribers = alwaysNotifyValueSubscribers;
    this.optionGetter = optionGetter;
    this.setFailedValidationInfo = setFailedValidationInfo;
    this.addError = addError;
    this.setAllowOpsWhileDragging = setAllowOpsWhileDragging;
    this.getAllowOpsWhileDragging = getAllowOpsWhileDragging;
    this.clearErrors = clearErrors;
    this.doNotIncludeUnit = doNotIncludeUnit;
    this.disableUndoOpCreation = disableUndoOpCreation;
    this.enableUndoOpCreation = enableUndoOpCreation;
    this.getSelectOptionById = getSelectOptionById;
    this.getSelectOptions = getSelectOptions;
    this.refreshPermission = refreshPermission;
    this.addLinkedSliderId = addLinkedSliderId;
    this.getValue = getValue;
    this.getSetterRef = getSetterRef;
    this.getUndoVal = getUndoVal;
    this.preventUndoValueUpdateWhileDragging = preventUndoValueUpdateWhileDragging;
    this.setPreDraggingUndoVal = setPreDraggingUndoVal;
    this.getPreDraggingUndoVal = getPreDraggingUndoVal;
    this.getErrorsArray = getErrorsArray;
    this.addLinkedAOG = addLinkedAOG;
    this.getLinkedAOGs = getLinkedAOGs;
    this.getFormattingRuleFunc = getFormattingRuleFunc;
    this.getCustomFormattingFunc = getCustomFormattingFunc;
    this.addLinkedMultiCardAOG = addLinkedMultiCardAOG;
    //this.preventBlankEntry = preventBlankEntry;

    //this.setDoActionsCallEnabled = setDoActionsCallEnabled;

}

export default AttributeObservableGroup;


