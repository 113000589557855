<template>
    
    <template v-if="displayOfferRelatedParts">
        <div
            id="nav-button-save"
            class="offer-nav-bar-menu"
            :title="getTranslationText('2340')"
            v-visible="offerIsOpenAndNotVehicleStatusPending()"
        >
            <div class="dropdown display-overflow">
                <a
                    href="#"
                    class="dropdown-toggle nav-buttons"
                    data-bs-toggle="dropdown"
                    data-toggle="dropdown"
                    id="save-dropdown-control"
                    :title="getTranslationText('2340')"
                    @click.prevent="toggleSaveOfferDropdown"
                >
                    <i class="fas fa-save fa-lg custom-fa-size"></i>
                    <i class="fas fa-caret-down"></i>
                </a>
                <ul
                    class="dropdown-menu"
                    role="menu"
                    aria-labelledby="dropdownSaveMenu"
                    id="dropdownSaveMenu"
                >
                    <li
                        role="presentation"
                        :title="getTranslationText('2532')"
                        @click="saveOfferOnMenu"
                    >
                        <a role="menuitem" tabindex="-1" href="#">
                            <span>{{getTranslationText('2398')}}</span>
                        </a>
                    </li>
                    <li
                        role="presentation"
                        :title="getTranslationText('2533')"
                        @click="saveAsOfferOnMenu"
                    >
                        <a role="menuitem" tabindex="-1" href="#">
                            <span>{{getTranslationText('2338')}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div id="nav-button-update" 
            class="offer-nav-bar-menu hide-from-view"
            :title="getTranslationText('2351')"
            v-visible="offerIsOpenAndVehicleStatusPending()"
            data-bind="visible: offerIsOpenAndVehicleStatusPending(), click: openSaveMenuOnClick, touchstart: openSaveMenuOnClick, css: {'nav-button-disabled': offerManager.cachingNotCompleted, 'offer-is-closed': offerIsOpen() === false}, attr: { title: navBarTextObservable().updateButtonTooltip }">
            <div class="dropdown display-overflow">
                <a href="#" 
                    class="dropdown-toggle nav-buttons" 
                    data-toggle="dropdown" 
                    id="update-dropdown-control" 
                    data-bind="click: toggleDropdown">
                    <i class="fa fa-database fa-lg custom-fa-size"></i>
                </a>
                <ul class="dropdown-menu" 
                    role="menu" 
                    aria-labelledby="dropdownUpdateMenu" 
                    id="dropdownUpdateMenu">
                    <li role="presentation" 
                        data-bind="click: updateVehicle, touchstart: updateVehicle">
                        <a role="menuitem" tabindex="-1" href="#">
                            <span data-bind="text: navBarTextObservable().updateText">{{getTranslationText('1112')}}</span>
                        </a>
                    </li>
                    <li role="presentation" 
                        @click="updateAndSubmitVehicle"
                        data-bind="click: updateAndSubmitVehicle, touchstart: updateAndSubmitVehicle">
                        <a role="menuitem" tabindex="-1" href="#">
                            <span data-bind="text: navBarTextObservable().updateAndSubmitText">{{getTranslationText('1113')}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div v-if="canShowShareButtonRef === true"
            id="nav-button-share"
            class="nav-buttons knockout-clickable-item offer-nav-bar-menu hide-from-view"
            :title="getTranslationText('2399')"
            :class="{'offer-is-closed': offerIsOpen === false}"
            @click="openShareDialog"
            data-bind="click: openShareDialogOnClick, touchstart: openShareDialogOnClick, css: {'nav-button-disabled': offerManager.cachingNotCompleted, 'offer-is-closed': offerIsOpen() === false}, attr: { title: navBarTextObservable().shareButtonTooltip }"
        >
            <i class="fas fa-share-alt"></i>
        </div>
        <!-- <div
            id="nav-button-undo"
            class="nav-buttons knockout-clickable-item offer-nav-bar-menu hide-from-view"
            :title="getTranslationText('2395')"
            :class="{'offer-is-closed': offerIsOpen === false}"
            @click="undo"
            data-bind="click: offerManager.undo, touchstart: offerManager.undo, css: {'nav-button-disabled': offerManager.undoHandlerHasNoUndoOps, 'offer-is-closed': offerIsOpen() === false}, attr: { title: navBarTextObservable().undoTooltip }"
        >
            <i class="fas fa-undo-alt"></i>
        </div>
        <div
            id="nav-button-redo"
            class="nav-buttons knockout-clickable-item offer-nav-bar-menu hide-from-view"
            :title="getTranslationText('2396')"
            :class="{'offer-is-closed': offerIsOpen === false}"
            @click="redo"
            data-bind="click: offerManager.redo, touchstart: offerManager.redo, css: {'nav-button-disabled': offerManager.undoHandlerHasNoRedoOps, 'offer-is-closed': offerIsOpen() === false}, attr: { title: navBarTextObservable().redoTooltip }"
        >
            <i class="fas fa-redo-alt"></i>
        </div> -->
        <div
            id="nav-button-undo"
            class="offer-nav-bar-menu"
            :title="getTranslationText('4635')"
            :class="{'offer-is-closed': offerIsOpen === false}"
            v-visible="offerIsOpenAndNotVehicleStatusPending()"
        >
            <div class="dropdown display-overflow keepopen">
                <a
                    href="#"
                    class="dropdown-toggle nav-buttons"
                    data-bs-toggle="dropdown"
                    data-toggle="dropdown"
                    id="save-dropdown-control"
                    :title="getTranslationText('4635')"
                    @click.prevent="toggleSaveOfferDropdown"
                >
                    <i class="fas fa-undo-alt"></i>
                    <i class="fas fa-caret-down"></i>
                </a>
                <ul
                    class="dropdown-menu"
                    role="menu"
                    aria-labelledby="dropdownUndoMenu"
                    id="dropdownUndoMenu"
                >
                    <li
                        role="presentation"
                        class="keepopen"
                        :title="getTranslationText('2395')"
                        @click="undo"
                    >
                        <a role="menuitem" tabindex="-1" href="#">
                            <span>{{ getTranslationText('4961') }}</span>
                        </a>
                    </li>
                    <li
                        role="presentation"                        
                        class="keepopen"
                        :title="getTranslationText('2396')"
                        @click="redo"
                    >
                        <a role="menuitem" tabindex="-1" href="#">
                            <span>{{ getTranslationText('4962') }}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div
            id="nav-button-swap-item"
            class="nav-buttons knockout-clickable-item offer-nav-bar-menu hide-from-view"
            :title="getTranslationText('4636')"
            :class="{'offer-is-closed': offerIsOpen === false}"
            @click="openSwapItemModal"
        >
            <i class="fas fa-exchange"></i>
        </div>
        <NavBarDivider :displayDivider="true"  />
    </template>
    <template v-if="loginScreenActive === false">
        <div :title="fullScreenButtonTooltip"
            @click="toggleFullScreen"
            id="nav-button-full-screen" 
            class="nav-buttons knockout-clickable-item">
            <i class="fas fa-lg custom-fa-size"
                :class="{ 'fa-expand': isFullScreen === false, 'fa-compress': isFullScreen === true }"    
            ></i>
        </div>
        <div @click="openSettingsModal"
            id="nav-button-settings" 
            class="nav-buttons knockout-clickable-item"
            :title="getTranslationText('2351')"
            >
            <i class="fa fa-gear fa-lg custom-fa-size"></i>
        </div>
    </template>
    
    
    <UserDropdownMenu v-if="loginScreenActive === false" />
</template>

<script>
import { ref, onMounted, watch, onUpdated } from 'vue';
import bus from '@/main';
import config from '@/services/config';
import offerManager from '@/services/offerManager';
import NavBarDivider from "./NavBarDivider.vue";
import UserDropdownMenu from "./UserDropdownMenu.vue";
import globals from '@/services/globals';

const $ = window.$;
const TScMessenger = window.TScMessenger;

const isFullScreen = ref();
// const fullScreenButtonTooltip = ref();
// watch(isFullScreen, (newValue, oldValue) => {
//     if (newValue === true) {
//         fullScreenButtonTooltip.value = config.getTranslationText('2345');
//     } else {
//         fullScreenButtonTooltip.value = config.getTranslationText('1057');
//     }
// });
watch(isFullScreen, (newValue, oldValue) => {
    globals.updateFullScreenTooltipText(newValue);
});

function openShareDialog() {
    bus.emit('openShareDialogOnClick', {});
}

function saveOfferOnMenu() {
    bus.emit('saveOfferOnMenu', {});
}

function saveAsOfferOnMenu() {
    bus.emit('saveAsOfferOnMenu', {});
}

function openSettingsModal() {
    bus.emit('openSettingsModalOnClick', {});
}

function updateAndSubmitVehicle() {
    bus.emit('updateAndSubmitVehicleClick', {});
}

function toggleSaveOfferDropdown() {
    $('#save-dropdown-control').dropdown('toggle');
}

function openSwapItemModal() {
    bus.emit('openSwapItemModalOnClick', {
        modalType: 'vehicle'
    });
}

function toggleFullScreen() {
    TScMessenger.writeDebugMessage('UserMenu.vue, toggleFullScreen triggered');
    if (isFullScreen.value) {
        exitFromFullScreenMode();
    } else {
        setAppToFullScreenMode();
    }
}

/**
 * Checks if the app is in full screen mode
 * @returns {boolean}
 */
function checkIsAppFullScreen() {
    // IE 11
    if (document.msFullscreenElement !== undefined) {
        if (document.msFullscreenElement !== null) {
            return true;
        } else {
            return false;
        }
    }
    // Firefox
    if (document.mozFullScreenElement !== undefined) {
        if (document.mozFullScreenElement !== null) {
            return true;
        } else {
            return false;
        }
    }
    // Chrome, Opera, Edge, Safari
    if (document.webkitFullscreenElement !== undefined) {
        if (document.webkitFullscreenElement !== null) {
            return true;
        } else {
            return false;
        }
    }
    return false;
}

/**
 * 
 */
function setAppToFullScreenMode() {
    // IE 11
    if (document.documentElement.msRequestFullscreen !== undefined) {
        document.documentElement.msRequestFullscreen();
    }
    // Firefox
    if (document.documentElement.mozRequestFullScreen !== undefined) {
        if (document.documentElement.requestFullscreen !== undefined) {
            document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen !== undefined) {
            document.documentElement.mozRequestFullScreen();
        }
    }
    // Chrome, Opera, Edge, Safari
    if (document.documentElement.webkitRequestFullscreen !== undefined) {
        document.documentElement.webkitRequestFullscreen();
    }
}

/**
 * 
 */
function addEventListenersForFullScreenChange() {
    // IE 11
    document.addEventListener('MSFullscreenChange', successFunction);
    document.addEventListener('MSFullscreenError', errorFunction);
    // Firefox
    document.addEventListener('fullscreenchange', successFunction);
    document.addEventListener('fullscreenerror', errorFunction);
    // Chrome, Opera, Edge, Safari
    document.addEventListener('webkitfullscreenchange', successFunction);
    document.addEventListener('webkitfullscreenerror', errorFunction);
    
    function successFunction() {
        isFullScreen.value = checkIsAppFullScreen();
        
        setTimeout(function () {
            if ($('.intercom-app.intercom-app-launcher-enabled').hasClass('intercom-app-hidden')) {
                $('.intercom-app.intercom-app-launcher-enabled').removeClass('intercom-app-hidden');
            }
        }, 100);
    }

    function errorFunction() {
        isFullScreen.value = checkIsAppFullScreen();
    }
}

/**
 * 
 */
function exitFromFullScreenMode() {
    // IE 11
    if (document.msExitFullscreen !== undefined) {
        document.msExitFullscreen()
    }
    // Firefox
    if (document.mozCancelFullScreen !== undefined) {
        document.mozCancelFullScreen();
    }
    // Chrome, Opera, Edge, Safari
    if (document.webkitExitFullscreen !== undefined) {
        document.webkitExitFullscreen();
    }
    isFullScreen.value = checkIsAppFullScreen();
}

function toggleDropDown() {
    $('.dropdown-menu').dropdown('toggle');
}

function offerIsOpenAndVehicleStatusPending() {
    if (offerManager.offerIsOpen.value === true) {
        return offerManager.vehicleStatus.value === config.VEHICLE_STATUS_TYPES.PENDING;
    } else {
        return false;
    }
}

function offerIsOpenAndNotVehicleStatusPending() {
    if (offerManager.offerIsOpen.value === true) {
        return offerManager.vehicleStatus.value !== config.VEHICLE_STATUS_TYPES.PENDING;
    } else {
        return false;
    }
}

export default {
    props: {
        displayOfferRelatedParts: {
            type: Boolean,
            default: false
        },
    },
    setup() {
        function preventUndoRedoMenuFromClosingOnClick() {
            // $('#nav-button-undo .dropdown-menu').on('click', function (event) {
            //     event.stopPropagation();
            // });

            // $('#nav-button-undo').on('hide.bs.dropdown', function (e) {
            //     var target = $(e.target);
            //     if(target.hasClass("keepopen") || target.parents(".keepopen").length){
            //         return false; // returning false should stop the dropdown from hiding.
            //     }else{
            //         return true;
            //     }
            // });
        }

        onMounted(() => {
            isFullScreen.value = checkIsAppFullScreen();
            addEventListenersForFullScreenChange();
            addEventListenersForFullScreenChange();
            preventUndoRedoMenuFromClosingOnClick();
            document.addEventListener('keydown', function (event) {
                // F11
                if (event.keyCode === 122 || (typeof event.key === 'string' && event.key.toUpperCase() === 'F11')) {
                    event.preventDefault();
                    toggleFullScreen();
                }
                // Esc
                if (event.keyCode === 27 || (typeof event.key === 'string' && event.key.toUpperCase() === 'ESCAPE')) {
                    event.preventDefault();
                    if (isFullScreen.value) {
                        exitFromFullScreenMode();
                    }
                }
            });
        });

        onUpdated(() => {
            preventUndoRedoMenuFromClosingOnClick();
        });

        return {
            // Refs
            isFullScreen,
            fullScreenButtonTooltip: globals.fullScreenButtonTooltip,
            offerIsOpen: offerManager.offerIsOpen,
            // Functions
            saveOfferOnMenu,
            saveAsOfferOnMenu,
            openShareDialog,
            openSettingsModal,
            openSwapItemModal,
            updateAndSubmitVehicle,
            toggleFullScreen,
            toggleDropDown,
            offerIsOpenAndVehicleStatusPending,
            offerIsOpenAndNotVehicleStatusPending,
            redo: offerManager.redo,
            undo: offerManager.undo,
            loginScreenActive: globals.loginScreenActive,
            getTranslationText: config.getTranslationText,
            canShowShareButtonRef: globals.user.canShowShareButtonRef
        };
    },
    components: { NavBarDivider, UserDropdownMenu }
}
</script>

<style lang="scss" scoped>
</style>