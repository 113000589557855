import config from './config';
import globals from './globals';
import utils from './utils';
import logger from './logger';
// import $ from '../assets/scripts/jquery-3.6.0.min';
import moment from '../assets/scripts/moment.min';
import * as msalAuth from './msal/auth';

const $ = window.$;
var TScMessenger = window.TScMessenger;

TScMessenger.writeDebugMessage("Security.js");
var
    securityTokenValue = '',
    validUntilValue = '',
    machineCodeValue = '',
    cdgValue = '',
    userNameProvided,
    passwordProvided,
    machineCodeProvided,
    cdgProvided,
    passwordObject = undefined,
    isFromSignUpProvided,
    dataManagerLogCallback,
    authenticationMethodToUse,
    localStorageKey = '';

function login(username, password, machineCode, cdg, async, isFromSignUp, authenticationMethod) {

    //'#########################################################################################
    //' RETURN CODES
    //' 1     The user authenticated successfully
    //' -1    The username was not found
    //' -2    The password is invalid
    //' -3    The account is in arrears
    //' -4    The trial licence has expired
    //' -5    The account has been cancelled
    //' -6    The maximum number of machine code resets has been exceeded
    //' -7    Billable Items not found
    //' -8    Associated Countries not found
    //' -9    Customer Distribution Group not found
    //' -10   An unexpected error has occurred
    //'#########################################################################################

    // var dfd = $.Deferred();
    if(globals.loginDfd) {
        return globals.loginDfd.promise();
    } else {
        globals.loginDfd = $.Deferred();
    }

    userNameProvided = username;
    passwordProvided = password;
    if(authenticationMethod === config.AUTHENTICATION_METHOD.MICROSOFT) {
        globals.user.setMicrosoftLoggedInUserAccessTokenAsPassword(password);
    }
    machineCodeProvided = machineCode === '' ? undefined : machineCode;
    cdgProvided = cdg;
    isFromSignUpProvided = isFromSignUp !== undefined ? isFromSignUp.toString().toUpperCase() : 'FALSE';
    authenticationMethodToUse = authenticationMethod !== undefined ? authenticationMethod : config.AUTHENTICATION_METHOD.TRUCKSCIENCE;
    TScMessenger.writeDebugMessage("Security.js, login");

    var options = utils.setOptions('authentication/application', 'GET', '', setAuthorisationHeader, globals.cloudServicesVersion4, async);
    // if(authenticationMethod !== config.AUTHENTICATION_METHOD.TRUCKSCIENCE_API) {
        options.xhrFields = {
            withCredentials: true
       }
    // }


    var curOp = 'login';
    var curTimeOut = options.timeout;


    // make the ajax call
    $.ajax(options)
        .then(requestSucceeded)
        .fail(requestFailed);

    // handle the ajax callback
    function requestSucceeded(data, textStatus, jqXHR) {
        var returnedCultureCode, returnedCode, trialDays, defaultCdg, defaultApplication,
            defaultVehicleType, fullDays, versions, reportGraphics, defaultApplicationCode,
            defaultVehicleTypeCode, appOptionItemObjects = [];

        securityTokenValue = jqXHR.getResponseHeader('SecurityToken');
        validUntilValue = jqXHR.getResponseHeader('ValidUntil');
        machineCodeValue = jqXHR.getResponseHeader('MachineCode');
        localStorageKey = jqXHR.getResponseHeader('LocalStorageKey');

        globals.setPreventReAuthAttempt(false);

        if (data.Result.ReturnCode > 0 || data.Result.ReturnCode === -4 || data.Result.ReturnCode === -5) {

            var palette = { colour1: data.User.Palette.Colour1, colour2: data.User.Palette.Colour2, colour3: data.User.Palette.Colour3, colour4: data.User.Palette.Colour4,
                colourNavBarBackground1: data.User.Palette.ColourNavBarBackground1, colourNavBarBackground2: data.User.Palette.ColourNavBarBackground2,
                colourTabBackground: data.User.Palette.ColourTabBackground, colourReminderLate: data.User.Palette.ColourReminderLate, colourReminderPresent: data.User.Palette.ColourReminderPresent,
                colourReminderFuture: data.User.Palette.ColourReminderFuture, colourTooltip: data.User.Palette.ColourTooltip, colourButtonDark: data.User.Palette.ColourButtonDark,
                colourButtonLight: data.User.Palette.ColourButtonLight, colourButtonBorder: data.User.Palette.ColourButtonBorder, colourButtonFont: data.User.Palette.ColourButtonFont,
                colourVehicleTypeManufacturer: data.User.Palette.ColourVehicleTypeManufacturer, colourVehicleTypeGeneric: data.User.Palette.ColourVehicleTypeGeneric,
                colourWizardBreadcrumbsActive: data.User.Palette.ColourWizardBreadcrumbsActive, colourWizardActive: data.User.Palette.ColourWizardActive,
                colourBorder1: data.User.Palette.ColourBorder1, colourBorder2: data.User.Palette.ColourBorder2, colourBoxShadow1: data.User.Palette.ColourBoxShadow1,
                colourBorderMenu: data.User.Palette.ColourBorderMenu, colourFontDark: data.User.Palette.ColourFontDark, colourFontLight: data.User.Palette.ColourFontLight,
                colourFontOther1: data.User.Palette.ColourFontOther1, colourFontOther2: data.User.Palette.ColourFontOther2, colourFontOther3: data.User.Palette.ColourFontOther3,
                colourToastrInfo: data.User.Palette.ColourToastrInfo
            };

            reportGraphics = {
                reportHeaderURL: data.User.ReportGraphics.ReportHeaderURL,
                reportFooterURL: data.User.ReportGraphics.ReportFooterURL,
                landscapeReportHeaderLeftURL: data.User.ReportGraphics.LandscapeReportHeaderLeftURL
            };
            if (data.User.UserType.indexOf('Trial') !== -1) {
                reportGraphics.landscapeReportHeaderLeftURL = config.getTrialUserReportLogoURL();
            }
            var decimalPlaces = {};
            if(data.User.DecimalPlaces != undefined) {
                decimalPlaces = {
                    decimalsHighPricedItems: data.User.DecimalPlaces.DecimalsHighPricedItems ? data.User.DecimalPlaces.DecimalsHighPricedItems : 0,
                    decimalsMediumPricedItems: data.User.DecimalPlaces.DecimalsMediumPricedItems ? data.User.DecimalPlaces.DecimalsMediumPricedItems : 0,
                    decimalsLowPricedItems: data.User.DecimalPlaces.DecimalsLowPricedItems ? data.User.DecimalPlaces.DecimalsLowPricedItems : 0
                };
            } else {
                decimalPlaces = {
                    decimalsHighPricedItems: 0,
                    decimalsMediumPricedItems: 0,
                    decimalsLowPricedItems: 0
                };
            }
            var reminderDate = data.User.Reminders;

            defaultCdg = data.User.CustomerDistributionGroups.filter(function (cdgElement) {
                return cdgElement.Default;
            });

            defaultApplication = data.User.AccessibleApplications.filter(function (application) {
                return application.Default;
            });

            defaultVehicleType = data.User.AccessibleVehicleTypes.filter(function (vehicleType) {
                return vehicleType.Default;
            });

            defaultApplicationCode = defaultApplication.length > 0 ? defaultApplication[0].Code : '';
            defaultVehicleTypeCode = defaultVehicleType.length > 0 ? defaultVehicleType[0].Code : '';

            if (data.User === null) {
                fullDays = 0;
                trialDays = 0;
                versions = null;
            } else {
                trialDays = data.User.TrialDays;
                fullDays = data.User.FullDays;
                versions = data.Versions;
            }

            var validUntilOffline, validUntilOfflineMillis, date;
            if (trialDays > 0) {
                validUntilOfflineMillis = Date.now() + (trialDays * config.millisInDay);
                date = new Date(validUntilOfflineMillis);
                validUntilOffline = moment(date).format("M/D/YYYY h:mm:ss a"); // User Story 17565
            } else {
                validUntilOfflineMillis = Date.now() + (fullDays * config.millisInDay);
                date = new Date(validUntilOfflineMillis);
                validUntilOffline = moment(date).format("M/D/YYYY h:mm:ss a"); // User Story 17565
            }

            globals.machineCodeFlag.value = data.User.MachineCodeFlag || 'NOT_SET'; // User Story 17565

            data.User.AppOptionItems.forEach(function (appOptionItemString) {
                appOptionItemObjects.push(JSON.parse(appOptionItemString));
            });

            globals.setStorageKey(localStorageKey);
            globals.user.getMostRecentUser();

            // if(data.User.AppMessengerMode && (data.User.AppMessengerMode === 'DEBUG' || data.User.AppMessengerMode === 'TIMER')) {
            //     if(data.User.AppMessengerMode === 'DEBUG') {
            //         TScMessenger.setMessengerModeToDebug();
            //     } else if(data.User.AppMessengerMode === 'TIMER') {
            //         TScMessenger.setMessengerModeToTimer();
            //     }
            //     TScMessenger.setIntercomCallback(globals.getSendEventInfoToIntercomCallback());
            // }
            utils.handleAppMessengerModeOverride(data.User.AppMessengerMode);
            

            TScMessenger.writeDebugMessage("Security.js, requestSucceeded, updating user");
            globals.user.updateUser({
                firstName: data.User.FirstName,
                lastName: data.User.LastName,
                emailAddress: data.User.EmailAddress,
                position: data.User.Position,
                directNumber: data.User.DirectNumber,
                mobileNumber: data.User.MobileNumber,
                companyNumber: data.User.CompanyNumber,
                address: data.User.Address,
                cultureCode: data.User.CultureCode,
                reminders: data.User.Reminders,
                // securityToken: securityTokenValue,
                // validUntil: validUntilValue,
                // validUntilOffline: validUntilOffline,
                // machineCode: machineCodeValue,
                username: data.User.Username,
                // password: password,
                palette: palette,
                permissions: data.User.Permissions,
                //appOptionItems: data.User.AppOptionItems,
                appOptionItems: appOptionItemObjects,
                decimalPlaces: decimalPlaces,
                reminderDate: reminderDate,
                customerDistributionGroups: data.User.CustomerDistributionGroups,
                accessibleApplications: data.User.AccessibleApplications,
                accessibleVehicleTypes: data.User.AccessibleVehicleTypes,
                cdg: defaultCdg[0].Id,
                defaultApplication: defaultApplicationCode,
                defaultVehicleType: defaultVehicleTypeCode,
                trainingUrl: data.User.TrainingUrl,
                supportEmailAddress: data.User.SupportEmailAddress,
                supportTelephoneNumber: data.User.SupportTelephoneNumber,
                supportTelephoneNumberForLink: data.User.SupportTelephoneNumberForLink,
                supportWebsite: data.User.SupportWebsite,
                countryName: data.User.CountryName,
                activeLegislationId: data.User.LegislationId,
                activeMeasurementSystemId: data.User.MeasurementSystemId,
                lengthIncrement: data.User.LengthIncrement,
                massIncrement: data.User.MassIncrement,
                percentageIncrement: data.User.PercentageIncrement,
                reportGraphics: reportGraphics,
                colourDrawings: data.User.ColourDrawings,
                loggedInUsingEmail: data.User.LoggedInUsingEmail,
                emailVerifiedStatus: data.User.EmailVerifiedStatus,
                userType: data.User.UserType,
                trialCalculationsRemaining: data.User.TrialCalculationsRemaining,
                numberOfUsesBeforeReferralPrompt: data.User.NumberOfUsesBeforeReferralPrompt,
                numberOfUsesSinceLastPrompt: data.User.NumberOfUsesSinceLastPrompt,
                firstLogin: data.User.FirstLogin,
                trialDaysRemaining: trialDays,
                billingDetails: data.User.BillingDetails,
                intercomUserId: data.User.IntercomUserId,
                companyName: data.User.CompanyName,
                companyId: data.User.CompanyId,
                expiryDate: data.User.ExpiryDate,
                firstLoginDate: data.User.FirstLoginDate,
                latestLoginDate: data.User.LatestLoginDate,
                userPlan: data.User.UserPlan,
                authenticationMethod: authenticationMethodToUse,
                integrations: data.User.Integrations,
                defaultReportPdfPageSize: data.User.DefaultReportPdfPageSize,
                userLicenceInstanceId: data.User.UserLicenceInstanceId,
                isMemberOfDistributorCompany: data.User.IsMemberOfDistributorCompany,
                companyAdditionalNote: data.User.CompanyAdditionalNote,
                specifyBodyPositionAs: data.User.SpecifyBodyPositionAs,
                specifyEquipmentPositionAs: data.User.SpecifyEquipmentPositionAs,
                countryAbbreviation: data.User.CountryAbbreviation,
                edition: data.User.Edition,
                product: data.User.Product,
                appMessengerModeOverride: data.User.AppMessengerMode || undefined
            });

            globals.updateActiveCultureCode(data.User.CultureCode);
        }

        logger.log('Retrieved security token from data source', '', '', config.showDebugToast);

        //$('#applicationBody').append('<script>(function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic(\'reattach_activator\'); ic(\'update\', intercomSettings); } else { var d = document; var i = function () { i.c(arguments) }; i.q = []; i.c = function (args) { i.q.push(args) }; w.Intercom = i; function l() { var s = d.createElement(\'script\'); s.type = \'text/javascript\'; s.async = true; s.src = \'https://widget.intercom.io/widget/gvykpldh\'; var x = d.getElementsByTagName(\'script\')[0]; x.parentNode.insertBefore(s, x); } if (w.attachEvent) { w.attachEvent(\'onload\', l); } else { w.addEventListener(\'load\', l, false); } } })()</script>');

        if (globals.user.displayPerformance() && !globals.scriptTagWithSrcExistsInHtml('https://www.bing.com/api/maps/mapcontrol?callback=loadMap&setMkt=en-US')) {
            $('#applicationBody').append('<script type="text/javascript" src="https://www.bing.com/api/maps/mapcontrol?callback=loadMap&setMkt=en-US" async defer></script>');
        }

        globals.loginDfd.resolve({
            result: data.Result,
            trialDays: trialDays,
            versions: versions,
            fullDays: fullDays,
            reportGraphics: reportGraphics
        });


    }

    function requestFailed(xhr, ajaxOptions, thrownError) {
        let returnObj = {
            returnCode: -10,
            failedOp: curOp,
            opTimeout: curTimeOut,
            errorMessage: xhr.statusText
        };

        globals.loginDfd.reject(returnObj);
    }

    return globals.loginDfd.promise();

}

function forgottenPassword(email) {

    var dfd = $.Deferred();

    // make the ajax call
    $.ajax(utils.setOptions('authentication/credentials?email=' + email, 'GET', '', undefined, globals.cloudServicesVersion1))
        .then(requestSucceeded)
        .fail(requestFailed);

    // handle the ajax callback
    function requestSucceeded(data, textStatus, jqXHR) {

        logger.log('Retrieved security token from data source', '', '', config.showDebugToast);
        dfd.resolve({ message: data });

    }

    function requestFailed(xhr, ajaxOptions, thrownError) {
        dfd.reject({ errorMessage: xhr.statusText });
    }

    return dfd.promise();

}

//function changePassword(oldPassword, newPassword) {

//    var dfd = $.Deferred();

//    //securityTokenValue = securityToken;

//    passwordObject = {
//        "OldPassword": oldPassword,
//        "NewPassword": newPassword
//    }

//    // make ajax call
//    $.ajax(utils.setPostOptions('users/changepassword', 'POST', 'json', passwordObject, setChangePasswordHeader, globals.cloudServicesVersion1))
//        .done(requestCompleted)
//        .fail(requestFailed);

//    // handle the ajax callback
//    function requestCompleted(data, textStatus, jqXHR) {

//        //update the local security token with the one received from the data layer
//        var securityToken, validUntil;
//        securityToken = jqXHR.getResponseHeader('SecurityToken');
//        validUntil = jqXHR.getResponseHeader('ValidUntil');
//        setSecurityToken(securityToken, validUntil);

//        dfd.resolve({ chgPwd: data });

//    };

//    function requestFailed(xhr, ajaxOptions, thrownError) {
//        dfd.reject({ errorMessage: xhr.statusText });
//    }

//    return dfd.promise();
//}

function logout(logoutFrom3rdPartyIdentityProvider) {
    var dfd = $.Deferred();
    // clear security token and valid until
    securityTokenValue = '';
    validUntilValue = '';

    // Shutdown Intercom
    if (typeof window.Intercom !== 'undefined') {
        setTimeout(function () {
            try {
                window.Intercom('shutdown');
                var bootObject = {};
                bootObject[config.INTERCOM_USER.APP_ID] = config.intercomAppId;
                window.Intercom('boot', bootObject);
            } catch (error) {
                logger.log(error.errorMessage, undefined, "services/security", config.showDebugToast);
                dataManagerLogCallback("Error when trying to update Intercom to remove user when logging out", error, "security.logout", config.LOG_MESSAGE_TYPE.ERROR);
            }

        }, 1000);
    }

    if (globals.user.getAuthenticationMethod() === config.AUTHENTICATION_METHOD.GOOGLE && logoutFrom3rdPartyIdentityProvider === true && globals.isOnline.value === true) {
        //var auth2 = gapi.auth2.getAuthInstance();
        // window.gapi.auth2.getAuthInstance().signOut().then(function () {
        //     TScMessenger.writeDebugMessage('User logged out');
        //     //gapi.auth2.getAuthInstance().disconnect();
        //     globals.user.clearUser();
        //     dfd.resolve();
        // });

        // https://developers.google.com/identity/gsi/web/guides/revoke
        // window.google.accounts.id.revoke(globals.user.getEmailAddress(), done => {
        //     globals.user.clearUser();
        //     dfd.resolve();
        // });
        globals.user.clearUser();
        dfd.resolve();
    } else if (globals.user.getAuthenticationMethod() === config.AUTHENTICATION_METHOD.MICROSOFT && logoutFrom3rdPartyIdentityProvider === true && globals.isOnline.value === true) {
        localStorage.removeItem('loginHint');
        let msalAuthContext = msalAuth.getAuthContext();
        if (msalAuthContext) {
            msalAuthContext.logoutRedirect({account: msalAuthContext.getActiveAccount()});
        }
        globals.user.clearUser();
        dfd.resolve();
    } else {
        // empty local and session storage
        globals.user.clearUser();

        dfd.resolve();
    }

    return dfd.promise();
}

function getSecurityToken() {

    // if (securityTokenValue === '') {
    //     //try to retrieve it from local storage
    //     securityTokenValue = globals.user.securityToken;
    // }

    return securityTokenValue;

}

function getMachineCode() {
    return machineCodeValue;
}

function setSecurityToken(token, validUntil, machineCode) {

    //store the values in memory for access application-wide
    securityTokenValue = token;
    validUntilValue = validUntil;

    //store it locally in case the user opens the app when the security details are still valid
    // globals.user.updateUser({
    //     // securityToken: securityTokenValue,
    //     // validUntil: validUntilValue,
    //     machineCode: machineCode
    // });

}

function getUserNameProvided() {
    return userNameProvided;
}

function securityTokenIsValid() {

    var now = moment.utc();
    //var zone = moment().zone();
    //var nowGmt = now.add(zone, 'minutes');

    var tokenIsValid = true;

    // //get the security token from the user object in memory
    // securityTokenValue = globals.user.getSecurityToken();
    // validUntilValue = globals.user.getValidUntil(globals.isOnline.value);

    var validUntilMoment;
    //validUntilMoment = moment(moment(validUntilValue, "M/D/YYYY h:mm:ss tt").format('YYYY-MM-DD HH:mm:ss'));
    validUntilMoment = moment.utc(validUntilValue, "M/D/YYYY h:mm:ss a"); // User Story 17565
    //validUntilMoment = moment.utc(validUntilMoment);


    var loginData;

    //if the token is invalid then give up
    if (securityTokenValue === undefined || validUntilValue === undefined ||
        validUntilValue === '' || securityTokenValue === '' ||
        securityTokenValue === null || validUntilValue === null) {
        tokenIsValid = false;
    } else {
        //need to check if the current in-memory credentials are valid
        if (now.isBefore(validUntilMoment)) {
            tokenIsValid = true;
        } else {
            tokenIsValid = false;
        }
    }

    if (!tokenIsValid) {
        //the in-memory token is not valid, so need to check if the stored tokens are valid
        if (globals.isPrivateBrowsingDisabled() === true) {

            var userName, password, machineCode, loginSucceeded;

            //see if the credentials have been stored locally
            userName = "";//globals.user.getEmailAddress();
            password = "";//globals.user.getPassword();
            machineCode = '';//globals.user.getMachineCode();

            let applyCookieFirstTimeUpDateHack = false;
            if(config.appVersionNumber === '2024.3.57' && localStorage.getItem('localStorageState') === null) {
                globals.user.getMostRecentUser();

                if(globals.user.getPassword() !== '' && globals.user.getPassword() !== undefined && globals.user.getPassword() !== null) {
                    applyCookieFirstTimeUpDateHack = true;

                    userName = globals.user.getEmailAddress();
                    password = globals.user.getPassword();
                    machineCode = globals.user.getMachineCode();

                }

            }

            let changingLanguageCDG = localStorage.getItem('tempCDG');
            if(changingLanguageCDG !== null) {
                cdgValue = changingLanguageCDG;
                localStorage.removeItem('tempCDG');
            }else {
                cdgValue = globals.user.getCdg();
            }

            machineCode = machineCode === '' ? undefined : machineCode;
            loginSucceeded = false;
            var authenticationMethod = globals.user.getAuthenticationMethod();
            if(authenticationMethod === config.AUTHENTICATION_METHOD.MICROSOFT) {
                let msalAuthContext = msalAuth.getAuthContext();
                if (msalAuthContext) {
                    userName = msalAuthContext.getActiveAccount().username;
                    password = globals.user.getMicrosoftLoggedInUserAccessTokenAsPassword();
                }

            }
            // if (userName !== '' && password !== '') {
                if (navigator.onLine === true && !globals.getPreventReAuthAttempt() && (applyCookieFirstTimeUpDateHack || (typeof localStorage.getItem('authMethod') === 'string' && localStorage.getItem('authMethod') !== '' && localStorage.getItem('authMethod') !== 'null'))) {
                    $.when(login(userName, password, machineCode, cdgValue, false, false, authenticationMethod)).then(

                        function (success) {
                            globals.loginDfd = null;
                            if (success.result.ReturnCode > 0) {
                                //the user was successfully authenticated so we can continue
                                loginSucceeded = true;

                            } else {
                                //authentication was unsuccessful, so we cannot continue
                                globals.setPreventReAuthAttempt(true);
                                loginSucceeded = false;
                            }
                            loginData = success;
                        },

                        function (error) {
                            globals.setPreventReAuthAttempt(true);
                            globals.loginDfd = null;
                            //authentication was unsuccessful, so we cannot continue
                            loginSucceeded = false;

                            loginData = { error: error };

                        }
                    );
                } else {
                    loginSucceeded = false;
                }


            // } else {
            //     loginSucceeded = false;
            // }

            return { IsValid: loginSucceeded, LoginData: loginData};

        } else {
            //in-memory token is not valid and user has enabled private browsing
            return { IsValid: false, LoginData: loginData };
        }

    } else {
        return { IsValid: true, LoginData: loginData };
    }

}

function setAuthorisationHeader(xhr) {
    // xhr.withCredentials = true;
    xhr.setRequestHeader('UserName', userNameProvided);
    xhr.setRequestHeader('Password', passwordProvided);
    xhr.setRequestHeader('ApplicationType', 'HTML5');

    if(config.appVersionNumber === '2024.3.57' && localStorage.getItem('localStorageState') === null) {
        xhr.setRequestHeader('MachineCode', machineCodeProvided);
    } else {
        xhr.setRequestHeader('MachineCode', machineCodeValue);
    }
    xhr.setRequestHeader('CDG', cdgProvided);
    xhr.setRequestHeader('IsFromSignUp', isFromSignUpProvided);
    xhr.setRequestHeader('AuthenticationMethod', authenticationMethodToUse);
    if(authenticationMethodToUse === config.AUTHENTICATION_METHOD.TRUCKSCIENCE_API) {
        xhr.setRequestHeader('SecurityToken', security.getSecurityToken());
        let isInIframe = window.self !== window.top;
        xhr.setRequestHeader('Embedded', isInIframe);
    }
}

function setChangePasswordHeader(xhr, data) {
    xhr.setRequestHeader('SecurityToken', securityTokenValue);
}

function registerDataManagerLogCallback(callback) {
    dataManagerLogCallback = callback;
}

var security = {
    validUntilValue: validUntilValue,
    login: login,
    forgottenPassword: forgottenPassword,
    logout: logout,
    getSecurityToken: getSecurityToken,
    setSecurityToken: setSecurityToken,
    securityTokenIsValid: securityTokenIsValid,
    getUserNameProvided: getUserNameProvided,
    //changePassword: changePassword,
    registerDataManagerLogCallback: registerDataManagerLogCallback,
    getMachineCode: getMachineCode
};


export default security