<template>
    <popup-modal ref="popup">
        <div id="modal" class="messageBox offerManagementModal-container openTsvFileModal-container">
            <div class="modal-header modal-header-text">
                <h4 class="modal-title">
                    <span data-bind="text: getTranslationText('4071')">{{getTranslationText('4071')}}</span>
                </h4>
            </div>
            <div class="modal-body modal-body-text">
                <div class="modal-label-input-container">
                    <div class="modal-label">
                        <span data-bind="text: getTranslationText('4072')">{{getTranslationText('4072')}}</span>
                    </div>
                    <div class="file-input-div">
                        <input id="tsv-file-select" 
                            name="tsvFile" 
                            type="file" 
                            accept=".tsv"
                            @change="checkFileType"
                            data-bind="event: { change: checkFileType }" />
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-default btn-truckscience btn-primary"
                    @click="ok"
                    data-bind="click: ok, touchstart: ok">
                    <span data-bind="text: getTranslationText('271')">{{getTranslationText('271')}}</span>
                </button>
                <button class="btn btn-default btn-truckscience"
                    @click="cancel"
                    data-bind="click: cancel, touchstart: cancel">
                    <span data-bind="text: getTranslationText('65')">{{getTranslationText('65')}}</span>
                </button>
            </div>
        </div>
    </popup-modal>
</template>

<script>
import { ref, toRef, shallowRef, onMounted, nextTick } from 'vue';

import config from '@/services/config';
import globals from '@/services/globals';
import utils from '@/services/utils';
import dataManager from '@/services/dataManager';

import PopupModal from './PopupModal.vue';
import CustomMessageBox from '@/services/CustomMessageBox';

var $ = window.$;
var TScMessenger = window.TScMessenger;

export default {
    name: 'OpenTsvFileModal',
    components: { PopupModal },
    props: {
        propsForModal: Object
    },
    setup (props) {
        const opts = toRef(props, "propsForModal");
        const popup = ref(null);
        
        let resolvePromise;

        function show(opts = {}) {
           // Once we set our config, we tell the popup modal to open
            this.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                this.rejectPromise = reject
            })
        }

        function checkFileType() {
            var fileIsValid = true,
                errorObject = null;
            var fileSelect = document.getElementById('tsv-file-select');
            var files = fileSelect.files;

            if (files.length > 0) {
                var file = files[0],
                    extension = file.name.slice(-4).toLowerCase();

                if (extension !== config.VALID_FILE_INPUT_EXTENSIONS.TSV) {
                    fileIsValid = false;
                    errorObject = {
                        messageCode: '4074'
                    };
                }

                if (fileIsValid === true) {
                    TScMessenger.writeDebugMessage('OpenTsvFileModal.vue, fileIsValid=true');
                } else {
                    TScMessenger.writeDebugMessage('OpenTsvFileModal.vue, fileIsValid=false');
                    if (errorObject !== null && errorObject.variable !== undefined) {
                        globals.showDialog(new CustomMessageBox(config.getMessageText(errorObject.messageCode, globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference(errorObject.messageCode)));
                    } else {
                        globals.showDialog(new CustomMessageBox(config.getMessageText(errorObject.messageCode, globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference(errorObject.messageCode)));
                    }
                }
            }
        }

        function close(data) {
            popup.value.close();
            resolvePromise(data);
        }

        function ok() {
            // Check that file has been selected
            var fileIsValid = true,
                errorObject = null;
            var fileSelect = document.getElementById('tsv-file-select');
            var files = fileSelect.files;

            if (files.length > 0) {
                var file = files[0],
                    extension = file.name.slice(-4).toLowerCase();

                if (extension !== config.VALID_FILE_INPUT_EXTENSIONS.TSV) {
                    fileIsValid = false;
                    errorObject = {
                        messageCode: '4074'
                    };
                }

                if (fileIsValid === true) {
                    globals.showSpinner();

                    var formData = new FormData();
                    formData.append('tsvFile', file, file.name);

                    dataManager.postTransSolveFileData(formData)
                        .then(function (data) {
                            if (data.Result.ReturnCode > 0) {
                                // self.cleanUp();
                                // dialog.close(self, data);
                                // close(data);
                                if(utils.itemHasSideViewGraphic(data.Offer.Configuration)) {
                                    utils.translateGraphicToXmlDoc(data.Offer.Configuration.VehicleDrawing).then(function (sideElevationXmlDoc) {
                                        if(utils.isValidDecompressedGraphicFile(sideElevationXmlDoc)) {
                                            data.Offer.Configuration.DecompressedVehicleDrawing = sideElevationXmlDoc;
                                            if(utils.itemHasTopViewGraphic(data.Offer.Configuration)) {
                                                utils.translateGraphicToXmlDoc(data.Offer.Configuration.VehicleTopViewDrawing).then(function (topElevationXmlDoc) {
                                                    if(utils.isValidDecompressedGraphicFile(topElevationXmlDoc)) {
                                                        data.Offer.Configuration.DecompressedVehicleTopViewDrawing = topElevationXmlDoc;
                                                    }
                                                    // getActiveOffer().getConfiguration().setDecompressedVehicleTopViewDrawing(topElevationXmlDoc);
                                                    close(data);
                                                });
                                            }else {
                                                close(data);
                                            }
                                        }else {
                                            close(data);
                                        }
                                        
                                        // getActiveOffer().getConfiguration().setDecompressedVehicleDrawing(sideElevationXmlDoc);
                                        
                                    }).catch(function (){
                                        close(data);
                                    });
                                }else if(utils.itemHasTopViewGraphic(data.Offer.Configuration)) {
                                    utils.translateGraphicToXmlDoc(data.Offer.Configuration.VehicleTopViewDrawing).then(function (topElevationXmlDoc) {
                                        if(utils.isValidDecompressedGraphicFile(topElevationXmlDoc)) {
                                            data.Offer.Configuration.DecompressedVehicleTopViewDrawing = topElevationXmlDoc;
                                        }
                                        close(data);
                                    });
                                }else {
                                    close(data);
                                }
                            } else {
                                globals.hideSpinner();
                                if (data.Result.ReturnCode === -1 || data.Result.ReturnCode === -2) {
                                    globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                                } else if (data.Result.ReturnCode === -3) {
                                    globals.showDialog(new CustomMessageBox(config.getMessageText('4147', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('4147')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                                } else if (data.Result.ReturnCode === -4) {
                                    var listItems = '';
                                    data.ErrorCodes.forEach(function (errorCode) {
                                        listItems += '<li>' + config.getTranslationText(errorCode) + '</li>'
                                    });
                                    var errorCodeList = '<ul>' + listItems + '</ul>';
                                    globals.showDialog(new CustomMessageBox(config.getMessageText('4075', globals.getActiveCultureCodeForTranslations(), errorCodeList), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('4075')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                                }
                            }
                        })
                        .fail(function (error) {
                            globals.hideSpinner();
                            dataManager.log("openTsvFileModal.ok, dataManager.postTransSolveFileData, fail", error, 'OpenTsvFileModal', config.LOG_MESSAGE_TYPE.ERROR);
                            globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                        });
                } else {
                    if (errorObject !== null && errorObject.variable !== undefined) {
                        globals.showDialog(new CustomMessageBox(config.getMessageText(errorObject.messageCode, globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference(errorObject.messageCode)));
                    } else {
                        globals.showDialog(new CustomMessageBox(config.getMessageText(errorObject.messageCode, globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference(errorObject.messageCode)));
                    }
                } 
            } else {
                globals.showDialog(new CustomMessageBox(config.getMessageText('4074', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('4074')));
            }
        }

        function cancel() {
            close();
        }

        return {
            popup,
            show,
            ok,
            cancel,
            checkFileType,
            // Text
            getTranslationText: config.getTranslationText
        }
    }
}
</script>

<style lang="scss" scoped>

</style>