<template>
    <tr class="menu-li menu-li-item menu-tr-selection-group"
        v-visible="isVisible()"
        data-bind="visible: permissionObv().visible, template: {name:'template-menu-selection-group' }">
        <td colspan="2" class="menu-td-selectionGroup" :class="selectionGroup.customCss" data-bind="css: customCss">
            <template v-if="selectionGroup.displaySelectionWizard.value === false">
                <div v-if="selectionGroup.label !== '' && selectionGroup.displayLabel !== true" class="menu-label-selectionGroup" data-bind="text: label">{{selectionGroup.label}}</div>
                <table>
                    <tr>
                        <td v-if="selectionGroup.displayButton === true || selectionGroup.dropDownOptions.value.length === 0" class="selectionGroup-button-td">
                            <a class="btn btn-primary btn-lg selectionGroup-button" 
                                @click.prevent="selectionGroup.click"
                                :id="selectionGroup.tooltipId"
                                :data-content="selectionGroup.tooltipMessage()"
                                href="#" 
                                data-bind="click: click, touchstart: click, attr: {id: tooltipId, 'data-content': tooltipMessage()}">
                                <i class="fa fa-plus-circle"></i>
                            </a>
                        </td>
                        <td v-if="selectionGroup.displayDropDownButton === true && selectionGroup.dropDownOptions.value.length > 0" class="selectionGroup-button-td">
                            <div class="dropdown display-overflow">
                                <a href="#" 
                                    class="btn btn-primary btn-lg selectionGroup-button dropdown-toggle" 
                                    data-toggle="dropdown" 
                                    aria-haspopup="true"
                                    aria-expanded="true"
                                    :id="selectionGroup.tooltipId"
                                    :data-content="selectionGroup.tooltipMessage()"
                                    data-bind="attr: {id: tooltipId, 'data-content': tooltipMessage()}">
                                    <i class="fa fa-plus-circle"></i>
                                </a>
                                <ul class="dropdown-menu" data-bind="foreach: dropDownOptions">
                                    <li role="presentation"
                                        v-for="option in selectionGroup.dropDownOptions.value" :key="option"
                                        @click="selectionGroup.click(option.id)"
                                        data-bind="click: function () { $parent.click(id) }, touchstart: function () { $parent.click(id) }">
                                        <a v-if="option.displayIconBesideText === true" role="menuitem" tabindex="-1" href="#"><span>{{option.label}}</span><i class="fa" :class="option.iconToDisplayClass" data-bind="css: iconToDisplayClass"></i></a>
                                        <a v-else role="menuitem" tabindex="-1" href="#"><span>{{option.label}}</span></a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                        <td class="selectionGroup-slider-td">
                            <table v-if="selectionGroup.selectedMenuItems.value.length > 0" class="selectionGroup-slider-container-table">
                                <tr>
                                    <td>
                                        <div class="clickable-item div-equipment-left-arrow" 
                                            :id="selectionGroup.displayId + '-slider-prev'" 
                                            @click="goToPrev"
                                            data-bind="attr: {id: displayId + '-slider-prev'}">
                                            <i class="fa fa-2x fa-angle-left"></i>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="menu-accessories-container-div">
                                            <Carousel ref="myCarousel" :itemsToShow="2.5" class="menu-accessories-div equipment-slider">
                                                <template v-for="(item, index) in selectionGroup.selectedMenuItems.value" :key="selectionGroup.displayId + '-slider-' + item.id() + '-' + index">
                                                    <Slide v-if="isSlideVisible(item)">
                                                        <div class="menu-div-item menu-equipment-card" 
                                                            @click="item.activateSelectionGroupItem(selectionGroup, index)"
                                                            :class="{ 'active': item.isActive.value === true, 'sg-tab-open': selectionGroup.activeMenuCardStep.value !== null }"
                                                            :id="selectionGroup.displayId + '-slider-' + item.id()"
                                                            data-bind="click: function () { activateSelectionGroupItem($parent, $index); }, touchstart: function () { activateSelectionGroupItem($parent); }, template: { name: 'template-menu-card' }, css: { 'active': isActive() === true, 'sg-tab-open': $parent.activeMenuCardStep() !== null }, attr: { id: $parent.displayId + '-slider-' + id() } ">
                                                            <MenuCard :menuCardFromParent="item" 
                                                                @copySelectionGroupItemClicked="copySelectionGroupItemClicked" 
                                                                @deleteSelectionGroupItemClicked="deleteSelectionGroupItemClicked"
                                                                @pinSelectionGroupItemClicked="pinSelectionGroupItemClicked">
                                                            </MenuCard>
                                                        </div>
                                                    </Slide>
                                                </template>
                                            </Carousel>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="clickable-item div-equipment-right-arrow" 
                                            :id="selectionGroup.displayId + '-slider-next'"
                                            @click="goToNext"
                                            data-bind="attr: {id: displayId + '-slider-next'}">
                                            <i class="fa fa-2x fa-angle-right"></i>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
                <div v-if="selectionGroup.activeMenuCardStep.value !== null" class="menu-card-active-step-container">
                    <MenuContent :activeMenuStepFromParent="selectionGroup.activeMenuCardStep.value.activeMenuStep"></MenuContent>
                </div>
            </template>
            <MenuContentItemSelectionWizard 
                v-if="selectionGroup.canAddItemUsingSelectionWizard === true && selectionGroup.displaySelectionWizard.value === true" 
                :valueFromParent="selectionGroup.getMenuGroupSelectionWizard()" 
                :renderAsFromParent="selectionGroup.getMenuGroupSelectionWizard().renderAs">
            </MenuContentItemSelectionWizard>
        </td>
    </tr>
</template>

<script>
import { ref, watch, onUpdated, toRef, onMounted, onUnmounted, isRef } from 'vue';
import MenuCard from './selectionGroup/MenuCard.vue';
import MenuContent from './MenuContent.vue';
import MenuContentItemSelectionWizard from './MenuContentItemSelectionWizard.vue';
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

const $ = window.$;
const TScMessenger = window.TScMessenger;

export default {
    name: 'MenuContentItemSelectionGroup',
    props: {
        valueFromParent: Object,
        activeMenuCardStepFromParent: Object
    },
    setup(props) {
        const selectionGroup = toRef(props, "valueFromParent");
        const selectionGroupActiveMenuStep = toRef(props, "activeMenuCardStepFromParent");
        const myCarousel = ref(null);

        /**
         * Register function on MenuSelectionGroup so it can be used in addSliderToStep function
         */
        selectionGroup.value.registerAddSliderToStepCallback(addSliderToStepCallback);

        selectionGroup.value.setActivateMenuTabFunction(function() {
            setTimeout(function () {
                callResizeFunctions();
            }, 16);
        });

        if(selectionGroup.value.activeMenuCardStep.value !== null) {
            selectionGroupActiveMenuStep.value = selectionGroup.value.activeMenuCardStep.value.activeMenuStep.value;
        }

        watch(() => props.activeMenuCardStepFromParent,
            (newValue, oldValue) => {
                // TScMessenger.writeDebugMessage('in watch');
                selectionGroupActiveMenuStep.value = newValue;
            });

        onUpdated(()=>{
            if(selectionGroup.value.activeMenuCardStep.value !== null) {
                // selectionGroup.value.activeMenuCardStep.value.activeMenuStep.updateJsElements();
                var index = selectionGroup.value.selectedMenuItems.value.findIndex(function(el){
                    return el.path === selectionGroup.value.activeMenuCardStep.value.path;
                });
                if(index >= 0) {
                    myCarousel.value.slideTo(index);
                }
            }
        })

        onMounted(() => {
            // TScMessenger.writeDebugMessage('in MenuContentItemSelectionGroup onMounted');
        });

        onUnmounted(() => {
            selectionGroup.value.setActivateMenuTabFunction(null);
        })

        watch(props.valueFromParent.selectedMenuItems,
            (newValue, oldValue) => {
                if(newValue.length > 0) {
                    selectionGroup.value.updateSlider();
                    if(selectionGroup.value.getActivateMenuCardOnMount() !== null){
                        let menuCardToActivate = selectionGroup.value.getActivateMenuCardOnMount();
                        let indexOfCard = selectionGroup.value.selectedMenuItems.value.findIndex(function(card){
                            return card.id() === menuCardToActivate.id();
                        })
                        $("#" + selectionGroup.value.displayId + "-slider-" + menuCardToActivate.id()).click();
                        // Slide to 
                        if(myCarousel.value) {
                            myCarousel.value.slideTo(indexOfCard);
                        }
                        // currentSlide.value = indexOfCard;
                    }
                }
            })

        function deleteSelectionGroupItemClicked(menuCard) {
            // TScMessenger.writeDebugMessage('In deleteSelectionGroupItemClicked');
            selectionGroup.value.deleteSelectionGroupItem(menuCard);
        }

        function copySelectionGroupItemClicked(menuCard) {
            // TScMessenger.writeDebugMessage('In copySelectionGroupItemClicked');
            selectionGroup.value.copySelectionGroupItem(menuCard);
        }

        function pinSelectionGroupItemClicked(menuCard) {
            // TScMessenger.writeDebugMessage('In pinSelectionGroupItemClicked');
            menuCard.pinIconClicked(selectionGroup.value)
        }

        function activateMenuCard(menuCard) {
            // TScMessenger.writeDebugMessage('In pinSelectionGroupItemClicked');
        }

        function goToPrev() {
            myCarousel.value.prev();
        }
        function goToNext() {
            myCarousel.value.next();
        }

        function callResizeFunctions() {
            if(myCarousel.value !== null){
                myCarousel.value.updateBreakpointsConfigs();
                myCarousel.value.updateSlidesData();
                myCarousel.value.updateSlideWidth();
            }
        }

        /**
         * Function to be called in menu.js addSliderToStep function
         * This is run when the div is pulsed
         */
        function addSliderToStepCallback() {
            callResizeFunctions();
        }

        function isVisible() {
            if(isRef(selectionGroup.value.permissionObv)) {
                return selectionGroup.value.permissionObv.value.visible;
            } else {
                return selectionGroup.value.permissionObv.visible;
            }
        }

        function isSlideVisible(slide) {
            if(isRef(slide.permissionObv)) {
                return slide.permissionObv.value.visible;
            } else {
                return slide.permissionObv.visible;
            }
        }

        return {
            selectionGroup,
            selectionGroupActiveMenuStep,
            deleteSelectionGroupItemClicked,
            copySelectionGroupItemClicked,
            pinSelectionGroupItemClicked,
            activateMenuCard,
            myCarousel,
            goToPrev,
            goToNext,
            isVisible,
            isSlideVisible
        };
    },
    // components: { MenuCard, MenuContentItemSelectionWizard, MenuContent }
    components: { MenuCard, MenuContentItemSelectionWizard, MenuContent, Carousel, Slide }
}
</script>

<style lang="scss" scoped>
.menu-accessories-container-div .slick-track {
    display: flex;
}

.carousel__viewport,
.carousel__track {
    width: 425px !important;
}

.carousel__track li {
    width: 150px !important;
}
.carousel {
    text-align: inherit;
}
</style>