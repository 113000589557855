<template>
    <popup-modal ref="popup">
        <div id="modal" class="messageBox share-modal-container">
        <div class="modal-header modal-header-text">
            <h4 class="modal-title new-header-text" v-text="getTranslationText('1123')"></h4>
        </div>
        <div class="modal-body modal-body-text">

            
            <div class="datagrid-wizard-container" v-if="existingShareesArray.length > 0">
                <div class="message-text" v-text="getTranslationText('1124')"></div>
                
                <div class="share-modal-table-container">
                    <table class="share-modal-table">
                        <tbody v-for="(sharee, index) in existingShareesArray" v-bind:key="sharee.shareeEmailAddress">
                            <tr>
                                <td class="share-modal-table-icon-column">
                                    <div>
                                        <i class="far fa-user fa-lg"></i>
                                    </div>
                                </td>
                                <td class="share-modal-table-column">
                                    
                                    <div class="sharee-name" v-if="sharee.shareeName">
                                        <span v-text="sharee.shareeName"></span>
                                    </div>
                                    
                                    <div class="sharee-email">
                                        <span v-text="sharee.shareeEmailAddress"></span>
                                    </div>
                                </td>
                                <td>
                                    <span v-text="sharee.shareStatus"></span>
                                </td>
                                <td class="shareAccessLevel-td" :class="{ 'shareTypeOptions-td': sharee.shareeUserId !== null }">
                                    <div>
                                        <span class="shareAccessLevel-span" v-if="sharee.shareeUserId === null" v-text="sharee.shareAccessLevel"></span>
                                        <select v-else :value="sharee.shareAccessLevelId" :id="'shareAccessLevel-selectment-' + index">
                                            <option v-for="option in shareTypeOptions" :key="option" :value="option.id">
                                                {{ option.description }}
                                            </option>
                                        </select>
                                    </div>
                                </td>
                                <td class="share-modal-table-column share-modal-table-icon-column">
                                    <div v-if="sharee.shareeUserId !== null" class="selection-datagrid-type-icon-small datagrid-icon-delete" @click.stop="removeShare(sharee)" :title="getTranslationText('1137')">
                                        <i class="fas fa-times fa-lg"></i>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            

            <div class="table-container">
                <div class="message-text" v-text="getTranslationText('1122')"></div>
                <div class="message-text shareModal-message-subtext" v-text="getTranslationText('2535')"></div>
                <div class="share-modal-share-details">
                    <div class="share-modal-invitation-inputs">
                        <div class="selectize-container">
                            <input id="share-nameOrEmailAddress" 
                                    class="contacts" 
                                    type="text" 
                                    maxlength="200" 
                                    data-toggle="tooltip" 
                                    data-placement="right" 
                                    :placeholder="getTranslationText('1127')" 
                                    :data-title="getTranslationText('715')" />
                        </div>
                        <div class="menu-tooltip-div">
                            <a id='share-level-info-tooltip' data-placement="top" @click="displayTooltip('#share-level-info-tooltip')" :data-content="getTranslationText('4087')"><i class="fa fa-info-circle fa-lg"></i></a>
                        </div>
                        <div>
                            <select id="invitation-select" optionsText="description" optionsValue="id" :value="selectedInvitationOptionId">
                                <option v-for="shareTypeOption in shareTypeOptions" :value="shareTypeOption.id" :key="shareTypeOption.id">
                                    {{ shareTypeOption.description }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="input-container-div contains-textarea">
                        <textarea id="share-optionalComment" maxlength="2000" data-toggle="tooltip" data-placement="right" v-model="optionalComment" :placeholder="getTranslationText('1128')"></textarea>
                    </div>
                    <div class="tsc-checkbox-with-message" @click="toggleCheckbox" @touchstart="toggleCheckbox">
                        <div class="tsc-icon-container">
                            <i class="far fa-lg" :class="{ 'fa-check-square': isCopyMeToInvitiationCheckboxChecked, 'fa-square': !isCopyMeToInvitiationCheckboxChecked }"></i>
                        </div>
                        <span v-text="getTranslationText('2978')"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-default btn-truckscience btn-primary" @click="share" @touchstart="share">
                <span v-text="submitButtonText"></span>
            </button>
            <button class="btn btn-default btn-truckscience" @click="close" @touchstart="close"><span v-text="getTranslationText('1126')"></span></button>
        </div>
    </div>
    </popup-modal>


</template>

<script>

import config from '@/services/config';
import globals from '@/services/globals';
import dataManager from '@/services/dataManager';
import offerManager from '@/services/offerManager';
import CustomMessageBox from '@/services/CustomMessageBox';
import { onBeforeMount, onMounted, ref, shallowRef, toRef, triggerRef, nextTick } from 'vue';
import PopupModal from '@/components/modals/PopupModal.vue';

const $ = window.$;
const TScMessenger = window.TScMessenger;

export default {


    name: 'ShareModal',
    components: { PopupModal },
    props: {
        propsForModal: Object
    },
    setup (props) {
        const opts = toRef(props, 'propsForModal');
        const popup = ref(null);
        let resolvePromise = undefined,
        rejectPromise = undefined;

        let selectizeObject,
        typedText,
        getTranslationText = config.getTranslationText,
        submitButtonText = shallowRef(''),
        displayTooltip = globals.displayTooltip,
        optionalComment = ref(''),
        isCopyMeToInvitiationCheckboxChecked = ref(false);
        //#region Properties
        let offerDetails = null,
        shareFunction = null,
        spinnerShowFunc = null,
        shareeUserOfferDetailsArray = null,
        sharerUserAssociationsArray = null,
        handleEssentialsMessageResponse = null,
        originalShareeUserOfferDetailsArray = [];
    
    
//offerDetails, shareFunction, shareeUserOfferDetailsArray, sharerUserAssociationsArray, spinnerShowFunc, handleEssentialsMessageResponse
    
        let shareTypeOptions = [
            {
                id: config.SHARE_ACCESS_LEVEL_OPTIONS.EDIT,
                description: config.getTranslationText('2981'),
                isOptionDisabled: false
            }, 
            {
                id: config.SHARE_ACCESS_LEVEL_OPTIONS.VIEW_ONLY,
                description: config.getTranslationText('2982'),
                isOptionDisabled: false
            }
        ],
        selectedInvitationOption = getShareTypeOptionUsingId(config.SHARE_ACCESS_LEVEL_OPTIONS.VIEW_ONLY),
        selectedInvitationOptionId = selectedInvitationOption.id,
        existingShareesArray = shallowRef([]);//[{ shareeName: 'Bob', shareStatus: 'Invited' }, { shareeName: 'Joey', shareStatus: 'Accepted' }];
    
        
        onBeforeMount(() => {
            disableOptionsIfNecessary();

            offerDetails = opts.value.offerDetails;
            shareeUserOfferDetailsArray = opts.value.shareeUserOfferDetailsArray;
            existingShareesArray.value = getProcessedArrayForUI(opts.value.shareeUserOfferDetailsArray);
            triggerRef(existingShareesArray);
            handleEssentialsMessageResponse = opts.value.handleEssentialsMessageResponse;
            shareFunction = opts.value.shareFunction;
            sharerUserAssociationsArray = opts.value.sharerUserAssociationsArray;
            spinnerShowFunc = opts.value.spinnerShowFunc;
            submitButtonText.value = shareeUserOfferDetailsArray === null ? config.getTranslationText('1125') : config.getTranslationText('3057');
            originalShareeUserOfferDetailsArray = shareeUserOfferDetailsArray || [];
            
            
        })

        onMounted(() => {
            popup.value.open();
            nextTick(function () {
                createSelectizeDropdown();
        
                createSelectMenu('#invitation-select', function (event, ui) {
                    if (globals.user.isUserAllowedFullAccessToShareCalculations() === false && ui.item.value === config.SHARE_ACCESS_LEVEL_OPTIONS.EDIT) {
                        $('#invitation-select').val(config.SHARE_ACCESS_LEVEL_OPTIONS.VIEW_ONLY);
                        $('#invitation-select').selectmenu('refresh');
                        globals.showDialog(new CustomMessageBox(config.getMessageText('4493', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271'), config.getTranslationText('65')], config.getMessageReference('4493')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                            .addCallback(function (dialogResult) {
                                handleEssentialsMessageResponse(dialogResult, config.ESSENTIALS_CHECK_LOCATION.SHARE_CALCULATION, config.MODAL_DISPLAY_MODE.OVERLAY);
                            });
                            //.then(handleEssentialsMessageResponse);
                    } else {
                        selectedInvitationOptionId = ui.item.value;
                        selectedInvitationOption = getShareTypeOptionUsingId(selectedInvitationOptionId);
                    }
                }, updateEditDropdownOption);

                if (!globals.user.isUserAllowedFullAccessToShareCalculations()) {
                    shareTypeOptions[0].isOptionDisabled = true;
                }

                existingShareesArray.value.forEach(function (sharee, index) {
                    if (sharee.shareeUserId !== null) {
                        createSelectMenu('#shareAccessLevel-selectment-' + index, function (event, ui) {
                            //var sharee = ko.dataFor(event.target);
                            //sharee.shareAccessLevelId = ui.item.value;
                            //sharee.shareAccessLevel = getShareTypeOptionUsingId(sharee.shareAccessLevelId);
                            //TScMessenger.writeDebugMessage('');

                            if (globals.user.isUserAllowedFullAccessToShareCalculations() === false && ui.item.value === config.SHARE_ACCESS_LEVEL_OPTIONS.EDIT) {
                                $('#shareAccessLevel-selectment-' + index).val(config.SHARE_ACCESS_LEVEL_OPTIONS.VIEW_ONLY);
                                $('#shareAccessLevel-selectment-' + index).selectmenu('refresh');
                                globals.showDialog(new CustomMessageBox(config.getMessageText('4493', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271'), config.getTranslationText('65')], config.getMessageReference('4493')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                                    .addCallback(function (dialogResult) {
                                        handleEssentialsMessageResponse(dialogResult, config.ESSENTIALS_CHECK_LOCATION.SHARE_CALCULATION, config.MODAL_DISPLAY_MODE.OVERLAY);
                                    });
                            } else {
                                // Not sure if this code needs to be replaced - looks like it could be a copy
                                // var sharee = ko.dataFor(event.target);
                                sharee.shareAccessLevelId = ui.item.value;
                                sharee.shareAccessLevel = getShareTypeOptionUsingId(sharee.shareAccessLevelId);
                                // TScMessenger.writeDebugMessage('');
                            }
                        }, updateEditDropdownOption);
                    }
                });
            });
        })

        function show() {
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                rejectPromise = reject
            })
            
        }

        function close() {
            popup.value.close();
            resolvePromise();
        }

        function disableOptionsIfNecessary() {
            if (!globals.user.isUserAllowedFullAccessToShareCalculations()) {
                shareTypeOptions[0].isOptionDisabled = true;
            }
        }


        function createSelectMenu(id, changeCallback, createCallback) {
            $(id).selectmenu({
                width: 120,
                open: typeof createCallback === 'function' ? createCallback : function () { },
                change: changeCallback
            });
        }

        function getShareTypeOptionUsingId(id) {
            return shareTypeOptions.find(function (option) {
                return option.id === id;
            });
        }

        function toggleCheckbox() {
            if (isCopyMeToInvitiationCheckboxChecked.value === true) {
                isCopyMeToInvitiationCheckboxChecked.value = false;
            } else {
                isCopyMeToInvitiationCheckboxChecked.value = true;
            }
        }

        function clearFields() {
            $('#share-nameOrEmailAddress').val('');
            optionalComment.value = '';
        }

        function checkFields() {
            var returnVal = true;

            if ($('#share-nameOrEmailAddress').val() === '') {
                $('#share-nameOrEmailAddress').addClass('error');
                returnVal = {
                    isSuccessful: false,
                    showMessage: true,
                    messageCode: '1599'
                };
            }

            var emailsArray = $('#share-nameOrEmailAddress').val().split(',');
            emailsArray.forEach(function (email) {
                if (email.toLowerCase() === globals.user.getEmailAddress().toLowerCase()) {                        
                    returnVal = {
                        isSuccessful: false,
                        showMessage: true,
                        messageCode: '1595'
                    };
                }
            });
            
            return returnVal;
        }

        function removeErrorFormatting() {
            $('#share-nameOrEmailAddress').removeClass('error');
        }

        function removeShare(itemData) {
            spinnerShowFunc(true);
            var drivenBySharer = true
            dataManager.deleteUserOfferShareObject(itemData.shareeUserId, offerDetails, drivenBySharer)
                .then(function (result) {
                    spinnerShowFunc(false);
                    if (result.ReturnCode === 1) {
                        var indexOfElementToRemove = originalShareeUserOfferDetailsArray.map(function (item) { return item.UserId; }).indexOf(itemData.shareeUserId),
                            selectizeObjectToUse = getSelectizeObject(),
                            objectToAdd = {
                                email: itemData.shareeEmailAddress
                            };
                        if (itemData.shareeName !== null) {
                            objectToAdd.name = itemData.shareeName;
                        }
                        
                        selectizeObjectToUse.addOption(objectToAdd);
                        originalShareeUserOfferDetailsArray.splice(indexOfElementToRemove, 1);
                        existingShareesArray.value = getProcessedArrayForUI(originalShareeUserOfferDetailsArray);
                        triggerRef(existingShareesArray);
                        var metaDataObj = {};
                        metaDataObj[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = offerDetails.Description;
                        metaDataObj[config.INTERCOM_METADATA_ITEM.INITIATED_BY] = config.SHARE_USER_TYPES.SHARER;
                        dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.UNSHARED_OFFER, metaDataObj));
                    }
                })
                .fail(function (error) {
                    spinnerShowFunc(false);
                    globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                });
        }

        function getProcessedArrayForUI(shareeArray) {
            var processedSharees = [];

            processedSharees.push({
                shareeEmailAddress: globals.user.getEmailAddress(),
                shareeName: globals.user.getDisplayName() + ' (' + config.getTranslationText('1134') + ')',
                shareStatus: '',
                shareAccessLevel: config.getTranslationText('1135'),
                shareeUserId: null,
            });

            if (shareeArray !== null) {
                shareeArray.forEach(function (sharee) {
                    var shareeEmailAddress, shareeName, shareStatus, processedSharee;
                    shareeEmailAddress = sharee.EmailAddress;

                    if (sharee.FirstName === config.shareeTempName) {
                        shareeName = null;
                    } else {
                        shareeName = sharee.FirstName + ' ' + sharee.LastName;
                    }
                    if (sharee.ShareStatus === config.SHARE_STATUS_TYPES.INVITED || sharee.ShareStatus === config.SHARE_STATUS_TYPES.NOTIFIED || sharee.ShareStatus === config.SHARE_STATUS_TYPES.UPDATED) {
                        shareStatus = config.getTranslationText('1132');
                    } else {
                        shareStatus = config.getTranslationText('1133');
                    }
                    
                    processedSharee = {
                        shareeEmailAddress: shareeEmailAddress,
                        shareeName: shareeName,
                        shareStatus: shareStatus,
                        originalShareAccessLevelId: sharee.SharePermission,
                        shareeUserId: sharee.UserId
                    };

                    processedSharee.shareAccessLevelId = sharee.SharePermission;
                    processedSharee.shareAccessLevel = getShareTypeOptionUsingId(processedSharee.shareAccessLevelId);
                    processedSharees.push(processedSharee);
                });
            }
            return processedSharees;
        }

        /**
         * If the user is not allowed to share calculations
         * Update first element in array if the use upgrade icon
         * */
        function updateEditDropdownOption() {
            if (globals.user.isUserAllowedFullAccessToShareCalculations() === false) {
                //var selectMenu = $('#invitation-select-menu li:first-child');
                var selectMenu = $('#' + this.id + '-menu li:first-child');
                if (selectMenu[0].innerHTML.includes('<i class="fa fa-chevron-circle-up info-upgrade-icon"></i>') === false) {
                    selectMenu[0].innerHTML = selectMenu[0].innerHTML + '<i class="fa fa-chevron-circle-up info-upgrade-icon"></i>';
                }
            }
        }

        function createSelectizeDropdown() {
            var REGEX_EMAIL = '([a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)';

            selectizeObject = $('#share-nameOrEmailAddress').selectize({
                persist: false,
                maxItems: null,
                valueField: 'email',
                labelField: 'name',
                searchField: ['name', 'email'],
                options: getUserAndEmailObjects(),
                plugins: ['remove_button'],
                render: {
                    item: function (item, escape) {
                        return '<div>' +
                            (item.name ? '<span class="name">' + escape(item.name) + '</span>' : '') +
                            (!item.name && item.email ? '<span class="email">' + escape(item.email) + '</span>' : '') +
                            '</div>';
                    },
                    option: function (item, escape) {
                        var label = item.name || item.email;
                        var caption = item.name ? item.email : null;
                        return '<div>' +
                            '<span class="label">' + escape(label) + '</span>' +
                            (caption ? '<span class="caption">' + escape(caption) + '</span>' : '') +
                            '</div>';
                    }
                },
                createFilter: function (input) {
                    var match, regex;

                    // email@address.com
                    regex = new RegExp('^' + REGEX_EMAIL + '$', 'i');
                    match = input.match(regex);
                    if (match) return !this.options[match[0]];

                    // name <email@address.com>
                    regex = new RegExp('^([^<]*)<' + REGEX_EMAIL + '>$', 'i');
                    match = input.match(regex);
                    if (match) return !this.options[match[2]];

                    return false;
                },
                create: function (input) {
                    if ((new RegExp('^' + REGEX_EMAIL + '$', 'i')).test(input)) {
                        return { email: input };
                    }
                    var match = input.match(new RegExp('^([^<]*)<' + REGEX_EMAIL + '>$', 'i'));
                    if (match) {
                        return {
                            email: match[2],
                            name: $.trim(match[1])
                        };
                    }
                    // alert('Invalid email address.');
                    return false;
                },
                createOnBlur: true,
                onBlur: function () {
                    // TScMessenger.writeDebugMessage('');
                    var selectizeObjectTest = getSelectizeObject();
                    // if (selectizeObjectTest.options.hasOwnProperty(typedText) === true) {
                    if (Object.prototype.hasOwnProperty.call(selectizeObjectTest.options, typedText) === true) {
                        selectizeObjectTest.addItem(selectizeObjectTest.options[typedText].email);
                    }
                },
                onType: function (val) {
                    typedText = val;
                }
            });
        }

        function getUserAndEmailObjects(){
            var returnArray = [];

            // TODO: add check to remove subordinates from list of emails

            sharerUserAssociationsArray.forEach(function (sharerUser) {
                var objToAdd, alreadySharedWithUser = false;
                if (sharerUser.RelationshipType === "PEER") {
                    existingShareesArray.value.forEach(function (userAlreadySharedOffer) {
                        if (userAlreadySharedOffer.shareeEmailAddress === sharerUser.EmailAddress) {
                            alreadySharedWithUser = true;
                        }
                    });
                    if (!alreadySharedWithUser) {
                        objToAdd = {
                            email: sharerUser.EmailAddress
                        };

                        if (sharerUser.FirstName !== "ShareeFirstName") {
                            objToAdd.name = sharerUser.FirstName + ' ' + sharerUser.LastName;
                        }

                        returnArray.push(objToAdd);
                    }
                }
            });

            var supportEmailArray = returnArray.filter(function (item) {
                return item.email === globals.user.getSupportEmailAddress();
            });

            var supportEmailExistingShareesArray = existingShareesArray.value.filter(function (item) {
                return item.email === globals.user.getSupportEmailAddress();
            });

            if (supportEmailArray.length === 0 && supportEmailExistingShareesArray.length === 0) {
                returnArray.push({
                    email: globals.user.getSupportEmailAddress(),
                    name: config.getTranslationText('2977')
                });
            }

            // sort array on name
            returnArray.sort(function (a, b) {
                if (a.email === globals.user.getSupportEmailAddress()) {
                    return -1;
                }
                if (b.email === globals.user.getSupportEmailAddress()) {
                    return 1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            });

            return returnArray;
        }

        function clearItemFromSelectize(itemToClear) {
            var selectizeObjectToUse = getSelectizeObject(),
                stringArray;
            if (typeof itemToClear === 'string') {
                if (itemToClear.includes(',')) {
                    // split string
                    stringArray = itemToClear.split(',');
                    // loop though
                    stringArray.forEach(function (clearThis) {
                        selectizeObjectToUse.removeOption(clearThis);
                    });
                } else {
                    selectizeObjectToUse.removeOption(itemToClear);
                }
            }
        }

        function getSelectizeObject() {
            return selectizeObject[0].selectize;
        }
    
        function share() {
            var checkFieldsValue = true,
                changesMadeToSharees = false;

            existingShareesArray.value.forEach(function (sharee, index) {
                if (sharee.shareeUserId !== null) {
                    if (sharee.shareAccessLevelId !== sharee.originalShareAccessLevelId) {
                        changesMadeToSharees = true;
                    }
                }
            });

            if ($('#share-nameOrEmailAddress').val() !== '') {
                checkFieldsValue = this.checkFields();
            }

            removeErrorFormatting();

            // TC TODO 2020.2: Add check here for changesMadeToSharees. Need to know what is required by cloud services
            if (checkFieldsValue === true || changesMadeToSharees === true) {

                var cleanedEmailAddessList = stripDuplicateEmails($('#share-nameOrEmailAddress').val());

                if (cleanedEmailAddessList !== '' || changesMadeToSharees === true) {
                    spinnerShowFunc(true);
                    doShare(getShareeInfoStubs(cleanedEmailAddessList));
                    close();
                } else {
                    clearItemFromSelectize($('#share-nameOrEmailAddress').val());
                    offerManager.getActiveOffer().updateShareInfo(existingShareesArray.value);
                    getSelectizeObject().clear(true);
                    close();
                }
            } else {
                if (typeof checkFieldsValue === 'object' && !checkFieldsValue.isSuccessful && checkFieldsValue.showMessage) {
                    globals.showDialog(new CustomMessageBox(config.getMessageText(checkFieldsValue.messageCode, globals.getActiveCultureCodeForTranslations(), globals.user.getEmailAddress()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference(checkFieldsValue.messageCode)).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY)).addCallback(function () {
                        $('#share-nameOrEmailAddress').addClass('error');
                    });
                }
                // notify user to update the relevant fields
            }

            function stripDuplicateEmails(emailString) {
                originalShareeUserOfferDetailsArray
                    .forEach(function (userOfferDetail) {
                        emailString = emailString.replace(userOfferDetail.EmailAddress, '');
                    });
                emailString.replace(',,', ',');
                if (emailString.substr(emailString.length - 1) === ',') {
                    emailString = emailString.substring(0, emailString.length - 1);
                }
                if (emailString.substr(0, 1) === ',') {
                    emailString = emailString.substring(1);
                }
                return emailString;
            }

            function doShare(shareeInfoStubs) {
                if (shareeInfoStubs.length > 0 || changesMadeToSharees === true) {
                    shareFunction(shareeInfoStubs, offerDetails, optionalComment.value, isCopyMeToInvitiationCheckboxChecked.value)
                        .then(function (result) {
                            spinnerShowFunc(false);
                            if (result.Result.ReturnCode > 0) {
                                clearItemFromSelectize($('#share-nameOrEmailAddress').val());
                                getSelectizeObject().clear(true);
                                if(result.ShareDetails !== null && result.ShareDetails !== undefined) {
                                    offerManager.getActiveOffer().setupShareInfo(result.ShareDetails);
                                }
                                
                                var metaDataObj = {};
                                metaDataObj[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = offerDetails[0].Description;
                                metaDataObj[config.INTERCOM_METADATA_ITEM.ACCESS_PERMISSION] = selectedInvitationOptionId;
                                dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.SHARED_OFFER, metaDataObj));
                            } else {
                                dataManager.log("share, dataManager.shareOffer, success code path, request failed, ReturnCode:" + result.Result.ReturnCode + ", Message=" + result.Result.Message + ", 1639", null, 'shareModal', config.LOG_MESSAGE_TYPE.ERROR);
                                globals.showDialog(new CustomMessageBox(config.getMessageText('1639', globals.getActiveCultureCodeForTranslations()).replace('$S1', $('#share-nameOrEmailAddress').val()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1639')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                            }
                            
                        })
                        .fail(function (error) {
                            spinnerShowFunc(false);
                            clearItemFromSelectize($('#share-nameOrEmailAddress').val());
                            getSelectizeObject().clear(true);
                            dataManager.log("share, dataManager.shareOffer, fail, 1571", error, 'shareModal', config.LOG_MESSAGE_TYPE.ERROR);
                            globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.getActiveCultureCodeForTranslations()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                        });
                }
            }
            
            function getShareeInfoStubs(cleanedEmailAddessList) {
                var shareeInfoStubs = [];
                var newShareeEmailAddresses;
                if (cleanedEmailAddessList.indexOf(',') === -1) {
                    newShareeEmailAddresses = [cleanedEmailAddessList];
                } else {
                    newShareeEmailAddresses = cleanedEmailAddessList.split(',');
                }
                existingShareesArray.value.forEach(function (existingSharee) {
                    if (existingSharee.shareAccessLevel.id) {
                        shareeInfoStubs.push({
                            ShareeEmailAddress: existingSharee.shareeEmailAddress,
                            SharePermission: existingSharee.shareAccessLevelId,
                            IsNew: false
                        })
                    }
                });
                newShareeEmailAddresses.forEach(function (newShareeEmailAddress) {
                    if (newShareeEmailAddress !== '') {
                        shareeInfoStubs.push({
                            ShareeEmailAddress: newShareeEmailAddress,
                            SharePermission: selectedInvitationOptionId,
                            IsNew: true
                        })
                    }
                });
                return shareeInfoStubs;
            }
        }

        return {
            popup,
            show,
            share,
            removeShare,
            getTranslationText,
            submitButtonText,
            displayTooltip,
            existingShareesArray,
            toggleCheckbox,
            checkFields,
            clearFields,
            shareTypeOptions,
            optionalComment,
            selectedInvitationOptionId,
            isCopyMeToInvitiationCheckboxChecked,
            close
        }
    }
}
</script>

<style lang="scss" scoped>

</style>